/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Table } from 'react-bootstrap';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import Select from '../Misc/Select';
import { randomKey, reorder } from '../../utils/utils';
import TitleButton from '../TitleButton';
import { startUpdateConfig } from '../../actions/auth';
import Copy from './Copy';
import { setOnDragEndCallback } from '../DropContext';
import { startDownloadCategories } from '../../actions/priceGuide2';
import InlineAutoComplete from '../SharedComponents/InlineAutoComplete';

const measureSheetTypes = [
  {
    value: 'default',
    label: 'Standard',
  },
  {
    value: 'detail',
    label: 'Drill-Down',
  },
  {
    value: 'deep_drill_down',
    label: 'Deep Drill-Down',
  },
];

const categoriesDropableSectionId = 'estimateDropableSection';

const invalidFormulaIds = ['B', 'CP', 'DP', 'FP', 'T', 'UA'];

const errorsFromState = ({ categories = [] }) => {
  const errors = {};
  categories.forEach(({ name, type, objectId, formulaId = '' }, index) => {
    const error = {};
    if (!name) {
      error.name = 'Required';
    }
    if (!type) {
      error.type = 'Required';
    }
    if (invalidFormulaIds.indexOf(formulaId.toUpperCase()) > -1) {
      error.formulaId = 'Invalid';
    }
    if (formulaId) {
      const found = categories
        .filter((cat, catIndex) => catIndex !== index && cat.formulaId)
        .find((cat) => cat.formulaId === formulaId);
      if (found) {
        error.formulaId = 'Duplicate';
      }
    }
    if (error.name || error.type || error.formulaId) {
      errors[objectId] = error;
    }
  });
  return errors;
};

export class Categories extends React.Component {
  constructor(props) {
    super(props);
    const newCategories = props.categories.map((obj) => ({
      ...obj,
      objectId: obj.objectId || randomKey(10),
      showCopy: false,
    }));
    const newState = { categories: newCategories };
    newState.errors = errorsFromState(newState);
    this.state = newState;
    setOnDragEndCallback(categoriesDropableSectionId, (result) => {
      // dropped outside the list
      const { destination = {} } = result;
      if (destination === null) {
        return;
      }
      const { droppableId } = destination;
      if (droppableId !== categoriesDropableSectionId) {
        return;
      }
      const categories = reorder(
        this.state.categories,
        result.source.index,
        result.destination.index,
      );
      this.setState({ categories });
    });
    this.renderButtons = () => {
      const { setButtons } = this.props;

      setButtons(
        <>
          <TitleButton
            variant="primary"
            onClick={() => this.setState({ showCopy: true })}
            title="Copy"
          />
          <TitleButton
            variant="success"
            onClick={() => this.onSave()}
            title="Save"
            disabled={!this.isInLimit() || this.saveDisabled()}
          />
        </>,
      );
    };
  }

  componentDidMount() {
    this.props.startDownloadCategories();
    const {
      setCrumbs,
      location: { pathname },
    } = this.props;

    const crumb = { title: 'Categories', link: pathname };
    setCrumbs([crumb]);
    this.renderButtons();
  }

  UNSAFE_componentWillReceiveProps({ categories }) {
    const newCategories = categories.map((obj) => ({
      ...obj,
      objectId: obj.objectId || randomKey(10),
    }));
    // const newState = { categories: newCategories };
    // newState.errors = errorsFromState(newState);
    const propsChange = !_.isEqual(categories, this.props.categories);
    if (propsChange) {
      this.setState({ categories: newCategories });
    }
  }

  componentDidUpdate(prevProps, previousState) {
    const { categories } = this.props;
    const { categories: prevCategories } = prevProps;

    const propsChange = !_.isEqual(prevCategories, categories);
    const stateChange = !_.isEqual(
      previousState.categories,
      this.state.categories,
    );
    if (propsChange || stateChange) {
      this.renderButtons();
    }
  }

  componentWillUnmount() {
    const { setCrumbs, setButtons } = this.props;

    setCrumbs([]);
    setButtons();
  }

  onAdd = () => {
    let categories = [...this.state.categories];
    categories.unshift({
      name: '',
      type: 'default',
      order: 0,
      objectId: randomKey(10),
    });
    categories = categories.map((obj, index) => ({
      ...obj,
      order: index,
    }));
    this.setState({ categories });
  };

  onValueChanged = (value, key, id) => {
    const categories = this.state.categories.map((obj) => {
      if (obj.objectId === id) {
        return {
          ...obj,
          [key]: value,
        };
      }
      return obj;
    });
    this.setState({ categories });
  };

  onCategoryChanged = ({ value }, id) => {
    this.onValueChanged(value, 'name', id);
  };

  onMeasureSheetChange = ({ value }, id) => {
    this.onValueChanged(value, 'type', id);
  };

  onFormulaIdChange = ({ value }, id) => {
    this.onValueChanged(value ? value.toUpperCase() : value, 'formulaId', id);
  };

  onDelete = (id) => {
    const categories = this.state.categories.filter(
      ({ objectId }) => objectId !== id,
    );
    this.setState({ categories });
  };

  onSave = () => {
    this.props.startUpdateConfig({
      categories_: this.state.categories,
    });
  };

  setState(state) {
    const newState = { ...state };
    newState.errors = errorsFromState(newState);
    super.setState(newState);
  }

  saveDisabled = () => {
    const keys = Object.keys(this.state.errors);
    return !!keys.length;
  };

  isInLimit = () => {
    const numberOfCategories = this.state.categories.length;
    const { maxCategoryCount } = this.props;
    const inLimit =
      maxCategoryCount === -1 ? true : numberOfCategories < maxCategoryCount;
    return inLimit;
  };

  render() {
    return (
      <>
        <Copy
          title="Copy Categories"
          show={this.state.showCopy}
          onClose={() => this.setState({ showCopy: false })}
          warning="Warning! This will overwrite your current category settings!"
          configKeys={['categories_']}
        />
        <Table striped bordered hover className="categories-table">
          <thead>
            <tr>
              <th style={{ textAlign: 'center' }}>Category</th>
              <th style={{ textAlign: 'center', width: '100px' }}>
                Identifier
              </th>
              <th style={{ textAlign: 'center', width: '175px' }}>
                Measure Sheet Type
              </th>
              <th style={{ textAlign: 'center', width: '100px' }}>
                <button
                  className="btn btn-primary"
                  disabled={!this.isInLimit()}
                  type="button"
                  onClick={this.onAdd}
                >
                  Add
                </button>
              </th>
            </tr>
          </thead>
          <Droppable
            droppableId={categoriesDropableSectionId}
            type={categoriesDropableSectionId}
          >
            {(provided) => (
              <tbody
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{
                  ...provided.droppableProps.style,
                  width: '100% !important',
                }}
              >
                {this.state.categories.map(
                  ({ name, type, objectId, formulaId = '' }, index) => {
                    const { errors } = this.state;
                    const error = errors[objectId] || {};
                    return (
                      <Draggable
                        key={objectId}
                        draggableId={objectId}
                        index={index}
                      >
                        {(draggableProvided, snapshot) => (
                          <tr
                            key={objectId}
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                            style={{
                              ...draggableProvided.draggableProps.style,
                              width: '100% !important',
                            }}
                          >
                            <td
                              style={{
                                backgroundColor: snapshot.isDragging
                                  ? '#F7F7F7'
                                  : 'transparent',
                                width: '80%',
                              }}
                            >
                              <div
                                style={{
                                  border: error.name ? '1px solid red' : 0,
                                  backgroundColor: 'white',
                                }}
                              >
                                <InlineAutoComplete
                                  selected={[{ label: name, value: name }]}
                                  options={this.props.allCategories}
                                  onSelection={(selected) => {
                                    const [item] = selected;
                                    this.onCategoryChanged(item, objectId);
                                  }}
                                />
                              </div>
                              {error.name && (
                                <div className="danger">{error.name}</div>
                              )}
                            </td>
                            <td
                              style={{
                                backgroundColor: snapshot.isDragging
                                  ? '#F7F7F7'
                                  : 'transparent',
                                width: '100px',
                              }}
                            >
                              <input
                                className="table-row__cell__input"
                                type="text"
                                value={formulaId}
                                placeholder="Identifier"
                                onChange={(e) =>
                                  this.onFormulaIdChange(
                                    { value: e.target.value },
                                    objectId,
                                  )
                                }
                              />
                              {error.formulaId && (
                                <div className="danger">{error.formulaId}</div>
                              )}
                            </td>
                            <td
                              style={{
                                backgroundColor: snapshot.isDragging
                                  ? '#F7F7F7'
                                  : 'transparent',
                                width: '175px',
                              }}
                            >
                              <div
                                style={{
                                  border: error.type ? '1px solid red' : 0,
                                }}
                              >
                                <Select
                                  name="form-field-name"
                                  value={type}
                                  options={measureSheetTypes}
                                  isClearable={false}
                                  closeMenuOnSelect
                                  onChange={(value) =>
                                    this.onMeasureSheetChange(value, objectId)
                                  }
                                />
                              </div>
                              {error.type && (
                                <div className="danger">{error.type}</div>
                              )}
                            </td>
                            <td
                              style={{
                                backgroundColor: snapshot.isDragging
                                  ? '#F7F7F7'
                                  : 'transparent',
                                width: '100px',
                              }}
                            >
                              <button
                                className="fill-cell danger"
                                type="button"
                                onClick={() => this.onDelete(objectId)}
                              >
                                <i className="far fa-trash-alt" />
                              </button>
                            </td>
                            {draggableProvided.placeholder}
                          </tr>
                        )}
                      </Draggable>
                    );
                  },
                )}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </Table>
        {!this.isInLimit() && (
          <p className="danger">
            Plan is limited to {this.props.maxCategoryCount} Categories
          </p>
        )}
      </>
    );
  }
}

Categories.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      order: PropTypes.number.isRequired,
      type: PropTypes.oneOf(['default', 'detail', 'deep_drill_down'])
        .isRequired,
    }),
  ),
  maxCategoryCount: PropTypes.number.isRequired,
  startUpdateConfig: PropTypes.func.isRequired,
  ...layoutContextPropTypes,
};

Categories.defaultProps = {
  categories: [],
};

const mapStateToProps = ({
  auth: { config },
  plan = {},
  priceGuide2: { categories = [] },
}) => ({
  categories: config.categories_,
  maxCategoryCount: plan.maxCategoryCount,
  startDownloadCategories: PropTypes.func.isRequired,
  allCategories: categories.map((catString) => ({
    label: catString,
    value: catString,
  })),
});

const mapDispatchToProps = (dispatch) => ({
  startUpdateConfig: (updates) => dispatch(startUpdateConfig(updates)),
  startDownloadCategories: () => dispatch(startDownloadCategories()),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(Categories),
);
