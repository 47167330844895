/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { LayoutContext } from '../../Layout';
import EmailSection from './Sections/EmailSection';
import MarketsharpSection from './Sections/MarketsharpSection';
import LeadPerfectionSection from './Sections/LeadPerfectionSection';
import JobNimbusSection from './Sections/JobNimbusSection';
import SalesforceSection from './Sections/SalesforceSection';
import WebhookSection from './Sections/WebhookSection';
import JobProgressSection from './Sections/JobProgressSection';
import TitleButton from '../../TitleButton';
import Copy from '../Copy';

const TemplateSending = (props) => {
  const {
    backupRecipients,
    bccRecipients,
    ccRecipients,
    copyKey,
    currentPath,
    disableSave,
    disableAttachment,
    emailFileName,
    emailHTML,
    endpoints,
    errors,
    fileName,
    footer,
    from,
    isResults,
    jobNimbus,
    jobProgress = {},
    leadPerfection,
    onCopy,
    onCopyClose,
    onSaveClicked,
    onValueChanged,
    recipients,
    salesforce,
    settingsSection,
    showCopy,
    showCopyButton,
    subjectLine,
    title,
    webhook,
    updateStateErrors = () => {},
  } = props;

  const showSection = (section) => endpoints.indexOf(section) > -1;
  const { setButtons } = useContext(LayoutContext);

  useEffect(() => {
    setButtons(
      <>
        {showCopyButton && (
          <TitleButton onClick={onCopy} variant="primary" title="Copy" />
        )}
        <TitleButton
          onClick={onSaveClicked}
          variant="success"
          title="Save"
          disabled={disableSave}
        />
      </>,
    );

    return () => setButtons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCopyButton, setButtons, onSaveClicked, disableSave]);

  useEffect(() => {
    const settingsObject = endpoints.reduce((setObj, endpoint) => {
      const key = `${endpoint
        .substring(0, 1)
        .toLowerCase()}${endpoint.substring(1)}`;
      const section = props[key];
      return {
        ...setObj,
        [key]: {
          ...section,
        },
      };
    }, {});

    updateStateErrors(settingsObject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpoints]);

  return (
    <>
      {showCopyButton && (
        <Copy
          title={`Copy ${title}`}
          show={showCopy}
          warning={`Warning! This will overwrite your current ${title}`}
          configKeys={[copyKey]}
          onClose={onCopyClose}
        />
      )}
      <div className="default-page-padding">
        {settingsSection()}
        {/* {showSection('Email') && */}
        {showSection('Email') && (
          <EmailSection
            currentPath={currentPath}
            onChange={onValueChanged}
            disableAttachment={disableAttachment}
            from={from}
            subjectLine={subjectLine}
            recipients={recipients}
            ccRecipients={ccRecipients}
            bccRecipients={bccRecipients}
            emailHTML={emailHTML}
            emailFileName={emailFileName}
            errors={errors}
          />
        )}
        {showSection('Marketsharp') && (
          <MarketsharpSection
            onChange={onValueChanged}
            fileName={fileName}
            backupRecipients={backupRecipients}
            errors={errors}
          />
        )}
        {showSection('LeadPerfection') && (
          <LeadPerfectionSection
            onChange={onValueChanged}
            value={leadPerfection}
            errors={errors}
          />
        )}
        {showSection('JobNimbus') && (
          <JobNimbusSection
            onChange={onValueChanged}
            value={jobNimbus}
            errors={errors}
          />
        )}
        {showSection('JobProgress') && (
          <JobProgressSection
            onChange={onValueChanged}
            value={jobProgress}
            errors={errors}
          />
        )}
        {showSection('Salesforce') && (
          <SalesforceSection
            onChange={onValueChanged}
            value={salesforce}
            errors={errors}
            isResults={isResults}
          />
        )}
        {showSection('Webhook') && (
          <WebhookSection
            onChange={onValueChanged}
            value={webhook}
            errors={errors}
          />
        )}
        {!showSection('JobProgress') && footer()}
      </div>
    </>
  );
};

TemplateSending.defaultProps = {
  currentPath: null,
  isResults: false,
  leadPerfection: {
    fileName: '',
    fileDescription: '',
    backupRecipients: [],
  },
  jobNimbus: {
    fileName: '%customerName% Attachment.pdf',
    fileDescription: '%date% Attachment',
    backupRecipients: ['%userEmail%'],
  },
  jobProgress: {
    fileName: '',
    fileDestination: '',
    backupRecipients: [],
  },
  salesforce: {
    fileName: '',
    fileDescription: '',
    backupRecipients: [],
  },
  webhook: {
    includePDF: false,
    url: '',
    authHeader: '',
    version: undefined,
  },
  endpoints: [],
  disableAttachment: false,
  from: '',
  subjectLine: '',
  recipients: [],
  ccRecipients: [],
  bccRecipients: [],
  emailHTML: '',
  emailFileName: '',
  fileName: '',
  backupRecipients: [],
  footer: () => undefined,
  errors: {
    from: '',
    subjectLine: '',
    recipients: '',
    ccRecipients: '',
    bccRecipients: '',
    emailHTML: '',
    emailFileName: '',
    backupRecipients: '',
    fileName: '',
  },
  onCopy: undefined,
  showCopy: false,
  showCopyButton: true,
  onCopyClose: () => {},
  disableSave: false,
  updateStateErrors: () => {},
};

TemplateSending.propTypes = {
  currentPath: PropTypes.string,
  isResults: PropTypes.bool,
  settingsSection: PropTypes.func.isRequired,
  footer: PropTypes.func,
  onValueChanged: PropTypes.func.isRequired,
  onSaveClicked: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  leadPerfection: PropTypes.shape({
    fileName: PropTypes.string,
    fileDescription: PropTypes.string,
    backupRecipients: PropTypes.arrayOf(PropTypes.string),
  }),
  jobNimbus: PropTypes.shape({
    fileName: PropTypes.string,
    fileDescription: PropTypes.string,
    backupRecipients: PropTypes.arrayOf(PropTypes.string),
  }),
  jobProgress: PropTypes.shape({
    fileName: PropTypes.string,
    fileDestination: PropTypes.string,
    backupRecipients: PropTypes.arrayOf(PropTypes.string),
  }),
  salesforce: PropTypes.shape({
    fileName: PropTypes.string,
    fileDescription: PropTypes.string,
    backupRecipients: PropTypes.arrayOf(PropTypes.string),
  }),
  webhook: PropTypes.shape({
    includePDF: PropTypes.bool,
    url: PropTypes.string,
    authHeader: PropTypes.string,
    version: PropTypes.string,
  }),
  endpoints: PropTypes.arrayOf(PropTypes.string),
  disableAttachment: PropTypes.bool,
  from: PropTypes.string,
  subjectLine: PropTypes.string,
  recipients: PropTypes.arrayOf(PropTypes.string),
  ccRecipients: PropTypes.arrayOf(PropTypes.string),
  bccRecipients: PropTypes.arrayOf(PropTypes.string),
  emailHTML: PropTypes.string,
  emailFileName: PropTypes.string,
  backupRecipients: PropTypes.arrayOf(PropTypes.string),
  fileName: PropTypes.string,
  errors: PropTypes.shape({
    from: PropTypes.string,
    subjectLine: PropTypes.string,
    recipients: PropTypes.string,
    ccRecipients: PropTypes.string,
    bccRecipients: PropTypes.string,
    emailHTML: PropTypes.string,
    emailFileName: PropTypes.string,
    backupRecipients: PropTypes.string,
    fileName: PropTypes.string,
  }),
  onCopy: PropTypes.func,
  showCopy: PropTypes.bool,
  copyKey: PropTypes.string.isRequired,
  onCopyClose: PropTypes.func,
  showCopyButton: PropTypes.bool,
  disableSave: PropTypes.bool,
  updateStateErrors: PropTypes.func,
};

export default TemplateSending;
