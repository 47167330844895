import {
  validateFrom,
  validateRecipients,
  validateCCRecipients,
  validateSubjectLine,
  validateEmailHTML,
  validateEmailFileName,
  validateMarketsharpBackupRecipients,
  validateMarketsharpFileName,
  validateLeadPerfection,
  validateSalesforce,
  validateWebhook,
  validateJobNimbus,
  validateJobProgress,
} from './ContractSending';
import {
  validateAlertInterval,
  validateResultsOptions,
} from './ResultsSending';

const validators = {
  from: validateFrom,
  recipients: validateRecipients,
  ccRecipients: validateCCRecipients,
  bccRecipients: validateCCRecipients,
  subjectLine: validateSubjectLine,
  emailHTML: validateEmailHTML,
  emailFileName: validateEmailFileName,
  backupRecipients: validateMarketsharpBackupRecipients,
  fileName: validateMarketsharpFileName,
  leadPerfection: validateLeadPerfection,
  jobNimbus: validateJobNimbus,
  jobProgress: validateJobProgress,
  salesforce: validateSalesforce,
  webhook: validateWebhook,
  alertInterval: validateAlertInterval,
  saleResult: validateResultsOptions,
  demoResult: validateResultsOptions,
};

const Validator = (key, value, state) => {
  const validator = validators[key];
  if (validator) {
    return validator(value, state);
  }
  return '';
};
export default Validator;

export const validateAll = (state) => {
  const keys = Object.keys(validators);
  const errors = {};
  keys.forEach((key) => {
    const value = state[key];
    errors[key] = validators[key](value, state);
  });
  return errors;
};

export const updatedErrors = (errors, state) => ({
  ...errors,
  ...validateAll(state),
});

export const checkForErrors = (errors) => {
  const keys = Object.keys(errors);
  for (let i = 0; i < keys.length; i += 1) {
    const value = errors[keys[i]];
    if (typeof value === 'string') {
      if (value.length) {
        return true;
      }
    } else if (Array.isArray(value)) {
      for (let j = 0; j < value.length; j += 1) {
        if (checkForErrors(value[j])) {
          return true;
        }
      }
    } else if (typeof value === 'object') {
      if (checkForErrors(value)) {
        return true;
      }
    }
  }
  return false;
};
