import { useState, useEffect } from 'react';
import Parse from 'parse';
import { useDispatch } from 'react-redux';
import { ReplaySubject } from 'rxjs';
import { isEqual } from 'lodash';
import math from 'mathjs';
import { hideLoader, showLoader } from '../actions/loading';
import AppToaster from '../utils/AppToaster';
import { pushToDataLayer } from '../actions/tagManager';

const defaultSectionListMap = {
  left: [],
  top: [],
  right: [],
};

const creditSubject = new ReplaySubject();

let statesList = [];

export const useCreditField = (fieldId) => {
  const [formData, setFormData] = useState({});
  const { creditAppFields, onFieldUpdate } = useCreditAppForm();
  const updatedFormData = getFormData(fieldId, creditAppFields);
  const displayType = getDisplayType(updatedFormData);

  const calculated = calculateValue(updatedFormData, creditAppFields, formData);
  const final = {
    ...updatedFormData,
    currentValue: calculated,
    displayType,
  };
  if (updatedFormData.currentValue !== calculated) {
    onFieldUpdate({ fieldId, update: final });
  } else {
    const changed = !isEqual(formData, final);
    if (changed) {
      setFormData(final);
    }
  }

  return { formData, onFieldUpdate };
};

export const useCreditAppForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const [creditAppData, setCreditAppData] = useState({});
  const [creditAppFields, setCreditAppFields] = useState({});
  const [sectionList, setSectionList] = useState({
    ...defaultSectionListMap,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    const subObj = creditSubject.subscribe({
      next: (creditAppFieldMap) => {
        setCreditAppFields({ ...creditAppFieldMap });
      },
    });
    return () => {
      subObj.unsubscribe();
    };
  }, []);

  const initCreditAppFields = (sections) => {
    const {
      creditAppFieldMap,
      sectionListMap,
      cellIdToFeildIds,
    } = getFieldMapsFromSections(sections);

    const allMaps = {
      ...creditAppFieldMap,
      cellIdToFeildIds,
    };
    updateCreditAppFields(allMaps);
    setSectionList({ ...sectionListMap });
  };

  const getPublicCreditApp = async ({ customerCreditAppId }) => {
    try {
      const response = await Parse.Cloud.run('getPublicCreditApp', {
        customerCreditAppId,
      });
      const { sections = [] } = response;
      setCreditAppData({
        ...response,
        customerCreditAppId,
      });

      statesList = [...response.statesList];
      initCreditAppFields(sections);
    } catch (error) {
      dispatch(onCreditAppError(error));
    }
  };

  const updateCreditAppFields = (updated) => {
    creditSubject.next(updated);
  };

  const onFieldUpdate = (event) => {
    const { fieldId, update } = event;

    if (creditAppFields[fieldId]) {
      creditAppFields[fieldId].formData = {
        ...update,
      };
      updateCreditAppFields(creditAppFields);
    }
  };

  const submitPublicCreditApp = async ({ values }) => {
    try {
      const { customerCreditAppId } = creditAppData;
      dispatch(showLoader('submitting ...'));
      await Parse.Cloud.run('processCustomerValues', {
        customerCreditAppId,
        values,
      });
      setSubmitted(true);
      if (creditAppData.redirectURL) {
        window.location.href = creditAppData.redirectURL;
      }
    } catch (error) {
      dispatch(onCreditAppError(error));
    } finally {
      dispatch(hideLoader());
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    const { cellIdToFeildIds, ...fields } = creditAppFields;

    const invalidField = getInvalidFeilds(creditAppFields);

    const canSave = Object.keys(invalidField).length === 0;
    if (canSave) {
      const values = Object.keys(fields)
        .map((fieldId) => fields[fieldId])
        .reduce((valueMap, field) => {
          const { appKey, formData = {} } = field;
          const { currentValue } = formData;
          return {
            ...valueMap,
            [appKey]: `${currentValue}`,
          };
        }, {});
      submitPublicCreditApp({ values });
    } else {
      updateCreditAppFields({ ...creditAppFields, ...invalidField });
    }
  };

  return {
    submitted,
    creditAppData,
    getPublicCreditApp,
    creditAppFields,
    onFieldUpdate,
    sectionList,
    initCreditAppFields,
    updateCreditAppFields,
    onSubmit,
  };
};

const onCreditAppError = (error) => (dispatch) => {
  dispatch(
    pushToDataLayer({
      event: 'leapErrorEvent',
      eventCategory: 'Errors',
      eventAction: window.location.pathname,
      eventLabel: error.message || error.code,
    }),
  );

  AppToaster.show({
    message: error.message || error.code,
    className: '',
    timeout: 60000,
  });
};

const getDefaultFormData = (formField) => {
  const { pickerOptions = [] } = formField;
  const options = pickerOptions.map((pickerValue) => {
    return {
      label: pickerValue,
      value: pickerValue,
    };
  });

  const defaultFromData = formField.formData || {
    ...formField,
    enabled: true,
    pickerOptions: options,
    currentValue: formField.value,
  };

  return defaultFromData;
};

const getSectionFields = (sectionData, sectionKey) => {
  const { cells = [] } = sectionData;
  return cells.reduce(
    ({ fieldMap = {}, sectionCellIds = {}, list = [] }, fieldData, index) => {
      const { cellId, cellType } = fieldData;

      if (cellType === 'photos') {
        return {
          fieldMap,
          sectionCellIds,
          list,
        };
      }

      const fieldId = `${sectionKey}-${index}`;
      const formData = getDefaultFormData(fieldData);
      const fieldWithFormData = {
        ...fieldData,
        formData: {
          ...formData,
          fieldId,
        },
      };
      return {
        fieldMap: {
          ...fieldMap,
          [fieldId]: fieldWithFormData,
        },
        sectionCellIds: {
          ...sectionCellIds,
          ...(cellId ? { [cellId]: fieldId } : {}),
        },
        list: [...list, { ...fieldData, fieldId }],
      };
    },
    {},
  );
};

export const getFieldMapsFromSections = (sections) => {
  return sections.reduce(
    (
      {
        creditAppFieldMap,
        sectionListMap = { ...defaultSectionListMap },
        cellIdToFeildIds = {},
      },
      sectionData,
    ) => {
      const { section } = sectionData;

      const listSection = sectionListMap[section] || [];

      const sectionKey = `${section}${listSection.length}`;

      const sectionFields = getSectionFields(sectionData, sectionKey);

      const sectionListData = {
        ...sectionData,
        sectionKey,
        cellList: [...sectionFields.list],
      };

      const { sectionCellIds = {} } = sectionFields;

      return {
        creditAppFieldMap: {
          ...creditAppFieldMap,
          ...sectionFields.fieldMap,
        },
        sectionListMap: {
          ...sectionListMap,
          [section]: [...listSection, sectionListData],
        },
        cellIdToFeildIds: {
          ...cellIdToFeildIds,
          ...sectionCellIds,
        },
      };
    },
    {},
  );
};

const getInvalidFeilds = (creditAppFields) => {
  const { cellIdToFeildIds, ...fields } = creditAppFields;

  const invladFields = Object.keys(fields).reduce(
    (invalidFieldObject, fieldId) => {
      const updatedFormData = getFormData(fieldId, creditAppFields);
      const { required, currentValue } = updatedFormData;
      const isInvalid = required && !currentValue && currentValue !== 0;
      const field = fields[fieldId];

      if (isInvalid) {
        return {
          ...invalidFieldObject,
          [fieldId]: {
            ...field,
            formData: {
              ...updatedFormData,
              errorMessage: 'Required',
            },
          },
        };
      }
      return {
        ...invalidFieldObject,
      };
    },
    {},
  );

  return invladFields;
};

const updateDynamicField = ({ formField, updatedFields }) => {
  const { dynamicInputObject, pickerOptions = [], formData = {} } = formField;

  const refCell = updatedFields.find(
    (field) => field.cellId === dynamicInputObject.cellId,
  );

  if (refCell) {
    const { objects } = dynamicInputObject;

    const { formData: refFormData = {} } = refCell;
    const { currentValue: refValue } = refFormData;

    const defaultObject =
      objects.find((object) => object.values.includes('default')) || {};

    const objectMatch =
      objects
        .filter((object) => !object.values.includes('default'))
        .find(({ values = [] }) => values.includes(refValue)) || defaultObject;

    const options = (objectMatch.inputType === 'picker'
      ? objectMatch.pickerValues
      : pickerOptions
    ).map((pickerValue) => {
      return {
        label: pickerValue,
        value: pickerValue,
      };
    });
    return {
      ...formField,
      ...formData,
      ...objectMatch,
      pickerOptions: options,
    };
  }

  return formField;
};

const validateFormData = (formData = {}) => {
  const { required, currentValue, errorMessage, ...data } = formData;

  if (required && !currentValue && currentValue !== 0) {
    return {
      ...data,
      currentValue,
      required,
      errorMessage,
    };
  }

  return {
    ...data,
    currentValue,
    required,
  };
};

const getFormData = (fieldId, creditAppFieldMap) => {
  const formField = creditAppFieldMap[fieldId] || { pickerOptions: [] };

  const { inputType } = formField;

  switch (inputType) {
    case 'statePicker': {
      return validateFormData({
        ...formField.formData,
        statesList,
      });
    }

    case 'dynamic': {
      const updated = updateDynamicField({
        formField,
        updatedFields: Object.keys(creditAppFieldMap).map(
          (field) => creditAppFieldMap[field],
        ),
      });

      return validateFormData({
        ...updated,
        statesList,
      });
    }

    default: {
      return validateFormData(formField.formData);
    }
  }
};

const getFormulaValue = (formula) => {
  try {
    const val = math.eval(formula);
    return val;
  } catch (e) {
    return 0;
  }
};

const roundValue = ({ updatedFormData, value }) => {
  const { inputType } = updatedFormData;
  switch (inputType) {
    case 'formulaWhole':
    case 'formulaCurrencyWhole': {
      return Math.round(value);
    }
    case 'formula':
    case 'formulaCurrency': {
      return value.toFixed(2);
    }
    default:
      return value;
  }
};

const calculateValue = (updatedFormData, creditAppFieldMap, formData) => {
  const { inputType } = updatedFormData;
  const { cellIdToFeildIds } = creditAppFieldMap;
  switch (inputType) {
    case 'formulaWhole':
    case 'formulaCurrency':
    case 'formulaCurrencyWhole':
    case 'formula': {
      const regex = /\[\w+\]/g;
      const placeholders = updatedFormData.formula.match(regex) || [];

      const replacedFormula = placeholders.reduce(
        (formulaString, placeholder) => {
          const cellIdKey = placeholder.replace(/[\W_]+/g, '');
          const refId = cellIdToFeildIds[cellIdKey];
          const refCell = creditAppFieldMap[refId] || {};

          const { formData: refFormData = {} } = refCell;
          const { currentValue: refValue = 0 } = refFormData;

          return formulaString.replace(placeholder, refValue);
        },
        updatedFormData.formula,
      );
      const value = getFormulaValue(replacedFormula);

      const rounded = roundValue({ updatedFormData, value });

      return rounded;
    }
    default: {
      if (!isEqual(updatedFormData.values, formData.values)) {
        return updatedFormData.defaultValue;
      }
      return updatedFormData.currentValue;
    }
  }
};

const getDisplayType = (props) => {
  const { cellType, inputType } = props;

  switch (cellType) {
    case 'disclosure':
    case 'photos':
    case 'linkCell':
    case 'textParagraph':
    case 'switched': {
      return cellType;
    }

    default: {
      return inputType;
    }
  }
};
