import { validateAll as ValidateAll } from './Validator';

const validateTitle = (title) => {
  if (!title) {
    return 'Title must contain at least one character';
  }
  return '';
};

export const validateCustomOptionTitles = (values = []) =>
  values.map(({ title }) => ({
    title: validateTitle(title),
  }));

export const validateAll = (state) => {
  const errors = { ...ValidateAll(state.uploadSettings) };
  errors.title = validateTitle(state.title);
  return errors;
};
