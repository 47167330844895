import React, { useState, useEffect, useContext, useCallback } from 'react';
import { FileInput } from '@blueprintjs/core';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Parse from 'parse';
import _ from 'lodash';
import { Button } from 'react-bootstrap';
import { LayoutContext } from '../../Layout';
import {
  startFetchSharedResources,
  startSaveSharedResourceGroup,
  startAddResourceToGroup,
  startRemoveSharedResource,
  setSharedResourceGroup,
  setSharedResources,
} from '../../../actions/resources';
import FormGroup, { TextGroup, ButtonGroup } from '../../FormGroup';
import Panel from '../../Panel';
import SharedResourceCell from '../../Resources/SharedResources/SharedResourceCell';
import NewResourceModal from '../NewResourceModal';
import { showDeleteConfirmation } from '../../../actions/deleteConfirmation';

const sharedResourceGroupSelector = ({
  resources: { sharedResources, sharedResourceGroup },
}) => ({
  items: sharedResources,
  sharedResourceGroup,
});

const SharedResourcesEditPage = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { items, sharedResourceGroup } = useSelector(
    sharedResourceGroupSelector,
  );
  const { crumbs, setCrumbs, setButtons } = useContext(LayoutContext);
  const [state, updateState] = useState({
    groupData: {},
    showModal: false,
  });
  const setState = useCallback(
    (stateUpdate) => {
      const newState = { ...state, ...stateUpdate };
      updateState(newState);
    },
    [state, updateState],
  );
  const { groupData, thumbnailURL } = state;
  const title = id ? groupData.name : 'Add New Resource Group';
  const resourceItems = items || [];
  const previewUrl = thumbnailURL || '/images/no_image.png';

  useEffect(() => {
    if (id && dispatch) {
      dispatch(startFetchSharedResources(id));
    }
    return () => {
      dispatch(setSharedResourceGroup());
      dispatch(setSharedResources());
    };
  }, [id, dispatch]);

  useEffect(() => {
    if (
      sharedResourceGroup !== undefined &&
      state.groupData.name === undefined &&
      setState
    ) {
      setState({
        groupData: {
          name: sharedResourceGroup.get('name'),
          thumbnail: sharedResourceGroup.get('thumbnail'),
        },
        thumbnailURL: sharedResourceGroup.has('thumbnail')
          ? sharedResourceGroup.get('thumbnail').url()
          : '/images/no_image.png',
      });
    }
  }, [sharedResourceGroup, setState, state]);

  useEffect(() => {
    if (title && crumbs && crumbs.length && setCrumbs && setButtons) {
      const crumb = {
        title,
        link: `/shared_resources/${id ? `edit/${id}` : 'add'}`,
      };
      const newCrumbs = [...crumbs];
      newCrumbs[1] = crumb;
      if (!_.isEqual(crumbs[1], newCrumbs[1])) {
        setCrumbs(newCrumbs);
      }
    }
  }, [id, title, crumbs, setCrumbs, setButtons, setState]);

  useEffect(() => {
    setButtons(
      <Button variant="success" onClick={() => setState({ showModal: true })}>
        Add New Resource
      </Button>,
    );
  }, [setButtons, setState]);

  const onDeleteResourceClick = (sharedResource) => {
    dispatch(
      showDeleteConfirmation({
        title: 'Delete Shared Resource ?',
        message: `Are you sure you want to delete ${sharedResource.name}`,
        onConfirm: () => {
          onRemoveResource(sharedResource);
        },
      }),
    );
  };

  const onNameChange = (textValue) => {
    setState({
      groupData: {
        ...state.groupData,
        name: textValue,
      },
    });
  };

  const onNewResourceSubmit = async (dataEvent, file, thumbnail) => {
    setState({ showModal: false });
    const { buttons, displayName, fileType, url } = dataEvent;

    const resourceData = {
      buttons,
      name: displayName,
      fileType,
      thumbnail,
      url,
      file,
    };

    await dispatch(startAddResourceToGroup(sharedResourceGroup, resourceData));
    await dispatch(startFetchSharedResources(sharedResourceGroup.id));
  };

  const onRemoveResource = async (resource) => {
    await dispatch(startRemoveSharedResource(resource));
    await dispatch(startFetchSharedResources(sharedResourceGroup.id));
  };

  const onThumbnailChanged = (e) => {
    const file = e.target.files[0];
    const reader = new window.FileReader();
    reader.onload = (onLoad) => {
      const extension = file.name.split('.').pop();
      const name = `thumbnail.${extension}`;
      setState({
        groupData: {
          ...state.groupData,
          thumbnail: new Parse.File(name, file),
        },
        previewUrl: onLoad.target.result,
      });
    };
    reader.readAsDataURL(file);
  };

  const saveResourseGroup = async () => {
    const objAttributes = { ...groupData };
    if (sharedResourceGroup) {
      objAttributes.id = id;
    }
    return dispatch(startSaveSharedResourceGroup(objAttributes));
  };

  return (
    <div className="default-page-padding">
      <Panel title="Resource Group">
        <img
          alt="Thumbnail"
          src={state.previewUrl ? state.previewUrl : previewUrl}
          className="resources-thumbnail"
        />
        <FormGroup title="">
          <FileInput
            text="Upload Thumbnail"
            onChange={onThumbnailChanged}
            inputProps={{ accept: 'image/*' }}
            fill
            large
          />
        </FormGroup>
        <TextGroup
          value={groupData.name}
          title="Group Name"
          placeholder="Group name"
          onChange={onNameChange}
        />
        <ButtonGroup
          variant="success"
          title=""
          buttonTitle="Save"
          onClick={() => saveResourseGroup()}
        />
      </Panel>
      <Panel title="Shared Resources">
        <div className="resources-container">
          {resourceItems &&
            resourceItems.map((item) => (
              <SharedResourceCell
                objectId={item.objectId}
                thumbnailURL={
                  item.thumbnail ? item.thumbnail.url : '/images/no_image.png'
                }
                fileURL={item.file ? item.file.url : item.url}
                name={item.name}
                key={item.objectId}
                onDelete={() => onDeleteResourceClick(item)}
                onEdit={() => history.push(`${pathname}/${item.objectId}`)}
              />
            ))}
        </div>
      </Panel>
      <NewResourceModal
        onSubmit={onNewResourceSubmit}
        show={state.showModal}
        onClose={() => setState({ showModal: false })}
      />
    </div>
  );
};

export default SharedResourcesEditPage;
