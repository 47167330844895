import React from 'react';
import PropTypes from 'prop-types';

const Well = ({ children, bsSize, style }) => {
  const wellStyle = {
    boxShadow: 'inset 0 1px 1px rgba(0,0,0,.05)',
    backgroundColor: '#f5f5f5',
    border: '1px solid #e3e3e3',
    borderRadius: '4px',
    marginBottom: '20px',
    minHeight: '20px',
    padding: '19px',
  };

  const wellSizeStyle =
    bsSize === 'lg'
      ? {
          borderRadius: '6px',
          padding: '24px',
        }
      : {};

  return (
    <div
      style={{
        ...wellStyle,
        ...wellSizeStyle,
        ...style,
      }}
    >
      {children}
    </div>
  );
};

Well.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  bsSize: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};

Well.defaultProps = {
  bsSize: '',
  style: {},
};

export default Well;
