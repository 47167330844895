import { randomKey } from '../../../utils/utils';

const containerKey = 'image_object';

class ImageObject {
  constructor(object, parent) {
    this.JSON = object;
    this.keys = ['id', 'title', 'value', 'file'];
    this.parent = parent;
    this.containerKey = containerKey;
    if (object) {
      this.title = '';
      this.value = '';
      Object.assign(this, object);
      this.id = this.id || randomKey(10);
    } else {
      this.id = randomKey(10);
      this.title = '';
      this.value = '';
    }
  }

  toJSON() {
    const json = {};
    this.keys.forEach((key) => {
      json[key] = this[key];
    });
    return json;
  }

  clone() {
    const jsonObject = this.toJSON();
    const json = JSON.parse(JSON.stringify(jsonObject));
    json.id = randomKey(10);
    // you ave to set the file property back because the JSON.stringify gets rid it
    if (this.file) {
      this.file = { ...this.file };
    }
    return new ImageObject(json, this.parent);
  }

  getSourceObject() {
    if (!this.parent) {
      return undefined;
    }
    return this.parent.getSourceObject();
  }

  async convertNewFiles() {
    const { file } = this;
    if (file && file.isNew) {
      delete this.file.isNew;
      delete this.file._url;
      await this.file.save();
      return this.file;
    }
    return this.file;
  }

  get objectId() {
    return this.id;
  }

  set objectId(id) {
    this.id = id;
  }

  static ContainerKey = containerKey;
}

export default ImageObject;
