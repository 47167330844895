/* eslint-disable react/prop-types */
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import { jsx, css } from '@emotion/core';
import Select from '../Misc/Select';

const fonts = [
  'AcademyEngravedLetPlain',
  'AlNile',
  'AlNile-Bold',
  'AmericanTypewriter',
  'AmericanTypewriter-Bold',
  'AmericanTypewriter-Condensed',
  'AmericanTypewriter-CondensedBold',
  'AmericanTypewriter-CondensedLight',
  'AmericanTypewriter-Light',
  'AmericanTypewriter-Semibold',
  'AppleColorEmoji',
  'AppleSDGothicNeo-Bold',
  'AppleSDGothicNeo-Light',
  'AppleSDGothicNeo-Medium',
  'AppleSDGothicNeo-Regular',
  'AppleSDGothicNeo-SemiBold',
  'AppleSDGothicNeo-Thin',
  'AppleSDGothicNeo-UltraLight',
  'Arial-BoldItalicMT',
  'Arial-BoldMT',
  'Arial-ItalicMT',
  'ArialHebrew',
  'ArialHebrew-Bold',
  'ArialHebrew-Light',
  'ArialMT',
  'ArialRoundedMTBold',
  'Avenir-Black',
  'Avenir-BlackOblique',
  'Avenir-Book',
  'Avenir-BookOblique',
  'Avenir-Heavy',
  'Avenir-HeavyOblique',
  'Avenir-Light',
  'Avenir-LightOblique',
  'Avenir-Medium',
  'Avenir-MediumOblique',
  'Avenir-Oblique',
  'Avenir-Roman',
  'AvenirNext-Bold',
  'AvenirNext-BoldItalic',
  'AvenirNext-DemiBold',
  'AvenirNext-DemiBoldItalic',
  'AvenirNext-Heavy',
  'AvenirNext-HeavyItalic',
  'AvenirNext-Italic',
  'AvenirNext-Medium',
  'AvenirNext-MediumItalic',
  'AvenirNext-Regular',
  'AvenirNext-UltraLight',
  'AvenirNext-UltraLightItalic',
  'AvenirNextCondensed-Bold',
  'AvenirNextCondensed-BoldItalic',
  'AvenirNextCondensed-DemiBold',
  'AvenirNextCondensed-DemiBoldItalic',
  'AvenirNextCondensed-Heavy',
  'AvenirNextCondensed-HeavyItalic',
  'AvenirNextCondensed-Italic',
  'AvenirNextCondensed-Medium',
  'AvenirNextCondensed-MediumItalic',
  'AvenirNextCondensed-Regular',
  'AvenirNextCondensed-UltraLight',
  'AvenirNextCondensed-UltraLightItalic',
  'Baskerville',
  'Baskerville-Bold',
  'Baskerville-BoldItalic',
  'Baskerville-Italic',
  'Baskerville-SemiBold',
  'Baskerville-SemiBoldItalic',
  'BodoniOrnamentsITCTT',
  'BodoniSvtyTwoITCTT-Bold',
  'BodoniSvtyTwoITCTT-Book',
  'BodoniSvtyTwoITCTT-BookIta',
  'BodoniSvtyTwoOSITCTT-Bold',
  'BodoniSvtyTwoOSITCTT-Book',
  'BodoniSvtyTwoOSITCTT-BookIt',
  'BodoniSvtyTwoSCITCTT-Book',
  'BradleyHandITCTT-Bold',
  'ChalkboardSE-Bold',
  'ChalkboardSE-Light',
  'ChalkboardSE-Regular',
  'Chalkduster',
  'Cochin',
  'Cochin-Bold',
  'Cochin-BoldItalic',
  'Cochin-Italic',
  'Copperplate',
  'Copperplate-Bold',
  'Courier',
  'Courier-Bold',
  'Courier-BoldOblique',
  'Courier-Oblique',
  'CourierNewPS-BoldItalicMT',
  'CourierNewPS-BoldMT',
  'CourierNewPS-ItalicMT',
  'CourierNewPSMT',
  'Damascus',
  'DamascusBold',
  'DamascusLight',
  'DamascusMedium',
  'DamascusSemiBold',
  'DevanagariSangamMN',
  'DevanagariSangamMN-Bold',
  'Didot',
  'Didot-Bold',
  'Didot-Italic',
  'DiwanMishafi',
  'EuphemiaUCAS',
  'EuphemiaUCAS-Bold',
  'EuphemiaUCAS-Italic',
  'Farah',
  'Futura-Bold',
  'Futura-CondensedExtraBold',
  'Futura-CondensedMedium',
  'Futura-Medium',
  'Futura-MediumItalic',
  'GeezaPro',
  'GeezaPro-Bold',
  'Georgia',
  'Georgia-Bold',
  'Georgia-BoldItalic',
  'Georgia-Italic',
  'GillSans',
  'GillSans-Bold',
  'GillSans-BoldItalic',
  'GillSans-Italic',
  'GillSans-Light',
  'GillSans-LightItalic',
  'GillSans-SemiBold',
  'GillSans-SemiBoldItalic',
  'GillSans-UltraBold',
  'GujaratiSangamMN',
  'GujaratiSangamMN-Bold',
  'GurmukhiMN',
  'GurmukhiMN-Bold',
  'Helvetica',
  'Helvetica-Bold',
  'Helvetica-BoldOblique',
  'Helvetica-Condensed',
  'Helvetica-Condensed-Bold',
  'Helvetica-Condensed-Light',
  'Helvetica-Light',
  'Helvetica-LightOblique',
  'Helvetica-Oblique',
  'HelveticaNeue',
  'HelveticaNeue-Bold',
  'HelveticaNeue-BoldItalic',
  'HelveticaNeue-CondensedBlack',
  'HelveticaNeue-CondensedBold',
  'HelveticaNeue-Italic',
  'HelveticaNeue-Light',
  'HelveticaNeue-LightItalic',
  'HelveticaNeue-Medium',
  'HelveticaNeue-MediumItalic',
  'HelveticaNeue-Thin',
  'HelveticaNeue-ThinItalic',
  'HelveticaNeue-UltraLight',
  'HelveticaNeue-UltraLightItalic',
  'HiraMaruProN-W4',
  'HiraMinProN-W3',
  'HiraMinProN-W6',
  'HiraginoSans-W3',
  'HiraginoSans-W6',
  'HoeflerText-Black',
  'HoeflerText-BlackItalic',
  'HoeflerText-Italic',
  'HoeflerText-Regular',
  'Kailasa',
  'Kailasa-Bold',
  'KannadaSangamMN',
  'KannadaSangamMN-Bold',
  'Kefa-Regular',
  'KhmerSangamMN',
  'KohinoorBangla-Light',
  'KohinoorBangla-Regular',
  'KohinoorBangla-Semibold',
  'KohinoorDevanagari-Light',
  'KohinoorDevanagari-Regular',
  'KohinoorDevanagari-Semibold',
  'KohinoorTelugu-Light',
  'KohinoorTelugu-Medium',
  'KohinoorTelugu-Regular',
  'LaoSangamMN',
  'LucidaSans-Demi',
  'LucidaSans-DemiItalic',
  'LucidaSans-Italic',
  'LucidaSansUnicode',
  'MalayalamSangamMN',
  'MalayalamSangamMN-Bold',
  'MarkerFelt-Thin',
  'MarkerFelt-Wide',
  'Menlo-Bold',
  'Menlo-BoldItalic',
  'Menlo-Italic',
  'Menlo-Regular',
  'MyanmarSangamMN',
  'MyanmarSangamMN-Bold',
  'Noteworthy-Bold',
  'Noteworthy-Light',
  'NotoNastaliqUrdu',
  'Optima-Bold',
  'Optima-BoldItalic',
  'Optima-ExtraBlack',
  'Optima-Italic',
  'Optima-Regular',
  'OriyaSangamMN',
  'OriyaSangamMN-Bold',
  'Palatino-Bold',
  'Palatino-BoldItalic',
  'Palatino-Italic',
  'Palatino-Roman',
  'Papyrus',
  'Papyrus-Condensed',
  'PartyLetPlain',
  'PingFangHK-Light',
  'PingFangHK-Medium',
  'PingFangHK-Regular',
  'PingFangHK-Semibold',
  'PingFangHK-Thin',
  'PingFangHK-Ultralight',
  'PingFangSC-Light',
  'PingFangSC-Medium',
  'PingFangSC-Regular',
  'PingFangSC-Semibold',
  'PingFangSC-Thin',
  'PingFangSC-Ultralight',
  'PingFangTC-Light',
  'PingFangTC-Medium',
  'PingFangTC-Regular',
  'PingFangTC-Semibold',
  'PingFangTC-Thin',
  'PingFangTC-Ultralight',
  'SavoyeLetPlain',
  'SinhalaSangamMN',
  'SinhalaSangamMN-Bold',
  'SnellRoundhand',
  'SnellRoundhand-Black',
  'SnellRoundhand-Bold',
  'Symbol',
  'TamilSangamMN',
  'TamilSangamMN-Bold',
  'Thonburi',
  'Thonburi-Bold',
  'Thonburi-Light',
  'TimesNewRomanPS-BoldItalicMT',
  'TimesNewRomanPS-BoldMT',
  'TimesNewRomanPS-ItalicMT',
  'TimesNewRomanPSMT',
  'Trebuchet-BoldItalic',
  'TrebuchetMS',
  'TrebuchetMS-Bold',
  'TrebuchetMS-Italic',
  'Verdana',
  'Verdana-Bold',
  'Verdana-BoldItalic',
  'Verdana-Italic',
  'ZapfDingbatsITC',
  'Zapfino',
];

export const fontFamilyExists = (font) => fonts.indexOf(font) > -1;

const defaultOption = { label: 'Default', value: '' };
const options = [
  defaultOption,
  ...fonts.map((value) => ({ label: value, value })),
];

const FontSelect = ({ value: fontValue, onChange, style, ...rest }) => (
  <div
    style={style}
    css={css`
      ${options.map(
        (option, index) =>
          `#react-select-4-option-${index} {
          font-family: "${option.value}" !important;
          width: 100%;
        }`,
      )}
    `}
  >
    <Select
      onChange={({ value }) => onChange(value)}
      value={options.find(({ value }) => value === fontValue) || defaultOption}
      options={options}
      clearable={false}
      closeMenuOnSelect={false}
      {...rest}
    />
  </div>
);

FontSelect.propTypes = {
  value: PropTypes.oneOf(['', ...fonts]),
  onChange: PropTypes.func.isRequired,
};

FontSelect.defaultProps = {
  value: '',
};

export default FontSelect;
