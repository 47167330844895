/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import useDecryptedCreditApp from '../../hooks/decryptedCreditApp';
import DecryptedSection from './DecryptedSection';
import CompletedCreditAppModal from './CompletedCreditAppModal';
import { LayoutContext } from '../Layout';
import { HistoryLink } from '../SharedComponents/HistoryLink';

import './styles.scss';

const CompletedCreditApp = () => {
  const { id: customerCreditAppId } = useParams();

  const {
    creditAppData = {},
    showAuth,
    onCancel,
    hideModal,
    initDecryptedCreditApp,
    clearDecryptedData,
    getDecryptedCreditAppValues,
  } = useDecryptedCreditApp();

  const {
    companyLogo = '/images/leap_logo.png',
    companyName = '',
    customerAccount = '',
    salesPerson = '',
    date = '',
    decryptedSections = [],
    customerId,
  } = creditAppData;

  useEffect(() => {
    if (customerCreditAppId) {
      initDecryptedCreditApp({ customerCreditAppId });
    }

    return () => {
      clearDecryptedData();
    };
  }, [customerCreditAppId]);

  const { setCrumbs, setButtons } = useContext(LayoutContext);

  useEffect(() => {
    setCrumbs([
      { title: 'Estimates', link: '/estimates' },
      {
        title: 'Estimate Break Down',
        link: `/estimate_details/${customerId}`,
      },
      { title: 'Completed Credit App' },
    ]);

    const relatedIds = `CustomerCreditApp$${customerCreditAppId}`;

    const logLink = `/event-log?actions=Viewed&categories=Encrypted Credit App&ids=${relatedIds}`;
    setButtons(
      <HistoryLink className="btn btn-primary" href={logLink}>
        View Access Logs
      </HistoryLink>,
    );

    return () => {
      setCrumbs();
      setButtons('');
    };
  }, [creditAppData.customerId]);

  return (
    <Container className="completed-credit-app-container">
      <div className="completed-app-header">
        <Row>
          <Col>
            <img
              alt="company logo"
              className="completed-credit-app-logo"
              src={companyLogo}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <h1 className="m-0">{companyName}</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>Customer Account:</h2>
            <h2>{customerAccount}</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>Sales Person:</h2>
            <h2>{salesPerson}</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>Date:</h2>
            <h2>{moment(date).format('MM/DD/YYYY')}</h2>
          </Col>
        </Row>
      </div>
      {decryptedSections.map((sectionData) => {
        return <DecryptedSection sectionData={sectionData} />;
      })}
      <CompletedCreditAppModal
        show={showAuth}
        onHide={hideModal}
        onCancel={onCancel}
        customerCreditAppId={customerCreditAppId}
        customerAccount={customerAccount}
        getDecryptedCreditAppValues={getDecryptedCreditAppValues}
      />
    </Container>
  );
};

export default CompletedCreditApp;
