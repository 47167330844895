export default (state = { logs: [], lastChangeLog: null }, action) => {
  switch (action.type) {
    case 'SET_LOGS':
      return {
        ...state,
        logs: action.logs,
      };
    case 'SET_LAST_CHANGE_LOG':
      return {
        ...state,
        lastChangeLog: action.lastChangeLog,
      };
    default:
      return state;
  }
};
