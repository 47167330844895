import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Classes, Menu, MenuItem, TextArea } from 'leap-menu-item';
import { setInitialStateForTemplate } from '../../../actions/templateEdit';
import ContractObject from '../Models/ContractObject';
import SignatureItemObject from '../Models/SignatureItem';
import YesNoMenuItem from './YesNoMenuItem';
import DebounceInputWithNet from './DebounceInputWithNet';

const SignatureCell = ({ templateValues, editCellItem, setTemplateState }) => {
  const contractObject = new ContractObject(templateValues.toJSON());
  const updatedObject =
    contractObject.getObjectWithId(editCellItem.objectId) || {};
  return (
    <Menu className={Classes.ELEVATION_1}>
      <MenuItem text="App Note">
        <DebounceInputWithNet
          element={TextArea}
          minLength={0}
          debounceTimeout={300}
          value={updatedObject.appNote}
          onChange={(e) => {
            updatedObject.appNote = e.target.value;
            setTemplateState(contractObject);
          }}
        />
      </MenuItem>
      <MenuItem text="Contract Note">
        <DebounceInputWithNet
          element={TextArea}
          minLength={0}
          debounceTimeout={300}
          value={updatedObject.contractNote}
          onChange={(e) => {
            updatedObject.contractNote = e.target.value;
            setTemplateState(contractObject);
          }}
        />
      </MenuItem>
      <MenuItem text="Footer Note">
        <DebounceInputWithNet
          element={TextArea}
          minLength={0}
          debounceTimeout={300}
          value={updatedObject.footerNote}
          onChange={(e) => {
            updatedObject.footerNote = e.target.value;
            setTemplateState(contractObject);
          }}
        />
      </MenuItem>
      <Menu.Divider />
      <YesNoMenuItem
        text="Underline"
        active={updatedObject.underline}
        onChange={(value) => {
          updatedObject.underline = value;
          setTemplateState(contractObject);
        }}
      />
      <MenuItem
        text="Column"
        shouldDismissPopover={false}
        label={updatedObject.column === 0 ? 'Left' : 'Right'}
      >
        <MenuItem
          text="Left"
          shouldDismissPopover={false}
          active={updatedObject.column === 0}
          icon={updatedObject.column === 0 ? 'dot' : 'blank'}
          onClick={() => {
            updatedObject.column = 0;
            setTemplateState(contractObject);
          }}
        />
        <MenuItem
          text="Right"
          shouldDismissPopover={false}
          active={updatedObject.column === 1}
          icon={updatedObject.column === 1 ? 'dot' : 'blank'}
          onClick={() => {
            updatedObject.column = 1;
            setTemplateState(contractObject);
          }}
        />
      </MenuItem>
    </Menu>
  );
};

SignatureCell.propTypes = {
  templateValues: PropTypes.instanceOf(ContractObject).isRequired,
  editCellItem: PropTypes.instanceOf(SignatureItemObject).isRequired,
  setTemplateState: PropTypes.func.isRequired,
};

const mapStateToProps = ({ templatesEdit = {} }) => ({
  templateValues: templatesEdit[templatesEdit.currentWorkingId],
  editCellItem: templatesEdit.editCellItem,
});

const mapDispatchToProps = (dispatch) => ({
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignatureCell);
