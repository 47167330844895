/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { FileInput } from '@blueprintjs/core';
import FormGroup, { TextGroup, SwitchGroup } from '../../FormGroup';
import UpChargeObject from '../../../Models/UpCharge';
import { newFileFromEvent } from '../../../utils/utils';

export default class UpChargeEditModal extends React.Component {
  constructor(props) {
    super(props);
    const { upCharge } = props;
    if (upCharge) {
      this.state = upCharge.toJSON();
      this.state.image = upCharge.get('image');
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { upCharge } = newProps;
    if (upCharge) {
      const json = upCharge.toJSON();
      json.image = upCharge.get('image');
      this.setState(json);
    }
  }

  onValueChanged = (key, value) => {
    this.setState({ [key]: value });
  };

  onFileChanged = async (e) => {
    const file = await newFileFromEvent(e);
    this.setState({ image: file });
  };

  onSave = () => {
    const {
      image,
      name,
      info,
      measurementType,
      identifier,
      shouldShowSwitch,
      percentagePrice,
    } = this.state;
    this.props.upCharge.set('image', image);
    this.props.upCharge.set('name', name);
    this.props.upCharge.set('info', info);
    this.props.upCharge.set('measurementType', measurementType);
    this.props.upCharge.set('identifier', identifier);
    this.props.upCharge.set('shouldShowSwitch', shouldShowSwitch);
    this.props.upCharge.set('percentagePrice', percentagePrice);
    this.props.onSave(this.props.upCharge);
  };

  render() {
    if (!this.props.upCharge) {
      return null;
    }
    const imageURL = this.state.image
      ? this.state.image.url()
      : '/images/no_image.png';
    return (
      <Modal
        show={!!this.props.upCharge}
        onHide={this.props.onClose}
        dialogClassName="price-guide__edit-msi-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Up-Charge</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            className="packages-thumbnail-img"
            alt="Thumbnail"
            src={imageURL}
          />
          <FormGroup title="Thumbnail">
            <div className="packages-thumbnail ">
              <FileInput
                text="Choose file..."
                onChange={this.onFileChanged}
                inputProps={{ accept: 'image/*' }}
                fill
                large
              />
            </div>
          </FormGroup>
          <TextGroup
            title="Identifier"
            value={this.state.identifier}
            onChange={(value) => this.onValueChanged('identifier', value)}
            errorMessage=""
          />
          <TextGroup
            title="Name"
            value={this.state.name}
            onChange={(value) => this.onValueChanged('name', value)}
            errorMessage=""
          />
          <TextGroup
            title="Note"
            value={this.state.info}
            onChange={(value) => this.onValueChanged('info', value)}
            errorMessage=""
          />
          <TextGroup
            title="Measurement Type"
            value={this.state.measurementType}
            onChange={(value) => this.onValueChanged('measurementType', value)}
            errorMessage=""
          />
          <SwitchGroup
            title="Show Toggle"
            checked={this.state.shouldShowSwitch === 1}
            onChange={(value) =>
              this.onValueChanged('shouldShowSwitch', value ? 1 : 0)
            }
          />
          <SwitchGroup
            title="Calculate as % of Parent"
            checked={this.state.percentagePrice}
            onChange={(value) => this.onValueChanged('percentagePrice', value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onClose}>Close</Button>
          <Button onClick={this.onSave} variant="danger">
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

UpChargeEditModal.propTypes = {
  upCharge: PropTypes.instanceOf(UpChargeObject),
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

UpChargeEditModal.defaultProps = {
  upCharge: undefined,
  onClose: undefined,
  onSave: undefined,
};
