/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import { FieldDisplayList } from '../SharedComponents/FieldDisplayList';
import { SectionTitle } from '../SharedComponents/SectionTitle';

const AppointmentSection = (props) => {
  const { analyticsData } = props;

  let startTime = '';

  if (analyticsData.startTime) {
    startTime = new Date(analyticsData.startTime).toLocaleString();
  }

  return (
    <article className="appointment-meta-section leap-data-section">
      <SectionTitle {...{ title: 'Appointment' }} />
      <div className="field-section my-1">
        <div className="field-section-row my-2 row">
          <FieldDisplayList
            {...{
              colClass: 'col-md-4',
              title: 'Appointment Time',
              list: [
                {
                  label: 'Time:',
                  value: startTime,
                },
                {
                  label: 'Duration:',
                  value: moment
                    .duration(analyticsData.apptDuration / 1000, 'hours')
                    .humanize(),
                },
                {
                  label: 'On Time:',
                  value: `${moment
                    .duration(Math.abs(analyticsData.onTime) / 1000, 'hours')
                    .humanize()}  ${
                    analyticsData.onTime < 0 ? 'early' : 'late'
                  }`,
                },
              ],
            }}
          />
        </div>
      </div>
    </article>
  );
};

export default AppointmentSection;
