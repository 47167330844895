/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';

import Panel from '../../Panel';
import FormGroup, { NumberGroup } from '../../FormGroup';
import PlaceholderInputRow from '../PriceFormulas/FormulaTestSuite/PlaceholderInputRow';

class CommissionTierTestSuite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      placeholders: props.placeholders,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({ placeholders: newProps.placeholders });
  }

  updateTestValue = (value, updated) => {
    const placeholders = this.state.placeholders.map((placeholder) => {
      if (placeholder.symbol === updated.symbol) {
        return {
          ...placeholder,
          value,
        };
      }
      return placeholder;
    });

    this.props.onPlaceHolderUpdate(placeholders);
    this.setState({ placeholders });
  };

  render() {
    return (
      <Panel title="Test Suite">
        <FormGroup title="">
          <PlaceholderInputRow
            placeholders={this.state.placeholders}
            updateTestValue={this.updateTestValue}
          />
        </FormGroup>
        <NumberGroup
          value={this.props.formulaResult.greaterThanResult}
          title="Greater Than Result"
          onChange={(e) => e}
        />
        <NumberGroup
          value={this.props.formulaResult.commissionResult}
          title="Commission Formula Result"
          onChange={(e) => e}
        />
      </Panel>
    );
  }
}

CommissionTierTestSuite.propTypes = {
  placeholders: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      symbol: PropTypes.string,
    }),
  ).isRequired,
  formulaResult: PropTypes.shape({
    greaterThanResult: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
    ]),
    commissionResult: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
    ]),
  }).isRequired,
  onPlaceHolderUpdate: PropTypes.func.isRequired,
};

CommissionTierTestSuite.defaultProps = {};

export default CommissionTierTestSuite;
