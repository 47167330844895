export default (
  state = {
    items: [],
    applications: {},
    partners: {
      items: [],
    },
  },
  action,
) => {
  switch (action.type) {
    case 'SET_CREDIT_APPLICATIONS':
      return {
        ...state,
        items: action.items,
      };
    case 'SET_CREDIT_APPLICATION':
      return {
        ...state,
        applications: {
          ...state.applications,
          [action.object.id]: action.object,
        },
      };
    case 'SET_PARTNER_CREDIT_APPLICATIONS':
      return {
        ...state,
        partners: {
          items: action.items,
        },
      };
    case 'CREDIT_APPLICATIONS_SET_EDIT_CELL_ITEM':
      return {
        ...state,
        editCellItem: action.editCellItem,
      };
    case 'CREDIT_APPLICATIONS_SET_COMMIT':
      return {
        ...state,
        commit: action.commit,
      };
    default:
      return state;
  }
};
