/* eslint-disable react/prop-types */
import React from 'react';
// import PropTypes from 'prop-types';
import moment from 'moment';

const appStoreURL =
  'https://itunes.apple.com/us/app/leap-crush-inefficiency/id1146698852?mt=8';

const LeapTemplate = ({ children }) => (
  <div className="new-account__container">
    <div className="new-account__body-container">
      <div className="new-account__header-container">
        <div className="new-account__hero-img-container">
          <img src="/images/new_account_hero.png" alt="new account" />
        </div>
        <div className="new-account__hero-text">
          <span>
            Beautiful Proposals, Accurate Estimates, Happy Customers.
            <br />
            <div style={{ fontWeight: 'bold' }}>Higher Profits.</div>
          </span>
        </div>
        <div className="new-account__leap-header-logo">
          <img src="/images/leap_logo_white.png" alt="leap logo" />
        </div>
      </div>
      {children}
      <div className="new-account__footer-conatiner">
        <div className="new-account__download-app-store-container">
          <a href={appStoreURL} target="__blank">
            <img src="/images/app_store.png" alt="app store" />
          </a>
          <div style={{ maxWidth: 802 }}>
            <span style={{ paddingLeft: 20 }}>
              You can download the Leap app on your Apple devices{' '}
              <a href={appStoreURL} target="__blank">
                here
              </a>
              , or simply search for the &quot;Leap&quot; app in the iTunes app
              store.
            </span>
          </div>
        </div>
      </div>
    </div>
    <p className="new-account__copyright">
      © {moment().format('YYYY')} Leap LLC. All Rights Reserved.
    </p>
  </div>
);

export default LeapTemplate;
