/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Parse } from 'parse';
import { FileInput } from '@blueprintjs/core';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import { startUpdateConfig } from '../../actions/auth';
import FormGroup, { SliderGroup } from '../FormGroup';
import Panel from '../Panel';
import TitleButton from '../TitleButton';
import Copy from './Copy';

let newFile;

export class Watermark extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: props.url || '',
      alpha: props.alpha,
      width: props.width,
    };
  }

  componentDidMount() {
    const {
      setCrumbs,
      setButtons,
      location: { pathname },
    } = this.props;

    const crumb = { title: 'Watermark', link: pathname };
    setCrumbs([crumb]);
    setButtons(
      <TitleButtons
        onCopyClicked={this.onCopyClicked}
        onSaveClicked={this.onSaveClicked}
      />,
    );
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      url: newProps.url || '',
      alpha: newProps.alpha,
      width: newProps.width,
    });
  }

  componentWillUnmount() {
    const { setCrumbs, setButtons } = this.props;

    setCrumbs([]);
    setButtons();
  }

  onFileChanged = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (onload) => {
      this.setState({ url: onload.target.result });
    };
    reader.readAsDataURL(file);

    const extension = file.type.split('/')[1];
    const name = `watermark.${extension}`;
    newFile = new Parse.File(name, file);
  };

  onCopyClicked = () => {
    this.setState({ showCopy: true });
  };

  onSaveClicked = () => {
    const changes = {
      watermarkAlpha: this.state.alpha / 100,
      watermarkWidthPercent: this.state.width,
    };
    if (newFile) {
      changes.watermark = newFile;
    }
    this.props.startUpdateConfig(changes);
  };

  onAlphaChanged = (value) => {
    this.setState({ alpha: value });
  };

  onWidthChange = (value) => {
    this.setState({ width: value });
  };

  render() {
    return (
      <>
        <Copy
          title="Copy Watermark"
          show={this.state.showCopy}
          warning="Warning! This will overwrite your current Watermark settings"
          configKeys={['watermark', 'watermarkAlpha', 'watermarkWidthPercent']}
          onClose={() => this.setState({ showCopy: false })}
        />
        <div className="default-page-padding">
          <Panel title="Watermark">
            <div>
              <div className="watermark-image-container">
                <img
                  className="watermark-image"
                  alt="Watermark"
                  src={this.state.url}
                  style={{
                    opacity: this.state.alpha / 100,
                    width: `${this.state.width}%`,
                  }}
                />
              </div>
              <FormGroup title="File">
                <FileInput
                  text="Choose file..."
                  onChange={this.onFileChanged}
                  inputProps={{ accept: 'image/*' }}
                />
              </FormGroup>
              <SliderGroup
                title="Transparency"
                value={this.state.alpha}
                max={100}
                min={0}
                onChange={this.onAlphaChanged}
                labelStepSize={10}
              />
              <SliderGroup
                title="Width Relative to Page"
                value={this.state.width}
                max={100}
                min={0}
                onChange={this.onWidthChange}
                labelStepSize={10}
              />
            </div>
          </Panel>
        </div>
      </>
    );
  }
}

Watermark.propTypes = {
  url: PropTypes.string,
  alpha: PropTypes.number,
  width: PropTypes.number,
  startUpdateConfig: PropTypes.func.isRequired,
  ...layoutContextPropTypes,
};

Watermark.defaultProps = {
  url: '',
  alpha: 0.05,
  width: 100,
};

const TitleButtons = ({ onCopyClicked, onSaveClicked }) => (
  <>
    <TitleButton
      variant="primary"
      onClick={() => onCopyClicked({ source: 'copy' })}
      title="Copy"
    />
    <TitleButton
      variant="success"
      onClick={() => onSaveClicked({ source: 'save' })}
      title="Save"
    />
  </>
);

TitleButtons.propTypes = {
  onCopyClicked: PropTypes.func.isRequired,
  onSaveClicked: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth: { config = {} } }) => {
  const {
    watermark = {},
    watermarkAlpha = 0.05,
    watermarkWidthPercent = 100,
  } = config;
  return {
    url: watermark ? watermark.url : '',
    alpha: watermarkAlpha * 100,
    width: watermarkWidthPercent,
  };
};

const mapDispatchToProps = (dispatch) => ({
  startUpdateConfig: (updates) => dispatch(startUpdateConfig(updates)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(Watermark),
);
