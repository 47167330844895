/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import Parse from 'parse';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import isURL from 'validator/lib/isURL';
import { withUserContext } from '../UserContext';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import Select from '../Misc/Select';
import { startUpdateConfig, startSetupFori360 } from '../../actions/auth';
import FormGroup, {
  TextGroup,
  DropDownGroup,
  TextAreaGroup,
  SwitchGroup,
} from '../FormGroup';
import { randomKey, reorder } from '../../utils/utils';
import Panel from '../Panel';
import TitleButton from '../TitleButton';
import { checkForErrors } from './ContractSending/Validation/Validator';
import { showErrorAlert, hideAlert } from '../../actions/alert';
import Copy from './Copy';
import Switch from '../Misc/Switch';
import AppToaster from '../../utils/AppToaster';
import { setOnDragEndCallback } from '../DropContext';

const endpointOptions = [
  {
    label: '-None-',
    value: '',
  },
  {
    label: 'Marketsharp',
    value: 'Marketsharp',
  },
  {
    label: 'LeadPerfection',
    value: 'LeadPerfection',
  },
  {
    label: 'Salesforce',
    value: 'Salesforce',
  },
  {
    label: 'JobNimbus',
    value: 'JobNimbus',
  },
  {
    label: 'Calendar',
    value: 'Calendar',
  },
  {
    label: 'Webhook',
    value: 'Webhook',
    planKey: 'webhooksEnabled',
  },
  {
    label: 'JobProgress',
    value: 'JobProgress',
  },
];

const typeOptions = [
  {
    label: 'Text | Picklist',
    value: 'string',
  },
  {
    label: 'Number',
    value: 'number',
  },
  {
    label: 'Appointment Date/Time',
    value: 'appDate',
  },
  {
    label: 'Date',
    value: 'date',
  },
  {
    label: 'Phone',
    value: 'phone',
  },
];

const appKeyOptions = [
  {
    label: '-None-',
    value: '',
  },
  {
    label: 'First and Last Name',
    value: 'name',
  },
  {
    label: 'First Name',
    value: 'nameFirst',
  },
  {
    label: 'Last Name',
    value: 'nameLast',
  },
  {
    label: 'Spouse First and Last Name',
    value: 'nameSpouse',
  },
  {
    label: 'Spouse First Name',
    value: 'nameFirstSpouse',
  },
  {
    label: 'Spouse Last Name',
    value: 'nameLastSpouse',
  },
  {
    label: 'Full Address',
    value: 'address',
  },
  {
    label: 'Street',
    value: 'addressStreet',
  },
  {
    label: 'City',
    value: 'addressCity',
  },
  {
    label: 'State',
    value: 'addressState',
  },
  {
    label: 'ZIP Code',
    value: 'addressZip',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Phone',
    value: 'phone',
  },
  {
    label: 'Appointment Notes',
    value: 'notes',
  },
  {
    label: 'Search Button',
    value: 'searchEstimates',
  },
  {
    label: 'Salesforce Id',
    value: 'salesforceId',
  },
  { label: 'Placeholder', value: 'placeholder' },
];

const validateData = ({ endpoint, query, dataObjects, webhook }) => {
  const updatedErrors = {
    query: '',
    dataObjects: [],
    webhook: { url: '' },
  };
  if (endpoint === 'Webhook') {
    if (!webhook.url || !isURL(webhook.url)) {
      updatedErrors.webhook.url =
        "You must specify a valid URL beginning with 'http://' or 'https://'";
    }
  } else if (endpoint === 'Salesforce') {
    updatedErrors.query = query ? '' : 'Invalid Query';
    updatedErrors.appointment =
      'One field MUST be mapped to Appointment Date/Time';
    updatedErrors.dataObjects = dataObjects.map(
      ({ key, title, type, dateFormat, display, appKey, placeholder }) => {
        let titleError = '';
        if (display && !title) {
          titleError = 'Required';
        }
        const error = {
          title: titleError,
          key: key ? '' : 'Required',
          type: type ? '' : 'Required',
          dateFormat: '',
        };
        if ((type === 'date' || type === 'appDate') && !dateFormat) {
          error.dateFormat = 'Required';
        }
        if (appKey === 'searchEstimates') {
          error.key = '';
          error.type = '';
          error.dateFormat = '';
        }
        if (type === 'appDate') {
          updatedErrors.appointment = '';
        }
        if (appKey === 'placeholder' && !placeholder) {
          error.placeholder = 'Required';
        }
        return error;
      },
    );
  }
  return updatedErrors;
};

const appointmentsTableDropableSectionId = 'appointmentsTableDropableSection';

export class Appointments extends React.Component {
  constructor(props) {
    super(props);
    const newState = {
      endpoint: props.appointmentsConfig.endpoint || '',
      webhook: props.appointmentsConfig.webhook || {},
      query: props.query,
      dataObjects: props.dataObjects.map((object) => ({
        ...object,
        objectId: randomKey(10),
      })),
      showi360Alert: false,
      jobNimbus: props.appointmentsConfig.jobNimbus || { recordTypes: [] },
      jobNimbusActivityTypes: [],
      marketsharp: props.appointmentsConfig.marketsharp || {},
    };
    newState.errors = validateData(newState);
    this.state = newState;

    setOnDragEndCallback(appointmentsTableDropableSectionId, (result) => {
      this.onReorder(result);
    });
  }

  componentDidMount() {
    const {
      setCrumbs,
      setButtons,
      location: { pathname },
      loggedIn,
    } = this.props;

    const crumb = { title: 'Appointments', link: pathname };
    setCrumbs([crumb]);
    setButtons(
      <TitleButtons
        onCopyClicked={this.onCopyClicked}
        onSaveClicked={this.onSaveClicked}
      />,
    );

    if (loggedIn) {
      this.fetchJobNimbusActivityTypes();
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const newState = {
      endpoint: newProps.appointmentsConfig.endpoint || '',
      webhook: newProps.appointmentsConfig.webhook || {},
      query: newProps.query,
      dataObjects: newProps.dataObjects.map((object) => ({
        ...object,
        objectId: randomKey(10),
      })),
      jobNimbus: newProps.appointmentsConfig.jobNimbus || { recordTypes: [] },
      marketsharp: newProps.appointmentsConfig.marketsharp || {},
    };
    newState.errors = validateData(newState);
    this.setState(newState);
    this.fetchJobNimbusActivityTypes();
  }

  componentWillUnmount() {
    const { setCrumbs, setButtons } = this.props;
    setCrumbs([]);
    setButtons();
  }

  onReorder(result) {
    const { destination = {} } = result;
    if (destination === null) {
      return;
    }
    const { droppableId } = destination;
    if (droppableId !== appointmentsTableDropableSectionId) {
      return;
    }
    const dataObjects = reorder(
      this.state.dataObjects,
      result.source.index,
      result.destination.index,
    ).map((item, order) => ({ ...item, order }));
    this.setState({ dataObjects });
  }

  onEndpointChanged = (value) => {
    const newState = {
      ...this.state,
      endpoint: value.value,
    };
    newState.errors = validateData(newState);
    this.setState(newState);
    this.fetchJobNimbusActivityTypes();
  };

  onQueryChanged = (value) => {
    const newState = {
      ...this.state,
      query: value,
    };
    newState.errors = validateData(newState);
    this.setState(newState);
  };

  onDataObjectChanged = (key, value, index) => {
    const newState = { ...this.state };
    const objects = this.state.dataObjects.map((object, i) => {
      const updatedObject = { ...object };
      if (index === i) {
        updatedObject[key] = value;
      }
      return updatedObject;
    });
    newState.dataObjects = objects;
    newState.errors = validateData(newState);
    this.setState(newState);
  };

  onDelete = (index) => {
    const dataObjects = [...this.state.dataObjects];
    dataObjects.splice(index, 1);
    const newState = {
      ...this.state,
      dataObjects,
    };
    newState.errors = validateData(newState);
    this.setState(newState);
  };

  onWebHookURLChanged = (value) => {
    const webhook = { ...this.state.webhook };
    webhook.url = value;
    const newState = {
      ...this.state,
      webhook,
    };
    newState.errors = validateData(newState);
    this.setState(newState);
  };

  onWebHookAuthHeaderChanged = (value) => {
    const webhook = { ...this.state.webhook };
    webhook.authHeader = value;
    const newState = {
      ...this.state,
      webhook,
    };
    newState.errors = validateData(newState);
    this.setState(newState);
  };

  onCopyClicked = () => {
    this.setState({ showCopy: true });
  };

  onSaveClicked = () => {
    if (checkForErrors(this.state.errors)) {
      this.props.showErrorAlert({
        title: 'Error',
        message: 'Please fix all validation errors',
        onConfirm: this.props.hideAlert,
      });
      return;
    }
    const updates = {
      appointmentsConfig: {
        endpoint: this.state.endpoint,
        webhook:
          this.state.endpoint === 'Webhook' ? this.state.webhook : undefined,
        jobNimbus:
          this.state.endpoint === 'JobNimbus'
            ? this.state.jobNimbus
            : undefined,
        marketsharp:
          this.state.endpoint === 'Marketsharp' && this.state.marketsharp
            ? this.state.marketsharp
            : undefined,
      },
      salesforceQuery: this.state.query,
      salesforceDataObjects: this.state.dataObjects,
    };
    this.props.startUpdateConfig(updates);
  };

  onAddClicked = () => {
    const dataObjects = [...this.state.dataObjects];
    dataObjects.unshift({
      key: '',
      title: '',
      type: '',
      display: '',
      appKey: '',
      dateFormat: '',
      placeholder: '',
      objectId: randomKey(10),
    });
    const newState = {
      ...this.state,
      dataObjects,
    };
    newState.errors = validateData(newState);
    this.setState(newState);
  };

  oni360SetupConfirm = () => {
    this.props.startSetupFori360(() => {
      this.showi360ConfirmAlert(false);
    });
  };

  onJobNimbusChanged = (results) => {
    this.setState({ jobNimbus: results });
  };

  showi360ConfirmAlert = (show) => {
    this.setState({ showi360Alert: show });
  };

  onMarketsharpChanged = (key, value) => {
    this.setState({
      marketsharp: {
        ...this.state.marketsharp,
        [key]: value,
      },
    });
  };

  fetchJobNimbusActivityTypes = async () => {
    if (this.state.endpoint === 'JobNimbus') {
      try {
        const params = { officeId: this.props.selectedOffice.id };
        const results = await Parse.Cloud.run(
          'jobNimbusGetActivityTypes',
          params,
        );
        const jobNimbusActivityTypes = results.map(
          ({ DefaultName, TypeName }) => ({
            value: TypeName,
            label: DefaultName,
          }),
        );
        this.setState({ jobNimbusActivityTypes });
      } catch (e) {
        AppToaster.show({ message: e.message, timeout: 5000 });
      }
    }
  };

  render() {
    const jobNimbusSelectedRecordTypes = this.state.jobNimbus.recordTypes.map(
      (recordType) =>
        this.state.jobNimbusActivityTypes.find(
          ({ value }) => value === recordType,
        ),
    );
    const {
      directionsDisabled,
      appointmentNotesDisabled,
      productInterestsDisabled,
      inquiryNotesDisabled,
      inquiryDateDisabled,
      primaryLeadSourceDisabled,
      secondaryLeadSourceDisabled,
      canvasserDisabled,
      telemarketerDisabled,
      promoterDisabled,
      yearHomeBuiltDisabled,
      phoneNumbersDisabled,
      emailsDisabled,
    } = this.state.marketsharp || {};
    return (
      <>
        <Copy
          title="Copy Appointment Settings"
          show={this.state.showCopy}
          warning="Warning! This will overwrite your current Appointment Settings"
          configKeys={[
            'appointmentsConfig',
            'salesforceDataObjects',
            'salesforceQuery',
          ]}
          onClose={() => this.setState({ showCopy: false })}
        />
        {!!this.state.showi360Alert && (
          <SweetAlert
            warning
            showCancel
            title="Setup for i360?"
            confirmBtnText="Yes"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            onConfirm={this.oni360SetupConfirm}
            onCancel={() => this.showi360ConfirmAlert(false)}
          >
            <div className="danger">
              <p>This will overwrite your current Appointment settings!</p>
              <p>THIS ACTION CANNOT BE UNDONE</p>
              <p>Are you sure you want to make this change?</p>
            </div>
          </SweetAlert>
        )}
        <div className="default-page-padding">
          <Panel title="Appointments Settings">
            <div>
              <DropDownGroup
                title="Source"
                value={
                  this.props.endpointOptions.filter(
                    (option) => option.value === this.state.endpoint,
                  )[0]
                }
                onChange={this.onEndpointChanged}
                closeMenuOnSelect
                options={this.props.endpointOptions}
                isClearable={false}
              />
            </div>
          </Panel>
          {this.state.endpoint === 'Webhook' && (
            <Panel title="Webhook Settings">
              <div>
                <TextGroup
                  title="Webhook URL"
                  value={this.state.webhook.url}
                  placeholder="Webhook URL"
                  onChange={this.onWebHookURLChanged}
                  errorMessage={this.state.errors.webhook.url}
                />
                <TextGroup
                  title="Auth Header"
                  value={this.state.webhook.authHeader}
                  placeholder="Auth Header"
                  onChange={this.onWebHookAuthHeaderChanged}
                />
              </div>
            </Panel>
          )}
          {this.state.endpoint === 'JobNimbus' && (
            <Panel title="JobNimbus Settings">
              <div>
                <FormGroup title="Task Types">
                  <Select
                    name="form-field-name"
                    value={jobNimbusSelectedRecordTypes}
                    onChange={(values) =>
                      this.onJobNimbusChanged({
                        ...this.state.jobNimbus,
                        recordTypes: values.map(({ value }) => value),
                      })
                    }
                    isMulti
                    closeMenuOnSelect={false}
                    options={this.state.jobNimbusActivityTypes}
                  />
                </FormGroup>
              </div>
            </Panel>
          )}
          {this.state.endpoint === 'Salesforce' && (
            <Panel title="Salesforce Settings">
              <div>
                <TextAreaGroup
                  title="Salesforce Query"
                  value={this.state.query}
                  onChange={this.onQueryChanged}
                  errorMessage={this.state.errors.query}
                />
                <FormGroup title="">
                  <div className="appointments-i360-button-container">
                    <TitleButton
                      variant="primary"
                      onClick={() => this.showi360ConfirmAlert(true)}
                      title="Setup for i360"
                    />
                  </div>
                </FormGroup>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th style={{ width: '20%', textAlign: 'center' }}>
                        App Title
                      </th>
                      <th style={{ width: '20%', textAlign: 'center' }}>
                        Salesforce API Name
                      </th>
                      <th style={{ width: '20%', textAlign: 'center' }}>
                        Salesforce Data Type
                        <div className="error">
                          {this.state.errors.appointment}
                        </div>
                      </th>
                      <th style={{ width: '20%', textAlign: 'center' }}>
                        Date Format/Placeholder
                      </th>
                      <th style={{ width: '20%', textAlign: 'center' }}>
                        Map To
                      </th>
                      <th style={{ width: '35px', textAlign: 'center' }}>
                        Display
                      </th>
                      <th style={{ width: '35px', textAlign: 'center' }}>
                        <button type="button" onClick={this.onAddClicked}>
                          Add
                        </button>
                      </th>
                    </tr>
                  </thead>

                  <Droppable
                    droppableId={appointmentsTableDropableSectionId}
                    direction="vertical"
                    type={appointmentsTableDropableSectionId}
                  >
                    {(provided) => (
                      <tbody
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {this.state.dataObjects.map(
                          (
                            {
                              key,
                              title,
                              type,
                              display,
                              dateFormat,
                              appKey,
                              objectId,
                              placeholder,
                            },
                            index,
                          ) => (
                            <Draggable
                              key={objectId}
                              draggableId={objectId}
                              index={index}
                              type={appointmentsTableDropableSectionId}
                            >
                              {(draggableProvided) => (
                                <tr
                                  ref={draggableProvided.innerRef}
                                  {...draggableProvided.draggableProps}
                                  {...draggableProvided.dragHandleProps}
                                  key={objectId}
                                >
                                  <td>
                                    <input
                                      type="text"
                                      value={title}
                                      placeholder="Title"
                                      onChange={(e) =>
                                        this.onDataObjectChanged(
                                          'title',
                                          e.target.value,
                                          index,
                                        )
                                      }
                                      className={
                                        this.state.errors.dataObjects[index]
                                          .title
                                          ? 'error-border'
                                          : ''
                                      }
                                      style={{ width: '100%' }}
                                    />
                                    <div className="error">
                                      {
                                        this.state.errors.dataObjects[index]
                                          .title
                                      }
                                    </div>
                                  </td>
                                  <td>
                                    {appKey !== 'searchEstimates' && (
                                      <div>
                                        <input
                                          type="text"
                                          value={key}
                                          placeholder="API Name"
                                          onChange={(e) =>
                                            this.onDataObjectChanged(
                                              'key',
                                              e.target.value,
                                              index,
                                            )
                                          }
                                          className={
                                            this.state.errors.dataObjects[index]
                                              .key
                                              ? 'error-border'
                                              : ''
                                          }
                                          style={{ width: '100%' }}
                                        />
                                        <div className="error">
                                          {
                                            this.state.errors.dataObjects[index]
                                              .key
                                          }
                                        </div>
                                      </div>
                                    )}
                                  </td>
                                  <td>
                                    {appKey !== 'searchEstimates' && (
                                      <div>
                                        <div
                                          className={
                                            this.state.errors.dataObjects[index]
                                              .type
                                              ? 'error-border'
                                              : ''
                                          }
                                        >
                                          <Select
                                            onChange={(e) =>
                                              this.onDataObjectChanged(
                                                'type',
                                                e.value,
                                                index,
                                              )
                                            }
                                            value={
                                              typeOptions.filter(
                                                (object) =>
                                                  object.value === type,
                                              )[0]
                                            }
                                            options={typeOptions}
                                            isSearchable
                                            isClearable={false}
                                          />
                                        </div>
                                        <div className="error">
                                          {
                                            this.state.errors.dataObjects[index]
                                              .type
                                          }
                                        </div>
                                      </div>
                                    )}
                                  </td>
                                  <td>
                                    {(type === 'date' ||
                                      type === 'appDate') && (
                                      <div>
                                        <input
                                          type="text"
                                          value={dateFormat}
                                          placeholder="Date Format"
                                          onChange={(e) =>
                                            this.onDataObjectChanged(
                                              'dateFormat',
                                              e.target.value,
                                              index,
                                            )
                                          }
                                          className={
                                            this.state.errors.dataObjects[index]
                                              .dateFormat
                                              ? 'error-border'
                                              : ''
                                          }
                                          style={{ width: '100%' }}
                                        />
                                        <div className="error">
                                          {
                                            this.state.errors.dataObjects[index]
                                              .dateFormat
                                          }
                                        </div>
                                      </div>
                                    )}
                                    {appKey === 'placeholder' && (
                                      <div>
                                        <input
                                          type="text"
                                          value={placeholder}
                                          placeholder="Placeholder"
                                          onChange={(e) =>
                                            this.onDataObjectChanged(
                                              'placeholder',
                                              e.target.value,
                                              index,
                                            )
                                          }
                                          className={
                                            this.state.errors.dataObjects[index]
                                              .placeholder
                                              ? 'error-border'
                                              : ''
                                          }
                                          style={{ width: '100%' }}
                                        />
                                        <div className="error">
                                          {
                                            this.state.errors.dataObjects[index]
                                              .placeholder
                                          }
                                        </div>
                                      </div>
                                    )}
                                  </td>
                                  <td>
                                    <Select
                                      onChange={(e) =>
                                        this.onDataObjectChanged(
                                          'appKey',
                                          e.value,
                                          index,
                                        )
                                      }
                                      value={
                                        appKeyOptions.filter(
                                          (object) => object.value === appKey,
                                        )[0] || ''
                                      }
                                      options={appKeyOptions}
                                      isSearchable
                                      isClearable={false}
                                    />
                                  </td>
                                  <td>
                                    <Switch
                                      checked={display}
                                      onChange={() =>
                                        this.onDataObjectChanged(
                                          'display',
                                          !display,
                                          index,
                                        )
                                      }
                                      large
                                    />
                                  </td>
                                  <td>
                                    <button
                                      className="fill-cell danger"
                                      type="button"
                                      onClick={() => this.onDelete(index)}
                                    >
                                      <i className="far fa-trash-alt" />
                                    </button>
                                  </td>
                                  {draggableProvided.placeholder}
                                </tr>
                              )}
                            </Draggable>
                          ),
                        )}
                        {provided.placeholder}
                      </tbody>
                    )}
                  </Droppable>
                </Table>
              </div>
            </Panel>
          )}
          {this.state.endpoint === 'Marketsharp' && (
            <Panel title="Select fields to pull from Marketsharp">
              <>
                <SwitchGroup
                  title="Phone Numbers"
                  checked={!phoneNumbersDisabled}
                  onChange={(value) =>
                    this.onMarketsharpChanged('phoneNumbersDisabled', !value)
                  }
                />
                <SwitchGroup
                  title="Emails"
                  checked={!emailsDisabled}
                  onChange={(value) =>
                    this.onMarketsharpChanged('emailsDisabled', !value)
                  }
                />
                <SwitchGroup
                  title="Directions"
                  checked={!directionsDisabled}
                  onChange={(value) =>
                    this.onMarketsharpChanged('directionsDisabled', !value)
                  }
                />
                <SwitchGroup
                  title="Product Interests"
                  checked={!productInterestsDisabled}
                  onChange={(value) =>
                    this.onMarketsharpChanged(
                      'productInterestsDisabled',
                      !value,
                    )
                  }
                />
                <SwitchGroup
                  title="Inquiry Notes"
                  checked={!inquiryNotesDisabled}
                  onChange={(value) =>
                    this.onMarketsharpChanged('inquiryNotesDisabled', !value)
                  }
                />
                <SwitchGroup
                  title="Inquiry Date"
                  checked={!inquiryDateDisabled}
                  onChange={(value) =>
                    this.onMarketsharpChanged('inquiryDateDisabled', !value)
                  }
                />
                <SwitchGroup
                  title="Primary Lead Source"
                  checked={!primaryLeadSourceDisabled}
                  onChange={(value) =>
                    this.onMarketsharpChanged(
                      'primaryLeadSourceDisabled',
                      !value,
                    )
                  }
                />
                <SwitchGroup
                  title="Secondary Lead Source"
                  checked={!secondaryLeadSourceDisabled}
                  onChange={(value) =>
                    this.onMarketsharpChanged(
                      'secondaryLeadSourceDisabled',
                      !value,
                    )
                  }
                />
                <SwitchGroup
                  title="Canvasser"
                  checked={!canvasserDisabled}
                  onChange={(value) =>
                    this.onMarketsharpChanged('canvasserDisabled', !value)
                  }
                />
                <SwitchGroup
                  title="Telemarketer"
                  checked={!telemarketerDisabled}
                  onChange={(value) =>
                    this.onMarketsharpChanged('telemarketerDisabled', !value)
                  }
                />
                <SwitchGroup
                  title="Promoter"
                  checked={!promoterDisabled}
                  onChange={(value) =>
                    this.onMarketsharpChanged('promoterDisabled', !value)
                  }
                />
                <SwitchGroup
                  title="Year Home Built"
                  checked={!yearHomeBuiltDisabled}
                  onChange={(value) =>
                    this.onMarketsharpChanged('yearHomeBuiltDisabled', !value)
                  }
                />
                <SwitchGroup
                  title="Appointment Notes"
                  checked={!appointmentNotesDisabled}
                  onChange={(value) =>
                    this.onMarketsharpChanged(
                      'appointmentNotesDisabled',
                      !value,
                    )
                  }
                />
              </>
            </Panel>
          )}
          <div
            style={{
              minHeight: '100px',
              height: '100px',
              width: '100%',
              clear: 'both',
            }}
          />
        </div>
      </>
    );
  }
}

const TitleButtons = ({ onCopyClicked, onSaveClicked }) => (
  <>
    <TitleButton variant="primary" onClick={onCopyClicked} title="Copy" />
    <TitleButton variant="success" onClick={onSaveClicked} title="Save" />
  </>
);

Appointments.propTypes = {
  appointmentsConfig: PropTypes.shape({
    endpoint: PropTypes.string,
    webhook: PropTypes.shape({
      url: PropTypes.string,
      authHeader: PropTypes.string,
    }),
    jobNimbus: PropTypes.shape({
      recordTypes: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  query: PropTypes.string,
  dataObjects: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string,
      type: PropTypes.string,
      display: PropTypes.bool,
      appKey: PropTypes.string,
      dateFormat: PropTypes.string,
    }),
  ),
  startUpdateConfig: PropTypes.func.isRequired,
  showErrorAlert: PropTypes.func.isRequired,
  hideAlert: PropTypes.func.isRequired,
  startSetupFori360: PropTypes.func.isRequired,
  selectedOffice: PropTypes.instanceOf(Parse.Object).isRequired,
  ...layoutContextPropTypes,
};

Appointments.defaultProps = {
  appointmentsConfig: {
    endpoint: '',
    webhook: {
      url: '',
      authHeader: '',
    },
    jobNimbus: {
      recordTypes: [],
    },
  },
  query: '',
  dataObjects: [],
};

TitleButtons.propTypes = {
  onCopyClicked: PropTypes.func.isRequired,
  onSaveClicked: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth: { config = {}, selectedOffice }, plan }) => ({
  endpointOptions: endpointOptions.filter((option) => {
    const { planKey } = option;
    if (planKey) {
      return plan[planKey];
    }
    return true;
  }),
  appointmentsConfig: config.appointmentsConfig,
  query: config.salesforceQuery,
  dataObjects: config.salesforceDataObjects,
  selectedOffice,
});

const mapDispatchToProps = (dispatch) => ({
  startUpdateConfig: (updates) => dispatch(startUpdateConfig(updates)),
  showErrorAlert: (object) => dispatch(showErrorAlert(object)),
  hideAlert: () => dispatch(hideAlert()),
  startSetupFori360: (callback) => dispatch(startSetupFori360(callback)),
});

export default withLayoutContext(
  withUserContext(connect(mapStateToProps, mapDispatchToProps)(Appointments)),
);
