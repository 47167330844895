/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  setInitialStateForTemplate,
  setEditCellItem,
} from '../../../actions/templateEdit';
import SectionTitle from '../SectionTitle';
import BodySectionGroup from './BodySectionGroup';
import BodyGroupObject from '../Models/BodyGroup';
import ContractObject from '../Models/ContractObject';
import { fontFamilyExists } from '../FontSelect';

const keyMaps = {
  value: 'contractTitle',
  bold: 'fontBold',
  border: 'drawBorder',
  underline: 'fontUnderline',
  size: 'fontSize',
  font: 'fontName',
  color: 'fontColor',
  cellItems: 'cellItems',
  shading: 'shading',
  newPage: 'newPage',
  linkedMeasureSheetItemIds: 'linkedMeasureSheetItemIds',
};

const bodySectionStyle = {
  position: 'relative',
};

const bodyTitleStyle = ({
  contractTitle,
  fontBold = true,
  fontColor,
  fontName = undefined,
  fontSize = 14,
  fontUnderline = false,
}) => {
  const colorArray = fontColor ? fontColor.split('-') : [];
  const [r, g, b, a] = colorArray;
  return {
    fontWeight: fontBold ? 900 : undefined,
    color: fontColor ? `rgba(${r},${g},${b},${a})` : undefined,
    fontSize,
    textDecoration: fontUnderline ? 'underline' : undefined,
    fontFamily: fontFamilyExists(fontName) ? fontName : 'HelveticaNeue',
    cursor: 'pointer',
    maxHeight: contractTitle ? undefined : '1px',
  };
};

class BodySection extends React.Component {
  render() {
    const { section, setTemplateState, setEditItem } = this.props;
    const { contractTitle, cellItems, ...rest } = section;
    return (
      <div id="body-section" css={bodySectionStyle}>
        <SectionTitle
          title={contractTitle}
          keyMaps={keyMaps}
          toolbarKey="bodyTitle"
          style={bodyTitleStyle(section)}
          onChange={(value) => {
            section.contractTitle = value;
            const contractObject = section.getSourceObject();
            setTemplateState(new ContractObject(contractObject.toJSON()));
          }}
          onClick={() => setEditItem(section)}
          {...rest}
        />
        <BodySectionGroup section={section} />
      </div>
    );
  }
}

BodySection.propTypes = {
  section: PropTypes.instanceOf(BodyGroupObject).isRequired,
  setEditItem: PropTypes.func.isRequired,
  setTemplateState: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
  setEditItem: (cellItem) => dispatch(setEditCellItem(cellItem)),
});

export default connect(undefined, mapDispatchToProps)(BodySection);
