import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { LayoutContext } from '../Layout';
import { pushToDataLayer as pushToDataLayerAction } from '../../actions/tagManager';
import { startUpdateConfig as startUpdateConfigAction } from '../../actions/auth';
import { SwitchGroup } from '../FormGroup';
import Copy from '../AppSettings/Copy';
import Panel from '../Panel';
import TitleButton from '../TitleButton';

/** @typedef {{
 *  enabled: boolean,
 *  showCopy: boolean,
 * }} GAFState */

const GAFQuickMeasure = () => {
  const title = 'GAF QuickMeasure';
  const { setButtons, setCrumbs } = useContext(LayoutContext);
  // Get config values from redux store
  const gaf = useSelector(({ auth: { config } }) => config.gaf);
  // Set dispatch instance and map actions to functions
  const dispatch = useDispatch();
  const [pushToDataLayer, startUpdateConfig] = [
    /** @param {import('../../actions/tagManager').AnalyticsEventData} payload */
    (payload) => dispatch(pushToDataLayerAction(payload)),
    (configUpdate) => dispatch(startUpdateConfigAction(configUpdate)),
  ];
  // Create state object and destructure it
  const [{ enabled, showCopy }, updateState] = useState({
    enabled: false,
    showCopy: false,
  });
  // Create a setState function that mirrors the way class state works
  /** @param {Partial<GAFState>} newState */
  const setState = (newState) => {
    updateState(
      /** @param {GAFState} prevState */
      (prevState) => ({
        ...prevState,
        ...newState,
      }),
    );
  };

  // Set state from redux store values
  useEffect(() => {
    if (typeof gaf === 'object') {
      const { enabled: _enabled = false } = gaf;
      setState({ enabled: _enabled });
    }
  }, [gaf]);

  const onCopyClicked = () => {
    setState({ showCopy: true });
  };

  const onSaveClicked = () => {
    startUpdateConfig({ gaf: { enabled } });
  };

  const buttons = (
    <TitleButtons onCopyClicked={onCopyClicked} onSaveClicked={onSaveClicked} />
  );

  // Add crumb and buttons to breadcrumb bar
  useEffect(() => {
    setCrumbs([{ title, link: '/gaf' }]);
    setButtons(buttons);
    return () => {
      setCrumbs([]);
      setButtons();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, setCrumbs, setButtons]);

  /** @param {boolean} checked */
  const onEnabledChanged = (checked) => {
    setState({ enabled: checked });
    if (!checked) {
      setState({ token: '' });
      pushToDataLayer({
        event: 'integrationEvent',
        eventCategory: 'Integration',
        eventAction: 'Disable',
        eventLabel: 'GAF QuickMeasure',
      });
    } else {
      pushToDataLayer({
        event: 'integrationEvent',
        eventCategory: 'Integration',
        eventAction: 'Enable',
        eventLabel: 'GAF QuickMeasure',
      });
    }
  };

  return (
    <div className="default-page-padding">
      <Copy
        title={`Copy ${title} Settings`}
        show={showCopy}
        warning={`Warning! This will overwrite your current ${title} settings`}
        configKeys={['gaf']}
        onClose={() => setState({ showCopy: false })}
      />
      <Panel title={title}>
        <div>
          <SwitchGroup
            title="Enabled"
            checked={enabled}
            onChange={onEnabledChanged}
          />
        </div>
      </Panel>
    </div>
  );
};

/**
 * @param {{
 *  onClearClick: () => void,
 *  onCopyClicked: () => void,
 *  onSaveClicked: () => void,
 * }} props
 */
const TitleButtons = ({ onClearClick, onCopyClicked, onSaveClicked }) => (
  <>
    <TitleButton variant="warning" onClick={onClearClick} title="Clear" />
    <TitleButton variant="primary" onClick={onCopyClicked} title="Copy" />
    <TitleButton variant="success" onClick={onSaveClicked} title="Save" />
  </>
);

TitleButtons.propTypes = {
  onClearClick: PropTypes.func.isRequired,
  onCopyClicked: PropTypes.func.isRequired,
  onSaveClicked: PropTypes.func.isRequired,
};

export default GAFQuickMeasure;
