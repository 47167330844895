/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import Parse from 'parse';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import EsitmatesFilterSection from './EsitmatesFilterSection';
import EsitmatesListSection from './EsitmatesListSection';
import {
  getEstimates as getEstimatesAction,
  setEstimatesFilter as setEstimatesFilterAction,
  initOfficeSelection as initOfficeSelectionAction,
} from '../../actions/estimatesPage';
import Paginator from '../Misc/Paginator';
import { fetchUsersForDropDown as fetchUsersForDropDownAction } from '../../actions/customers';
import { HistoryLink } from '../SharedComponents/HistoryLink';

class EstimatesCenterPage extends Component {
  componentDidMount() {
    const {
      initOfficeSelection,
      getEstimates,
      fetchUsersForDropDown,
      usersForDropDown,
      items,
      setCrumbs,
      setButtons,
      location: { pathname },
    } = this.props;
    initOfficeSelection();
    if (!items.length) {
      getEstimates({});
    }
    if (!usersForDropDown.length) {
      fetchUsersForDropDown();
    }

    const crumb = { title: 'Estimates', link: pathname };
    setCrumbs([crumb]);
    setButtons(
      <HistoryLink href="/emails" className="btn btn-primary">
        View Emails
      </HistoryLink>,
    );
  }

  componentWillUnmount() {
    const { setCrumbs, setButtons } = this.props;
    setCrumbs([]);
    setButtons();
  }

  onLimitChanged = (limit) => {
    this.props.setEstimatesFilter({ limit });
    this.props.getEstimates({});
  };

  onPageChanged = (page) => {
    const skip = (page - 1) * this.props.limit;
    this.props.setEstimatesFilter({ skip });
    this.props.getEstimates({});
  };

  render() {
    return (
      <div className="default-page-padding">
        <div
          id="estimates-center-stage"
          className="stage h-100 d-flex flex-column estimates-center-stage-inner"
        >
          <EsitmatesFilterSection />
          <EsitmatesListSection />
          <Paginator
            noCount
            page={this.props.page}
            limit={this.props.limit}
            totalCount={this.props.count}
            onLimitChanged={({ value }) => this.onLimitChanged(value)}
            pageRange={5}
            onPageClicked={(value) => this.onPageChanged(value)}
          />
        </div>
      </div>
    );
  }
}

EstimatesCenterPage.propTypes = {
  getEstimates: PropTypes.func.isRequired,
  setEstimatesFilter: PropTypes.func.isRequired,
  initOfficeSelection: PropTypes.func.isRequired,
  fetchUsersForDropDown: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  usersForDropDown: PropTypes.arrayOf(PropTypes.instanceOf(Parse.Object))
    .isRequired,
  items: PropTypes.arrayOf(PropTypes.instanceOf(Parse.Object)).isRequired,
  ...layoutContextPropTypes,
};

const mapStateToProps = ({
  estimatesPage,
  customers: { usersForDropDown = [] },
}) => {
  const { limit, skip, count } = estimatesPage;
  return {
    usersForDropDown,
    items: estimatesPage.items || [],
    limit,
    page: Math.floor(skip / limit) + 1,
    count,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getEstimates: (param) => dispatch(getEstimatesAction(param)),
  setEstimatesFilter: (param) => dispatch(setEstimatesFilterAction(param)),
  fetchUsersForDropDown: () => dispatch(fetchUsersForDropDownAction()),
  initOfficeSelection: () => dispatch(initOfficeSelectionAction()),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(EstimatesCenterPage),
);
