import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { startCopySharedResource } from '../../../actions/resources';

const ResourceCell = ({
  objectId,
  thumbnailURL,
  fileURL,
  name,
  startCopy,
  onDelete,
  onEdit,
}) => {
  const [imgError, setImgError] = useState(false);

  const handleImgError = () => {
    setImgError(true);
  };

  return (
    <div className="mask no-caption resources-cell card">
      <div className="thumbnail-container" role="button">
        <div className="img-thumbnail view view-first">
          <img
            className={`m-auto h-100 ${imgError ? 'd-none' : 'd-block'}`}
            src={thumbnailURL}
            onError={handleImgError}
            alt="Thumbnail"
          />
          <div
            style={{ fontSize: '7rem' }}
            className={`h-100 w-100 ${imgError ? 'd-block' : 'd-none'}`}
          >
            <i className="fas fa-camera-retro text-muted" />
          </div>
          <div className="mask no-caption">
            <div className="tools tools-bottom">
              {onEdit && (
                <button type="button" onClick={onEdit}>
                  <i className="fas fa-pencil-alt" aria-hidden="true" />
                </button>
              )}
              {fileURL && (
                <a
                  href={fileURL}
                  target="_blank"
                  id="viewButton"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-paperclip" aria-hidden="true" />
                </a>
              )}
              <button type="button" onClick={() => startCopy(objectId)}>
                <i className="far fa-copy" />
              </button>
              {onDelete && (
                <button type="button" onClick={onDelete}>
                  <i className="fas fa-trash" aria-hidden="true" />
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="caption">
          <p>{name}</p>
        </div>
      </div>
    </div>
  );
};

ResourceCell.propTypes = {
  objectId: PropTypes.string.isRequired,
  thumbnailURL: PropTypes.string.isRequired,
  fileURL: PropTypes.string,
  name: PropTypes.string.isRequired,
  startCopy: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
};

ResourceCell.defaultProps = {
  fileURL: undefined,
  onDelete: undefined,
  onEdit: undefined,
};

const mapDispatchToProps = (dispatch) => ({
  startCopy: (objectId) => dispatch(startCopySharedResource(objectId)),
});

export default connect(undefined, mapDispatchToProps)(ResourceCell);
