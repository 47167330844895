/* eslint-disable no-shadow */
/* eslint-disable react/forbid-prop-types */
/** @jsx jsx */
import { jsx } from '@emotion/core';
// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import {
  setInitialStateForTemplate,
  setEditCellItem,
} from '../../../actions/templateEdit';
import HeaderSection from './HeaderSection';
import { getItemStyle, getListStyle } from '../Utils';
import { setOnDragEndCallback } from '../../DropContext';
import ContractObject from '../Models/ContractObject';
import HeaderGroup from '../Models/HeaderGroup';
import HeaderSectionObject from '../Models/HeaderSection';

const HeaderContainer = ({
  section,
  setTemplateState,
  editCellItem,
  draggableType,
  setEditCellItem,
}) => {
  const droppableId = `${section.objectId}-${HeaderSectionObject.ContainerKey}`;
  setOnDragEndCallback(droppableId, (result) => {
    const { source, destination } = result;
    const contractObject = section.getSourceObject();
    let newGroup;
    if (source.droppableId === `new-${HeaderSectionObject.ContainerKey}`) {
      newGroup = new HeaderGroup();
      section.data.splice(destination.index, 0, newGroup);
    } else {
      const sourceId = source.droppableId.split('-')[0];
      const sourceContainer = contractObject.getObjectWithId(sourceId);
      const sourceItem = sourceContainer.data[source.index];
      sourceContainer.data.splice(source.index, 1);
      const destinationId = destination.droppableId.split('-')[0];
      const destinationContainer = contractObject.getObjectWithId(
        destinationId,
      );
      destinationContainer.data.splice(destination.index, 0, sourceItem);
    }

    const updateContract = new ContractObject(contractObject.toJSON());
    const [headsection] = updateContract.contractData.filter(
      (contractSection) => contractSection.objectId === section.objectId,
    );
    setTemplateState(updateContract);
    if (newGroup) {
      setEditCellItem(headsection.data[destination.index]);
    }
  });
  return (
    <Droppable
      droppableId={droppableId}
      isDropDisabled={draggableType !== HeaderSectionObject.ContainerKey}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          css={getListStyle(
            snapshot.isDraggingOver,
            editCellItem.objectId === section.objectId,
          )}
          {...provided.droppableProps}
          className="header-container"
        >
          {section.data.map((dataObject, index) => (
            <Draggable
              key={dataObject.objectId}
              draggableId={dataObject.objectId}
              index={index}
            >
              {(draggableProvided, draggableSnapshot) => (
                <div
                  ref={draggableProvided.innerRef}
                  {...draggableProvided.draggableProps}
                  css={{
                    ...getItemStyle(
                      draggableSnapshot,
                      draggableProvided.draggableProps.style,
                      editCellItem.objectId === dataObject.objectId,
                    ),
                  }}
                >
                  <HeaderSection section={dataObject} />
                  <div
                    {...draggableProvided.dragHandleProps}
                    css={{
                      ...draggableProvided.draggableProps.style,
                      position: 'absolute',
                      left: '-15px',
                      top: '0',
                      height: '100%',
                      backgroundColor: 'transparent',
                      width: '15px',
                    }}
                  />
                  {draggableProvided.placeholder}
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

HeaderContainer.propTypes = {
  section: PropTypes.instanceOf(HeaderSectionObject).isRequired,
  setTemplateState: PropTypes.func.isRequired,
  editCellItem: PropTypes.any,
  setEditCellItem: PropTypes.func.isRequired,
  draggableType: PropTypes.string,
};

HeaderContainer.defaultProps = {
  editCellItem: {},
  draggableType: undefined,
};

const mapStateToProps = ({ templatesEdit }) => ({
  editCellItem: templatesEdit.editCellItem,
  draggableType: templatesEdit.draggableType,
});

const mapDispatchToProps = (dispatch) => ({
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
  setEditCellItem: (item) => dispatch(setEditCellItem(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
