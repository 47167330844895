import Parse from 'parse';
import { startBecomeUser } from './actions/auth';
import MeasureSheetItem from './Models/MeasureSheetItem';
import CompanyModel from './Models/CompanyModel';
import PitchBookObject from './Models/PitchBookObject';
import CreditApplicationObject from './components/CreditApplications/Models/CreditApplicationObject';
import UserObject from './Models/UserObject';
import toShallowJSON from './toShallowJSON';

const configureParse = (store) => {
  Parse.initialize(process.env.REACT_APP_PARSE_APP_ID, '');
  Parse.serverURL = process.env.REACT_APP_SERVER_URL;
  Parse.Object.registerSubclass('SSMeasureSheetItem', MeasureSheetItem);
  Parse.Object.registerSubclass(
    'CreditApplicationObject',
    CreditApplicationObject,
  );
  Parse.Object.registerSubclass('PitchBookObject', PitchBookObject);
  Parse.Object.registerSubclass('Company', CompanyModel);
  Parse.Object.registerSubclass('_User', UserObject);
  // eslint-disable-next-line func-names
  Parse.Object.prototype.unsavedValues = function() {
    const dirtyKeys = this.dirtyKeys();
    return dirtyKeys.reduce(
      (prev, key) => ({
        ...prev,
        [key]: this.get(key),
      }),
      {},
    );
  };
  Parse.Object.prototype.toShallowJSON = toShallowJSON;
  const user = Parse.User.current();

  if (user) {
    store.dispatch(startBecomeUser(user));
  }
};

export default configureParse;
