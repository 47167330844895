import React from 'react';
import { Form } from 'react-bootstrap';
import Switch from 'react-switch';
import CurrencyInput from 'react-currency-input';
import NumberFormat from 'react-number-format';
import SelectComponent from 'react-select';
import StatePicker from './StatePicker';
import validators from './validators';

/**
 *
 * @param {{
 *  onChange: (value: any) => void,
 *  formData: {
 *    apiKey: string,
 *    appKey: string,
 *    cellId:  string,
 *    cellType:  string,
 *    displayType: string,
 *    dynamicInputObject: {
 *      cellId: string,
 *      objects: {
 *        defaultValue: string,
 *        enabled: boolean,
 *        fractionDigits: string,
 *        inputType: string,
 *        pickerValues: [],
 *        required: boolean,
 *        values: string[],
 *      }[]
 *    },
 *    enabled: true,
 *    fieldId: string,
 *    inputType: string,
 *    isCustomerFillable: boolean,
 *    note: string,
 *    pickerOptions: string[],
 *    placeholder: string,
 *    required: boolean,
 *    title: string,
 *  }
 * }} props
 */
const CellInputTypeSwitch = (props) => {
  const { onChange: changeFn, formData } = props;

  const {
    currentValue,
    pickerOptions = [],
    switchValueOn,
    switchValueOff,
    displayType,
    placeholder,
    statesList,
    required,
  } = formData;

  /**
   * Change handler for standard HTMLInput-eqsue components
   * @param {React.ChangeEvent<HTMLInputElement>} event - onChange event from an input-like element
   */
  const onInputChange = (event) => {
    /**
     * @type {HTMLInputElement}
     */
    const element = event.currentTarget;
    const { value } = element;
    changeFn(value);
    element.reportValidity();
  };

  /**
   *
   * @param {{value: string, formattedValue: string, floatValue: number}} values
   */
  const onNumberChange = ({ floatValue, formattedValue, value }) => {
    changeFn(value);
  };
  const onNumberChangeEvent = (event) => {
    /**
     * @type {HTMLInputElement}
     */
    const element = event.target;
    element.reportValidity();
  };

  /**
   * Change handler for react-currency-input components
   * @param {React.ChangeEvent<HTMLInputElement>} event - Change event of underlying element
   * @param {string} maskedvalue - WYSIWYG string value w/ formatting
   * @param {number} floatvalue - Floating point numerical value
   */
  const onCurrencyChange = (event, maskedvalue, floatvalue) => {
    const element = event.target;
    changeFn(floatvalue);
    element.reportValidity();
  };

  /**
   * Change handler for react-select compatible components
   * @param {value: ValueType<OptionTypeBase>} option - Selected option of Select component
   */
  const onSelectChange = (option) => {
    const { value } = option;
    changeFn(value);
  };

  /**
   *
   * @param {React.FocusEvent<HTMLInputElement>} event
   */
  const onBlur = (event) => {
    const element = event.currentTarget;
    element.reportValidity();
  };

  const validatorObject = validators[displayType] || {};

  switch (displayType) {
    case 'picker': {
      const value = pickerOptions.find(
        (option) => option.value === currentValue,
      );
      return (
        <SelectComponent
          className="lpstrp-select w-100"
          classNamePrefix="lpstrp-select"
          value={value}
          onChange={onSelectChange}
          onBlur={onBlur}
          closeMenuOnSelect
          required={required}
          options={pickerOptions}
        />
      );
    }
    case 'statePicker': {
      return (
        <StatePicker
          statesList={statesList}
          value={currentValue}
          required={required}
          onBlur={onBlur}
          onChange={onSelectChange}
        />
      );
    }
    case 'currency': {
      return (
        <CurrencyInput
          prefix="$"
          placeholder={placeholder}
          onBlur={onBlur}
          onChangeEvent={onCurrencyChange}
          required={required}
          value={currentValue}
          className="form-control form-control-lg"
          maxLength={validatorObject.maxLength}
          minLength={validatorObject.minLength}
          pattern={validatorObject.pattern}
          type={validatorObject.type}
        />
      );
    }
    case 'formulaCurrency': {
      return (
        <CurrencyInput
          prefix="$"
          placeholder={placeholder}
          onBlur={onBlur}
          onChangeEvent={onCurrencyChange}
          required={required}
          value={currentValue}
          className="form-control form-control-lg"
          maxLength={validatorObject.maxLength}
          minLength={validatorObject.minLength}
          pattern={validatorObject.pattern}
          type={validatorObject.type}
        />
      );
    }
    case 'currencyWhole': {
      return (
        <CurrencyInput
          precision="0"
          prefix="$"
          placeholder={placeholder}
          onBlur={onBlur}
          onChangeEvent={onCurrencyChange}
          required={required}
          value={currentValue}
          className="form-control form-control-lg"
          maxLength={validatorObject.maxLength}
          minLength={validatorObject.minLength}
          pattern={validatorObject.pattern}
          type={validatorObject.type}
        />
      );
    }
    case 'formulaCurrencyWhole': {
      return (
        <CurrencyInput
          precision="0"
          prefix="$"
          placeholder={placeholder}
          onBlur={onBlur}
          onChangeEvent={onCurrencyChange}
          required={required}
          value={currentValue}
          className="form-control form-control-lg"
          maxLength={validatorObject.maxLength}
          minLength={validatorObject.minLength}
          pattern={validatorObject.pattern}
          type={validatorObject.type}
        />
      );
    }
    case 'switched': {
      const checked = currentValue === switchValueOn;
      // TODO: Simplify onchange
      return (
        <div>
          <Switch
            checked={checked}
            onBlur={onBlur}
            onChange={(event) => {
              changeFn(event ? switchValueOn : switchValueOff);
            }}
            required={required}
          />
        </div>
      );
    }
    case 'phone':
    case 'creditCard':
    case 'ssn': {
      const formats = {
        ssn: '###-##-####',
        creditCard: '####-####-####',
        phone: '###-###-####',
      };
      const format = formats[displayType];
      return (
        <NumberFormat
          className="form-control form-control-lg"
          format={format}
          mask="_"
          max={validatorObject.max}
          maxLength={validatorObject.maxLength}
          min={validatorObject.min}
          minLength={validatorObject.minLength}
          onBlur={onBlur}
          onChange={onNumberChangeEvent}
          onValueChange={onNumberChange}
          pattern={validatorObject.pattern}
          placeholder={placeholder}
          required={required}
          type={validatorObject.type}
          value={currentValue}
        />
      );
    }

    default: {
      return (
        <Form.Control
          placeholder={placeholder}
          className="form-control-lg"
          onBlur={onBlur}
          onChange={onInputChange}
          required={required}
          value={currentValue}
          max={validatorObject.max}
          min={validatorObject.min}
          maxLength={validatorObject.maxLength}
          minLength={validatorObject.minLength}
          pattern={validatorObject.pattern}
          type={validatorObject.type}
        />
      );
    }
  }
};

export default CellInputTypeSwitch;
