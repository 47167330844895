import React from 'react';
import PropTypes from 'prop-types';

const PlaceholderInputRow = ({ placeholders, updateTestValue }) => (
  <div
    style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap' }}
  >
    {placeholders.map((placeholder, index) => (
      <div
        key={placeholder.symbol}
        style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          marginBottom: '10px',
          marginRight: '7px',
        }}
      >
        <span style={{ fontWeight: 'bold' }}>{placeholder.name}</span>
        <input
          style={{
            borderColor: placeholder.color,
            borderStyle: 'solid',
            borderWidth: 4,
            fontSize: '1.6rem',
            padding: '.675rem .75rem',
            cursor: 'text',
          }}
          type="number"
          placeholder={placeholder.symbol}
          value={placeholder.value}
          className="form-control"
          onChange={(e) => updateTestValue(e.target.value, placeholder, index)}
        />
      </div>
    ))}
  </div>
);

PlaceholderInputRow.propTypes = {
  updateTestValue: PropTypes.func.isRequired,
  placeholders: PropTypes.arrayOf(
    PropTypes.shape({
      symbol: PropTypes.string,
      color: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
      ]),
    }),
  ),
};

PlaceholderInputRow.defaultProps = {
  placeholders: [],
};

export default PlaceholderInputRow;
