import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from 'react';
import { useParams, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Parse from 'parse';
import { Table, Form, Container, Col, Row } from 'react-bootstrap';
import { FileInput, Icon, ProgressBar, Button } from '@blueprintjs/core';
import Dropzone from 'react-dropzone';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import isURL from 'validator/lib/isURL';
import { pdfjs, Document, Page } from 'react-pdf';
// Don't add psdjd-dist to dependencies: doing so will make pdfjs throw irrelevant prop validation errors to the console
// eslint-disable-next-line import/no-extraneous-dependencies
import workerSrc from 'pdfjs-dist/build/pdf.worker';
import { LayoutContext } from '../../Layout';
import Select from '../../Misc/Select';
import TitleButton from '../../TitleButton';
import { checkForErrors } from '../../AppSettings/ContractSending/Validation/Validator';
import {
  showErrorAlert,
  hideAlert,
  showSuccessAlert,
} from '../../../actions/alert';
import { setOnDragEndCallback } from '../../DropContext';
import { newFileFromFile } from '../../../utils/utils';
import { pushToDataLayer } from '../../../actions/tagManager';
import { showLoader, hideLoader } from '../../../actions/loading';
import Well from '../../SharedComponents/Well';
import SharedResourceObject from '../../../Models/SharedResourceObject';
import SharedResourceGroup from '../../../Models/SharedResourceGroup';

pdfjs.GlobalWorkerOptions.workerSrc = workerSrc;

const droppableId = 'sharedResourcesTabsEdit';

const acceptedString =
  '.pdf, .epub, .ppt, .pptx, .mp4, .aac, .ac3, .aif, .aiff, .aifc, .caf, .mp3, .m4a, .snd, .au, .sd2, .wav, .mov, .m4v, .3gp';

const fileTypes = [
  {
    value: 'file',
    label: 'File',
  },
  {
    value: 'url',
    label: 'Website',
  },
];

const fileTypeForDropDown = (fileType) => {
  switch (fileType) {
    case 'pdf':
    case 'ppt':
    case 'epub':
    case 'avf':
      return 'file';
    default:
      return fileType;
  }
};

const fileTypeForFileExtension = (extension) => {
  switch (extension.toLowerCase()) {
    case 'pdf':
      return 'pdf';
    case 'ppt':
    case 'pptx':
      return 'ppt';
    case 'epub':
      return 'epub';
    case 'aac':
    case 'ac3':
    case 'aif':
    case 'aiff':
    case 'aifc':
    case 'caf':
    case 'mp3':
    case 'mp4':
    case 'm4a':
    case 'snd':
    case 'au':
    case 'sd2':
    case 'wav':
    case 'mov':
    case 'm4v':
    case '3gp':
      return 'avf';
    default:
      return '';
  }
};

const validationErrors = (item) => {
  let url = '';
  if (item.get('fileType') === 'url' && item.get('url')) {
    if (!isURL(item.get('url'))) {
      url = 'Invalid URL';
    }
  }
  const buttons =
    item.get('fileType') === 'pdf' ? item.get('buttons') || [] : [];
  const errors = {
    item: {
      url,
      name: item.get('name') ? '' : 'You must specify a name',
      fileType: item.get('fileType') ? '' : 'You must specify a file type',
      buttons: buttons.map(({ pageNumber, title }) => ({
        pageNumber: pageNumber > 0 ? '' : 'Invalid',
        title: title ? '' : 'You must specify a Tab Title',
      })),
    },
  };
  return errors;
};

const SharedResourcesEditObj = () => {
  const dispatch = useDispatch();
  const thumbnailPlaceholder = 'Drop or select thumbnail...';
  const [state, updateState] = useState({
    errors: {},
    imgError: false,
    item: {
      buttons: [],
      file: new Parse.File(),
      fileType: '',
      isLandscape: false,
      name: '',
      thumbnail: new Parse.File(),
      url: '',
    },
    pageNumbers: [],
    progress: 0,
  });
  const resourceRef = useRef(new SharedResourceObject());
  const groupRef = useRef(new SharedResourceGroup());
  const sharedResourceObject = resourceRef.current;
  const sharedResourceGroup = groupRef.current;
  const setState = useCallback(
    (statePartial) => {
      if (typeof statePartial.item === 'object') {
        Object.keys(statePartial.item).forEach((key) => {
          sharedResourceObject.set(key, statePartial.item[key]);
        });
      }

      // Run validation on every state change
      const newErrors = validationErrors(sharedResourceObject);

      updateState({
        ...state,
        errors: newErrors,
        ...statePartial,
      });
    },
    [sharedResourceObject, state],
  );
  const { item, errors, imgError, progress, pageNumbers } = state;
  const { buttons, file, fileType, name, url } = item;
  const { crumbs, setButtons, setCrumbs } = useContext(LayoutContext);
  const { id, objId } = useParams();
  const { pathname } = useLocation();

  const stateFromResource = () => {
    const newPageNumbers = [];
    if (sharedResourceObject.get('fileType') === 'pdf') {
      for (let i = 0; i < item.pageNumber; i += 1) {
        newPageNumbers.push({
          value: i + 1,
          label: i + 1,
        });
      }
    }

    updateState({
      ...state,
      item: {
        buttons: sharedResourceObject.get('buttons'),
        file: sharedResourceObject.get('file'),
        fileType: sharedResourceObject.get('fileType'),
        isLandscape: sharedResourceObject.get('isLandscape'),
        name: sharedResourceObject.get('name'),
        pageNumber: sharedResourceObject.get('pageNumber'),
        thumbnail: sharedResourceObject.get('thumbnail'),
        url: sharedResourceObject.get('url'),
      },
      pageNumbers: newPageNumbers,
    });
  };

  const onSaveClicked = useCallback(async () => {
    if (checkForErrors(errors)) {
      dispatch(
        showErrorAlert({
          title: 'Error',
          message: 'Please fix all validation errors',
          onConfirm: hideAlert,
        }),
      );
      return;
    }
    try {
      if (objId) {
        dispatch(
          pushToDataLayer({
            event: 'resourceEvent',
            eventCategory: 'Resources',
            eventAction: 'Edit',
            eventLabel: name,
          }),
        );
      } else {
        dispatch(
          pushToDataLayer({
            event: 'resourceEvent',
            eventCategory: 'Resources',
            eventAction: 'Create',
            eventLabel: name,
          }),
        );
      }
      dispatch(showLoader('... saving resource'));
      await sharedResourceObject.save(null, undefined, (progress_) => {
        setState({ progress: progress_ });
      });
      dispatch(
        showSuccessAlert({
          title: 'Saved',
          message: `Resource: "${sharedResourceObject.get('name')}" updated!`,
          onConfirm: () => dispatch(hideAlert()),
        }),
      );
    } catch (e) {
      dispatch(
        showErrorAlert({
          title: 'Error',
          message: e.message,
          onConfirm: hideAlert,
        }),
      );
    } finally {
      dispatch(hideLoader());
    }
  }, [dispatch, errors, name, objId, setState, sharedResourceObject]);

  const titleButtons = <TitleButtons onSaveClicked={onSaveClicked} />;

  /**
   * React DnD setup (outdated, will need re-write later)
   */
  setOnDragEndCallback(droppableId, ({ source, destination }) => {
    const itemButtons = [...buttons];
    const sourceObject = itemButtons[source.index];
    itemButtons.splice(source.index, 1);
    itemButtons.splice(destination.index, 0, sourceObject);
    setState({ item: { ...item, buttons: itemButtons } });
  });

  /**
   * Get remote resources from Parse, then update breadcrumbs and state with it
   */
  useEffect(() => {
    const getSharedResourceObj = async () => {
      dispatch(showLoader('...Loading Resource'));

      sharedResourceObject.id = objId;
      await sharedResourceObject.fetch();
      sharedResourceGroup.id = id;
      await sharedResourceGroup.fetch();

      const resourceCrumb = {
        title: sharedResourceObject.get('name'),
        link: pathname,
      };
      const groupCrumb = {
        title: sharedResourceGroup.get('name'),
        link: `/shared_resources/edit/${id}`,
      };
      const newCrumbs = [...crumbs];
      newCrumbs[1] = groupCrumb;
      newCrumbs[2] = resourceCrumb;
      setCrumbs(newCrumbs);
      setButtons(titleButtons);

      stateFromResource();

      dispatch(hideLoader());
      dispatch(
        pushToDataLayer({
          event: 'resourceEvent',
          eventCategory: 'SharedResources',
          eventAction: 'View',
          eventLabel: sharedResourceObject.get('name') || '',
        }),
      );
    };

    if (objId && id && crumbs.length === 1) {
      getSharedResourceObj();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objId, id, crumbs.length]);

  const onInvalidFileType = () => {
    dispatch(
      showErrorAlert({
        title: 'Error',
        message: 'File type not supported',
        onConfirm: () => dispatch(hideAlert()),
      }),
    );
  };

  /**
   *
   * @param {File} fileObj The file object droped or uploaded by the user
   */
  const onFileChanged = async (fileObj) => {
    const newFile = await newFileFromFile(
      fileObj,
      fileObj.name,
      'shared_resource',
    );
    const fileExtension = newFile
      .name()
      .split('.')
      .pop();
    const validFile = acceptedString
      .split(', ')
      .filter((type) => type === `.${fileExtension.toLowerCase()}`).length;
    if (!validFile) {
      onInvalidFileType();
    } else {
      const fType = fileTypeForFileExtension(fileExtension.toLowerCase());
      const newFilePlaceholder = fileObj.name;
      setState({
        item: {
          ...item,
          file: newFile,
          fileType: fType,
        },
        filePlaceholder: newFilePlaceholder,
      });
    }
  };

  const onThumbnailChanged = async (e) => {
    const newFile = await newFileFromFile(
      e,
      'thumbnail',
      'shared_resource_thumbnail',
    );
    const newThumbnailPlaceholder = newFile.name();
    setState({
      item: {
        ...item,
        thumbnail: newFile,
      },
      thumbnailPlaceholder: newThumbnailPlaceholder,
    });
  };

  const onRemoveThumbnail = () => {
    sharedResourceObject.unset('thumbnail');
    const newThumbnailPlaceholder = 'Drop or select thumbnail...';
    const newItem = { ...item };
    newItem.thumbnail = undefined;
    setState({ item: newItem, thumbnailPlaceholder: newThumbnailPlaceholder });
  };

  const onTypeChanged = ({ value }) => {
    setState({ item: { ...item, fileType: value } });
  };

  /**
   * Handle all change events for text fields on SharedResourceObject
   * @param {React.ChangeEvent<HTMLInputElement>} event A React onChange event
   */
  const onTextChange = (event) => {
    const element = event.currentTarget;
    const { name: field, value } = element;
    setState({
      item: {
        ...item,
        [field]: value,
      },
    });
  };

  const onAddTab = () => {
    const pdfButtons = [...buttons].unshift({
      pageNumber: '',
      title: '',
    });
    setState({ item: { ...item, buttons: pdfButtons } });
  };

  const onTabChange = (value, index, key) => {
    const newButtons = buttons.map((button, i) => {
      if (i === index) {
        return {
          ...button,
          [key]: value,
        };
      }
      return button;
    });
    setState({
      item: {
        ...item,
        buttons: newButtons,
      },
    });
  };

  const onDeleteTab = (index) => {
    const pdfButtons = [...buttons].splice(index, 1);
    setState({
      item: {
        ...item,
        buttons: pdfButtons,
      },
    });
  };

  const onPDFLoadSuccess = (numPages) => {
    setState({ item: { ...item, pageNumber: numPages } });
  };

  const onImageError = () => {
    setState({ imgError: true });
  };

  return (
    <div className="default-page-padding">
      <div className="d-none">
        <Document
          file={item.file ? item.file.url : ''}
          onLoadSuccess={({ numPages }) => onPDFLoadSuccess(numPages)}
          noData={<h4>Please select a file</h4>}
        >
          <Page pageNumber={1} />
        </Document>
      </div>
      <Container>
        <ProgressBar
          value={progress}
          className={`mb-2${progress ? ' d-block' : ' d-none'}`}
        />
        <Form onSubmit={(e) => e.preventDefault()}>
          <Form.Group as={Row}>
            <Form.Label column sm={2} className="font-weight-bold">
              Thumbnail
            </Form.Label>
            <Col sm={10}>
              <Dropzone
                onDrop={(e) => {
                  if (e[0]) {
                    onThumbnailChanged(e[0]);
                  }
                }}
                accept="image/*"
              >
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <div
                    {...getRootProps()}
                    style={{ minHeight: '255px', cursor: 'pointer' }}
                  >
                    <input {...getInputProps()} />
                    <Well
                      bsSize="lg"
                      style={{
                        minHeight: '255px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {item.thumbnail ? (
                        <>
                          <img
                            alt="Thumbnail"
                            src={item.thumbnail.url()}
                            className={`resources-thumbnail ${
                              imgError ? 'd-none' : 'd-block'
                            }`}
                            onError={onImageError}
                          />
                          <div
                            className={`h-100 w-100 text-center ${
                              imgError ? 'd-block' : 'd-none'
                            }`}
                          >
                            <i
                              style={{ fontSize: '10rem' }}
                              className="fas fa-camera-retro text-muted"
                            />
                            <br />
                            Couldn&apos;t load thumbnail image
                            <br />
                            Click to upload new thumbnail
                          </div>
                        </>
                      ) : (
                        <Button
                          text={
                            isDragActive
                              ? 'Drop file here...'
                              : thumbnailPlaceholder
                          }
                        />
                      )}
                    </Well>
                  </div>
                )}
              </Dropzone>
              {item.thumbnail ? (
                <button
                  onClick={onRemoveThumbnail}
                  type="button"
                  className="clear-button"
                >
                  <i className="fas fa-times-circle danger" />
                </button>
              ) : (
                <div />
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={2} className="font-weight-bold">
              Resource Type
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                as="select"
                value={fileTypeForDropDown(fileType)}
                onChange={onTypeChanged}
                size="lg"
              >
                {fileTypes.map(({ label, value }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </Form.Control>
              {/* errorMessage={errors.item && errors.item.fileType} */}
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={2} className="font-weight-bold">
              Name
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                as="input"
                name="name"
                value={name}
                onChange={onTextChange}
                size="lg"
              />
              {/* errorMessage={errors.item && errors.item.name} */}
            </Col>
          </Form.Group>
          {item.fileType !== 'url' && (
            <Form.Group as={Row}>
              <Form.Label column sm={2} className="font-weight-bold">
                File
              </Form.Label>
              <Col sm={10}>
                <Dropzone
                  onDropRejected={() => onInvalidFileType()}
                  onDrop={(e) => {
                    if (e[0]) {
                      onFileChanged(e[0]);
                    }
                  }}
                  accept={acceptedString}
                >
                  {({ getRootProps, isDragActive }) => (
                    <div {...getRootProps()}>
                      <FileInput
                        // eslint-disable-next-line no-underscore-dangle
                        text={isDragActive ? 'Drop file here...' : file._name}
                        onChange={(e) => onFileChanged(e.target.files[0])}
                        inputProps={{
                          accept: acceptedString,
                        }}
                        fill
                        large
                      />
                    </div>
                  )}
                </Dropzone>
              </Col>
            </Form.Group>
          )}
          {item.fileType === 'url' && (
            <Form.Group as={Row}>
              <Form.Label column sm={2} className="font-weight-bold">
                URL
              </Form.Label>
              <Col sm={10}>
                <Form.Control value={url} name="url" onChange={onTextChange} />
                {/* errorMessage={errors.url} */}
              </Col>
            </Form.Group>
          )}
          {item.fileType === 'pdf' && (
            <Form.Group as={Row}>
              <Form.Label column sm={2} className="font-weight-bold">
                Tabs
              </Form.Label>
              <Col sm={10}>
                <Table bordered hover className="resources-tabs-table">
                  <thead>
                    <tr>
                      <th style={{ textAlign: 'center', width: '80px' }}>
                        Pg #
                      </th>
                      <th style={{ textAlign: 'center' }}>Tab Title</th>
                      <th style={{ textAlign: 'center', width: '25px' }}>
                        <button onClick={onAddTab} type="button">
                          Add
                        </button>
                      </th>
                    </tr>
                  </thead>
                </Table>
                <Droppable droppableId={droppableId} direction="vertical">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {buttons.map(({ pageNumber, title }, index) => (
                        <Draggable
                          draggableId={`button-${index}`}
                          index={index}
                          key={pageNumber}
                        >
                          {(draggableProvided, snapshot) => (
                            <div
                              // eslint-disable-next-line react/jsx-indent-props
                              ref={draggableProvided.innerRef}
                              // eslint-disable-next-line react/jsx-indent-props
                              {...draggableProvided.draggableProps}
                              // eslint-disable-next-line react/jsx-indent-props
                              {...draggableProvided.dragHandleProps}
                              // eslint-disable-next-line react/jsx-closing-bracket-location
                            >
                              <Table
                                bordered
                                hover
                                className="resources-tabs-table"
                              >
                                <tbody>
                                  <tr
                                    style={{
                                      backgroundColor: snapshot.isDragging
                                        ? 'white'
                                        : 'transparent',
                                    }}
                                  >
                                    <td style={{ width: '80px' }}>
                                      <div
                                        className={
                                          errors.item.buttons[index].pageNumber
                                            ? 'error-border'
                                            : ''
                                        }
                                      >
                                        <Select
                                          name="form-field-name"
                                          value={{
                                            value: pageNumber,
                                            label: pageNumber,
                                          }}
                                          onChange={({ value }) =>
                                            onTabChange(
                                              value,
                                              index,
                                              'pageNumber',
                                            )
                                          }
                                          closeMenuOnSelect
                                          isClearable={false}
                                          options={pageNumbers}
                                          noOptionsMessage="No file uploaded"
                                          placeholder=""
                                        />
                                      </div>
                                      <div className="error">
                                        {errors.item.buttons[index].pageNumber}
                                      </div>
                                    </td>
                                    <td>
                                      <input
                                        value={title}
                                        onChange={(e) =>
                                          onTabChange(
                                            e.target.value,
                                            index,
                                            'title',
                                          )
                                        }
                                        className={`resources__tab-title${
                                          errors.item.buttons[index].title
                                            ? ' error-border'
                                            : ''
                                        }`}
                                      />
                                      <div className="error">
                                        {errors.item.buttons[index].title}
                                      </div>
                                    </td>
                                    <td style={{ width: '54px' }}>
                                      <Icon
                                        icon="trash"
                                        intent="danger"
                                        onClick={() => onDeleteTab(index)}
                                        style={{ cursor: 'pointer' }}
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    </div>
                  )}
                </Droppable>
              </Col>
            </Form.Group>
          )}
        </Form>
      </Container>
    </div>
  );
};

const TitleButtons = ({ onSaveClicked }) => (
  <div>
    <TitleButton variant="success" onClick={onSaveClicked} title="Save" />
  </div>
);

TitleButtons.propTypes = {
  onSaveClicked: PropTypes.func.isRequired,
};

export default SharedResourcesEditObj;
