/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import PieCellRenderWrapper from './PieCellRenderWrapper';
import { getRandomColor } from '../utils';

const toolTipPropsTransform = (props) => {
  const { payload, dataSet } = props;
  const total = dataSet.reduce((sum, item) => sum + item.value, 0);
  const [dataItem] = payload;
  if (dataItem) {
    const itemData = {
      ...dataItem.payload,
    };
    const percent = itemData.value / total;
    return {
      ...itemData,
      percent,
    };
  }
  return {
    ...props,
  };
};

const DefaultToolTipRenderer = (props) => (
  <div>{`${props.name} ${(props.percent * 100).toFixed(0)}%`}</div>
);

const LeapAnalyticsPie = (props) => (
  <PieChart width={props.width} height={props.height}>
    <Pie
      dataKey={props.dataKey}
      data={props.data}
      labelLine={false}
      label={(labelData) => (
        <PieCellRenderWrapper cellProps={labelData} userProps={props} />
      )}
      fill="#8884d8"
    >
      {props.data.map((entry, index) => (
        <Cell key={entry} fill={getRandomColor()} />
      ))}
    </Pie>
    <Tooltip
      content={(labelData) =>
        props.toolTipRenderer ? (
          props.toolTipRenderer(
            toolTipPropsTransform({
              ...labelData,
              dataSet: props.data,
            }),
          )
        ) : (
          <DefaultToolTipRenderer
            {...toolTipPropsTransform({
              ...labelData,
              dataSet: props.data,
            })}
          />
        )
      }
    />
  </PieChart>
);

LeapAnalyticsPie.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  toolTipRenderer: PropTypes.func,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  dataKey: PropTypes.string,
};

LeapAnalyticsPie.defaultProps = {
  toolTipRenderer: null,
  data: [],
  height: 200,
  width: 200,
  dataKey: 'value',
};

export default LeapAnalyticsPie;
