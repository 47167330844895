/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
// import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { VertDropDownWidget } from '../SharedComponents/VertDropDownWidget';
import { VertInputWidget } from '../SharedComponents/VertInputWidget';
import { showSuccessAlert, hideAlert } from '../../actions/alert';
import { initPaymentRequest } from '../../actions/estimatesPage';
import { startUpdateConfig } from '../../actions/auth';
import VertAutoCompleteWidget from '../SharedComponents/VertAutoCompleteWidget';
import { emailRegex } from '../../validators';

// import { cleanHtml } from '../../utils/cleanHtml';
let sending = false;
// const templateHasRequiredPlaceHolders = (props) => {
//   return props.defaultEmailTemplate
//     && props.defaultEmailTemplate.indexOf('%message%') > -1
//     && props.defaultEmailTemplate.indexOf('%payment_link_url%') > -1;
// }

export const hasValidRequestParams = (props) => {
  const {
    paymentAmount,
    allowedPaymentTypes,
    toEmail,
    fromEmail,
    paymentRequestMessage,
    selectedOfficeId,
    // transactionDescription
  } = props;
  return (
    paymentAmount &&
    paymentRequestMessage &&
    allowedPaymentTypes &&
    allowedPaymentTypes.length &&
    selectedOfficeId &&
    toEmail.length &&
    !toEmail.filter((email) => !emailRegex.test(email)).length &&
    fromEmail
  );
};

const getFromEmailOptions = (props) => {
  const { userEmail, selectedOfficeId, paymentRequestConfigByOffice } = props;
  const paymentRequestConfig =
    paymentRequestConfigByOffice[selectedOfficeId] || {};
  const { defaultFromEmail = '' } = paymentRequestConfig;
  const emailDropDownOptions = [];
  if (defaultFromEmail) {
    emailDropDownOptions.push({
      label: defaultFromEmail,
      value: defaultFromEmail,
    });
  }
  if (userEmail !== defaultFromEmail) {
    emailDropDownOptions.push({
      label: userEmail,
      value: userEmail,
    });
  }
  return emailDropDownOptions;
};

class PaymentRequestModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentAmount: 0,
      toEmail: [],
      paymentRequestMessage: 'Thank You For Your Business',
      defaultEmailTemplate: props.defaultEmailTemplate,
      allowedPaymentOptions: props.allowedPaymentOptions,
      transactionDescription: props.transactionDescription,
      configOffice: [],
      allowedPaymentTypes: [],
      showHtml: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.show && this.props.show) {
      if (!this.state.toEmail.length && this.props.customerEmails.length >= 1) {
        this.setState({ toEmail: [this.props.customerEmails[0].value] });
      }
      if (
        !this.state.fromEmail &&
        this.props.emailDropDownOptions.length >= 1
      ) {
        this.setState({ fromEmail: this.props.emailDropDownOptions[0].value });
      }

      if (
        !this.state.selectedOfficeId &&
        this.props.validOfficeIds.length >= 1
      ) {
        this.setState({ selectedOfficeId: this.props.validOfficeIds[0].value });
      }
    }
  }

  onSendPaymentRequest = async () => {
    const {
      paymentAmount,
      allowedPaymentTypes,
      toEmail,
      fromEmail,
      selectedOfficeId,
      paymentRequestMessage,
      transactionDescription,
    } = this.state;
    if (hasValidRequestParams(this.state) && !sending) {
      sending = true;
      const { companyId, customerId } = this.props;
      const paymentRequestData = {
        paymentAmount: parseInt((paymentAmount * 100).toFixed(0), 10),
        allowedPaymentTypes,
        toEmail,
        selectedOfficeId,
        fromEmail,
        companyId,
        customerId,
        paymentRequestMessage,
        description: transactionDescription,
      };
      await this.props.initPaymentRequest(paymentRequestData);
      sending = false;
      this.props.onClose();
    }
  };

  allowedPaymentOptions = () =>
    (this.props.paymentRequestConfigByOffice[this.state.selectedOfficeId] || {})
      .allowedPaymentOptions || [];

  templateWithMessage = () =>
    this.state.defaultEmailTemplate
      .split('%message%')
      .join(this.state.paymentRequestMessage);

  render() {
    const allFeilds = hasValidRequestParams(this.state);
    return (
      <Modal
        dialogClassName="payment-request-modal"
        show={this.props.show}
        onHide={this.props.onClose}
      >
        <Modal.Header closeButton>
          <Modal.Title className="flex-1">
            <div className="d-flex justify-content-between align-items-center">
              <span> Request Payment </span>
              <button
                type="button"
                disabled={!allFeilds}
                onClick={this.onSendPaymentRequest}
                className="btn btn-success"
              >
                Send Payment Request
              </button>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container my-1">
            <div className="row">
              <div className="col-md-12">
                <h1 className="payment-request-modal-header lil-green-blurb">
                  Payment Request
                </h1>
              </div>
            </div>
          </div>
          <div
            style={{
              display: this.props.validOfficeIds.length > 1 ? 'block' : 'none',
            }}
            className="container my-3"
          >
            <div className="row">
              <div className="col-md-6">
                <VertDropDownWidget
                  hasSelectAll={false}
                  disableSearch
                  placeholder="Select An Office"
                  selected={[this.state.selectedOfficeId]}
                  options={this.props.validOfficeIds}
                  title="Office:"
                  onSelectedChanged={(selected) => {
                    const [selectedOfficeId] = selected.filter(
                      (office) => office !== this.state.selectedOfficeId,
                    );
                    if (selectedOfficeId) {
                      this.setState({
                        selectedOfficeId,
                        allowedPaymentTypes: [],
                        fromEmail: this.props.userEmail,
                      });
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="container my-3">
            <div className="row">
              <div className="col-md-6">
                <VertDropDownWidget
                  title="From:"
                  hasSelectAll={false}
                  placeholder="Select an Email"
                  disableSearch
                  errorMessage={
                    !this.state.fromEmail ? 'Must Select From Email!' : null
                  }
                  selected={[this.state.fromEmail]}
                  options={[
                    ...getFromEmailOptions({
                      ...this.props,
                      selectedOfficeId: this.state.selectedOfficeId,
                    }),
                  ]}
                  onSelectedChanged={(selected) => {
                    const [fromEmail] = selected.filter(
                      (office) => office !== this.state.fromEmail,
                    );
                    if (fromEmail) {
                      this.setState({ fromEmail });
                    }
                  }}
                />
              </div>
              <div className="col-md-6">
                <VertDropDownWidget
                  hasSelectAll={false}
                  disableSearch
                  placeholder="Select an Payment Type"
                  errorMessage={
                    !this.state.allowedPaymentTypes.length
                      ? 'Must Select Payment Type!'
                      : null
                  }
                  selected={this.state.allowedPaymentTypes}
                  options={this.allowedPaymentOptions().map((type) => ({
                    label: type,
                    value: type,
                  }))}
                  title="Payment Type:"
                  onSelectedChanged={(selected) => {
                    this.setState({ allowedPaymentTypes: selected });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="container my-3">
            <div className="row">
              <div className="col-md-6">
                <VertAutoCompleteWidget
                  hasSelectAll={false}
                  placeholder="To:"
                  errorMessage={
                    this.state.toEmail.filter(
                      (email) => !emailRegex.test(email),
                    ).length || !this.state.toEmail.length
                      ? 'Vaild "To Email" Is Required !'
                      : null
                  }
                  selected={this.state.toEmail.map((email) => ({
                    label: email,
                    value: email,
                  }))}
                  options={this.props.customerEmails
                    .map(({ value }) => ({
                      value,
                      label: value,
                    }))
                    .filter(
                      (option) => !this.state.toEmail.includes(option.value),
                    )}
                  title="To:"
                  onSelection={(values) => {
                    this.setState({
                      toEmail: values.map((optionObject) => optionObject.value),
                    });
                  }}
                />
              </div>
              <div className="col-md-6">
                <VertInputWidget
                  type="currency"
                  errorMessage={
                    !this.state.paymentAmount
                      ? 'Must Have Positive Payment Amount!'
                      : null
                  }
                  value={this.state.paymentAmount}
                  title="Amount:"
                  onChange={(event) => {
                    this.setState({
                      paymentAmount: parseFloat(event.target.value),
                    });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="container my-3">
            <div className="row">
              <div className="col-md-12">
                <VertInputWidget
                  textarea
                  className="payment-request-email-message-input"
                  type="string"
                  value={this.state.paymentRequestMessage}
                  title="Message:"
                  onChange={(event) => {
                    this.setState({
                      paymentRequestMessage: event.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="container my-3">
            <div className="row">
              <div className="col-md-12">
                <VertInputWidget
                  textarea
                  className="payment-request-email-message-input"
                  type="string"
                  value={this.state.transactionDescription}
                  title="Transaction Description:"
                  onChange={(event) => {
                    this.setState({
                      transactionDescription: event.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          {/* <div className='container my-3'>
            <div className='row' >
              <div className="col-md-12">
                <div className='top-email-bar p-2'>

                </div>
                <div
                  className='payment-request-email-body-section p-2' >
                  {
                    <div dangerouslySetInnerHTML={{ __html: cleanHtml(this.templateWithMessage()) }} />
                  }
                </div>
              </div>
            </div>
          </div> */}
        </Modal.Body>
      </Modal>
    );
  }
}

PaymentRequestModal.propTypes = {};

PaymentRequestModal.defaultProps = {
  show: false,
};

const mapStateToProps = ({ auth, estimatesPage }) => {
  const { config = {}, user, company } = auth;
  const { details = { estimate: {} }, validOffices = [] } = estimatesPage;
  const userEmail = user.get('email');
  const { spreedly = {} } = config;
  const { paymentRequest = {} } = spreedly;
  const {
    defaultEmailTemplate = '',
    transactionDescription = '',
    defaultEmailMessage = '',
    defaultFromEmail = '',
    allowedPaymentOptions = [],
  } = paymentRequest;
  const emailDropDownOptions = [];
  if (defaultFromEmail) {
    emailDropDownOptions.push({
      label: defaultFromEmail,
      value: defaultFromEmail,
    });
  }
  if (userEmail !== defaultFromEmail) {
    emailDropDownOptions.push({
      label: userEmail,
      value: userEmail,
    });
  }
  const paymentRequestConfigByOffice = validOffices.reduce(
    (configs, office) => {
      // const { spreedly: spreedlySettings } = office.get('config').toJSON();
      const spreedlySettings = office.get('config').get('spreedly');

      return {
        ...configs,
        [office.id]: {
          ...spreedlySettings.paymentRequest,
        },
      };
    },
    {},
  );
  return {
    userEmail,
    paymentRequestConfigByOffice,
    spreedly,
    validOfficeIds: validOffices.map((office) => ({
      label: office.get('name'),
      value: office.id,
    })),
    companyId: company.id,
    customerId: details.objectId ? details.objectId : null,
    toName: details.estimate ? `${details.nameFirst} ${details.nameLast}` : '',
    emailDropDownOptions,
    allowedPaymentOptions: allowedPaymentOptions.map((type) => ({
      label: type,
      value: type,
    })),
    paymentRequest,
    defaultEmailTemplate,
    defaultEmailMessage,
    defaultFromEmail,
    transactionDescription,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showSuccessAlert: (params) => dispatch(showSuccessAlert(params)),
  hideAlert: () => dispatch(hideAlert()),
  startUpdateConfig: (update) => dispatch(startUpdateConfig(update)),
  initPaymentRequest: (params) => dispatch(initPaymentRequest(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentRequestModal);
