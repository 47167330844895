/* eslint-disable no-underscore-dangle */
// @ts-check
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Parse from 'parse';
import { Form } from 'react-bootstrap';
import CreditApplicationObject from '../../../Models/CreditApplicationObject';
import Switch from '../../Misc/Switch';

const EnableSendButton = () => {
  /**
   * @type {{id: string}}
   */
  const { id: creditAppId } = useParams();
  /**
   * @type {[undefined | CreditApplicationObject, React.Dispatch<undefined | CreditApplicationObject>]}
   */
  const [creditApp, setCreditApp] = useState();
  const [enabled, setEnabled] = useState(false);
  const [isPartner, setIsPartner] = useState(true);

  useEffect(() => {
    const getCreditApp = async () => {
      if (creditAppId) {
        const query = new Parse.Query(CreditApplicationObject);
        const creditApp_ = await query.get(creditAppId);
        if (creditApp_) {
          setCreditApp(creditApp_);
          setEnabled(creditApp_.get('isCustomerFillable'));
          setIsPartner(!!creditApp_.get('partnerCreditApplication'));
        }
      }
    };
    getCreditApp();
  }, [creditAppId]);

  const toggleSend = async () => {
    if (creditApp) {
      const newVal = !creditApp.get('isCustomerFillable');
      creditApp.set('isCustomerFillable', newVal);
      await creditApp.save(null);
      setEnabled(newVal);
    }
  };

  return (
    <div className="d-flex align-items-center mx-3 px-2">
      <Form.Label className={`m-0 mr-3${isPartner ? ' text-muted' : ''}`}>
        Enable Send
      </Form.Label>
      <Switch checked={enabled} onChange={toggleSend} disabled={isPartner} />
    </div>
  );
};

export default EnableSendButton;
