/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/no-access-state-in-setstate */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Popover, TextArea } from 'leap-menu-item';
import onClickOutside from 'react-onclickoutside';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import {
  setInitialStateForTemplate,
  setEditCellItem,
} from '../../../actions/templateEdit';
import ContractObject from '../Models/ContractObject';
import SignatureItemObject from '../Models/SignatureItem';
import SignatureAdditionalLineObject from '../Models/SignatureAdditionalLine';
import { setOnDragEndCallback } from '../../DropContext';
import { getItemStyle, getListStyle } from '../Utils';
import { fontFamilyExists } from '../FontSelect';

const colorFromString = (string) => {
  const [r, g, b, a] = string.split('-');
  return `rgba(${r},${g},${b},${a})`;
};

const styleForNote = (
  {
    contractNoteFontSize,
    contractNoteBold,
    contractNoteUnderline,
    contractNoteFontName,
    contractNoteFontColor,
  },
  isHovering,
) => ({
  paddingLeft: '10px',
  fontSize: contractNoteFontSize,
  fontWeight: contractNoteBold ? '900' : '400',
  textDecoration: contractNoteUnderline ? 'underline' : undefined,
  fontFamily: fontFamilyExists(contractNoteFontName)
    ? contractNoteFontName
    : 'HelveticaNeue',
  color: contractNoteFontColor
    ? colorFromString(contractNoteFontColor)
    : undefined,
  opacity: isHovering ? '0.7' : undefined,
  cursor: 'pointer',
});

const styleForAdditionalLineValue = (
  {
    valueFontSize,
    valueFontBold,
    valueFontUnderline,
    valueFontName,
    valueFontColor,
  },
  isHovering,
) => ({
  paddingLeft: '15px',
  fontSize: valueFontSize || 12,
  fontWeight: valueFontBold ? '900' : '400',
  textDecoration: valueFontUnderline ? 'underline' : undefined,
  fontFamily: fontFamilyExists(valueFontName) ? valueFontName : 'HelveticaNeue',
  color: valueFontColor ? colorFromString(valueFontColor) : undefined,
  opacity: isHovering ? '0.7' : undefined,
  cursor: 'pointer',
});

const styleForAdditionalLineNote = (
  {
    noteFontSize,
    noteFontBold,
    noteFontUnderline,
    noteFontName,
    noteFontColor,
  },
  isHovering,
) => ({
  paddingLeft: '10px',
  fontSize: noteFontSize || 8,
  fontWeight: noteFontBold ? '900' : '400',
  textDecoration: noteFontUnderline ? 'underline' : undefined,
  fontFamily: fontFamilyExists(noteFontName) ? noteFontName : 'HelveticaNeue',
  color: noteFontColor ? colorFromString(noteFontColor) : undefined,
  opacity: isHovering ? '0.7' : undefined,
  cursor: 'pointer',
});

class SignatureCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hovering: {}, showPopover: '' };
    this.noteRef = React.createRef();
    this.droppableId = `${props.cell.getPath().join('-')}-${
      props.cell.objectId
    }-${SignatureAdditionalLineObject.ContainerKey}`;
    this.updateDraggableCallback();
  }

  componentDidUpdate() {
    this.updateDraggableCallback();
  }

  onMouseOver = (id) => {
    const hovering = { ...this.state.hovering };
    hovering[id] = true;
    this.setState({ hovering });
  };

  onMouseOut = (id) => {
    const hovering = { ...this.state.hovering };
    hovering[id] = false;
    this.setState({ hovering });
  };

  onMouseDown = (object) => {
    this.props.setEditItem(object);
  };

  onDoubleClick = (id) => {
    this.setState({ showPopover: id });
  };

  handleClickOutside = () => {
    this.setState({ showPopover: '' });
  };

  updateDraggableCallback = () => {
    const { cell, setTemplateState, setEditItem } = this.props;
    setOnDragEndCallback(this.droppableId, (result) => {
      const { destination, draggableId, source } = result;
      let destinationPath = destination.droppableId.split('-');
      destinationPath.pop();
      destinationPath.pop();
      destinationPath = destinationPath.map((index) => parseInt(index, 10));
      destinationPath.push(destination.index);
      const contractObject = cell.getSourceObject();
      const updatedContractObject = new ContractObject(contractObject.toJSON());
      if (
        source.droppableId ===
        `new-${SignatureAdditionalLineObject.ContainerKey}`
      ) {
        const newItem = new SignatureAdditionalLineObject();
        updatedContractObject.insertObjectAtPath(destinationPath, newItem, 0);
        setTemplateState(updatedContractObject);
        setEditItem(newItem);
        return;
      }
      let sourcePath = draggableId.split('-');
      sourcePath.pop();
      sourcePath = sourcePath.map((index) => parseInt(index, 10));
      if (
        sourcePath.every((value, index) => value === destinationPath[index])
      ) {
        return; // same path
      }
      const sourceItem = updatedContractObject.getItemAtPath(sourcePath);
      updatedContractObject.deleteObjectAtPath(sourcePath);
      updatedContractObject.insertObjectAtPath(destinationPath, sourceItem, 0);
      setTemplateState(updatedContractObject);
    });
  };

  render() {
    const { cell, editCellItem, setTemplateState, draggableType } = this.props;
    const signatureNoteStyle = styleForNote(
      cell,
      this.state.hovering[cell.objectId],
    );
    return (
      <div>
        <div
          css={{
            minHeight: '50px',
            maxHeight: '50px',
            width: '100%',
            borderBottom: cell.underline ? '1px solid' : undefined,
          }}
        />
        <div
          css={signatureNoteStyle}
          onMouseOver={() => this.onMouseOver(cell.objectId)}
          onMouseOut={() => this.onMouseOut(cell.objectId)}
          onDoubleClick={() => this.onDoubleClick(cell.objectId)}
          onMouseDown={() => this.onMouseDown(cell)}
        >
          <Popover
            content={
              <textarea
                onContextMenu={(e) => e.stopPropagation()}
                fill
                ref={this.noteRef}
                defaultValue={cell.contractNote}
                className="ignore-react-onclickoutside"
              />
            }
            isOpen={this.state.showPopover === cell.objectId}
            onClose={() => {
              if (cell.contractNote !== this.noteRef.current.value) {
                cell.contractNote = this.noteRef.current.value;
                const contractObject = cell.getSourceObject();
                setTemplateState(new ContractObject(contractObject.toJSON()));
              }
            }}
          >
            <div css={signatureNoteStyle}>{cell.contractNote}</div>
          </Popover>
        </div>
        <Droppable
          droppableId={this.droppableId}
          isDropDisabled={
            draggableType !== SignatureAdditionalLineObject.ContainerKey
          }
        >
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              css={getListStyle(
                snapshot.isDraggingOver,
                editCellItem.objectId === cell.objectId,
              )}
            >
              {cell.additionalLines.map((additionalLine, index) => {
                const valueId = `${additionalLine.objectId}-value`;
                const noteId = `${additionalLine.objectId}-note`;
                const valueStyle = styleForAdditionalLineValue(
                  additionalLine,
                  this.state.hovering[valueId],
                );
                const noteStyle = styleForAdditionalLineNote(
                  additionalLine,
                  this.state.hovering[noteId],
                );
                const draggableId = `${additionalLine.getPath().join('-')}-${
                  additionalLine.objectId
                }`;
                return (
                  <Draggable
                    key={additionalLine.objectId}
                    draggableId={draggableId}
                    index={index}
                  >
                    {(draggableProvided, draggableSnapshot) => (
                      <div
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                        css={getItemStyle(
                          draggableSnapshot,
                          draggableProvided.draggableProps.style,
                          editCellItem.objectId === additionalLine.objectId,
                        )}
                      >
                        <div
                          css={{
                            width: '100%',
                            borderBottom: additionalLine.underline
                              ? '1px solid'
                              : undefined,
                          }}
                        >
                          <div
                            css={valueStyle}
                            onMouseOver={() => this.onMouseOver(valueId)}
                            onMouseOut={() => this.onMouseOut(valueId)}
                            onDoubleClick={() => this.onDoubleClick(valueId)}
                            onMouseDown={() => this.onMouseDown(additionalLine)}
                          >
                            <Popover
                              content={
                                <textarea
                                  onContextMenu={(e) => e.stopPropagation()}
                                  fill
                                  ref={(ref) => {
                                    this[valueId] = ref;
                                  }}
                                  defaultValue={additionalLine.value}
                                  className="ignore-react-onclickoutside"
                                />
                              }
                              isOpen={this.state.showPopover === valueId}
                              onClose={() => {
                                if (
                                  additionalLine.value !== this[valueId].value
                                ) {
                                  additionalLine.value = this[valueId].value;
                                  const contractObject = additionalLine.getSourceObject();
                                  setTemplateState(
                                    new ContractObject(contractObject.toJSON()),
                                  );
                                }
                              }}
                            >
                              <div css={valueStyle}>{additionalLine.value}</div>
                            </Popover>
                          </div>
                        </div>
                        <div
                          css={noteStyle}
                          onMouseOver={() => this.onMouseOver(noteId)}
                          onMouseOut={() => this.onMouseOut(noteId)}
                          onDoubleClick={() => this.onDoubleClick(noteId)}
                          onMouseDown={() => this.onMouseDown(additionalLine)}
                        >
                          <Popover
                            content={
                              <TextArea
                                onContextMenu={(e) => e.stopPropagation()}
                                fill
                                inputRef={(ref) => {
                                  this[noteId] = ref;
                                }}
                                defaultValue={additionalLine.note}
                                className="ignore-react-onclickoutside"
                              />
                            }
                            isOpen={this.state.showPopover === noteId}
                            onClose={() => {
                              additionalLine.note = this[noteId].value;
                              const contractObject = additionalLine.getSourceObject();
                              setTemplateState(
                                new ContractObject(contractObject.toJSON()),
                              );
                            }}
                          >
                            <div css={noteStyle}>{additionalLine.note}</div>
                          </Popover>
                        </div>
                        {draggableProvided.placeholder}
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    );
  }
}

SignatureCell.propTypes = {
  setTemplateState: PropTypes.func.isRequired,
  setEditItem: PropTypes.func.isRequired,
  cell: PropTypes.instanceOf(SignatureItemObject).isRequired,
  draggableType: PropTypes.string,
  editCellItem: PropTypes.any,
};

SignatureCell.defaultProps = {
  draggableType: undefined,
  editCellItem: {},
};

const mapStateToProps = ({ templatesEdit }) => ({
  draggableType: templatesEdit.draggableType,
  editCellItem: templatesEdit.editCellItem,
});

const mapDispatchToProps = (dispatch) => ({
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
  setEditItem: (cellItem) => dispatch(setEditCellItem(cellItem)),
});

const wrappedComponent = onClickOutside(SignatureCell);
export default connect(mapStateToProps, mapDispatchToProps)(wrappedComponent);
