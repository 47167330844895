/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-shadow */
/* eslint-disable react/no-deprecated */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import { TextGroup } from '../FormGroup';
import { startUpdateConfig } from '../../actions/auth';
import Panel from '../Panel';
import TitleButton from '../TitleButton';
import Copy from '../AppSettings/Copy';
import { showDeleteConfirmation } from '../../actions/deleteConfirmation';

export class Mosaic extends React.Component {
  constructor(props) {
    super(props);
    const { mosaic = {} } = props;
    const { partnerId = '' } = mosaic;
    this.state = { partnerId };
  }

  componentDidMount() {
    const {
      setCrumbs,
      setButtons,
      location: { pathname },
    } = this.props;
    const crumb = { title: 'Mosaic', link: pathname };
    setCrumbs([crumb]);
    setButtons(
      <TitleButtons
        onClearClicked={this.askForClearPermission}
        onCopyClicked={this.onCopyClicked}
        onSaveClicked={this.onSaveClicked}
      />,
    );
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { mosaic = {} } = newProps;
    const { partnerId = '' } = mosaic;
    this.setState({ partnerId });
  }

  componentWillUnmount() {
    const { setCrumbs, setButtons } = this.props;
    setCrumbs([]);
    setButtons();
  }

  askForClearPermission = () => {
    this.props.showDeleteConfirmation({
      message: 'Are you sure you want to clear this integration?',
      title: 'Clear Integration?',
      onConfirm: () => {
        this.onClearClicked();
      },
    });
  };

  onClearClicked = () => {
    this.setState({ partnerId: '' });
  };

  onPartnerIdChanged = (value) => {
    this.setState({ partnerId: value });
  };

  onCopyClicked = () => {
    this.setState({ showCopy: true });
  };

  onSaveClicked = () => {
    const { partnerId } = this.state;
    const updates = { partnerId };
    this.props.startUpdateConfig({ mosaic: updates });
  };

  render() {
    return (
      <>
        <Copy
          title="Copy Mosaic Settings"
          show={this.state.showCopy}
          warning="Warning! This will overwrite your current Mosaic settings"
          configKeys={['mosaic']}
          onClose={() => this.setState({ showCopy: false })}
        />
        <Panel title="Mosaic">
          <TextGroup
            title="Mosaic Partner Id"
            value={this.state.partnerId}
            onChange={this.onPartnerIdChanged}
          />
        </Panel>
      </>
    );
  }
}

Mosaic.propTypes = {
  startUpdateConfig: PropTypes.func.isRequired,
  mosaic: PropTypes.shape({
    partnerId: PropTypes.string,
  }),
  ...layoutContextPropTypes,
};

Mosaic.defaultProps = {
  mosaic: {
    partnerId: '',
  },
};

const TitleButtons = ({
  onCopyClicked,
  onSaveClicked,
  onClearClicked,
  disableSave,
}) => (
  <>
    <TitleButton
      variant="warning"
      onClick={() => onClearClicked()}
      title="Clear"
    />
    <TitleButton
      variant="primary"
      onClick={() => onCopyClicked()}
      title="Copy"
    />
    <TitleButton
      disabled={disableSave}
      variant="success"
      onClick={() => onSaveClicked()}
      title="Save"
    />
  </>
);

TitleButtons.propTypes = {
  onCopyClicked: PropTypes.func.isRequired,
  onSaveClicked: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth: { config } }) => ({
  mosaic: config.mosaic,
});

const mapDispatchToProps = (dispatch) => ({
  startUpdateConfig: (updates) => dispatch(startUpdateConfig(updates)),
  showDeleteConfirmation: (params) => dispatch(showDeleteConfirmation(params)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(Mosaic),
);
