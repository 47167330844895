import React, { useContext, useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { LayoutContext } from '../Layout';
import PackagesEdit from './Edit';
import PackagesIndex from './IndexPage';

const Packages = () => {
  const { path } = useRouteMatch();
  const { setCrumbs } = useContext(LayoutContext);

  useEffect(() => {
    const crumb = { title: 'Packages', link: path };
    setCrumbs([crumb]);

    return () => {
      setCrumbs([]);
    };
  }, [path, setCrumbs]);

  return (
    <Switch>
      <Route
        path={[`${path}/edit/:id`, `${path}/new`]}
        component={PackagesEdit}
      />
      <Route path={path} component={PackagesIndex} exact />
    </Switch>
  );
};

export default Packages;
