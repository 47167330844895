/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';

const FilterSectionInputRow = (props) => {
  return (
    <div className="flex-1 filter-section-input-row d-flex m-3 align-items-center">
      <div className="filter-section-dropdown-title">
        <span className="list-line-action-item"> {props.title}</span>
      </div>
      <div className="flex-1 filter-section-dropdown-element-container">
        <input
          onKeyUp={(event) => {
            if (event.keyCode === 13 && props.onEnterPress) {
              props.onEnterPress();
            }
          }}
          onChange={props.onChange}
          value={props.value}
          className="form-control form-control-lg"
        />
      </div>
    </div>
  );
};

export default FilterSectionInputRow;
