export default (state = {}, { type, items = [], count = 0 }) => {
  switch (type) {
    case 'ADD_FINANCE_OPTIONS':
      return {
        items: [...state.items, ...items],
        count: state.count,
      };
    case 'SET_FINANCE_OPTIONS':
      return {
        items: [...items],
        count: state.count,
      };
    case 'SET_FINANCE_OPTIONS_COUNT':
      return {
        items: state.items || [],
        count,
      };
    case 'RESET_FINANCE_OPTIONS':
      return {};
    default:
      return state;
  }
};
