/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Spinner } from '@blueprintjs/core';

const BankAccountForm = (props) => {
  return (
    <form
      acceptCharset="UTF-8"
      style={{
        display:
          props.showForm && props.paymentType === 'Bank/eCheck'
            ? 'initial'
            : 'none',
      }}
      onSubmit={props.onSubmitBankAccount}
    >
      <input name="environment_key" type="hidden" value={props.apiKey} />
      <input name="redirect_url" type="hidden" value={props.redirect_url} />
      <input name="utf8" type="hidden" value="✓" />
      <div className="form-row">
        <div className="col-sm-6 mb-1">
          <label htmlFor="bank_routing_number" className="col-form-label">
            Full Name
          </label>
          <div>
            <input
              type="text"
              className="form-control form-control-lg"
              id="fullName"
              name="fullName"
            />
          </div>
        </div>
        <div className="col-sm-6 mb-1">
          <label htmlFor="bank_routing_number" className="col-form-label">
            Bank Routing Number
          </label>
          <div>
            <input
              type="text"
              className="form-control form-control-lg"
              id="bank_routing_number"
              name="bank_routing_number"
            />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-6 mb-1">
          <label htmlFor="bank_account_number" className="col-form-label">
            Bank Account Number
          </label>
          <div>
            <input
              type="text"
              className="form-control form-control-lg"
              id="bank_account_number"
              name="bank_account_number"
            />
          </div>
        </div>
        <div className="col-sm-6 mb-1">
          <label htmlFor="bank_account_type" className="col-form-label">
            Account Type
          </label>
          <div>
            <select
              className="form-control form-control-lg"
              id="bank_account_type"
              name="bank_account_type"
            >
              <option value="checking">Checking</option>
              <option value="savings">Savings</option>
            </select>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-6 mb-1">
          <label htmlFor="bank_account_holder_type" className="col-form-label">
            Account Holder Type
          </label>
          <div>
            <select
              className="form-control form-control-lg"
              id="bank_account_holder_type"
              name="bank_account_holder_type"
            >
              <option value="personal">Personal</option>
              <option value="business">Business</option>
            </select>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-12 center mt-4">
          <button
            type="submit"
            disabled={props.isSubmitting}
            className="btn btn-lg btn-primary btn-block"
          >
            {props.isSubmitting ? <Spinner size={21} /> : 'Submit'}
          </button>
          <div className="mt-2">{props.submitMessage}</div>
        </div>
      </div>
    </form>
  );
};

export default BankAccountForm;
