/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable react/button-has-type */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Parse from 'parse';
import {
  Classes,
  Menu,
  MenuItem,
  Popover,
  Button,
  Position,
  TextArea,
} from 'leap-menu-item';
import { ChromePicker } from 'react-color';
import { historyPropType } from '../../../router';
import AppToaster from '../../../utils/AppToaster';
import PlanActions from '../../../actions/plan';
import {
  setInitialStateForTemplate,
  setCurrentWorkingTemplate,
} from '../../../actions/templateEdit';
import { fileDataFromFileChange } from './PageButton';
import ContractObject from '../Models/ContractObject';
import { handleError } from '../../../actions/auth';
import { mainMenuOptionPropType } from '../../AppSettings/ContractSending/CustomOptions/CustomOptionEdit';
import YesNoMenuItem from '../Toolbox/YesNoMenuItem';
import SaveAsModal from './SaveAsModal';
import RestoreDocumentSnapshotModal from '../RestoreDocumentSnapshotModal';
import { pushToDataLayer } from '../../../actions/tagManager';
import { restoreDocumentSnapshot } from '../../../actions/documentSnapshots';
import DebounceInputWithNet from '../Toolbox/DebounceInputWithNet';

const acceptedThumbnailString = 'image/*';

const setNewPath = (oldType, newType) => {
  const newPath = window.location.pathname.replace(
    `/${oldType}/`,
    `/${newType}/`,
  );

  window.history.replaceState(newType, newType, newPath);
};

const iconBackgroundColorFromTemplate = (template) => {
  const { iconBackgroundColor = [0, 0, 0, 0] } = template;
  const [r, g, b, a] = iconBackgroundColor;
  return { r, g, b, a };
};

const iconBackgroundColorStyle = (template) => {
  const { r, g, b, a } = iconBackgroundColorFromTemplate(template);
  return {
    width: '30px',
    height: '20px',
    backgroundColor: `rgba(${r},${g},${b},${a})`,
    borderRadius: '4px',
    border: '1px solid #DEDEDE',
  };
};

const appSectionLabel = ({ type }, mainMenuOptions, getLabelByAnchor) => {
  switch (type) {
    case 'contract':
      return getLabelByAnchor({
        anchor: `templates/contract`,
        title: 'Contracts',
      });
    case 'proposal':
      return getLabelByAnchor({
        anchor: `templates/proposal`,
        title: 'Proposal',
      });
    default:
      break;
  }
  const { title = '' } =
    mainMenuOptions.find(({ objectId }) => objectId === type) || {};
  return title;
};

const includedStatesLabel = (template) => {
  const { includedStates = [] } = template;
  switch (includedStates.length) {
    case 0:
      return 'None';
    case 1:
      return includedStates[0];
    case 2:
      return `${includedStates[0]}, ${includedStates[1]}`;
    default:
      return `${includedStates.length}`;
  }
};
const MenuFix = () => (
  <MenuItem style={{ display: 'none' }}>
    <MenuItem />
  </MenuItem>
);

class FileButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showRestorModal: false,
      thumbnailIsOpen: undefined,
      categories: [],
    };
  }

  componentDidMount() {
    const getTemplateCategories = async () => {
      if (this.props.template) {
        const { type } = this.props.template;
        const categories = await Parse.Cloud.run('all_template_categories', {
          type,
        });
        this.setState({ categories });
      } else {
        const { type, id } = this.props.urlParams;
        if (id === 'new') {
          const templateId = `${type}_new`;
          this.props.setCurrentWorkingTemplate(templateId);
        }
      }
    };

    const loggedIn = !!Parse.User.current();
    if (loggedIn) {
      getTemplateCategories();
    }
  }

  onFileInputChanged = async (e) => {
    const newFile = await fileDataFromFileChange(e);
    this.props.template.iconImage = newFile;
    this.props.setTemplateState(this.props.template);
    this.setState({ thumbnailIsOpen: undefined });
  };

  removeThumbnail = () => {
    this.props.template.iconImage = undefined;
    this.props.setTemplateState(this.props.template);
    this.setState({ thumbnailIsOpen: undefined });
  };

  onSaveTemplate = async (contractObject) => {
    try {
      if (!contractObject.isTemplate) {
        this.props.pushToDataLayer({
          event: 'documentTemplateEvent',
          eventCategory: 'Documents',
          eventAction: 'Edit',
          eventLabel: contractObject.displayName,
        });

        const template = await contractObject.saveToServer();
        AppToaster.show({ message: 'Saved Successfully!', timeout: 3000 });
        this.props.setTemplateState(template);
        this.props.setCurrentWorkingTemplate(template.objectId);

        if (window.location.href.indexOf('new') > -1) {
          this.props.history.push(
            window.location.pathname.replace('new', template.objectId),
          );
        }
      }
    } catch (e) {
      this.props.handleError(e);
    }
  };

  onNameChange = (name) => {
    const { contractObject } = this.state;
    contractObject.displayName = name;
    this.setState(() => ({ contractObject }));
  };

  onSaveCopy = (contractObject) => {
    contractObject.displayName = `${contractObject.displayName} (Copy)`;
    this.setState(() => ({ showModal: true, contractObject }));
  };

  onCopySubmit = async () => {
    try {
      const { contractObject } = this.state;

      const object = contractObject;
      const fromTemplate = object.isTemplate;
      object.isTemplate = false;
      const copy = await contractObject.saveCopyToServer(fromTemplate);
      AppToaster.show({ message: 'Saved Successfully!', timeout: 3000 });
      if (contractObject.id === 'contract_new') {
        const original = await contractObject.revert();
        await this.props.setTemplateState(original);
      }
      await this.props.setTemplateState(copy);
      await this.props.setCurrentWorkingTemplate(copy.objectId);
      this.setState(() => ({ showModal: false, contractObject: undefined }));
      // window.location.href = `/templates/${copy.type}/${copy.objectId}`;
      this.props.history.push(`/templates/${copy.type}/${copy.objectId}`);

      // this.props.showSuccessAlert({
      //   title: 'Success!',
      //   message: `${copy.displayName} successfully saved`,
      //   onConfirm: () => {
      //     this.props.hideAlert();
      //   },
      // });
    } catch (e) {
      this.props.handleError(e);
    }
  };

  render() {
    const {
      template = {},
      setTemplateState,
      mainMenuOptions,
      offices,
      stateValues,
    } = this.props;

    let contractObject = {};
    if (template.toJSON) {
      contractObject = new ContractObject(template.toJSON());
    }
    const {
      includedStates = [],
      includedOffices = [],
      iconImage,
    } = contractObject;
    return (
      <div>
        <SaveAsModal
          show={this.state.showModal}
          onClose={() =>
            this.setState(() => ({
              showModal: false,
              contractObject: undefined,
            }))
          }
          object={this.state.contractObject}
          onNameChange={this.onNameChange}
          onSubmit={this.onCopySubmit}
        />
        <RestoreDocumentSnapshotModal
          show={this.state.showRestorModal}
          template={contractObject}
          onVersionSelection={(snapshot) => {
            this.setState({ showRestorModal: false });
            this.props.restoreDocumentSnapshot(snapshot.id);
          }}
          onHide={() => {
            this.setState({
              showRestorModal: false,
            });
          }}
        />
        <Popover
          content={
            <Menu className={Classes.ELEVATION_1}>
              <MenuItem
                icon="floppy-disk"
                text="Save"
                onClick={() => this.onSaveTemplate(contractObject)}
              />
              <MenuItem
                icon="floppy-disk"
                text="Save As New"
                onClick={() => this.onSaveCopy(contractObject)}
              />
              {contractObject.objectId !== 'contract_new' && (
                <MenuItem
                  onClick={() => {
                    this.setState({
                      showRestorModal: true,
                    });
                  }}
                  icon="refresh"
                  text="Restore From Previous Save"
                />
              )}
              <Menu.Divider />
              <MenuFix />
              <MenuItem
                icon=""
                text="Category"
                label={contractObject.category}
                shouldDismissPopover={false}
              >
                <DebounceInputWithNet
                  element={TextArea}
                  minLength={0}
                  debounceTimeout={300}
                  value={contractObject.category}
                  onChange={(e) => {
                    contractObject.category = e.target.value;
                    setTemplateState(contractObject);
                  }}
                />
                {this.state.categories.length > 0 && <Menu.Divider />}
                {this.state.categories.map((category) => (
                  <MenuItem
                    key={category}
                    text={category}
                    shouldDismissPopover={false}
                    active={contractObject.category === category}
                    icon={
                      contractObject.category === category ? 'dot' : 'blank'
                    }
                    onClick={() => {
                      contractObject.category = category;
                      setTemplateState(contractObject);
                    }}
                  />
                ))}
              </MenuItem>
              <MenuItem
                icon=""
                text="Name"
                label={contractObject.displayName}
                shouldDismissPopover={false}
              >
                <DebounceInputWithNet
                  element={TextArea}
                  minLength={0}
                  debounceTimeout={300}
                  value={contractObject.displayName}
                  onChange={(e) => {
                    contractObject.displayName = e.target.value;
                    setTemplateState(contractObject);
                  }}
                />
              </MenuItem>
              <Menu.Divider />
              <MenuItem
                icon="globe"
                text="Included States"
                label={includedStatesLabel(contractObject)}
                shouldDismissPopover={false}
              >
                <MenuItem
                  icon={
                    includedStates.length === stateValues.length
                      ? 'dot'
                      : 'blank'
                  }
                  text="Select All"
                  shouldDismissPopover={false}
                  active={includedStates.length === stateValues.length}
                  onClick={() => {
                    if (includedStates.length === stateValues.length) {
                      contractObject.includedStates = [];
                    } else {
                      contractObject.includedStates = stateValues;
                    }
                    setTemplateState(contractObject);
                  }}
                />
                <Menu.Divider />
                {stateValues.map((stateValue) => (
                  <MenuItem
                    key={`state-${stateValue}`}
                    icon={
                      includedStates.indexOf(stateValue) > -1 ? 'dot' : 'blank'
                    }
                    text={stateValue}
                    shouldDismissPopover={false}
                    active={includedStates.indexOf(stateValue) > -1}
                    onClick={() => {
                      const updatedStates = includedStates.filter(
                        (value) => value !== stateValue,
                      );
                      if (includedStates.indexOf(stateValue) === -1) {
                        updatedStates.push(stateValue);
                      }
                      contractObject.includedStates = updatedStates;
                      setTemplateState(contractObject);
                    }}
                  />
                ))}
              </MenuItem>
              {this.props.maxOfficeCount !== 1 && (
                <MenuItem
                  icon="map"
                  text="Included Offices"
                  label={includedOffices.length}
                >
                  <MenuItem
                    icon={
                      includedOffices.length === offices.length
                        ? 'dot'
                        : 'blank'
                    }
                    text="Select All"
                    shouldDismissPopover={false}
                    active={includedOffices.length === offices.length}
                    onClick={() => {
                      if (includedOffices.length === offices.length) {
                        contractObject.includedOffices = [];
                      } else {
                        contractObject.includedOffices = offices.map((office) =>
                          office.toPointer(),
                        );
                      }
                      setTemplateState(contractObject);
                    }}
                  />
                  <Menu.Divider />
                  {offices.map((office) => (
                    <MenuItem
                      key={office.id}
                      icon={
                        includedOffices.find(
                          ({ id, objectId }) =>
                            office.id === id || office.id === objectId,
                        )
                          ? 'dot'
                          : 'blank'
                      }
                      active={
                        !!includedOffices.find(
                          ({ id, objectId }) =>
                            office.id === id || office.id === objectId,
                        )
                      }
                      shouldDismissPopover={false}
                      text={office.get('name')}
                      onClick={() => {
                        const updatedOffices = includedOffices.filter(
                          ({ id, objectId }) =>
                            !(id === office.id || office.id === objectId),
                        );
                        if (
                          !includedOffices.find(
                            ({ id, objectId }) =>
                              id === office.id || office.id === objectId,
                          )
                        ) {
                          updatedOffices.push(office.toPointer());
                        }
                        contractObject.includedOffices = updatedOffices;
                        setTemplateState(contractObject);
                      }}
                    />
                  ))}
                </MenuItem>
              )}
              <Menu.Divider />
              <YesNoMenuItem
                text="Allow Multiple"
                active={template.canAddMultiplePages}
                onChange={(value) => {
                  contractObject.canAddMultiplePages = value;
                  setTemplateState(contractObject);
                }}
              />
              <Menu.Divider />
              <MenuItem
                icon="media"
                text="Thumbnail"
                shouldDismissPopover={false}
                popoverProps={{ isOpen: this.state.thumbnailIsOpen }}
              >
                <div style={{ cursor: 'pointer' }}>
                  <img
                    alt="Thumbnail"
                    src={
                      iconImage
                        ? iconImage.url() || '/images/no_image.png'
                        : '/images/no_image.png'
                    }
                    style={{
                      maxWidth: '200px',
                      maxHeight: '200px',
                      cursor: 'pointer',
                    }}
                  />
                  <input
                    accept={acceptedThumbnailString}
                    type="file"
                    onChange={this.onFileInputChanged}
                    onClick={() => this.setState({ thumbnailIsOpen: true })}
                    style={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      left: 0,
                      top: 0,
                      opacity: 0,
                      cursor: 'pointer',
                    }}
                  />
                  <button
                    onClick={() => this.removeThumbnail()}
                    style={{
                      background: 'transparent',
                      border: 0,
                      position: 'absolute',
                      right: '0px',
                      top: '5px',
                    }}
                  >
                    <i className="fas fa-times-circle fa-2x danger" />
                  </button>
                </div>
              </MenuItem>
              <MenuItem
                icon="layout-grid"
                text="App Section"
                label={appSectionLabel(
                  contractObject,
                  mainMenuOptions,
                  this.props.getLabelByAnchor,
                )}
              >
                <Menu.Divider title="App Section" />
                <MenuItem
                  icon={contractObject.type === 'contract' ? 'dot' : 'blank'}
                  text={this.props.getLabelByAnchor({
                    anchor: `templates/contract`,
                    title: 'Contracts',
                  })}
                  shouldDismissPopover={false}
                  active={contractObject.type === 'contract'}
                  onClick={() => {
                    setNewPath(contractObject.type, 'contract');
                    contractObject.type = 'contract';
                    setTemplateState(contractObject);
                  }}
                />
                {this.props.maxProposalTemplateCount !== 0 && (
                  <MenuItem
                    icon={contractObject.type === 'proposal' ? 'dot' : 'blank'}
                    text={this.props.getLabelByAnchor({
                      anchor: `templates/proposals`,
                      title: 'Proposals',
                    })}
                    shouldDismissPopover={false}
                    active={contractObject.type === 'proposal'}
                    onClick={() => {
                      setNewPath(contractObject.type, 'proposal');
                      contractObject.type = 'proposal';
                      setTemplateState(contractObject);
                    }}
                  />
                )}
                {mainMenuOptions.map(({ title, objectId }) => (
                  <MenuItem
                    key={objectId}
                    icon={contractObject.type === objectId ? 'dot' : 'blank'}
                    text={title}
                    shouldDismissPopover={false}
                    active={contractObject.type === objectId}
                    onClick={() => {
                      setNewPath(contractObject.type, objectId);
                      contractObject.type = objectId;
                      setTemplateState(contractObject);
                    }}
                  />
                ))}
              </MenuItem>
              <MenuItem
                icon="tint"
                text="Icon Color"
                labelElement={
                  <div style={iconBackgroundColorStyle(contractObject)} />
                }
              >
                <Menu.Divider title="Icon Color" />
                <ChromePicker
                  color={iconBackgroundColorFromTemplate(contractObject)}
                  onChange={({ rgb: { r, g, b, a } }) => {
                    contractObject.iconBackgroundColor = [r, g, b, a];
                    setTemplateState(contractObject);
                  }}
                />
              </MenuItem>
            </Menu>
          }
          position={Position.BOTTOM}
        >
          <Button
            onClick={(event) => {
              const { parentNode } = event.target;
              const { className } = parentNode;
              const open = !(
                className.indexOf('open') > -1 ||
                className.indexOf('active') > -1
              );
              if (open) {
                this.props.pushToDataLayer({
                  event: 'contractMenuEvent',
                  eventCategory: 'Documents',
                  eventAction: 'Menu Open',
                  eventLabel: 'File Button',
                });
              }
            }}
            className={Classes.MINIMAL}
            text="File"
          />
        </Popover>
      </div>
    );
  }
}

FileButton.propTypes = {
  urlParams: PropTypes.shape({
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  maxOfficeCount: PropTypes.number.isRequired,
  getLabelByAnchor: PropTypes.func.isRequired,
  template: PropTypes.instanceOf(ContractObject),
  offices: PropTypes.arrayOf(PropTypes.instanceOf(Parse.Object)),
  setTemplateState: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  pushToDataLayer: PropTypes.func.isRequired,
  mainMenuOptions: PropTypes.arrayOf(mainMenuOptionPropType),
  setCurrentWorkingTemplate: PropTypes.func.isRequired,
  restoreDocumentSnapshot: PropTypes.func.isRequired,
  stateValues: PropTypes.arrayOf(PropTypes.string),
  history: historyPropType.isRequired,
  maxProposalTemplateCount: PropTypes.number.isRequired,
};

FileButton.defaultProps = {
  offices: [],
  mainMenuOptions: [],
  template: undefined,
  stateValues: [],
};

const mapStateToProps = ({
  templatesEdit,
  auth: { config: { mainMenuOptions = [] } = {}, offices = [] } = {},
  config,
  plan = {},
}) => ({
  template: templatesEdit[templatesEdit.currentWorkingId],
  mainMenuOptions,
  offices,
  stateValues: config.states,
  maxOfficeCount: plan.maxOfficeCount,
  maxProposalTemplateCount: plan.maxProposalTemplateCount,
});

const mapDispatchToProps = (dispatch) => ({
  getLabelByAnchor: (feature) =>
    dispatch(PlanActions.getLabelByAnchor(feature)),
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
  setCurrentWorkingTemplate: (objectId) =>
    dispatch(setCurrentWorkingTemplate(objectId)),
  handleError: (error) => dispatch(handleError(error)),
  pushToDataLayer: (variablesForLayer) =>
    dispatch(pushToDataLayer(variablesForLayer)),
  restoreDocumentSnapshot: (objectId) =>
    dispatch(restoreDocumentSnapshot(objectId)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FileButton),
);
