/* eslint-disable react/prop-types */
import React from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Classes, Menu, MenuItem, TextArea } from 'leap-menu-item';
import { setInitialStateForTemplate } from '../../../actions/templateEdit';
import ContractObject from '../Models/ContractObject';
import DebounceInputWithNet from './DebounceInputWithNet';

const borderMap = {
  0: 'None',
  1: 'Outside',
  2: 'Inside & Outside',
  3: 'Top & Bottom',
};

const borders = [0, 1, 2, 3];

const Header = ({ templateValues, editCellItem, setTemplateState }) => {
  const contractObject = new ContractObject(templateValues.toJSON());
  const updatedObject =
    contractObject.getObjectWithId(editCellItem.objectId) || {};
  return (
    <Menu className={Classes.ELEVATION_1}>
      <MenuItem text="App Title">
        <DebounceInputWithNet
          element={TextArea}
          minLength={0}
          debounceTimeout={300}
          value={updatedObject.appTitle}
          onChange={(e) => {
            updatedObject.appTitle = e.target.value;
            setTemplateState(contractObject);
          }}
        />
      </MenuItem>
      <MenuItem text="PDF Title">
        <DebounceInputWithNet
          element={TextArea}
          minLength={0}
          debounceTimeout={300}
          value={updatedObject.title}
          onChange={(e) => {
            updatedObject.title = e.target.value;
            setTemplateState(contractObject);
          }}
        />
      </MenuItem>
      <MenuItem text="Border">
        {borders.map((border) => (
          <MenuItem
            key={`border-${border}`}
            text={borderMap[border]}
            active={updatedObject.border === border}
            onClick={() => {
              updatedObject.border = border;
              setTemplateState(contractObject);
            }}
          />
        ))}
      </MenuItem>
    </Menu>
  );
};

const mapStateToProps = ({ templatesEdit = {} }) => ({
  templateValues: templatesEdit[templatesEdit.currentWorkingId],
  editCellItem: templatesEdit.editCellItem,
});

const mapDispatchToProps = (dispatch) => ({
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
