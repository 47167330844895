/* eslint-disable consistent-return */
import store from '../../store/configureStore';

let isScrolling = null;

export default class TemplateScrollContext {
  sectionData = {};

  sections = [];

  expectedSections = {
    count: 0,
  };

  setMainRef = (mainTemplatesRef, update) => {
    this.mainTemplatesRef = mainTemplatesRef;
    this.update = () => {
      update({});
    };
    this.mainTemplatesRef.onscroll = (event) => {
      const { isDragging } = store.getState().templatesEdit;
      if (!isDragging) {
        window.clearTimeout(isScrolling);
        isScrolling = setTimeout(() => {
          this.pageContract();
        }, 100);
      }
    };
    this.pageContract();
  };

  addSectionRef = (section, ref) => {
    if (!this.sectionData[section.objectId]) {
      this.sections.push(section);
    }
    this.sectionData[section.objectId] = {
      ...this.sectionData[section.objectId],
      ref,
    };
    if (this.expectedSections.initCount && !this.initSet) {
      // eslint-disable-next-line no-plusplus
      this.expectedSections.count++;
      if (this.expectedSections.initCount === this.expectedSections.count) {
        this.expectedSections.initCount = null;
        setTimeout(() => {
          if (this.update && !this.initSet) {
            this.pageContract();
          }
          this.initSet = true;
        }, 250);
      }
    }
  };

  inBounds = (number, topBound = 0, bottomBound, space = 0) => {
    return number < bottomBound + space && number > topBound - space;
  };

  getBound = (section) => {
    const data = this.sectionData[section.objectId];
    if (data && data.rect) {
      return data;
    }
    return { inBound: true };
  };

  calcSectionPage = (sectionRef) => {
    if (sectionRef && this.mainTemplatesRef) {
      const rect = sectionRef.getBoundingClientRect();
      const parentRect = this.mainTemplatesRef.getBoundingClientRect();
      let top = this.inBounds(rect.top, parentRect.top, parentRect.bottom, 100);
      let bottom = this.inBounds(
        rect.bottom,
        parentRect.top,
        parentRect.bottom,
        100,
      );
      if (rect.height > parentRect.height) {
        top = this.inBounds(parentRect.top, rect.top, rect.bottom, 100);
        bottom = this.inBounds(parentRect.bottom, rect.top, rect.bottom, 100);
      }
      if (!(top || bottom)) {
        return { rect, ref: sectionRef, inBound: false };
      }
      return { rect, ref: sectionRef, inBound: true };
    }
  };

  pageContract = () => {
    this.sections.forEach((section) => {
      const { ref } = this.sectionData[section.objectId];
      const data = this.calcSectionPage(ref);
      this.sectionData[section.objectId] = data;
    });
    this.update();
  };
}
