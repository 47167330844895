/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { TextGroup, TagGroup } from '../../../FormGroup';
import Panel from '../../../Panel';

const JobNimbusSection = (props) => (
  <Panel title="JobNimbus">
    <div>
      <TextGroup
        title="File Name"
        value={props.value.fileName}
        placeholder="File Name"
        onChange={(value) =>
          props.onChange({
            key: 'jobNimbus',
            value: {
              ...props.value,
              fileName: value,
            },
          })
        }
        errorMessage={props.errors.jobNimbus.fileName}
      />
      <TextGroup
        title="File Description"
        value={props.value.fileDescription}
        placeholder="File Description"
        onChange={(value) =>
          props.onChange({
            key: 'jobNimbus',
            value: {
              ...props.value,
              fileDescription: value,
            },
          })
        }
        errorMessage={props.errors.jobNimbus.fileDescription}
      />
      <TagGroup
        title="Backup Recipients"
        values={props.value.backupRecipients}
        placeholder="Add Email Addresses"
        onChange={(value) =>
          props.onChange({
            key: 'jobNimbus',
            value: {
              ...props.value,
              backupRecipients: value,
            },
          })
        }
        errorMessage={props.errors.jobNimbus.backupRecipients}
      />
    </div>
  </Panel>
);

JobNimbusSection.propTypes = {
  value: PropTypes.shape({
    fileName: PropTypes.string,
    fileDescription: PropTypes.string,
    backupRecipients: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    jobNimbus: PropTypes.shape({
      fileName: PropTypes.string,
      fileDescription: PropTypes.string,
      backupRecipients: PropTypes.string,
    }).isRequired,
  }),
};

JobNimbusSection.defaultProps = {
  errors: {
    jobNimbus: {
      fileName: '',
      fileDescription: '',
      backupRecipients: '',
    },
  },
};

export default JobNimbusSection;
