/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import Parse from 'parse';
import { Textfit } from 'react-textfit';
import {
  Popover,
  Switch,
  FormGroup,
  InputGroup,
  Alignment,
  FileInput,
} from 'leap-menu-item';
import ImageObject from '../Models/ImageObject';
import { newFileFromEvent } from '../../../utils/utils';

class ImagePickerCell extends React.Component {
  constructor(props) {
    super(props);
    this.titleRef = React.createRef();
    this.valueRef = React.createRef();
    this.defaultRef = React.createRef();
    this.state = { showPopover: false };
  }

  onDoubleClick = () => {
    this.setState({ showPopover: true });
  };

  updateThumnail = async (e) => {
    const parsefile = await newFileFromEvent(e);
    this.props.imageObject.file = parsefile;
    this.props.onChange(this.props.imageObject);
  };

  render() {
    const { title, value, file, id } = this.props.imageObject;
    let fileURL = file ? file.url : '/images/no_image.png';
    if (file instanceof Parse.File) {
      fileURL = file.url();
    }
    return (
      <Popover
        content={
          <div style={{ padding: 8 }}>
            <FormGroup label="Title" labelFor="title-input">
              <InputGroup
                id="title-input"
                inputRef={this.titleRef}
                defaultValue={title}
              />
            </FormGroup>
            <FormGroup label="Value" labelFor="value-input">
              <InputGroup
                id="value-input"
                inputRef={this.valueRef}
                defaultValue={value}
              />
            </FormGroup>
            <FormGroup label="Image" labelFor="file-input">
              <FileInput
                text="Image"
                inputProps={{ id: 'file-input', accept: 'image/*' }}
                onInputChange={this.updateThumnail}
              />
            </FormGroup>
            <Switch
              alignIndicator={Alignment.RIGHT}
              defaultChecked={this.props.isDefault}
              label="Make Default"
              inputRef={this.defaultRef}
              onChange={(e) => {
                this.props.onDefaultChanged(id, e.target.checked);
              }}
            />
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => this.props.onDelete({})}
            >
              {' '}
              delete{' '}
            </button>
          </div>
        }
        isOpen={this.state.showPopover}
        onClose={() => {
          this.setState({ showPopover: false });
          this.props.imageObject.title = this.titleRef.current.value;
          this.props.imageObject.value = this.valueRef.current.value;
          this.props.onChange(this.props.imageObject);
        }}
      >
        <div
          onContextMenu={(e) => e.stopPropagation()}
          className={`templates-app__image_selector_tile${
            this.props.isDefault ? '_default' : ''
          }`}
          onDoubleClick={this.onDoubleClick}
        >
          <img
            onContextMenu={(e) => e.stopPropagation()}
            src={fileURL || '/images/no_image.png'}
            className="templates-app__image_selector_image"
            alt="template selector"
          />
          <Textfit mode="single" max={17}>
            <div className="templates-app__image_selector_label">{value}</div>
          </Textfit>
        </div>
      </Popover>
    );
  }
}

ImagePickerCell.propTypes = {
  imageObject: PropTypes.instanceOf(ImageObject).isRequired,
  isDefault: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onDefaultChanged: PropTypes.func.isRequired,
};

ImagePickerCell.defaultProps = {
  isDefault: false,
};

export default ImagePickerCell;
