/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react';

const AddressList = (props) => {
  const { list = [] } = props;
  return (
    <div className="addressList">
      {list.map((item, i) => {
        const items = item.value.split('\n');
        const itemsCount = items.length;
        return (
          <address key={i}>
            {items.map((itemInner, itemKey) => {
              if (itemKey < itemsCount - 1) {
                return (
                  <React.Fragment key={`${i}_${itemKey}`}>
                    {itemInner}
                    <br />
                  </React.Fragment>
                );
              }
              return (
                <React.Fragment key={`${i}_${itemKey}`}>
                  {itemInner}
                </React.Fragment>
              );
            })}
          </address>
        );
      })}
    </div>
  );
};

export default AddressList;
