/* eslint-disable react/button-has-type */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import HeaderCell from './HeaderCell';
import ExpandAllCell from '../ExpandAllCell';

const headerToolTips = {
  save: {
    title: 'Save',
    message: '',
  },
  reorder: {
    title: 'Reorder',
    message: 'Drag and drop to reorder rows',
  },
  refresh: {
    title: 'Refresh',
    message: 'Undo all changes on a row',
  },
  add: {
    title: 'Add',
    message:
      'Add or Copy/Paste new Measure Sheet Items, Price Guide Options or Up-Charges',
  },
  settings: {
    title: 'Settings',
    message: 'Edit a row from a form view',
  },
  additionalDetails: {
    title: 'Additional Details',
    message:
      'Additional inputs that capture information about items added to the estimate',
  },
  placeholders: {
    title: 'Placeholders',
    message:
      'Custom placeholders that can be used when linking items to a template',
  },
  showToggle: {
    title: 'Show Toggle',
    message: 'Allow the user to add a quantity of 1 or 0 via a toggle switch',
  },
  image: {
    title: 'Image',
    message: 'Thumbnail image',
  },
};

const MeasureSheetItemHeader = ({
  onSaveAll,
  saveAllDisabled,
  onExpandAll,
  isAllExpanded,
  onRefresh,
  isRefreshDisabled,
  maxOfficeCount,
  onAdd,
}) => (
  <div className="priceguide__msi_header-container">
    <ExpandAllCell
      onClick={() => onExpandAll(!isAllExpanded)}
      isOpen={isAllExpanded}
      type="item-header"
    />
    <HeaderCell
      popoverTitle={headerToolTips.reorder.title}
      popoverBody={headerToolTips.reorder.message}
      disableResize
    >
      <i className="fas fa-grip-lines" />
    </HeaderCell>
    <HeaderCell
      popoverTitle={headerToolTips.save.title}
      popoverBody={headerToolTips.save.message}
      disableResize
    >
      <button
        className="priceguide__button"
        onClick={onSaveAll}
        disabled={saveAllDisabled}
      >
        <i className="far fa-save" />
      </button>
    </HeaderCell>
    <HeaderCell
      popoverTitle={headerToolTips.refresh.title}
      popoverBody={headerToolTips.refresh.message}
      disableResize
    >
      <button
        className="priceguide__button"
        onClick={onRefresh}
        disabled={isRefreshDisabled}
      >
        <i className="fas fa-undo-alt" />
      </button>
    </HeaderCell>
    <HeaderCell
      columnKey="add"
      popoverTitle={headerToolTips.add.title}
      popoverBody={headerToolTips.add.message}
      disableResize
    >
      <button className="priceguide__button" onClick={() => onAdd()}>
        <i className="fas fa-plus-circle" />
      </button>
    </HeaderCell>
    <HeaderCell
      columnKey="settings"
      popoverTitle={headerToolTips.settings.title}
      popoverBody={headerToolTips.settings.message}
      disableResize
    >
      <i className="fas fa-cog" />
    </HeaderCell>
    <HeaderCell
      columnKey="additionalDetails"
      popoverTitle={headerToolTips.additionalDetails.title}
      popoverBody={headerToolTips.additionalDetails.message}
      disableResize
    >
      <i className="fas fa-keyboard" />
    </HeaderCell>
    <HeaderCell
      columnKey="placeholders"
      popoverTitle={headerToolTips.placeholders.title}
      popoverBody={headerToolTips.placeholders.message}
      disableResize
    >
      <i className="fas fa-retweet" />
    </HeaderCell>
    <HeaderCell
      columnKey="showToggle"
      popoverTitle={headerToolTips.showToggle.title}
      popoverBody={headerToolTips.showToggle.message}
      disableResize
    >
      <i className="fas fa-toggle-on" />
    </HeaderCell>
    <HeaderCell
      columnKey="image"
      popoverTitle={headerToolTips.image.title}
      popoverBody={headerToolTips.image.message}
      disableResize
      thumbnail
      style={{ width: 60 }}
    >
      <i className="fas fa-image" />
    </HeaderCell>
    <HeaderCell columnKey="category">Category</HeaderCell>
    <HeaderCell columnKey="subCategory">Sub Category</HeaderCell>
    <HeaderCell columnKey="subSubCategories">Drill-Downs</HeaderCell>
    <HeaderCell columnKey="name">Name</HeaderCell>
    <HeaderCell columnKey="note">Note</HeaderCell>
    <HeaderCell columnKey="measurementType">Measurement</HeaderCell>
    {maxOfficeCount !== 1 && (
      <HeaderCell columnKey="offices" style={{ minWidth: '200px' }}>
        Offices
      </HeaderCell>
    )}
    <HeaderCell columnKey="formulaId">Formula ID</HeaderCell>
    <HeaderCell columnKey="formula">Formula</HeaderCell>
  </div>
);

MeasureSheetItemHeader.propTypes = {
  onSaveAll: PropTypes.func.isRequired,
  saveAllDisabled: PropTypes.bool,
  onExpandAll: PropTypes.func.isRequired,
  isAllExpanded: PropTypes.bool,
  onRefresh: PropTypes.func.isRequired,
  isRefreshDisabled: PropTypes.bool,
  onAdd: PropTypes.func,
  maxOfficeCount: PropTypes.number.isRequired,
};

MeasureSheetItemHeader.defaultProps = {
  saveAllDisabled: false,
  isAllExpanded: false,
  isRefreshDisabled: false,
  onAdd: undefined,
};

export default MeasureSheetItemHeader;
