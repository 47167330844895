/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

export const LogObjectSection = (props) => {
  const { logObjects = [] } = props;

  const history = useHistory();

  return (
    <div className="logs-section col-lg-8 offset-lg-2">
      <h2 className="logs-header">Log Files</h2>
      <table className="logs-table table table-striped table-sm table-bordered">
        <thead>
          <tr>
            <th className="text-center list-line-header">Date</th>
            <th className="text-center list-line-header"> User</th>
          </tr>
        </thead>
        <tbody>
          {logObjects.map((log, i) => {
            const { date, createdBy } = log;
            return (
              <tr
                onClick={() => {
                  const fileLogsLink = history.location.pathname.replace(
                    'files',
                    `file-logs/${log.id}`,
                  );

                  history.push(fileLogsLink);
                }}
                key={i}
                className={` ${
                  log.key_metric ? 'table-primary logs-table-keymetric' : ''
                }`}
              >
                <td className="text-center cursor-pointer">{date}</td>
                <td className="text-center cursor-pointer">{createdBy}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

LogObjectSection.propTypes = {
  logObjects: PropTypes.arrayOf(PropTypes.shape({})),
};

LogObjectSection.defaultProps = {
  logObjects: [],
};
