/* eslint-disable react-hooks/exhaustive-deps */
// @ts-check
import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Button } from 'react-bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Droppable } from 'react-beautiful-dnd';
import store from '../../store/configureStore';
import { LayoutContext } from '../Layout';
import Paginator from '../Misc/Paginator';
import {
  setResources,
  startDeleteResource,
  setIncludedOfficesFilter,
  updateResourcesOrder,
  updateItemFolder,
  fireSortResources,
  startQueryResourcesOptions,
} from '../../actions/resources';
import { setOnDragEndCallback } from '../DropContext';
import FormGroup from '../FormGroup';
import OfficesDropDown from '../IncludedOfficesDropDown';
import ResourceCell from './ResourceCell';
import TitleButton from '../TitleButton';
import DragGrid from './DragGrid';
import { showDeleteConfirmation } from '../../actions/deleteConfirmation';

// Selectors
const resourcesSelector = (
  { resources: { items = [], resourcesTotalCount = 0 } } = {
    resources: {
      items: [],
      resourcesTotalCount: 0,
    },
  },
) => ({
  items,
  resourcesTotalCount,
});

const maxOfficeCountSelector = (
  { plan: { maxOfficeCount = 1 } = { maxOfficeCount: 1 } } = {
    plan: { maxOfficeCount: 1 },
  },
) => maxOfficeCount;

// Hook to use URL query params from react-router
const useQuery = () => new URLSearchParams(useLocation().search);

const prevQuery = { current: undefined };
/**
 * pass in query params for the redux action to get resources
 * (PitchBookObject in Parse)
 * @param {string[]} offices
 * @param {string} folderName
 * @param {number} page
 * @param {number} limit
 */
const queryResources = (
  offices = [],
  folderName = '',
  page = 1,
  limit = 25,
) => {
  const querystring =
    offices.join() + folderName + page.toString() + limit.toString();
  if (!_.isEqual(querystring, prevQuery.current)) {
    prevQuery.current = querystring;
    const skip = limit * (page - 1);
    store.dispatch(
      startQueryResourcesOptions({
        folderName,
        limit,
        skip,
        includedOffices: offices,
      }),
    );
  }
};

// Component
const ResourcesIndex = () => {
  // React Router Hooks to get state info stored in url
  const { pathname } = useLocation();
  const history = useHistory();
  const query = useQuery();
  const folderName = pathname
    .split('/')
    .filter((path) => path !== 'resources' && path !== '')
    .join('/');
  const officesList = query.get('includedOffices');
  const includedOffices =
    typeof officesList === 'string'
      ? officesList.split(',').filter((id) => id)
      : [];

  // Layout Context
  const { crumbs, setButtons, setCrumbs } = useContext(LayoutContext);

  // Redux Store
  const dispatch = useDispatch();
  const { items, resourcesTotalCount } = useSelector(resourcesSelector);
  const maxOfficeCount = useSelector(maxOfficeCountSelector);

  // Component State
  const [
    {
      pagination: { page, limit },
      resourcesToDelete,
    },
    updateState,
  ] = useState({
    pagination: {
      page: 1,
      limit: 25,
    },
    resourcesToDelete: undefined,
  });
  const setState = (partialState) => {
    updateState((oldState) => ({
      ...oldState,
      ...partialState,
    }));
  };

  // On Component mount

  useEffect(() => {
    queryResources(includedOffices, folderName, page, limit);
  }, []);

  useEffect(() => {
    setOnDragEndCallback('parent-folder-drop', (result) => {
      const { destination = {} } = result;
      if (!destination || destination.droppableId !== 'parent-folder-drop') {
        return;
      }
      const [resourceId] = result.draggableId.split('-').reverse();

      const [resource] = items.filter((item) => item.objectId === resourceId);

      const { displayName, pathDepth } = resource;

      const path = displayName.split('/');

      if (pathDepth === 0) {
        return;
      }

      const newPath = path
        .reverse()
        .reduce((pathRun, segment, i) => {
          if (i === 1) {
            return [...pathRun];
          }
          return [...pathRun, segment];
        }, [])
        .reverse();

      const itemDisplayNameUpdate = newPath
        .reduce((name, segment) => `${name}/${segment}`, '')
        .replace('/', '');

      dispatch(
        setResources(
          items.filter((item) => item.objectId !== resource.objectId),
        ),
      );
      dispatch(
        updateItemFolder(resource, itemDisplayNameUpdate, () =>
          queryResources(includedOffices, folderName, page, limit),
        ),
      );
    });

    setOnDragEndCallback('parent-folder-drop', (result) => {
      const { destination = {} } = result;
      if (!destination || destination.droppableId !== 'parent-folder-drop') {
        return;
      }
      const [resourceId] = result.draggableId.split('-').reverse();

      const [resource] = items.filter((item) => item.objectId === resourceId);

      const { displayName, pathDepth } = resource;

      const path = displayName.split('/');

      if (pathDepth === 0) {
        return;
      }

      const newPath = path
        .reverse()
        .reduce((pathRun, segment, i) => {
          if (i === 1) {
            return [...pathRun];
          }
          return [...pathRun, segment];
        }, [])
        .reverse();

      const itemDisplayNameUpdate = newPath
        .reduce((name, segment) => `${name}/${segment}`, '')
        .replace('/', '');

      dispatch(
        setResources(
          items.filter((item) => item.objectId !== resource.objectId),
        ),
      );
      dispatch(
        updateItemFolder(resource, itemDisplayNameUpdate, () =>
          queryResources(includedOffices, folderName, page, limit),
        ),
      );
    });
  }, [items, includedOffices, folderName, page, limit]);

  useEffect(() => {
    const saveResourcesOrder = () => {
      dispatch(updateResourcesOrder({ items }));
    };

    setButtons(
      <>
        <TitleButton
          variant="secondary"
          onClick={onResourceLibraryClicked}
          title="Shared Resources"
        />
        <TitleButton
          variant="warning"
          onClick={onSortResources}
          title="Sort Alphabetically"
        />
        <TitleButton variant="success" onClick={onAddClicked} title="New" />
        <TitleButton
          variant="success"
          onClick={saveResourcesOrder}
          title="Save Order"
        />
      </>,
    );

    return () => {
      setButtons();
    };
  }, [items, page, limit]);

  // When included offices changes
  useEffect(() => {
    const newCrumbs = crumbs.map((crumb) => {
      const newCrumb = { ...crumb };
      newCrumb.link = `${crumb.link.split('?')[0]}?${query.toString()}`;
      return newCrumb;
    });
    setCrumbs(newCrumbs);
    queryResources(includedOffices, folderName, page, limit);
  }, [officesList]);

  // When the folder name changes
  useEffect(() => {
    const pathArr = pathname.split('/').filter((p) => !!p);
    const newCrumbs = [...new Array(pathArr.length)];
    for (let i = 0; i < pathArr.length; i++) {
      newCrumbs[i] = {
        title: i === 0 ? 'Resources' : pathArr[i],
        link: `${i > 0 ? newCrumbs[i - 1].link : ''}/${pathArr[i]}${
          i < pathArr.length ? '' : '/'
        }`,
      };
    }
    for (let i = 0; i < newCrumbs.length; i++) {
      newCrumbs[i] = {
        ...newCrumbs[i],
        link: `${newCrumbs[i].link}?${query.toString()}`,
      };
    }
    setCrumbs(newCrumbs);
    queryResources(includedOffices, folderName, page, limit);
  }, [folderName]);

  const onPagenation = (paginationEvent) => {
    setState({
      pagination: {
        page,
        limit,
        ...paginationEvent,
      },
    });
    queryResources(includedOffices, folderName, page, limit);
  };

  const onSortResources = () => {
    const skip = limit * (page - 1);
    dispatch(
      showDeleteConfirmation({
        title: 'Sort Resources ?',
        message:
          'This will sort ALL resource by their name alphabetically. Are you sure ? ',
        onConfirm: () => {
          dispatch(
            fireSortResources({
              folderName,
              limit,
              skip,
              includedOffices,
            }),
          );
        },
      }),
    );
  };

  const onAddClicked = () => {
    const inFolder = window.location.pathname.indexOf('/resources/') > -1;
    const folderPath = window.location.pathname.replace('/resources/', '');

    const newResourceLink = inFolder
      ? `/resources/new?folder=${folderPath}`
      : '/resources/new';

    history.push(newResourceLink);
  };

  const onClickParentDrop = () => {
    if (folderName && folderName.length) {
      const path = pathname.split('/');
      if (path.length > 1) {
        path.pop();
        const upPath = path.join('/');
        history.push({
          pathname: upPath,
          search: query.toString(),
        });
      }
    }
  };

  const onDelete = (objectId) => {
    setState({
      resourcesToDelete: objectId,
    });
  };

  const onIncludedOfficesChanged = (offices) => {
    query.set('includedOffices', offices.join(','));
    history.push({ pathname, search: query.toString() });
    dispatch(setIncludedOfficesFilter(offices));
    setState({
      pagination: {
        limit,
        page: 1,
      },
    });
  };

  const onDeleteConfirm = async () => {
    const objectId = resourcesToDelete;
    setState({
      resourcesToDelete: undefined,
    });
    dispatch(startDeleteResource(objectId));
  };

  const onDeleteCancel = () => {
    setState({
      resourcesToDelete: undefined,
    });
  };

  const onFolderClick = (name = '') => {
    setState({
      pagination: {
        limit,
        page: 1,
      },
    });
    history.push({ pathname: `${pathname}/${name}`, search: query.toString() });
  };

  const onResourceLibraryClicked = () => {
    history.push('/resources/shared_resources');
  };

  const onFolderDrop = ({ combineItem, source, sourceRowIndex, rows }) => {
    const sourceRow = rows[sourceRowIndex];
    const dropedItem = sourceRow.items[source.index];
    const { displayName } = combineItem;
    const [itemName] = dropedItem.displayName.split('/').reverse();
    const itemDisplayNameUpdate = `${displayName}/${itemName}`;
    dispatch(
      setResources(
        items.filter((item) => item.objectId !== dropedItem.objectId),
      ),
    );
    dispatch(
      updateItemFolder(dropedItem, itemDisplayNameUpdate, () =>
        queryResources(includedOffices, folderName, page, limit),
      ),
    );
  };

  return (
    <>
      {!!resourcesToDelete && (
        <SweetAlert
          warning
          showCancel
          title="Delete Resource"
          confirmBtnText="Yes"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          onConfirm={onDeleteConfirm}
          onCancel={onDeleteCancel}
        >
          Are you sure you want to delete this resource?
        </SweetAlert>
      )}
      <div className="default-page-padding">
        {maxOfficeCount !== 1 && (
          <FormGroup title="Included Offices">
            <OfficesDropDown
              onChange={onIncludedOfficesChanged}
              selected={includedOffices}
            />
          </FormGroup>
        )}
        <Droppable droppableId="parent-folder-drop" direction="horizontal">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <Button
                disabled={folderName.length === 0}
                onClick={onClickParentDrop}
                style={{
                  backgroundColor: 'lightgrey',
                  height: 40,
                  width: 150,
                  borderColor: 'black',
                  borderStyle: 'dotted',
                  borderWidth: 2,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <i className="fas fa-level-up-alt" />
              </Button>
            </div>
          )}
        </Droppable>
        <br />
        <DragGrid
          pagenationSkip={() => {
            return limit * (page - 1);
          }}
          onCombine={({ combine, source, sourceRowIndex, rows }) => {
            const [folderId] = combine.draggableId.split('-').reverse();
            const [combineItem] = items.filter(
              (item) => item.objectId === folderId,
            );
            if (combineItem.fileType === 'folder') {
              onFolderDrop({
                combineItem,
                source,
                sourceRowIndex,
                rows,
              });
            }
          }}
          canCombine={(dropResult) => {
            const { combine } = dropResult;
            if (combine) {
              const { draggableId } = combine;
              return draggableId.indexOf('drag-grid-item-folder') === 0;
            }
            return false;
          }}
          onDragableId={({ item }) =>
            item.fileType === 'folder'
              ? `drag-grid-item-folder-${item.objectId}`
              : `drag-grid-item-${item.objectId}`
          }
          onCell={({ item, snapshot }) => {
            let isOverFolder = false;

            if (snapshot.draggingOver) {
              isOverFolder = snapshot.draggingOver.indexOf('folder') > -1;
            }

            if (snapshot.combineWith) {
              isOverFolder = snapshot.combineWith.indexOf('folder') > -1;
            }
            return (
              <div
                style={{
                  transform: isOverFolder ? 'scale(0.5, 0.5)' : '',
                }}
              >
                <ResourceCell
                  objectId={item.objectId}
                  thumbnailURL={
                    item.thumbnail ? item.thumbnail.url : '/images/no_image.png'
                  }
                  fileURL={item.file ? item.file.url : item.url}
                  name={item?.displayName?.split('/')?.pop()}
                  onDelete={() => onDelete(item.objectId)}
                  key={item.objectId}
                  isFolder={item.fileType === 'folder'}
                  onFolderClick={() =>
                    onFolderClick(item.displayName.split('/').pop())
                  }
                />
              </div>
            );
          }}
          onUpdatedItemOrder={(updatedItems) => {
            dispatch(setResources(updatedItems));
          }}
          items={() => items}
        />
        <br />
        <Paginator
          page={page}
          limit={limit}
          totalCount={resourcesTotalCount}
          onLimitChanged={({ value }) =>
            onPagenation({ page: 1, limit: value })
          }
          pageRange={5}
          onPageClicked={(event) => onPagenation({ page: event })}
        />
      </div>
    </>
  );
};

export default ResourcesIndex;
