/* eslint-disable import/no-named-as-default */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withLayoutContext, layoutContextPropTypes } from '../../Layout';
import PlanActions from '../../../actions/plan';
import { startUpdateConfig } from '../../../actions/auth';
import FormGroup, { SwitchGroup, TextGroup } from '../../FormGroup';
import APIDropDown from '../APIDropDown';
import TemplateSending from './TemplateSending';
import Panel from '../../Panel';
import { showErrorAlert, hideAlert } from '../../../actions/alert';
import { updatedErrors, checkForErrors } from './Validation/Validator';

const defaultUploadSettings = {
  fileName: '%customerName% Attachment.pdf',
  fileDescription: '%date% Attachment',
  backupRecipients: ['%userEmail%'],
};

const defaultjobProgressUploadSettings = {
  fileName: 'attachment.pdf',
  fileDestination: 'estimates',
  backupRecipients: [],
};

class ContractSending extends React.Component {
  constructor(props) {
    super(props);
    const updatedSettings = {
      ...props.settings,
      webhook: props.settings.webhook,
      salesforce: props.settings.salesforce,
      leadPerfection: props.settings.leadPerfection || defaultUploadSettings,
      jobNimbus: props.settings.jobNimbus || defaultUploadSettings,
      jobProgress:
        props.settings.jobProgress || defaultjobProgressUploadSettings,
    };
    this.state = {
      ...updatedSettings,
      errors: updatedErrors({}, updatedSettings),
    };
  }

  componentDidMount() {
    const {
      setCrumbs,
      location: { pathname },
      title,
    } = this.props;

    const crumb = { title, link: pathname };
    setCrumbs([crumb]);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const updatedSettings = {
      ...newProps.settings,
      webhook: newProps.settings.webhook || {},
      salesforce: newProps.settings.salesforce || {},
      leadPerfection: newProps.settings.leadPerfection || defaultUploadSettings,
      jobNimbus: newProps.settings.jobNimbus || defaultUploadSettings,
      jobProgress:
        newProps.settings.jobProgress || defaultjobProgressUploadSettings,
    };
    this.setState({
      ...updatedSettings,
      errors: updatedErrors(this.state.errors, updatedSettings),
    });
  }

  componentWillUnmount() {
    const { setCrumbs } = this.props;

    setCrumbs([]);
  }

  onSaveClicked = () => {
    if (checkForErrors(this.state.errors)) {
      this.props.showErrorAlert({
        title: 'Invalid Values',
        message: 'You must correct all validation errors before saving',
        onConfirm: this.props.hideAlert,
      });
      return;
    }
    const { errors, ...rest } = this.state;
    this.props.startUpdateConfig({
      [this.props.settingsKey]: rest,
    });
  };

  onValueChanged = ({ key, value }) => {
    const newState = { ...this.state };
    newState[key] = value;
    const errors = updatedErrors(this.state.errors, newState);
    this.setState({ [key]: value, errors });
  };

  settingsSection = () => {
    const title = this.props.getSettingsLabel({
      anchor: `${this.props.title
        .toLowerCase()
        .replace(' sending', '')}_sending`,
      title: `${this.props.title}`,
    });

    return (
      <Panel title={title}>
        <div>
          <FormGroup title="APIs">
            <APIDropDown
              selectedOptions={this.state.endpoints}
              onChange={(value) =>
                this.onValueChanged({ key: 'endpoints', value })
              }
            />
          </FormGroup>
          <SwitchGroup
            title="Fill Empty White Space"
            checked={this.state.fillEmptyWhiteSpace}
            onChange={(value) =>
              this.onValueChanged({ key: 'fillEmptyWhiteSpace', value })
            }
          />
          {this.state.fillEmptyWhiteSpace && (
            <TextGroup
              title="Empty White Space Note"
              value={this.state.emptyWhiteSpaceNote}
              placeholder="Empty White Space Note"
              onChange={(value) =>
                this.onValueChanged({ key: 'emptyWhiteSpaceNote', value })
              }
            />
          )}
          <SwitchGroup
            title="Use Email Links"
            checked={this.state.useBrochures}
            onChange={(value) =>
              this.onValueChanged({ key: 'useBrochures', value })
            }
          />
          {this.state.useVerifyEmail && (
            <SwitchGroup
              title="Use Verify Email"
              checked={this.state.useVerifyEmail}
              onChange={(value) =>
                this.onValueChanged({ key: 'useVerifyEmail', value })
              }
            />
          )}
          <SwitchGroup
            title="Show Verify Email Alert"
            checked={!this.state.disableVerifyEmail}
            onChange={(value) =>
              this.onValueChanged({ key: 'disableVerifyEmail', value: !value })
            }
          />
          <SwitchGroup
            title="Require Email"
            checked={!this.state.disableRequireEmail}
            onChange={(value) =>
              this.onValueChanged({ key: 'disableRequireEmail', value: !value })
            }
          />
          <SwitchGroup
            title="Require Phone"
            checked={!this.state.disableRequirePhone}
            onChange={(value) =>
              this.onValueChanged({ key: 'disableRequirePhone', value: !value })
            }
          />
          <SwitchGroup
            title="Disable Review Button"
            checked={this.state.disableReviewButton}
            onChange={(value) =>
              this.onValueChanged({ key: 'disableReviewButton', value })
            }
          />
          <SwitchGroup
            title="Disable Print Button"
            checked={this.state.disablePrintButton}
            onChange={(value) =>
              this.onValueChanged({ key: 'disablePrintButton', value })
            }
          />
        </div>
      </Panel>
    );
  };

  render() {
    const title = this.props.getSettingsLabel({
      anchor: `${this.props.title
        .toLowerCase()
        .replace(' sending', '')}_sending`,
      title: `${this.props.title}`,
    });

    return (
      <div className="default-page-padding">
        <TemplateSending
          settingsSection={this.settingsSection}
          onValueChanged={this.onValueChanged}
          onSaveClicked={this.onSaveClicked}
          title={title}
          onCopy={() => this.setState({ showCopy: true })}
          onCopyClose={() => this.setState({ showCopy: false })}
          showCopy={this.state.showCopy}
          copyKey={this.props.settingsKey}
          {...this.state}
        />
      </div>
    );
  }
}

ContractSending.defaultProps = {
  settings: {
    disableRequireEmail: false,
    disableRequirePhone: false,
    disableVerifyEmail: false,
    disableReviewButton: false,
    disablePrintButton: false,
    leadPerfection: {
      fileName: '%customerName% Attachment.pdf',
      fileDescription: '%date% Attachment',
      backupRecipients: ['%userEmail%'],
    },
    jobNimbus: {
      fileName: '%customerName% Attachment.pdf',
      fileDescription: '%date% Attachment',
      backupRecipients: ['%userEmail%'],
    },
    salesforce: {
      fileName: '%customerName% Attachment.pdf',
      backupRecipients: ['%userEmail%'],
    },
    webhook: {
      includePDF: false,
      url: '',
      authHeader: '',
    },
    endpoints: [],
    fillEmptyWhiteSpace: false,
    emptyWhiteSpaceNote: '',
    useBrochures: false,
    useVerifyEmail: false,
    from: '',
    subjectLine: '',
    recipients: [],
    ccRecipients: [],
    bccRecipients: [],
    emailHTML: '',
    emailFileName: '',
    backupRecipients: [],
    fileName: '',
  },
};

ContractSending.propTypes = {
  settingsKey: PropTypes.string.isRequired,
  getSettingsLabel: PropTypes.func.isRequired,
  startUpdateConfig: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  settings: PropTypes.shape({
    disableRequireEmail: PropTypes.bool,
    disableRequirePhone: PropTypes.bool,
    disableVerifyEmail: PropTypes.bool,
    disableReviewButton: PropTypes.bool,
    disablePrintButton: PropTypes.bool,
    leadPerfection: PropTypes.shape({
      fileName: PropTypes.string,
      fileDescription: PropTypes.string,
      backupRecipients: PropTypes.arrayOf(PropTypes.string),
    }),
    jobNimbus: PropTypes.shape({
      fileName: PropTypes.string,
      fileDescription: PropTypes.string,
      backupRecipients: PropTypes.arrayOf(PropTypes.string),
    }),
    salesforce: PropTypes.shape({
      fileName: PropTypes.string,
      backupRecipients: PropTypes.arrayOf(PropTypes.string),
    }),
    webhook: PropTypes.shape({
      includePDF: PropTypes.bool,
      url: PropTypes.string,
      authHeader: PropTypes.string,
    }),
    endpoints: PropTypes.arrayOf(PropTypes.string),
    fillEmptyWhiteSpace: PropTypes.bool,
    emptyWhiteSpaceNote: PropTypes.string,
    useBrochures: PropTypes.bool,
    useVerifyEmail: PropTypes.bool,
    from: PropTypes.string,
    subjectLine: PropTypes.string,
    recipients: PropTypes.arrayOf(PropTypes.string),
    ccRecipients: PropTypes.arrayOf(PropTypes.string),
    bccRecipients: PropTypes.arrayOf(PropTypes.string),
    emailHTML: PropTypes.string,
    emailFileName: PropTypes.string,
    backupRecipients: PropTypes.arrayOf(PropTypes.string),
    fileName: PropTypes.string,
  }),
  showErrorAlert: PropTypes.func.isRequired,
  hideAlert: PropTypes.func.isRequired,
  ...layoutContextPropTypes,
};

const mapStateToProps = ({ auth: { config } }, props) => ({
  settings: { ...config[props.settingsKey] },
});

const mapDispatchToProps = (dispatch) => ({
  getSettingsLabel: (anchor, defaultValue) =>
    dispatch(PlanActions.getSettingsLabel(anchor, defaultValue)),
  startUpdateConfig: (updates) => dispatch(startUpdateConfig(updates)),
  showErrorAlert: (props) => dispatch(showErrorAlert(props)),
  hideAlert: () => dispatch(hideAlert()),
});

export default withLayoutContext(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractSending)),
);
