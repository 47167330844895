/* eslint-disable react/prop-types */
import React from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Menu, MenuItem, TextArea, Classes } from 'leap-menu-item';
import {
  setInitialStateForTemplate,
  setShowLinkMeasureSheetItems,
  startSyncPlaceholders,
} from '../../../actions/templateEdit';
import ContractObject from '../Models/ContractObject';
import YesNoMenuItem from './YesNoMenuItem';
import MSIPlaceholderMenu from '../MSIPlaceholderMenu';

import { useValidLinkedItems } from '../../../hooks/Templates/useValidLinkedItems';
import DebounceInputWithNet from './DebounceInputWithNet';

const shadingStyleLabel = (shadingStyle) => {
  switch (shadingStyle) {
    case 0:
      return 'None';
    case 1:
      return 'Alternate Rows';
    case 2:
      return 'All Rows';
    default:
      return 'None';
  }
};

const BodySection = ({
  templateValues,
  editCellItem,
  setTemplateState,
  setShowLinkItems,
  syncPlacehoders,
}) => {
  const contractObject = new ContractObject(templateValues.toJSON());
  const updatedObject =
    contractObject.getObjectWithId(editCellItem.objectId) || {};
  const { linkedMeasureSheetItemIds = [], objectId } = updatedObject;
  const validCount = useValidLinkedItems({
    objectId,
    linkedMeasureSheetItemIds,
  });

  return (
    <Menu className={Classes.ELEVATION_1}>
      <MenuItem text="App Title">
        <DebounceInputWithNet
          element={TextArea}
          minLength={0}
          debounceTimeout={300}
          value={updatedObject.appTitle}
          onChange={(e) => {
            updatedObject.appTitle = e.target.value;
            setTemplateState(contractObject);
          }}
        />
      </MenuItem>
      <MenuItem text="PDF Title">
        <DebounceInputWithNet
          element={TextArea}
          minLength={0}
          debounceTimeout={300}
          value={updatedObject.contractTitle}
          onChange={(e) => {
            updatedObject.contractTitle = e.target.value;
            setTemplateState(contractObject);
          }}
        />
      </MenuItem>
      <Menu.Divider />
      <YesNoMenuItem
        text="Begin on New Page"
        active={updatedObject.newPage}
        onChange={(value) => {
          updatedObject.newPage = value;
          setTemplateState(contractObject);
        }}
      />
      <YesNoMenuItem
        text="Draw Border"
        active={updatedObject.drawBorder}
        onChange={(value) => {
          updatedObject.drawBorder = value;
          setTemplateState(contractObject);
        }}
      />
      <YesNoMenuItem
        text="Hidden In App"
        active={!updatedObject.userInputRequired}
        onChange={(value) => {
          updatedObject.userInputRequired = !value;
          setTemplateState(contractObject);
        }}
      />
      <YesNoMenuItem
        text="Allow Copy/Paste"
        active={updatedObject.canAddMultiple}
        onChange={(value) => {
          updatedObject.canAddMultiple = value;
          setTemplateState(contractObject);
        }}
      />
      <MenuItem
        text="Shading Style"
        label={shadingStyleLabel(updatedObject.shading)}
      >
        <MenuItem
          text="None"
          icon={updatedObject.shading === 0 ? 'dot' : 'blank'}
          shouldDismissPopover={false}
          active={updatedObject.shading === 0}
          onClick={(e) => {
            updatedObject.shading = 0;
            setTemplateState(contractObject);
          }}
        />
        <MenuItem
          text="Alternate Rows"
          icon={updatedObject.shading === 1 ? 'dot' : 'blank'}
          shouldDismissPopover={false}
          active={updatedObject.shading === 1}
          onClick={(e) => {
            updatedObject.shading = 1;
            setTemplateState(contractObject);
          }}
        />
        <MenuItem
          text="All Rows"
          icon={updatedObject.shading === 2 ? 'dot' : 'blank'}
          shouldDismissPopover={false}
          active={updatedObject.shading === 2}
          onClick={(e) => {
            updatedObject.shading = 2;
            setTemplateState(contractObject);
          }}
        />
      </MenuItem>
      <Menu.Divider />
      <MenuItem
        text="Linked Measure Sheet Items"
        label={validCount}
        onClick={() =>
          setShowLinkItems(
            true,
            'linkedMeasureSheetItemIds',
            false,
            'Linked Items',
            true,
          )
        }
      />
      <MSIPlaceholderMenu
        itemIds={linkedMeasureSheetItemIds}
        disabled={!linkedMeasureSheetItemIds.length}
      />
      <MenuItem
        text="Sync Placeholders"
        disabled={!linkedMeasureSheetItemIds.length}
        onClick={() => syncPlacehoders(linkedMeasureSheetItemIds, editCellItem)}
      />
    </Menu>
  );
};

const mapStateToProps = ({ templatesEdit = {} }) => ({
  templateValues: templatesEdit[templatesEdit.currentWorkingId],
  editCellItem: templatesEdit.editCellItem,
});

const mapDispatchToProps = (dispatch) => ({
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
  setShowLinkItems: (show, key, singleSelect, title, showCustomProduct) =>
    dispatch(
      setShowLinkMeasureSheetItems(
        show,
        key,
        singleSelect,
        title,
        showCustomProduct,
      ),
    ),
  syncPlacehoders: (objectIds, cells) =>
    dispatch(startSyncPlaceholders(objectIds, cells)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BodySection);
