/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { withLayoutContext, layoutContextPropTypes } from '../../../Layout';
import TitleButton from '../../../TitleButton';
import { startUpdateConfig } from '../../../../actions/auth';
import { randomKey, reorder } from '../../../../utils/utils';
import { history } from '../../../../router';
import { setOnDragEndCallback } from '../../../DropContext';
import Panel from '../../../Panel';
import { showErrorAlert, hideAlert } from '../../../../actions/alert';
import { validateCustomOptionTitles } from '../Validation/CustomOptions';
import { checkForErrors } from '../Validation/Validator';
import Copy from '../../Copy';
import { pushToDataLayer } from '../../../../actions/tagManager';

const customOptionsDropableSectionId = 'customOptionsDropableSection';

export class CustomOptionsIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: props.options,
      errors: validateCustomOptionTitles(props.options),
    };
    setOnDragEndCallback(customOptionsDropableSectionId, (result) => {
      // dropped outside the list
      const { destination = {} } = result;
      if (destination === null) {
        return;
      }
      const { droppableId } = destination;
      if (droppableId !== customOptionsDropableSectionId) {
        return;
      }
      const options = reorder(
        this.state.options,
        result.source.index,
        result.destination.index,
      );
      this.setState({ options });
    });
  }

  componentDidMount() {
    const { setButtons } = this.props;

    setButtons(
      <TitleButtons
        maxCustomTemplateCount={this.props.maxCustomTemplateCount}
        onClick={this.onButtonClick}
      />,
    );
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      options: newProps.options,
      errors: validateCustomOptionTitles(newProps.options),
    });
  }

  componentWillUnmount() {
    const { setButtons } = this.props;

    setButtons();
  }

  onSaveClicked = () => {
    if (checkForErrors(this.state.errors)) {
      this.props.showErrorAlert({
        title: 'Invalid Values',
        message: 'You must correct all validation errors before saving',
        onConfirm: this.props.hideAlert,
      });
      return;
    }
    this.props.startUpdateConfig({
      mainMenuOptions: this.state.options,
      officeId: this.state.selectedOffice,
    });
  };

  onAddClicked = () => {
    this.props.pushToDataLayer({
      event: 'settingsPageTitleButtonEvent',
      eventCategory: 'Settings Page',
      eventAction: 'custom_options',
      eventLabel: 'Add',
    });

    const objectId = randomKey(10);
    history.push(`custom_documents/edit/${objectId}`);
  };

  onCopyClicked = () => {
    this.setState({ showCopy: true });
  };

  onButtonClick = ({ source }) => {
    switch (source) {
      case 'save':
        this.onSaveClicked();
        break;
      case 'add':
        this.onAddClicked();
        break;
      case 'copy':
        this.onCopyClicked();
        break;
      default:
        break;
    }
  };

  onEdit = (objectId) => {
    history.push(`custom_documents/edit/${objectId}`);
  };

  onDelete = (objectId) => {
    const options = this.state.options.filter(
      (old) => old.objectId !== objectId,
    );
    this.setState({ options });
  };

  onTitleChange = ({ value, objectId }) => {
    const options = this.state.options.map((option) => {
      if (option.objectId === objectId) {
        return {
          ...option,
          title: value,
        };
      }
      return option;
    });
    const errors = validateCustomOptionTitles(options);
    this.setState({ options, errors });
  };

  render() {
    return (
      <>
        <Copy
          title="Copy Document Types"
          show={this.state.showCopy}
          warning="Warning! This will overwrite your current Document Types Settings"
          configKeys={['mainMenuOptions']}
          onClose={() => this.setState({ showCopy: false })}
        />
        <div className="default-page-padding">
          <Panel title="Document Types">
            <div>
              <div>
                <Table striped bordered hover style={{ tableLayout: 'fixed' }}>
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th style={{ width: '70px', textAlign: 'center' }}>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.options.map(({ objectId, title }, index) => (
                      <tr
                        style={{
                          position: 'relative',
                          width: '100% !important',
                        }}
                        key={objectId}
                      >
                        <td
                          style={{
                            backgroundColor: 'transparent',
                            width: '99%',
                          }}
                        >
                          <div className="input-container">
                            <input
                              className="table-row__cell__input"
                              type="text"
                              value={title}
                              placeholder="Name"
                              onChange={(e) =>
                                this.onTitleChange({
                                  value: e.target.value,
                                  objectId,
                                })
                              }
                            />
                            {this.state.errors[index].title && (
                              <div className="error">
                                {this.state.errors[index].title}
                              </div>
                            )}
                          </div>
                        </td>
                        <td
                          style={{
                            backgroundColor: 'transparent',
                            width: '75px',
                          }}
                          className="custom-options__action-column"
                        >
                          <div className="custom-options__action-cell">
                            <Link
                              to={`/custom_documents/edit/${objectId}`}
                              id="editButton"
                            >
                              <i className="fas fa-pencil-alt fill-cell" />
                            </Link>
                            <button
                              className="fill-cell danger"
                              type="button"
                              onClick={() => this.onDelete(objectId)}
                            >
                              <i className="far fa-trash-alt" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </Panel>
        </div>
      </>
    );
  }
}

CustomOptionsIndex.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      objectId: PropTypes.string,
      order: PropTypes.number,
    }),
  ),
  maxCustomTemplateCount: PropTypes.number.isRequired,
  pushToDataLayer: PropTypes.func.isRequired,
  startUpdateConfig: PropTypes.func.isRequired,
  showErrorAlert: PropTypes.func.isRequired,
  hideAlert: PropTypes.func.isRequired,
  ...layoutContextPropTypes,
};

CustomOptionsIndex.defaultProps = {
  options: [],
};

const TitleButtons = ({ onClick, maxCustomTemplateCount }) => (
  <>
    <TitleButton
      variant="primary"
      onClick={() => onClick({ source: 'copy' })}
      title="Copy"
    />
    {maxCustomTemplateCount && (
      <TitleButton
        variant="success"
        onClick={() => onClick({ source: 'add' })}
        title="New"
      />
    )}
    <TitleButton
      variant="success"
      onClick={() => onClick({ source: 'save' })}
      title="Save"
    />
  </>
);

TitleButtons.propTypes = {
  maxCustomTemplateCount: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth: { config }, plan }) => ({
  options: config.mainMenuOptions || [],
  maxCustomTemplateCount: plan.maxCustomTemplateCount,
});

const mapDispatchToProps = (dispatch) => ({
  startUpdateConfig: (updates) => dispatch(startUpdateConfig(updates)),
  showErrorAlert: (props) => dispatch(showErrorAlert(props)),
  hideAlert: () => dispatch(hideAlert()),
  pushToDataLayer: (variablesForLayer) =>
    dispatch(pushToDataLayer(variablesForLayer)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(CustomOptionsIndex),
);
