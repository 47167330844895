/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ButtonGroup } from 'react-bootstrap';
import { startCreateNewCompany } from '../../actions/admin';
import FormGroup, { TextGroup, PasswordGroup } from '../FormGroup';
import Panel from '../Panel';
import TitleButton from '../TitleButton';

export class NewCompanyPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      maxActiveUsers: props.maxActiveUsers,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { createCompanyResponse } = newProps;
    const state = {};
    if (createCompanyResponse.includes('username')) {
      state.usernameError = 'Account already exists for this username';
    }
    if (createCompanyResponse.includes('email')) {
      state.emailError = 'Account already exists for this username';
    }
    this.setState(() => state);
  }

  onCompanyNameChange = (companyName) => {
    this.setState(() => ({ companyName, companyNameError: undefined }));
  };

  onMaxActiveUsersChange = (maxActiveUsers) => {
    let maxActiveUsersError;
    if (maxActiveUsers !== '' && !/^\d+$/.test(maxActiveUsers)) {
      maxActiveUsersError = 'Invalid number';
    }
    this.setState(() => ({ maxActiveUsers, maxActiveUsersError }));
  };

  onOfficeNameChange = (officeName) => {
    this.setState(() => ({ officeName, officeNameError: undefined }));
  };

  onFirstNameChange = (firstName) => {
    this.setState(() => ({ firstName, firstNameError: undefined }));
  };

  onLastNameChange = (lastName) => {
    this.setState(() => ({ lastName, lastNameError: undefined }));
  };

  onEmailChange = (email) => {
    let emailError;
    if (email !== '' && !/\S+@\S+\.\S+/.test(email)) {
      emailError = 'Invalid email';
    }
    this.setState(() => ({ email, emailError }));
  };

  onUsernameChange = (username) => {
    this.setState(() => ({ username, usernameError: undefined }));
  };

  onPasswordChange = (password) => {
    this.setState(() => ({ password, passwordError: undefined }));
  };

  onConfirmPasswordChange = (confirmPassword) => {
    this.setState(() => ({ confirmPassword, confirmPasswordError: undefined }));
  };

  onSave = () => {
    const {
      companyName,
      maxActiveUsers,
      officeName,
      firstName,
      lastName,
      email,
      username,
      password,
      confirmPassword,
      maxActiveUsersError,
      emailError,
    } = this.state;
    const errors = {};
    if (!companyName) {
      errors.companyNameError = this.props.missingFieldMessage;
    }
    if (maxActiveUsers === '') {
      errors.maxActiveUsersError = this.props.missingFieldMessage;
    } else if (maxActiveUsersError) {
      errors.maxActiveUsersError = maxActiveUsersError;
    }
    if (!officeName) {
      errors.officeNameError = this.props.missingFieldMessage;
    }
    if (!firstName) {
      errors.firstNameError = this.props.missingFieldMessage;
    }
    if (!lastName) {
      errors.lastNameError = this.props.missingFieldMessage;
    }
    if (!email) {
      errors.emailError = this.props.missingFieldMessage;
    } else if (emailError) {
      errors.emailError = emailError;
    }
    if (!username) {
      errors.usernameError = this.props.missingFieldMessage;
    }
    if (!password) {
      errors.passwordError = this.props.missingFieldMessage;
    }
    if (!confirmPassword) {
      errors.confirmPasswordError = this.props.missingFieldMessage;
    }
    if (password && confirmPassword && password !== confirmPassword) {
      errors.confirmPasswordError = 'The password is not the same';
    }
    if (Object.keys(errors).length > 0) {
      this.setState(() => errors);
    } else {
      this.props.startCreateNewCompany({
        companyName,
        maxActiveUsers: parseInt(maxActiveUsers, 10),
        officeName,
        username,
        password,
        nameFirst: firstName,
        nameLast: lastName,
        email,
      });
    }
  };

  onClear = () => {
    this.setState(() => ({
      companyName: undefined,
      maxActiveUsers: undefined,
      officeName: undefined,
      username: undefined,
      password: undefined,
      confirmPassword: undefined,
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      companyNameError: undefined,
      maxActiveUsersError: undefined,
      officeNameError: undefined,
      usernameError: undefined,
      passwordError: undefined,
      confirmPasswordError: undefined,
      firstNameError: undefined,
      lastNameError: undefined,
      emailError: undefined,
    }));
  };

  render() {
    // <MainSection title="New Company">
    return (
      <Panel title="Create New Company">
        <TextGroup
          title="Company Name"
          errorMessage={this.state.companyNameError}
          value={this.state.companyName}
          placeholder="Company Name"
          onChange={this.onCompanyNameChange}
        />
        <TextGroup
          title="Max Active Users"
          errorMessage={this.state.maxActiveUsersError}
          value={this.state.maxActiveUsers}
          placeholder="Max Active Users"
          onChange={this.onMaxActiveUsersChange}
        />
        <TextGroup
          title="Office Name"
          errorMessage={this.state.officeNameError}
          value={this.state.officeName}
          placeholder="Office Name"
          onChange={this.onOfficeNameChange}
        />
        <TextGroup
          title="First Name"
          errorMessage={this.state.firstNameError}
          value={this.state.firstName}
          placeholder="First Name"
          onChange={this.onFirstNameChange}
        />
        <TextGroup
          title="Last Name"
          errorMessage={this.state.lastNameError}
          value={this.state.lastName}
          placeholder="Last Name"
          onChange={this.onLastNameChange}
        />
        <TextGroup
          title="Email"
          errorMessage={this.state.emailError}
          value={this.state.email}
          placeholder="Email"
          onChange={this.onEmailChange}
        />
        <TextGroup
          title="Username"
          errorMessage={this.state.usernameError}
          value={this.state.username}
          placeholder="Username"
          onChange={this.onUsernameChange}
        />
        <PasswordGroup
          title="Password"
          errorMessage={this.state.passwordError}
          value={this.state.password}
          placeholder="Password"
          onChange={this.onPasswordChange}
        />
        <PasswordGroup
          title="Confirm Password"
          errorMessage={this.state.confirmPasswordError}
          value={this.state.confirmPassword}
          placeholder="Confirm Password"
          onChange={this.onConfirmPasswordChange}
        />
        <FormGroup title="">
          <ButtonGroup justified>
            <ButtonGroup>
              <TitleButton
                title="Clear"
                variant="primary"
                onClick={this.onClear}
              />
            </ButtonGroup>
            <ButtonGroup>
              <TitleButton
                title="Save"
                variant="success"
                onClick={this.onSave}
              />
            </ButtonGroup>
          </ButtonGroup>
        </FormGroup>
      </Panel>
    );
  }
}

NewCompanyPage.propTypes = {
  maxActiveUsers: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  startCreateNewCompany: PropTypes.func.isRequired,
};

NewCompanyPage.defaultProps = {
  maxActiveUsers: undefined,
  missingFieldMessage: 'This field is required',
};

const mapStateToProps = ({ admin: { fetchedThru = '' } }) => ({
  createCompanyResponse: fetchedThru,
});

const mapDispatchToProps = (dispatch) => ({
  startCreateNewCompany: (params) => dispatch(startCreateNewCompany(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewCompanyPage);
