// @ts-check
import Parse from 'parse';

/**
 * @typedef { "textWords" | "textSentence" | "textParagraph" | "switched" | "disclosure" | "photos" | "linkCell" } CellType
 * @typedef { "default" | "picker" | "keypad" | "keypadDecimal" | "phone" | "currency" | "currencyWhole" | "email" | "numbers" | "datePicker" | "dateTimePicker" | "timePicker" | "formula" | "formulaWhole" | "formulaCurrency" | "formulaCurrencyWhole" | "creditCard" | "ccExpDate" | "dynamic" | "financeOptionsPicker" | "ssn" | "statePicker" | "zipCode" } InputType
 * @typedef {{
 *  text: string,
 *  value: string | {},
 * }} PickerObject
 * @typedef {{
 *  inputType: InputType,
 *  pickerValues: string[],
 *  pickerObjects: PickerObject[],
 *  enabled: boolean,
 *  required: boolean,
 *  values: string[],
 *  value: string,
 *  defaultValue: string,
 * }} DynamicInputObject
 * @typedef {{
 *  cellId: string,
 *  objects: DynamicInputObject[],
 * }} ContractDataBodyDynamicInputObject
 * @typedef {{
 *  apiKey: string,
 *  appKey: string,
 *  cellId: string,
 *  cellType: CellType,
 *  customerFillable: boolean,
 *  dateDisplayFormat: string,
 *  dynamicInputObject: ContractDataBodyDynamicInputObject,
 *  endpoint: string,
 *  formula: string,
 *  hideFromPDF: boolean,
 *  inputType: InputType,
 *  loadFromEndpoint: boolean,
 *  note: string,
 *  pickerObjects: {}[],
 *  pickerOptions: string[],
 *  required: boolean,
 *  switchValueOff: string,
 *  switchValueOn: string,
 *  title: string,
 *  url: string,
 *  value: string,
 * }} CreditApplicationCell
 * @typedef {{
 *  title: string,
 *  button: 'scanPrimary' | 'scanSecondary',
 *  section: 'top' | 'left' | 'right',
 *  buttonTitle: string,
 *  cells: CreditApplicationCell[],
 * }} CreditApplicaitonSection
 * @typedef {{
 *  show: boolean,
 *  title: string,
 *  disclosure: string,
 *  buttonTitle: string,
 * }} SubmitAlert
 * @typedef {{
 *  isCustomerFillable: boolean,
 *  title: string,
 *  pdfDisclousure: string,
 *  image: Parse.File,
 *  showPDF: boolean,
 *  submitAlert: SubmitAlert,
 *  sections: CreditApplicaitonSection[],
 *  partnerCreditApplication: import('./PartnerCreditApplication'),
 * }} CreditApplicationAttributes
 */

/**
 * @extends Parse.Object<CreditApplicationAttributes>
 */
export default class CreditApplicationObject extends Parse.Object {
  // TODO: isCustomerFillable is the toggle on the edit credit app NOT CONFIG ENABLE! change dashboard!
  /**
   * @param {CreditApplicationAttributes} attributes
   */
  constructor(attributes) {
    super('CreditApplicationObject', attributes);
  }

  // /**
  //  * Validate attributes
  //  * @param {CreditApplicationAttributes} attrs
  //  * @returns {false | Parse.Error}
  //  */
  // validate(attrs) {
  // }

  isCustomCreditApplication() {
    return !this.get('partnerCreditApplication');
  }
}
