/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import { history } from '../../router';

export const HistoryLink = (props) => {
  const { href } = props;
  return (
    <a
      href={href}
      onClick={(event) => {
        event.preventDefault();
        history.push(href);
      }}
      className={props.className}
      style={props.style}
    >
      {props.children}
    </a>
  );
};
