import Parse from 'parse';
import { handleError } from './auth';

export const updateIsDocuSignAuthorized = (updates) => ({
  type: 'UPDATE_IS_DOCUSIGN_AUTHORIZED',
  updates,
});

export const updateIsDocuSignReAuthorized = (updates) => ({
  type: 'UPDATE_IS_DOCUSIGN_AUTHORIZED',
  updates,
});

export const startAuthenticateDocuSign = (token) => async (
  dispatch,
  getState,
) => {
  try {
    const { auth } = getState();
    const { selectedOffice } = auth;
    const docuSignStatus = await Parse.Cloud.run('authorizeDocuSign', {
      token,
      officeId: selectedOffice.id,
    });
    dispatch(updateIsDocuSignAuthorized(docuSignStatus));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const startCheckDocuSignIsAuthorized = () => async (
  dispatch,
  getState,
) => {
  const { auth } = getState();
  const { selectedOffice } = auth;

  try {
    const docuSignStatus = await Parse.Cloud.run('isDocuSignAuthorized', {
      officeId: selectedOffice.id,
    });

    await dispatch(updateIsDocuSignAuthorized(docuSignStatus));
  } catch (e) {
    dispatch(handleError(e));
  }
};

// eslint-disable-next-line consistent-return
export const removeDocusignAuth = () => async (dispatch, getState) => {
  const { auth } = getState();
  const { selectedOffice } = auth;
  try {
    const result = await Parse.Cloud.run('removeDocusignAuthentication', {
      officeId: selectedOffice.id,
    });

    return result;
  } catch (e) {
    dispatch(handleError(e));
  }
};
