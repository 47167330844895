import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from 'leap-menu-item';
import Switch from '../../Misc/Switch';

const YesNoMenuItem = ({ text, active, onChange }) => (
  <MenuItem
    shouldDismissPopover={false}
    text={text}
    label={<Switch checked={active} onChange={() => onChange(!active)} />}
  />
);

YesNoMenuItem.propTypes = {
  text: PropTypes.string,
  active: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

YesNoMenuItem.defaultProps = {
  text: '',
  active: false,
};

export default YesNoMenuItem;
