/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DebounceInput } from 'react-debounce-input';

const OptionCell = ({
  columnKey,
  type,
  width,
  activeColumns,
  widths,
  children,
  onChange,
  value,
  onContextMenu,
}) => (
  <div
    className={`priceguide__option-cell${
      activeColumns[columnKey] ? '_hidden' : ''
    }`}
    style={{ width: width || widths[columnKey] }}
    onContextMenu={onContextMenu}
  >
    {!!children && children}
    {!children && (
      <DebounceInput
        type={type}
        minLength={0}
        debounceTimeout={300}
        onChange={onChange}
        value={value}
      />
    )}
  </div>
);

OptionCell.propTypes = {
  columnKey: PropTypes.string,
  type: PropTypes.oneOf(['text', 'number']),
  activeColumns: PropTypes.shape({
    optionPlaceholders: PropTypes.bool.isRequired,
    optionBrand: PropTypes.bool.isRequired,
    optionName: PropTypes.bool.isRequired,
    optionPrices: PropTypes.bool.isRequired,
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  width: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onContextMenu: PropTypes.func,
};

OptionCell.defaultProps = {
  columnKey: '',
  type: 'text',
  children: undefined,
  width: undefined,
  onChange: undefined,
  value: '',
  onContextMenu: undefined,
};

const mapStateToProps = ({ priceGuide2: { activeColumns, columnWidths } }) => ({
  activeColumns,
  widths: columnWidths.options,
});

export default connect(mapStateToProps)(OptionCell);
