/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { LineChart, Line, XAxis, YAxis, Tooltip, Bar } from 'recharts';
import { getRandomColor } from '../utils';

const LeapLineChart = (props) => (
  <LineChart
    width={props.width}
    height={props.height}
    data={props.data}
    margin={{
      top: 5,
      right: 30,
      left: 20,
      bottom: 5,
    }}
  >
    <XAxis
      tickLine={false}
      axisLine={false}
      domain={['dataMin', 'dataMax']}
      dataKey={props.xDataKey}
      label={{ offset: -5, value: props.xAxisLabel, position: 'insideBottom' }}
    />
    <YAxis
      tickLine={false}
      axisLine={false}
      allowDecimals={props.allowDecimals}
      label={{
        value: props.yAxisLabel,
        angle: -90,
        position: 'insideBottomLeft',
      }}
      domain={['dataMin', 'dataMax']}
    />
    <Tooltip />
    {props.barDataKey && (
      <Bar dataKey={props.barDataKey} barSize={20} fill={getRandomColor()} />
    )}
    {props.lines.map((line) => (
      <Line
        dot={{ strokeWidth: 2, r: 2 }}
        strokeWidth={3}
        key={line.dataKey}
        type="monotone"
        dataKey={line.dataKey}
        stroke={line.stroke}
        activeDot={{ r: 4 }}
      />
    ))}
  </LineChart>
);

LeapLineChart.propTypes = {
  barDataKey: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  lines: PropTypes.arrayOf(
    PropTypes.shape({
      dataKey: PropTypes.string,
      stroke: PropTypes.string,
    }),
  ),
  xDataKey: PropTypes.string,
  yAxisLabel: PropTypes.string,
  xAxisLabel: PropTypes.string,
  allowDecimals: PropTypes.bool,
};

LeapLineChart.defaultProps = {
  data: [],
  height: 200,
  width: 400,
  xDataKey: 'x',
  lines: [
    {
      dataKey: 'y',
      stroke: getRandomColor(),
    },
  ],
  yAxisLabel: null,
  allowDecimals: false,
  xAxisLabel: null,
  barDataKey: null,
};

export default LeapLineChart;
