/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Parse from 'parse';
import { MenuItem } from 'leap-menu-item';
import { Modal, Button } from 'react-bootstrap';
import { Icon } from '@blueprintjs/core';
import _ from 'lodash';
import AppToaster from '../../../../utils/AppToaster';

const LinkedCellMenu = (props) => {
  const { value = {} } = props;
  const { objectId = '', overwritePriority = 1000 } = value;
  const [linkedValueObjects, setLinkedValueObjects] = useState([]);
  const [open, setOpen] = useState(false);
  const [linkedName, setLinkedName] = useState('');
  const [updatedName, setUpdatedName] = useState('');
  const [selectedName, setSelectedName] = useState('');
  const [activeMenuItem, setActiveMenuItem] = useState('');
  const [editMenuItem, setEditMenuItem] = useState('');
  const [showIcon, setShowIcon] = useState(false);
  const [showSaveUpdateIcon, setShowUpdateSaveIcon] = useState(false);

  const sortObjects = (objects) =>
    _.orderBy(objects, [(object) => object.get('name').toLowerCase()], ['asc']);

  const saveNewLinkedValueObject = useCallback(
    async (newValue) => {
      try {
        const foundDuplicateName = _.find(
          linkedValueObjects,
          (obj) => newValue === obj.get('name'),
        );
        if (!foundDuplicateName) {
          const newObject = new Parse.Object('LinkedValueObject');
          await newObject.save({ name: newValue });
          const sortedLinkedObjects = sortObjects([
            ...linkedValueObjects,
            newObject,
          ]);
          setLinkedValueObjects(sortedLinkedObjects);
          props.onChange({ objectId: newObject.id, overwritePriority });
        }
      } catch (e) {
        AppToaster.show({ message: e.message, timeout: 5000 });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [linkedValueObjects],
  );

  const updateLinkedValueObject = useCallback(
    async (obj, updatedValue) => {
      try {
        const isDuplicateName = linkedValueObjects.some(
          (linkedObj) => linkedObj.get('name') === updatedValue,
        );

        if (!isDuplicateName) {
          if (obj.get('name') === selectedName) {
            setSelectedName(updatedValue);
          }
          const updatedObject = await new Parse.Query('LinkedValueObject').get(
            obj.id,
          );
          updatedObject.set('name', updatedValue);
          await updatedObject.save();

          props.onChange({ objectId: updatedObject.id, overwritePriority });
        }

        setUpdatedName('');
        setEditMenuItem('');
      } catch (err) {
        AppToaster.show({ message: err.message, timeout: 5000 });
      }
    },
    [linkedValueObjects, overwritePriority, props, selectedName],
  );

  const deleteNewLinkedValueObject = useCallback(
    async (id) => {
      try {
        const updatedLinkedValueObjects = linkedValueObjects.filter(
          (obj) => obj.id !== id,
        );
        const objectToDestroy = new Parse.Object('LinkedValueObject');
        objectToDestroy.id = id;
        await objectToDestroy.destroy();
        setLinkedValueObjects(updatedLinkedValueObjects);
        AppToaster.show({
          message: 'Deleted Item Successfully!',
          timeout: 5000,
        });
      } catch (e) {
        AppToaster.show({ message: e.message, timeout: 5000 });
      }
    },
    [linkedValueObjects],
  );

  const handleLinkedObjectClick = (object) => {
    if (object.id) {
      setActiveMenuItem(object.id);
      props.onChange({ objectId: object.id, overwritePriority });
    } else {
      setSelectedName('');
      setActiveMenuItem('');
      props.onChange({ objectId: '', overwritePriority });
    }
  };

  const handleValueUpdate = useCallback(
    (obj) => {
      if (updatedName.length > 0) {
        updateLinkedValueObject(obj, updatedName);
      }
    },
    [updateLinkedValueObject, updatedName],
  );

  const handleKeyPress = useCallback(
    (e, obj) => {
      if (e.key === 'Enter') {
        handleValueUpdate(obj);
      }
    },
    [handleValueUpdate],
  );

  const getActiveMenuItemName = () => {
    const activeObj = linkedValueObjects.find(
      (obj) => obj.id === activeMenuItem,
    );
    return activeObj ? activeObj.get('name') : '';
  };

  const fetchLinkedValueObjects = async () => {
    try {
      const user = Parse.User.current();
      const company = user?.get('company');
      const query = new Parse.Query('LinkedValueObject');
      query.equalTo('company', company);
      query.select('name');
      const objects = [];
      await query.each((obj) => {
        objects.push(obj);
        if (obj.id === objectId) {
          setSelectedName(obj.get('name'));
        }
      });
      const sortedLinkedObjects = sortObjects(objects);
      const uniqueLinkedObjects = _.uniqBy(sortedLinkedObjects, (obj) =>
        obj.get('name'),
      );
      setLinkedValueObjects(uniqueLinkedObjects);
    } catch (e) {
      AppToaster.show({ message: e.message, timeout: 5000 });
    }
  };

  useEffect(() => {
    fetchLinkedValueObjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const IconContainer = ({ id, name }) => {
    return (
      <div className="linked-menu-icons-container">
        <Icon
          icon="edit"
          intent="primary"
          onClick={(event) => {
            event.stopPropagation();
            setUpdatedName(name);
            setEditMenuItem(id);
          }}
          className="linked-menu-cursor-pointer"
        />
        <Icon
          icon="trash"
          intent="danger"
          onClick={(event) => {
            event.stopPropagation();
            setOpen(true);
          }}
          className="linked-menu-cursor-pointer"
        />
      </div>
    );
  };

  IconContainer.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  };

  return (
    <>
      <MenuItem text="Linked Object" label={selectedName}>
        <div className="linked-menu-container">
          <div className="linked-menu-input-wrapper">
            <input
              type="text"
              placeholder="Create Linked Object"
              onChange={(e) => setLinkedName(e.target.value)}
              value={linkedName}
              onMouseEnter={() => setShowIcon(true)}
              onMouseLeave={() => setShowIcon(false)}
              onKeyDown={(e) => {
                setShowIcon(true);
                if (e.key === 'Enter' && e.target.value.length) {
                  saveNewLinkedValueObject(linkedName);
                  setLinkedName('');
                }
              }}
              className="linked-menu-input-main"
            />
            {showIcon && (
              <Icon
                icon="floppy-disk"
                intent="primary"
                onMouseEnter={() => setShowIcon(true)}
                onMouseLeave={() => setShowIcon(false)}
                onClick={() => {
                  if (linkedName.length) {
                    saveNewLinkedValueObject(linkedName);
                    setLinkedName('');
                  }
                }}
                className="linked-menu-cursor-pointer"
              />
            )}
          </div>
        </div>
        <div className="linked-menu-list-container">
          {!!_.size(linkedValueObjects) && (
            <div onMouseEnter={() => setActiveMenuItem('')}>
              <MenuItem
                key="linked-object-none"
                text="None"
                active={selectedName === ''}
                onClick={() => handleLinkedObjectClick('')}
                textClassName="linked-object-none"
              />
            </div>
          )}
          {_.size(linkedValueObjects) ? (
            linkedValueObjects.map((obj) => (
              <div
                key={obj.id}
                onClick={() => handleLinkedObjectClick(obj)}
                onMouseEnter={() => setActiveMenuItem(obj.id)}
                className="linked-menu-list"
              >
                {!(editMenuItem === obj.id) ? (
                  <MenuItem
                    key={activeMenuItem}
                    text={obj.get('name')}
                    active={selectedName === obj.get('name')}
                    onClick={() => setSelectedName(obj.get('name'))}
                    label={
                      activeMenuItem === obj.id && (
                        <IconContainer id={obj.id} name={obj.get('name')} />
                      )
                    }
                    textClassName="linked-object-text"
                  />
                ) : (
                  <div className="linked-menu-input-wrapper">
                    <input
                      type="text"
                      placeholder="Create Linked Object"
                      onChange={(e) => {
                        setUpdatedName(e.target.value);
                      }}
                      defaultValue={obj.get('name')}
                      onKeyDown={(e) => {
                        setShowUpdateSaveIcon(true);
                        handleKeyPress(e, obj);
                      }}
                      onMouseEnter={() => setShowUpdateSaveIcon(true)}
                      onMouseLeave={() => setShowUpdateSaveIcon(false)}
                      className="linked-menu-input-secondary"
                    />
                    {showSaveUpdateIcon && (
                      <Icon
                        icon="floppy-disk"
                        intent="primary"
                        onMouseEnter={() => setShowUpdateSaveIcon(true)}
                        onMouseLeave={() => setShowUpdateSaveIcon(false)}
                        onClick={() => handleValueUpdate(obj)}
                        className="linked-menu-cursor-pointer"
                      />
                    )}
                  </div>
                )}
              </div>
            ))
          ) : (
            <p className="linked-menu-no-objects">No Linked Objects</p>
          )}
        </div>
      </MenuItem>
      <Modal show={open} variant="lg" onHide={() => setOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title className="linked-menu-modal-title">
            {' '}
            {`Delete ${getActiveMenuItemName()}?`}{' '}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Deleting <b>{getActiveMenuItemName()}</b> will remove the Linked
            Value from all document templates for all users and remove the
            option from Results forms.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="alternate" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            variant="success"
            onClick={() => deleteNewLinkedValueObject(activeMenuItem)}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

LinkedCellMenu.propTypes = {
  value: PropTypes.shape({
    objectId: PropTypes.string.isRequired,
    overwritePriority: PropTypes.number.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

LinkedCellMenu.defaultProps = {};

export default LinkedCellMenu;
