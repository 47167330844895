/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, TextArea } from 'leap-menu-item';
import { randomKey, reorder } from '../../utils/utils';
import { setOnDragEndCallback } from '../DropContext';
import { getPasteboardValue } from '../../utils/Pasteboard';
import DebounceInputWithNet from './Toolbox/DebounceInputWithNet';

const containerStyle = {
  minWidth: '200px',
  maxWidth: '400px',
};

const portal = document.createElement('div');
document.body.appendChild(portal);

class PickerOptionsEditor extends React.Component {
  constructor(props) {
    super(props);
    this.droppableId = randomKey(10);
    setOnDragEndCallback(this.droppableId, (result) => {
      // dropped outside the list
      const { destination = {} } = result;
      if (destination === null) {
        return;
      }
      const { droppableId } = destination;
      if (droppableId !== this.droppableId) {
        return;
      }
      this.onDragEnd(result);
    });
    this.state = {};
  }

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const updatedValues = reorder(
      this.props.values,
      result.source.index,
      result.destination.index,
    );
    this.props.onChange(updatedValues);
  };

  onClickPaste = () => {
    const pasteText = document.getElementById('picker-text-input');
    pasteText.focus();
    const pickerClipboardValue = getPasteboardValue('picker-clip-board');
    if (pickerClipboardValue) {
      pasteText.value = pickerClipboardValue;
    }
  };

  onDeleteValue = (index) => {
    const updatedValues = [...this.props.values];
    updatedValues.splice(index, 1);
    this.props.onChange(updatedValues);
  };

  onDrag = (isDragging, isDropAnimating) => {
    if (this.state.isDragging) {
      if (isDropAnimating) {
        if (!this.isDragging) {
          this.setState(() => ({ isDragging: undefined }));
        }
      }
    } else if (isDragging && !isDropAnimating) {
      this.setState(() => ({ isDragging: true }));
    }
  };

  render() {
    return (
      <MenuItem
        popoverProps={{ isOpen: this.state.isDragging }}
        {...this.props.menuItemProps}
      >
        <div style={containerStyle}>
          <span style={{ margin: 5 }}>
            {this.props.values.length
              ? `(${this.props.values.length}) values enter`
              : ''}
          </span>
          <br />
          <DebounceInputWithNet
            element={TextArea}
            id="picker-text-input"
            className="form-control"
            placeholder="Enter Picker Option"
            style={{ minHeight: 100, margin: 5 }}
            value={this.props.values.join('\n')}
            onChange={(event) => {
              const updatedValues = event.target.value.split('\n');
              this.props.onChange(updatedValues);
            }}
          />
        </div>
      </MenuItem>
    );
  }
}

PickerOptionsEditor.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
};

PickerOptionsEditor.defaultProps = {
  values: [],
};

export default PickerOptionsEditor;
