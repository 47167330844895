import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './styles.scss';
import LockAnimation from './LockAnimation';

const CompletedCreditAppModal = (props) => {
  const {
    show,
    onHide = () => {},
    onCancel = () => {},
    customerCreditAppId,
    customerAccount = '',
    getDecryptedCreditAppValues,
  } = props;

  const history = useHistory();

  const [errorMessage, setErrorMessage] = useState(undefined);
  const [password, setPassword] = useState('');
  const [lockOpen, setLockOpen] = useState(false);

  const submitAuth = async () => {
    try {
      if (password) {
        await getDecryptedCreditAppValues({ password, customerCreditAppId });
        setLockOpen(true);
        const completedUrl = `/completed_credit_app/${customerCreditAppId}`;
        setErrorMessage(undefined);
        setTimeout(() => {
          onHide();
          history.push(completedUrl);
        }, 600);
      } else {
        setErrorMessage('Must enter Password');
      }
    } catch (error) {
      const { message } = error;
      const formatedMessage = message.replace('username/', '');
      setErrorMessage(formatedMessage);
    }
  };

  return (
    <Modal
      dialogClassName="decrypted-credit-app-auth-modal"
      show={show}
      onHide={onCancel}
    >
      <Modal.Header closeButton>
        <Modal.Title>View Credit App Data</Modal.Title>
      </Modal.Header>
      <Modal.Body
        className={`d-flex flex-column align-items-center ${
          errorMessage ? ' has-error' : ''
        }`}
      >
        <LockAnimation isOpen={lockOpen} />
        <h1>Credit App for:</h1>
        <h1>{customerAccount}</h1>
        <p>Please enter your password to access this information</p>
        <div className="d-flex">
          <input
            onKeyDown={(event) => {
              switch (event.key) {
                case 'Enter': {
                  submitAuth();
                  break;
                }
                default:
                  break;
              }
            }}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="form-control form-control-lg password-input"
          />
          <button
            type="button"
            onClick={submitAuth}
            className="btn submit-button"
          >
            Submit
          </button>
        </div>
        <p className="danger">{errorMessage}</p>
        <div className="d-flex w-100 justify-content-end">
          <button
            type="button"
            onClick={onCancel}
            className="btn cancel-button"
          >
            Cancel
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

CompletedCreditAppModal.propTypes = {
  customerCreditAppId: PropTypes.string.isRequired,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onCancel: PropTypes.func,
  getDecryptedCreditAppValues: PropTypes.func,
  customerAccount: PropTypes.string.isRequired,
};

CompletedCreditAppModal.defaultProps = {
  show: false,
  onHide: () => {},
  onCancel: () => {},
  getDecryptedCreditAppValues: () => {},
};

export default CompletedCreditAppModal;
