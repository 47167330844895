/**
 * @typedef {{
 * domainKey: string,
 * lastPath: string,
 * selectedOffice?: string,
 * partnerTag?: string,
 * }} NavigationDocType
 * @typedef {{}} NavigationMethods
 * @typedef {{}} NavigationCollectionMethods
 * @typedef {import("rxdb").RxDocument<
 *  NavigationDocType,
 *  NavigationMethods
 * >} NavigationDocument
 * @typedef {import("rxdb").RxCollection<
 *  NavigationDocType,
 *  NavigationMethods,
 *  NavigationCollectionMethods
 * >} NavigationCollection
 */

/** @type {import("rxdb").RxJsonSchema<NavigationDocType>} */
export const navigationSchema = {
  title: 'Navigation',
  description: "Navigation object to restore the user's session",
  version: 0, // Whenever a change is made to the schema, increment the version
  keyCompression: false,
  type: 'object',
  properties: {
    domainKey: { type: 'string', primary: true },
    lastPath: { type: 'string' },
    selectedOffice: { type: ['string', 'null'] },
    partnerTag: { type: ['string', 'null'] },
  },
};

/** @type {NavigationMethods} */
export const navigationMethods = {};

/** @type {NavigationCollectionMethods} */
export const navigationCollectionMethods = {};
