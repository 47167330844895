import React from 'react';
import PropTypes from 'prop-types';

const NoCountPageControl = (props) => {
  const { nextDisabled, activePage } = props;

  return (
    <div className="page-item d-flex no-count-page-control">
      <button
        type="button"
        onClick={() => {
          if (activePage > 1) {
            const newPage = activePage - 1;
            props.onChange(newPage);
          }
        }}
        className="page-link"
      >
        <i className="fas fa-chevron-left" />
      </button>
      <div className="page-item">
        <span className="page-link">{activePage}</span>
      </div>
      <button
        type="button"
        disabled={nextDisabled}
        onClick={() => {
          if (!nextDisabled) {
            const newPage = activePage + 1;
            props.onChange(newPage);
          }
        }}
        className="page-link"
      >
        <i className="fas fa-chevron-right" />
      </button>
    </div>
  );
};

NoCountPageControl.propTypes = {
  onChange: PropTypes.func.isRequired,
  nextDisabled: PropTypes.bool.isRequired,
  activePage: PropTypes.number.isRequired,
};

export default NoCountPageControl;
