/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import Parse from 'parse';
import { Card } from 'react-bootstrap';
import LeapLineChart from './LeapLineChart';

class LeapLineChartPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      data: [],
      lines: [],
    };
  }

  componentDidMount = async () => {
    const { endDate, startDateSetBack } = this.props;
    let { startDate } = this.props;
    if (startDateSetBack) {
      startDate = moment(endDate).subtract(
        startDateSetBack.num,
        startDateSetBack.frame,
      );
    }
    const results = await Parse.Cloud.run(this.props.cloudFunctionName, {
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
      ...this.props.cloudFunctionParams,
    });
    this.setState({
      ...results,
    });
  };

  componentDidUpdate = async (previousProps) => {
    const { startDate, endDate } = this.props;
    if (
      startDate !== previousProps.startDate ||
      endDate !== previousProps.endDate
    ) {
      const results = await Parse.Cloud.run(this.props.cloudFunctionName, {
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
        ...this.props.cloudFunctionParams,
      });
      this.setState({
        ...results,
      });
    }
  };

  render() {
    return (
      <Card style={{ width: 450, margin: 20 }}>
        <Card.Title
          style={{ display: 'flex', justifyContent: 'center', margin: 10 }}
          componentClass="h3"
        >
          {this.props.chartTitle}
        </Card.Title>
        <Card.Body
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <LeapLineChart {...{ ...this.props, ...this.state }} />
          <span>
            <b>Total</b>
          </span>
          <p>
            <b>{this.state.total}</b>
          </p>
        </Card.Body>
      </Card>
    );
  }
}

LeapLineChartPanel.propTypes = {
  title: PropTypes.string,
  yAxisLabel: PropTypes.string,
  cloudFunctionName: PropTypes.string.isRequired,
  cloudFunctionParams: PropTypes.shape({}).isRequired,
};

LeapLineChartPanel.defaultProps = {
  title: '',
  yAxisLabel: null,
};

const mapStateToProps = ({ leapAnalytics }) => {
  const { startDate, endDate } = leapAnalytics;
  return {
    startDate,
    endDate,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LeapLineChartPanel);
