import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { tagManagerMiddleware } from '../middleware';
import rootReducer from '../reducers';

// Check for redux dev tools in browser, if they exist and the environment is
// not production, wrap enhancers with dev tools extension, otherwise use
// default compose

const initDevTools = () => {
  if (window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    return window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Option to enable tracing the action to the dispatching function
      trace: true,
      traceLimit: 10,
    });
  }
  return false;
};

const composeEnhancers =
  (typeof window !== 'undefined' &&
    process.env.NODE_ENV !== 'production' &&
    initDevTools()) ||
  compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk, tagManagerMiddleware)),
);

export default store;
