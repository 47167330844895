/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Parse from 'parse';
import MultiSelect from './Misc/MultiSelect';

const valueRenderer = (value, options) => {
  if (value.length > 3 && value.length === options.length) {
    return `All ${value.length} Offices selected`;
  }
  switch (value.length) {
    case 0:
      return 'None Selected';
    case 1:
    case 2:
    case 3:
      return value
        .map((id) => options.find(({ value: officeId }) => id === officeId))
        .filter((obj) => obj)
        .map(({ label }) => label)
        .join(', ');
    default:
      return `${value.length} Offices selected`;
  }
};

const options = (offices, includeNone, excludeOffices) => {
  const values = offices.map((office) => ({
    value: office.id,
    label: office.get('name'),
  }));
  if (includeNone) {
    return [{ value: '**No Office**', label: '**No Office**' }, ...values];
  }
  if (excludeOffices.length > 0) {
    return values.filter(({ value }) => !excludeOffices.includes(value));
  }
  return values;
};

const IncludedOfficesDropDown = ({
  selected = [],
  offices = [],
  excludeOffices = [],
  onChange,
  includeNone,
  hideSelectAll,
  ...rest
}) => (
  <MultiSelect
    options={options(offices, includeNone, excludeOffices)}
    onSelectedChanged={onChange}
    selected={selected}
    selectAllLabel="Select All"
    valueRenderer={valueRenderer}
    hasSelectAll={!hideSelectAll && offices.length > 1}
    disableSearch={offices.length <= 1}
    {...rest}
  />
);

IncludedOfficesDropDown.propTypes = {
  offices: PropTypes.arrayOf(PropTypes.instanceOf(Parse.Object)).isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  includeNone: PropTypes.bool,
};

IncludedOfficesDropDown.defaultProps = {
  includeNone: false,
};

const mapStateToProps = ({ auth: { offices = [] } }) => ({
  offices,
});

export default connect(mapStateToProps)(IncludedOfficesDropDown);
