import React from 'react';
import PropTypes from 'prop-types';

const Conditional = ({ children, condition }) =>
  condition ? <>{children}</> : <></>;

Conditional.propTypes = {
  children: PropTypes.shape({}).isRequired,
  condition: PropTypes.bool.isRequired,
};

export default Conditional;
