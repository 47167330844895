/* 
  {
    <route permission string from server>: regex to test current relative path,
    ...
  } 
*/
const routePermissions = {
  'signup/prosell': /^\/signup\/prosell$/i,
  'signup/prosell/activate': /^\/signup\/prosell\/activate$/i,
  'signup/leap/activate': /^\/signup\/leap\/activate$/i,
  index: /^\/AppAppearance$/i,
  /*
    /attachments
    /estimate_details/:id
    /estimate_details/:id/logs
    /estimates_page
    /payments
    /payments/:id
  */
  estimates: /^\/(estimate_details(\/\w+|\/\w+\/logs)?|estimates_page|estimates|attachments|payments(\/\w+)?)$/i,
  emails: /^\/emails$/,
  check_blacklist: /^\/check_blacklist$/i,
  app_appearance: /^\/app_appearance$/i,
  /*
    /price_guide/copy/destination/:id
    /price_guide/copy/source
    /price_guide/increment_upcharges
  */
  price_guide: /^\/price_guide(\/(copy(\/(source|destination\/\w+))|increment_upcharges))?$/i,
  finance_options: /^\/finance_options$/i,
  contract_sending: /^\/contract_sending$/i,
  proposal_sending: /^\/proposal_sending$/i,
  report_sending: /^\/report_sending$/i,
  credit_app_upload: /^\/credit_app_upload$/i,
  results: /^\/results$/i,
  change_company: /^\/change_company$/i,
  email_links: /^\/email_links$/i,
  commission_formulas: /^\/commission_formulas$/i,
  company_logo: /^\/company_logo$/i,
  watermark: /^\/watermark$/i,
  new_user_defaults: /^\/new_user_defaults$/i,
  api_access: /^\/api_access$/i,
  placeholders: /^\/placeholders$/i,
  appointments: /^\/appointments$/i,
  masquerade: /^\/masquerade$/i,
  service_finance: /^\/service_finance$/i,
  greensky: /^\/greensky$/i,
  fortiva: /^\/fortiva$/i,
  provia: /^\/provia$/i,
  marketsharp: /^\/marketsharp$/i,
  leadperfection: /^\/leadperfection$/i,
  salesforce: /^\/salesforce$/i,
  dividend: /^\/dividend$/i,
  powerPay: /^\/powerPay$/i,
  sunlight: /^\/sunlight$/i,
  lendkey: /^\/lendkey$/i,
  mosaic: /^\/mosaic$/i,
  enerBank: /^\/enerBank$/i,
  foundation: /^\/foundation$/i,
  docusign: /^\/docusign$/i,
  jobnimbus: /^\/jobnimbus$/i,
  amerifirst: /^\/amerifirst$/i,
  pure: /^\/pure$/i,
  hover: /^\/hover$/i,
  available_contacts: /^\/available_contacts$/i,
  categories: /^\/categories$/i,

  payment_capture: /^\/payment_capture$/i,
  /*
    /groupme
    /groupme1
  */
  groupme: /^\/groupme1?$/i,
  /* 
    /packages
    /packages/edit/:id
    /packages/new
  */
  packages: /^\/packages(\/(new|edit\/\w+))?$/i,
  /*
    /price_formulas
    /price_formulas/edit/:id
  */
  price_formulas: /^\/price_formulas(\/edit\/\w+)?$/i,
  /*
    /custom_options
    /custom_documents/edit/:id
  */
  custom_options: /^\/(custom_options)?|(custom_documents(\/edit\/\w+))?$/i,
  /*
    /resources
    /resources/new
    /resources/edit/:id
    /resources/shared_resources
    /resources/shared_resources/:id
  */
  resources: /^\/resources(\/edit\/\w+|\/new|\/shared_resources(\/\w+)?)?$/i,
  /*
    /templates/:type
  */
  'templates/:type': /^\/templates\/\w+$/i,
  /*
    /templates/:type/template
  */
  'templates/:type/template': /^\/templates\/\w+\/template$/i,
  /*
    /templates/:type/template_group/:id
  */
  'templates/:type/template_group/:id': /^\/templates\/\w+\/template_group\/\w+$/i,
  /*
    /templates/:type/template_groups
  */
  'templates/:type/template_groups': /^\/templates\/\w+\/template_groups$/i,
  /*
    /templates/:type/:id
  */
  'templates/:type/:id': /^\/templates\/\w+\/\w+$/i,

  offices: /^\/offices$/i,
  'offices/new': /^\/offices\/new$/i,
  users: /^\/users$/i,
  'users/new': /^\/users\/new$/i,
  /*
    /users/edit/:id
  */
  'users/edit/:id': /^\/users\/edit\/\w+$/i,
  permissions: /^\/permissions$/i,
  /*
    /permissions/edit/:id
  */
  'permissions/edit/:id': /^\/permissions\/edit\/\w+$/i,
  'permissions/new': /^\/permissions\/new$/i,
  account: /^\/account$/i,
  shared_resources: /^\/shared_resources$/i,
  /*
    /shared_resources/edit/:id
  */
  'shared_resources/edit/:id': /^\/shared_resources\/edit\/\w+$/i,
  'shared_resources/add': /^\/shared_resources\/add$/i,
  copy_price_guide: /^\/copy_price_guide$/i,
  copy_templates: /^\/copy_templates$/i,
  copy_data: /^\/copy_data$/i,
  email_usage_report: /^\/email_usage_report$/i,
  new_company: /^\/new_company$/i,
  leap_analytics: /^\/leap_analytics$/i,
  /*
    /credit_applications
    /credit_applications/edit/:id
    /credit_applications/new
    /credit_applications/new/from_partner
  */
  credit_applications: /^\/credit_applications(\/(new(\/from_partner)?|edit\/\w+|))?$/i,
  'change-logs': /^\/change-logs$/i,
  'admin-change-logs': /^\/admin-change-logs$/i,
};

export default routePermissions;
