const detailRowUpdate = (name, value) => {
  switch (name) {
    case 'minSizePickerWidth':
    case 'maxSizePickerWidth':
    case 'minSizePickerHeight':
    case 'maxSizePickerHeight':
    case 'minSizePickerDepth':
    case 'maxSizePickerDepth': {
      return { name, value: parseFloat(value) };
    }
    default:
      return { name, value };
  }
};

export default detailRowUpdate;
