// @ts-check
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { cloneDeep, snakeCase } from 'lodash';
import {
  menuTemplate,
  isLink,
  isParentLink,
  mapTemplateToArr,
} from './menuTemplate';
import adminMenuTemplate from './adminMenuTemplate';
import { defaultPlanState } from '../../../reducers/plan';
import { defaultAuthState } from '../../../reducers/auth';

/**
 * Redux store selector to get relavent menu items per permissions and company
 * customizations
 * @param {import('../../../reducers').State} state
 */
const getMenuSettings = ({
  plan: {
    allowedIntegration = [],
    allowedAppSettings = [],
    allowedMainFeatures = [],
    mainMenuLabelOverrides = {},
    maxOfficeCount = 0,
  } = { ...defaultPlanState },
  auth: {
    config: { mainMenuOptions = [], appTitles = {} } = {},
  } = defaultAuthState,
}) => {
  return {
    allowedAppSettings,
    allowedIntegration,
    allowedMainFeatures,
    appTitles,
    customOptions: mainMenuOptions.map(
      ({ title, objectId, webIcon = 'fa fa-layer-group' }) => ({
        title: title.toLowerCase(),
        objectId,
        icon: webIcon,
      }),
    ),
    mainMenuLabelOverrides,
    maxOfficeCount,
  };
};

/**
 * Redux store selector to ascertain if the user is a corporate admin
 * @param {import('../../../reducers').State} state
 */
const getAdminStatus = ({
  auth: { isCorporateAdmin = false } = defaultAuthState,
}) => isCorporateAdmin;

const useMenuItems = () => {
  const menuItems = cloneDeep(menuTemplate);
  const {
    appTitles,
    allowedAppSettings,
    allowedIntegration,
    allowedMainFeatures,
    customOptions,
    mainMenuLabelOverrides,
    maxOfficeCount,
  } = useSelector(getMenuSettings);
  const isAdmin = useSelector(getAdminStatus);
  const { pathname } = useLocation();
  const isAdminRoute = adminMenuTemplate.has(
    pathname.split('/')[1].toLowerCase(),
  );

  const baseFetures = [
    'emails',
    'offices',
    'users',
    'permissions',
    'account',
    'onboarding-status-report',
    'event-log',
    // 'company_setttings',
  ];
  /**
   * @type {Array<string>}
   */
  const allowedKeys = [
    ...baseFetures,
    ...allowedAppSettings,
    ...allowedIntegration,
    ...allowedMainFeatures,
  ];

  menuItems.forEach((value, key) => {
    // If it's a menu item that doesn't exist in allowedKeys, remove it
    if (isLink(value)) {
      if (!allowedKeys.includes(key)) {
        menuItems.delete(key);
      }
    }
    // If it's a menu category, traverse the sub links and remove them if they
    // don't exist in allowedKeys
    if (isParentLink(value)) {
      value.subLinks.forEach((subVal, subKey) => {
        if (!allowedKeys.includes(subKey)) {
          value.subLinks.delete(subKey);
        }
      });

      // If the user has no access to any sublinks, remove the category
      if (value.subLinks.size === 0) {
        menuItems.delete(key);
      }
    }
  });

  // Create menu items for custom documents
  /**
   * @type {[string, import('./menuTemplate').ParentLink][]}
   */
  const customDocuments = customOptions.map(({ title, objectId, icon }) => ([
    objectId,
    {
      title,
      icon,
      subLinks: new Map([
        [
          `${title}_template`,
          {
            title: `${title} Templates`,
            anchor: `templates/${objectId}`,
          },
        ],
        [
          `${title}_sending`,
          {
            title: `${title} Sending`,
            anchor: `custom_documents/edit/${objectId}`,
          },
        ],
      ]),
    },
  ]));
  // Break the menu item map back into an array, so we can insert the custom
  // docs at the correct position (after proposals)
  const menuItemsArr = [...menuItems];
  const proposalsIndex = menuItemsArr.findIndex(([key]) => key === 'proposals');
  menuItemsArr.splice(proposalsIndex + 1, 0, ...customDocuments);
  // Clear and re-populate the map now that custom docs are inserted at the
  // correct location
  menuItems.clear();
  for (const link of menuItemsArr) {
    menuItems.set(link[0], link[1]);
  }

  // Set menu label overrides
  for (const key in mainMenuLabelOverrides) {
    if (Object.prototype.hasOwnProperty.call(mainMenuLabelOverrides, key)) {
      /**
       * Keys in the mainMenuLabelOverrides obj are camel cased, need to make
       * them snake cased to match the menu item map keys
       */
      const snakeKey = snakeCase(key);
      /**
       * Hard coded check to ignore 'settings' main menu label override, since
       * that is only for the app, not the dashboard
       */
      if (snakeKey !== 'settings') {
        const { title } = mainMenuLabelOverrides[key];
        // Set locked top level links and parent items
        if (menuItems.has(snakeKey)) {
          const menuItem = menuItems.get(snakeKey);
          menuItems.set(snakeKey, { ...menuItem, title });
        }
      }
    }
  }

  // Set user label overrides
  Object.keys(appTitles).forEach((appTitle) => {
    const title = appTitles[appTitle];
    if (title) {
      /**
       * Manual override because availableContacts is set as availableManagers
       * and resources are pitchBooks server side on the default plan object
       * (config > appTitles).
       */
      const appTitleOverride = {
        availableManagers: 'available_contacts',
        pitchBooks: 'resources',
      };
      const key = appTitleOverride[appTitle] ?? snakeCase(appTitle);
      if (menuItems.has(key)) {
        menuItems.set(key, { ...menuItems.get(key), title });
        if (key === 'contracts') {
          const contractsItem = menuItems.get('contracts');
          if (isParentLink(contractsItem)) {
            const { subLinks } = contractsItem;
            subLinks.set('contract', {
              ...subLinks.get('contract'),
              title: `${title} Templates`,
            });
            subLinks.set('contract_sending', {
              ...subLinks.get('contract_sending'),
              title: `${title} Sending`,
            });
          }
        }
        if (key === 'proposals') {
          const proposalsItem = menuItems.get('proposals');
          if (isParentLink(proposalsItem)) {
            const { subLinks } = proposalsItem;
            subLinks.set('proposal', {
              ...subLinks.get('proposal'),
              title: `${title} Templates`,
            });
            subLinks.set('proposal_sending', {
              ...subLinks.get('proposal_sending'),
              title: `${title} Sending`,
            });
          }
        }
      } else {
        [...menuItems.values()].some((menuItem) => {
          if (isParentLink(menuItem)) {
            if (menuItem.subLinks.has(key)) {
              menuItem.subLinks.set(key, {
                ...menuItem.subLinks.get(key),
                title,
              });
              return undefined;
            }
          }
          return undefined;
        });
      }
    }
  });

  if (maxOfficeCount === 1) {
    const companySettings = menuItems.get('company_settings');
    if (isParentLink(companySettings)) {
      companySettings.subLinks.delete('offices');
    }
  }

  return isAdmin && isAdminRoute
    ? mapTemplateToArr(adminMenuTemplate)
    : mapTemplateToArr(menuItems);
};

export default useMenuItems;
