/* eslint-disable import/no-named-as-default */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { connect } from 'react-redux';
import { withLayoutContext, layoutContextPropTypes } from '../../Layout';
import { startUpdateConfig } from '../../../actions/auth';
import FormGroup, { PasswordGroup } from '../../FormGroup';
import APIDropDown from '../APIDropDown';
import TemplateSending from './TemplateSending';
import Panel from '../../Panel';
import { validateAll } from './Validation/CreditAppSending';

const defaultUploadSettings = {
  fileName: '%customerName% Attachment.pdf',
  fileDescription: '%date% Attachment',
  backupRecipients: ['%userEmail%'],
};

const formattedSettings = (settings) => ({
  ...settings,
  webhook: settings.webhook || {},
  salesforce: settings.salesforce || {},
  leadPerfection: settings.leadPerfection || defaultUploadSettings,
  jobNimbus: settings.jobNimbus || defaultUploadSettings,
  password: settings.password || '',
});

export class CreditAppSending extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };
  }

  componentDidMount() {
    const {
      setCrumbs,
      location: { pathname },
      settings,
    } = this.props;

    const crumb = { title: 'Credit App Sending', link: pathname };
    setCrumbs([crumb]);

    this.stateFromSettings(settings);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { settings, config } = newProps;
    const change = !isEqual(config.objectId, this.props.config.objectId);
    if (change) {
      this.stateFromSettings(settings);
    }
  }

  stateFromSettings = (settings = {}) => {
    const newState = formattedSettings(settings);
    const creditAppUpload = {
      ...newState,
      confirmPassword: newState.password,
    };
    const errors = validateAll(creditAppUpload);
    this.setState({ ...creditAppUpload, errors });
  };

  isValid = () => {
    const valid = Object.values(this.state.errors).reduce((prev, errVal) => {
      switch (typeof errVal) {
        case 'string': {
          // if there is no error, string will be empty
          return errVal.length === 0 && prev;
        }
        case 'object': {
          if (Array.isArray(errVal)) {
            return errVal.length === 0 && prev;
          }
          return Object.values(errVal).reduce(
            (prev_, errVal_) => errVal_.length === 0 && prev_,
            prev,
          );
        }
        default:
          return false;
      }
    }, true);
    return valid;
  };

  onSaveClicked = () => {
    const { errors, confirmPassword, ...creditAppUpload } = this.state;
    const { password } = creditAppUpload;
    if (!password || (password && password === confirmPassword)) {
      this.props.startUpdateConfig({ creditAppUpload });
    }
  };

  onValueChanged = ({ key, value }) => {
    const newState = {
      ...this.state,
      [key]: value,
    };
    newState.errors = validateAll(newState);
    this.setState(newState);
  };

  settingsSection = () => (
    <Panel title="Credit App Sending">
      <FormGroup title="APIs">
        <APIDropDown
          selectedOptions={this.state.endpoints}
          onChange={(value) => this.onValueChanged({ key: 'endpoints', value })}
        />
      </FormGroup>
      <PasswordGroup
        title="PDF Password"
        value={this.state.password}
        placeholder="PDF Password"
        onChange={(value) => this.onValueChanged({ key: 'password', value })}
        errorMessage={this.state.errors.password}
      />
      <FormGroup title="">
        <p>
          This password cannot be recovered if lost. If this password is
          changed, it will not affect previously created PDF documents.
        </p>
      </FormGroup>
      <PasswordGroup
        title="Confirm PDF Password"
        value={this.state.confirmPassword}
        placeholder="Confirm PDF Password"
        onChange={(value) =>
          this.onValueChanged({
            key: 'confirmPassword',
            value,
          })
        }
        errorMessage={this.state.errors.confirmPassword}
      />
    </Panel>
  );

  render() {
    return (
      <TemplateSending
        settingsSection={this.settingsSection}
        onValueChanged={this.onValueChanged}
        onSaveClicked={this.onSaveClicked}
        title="Credit App Sending"
        onCopy={() => this.setState({ showCopy: true })}
        onCopyClose={() => this.setState({ showCopy: false })}
        showCopy={this.state.showCopy}
        copyKey="creditAppUpload"
        disableSave={!this.isValid()}
        {...this.state}
      />
    );
  }
}

CreditAppSending.defaultProps = {
  settings: {
    leadPerfection: {
      fileName: '%customerName% Attachment.pdf',
      fileDescription: '%date% Attachment',
      backupRecipients: ['%userEmail%'],
    },
    salesforce: {
      fileName: '',
      backupRecipients: [],
    },
    jobNimbus: {
      fileName: '%customerName% Attachment.pdf',
      fileDescription: '%date% Attachment',
      backupRecipients: ['%userEmail%'],
    },
    webhook: {
      includePDF: false,
      url: '',
      authHeader: '',
    },
    endpoints: [],
    fillEmptyWhiteSpace: false,
    emptyWhiteSpaceNote: '',
    useBrochures: false,
    useVerifyEmail: false,
    from: '',
    subjectLine: '',
    recipients: [],
    ccRecipients: [],
    bccRecipients: [],
    emailHTML: '',
    emailFileName: '',
    fileName: '',
    disclosure: '',
    password: '',
  },
};

CreditAppSending.propTypes = {
  startUpdateConfig: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    leadPerfection: PropTypes.shape({
      fileName: PropTypes.string,
      fileDescription: PropTypes.string,
      backupRecipients: PropTypes.arrayOf(PropTypes.string),
    }),
    salesforce: PropTypes.shape({
      fileName: PropTypes.string,
      fileDescription: PropTypes.string,
      backupRecipients: PropTypes.arrayOf(PropTypes.string),
    }),
    jobNimbus: PropTypes.shape({
      fileName: PropTypes.string,
      fileDescription: PropTypes.string,
      backupRecipients: PropTypes.arrayOf(PropTypes.string),
    }),
    webhook: PropTypes.shape({
      includePDF: PropTypes.bool,
      url: PropTypes.string,
      authHeader: PropTypes.string,
    }),
    endpoints: PropTypes.arrayOf(PropTypes.string),
    fillEmptyWhiteSpace: PropTypes.bool,
    emptyWhiteSpaceNote: PropTypes.string,
    useBrochures: PropTypes.bool,
    useVerifyEmail: PropTypes.bool,
    from: PropTypes.string,
    subjectLine: PropTypes.string,
    recipients: PropTypes.arrayOf(PropTypes.string),
    ccRecipients: PropTypes.arrayOf(PropTypes.string),
    bccRecipients: PropTypes.arrayOf(PropTypes.string),
    emailHTML: PropTypes.string,
    emailFileName: PropTypes.string,
    backupRecipients: PropTypes.arrayOf(PropTypes.string),
    fileName: PropTypes.string,
    disclosure: PropTypes.string,
    password: PropTypes.string,
  }),
  config: PropTypes.shape({ objectId: PropTypes.string }).isRequired,
  ...layoutContextPropTypes,
};

const mapStateToProps = ({ auth: { config = {} } }) => ({
  config,
  settings: { ...config.creditAppUpload },
});

const mapDispatchToProps = (dispatch) => ({
  startUpdateConfig: (updates) => dispatch(startUpdateConfig(updates)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(CreditAppSending),
);
