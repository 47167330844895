import Parse from 'parse';
// eslint-disable-next-line no-unused-vars
import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { handleError } from '../actions/auth';
import { randomKey } from '../utils/utils';
import UserContext from './UserContext';

const AccessControl = () => {
  const { user } = useContext(UserContext);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      const checkDashboardAccess = async () => {
        const company = user.get('company');
        await company.fetch();
        const planObj = company.get('plan');
        await planObj.fetch();
        const { allowedApps } = planObj.attributes;
        const db2Access = allowedApps.includes('DB2');
        const db3Access = allowedApps.includes('DB3');
        if (!db2Access) {
          if (db3Access) {
            try {
              const key = randomKey(10);
              const { sessionId } = await Parse.Cloud.run(
                'generate_temp_session',
                {
                  key,
                },
              );
              window.open(
                `${process.env.REACT_APP_DASH360_URL}?sessionId=${sessionId}&key=${key}`,
                '_self',
              );
            } catch (error) {
              dispatch(handleError(error));
            }
          } else {
            const accessDeniedError = new Error(
              'Your company is not setup to access the Leap Dashboard, please contact support for further information.',
            );
            accessDeniedError.code = 403;
            dispatch(handleError(accessDeniedError, true));
          }
        }
      };
      try {
        checkDashboardAccess();
      } catch (e) {
        dispatch(handleError(e));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return null;
};

export default AccessControl;
