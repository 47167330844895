/* eslint-disable func-names */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { useWindowSize } from '../../hooks';
import { setOnDragEndCallback } from '../DropContext';
import { reorder } from '../../utils/utils';

const DragGrid = ({
  items,
  onCell,
  onCombine,
  onDragableId,
  onUpdatedItemOrder,
  pagenationSkip,
  canCombine = () => false,
}) => {
  const [width] = useWindowSize();
  const [rows, setRows] = useState([]);
  const breakPointsConfig = {
    lg: 4,
    md: 3,
    sm: 2,
    xs: 1,
    xxs: 1,
  };
  const breakPoint =
    width < 550
      ? 'xxs'
      : width > 550 && width < 768
      ? 'xs'
      : width > 768 && width < 1030
      ? 'sm'
      : width > 1030 && width < 1300
      ? 'md'
      : 'lg';

  const setRowDropCallbacks = (rows_) => {
    rows_.forEach((row, i) => {
      setOnDragEndCallback(`drag-grid-row-${i}`, (result) => {
        const { destination = {}, source = {}, combine = {} } = result;

        if (!(result.draggableId.indexOf('drag-grid-item-') > -1)) {
          return;
        }
        const sourceRowIndex = parseInt(
          source.droppableId.replace('drag-grid-row-', ''),
          10,
        );
        const sourceRow = rows_[sourceRowIndex];

        const validCombine = canCombine(result);

        if (validCombine) {
          onCombine({
            combine,
            source,
            sourceRowIndex,
            rows: rows_,
            items: items(),
          });
          return;
        }

        if (
          destination === null ||
          !(destination.droppableId.indexOf('drag-grid-row-') > -1)
        ) {
          return;
        }
        const destinationRowIndex = parseInt(
          destination.droppableId.replace('drag-grid-row-', ''),
          10,
        );
        const destinationRow = rows_[destinationRowIndex];

        if (destinationRowIndex === sourceRowIndex) {
          inRowUpdate({
            rows: rows_,
            destinationRowIndex,
            result,
            destinationRow,
          });
        } else {
          outRowUpdate({
            rows: rows_,
            destinationRowIndex,
            destinationRow,
            sourceRowIndex,
            sourceRow,
            result,
          });
        }
      });
    });
  };

  const calculateRows = (items_) => {
    const numOfColumns = breakPointsConfig[breakPoint];
    const update = [];

    items_.forEach((item, i) => {
      const rowNum = Math.floor(i / numOfColumns);
      if (update[rowNum]) {
        update[rowNum].items.push(item);
      } else {
        update.push({ items: [] });
        update[rowNum].items.push(item);
      }
    });
    setRows(update);
    setRowDropCallbacks(update);
  };

  useEffect(
    function() {
      calculateRows(items());
    },
    [items(), width],
  );

  const setOrderNumbers = (rows_) => {
    const updatedItems = [];
    rows_.forEach((row) => {
      row.items.forEach((item) => {
        updatedItems.push({
          ...item,
          orderNumber: updatedItems.length + pagenationSkip(),
        });
      });
    });
    calculateRows(updatedItems);
    onUpdatedItemOrder(updatedItems);
  };

  const inRowUpdate = ({
    rows: rows_,
    destinationRowIndex,
    result,
    destinationRow,
  }) => {
    const rowItemsUpdate = reorder(
      destinationRow.items,
      result.source.index,
      result.destination.index,
    );
    const newRows = rows_.map((row, count) => {
      if (count === destinationRowIndex) {
        return {
          ...row,
          items: rowItemsUpdate,
        };
      }
      return row;
    });
    setOrderNumbers(newRows);
  };

  const outRowUpdate = ({
    rows: rows_,
    destinationRowIndex,
    sourceRowIndex,
    sourceRow,
    result,
    destinationRow,
  }) => {
    const sourceItem = sourceRow.items[result.source.index];

    let destinationUpdate = destinationRow.items.reduce(
      (newList, item, index) => {
        if (index === result.destination.index) {
          return [...newList, sourceItem, item];
        }
        return [...newList, item];
      },
      [],
    );
    if (result.destination.index >= destinationRow.items.length) {
      destinationUpdate = [...destinationRow.items, sourceItem];
    }
    const sourceUpdate = sourceRow.items.reduce((newList, item, index) => {
      if (index === result.source.index) {
        return [...newList];
      }
      return [...newList, item];
    }, []);

    const newRows = rows_.map((row, count) => {
      if (count === destinationRowIndex) {
        return {
          ...row,
          items: destinationUpdate,
        };
      }
      if (count === sourceRowIndex) {
        return {
          ...row,
          items: sourceUpdate,
        };
      }
      return row;
    });
    setOrderNumbers(newRows);
  };

  return (
    <div className="drag-grid-container">
      {rows.map((row, rowIndex) => (
        <Droppable
          key={rowIndex}
          droppableId={`drag-grid-row-${rowIndex}`}
          direction="horizontal"
          isCombineEnabled
        >
          {(provided) => (
            <div
              style={{
                marginBottom: 10,
                position: 'relative',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {row.items.map((item, index) => (
                <Draggable
                  key={onDragableId({
                    item,
                    row,
                    index,
                    rowIndex,
                  })}
                  draggableId={onDragableId({
                    item,
                    row,
                    index,
                    rowIndex,
                  })}
                  index={index}
                >
                  {(draggableProvided, snapshot) => (
                    <div
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                    >
                      <div
                        style={{
                          marginLeft: 5,
                          marginRight: 5,
                        }}
                      >
                        {onCell({
                          item,
                          row,
                          index,
                          rowIndex,
                          snapshot,
                        })}
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      ))}
    </div>
  );
};

DragGrid.propTypes = {
  items: PropTypes.func.isRequired,
  onCell: PropTypes.func.isRequired,
  onCombine: PropTypes.func,
  canCombine: PropTypes.func,
  onDragableId: PropTypes.func.isRequired,
  onUpdatedItemOrder: PropTypes.func.isRequired,
  pagenationSkip: PropTypes.func.isRequired,
};

DragGrid.defaultProps = {
  onCombine: () => {},
  canCombine: () => {},
};

export default DragGrid;
