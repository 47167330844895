import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import safeFetch from 'fetch-ie8';

const zipDocuments = async (documents) => {
  const zip = new JSZip();
  documents.forEach((doc) => {
    zip.file(doc.fileName, doc.buffer);
  });
  const content = await zip.generateAsync({ type: 'blob' });
  saveAs(content, 'documents.zip');
};

const getDocumentData = async (doc) => {
  if (fetch) {
    const response = await fetch(decodeURIComponent(doc.file.url()));
    const buffer = await response.arrayBuffer();
    return { ...doc, buffer };
  }
  const response = await safeFetch(doc.file.url());
  const buffer = await response.arrayBuffer();
  return { ...doc, buffer };
};

const getAllDocumentsData = async (docs) => {
  const allDocsPromises = docs
    .filter((doc) => doc)
    .map((doc) => getDocumentData(doc));
  const allDocsData = await Promise.all(allDocsPromises);
  return allDocsData;
};

// eslint-disable-next-line import/prefer-default-export
export const dumpDocumentLinks = async ({ estimate, mainMenuOptions }) => {
  const contractPDF = estimate.get('contractPDF');
  const proposalPDF = estimate.get('proposalPDF');
  const resultPDF = estimate.get('resultPDF');
  const creditAppPDF = estimate.get('creditAppPDF');
  const reportPDF = estimate.get('reportPDF');
  const contractVerifyPDF = estimate.get('contractVerifyPDF');
  const proposalVerifyPDF = estimate.get('proposalVerifyPDF');

  const docs = [
    {
      fileName: 'Contract.pdf',
      file: contractPDF,
    },
    {
      fileName: 'Proposal.pdf',
      file: proposalPDF,
    },
    {
      fileName: 'Contract Acceptance.pdf',
      file: contractVerifyPDF,
    },
    {
      fileName: 'Proposal Acceptance.pdf',
      file: proposalVerifyPDF,
    },
    {
      fileName: 'Results.pdf',
      file: resultPDF,
    },
    {
      fileName: 'Credit App.pdf',
      file: creditAppPDF,
    },
    {
      fileName: 'Report.pdf',
      file: reportPDF,
    },
  ];
  mainMenuOptions.forEach(({ objectId: fileObjectId, title }) => {
    const documents = estimate.get('documents') || {};
    const file = documents[fileObjectId];
    if (file) {
      docs.push({
        fileName: `${title}.pdf`,
        file,
      });
    }
  });
  const filteredDocs = docs.filter(({ file }) => file);
  const allDocsData = await getAllDocumentsData(filteredDocs);
  await zipDocuments(allDocsData);
};
