/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import moment from 'moment';
import Parse from 'parse';
import { useSelector } from 'react-redux';

const useZapTriggers = () => {
  const [zaps, setZaps] = useState([]);

  const zapierConfig = useSelector(({ auth }) => {
    const { config = {} } = auth;
    const { zapier = {} } = config;

    return zapier;
  });

  const { triggers } = zapierConfig;

  const initZaps = async () => {
    const zapData = await getZapData(zapierConfig);
    setZaps(zapData);
  };

  useEffect(() => {
    initZaps();
  }, [triggers]);

  return { zaps };
};

const getUserData = async (id) => {
  const parseuser = new Parse.User();
  parseuser.id = id;

  const nameFirst = parseuser.get('nameFirst');
  const nameLast = parseuser.get('nameLast');

  if (!nameFirst || !nameLast) {
    await parseuser.fetch();
  }

  return {
    id,
    nameFirst: parseuser.get('nameFirst'),
    nameLast: parseuser.get('nameLast'),
  };
};

const getZapData = async (zapierConfig) => {
  const { triggers = [] } = zapierConfig;

  const { results, ...otherTriggers } = triggers;

  let createdDate;
  let zapUser;

  const allZaps = ['results', ...Object.keys(otherTriggers)].reduce(
    (all, trigger) => {
      const sectionTriggers = triggers[trigger] || [];

      const labeledTriggers = sectionTriggers.map((sectionTrigger) => {
        const { createdAt, createdBy } = sectionTrigger;
        const date = createdAt
          ? moment(createdAt).format('MM/DD/YYYY')
          : undefined;
        if (date) {
          createdDate = date;
        }

        const user = createdBy;
        if (user) {
          zapUser = user;
        }

        return {
          ...sectionTrigger,
          date,
          trigger,
          user,
        };
      });

      return [...all, ...labeledTriggers];
    },
    [],
  );

  const collectedUsers = allZaps.reduce((collected, zap) => {
    const { user } = zap;
    const userid = user || zapUser;
    if (!collected.includes(userid)) {
      return [...collected, userid];
    }
    return collected;
  }, []);

  const fetcthedUserData = await Promise.all(
    collectedUsers.map(async (userid) => getUserData(userid)),
  );

  const usersData = fetcthedUserData.reduce((users, userData) => {
    const { id } = userData;
    return {
      ...users,
      [id]: userData,
    };
  }, {});

  const formatted = allZaps.map((zap) => {
    const { date, user } = zap;
    const commonDate = date || createdDate;
    const userid = user || zapUser;

    const { nameFirst = 'N/A', nameLast = 'N/A' } = usersData[userid] || {
      nameFirst: 'N/A',
      lastName: 'N/A',
    };
    return {
      ...zap,
      commonDate,
      reportedUser: `${nameLast}, ${nameFirst}`,
    };
  });

  return formatted;
};

export default useZapTriggers;
