export const defaultState = {
  checkList: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_ONBOARDING_STATUS_REPORT': {
      return {
        ...state,
        ...action.onboardingStatusReport,
      };
    }
    default:
      return state;
  }
};
