/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Panel from '../Panel';
import { upperWords } from '../../utils/utils';
import { AppTitleHeaderCell } from './AppAppearance';

const AppTitleRow = ({
  id,
  disabled,
  defaultTitle,
  title,
  onChange,
  showNewCustomMenu,
  value,
  source,
  error,
}) => {
  return (
    <>
      {showNewCustomMenu ? (
        <tr className="app-appearance-row">
          <td className="app-appearance-cell app-appearance-cell-title">
            {defaultTitle}
          </td>
          <td className="app-appearance-cell app-appearance-cell-value">
            <input
              type="text"
              value={title || ''}
              placeholder={title}
              disabled={disabled}
              onChange={(e) => onChange(e, id)}
            />
            {error && <div style={{ color: 'red' }}>{error}</div>}
          </td>
        </tr>
      ) : (
        <tr className="app-appearance-row">
          <td className="app-appearance-cell app-appearance-cell-title">
            {title}
          </td>
          <td className="app-appearance-cell app-appearance-cell-value">
            <input
              disabled={disabled}
              type="text"
              value={value}
              placeholder={title}
              onChange={(e) => onChange({ ...e, source })}
            />
          </td>
        </tr>
      )}
    </>
  );
};

AppTitleRow.propTypes = {
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  defaultTitle: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  error: PropTypes.shape({}).isRequired,
  source: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  showNewCustomMenu: PropTypes.bool.isRequired,
};

const AppTitle = ({
  onTextInputChange,
  appTitles,
  error,
  mainMenuLabelOverrides,
  showNewCustomMenu,
  customMenuItem,
}) => {
  const defaultCustomizeMenuTitles = {
    availableManagers: 'Available Contacts',
    contracts: 'Contracts',
    creditApplication: 'Credit Application',
    customers: 'Customers',
    estimates: 'Estimates',
    financeCalculator: 'Finance Calculator',
    pitchBooks: 'Resources',
    priceGuide: 'Price Guide',
    proposals: 'Proposals',
    settings: 'Settings',
    tasks: 'Tasks',
  };

  return (
    <>
      {showNewCustomMenu ? (
        <Panel title="Main Menu">
          <div style={{ fontWeight: 700 }}>
            To reorder menu items on the SalesPro App, please use the{' '}
            <a
              href="https://dashboard.leaptodigital.com/settings/app/customize_menu"
              target="_blank"
              rel="noopener noreferrer"
            >
              Redesigned Dashboard
            </a>
            .
          </div>
          <div style={{ marginTop: '32px' }}>
            <p>
              Replace menu item names using the fields on the right. Document
              Types can be renamed on the “Document Types” page under App
              Settings.
            </p>
            <table className="table">
              <thead className="table-header">
                <tr>
                  <AppTitleHeaderCell title="Default Title" />
                  <AppTitleHeaderCell title="Custom Title" />
                </tr>
              </thead>
              <tbody>
                {customMenuItem?.length &&
                  customMenuItem.map((item) => {
                    const defaultTitle =
                      defaultCustomizeMenuTitles[item.key] ||
                      upperWords(item.key);
                    return (
                      <AppTitleRow
                        key={item.id}
                        id={item.id}
                        defaultTitle={defaultTitle}
                        title={item.title}
                        onChange={onTextInputChange}
                        disabled={item.objectId}
                        customMenuItem={customMenuItem}
                        showNewCustomMenu={showNewCustomMenu}
                        error={error[item.id]}
                      />
                    );
                  })}
              </tbody>
            </table>
          </div>
        </Panel>
      ) : (
        <Panel title="Main Menu">
          <div>
            <table className="table">
              <thead className="table-header">
                <tr>
                  <AppTitleHeaderCell title="Default Title" />
                  <AppTitleHeaderCell title="New Title" />
                </tr>
              </thead>
              <tbody>
                {Object.keys(mainMenuLabelOverrides)
                  .map((labelDataKey) => ({
                    ...mainMenuLabelOverrides[labelDataKey],
                    labelDataKey,
                  }))
                  .map((labelData) => {
                    const { labelDataKey, title, locked } = labelData;
                    const value = locked ? title : appTitles[labelDataKey];
                    return (
                      <AppTitleRow
                        disabled={locked}
                        title={title}
                        source={labelDataKey}
                        value={value}
                        onChange={onTextInputChange}
                      />
                    );
                  })}
              </tbody>
            </table>
          </div>
        </Panel>
      )}
    </>
  );
};

const appTitlePropType = PropTypes.shape({
  estimates: PropTypes.string,
  contracts: PropTypes.string,
  proposals: PropTypes.string,
  financeCalculator: PropTypes.string,
  priceGuide: PropTypes.string,
  availableManagers: PropTypes.string,
  pitchBooks: PropTypes.string,
  settings: PropTypes.string,
  creditApplication: PropTypes.string,
});

AppTitle.propTypes = {
  onTextInputChange: PropTypes.func.isRequired,
  customMenuItem: PropTypes.shape([]).isRequired,
  mainMenuLabelOverrides: PropTypes.shape({}).isRequired,
  appTitles: appTitlePropType.isRequired,
  error: PropTypes.shape({}).isRequired,
  showNewCustomMenu: PropTypes.bool.isRequired,
};

export const mapStateToProps = ({
  plan: { mainMenuLabelOverrides = {} } = {},
}) => ({
  mainMenuLabelOverrides,
});

export const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AppTitle);
