/* eslint-disable no-use-before-define */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import MultiSelectComponent from '@khanacademy/react-multi-select';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import { validateEmailAddress } from '../AppSettings/ContractSending/Validation/ContractSending';
import FormGroup, {
  TagGroup,
  ButtonGroup,
  TextGroup,
  PasswordGroup,
  DropDownGroup,
  TextAreaGroup,
} from '../FormGroup';
import {
  startGetPaymentGateways as startGetPaymentGatewaysAction,
  startUpdatePaymentGateway,
} from '../../actions/paymentCapture';
import { startUpdateConfig } from '../../actions/auth';
import { showDeleteConfirmation } from '../../actions/deleteConfirmation';
import { pushToDataLayer } from '../../actions/tagManager';
import { showWarningAlert } from '../../actions/alert';
import Panel from '../Panel';
import TitleButton from '../TitleButton';
import Copy from '../AppSettings/Copy';
import { checkspfRecords } from '../../actions/spfRecords';
import { cleanHtml } from '../../utils/cleanHtml';
import {
  paymentRequestEmailTemplate,
  defaultEmailSubject,
} from '../../utils/paymentRequestEmailTemplate';
import EmailPreview from '../Misc/EmailPreview';

const allowedPaymentSelectOptions = [
  {
    label: 'Credit Card',
    value: 'Credit Card',
  },
  {
    label: 'Bank/eCheck',
    value: 'Bank/eCheck',
  },
];

const validateRedirectURL = (url) => {
  if (url) {
    const isHttp = url.substring(0, 7) === 'http://';
    const isHttps = url.substring(0, 8) === 'https://';
    return isHttp || isHttps ? '' : 'Must be Valid Url ( http:// or https:// )';
  }
  return undefined;
};

export class PaymentCapture extends React.Component {
  constructor(props) {
    super(props);
    const { gateway = {} } = props;
    const { gatewayType, paymentRequest = {} } = gateway || {};

    this.state = {
      converted: '',
      enabled: gateway.enabled,
      gatewayType,
      termsAccepted: false,
      showEmailPreview: false,
      requestEmailSubject:
        paymentRequest.requestEmailSubject || defaultEmailSubject,
      defaultFromEmail:
        paymentRequest.defaultFromEmail || 'noreply@leaptodigital.com',
      redirectURL: paymentRequest.redirectURL || '',
      defaultEmailTemplate:
        paymentRequest.defaultEmailTemplate || paymentRequestEmailTemplate,
      allowedPaymentOptions: paymentRequest.allowedPaymentOptions || [],
      notificationRecipients: paymentRequest.notificationRecipients || [],
    };
  }

  componentDidMount() {
    const {
      location: { pathname },
      startGetPaymentGateways,
    } = this.props;
    startGetPaymentGateways();
    const crumb = { title: 'Secure Payment Capture', link: pathname };
    this.props.setCrumbs([crumb]);
    this.renderButtons();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.selectedOfficeId !== this.props.selectedOfficeId) {
      this.clearCredentials();
    }
    const { gateway = {} } = newProps;
    const { gatewayType, paymentRequest = {} } = gateway || {};
    const changed = !_.isEqual(this.props, newProps);
    if (changed) {
      this.setState({
        gatewayType,
        requestEmailSubject:
          paymentRequest.requestEmailSubject || defaultEmailSubject,
        defaultFromEmail:
          paymentRequest.defaultFromEmail || 'noreply@leaptodigital.com',
        redirectURL: paymentRequest.redirectURL || '',
        defaultEmailTemplate:
          paymentRequest.defaultEmailTemplate || paymentRequestEmailTemplate,
        allowedPaymentOptions: paymentRequest.allowedPaymentOptions || [],
        notificationRecipients: paymentRequest.notificationRecipients || [],
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      gatewayType: prevGatewayType,
      termsAccepted: prevTermsAccepted,
      defaultFromEmail: prevDefaultFromEmail,
      notificationRecipients: prevNotificationRecipients,
      defaultEmailTemplate: prevDefaultEmailTemplate,
      redirectURL: prevRedirectURL,
    } = prevState;
    const {
      gatewayType,
      termsAccepted,
      defaultFromEmail,
      notificationRecipients,
      defaultEmailTemplate,
      redirectURL,
    } = this.state;
    if (
      !_.isEqual(prevGatewayType, gatewayType) ||
      !_.isEqual(prevTermsAccepted, termsAccepted) ||
      !_.isEqual(prevDefaultFromEmail, defaultFromEmail) ||
      !_.isEqual(prevNotificationRecipients, notificationRecipients) ||
      !_.isEqual(prevDefaultEmailTemplate, defaultEmailTemplate) ||
      !_.isEqual(prevRedirectURL, redirectURL)
    ) {
      this.renderButtons();
    }
  }

  componentWillUnmount() {
    const { setCrumbs, setButtons } = this.props;
    setCrumbs([]);
    setButtons();
  }

  renderButtons = () => {
    const { setButtons } = this.props;
    setButtons(
      <>
        <TitleButton
          variant="warning"
          onClick={this.askForClearPermission}
          title="Clear"
        />
        <TitleButton
          variant="primary"
          onClick={this.onCopyClicked}
          title="Copy"
        />
        <TitleButton
          variant="success"
          onClick={this.onSaveClicked}
          title="Save"
          disabled={!this.canSave()}
        />
      </>,
    );
  };

  askForClearPermission = () => {
    this.props.showDeleteConfirmation({
      message: 'Are you sure you want to clear this integration ?',
      title: 'Clear Integration ?',
      onConfirm: () => {
        this.onClearClick();
      },
    });
  };

  onClearClick = () => {
    const {
      notificationRecipients,
      allowedPaymentOptions,
      ...data
    } = this.state;

    const unset = Object.keys(data).reduce(
      (unset2, key) => ({ ...unset2, [key]: '' }),
      {},
    );
    this.setState(
      { ...unset, notificationRecipients: [], allowedPaymentOptions: [] },
      () => {
        this.props.startUpdateConfig({ spreedly: {} });
      },
    );
    this.props.pushToDataLayer({
      event: 'integrationEvent',
      eventCategory: 'Integration',
      eventAction: 'Disable',
      eventLabel: 'PaymentCapture',
    });
  };

  onEnabledChanged = (checked) => {
    this.setState({ enabled: checked });
    if (!checked) {
      this.onClearClick();
    } else {
      this.props.pushToDataLayer({
        event: 'integrationEvent',
        eventCategory: 'Integration',
        eventAction: 'Enable',
        eventLabel: 'PaymentCapture',
      });
    }
  };

  onCredentialsChanged = (name, value) => {
    const { gatewayType } = this.state;
    const existingValue = this.state[gatewayType] || {};
    const newValue = { ...existingValue };
    newValue[name] = value;
    this.setState({ [gatewayType]: newValue });
  };

  onCopyClicked = () => {
    this.setState({ showCopy: true });
  };

  onSaveClicked = async () => {
    const {
      gatewayType,
      defaultFromEmail,
      requestEmailSubject,
      defaultEmailTemplate = defaultEmailTemplate,
      allowedPaymentOptions,
      notificationRecipients,
      redirectURL,
    } = this.state;

    const params = {
      gateway_type: gatewayType,
      ...this.state[gatewayType],
    };

    const paymentRequestUpdate = {
      defaultFromEmail,
      requestEmailSubject,
      defaultEmailTemplate,
      allowedPaymentOptions,
      notificationRecipients,
      redirectURL,
    };

    const result = await this.props.checkspfRecords({
      email: this.state.defaultFromEmail,
    });
    if (!result.message) {
      await this.props.startUpdateConfig({
        spreedly: {
          ...this.props.gateway,
          paymentRequest: {
            ...(this.props.gateway.paymentRequest || {}),
            ...paymentRequestUpdate,
          },
        },
      });
      this.props.startUpdatePaymentGateway(params);
      this.clearCredentials();
    } else {
      this.props.showWarningAlert({
        title: 'SPF Records',
        message: result.message,
        onConfirm: async () => {
          await this.props.startUpdateConfig({
            spreedly: {
              ...this.props.gateway,
              paymentRequest: {
                ...(this.props.gateway.paymentRequest || {}),
                ...paymentRequestUpdate,
              },
            },
          });
          this.props.startUpdatePaymentGateway(params);

          this.clearCredentials();
        },
      });
    }
  };

  clearCredentials = () => {
    const { gatewayType } = this.state;
    const gateway =
      this.props.gateways.find(
        ({ gateway_type }) => gateway_type === gatewayType,
      ) || {};
    const { auth_modes = [] } = gateway;
    const authMode = auth_modes[0] || {};
    const { credentials = [] } = authMode;
    const existingValue = this.state[gatewayType] || {};
    const credentialsUpdate = credentials.reduce((update, credential) => {
      const { name } = credential;
      return {
        ...update,
        [name]: '',
      };
    }, {});
    const newValue = { ...existingValue, ...credentialsUpdate };
    this.setState({ [gatewayType]: newValue });
  };

  canSave = () => {
    const saveEnabled =
      !!this.state.gatewayType &&
      this.state.termsAccepted &&
      !validateEmailAddress(this.state.defaultFromEmail) &&
      this.state.notificationRecipients.filter((recip) =>
        validateEmailAddress(recip),
      ).length === 0 &&
      this.state.defaultEmailTemplate &&
      this.state.defaultEmailTemplate.indexOf('%message%') > -1 &&
      this.state.defaultEmailTemplate.indexOf('%payment_link_url%') > -1 &&
      !validateRedirectURL(this.state.redirectURL);
    return saveEnabled;
  };

  onNotificationRecipientsChanged = (value) => {
    this.setState({ notificationRecipients: value });
  };

  render() {
    const gatewayOptions = this.props.gateways.map(
      ({ name, gateway_type }) => ({
        value: gateway_type,
        label: name,
      }),
    );
    const gateway =
      this.props.gateways.find(
        ({ gateway_type }) => gateway_type === this.state.gatewayType,
      ) || {};
    const { auth_modes = [] } = gateway;
    const authMode = auth_modes[0] || {};
    const { credentials = [] } = authMode;
    const { gateway: existingGateway = {} } = this.props;
    const { gatewayToken, gatewayType } = existingGateway || {};
    const placeholder =
      this.state.gatewayType === gatewayType && gatewayToken ? 'Hidden' : '';
    let saveEnabled = this.canSave();
    for (let i = 0; i < credentials.length && saveEnabled; i += 1) {
      const { name } = credentials[i];
      const gatewayValues = this.state[this.state.gatewayType] || {};
      saveEnabled = !!gatewayValues[name];
    }

    return (
      <>
        <Copy
          title="Copy Payment Capture Settings"
          show={this.state.showCopy}
          warning="Warning! This will overwrite your current Payment Capture settings"
          configKeys={['spreedly']}
          onClose={() => this.setState({ showCopy: false })}
        />
        <div className="default-page-padding">
          <Panel title="Secure Payment Capture">
            <div>
              <DropDownGroup
                title="Gateway"
                value={this.state.gatewayType}
                onChange={(obj = {}) =>
                  this.setState({ gatewayType: obj.value })
                }
                closeMenuOnSelect
                options={gatewayOptions}
              />
              {credentials.map(({ name, label, safe }) => {
                const gatewayValues = this.state[this.state.gatewayType] || {};
                const inputValue = gatewayValues[name] || '';
                if (name === 'pem') {
                  return (
                    <TextAreaGroup
                      key={name}
                      title={label}
                      value={inputValue}
                      onChange={(value) =>
                        this.onCredentialsChanged(name, value)
                      }
                      placeholder={placeholder}
                    />
                  );
                }
                if (!safe) {
                  return (
                    <PasswordGroup
                      key={name}
                      title={label}
                      value={inputValue}
                      onChange={(value) =>
                        this.onCredentialsChanged(name, value)
                      }
                      placeholder={placeholder}
                    />
                  );
                }
                return (
                  <TextGroup
                    key={name}
                    title={label}
                    value={inputValue}
                    onChange={(value) => this.onCredentialsChanged(name, value)}
                    placeholder={placeholder}
                  />
                );
              })}
              <FormGroup title="">
                <span style={{ display: 'flex' }}>
                  <input
                    type="checkbox"
                    checked={this.state.termsAccepted}
                    onChange={() =>
                      this.setState((prev) => ({
                        termsAccepted: !prev.termsAccepted,
                      }))
                    }
                  />
                  <p
                    style={{
                      paddingLeft: '5px',
                    }}
                  >
                    I have read and agree to the{' '}
                    <a
                      href="http://leaptodigital.com/leap-terms-use-secure-payment-capture/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms of Use
                    </a>{' '}
                    associated with this feature.
                  </p>
                </span>
              </FormGroup>
              <TextGroup
                defaultValue="noreply@leaptodigital.com"
                errorMessage={validateEmailAddress(this.state.defaultFromEmail)}
                onChange={(value) => {
                  this.setState({
                    defaultFromEmail: value,
                  });
                }}
                title="Default From Email"
                value={this.state.defaultFromEmail}
              />
              <TextGroup
                defaultValue={defaultEmailSubject}
                onChange={(value) => {
                  this.setState({
                    requestEmailSubject: value,
                  });
                }}
                title="Request Email Subject"
                value={this.state.requestEmailSubject}
              />
              <FormGroup title="Allowed Payment Types">
                <MultiSelectComponent
                  selected={this.state.allowedPaymentOptions || []}
                  onSelectedChanged={(selected) => {
                    this.setState({ allowedPaymentOptions: selected });
                  }}
                  options={allowedPaymentSelectOptions}
                  disableSearch
                />
              </FormGroup>

              <TextGroup
                onChange={(value) => {
                  this.setState({
                    redirectURL: value,
                  });
                }}
                title="Success Redirect URL"
                value={this.state.redirectURL}
                errorMessage={validateRedirectURL(this.state.redirectURL)}
              />
              <TagGroup
                title="Success Notification Recipients"
                values={this.state.notificationRecipients}
                onChange={this.onNotificationRecipientsChanged}
                placeholder="Add Email Addresses"
              />

              <TextAreaGroup
                errorMessage={
                  this.state.defaultEmailTemplate.indexOf('%message%') === -1 ||
                  this.state.defaultEmailTemplate.indexOf(
                    '%payment_link_url%',
                  ) === -1
                    ? 'Must include : %message% and %payment_link_url% on Template'
                    : ''
                }
                textAreaStyle={{
                  minHeight: 500,
                }}
                onChange={(value) => {
                  this.setState({
                    defaultEmailTemplate: value,
                  });
                }}
                title="Default Email Template"
                value={this.state.defaultEmailTemplate}
              />

              <ButtonGroup
                onClick={() => {
                  const converted = this.state.defaultEmailTemplate
                    .split('%companyLogo%')
                    .join(this.props.companyLogo); // split('%message%').join(this.state.defaultEmailMessage)
                  this.setState({ showEmailPreview: true, converted });
                }}
                buttonTitle="See Email Preview"
                title="See Email Preview"
                variant="info"
              />
              <EmailPreview
                emailPreview={cleanHtml(this.state.converted)}
                handleClose={() => this.setState({ showEmailPreview: false })}
                show={this.state.showEmailPreview}
              />
            </div>
          </Panel>
        </div>
      </>
    );
  }
}

PaymentCapture.propTypes = {
  startUpdateConfig: PropTypes.func.isRequired,
  startGetPaymentGateways: PropTypes.func.isRequired,
  startUpdatePaymentGateway: PropTypes.func.isRequired,
  gateway: PropTypes.shape({
    gatewayToken: PropTypes.string.isRequired,
    gatewayType: PropTypes.string.isRequired,
    characteristics: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
  gateways: PropTypes.arrayOf(
    PropTypes.shape({
      gateway_type: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      auth_modes: PropTypes.arrayOf(
        PropTypes.shape({
          credentials: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string.isRequired,
              label: PropTypes.string,
              safe: PropTypes.bool.isRequired,
            }),
          ).isRequired,
        }),
      ),
    }),
  ),
  ...layoutContextPropTypes,
};

PaymentCapture.defaultProps = {
  gateway: {
    gatewayToken: '',
    gatewayType: '',
    characteristics: [],
  },
  gateways: [],
};

const mapStateToProps = ({
  auth: { config, selectedOffice },
  paymentCapture,
}) => {
  const { companyLogo = {} } = config;
  return {
    selectedOfficeId: selectedOffice?.id,
    companyLogo: companyLogo.url,
    gateway: config?.spreedly || {},
    gateways: paymentCapture?.gateways,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showWarningAlert: (params) => dispatch(showWarningAlert(params)),
  checkspfRecords: (params) => dispatch(checkspfRecords(params)),
  startUpdateConfig: (update) => dispatch(startUpdateConfig(update)),
  startGetPaymentGateways: () => dispatch(startGetPaymentGatewaysAction()),
  startUpdatePaymentGateway: (params, callback) =>
    dispatch(startUpdatePaymentGateway(params, callback)),
  pushToDataLayer: (variablesForLayer) =>
    dispatch(pushToDataLayer(variablesForLayer)),
  showDeleteConfirmation: (params) => dispatch(showDeleteConfirmation(params)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(PaymentCapture),
);
