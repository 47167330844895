import CreditApplicationLineItem from './CreditApplicationLineItem';

class CreditApplicationSection {
  constructor(obj, section) {
    Object.assign(this, obj);
    const cells = obj.cells || [];
    this.cells = cells.map(
      (cell, index) => new CreditApplicationLineItem(cell, section, index),
    );
  }

  toJSON() {
    return {
      title: this.title,
      button: this.button,
      section: this.section,
      cells: this.cells.map((cell) => cell.toJSON()),
    };
  }
}

export default CreditApplicationSection;
