/* eslint-disable react/prop-types */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isURL from 'validator/lib/isURL';
import { withLayoutContext, layoutContextPropTypes } from '../../Layout';
import {
  startUpdateConfig,
  startCopyFromResources,
  startRefreshConfig,
} from '../../../actions/auth';
import { randomKey, reorder } from '../../../utils/utils';
import FormGroup, { ButtonGroup } from '../../FormGroup';
import Panel from '../../Panel';
import TitleButton from '../../TitleButton';
import { checkForErrors } from '../ContractSending/Validation/Validator';
import { showErrorAlert, hideAlert } from '../../../actions/alert';
import New from './New';
import Copy from '../Copy';
import { setOnDragEndCallback } from '../../DropContext';
import CategorySectionTable from './CategorySectionTable';
import { pushToDataLayer } from '../../../actions/tagManager';

const formatErrorList = (list) =>
  list.map(({ category, link, linkName, name }) => ({
    category: category ? '' : 'Required',
    link: link && isURL(link) ? '' : 'Invalid URL',
    linkName: linkName ? '' : 'Required',
    name: name ? '' : 'Required',
  }));

const validationErrors = ({ placeholder, emailLinks = [] } = []) => {
  const errors = {
    placeholder: '',
    emailLinks: formatErrorList(emailLinks),
  };
  if (placeholder && placeholder.indexOf('[link]') === -1) {
    errors.placeholder = "Placeholder must contain '[link]'";
  } else if (placeholder && placeholder.indexOf('[linkname]') === -1) {
    errors.placeholder = "Placeholder must contain '[linkname]'";
  }
  return errors;
};

const groupedEmailLinks = (emailLinks) => {
  const categories = emailLinks
    .map((link) => link.category)
    .filter((category, index, array) => array.indexOf(category) === index)
    .sort();
  const groupedLinks = categories.map((category) => ({
    category,
    emailLinks: emailLinks.filter(
      (emailLink) => emailLink.category === category,
    ),
  }));
  return groupedLinks;
};

let refs = {};

class EmailLinks extends React.Component {
  constructor(props) {
    super(props);
    const newState = {
      emailLinks: props.emailLinks,
      placeholder: props.placeholder,
      groups: groupedEmailLinks(props.emailLinks),
    };
    newState.errors = validationErrors(newState);
    this.state = newState;

    this.state.groups.forEach((group, groupIndex) => {
      setOnDragEndCallback(
        `category-${groupIndex}-${group.category}`,
        (result) => {
          const { destination = {}, source = {} } = result;
          const [, sourceRowIndex] = source.droppableId.split('-');
          const sourceRow = this.state.groups[sourceRowIndex];

          if (destination === null) {
            return;
          }
          const { droppableId } = destination;
          if (droppableId !== `category-${groupIndex}-${group.category}`) {
            return;
          }

          const [, destinationRowIndex] = destination.droppableId.split('-');
          const destinationRow = this.state.groups[destinationRowIndex];

          if (destinationRowIndex === sourceRowIndex) {
            this.inRowUpdate({
              rows: this.state.groups,
              destinationRowIndex: parseFloat(destinationRowIndex),
              result,
              destinationRow,
            });
          } else {
            this.outRowUpdate({
              rows: this.state.groups,
              destinationRowIndex: parseFloat(destinationRowIndex),
              destinationRow,
              sourceRowIndex: parseFloat(sourceRowIndex),
              sourceRow,
              result,
            });
          }
        },
      );
    });
  }

  componentDidMount() {
    const {
      setCrumbs,
      setButtons,
      location: { pathname },
    } = this.props;

    const crumb = { title: 'Email Links', link: pathname };
    setCrumbs([crumb]);
    setButtons(
      <TitleButtons
        onNewClicked={this.onNewClicked}
        onCopyClicked={this.onCopyClicked}
        onSaveClicked={this.onSaveClicked}
      />,
    );
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const newState = {
      emailLinks: newProps.emailLinks,
      placeholder: newProps.placeholder,
      groups: groupedEmailLinks(newProps.emailLinks),
    };
    newState.errors = validationErrors(newState);
    this.setState(newState);
  }

  componentWillUnmount() {
    this.props.setButtons();
  }

  onPlaceholderChange = (e) => {
    const newState = {
      ...this.state,
      placeholder: e.target.value,
    };
    newState.errors = validationErrors(newState);
    this.setState(newState);
  };

  onTextChange = (e) => {
    const newState = {
      ...this.state,
      emailLinks: this.state.emailLinks.map((emailLink) => {
        if (emailLink.id === e.id) {
          const updatedEmailLink = { ...emailLink };
          updatedEmailLink[e.source] = e.value;
          return updatedEmailLink;
        }
        return emailLink;
      }),
    };
    this.setState(
      {
        ...newState,
        errors: validationErrors(newState),
        groups: groupedEmailLinks(newState.emailLinks),
      },
      () => {
        if (e.source === 'category') {
          const ref = refs[e.id];
          ref.focus();
        }
      },
    );
  };

  onSaveClicked = () => {
    if (checkForErrors(this.state.errors)) {
      this.props.showErrorAlert({
        title: 'Error',
        message: 'Please fix all validation errors',
        onConfirm: this.props.hideAlert,
      });
      return;
    }
    this.props.startUpdateConfig({
      brochures_: this.state.emailLinks,
      emailLinkPlaceholder: this.state.placeholder,
    });
  };

  onAddClicked = (event) => {
    const { group } = event;
    const emailLinks = [...this.state.emailLinks];
    emailLinks.unshift({
      id: randomKey(10),
      category: group.category,
      name: '',
      link: '',
      linkName: '',
    });
    const newState = {
      ...this.state,
      emailLinks,
      groups: groupedEmailLinks(emailLinks),
    };
    newState.errors = validationErrors(newState);
    this.setState(newState);
  };

  onCopyClicked = () => {
    this.setState({ showCopy: true });
  };

  onDelete = ({ id }) => {
    const emailLinks = this.state.emailLinks.filter((old) => old.id !== id);
    const newState = {
      ...this.state,
      emailLinks,
      groups: groupedEmailLinks(emailLinks),
    };
    newState.errors = validationErrors(newState);
    this.setState(newState);
  };

  onNewClicked = () => {
    this.props.pushToDataLayer({
      event: 'settingsPageTitleButtonEvent',
      eventCategory: 'Settings Page',
      eventAction: 'email_links',
      eventLabel: 'New',
    });
    this.setState(() => ({ showNew: true }));
  };

  onNewAdded = (row) => {
    const emailLinks = [...this.state.emailLinks];
    emailLinks.unshift(row);
    this.setState(() => ({
      ...this.state,
      emailLinks,
      groups: groupedEmailLinks(emailLinks),
    }));
  };

  updateGroupOrders = (rows) => {
    const updatedItems = [];
    rows.forEach((row) => {
      row.emailLinks.forEach((item, orderNumber) => {
        updatedItems.push({
          ...item,
          orderNumber,
        });
      });
    });
    return updatedItems;
  };

  inRowUpdate = ({ rows, destinationRowIndex, result, destinationRow }) => {
    const rowItemsUpdate = reorder(
      destinationRow.emailLinks,
      result.source.index,
      result.destination.index,
    );

    const newRows = rows.map((row, count) => {
      if (count === destinationRowIndex) {
        return {
          ...row,
          emailLinks: rowItemsUpdate,
        };
      }
      return row;
    });
    const emailLinks = this.updateGroupOrders(newRows);
    this.setState({
      emailLinks,
      groups: groupedEmailLinks(emailLinks),
    });
  };

  outRowUpdate = ({
    rows,
    destinationRowIndex,
    sourceRowIndex,
    sourceRow,
    result,
    destinationRow,
  }) => {
    const sourceItem = sourceRow.emailLinks[result.source.index];
    sourceItem.category = destinationRow.category;

    let destinationUpdate = destinationRow.emailLinks.reduce(
      (newList, item, index) => {
        if (index === result.destination.index) {
          return [...newList, sourceItem, item];
        }
        return [...newList, item];
      },
      [],
    );
    if (result.destination.index >= destinationRow.emailLinks.length) {
      destinationUpdate = [...destinationRow.emailLinks, sourceItem];
    }
    const sourceUpdate = sourceRow.emailLinks.reduce((newList, item, index) => {
      if (index === result.source.index) {
        return [...newList];
      }
      return [...newList, item];
    }, []);
    const newRows = rows.map((row, count) => {
      if (count === destinationRowIndex) {
        return {
          ...row,
          emailLinks: destinationUpdate,
        };
      }
      if (count === sourceRowIndex) {
        return {
          ...row,
          emailLinks: sourceUpdate,
        };
      }
      return row;
    });
    const emailLinks = this.updateGroupOrders(newRows);
    this.setState({
      emailLinks,
      groups: groupedEmailLinks(emailLinks),
    });
  };

  render() {
    return (
      <>
        <New
          show={this.state.showNew}
          onAdd={this.onNewAdded}
          onClose={() => this.setState({ showNew: false })}
        />
        <Copy
          title="Copy Email Links"
          show={this.state.showCopy}
          warning="Warning! This will overwrite your current Email Links"
          configKeys={['brochures_']}
          onClose={() => this.setState({ showCopy: false })}
        />
        <div className="default-page-padding">
          <Panel title="Email Links">
            <div>
              <FormGroup
                title="Placeholder"
                errorMessage={this.state.errors.placeholder}
              >
                <input
                  type="text"
                  value={this.state.placeholder}
                  placeholder={'<li><a href="[link]">[linkname]</a></li>'}
                  onChange={this.onPlaceholderChange}
                />
              </FormGroup>
              <FormGroup title="">
                <div className="email-link__link-container">
                  <span>
                    <span style={{ fontWeight: 700 }}>Link</span> = [link]
                  </span>
                  <p>
                    <span style={{ fontWeight: 700 }}>Link Name</span> =
                    [linkname]
                  </p>
                </div>
              </FormGroup>
              <ButtonGroup
                title=""
                buttonTitle="Copy From Resources"
                variant="success"
                onClick={async () => {
                  await this.props.copyFromResources();
                  await this.props.refreshConfig();
                }}
              />
              <div>
                {this.state.groups.map((group, groupIndex) => {
                  return (
                    <div key={group.category}>
                      <div
                        style={{
                          backgroundColor: 'silver',
                          padding: 5,
                        }}
                      >
                        <span>
                          <b>
                            <u>{group.category}</u>
                          </b>
                        </span>
                      </div>
                      <CategorySectionTable
                        group={group}
                        setRef={({ id, ref }) => {
                          refs = {
                            ...refs,
                            [id]: ref,
                          };
                        }}
                        sectionId={`category-${groupIndex}-${group.category}`}
                        emailLinks={group.emailLinks}
                        errors={formatErrorList(group.emailLinks)}
                        onTextChange={this.onTextChange}
                        onAddClicked={this.onAddClicked}
                        onDelete={this.onDelete}
                      />
                      <br />
                    </div>
                  );
                })}
              </div>
            </div>
          </Panel>
        </div>
      </>
    );
  }
}

EmailLinks.propTypes = {
  emailLinks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      category: PropTypes.string,
      link: PropTypes.string,
      linkName: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  placeholder: PropTypes.string,
  startUpdateConfig: PropTypes.func.isRequired,
  showErrorAlert: PropTypes.func.isRequired,
  hideAlert: PropTypes.func.isRequired,
  copyFromResources: PropTypes.func.isRequired,
  refreshConfig: PropTypes.func.isRequired,
  ...layoutContextPropTypes,
};

EmailLinks.defaultProps = {
  emailLinks: [],
  placeholder: '',
};

const TitleButtons = ({ onNewClicked, onCopyClicked, onSaveClicked }) => (
  <>
    <TitleButton
      variant="primary"
      onClick={() => onCopyClicked()}
      title="Copy"
    />
    <TitleButton variant="success" onClick={() => onNewClicked()} title="New" />
    <TitleButton
      variant="success"
      onClick={() => onSaveClicked()}
      title="Save"
    />
  </>
);

TitleButtons.propTypes = {
  onNewClicked: PropTypes.func.isRequired,
  onCopyClicked: PropTypes.func.isRequired,
  onSaveClicked: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth: { config } }) => ({
  emailLinks: config.brochures_,
  placeholder: config.emailLinkPlaceholder,
});

const mapDispatchToProps = (dispatch) => ({
  startUpdateConfig: (updates) => dispatch(startUpdateConfig(updates)),
  showErrorAlert: (params) => dispatch(showErrorAlert(params)),
  hideAlert: () => dispatch(hideAlert()),
  copyFromResources: () => dispatch(startCopyFromResources()),
  refreshConfig: () => dispatch(startRefreshConfig()),
  pushToDataLayer: (variablesForLayer) =>
    dispatch(pushToDataLayer(variablesForLayer)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(EmailLinks),
);
