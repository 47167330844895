import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import { Switch } from '@blueprintjs/core';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { withLayoutContext, layoutContextPropTypes } from '../../Layout';
import { startUpdateConfig as startUpdateConfigAction } from '../../../actions/auth';
import { formulaEvaluates, randomKey, reorder } from '../../../utils/utils';
import Panel from '../../Panel';
import TitleButton from '../../TitleButton';
import SwitchedCell from '../../Misc/SwitchedCell';
import Copy from '../Copy';
import { history } from '../../../router';
import { setOnDragEndCallback } from '../../DropContext';

const priceFormulaDropableSectionId = 'priceFormulaDropableSection';

const getStyle = ({ snapshot }) => ({
  backgroundColor: snapshot.isDragging ? 'white' : 'transparent',
});

class PriceFormulas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      priceFormulas: props.priceFormulas,
    };
    setOnDragEndCallback(priceFormulaDropableSectionId, (result) => {
      // dropped outside the list
      const { destination = {} } = result;
      if (destination === null) {
        return;
      }
      const { droppableId } = destination;
      if (droppableId !== priceFormulaDropableSectionId) {
        return;
      }
      this.setState((prevState) => {
        const priceFormulas = reorder(
          prevState.priceFormulas,
          result.source.index,
          result.destination.index,
        );
        return { priceFormulas };
      });
    });
  }

  componentDidMount() {
    const { setButtons } = this.props;

    setButtons(
      <TitleButtons
        onCopyClicked={this.onCopyClicked}
        onSaveClicked={this.onSaveClicked}
      />,
    );
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({ priceFormulas: newProps.priceFormulas });
  }

  componentWillUnmount() {
    const { setButtons } = this.props;

    setButtons();
  }

  onFormulaChange = (key, value, id) => {
    this.setState((prevState) => {
      const priceFormulas = prevState.priceFormulas.map((formula) => {
        const updatedFormula = { ...formula };
        if (formula.id === id) {
          updatedFormula[key] = value;
          return updatedFormula;
        }
        switch (key) {
          case 'isDefault':
          case 'isResultsFormula':
          case 'isResultsReport':
          case 'isPriceGuide':
          case 'isContract': {
            if (value) {
              updatedFormula[key] = false;
            }
            break;
          }
          default:
            break;
        }
        return updatedFormula;
      });
      return { priceFormulas };
    });
  };

  onDelete = (id) => {
    this.setState((prevState) => {
      const priceFormulas = prevState.priceFormulas.filter(
        (formula) => formula.id !== id,
      );
      return { priceFormulas };
    });
  };

  onCopyClicked = () => {
    this.setState({ showCopy: true });
  };

  onAddClicked = () => {
    this.setState((prevState) => {
      const updatedFormulas = [
        {
          id: randomKey(10),
          formula: '',
          name: '',
          isVisable: false,
          isDefault: false,
          isResultsFormula: false,
          isResultsReport: false,
          isPriceGuide: false,
          isContract: false,
        },
        ...prevState.priceFormulas,
      ];

      return { priceFormulas: updatedFormulas };
    });
  };

  isInLimit = () => {
    const { priceFormulas } = this.state;
    const { maxPriceFormulasCount } = this.props;
    const inLimit =
      maxPriceFormulasCount === -1
        ? true
        : priceFormulas.length < maxPriceFormulasCount;
    return inLimit;
  };

  onSaveClicked = () => {
    const { categories, startUpdateConfig } = this.props;
    const { priceFormulas } = this.state;
    let evaluates = true;
    const formulaIds = categories
      .filter(({ formulaId }) => formulaId)
      .map(({ formulaId }) => formulaId);
    priceFormulas.forEach((formula) => {
      if (!formulaEvaluates(formula.formula, formulaIds)) {
        evaluates = false;
      }
    });
    if (evaluates) {
      startUpdateConfig({
        priceFormulas,
      });
    }
  };

  testClick = (id) => history.push(`/price_formulas/edit/${id}`);

  SwitchedCell = ({ value, onChange }) => (
    <td>
      <Switch checked={value} onChange={onChange} large />
    </td>
  );

  render() {
    const { categories, maxPriceFormulasCount } = this.props;
    const { showCopy, priceFormulas } = this.state;

    const formulaIds = categories
      .filter(({ formulaId }) => formulaId)
      .map(({ formulaId }) => formulaId);

    return (
      <>
        <Copy
          title="Copy Price Formulas"
          show={showCopy}
          warning="Warning! This will overwrite your current Price Formulas"
          configKeys={['priceFormulas']}
          onClose={() => this.setState({ showCopy: false })}
        />
        <div className="default-page-padding">
          <Panel title="Price Formulas">
            <div>
              <div>
                <Table striped bordered hover style={{ tableLayout: 'fixed' }}>
                  <thead>
                    <tr>
                      <th style={{ textAlign: 'center', width: '30%' }}>
                        Name
                      </th>
                      <th style={{ textAlign: 'center', width: '70%' }}>
                        Formula
                      </th>
                      <th style={{ width: 75, textAlign: 'center' }}>
                        Category
                      </th>
                      <th style={{ width: 75, textAlign: 'center' }}>
                        Price Button
                      </th>
                      <th style={{ width: 75, textAlign: 'center' }}>
                        Results Note
                      </th>
                      <th style={{ width: 75, textAlign: 'center' }}>
                        Results Report
                      </th>
                      <th style={{ width: 75, textAlign: 'center' }}>
                        Price Guide
                      </th>
                      <th style={{ width: 75, textAlign: 'center' }}>
                        Contracts
                      </th>
                      <th style={{ width: 50, textAlign: 'center' }}> Edit </th>
                      <th style={{ width: 50, textAlign: 'center' }}>
                        <button
                          className="btn btn-primary"
                          disabled={!this.isInLimit()}
                          type="button"
                          onClick={this.onAddClicked}
                        >
                          Add
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <Droppable
                    droppableId={priceFormulaDropableSectionId}
                    direction="vertical"
                    type={priceFormulaDropableSectionId}
                  >
                    {(provided) => (
                      <tbody
                        style={{
                          position: 'relative',
                          width: '100% !important',
                        }}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {priceFormulas.map(
                          (
                            {
                              id,
                              formula,
                              name,
                              isVisable,
                              isDefault,
                              isResultsFormula,
                              isResultsReport,
                              isPriceGuide,
                              isContract,
                            },
                            index,
                          ) => (
                            <Draggable
                              key={id}
                              draggableId={id}
                              index={index}
                              type={priceFormulaDropableSectionId}
                            >
                              {(draggableProvided, snapshot) => (
                                <tr
                                  style={{
                                    position: 'relative',
                                    width: '100% !important',
                                  }}
                                  ref={draggableProvided.innerRef}
                                  {...draggableProvided.draggableProps}
                                  {...draggableProvided.dragHandleProps}
                                  key={id}
                                >
                                  <td
                                    style={{
                                      ...getStyle({ snapshot }),
                                      width: '30%',
                                    }}
                                  >
                                    <input
                                      className="table-row__cell__input"
                                      type="text"
                                      value={name}
                                      placeholder="Name"
                                      onChange={(e) =>
                                        this.onFormulaChange(
                                          'name',
                                          e.target.value,
                                          id,
                                        )
                                      }
                                    />
                                  </td>
                                  <td
                                    style={{
                                      ...getStyle({ snapshot }),
                                      width: '70%',
                                    }}
                                  >
                                    <input
                                      className={`table-row__cell__input${
                                        formulaEvaluates(formula, formulaIds)
                                          ? ''
                                          : ' error-border'
                                      }`}
                                      type="text"
                                      value={formula}
                                      placeholder="Formula"
                                      onChange={(e) =>
                                        this.onFormulaChange(
                                          'formula',
                                          e.target.value,
                                          id,
                                        )
                                      }
                                    />
                                    <div className="error">
                                      {formulaEvaluates(formula, formulaIds)
                                        ? ''
                                        : 'Invalid Formula'}
                                    </div>
                                  </td>
                                  <SwitchedCell
                                    style={{
                                      ...getStyle({ snapshot }),
                                    }}
                                    value={isDefault}
                                    onChange={this.onFormulaChange}
                                    source="isDefault"
                                    id={id}
                                  />
                                  <SwitchedCell
                                    style={{
                                      ...getStyle({ snapshot }),
                                    }}
                                    value={isVisable}
                                    onChange={this.onFormulaChange}
                                    source="isVisable"
                                    id={id}
                                  />
                                  <SwitchedCell
                                    style={{
                                      ...getStyle({ snapshot }),
                                    }}
                                    value={isResultsFormula}
                                    onChange={this.onFormulaChange}
                                    source="isResultsFormula"
                                    id={id}
                                  />
                                  <SwitchedCell
                                    style={{
                                      ...getStyle({ snapshot }),
                                    }}
                                    value={isResultsReport}
                                    onChange={this.onFormulaChange}
                                    source="isResultsReport"
                                    id={id}
                                  />
                                  <SwitchedCell
                                    style={{
                                      ...getStyle({ snapshot }),
                                    }}
                                    value={isPriceGuide}
                                    onChange={this.onFormulaChange}
                                    source="isPriceGuide"
                                    id={id}
                                  />
                                  <SwitchedCell
                                    style={{
                                      ...getStyle({ snapshot }),
                                    }}
                                    value={isContract}
                                    onChange={this.onFormulaChange}
                                    source="isContract"
                                    id={id}
                                  />
                                  <td
                                    style={{
                                      ...getStyle({ snapshot }),
                                      width: 75,
                                    }}
                                  >
                                    <button
                                      onClick={() => this.testClick(id)}
                                      type="button"
                                      className="fill-cell primary"
                                    >
                                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                      <a>
                                        <i className="fas fa-pencil-alt" />
                                      </a>
                                    </button>
                                  </td>
                                  <td
                                    style={{
                                      ...getStyle({ snapshot }),
                                      width: 75,
                                    }}
                                  >
                                    <button
                                      className="fill-cell danger"
                                      type="button"
                                      onClick={() => this.onDelete(id)}
                                    >
                                      <i className="far fa-trash-alt" />
                                    </button>
                                  </td>
                                  {draggableProvided.placeholder}
                                </tr>
                              )}
                            </Draggable>
                          ),
                        )}
                        {provided.placeholder}
                      </tbody>
                    )}
                  </Droppable>
                </Table>
              </div>
            </div>
            {!this.isInLimit() && (
              <p className="danger">
                Plan is limited to {maxPriceFormulasCount} Price Formula(s)
              </p>
            )}
          </Panel>
        </div>
      </>
    );
  }
}

PriceFormulas.propTypes = {
  priceFormulas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      formula: PropTypes.string,
      name: PropTypes.string,
      isVisable: PropTypes.bool,
      isDefault: PropTypes.bool,
      isResultsFormula: PropTypes.bool,
      isResultsReport: PropTypes.bool,
      isPriceGuide: PropTypes.bool,
      isContract: PropTypes.bool,
    }),
  ),
  maxPriceFormulasCount: PropTypes.number,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      formulaId: PropTypes.string,
    }),
  ),
  startUpdateConfig: PropTypes.func.isRequired,
  ...layoutContextPropTypes,
};

PriceFormulas.defaultProps = {
  priceFormulas: [],
  categories: [],
  maxPriceFormulasCount: 0,
};

const TitleButtons = ({ onCopyClicked, onSaveClicked }) => (
  <>
    <TitleButton
      variant="primary"
      onClick={() => onCopyClicked()}
      title="Copy"
    />
    <TitleButton
      variant="success"
      onClick={() => onSaveClicked()}
      title="Save"
    />
  </>
);

TitleButtons.propTypes = {
  onCopyClicked: PropTypes.func.isRequired,
  onSaveClicked: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth: { config }, plan = {} }) => ({
  priceFormulas: config.priceFormulas,
  categories: config.categories_,
  maxPriceFormulasCount: plan.maxPriceFormulasCount,
});

const mapDispatchToProps = (dispatch) => ({
  startUpdateConfig: (updates) => dispatch(startUpdateConfigAction(updates)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(PriceFormulas),
);
