/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MultiSelect from '../Misc/MultiSelect';
import Select from '../Misc/Select';
import { startDownloadCategories } from '../../actions/priceGuide2';

const valueRenderer = (value, options) => {
  if (value.length > 3 && value.length === options.length) {
    return `All ${value.length} Categories selected`;
  }
  switch (value.length) {
    case 0:
      return 'None Selected';
    case 1:
    case 2:
    case 3:
      return value
        .map((id) => options.find(({ value: officeId }) => id === officeId))
        .filter((obj) => obj)
        .map(({ label }) => label)
        .join(', ');
    default:
      return `${value.length} Categories selected`;
  }
};
class CategoriesDropDown extends React.Component {
  componentDidMount = () => {
    this.props.startDownload();
  };

  render() {
    const {
      startDownload,
      value,
      onChange,
      categories,
      isMulti,
      showCustomProduct,
      ...rest
    } = this.props;
    const options = categories.map((cat) => ({ value: cat, label: cat }));
    if (showCustomProduct) {
      options.push({ value: 'custom_product', label: 'Custom Product' });
    }
    let values;
    if (Array.isArray(value)) {
      values = value.map((v) => options.find((obj) => obj.value === v));
    } else {
      values = options.find((obj) => obj.value === value);
    }
    if (isMulti) {
      return (
        <MultiSelect
          options={options}
          onSelectedChanged={onChange}
          selected={value || []}
          selectAllLabel="Select All"
          valueRenderer={valueRenderer}
          hasSelectAll={options.length > 1}
          disableSearch={options.length <= 1}
          {...rest}
        />
      );
    }
    return (
      <Select
        isLoading={!this.props.categories}
        placeholder={!this.props.categories ? 'Loading...' : 'None Selected'}
        value={values}
        options={options}
        clearable={false}
        closeMenuOnSelect
        onChange={onChange}
        {...rest}
      />
    );
  }
}

CategoriesDropDown.propTypes = {
  isMulti: PropTypes.bool,
  startDownload: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  showCustomProduct: PropTypes.bool,
};

CategoriesDropDown.defaultProps = {
  isMulti: false,
  categories: null,
  value: '',
  showCustomProduct: false,
};

const mapStateToProps = ({ priceGuide2: { categories } }) => ({
  categories,
});

const mapDispatchToProps = (dispatch) => ({
  startDownload: () => dispatch(startDownloadCategories()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesDropDown);
