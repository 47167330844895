/* eslint-disable no-underscore-dangle */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import React from 'react';
import Parse from 'parse';
import math from 'mathjs';

export const randomKey = (length) => {
  let text = '';
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < length; i += 1) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

export const decryptString = async (password) => {
  try {
    const result = await Parse.Cloud.run('decryptString_', {
      string: password,
    });
    return result;
  } catch (e) {
    return e;
  }
};

export const evaluateFormula = ({ formula, placeholders }) => {
  const renderedFormula = placeholders.reduce(
    (rendered, { symbol, value }) => rendered.split(symbol).join(value || 0),
    formula,
  );
  try {
    return math.eval(renderedFormula.split('[]').join(''));
  } catch (error) {
    return 0;
  }
};

export const formulaEvaluates = (formula, formulaIds = []) => {
  const allIds = ['B', 'T', 'CP', 'DP', 'UA', 'FP', ...formulaIds];
  let newFormula = formula;
  allIds.forEach((formulaId) => {
    newFormula = newFormula.split(`[${formulaId}]`).join('2');
  });
  try {
    const val = math.eval(newFormula.split('[]').join(''));
    return val === 0 || !!val;
  } catch (e) {
    return false;
  }
};

export const removeLeadingZeros = (value) => value.replace(/^0+/, '');

export const currencyFromNumber = (number, withoutDecimal = false) => {
  const params = {
    style: 'currency',
    currency: 'USD',
  };

  if (withoutDecimal) {
    params.maximumFractionDigits = 0;
    params.maximumFractionDigits = 0;
  }

  return Intl.NumberFormat('en-US', params).format(number);
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const toScss = (name) =>
  name
    .split(/(?=[A-Z])/)
    .map((name, index) =>
      index !== 0 ? name.replace(/^\w/, (c) => c.toLowerCase()) : name,
    )
    .join('-');

const styleElement = (element, style = {}) => `${element} {
    ${Object.keys(style)
      .map((name) => {
        const element = style[name];
        if (typeof element === 'object') {
          return styleElement(name, element);
        }
        return `${toScss(name)}: ${element} !important;`;
      })
      .join('')}
  }`;

export const createStyleElement = (style = {}) => {
  const scss = styleElement('', style);
  return scss.substring(scss.indexOf('{') + 1, scss.length - 1).trim();
};
export const interlace = ([x, ...xs], [y, ...ys]) => {
  if (x === undefined && y === undefined) {
    return [];
  }
  if (x === undefined) {
    return [y, ...ys];
  }
  if (y === undefined) {
    return [x, ...xs];
  }
  return [x, y, ...interlace(xs, ys)];
};

export const newFileFromFile = (file, name = 'thumbnail', fileSource) =>
  new Promise((resolve, reject) => {
    if (!fileSource) {
      reject(new Error('Cannot create new file with no file source'));
      return;
    }
    const reader = new window.FileReader();
    reader.onload = (onLoad) => {
      const extension = file.name.split('.').pop();
      const newName = name.split('.').join('');
      const fileName = `${newName}.${extension}`;
      const newFile = new Parse.File(fileName, file);
      newFile.addTag('fileSource', fileSource);
      newFile._url = onLoad.target.result;
      newFile.isNew = true;
      resolve(newFile);
    };
    reader.readAsDataURL(file);
  });

export const newFileFromEvent = (
  event,
  name = 'thumbnail',
  fileSource = 'thumbnail',
) => newFileFromFile(event.target.files[0], name, fileSource);

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  Array(6)
    .fill()
    .forEach(() => {
      color += letters[Math.floor(Math.random() * letters.length)];
    });
  return color;
};

export const upperWords = (text) => {
  return text
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (str) => str.toUpperCase());
};

export const dateTimePlaceHolders = {
  datePicker: 'MM/dd/yyyy',
  dateTimePicker: 'MM/dd/yyyy hh:mm a',
  timePicker: 'h:mm a',
};

export const strCompare = (str1 = '', str2 = '') =>
  str1.trim().toLowerCase() === str2.trim().toLowerCase();
