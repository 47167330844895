/* eslint-disable react/no-deprecated */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import Parse from 'parse';
import { connect } from 'react-redux';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import { startFetchCompanies } from '../../actions/config';
import {
  startFetchPackageCategories,
  startCopyData,
} from '../../actions/admin';
import { DropDownGroup, MultiSelectGroup, ButtonGroup } from '../FormGroup';
import Panel from '../Panel';

export class CopyDataPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companies: props.companies,
      dataTypes: props.dataTypes,
    };
  }

  componentDidMount() {
    const {
      setCrumbs,
      location: { pathname },
    } = this.props;
    const crumb = { title: 'Copy Data', link: pathname };
    setCrumbs([crumb]);
    this.props.startFetchCompanies();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const newState = {};
    const { companies, packageCategories } = newProps;
    if (companies) {
      newState.companies = companies.map((company) => ({
        value: company.id,
        label: company.get('name'),
      }));
    }
    if (packageCategories) {
      newState.packageCategories = packageCategories.map((category) => ({
        value: category,
        label: category,
      }));
    }
    this.setState(() => newState);
  }

  componentWillUnmount() {
    const { setCrumbs } = this.props;
    setCrumbs([]);
  }

  onSourceCompanyChange = (e) => {
    this.props.startFetchPackageCategories(e.value);
    this.setState(() => ({ sourceCompany: e, sourceCompanyError: undefined }));
  };

  onDestinationCompanyChange = (e) => {
    this.setState(() => ({
      destinationCompany: e,
      destinationCompanyError: undefined,
    }));
  };

  onDataToCopyChange = (e) => {
    this.setState(() => ({ dataToCopy: e, dataToCopyError: undefined }));
  };

  onPackageCategoriesChange = (e) => {
    this.setState(() => ({
      selectedCategories: e,
      packageCategoriesError: undefined,
    }));
  };

  onSubmit = () => {
    const {
      sourceCompany,
      destinationCompany,
      dataToCopy,
      selectedCategories,
    } = this.state;
    const errors = {};
    if (!sourceCompany) {
      errors.sourceCompanyError = this.props.missingFieldMessage;
    }
    if (!destinationCompany) {
      errors.destinationCompanyError = this.props.missingFieldMessage;
    }
    if (!dataToCopy) {
      errors.dataToCopyError = this.props.missingFieldMessage;
    }
    if (dataToCopy.value === 'copyPackages' && !selectedCategories) {
      errors.packageCategoriesError = this.props.missingFieldMessage;
    }
    if (Object.keys(errors).length > 0) {
      this.setState(() => errors);
    } else {
      this.props.startCopyData({
        sourceCompanyId: sourceCompany.value,
        destinationCompanyId: destinationCompany.value,
        descriptor: dataToCopy.value,
        categories: selectedCategories,
      });
    }
  };

  // eslint-disable-next-line consistent-return
  categoryValueRenderer = (selected, options) => {
    if (selected.length === 0) {
      return 'Select package categories to copy';
    }
    if (selected.length === options.length) {
      return 'All package categories selected';
    }
  };

  render() {
    return (
      <div className="default-page-padding">
        <Panel title="Copy Data">
          <DropDownGroup
            title="Source Company"
            errorMessage={this.state.sourceCompanyError}
            value={this.state.sourceCompany}
            placeholder="Select company to copy data FROM"
            onChange={this.onSourceCompanyChange}
            options={this.state.companies}
            isDisabled={!this.state.companies}
            isLoading={!this.state.companies}
            isClearable={false}
            backspaceRemovesValue={false}
            closeMenuOnSelect
          />
          <DropDownGroup
            title="Destination Company"
            errorMessage={this.state.destinationCompanyError}
            value={this.state.destinationCompany}
            placeholder="Select company to copy data TO"
            onChange={this.onDestinationCompanyChange}
            options={this.state.companies}
            isDisabled={!this.state.companies}
            isLoading={!this.state.companies}
            isClearable={false}
            backspaceRemovesValue={false}
            closeMenuOnSelect
          />
          <DropDownGroup
            title="Data to Copy"
            errorMessage={this.state.dataToCopyError}
            value={this.state.dataToCopy}
            placeholder="Select data to copy"
            onChange={this.onDataToCopyChange}
            options={this.state.dataTypes}
            isSearchable={false}
            isClearable={false}
            backspaceRemovesValue={false}
            closeMenuOnSelect
          />
          {this.state.sourceCompany &&
            this.state.dataToCopy &&
            this.state.dataToCopy.value === 'copyPackages' && (
              <MultiSelectGroup
                title="Categories"
                errorMessage={this.state.packageCategoriesError}
                selected={this.state.selectedCategories || []}
                valueRenderer={this.categoryValueRenderer}
                onSelectedChanged={this.onPackageCategoriesChange}
                options={this.state.packageCategories}
                disabled={!this.state.packageCategories}
                isLoading={!this.state.packageCategories}
                disableSearch
              />
            )}
          <hr />
          <ButtonGroup
            title=""
            buttonTitle="Submit"
            onClick={this.onSubmit}
            variant="success"
          />
        </Panel>
      </div>
    );
  }
}

CopyDataPage.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.instanceOf(Parse.Object)),
  startFetchCompanies: PropTypes.func.isRequired,
  startFetchPackageCategories: PropTypes.func.isRequired,
  startCopyData: PropTypes.func.isRequired,
  missingFieldMessage: PropTypes.string,
  dataTypes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  ...layoutContextPropTypes,
};

CopyDataPage.defaultProps = {
  companies: [],
  dataTypes: [
    { value: 'copyFinanceOptions', label: 'Finance Options' },
    { value: 'copyResources', label: 'Resources' },
    { value: 'copyContracts', label: 'Contracts' },
    { value: 'copyPackages', label: 'Packages' },
  ],
  missingFieldMessage: 'This field is required',
};

const mapStateToProps = ({ config, admin }) => ({
  companies: config.companies,
  packageCategories: admin.packageCategories,
});

const mapDispatchToProps = (dispatch) => ({
  startFetchCompanies: () => dispatch(startFetchCompanies()),
  startFetchPackageCategories: (company) =>
    dispatch(startFetchPackageCategories(company)),
  startCopyData: (params) => dispatch(startCopyData(params)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(CopyDataPage),
);
