/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import initConext from './StickyUtils';

class Sticky extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.myRef = React.createRef();
  }

  componentDidMount() {
    this.initSticky();
    this.stickyContext.enable(this.props.uid);
    if (!this.props.enabelSticky) {
      this.stickyContext.disable(this.props.uid);
    }
  }

  componentDidUpdate() {
    this.stickyContext.enable(this.props.uid);
    this.myRef.current.enabelSticky = true;
    if (!this.props.enabelSticky) {
      this.stickyContext.disable(this.props.uid);
      this.myRef.current.enabelSticky = false;
    }
  }

  initSticky() {
    this.stickyContext = initConext(this.props.scrollElementId);
    this.stickyContext.registerSticky({
      elementRef: this.myRef,
      topRef: this.props.topRef,
      stickTo: this.props.stickTo,
      uid: this.props.uid,
      stackOrder: this.props.stackOrder,
      bumpType: this.props.bumpType,
      waitFor: this.props.waitFor,
      holdUp: this.props.holdUp,
    });
  }

  render() {
    return (
      <div
        id={this.props.uid}
        className="sticky-wrapper"
        style={{ position: 'relative' }}
        ref={this.myRef}
      >
        <div className="moving-sticky-child" style={{ position: 'relative' }}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

Sticky.propTypes = {
  stackOrder: PropTypes.number,
  topRef: PropTypes.bool,
  enabelSticky: PropTypes.bool,
  scrollElementId: PropTypes.string.isRequired,
  bumpType: PropTypes.string.isRequired,
  waitFor: PropTypes.string,
  holdUp: PropTypes.string,
  uid: PropTypes.string.isRequired,
  stickTo: PropTypes.shape({
    type: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
    ]),
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.shape({}).isRequired,
    PropTypes.arrayOf(PropTypes.shape({}).isRequired),
  ]).isRequired,
};

Sticky.defaultProps = {
  enabelSticky: true,
  waitFor: null,
  holdUp: null,
  topRef: false,
  stackOrder: 0,
};

export default Sticky;
