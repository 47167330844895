/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { BarChart, Bar, XAxis, YAxis, Tooltip } from 'recharts';
import { getRandomColor } from '../utils';

const LeapBarChart = (props) => (
  <BarChart
    width={props.width}
    height={props.height}
    data={props.data}
    margin={{
      top: 5,
      right: 30,
      left: 20,
      bottom: 5,
    }}
  >
    <XAxis
      tickLine={false}
      axisLine={false}
      label={{ offset: -5, value: props.xAxisLabel, position: 'insideBottom' }}
      dataKey={props.xDataKey}
    />
    <YAxis
      tickLine={false}
      axisLine={false}
      allowDecimals={props.allowDecimals}
      label={{
        value: props.yAxisLabel,
        angle: -90,
        position: 'insideBottomLeft',
      }}
    />
    <Tooltip />
    <Bar dataKey={props.barDatatKey} fill={props.barColor} />
  </BarChart>
);

LeapBarChart.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  barDatatKey: PropTypes.string,
  xDataKey: PropTypes.string,
  barColor: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  yAxisLabel: PropTypes.string,
  xAxisLabel: PropTypes.string,
  allowDecimals: PropTypes.bool,
};

LeapBarChart.defaultProps = {
  data: [],
  height: 200,
  width: 400,
  barDatatKey: 'value',
  xDataKey: null,
  barColor: getRandomColor(),
  yAxisLabel: null,
  allowDecimals: false,
  xAxisLabel: null,
};

export default LeapBarChart;
