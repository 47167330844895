import React from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/prop-types
const AccountInfoGroup = ({ title, value, altValue }) => (
  <div className="new-account__account-info-group">
    <div className="new-account_account-info-title">{title}</div>
    <div className="new-account_account-info-value">
      {altValue ? (
        <span>
          <s>{value}</s> –<strong>{altValue}</strong>
        </span>
      ) : (
        value
      )}
    </div>
  </div>
);

AccountInfoGroup.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
};

AccountInfoGroup.defaultProps = {
  title: '',
  value: '',
};

export default AccountInfoGroup;
