/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from '../../Misc/Select';
import { startDownloadGroupMeBots } from '../../../actions/auth';

export class GroupMeGroupsDropDown extends React.Component {
  componentDidMount() {
    this.props.startDownloadGroupMeBots();
  }

  onSelectionChange = (selectedGroup) => {
    if (selectedGroup) {
      this.props.onChange(selectedGroup);
    } else {
      this.props.onChange(undefined);
    }
  };

  render() {
    const groups = this.props.groups || [];
    const selectedGroup = groups.filter(
      (group) => group.value === this.props.selectedGroupId,
    )[0];
    return (
      <Select
        name="form-field-name"
        value={selectedGroup}
        onChange={this.onSelectionChange}
        closeMenuOnSelect
        options={this.props.groups}
      />
    );
  }
}

GroupMeGroupsDropDown.propTypes = {
  selectedGroupId: PropTypes.string,
  startDownloadGroupMeBots: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
};

GroupMeGroupsDropDown.defaultProps = {
  selectedGroupId: '',
  groups: [],
};

const mapStateToProps = ({ auth: { groupMeGroups = [] } }) => ({
  groups: groupMeGroups.map((group) => ({
    value: group.id,
    label: group.name,
  })),
});

const mapDispatchtoProps = (dispatch) => ({
  startDownloadGroupMeBots: () => dispatch(startDownloadGroupMeBots()),
});

export default connect(
  mapStateToProps,
  mapDispatchtoProps,
)(GroupMeGroupsDropDown);
