import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setInitialStateForTemplate } from '../../../../actions/templateEdit';
import BodyCellItem from '../../Models/BodyCellItem';
import ContractObject from '../../Models/ContractObject';

const SignatureMenu = ({ contractObject, object, setTemplateState }) => (
  <div> </div>
);

SignatureMenu.propTypes = {
  contractObject: PropTypes.instanceOf(ContractObject).isRequired,
  object: PropTypes.instanceOf(BodyCellItem).isRequired,
  setTemplateState: PropTypes.func.isRequired,
};

SignatureMenu.defaultProps = {};

const mapDispatchToProps = (dispatch) => ({
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
});

export default connect(undefined, mapDispatchToProps)(SignatureMenu);
