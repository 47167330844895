/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import moneyFormatter from 'money-formatter';
import { FieldDisplayList } from '../SharedComponents/FieldDisplayList';
import { SectionTitle } from '../SharedComponents/SectionTitle';
import ResponsiveTable from '../SharedComponents/ResponsiveTable';
import TransactionModal from '../Payments/TransactionModal';
import RefundModal from '../Payments/RefundModal';
import {
  startSubmitRefund,
  startSubmitVoid,
} from '../../actions/paymentCapture';

import { startSubmitTransaction } from '../../actions/estimatesPage';

import { FieldTitle } from '../SharedComponents/FieldTitle';

const valueFormatter = (value) => {
  switch (value) {
    case 'american_express':
      return (
        <>
          <i className="fab fa-cc-amex" />
          &nbsp;American Express
        </>
      );
    case 'diners_club':
      return (
        <>
          <i className="fab fa-cc-diners-club" />
          &nbsp;Diners Club
        </>
      );
    case 'discover':
      return (
        <>
          <i className="fab fa-cc-discover" />
          &nbsp;Discover
        </>
      );
    case 'jcb':
      return (
        <>
          <i className="fab fa-cc-jcb" />
          &nbsp;JCB
        </>
      );
    case 'master':
      return (
        <>
          <i className="fab fa-cc-mastercard" />
          &nbsp;MasterCard
        </>
      );
    case 'visa':
      return (
        <>
          <i className="fab fa-cc-visa" />
          &nbsp;Visa
        </>
      );
    case 'checking':
      return (
        <>
          <i className="fas fa-money-check-alt" />
          &nbsp;Checking
        </>
      );
    case 'savings':
      return (
        <>
          <i className="fas fa-money-check-alt" />
          &nbsp;Savings
        </>
      );
    default:
      return value;
  }
};

const PaymentMethodSection = (props) => {
  const { paymentMethods = [], transactions = [], customer } = props;
  const [selectedPayment, setPayment] = useState(undefined);
  const [selectedTransaction, setTransaction] = useState(undefined);

  const total = transactions.reduce(
    (amount, transaction) => amount + transaction.amount / 100,
    0,
  );
  return (
    <article className="payments-methods-section leap-data-section">
      <SectionTitle title="Payment Methods" />
      <div className="field-section my-1">
        <div className="field-section-row leap-row-space row">
          <FieldDisplayList
            title="Total Transactions"
            list={[{ value: moneyFormatter.format('USD', total) }]}
          />
        </div>

        <div className="field-section-row leap-row-space row">
          <section className="col-12 d-flex flex-column">
            <FieldTitle title="Payment Methods" />
            {!!paymentMethods.length && (
              <ResponsiveTable
                onRowClick={(rowData) => setPayment(rowData)}
                valueFormatter={valueFormatter}
                items={paymentMethods}
                columns={[
                  {
                    title: 'Type',
                    itemKey: 'cardType',
                  },
                  {
                    title: 'Account #',
                    itemKey: 'number',
                  },
                  {
                    title: 'Exp. Date',
                    itemKey: 'expDate',
                  },
                  {
                    title: 'Billing Zip',
                    itemKey: 'zipCode',
                  },
                ]}
              />
            )}
            {!paymentMethods.length && <p>No Payment Methods Set</p>}
          </section>
        </div>

        <div className="field-section-row leap-row-space row">
          <section className="col-12 d-flex flex-column ">
            <FieldTitle title="Transactions" />
            {!!transactions.length && (
              <ResponsiveTable
                onRowClick={(rowData) => {
                  if (rowData.type === 'Purchase') {
                    setTransaction(rowData);
                  }
                }}
                rowStyles={(item) => {
                  if (item.type !== 'Purchase') {
                    return { cursor: 'not-allowed' };
                  }
                  return undefined;
                }}
                items={transactions}
                columns={[
                  {
                    title: 'Date',
                    itemKey: 'date',
                  },
                  {
                    title: 'Transaction Id',
                    itemKey: 'gateway_transaction_id',
                  },
                  {
                    title: 'Account #',
                    itemKey: 'accountNumber',
                  },
                  {
                    title: 'Type',
                    itemKey: 'type',
                  },
                  {
                    title: 'Amount',
                    itemKey: 'displayAmount',
                  },
                  {
                    title: 'Description',
                    itemKey: 'description',
                  },
                  {
                    title: 'Created By',
                    itemKey: 'createdBy',
                  },
                ]}
              />
            )}
            {!transactions.length && <p>No Transactions</p>}
          </section>
        </div>
      </div>
      <TransactionModal
        show={!!selectedPayment}
        onClose={() => setPayment(undefined)}
        onSubmit={(params) => {
          props.startSubmitTransaction(
            { ...params, customerId: customer.objectId },
            () => {
              setPayment(undefined);
            },
          );
        }}
        paymentMethod={selectedPayment}
      />
      <RefundModal
        transaction={selectedTransaction}
        show={!!selectedTransaction}
        onClose={() => setTransaction(undefined)}
        onSubmit={(params) => {
          if (params.transactionType === 'void') {
            props.startSubmitVoid(
              { ...params, customerId: customer.objectId },
              () => {
                setTransaction(undefined);
              },
            );
          } else {
            props.startSubmitRefund(
              { ...params, customerId: customer.objectId },
              () => {
                setTransaction(undefined);
              },
            );
          }
        }}
      />
    </article>
  );
};

const mapDispatchToProps = (dispatch) => ({
  startSubmitTransaction: (params, callback) =>
    dispatch(startSubmitTransaction(params, callback)),
  startSubmitRefund: (params, callback) =>
    dispatch(startSubmitRefund(params, callback)),
  startSubmitVoid: (params, callback) =>
    dispatch(startSubmitVoid(params, callback)),
});

export default connect(undefined, mapDispatchToProps)(PaymentMethodSection);
