import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import ContractObject from '../Models/ContractObject';
import useGetDocumentSnapshot from './hooks';
import DocumentSnapshotList from './DocumentSnapshotList';
import Paginator from '../../Misc/Paginator';

export default function RestoreDocumentSnapshotModal(props) {
  const { template, show, onVersionSelection } = props;
  const [documentSnapshots, setDocumentSnapshots] = useState([]);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useGetDocumentSnapshot({
    template,
    setDocumentSnapshots,
    show,
    page,
    limit,
  });

  return (
    <Modal
      onHide={() => {
        props.onHide();
      }}
      show={show}
      dialogClassName="restore-autosave-modal-container"
    >
      <Modal.Header closeButton>
        <Modal.Title>Restore From Previous Save</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DocumentSnapshotList
          documentSnapshots={documentSnapshots}
          onVersionSelection={onVersionSelection}
        />
        <Paginator
          noCount
          page={page}
          limit={limit}
          onLimitChanged={({ value }) => {
            setLimit(value);
          }}
          pageRange={5}
          onPageClicked={(value) => {
            setPage(value);
          }}
        />
      </Modal.Body>
    </Modal>
  );
}

RestoreDocumentSnapshotModal.propTypes = {
  template: PropTypes.instanceOf(ContractObject).isRequired,
  show: PropTypes.bool.isRequired,
  onVersionSelection: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};
RestoreDocumentSnapshotModal.defaultProps = {};
