// eslint-disable-next-line func-names
const toShallowJSON = function() {
  const { attributes } = this;

  const keys = Object.keys(attributes);

  const shallow = keys.reduce((shell, key) => {
    const realValue = this.get(key);
    const value = getValue(realValue);

    return {
      ...shell,
      [key]: value,
    };
  }, {});

  return shallow;
};

const getShallowArray = (value) =>
  value.map((item) => {
    const canBePointer = item.toPointer;
    if (canBePointer) {
      return item.toPointer();
    }
    return item;
  });

const getValue = (value) => {
  const canBePointer = value.toPointer;

  const isArray = Array.isArray(value);

  if (canBePointer) {
    return value.toPointer();
  }

  if (isArray) {
    return getShallowArray(value);
  }

  return value;
};

export default toShallowJSON;
