import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import Parse from 'parse';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import SweetAlert from 'react-bootstrap-sweetalert';
import { generateKeyBetween } from 'fractional-indexing';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import Paginator from '../Misc/Paginator';
import {
  setPage,
  setLimit,
  setSelectedCategories,
  setSelectedSubCategories,
  startDownloadSubCategories,
  startCountItems,
  startFetchItems,
  setIncludedOffices,
  setItems,
  setItem,
  startFetchOptionsForItems,
  startRefreshItems,
  startAddNewItem,
  startDownloadOwensCorning,
  startDownloadSoftLite,
  startExportPriceGuide,
  startImportPriceGuide,
  onPriceGuideScroll,
  getOtherPageItem,
} from '../../actions/priceGuide2';
import {
  injectReduxInContext,
  toggleStickyHeaders,
} from '../../actions/stickyContext';
import { showDeleteConfirmation } from '../../actions/deleteConfirmation';
import CategoriesDropDown from './CategoriesDropDown';
import SubCategoriesDropDown from './SubCategoriesDropDown';
import FormGroup from '../FormGroup';
import OfficesDropDown from '../IncludedOfficesDropDown';
import ActiveColumns from './ActiveColumns/ActiveColumns';
import MSIHeader from './MeasureSheetItems/Header';
import MSIRow from './MeasureSheetItems/Row';
import MSIEditModal from './EditModal';
import UpChargeEditModal from './UpCharges/EditModal';
import AdditionalDetailsEditModal from './AdditionalDetails/AdditionalDetailsEditModal';
import PlaceholderEditModal from './Placeholders/PlaceholderEditModal';
import { history } from '../../router';
import { setOnDragEndCallback } from '../DropContext';
import AppToaster from '../../utils/AppToaster';
import { handleError } from '../../actions/auth';
import Tools from './Tools';
import MassPriceChangeModal from './MassPriceChangeModal';
import SoftLiteModal from './SoftLiteModal';
import ImportModal from '../ImportModal';
import Sticky from './Sticky';
import initContext from './StickyUtils';
import TitleButton from '../TitleButton';

export const portal = document.createElement('div');
document.body.appendChild(portal);
const priceGuideVertStickyContext = initContext('priceguide__index');
let toPath = '';
class PriceGuide extends React.Component {
  allattributes = {};

  constructor(props) {
    super(props);
    this.state = {
      editItem: undefined,
      showMassPriceChange: false,
      showSoftLite: false,
      showOwensCorningAlert: false,
      canDrag: true,
    };
    setOnDragEndCallback('msi_index', this.onItemsDidReorder);
  }

  UNSAFE_componentWillMount() {
    window.onbeforeunload = () => {
      const { upCharges, options, items } = this.props;
      const dirtyUpcharges = Object.keys(upCharges || {})
        .map((id) => upCharges[id])
        .some((obj) => obj.dirty());
      const dirtyOptions = Object.keys(options || {})
        .map((id) => options[id])
        .some((obj) => obj.dirty());
      const saveAllEnabled = items.some((obj) => obj.dirty());
      return saveAllEnabled || dirtyUpcharges || dirtyOptions
        ? 'Changes you made may not be saved.'
        : null;
    };

    const {
      includedOffices,
      page,
      limit,
      selectedCategories,
      selectedSubCategories,
      startCountItems: _startCountItems,
      startFetchItems: _startFetchItems,
    } = this.props;
    _startCountItems(
      includedOffices,
      selectedCategories,
      selectedSubCategories,
    );
    _startFetchItems(
      includedOffices,
      limit,
      page,
      selectedCategories,
      selectedSubCategories,
    );
  }

  componentDidMount() {
    const { injectReduxInContext: _injectReduxInContext } = this.props;
    this.initIncludedOffices();
    _injectReduxInContext(priceGuideVertStickyContext);
    this.renderButtons();
  }

  componentDidUpdate = (prevProps) => {
    const newAttributes = this.getEditedObjects(this.props);
    if (!_.isEqual(this.allattributes, newAttributes)) {
      this.renderButtons();
      this.allattributes = { ...newAttributes };
    }
  };

  componentWillUnmount() {
    const {
      setButtons,
      upCharges,
      options,
      items,
      showDeleteConfirmation: _showDeleteConfirmation,
    } = this.props;
    const dirtyUpcharges = Object.keys(upCharges || {})
      .map((id) => upCharges[id])
      .some((obj) => obj.dirty());
    const dirtyOptions = Object.keys(options || {})
      .map((id) => options[id])
      .some((obj) => obj.dirty());
    const saveAllEnabled = items.some((obj) => obj.dirty());
    if (saveAllEnabled || dirtyUpcharges || dirtyOptions) {
      toPath = history.location.pathname;
      history.push('/price_guide');
      _showDeleteConfirmation({
        title: 'Do You Still Want to leave ?',
        message: 'Changes you made may not be saved.',
        onConfirm: async () => {
          items.forEach((obj) => obj.revert());
          Object.keys(upCharges || {})
            .map((id) => upCharges[id])
            .map((obj) => obj.revert());
          Object.keys(options || {})
            .map((id) => options[id])
            .map((obj) => obj.revert());
          history.push(toPath);
        },
      });
    }
    setButtons();
  }

  getEditedObjects = (props) => {
    const { upCharges, options, items } = props;
    const updated = {};
    Object.keys(upCharges || {}).forEach((id) => {
      updated[`upcharge-${id}`] = {
        ...upCharges[id].attributes,
        id,
      };
    });
    Object.keys(options || {}).forEach((id) => {
      updated[`option-${id}`] = {
        ...options[id].attributes,
        id,
      };
    });
    items.forEach((item) => {
      updated[`item-${item.id}`] = {
        ...item.attributes,
        id: item.id,
      };
    });
    return updated;
  };

  renderButtons = () => {
    const { items = {}, options = {}, upCharges = {}, setButtons } = this.props;
    const dirtyUpcharges = Object.keys(upCharges)
      .map((id) => upCharges[id])
      .some((obj) => obj.dirty());
    const dirtyOptions = Object.keys(options || {})
      .map((id) => options[id])
      .some((obj) => obj.dirty());
    const dirtyItems = items.some((obj) => obj.dirty());
    const saveAllEnabled = dirtyItems || dirtyUpcharges || dirtyOptions;
    setButtons(
      <TitleButton
        disabled={!saveAllEnabled}
        variant="success"
        onClick={() => {
          this.onSaveAll();
        }}
        title="Save"
      />,
    );
  };

  onLimitChanged = (limit) => {
    const {
      includedOffices,
      selectedCategories,
      selectedSubCategories,
      setPage: _setPage,
      startFetchItems: _startFetchItems,
      setLimit: _setLimit,
    } = this.props;
    const resetPage = 1;
    _setLimit(limit);
    _setPage(resetPage);
    _startFetchItems(
      includedOffices,
      limit,
      resetPage,
      selectedCategories,
      selectedSubCategories,
    );
  };

  onPageChanged = (page) => {
    const {
      includedOffices,
      limit,
      selectedCategories,
      selectedSubCategories,
      setPage: _setPage,
      startFetchItems: _startFetchItems,
    } = this.props;
    _setPage(page);
    _startFetchItems(
      includedOffices,
      limit,
      page,
      selectedCategories,
      selectedSubCategories,
    );
  };

  onSubCategoryChanged = (values = []) => {
    const selectedSubCategories = [...values];
    const {
      includedOffices,
      limit,
      selectedCategories,
      setSelectedSubCategories: _setSelectedSubCategories,
      setPage: _setPage,
      startCountItems: _startCountItems,
      startFetchItems: _startFetchItems,
    } = this.props;
    _setSelectedSubCategories(selectedSubCategories);
    const resetPage = 1;
    _setPage(resetPage);
    _startCountItems(
      includedOffices,
      selectedCategories,
      selectedSubCategories,
    );
    _startFetchItems(
      includedOffices,
      limit,
      resetPage,
      selectedCategories,
      selectedSubCategories,
    );
  };

  onCategoryChanged = (values = []) => {
    const selectedCategories = [...values];
    const {
      includedOffices,
      limit,
      selectedSubCategories,
      setCategories: _setCategories,
      setPage: _setPage,
      startCountItems: _startCountItems,
      startFetchItems: _startFetchItems,
      startDownloadSubCategories: _startDownloadSubCategories,
    } = this.props;
    _setCategories(selectedCategories);
    _startDownloadSubCategories(selectedCategories);
    const resetPage = 1;
    _setPage(resetPage);
    _startCountItems(
      includedOffices,
      selectedCategories,
      selectedSubCategories,
    );
    _startFetchItems(
      includedOffices,
      limit,
      resetPage,
      selectedCategories,
      selectedSubCategories,
    );
  };

  onIncludedOfficesChanged = (values) => {
    const {
      limit,
      selectedCategories,
      selectedSubCategories,
      setIncludedOffices: _setIncludedOffices,
      setPage: _setPage,
      startCountItems: _startCountItems,
      startFetchItems: _startFetchItems,
    } = this.props;
    _setIncludedOffices(values);
    const resetPage = 1;
    _setPage(resetPage);
    _startCountItems(values, selectedCategories, selectedSubCategories);
    _startFetchItems(
      values,
      limit,
      resetPage,
      selectedCategories,
      selectedSubCategories,
    );
  };

  onEditItemClicked = (item) => {
    this.setState({ editItem: item });
  };

  /**
   * Handle the reordering of items to set the new orderNumber (fractional index)
   * @param {import('react-beautiful-dnd').DropResult} result Result object from react-beautiful-dnd
   */
  onItemsDidReorder = async (result) => {
    const { destination } = result;
    const activeIndex = result.source.index;
    const destinationIndex = destination.index;
    const {
      includedOffices,
      page,
      limit,
      selectedCategories,
      selectedSubCategories,
      getOtherPageItem: _getOtherPageItem,
      items,
      setItems: _setItems,
    } = this.props;
    const itemsExcludingDropped = items.filter(
      (item) => item.id !== result.draggableId,
    );
    /** @type {string | null} */
    let aboveOrderNumber;
    /** @type {string | null} */
    let belowOrderNumber;
    // If the destination is the first item on the page, and the page is not the first, we need to get the last item of the prev page
    if (destinationIndex === 0 && page >= 2) {
      const pageType = 'prev';
      const prevPageLastItem = await _getOtherPageItem(
        pageType,
        includedOffices,
        limit,
        page,
        selectedCategories,
        selectedSubCategories,
      );
      aboveOrderNumber = prevPageLastItem?.get('orderNumber') ?? null;
    } else {
      aboveOrderNumber =
        itemsExcludingDropped[destinationIndex - 1]?.get('orderNumber') ?? null;
    }
    // If the destination is the last item in the page, we need to get the first item of the next page if it exists
    if (destinationIndex === items.length - 1) {
      const pageType = 'next';
      const nextPageFirstItem = await _getOtherPageItem(
        pageType,
        includedOffices,
        limit,
        page,
        selectedCategories,
        selectedSubCategories,
      );
      belowOrderNumber = nextPageFirstItem?.get('orderNumber') ?? null;
    } else {
      belowOrderNumber =
        itemsExcludingDropped[destinationIndex]?.get('orderNumber') ?? null;
    }
    const newOrder = generateKeyBetween(belowOrderNumber, aboveOrderNumber);
    items[activeIndex].set('orderNumber', newOrder);
    const [item] = items.splice(activeIndex, 1);
    items.splice(destinationIndex, 0, item);
    _setItems(items);
  };

  onExpandAll = (expand) => {
    const {
      items,
      setItems: _setItems,
      startFetchOptionsForItems: _startFetchOptionsForItems,
    } = this.props;
    const newItems = items.map((obj) => {
      const newInstance = obj.newInstance();
      newInstance.isOptionsExpanded = expand;
      return newInstance;
    });
    _setItems(newItems);
    _startFetchOptionsForItems(newItems);
  };

  onSaveAll = async () => {
    const {
      handleErr: _handleErr,
      items,
      options,
      setItems: _setItems,
      upCharges,
    } = this.props;
    try {
      items.forEach((obj) => {
        obj.convertImage();
      });
      const objects = await Parse.Object.saveAll(
        items.filter((item) => item.dirty()),
      );

      const dirtyOptions = Object.keys(options || {})
        .map((id) => options[id])
        .filter((item) => item.dirty());
      const dirtyUpcharges = Object.keys(upCharges || {})
        .map((id) => upCharges[id])
        .filter((item) => item.dirty())
        .map((op) => op.convertImage());

      await Parse.Object.saveAll(dirtyOptions);
      await Parse.Object.saveAll(dirtyUpcharges);

      await Parse.Cloud.run('measureSheetItemsSaved', {
        objects: objects.map((obj) => obj.toJSON()),
      });
      const updatedItems = items.map((obj) => obj.newInstance());
      _setItems(updatedItems);
      AppToaster.show({
        message: `${items.length} items saved!`,
        timeout: 3000,
      });
    } catch (e) {
      _handleErr(e);
    }
  };

  onRefreshAll = () => {
    const { items, startRefresh: _startRefresh } = this.props;
    _startRefresh(items);
  };

  onConfirmOwensCorningDownload = () => {
    const {
      startDownloadOwensCorning: _startDownloadOwensCorning,
    } = this.props;
    _startDownloadOwensCorning(() => {
      this.setState({ showOwensCorningAlert: false });
    });
  };

  onExportPriceGuide = async () => {
    const { startExportPriceGuide: _startExportPriceGuide } = this.props;
    try {
      await _startExportPriceGuide();
    } catch ({ message }) {
      AppToaster.show({ message, timeout: 500 });
    }
  };

  onImportPriceGuide = (params) => {
    const { startImportPriceGuide: _startImportPriceGuide } = this.props;
    try {
      this.setState({ showImport: false });
      _startImportPriceGuide(params);
    } catch ({ message }) {
      AppToaster.show({ message, timeout: 500 });
    }
  };

  onImportPriceGuideError = ({ message }) => {
    AppToaster.show({ message, timeout: 500 });
  };

  onIncrementUpChargesClicked = () => {
    this.onExpandAll(false);
    history.push('/price_guide/increment_upcharges');
  };

  onMassCopyClicked = () => {
    this.onExpandAll(false);
    history.push('/price_guide/copy/source');
  };

  initIncludedOffices() {
    const { includedOffices, maxOfficeCount, offices } = this.props;
    if (maxOfficeCount === 1) {
      this.onIncludedOfficesChanged([offices[0].id]);
    } else {
      const realOffices = includedOffices.filter(
        (officeId) =>
          offices.find(({ id }) => officeId === id) ||
          officeId === '**No Office**',
      );

      if (realOffices.length !== includedOffices.length) {
        this.onIncludedOfficesChanged(realOffices);
      }
    }
  }

  render() {
    const {
      selectedCategories,
      selectedSubCategories,
      page,
      limit,
      count,
      items,
      offices,
      options = {},
      upCharges = {},
      includedOffices,
      additionalDetails,
      placeholders,
      upChargeEdit,
      onPriceGuideScroll: _onPriceGuideScroll,
      setItem: _setItem,
      disableSticky,
      toggleStickyHeaders: _toggleStickyHeaders,
      maxOfficeCount,
      addNewItem,
    } = this.props;
    const {
      editItem,
      showMassPriceChange,
      showSoftLite,
      showImport,
      showOwensCorningAlert,
      canDrag,
    } = this.state;
    const optionsList = Object.keys(options || {}).map((id) => options[id]);
    const upChargesList = Object.keys(upCharges || {}).map(
      (id) => upCharges[id],
    );
    const saveAllEnabled = [
      ...items,
      ...optionsList,
      ...upChargesList,
    ].some((obj) => obj.dirty());
    const isAllExpanded = items.some((obj) => obj.isOptionsExpanded);

    // We add 1 to the length of the offices array to account for the "No Office" option
    const isMSItemSortable =
      ((includedOffices.length === 0 ||
        includedOffices.length === offices.length + 1) &&
        selectedCategories.length === 1 &&
        selectedSubCategories.length === 0) ||
      false;

    return (
      <div
        ref={(ref) => {
          if (ref) {
            priceGuideVertStickyContext.setScrollElementRef(
              ref,
              _onPriceGuideScroll,
            );
          }
        }}
        id="priceguide__index"
        className="priceguide__index default-page-padding"
      >
        <MSIEditModal
          show={!!editItem}
          item={editItem}
          onClose={async (item) => {
            this.setState({ editItem: undefined });
            if (item) {
              await _setItem(item.newInstance());
            }
          }}
        />
        <UpChargeEditModal
          upCharge={upChargeEdit.upCharge}
          onClose={upChargeEdit.onClose}
          onSave={upChargeEdit.onSave}
        />
        <MassPriceChangeModal
          show={showMassPriceChange}
          onClose={() => this.setState({ showMassPriceChange: false })}
          isMulti
        />
        <SoftLiteModal
          show={showSoftLite}
          onClose={() => this.setState({ showSoftLite: false })}
        />
        <ImportModal
          title="Import Price Guide"
          show={showImport}
          onClose={() => this.setState({ showImport: false })}
          onSave={this.onImportPriceGuide}
          onError={this.onImportPriceGuideError}
        />
        {additionalDetails && (
          <AdditionalDetailsEditModal
            show
            objects={additionalDetails.objects}
            onClose={additionalDetails.onClose}
            onSave={additionalDetails.onSave}
            tagRow={additionalDetails.tagRow}
            showNotAdded={additionalDetails.showNotAdded}
            isUpCharge={additionalDetails.isUpCharge}
          />
        )}
        {placeholders && (
          <PlaceholderEditModal
            show
            onClose={placeholders.onClose}
            placeholders={placeholders.values}
            onSave={placeholders.onSave}
            showNotAdded={placeholders.showNotAdded}
          />
        )}
        {showOwensCorningAlert && (
          <SweetAlert
            warning
            showCancel
            title="Download Owens Corning Price Guide"
            confirmBtnText="Yes"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            onConfirm={this.onConfirmOwensCorningDownload}
            onCancel={() => this.setState({ showOwensCorningAlert: false })}
          >
            After all items are downloaded you must assign them to an office.
            <br />
            <br />
            Are you sure you want to continue?
          </SweetAlert>
        )}
        <div style={{ position: 'relative', zIndex: 11 }}>
          <div style={{ position: 'relative', zIndex: 5 }}>
            <FormGroup title="Tools">
              <Tools
                onMassPriceChangeClicked={() =>
                  this.setState({ showMassPriceChange: true })
                }
                onMassCopy={this.onMassCopyClicked}
                onDownloadOwensCorning={() =>
                  this.setState({ showOwensCorningAlert: true })
                }
                onDownloadSoftLite={() => this.setState({ showSoftLite: true })}
                onExportPriceGuide={this.onExportPriceGuide}
                onImportPriceGuide={() => this.setState({ showImport: true })}
                onIncrementUpCharges={this.onIncrementUpChargesClicked}
              />
            </FormGroup>
          </div>
          <FormGroup title="Active Columns">
            <ActiveColumns
              disableSticky={disableSticky}
              toggleStickyHeaders={_toggleStickyHeaders}
            />
          </FormGroup>
          {maxOfficeCount !== 1 && (
            <FormGroup title="Included Offices">
              <OfficesDropDown
                onChange={this.onIncludedOfficesChanged}
                selected={includedOffices}
                includeNone
              />
            </FormGroup>
          )}
          <FormGroup title="Categories">
            <CategoriesDropDown
              isMulti
              closeMenuOnSelect={false}
              onChange={(value) => this.onCategoryChanged(value)}
              value={selectedCategories}
            />
          </FormGroup>
          <FormGroup title="Sub Categories">
            <SubCategoriesDropDown
              isMulti
              closeMenuOnSelect={false}
              onChange={(value) => this.onSubCategoryChanged(value)}
              value={selectedSubCategories}
            />
          </FormGroup>
        </div>
        <div
          id="priceguide__container"
          className="priceguide__container"
          onScroll={(event) => {
            const { scrollLeft } = event.target;
            if (scrollLeft > 200 && canDrag) {
              this.setState({ canDrag: false });
            } else if (scrollLeft < 200 && !canDrag) {
              this.setState({ canDrag: true });
            }
          }}
        >
          <div
            id="priceguide__msi-table"
            className={`priceguide__msi-table ${canDrag ? ' can-drag' : ''}`}
          >
            <div style={{ position: 'relative', zIndex: 700 }}>
              <Sticky
                scrollElementId="priceguide__index"
                stickTo={{
                  type: 'elementId',
                  value: 'top-crumb-bar',
                }}
                uid="priceguide__Header"
                bumpType="blue"
                stackOrder={0}
                topRef
              >
                <MSIHeader
                  maxOfficeCount={maxOfficeCount}
                  onAdd={() => addNewItem(undefined, items)}
                  onSaveAll={this.onSaveAll}
                  saveAllDisabled={!saveAllEnabled}
                  onExpandAll={this.onExpandAll}
                  isAllExpanded={isAllExpanded}
                  onRefresh={this.onRefreshAll}
                  isRefreshDisabled={!saveAllEnabled}
                />
              </Sticky>
            </div>
            <Droppable droppableId="msi_index" type="msi_index">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {items.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                      type={item.id}
                      isDragDisabled={!isMSItemSortable}
                    >
                      {(draggableProvided) => (
                        <MSIRow
                          item={item}
                          index={index}
                          items={items}
                          onEdit={this.onEditItemClicked}
                          draggableProvided={draggableProvided}
                          isMSItemSortable={isMSItemSortable}
                        />
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
            <div
              style={{
                position: 'relative',
                height: 30,
                backgroundColor: 'transparent',
              }}
            />
          </div>
        </div>
        <Paginator
          page={page}
          limit={limit}
          totalCount={count}
          onLimitChanged={({ value }) => this.onLimitChanged(value)}
          pageRange={5}
          onPageClicked={(value) => this.onPageChanged(value)}
          noCount={false}
          noRowCount={false}
        />
      </div>
    );
  }
}

PriceGuide.propTypes = {
  page: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  setCategories: PropTypes.func.isRequired,
  setLimit: PropTypes.func.isRequired,
  startCountItems: PropTypes.func.isRequired,
  getOtherPageItem: PropTypes.func.isRequired,
  startFetchItems: PropTypes.func.isRequired,
  includedOffices: PropTypes.arrayOf(PropTypes.string),
  selectedCategories: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.arrayOf(PropTypes.instanceOf(Parse.Object)),
  setIncludedOffices: PropTypes.func.isRequired,
  additionalDetails: PropTypes.shape({
    objects: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    tagRow: PropTypes.shape({
      inputType: PropTypes.string,
      pickerValues: PropTypes.arrayOf(PropTypes.string),
      title: PropTypes.string,
      note: PropTypes.string,
      defaultValue: PropTypes.string,
      shouldCopy: PropTypes.bool,
      required: PropTypes.bool,
    }),
  }),
  placeholders: PropTypes.shape({
    values: PropTypes.arrayOf(
      PropTypes.shape({
        placeholder: PropTypes.string.isRequired,
        replacement: PropTypes.string.isRequired,
      }),
    ),
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
  }),
  setItem: PropTypes.func.isRequired,
  setItems: PropTypes.func.isRequired,
  handleErr: PropTypes.func.isRequired,
  startFetchOptionsForItems: PropTypes.func.isRequired,
  startRefresh: PropTypes.func.isRequired,
  upChargeEdit: PropTypes.shape({
    upCharge: PropTypes.any,
    onSave: PropTypes.func,
    onClose: PropTypes.func,
  }),
  addNewItem: PropTypes.func.isRequired,
  startDownloadOwensCorning: PropTypes.func.isRequired,
  startExportPriceGuide: PropTypes.func.isRequired,
  startImportPriceGuide: PropTypes.func.isRequired,
  offices: PropTypes.arrayOf(PropTypes.instanceOf(Parse.Object)).isRequired,
  ...layoutContextPropTypes,
};

PriceGuide.defaultProps = {
  includedOffices: [],
  selectedCategories: [],
  items: [],
  additionalDetails: undefined,
  placeholders: undefined,
  upChargeEdit: {},
};

const mapStateToProps = ({
  plan = {},
  priceGuide2,
  auth: { offices = [] },
}) => {
  const {
    page,
    limit,
    count,
    otherPageItem,
    selectedCategories,
    selectedSubCategories,
    items,
    itemIds,
    includedOffices,
    additionalDetails,
    placeholders,
    upChargeEdit,
    options,
    upCharges,
    disableSticky,
  } = priceGuide2;
  /** @type {Parse.Object[]} */
  const measureSheetItems = itemIds.map((id) => items[id]);
  return {
    disableSticky,
    options,
    upCharges,
    page,
    limit,
    count,
    otherPageItem,
    selectedCategories,
    selectedSubCategories,
    items: measureSheetItems,
    includedOffices,
    additionalDetails,
    placeholders,
    upChargeEdit,
    maxOfficeCount: plan.maxOfficeCount,
    offices,
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleStickyHeaders: (disableSticky) =>
    dispatch(toggleStickyHeaders(disableSticky)),
  injectReduxInContext: (context) => dispatch(injectReduxInContext(context)),
  setPage: (page) => dispatch(setPage(page)),
  setLimit: (limit) => dispatch(setLimit(limit)),
  setSelectedSubCategories: (subCategories) =>
    dispatch(setSelectedSubCategories(subCategories)),
  setCategories: (categories) => dispatch(setSelectedCategories(categories)),
  startDownloadSubCategories: (categories) =>
    dispatch(startDownloadSubCategories(categories)),
  startCountItems: (includedOffices, categories, subCategories) =>
    dispatch(startCountItems(includedOffices, categories, subCategories)),
  getOtherPageItem: (includedOffices, limit, page, categories, subCategories) =>
    dispatch(
      getOtherPageItem(includedOffices, limit, page, categories, subCategories),
    ),
  startFetchItems: (includedOffices, limit, page, categories, subCategories) =>
    dispatch(
      startFetchItems(includedOffices, limit, page, categories, subCategories),
    ),
  setIncludedOffices: (offices) => dispatch(setIncludedOffices(offices)),
  setItems: (items) => dispatch(setItems(items)),
  handleErr: (e) => dispatch(handleError(e)),
  startFetchOptionsForItems: (items) =>
    dispatch(startFetchOptionsForItems(items)),
  startRefresh: (items) => dispatch(startRefreshItems(items)),
  addNewItem: (index, items) => dispatch(startAddNewItem(index, items)),
  startDownloadOwensCorning: (callback) =>
    dispatch(startDownloadOwensCorning(callback)),
  startDownloadSoftLite: (params, callback) =>
    dispatch(startDownloadSoftLite(params, callback)),
  startExportPriceGuide: () => dispatch(startExportPriceGuide()),
  startImportPriceGuide: (params) => dispatch(startImportPriceGuide(params)),
  onPriceGuideScroll: (event) => dispatch(onPriceGuideScroll(event)),
  showDeleteConfirmation: (params) => dispatch(showDeleteConfirmation(params)),
  setItem: (item) => dispatch(setItem(item)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(PriceGuide),
);
