/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';

const DetailDraggable = (props) => {
  const { draggable, children, index, draggableId } = props;

  return draggable ? (
    <Draggable draggableId={draggableId} index={index}>
      {(draggableProvided, snapshot) => {
        return (
          <div
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            {...draggableProvided.dragHandleProps}
            className="w-100 add-detail-block"
          >
            {children}
            {draggableProvided.placeholder}
          </div>
        );
      }}
    </Draggable>
  ) : (
    <div className="w-100 add-detail-block">{children}</div>
  );
};

DetailDraggable.defaultProps = {
  draggable: false,
};
DetailDraggable.propTypes = {
  draggable: PropTypes.bool,
  draggableId: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  index: PropTypes.number.isRequired,
};

export default DetailDraggable;
