/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Parse } from 'parse';
import { FileInput } from '@blueprintjs/core';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import { startUpdateConfig } from '../../actions/auth';
import Panel from '../Panel';
import TitleButton from '../TitleButton';
import Copy from './Copy';

let newFile;

export class CompanyLogo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: props.url || '',
    };
  }

  componentDidMount() {
    const {
      setCrumbs,
      setButtons,
      location: { pathname },
    } = this.props;

    const crumb = { title: 'Company Logo', link: pathname };
    setCrumbs([crumb]);
    setButtons(
      <TitleButtons
        onCopyClicked={this.onCopyClicked}
        onSaveClicked={this.onSaveClicked}
      />,
    );
  }

  UNSAFE_componentWillReceiveProps({ url }) {
    this.setState({ url: url || '' });
  }

  componentWillUnmount() {
    const { setCrumbs, setButtons } = this.props;

    setCrumbs([]);
    setButtons();
  }

  onFileChanged = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      this.setState({ url: event.target.result });
    };
    reader.readAsDataURL(file);

    const extension = file.type.split('/')[1];
    const name = `company_logo.${extension}`;
    newFile = new Parse.File(name, file);
  };

  onCopyClicked = () => {
    this.setState({ showCopy: true });
  };

  onSaveClicked = () => {
    this.props.startUpdateConfig({ companyLogo: newFile });
  };

  render() {
    return (
      <>
        <Copy
          title="Copy Company Logo"
          show={this.state.showCopy}
          warning="Warning! This will overwrite your current Company Logo"
          configKeys={['companyLogo']}
          onClose={() => this.setState({ showCopy: false })}
        />
        <div className="default-page-padding">
          <Panel title="Company Logo">
            <div className="company-logo-container">
              <img alt="Company Logo" src={this.state.url} />
              <FileInput
                text="Choose file..."
                onChange={this.onFileChanged}
                inputProps={{ accept: 'image/*' }}
              />
            </div>
          </Panel>
        </div>
      </>
    );
  }
}

const TitleButtons = ({ onCopyClicked, onSaveClicked }) => (
  <>
    <TitleButton
      variant="primary"
      onClick={() => onCopyClicked({ source: 'copy' })}
      title="Copy"
    />
    <TitleButton
      variant="success"
      onClick={() => onSaveClicked({ source: 'save' })}
      title="Save"
    />
  </>
);

CompanyLogo.propTypes = {
  url: PropTypes.string,
  startUpdateConfig: PropTypes.func.isRequired,
  setCrumbs: PropTypes.func.isRequired,
  setButtons: PropTypes.func.isRequired,
  ...layoutContextPropTypes,
};

CompanyLogo.defaultProps = {
  url: '',
};

TitleButtons.propTypes = {
  onCopyClicked: PropTypes.func.isRequired,
  onSaveClicked: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth: { config = {} } }) => {
  const { companyLogo = {} } = config;
  return {
    url: companyLogo.url,
    objectId: config.objectId, // this forces props to update when office is changed
  };
};

const mapDispatchToProps = (dispatch) => ({
  startUpdateConfig: (updates) => dispatch(startUpdateConfig(updates)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(CompanyLogo),
);
