/* eslint-disable import/no-cycle */
import Parse from 'parse';
import moment from 'moment';
import { handleError } from './auth';

export const setActiveUserCount = (activeUsers) => ({
  type: 'SET_ACCOUNT_ACTIVE_USER_COUNT',
  activeUsers,
});

export const startCountActiveUsers = () => async (dispatch) => {
  const query = new Parse.Query(Parse.User);
  query.equalTo('isActive', true);
  const count = await query.count();
  dispatch(setActiveUserCount(count));
};

export const setDocuSigns = (docusigns) => ({
  type: 'SET_ACCOUNT_DOCUSIGNS',
  docusigns,
});

export const setDocuSignCount = (count) => ({
  type: 'SET_ACCOUNT_DOCUSIGN_COUNT',
  count,
});

const fileSourceMap = {
  contractUpload: 'contractPDF',
  proposalUpload: 'proposalPDF',
  contractVerifyUpload: 'contractVerifyPDF',
  proposalVerifyUpload: 'proposalVerifyPDF',
};

export const startFetchDocuSigns = ({
  startDate = moment().startOf('month'),
  endDate = moment(),
  limit = 10,
  page = 1,
}) => async (dispatch, getState) => {
  try {
    const query = new Parse.Query('SentDocuSign');
    query.descending('createdAt');
    const {
      auth: { company },
    } = getState();
    query.equalTo('company', company);
    query.greaterThanOrEqualTo('createdAt', startDate.startOf('day').toDate());
    query.lessThanOrEqualTo('createdAt', endDate.endOf('day').toDate());
    const count = await query.count();
    const skip = (page - 1) * limit;
    query.skip(skip);
    query.limit(limit);
    query.select([
      'customer.nameFirst',
      'customer.nameLast',
      'customer.estimate.docuSignCompleted',
      'customer.estimate.contractPDF',
      'customer.estimate.proposalPDF',
      'customer.estimate.contractVerifyPDF',
      'customer.estimate.proposalVerifyPDF',
      'customer.estimate.documents',
      'createdBy.nameFirst',
      'createdBy.nameLast',
      'docuSignResponse',
      'source',
      'document',
      'completed',
    ]);
    const docusigns = await query.find();
    const objects = docusigns.map((object) => {
      const json = object.toJSON();
      const { customer = {} } = json;
      const { estimate = {} } = customer;
      const { docuSignCompleted = {} } = estimate;
      let { completed } = json;
      let file = completed || json.document;
      if (!file) {
        // backwards compatability for old way files were retrieved for docusign objects
        const fileSource = fileSourceMap[json.source];
        const { documents = {} } = estimate;
        file = estimate[fileSource] || documents[json.source];
        completed = docuSignCompleted[json.source];
      }
      return { ...json, completed, file };
    });
    await dispatch(setDocuSigns(objects));
    await dispatch(setDocuSignCount(count));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const searchText = (text) => ({
  type: 'SET_DOCUSIGN_SEARCH_TEXT',
  text,
});

export const setDocuSignSearchText = (text) => (dispatch) =>
  dispatch(searchText(text));

export const pagination = (page, limit) => ({
  type: 'SET_DOCUSIGN_PAGINATION',
  page,
  limit,
});

export const setDocuSignPagination = (page, limit) => async (dispatch) =>
  dispatch(pagination(page, limit));

// SET_START_DATE
export const setDocuSignFilterStartDate = (startDate) => ({
  type: 'SET_DOCUSIGN_FILTER_START_DATE',
  startDate,
});

// SET_END_DATE
export const setDocuSignFilterEndDate = (endDate) => ({
  type: 'SET_DOCUSIGN_FILTER_END_DATE',
  endDate,
});

export const resetAccount = () => ({
  type: 'RESET_ACCOUNT',
});
