/* eslint-disable import/no-named-as-default */
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import TemplateIndex from './TemplateIndex';
import TemplateLibrary from './TemplateLibrary';
import TemplateGroup from './TemplateGroup';
import TemplateGroupLibrary from './TemplateGroupLibrary';
import TemplateEdit from './TemplateEdit';
import ContractRestorePage from './ContractRestorePage';

const Templates = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}/:type/restore_deleted`}
        component={ContractRestorePage}
      />
      <Route
        path={`${path}/:type/template_group/:id`}
        component={TemplateGroup}
      />
      <Route
        path={`${path}/:type/template_groups`}
        component={TemplateGroupLibrary}
      />
      <Route path={`${path}/:type/template`} component={TemplateLibrary} />
      <Route path={`${path}/:type/:id`} component={TemplateEdit} />
      <Route path={`${path}/:type`} component={TemplateIndex} />
    </Switch>
  );
};

export default Templates;
