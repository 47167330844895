import { validateDimention } from '../../../PriceGuide/AdditionalDetails/validationErrors';

export const validateAlertInterval = (value, state) => {
  if (state.alertEnabled) {
    if (!value || value <= 0) {
      return 'Must enter a value greater than 0';
    }
  }
  return '';
};

export const validateResultsOptions = (values = [], state) =>
  values.map(
    ({
      title,
      inputType,
      values: pickerValues,
      salesforce = {},
      ...object
    }) => {
      const error = {
        title: '',
        inputType: '',
        values: '',
        salesforce: {
          type: '',
        },
        minSizePickerWidth: validateDimention('minSizePickerWidth', object),
        maxSizePickerWidth: validateDimention('maxSizePickerWidth', object),
        minSizePickerHeight: validateDimention('minSizePickerHeight', object),
        maxSizePickerHeight: validateDimention('maxSizePickerHeight', object),
        minSizePickerDepth: validateDimention('minSizePickerDepth', object),
        maxSizePickerDepth: validateDimention('maxSizePickerDepth', object),
      };
      if (!title) {
        error.title = 'Invalid Value';
      }
      if (!inputType) {
        error.inputType = 'Invalid Value';
      }
      if (
        (inputType === '__picker' || inputType === 'multiSelectPicker') &&
        (!pickerValues || !pickerValues.length)
      ) {
        error.values = 'You must specify at least one picker option';
      }
      if (inputType === 'linkedObject' && !pickerValues) {
        error.values = 'You must specify at least one picker option';
      }
      if (salesforce.field && !salesforce.type) {
        error.salesforce.type = 'Required';
      }
      return error;
    },
  );

// salesforce: PropTypes.shape({
//   field: PropTypes.string,
//   type: PropTypes.string,
// }),
