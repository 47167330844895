/* eslint-disable react/button-has-type */
/* eslint-disable max-len */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import Parse from 'parse';
import qs from 'query-string';
import moneyFormatter from 'money-formatter';
import LeapTemplate from './LeapTemplate';
import AccountInfoGroup from './AccountInfoGroup';
import ProSellSuccess from './ProSellSuccess';
import { history } from '../../router';
import AppToaster from '../../utils/AppToaster';
import PaymentMethodModal from '../PaymentMethodModal';
import TermsOfService from './TermsOfService';

class ProSellSignup extends React.Component {
  constructor(props) {
    super(props);
    const { token } = qs.parse(this.props.location.search);
    this.token = token;
    this.state = {
      companyName: '',
      firstName: '',
      lastName: '',
      occId: '',
      email: '',
      setupFee: 0,
      numberOfUsers: 0,
      nonPromoCostPerUser: 0,
      promoCostPerUser: 0,
      promotionPeriodMonths: 0,
      nonPromoSetupFee: 0,
      success: false,
      showPaymentCapture: false,
      paymentToken: '',
      paymentNumber: '',
      submitButtontitle: 'SUBMIT',
      submitDisabled: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.downloadAccountInfo();
  }

  onSubmit = async () => {
    try {
      this.setState({ submitButtontitle: 'LOADING...', submitDisabled: true });
      const { paymentToken } = this.state;
      const result = await Parse.Cloud.run('activateAccount', {
        token: this.token,
        paymentToken,
      });
      if (result.status !== 200) {
        throw new Parse.Error(
          result.status,
          'There was an error activating your account. Please contact Leap @ 1-844-370-LEAP',
        );
      }
      this.setState({ success: true });
    } catch (err) {
      AppToaster.show({ message: err.message, timeout: 10000 });
    } finally {
      this.setState({ submitButtontitle: 'SUBMIT', submitDisabled: false });
    }
  };

  onChangePaymentMethod = async (paymentToken) => {
    try {
      const params = { paymentToken, newAccountToken: this.token };
      const result = await Parse.Cloud.run(
        'capturePaymentForNewAccount',
        params,
      );
      this.setState({ paymentToken: result, showPaymentCapture: false });
    } catch (e) {
      this.setState({ showPaymentCapture: false });
      AppToaster.show({ message: e.message, timeout: 10000 });
    }
  };

  downloadAccountInfo = async () => {
    try {
      const { token } = qs.parse(this.props.location.search);
      const result = await Parse.Cloud.run('fetchNewAccountFromToken', {
        token,
      });
      if (result.product !== 'Leap' && result.product !== 'SalesPro') {
        throw new Parse.Error(500, 'This is not a Leap Agreement');
      }
      this.setState({ ...result });
    } catch (e) {
      history.push('/');
    }
  };

  render() {
    if (this.state.success) {
      return (
        <LeapTemplate>
          <ProSellSuccess email={this.state.email} />
        </LeapTemplate>
      );
    }
    const {
      companyName,
      firstName,
      lastName,
      email,
      costPerUser,
      notes,
      setupFee,
      userCount,
      paymentToken,
      term,
      billingTerms,
      paymentNumber,
    } = this.state;
    const submitEnabled =
      this.state.accepted &&
      this.state.paymentToken &&
      !this.state.submitDisabled;
    const ccButtonTitle = `${paymentToken ? 'Change' : 'Add'} Credit Card`;
    return (
      <LeapTemplate>
        <PaymentMethodModal
          title={ccButtonTitle}
          show={this.state.showPaymentCapture}
          onClose={() => this.setState(() => ({ showPaymentCapture: false }))}
          onChange={this.onChangePaymentMethod}
        />
        Subject to the Leap, LLC End User License Agreement Leap, LLC (‘Leap’)
        hereby licenses to Licensee the Leap software platform (‘Licensed
        Product’) for the terms set forth below:
        <hr />
        <p className="new-account__body-header">SalesPro Subscription</p>
        <ul>
          <li>Digital resource center</li>
          <li>Digital estimator</li>
          <li>Proposal generator</li>
          <li>Digital contract generator</li>
          <li>Digital credit application generator</li>
          <li>Finance calculator</li>
          <li>All available shared templates & resources</li>
          <li>Access to all available integrations</li>
          <li>Support via Email, Phone & Online Chat*</li>
        </ul>
        <span style={{ fontSize: 10, fontColor: 'lightgray' }}>
          *Certain levels of support may incur additional fees
        </span>
        <hr />
        <p className="new-account__body-header">ACCOUNT INFO</p>
        <AccountInfoGroup title="Company Name" value={companyName} />
        <AccountInfoGroup title="Name" value={`${firstName} ${lastName}`} />
        <AccountInfoGroup title="Email" value={email} />
        <hr />
        <p className="new-account__body-header">SUBSCRIPTION DETAILS</p>
        <AccountInfoGroup
          title="Setup Fee"
          value={moneyFormatter.format('USD', setupFee)}
        />
        <AccountInfoGroup title="Licenses" value={`${userCount}`} />
        <AccountInfoGroup
          title="Per License Cost"
          value={
            <span>
              {moneyFormatter.format('USD', costPerUser)}
              /mo
            </span>
          }
        />
        <AccountInfoGroup title="Contract Term" value={`${term} Months`} />
        <AccountInfoGroup
          title="Total Contract Amount"
          value={moneyFormatter.format(
            'USD',
            costPerUser * userCount * term + setupFee,
          )}
        />
        <AccountInfoGroup
          title="Total Monthly Cost"
          value={moneyFormatter.format('USD', costPerUser * userCount)}
        />
        <AccountInfoGroup title="Billing Terms" value={billingTerms} />
        <AccountInfoGroup
          title="Credit Card"
          value={paymentToken ? paymentNumber : 'Not Added'}
        />
        <button
          className="new-account__prosell-submit"
          onClick={() => this.setState({ showPaymentCapture: true })}
        >
          {ccButtonTitle}
        </button>
        <hr />
        {notes && (
          <>
            <p className="new-account__body-header">ADDITIONAL DETAILS</p>
            {notes}
            <hr />
          </>
        )}
        <TermsOfService />
        <div className="new-account__accept-container">
          <input
            type="checkbox"
            value={this.state.accepted}
            onChange={(e) => this.setState({ accepted: e.target.checked })}
          />
          I have read and agree to the Leap TERMS OF SERVICE
        </div>
        <button
          className="new-account__prosell-submit"
          onClick={this.onSubmit}
          disabled={!submitEnabled}
        >
          {this.state.submitButtontitle}
        </button>
      </LeapTemplate>
    );
  }
}

ProSellSignup.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

export default ProSellSignup;
