/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import FilterSectionDropRow from '../SharedComponents/FilterSectionDropRow';
import FilterSectionInputRow from '../SharedComponents/FilterSectionInputRow';
import FilterSectionDateRangeInput from '../SharedComponents/FilterSectionDateRangeInput';

const onDatesChange = (dateChangeEvent, props) => {
  const { startDate, endDate } = props;
  props.onFilterUpdate({
    page: 1,
    startDate:
      dateChangeEvent.startDate && dateChangeEvent.startDate != null
        ? dateChangeEvent.startDate
        : startDate,
    endDate:
      dateChangeEvent.endDate && dateChangeEvent.endDate != null
        ? dateChangeEvent.endDate
        : endDate,
  });
};

const EmailsFilterSection = (props) => {
  const { filter } = props;

  const { startDate, endDate, searchText, userFilter } = filter;

  return (
    <div className="filter-section my-3">
      <div className="filter-section-dropdowns row">
        <div className="col-md-6 filter-section-dropdowns-half">
          <FilterSectionInputRow
            title="Search Text"
            value={searchText}
            onEnterPress={() => props.onSearch({})}
            onChange={(event) => {
              props.onFilterUpdate({ page: 1, searchText: event.target.value });
            }}
          />
          <FilterSectionDateRangeInput
            isOutsideRange={(date) =>
              date.valueOf() >=
              moment()
                .add(1, 'days')
                .endOf('day')
                .valueOf()
            }
            title="Date"
            startDate={startDate}
            endDate={endDate}
            onDatesChange={(dateChangeEvent) =>
              onDatesChange(dateChangeEvent, props)
            }
          />
        </div>
        <div className="col-md-6 filter-section-dropdowns-half">
          <FilterSectionDropRow
            placeholder="Select a User"
            options={props.mappedOptions}
            selected={userFilter}
            onSelectedChanged={(selected) => {
              props.onFilterUpdate({ page: 1, userFilter: [...selected] });
            }}
            title="User"
          />
          {/* <FilterSectionDropRow
            options={props.offices}
            selected={selectedOffices}
            onSelectedChanged={(selected) => {
              props.onFilterUpdate({ page: 1, selectedOffices: [...selected] })
            }}
            title='Office'
          /> */}
        </div>
      </div>
      <button
        type="button"
        onClick={(event) => {
          props.onSearch({});
        }}
        className="btn btn-success m-3"
      >
        {' '}
        Search
      </button>
    </div>
  );
};

const mapStateToProps = ({
  auth: { offices = [] },
  customers: { usersForDropDown = [] },
}) => {
  const mappedOptions = usersForDropDown.map((user) => ({
    label: `${user.nameFirst} ${user.nameLast}`,
    value: user.objectId,
    id: user.objectId,
  }));
  return {
    offices: offices.map((office) => ({
      label: office.get('name'),
      value: office.id,
    })),
    mappedOptions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  // setFilter: params => dispatch(setFilter(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmailsFilterSection);
