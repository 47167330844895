export const defaultState = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_TEMPLATE_SCROLL_REF': {
      const { mainTemplatesRef } = action;
      return {
        ...state,
        mainTemplatesRef,
      };
    }
    case 'ON_TEMPLATE_SCROLL': {
      const { scrollEventData } = action;
      return {
        ...state,
        scrollEventData,
      };
    }
    default:
      return state;
  }
};
