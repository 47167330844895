/* eslint-disable react/prop-types */
// Styled Slider
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Slider } from '@blueprintjs/core';
import { jsx, css } from '@emotion/core';
import { createStyleElement } from '../../utils/utils';

const StyledSlider = ({
  value,
  max,
  min,
  onChange,
  labelStepSize,
  style,
  ...rest
}) => (
  <div
    css={css`
      ${createStyleElement(style)}
    `}
  >
    &gt;
    <Slider
      initialValue={value}
      value={value}
      max={max}
      min={min}
      onChange={onChange}
      labelStepSize={labelStepSize}
      labelRenderer={(e) => `${e}%`}
      {...rest}
    />
  </div>
);

StyledSlider.propTypes = {
  value: PropTypes.number,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  labelStepSize: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

StyledSlider.defaultProps = {
  value: 0,
};

const mapStateToProps = ({ config: { styles = {} } }) => ({
  style: styles.Slider,
});

export default connect(mapStateToProps)(StyledSlider);
