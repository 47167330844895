import { combineReducers } from 'redux';

import accountReducer from './account';
import adminReducer from './admin';
import alertReducer from './alert';
import authReducer from './auth';
import availableContactsReducer from './availableContacts';
import changeLogs from './ChangeLogs';
import configReducer from './config';
import creditApplicationReducer from './CreditApplications';
import customersReducer from './customers';
import deleteConfirmationReducer from './deleteConfirmation';
import emailsReducer from './emails';
import estimatesPage from './estimatesPage';
import financeOptionReducer from './financeOptions';
import leapAnalyticsReducer from './leapAnalytics';
import loadingReducer from './loading';
import mainMenuReducer from './mainMenu';
import packagesReducer from './packages';
import pasteBoardReducer from './pasteBoard';
import paymentCaptureReducer from './paymentCapture';
import permissionsReducer from './permissions';
import plan from './plan';
import priceGuide2Reducer from './priceGuide2';
import priceGuideReducer from './priceGuide';
import priceGuideScroll from './priceGuideScroll';
import resize from './resize';
import resourcesReducer from './resources';
import templateScrollContext from './templateScrollContext';
import templatesEditReducer from './templateEdit';
import templatesReducer from './templates';
import usersReducer from './users';
import onboardingStatusReport from './onboardingStatusReport';

/** @typedef {{
 * plan: import('./plan').PlanState,
 * auth: import('./auth').AuthState,
 * }} State */

const rootReducer = combineReducers({
  account: accountReducer,
  admin: adminReducer,
  alert: alertReducer,
  auth: authReducer,
  availableContacts: availableContactsReducer,
  changeLogs,
  config: configReducer,
  creditApplications: creditApplicationReducer,
  customers: customersReducer,
  deleteConfirmation: deleteConfirmationReducer,
  emails: emailsReducer,
  estimatesPage,
  financeOptions: financeOptionReducer,
  leapAnalytics: leapAnalyticsReducer,
  loading: loadingReducer,
  mainMenu: mainMenuReducer,
  packages: packagesReducer,
  pasteBoard: pasteBoardReducer,
  paymentCapture: paymentCaptureReducer,
  permissions: permissionsReducer,
  plan,
  priceGuide: priceGuideReducer,
  priceGuide2: priceGuide2Reducer,
  priceGuideScroll,
  resize,
  resources: resourcesReducer,
  templates: templatesReducer,
  templateScrollContext,
  templatesEdit: templatesEditReducer,
  users: usersReducer,
  onboardingStatusReport,
});

export default rootReducer;
