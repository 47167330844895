/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import CurrencyInput from 'react-currency-input';
import Select from '../Misc/Select';
import FormGroup, { TextGroup, NumberGroup } from '../FormGroup';

const transactionMaps = {
  purchase: 'Purchase',
  // authorize: 'Authorize',
  // capture: 'Capture',
  // refund: 'Refund',
  general_credit: 'General Credit',
};

class TransactionModal extends React.Component {
  constructor(props) {
    super(props);
    const { paymentMethod = {}, spreedly = {} } = props;
    this.state = {
      transactionType:
        spreedly.characteristics && spreedly.characteristics.length
          ? spreedly.characteristics[0]
          : '',
      amount: 0,
      zip: paymentMethod.zipCode || '',
      description: '',
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { paymentMethod = {} } = newProps;
    const { zipCode = '' } = paymentMethod;
    this.setState({ zip: zipCode });
  }

  onTransactionTypeChanged = ({ value }) => {
    this.setState({ transactionType: value });
  };

  onAmountChanged = (event, maskedvalue, floatvalue) => {
    this.setState({ amount: floatvalue });
  };

  onSubmit = () => {
    const params = {
      ...this.state,
      amount: this.state.amount * 100,
      paymentMethod: this.props.paymentMethod,
    };
    this.props.onClose();
    this.props.onSubmit(params);
  };

  render() {
    const { show, onClose, paymentMethod, spreedly } = this.props;
    const { characteristics = [] } = spreedly;
    const transactionOptions = characteristics
      .filter((c) => !!transactionMaps[c])
      .map((c) => ({
        value: c,
        label: transactionMaps[c],
      }));
    const { cardType } = paymentMethod || {};
    return (
      <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>New Transaction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup title="Account">
            <p style={{ marginTop: 'auto', marginBottom: 'auto' }}>
              {paymentMethod ? paymentMethod.number : ''}
            </p>
          </FormGroup>
          <FormGroup title="Transaction Type">
            <Select
              onChange={this.onTransactionTypeChanged}
              value={this.state.transactionType}
              options={transactionOptions}
              isClearable={false}
              closeMenuOnSelect
            />
          </FormGroup>
          <FormGroup title="Amount">
            <CurrencyInput
              value={this.state.amount}
              onChangeEvent={this.onAmountChanged}
              prefix="$"
            />
          </FormGroup>
          {cardType !== 'checking' && cardType !== 'savings' && (
            <NumberGroup
              title="Billing Zip Code"
              value={this.state.zip}
              onChange={(zip) => this.setState({ zip })}
            />
          )}
          <TextGroup
            title="Description"
            value={this.state.description}
            onChange={(description) => this.setState({ description })}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={this.props.loading.show}
            onClick={this.onSubmit}
            variant="danger"
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

TransactionModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  loading: PropTypes.shape({
    show: PropTypes.bool.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  paymentMethod: PropTypes.shape({
    objectId: PropTypes.string.isRequired,
    zipCode: PropTypes.string,
    number: PropTypes.string,
  }),
  spreedly: PropTypes.shape({
    characteristics: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
};

TransactionModal.defaultProps = {
  show: false,
  paymentMethod: undefined,
  spreedly: {
    characteristics: [],
  },
};

const mapStateToProps = ({ auth, loading = {} }) => ({
  spreedly: auth.config.spreedly,
  loading,
});

export default connect(mapStateToProps)(TransactionModal);
