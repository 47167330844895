/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import GrayDropDown from './GrayDropDown';

const VertDropDownWidget = (props) => {
  return (
    <div className="flex-1 vert-drop-down">
      <div className="filter-section-dropdown-title mr-2">
        <span className="lfw-bold input-title" style={{ color: 'black' }}>
          {' '}
          {props.title}
        </span>
      </div>
      <div className="flex-1 filter-section-dropdown-element-container">
        <GrayDropDown
          selected={props.selected}
          placeholder={props.placeholder}
          options={props.options || []}
          hasSelectAll={props.hasSelectAll}
          disableSearch={props.disableSearch}
          onSelectedChanged={props.onSelectedChanged}
        />
      </div>
      {props.errorMessage ? (
        <span className="input-error-message lfw-bold danger">
          {props.errorMessage}
        </span>
      ) : (
        ''
      )}
    </div>
  );
};

export { VertDropDownWidget };
