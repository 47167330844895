import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';

const PreviewView = ({ objects, tagRow }) => (
  <div>
    <div className="priceguide-additional-details_container">
      <Table striped bordered hover>
        <thead>
          <tr>
            <td className="priceguide-additional-details_header-cell">Title</td>
            <td className="priceguide-additional-details_header-cell">Note</td>
          </tr>
        </thead>
        <tbody>
          {tagRow && (
            <tr className="priceguide-additional-details_row">
              <td className="priceguide-additional-details_cell">
                {tagRow.title || 'Tag'}
              </td>
              <td className="priceguide-additional-details_cell">
                {tagRow.note || ''}
              </td>
            </tr>
          )}
          {objects.map(({ objectId, title, note }) => (
            <tr key={objectId} className="priceguide-additional-details_row">
              <td className="priceguide-additional-details_cell">{title}</td>
              <td className="priceguide-additional-details_cell">{note}</td>
            </tr>
          ))}
          {!objects.length && !tagRow && (
            <tr>
              <td colSpan="2">None Added</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
    <span className="priceguide-additional-details_disclosure">
      Right click icon to copy/paste
    </span>
  </div>
);
PreviewView.propTypes = {
  objects: PropTypes.arrayOf(
    PropTypes.shape({
      objectId: PropTypes.string,
      cellType: PropTypes.string,
      inputType: PropTypes.string,
      placeholder: PropTypes.string,
      notAddedReplacement: PropTypes.string,
      pickerValues: PropTypes.arrayOf(PropTypes.string),
      title: PropTypes.string,
      note: PropTypes.string,
      defaultValue: PropTypes.string,
      shouldCopy: PropTypes.bool,
      required: PropTypes.bool,
    }),
  ),
  tagRow: PropTypes.shape({
    inputType: PropTypes.string,
    pickerValues: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
    note: PropTypes.string,
    defaultValue: PropTypes.string,
    shouldCopy: PropTypes.bool,
    required: PropTypes.bool,
  }),
};

PreviewView.defaultProps = {
  objects: [],
  tagRow: undefined,
};

export default PreviewView;
