/* eslint-disable no-undef */
/* eslint-disable import/no-cycle */
import Parse from 'parse';
import { handleError } from './auth';
import { permissionById } from '../selectors/permissions';
import { showSuccessAlert, hideAlert } from './alert';
import { randomKey } from '../utils/utils';
import { pushToDataLayer } from './tagManager';

export const addPermissions = (items = []) => ({
  type: 'ADD_PERMISSIONS',
  items,
});

export const setPermissions = (items = []) => ({
  type: 'SET_PERMISSIONS',
  items,
});

export const startFetchPermissions = (/* limit = 100 */) => async (
  dispatch,
) => {
  try {
    const items = [];
    const query = new Parse.Query(Parse.Role);

    query.select(['title', 'users', 'roles', 'type', 'isDefault']);
    query.notEqualTo('availableContacts', true);

    await query.each((item) => {
      items.push(item);
    });
    dispatch(
      setPermissions(
        items.sort((itemA, itemB) =>
          `${itemA.get('title')}`.localeCompare(itemB.get('title')),
        ),
      ),
    );
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const deletePermissions = () => ({
  type: 'CLEAR_PERMISSIONS',
});

export const startRefreshPermissions = () => async (dispatch) => {
  await dispatch(deletePermissions());
  await dispatch(startFetchPermissions());
};

export const setEditPermission = (editPermission) => ({
  type: 'SET_PERMISSION_TO_EDIT',
  editPermission,
});

export const updateEditPermission = (params) => async (dispatch, getState) => {
  const { permissions = {} } = getState();
  const { editPermission = {} } = permissions;
  const { activeUserIds, activePermissionIds, permission } = params;
  dispatch(
    setEditPermission({
      ...editPermission,
      permission: permission || editPermission.permission,
      activeUserIds: activeUserIds || editPermission.activeUserIds,
      activePermissionIds:
        activePermissionIds || editPermission.activePermissionIds,
    }),
  );
};

export const startFetchPermission = (objectId) => async (
  dispatch,
  getState,
) => {
  await dispatch(startFetchPermissions());
  const { permissions = [] } = getState();
  const permission = permissionById(permissions.items, objectId);
  const usersQuery = permission.relation('users').query();
  usersQuery.select(['nameFirst', 'nameLast']);
  usersQuery.equalTo('isActive', true);
  /**
   * @type {(import('../Models/UserObject'))[]}
   */
  const users = [];
  await usersQuery.each((user) => users.push(user));
  const activeUserIds = users.map(({ id }) => id);
  /**
   * @type {Parse.Role[]}
   */
  const activePermissions = [];
  const permissionQuery = new Parse.Query(Parse.Role);
  permissionQuery.equalTo('roles', permission);
  permissionQuery.select('objectId');
  await permissionQuery.each((perm) => activePermissions.push(perm));
  const activePermissionIds = activePermissions.map(({ id }) => id);
  dispatch(
    setEditPermission({
      activeUserIds,
      activePermissionIds,
      permission,
    }),
  );
};

export const startSavePermission = ({
  permission,
  title,
  addedUserIds = [],
  activePermissionIds,
  permissionGroups,
}) => async (dispatch, getState) => {
  try {
    const { users: usersState } = getState();
    const { items } = usersState;
    permission.set('type', 'Group');
    permission.set('title', title);
    if (!permission.has('name')) {
      permission.set('name', title);
    }

    if (permission.id) {
      const userObjects = items.map(({ objectId }) => {
        const user = new Parse.User();
        user.id = objectId;
        return user;
      });
      if (userObjects) {
        permission.getUsers().remove(userObjects);
      }
      dispatch(
        pushToDataLayer({
          event: 'permissionGroupEvent',
          eventCategory: 'Permissions',
          eventAction: 'Edit',
        }),
      );
    } else {
      dispatch(
        pushToDataLayer({
          event: 'permissionGroupEvent',
          eventCategory: 'Permissions',
          eventAction: 'Create',
        }),
      );
    }

    const addedUserObjects = addedUserIds.map((userId) => {
      const user = new Parse.User();
      user.id = userId;
      return user;
    });
    if (addedUserObjects.length) {
      permission.getUsers().add(addedUserObjects);
    }
    await permission.save();
    permissionGroups.forEach((role) => {
      role.getRoles().remove(permission);
      if (activePermissionIds.indexOf(role.id) > -1) {
        role.getRoles().add(permission);
      }
    });
    await Parse.Object.saveAll(permissionGroups);
    await dispatch(startFetchPermissions());
    dispatch(
      showSuccessAlert({
        title: 'Success!',
        message: `${title} Permission Group updated successfully.`,
        onConfirm: () => dispatch(hideAlert()),
      }),
    );
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const showDeleteConfirm = (object) => ({
  type: 'SET_DELETE_CONFIRM',
  object,
});

export const startDeletePermission = (objectId) => async (dispatch) => {
  try {
    await dispatch(showDeleteConfirm());
    const permission = new Parse.Role();
    permission.id = objectId;

    dispatch(
      pushToDataLayer({
        event: 'permissionGroupEvent',
        eventCategory: 'Permissions',
        eventAction: 'Delete',
      }),
    );

    await permission.destroy();
    await dispatch(startRefreshPermissions());

    dispatch(
      showSuccessAlert({
        title: 'Success!',
        message: 'Permission Group has been deleted',
        onConfirm: () => dispatch(hideAlert()),
      }),
    );
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const startClonePermission = (objectId) => async (dispatch) => {
  try {
    const permission = new Parse.Role();
    permission.id = objectId;
    await permission.fetch();
    const roleQuery = new Parse.Query(Parse.Role);
    roleQuery.equalTo('roles', permission);
    roleQuery.equalTo('type', 'Class');
    /**
     * @type {Parse.Role[]}
     */
    const roles = [];
    await roleQuery.each((role) => roles.push(role));
    const newPermission = new Parse.Role();
    newPermission.set('name', `${randomKey(10)}_clone`);
    newPermission.set('title', `${permission.get('title')} (Clone)`);
    newPermission.set('type', 'Group');
    dispatch(
      pushToDataLayer({
        event: 'permissionGroupEvent',
        eventCategory: 'Permissions',
        eventAction: 'Copy',
      }),
    );
    await newPermission.save();
    roles.forEach((role) => {
      role.getRoles().add(newPermission);
    });
    await Parse.Object.saveAll(roles);
    await dispatch(startRefreshPermissions());
    dispatch(
      showSuccessAlert({
        title: 'Success!',
        message: 'Permission Group has been cloned!',
        onConfirm: () => dispatch(hideAlert()),
      }),
    );
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const resetPermissions = () => ({
  type: 'RESET_PERMISSIONS',
});
