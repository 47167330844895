export const defaultState = {
  items: [],
};

export default (state = defaultState, { type, items = [], ...rest }) => {
  switch (type) {
    case 'SET_PERMISSIONS':
      return {
        ...state,
        items: [...items],
      };
    case 'ADD_PERMISSIONS':
      return {
        ...state,
        items: [...state.items, ...items],
      };
    case 'SET_PERMISSION_TO_EDIT':
      return {
        ...state,
        editPermission: rest.editPermission,
      };
    case 'CLEAR_PERMISSIONS':
      return {
        ...state,
        items: [],
        editPermission: undefined,
      };
    case 'SET_DELETE_CONFIRM':
      return {
        ...state,
        confirmDelete: rest.object,
      };
    case 'RESET_PERMISSIONS':
      return {
        ...defaultState,
      };
    default:
      return state;
  }
};
