/* eslint-disable consistent-return */
import React from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ContextMenu } from 'leap-menu-item';
import {
  setInitialStateForTemplate,
  setEditCellItem,
} from '../../actions/templateEdit';
import { showWarningAlert, hideAlert } from '../../actions/alert';
import EmptySpace from './Body/CellTypes/EmptySpace';
import TextShort from './Body/CellTypes/TextShort';
import HeaderSection from './Header/HeaderSection';
import DetailImage from './Body/CellTypes/DetailImage';
import Drawing from './Body/CellTypes/Drawing';
import ProVia from './Body/CellTypes/ProVia';
import MeasureSheetItemDetailCell from './Body/CellTypes/MeasureSheetItemDetailCell';
import SignatureContainer from './Signature/SignatureContainer';
import ContractObject from './Models/ContractObject';
import BodyCellItemObject from './Models/BodyCellItem';
import { setPasteboardValue, getPasteboardValue } from '../../utils/Pasteboard';
import CopyPasteMenu from './CopyPasteMenu';
import TouchHoldWrapper from './TouchHoldWrapper';

export const bodyRowForCellItem = (cellItem) => {
  const { cellType = '' } = cellItem;
  switch (cellType) {
    case 'emptySpace':
      return <EmptySpace cellItem={cellItem} />;
    case 'textShort':
    case 'textLong':
    case 'switched':
    case 'textOnly':
    case 'textXLong':
      return <TextShort cellItem={cellItem} />;
    case 'header':
      return <HeaderSection section={cellItem.header} noBorder />;
    case 'detailCell':
      return <DetailImage cellItem={cellItem} />;
    case 'photoPickerCell':
      return (
        <div>
          <TextShort
            cellItem={cellItem}
            lockedTitle={`${cellItem.photoTag || 'Image '} 1.1`}
            lockedValue="(Placeholder Image Note)"
          />
          <TextShort
            cellItem={cellItem}
            lockedTitle={`${cellItem.photoTag || 'Image '} 1.2`}
            lockedValue="(Placeholder Image Note)"
          />
        </div>
      );
    case 'securePaymentCaptureAll':
    case 'creditCardCell':
      return (
        <TextShort cellItem={cellItem} lockedValue="XXXX-XXXX-XXXX-1234" />
      );
    case 'bankAccountCell':
      return <TextShort cellItem={cellItem} lockedValue="XXXXX1234" />;
    case 'drawing':
      return <Drawing cellItem={cellItem} />;
    case 'measureSheetItemDetail':
      return <MeasureSheetItemDetailCell cellItem={cellItem} />;
    case 'signatures':
      return (
        <SignatureContainer
          data={cellItem.signatures}
          parentPath={cellItem.getPath()}
        />
      );
    case 'provia':
      return <ProVia cellItem={cellItem} />;
    default:
      return <div>{cellType}</div>;
  }
};

const onDelete = ({ template, cellItem, setTemplateState }) => {
  template.deleteObject(cellItem);
  const contractObject = new ContractObject(template.toJSON());
  setTemplateState(contractObject);
};

const onCopy = ({ cellItem }) => {
  setPasteboardValue(cellItem);
};

const onCut = (props) => {
  onCopy(props);
  onDelete(props);
};

const onPasteObject = (
  object,
  index,
  { template, setTemplateState, showAlert, closeAlert, cellItem },
) => {
  if (object) {
    const path = cellItem.getPath();
    const allIds = template.getAllCellIdentifiers([]);
    if (object.getCellIds) {
      const ids = object.getCellIds();
      const conflicts = ids.filter((id) => allIds.indexOf(id) > -1);
      if (conflicts.length) {
        return showAlert({
          title: 'Identifier Conflict',
          message: `Cannot paste due to cells already existing with identifiers: ${conflicts.join(
            ', ',
          )}`,
          onConfirm: () => {
            closeAlert();
          },
        });
      }
    }
    template.insertObjectAtPath(path, object.clone(), index);
    const contractObject = new ContractObject(template.toJSON());
    setTemplateState(contractObject);
  }
};

const onInsertObject = (props) => {
  const object = new BodyCellItemObject();
  onPasteObject(object, 1, props);
};

const onContextMenu = (e, props) => {
  e.preventDefault();
  props.setEditItem(props.cellItem);
  const pasteBoardValue = getPasteboardValue() || {};
  const menu = (
    <CopyPasteMenu
      onInsert={() => onInsertObject(props)}
      onDelete={() => onDelete(props)}
      onCut={() => onCut(props)}
      onCopy={() => onCopy(props)}
      onPasteAbove={() => onPasteObject(getPasteboardValue(), 0, props)}
      pasteAboveDisabled={
        pasteBoardValue.containerKey !== props.cellItem.containerKey
      }
      onPasteBelow={() => onPasteObject(getPasteboardValue(), 1, props)}
      pasteBelowDisabled={
        pasteBoardValue.containerKey !== props.cellItem.containerKey
      }
    />
  );
  ContextMenu.show(menu, { left: e.clientX, top: e.clientY });
};

const BodyRow = ({ children, ...rest }) =>
  React.Children.map(children, (child) => (
    <TouchHoldWrapper onContextMenu={(e) => onContextMenu(e, rest)}>
      {React.cloneElement(child)}
    </TouchHoldWrapper>
  ));

BodyRow.propTypes = {};

const mapStateToProps = ({ templatesEdit }) => ({
  template: templatesEdit[templatesEdit.currentWorkingId],
});

const mapDispatchToProps = (dispatch) => ({
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
  setEditItem: (cellItem) => dispatch(setEditCellItem(cellItem)),
  showAlert: (props) => dispatch(showWarningAlert(props)),
  closeAlert: () => dispatch(hideAlert()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BodyRow);
