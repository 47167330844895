/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import Parse from 'parse';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { startChangePassword, removeQueryParameter } from '../actions/auth';
import { TextGroup } from './FormGroup';

class ChangePasswordModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      isLoading: false,
      password: '',
      confirmPassword: '',
    };
  }

  componentDidMount() {
    const loggedIn = !!Parse.User.current();
    if (loggedIn) {
      this.startCheckUser();
    }
  }

  onPasswordChanged = (password) => {
    this.setState({
      password,
      showError: password !== this.state.confirmPassword,
    });
  };

  onConfirmPasswordChanged = (confirmPassword) => {
    this.setState({
      confirmPassword,
      showError: confirmPassword !== this.state.password,
    });
  };

  onSave = async () => {
    try {
      const { password, confirmPassword } = this.state;
      const { username: un, password: pw } = this.props;
      if (password && password === confirmPassword) {
        const decodedUsername = decodeURI(un);
        const decodedPassword = decodeURI(pw);
        this.setState({ isLoading: true });
        await this.props.startChangePassword(
          decodedUsername,
          decodedPassword,
          password,
        );
        this.props.setUsernameAndPassword(decodedUsername, password);
        this.setState(() => ({ show: false }));
      } else {
        this.setState(() => ({ showError: true }));
      }
    } catch (e) {
      this.setState({ show: false });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  onEnterPress = ({ key }) => {
    if (key === 'Enter') {
      this.onSave();
    }
  };

  startCheckUser = async () => {
    try {
      const { username, password } = this.props;
      if (username && password) {
        const {
          needsResetPassword,
        } = await Parse.Cloud.run('userNeedsUpdatePassword', { username });
        this.setState({ show: needsResetPassword });
      }
    } catch (e) {
      this.setState({ show: false });
    }
  };

  render() {
    const {
      show,
      showError,
      password,
      confirmPassword,
      isLoading,
    } = this.state;
    const disableSubmit =
      this.state.showError || isLoading || (!password && !confirmPassword);
    return (
      <Modal
        show={show}
        onHide={() => this.setState(() => ({ show: false }))}
        backdrop="static"
      >
        <Modal.Header closeButton={false}>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextGroup
            title="New Password"
            value={password}
            onChange={this.onPasswordChanged}
            type="password"
            errorMessage={showError ? '' : undefined}
          />
          <TextGroup
            title="Confirm Password"
            value={confirmPassword}
            onChange={this.onConfirmPasswordChanged}
            type="password"
            errorMessage={showError ? 'Passwords do not match' : undefined}
            onKeyPress={this.onEnterPress}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={this.onSave}
            disabled={disableSubmit}
          >
            {isLoading ? 'Loading...' : 'Submit'}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

ChangePasswordModal.propTypes = {
  startChangePassword: PropTypes.func.isRequired,
  setUsernameAndPassword: PropTypes.func.isRequired,
  removeQueryParameter: PropTypes.func.isRequired,
  username: PropTypes.string,
  password: PropTypes.string,
};

ChangePasswordModal.defaultProps = {
  username: '',
  password: '',
};

const mapStateToProps = ({ auth: { query = {} } }) => ({
  query,
});

const mapDispatchToProps = (dispatch) => ({
  startChangePassword: (username, password, newPassword) =>
    dispatch(startChangePassword(username, password, newPassword)),
  removeQueryParameter: (key) => dispatch(removeQueryParameter(key)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChangePasswordModal);
