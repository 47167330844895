import React from 'react';
import Select from 'react-select';

// Using forwardRef to wrap the component to hoist the ref from the inner DOM
// element to the parent component to be able to access it's ref.
const LeapSelect = React.forwardRef(function LeapSelect(
  // Using "...props" as rest parameters to handle native props of the 'LeapSelect'
  // element
  { children, color = 'blue', className = '', ...props },
  ref,
) {
  return (
    <Select
      classNamePrefix="lpstrp-select"
      className="lpstrp-select"
      {...{ ...props }}
    />
  );
});

export default LeapSelect;
