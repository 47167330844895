/* eslint-disable react/no-deprecated */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import { TextGroup, PasswordGroup, SwitchGroup } from '../FormGroup';
import { startUpdateConfig } from '../../actions/auth';
import { decryptString } from '../../utils/utils';
import Panel from '../Panel';
import TitleButton from '../TitleButton';
import Copy from '../AppSettings/Copy';
import { showDeleteConfirmation } from '../../actions/deleteConfirmation';
import { pushToDataLayer } from '../../actions/tagManager';

export class ProVia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enabled: props.provia.enabled,
      username: props.provia.username,
      password: '',
      isPortalLogin: props.provia.isPortalLogin,
    };
  }

  componentDidMount() {
    const {
      setCrumbs,
      setButtons,
      location: { pathname },
      provia,
    } = this.props;

    decryptString(provia.password).then(({ password }) => {
      this.setState({ password });
    });

    const crumb = { title: 'ProVia', link: pathname };
    setCrumbs([crumb]);
    setButtons(
      <TitleButtons
        onClearClick={this.askForClearPermission}
        onCopyClicked={this.onCopyClicked}
        onSaveClicked={this.onSaveClicked}
      />,
    );
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    decryptString(newProps.provia.password).then(({ password }) => {
      this.setState({
        username: newProps.provia.username,
        password,
        isPortalLogin: newProps.provia.isPortalLogin,
      });
    });
  }

  componentWillUnmount() {
    const { setCrumbs, setButtons } = this.props;
    setCrumbs([]);
    setButtons();
  }

  onEnabledChanged = (checked) => {
    this.setState({ enabled: checked });
    if (!checked) {
      const unset = Object.keys(this.state).reduce(
        (unset2, key) => ({ ...unset2, [key]: '' }),
        {},
      );
      this.setState({ ...unset });
      this.props.pushToDataLayer({
        event: 'integrationEvent',
        eventCategory: 'Integration',
        eventAction: 'Disable',
        eventLabel: 'Provia',
      });
    } else {
      this.props.pushToDataLayer({
        event: 'integrationEvent',
        eventCategory: 'Integration',
        eventAction: 'Enable',
        eventLabel: 'Provia',
      });
    }
  };

  askForClearPermission = () => {
    this.props.showDeleteConfirmation({
      message: `Are you sure you want to clear this integration ?`,
      title: 'Clear Integration ?',
      onConfirm: () => {
        this.onClearClick();
      },
    });
  };

  onClearClick = () => {
    this.onEnabledChanged(false);
  };

  onUsernameChanged = (value) => {
    this.setState({ username: value });
  };

  onPasswordChanged = (value) => {
    this.setState({ password: value });
  };

  onIsPortalLoginChanged = (checked) => {
    this.setState({ isPortalLogin: checked });
  };

  onCopyClicked = () => {
    this.setState({ showCopy: true });
  };

  onSaveClicked = () => {
    this.props.startUpdateConfig({ provia: this.state });
  };

  render() {
    return (
      <>
        <Copy
          title="Copy ProVia Settings"
          show={this.state.showCopy}
          warning="Warning! This will overwrite your current ProVia settings"
          configKeys={['provia']}
          onClose={() => this.setState({ showCopy: false })}
        />
        <div className="default-page-padding">
          <Panel title="ProVia">
            <div>
              <TextGroup
                title={`entryLink ${
                  this.state.isPortalLogin ? 'Portal ID' : 'Username'
                }`}
                value={this.state.username}
                placeholder={`entryLink ${
                  this.state.isPortalLogin ? 'Portal ID' : 'Username'
                }`}
                onChange={this.onUsernameChanged}
              />
              <PasswordGroup
                title="entryLink Password"
                value={this.state.password}
                placeholder="entryLink Password"
                onChange={this.onPasswordChanged}
              />
              <SwitchGroup
                title="Portal Login"
                checked={this.state.isPortalLogin}
                onChange={this.onIsPortalLoginChanged}
              />
            </div>
          </Panel>
        </div>
      </>
    );
  }
}

ProVia.propTypes = {
  provia: PropTypes.shape({
    username: PropTypes.string,
    password: PropTypes.string,
    isPortalLogin: PropTypes.bool,
  }),
  startUpdateConfig: PropTypes.func.isRequired,
  ...layoutContextPropTypes,
};

ProVia.defaultProps = {
  provia: {
    username: '',
    password: '',
    isPortalLogin: false,
  },
};

const TitleButtons = ({ onCopyClicked, onSaveClicked, onClearClick }) => (
  <>
    <TitleButton
      variant="warning"
      onClick={() => onClearClick()}
      title="Clear"
    />
    <TitleButton
      variant="primary"
      onClick={() => onCopyClicked({ source: 'copy' })}
      title="Copy"
    />
    <TitleButton
      variant="success"
      onClick={() => onSaveClicked({ source: 'save' })}
      title="Save"
    />
  </>
);

TitleButtons.propTypes = {
  onCopyClicked: PropTypes.func.isRequired,
  onSaveClicked: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth: { config } }) => ({
  provia: config.provia,
});

const mapDispatchToProps = (dispatch) => ({
  startUpdateConfig: (updates) => dispatch(startUpdateConfig(updates)),
  pushToDataLayer: (variablesForLayer) =>
    dispatch(pushToDataLayer(variablesForLayer)),
  showDeleteConfirmation: (params) => dispatch(showDeleteConfirmation(params)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(ProVia),
);
