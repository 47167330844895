export const defaultState = {
  activeUserIds: [],
};

export default (state = defaultState, { type, role, activeUserIds }) => {
  switch (type) {
    case 'SET_AVAILABLE_CONTACTS_ROLE':
      return {
        ...state,
        role,
        activeUserIds,
      };
    case 'RESET_AVAILABLE_CONTACTS':
      return {
        ...defaultState,
      };
    default:
      return state;
  }
};
