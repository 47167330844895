/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Parse from 'parse';
import { Table } from 'react-bootstrap';
import Paginator from '../Misc/Paginator';
import { showLoader, hideLoader } from '../../actions/loading';
import { history } from '../../router';
import AppToaster from '../../utils/AppToaster';
import { showSuccessAlert, hideAlert } from '../../actions/alert';

import {
  setPage,
  setLimit,
  setSelectedCategories,
  setSelectedSubCategories,
  startDownloadSubCategories,
  startCountItems,
  startFetchItems,
  setIncludedOffices,
  startFetchOptionsForItems,
  setItem,
  startFetchOptionsForItem,
} from '../../actions/priceGuide2';
import CategoriesDropDown from './CategoriesDropDown';
import SubCategoriesDropDown from './SubCategoriesDropDown';
import FormGroup from '../FormGroup';
import OfficesDropDown from '../IncludedOfficesDropDown';
import Panel from '../Panel';
import TitleButton from '../TitleButton';
import { itemLabel } from '../Templates/MeasureSheetItemSlideOutPanel';

class IncrementUpChargeSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIds: [],
    };
  }

  UNSAFE_componentWillMount() {
    const {
      includedOffices,
      page,
      limit,
      selectedCategories,
      selectedSubCategories,
    } = this.props;
    this.props.startCountItems(
      includedOffices,
      selectedCategories,
      selectedSubCategories,
    );
    this.props.startFetchItems(
      includedOffices,
      limit,
      page,
      selectedCategories,
      selectedSubCategories,
    );
  }

  onLimitChanged = (limit) => {
    this.props.setLimit(limit);
    const {
      includedOffices,
      page,
      selectedCategories,
      selectedSubCategories,
    } = this.props;
    this.props.startFetchItems(
      includedOffices,
      limit,
      page,
      selectedCategories,
      selectedSubCategories,
    );
  };

  onPageChanged = (page) => {
    this.props.setPage(page);
    const {
      includedOffices,
      limit,
      selectedCategories,
      selectedSubCategories,
    } = this.props;
    this.props.startFetchItems(
      includedOffices,
      limit,
      page,
      selectedCategories,
      selectedSubCategories,
    );
  };

  onSubCategoryChanged = (values = []) => {
    const selectedSubCategories = values;
    this.props.setSelectedSubCategories(selectedSubCategories);
    const { includedOffices, page, limit, selectedCategories } = this.props;
    this.props.startCountItems(
      includedOffices,
      selectedCategories,
      selectedSubCategories,
    );
    this.props.startFetchItems(
      includedOffices,
      limit,
      page,
      selectedCategories,
      selectedSubCategories,
    );
  };

  onCategoryChanged = (values = []) => {
    const selectedCategories = values;
    this.props.setCategories(selectedCategories);
    this.props.startDownloadSubCategories(selectedCategories);
    const { includedOffices, page, limit, selectedSubCategories } = this.props;
    this.props.startCountItems(
      includedOffices,
      selectedCategories,
      selectedSubCategories,
    );
    this.props.startFetchItems(
      includedOffices,
      limit,
      page,
      selectedCategories,
      selectedSubCategories,
    );
  };

  onIncludedOfficesChanged = (values) => {
    this.props.setIncludedOffices(values);
    const {
      page,
      limit,
      selectedCategories,
      selectedSubCategories,
    } = this.props;
    this.props.startCountItems(
      values,
      selectedCategories,
      selectedSubCategories,
    );
    this.props.startFetchItems(
      values,
      limit,
      page,
      selectedCategories,
      selectedSubCategories,
    );
  };

  onSelectAll = (e) => {
    const { checked } = e.target;
    let selectedIds = [];
    if (checked) {
      selectedIds = this.props.items.map(({ id }) => id);
    }
    this.setState({ selectedIds });
  };

  onSelectItem = (objectId) => {
    const isSelected = this.state.selectedIds.indexOf(objectId) === -1;
    const selectedIds = this.state.selectedIds.filter((id) => id !== objectId);
    if (isSelected) {
      selectedIds.push(objectId);
    }
    this.setState({ selectedIds });
  };

  onNextClicked = async () => {
    if (this.state.selectedIds.length === 0) {
      AppToaster.show({
        message: 'You must select at least 1 destination item',
        timeout: 3000,
      });
      return;
    }
    this.props.showLoader('updated upcharges');
    const upCharges = {};
    const selectedItems = [];
    this.state.selectedIds.forEach((id) => {
      const item = this.props.itemsObject[id];
      if (item) {
        selectedItems.push(item);
      }
    });
    this.setState({ selectedItems });

    const upChargeQueries = selectedItems.map(async (item) => {
      await item.fetch();
      const accessories = item.get('accessories') || [];
      accessories.forEach((upCharge) => {
        upCharges[upCharge.id] = upCharge;
      });
    });

    await Promise.all(upChargeQueries);

    const idSet = await Object.keys(upCharges).map(async (upid, index) => {
      const upCharge = upCharges[upid];
      const identifier = index + 1;
      await upCharge.fetch();

      const placeholders = (upCharge.get('placeholders') || []).filter(
        (placeholder) => {
          const isIncluded =
            placeholder.placeholder === 'included' &&
            placeholder.replacement === 'Included';
          return !isIncluded;
        },
      );

      placeholders.push({ placeholder: 'included', replacement: 'Included' });
      upCharge.set('placeholders', placeholders);
      upCharge.set('identifier', `${identifier}`);
      return upCharge;
    });
    const list = await Promise.all(idSet);

    await Parse.Object.saveAll(list);
    this.props.hideLoader();
    this.props.showSuccessAlert({
      title: 'UpCharge Idenifiers',
      message: 'UpCharge Idenifiers Generated !',
      onConfirm: () => {
        this.props.hideAlert();
        history.push('/price_guide');
      },
    });
  };

  render() {
    const {
      selectedCategories,
      selectedSubCategories,
      page,
      limit,
      count,
      includedOffices,
    } = this.props;

    const { items } = this.props;
    return (
      <div className="default-page-padding">
        <Panel title="Select Measure Sheet Item(s)">
          <div style={{ position: 'relative', zIndex: 11 }}>
            <div className="custom-panel__header-title">
              <div />
              <TitleButton
                variant="success"
                onClick={this.onNextClicked}
                title="Auto Generate UpCharge IDs"
              />
            </div>
            {this.props.maxOfficeCount !== 1 && (
              <FormGroup title="Included Offices">
                <OfficesDropDown
                  onChange={this.onIncludedOfficesChanged}
                  selected={includedOffices}
                />
              </FormGroup>
            )}
            <FormGroup title="Categories">
              <CategoriesDropDown
                isMulti
                closeMenuOnSelect={false}
                onChange={(value) => this.onCategoryChanged(value)}
                value={selectedCategories}
              />
            </FormGroup>
            <FormGroup title="Sub Categories">
              <SubCategoriesDropDown
                isMulti
                closeMenuOnSelect={false}
                onChange={(value) => this.onSubCategoryChanged(value)}
                value={selectedSubCategories}
              />
            </FormGroup>
          </div>
          <Table striped bordered hover className="package-options-table">
            <thead>
              <tr>
                <th style={{ width: '20px' }}>
                  <input
                    type="checkbox"
                    value={false}
                    onChange={this.onSelectAll}
                  />
                </th>
                <th style={{ width: '50px' }}>Image</th>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item) => (
                <tr
                  key={item.id}
                  onClick={() => this.onSelectItem(item.id)}
                  style={{ cursor: 'pointer' }}
                >
                  <td>
                    <input
                      type="checkbox"
                      checked={this.state.selectedIds.indexOf(item.id) > -1}
                      onChange={() => this.onSelectItem(item.id)}
                    />
                  </td>
                  <td>
                    <img
                      alt={item.get('itemName"')}
                      src={
                        item.has('image')
                          ? item.get('image').url()
                          : '/images/no_image.png'
                      }
                      style={{ width: '100%' }}
                    />
                  </td>
                  <td>
                    {itemLabel(item.get('subCategory'), item.get('itemName'))}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Panel>
        <Paginator
          page={page}
          limit={limit}
          totalCount={count}
          onLimitChanged={({ value }) => this.onLimitChanged(value)}
          pageRange={5}
          onPageClicked={(value) => this.onPageChanged(value)}
        />
      </div>
    );
  }
}

IncrementUpChargeSelection.propTypes = {
  selectedSubCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedSubCategories: PropTypes.func.isRequired,
  startDownloadSubCategories: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  maxOfficeCount: PropTypes.number.isRequired,
  showLoader: PropTypes.func.isRequired,
  hideLoader: PropTypes.func.isRequired,
  showSuccessAlert: PropTypes.func.isRequired,
  hideAlert: PropTypes.func.isRequired,
  itemsObject: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  count: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  setCategories: PropTypes.func.isRequired,
  setLimit: PropTypes.func.isRequired,
  startCountItems: PropTypes.func.isRequired,
  startFetchItems: PropTypes.func.isRequired,
  includedOffices: PropTypes.arrayOf(PropTypes.string),
  selectedCategories: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.arrayOf(PropTypes.instanceOf(Parse.Object)),
  setIncludedOffices: PropTypes.func.isRequired,
};

IncrementUpChargeSelection.defaultProps = {
  includedOffices: [],
  selectedCategories: [],
  items: [],
};

const mapStateToProps = ({ plan = {}, priceGuide2 }) => {
  const {
    page,
    limit,
    count,
    selectedCategories,
    selectedSubCategories,
    items,
    itemIds,
    includedOffices,
  } = priceGuide2;
  const measureSheetItems = itemIds.map((id) => items[id]);
  return {
    page,
    limit,
    count,
    selectedCategories,
    selectedSubCategories,
    items: measureSheetItems,
    includedOffices,
    itemsObject: items,
    maxOfficeCount: plan.maxOfficeCount,
  };
};

const mapDispatchToProps = (dispatch) => ({
  hideAlert: () => dispatch(hideAlert()),
  showSuccessAlert: (params) => dispatch(showSuccessAlert(params)),
  showLoader: (params) => dispatch(showLoader(params)),
  hideLoader: () => dispatch(hideLoader()),
  setPage: (page) => dispatch(setPage(page)),
  setLimit: (limit) => dispatch(setLimit(limit)),
  setSelectedSubCategories: (subCategories) =>
    dispatch(setSelectedSubCategories(subCategories)),
  setCategories: (categories) => dispatch(setSelectedCategories(categories)),
  startDownloadSubCategories: (categories) =>
    dispatch(startDownloadSubCategories(categories)),
  startCountItems: (includedOffices, categories, subCategories) =>
    dispatch(startCountItems(includedOffices, categories, subCategories)),
  startFetchItems: (includedOffices, limit, page, categories, subCategories) =>
    dispatch(
      startFetchItems(includedOffices, limit, page, categories, subCategories),
    ),
  setIncludedOffices: (offices) => dispatch(setIncludedOffices(offices)),
  startFetchOptionsForItems: (items) =>
    dispatch(startFetchOptionsForItems(items)),
  updateItem: (item) => dispatch(setItem(item)),
  startFetchOptions: (item) => dispatch(startFetchOptionsForItem(item)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IncrementUpChargeSelection);
