/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
// import PropTypes from 'prop-types';
import Parse from 'parse';
import { connect } from 'react-redux';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import { TextGroup } from '../FormGroup';
import TitleButton from '../TitleButton';
import { handleError } from '../../actions/auth';
import { showLoader, hideLoader } from '../../actions/loading';

import { showSuccessAlert, hideAlert } from '../../actions/alert';
import Panel from '../Panel';

export class CheckBlackList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      message: 'Email is NOT Blacklisted',
      showRemoveFromBlackListOptions: false,
      showNotBlacklistedMessage: false,
    };
  }

  componentDidMount() {
    const {
      setCrumbs,
      location: { pathname },
    } = this.props;

    const crumb = { title: 'Check Blacklist', link: pathname };
    setCrumbs([crumb]);
  }

  componentWillUnmount() {
    const { setCrumbs } = this.props;
    setCrumbs([]);
  }

  onSearchBlacklist = async () => {
    this.setState(
      {
        showRemoveFromBlackListOptions: false,
        showNotBlacklistedMessage: false,
      },
      async () => {
        if (this.state.email) {
          const searchResult = await Parse.Cloud.run(
            'checkIfEmailIsBlackListed',
            { email: this.state.email },
          );
          const message = searchResult.message || 'Email is blacklisted';

          if (searchResult && searchResult.isBlackListed) {
            this.setState({
              message,
              showRemoveFromBlackListOptions: true,
            });
          } else {
            const message2 = searchResult.message || 'Email is NOT Blacklisted';
            this.setState({
              message: message2,
              showNotBlacklistedMessage: true,
            });
          }
        }
      },
    );
  };

  onRemoveFromBlackList = async () => {
    if (this.state.email) {
      this.props.showLoader('removing from blacklist');
      await Parse.Cloud.run('removeEmailFromSuppressions', {
        email: this.state.email,
      });
      this.props.hideLoader();
      this.props.showSuccessAlert({
        title: 'Success',
        message: 'Email Removed From Blacklist',
        onConfirm: () => {
          this.props.hideAlert();
          this.setState({
            showRemoveFromBlackListOptions: false,
          });
        },
      });
    }
  };

  render() {
    return (
      <div className="default-page-padding">
        <Panel title="Check Blacklist">
          {this.state.showNotBlacklistedMessage && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p style={{ color: 'green' }}>{this.state.message}</p>
              <i style={{ color: 'green' }} className="fas fa-check fa-5x" />
            </div>
          )}
          {this.state.showRemoveFromBlackListOptions && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p style={{ color: 'red' }}>{this.state.message}</p>
              <i
                style={{ color: 'red' }}
                className="far fa-times-circle fa-5x"
              />
              <br />
              <TitleButton
                title="Remove From Blacklist"
                variant="primary"
                onClick={this.onRemoveFromBlackList}
              />
            </div>
          )}
          <TextGroup
            title="Email"
            errorMessage={this.state.companyNameError}
            value={this.state.email}
            placeholder="Email"
            onChange={(email) => {
              this.setState({ email });
            }}
          />
          <TitleButton
            title="Search BlackList"
            variant="primary"
            onClick={this.onSearchBlacklist}
          />
        </Panel>
      </div>
    );
  }
}

CheckBlackList.propTypes = {
  ...layoutContextPropTypes,
};

CheckBlackList.defaultProps = {};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  showSuccessAlert: (params) => dispatch(showSuccessAlert(params)),
  handleError: (message) => dispatch(handleError(message)),
  hideAlert: () => dispatch(hideAlert()),
  showLoader: (message) => dispatch(showLoader(message)),
  hideLoader: () => dispatch(hideLoader()),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(CheckBlackList),
);
