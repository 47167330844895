/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import { withLayoutContext, layoutContextPropTypes } from '../../Layout';
import { startUpdateConfig } from '../../../actions/auth';
import {
  randomKey,
  formulaEvaluates,
  evaluateFormula,
} from '../../../utils/utils';
import Panel from '../../Panel';
import TitleButton from '../../TitleButton';
import Copy from '../Copy';
import CommissionTierTestSuite from './CommissionTierTestSuite';
import FormGroup from '../../FormGroup';
import FormulaTipButton from '../PriceFormulas/FormulaTestSuite/FormulaTipButton';
import { addDefaultColors } from '../PriceFormulas/edit';

const DEFAULT_PLACEHOLDERS = [
  {
    symbol: '[FP]',
    name: 'Financing Fee',
    color: 'red',
    isDefault: true,
    id: 'FP',
  },
  {
    symbol: '[UA]',
    name: 'User Amount',
    color: 'green',
    isDefault: true,
    id: 'UA',
  },
  {
    symbol: '[DP]',
    name: 'Down Payment',
    color: 'blue',
    isDefault: true,
    id: 'DP',
  },
  {
    symbol: '[B]',
    name: 'Book Price',
    color: 'orange',
    isDefault: true,
    id: 'B',
  },
  {
    symbol: '[CP]',
    name: 'Custom Product',
    color: 'lightblue',
    isDefault: true,
    id: 'CP',
  },
  {
    symbol: '[T]',
    name: 'Contract Amount',
    color: 'purple',
    isDefault: true,
    id: 'T',
  },
];

class CommissionFormulas extends React.Component {
  constructor(props) {
    super(props);
    const formulaIdCategories = props.categories.filter(
      ({ formulaId }) => formulaId,
    );
    const placeholders = [
      ...DEFAULT_PLACEHOLDERS,
      ...formulaIdCategories.map(({ name, formulaId }) => ({
        symbol: `[${formulaId}]`,
        name,
        isDefault: false,
        id: formulaId,
      })),
    ];
    this.state = {
      focused: {},
      placeholders: addDefaultColors(placeholders),
      highestTier: {
        greaterThanResult: 0,
        commissionResult: 0,
      },
      formulas: props.formulas.map((formula) => ({
        ...formula,
        objectId: formula.objectId || randomKey(10),
        selected: 0,
        commissionInputRef: React.createRef(),
        greaterThanInputRef: React.createRef(),
      })),
      invalidGreaterThanIndexes: [],
      invalidCommissionIndexes: [],
    };
  }

  componentDidMount() {
    const {
      setCrumbs,
      setButtons,
      location: { pathname },
    } = this.props;

    const crumb = { title: 'Commission Formulas', link: pathname };
    setCrumbs([crumb]);
    setButtons(
      <TitleButtons
        onCopyClicked={this.onCopyClicked}
        onSaveClicked={this.onSaveClicked}
      />,
    );
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { categories, formulas } = newProps;
    const formulaIdCategories = categories.filter(({ formulaId }) => formulaId);
    const placeholders = [
      ...DEFAULT_PLACEHOLDERS,
      ...formulaIdCategories.map(({ name, formulaId }) => ({
        symbol: `[${formulaId}]`,
        name,
        isDefault: false,
        id: formulaId,
      })),
    ];
    this.setState({
      placeholders: addDefaultColors(placeholders),
      formulas: formulas.map((formula) => ({
        ...formula,
        objectId: formula.objectId || randomKey(10),
      })),
    });
  }

  componentWillUnmount() {
    const { setCrumbs, setButtons } = this.props;

    setCrumbs([]);
    setButtons();
  }

  onGreaterThanChange = ({ value, index }) => {
    const { formulas } = this.state;
    formulas[index].greaterThanFormula = value;
    const invalidIndexes = this.state.invalidGreaterThanIndexes.filter(
      (i) => i !== index,
    );
    if (!formulaEvaluates(value, this.props.formulaIds)) {
      invalidIndexes.push(index);
    }
    this.setState(
      {
        formulas,
        invalidGreaterThanIndexes: invalidIndexes,
      },
      () => this.calculateHighestTier(),
    );
  };

  validPriority = ({ value, index }) => {
    const { formulas } = this.state;

    const listHasPriorities = formulas.filter(
      (formula, i) =>
        i !== index && (formula.priority || formula.priority === 0),
    );

    const emptyValue = !value && value !== 0;

    if (listHasPriorities.length && emptyValue) {
      return false;
    }

    const duplicatePriorities = formulas.filter(
      (formula, i) => i !== index && !emptyValue && formula.priority === value,
    );

    if (duplicatePriorities.length) {
      return false;
    }

    return true;
  };

  updatedPriority = ({ value, index }) => {
    const { formulas } = this.state;
    formulas[index].priority = parseInt(value, 10);
    this.setState(
      {
        formulas,
      },
      () => this.calculateHighestTier(),
    );
  };

  onCommissionChange = ({ value, index }) => {
    const { formulas } = this.state;
    formulas[index].commissionFormula = value;
    const invalidIndexes = this.state.invalidCommissionIndexes.filter(
      (i) => i !== index,
    );
    if (!formulaEvaluates(value, this.props.formulaIds)) {
      invalidIndexes.push(index);
    }
    this.setState(
      {
        formulas,
        invalidCommissionIndexes: invalidIndexes,
      },
      () => this.calculateHighestTier(),
    );
  };

  onFormulaChange = ({ key, value, index }) => {
    const { formulas } = this.state;
    formulas[index][key] = value;
    this.setState({ formulas });
  };

  onDelete = (index) => {
    const { formulas } = this.state;
    formulas.splice(index, 1);
    this.setState({ formulas });
  };

  onCopyClicked = () => {
    this.setState({ showCopy: true });
  };

  onAddClicked = () => {
    const { formulas } = this.state;
    const updatedFormulas = [
      {
        greaterThanFormula: '',
        commissionFormula: '',
        selected: 0,
        commissionInputRef: React.createRef(),
        greaterThanInputRef: React.createRef(),
        objectId: randomKey(10),
      },
      ...formulas,
    ];
    this.setState({ formulas: updatedFormulas });
  };

  onSaveClicked = () => {
    let evaluates = true;
    const { formulas } = this.state;
    formulas.forEach((formula) => {
      if (
        !formulaEvaluates(formula.greaterThanFormula, this.props.formulaIds) ||
        !formulaEvaluates(formula.commissionFormula, this.props.formulaIds)
      ) {
        evaluates = false;
      }
    });
    if (evaluates) {
      const listHasPriorities = formulas.filter(
        (formula, i) => formula.priority || formula.priority === 0,
      );

      this.props.startUpdateConfig({
        commissionFormulas: this.state.formulas
          .map(({ priority, ...clean }) =>
            listHasPriorities.length === 0 ? clean : { priority, ...clean },
          )
          .map(
            ({
              greaterThanFormula,
              commissionFormula,
              order,
              objectId,
              priority,
            }) => ({
              greaterThanFormula,
              commissionFormula,
              order,
              objectId,
              priority,
            }),
          ),
      });
    }
  };

  onPlaceHolderUpdate = (placeholders) => {
    this.setState({ placeholders }, () => this.calculateHighestTier());
  };

  onHighestTier = (tierData) => {
    const { formulas } = this.state;
    const { greaterThanResult, commissionResult } = tierData;
    const updatedFormulas = formulas.map((formula, i) => {
      if (i === tierData.index) {
        return {
          ...formula,
          selected: 4,
        };
      }

      return {
        ...formula,
        selected: 0,
      };
    });

    this.setState({
      formulas: updatedFormulas,
      highestTier: {
        greaterThanResult: (greaterThanResult || 0).toFixed(2),
        commissionResult: (commissionResult || 0).toFixed(2),
      },
    });
  };

  getTotalAmount = () =>
    this.state.placeholders.reduce((amount, placeholder) => {
      if (placeholder.symbol === '[T]' && placeholder.value) {
        return parseFloat(placeholder.value);
      }
      return amount;
    }, 0);

  getCommissionResultOnSet = ({ greaterThanFormula, commissionFormula }) => {
    const { placeholders } = this.state;

    if (
      formulaEvaluates(greaterThanFormula, this.props.formulaIds) &&
      formulaEvaluates(commissionFormula, this.props.formulaIds)
    ) {
      const greaterThanResult = evaluateFormula({
        formula: greaterThanFormula,
        placeholders,
      });

      let commissionResult = evaluateFormula({
        formula: commissionFormula,
        placeholders,
      });

      const totalAmount = this.getTotalAmount();

      if (greaterThanResult > totalAmount) {
        commissionResult = 0;
      }

      return commissionResult;
    }

    return 0;
  };

  selectPlaceholder = (placeholder) => {
    const { focused } = this.state;
    const { index, key } = focused;
    if (this.state.formulas[index]) {
      const inputRef = this.state.formulas[index][`${key}InputRef`].current;
      let cursor = inputRef.selectionStart;
      const formula = inputRef.value;
      const before = formula.substring(0, cursor);
      const after = formula.substring(cursor);
      const updatedFormula = `${before}${placeholder.symbol}${after}`;
      const updatedFormulas = this.state.formulas.map((formulaObject, i) => {
        if (i === index) {
          return {
            ...formulaObject,
            [`${key}Formula`]: updatedFormula,
          };
        }
        return formulaObject;
      });
      this.setState(
        {
          formulas: updatedFormulas,
        },
        () => {
          // this is a mess, mixing refs state and mutating it directly,
          // will have to come back to this later
          this.state.formulas[index][`${key}InputRef`].current.focus();
          cursor += placeholder.symbol.length;
          // eslint-disable-next-line react/no-direct-mutation-state
          this.state.formulas[index][
            `${key}InputRef`
          ].current.selectionStart = cursor;
          // eslint-disable-next-line react/no-direct-mutation-state
          this.state.formulas[index][
            `${key}InputRef`
          ].current.selectionEnd = cursor;
        },
      );
    }
  };

  isInLimit = () => {
    const numberOfFormulas = this.state.formulas.length;
    const { maxCommissionFormulasCount } = this.props;
    const inLimit =
      maxCommissionFormulasCount === -1
        ? true
        : numberOfFormulas < maxCommissionFormulasCount;
    return inLimit;
  };

  calculateHighestTierDefault = () => {
    const { placeholders } = this.state;
    const formulaSet = this.state.formulas;
    const values = formulaSet.map((formulaObject) =>
      this.getCommissionResultOnSet(formulaObject),
    );

    const max = Math.max(...values);
    const maxFormulaIndex = values.indexOf(max);
    const highestTier = formulaSet[maxFormulaIndex];

    if (
      highestTier &&
      formulaEvaluates(
        highestTier.greaterThanFormula,
        this.props.formulaIds,
      ) /* && max > 0 */
    ) {
      const greaterThanResult = evaluateFormula({
        formula: highestTier.greaterThanFormula,
        placeholders,
      });
      const results = {
        highestTier,
        index: maxFormulaIndex,
        commissionResult: max,
        greaterThanResult,
      };
      this.onHighestTier(results);
      return results;
    }
    this.onHighestTier({ index: null });
    return {};
  };

  calculateHighestTierPriority = ({
    greaterThanZeroFormulas,
    placeholders,
  }) => {
    const { formulas } = this.state;
    const allPriorties = formulas.map(
      (formulaObject) => formulaObject.priority,
    );

    const prioritiesOfgreaterThanZeroFormulas = greaterThanZeroFormulas.map(
      ({ formulaObject }) => formulaObject.priority,
    );

    const min = Math.min(...prioritiesOfgreaterThanZeroFormulas);
    const minPriorityIndex = allPriorties.indexOf(min);
    const highestTier = formulas[minPriorityIndex] || {};

    if (
      highestTier &&
      formulaEvaluates(highestTier.greaterThanFormula, this.props.formulaIds)
    ) {
      const greaterThanResult = evaluateFormula({
        formula: highestTier.greaterThanFormula,
        placeholders,
      });
      const results = {
        highestTier,
        index: minPriorityIndex,
        commissionResult: this.getCommissionResultOnSet(highestTier),
        greaterThanResult,
      };
      this.onHighestTier(results);
      return results;
    }
    this.onHighestTier({ index: null });
    return {};
  };

  calculateHighestTier = () => {
    const { formulas, placeholders } = this.state;
    const values = formulas.map((formulaObject) => ({
      value: this.getCommissionResultOnSet(formulaObject),
      formulaObject,
    }));
    const listHasPriorities = formulas.filter(
      (formula, i) => formula.priority || formula.priority === 0,
    );

    const greaterThanZeroFormulas = values.filter(({ value }) => value > 0);

    if (listHasPriorities.length > 0 && greaterThanZeroFormulas.length > 1) {
      return this.calculateHighestTierPriority({
        greaterThanZeroFormulas,
        placeholders,
      });
    }

    return this.calculateHighestTierDefault();
  };

  render() {
    const { formulaIds } = this.props;
    return (
      <>
        <Copy
          title="Copy Commission Formulas"
          show={this.state.showCopy}
          warning="Warning! This will overwrite your current Commission Formulas"
          configKeys={['commissionFormulas']}
          onClose={() => this.setState({ showCopy: false })}
        />
        <div className="default-page-padding">
          <Panel title="Commission Formulas">
            <div>
              <div>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th style={{ width: '35px' }}>Priority</th>
                      <th>Greater Than Formula</th>
                      <th>Commission Formula</th>
                      <th style={{ width: '35px' }}>
                        <button
                          disabled={!this.isInLimit()}
                          type="button"
                          onClick={this.onAddClicked}
                        >
                          Add
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.formulas.map(
                      (
                        {
                          greaterThanFormula,
                          commissionFormula,
                          greaterThanInputRef,
                          commissionInputRef,
                          objectId,
                          selected,
                          priority,
                        },
                        index,
                      ) => (
                        <tr key={objectId}>
                          <td>
                            <input
                              className={`table-row__cell__input${
                                this.validPriority({ value: priority, index })
                                  ? ''
                                  : ' error-border'
                              }`}
                              type="number"
                              value={priority}
                              placeholder="Priority"
                              onChange={(e) =>
                                this.updatedPriority({
                                  value: e.target.value,
                                  index,
                                })
                              }
                            />
                            <div className="error">
                              {this.validPriority({ value: priority, index })
                                ? ''
                                : 'Invalid Formula'}
                            </div>
                          </td>
                          <td
                            style={{
                              borderColor: 'blue',
                              borderWidth: selected,
                              borderStyle: 'solid',
                            }}
                          >
                            <input
                              ref={greaterThanInputRef}
                              className={`table-row__cell__input${
                                formulaEvaluates(greaterThanFormula, formulaIds)
                                  ? ''
                                  : ' error-border'
                              }`}
                              type="text"
                              value={greaterThanFormula}
                              placeholder="Greater Than Formula"
                              onChange={(e) =>
                                this.onGreaterThanChange({
                                  value: e.target.value,
                                  index,
                                })
                              }
                              onFocus={() =>
                                this.setState({
                                  focused: {
                                    index,
                                    key: 'greaterThan',
                                  },
                                })
                              }
                            />
                            <div className="error">
                              {formulaEvaluates(greaterThanFormula, formulaIds)
                                ? ''
                                : 'Invalid Formula'}
                            </div>
                          </td>
                          <td
                            style={{
                              borderColor: 'blue',
                              borderWidth: selected,
                              borderStyle: 'solid',
                            }}
                          >
                            <input
                              ref={commissionInputRef}
                              className={`table-row__cell__input${
                                formulaEvaluates(commissionFormula, formulaIds)
                                  ? ''
                                  : ' error-border'
                              }`}
                              type="text"
                              value={commissionFormula}
                              placeholder="Commission Formula"
                              onChange={(e) =>
                                this.onCommissionChange({
                                  value: e.target.value,
                                  index,
                                })
                              }
                              onFocus={() =>
                                this.setState({
                                  focused: {
                                    index,
                                    key: 'commission',
                                  },
                                })
                              }
                            />
                            <div className="error">
                              {formulaEvaluates(commissionFormula, formulaIds)
                                ? ''
                                : 'Invalid Formula'}
                            </div>
                          </td>
                          <td>
                            <button
                              className="fill-cell danger"
                              type="button"
                              onClick={() => this.onDelete(index)}
                            >
                              <i className="far fa-trash-alt" />
                            </button>
                          </td>
                        </tr>
                      ),
                    )}
                  </tbody>
                </Table>
                {!this.isInLimit() && (
                  <p className="danger">
                    Plan is limited to {this.props.maxCommissionFormulasCount}{' '}
                    commission formulas
                  </p>
                )}
                <FormGroup title="">
                  <div style={{ display: 'flex', flexFlow: 'wrap' }}>
                    {this.state.placeholders.map((placeholder) => (
                      <FormulaTipButton
                        onClick={() => this.selectPlaceholder(placeholder)}
                        key={placeholder.symbol}
                        symbol={placeholder.symbol}
                        info={placeholder.name}
                        color={placeholder.color}
                      />
                    ))}
                  </div>
                </FormGroup>
              </div>
            </div>
          </Panel>
          <CommissionTierTestSuite
            placeholders={this.state.placeholders}
            onPlaceHolderUpdate={(placeholders) => {
              this.onPlaceHolderUpdate(placeholders);
            }}
            formulaResult={this.state.highestTier}
          />
        </div>
      </>
    );
  }
}

const TitleButtons = ({ onCopyClicked, onSaveClicked }) => (
  <>
    <TitleButton
      variant="primary"
      onClick={() => onCopyClicked()}
      title="Copy"
    />
    <TitleButton
      variant="success"
      onClick={() => onSaveClicked()}
      title="Save"
    />
  </>
);

CommissionFormulas.propTypes = {
  formulas: PropTypes.arrayOf(
    PropTypes.shape({
      greaterThanFormula: PropTypes.string,
      commissionFormula: PropTypes.string,
      order: PropTypes.number,
    }),
  ),
  categories: PropTypes.arrayOf(PropTypes.object),
  maxCommissionFormulasCount: PropTypes.number.isRequired,
  startUpdateConfig: PropTypes.func.isRequired,
  formulaIds: PropTypes.arrayOf(PropTypes.string),
  ...layoutContextPropTypes,
};

CommissionFormulas.defaultProps = {
  formulas: [],
  formulaIds: [],
  categories: [],
};

TitleButtons.propTypes = {
  onCopyClicked: PropTypes.func.isRequired,
  onSaveClicked: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth: { config }, plan = {} }) => ({
  formulas: config.commissionFormulas,
  maxCommissionFormulasCount: plan.maxCommissionFormulasCount,
  objectId: config.objectId, // this forces props to update when office is changed
  categories: config.categories_,
  // eslint-disable-next-line max-len
  formulaIds: config?.categories_
    ?.filter(({ formulaId }) => formulaId)
    .map(({ formulaId }) => formulaId),
});

const mapDispatchToProps = (dispatch) => ({
  startUpdateConfig: (updates) => dispatch(startUpdateConfig(updates)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(CommissionFormulas),
);
