/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';

export const LogsSection = (props) => {
  return (
    <div className="logs-section col-lg-8 offset-lg-2">
      <h2 className="logs-header">Log</h2>
      <table className="logs-table table table-striped table-sm table-bordered">
        <thead>
          <tr>
            <th className="text-center list-line-header">Time</th>
            <th className="text-center list-line-header"> Logs</th>
          </tr>
        </thead>
        <tbody>
          {props.logs.map((log, i) => {
            return (
              <tr
                key={i}
                className={` ${
                  log.key_metric ? 'table-primary logs-table-keymetric' : ''
                }`}
              >
                <th scope="row">{log.time}</th>
                <td className="">{log.value}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

LogsSection.propTypes = {
  logs: PropTypes.arrayOf(
    PropTypes.shape({
      key_metric: PropTypes.string,
      time: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};

LogsSection.defaultProps = {
  logs: [],
};
