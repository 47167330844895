/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';

import DropDownSelect from './DropDownSelect';

const VertAutoCompleteWidget = (props) => {
  return (
    <div className="flex-1 vert-drop-down">
      <div className="filter-section-dropdown-title mr-2">
        <span className="lfw-bold input-title" style={{ color: 'black' }}>
          {props.title}
        </span>
      </div>
      <div className="flex-1 filter-section-dropdown-element-container">
        <DropDownSelect
          selected={props.selected}
          options={props.options}
          onSelection={(selected) => {
            props.onSelection(selected);
          }}
        />
      </div>
      {props.errorMessage ? (
        <span className="input-error-message lfw-bold danger">
          {props.errorMessage}
        </span>
      ) : (
        <span />
      )}
    </div>
  );
};

VertAutoCompleteWidget.propTypes = {
  errorMessage: PropTypes.string,
  title: PropTypes.string.isRequired,
  onSelection: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

VertAutoCompleteWidget.defaultProps = {
  errorMessage: undefined,
};

export default VertAutoCompleteWidget;
