export default (state = {}, action) => {
  switch (action.type) {
    case 'SHOW_DELETE_CONFIRMATION':
      return {
        show: true,
        title: action.title,
        message: action.message,
        onConfirm: action.onConfirm,
      };
    case 'HIDE_DELETE_CONFIRMATION':
      return {
        show: false,
      };
    default:
      return state;
  }
};
