export const defaultState = {
  viewSelection: 1,
  isDragging: false,
};

export default (state = defaultState, { type, ...rest }) => {
  switch (type) {
    case 'TEMPLATES_EDIT_SET_CURRENT_WORKING_TEMPLATE':
      return {
        ...state,
        currentWorkingId: rest.objectId,
      };
    case 'TEMPLATES_EDIT_SET_INITIAL_STATE':
      return {
        ...state,
        [rest.template.objectId]: rest.template,
      };
    case 'LOGOUT': {
      return defaultState;
    }
    case 'TEMPLATES_EDIT_SET_CHANGES':
      return {
        ...state,
        [rest.templateObjectId]: {
          ...state[rest.templateObjectId],
          ...rest.changes,
        },
      };
    case 'TEMPLATE_EDIT_SET_SHOW_WATERMARK_TOOLBAR':
      return {
        ...state,
        [rest.template]: {
          ...state[rest.template],
          showWatermarkToolbar: rest.show,
        },
      };
    case 'TEMPLATES_SET_EDIT_CELL_ITEM':
      return {
        ...state,
        editCellItem: rest.editCellItem,
      };
    case 'TEMPLATE_EDIT_SET_ON_DRAG_END_CALLBACK':
      return {
        ...state,
        onDragEndCallbacks: {
          [rest.identifier]: rest.callback,
        },
      };
    case 'TEMPLATE_EDIT_SET_SCALE':
      return {
        ...state,
        scale: rest.scale,
      };
    case 'TEMPLATES_SET_CONTRACT_DATA':
      return {
        ...state,
        [rest.templateObjectId]: {
          ...state[rest.templateObjectId],
          contractData: rest.contractData,
        },
      };
    case 'TEMPLATE_EDIT_SET_SHOW_MEASURE_SHEET_ITEMS':
      return {
        ...state,
        linkMeasureSheetItemsShow: rest.show,
        linkMeasuresheetItemsKey: rest.key,
        linkMeasureSheetItemsSingleSelect: rest.singleSelect,
        linkMeasureSheetItemsTitle: rest.title,
        linkMeasureSheetItemsShowCustomProduct: rest.showCustomProduct,
      };
    case 'TEMPLATE_SET_MEASURE_SHEET_ITEMS':
      return {
        ...state,
        measureSheetItems: rest.items,
      };
    case 'TEMPLATE_SET_ALL_MEASURE_SHEET_ITEMS':
      return {
        ...state,
        allMeasureSheetItems: rest.items,
      };
    case 'TEMPLATE_SET_MEASURE_SHEET_ITEM_TOTAL':
      return {
        ...state,
        measureSheetItemTotal: rest.itemTotal,
      };
    case 'TEMPLATE_EDIT_SET_SUBCATEGORY_FILTER':
      return {
        ...state,
        subCategoryFilter: rest.subCategory,
      };
    case 'TEMPLATE_EDIT_SET_CATEGORY_FILTER':
      return {
        ...state,
        categoryFilter: rest.category,
      };
    case 'TEMPLATE_SET_DRAGGABLE_TYPE':
      return {
        ...state,
        draggableType: rest.draggableType,
      };
    case 'TEMPLATE_SET_VIEW_SELECTION':
      return {
        ...state,
        viewSelection: rest.viewSelection,
      };
    case 'TEMPLATE_SET_IS_DRAGGING':
      return {
        ...state,
        isDragging: rest.payload,
      };
    case 'SET_VALID_LINKED_COUNT':
      return {
        ...state,
        validLinkedItemCounts: {
          ...state.validLinkedItemCounts,
          ...rest.newCount,
        },
      };
    default:
      return state;
  }
};
