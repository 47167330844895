/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import { TextGroup, ButtonGroup, SwitchGroup } from '../FormGroup';
import { startUpdateConfig } from '../../actions/auth';
import Panel from '../Panel';
import TitleButton from '../TitleButton';
import Copy from '../AppSettings/Copy';
import { showDeleteConfirmation } from '../../actions/deleteConfirmation';
import { pushToDataLayer } from '../../actions/tagManager';

const fortivaLink =
  'https://secure.na1.echosign.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhAxNpWACD-BIXc1bugjMBEQ5Wv9bbTwH11rSxypJX-eKt4zm73cabqiLOIvLaT5V34';

export class Fortiva extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.fortivaConfig,
    };
  }

  componentDidMount() {
    const {
      setCrumbs,
      setButtons,
      location: { pathname },
    } = this.props;
    const crumb = { title: 'Fortiva', link: pathname };
    setCrumbs([crumb]);
    setButtons(
      <TitleButtons
        onClearClick={this.askForClearPermission}
        onCopyClicked={this.onCopyClicked}
        onSaveClicked={this.onSaveClicked}
      />,
    );
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState(newProps.fortivaConfig);
  }

  componentWillUnmount() {
    const { setCrumbs, setButtons } = this.props;
    setCrumbs([]);
    setButtons();
  }

  onEnabledChanged = (checked) => {
    this.setState({ enabled: checked });
    if (!checked) {
      const unset = Object.keys(this.state).reduce(
        (unset2, key) => ({ ...unset2, [key]: '' }),
        {},
      );
      this.setState({ ...unset });
      this.props.pushToDataLayer({
        event: 'integrationEvent',
        eventCategory: 'Integration',
        eventAction: 'Disable',
        eventLabel: 'Fortiva',
      });
    } else {
      this.props.pushToDataLayer({
        event: 'integrationEvent',
        eventCategory: 'Integration',
        eventAction: 'Enable',
        eventLabel: 'Fortiva',
      });
    }
  };

  askForClearPermission = () => {
    this.props.showDeleteConfirmation({
      message: `Are you sure you want to clear this integration ?`,
      title: 'Clear Integration ?',
      onConfirm: () => {
        this.onClearClick();
      },
    });
  };

  onClearClick = () => {
    this.onEnabledChanged(false);
  };

  onLocationIdChanged = (value) => {
    this.setState({ leadGenLocationId: value });
  };

  onCompanyIdChanged = (value) => {
    this.setState({ leadGenCompanyId: value });
  };

  onCopyClicked = () => {
    this.setState({ showCopy: true });
  };

  onSaveClicked = () => {
    this.props.startUpdateConfig({ fortivaConfig: this.state });
  };

  render() {
    return (
      <>
        <Copy
          title="Copy Fortiva Settings"
          show={this.state.showCopy}
          warning="Warning! This will overwrite your current Fortiva settings"
          configKeys={['fortivaConfig']}
          onClose={() => this.setState({ showCopy: false })}
        />
        <div className="default-page-padding">
          <Panel title="Fortiva">
            <div>
              <SwitchGroup
                title="Enabled"
                checked={this.state.enabled}
                onChange={this.onEnabledChanged}
              />
              <TextGroup
                title="Location ID"
                value={this.state.leadGenLocationId}
                onChange={this.onLocationIdChanged}
              />
              <TextGroup
                title="Company ID"
                value={this.state.leadGenCompanyId}
                onChange={this.onCompanyIdChanged}
              />
              <a
                href={fortivaLink}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }}
              >
                <ButtonGroup
                  title=""
                  buttonTitle="Enroll with Fortiva"
                  variant="success"
                />
              </a>
            </div>
          </Panel>
        </div>
      </>
    );
  }
}

Fortiva.propTypes = {
  fortivaConfig: PropTypes.shape({
    leadGenLocationId: PropTypes.string,
    leadGenCompanyId: PropTypes.string,
  }),
  startUpdateConfig: PropTypes.func.isRequired,
};

Fortiva.defaultProps = {
  fortivaConfig: {
    leadGenLocationId: '',
    leadGenCompanyId: '',
  },
};

const TitleButtons = ({ onCopyClicked, onSaveClicked, onClearClick }) => (
  <>
    <TitleButton
      variant="warning"
      onClick={() => onClearClick()}
      title="Clear"
    />
    <TitleButton
      variant="primary"
      onClick={() => onCopyClicked({ source: 'copy' })}
      title="Copy"
    />
    <TitleButton
      variant="success"
      onClick={() => onSaveClicked({ source: 'save' })}
      title="Save"
    />
  </>
);

TitleButtons.propTypes = {
  onCopyClicked: PropTypes.func.isRequired,
  onSaveClicked: PropTypes.func.isRequired,
  ...layoutContextPropTypes,
};

const mapStateToProps = ({ auth: { config } }) => ({
  fortivaConfig: config.fortivaConfig,
});

const mapDispatchToProps = (dispatch) => ({
  startUpdateConfig: (updates) => dispatch(startUpdateConfig(updates)),
  pushToDataLayer: (variablesForLayer) =>
    dispatch(pushToDataLayer(variablesForLayer)),
  showDeleteConfirmation: (params) => dispatch(showDeleteConfirmation(params)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(Fortiva),
);
