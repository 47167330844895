/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import GrayDropDown from './GrayDropDown';

const FilterSectionDropRow = (props) => {
  return (
    <div className="flex-1 filter-section-input-row d-flex m-3 align-items-center">
      <div className="filter-section-dropdown-title">
        <span className="list-line-action-item"> {props.title}</span>
      </div>
      <div className="flex-1 filter-section-dropdown-element-container">
        <GrayDropDown
          placeholder={props.placeholder}
          selected={props.selected || []}
          options={props.options || []}
          onSelectedChanged={props.onSelectedChanged}
        />
      </div>
    </div>
  );
};

export default FilterSectionDropRow;
