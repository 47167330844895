import React from 'react';
import PropTypes from 'prop-types';

class TouchHoldWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPressed: false,
      initEvent: undefined,
    };

    window.document.body.addEventListener('touchmove', () => {
      this.onMouseOut();
    });
  }

  onMouseDown = (e) => {
    // e.preventDefault();
    let { clientX, clientY } = e;
    const { touches } = e;
    if (touches) {
      clientX = touches[0].clientX;
      clientY = touches[0].clientY;
    }

    e.clientX = clientX;
    e.clientY = clientY;

    const { startTimeout } = this.props;

    this.setState({ initEvent: e });
    this.clearTimeout();
    this.longPressTimeout = setTimeout(this.longPressStart, startTimeout);
  };

  onMouseOut = (e) => {
    // e.preventDefault();
    // e.stopPropagation();
    this.clearTimeout();
    if (this.isCurrentlyPressed()) {
      this.setState({
        isPressed: false,
      });
    }
  };

  clearTimeout = () => {
    clearTimeout(this.longPressTimeout);
    clearInterval(this.pressInterval);
    this.longPressTimeout = undefined;
    this.pressInterval = undefined;
  };

  isCurrentlyPressed = () => {
    const { isPressed } = this.state;
    return isPressed;
  };

  longPressStart = () => {
    const {
      longPressStart,
      finite,
      pressCallback,
      pressCallbackTimeout,
    } = this.props;

    longPressStart();
    // When inifite call the timeout for regular period
    if (!finite) {
      pressCallback();
      this.pressInterval = setInterval(pressCallback, pressCallbackTimeout);
    } else if (finite) {
      this.pressInterval = setTimeout(this.longPressEnd, pressCallbackTimeout);
    }

    this.setState({
      isPressed: true,
    });
  };

  longPressEnd = () => {
    const { initEvent } = this.state;
    this.onMouseOut(initEvent);
    const { longPressEnd = () => {}, onContextMenu = () => {} } = this.props;
    longPressEnd(initEvent);
    onContextMenu(initEvent);
    this.setState({ initEvent: undefined });
  };

  render() {
    const { children, onContextMenu } = this.props;
    return (
      <>
        {React.Children.map(children, (child) => (
          <>
            {React.cloneElement(child, {
              onContextMenu,
              onTouchMove: (e) => this.onMouseOut(e),
              onTouchCancel: (e) => this.onMouseOut(e),
              onTouchStart: (e) => this.onMouseDown(e),
              onTouchEnd: (e) => this.onMouseOut(e),
            })}
          </>
        ))}
      </>
    );
  }
}

TouchHoldWrapper.defaultProps = {
  startTimeout: 300,
  longPressStart: () => {},
  longPressEnd: () => {},
  pressCallbackTimeout: 300,
  pressCallback: undefined,
  finite: true,
};

TouchHoldWrapper.propTypes = {
  startTimeout: PropTypes.number,
  longPressStart: PropTypes.func,
  longPressEnd: PropTypes.func,
  pressCallbackTimeout: PropTypes.number,
  pressCallback: PropTypes.func,
  finite: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onContextMenu: PropTypes.func.isRequired,
};

export default TouchHoldWrapper;
