/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';

const ResponsiveTable = (props) => (
  <>
    <table className="table mb-20 table-striped table-bordered d-none d-md-table table-hover">
      <thead className="responsive-table-header">
        <tr>
          {props.columns.map((column, columnIndex) => {
            return (
              <th key={`header_cell_${columnIndex}`} className="text-center">
                {props.titleFormatter(column.title, column, columnIndex)}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {props.items.map((item, rowIndex) => {
          return (
            <tr
              key={`row_${rowIndex}`}
              style={{ ...props.rowStyles(item, rowIndex) }}
              onClick={() => {
                props.onRowClick(item, rowIndex);
              }}
            >
              {props.columns.map((column, columnIndex) => {
                const value = item[column.itemKey];

                const displayValue =
                  value === null || value === undefined || value === ''
                    ? 'N/A'
                    : value;

                return (
                  <td
                    key={`row_cell_${rowIndex}_${columnIndex}`}
                    className="text-center"
                  >
                    {props.valueFormatter(
                      displayValue,
                      { item, rowIndex, column, columnIndex },
                      false,
                    )}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
    <div>
      {props.items.map((item, rowIndex) => {
        return (
          <table
            key={`table_${rowIndex}`}
            className="table mb-20 table-bordered d-md-none cursor-pointer r-table-sm"
            style={{ ...props.rowStyles(item, rowIndex) }}
          >
            <tbody>
              {props.columns.map((column, columnIndex) => {
                const value = item[column.itemKey];

                const displayValue =
                  value === null || value === undefined || value === ''
                    ? 'N/A'
                    : value;

                return (
                  <tr
                    key={`row_${columnIndex}`}
                    onClick={() => {
                      props.onRowClick(item, rowIndex);
                    }}
                  >
                    <td className="text-center responsive-table-header">
                      {props.titleFormatter(column.title, column, columnIndex)}
                    </td>
                    <td className="text-center">
                      {props.valueFormatter(
                        displayValue,
                        { item, rowIndex, column, columnIndex },
                        true,
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        );
      })}
    </div>
  </>
);

ResponsiveTable.propTypes = {
  rowStyles: PropTypes.func,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      itemKey: PropTypes.string.isRequired,
    }),
  ).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  titleFormatter: PropTypes.func,
  onRowClick: PropTypes.func,
  valueFormatter: PropTypes.func,
};

ResponsiveTable.defaultProps = {
  rowStyles: () => ({}),
  titleFormatter: (title) => title,
  items: [],
  onRowClick: () => {},
  valueFormatter: (value) => value,
};

export default ResponsiveTable;
