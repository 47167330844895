import React, { useContext, useEffect } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { LayoutContext } from '../Layout';
import PriceGuideIndex from './PriceGuideIndex';
import PriceGuideMassCopySourcePage from './MassCopy/SourcePage';
import IncrementUpChargeSelection from './IncrementUpChargeSelection';
import PriceGuideMassCopyDestinationPage from './MassCopy/DestinationPage';

const PriceGuideRoute = () => {
  const { path } = useRouteMatch();
  const { setCrumbs } = useContext(LayoutContext);

  useEffect(() => {
    const crumb = { title: 'Price Guide', link: path };
    setCrumbs([crumb]);

    return () => {
      setCrumbs([]);
    };
  }, [setCrumbs, path]);

  return (
    <Switch>
      <Route
        path={`${path}/copy/destination/:id`}
        component={PriceGuideMassCopyDestinationPage}
      />
      <Route
        path={`${path}/copy/source`}
        component={PriceGuideMassCopySourcePage}
      />
      <Route
        path={`${path}/increment_upcharges`}
        component={IncrementUpChargeSelection}
      />
      <Route path={path} component={PriceGuideIndex} />
    </Switch>
  );
};

export default PriceGuideRoute;
