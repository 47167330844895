import React from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem, MenuDivider } from 'leap-menu-item';

const CopyPasteMenu = ({
  onInsert,
  insertDisabled,
  onDelete,
  deleteDisabled,
  onCut,
  cutDisabled,
  onCopy,
  copyDisabled,
  onPasteAbove,
  pasteAboveDisabled,
  onPasteBelow,
  pasteBelowDisabled,
}) => (
  <Menu>
    {onInsert && (
      <MenuItem
        onClick={onInsert}
        icon="insert"
        text="New"
        disabled={insertDisabled}
      />
    )}
    {onDelete && (
      <MenuItem
        onClick={onDelete}
        icon="small-cross"
        text="Delete"
        disabled={deleteDisabled}
      />
    )}
    {onInsert && <MenuDivider />}
    {onCut && (
      <MenuItem onClick={onCut} icon="cut" text="Cut" disabled={cutDisabled} />
    )}
    {onCopy && (
      <MenuItem
        onClick={onCopy}
        icon="duplicate"
        text="Copy"
        disabled={copyDisabled}
      />
    )}
    {onPasteAbove && (
      <MenuItem
        onClick={onPasteAbove}
        icon="clipboard"
        text="Paste Above"
        disabled={pasteAboveDisabled}
      />
    )}
    <MenuItem
      onClick={onPasteBelow}
      icon="clipboard"
      text="Paste Below"
      disabled={pasteBelowDisabled}
    />
  </Menu>
);

CopyPasteMenu.propTypes = {
  onInsert: PropTypes.func,
  insertDisabled: PropTypes.bool,
  onDelete: PropTypes.func,
  deleteDisabled: PropTypes.bool,
  onCut: PropTypes.func,
  cutDisabled: PropTypes.bool,
  onCopy: PropTypes.func,
  copyDisabled: PropTypes.bool,
  onPasteAbove: PropTypes.func,
  pasteAboveDisabled: PropTypes.bool,
  onPasteBelow: PropTypes.func,
  pasteBelowDisabled: PropTypes.bool,
};

CopyPasteMenu.defaultProps = {
  onInsert: undefined,
  insertDisabled: false,
  onDelete: undefined,
  deleteDisabled: false,
  onCut: undefined,
  cutDisabled: false,
  onCopy: undefined,
  copyDisabled: false,
  onPasteAbove: undefined,
  pasteAboveDisabled: false,
  onPasteBelow: undefined,
  pasteBelowDisabled: false,
};

export default CopyPasteMenu;
