/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import { startGenerateNewAPIKey } from '../../actions/auth';
import { TextGroup, ButtonGroup } from '../FormGroup';
import Panel from '../Panel';

export class APIAccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      apiKey: props.apiKey,
    };
  }

  componentDidMount() {
    const {
      setCrumbs,
      location: { pathname },
    } = this.props;
    const crumb = { title: 'API Access', link: pathname };
    setCrumbs([crumb]);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      apiKey: newProps.apiKey,
    });
  }

  componentWillUnmount() {
    const { setCrumbs } = this.props;
    setCrumbs([]);
  }

  onGenerateClicked = () => {
    this.props.startGenerateNewAPIKey();
  };

  render() {
    const { setTitle } = this.props;
    setTitle('API Access');
    return (
      <div className="default-page-padding">
        <Panel title="Generate new API Key">
          <div>
            <TextGroup
              title="API Key"
              value={this.state.apiKey}
              placeholder="API Key"
              disabled
            />
            <ButtonGroup
              title=""
              buttonTitle="Generate New Key"
              variant="success"
              onClick={this.onGenerateClicked}
            />
          </div>
        </Panel>
      </div>
    );
  }
}

APIAccess.propTypes = {
  startGenerateNewAPIKey: PropTypes.func.isRequired,
  apiKey: PropTypes.string,
  setTitle: PropTypes.func,
  ...layoutContextPropTypes,
};

APIAccess.defaultProps = {
  apiKey: '',
  setTitle: () => {},
};

const mapStateToProps = ({ auth }) => ({
  apiKey: auth.apiKey,
});

const mapDispatchToProps = (dispatch) => ({
  startGenerateNewAPIKey: () => dispatch(startGenerateNewAPIKey()),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(APIAccess),
);
