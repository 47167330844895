import React, { useContext, useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import _ from 'lodash';
import { LayoutContext } from '../../Layout';
import PriceFormulasIndex from './PriceFormulasIndex';
import EditPriceFormulas from './edit';

const PriceFormulas = () => {
  const { path } = useRouteMatch();
  const { crumbs, setCrumbs } = useContext(LayoutContext);

  useEffect(() => {
    const crumb = { title: 'Price Formulas', link: path };
    if (!_.isEqual(crumbs[0], crumb)) {
      const newCrumbs = [...crumbs];
      newCrumbs[0] = crumb;
      setCrumbs(newCrumbs);
    }
  }, [crumbs, path, setCrumbs]);

  return (
    <Switch>
      <Route path={`${path}/edit/:id`} component={EditPriceFormulas} />
      <Route path={path} component={PriceFormulasIndex} />
    </Switch>
  );
};

export default PriceFormulas;
