/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { cellForCellItem, shouldHideItem } from './AppView';
import BodyCellItem from '../Models/BodyCellItem';
import ContractObject from '../Models/ContractObject';
import {
  setInitialStateForTemplate,
  setEditCellItem,
} from '../../../actions/templateEdit';
import { setOnDragEndCallback } from '../../DropContext';

const SectionContent = ({
  cellItem,
  isOpen,
  setTemplateState,
  setEditCellItem,
  draggableType,
}) => {
  const containerClassName = `templates-app__content_more${
    isOpen ? '_offset' : ''
  }`;
  if (!isOpen) {
    return <div className={containerClassName} />;
  }
  const droppableId = `${cellItem.objectId}-app-${BodyCellItem.DetailContainerKey}`;
  setOnDragEndCallback(droppableId, (result) => {
    const { source, destination } = result;
    if (source.droppableId === `new-${BodyCellItem.DetailContainerKey}`) {
      const sourceItem = new BodyCellItem();
      cellItem.detailItems.splice(destination.index, 0, sourceItem);
      sourceItem.parent = cellItem;
      setEditCellItem(sourceItem);
    } else {
      const sourceItem = cellItem.detailItems[source.index];
      cellItem.detailItems.splice(source.index, 1);
      cellItem.detailItems.splice(destination.index, 0, sourceItem);
      sourceItem.parent = cellItem;
    }
    const contractObject = cellItem.getSourceObject();
    const updatedObject = new ContractObject(contractObject.toJSON());
    setTemplateState(updatedObject);
  });
  return (
    <div className={containerClassName}>
      <Droppable
        key={`app_${cellItem.objectId}`}
        droppableId={droppableId}
        isDropDisabled={draggableType !== BodyCellItem.DetailContainerKey}
      >
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {cellItem.detailItems.map((item, index) => {
              if (shouldHideItem(item)) {
                return null;
              }
              return (
                <Draggable
                  key={`app_${item.objectId}`}
                  draggableId={`${item.getPath().join('-')}-app_${
                    item.objectId
                  }`}
                  index={index}
                >
                  {(draggableProvided, draggableSnapshot) => (
                    <div
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                    >
                      {cellForCellItem(item)}
                      {draggableProvided.placeholder}
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

const mapStateToProps = ({ templatesEdit }) => ({
  draggableType: templatesEdit.draggableType,
});

const mapDispatchToProps = (dispatch) => ({
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
  setEditCellItem: (item) => dispatch(setEditCellItem(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SectionContent);
