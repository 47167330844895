/* eslint-disable react/no-deprecated */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import { TextGroup, DropDownGroup } from '../FormGroup';
import { startUpdateConfig } from '../../actions/auth';
import Panel from '../Panel';
import TitleButton from '../TitleButton';
import Copy from '../AppSettings/Copy';
import { showDeleteConfirmation } from '../../actions/deleteConfirmation';
import { pushToDataLayer } from '../../actions/tagManager';
import { emailRegex } from '../../validators';

const adminEmailOptions = [
  {
    label: 'User',
    value: 'user',
  },
  {
    label: 'Other',
    value: '',
  },
];
export class Foundation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orgId: props.foundation.orgId,
      enabled: this.props.foundation.enabled,
      adminEmail: props.foundation.adminEmail || 'user',
    };
  }

  componentDidMount() {
    const {
      setCrumbs,
      setButtons,
      location: { pathname },
    } = this.props;
    const crumb = { title: 'Foundation', link: pathname };
    setCrumbs([crumb]);
    setButtons(
      <TitleButtons
        onClearClick={this.askForClearPermission}
        onCopyClicked={this.onCopyClicked}
        onSaveClicked={this.onSaveClicked}
      />,
    );
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      orgId: newProps.foundation.orgId,
    });
  }

  componentWillUnmount() {
    const { setCrumbs, setButtons } = this.props;
    setCrumbs([]);
    setButtons();
  }

  onEnabledChanged = (checked) => {
    this.setState({ enabled: checked });
    if (!checked) {
      const unset = Object.keys(this.state).reduce(
        (unset2, key) => ({ ...unset2, [key]: '' }),
        {},
      );
      this.setState({ ...unset });
      this.props.pushToDataLayer({
        event: 'integrationEvent',
        eventCategory: 'Integration',
        eventAction: 'Disable',
        eventLabel: 'Foundation',
      });
    } else {
      this.props.pushToDataLayer({
        event: 'integrationEvent',
        eventCategory: 'Integration',
        eventAction: 'Enable',
        eventLabel: 'Foundation',
      });
    }
  };

  askForClearPermission = () => {
    this.props.showDeleteConfirmation({
      message: `Are you sure you want to clear this integration ?`,
      title: 'Clear Integration ?',
      onConfirm: () => {
        this.onClearClick();
      },
    });
  };

  onClearClick = () => {
    this.onEnabledChanged(false);
  };

  onOrgIdChanged = (value) => {
    this.setState({ orgId: value });
  };

  onCopyClicked = () => {
    this.setState({ showCopy: true });
  };

  onSaveClicked = () => {
    const isUserType = this.state.adminEmail === 'user';

    const errorMessage = this.validateEmail(this.state.adminEmail);

    const shouldSave =
      isUserType ||
      !errorMessage ||
      (!this.state.orgId && !this.state.adminEmail);

    if (shouldSave) {
      this.props.startUpdateConfig({ foundation: this.state });
    }
  };

  onAdminEmailConfigChange = (event) => {
    this.setState({ adminEmail: event.value });
  };

  // eslint-disable-next-line class-methods-use-this
  validateEmail(value) {
    const test = emailRegex.test(value);
    return !test ? 'invalid email format' : false;
  }

  render() {
    return (
      <>
        <Copy
          title="Copy Foundation Settings"
          show={this.state.showCopy}
          warning="Warning! This will overwrite your current Foundation settings"
          configKeys={['foundation']}
          onClose={() => this.setState({ showCopy: false })}
        />
        <div className="default-page-padding">
          <Panel title="Foundation">
            <div>
              <TextGroup
                title="Org Id"
                value={this.state.orgId}
                placeholder="Org Id"
                onChange={this.onOrgIdChanged}
              />
              <DropDownGroup
                title="Admin Email"
                value={
                  this.state.adminEmail === 'user'
                    ? adminEmailOptions[0]
                    : adminEmailOptions[1]
                }
                onChange={this.onAdminEmailConfigChange}
                closeMenuOnSelect
                options={adminEmailOptions}
                isClearable={false}
              />
              {this.state.adminEmail !== 'user' && (
                <TextGroup
                  errorMessage={this.validateEmail(this.state.adminEmail)}
                  title="Admin Email Address"
                  value={this.state.adminEmail}
                  placeholder="Admin Email Address"
                  onChange={(value) => {
                    this.onAdminEmailConfigChange({ value });
                  }}
                />
              )}
            </div>
          </Panel>
        </div>
      </>
    );
  }
}

Foundation.propTypes = {
  foundation: PropTypes.shape({
    orgId: PropTypes.string,
  }),
  startUpdateConfig: PropTypes.func.isRequired,
};

Foundation.defaultProps = {
  foundation: { orgId: '' },
};

const TitleButtons = ({ onCopyClicked, onSaveClicked, onClearClick }) => (
  <>
    <TitleButton
      variant="warning"
      onClick={() => onClearClick()}
      title="Clear"
    />
    <TitleButton
      variant="primary"
      onClick={() => onCopyClicked({ source: 'copy' })}
      title="Copy"
    />
    <TitleButton
      variant="success"
      onClick={() => onSaveClicked({ source: 'save' })}
      title="Save"
    />
  </>
);

TitleButtons.propTypes = {
  onCopyClicked: PropTypes.func.isRequired,
  onSaveClicked: PropTypes.func.isRequired,
  ...layoutContextPropTypes,
};

const mapStateToProps = ({ auth: { config } }) => ({
  foundation: config.foundation,
});

const mapDispatchToProps = (dispatch) => ({
  startUpdateConfig: (updates) => dispatch(startUpdateConfig(updates)),
  pushToDataLayer: (variablesForLayer) =>
    dispatch(pushToDataLayer(variablesForLayer)),
  showDeleteConfirmation: (params) => dispatch(showDeleteConfirmation(params)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(Foundation),
);
