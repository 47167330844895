/* eslint-disable react/prop-types */
import React from 'react';
// import PropTypes from 'prop-types';
import moment from 'moment';

const appStoreURL =
  'https://itunes.apple.com/us/app/owens-corning-roofing-prosell/id1446095443?mt=8';

const ProSellTemplate = ({ children }) => (
  <div className="new-account__container">
    <div className="new-account__body-container">
      <div className="new-account__header-container">
        <div className="new-account__header-image-conatiner">
          <img src="/images/ProSell/hero.jpg" alt="Prosell" />
        </div>
        <div className="new-account__header-oc-logo">
          <img src="/images/ProSell/oc_logo.png" alt="Prosell" />
        </div>
        <div className="new-account__header-prosell-logo">
          <img
            className="prosell-logo"
            src="/images/ProSell/logo.png"
            alt="Prosell"
          />
        </div>
      </div>
      {children}
      <div className="new-account__footer-conatiner">
        <div className="new-account__download-app-store-container">
          <a href={appStoreURL} target="__blank">
            <img src="/images/ProSell/download_app_store.png" alt="App Store" />
          </a>
          <div style={{ maxWidth: 802 }}>
            <span style={{ paddingLeft: 20 }}>
              You can download the ProSell app on your Apple devices{' '}
              <a href={appStoreURL} target="__blank">
                here
              </a>
              , or simply search for the &quot;ProSell&quot; app in the iTunes
              app store.
            </span>
          </div>
        </div>
      </div>
    </div>
    <p className="new-account__copyright">
      © {moment().format('YYYY')} Owens Corning. All Rights Reserved.
    </p>
  </div>
);

export default ProSellTemplate;
