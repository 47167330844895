/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Menu, MenuItem } from 'leap-menu-item';
import { DebounceInput } from 'react-debounce-input';

const placeholders = [
  '%selectedTotal%',
  '%selectedTotalWholeNumber%',
  '%financeOptionDealerFee%',
  '%financeOptionInterestRate%',
  '%financeOptionMinAmount%',
  '%financeOptionMaxAmount%',
  '%financeOptionPaymentMultiplier%',
  '%financeOptionNumberOfPayments%',
  '%financeOptionDownPayment%',
  '%financeOptionDownPaymentNoZero%',
];

class FormulaEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: undefined,
      showError: false,
    };
  }

  onIdentifierSelected = async (identifier) => {
    const placeholder = `[${identifier}]`;
    this.insertStringAtCursorPosition(placeholder);
  };

  onPlaceholderSelected = (placeholder) => {
    this.insertStringAtCursorPosition(placeholder);
  };

  insertStringAtCursorPosition = async (string) => {
    const { value } = this.props;
    let cursor = this.inputRef.selectionStart || value.length;
    const before = value.substring(0, cursor);
    const after = value.substring(cursor);
    const newFormula = `${before}${string}${after}`;
    await this.props.onChange(newFormula);
    this.inputRef.focus();
    cursor += string.length + 2;
    this.inputRef.selectionStart = cursor;
    this.inputRef.selectionEnd = cursor;
  };

  render() {
    const {
      creditApplication,
      editCellItem,
      value,
      onChange,
      title = 'Formula',
    } = this.props;
    return (
      <MenuItem
        text={title}
        shouldDismissPopover={false}
        popoverProps={{ isOpen: this.state.isOpen }}
        onClick={() =>
          this.setState({ isOpen: this.state.isOpen ? undefined : true })
        }
      >
        <DebounceInput
          style={{
            backgroundColor: this.state.showError ? 'red' : 'transparent',
            color: this.state.showError ? 'white' : 'black',
          }}
          element="textarea"
          minLength={0}
          debounceTimeout={300}
          value={value}
          inputRef={(ref) => {
            this.inputRef = ref;
          }}
          onChange={(e) => {
            if (e.target.value.indexOf(`[${editCellItem.cellId}]`) === -1) {
              onChange(e.target.value);
              this.setState({
                showError: false,
              });
            } else {
              onChange(e.target.value);
              this.setState({
                showError: true,
              });
            }
          }}
        />
        {this.state.showError && (
          <p
            style={{
              color: 'red',
            }}
          >
            You can not reference the current cell &quot;[
            {editCellItem.cellId}
            ]&quot;!
          </p>
        )}
        <Menu.Divider />
        <MenuItem text="Identifiers" shouldDismissPopover={false}>
          {creditApplication
            .getAllCellIds(this.props.excluding)
            .map((identifier) => (
              <MenuItem
                key={`cell-identifier-${identifier}`}
                text={identifier}
                shouldDismissPopover={false}
                onClick={() => this.onIdentifierSelected(identifier)}
              />
            ))}
        </MenuItem>
        <MenuItem text="Placeholders" shouldDismissPopover={false}>
          {placeholders.map((placeholder) => (
            <MenuItem
              key={`cell-placeholder-${placeholder}`}
              text={placeholder}
              shouldDismissPopover={false}
              onClick={() => this.onPlaceholderSelected(placeholder)}
            />
          ))}
        </MenuItem>
      </MenuItem>
    );
  }
}

FormulaEditor.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  creditApplication: PropTypes.shape({}).isRequired,
  excluding: PropTypes.string,
};

FormulaEditor.defaultProps = {
  value: '',
  excluding: undefined,
};

const mapStateToProps = ({ creditApplications }) => ({
  editCellItem: creditApplications.editCellItem,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FormulaEditor);
