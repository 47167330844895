import { pushToDataLayer } from './tagManager';

export const injectReduxInContext = (stickyContext) => async (
  dispatch,
  getState,
) => {
  stickyContext.injectRedux({ dispatch, getState });
};

export const toggleStickyHeaders = (disableSticky) => async (
  dispatch,
  getState,
) => {
  const eventAction = !disableSticky ? 'Enable' : 'Disable';

  dispatch(
    pushToDataLayer({
      event: 'stickyHeader',
      eventCategory: 'toggle',
      eventAction,
    }),
  );

  return dispatch({
    type: 'TOGGLE_STICKY_HEADERS',
    disableSticky,
  });
};
