import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { restoreDocumentDeleteSnapshot } from '../../../actions/documentSnapshots';

function DeletedDocumentsListSection(props) {
  const { deletedDocuments } = props;
  const dispatch = useDispatch();
  return (
    <div className="list-section m-3">
      {deletedDocuments.map((deletedDocument) => {
        const id = `${deletedDocument.id}`;
        return (
          <article
            id={id}
            key={id}
            className="email-estimate-list-item d-md-flex align-items-center list-line"
          >
            <div className="flex-1">
              <h1 className="email-estimate-list-item-title flex-1 d-flex m-0">
                <span className="list-line-header">
                  {deletedDocument.displayName}
                </span>
              </h1>
              <span className="user-line-symbol">USER:</span>
              <span className="user-line-name">
                {`${deletedDocument.user.firstName} ${deletedDocument.user.lastName}`}
              </span>
              <span className="user-line-date">
                {`${moment(deletedDocument.createdAt).format(
                  'MM/DD/YYYY h:mm a',
                )}`}
              </span>
            </div>
            <div className="email-estimate-list-item-statusbox d-flex justify-content-end">
              <button
                type="button"
                onClick={() => {
                  dispatch(restoreDocumentDeleteSnapshot(id));
                }}
                className="btn btn-primary"
              >
                Restore Version
              </button>
            </div>
          </article>
        );
      })}
    </div>
  );
}

DeletedDocumentsListSection.propTypes = {
  deletedDocuments: PropTypes.arrayOf(
    PropTypes.shape({
      objectId: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

DeletedDocumentsListSection.defaultProps = {};

export default DeletedDocumentsListSection;
