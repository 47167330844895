/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import Parse from 'parse';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { handleError } from '../../actions/auth';

import { setValidLinkedCount } from '../../actions/templateEdit';

export const queryValidCount = async (props) => {
  const { itemIds = [] } = props;

  const user = Parse.User.current();

  const linkedQuery = new Parse.Query('SSMeasureSheetItem');

  linkedQuery.containedIn('objectId', itemIds);
  linkedQuery.equalTo('company', user.get('company'));

  const validCount = await linkedQuery.count();

  return validCount;
};

export const useValidLinkedItems = (props = {}) => {
  const { linkedMeasureSheetItemIds = [], objectId } = props;

  const dispatch = useDispatch();

  const [validCount, setValidCount] = useState(0);

  const checkLinkedItems = async (itemIds) => {
    try {
      const count = await queryValidCount({
        itemIds,
      });

      dispatch(setValidLinkedCount({ [objectId]: count }));

      setValidCount(count);
    } catch (error) {
      dispatch(handleError(error));
    }
  };

  useEffect(() => {
    checkLinkedItems(linkedMeasureSheetItemIds);
  }, [objectId]);

  return validCount;
};
