/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  startFetchCreditApplication,
  setCreditApplicationCommit,
  setEditCellItem,
} from '../../../actions/creditApplications';
import CreditApplicationObject from '../Models/CreditApplicationObject';
import CreditApplicationLineItem from '../Models/CreditApplicationLineItem';
import MiddleSection from './MiddleSection';
import SignatureContainer from './SignatureContainer';
import Toolbar from '../Toolbar';
import BodyTitleToolbar from '../BodyTitleToolbar';
import CreditApplicationCellToolDrawer from './CreditApplicationCellToolDrawer';

const toolbarForCellItem = (application, cell) =>
  cell ? <BodyTitleToolbar application={application} cell={cell.cell} /> : null;

class CreditApplicationEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  UNSAFE_componentWillMount = async () => {
    if (this.props.match.params.id) {
      this.props.startFetchCreditApplication(this.props.match.params.id);
      this.props.setCreditApplicationCommit(() => {
        this.initApplication();
      });
    }
  };

  componentDidMount = () => {
    this.initApplication();
  };

  componentDidUpdate(oldProps) {
    if (!oldProps.application && this.props.application) {
      this.initApplication();
    }
  }

  onCellClicked = (cell, x, y) => {
    this.props.setCell({ cell, index: [x, y] });
  };

  getApplicationSections = async (application) => {
    const sections = application.filteredSections();
    return sections;
  };

  initApplication = async () => {
    const { application = new CreditApplicationObject() } = this.props;

    const sections = await this.getApplicationSections(application);
    const {
      topSections = [],
      leftSections = [],
      rightSections = [],
    } = sections;
    let topCells = [];
    topSections.forEach(({ cells }) => {
      topCells = [...topCells, ...cells];
    });
    const topLeftCells = [];
    const topRightCells = [];
    topCells.forEach((cell, index) => {
      if (index % 2) {
        topRightCells.push(cell);
      } else {
        topLeftCells.push(cell);
      }
    });
    let leftCells = [];
    let leftSectionTitle = '';
    leftSections.forEach(({ cells, title }) => {
      leftCells = [...leftCells, ...cells];
      leftSectionTitle = title || leftSectionTitle;
    });
    let rightCells = [];
    let rightSectionTitle = '';
    rightSections.forEach(({ cells, title }) => {
      rightCells = [...rightCells, ...cells];
      rightSectionTitle = title || rightSectionTitle;
    });

    this.setState({
      topLeftCells,
      topRightCells,
      // topCells,
      leftCells,
      rightCells,
      leftSectionTitle,
      rightSectionTitle,
    });
  };

  render() {
    const disclosure = this.props.application
      ? this.props.application.get('pdfDisclosure')
      : '';
    return (
      <div className="templates-pdf__background">
        <Toolbar application={this.props.application} />
        {toolbarForCellItem(this.props.application, this.props.cell)}
        <div className="credit-app-canvas">
          <div id="page-container" className="credit-apps__page">
            {this.props.watermark && (
              <div className="templates-pdf__watermark">
                <img
                  className="watermark-image"
                  alt="Watermark"
                  src={this.props.watermark.url}
                  style={{
                    opacity: `${this.props.watermarkAlpha}`,
                    width: `${this.props.watermarkWidthPercent}%`,
                  }}
                />
              </div>
            )}
            <div className="credit-apps__header-container">
              <div className="credit-apps__company-logo-container">
                <img
                  alt="Company Logo"
                  src={this.props.companyLogo ? this.props.companyLogo.url : ''}
                  className="credit-apps__company-logo"
                />
                <div className="credit-apps__date">
                  {moment().format('MM/DD/YYYY')}
                </div>
              </div>
              <span className="credit-apps__title">Credit Application</span>
            </div>
            <MiddleSection
              sectionId="applicationInfo"
              application={this.props.application}
              leftCells={this.state.topLeftCells}
              rightCells={this.state.topRightCells}
              onCellClicked={this.onCellClicked}
            />
            <div className="credit-apps__section-middle-container">
              <span className="credit-apps__section-title">
                {this.state.leftSectionTitle}
              </span>
              <span className="credit-apps__section-title">
                {this.state.rightSectionTitle}
              </span>
            </div>
            <MiddleSection
              sectionId="applicantInfo"
              application={this.props.application}
              leftCells={this.state.leftCells}
              rightCells={this.state.rightCells}
              onCellClicked={this.onCellClicked}
            />
            <pre className="credit-apps__disclosure">{disclosure}</pre>
            <SignatureContainer />
          </div>
          <CreditApplicationCellToolDrawer
            application={this.props.application}
          />
        </div>
      </div>
    );
  }
}

CreditApplicationEditor.propTypes = {
  startFetchCreditApplication: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  watermark: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
  watermarkAlpha: PropTypes.number,
  watermarkWidthPercent: PropTypes.number,
  application: PropTypes.instanceOf(CreditApplicationObject),
  companyLogo: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
  setCell: PropTypes.func.isRequired,
  setCreditApplicationCommit: PropTypes.func.isRequired,
  cell: PropTypes.shape({
    cell: PropTypes.instanceOf(CreditApplicationLineItem),
  }),
};

CreditApplicationEditor.defaultProps = {
  match: {},
  watermark: undefined,
  watermarkAlpha: 0,
  watermarkWidthPercent: 0,
  application: undefined,
  companyLogo: undefined,
  cell: undefined,
};

const mapStateToProps = ({ auth, creditApplications }, ownProps) => ({
  watermark: auth.config.watermark,
  watermarkAlpha: auth.config.watermarkAlpha,
  watermarkWidthPercent: auth.config.watermarkWidthPercent,
  application: creditApplications.applications[ownProps.match.params.id],
  companyLogo: auth.config.companyLogo,
  cell: creditApplications.editCellItem,
});

const mapDispatchToProps = (dispatch) => ({
  startFetchCreditApplication: (id) =>
    dispatch(startFetchCreditApplication(id)),
  setCell: (cell) => dispatch(setEditCellItem(cell)),
  setCreditApplicationCommit: (func) =>
    dispatch(setCreditApplicationCommit(func)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreditApplicationEditor);
