/* eslint-disable no-underscore-dangle */
import Parse from 'parse';

class MeasureSheetItem extends Parse.Object {
  constructor(obj) {
    super('SSMeasureSheetItem');
    Object.assign(this, obj);
  }

  get includedOffices() {
    return this.get('includedOffices') || [];
  }

  set includedOffices(value) {
    this.set('includedOffices', value);
  }

  newInstance() {
    const newInstance = super.newInstance();
    Object.assign(newInstance, this);
    return newInstance;
  }

  set(key, value) {
    super.set(key, value);
    if (this.onChange) {
      this.onChange(this, key);
    }
  }

  convertImage() {
    if (this.has('image')) {
      const image = this.get('image');
      if (image.isNew) {
        delete image._url;
        delete image.isNew;
      }
    }
    return this;
  }

  async save(...rest) {
    this.convertImage();
    const result = await super.save(...rest);
    const jsonResult = result.toJSON();
    const { company, ...restJsonResult } = jsonResult;

    let currentCompany = company;
    if (!company) {
      const user = Parse.User.current();
      currentCompany = await user.get('company').fetch();
    }

    const parseCompany = {
      className: 'Company',
      id: currentCompany?.id,
      __type: 'Pointer',
    };

    const cleanResult = {
      ...restJsonResult,
      company: parseCompany,
      items:
        jsonResult?.items?.map((item) => ({
          ...item,
          company: parseCompany,
        })) || [],
    };

    await Parse.Cloud.run('measureSheetItemsSaved', {
      objects: [cleanResult],
    });
  }

  moveOption(sourceIndex, toIndex) {
    const options = this.get('items');
    const [option] = options.splice(sourceIndex, 1);
    options.splice(toIndex, 0, option);
    this.set('items', options);
  }

  removeAllOptions() {
    this.set('items', []);
  }

  removeOption(objectId) {
    const options = this.get('items') || [];
    const filteredOptions = options.filter(({ id }) => id !== objectId);
    this.set('items', filteredOptions);
  }

  moveUpCharge(sourceIndex, toIndex) {
    const upCharges = this.get('accessories');
    const [upCharge] = upCharges.splice(sourceIndex, 1);
    upCharges.splice(toIndex, 0, upCharge);
    this.set('accessories', upCharges);
  }

  removeAllUpCharges() {
    this.set('accessories', []);
  }

  removeUpCharge(objectId) {
    const upCharges = this.get('accessories') || [];
    const filteredUpCharges = upCharges.filter(({ id }) => id !== objectId);
    this.set('accessories', filteredUpCharges);
  }

  addPlaceholders(placeholders = []) {
    const existing = this.get('placeholders') || [];
    const updated = [...existing, ...placeholders];
    this.set('placeholders', updated);
  }

  setTagRow({
    defaultValue = '',
    title = '',
    required = false,
    pickerValues = [],
    shouldCopy = false,
    note = '',
    inputType = 'default',
    tagParams = {},
  }) {
    this.set('tagDefaultValue', defaultValue);
    this.set('tagTitle', title);
    this.set('tagRequired', required);
    this.set('tagPickerOptions', pickerValues);
    this.set('shouldCopyTag', shouldCopy);
    this.set('tagNote', note);
    this.set('tagInputType', inputType);
    this.set('tagParams', tagParams);
  }

  getTagRow() {
    return {
      inputType: this.get('tagInputType'),
      pickerValues: this.get('tagPickerOptions'),
      title: this.get('tagTitle'),
      note: this.get('tagNote'),
      defaultValue: this.get('tagDefaultValue'),
      shouldCopy: this.get('shouldCopyTag'),
      required: this.get('tagRequired'),
      tagParams: this.get('tagParams'),
    };
  }

  addAdditionalDetails(values = []) {
    const existing = this.get('additionalDetailObjects') || [];
    const updated = [...existing, ...values];
    this.set('additionalDetailObjects', updated);
  }

  clearAdditionalDetails() {
    this.setTagRow({});
    this.set('additionalDetailObjects', []);
  }
}

export default MeasureSheetItem;
