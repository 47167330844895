/* eslint-disable import/no-cycle */
import Parse from 'parse';
import { history } from '../router';
import { startFetchStyles } from './config';
import { showSuccessAlert, showErrorAlert, hideAlert } from './alert';
import { LoadLogsAndToast } from './changeLogs';
import { pushToDataLayer } from './tagManager';
import { setActiveColumns } from './priceGuide2';
import { showLoader, hideLoader } from './loading';
import { defaultActiveColumns } from '../reducers/priceGuide2';
import PlanActions from './plan';
import { randomKey, decryptString } from '../utils/utils';
import {
  getNavigation,
  setPartnerTag,
  setSelectedOffice,
} from '../data/repo/navigation';
import UserObject from '../Models/UserObject';

export const logoutCompleted = (partnerLogo = {}, favicon = {}) => ({
  type: 'LOGOUT',
  partnerLogo,
  favicon,
});

export const handleError = (error, isLoginAttempt = false, onConfirm) => async (
  dispatch,
  getState,
) => {
  const stx = getState();
  if (stx && stx.alert && stx.alert.show) {
    return;
  }

  const { message, code, errors = [] } = error;

  const report =
    message || code || errors.map((eItem) => eItem.message || eItem.code || '');

  dispatch(
    pushToDataLayer({
      event: 'leapErrorEvent',
      eventCategory: 'Errors',
      eventAction: window.location.pathname,
      eventLabel: report,
    }),
  );
  const showError = () => {
    dispatch(
      showErrorAlert({
        title: 'Error!',
        message: report,
        style: { 'word-break': 'normal' },
        onConfirm: () => {
          if (onConfirm) {
            onConfirm();
          }
          dispatch(hideAlert());
          dispatch(hideLoader());
        },
      }),
    );
  };
  dispatch(hideLoader());
  if (error.code === 209 || !Parse.User.current()) {
    // Invalid session token
    if (isLoginAttempt) {
      showError();
    } else {
      // eslint-disable-next-line no-use-before-define
      dispatch(logout());
    }
    return;
  }
  showError();
};

export const logout = () => async (dispatch) => {
  let partnerLogo;
  let favicon;
  try {
    Parse.User.logOut();

    const isSignupPage =
      history.location.pathname.split('/')[1] === 'signup' ||
      history.location.pathname.split('/')[1] === 'submitPayment';
    if (isSignupPage) {
      return;
    }
    if (window.location.pathname === '/') {
      return;
    }
    const navigation = await getNavigation();
    const partnerTag =
      navigation.partnerTag || window.location.pathname.substr(1);

    partnerLogo = await Parse.Cloud.run('fetch_partner_logo', {
      url: partnerTag,
    });
    favicon = await Parse.Cloud.run('fetch_favicon', {
      url: partnerTag,
    });
  } catch (e) {
    dispatch(handleError(e));
  } finally {
    dispatch(logoutCompleted(partnerLogo, favicon));
  }
};

export const login = (
  user,
  selectedOffice,
  offices,
  config,
  isCorporateAdmin,
  partnerLogo,
  favicon,
  plan,
) => ({
  type: 'LOGIN',
  user,
  selectedOffice,
  offices,
  config,
  isCorporateAdmin,
  partnerLogo,
  favicon,
  plan,
});

export const setCompany = (company) => ({
  type: 'SET_COMPANY',
  company,
});

export const startRefreshCompany = () => async (dispatch) => {
  try {
    const user = Parse.User.current();
    const company = await user.get('company').fetch();
    dispatch(setCompany(company));
  } catch (e) {
    dispatch(handleError(e));
  }
};

const setAllMainMenuOptions = (options) => ({
  type: 'SET_ALL_MAIN_MENU_OPTIONS',
  options,
});

const getMainMenuOptionsFromAllOffices = (offices) => {
  const mainMenuOptions = offices.reduce((prevValue, currentValue) => {
    const config = currentValue.get('config');
    const options = config.get('mainMenuOptions') || [];
    const newValue = { ...prevValue };
    options.forEach((option) => {
      if (!newValue[option.objectId]) {
        newValue[option.objectId] = option;
      }
    });
    return newValue;
  }, {});
  const result = Object.keys(mainMenuOptions).map(
    (key) => mainMenuOptions[key],
  );
  return result;
};

export const startFetchOffices = async () => {
  const user = Parse.User.current();
  const company = await user.get('company').fetch();
  const offices = await Parse.Object.fetchAllWithInclude(
    company.get('offices'),
    'config',
  );
  const navigation = await getNavigation();
  let selectedOfficeId = navigation.selectedOffice;
  let includesOffice = false;
  offices.forEach((office) => {
    if (office.id === selectedOfficeId) {
      includesOffice = true;
    }
  });
  if (!selectedOfficeId || selectedOfficeId === 'null' || !includesOffice) {
    selectedOfficeId = offices[0].id;
  }
  const selectedOffice = new Parse.Object('Office');
  selectedOffice.id = selectedOfficeId;
  await selectedOffice.fetch();
  await setSelectedOffice(selectedOfficeId);
  return {
    selectedOffice,
    allOffices: offices,
  };
};

const loginUserWithSessionToken = async (sessionToken, dispatch) => {
  const user = await UserObject.become(sessionToken);
  const company = await user.get('company');
  const plan = await company.getPlan();
  configureFive9({ company });

  dispatch(PlanActions.setPlanConfig(plan));
  const userActiveColumns = user.get('activeColumns') || defaultActiveColumns;

  dispatch(setActiveColumns(userActiveColumns));
  const { selectedOffice, allOffices } = await startFetchOffices();
  pendoInitialize({ user, company, plan, selectedOffice });

  dispatch(setAllMainMenuOptions(getMainMenuOptionsFromAllOffices(allOffices)));
  const config = await selectedOffice.get('config').fetch();
  const isCorporateAdmin = await Parse.Cloud.run('isCorporateAdmin', {
    objectId: user.id,
  });
  const partnerLogo = await Parse.Cloud.run('fetch_logo_for_user');
  const partnerTag = await Parse.Cloud.run('fetch_login_url_for_user');
  await setPartnerTag(partnerTag);
  const favicon = await Parse.Cloud.run('fetch_favicon_for_user');
  await dispatch(startFetchStyles());

  await dispatch(LoadLogsAndToast());
  dispatch(
    login(
      user,
      selectedOffice,
      allOffices,
      config,
      isCorporateAdmin,
      partnerLogo,
      favicon,
      plan,
    ),
  );
  dispatch(hideLoader());
};

export const startLogin = (username, password) => async (dispatch) => {
  try {
    dispatch(showLoader('Authenticating'));
    const loginResult = await Parse.Cloud.run('loginUser', {
      username,
      password,
      source: 'website',
    });
    if (loginResult.type !== 'success') {
      throw new Parse.Error(
        500,
        loginResult.message || 'Error logging in: Unknown',
      );
    }

    const session = loginResult.result;
    await loginUserWithSessionToken(session.getSessionToken(), dispatch);
  } catch (error) {
    dispatch(hideLoader());
    dispatch(handleError(error, true));
  }
};

export const startLoginWithSessionId = (sessionId, key) => async (dispatch) => {
  try {
    dispatch(showLoader('Authenticating'));
    const { sessionToken } = await Parse.Cloud.run('exchange_temp_session', {
      sessionId,
      key,
    });
    await loginUserWithSessionToken(sessionToken, dispatch);
  } catch (error) {
    dispatch(hideLoader());
    // dispatch(handleError(error, true));
  }
};

const configureFive9 = (props) => {
  const { company } = props;
  const userFields = {
    company: {
      show: false,
      label: 'Company Name',
      value: company.get('name'),
    },
    siteName: {
      show: false,
      label: 'Site Name',
      value: 'webApp',
    },
    companyId: {
      show: false,
      label: 'Company Id',
      value: company.id,
    },
    'Chat.CompanyId': {
      show: false,
      label: 'Company Id',
      value: company.id,
    },
  };

  if (window.Five9SocialWidget && window.Five9SocialWidget.options) {
    const { fields = {} } = window.Five9SocialWidget.options;

    window.Five9SocialWidget.options.fields = {
      ...fields,
      ...userFields,
    };
  }
};

const pendoInitialize = async ({ user, company, plan, selectedOffice }) => {
  const activeIntegrations = [];
  if (company.get('hoverEnabled')) {
    activeIntegrations.push('hoverEnabled');
  }
  if (company.get('spreedlyEnabled')) {
    activeIntegrations.push('spreedlyEnabled');
  }
  if (company.get('docusignEnabled')) {
    activeIntegrations.push('docuSignEnabled');
  }
  if (company.get('companyCamEnabled')) {
    activeIntegrations.push('companyCamEnabled');
  }
  if (company.get('logsEnabled')) {
    activeIntegrations.push('logsEnabled');
  }
  if (company.get('stripeEnabled')) {
    activeIntegrations.push('stripeEnabled');
  }
  if (company.get('zapierEnabled')) {
    activeIntegrations.push('zapierEnabled');
  }
  if (company.get('eagleViewEnabled')) {
    activeIntegrations.push('eagleViewEnabled');
  }
  const qry = new Parse.Query('_Role').equalTo('users', {
    __type: 'Pointer',
    className: '_User',
    objectId: user.id,
  });
  const userParsePermissions = await qry.map((x) => x.get('title'));
  const userPermissions = userParsePermissions.filter((x) => x);
  // eslint-disable-next-line no-undef
  pendo.initialize({
    visitor: {
      schemaVersion: 1,
      id: user.id || '',
      userFullName: `${user.get('nameFirst')} ${user.get('nameLast')}`,
      userEmail: user.get('email') || '',
      userPermissions: userPermissions.join(',') || '',
      username: user.get('username') || '',
      userOfficeId: selectedOffice.id || '',
      userOfficeName: selectedOffice.get('name') || '',
      isUserManager: user.get('isManager') || false,
      userSettings: JSON.stringify(user.get('userSettings')) || '',
      userCanSubmitCreditApps: user.get('canSubmitCreditApps') || false,
      userCreationDate: user.get('createdAt') || '',
    },
    account: {
      schemaVersion: 1,
      id: company.id || '',
      accountName: company.get('name') || '',
      accountPlan: plan.get('name') || '',
      accountSeats: company.get('maxActiveUsers') || 0,
      accountCreationDate: company.get('createdAt') || '',
      accountActiveIntegrations: activeIntegrations?.join(', ') || '',
      accountApiAccess: company.get('apiAccess') || false,
    },
  });
};

export const startBecomeUser = (user) => async (dispatch) => {
  try {
    dispatch(showLoader('Loading User Profile'));
    const { selectedOffice, allOffices } = await startFetchOffices();
    const company = await user.get('company');
    const plan = await company.getPlan();
    dispatch(PlanActions.setPlanConfig(plan));
    dispatch(
      setAllMainMenuOptions(getMainMenuOptionsFromAllOffices(allOffices)),
    );
    const config = await selectedOffice.get('config').fetch();
    const isCorporateAdmin = await Parse.Cloud.run('isCorporateAdmin', {
      objectId: user.id,
    });
    const partnerLogo = await Parse.Cloud.run('fetch_logo_for_user');
    const loginUrl = await Parse.Cloud.run('fetch_login_url_for_user');
    await setPartnerTag(loginUrl);
    const favicon = await Parse.Cloud.run('fetch_favicon_for_user');

    const userActiveColumns = user.get('activeColumns') || defaultActiveColumns;
    dispatch(setActiveColumns(userActiveColumns));

    configureFive9({ company });
    pendoInitialize({ user, company, plan, selectedOffice });

    await dispatch(startFetchStyles());
    dispatch(
      login(
        user,
        selectedOffice,
        allOffices,
        config,
        isCorporateAdmin,
        partnerLogo,
        favicon,
        plan,
      ),
    );
    dispatch(hideLoader());
  } catch (e) {
    dispatch(hideLoader());
    dispatch(handleError(e));
  }
};

export const selectedOffice = (office, config) => ({
  type: 'SELECTED_OFFICE',
  selectedOffice: office,
  config,
});

export const updateConfigState = (updates) => ({
  type: 'UPDATE_CONFIG_STATE',
  updates,
});

export const startChangeOffice = (officeId) => async (dispatch, getState) => {
  try {
    await setSelectedOffice(officeId);

    const office = getState().auth.offices.filter(
      (object) => object.id === officeId,
    )[0];
    const config = await office.get('config').fetch();
    dispatch(selectedOffice(office, config));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const updateCustomConfigMainMenuOptions = (updates) => async (
  dispatch,
  getState,
) => {
  try {
    await Parse.Cloud.run('updateCustomConfigMainMenuOptions', updates);

    dispatch(
      showSuccessAlert({
        title: 'Success',
        message: 'Settings saved successfully!',
        onConfirm: () => {
          dispatch(hideAlert());
        },
      }),
    );
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const startUpdateConfig = (updates) => async (dispatch, getState) => {
  try {
    const configId = getState().auth.config.objectId;
    const config = new Parse.Object('CustomConfig');
    config.id = configId;
    const newUpdates = { ...updates };
    delete newUpdates.errors;
    await config.save(updates);
    const office = getState().auth.selectedOffice;

    dispatch(
      pushToDataLayer({
        event: 'settingsPageTitleButtonEvent',
        eventCategory: 'Settings Page',
        eventAction: window.location.pathname,
        eventLabel: 'Save',
      }),
    );

    dispatch(selectedOffice(office, config));
    dispatch(
      showSuccessAlert({
        title: 'Success',
        message: 'Settings saved successfully!',
        onConfirm: () => {
          dispatch(hideAlert());
        },
      }),
    );
  } catch (e) {
    const { auth } = getState();
    const configId = auth.config.objectId;
    const config = new Parse.Object('CustomConfig');
    config.id = configId;
    config.revert();
    const office = auth.selectedOffice;
    dispatch(selectedOffice(office, config));
    dispatch(handleError(e));
  }
};

export const startRefreshConfig = (callback) => async (dispatch, getState) => {
  try {
    const configId = getState().auth.config.objectId;
    const config = new Parse.Object('CustomConfig');
    config.id = configId;
    await config.fetch();
    const office = getState().auth.selectedOffice;
    await dispatch(selectedOffice(office, config));
    if (callback) {
      callback(config);
    }
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const setAPIKey = (apiKey) => ({
  type: 'SET_API_KEY',
  apiKey,
});

export const startGenerateNewAPIKey = () => async (dispatch) => {
  try {
    const apiKey = await Parse.Cloud.run('newAPIKey');
    dispatch(setAPIKey(apiKey));
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const setGroupMeGroups = (groups) => ({
  type: 'SET_GROUP_ME_GROUPS',
  groups,
});

export const startDownloadGroupMeBots = () => async (dispatch, getState) => {
  try {
    const { auth } = getState();
    const { config } = auth;
    const { groupMe = {} } = config;
    if (groupMe.token) {
      const results = await Parse.Cloud.run('groupMeListGroups', {
        officeId: auth.selectedOffice.id,
      });
      dispatch(setGroupMeGroups(results));
    } else {
      dispatch(setGroupMeGroups([]));
    }
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const startUpdateGroupMe = (params) => async (dispatch, getState) => {
  try {
    const { auth } = getState();
    let groupMe = {};
    groupMe.enabled = params.enabled;
    groupMe.token = params.token;
    if (params.enabled && params.groupId) {
      groupMe = await Parse.Cloud.run('groupMeCreateBot', {
        officeId: auth.selectedOffice.id,
        groupId: params.groupId,
        token: params.token,
      });
    }
    groupMe.sale = params.sale;
    groupMe.noSale = params.noSale;
    dispatch(startUpdateConfig({ groupMe }));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const startSetupFori360 = (callback) => async (dispatch, getState) => {
  try {
    const { auth = {} } = getState();
    const { selectedOffice: office } = auth;
    const result = await Parse.Cloud.run('setupFori360', {
      officeId: office.id,
    });
    await dispatch(startRefreshConfig());
    if (callback) {
      callback(result);
    }
  } catch (e) {
    dispatch(handleError(e));
  }
};

const copySettingsByKey = async (destinationConfig, sourceConfig, key) => {
  switch (key) {
    case 'provia': {
      const provia = sourceConfig.get(key);
      const { password } = await decryptString(provia.password);
      destinationConfig.set(key, { ...provia, password });
      break;
    }
    default:
      destinationConfig.set(key, sourceConfig.get(key));
      break;
  }
};

export const startCopySettings = (fromOfficeId, keys, callback) => async (
  dispatch,
  getState,
) => {
  const { auth } = getState();
  const destinationConfig = auth.selectedOffice.get('config');
  try {
    dispatch(
      pushToDataLayer({
        event: 'settingsPageTitleButtonEvent',
        eventCategory: 'Settings Page',
        eventAction: window.location.pathname,
        eventLabel: 'Copy',
      }),
    );

    const sourceOffice = new Parse.Object('Office');
    sourceOffice.id = fromOfficeId;
    await sourceOffice.fetch();
    const sourceConfig = sourceOffice.get('config');
    await sourceConfig.fetch();
    const settingsUpdates = [];
    keys.forEach((key) => {
      settingsUpdates.push(
        copySettingsByKey(destinationConfig, sourceConfig, key),
      );
    });
    await Promise.all(settingsUpdates);
    await destinationConfig.save();
    await dispatch(selectedOffice(auth.selectedOffice, destinationConfig));
    callback(destinationConfig);
  } catch (e) {
    destinationConfig.revert();
    dispatch(handleError(e));
  }
};

export const getUserFeatureFlags = async () => {
  try {
    const result = await Parse.Cloud.run('getUserFeatureFlags');
    return result;
  } catch (e) {
    throw new Parse.Error(400, e);
  }
};

export const startResetPassword = (email, callback) => async (dispatch) => {
  try {
    const result = await Parse.User.requestPasswordReset(email);
    callback(result);
  } catch (e) {
    dispatch(handleError(e));
  }
};

const modifyUpdateParseCustomMenuItem = (mainMenuOptions, menuItems) => {
  const updatedMainMenuOptions = mainMenuOptions.map((mainMenuItem, index) => {
    const matchingMenuItem = menuItems.find(
      (menuItem) => menuItem.objectId === mainMenuItem.objectId,
    );
    if (matchingMenuItem) {
      return { ...mainMenuItem, order: index };
    }
    return mainMenuItem;
  });

  const updatedMenuItems = menuItems.map((menuItem, index) => {
    if (menuItem.objectId) {
      const matchingMainMenuOption = updatedMainMenuOptions.find(
        (option) => option.objectId === menuItem.objectId,
      );
      const updatedItem = { ...menuItem };
      updatedItem.key = matchingMainMenuOption?.title?.toLowerCase();
      updatedItem.title = matchingMainMenuOption?.title || '';
      updatedItem.order = index;
      return updatedItem;
    }
    return menuItem;
  });

  const filteredItems = updatedMenuItems.filter((item) => {
    return item.title && item.title.trim() !== '';
  });
  menuItems.splice(0, menuItems.length, ...filteredItems);
};

const insertNewMenuOptions = (
  mainMenuOptions,
  objectIdMenuItems,
  menuItems,
) => {
  const newMainMenuOptions = mainMenuOptions.filter(
    (item) => !objectIdMenuItems?.some((obj) => obj.objectId === item.objectId),
  );

  newMainMenuOptions.forEach((newItem) => {
    const convertedItem = {
      ...newItem,
      id: newItem.objectId || '',
      key: '',
      title: '',
    };

    const firstMenuItemIndex = menuItems.findIndex(
      (item) => 'objectId' in item,
    );
    if (firstMenuItemIndex !== -1) {
      menuItems.splice(firstMenuItemIndex, 0, convertedItem);
    } else {
      menuItems.push(convertedItem);
    }
  });
};

export const fetchCustomizedMenuByOfficeId = (officeId) => async (
  dispatch,
  getState,
) => {
  try {
    // Query the CustomMenuItem collection based on the officeId
    const CustomMenuItem = new Parse.Object('CustomMenuItem');
    const query = new Parse.Query(CustomMenuItem);
    query.equalTo('office', {
      __type: 'Pointer',
      className: 'Office',
      objectId: officeId,
    });

    const results = await query.first();
    if (results) {
      const { config } = getState().auth;
      const { mainMenuOptions } = config;
      const menuItems = results.get('menuItems') || [];
      const objectIdMenuItems = menuItems?.filter((item) => item.objectId);
      insertNewMenuOptions(mainMenuOptions, objectIdMenuItems, menuItems);
      modifyUpdateParseCustomMenuItem(mainMenuOptions, menuItems);
      dispatch({ type: 'SET_CUSTOMIZED_MENU', customizedMenuItems: menuItems });
    } else {
      const { config } = getState().auth;
      const { appTitles, mainMenuOptions } = config;
      const flag = await getUserFeatureFlags();

      const convertedAppTitles = appTitles
        ? Object.keys(appTitles || {}).map((key) => ({
            id: appTitles[key] || randomKey(6),
            key,
            title: appTitles[key] || '',
          }))
        : [];

      const modifiedMainMenuOptions =
        mainMenuOptions &&
        mainMenuOptions.length &&
        mainMenuOptions.map((option) => ({
          id: randomKey(6),
          key: option.title,
          ...option,
        }));

      convertedAppTitles.unshift({
        id: 'CUSTOMERS',
        key: 'customers',
        title: 'CUSTOMERS',
      });

      const contractsIndex = convertedAppTitles.findIndex(
        (item) => item.key === 'contracts',
      );
      const settingsIndex = convertedAppTitles.findIndex(
        (item) => item.id === 'SETTINGS',
      );

      if (flag?.tasks?.enabled) {
        const taskTitle = {
          id: 'TASKS',
          key: 'tasks',
          title: 'TASKS',
        };
        if (settingsIndex !== -1) {
          convertedAppTitles.splice(settingsIndex, 0, taskTitle);
        }
      }

      if (modifiedMainMenuOptions && modifiedMainMenuOptions.length) {
        convertedAppTitles.splice(
          contractsIndex + 1,
          0,
          ...modifiedMainMenuOptions,
        );
      }

      const parseCustomMenuItem = new Parse.Object('CustomMenuItem');
      parseCustomMenuItem.set('menuItems', convertedAppTitles);
      const office = new Parse.Object('Office');
      office.id = officeId;
      parseCustomMenuItem.set('office', office);
      await parseCustomMenuItem.save();

      dispatch({
        type: 'SET_CUSTOMIZED_MENU',
        customizedMenuItems: convertedAppTitles,
      });
    }
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const updateOfficeAppTitles = (officeId, menuItem) => async (
  dispatch,
) => {
  const parseCustomMenuItem = new Parse.Object('CustomMenuItem');
  parseCustomMenuItem.set('menuItems', menuItem);

  const office = new Parse.Object('Office');
  office.id = officeId;

  parseCustomMenuItem.set('office', office);

  try {
    await parseCustomMenuItem.save();

    dispatch(
      showSuccessAlert({
        title: 'Success',
        message: 'Settings saved successfully!',
        onConfirm: () => {
          dispatch(hideAlert());
        },
      }),
    );
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const startCopyFromResources = () => async (dispatch, getState) => {
  try {
    const officeId = getState().auth.selectedOffice.id;
    await Parse.Cloud.run('convertResourcesToEmailLinks', { officeId });
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const setQuery = (query) => ({
  type: 'SET_QUERY',
  query,
});

export const removeQueryParameter = (key) => async (dispatch, getState) => {
  const { query } = getState().auth;
  if (query && query[key]) {
    delete query[key];
    dispatch(setQuery(query));
  }
};

export const startChangePassword = (username, password, newPassword) => async (
  dispatch,
) => {
  try {
    const { result } = await Parse.Cloud.run('loginUser', {
      username,
      password,
      source: 'website',
    });
    const user = await Parse.User.become(result.getSessionToken());
    user.setPassword(newPassword);
    user.set('needsResetPassword', false);
    await user.save();
    await Parse.User.logOut();
  } catch (e) {
    dispatch(handleError(e));
  }
};
