/* eslint-disable import/no-cycle */
import Parse from 'parse';
import { handleError } from './auth';
import { showSuccessAlert, hideAlert } from './alert';

export const setAvailableContacts = ({ role, activeUserIds }) => ({
  type: 'SET_AVAILABLE_CONTACTS_ROLE',
  role,
  activeUserIds,
});

export const startFetchUsers = async (query, count, objects) => {
  query.skip(objects.length);
  const moreObjects = await query.find();
  const updatedArray = [...objects, ...moreObjects];
  if (updatedArray.length < count) {
    return startFetchUsers(query, count, updatedArray);
  }
  return updatedArray;
};

export const startFetchAvailableContacts = () => async (dispatch) => {
  try {
    const query = new Parse.Query(Parse.Role);
    query.select(['users', 'roles']);
    query.equalTo('availableContacts', true);
    let role = await query.first();
    if (!role) {
      role = new Parse.Role();
    }
    const usersQuery = role.getUsers().query();
    usersQuery.equalTo('isActive', true);
    const userCount = await usersQuery.count();
    const users = await startFetchUsers(usersQuery, userCount, []);
    const activeUserIds = users.map(({ id }) => id);
    dispatch(setAvailableContacts({ role, activeUserIds }));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const startSaveAvailableContacts = ({
  role,
  addedUserIds = [],
}) => async (dispatch, getState) => {
  try {
    const { users: usersState } = getState();
    const { items } = usersState;
    role.set('type', 'Group');
    if (!role.has('name')) {
      role.set('name', 'Available_Contacts');
    }
    if (role.id) {
      const userObjects = items.map(({ objectId }) => {
        const user = new Parse.User();
        user.id = objectId;
        return user;
      });
      if (userObjects) {
        role.getUsers().remove(userObjects);
      }
    }
    const addedUserObjects = addedUserIds.map((userId) => {
      const user = new Parse.User();
      user.id = userId;
      return user;
    });
    if (addedUserObjects.length) {
      role.getUsers().add(addedUserObjects);
    }
    await role.save();
    dispatch(setAvailableContacts({ role, activeUserIds: addedUserIds }));
    dispatch(
      showSuccessAlert({
        title: 'Success!',
        message: 'Available Contacts updated successfully',
        onConfirm: () => dispatch(hideAlert()),
      }),
    );
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const resetAvailableContacts = () => ({
  type: 'RESET_AVAILABLE_CONTACTS',
});
