/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import Parse from 'parse';

async function getDeletedSnapShotQuery(props) {
  const { type, limit, page } = props;
  const snapShotQuery = new Parse.Query('ContractObjectSnapshot');
  snapShotQuery.equalTo('type', type);
  snapShotQuery.descending('createdAt');
  snapShotQuery.select(['createdBy', 'createdAt', 'objectId', 'displayName']);
  snapShotQuery.include(['createdBy']);
  snapShotQuery.equalTo('isDeleted', true);
  snapShotQuery.limit(limit);
  const skip = (page - 1) * limit;
  snapShotQuery.skip(skip);
  const results = await snapShotQuery.find();
  return results;
}

async function getDeletedDocuments(props) {
  const { setDeletedDocuments, type, limit, page } = props;
  const snapShots = await getDeletedSnapShotQuery({ type, limit, page });
  const snapShotData = snapShots.map((snapShot) => {
    const createdBy = snapShot.get('createdBy');
    const createdAt = snapShot.get('createdAt');
    const displayName = snapShot.get('displayName');
    const firstName = createdBy ? createdBy.get('nameFirst') : 'unknown';
    const lastName = createdBy ? createdBy.get('nameLast') : '';
    return {
      id: snapShot.id,
      displayName,
      user: {
        firstName,
        lastName,
      },
      createdAt,
    };
  });
  setDeletedDocuments(snapShotData);
}

export default function useGetDeletedDocuments(props) {
  const { limit, page } = props;

  useEffect(() => {
    getDeletedDocuments(props);
  }, [limit, page]);
}
