import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const titleForItem = (isFolder, name, onClick) => {
  if (isFolder) {
    return (
      <Button type="button" variant="light" onClick={() => onClick(name)}>
        {name}
      </Button>
    );
  }
  return <p> {name}</p>;
};

const ResourceCell = ({
  objectId,
  thumbnailURL,
  fileURL,
  name,
  onDelete,
  isFolder,
  onFolderClick,
}) => {
  const [imgError, setImgError] = useState(false);

  const handleImgError = () => {
    setImgError(true);
  };

  return (
    <div className="mask no-caption resources-cell card">
      <div className="thumbnail-container">
        <div className="img-thumbnail view view-first">
          <img
            className={`m-auto h-100 ${imgError ? 'd-none' : 'd-block'}`}
            src={thumbnailURL}
            onError={handleImgError}
            alt="Thumbnail"
          />
          <div
            style={{ fontSize: '7rem' }}
            className={`h-100 w-100 ${imgError ? 'd-block' : 'd-none'}`}
          >
            <i className="fas fa-camera-retro text-muted" />
          </div>
          <div className="mask no-caption">
            <div className="tools tools-bottom">
              <Link to={`/resources/edit/${objectId}`} id="editButton">
                <i className="fas fa-pencil-alt" />
              </Link>
              {fileURL && (
                <a
                  href={fileURL}
                  target="_blank"
                  id="viewButton"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-paperclip" aria-hidden="true" />
                </a>
              )}
              <button type="button" onClick={onDelete} id="deleteButton">
                <i className="fas fa-trash-alt" />
              </button>
            </div>
          </div>
        </div>
        <div className="caption">
          {titleForItem(isFolder, name, onFolderClick)}
        </div>
      </div>
    </div>
  );
};

ResourceCell.propTypes = {
  objectId: PropTypes.string.isRequired,
  thumbnailURL: PropTypes.string.isRequired,
  fileURL: PropTypes.string,
  name: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  isFolder: PropTypes.bool,
  onFolderClick: PropTypes.func,
};

ResourceCell.defaultProps = {
  isFolder: false,
  fileURL: undefined,
  onFolderClick: () => {},
};

export default ResourceCell;
