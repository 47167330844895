import React from 'react';
import ReactDOM from 'react-dom';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import './styles/styles.scss';
import App from './app';
import store from './store/configureStore';
import configureParse from './configureParse';
import * as serviceWorker from './serviceWorker';

configureParse(store);

const LDProvider = withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
  context: {
    kind: 'user',
    anonymous: true,
  },
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
})(App);

ReactDOM.render(<LDProvider />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
