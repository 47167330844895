/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import MultiSelectComponent from '@khanacademy/react-multi-select';

class GrayDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSelectedChanged = (event) => {
    this.props.onSelectedChanged(event);
  };

  render() {
    const overRides = {
      selectSomeItems: this.props.placeholder,
      allItemsAreSelected:
        this.props.options.length === 1 && this.props.selected.length === 1
          ? (this.props.options[0] || {}).label
          : undefined,
    };
    return (
      <MultiSelectComponent
        overrideStrings={overRides}
        selected={this.props.selected || []}
        onSelectedChanged={this.onSelectedChanged}
        options={this.props.options}
        hasSelectAll={this.props.hasSelectAll}
        disableSearch={this.props.disableSearch}
      />
    );
  }
}

export default GrayDropDown;
