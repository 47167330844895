const collectCrumbsFromPath = (props) => {
  const { match } = props;

  const { url } = match;

  const urlSplit = url.split('/').filter((segment) => segment);

  const [, type] = urlSplit;

  const crumbs = urlSplit.reduce((collected, segment) => {
    const collectedPath = collected.map((seg) => seg.titleSegment).join('/');

    const link = collectedPath.length
      ? `/${collectedPath}/${segment}`
      : `${collectedPath}/${segment}/${type}`;

    const crumb = {
      title: collected.length === 1 ? 'Contract Templates' : segment,
      titleSegment: segment,
      link:
        segment === 'template_group'
          ? link.replace('template_group', 'template_groups')
          : link,
    };

    if (crumb.title === 'template_groups' || crumb.title === 'template_group') {
      crumb.title = 'From Template';
    }

    return [...collected, crumb];
  }, []);

  const [, ...navCrumbs] = crumbs;

  return navCrumbs;
};

export default collectCrumbsFromPath;
