export const defaultState = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'ON_PRICE_GUIDE_SCROLL': {
      const { scrollEventData } = action;
      return {
        ...state,
        scrollEventData,
      };
    }
    default:
      return state;
  }
};
