import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import routePermissions from '../router/routePermissions';

const useIsAuthorized = () => {
  // Get current locaiton path (relative path) from router path
  const { pathname } = useLocation();
  // Get the allowed route keys from store
  const allowedRouteKeys = useSelector(({ plan: { allowedRoutes = [] } }) => {
    return allowedRoutes;
  });

  // If this is a specially permissioned route, check against the user's
  // allowed routes, otherwise return true since all users have access
  if (routePermissions[pathname]) {
    // Using the routePermissions object map, transform the array of allowed
    // route keys into an array of Regular Expressions that defined the allowed routes
    const allowedRouteExpressions = allowedRouteKeys.map(
      (routeKey) => routePermissions[routeKey],
    );

    // Check if the path is allowed by itterating through the allowed route
    // regular expressions and testing the current path
    const match = allowedRouteExpressions.some((allowedRoute) =>
      allowedRoute.test(pathname),
    );

    return match;
  }

  return true;
};

export default useIsAuthorized;
