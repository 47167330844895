export default (state = { show: false, message: 'loading' }, action) => {
  switch (action.type) {
    case 'SHOW_LOADER':
      return {
        ...state,
        show: true,
        message: action.message || 'loading',
      };
    case 'HIDE_LOADER':
      return {
        ...state,
        show: false,
      };
    default:
      return state;
  }
};
