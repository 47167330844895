/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { TextGroup, TagGroup } from '../../../FormGroup';
import Panel from '../../../Panel';

const SalesforceSection = (props) => (
  <Panel title="Salesforce">
    <div>
      <TextGroup
        title="File Name"
        value={props.value.fileName}
        placeholder="File Name"
        onChange={(value) =>
          props.onChange({
            key: 'salesforce',
            value: {
              ...props.value,
              fileName: value,
            },
          })
        }
        errorMessage={props.errors.salesforce.fileName}
      />
      <TagGroup
        title="Backup Recipients"
        values={props.value.backupRecipients}
        placeholder="Add Email Addresses"
        onChange={(value) =>
          props.onChange({
            key: 'salesforce',
            value: {
              ...props.value,
              backupRecipients: value,
            },
          })
        }
        errorMessage={props.errors.salesforce.backupRecipients}
      />
      {props.isResults && (
        <div>
          <TextGroup
            title="Note Title"
            value={props.value.resultTitle}
            placeholder="Note Title"
            onChange={(value) =>
              props.onChange({
                key: 'salesforce',
                value: {
                  ...props.value,
                  resultTitle: value,
                },
              })
            }
            errorMessage={props.errors.salesforce.resultTitle}
          />
          <TextGroup
            title="Object Class Name"
            value={props.value.objectClass}
            placeholder="Object Class Name"
            onChange={(value) =>
              props.onChange({
                key: 'salesforce',
                value: {
                  ...props.value,
                  objectClass: value,
                },
              })
            }
            errorMessage={props.errors.salesforce.objectClass}
          />
        </div>
      )}
    </div>
  </Panel>
);

SalesforceSection.propTypes = {
  value: PropTypes.shape({
    fileName: PropTypes.string,
    backupRecipients: PropTypes.arrayOf(PropTypes.string),
    resultTitle: PropTypes.string,
    objectClass: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    salesforce: PropTypes.shape({
      fileName: PropTypes.string,
      backupRecipients: PropTypes.string,
      resultTitle: PropTypes.string,
      objectClass: PropTypes.string,
    }).isRequired,
  }).isRequired,
  isResults: PropTypes.bool,
};

SalesforceSection.defaultProps = {
  isResults: false,
};

export default SalesforceSection;
