/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import FormulaPart from './FormulaPart';

const CenteredHorizontalScrollGroup = ({ children }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      flexWrap: 'nowrap',
      position: 'relative',
    }}
  >
    <div
      style={{
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        position: 'relative',
        overflowX: 'scroll',
        paddingTop: 10,
        paddingBottom: 10,
      }}
    >
      {children}
    </div>
  </div>
);

CenteredHorizontalScrollGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

class FormulaDisplayRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  getPlaceHoldBySymbol = (symbol, placeholders) => {
    const [placeholder] = placeholders.filter((pl) => pl.symbol === symbol);

    if (placeholder) {
      return placeholder;
    }
    return {};
  };

  rowData = () => {
    const { placeholders, getFormula } = this.props;
    let formula = getFormula();
    placeholders().forEach((pl) => {
      formula = formula.split(pl.symbol).join(`,${pl.symbol},`);
    });
    const displaySections = formula.split(',');
    return this.formulaRow(displaySections, placeholders());
  };

  formulaRow = (displaySections) =>
    displaySections.map((section) => {
      const placeholder = this.getPlaceHoldBySymbol(
        section,
        this.props.placeholders(),
      );
      if (section.includes('[')) {
        return {
          symbol: section,
          value: placeholder.value,
          color: placeholder.color,
        };
      }
      return section;
    });

  render() {
    return (
      <CenteredHorizontalScrollGroup>
        {this.rowData().map((section, i) => (
          <FormulaPart key={`${section}_${i}`} {...{ section }} />
        ))}
      </CenteredHorizontalScrollGroup>
    );
  }
}

FormulaDisplayRow.propTypes = {
  getFormula: PropTypes.func.isRequired,
  placeholders: PropTypes.func.isRequired,
};

FormulaDisplayRow.defaultProps = {};

export default FormulaDisplayRow;
