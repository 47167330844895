import React from 'react';
import PropTypes from 'prop-types';
import { Router } from 'react-router-dom';
import { createBrowserHistory as createHistory } from 'history';
import { historyPropType, locationPropType, matchPropType } from './propTypes';

export { default as CompanyRoute } from './CompanyRoute';
export { default as AuthorizedRoute } from './AuthorizedRoute';
// Function that generates arrays of of components that implement Route
export { default as routes } from './routes';
// Prop types for props routers pass to children
export { historyPropType, locationPropType, matchPropType };
// Combined prop types for router props, destructure object in component's
// propType declarion
export const routePropTypes = {
  history: historyPropType.isRequired,
  location: locationPropType.isRequired,
  match: matchPropType.isRequired,
};

// Create the history object for the router to use
export const history = createHistory();

const LeapRouter = ({ children }) => {
  return <Router history={history}>{children}</Router>;
};

LeapRouter.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LeapRouter;
