/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Table } from 'react-bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import {
  queryPackages,
  startDeletePackage,
  setPackages,
  setPackageCategory,
  startUpdatePackages,
  setIncludedOfficesFilter,
} from '../../actions/packages';
import { history } from '../../router';
import { setOnDragEndCallback } from '../DropContext';
import FormGroup, { DropDownGroup } from '../FormGroup';
import Panel from '../Panel';
import OfficesDropDown from '../IncludedOfficesDropDown';
import TitleButton from '../TitleButton';
import { reorder } from '../../utils/utils';

const packagesDropableSectionId = 'packagesDropabelSection';

export class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      packageToDelete: undefined,
    };
    setOnDragEndCallback(packagesDropableSectionId, (result) => {
      // dropped outside the list
      const { destination = {} } = result;
      if (destination === null) {
        return;
      }
      const { droppableId } = destination;
      if (droppableId !== packagesDropableSectionId) {
        return;
      }
      const updates = reorder(
        this.props.items,
        result.source.index,
        result.destination.index,
      ).map((pack, i) => ({
        ...pack,
        orderNumber: i + 1,
      }));
      this.props.setPackages(updates);
    });
  }

  componentDidMount() {
    this.queryPackages({});
    this.renderButtons();
  }

  componentDidUpdate(prevProps) {
    const { categories } = this.props;
    if (!_.isEqual(prevProps.categories, categories)) {
      this.renderButtons();
    }
  }

  onAddClicked = () => {
    if (!this.props.selectedCategory) {
      const firstCategory = this.props.categories[0].value;
      this.props.setPackageCategory(firstCategory);
    }
    history.push('/packages/new');
  };

  onSaveClicked = () => {
    this.props.startUpdatePackages({ updates: this.props.items });
  };

  onDelete = (objectId) => {
    this.setState({
      packageToDelete: objectId,
    });
  };

  onCategoryChanged = ({ value }) => {
    this.props.setPackageCategory(value);
    this.queryPackages({ selectedCategory: value });
  };

  onIncludedOfficesChanged = (offices) => {
    this.props.setIncludedOfficesFilter(offices);
    this.queryPackages({ offices });
  };

  onDeleteConfirm = async () => {
    const objectId = this.state.packageToDelete;
    this.setState({
      packageToDelete: undefined,
    });
    this.props.startDeletePackage(objectId);
  };

  onDeleteCancel = () => {
    this.setState({
      packageToDelete: undefined,
    });
  };

  queryPackages = ({ selectedCategory, offices }) => {
    const { categories = [] } = this.props;
    const defaultCategory = categories.length ? categories[0].value : '';
    const category =
      selectedCategory || this.props.selectedCategory || defaultCategory;
    const includedOffices = offices || this.props.includedOffices;
    this.props.queryPackages({ includedOffices, category });
  };

  renderButtons() {
    this.props.setButtons(
      <TitleButtons
        onAddClicked={() => this.onAddClicked()}
        onSaveClicked={() => this.onSaveClicked()}
        disabled={this.props.categories.length === 0}
      />,
    );
  }

  render() {
    return (
      <>
        {!!this.state.packageToDelete && (
          <SweetAlert
            warning
            showCancel
            title="Delete Package"
            confirmBtnText="Yes"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            onConfirm={this.onDeleteConfirm}
            onCancel={this.onDeleteCancel}
          >
            Are you sure you want to delete this package?
          </SweetAlert>
        )}
        <div className="default-page-padding">
          <DropDownGroup
            title="Category"
            value={this.props.selectedCategory}
            onChange={this.onCategoryChanged}
            closeMenuOnSelect
            options={this.props.categories}
            isClearable={false}
          />
          {this.props.maxOfficeCount !== 1 && (
            <FormGroup title="Included Offices">
              <OfficesDropDown
                onChange={this.onIncludedOfficesChanged}
                selected={this.props.includedOffices}
              />
            </FormGroup>
          )}
          <Panel title="">
            <div>
              <Table striped bordered hover style={{ tableLayout: 'fixed' }}>
                <thead>
                  <tr>
                    <th className="package__thumbnail">Image</th>
                    <th className="package__name">Name</th>
                    <th style={{ width: 75 }} className="package__action">
                      Action
                    </th>
                  </tr>
                </thead>
                <Droppable
                  droppableId={packagesDropableSectionId}
                  direction="vertical"
                  type={packagesDropableSectionId}
                >
                  {(provided) => (
                    <tbody
                      style={{ position: 'relative', width: '100% !important' }}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {this.props.items.map(
                        ({ name, thumbnail, objectId }, index) => (
                          <Draggable
                            key={objectId}
                            draggableId={objectId}
                            index={index}
                            type={packagesDropableSectionId}
                          >
                            {(draggableProvided, snapshot) => (
                              <tr
                                style={{
                                  position: 'relative',
                                  width: '100% !important',
                                }}
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                                {...draggableProvided.dragHandleProps}
                                key={objectId}
                              >
                                <td
                                  style={{
                                    backgroundColor: snapshot.isDragging
                                      ? 'white'
                                      : 'transparent',
                                    width: '20%',
                                  }}
                                  className="package__thumbnail"
                                >
                                  <div>
                                    <img
                                      alt="thumbnail"
                                      src={
                                        thumbnail
                                          ? thumbnail.url
                                          : '/images/no_image.png'
                                      }
                                    />
                                  </div>
                                </td>
                                <td
                                  style={{
                                    backgroundColor: snapshot.isDragging
                                      ? 'white'
                                      : 'transparent',
                                    width: '80%',
                                  }}
                                  className="package__name"
                                >
                                  <div>{name}</div>
                                </td>
                                <td
                                  style={{
                                    position: 'relative',
                                    backgroundColor: snapshot.isDragging
                                      ? 'white'
                                      : 'transparent',
                                    width: 75,
                                  }}
                                >
                                  <div
                                    style={{
                                      width: '100%',
                                      height: 75,
                                      display: 'flex',
                                      justifyContent: 'space-around',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Link to={`/packages/edit/${objectId}`}>
                                      <button
                                        type="button"
                                        style={{
                                          border: 0,
                                          backgroundColor: 'transparent',
                                        }}
                                      >
                                        <i className="fas fa-pencil-alt" />
                                      </button>
                                    </Link>
                                    <button
                                      style={{
                                        border: 0,
                                        backgroundColor: 'transparent',
                                      }}
                                      className="danger"
                                      onClick={() => this.onDelete(objectId)}
                                    >
                                      <i className="far fa-trash-alt" />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </Draggable>
                        ),
                      )}
                      {provided.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </Table>
            </div>
          </Panel>
        </div>
      </>
    );
  }
}

Index.propTypes = {
  maxOfficeCount: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      objectId: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      thumbnail: PropTypes.shape({
        url: PropTypes.isRequired,
      }),
    }),
  ).isRequired,
  queryPackages: PropTypes.func.isRequired,
  setPackageCategory: PropTypes.func.isRequired,
  startDeletePackage: PropTypes.func.isRequired,
  selectedCategory: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  includedOffices: PropTypes.arrayOf(PropTypes.string),
  setIncludedOfficesFilter: PropTypes.func.isRequired,
  startUpdatePackages: PropTypes.func.isRequired,
  setPackages: PropTypes.func.isRequired,
  ...layoutContextPropTypes,
};

Index.defaultProps = {
  includedOffices: [],
};

const TitleButtons = ({ onAddClicked, onSaveClicked, disabled }) => (
  <>
    <TitleButton
      variant="success"
      onClick={onAddClicked}
      title="New"
      disabled={disabled}
    />
    <TitleButton
      variant="success"
      onClick={onSaveClicked}
      title="Save"
      disabled={disabled}
    />
  </>
);

TitleButtons.propTypes = {
  onSaveClicked: PropTypes.func.isRequired,
  onAddClicked: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

const mapStateToProps = ({
  packages: { items = [], category = '', includedOffices = [] } = {},
  auth: { config: { categories_ = [] } = {}, selectedOffice = {} } = {},
  plan: { maxOfficeCount = 0 } = {},
}) => {
  const firstCat =
    categories_.length && categories_[0].name ? categories_[0].name : '';
  return {
    items,
    maxOfficeCount,
    categories: categories_.map(({ name = '' }) => ({
      value: name,
      label: name,
    })),
    category,
    selectedCategory:
      typeof category === 'string' && category ? category : firstCat,
    includedOffices,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setPackages: (packages) => dispatch(setPackages(packages)),
  queryPackages: (params) => dispatch(queryPackages(params)),
  setPackageCategory: (category) => dispatch(setPackageCategory(category)),
  startDeletePackage: (objectId) => dispatch(startDeletePackage(objectId)),
  setIncludedOfficesFilter: (officeIds) =>
    dispatch(setIncludedOfficesFilter(officeIds)),
  startUpdatePackages: ({ updates }) =>
    dispatch(startUpdatePackages({ updates })),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(Index),
);
