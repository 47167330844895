/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Parse from 'parse';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { handleError } from '../../actions/auth';
import { SectionTitle } from '../SharedComponents/SectionTitle';
import { CreditAppComponent } from './CreditAppComponent';
import useDecryptedCreditApp from '../../hooks/decryptedCreditApp';
import CompletedCreditAppModal from '../CompletedCreditApp/CompletedCreditAppModal';

const CreditAppSection = (props) => {
  const { customerId } = props;
  const [creditApplications, setCreditApplications] = useState([]);

  const dispatch = useDispatch();

  const {
    showAuth,
    setShowAuth,
    hideModal,
    onCancel,
    getDecryptedCreditAppValues,
    selectedAppData = {},
    setSelectedAppData,
  } = useDecryptedCreditApp();

  const onViewCompleted = (data) => {
    setShowAuth(true);
    setSelectedAppData(data);
  };

  useEffect(() => {
    const getCreditApps = async () => {
      try {
        const credAppQuery = new Parse.Query('SSCreditApplication');
        const currentUser = Parse.User.current();
        const customer = new Parse.Object('SSCustomer');
        customer.id = customerId;
        credAppQuery.equalTo('customer', customer);
        credAppQuery.equalTo('company', currentUser.get('company'));
        const results = [];
        const customerCreditAppQuery = new Parse.Query('CustomerCreditApp');
        customerCreditAppQuery.equalTo('isFilledOut', true);
        customerCreditAppQuery.equalTo('customer', customer);
        customerCreditAppQuery.equalTo('company', currentUser.get('company'));
        await customerCreditAppQuery.each((customerCreditApp) =>
          results.push(customerCreditApp),
        );
        await credAppQuery.each((creditApplication) =>
          results.push(creditApplication),
        );
        const sorted = results.sort(
          (a, b) => a.get('createdAt') - b.get('createdAt'),
        );

        const allCreditApps = [...sorted];

        setCreditApplications(allCreditApps);
      } catch (error) {
        dispatch(handleError(error));
      }
    };
    if (customerId) {
      getCreditApps();
    }
  }, [customerId]);
  const updateCreditApplication = (updatedCreditApplication) => {
    const updatedCreditApplications = creditApplications.map(
      (creditApplication) => {
        if (creditApplication.id === updatedCreditApplication.id) {
          return updatedCreditApplication;
        }
        return creditApplication;
      },
    );
    setCreditApplications(updatedCreditApplications);
  };
  return (
    <article className="appointment-meta-section leap-data-section">
      <SectionTitle {...{ title: 'Credit Applications' }} />
      {creditApplications.map((creditApplication) => (
        <CreditAppComponent
          onViewCompleted={onViewCompleted}
          key={creditApplication.id}
          creditApplication={creditApplication}
          updateCreditApplication={updateCreditApplication}
        />
      ))}
      {!creditApplications.length ? (
        <p>No Credit Applications</p>
      ) : (
        <CompletedCreditAppModal
          show={showAuth}
          onHide={hideModal}
          onCancel={onCancel}
          customerCreditAppId={selectedAppData.id}
          customerAccount={selectedAppData.customerAccount}
          getDecryptedCreditAppValues={getDecryptedCreditAppValues}
        />
      )}
    </article>
  );
};

CreditAppSection.propTypes = {
  objectId: PropTypes.string.isRequired,
};

export default CreditAppSection;
