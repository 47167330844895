import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';
import { Position, Tooltip } from '@blueprintjs/core';
import { setItemColumnWidth } from '../../../actions/priceGuide2';

const MeasuresheetItemHeaderCell = ({
  children,
  columnKey,
  disableResize,
  activeColumns,
  widths,
  thumbnail,
  setWidth,
  popoverTitle,
  popoverBody,
  ...rest
}) => {
  const component = (
    <div
      className={`priceguide__msi_header-cell${
        activeColumns[columnKey] ? '_hidden' : ''
      }${disableResize && !thumbnail ? ' static' : ''}${
        thumbnail ? ' thumbnail-header-cell' : ''
      } `}
      {...rest}
    >
      {children}
      {!disableResize && (
        <ReactResizeDetector
          handleWidth
          skipOnMount
          refreshMode="debounce"
          refreshRate={300}
          onResize={(width) => {
            setWidth(columnKey, width + 11);
          }}
        />
      )}
    </div>
  );

  const [isOpen, setToolOpen] = useState(false);

  if (popoverTitle || popoverBody) {
    return (
      <Tooltip
        isOpen={isOpen}
        content={
          <span onMouseLeave={() => setToolOpen(false)}>
            {`${popoverTitle} - ${popoverBody}`}
          </span>
        }
        position={Position.RIGHT}
      >
        <div
          onMouseEnter={() => setToolOpen(true)}
          onMouseLeave={() => setToolOpen(false)}
          style={{ backgroundColor: '#5fb3ce', height: 35 }}
        >
          {component}
        </div>
      </Tooltip>
    );
  }
  return component;
};

MeasuresheetItemHeaderCell.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  columnKey: PropTypes.string,
  disableResize: PropTypes.bool,
  activeColumns: PropTypes.shape({
    settings: PropTypes.bool.isRequired,
    additionalDetails: PropTypes.bool.isRequired,
    placeholders: PropTypes.bool.isRequired,
    showToggle: PropTypes.bool.isRequired,
    image: PropTypes.bool.isRequired,
    category: PropTypes.bool.isRequired,
    subCategory: PropTypes.bool.isRequired,
    subSubCategories: PropTypes.bool.isRequired,
    name: PropTypes.bool.isRequired,
    note: PropTypes.bool.isRequired,
    measurementType: PropTypes.bool.isRequired,
    offices: PropTypes.bool.isRequired,
    formulaId: PropTypes.bool.isRequired,
    formula: PropTypes.bool.isRequired,
  }).isRequired,
  style: PropTypes.shape({}),
};

MeasuresheetItemHeaderCell.defaultProps = {
  disableResize: false,
  columnKey: '',
  style: {},
};

const mapStateToProps = ({ priceGuide2: { activeColumns, columnWidths } }) => ({
  activeColumns,
  widths: columnWidths.items,
});

const mapDispatchToProps = (dispatch) => ({
  setWidth: (columnKey, width) =>
    dispatch(setItemColumnWidth(columnKey, width)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MeasuresheetItemHeaderCell);
