import PropTypes from 'prop-types';
import React from 'react';
import ContractObject from '../../Models/ContractObject';
import BodyCellItem from '../../Models/BodyCellItem';
import TextMenu from './TextMenu';
import DrawingMenu from './DrawingMenu';
import TextOnlyMenu from './TextOnlyMenu';
import DetailCellMenu from './DetailCellMenu';
import SecurePaymentMenu from './SecurePaymentMenu';
import EmptySpaceMenu from './EmptySpaceMenu';
import PhotoSelectorMenu from './PhotoSelectorMenu';
import MeasureSheetItemDetailMenu from './MeasureSheetItemDetailMenu';
import ImageSelectorMenu from './ImageSelectorMenu';
import SignatureMenu from './SignatureMenu';
import HeaderMenu from './HeaderMenu';

const SettingsMenuContent = ({
  updatedObject,
  contractObject,
  showDocLinking,
}) => {
  switch (updatedObject.cellType) {
    case 'textShort':
    case 'textLong':
    case 'textXLong':
    case 'switched':
      return (
        <TextMenu
          object={updatedObject}
          contractObject={contractObject}
          showDocLinking={showDocLinking}
        />
      );
    case 'textOnly':
      return (
        <TextOnlyMenu
          object={updatedObject}
          contractObject={contractObject}
          showDocLinking={showDocLinking}
        />
      );
    case 'drawing':
      return (
        <DrawingMenu object={updatedObject} contractObject={contractObject} />
      );
    case 'detailCell':
      return (
        <DetailCellMenu
          object={updatedObject}
          contractObject={contractObject}
        />
      );
    case 'provia':
      return (
        <DetailCellMenu
          disableImageHeight
          object={updatedObject}
          contractObject={contractObject}
        />
      );
    case 'securePaymentCaptureAll':
    case 'creditCardCell':
    case 'bankAccountCell':
      return (
        <SecurePaymentMenu
          object={updatedObject}
          contractObject={contractObject}
        />
      );
    case 'emptySpace':
      return (
        <EmptySpaceMenu
          object={updatedObject}
          contractObject={contractObject}
        />
      );
    case 'photoPickerCell':
      return (
        <PhotoSelectorMenu
          object={updatedObject}
          contractObject={contractObject}
        />
      );
    case 'measureSheetItemDetail':
      return (
        <MeasureSheetItemDetailMenu
          object={updatedObject}
          contractObject={contractObject}
        />
      );
    case 'imagePicker':
      return (
        <ImageSelectorMenu
          object={updatedObject}
          contractObject={contractObject}
        />
      );
    case 'header':
      return (
        <HeaderMenu object={updatedObject} contractObject={contractObject} />
      );
    case 'signatures':
      return (
        <SignatureMenu object={updatedObject} contractObject={contractObject} />
      );
    default:
      return null;
  }
};

export default SettingsMenuContent;

SettingsMenuContent.defaultProps = {
  showDocLinking: false,
};

SettingsMenuContent.propTypes = {
  contractObject: PropTypes.instanceOf(ContractObject).isRequired,
  updatedObject: PropTypes.instanceOf(BodyCellItem).isRequired,
  showDocLinking: PropTypes.bool,
};
