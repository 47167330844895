/* eslint-disable no-underscore-dangle */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
import Parse from 'parse';
import { handleError } from './auth';
import { showSuccessAlert, hideAlert } from './alert';
import { history } from '../router';
import { showLoader, hideLoader } from './loading';
import { pushToDataLayer } from './tagManager';

export const addResources = (items = []) => ({
  type: 'ADD_RESOURCES',
  items,
});

export const setResources = (items = []) => ({
  type: 'SET_RESOURCES',
  items,
});

export const setResourcesCount = (count = 0) => ({
  type: 'SET_RESOURCES_COUNT',
  count,
});

export const startUpdateResource = (item) => ({
  type: 'UPDATE_RESOURCE',
  item,
});

export const startSaveResource = (object, file, thumbnail) => async (
  dispatch,
) => {
  try {
    const {
      objectId,
      displayName,
      url,
      fileType,
      buttons,
      orderNumber,
      includedOffices = [],
    } = object;
    const resource = new Parse.Object('PitchBookObject');
    resource.id = objectId;
    const offices = includedOffices.map((office) => {
      const pointer = new Parse.Object('Office');
      pointer.id = office.objectId;
      return pointer.toPointer();
    });
    const changes = {
      displayName,
      buttons,
      url,
      fileType,
      orderNumber,
      includedOffices: offices,
      file,
      thumbnail,
    };

    await resource.save(changes);
    if (objectId) {
      dispatch(
        pushToDataLayer({
          event: 'resourceEvent',
          eventCategory: 'Resources',
          eventAction: 'Create',
          eventLabel: resource.get('displayName'),
        }),
      );
      dispatch(startUpdateResource(resource.toJSON()));
    } else {
      dispatch(
        pushToDataLayer({
          event: 'resourceEvent',
          eventCategory: 'Resources',
          eventAction: 'Edit',
          eventLabel: resource.get('displayName'),
        }),
      );
      dispatch(addResources([resource.toJSON()]));
    }
    history.push('/resources');
    dispatch(
      showSuccessAlert({
        title: 'Success!',
        message: `${displayName} Resource saved successfully.`,
        onConfirm: () => dispatch(hideAlert()),
      }),
    );
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const startDeleteResource = (objectId) => async (dispatch, getState) => {
  try {
    const object = new Parse.Object('PitchBookObject');
    object.id = objectId;
    dispatch(
      pushToDataLayer({
        event: 'resourceEvent',
        eventCategory: 'Resources',
        eventAction: 'Delete',
        eventLabel: object.get('displayName'),
      }),
    );
    await object.destroy();
    const newItems = getState().resources.items.filter(
      (item) => item.objectId !== objectId,
    );
    dispatch(setResources(newItems));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const setIncludedOfficesFilter = (offices) => ({
  type: 'SET_RESOURCES_INCLUDED_OFFICES_FILTER',
  offices,
});

export const setResourcesDirectory = (directory) => ({
  type: 'SET_RESOURCES_DIRECTORY',
  directory,
});

export const resetResources = () => ({
  type: 'RESET_RESOURCES',
});

export const setSharedResources = (objects) => ({
  type: 'SET_SHARED_RESOURCES',
  objects,
});

export const setSharedResourceGroup = (group) => ({
  type: 'SET_SHARED_RESOURCE_GROUP',
  group,
});

export const resetSharedResources = () => ({
  type: 'RESET_SHARED_RESOURCES',
});

export const startFetchSharedResources = (groupObjectId) => async (
  dispatch,
) => {
  try {
    const object = new Parse.Object('SharedResourceGroup');
    object.id = groupObjectId;
    await object.fetch();
    dispatch(setSharedResourceGroup(object));
    const query = object.relation('resourceObjects').query();
    query.limit(1000);
    query.select([
      'name',
      'thumbnail',
      'url',
      'isLandscape',
      'fileType',
      'file',
      'fileSize',
      'category',
      'pageNumber',
    ]);
    query.ascending('name');
    const objects = await query.find();
    dispatch(setSharedResources(objects.map((obj) => obj.toJSON())));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const setSharedResourceGroups = (objects) => ({
  type: 'SET_SHARED_RESOURCE_GROUPS',
  objects,
});

export const startFetchSharedResourceGroups = () => async (dispatch) => {
  try {
    const query = new Parse.Query('SharedResourceGroup');
    query.limit(1000);
    query.select(['name', 'thumbnail']);
    query.ascending('name');
    const objects = await query.find();
    dispatch(setSharedResourceGroups(objects));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const startSaveSharedResourceGroup = (sharedResourceGroupData) => async (
  dispatch,
) => {
  try {
    dispatch(showLoader('Saving Resource Group ...'));
    const sharedResourceGroup = new Parse.Object('SharedResourceGroup');
    await sharedResourceGroup.save(sharedResourceGroupData);
    dispatch(hideLoader());
    dispatch(setSharedResourceGroup(sharedResourceGroup));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const updateResourcesOrder = ({ items }) => async (dispatch) => {
  try {
    dispatch(showLoader('Saving Resource Order ...'));
    const pitchBookObjetcs = items
      .map((item) => {
        const pitchBookObject = new Parse.Object('PitchBookObject');
        pitchBookObject.id = item.objectId;
        const orderNumber = pitchBookObject.get('orderNumber');
        if (item.orderNumber !== orderNumber) {
          pitchBookObject.set('orderNumber', item.orderNumber);
        }
        return pitchBookObject;
      })
      .filter((pitch) => pitch.dirtyKeys().length);
    if (pitchBookObjetcs.length) {
      await Parse.Object.saveAll(pitchBookObjetcs);
      dispatch(
        showSuccessAlert({
          title: 'Success!',
          message: `Resource Order Saved.`,
          onConfirm: () => dispatch(hideAlert()),
        }),
      );
    }
    dispatch(hideLoader());
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const startAddResourceToGroup = (
  sharedResourceGroup,
  resourceData,
) => async (dispatch) => {
  try {
    dispatch(showLoader('Creating New Resource ...'));
    const sharedResourceObject = new Parse.Object('SharedResourceObject');
    await sharedResourceObject.save(resourceData);
    const groupResourceRelation = sharedResourceGroup.relation(
      'resourceObjects',
    );
    groupResourceRelation.add(sharedResourceObject);
    dispatch(showLoader('Adding Resource To Group ...'));
    await sharedResourceGroup.save();
    dispatch(setSharedResourceGroup(sharedResourceGroup));
  } catch (e) {
    dispatch(handleError(e));
  } finally {
    dispatch(hideLoader());
  }
};

export const startRemoveSharedResource = (sharedResourceData) => async (
  dispatch,
) => {
  try {
    dispatch(showLoader('Removing Resource...'));
    const sharedResourceObject = new Parse.Object('SharedResourceObject');
    sharedResourceObject.id = sharedResourceData.objectId;
    await sharedResourceObject.destroy();
    dispatch(hideLoader());
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const startRemoveResourceGroup = (sharedResourceGroup) => async (
  dispatch,
) => {
  try {
    dispatch(showLoader('Removing Shared Resource Group...'));
    await sharedResourceGroup.destroy();
    dispatch(hideLoader());
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const importSharedResourceGroup = (sharedResourceGroupId) => async (
  dispatch,
  getState,
) => {
  try {
    const { auth } = getState();
    dispatch(showLoader('Importing Resources ... '));
    await Parse.Cloud.run('ImportSharedResourceGroup', {
      sharedResourceGroupId,
      selectedOfficeId: auth.selectedOffice.id,
    });
    dispatch(hideLoader());
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const startCopySharedResource = (resourceId) => async (
  dispatch,
  getState,
) => {
  try {
    const { auth } = getState();

    const selectedOfficeId = auth.selectedOffice.id;
    const office = new Parse.Object('Office');
    office.id = selectedOfficeId;

    const sourceResource = new Parse.Object('SharedResourceObject');
    sourceResource.id = resourceId;
    await sourceResource.fetch();
    dispatch(
      pushToDataLayer({
        event: 'resourceEvent',
        eventCategory: 'Resources',
        eventAction: 'Clone',
        eventLabel: sourceResource.get('displayName'),
      }),
    );
    const newResource = new Parse.Object('PitchBookObject');
    newResource.set('buttons', sourceResource.get('buttons'));
    newResource.set('thumbnail', sourceResource.get('thumbnail'));
    newResource.set('displayName', sourceResource.get('name'));
    newResource.set('url', sourceResource.get('url'));
    newResource.set('isLandscape', sourceResource.get('isLandscape'));
    newResource.set('fileType', sourceResource.get('fileType'));
    newResource.set('orderNumber', 0);
    newResource.set('pageNumber', sourceResource.get('pageNumber'));
    newResource.set('includedOffices', [office.toPointer()]);
    newResource.set('file', sourceResource.get('file'));
    newResource.set('fileSize', sourceResource.get('fileSize'));
    newResource.set('sharedResource', sourceResource.toPointer());
    newResource.set(
      'showScreenShotButton',
      sourceResource.get('showScreenShotButton'),
    );
    await newResource.save();
    dispatch(
      showSuccessAlert({
        title: 'Success!',
        message: `${newResource.get('displayName')} added successfully`,
        onConfirm: () => dispatch(hideAlert()),
      }),
    );
  } catch (e) {
    dispatch(handleError(e));
  }
};

/**
 *
 * @param {{limit: number, skip: number, sortOption?: string, sortKey?: string, folderName: string, includedOffices: string[]}} params
 */
export const startQueryResourcesOptions = (params) => async (
  dispatch,
  getState,
) => {
  try {
    const {
      limit = 25,
      skip = 0,
      sortOption,
      sortKey,
      folderName = '',
      includedOffices = [],
    } = params;

    const {
      auth: { offices },
      plan = {},
    } = getState();

    if (plan.maxOfficeCount === 1) {
      // clear the array, and add the single office
      includedOffices.length = 0;
      includedOffices.push(offices[0].id);
    }

    const query = new Parse.Query('PitchBookObject');
    query.select([
      'displayName',
      'thumbnail',
      'file',
      'url',
      'fileType',
      'includedOffices',
      'orderNumber',
      'pathDepth',
    ]);
    if (folderName) {
      query.startsWith('displayName', folderName);
      const path = folderName.split('/');
      const depth = path.length;
      query.equalTo('pathDepth', depth);
    } else {
      query.equalTo('pathDepth', 0);
    }

    if (includedOffices.length) {
      const includedOfficesFilter = includedOffices
        .filter((o) => !!o)
        .map((objectId) => {
          const office = new Parse.Object('Office');
          office.id = objectId;
          return office;
        });
      query.containedIn('includedOffices', includedOfficesFilter);
    } else {
      query.notContainedIn('includedOffices', offices);
    }

    let count = 0;
    await query.each(() => count++);
    dispatch(setResourcesCount(count));

    query.ascending('orderNumber');
    query.limit(limit);
    query.skip(skip);
    if (sortOption && query[sortOption] && sortKey) {
      query[sortOption](sortKey);
    }
    const items = await query.find();
    dispatch(setResources(items.map((item) => mapResourceItem(item))));
  } catch (e) {
    dispatch(handleError(e));
  }
};

const mapResourceItem = (item) => {
  const selectedKeys = [
    'displayName',
    'thumbnail',
    'file',
    'url',
    'fileType',
    'includedOffices',
    'orderNumber',
    'pathDepth',
  ];
  const itemJSON = selectedKeys.reduce((itemJSON, key) => {
    let value = item.get(key);

    if (value && value._url) {
      value = value.toJSON();
    }

    return {
      objectId: item.id,
      ...itemJSON,
      [key]: value,
    };
  }, {});

  return itemJSON;
};

export const updateItemFolder = (
  dropedItem,
  itemDisplayNameUpdate,
  callback,
) => async (dispatch) => {
  try {
    const pitchBookObject = new Parse.Object('PitchBookObject');
    pitchBookObject.id = dropedItem.objectId;
    pitchBookObject.set('displayName', itemDisplayNameUpdate);
    await pitchBookObject.save();
    callback({});
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const setResourceFilter = (filter = '') => ({
  type: 'SET_RESOURCE_FILTER',
  filter,
});

export const fireSortResources = (lastParams) => async (dispatch) => {
  try {
    dispatch(showLoader('Sorting ..'));
    await Parse.Cloud.run('resourceSort', {});
    dispatch(hideLoader());
    dispatch(
      showSuccessAlert({
        title: 'Success!',
        message: 'Resources Sorted Successfully.',
        onConfirm: () => dispatch(hideAlert()),
      }),
    );
    await dispatch(startQueryResourcesOptions(lastParams));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const startSaveResourceNoUpload = (displayName, object) => async (
  dispatch,
) => {
  try {
    const { objectId } = object;
    const resource = new Parse.Object('PitchBookObject');
    resource.id = objectId;
    await resource.fetch();
    if (resource.has('edits')) {
      dispatch(startUpdateResource(resource.toJSON()));
    } else {
      dispatch(addResources([resource.toJSON()]));
    }
    history.push('/resources');
    dispatch(
      showSuccessAlert({
        title: 'Success!',
        message: `${displayName} Resource saved successfully.`,
        onConfirm: () => dispatch(hideAlert()),
      }),
    );
  } catch (e) {
    dispatch(handleError(e));
  }
};
