import Parse from 'parse';
import { handleError, selectedOffice } from './auth';
import { showSuccessAlert, hideAlert } from './alert';
import { showLoader, hideLoader } from './loading';
import { startFetchPaymentsForCustomer } from './estimatesPage';

class PaymentCaptureActions {
  static resetPaymentCapture() {
    return {
      type: 'PAYMENT_CAPTURE_RESET',
    };
  }

  static setPaymentGateways = (gateways) => ({
    type: 'PAYMENT_CAPTURE_SET_GATEWAYS',
    gateways,
  });

  static startGetPaymentGateways = () => async (dispatch) => {
    try {
      const gateways = await Parse.Cloud.run('spreedlyGatewayList');
      await dispatch(PaymentCaptureActions.setPaymentGateways(gateways));
    } catch (e) {
      dispatch(handleError(e));
    }
  };

  static startUpdatePaymentGateway = (params, callback) => async (
    dispatch,
    getState,
  ) => {
    try {
      const { auth } = getState();
      const gatewayInfo = {
        gatewayInfo: params,
        officeId: auth.selectedOffice.id,
      };
      const result = await Parse.Cloud.run(
        'spreedlyPaymentGateway',
        gatewayInfo,
      );
      if (callback) {
        callback(params);
      }
      const config = await auth.selectedOffice.get('config').fetch();
      dispatch(selectedOffice(auth.selectedOffice, config));
      dispatch(
        showSuccessAlert({
          title: 'Secure Payment Capture',
          message: result,
          onConfirm: () => {
            dispatch(hideAlert());
          },
        }),
      );
    } catch (e) {
      dispatch(handleError(e));
    }
  };

  static setPaymentMethods = (objects) => ({
    type: 'SET_PAYMENT_METHODS',
    objects,
  });

  static setTransactions = (transactions) => ({
    type: 'SET_TRANSACTIONS',
    transactions,
  });

  static startSubmitRefund = (params, callback) => async (dispatch) => {
    try {
      dispatch(showLoader('Processing Refund ...'));
      const { amount, description, transaction, customerId } = params;
      const {
        amount: transactionAmount,
        objectId: transactionId,
        token: transactionToken,
      } = transaction;
      const transactionType =
        amount < transactionAmount ? 'partial_refund' : 'full_refund';
      const transactionParams = {
        transactionType,
        transactionId,
        transactionToken,
        amount: parseInt(`${amount}`, 10),
        description,
      };
      const result = await Parse.Cloud.run(
        'spreedlyPaymentTransaction',
        transactionParams,
      );

      const customer = new Parse.Object('SSCustomer');
      customer.id = customerId;
      await dispatch(startFetchPaymentsForCustomer(customer));

      callback(result);
      dispatch(
        showSuccessAlert({
          title: 'Refund Transaction',
          message: result,
          onConfirm: () => {
            dispatch(hideAlert());
          },
        }),
      );
    } catch (e) {
      dispatch(handleError(e));
    } finally {
      dispatch(hideLoader());
    }
  };

  static startSubmitVoid = (params, callback) => async (dispatch) => {
    try {
      dispatch(showLoader('Voiding Transaction ...'));
      const { description, transaction, customerId } = params;
      const { objectId: transactionId, token: transactionToken } = transaction;
      const transactionParams = {
        transactionType: 'void',
        transactionId,
        transactionToken,
        description,
      };
      const result = await Parse.Cloud.run(
        'spreedlyPaymentTransaction',
        transactionParams,
      );

      const customer = new Parse.Object('SSCustomer');
      customer.id = customerId;
      await dispatch(startFetchPaymentsForCustomer(customer));
      callback(result);
      dispatch(
        showSuccessAlert({
          title: 'Void Transaction',
          message: result,
          onConfirm: () => {
            dispatch(hideAlert());
          },
        }),
      );
    } catch (e) {
      dispatch(handleError(e));
    } finally {
      dispatch(hideLoader());
    }
  };
}

export const {
  resetPaymentCapture,
  setPaymentGateways,
  startGetPaymentGateways,
  startUpdatePaymentGateway,
  setPaymentMethods,
  setTransactions,
  startSubmitPayment,
  startSubmitRefund,
  startSubmitVoid,
} = PaymentCaptureActions;

export default PaymentCaptureActions;
