/* eslint-disable no-return-assign */
/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import Parse from 'parse';
import SweetAlert from 'react-bootstrap-sweetalert';
import { jsx } from '@emotion/core';
import { DropdownButton, Button, Dropdown } from 'react-bootstrap';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import { history } from '../../router';
import Panel from '../Panel';
import DragGrid from '../Resources/DragGrid';
import { pushToDataLayer } from '../../actions/tagManager';
import PlanActions from '../../actions/plan';
import {
  startFetchTemplates,
  startSetIncludedOfficesFilter,
  startSetIncludedStatesFilter,
  startSetPage,
  startSetLimit,
  startCountTemplates,
  startDeleteTemplate,
  startCloneTemplate,
  updateTemplatesOrder,
  startClearTemplates,
} from '../../actions/templates';
import { setCurrentWorkingTemplate } from '../../actions/templateEdit';
import FormGroup from '../FormGroup';
import OfficesDropDown from '../IncludedOfficesDropDown';
import StateSelector from './StateSelector';
import Paginator from '../Misc/Paginator';
import TemplateCell from './TemplateCell';
import PublishModal from './PublishModal';

class TemplateIndex extends React.Component {
  constructor(props) {
    super(props);
    const { type } = props.match.params;
    props.startFetchTemplates(type);
    props.startCountTemplates(type);
    this.getTitle();
    this.state = {};
  }

  getTitle() {
    const { type } = this.props.match.params;
    switch (type) {
      case 'contract':
        return (this.title = 'Contracts');
      case 'proposal':
        return (this.title = 'Proposals');
      default:
        return (this.title = this.props.allMainMenuOptions
          .find(({ objectId }) => objectId === type)
          .title.toLowerCase()
          .split(' ')
          .map((word) => word.replace(/^\w/, (c) => c.toUpperCase()))
          .join(' '));
    }
  }

  UNSAFE_componentWillUpdate() {
    const {
      setCrumbs,
      location: { pathname },
      match: {
        params: { type },
      },
      getLabelByAnchor,
      crumbs,
    } = this.props;
    const pageTitle = getLabelByAnchor({
      anchor: `templates/${type}`,
      title: this.getTitle(),
    });
    const newCrumbs = [{ title: `${pageTitle} Templates`, link: pathname }];
    const updated = !_.isEqual(newCrumbs, crumbs);
    if (updated) {
      setCrumbs(newCrumbs);
    }
  }

  componentDidMount() {
    const {
      setCrumbs,
      setButtons,
      location: { pathname },
      match: {
        params: { type },
      },
      getLabelByAnchor,
    } = this.props;
    const pageTitle = getLabelByAnchor({
      anchor: `templates/${type}`,
      title: this.getTitle(),
    });
    setCrumbs([{ title: `${pageTitle} Templates`, link: pathname }]);
    setButtons(
      <>
        <Button
          onClick={() => {
            history.push(`/templates/${type}/restore_deleted`);
          }}
          id="restore"
          bsStyle="primary"
          pullRight
        >
          Restore Deleted Version
        </Button>
        <DropdownButton id="new" bsStyle="success" title="New" pullRight>
          <Dropdown.Item onClick={this.onNewClicked}>
            From Scratch
          </Dropdown.Item>
          <Dropdown.Item onClick={this.onFromTemplateClicked}>
            From Template
          </Dropdown.Item>
        </DropdownButton>
      </>,
    );
  }

  componentWillUnmount() {
    const { setCrumbs, setButtons } = this.props;
    setCrumbs([]);
    setButtons();
  }

  onDeleteConfirmed = (objectId) => {
    const { type } = this.props.match.params;
    this.props.startDeleteTemplate(type, objectId);
    this.setState({ alert: undefined });
  };

  onDelete = (obj) => {
    this.setState({
      alert: {
        title: `Delete ${obj.get('displayName')} Template?`,
        onConfirm: () => {
          this.props.pushToDataLayer({
            event: 'documentTemplateEvent',
            eventCategory: 'Documents',
            eventAction: 'Delete',
            eventLabel: obj.get('displayName'),
          });
          this.onDeleteConfirmed(obj.id);
        },
      },
    });
  };

  onPublish = (obj) => {
    this.setState(() => ({ templateToPublish: obj, showPublishModal: true }));
  };

  onCloneTempate = (objectId, obj) => {
    this.props.pushToDataLayer({
      event: 'documentTemplateEvent',
      eventCategory: 'Documents',
      eventAction: 'Copy',
      eventLabel: obj.get('displayName'),
    });
    const { type } = this.props.match.params;
    this.props.startCloneTemplate(type, objectId);
  };

  onNewClicked = () => {
    const { type } = this.props.match.params;
    history.push(`/templates/${type}/new`);
    const templateId = `${this.props.match.params.type}_new`;
    this.props.setCurrentWorkingTemplate(templateId);
  };

  onEditClicked = (obj) => {
    this.props.pushToDataLayer({
      event: 'documentTemplateEvent',
      eventCategory: 'Documents',
      eventAction: 'View',
      eventLabel: obj.get('displayName'),
    });
    this.props.setCurrentWorkingTemplate(obj.id);
    history.push(`/templates/${obj.get('type')}/${obj.id}`);
  };

  updateTemplateOrder = (items) => {
    const { type } = this.props.match.params;
    const templatesWithOrderNumber = items
      .map((item) => ({
        item,
        parseObject: this.props.templates.find(({ id }) => item.id === id),
      }))
      .filter(
        ({ item, parseObject }) =>
          parseObject.get('order') !== item.orderNumber,
      )
      .map(({ item, parseObject }) => {
        parseObject.set('order', item.orderNumber);
        return parseObject;
      });
    this.props.updateTemplatesOrder(templatesWithOrderNumber, type);
  };

  onFromTemplateClicked = async () => {
    await this.props.startClearTemplates();
    const { type } = this.props.match.params;
    history.push(`/templates/${type}/template_groups`);
  };

  render() {
    const { type } = this.props.match.params;
    const pageTitle = this.props.getLabelByAnchor({
      anchor: `templates/${type}`,
      title: this.getTitle(),
    });
    return (
      <>
        {!!this.state.alert && (
          <SweetAlert
            warning
            showCancel
            title={this.state.alert.title}
            confirmBtnText="Yes"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            onConfirm={this.state.alert.onConfirm}
            onCancel={() => this.setState({ alert: undefined })}
          >
            This action cannot be undone!
          </SweetAlert>
        )}
        <PublishModal
          show={this.state.showPublishModal}
          template={this.state.templateToPublish}
          onClose={() =>
            this.setState(() => ({
              templateToPublish: undefined,
              showPublishModal: false,
            }))
          }
        />
        <div className="default-page-padding">
          <Panel title="Filters">
            {this.props.maxOfficeCount !== 1 && (
              <FormGroup title="Offices">
                <OfficesDropDown
                  onChange={(offices) =>
                    this.props.startSetIncludedOfficesFilter(type, offices)
                  }
                  selected={this.props.includedOfficesFilter}
                />
              </FormGroup>
            )}
            <FormGroup title="States">
              <StateSelector
                selected={this.props.includedStatesFilter}
                onChange={(states) =>
                  this.props.startSetIncludedStatesFilter(type, states)
                }
              />
            </FormGroup>
          </Panel>
          <Panel title={pageTitle}>
            <div>
              <DragGrid
                pagenationSkip={() => {
                  const { page, limit } = this.props;
                  return limit * (page - 1);
                }}
                onDragableId={({ item }) => `drag-grid-item-${item.id}`}
                onCell={(dataWithItem) => {
                  let item = { ...dataWithItem.item };
                  if (!item.has) {
                    item = new Parse.Object('ContractObject');
                    item.id = dataWithItem.item.id;
                  }
                  return (
                    <TemplateCell
                      key={item.id}
                      objectId={item.id}
                      templateType={type}
                      thumbnailURL={
                        item.has('iconImage')
                          ? item.get('iconImage').url()
                          : undefined
                      }
                      name={item.get('displayName')}
                      onCopy={() => this.onCloneTempate(item.id, item)}
                      onDelete={() => this.onDelete(item)}
                      onEditClicked={() => this.onEditClicked(item)}
                      onPublish={() => this.onPublish(item)}
                    />
                  );
                }}
                onUpdatedItemOrder={(items) => this.updateTemplateOrder(items)}
                items={() =>
                  this.props.templates.sort(
                    (a, b) => a.get('order') - b.get('order'),
                  )
                }
              />
              <Paginator
                page={this.props.page}
                limit={this.props.limit}
                totalCount={this.props.count}
                onLimitChanged={({ value }) =>
                  this.props.startSetLimit(type, value)
                }
                pageRange={5}
                onPageClicked={(page) => this.props.startSetPage(type, page)}
              />
            </div>
          </Panel>
        </div>
      </>
    );
  }
}

TemplateIndex.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      type: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  templates: PropTypes.arrayOf(PropTypes.instanceOf(Parse.Object)),
  startFetchTemplates: PropTypes.func.isRequired,
  customOptions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      objectId: PropTypes.string.isRequired,
    }),
  ),
  maxOfficeCount: PropTypes.number.isRequired,
  getLabelByAnchor: PropTypes.func.isRequired,
  includedOfficesFilter: PropTypes.arrayOf(PropTypes.string),
  startSetIncludedOfficesFilter: PropTypes.func.isRequired,
  startSetIncludedStatesFilter: PropTypes.func.isRequired,
  includedStatesFilter: PropTypes.arrayOf(PropTypes.string),
  startSetPage: PropTypes.func.isRequired,
  startSetLimit: PropTypes.func.isRequired,
  startCountTemplates: PropTypes.func.isRequired,
  page: PropTypes.number,
  limit: PropTypes.number,
  count: PropTypes.number,
  startDeleteTemplate: PropTypes.func.isRequired,
  startCloneTemplate: PropTypes.func.isRequired,
  startClearTemplates: PropTypes.func.isRequired,
  setCurrentWorkingTemplate: PropTypes.func.isRequired,
  ...layoutContextPropTypes,
};

TemplateIndex.defaultProps = {
  templates: [],
  customOptions: [],
  includedOfficesFilter: [],
  includedStatesFilter: [],
  page: 1,
  limit: 10,
  count: 0,
};

const mapStateToProps = (
  { templates, auth = {}, config: { styles = {} }, plan = {} },
  ownprops,
) => {
  const { config: { mainMenuOptions } = {}, allMainMenuOptions = [] } = auth;
  const values = templates[ownprops.match.params.type] || {};
  return {
    templates: values.templates,
    customOptions: mainMenuOptions,
    allMainMenuOptions,
    includedOfficesFilter: values.includedOfficesFilter,
    includedStatesFilter: values.includedStatesFilter,
    page: values.page,
    limit: values.limit,
    count: values.count,
    style: styles.TitleButton,
    maxOfficeCount: plan.maxOfficeCount,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getLabelByAnchor: (feature) =>
    dispatch(PlanActions.getLabelByAnchor(feature)),
  startFetchTemplates: (type) => dispatch(startFetchTemplates(type)),
  startSetIncludedOfficesFilter: (type, includedOfficesFilter) =>
    dispatch(startSetIncludedOfficesFilter(type, includedOfficesFilter)),
  updateTemplatesOrder: (templatesWithOrderNumber, templateType) =>
    dispatch(updateTemplatesOrder(templatesWithOrderNumber, templateType)),
  startSetIncludedStatesFilter: (templateType, includedStatesFilter) =>
    dispatch(startSetIncludedStatesFilter(templateType, includedStatesFilter)),
  startSetPage: (templateType, page) =>
    dispatch(startSetPage(templateType, page)),
  startSetLimit: (templateType, limit) =>
    dispatch(startSetLimit(templateType, limit)),
  startCountTemplates: (type) => dispatch(startCountTemplates(type)),
  startDeleteTemplate: (templateType, objectId) =>
    dispatch(startDeleteTemplate(templateType, objectId)),
  startCloneTemplate: (templateType, objectId) =>
    dispatch(startCloneTemplate(templateType, objectId)),
  startClearTemplates: () =>
    dispatch(startClearTemplates(ownProps.match.params.type)),
  setCurrentWorkingTemplate: (objectId) =>
    dispatch(setCurrentWorkingTemplate(objectId)),
  pushToDataLayer: (variablesForLayer) =>
    dispatch(pushToDataLayer(variablesForLayer)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(TemplateIndex),
);
