import Parse from 'parse';
import { randomKey } from '../../../utils/utils';

const toolbarKeyMaps = {
  size: 'fontSize',
  bold: 'fontBold',
  underline: 'fontUnderline',
  name: 'fontName',
  color: 'fontColor',
  align: 'alignment',
  file: 'image',
  value: 'value',
};

const containerKey = 'header_cell';
const pasteKey = 'header_cell';

class HeaderCell {
  constructor(cell, parent, groupIndex) {
    this.JSON = cell;
    this.keys = [
      'objectId',
      'alignment',
      'fontSize',
      'fontBold',
      'fontUnderline',
      'fontName',
      'fontColor',
      'logoHeight',
      'value',
      'cellType',
      'emptySpaceHeight',
      'image',
    ];
    this.containerKey = containerKey;
    this.pasteKey = pasteKey;
    this.parent = parent;
    this.groupIndex = groupIndex;
    this.showToolbar = true;
    this.toolbarKey = 'sectionTitle';
    this.toolbarKeyMaps = toolbarKeyMaps;
    this.toolboxKey = 'header-cell';
    if (cell) {
      Object.assign(this, cell);
      this.objectId = this.objectId || randomKey(10);
    } else {
      this.alignment = 0;
      this.fontSize = 10;
      this.fontBold = false;
      this.fontUnderline = false;
      this.fontName = '';
      this.fontColor = '0-0-0-1';
      this.logoHeight = 40;
      this.value = 'New Item';
      this.cellType = 'default';
      this.emptySpaceHeight = 4;
      this.image = undefined;
    }
    if (!this.objectId) {
      this.objectId = randomKey(10);
    }
    const source = this.getSourceObject();
    if (source) {
      if (source.hasObjectId(this.objectId)) {
        this.objectId = randomKey(10);
      }
      source.addObjectId(this.objectId);
    }
  }

  get identifier() {
    return `${this.parent.identifier}-${this.groupIndex}-${this.objectId}`;
  }

  getPath() {
    if (!this.parent) {
      return undefined;
    }
    const index = this.parent.cellItems.findIndex(
      ({ objectId }) => objectId === this.objectId,
    );
    return [...this.parent.getPath(), index];
  }

  get toolbarKeys() {
    switch (this.cellType) {
      case 'image':
        return ['align', 'file'];
      case 'emptySpace':
        return [];
      default:
        switch (this.value) {
          case '%companyLogo%':
          case '%image%':
            return ['align'];
          default:
            return ['size', 'bold', 'underline', 'name', 'color', 'align'];
        }
    }
  }

  getSourceObject() {
    if (!this.parent) {
      return undefined;
    }
    return this.parent.getSourceObject();
  }

  clone() {
    const jsonObject = this.toJSON();
    const { image, ...data } = jsonObject;
    const json = JSON.parse(JSON.stringify(data));
    json.objectId = randomKey(10);
    json.image = { ...image };
    return new HeaderCell(json, this.parent, this.groupIndex);
  }

  toJSON() {
    const json = {};
    this.keys.forEach((key) => {
      json[key] = this[key];
    });
    return json;
  }

  async convertNewFiles() {
    if (this.image && this.image.isNew) {
      const { file } = this.image;
      const extension = file.type.split('/')[1];
      const name = `image.${extension}`;
      const newFile = new Parse.File(name, file);
      await newFile.save();
      this.image = newFile;
      return this.image;
    }
    return this.image;
  }

  static ContainerKey = containerKey;

  static PasteKey = pasteKey;
}

export default HeaderCell;
