import { useState, useEffect } from 'react';
import Parse from 'parse';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { BehaviorSubject } from 'rxjs';
import { handleError } from '../actions/auth';

const decryptedAppSubject = new BehaviorSubject();

const formatCells = (section) => {
  const { cells = [] } = section;

  return {
    ...section,
    cells: cells.map((cell) => {
      const formated =
        cell.decryptedValue !== 'undefined' ? cell.decryptedValue : 'N/A';
      return {
        ...cell,
        decryptedValue: formated,
      };
    }),
  };
};

const validateSections = (props) => {
  const { decryptedSections = [] } = props;

  const validSections = decryptedSections.reduce((valid, section) => {
    const { cells = [] } = section;
    const [coApplicantIndicator] = cells.filter(
      (cell) => cell.appKey === 'coApplicantIndicator',
    );

    const shouldAdd =
      !coApplicantIndicator ||
      (coApplicantIndicator &&
        coApplicantIndicator.switchValueOff !==
          coApplicantIndicator.decryptedValue);

    const update = shouldAdd ? [...valid, formatCells(section)] : [...valid];

    return update;
  }, []);

  return validSections;
};

const useDecryptedCreditApp = () => {
  const [selectedAppData, setSelectedAppData] = useState({});
  const [showAuth, setShowAuth] = useState(false);
  const [creditAppData, setCreditAppData] = useState(undefined);

  const history = useHistory();

  useEffect(() => {
    const subObj = decryptedAppSubject.subscribe({
      next: (creditAppValueMap) => {
        setCreditAppData({ ...creditAppValueMap });
      },
    });
    return () => {
      subObj.unsubscribe();
    };
  }, []);

  const updateCreditAppData = (updated) => {
    decryptedAppSubject.next(updated);
  };

  const dispatch = useDispatch();

  const initDecryptedCreditApp = async ({ customerCreditAppId }) => {
    try {
      if (!decryptedAppSubject.value) {
        const customeCreditApp = new Parse.Object('CustomerCreditApp');
        customeCreditApp.id = customerCreditAppId;
        await customeCreditApp.fetch();
        const customer = await customeCreditApp.get('customer').fetch();
        const customerFirst = await customer.get('nameFirst');
        const customerLast = await customer.get('nameLast');

        setCreditAppData({
          customerAccount: `${customerFirst} ${customerLast}`,
          customerId: customer.id,
        });
        setShowAuth(true);
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };

  const getDecryptedCreditAppValues = async ({
    customerCreditAppId,
    password,
  }) => {
    const response = await Parse.Cloud.run('getDecryptedCreditAppValues', {
      customerCreditAppId,
      password,
    });

    const decryptedSections = validateSections({ ...response });

    updateCreditAppData({
      ...response,
      decryptedSections,
      customerCreditAppId,
    });
  };

  const clearDecryptedData = () => {
    updateCreditAppData(undefined);
  };

  const hideModal = () => {
    setShowAuth(false);
  };

  const onCancel = () => {
    const { customerId } = creditAppData;

    const { location } = history;

    const { pathname } = location;

    if (!pathname.startsWith('/estimate_details/')) {
      history.push(`/estimate_details/${customerId}`);
    } else {
      hideModal();
    }
  };

  return {
    showAuth,
    onCancel,
    setShowAuth,
    hideModal,
    creditAppData,
    getDecryptedCreditAppValues,
    initDecryptedCreditApp,
    clearDecryptedData,
    selectedAppData,
    setSelectedAppData,
  };
};

export default useDecryptedCreditApp;
