/* eslint-disable react/prop-types */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Parse from 'parse';
import { Table } from 'react-bootstrap';
import { LayoutContext } from '../Layout';
import Panel from '../Panel';
import {
  startFetchPermission,
  startSavePermission,
  updateEditPermission,
} from '../../actions/permissions';
import FormGroup, { TextGroup } from '../FormGroup';
import { permissionsByType } from '../../selectors/permissions';
import { startQueryUsers } from '../../actions/users';
import TitleButton from '../TitleButton';
import { pushToDataLayer } from '../../actions/tagManager';
import Paginator from '../Misc/Paginator';

import './editStyles.scss';

export class Index extends React.Component {
  constructor(props) {
    super(props);
    const { editPermission } = props;
    this.state = {
      activeUserIds: editPermission.activeUserIds,
      activePermissionIds: editPermission.activePermissionIds,
      activeButtonGroupIndex: 0,
      pagination: {
        page: 1,
        limit: 10,
      },
    };
    if (editPermission.permission) {
      this.state.title = editPermission.permission.get('title');
    }
    this.checkBoxRefs = {};
  }

  UNSAFE_componentWillMount() {
    if (this.props.match.params.id) {
      const objectId = this.props.match.params.id;
      this.props.startFetchPermission(objectId);
    }
    this.queryUsers();
  }

  componentDidMount() {
    const { crumbs, setCrumbs, setButtons } = this.context;
    const { location: { pathname = '' } = {} } = this.props;

    const crumbTitle = pathname.includes('edit') ? 'Edit' : 'New';
    setCrumbs([...crumbs, { title: crumbTitle, link: pathname }]);
    setButtons(
      <TitleButton
        variant="success"
        onClick={this.onSaveClicked}
        title="Save"
      />,
    );
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { editPermission } = newProps;
    this.setState({
      activeUserIds: editPermission.activeUserIds,
      activePermissionIds: editPermission.activePermissionIds,
      title: editPermission.permission.get('title'),
    });
  }

  componentWillUnmount() {
    const { setCrumbs, setButtons } = this.context;
    setCrumbs([]);
    setButtons('');
  }

  queryUsers = () => {
    const { page, limit } = this.state.pagination;
    const { searchTerm } = this.state;

    const skip = limit * (page - 1);

    this.props.startQueryUsers({
      isActiveFilter: 2,
      searchTerm,
      limit,
      skip,
    });
  };

  onNameChanged = (title) => {
    const { editPermission = {} } = this.props;
    const { permission } = editPermission;
    permission.set('title', title);
    this.props.updateEditPermission({ ...editPermission });
  };

  onPermissionCheckBoxToggled = (objectId) => {
    const isAdded = this.state.activePermissionIds.indexOf(objectId) > -1;
    const activePermissionIds = this.state.activePermissionIds.filter(
      (id) => id !== objectId,
    );
    if (!isAdded) {
      activePermissionIds.push(objectId);
    }

    const [selected] = this.props.allPermissions.filter(
      (perm) => perm.id === objectId,
    );

    const { title } = selected.toJSON();

    this.props.pushToDataLayer({
      event: 'permissionToggle',
      eventCategory: 'Permissions',
      eventAction: !isAdded ? 'Enable' : 'Disable',
      eventLabel: title,
    });
    this.props.updateEditPermission({ activePermissionIds });
  };

  onPagenation = (paginationEvent) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          ...paginationEvent,
        },
      },
      this.queryUsers,
    );
  };

  onUserCheckboxToggled = (objectId) => {
    const isAdded = this.state.activeUserIds.indexOf(objectId) > -1;
    const activeUserIds = this.state.activeUserIds.filter(
      (id) => id !== objectId,
    );
    if (!isAdded) {
      activeUserIds.push(objectId);
    }
    this.props.updateEditPermission({ activeUserIds });
  };

  onSelectAllChanged = (value) => {
    let activeIds = [];
    if (this.state.activeButtonGroupIndex) {
      if (value) {
        activeIds = this.props.allPermissions.map((object) => object.id);
      }
      this.props.updateEditPermission({ activePermissionIds: activeIds });
      return;
    }
    if (value) {
      activeIds = this.props.allUsers.map((object) => object.objectId);
    }
    this.props.updateEditPermission({ activeUserIds: activeIds });
  };

  onButtonGroupChanged = (index) => {
    this.setState({ activeButtonGroupIndex: index });
  };

  onSaveClicked = () => {
    this.props.startSavePermission({
      permission: this.props.editPermission.permission,
      addedUserIds: this.state.activeUserIds,
      activePermissionIds: this.state.activePermissionIds,
      title: this.state.title,
      permissionGroups: this.props.allPermissions,
    });
  };

  arrayForIndex = (index) =>
    index ? this.props.allPermissions : this.props.allUsers;

  selectAllIsChecked = () => {
    if (this.state.activeButtonGroupIndex) {
      return (
        this.state.activePermissionIds.length ===
        this.props.allPermissions.length
      );
    }
    return this.state.activeUserIds.length === this.props.allUsers.length;
  };

  render() {
    const { activeButtonGroupIndex } = this.state;
    return (
      <div className="default-page-padding">
        <Panel title="Permissions">
          <div>
            <TextGroup
              title="Name"
              value={this.state.title}
              onChange={this.onNameChanged}
            />
            <FormGroup title="">
              <div className="button-container">
                <button
                  type="button"
                  className={
                    activeButtonGroupIndex === 0
                      ? 'button-active'
                      : 'button-innactive'
                  }
                  onClick={() => this.onButtonGroupChanged(0)}
                >
                  Users
                </button>
                <button
                  type="button"
                  className={
                    activeButtonGroupIndex === 1
                      ? 'button-active'
                      : 'button-innactive'
                  }
                  onClick={() => this.onButtonGroupChanged(1)}
                >
                  Permissions
                </button>
              </div>
            </FormGroup>
            <FormGroup title="">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th style={{ width: '35px' }}>
                      <input
                        type="checkbox"
                        checked={this.selectAllIsChecked()}
                        onChange={() =>
                          this.onSelectAllChanged(!this.selectAllIsChecked())
                        }
                      />
                    </th>
                    <th>
                      {this.state.activeButtonGroupIndex
                        ? 'Permission'
                        : 'User'}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.activeButtonGroupIndex === 0 &&
                    this.props.allUsers.map(
                      ({ objectId, nameFirst, nameLast }) => (
                        <tr key={objectId}>
                          <td>
                            <input
                              type="checkbox"
                              checked={
                                this.state.activeUserIds.indexOf(objectId) > -1
                              }
                              onChange={() =>
                                this.onUserCheckboxToggled(objectId)
                              }
                            />
                          </td>
                          <td>
                            <button
                              type="button"
                              className="fill-cell"
                              onClick={() =>
                                this.onUserCheckboxToggled(objectId)
                              }
                            >
                              {`${nameFirst} ${nameLast}`}
                            </button>
                          </td>
                        </tr>
                      ),
                    )}
                  {this.state.activeButtonGroupIndex === 1 &&
                    this.props.allPermissions.map((object) => (
                      <tr key={object.id}>
                        <td>
                          <input
                            type="checkbox"
                            checked={
                              this.state.activePermissionIds.indexOf(
                                object.id,
                              ) > -1
                            }
                            onChange={() =>
                              this.onPermissionCheckBoxToggled(object.id)
                            }
                          />
                        </td>
                        <td>
                          <button
                            type="button"
                            className="fill-cell"
                            onClick={() =>
                              this.onPermissionCheckBoxToggled(object.id)
                            }
                          >
                            {object.get('title')}
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </FormGroup>
            {this.state.activeButtonGroupIndex === 0 && (
              <Paginator
                page={this.state.pagination.page}
                limit={this.state.pagination.limit}
                totalCount={this.props.count}
                onLimitChanged={({ value }) =>
                  this.onPagenation({ page: 1, limit: value })
                }
                pageRange={5}
                onPageClicked={(event) => this.onPagenation({ page: event })}
              />
            )}
          </div>
        </Panel>
      </div>
    );
  }
}

Index.contextType = LayoutContext;

Index.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  startFetchPermission: PropTypes.func.isRequired,
  startQueryUsers: PropTypes.func.isRequired,
  updateEditPermission: PropTypes.func.isRequired,
  editPermission: PropTypes.shape({
    permission: PropTypes.instanceOf(Parse.Object),
    activeUserIds: PropTypes.arrayOf(PropTypes.string),
    activePermissionIds: PropTypes.arrayOf(PropTypes.string),
  }),
  allPermissions: PropTypes.arrayOf(PropTypes.instanceOf(Parse.Object)),
  allUsers: PropTypes.arrayOf(
    PropTypes.shape({
      objectId: PropTypes.string.isRequired,
      nameFirst: PropTypes.string.isRequired,
      nameLast: PropTypes.string.isRequired,
    }),
  ),
  startSavePermission: PropTypes.func.isRequired,
};

Index.defaultProps = {
  match: {
    params: {},
  },
  editPermission: {
    permission: new Parse.Role(),
    activeUserIds: [],
    activePermissionIds: [],
  },
  allPermissions: [],
  allUsers: [],
};

const mapStateToProps = ({ permissions, users }) => ({
  editPermission: permissions.editPermission,
  allPermissions: permissionsByType(permissions.items, 'Class'),
  allUsers: users.items,
  count: users.count,
});

const mapDispatchToProps = (dispatch, props) => ({
  startFetchPermission: () =>
    dispatch(startFetchPermission(props.match.params.id)),
  startQueryUsers: (params) => dispatch(startQueryUsers(params)),
  startSavePermission: (object) => dispatch(startSavePermission(object)),
  updateEditPermission: (params) => dispatch(updateEditPermission(params)),
  pushToDataLayer: (variablesForLayer) =>
    dispatch(pushToDataLayer(variablesForLayer)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
