import React, { useContext, useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import _ from 'lodash';
import { LayoutContext } from '../../Layout';
import SharedResourcesIndex from './SharedResourcesIndex';
import SharedResourceGroup from './SharedResourceGroup';

const SharedResources = () => {
  const { path } = useRouteMatch();
  const { crumbs, setCrumbs } = useContext(LayoutContext);

  useEffect(() => {
    if (crumbs.length > 0) {
      const crumb = { title: 'Shared Resource', link: path };
      const newCrumbs = [...crumbs];
      newCrumbs[1] = crumb;
      if (!_.isEqual(crumbs, newCrumbs)) {
        setCrumbs(newCrumbs);
      }
    }
  }, [crumbs, path, setCrumbs]);

  return (
    <Switch>
      <Route path={`${path}/:groupId`} component={SharedResourceGroup} />
      <Route path={path} component={SharedResourcesIndex} />
    </Switch>
  );
};

export default SharedResources;
