import React from 'react';
import PropTypes from 'prop-types';

import useZapActions from '../../hooks/Zaps/useZapActions';

const DeleteZapButton = (props) => {
  const { item } = props;

  const { onRemoveZap } = useZapActions(item);

  return (
    <button type="button" onClick={onRemoveZap} className="btn">
      <i className="fa fa-trash-alt" />
    </button>
  );
};

DeleteZapButton.propTypes = {
  item: PropTypes.shape({}).isRequired,
};

export default DeleteZapButton;
