/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/** @jsx jsx */
import { jsx } from '@emotion/core';
// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Button } from '@blueprintjs/core';
import { connect } from 'react-redux';
import AppToaster from '../../../../utils/AppToaster';
import ContractObject from '../../Models/ContractObject';
import { newFileFromFile } from '../../../../utils/utils';
import {
  setInitialStateForTemplate,
  setEditCellItem,
} from '../../../../actions/templateEdit';

import TextShort from './TextShort';
import BodyCellItemObject from '../../Models/BodyCellItem';
import BodyRow from '../../BodyRow';

const Drawing = ({ cellItem, setInitialStateForTemplate, setEditItem }) => {
  let imageurl = '';

  if (cellItem.drawingBackgroundImage) {
    if (cellItem.drawingBackgroundImage.url) {
      imageurl = cellItem.drawingBackgroundImage.url;
    }

    if (cellItem.drawingBackgroundImage._url) {
      imageurl = cellItem.drawingBackgroundImage._url;
    }
  }

  const { imageHeight = 100 } = cellItem;
  return (
    <BodyRow cellItem={cellItem}>
      <div>
        <div className="drawing-text-container">
          <TextShort cellItem={cellItem} />
        </div>
        <div
          css={{
            height: `${imageHeight}px`,
          }}
          className="drawing-img-container"
        >
          <img className="drawing-background-img" src={imageurl} />
        </div>
        <Dropzone
          onDrop={async (e) => {
            try {
              const file = await newFileFromFile(
                e[0],
                'document',
                'drawingBackgroundImage',
              );
              cellItem.drawingBackgroundImage = file;
              const contractObject = cellItem.getSourceObject();
              setInitialStateForTemplate(
                new ContractObject(contractObject.toJSON()),
              );
            } catch (err) {
              AppToaster.show({ message: err.message, timeout: 5000 });
            }
          }}
          accept="image/*"
        >
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div
              onClick={() => {
                setEditItem(cellItem);
              }}
              className="drawing-background-input-containter"
            >
              <input {...getInputProps()} />
              <Button
                {...getRootProps()}
                text="Drop Background Image Here..."
              />
            </div>
          )}
        </Dropzone>
      </div>
    </BodyRow>
  );
};

Drawing.propTypes = {
  setEditItem: PropTypes.func.isRequired,
  setInitialStateForTemplate: PropTypes.func.isRequired,
  cellItem: PropTypes.instanceOf(BodyCellItemObject).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  setInitialStateForTemplate: (template) =>
    dispatch(setInitialStateForTemplate(template)),
  setEditItem: (cellItem) => dispatch(setEditCellItem(cellItem)),
});

export default connect(undefined, mapDispatchToProps)(Drawing);
