import React from 'react';

const TermsOfService = () => (
  <>
    <div className="col-12">
      <h1>Leap, LLC End User License Agreement</h1>
      <p>
        IMPORTANT: READ THIS LICENSE CAREFULLY. YOU AGREE TO BE BOUND BY THE
        TERMS AND CONDITIONS OF THIS END USER LICENSE AGREEMENT, AS MAY BE
        AMENDED FROM TO TIME (THE
        <strong>“AGREEMENT“</strong>) BY (I) ELECTRONICALLY SIGNING THE
        AGREEMENT, (II) BY CLICKING ON A BOX INDICATING YOUR ACCEPTANCE, (III)
        BY EXECUTING A DOCUMENT THAT INCORPORATES THE TERMS OF THIS AGREEMENT BY
        REFERENCE, OR (IV) BY ACCESSING or USING THE LICENSED PRODUCT, IF YOU DO
        NOT AGREE TO THE TERMS AND CONDITIONS OF THIS AGREEMENT, DO NOT ACCESS
        OR CEASE TO ACCESS THE LICENSED PRODUCT.
      </p>
      <p>
        This Agreement is a legal agreement between you (
        <strong>“you,” “your”</strong>
        or
        <strong>“Licensee”</strong>) and LEAP, LLC, (
        <strong>“Leap,” “we,” “us,”</strong>
        or
        <strong>“our”</strong>). You represent that you have read and understood
        the terms and conditions set forth herein and agree to be bound by them.
        The terms and conditions of this Agreement will apply to your use of the
        digital contracting platform business for residential home improvement
        contractors offered via the
        <a href="https://leaptodigital.com/">www.leaptodigital.com</a>
        website (the
        <strong>“Website,”</strong>) which includes any websites owned or
        operated by Leap (the
        <strong>“Licensed Product”</strong>). These terms also apply to any
        other entities, products or services owned or operated by Leap and/or
        its affiliates. For purposes hereof, Licensed Product includes the
        software made available via the Website, together with any
        modifications, enhancements, or corrections thereto that are generally
        available to customers; and any user manuals and on-line help
        information for the Licensed Product, generally made available to
        customers from time to time, whether in print or in digital form.
      </p>
      <p>
        You acknowledge and agree that we may, in our sole discretion, amend
        this Agreement at any time by notifying you via email to the
        administrative contact on your account thirty (30) days in advance of
        the date on which the amendment becomes effective. Your continued use of
        the Leap services or Licensed Product after the amendment becomes
        effective shall constitute your acceptance of the amended Agreement.
      </p>
      <ol>
        <li>
          <strong>Grant of License.</strong>
          Licensee is hereby granted a revocable, non-exclusive,
          non-sublicensable and non-transferable right to access and use the
          Licensed Product solely for your internal business purposes and in
          strict accordance with this Agreement through the number of seat
          licenses (<strong>“Seat Licenses”</strong>) procured. Any download,
          copy, view and print content created using the Licensed Product for
          Licensee’s internal use only is permitted; provided, that all
          trademark, service mark, logos or other intellectual property notices
          are not removed or altered in any way. The term of the license is [one
          year] from the date payment is received by Leap (
          <strong>“Initial Term”</strong>). The term shall automatically renew
          for additional one month periods upon expiration of each one month
          period (each, a<strong>“Renewal Term,”</strong>
          together with the Initial Term, the
          <strong>“Term”</strong>), unless earlier terminated hereunder.
        </li>
        <li>
          <strong>Licensee Covenants.</strong>
          Licensee will not itself nor through any parent, subsidiary, affiliate
          or third party reverse engineer, decompile, disassemble, modify, or
          create derivative works of the Licensed Product or attempt to
          reconstruct or discover any source code, underlying algorithms, file
          formats or programming interfaces of the Licensed Product or defeat,
          disable or circumvent any protection mechanism related to the Licensed
          Product. Licensee shall not directly or indirectly copy, distribute or
          archive the Licensed Product, except to the extent that copying is
          necessary to use the Licensed Product for the limited purpose set
          forth herein. Licensee shall not assign, sublicense, rent, sell,
          timeshare, loan, lease, or otherwise transfer the Licensed Product
          without the prior written consent of Leap nor use the Licensed Product
          to operate a service bureau or to perform service department
          management services for any third party. Licensee shall use the
          Licensed Product in compliance with all applicable foreign, federal,
          state and local laws, rules and regulations, including without
          limitation, the U.S. Department of Commerce export administration
          regulations.
        </li>
        <li>
          <strong>Fees and Payment Terms.</strong>
          <ol>
            <li>
              Licensee shall pay Leap such fees as mutually agreed by the
              parties (<strong>“Fees”</strong>) based on the number of Seat
              Licenses acquired by Licensee. Licensee shall not have access to
              the Licensed Product until such Fees are received by Leap. In the
              event Licensee fails to timely pay any amount due hereunder, Leap
              shall have the right, in its sole discretion, to refuse
              performance, suspend performance, and/or terminate this Agreement
              without incurring any liability and shall be entitled to recover
              all costs and expenses, including reasonable attorneys’ fees,
              incurred in collecting or attempting to collect any amount due
              hereunder.
            </li>
            <li>
              Leap reserves the right to modify its Fees or other charges
              (however characterized) for the Leap services, or to impose
              additional charges for new features or enhancements, at any time
              in Leap’s sole discretion upon thirty (30) days’ notice (which may
              be via email to the administrative contact on your account). Any
              changes to any of your charges will be effective upon the
              commencement of your next billing cycle after the 30-day notice
              period unless otherwise specified. If you do not accept any change
              to your charges, you must terminate the affected services within
              the 30-day notice period (and you will be permitted to do so not
              withstanding anything to the contrary in this Agreement); if you
              do not terminate within this 30-day period, you will be deemed to
              have accepted the change to your charges. In connection with its
              notice to you of any impending change to any of your charges, Leap
              will remind you of your right to terminate the affected services
              and the applicable time period.
            </li>
            <li>
              Undisputed Fees will be paid within 30 days after receipt of the
              applicable invoice by Licensee. You will pay interest in the
              amount of 1.5% per month (or the highest rate permitted by law,
              whichever is lower) on any undisputed Fees not paid within 30 days
              of such due date. If Licensee disputes any invoice, Licensee shall
              pay the undisputed portion and will notify Leap in writing,
              specifying the amount in dispute and its reason for disputing such
              amount and both parties shall use reasonable efforts to resolve
              such dispute. Notwithstanding anything contained herein to the
              contrary, the only Fees that may be disputed are the Fees that are
              charged for the number of Seat Licenses (e.g., Leap has mistakenly
              charged the Licensee for 10 Seat Licenses and the Licensee has
              only five Seat Licenses).
            </li>
            <li>
              All Fees are exclusive of sales tax and other applicable taxes,
              which Licensee will be responsible to pay (except that Leap will
              pay income tax on its own income under this Agreement).
            </li>
          </ol>
        </li>
        <li>
          <strong>Updates and Enhancements; Maitenance.</strong>
          <ol>
            <li>
              During the Term, so long as all amounts due hereunder have been
              paid, Leap may from time to time provide Updates to the Licensed
              Product. “Updates” shall mean new versions of and/or additions to
              the Licensed Product. Updates shall include patches, bug fixes,
              enhancements, or modifications made by Leap to the Licensed
              Product.
            </li>
            <li>
              Leap reserves the right to perform maintenance from time to time
              in connection with the Licensed Product and the Website; Leap will
              provide written notice to you prior to such maintenance if any
              downtime is expected to result.
            </li>
          </ol>
        </li>
        <li>
          <strong>Support Services.</strong>
          During the Term, so long as all amounts due hereunder have been paid,
          Leap shall provide Licensee basic support services relating to the
          Licensed Product as determined by Leap and as may be requested from
          time to time by Licensee such as answering questions about the use or
          operation of the Licensed Product and analyzing and resolving problems
          or issues relating to Licensee’s use of the Licensed Product. In the
          event Leap determines that Licensee’s request for services exceeds the
          scope of the basic support services provided hereunder, Leap shall
          advise Licensee of such and the parties may agree on the additional
          fees to be paid to Leap for such services. Support Services will be
          provided between the hours of 8:30 am and 5:00 pm EST, Monday through
          Friday, excluding holidays.
        </li>
        <li>
          <strong>Authorized Users.</strong> Licensee will not permit the use of
          Seat Licenses which exceed the number of Seat Licenses acquired by
          Licensee to access or use the Licensed Product. The number of Seat
          Licenses may be decreased by Licensee only at the beginning of a
          Renewal Term and only if Licensee provides at least 45 days’ prior
          notice to Leap of such decrease. Any such decrease will result in a
          proportional decrease of the Fees.
        </li>
        <li>
          <strong>Access to the Licensed Product.</strong>
          <ol>
            <li>
              Each Seat License will be assigned to one individual solely
              through a user identification and a password (together, a
              <strong>“Password”</strong>) that will be issued by Leap. YOU ARE
              RESPONSIBLE FOR KEEPING ALL AUTHORIZED USER PASSWORDS STRICTLY
              CONFIDENTIAL AND TAKING OTHER REASONABLE PRECAUTIONS TO PREVENT
              THEIR UNAUTHORIZED USE. Licensee will notify Leap promptly of any
              unauthorized use of a Password of which it becomes aware. We may
              charge you additional fees for each unauthorized user who gains
              access to the Licensed Product without our approval.
            </li>
            <li>
              Subject to the terms and conditions contained in this Agreement,
              Leap grants to you mobile license(s) meant only to be used on the
              Leap mobile application. Licensee acknowledges that mobile
              licenses have limited functionality compared to platform licenses
              and that the mobile applications are to be used on tablet and
              smart phones in order to extend platform functionality in the
              field.
            </li>
          </ol>
        </li>
        <li>
          <strong>Financial Solutions</strong>
          <ol>
            <li>
              The Licensed Product includes an offering of financial products
              and services (each a <strong>“Financial Solution”</strong>). Such
              offerings are subject to certain fees as disclosed to you at the
              time of sign up. By using Financial Solutions, you acknowledge and
              agree that we may use third party technology and service providers
              (<strong>“Third Party Financial Partners”</strong>) and that the
              terms and privacy policies of any such Third Party Financial
              Partners are applicable to you. Leap is not liable for the acts or
              omissions of any third party, including any Third Party Financial
              Partners.
            </li>
            <li>
              Leap does not provide consumer financing, but may partner with a
              Third Party Financial Partner through which you may access their
              consumer financing offerings. When you apply for financing through
              such Third Party Financial Partners, you understand and agree that
              we may share information about your business, including processing
              and payment history, with such Third Party Financial Partner. You
              acknowledge and agree that any such consumer financing is provided
              solely by such Third Party Financial Partner, and Leap has no
              obligations or liability to you or your customers with regard to
              any offerings from, or your dealings with, such Third Party
              Financial Partner.
            </li>
          </ol>
        </li>
        <li>
          <strong>Termination.</strong>
          <ol>
            <li>
              The license granted may be terminated by either party upon at
              least 30 days’ prior written notice to the other or by Leap
              immediately upon written notice to Licensee (A) upon Licensee’s
              breach of this Agreement, and, except for a failure to pay Fees
              which may result in immediate termination, failure to remedy such
              breach within 30 days of receiving written notice of such breach
              from Leap; provided, however, if the same breach occurs more than
              once in any 12 month consecutive period, the cure period for each
              such additional breach will be only five days, (B) Licensee ceases
              operation as a going concern, is the subject of any voluntary or
              involuntary filing in any bankruptcy or insolvency proceeding,
              makes an assignment for the benefit of creditors, is subject to
              the appointment of a receiver, and in each case only if Licensee
              defaults in making any undisputed payments, or admits in writing
              its inability to pay the Fees.
            </li>
            <li>
              Upon the termination of the license for pursuant to paragraphs (A)
              and (B) above, (i) Licensee shall not be discharged from any
              liability or obligation that has arisen hereunder, including
              without limitation, payment in full of all amounts due through the
              remainder of the Term (ii) after the lapse of the notice period
              required to terminate this Agreement, if applicable, all rights
              granted to Licensee shall terminate and revert to Leap and
              Licensee shall be blocked and restricted from any further use of
              the Licensed Product, and (iii) Licensee shall return to Leap or
              destroy all Licensed Product and any other property belonging to
              Leap in Licensee’s possession (and provide Leap with a sworn
              certificate of destruction signed by an officer of Licensee, if
              requested).
            </li>
            <li>
              Provided all Fees due hereunder have been paid in full, if Leap
              breaches any obligation under this Agreement and fails to remedy
              such breach within 30 days of receiving notice of such breach by
              Licensee, Licensee shall have the right to terminate this
              Agreement.
            </li>
            <li>
              You will be responsible for exporting all Customer Data (as
              defined herein) upon expiration or termination of this Agreement.
              Leap shall provide instructions on the retrieval and export of
              Customer Data and provide you with 10 days to export the data.
            </li>
          </ol>
        </li>
        <li>
          <strong>Confidentiality.</strong>Each party (“receiving party”)
          acknowledges and agrees that the other party (“disclosing party”) has
          a legitimate business interest in maintaining the confidentiality the
          disclosing party’s non-public information (
          <strong>“Confidential Information”</strong>) and that the disclosing
          party may disclose or make such information available to the receiving
          party through the use of the Licensed Product and/or pursuant to this
          Agreement. Receiving party agrees that it shall not disclose to any
          person or entity any Confidential Information of the disclosing party
          or use Confidential Information of the disclosing party other than to
          satisfy its rights and obligations under this Agreement or in the use
          of the Licensed Product. Receiving party acknowledges and agrees that
          the Confidential Information is unique, that money damages would not
          be a sufficient remedy for breach of this Section, and that the
          disclosing party is entitled to seek equitable relief including an
          injunction and specific performance, in addition to any other remedies
          available to it, to enforce this Section of this Agreement and,
          provided disclosing party substantially prevails in such action,
          recovery of reasonable expenses and attorneys’ fees in connection with
          such proceedings.
        </li>
        <li>
          <strong>Ownership of the Licensed Product.</strong>
          <ol>
            <li>
              Licensee agrees that it will not claim any right, title, or
              interest (including any copyright, patent, trademark, trade secret
              or other intellectual property or proprietary right) in or to the
              Licensed Product or any Updates other than the limited right to
              use the same in strict accordance with the terms of this
              Agreement. Unless the prior written approval of Leap is obtained,
              Licensee may not modify or change the Licensed Product in any
              manner.
            </li>
            <li>
              Upon learning of any unauthorized possession or use of or access
              to the Licensed Product, you will promptly notify us and furnish
              details of such occurrence within 10 days of learning of such
              unauthorized possession, use or access. Licensee shall implement
              measures reasonably requested by Leap to prevent any such
              unauthorized access to the Licensed Product and shall provide
              assistance as reasonably requested by Leap to protect our rights
              against any third parties relating to such unauthorized use.
            </li>
            <li>
              Licensee will retain all right, title and ownership interest in
              and to all data, content and materials entered and updated using
              the Licensed Product (<strong>“Customer Data”</strong>) and Leap
              may use the Customer Data to perform its services as contemplated
              by this Agreement. Notwithstanding the foregoing, Leap may
              aggregate the metadata and usage data of Licensee collected or
              otherwise made available through the Licensed Product so that the
              results are non-personally identifiable with respect to Licensee
              or any Customer Data (“Aggregated Anonymous Data”). The Aggregated
              Anonymous Data will be owned by Leap, and Licensee acknowledges
              that Leap may use the Aggregated Anonymous Data, both during and
              after the termination or expiration of this Agreement, (i) for its
              own internal, statistical analysis, (ii) to develop and improve
              the Licensed Product, and (iii) to create and distribute reports,
              statistical analysis, examine trends, make comparisons, and
              otherwise generate industry benchmarks, best practice guidelines
              or other materials regarding the use of the Licensed Product. For
              purposes of clarity, nothing in this Section 11.3 gives Leap the
              right (or ability) to publicly identify Customer Data as the
              source or sources of any Aggregated Anonymous Data.
            </li>
            <li>
              The terms of this Section will survive termination of this
              Agreement.
            </li>
          </ol>
        </li>
        <li>
          <strong>NO WARRANTY.</strong>
          <ol>
            <li>
              THE LICENSED PRODUCT IS PROVIDED “AS IS,” “WHERE IS,” “AS
              AVAILABLE,” “WITH ALL FAULTS” AND, TO THE FULLEST EXTENT PERMITTED
              BY LAW, WITHOUT WARRANTY OF ANY KIND. LEAP DISCLAIMS ALL
              WARRANTIES WITH RESPECT TO THE LICENSED PRODUCT, INCLUDING THE
              IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
              PURPOSE, WARRANTIES ARISING FROM COURSE OF DEALING OR USAGE OF
              TRADE, NON-INFRINGEMENT AND TITLE, AND ANY WARRANTIES REGARDING
              QUIET ENJOYMENT, QUALITY OF INFORMATION, SECURITY, RELIABILITY,
              TIMELINESS, AVAILABILITY AND PERFORMANCE OF THE LICENSED PRODUCT.
              LEAP DOES NOT WARRANT THAT THE LICENSED PRODUCT WILL MEET LICENSES
              REQUIREMENTS, OR THAT THE OPERATION OF THE LICENSED PRODUCT WILL
              BE UNINTERRUPTED OR ERROR-FREE, OR THAT OTHER SECURITY MEASURES
              WILL BE SECURE OR EFFECTIVE. LICENSEE UNDERSTANDS AND AGREES THAT
              ITS INSTALLATION, USE AND ACCESS TO THE LICENSED PRODUCT IS AT ITS
              SOLE DISCRETION AND RISK AND THAT LICENSEE IS SOLELY RESPONSIBLE
              FOR ANY DAMAGES TO ITS COMPUTER, SOFTWARE AND THE LOSS OF DATA
              THAT RESULTS FROM THE USE THEREOF. LEAP ASSUMES NO RESPONSIBILITY
              FOR PROPER COLLECTION, VERIFICATION OR STORAGE OF DATA OR IMAGES.
              LICENSEE IS SOLELY RESPONSIBLE FOR THE SECURITY AND INTEGRITY OF
              ITS DATA AND SYSTEMS.
            </li>
            <li>
              LEAP MAKES NOT GUARANTEE OR WARRANTY OF LEGAL COMPLIANCE OF THE
              FEDERAL TOXIC SUBSTANCES CONTROL ACT AND RELATED STATE LAWS,
              NOTWITHSTANDING THE INCLUSION IN ANY FORMS OR DOCUMENTATION.
            </li>
            <li>
              ANY FORMS OR DOCUMENTS PROVIDED OR USED IN ACCORDANCE WITH THE
              FEATURES OF THE LICENSED PRODUCT ARE INTENDED TO BE OPERATIONAL IN
              NATURE AND ARE NOT DESIGNED TO BE LEGAL DOCUMENTS AND DO NOT
              CONSTITUTE LEGAL, TAX, ACCOUNTING OR BUSINESS ADVICE. WHILE SUCH
              FORMS MAY ASSIST YOU IN COMPLYING WITH APPLICABLE LAWS AND
              REGULATIONS, IT IS SOLELY YOUR RESPONSIBILITY TO ENSURE THAT YOUR
              BUSINESS COMPLIES WITH ALL APPLICABLE LAWS AND REGULATIONS. LEAP
              DOES NOT PROVIDE LEGAL, TAX, ACCOUNTING OR BUSINESS ADVICE AND
              PRIOR TO UTILIZING ANY FORM OR DOCUMENTATION OFFERED BY OR THROUGH
              THE LICENSED PRODUCT, LEAP RECOMMENDS THAT YOU CONSULT WITH
              EXPERIENCED AND APPROPRIATE PROFESSIONALS BEFORE USING ANY FORMS
              OR DOCUMENTS.
            </li>
          </ol>
        </li>
        <li>
          <strong>LIMITATION OF LIABILITY AND DAMAGES.</strong> TO THE MAXIMUM
          EXTENT PERMITTED BY LAW, IN NO EVENT SHALL LEAP BE LIABLE TO LICENSEE
          OR ANY THIRD PARTY FOR ANY CONSEQUENTIAL, SPECIAL, INCIDENTAL,
          PUNITIVE, INDIRECT, EXEMPLARY, OR ANY OTHER DAMAGES, INCLUDING, BUT
          NOT LIMITED TO, DAMAGES FOR PERSONAL INJURY, LOST PROFITS, LOSS OF
          DATA AND BACKED-UP DATA, BUSINESS INTERRUPTION OR LOST REVENUES,
          ARISING OUT OF LICENSEE’S USE OR INABILITY TO USE THE LICENSED
          PRODUCT, EVEN IF LEAP HAS BEEN ADVISED ABOUT THE POSSIBILITY OF SUCH
          DAMAGES (WHETHER SUCH DAMAGES ARISE IN CONTRACT, TORT (INCLUDING
          NEGLIGENCE) OR OTHERWISE). IN NO EVENT SHALL LEAP’S CUMULATIVE
          LIABILITY, FOR DAMAGES OF ANY TYPE TO LICENSEE OR ANY THIRD PARTY
          EXCEED THE AMOUNT PAID BY LICENSEE TO LEAP PURSUANT TO THIS AGREEMENT
          WITHIN THE THREE (3) CALENDAR MONTHS PRIOR TO THE ACTION GIVING RISE
          TO THE LIABILITY.
        </li>
        <li>
          <strong>Indemnification.</strong> Licensee shall indemnify and hold
          harmless Leap and its members, employees, and agents from and against
          any and all claims, causes of action, proceedings, damages,
          liabilities, losses, costs and expenses, including attorneys’ fees and
          costs (collectively,
          <strong>“Claims”</strong>), by a third party arising from Licensee’s
          use or misuse of the Licensed Product. Leap shall indemnify and hold
          harmless Licensee and its employees and agents from any and all
          Claims, by a third party alleging that the Licensed Product is
          infringing such third party’s intellectual property rights.
        </li>
        <li>
          <strong>Relationship with Consumers.</strong>
          Licensee is solely responsible for its interactions with consumers.
          Consumers’ rights under contracts a consumer enters into with you are
          governed by the terms of such contracts and by applicable federal,
          state, provincial and local laws. Should a consumer have a dispute
          with you, the consumer must address such dispute with you directly. We
          are not a party or third- party beneficiary to the contracts between
          you. You agree to fully cooperate in the event we receive a complaint
          from a consumer about you or your services.
        </li>
        <li>
          <strong>
            Governing Law, Jurisdiction, and Costs of Enforcement.
          </strong>{' '}
          This Agreement shall be governed in all respects by the laws of the
          State of Maryland without regard to its conflict of laws provisions.
          The parties agree that the exclusive venue and jurisdiction for all
          disputes arising out of or relating to this Agreement shall be in the
          District or Circuit Courts for Montgomery County, Maryland. The
          parties hereby consent to the exclusive venue and jurisdiction of such
          courts and agree to accept service of process pursuant to Maryland
          Rules and procedures. The parties further agree that this Agreement
          shall be deemed to have been entered into in the State the Maryland
          for purposes of jurisdiction. Leap shall be entitled to recover all
          costs and expenses, including attorneys’ fees and costs, incurred in
          enforcing, attempting to enforce, or defending any of the terms,
          covenants or conditions of this Agreement, including costs incurred
          prior to commencement of legal action and in any appeal.
        </li>
        <li>
          <strong>RELEASE.</strong>
          IN EXCHANGE FOR THE SERVICE PROVIDED BY US AND OTHER GOOD AND VALUABLE
          CONSIDERATION, THE RECEIPT AND SUFFICIENCY OF WHICH IS HEREBY
          ACKNOWLEDGED, LICENSEE EXPRESSLY AGREES TO RELEASE LEAP, ITS OFFICERS,
          DIRECTORS, AFFILIATES, PARENT COMPANIES, PARTNERS, EMPLOYEES AND
          AGENTS FROM ANY CLAIMS, DEMANDS, CAUSES OF ACTION, LOSSES AND DAMAGES
          (DIRECT AND CONSEQUENTIAL) OF EVERY KIND AND NATURE, KNOWN AND
          UNKNOWN, SUSPECTED AND UNSUSPECTED, DISCLOSED AND UNDISCLOSED, ARISING
          OUT OF OR IN ANY WAY CONNECTED WITH ANY DISPUTE YOU HAVE, EXCEPT THAT
          THIS RELEASE DOES NOT APPLY TO CLAIMS BY YOU DIRECTLY ARISING OUT OF
          LEAP’S MATERIAL BREACH OF THIS AGREEMENT, OR TO THE EXTENT THE
          FOREGOING RELEASE IS PROHIBITED BY LAW. IF YOU ARE A CALIFORNIA
          RESIDENT, YOU WAIVE CALIFORNIA CIVIL CODE SECTION 1542, WHICH SAYS: “A
          GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT
          KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE
          RELEASE, WHICH, IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS
          SETTLEMENT WITH THE DEBTOR.”
        </li>
        <li>
          <strong>Severability and Modification by Court.</strong>
          If any term or provision of this Agreement shall, for any reason, be
          adjudged by a court of competent jurisdiction to be invalid or
          unenforceable, such judgment shall not affect, impair or invalidate
          the remainder of this Agreement, but shall be confined in its
          operation to the provisions of this Agreement directly involved in the
          controversy in which such judgment shall have been rendered.
          Notwithstanding the above, it is the intent and desire of the parties
          that this Agreement and all of its terms be enforceable and in the
          event any provision as presently set forth is determined to be invalid
          by a court of competent jurisdiction, the parties hereto agree that
          this Agreement shall be appropriately modified by the court so that
          each and every provision hereof is enforceable to the maximum extent
          permitted by law.
        </li>
        <li>
          <strong>Entire Agreement.</strong>
          This Agreement constitutes the entire agreement between Leap and
          Licensee with respect to the subject matter hereof and there are no
          representations, understandings or agreements that are not fully
          expressed in this Agreement. This Agreement shall supersede all other
          agreements between the parties, whether written or oral. This
          Agreement may not be assigned by Licensee without the prior written
          consent of Leap, provided that, in the event that a third party
          acquires all or substantially all of the business of Licensee, consent
          to assignment shall not be required. This Agreement shall be binding
          upon the parties and their respective successors and permitted
          assigns. The failure of Leap to enforce any provision of this
          Agreement shall not in any way be construed as a waiver of any such
          provision, nor prevent Leap from thereafter enforcing each and every
          other provision of this Agreement. The rights granted herein are
          cumulative and shall not constitute a waiver of Leap’s right to assert
          all other legal remedies available to it under the circumstances.
        </li>
        <li>
          <strong>Notices.</strong>
          Any notices required or permitted hereunder shall be in writing and
          hand delivered or sent by United States registered, certified or
          express mail, postage prepaid, return receipt requested, or by a
          recognized national overnight shipping company such as FedEx or UPS
          addressed to the party at its address listed in this Agreement, or via
          email with confirmation of delivery, or to such other address as said
          party may designate in writing from time to time. Notice shall be
          deemed given when received (as indicated by the return receipt or
          otherwise) or on the date such notice is first refused, if that be the
          case.
        </li>
        <li>
          <strong>Headings.</strong>
          The heading of each section is inserted for convenience of reference
          only, and is not intended to be a part of or to affect the meaning or
          interpretation of this Agreement.
        </li>
        <li>
          <strong>WAIVER OF JURTY TRIAL.</strong> THE PARTIES HEREBY EXPRESSLY
          WAIVE ANY RIGHT TO A TRIAL BY JURY FOR ANY DISPUTES ARISING OUT OF
          THIS AGREEMENT.
        </li>
        <li>
          <strong>Force Majeure.</strong> Neither party will be liable for any
          delay in performance or failure to perform, other than paying any
          Fees, resulting from circumstances beyond its control.
        </li>
      </ol>
    </div>
  </>
);

export default TermsOfService;
