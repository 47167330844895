/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Popover, TextArea } from 'leap-menu-item';
import onClickOutside from 'react-onclickoutside';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import BodyCellItemObject from '../Models/BodyCellItem';
import {
  setInitialStateForTemplate,
  setEditCellItem,
} from '../../../actions/templateEdit';
import ContractObject from '../Models/ContractObject';
import { getItemStyle, getListStyle } from '../Utils';
import DetailTextCell from './DetailTextCell';
import { updateDraggableCallback } from '../Body/CellTypes/DetailImage';
import DetailParagraphCell from './DetailParagraphCell';
import { fontFamilyExists } from '../FontSelect';

export const styleForTitle = (
  {
    contractTitle,
    titleFontSize,
    titleFontBold,
    titleFontUnderline,
    titleFontName,
    titleFontColor,
  },
  isHovering,
) => {
  const colorArray = titleFontColor ? titleFontColor.split('-') : [];
  const [r, g, b, a] = colorArray;
  return {
    display: contractTitle ? undefined : 'none',
    cursor: 'pointer',
    padding: '0px 5px',
    fontSize: titleFontSize,
    fontWeight: titleFontBold ? 900 : undefined,
    fontFamily: fontFamilyExists(titleFontName)
      ? titleFontName
      : 'HelveticaNeue',
    color: titleFontColor ? `rgba(${r},${g},${b},${a})` : undefined,
    textDecoration: titleFontUnderline ? 'underline' : undefined,
    opacity: isHovering ? '0.7' : undefined,
  };
};

class DetailCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopoverTitle: false,
      showPopoverNote: false,
    };
    this.titleRef = React.createRef();
    this.noteRef = React.createRef();
  }

  onMouseDown = () => {
    this.props.cellItem.editKey = 'title';
    this.props.setEditItem(this.props.cellItem);
  };

  onContainerDoubleClick = () => {
    this.props.onDoubleClick(this.props.cellItem);
  };

  onDoubleClick = (source) => {
    const key = `showPopover${source}`;
    this.setState({ [key]: true });
  };

  handleClickOutside = () => {
    this.setState({
      showPopoverTitle: false,
      showPopoverNote: false,
    });
  };

  render() {
    const {
      cellItem,
      editCellItem,
      setTemplateState,
      templateValues,
      draggableType,
      setEditItem,
    } = this.props;
    const contractObject = new ContractObject(templateValues.toJSON());
    const updatedObject =
      contractObject.getObjectWithId(cellItem.objectId) || {};
    const { imageHeight, detailItems } = updatedObject;
    const filteredItems = detailItems.filter(({ appOnly }) => appOnly !== true);
    const longTextCells = filteredItems.filter(
      ({ cellType }) => cellType === 'textXLong',
    );
    const noLongTextCells = filteredItems.filter(
      ({ cellType }) => cellType !== 'textXLong',
    );
    const leftItems = noLongTextCells.filter((obj, index) => index % 2 === 0);
    const rightItems = noLongTextCells.filter((obj, index) => index % 2 > 0);
    const imageCell = detailItems.find(
      ({ cellType }) => cellType === 'imagePicker',
    );
    const droppableIdLeft = `${[...cellItem.getPath(), 0].join('-')}-${
      cellItem.objectId
    }-app-${BodyCellItemObject.DetailContainerKey}`;
    const droppableIdRight = `${[...cellItem.getPath(), 1].join('-')}-${
      cellItem.objectId
    }-app-${BodyCellItemObject.DetailContainerKey}`;
    const droppableIdBottom = `${[
      ...cellItem.getPath(),
      leftItems.length + rightItems.length - 1,
    ].join('-')}-${cellItem.objectId}-app-${
      BodyCellItemObject.DetailContainerKey
    }`;
    updateDraggableCallback(
      droppableIdLeft,
      cellItem,
      setTemplateState,
      setEditItem,
    );
    updateDraggableCallback(
      droppableIdRight,
      cellItem,
      setTemplateState,
      setEditItem,
    );
    updateDraggableCallback(
      droppableIdBottom,
      cellItem,
      setTemplateState,
      setEditItem,
    );
    return (
      <div
        className="templates-app__detail_cell"
        onDoubleClick={this.onContainerDoubleClick}
      >
        <div className="templates-app__stacked">
          <Popover
            content={
              <textarea
                onContextMenu={(e) => e.stopPropagation()}
                fill
                ref={this.titleRef}
                defaultValue={updatedObject.appTitle}
                className="ignore-react-onclickoutside"
              />
            }
            isOpen={this.state.showPopoverTitle}
            onClose={() => {
              updatedObject.appTitle = this.titleRef.current.value;
              setTemplateState(contractObject);
            }}
          >
            <div
              className={`templates-app__title${
                cellItem.required ? '_required' : ''
              }`}
              onMouseDown={() => this.onMouseDown()}
              onDoubleClick={() => this.onDoubleClick('title')}
            >
              {updatedObject.appTitle}
            </div>
          </Popover>
          {updatedObject.appNote && (
            <Popover
              className="templates-app__note"
              content={
                <TextArea
                  onContextMenu={(e) => e.stopPropagation()}
                  fill
                  inputRef={this.noteRef}
                  defaultValue={updatedObject.appNote}
                  className="ignore-react-onclickoutside"
                />
              }
              isOpen={this.state.showPopoverNote}
              onClose={() => {
                updatedObject.appNote = this.noteRef.current.value;
                setTemplateState(new ContractObject(contractObject.toJSON()));
              }}
            >
              <div
                onMouseDown={() => this.onMouseDown()}
                onDoubleClick={() => this.onDoubleClick('note')}
              >
                {updatedObject.appNote}
              </div>
            </Popover>
          )}
        </div>
        <div style={{ display: 'flex' }}>
          {imageCell && imageHeight > 0 && (
            <div
              style={{
                paddingLeft: '3px',
                paddingTop: '3px',
                paddingBottom: '3px',
                height: `${imageHeight}px`,
                maxHeight: `${imageHeight}px`,
                width: `${imageHeight}px`,
                maxWidth: `${imageHeight}px`,
              }}
              onClick={() => this.onMouseDown()}
            >
              <img
                src="/images/no_image.png"
                style={{
                  objectFit: 'contain',
                  maxWidth: '100%',
                  maxHeight: '100%',
                }}
                alt="thumbnail"
              />
            </div>
          )}
          <Droppable
            droppableId={droppableIdLeft}
            isDropDisabled={
              draggableType !== BodyCellItemObject.DetailContainerKey
            }
          >
            {(provided, snapshot) => (
              <div
                style={{
                  ...getListStyle(snapshot.isDraggingOver),
                  width: '50%',
                }}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {leftItems.map((cell, index) => (
                  <Draggable
                    key={cell.objectId}
                    draggableId={`${cell.getPath().join('-')}-app-${
                      cell.objectId
                    }`}
                    index={index}
                  >
                    {(draggableProvided, draggableSnapshot) => (
                      <div
                        key={cell.objectId}
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                        style={getItemStyle(
                          draggableSnapshot,
                          draggableProvided.draggableProps.style,
                          cell.objectId === editCellItem.objectId,
                        )}
                      >
                        <DetailTextCell cellItem={cell} />
                        {draggableProvided.placeholder}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <Droppable
            droppableId={droppableIdRight}
            isDropDisabled={
              draggableType !== BodyCellItemObject.DetailContainerKey
            }
          >
            {(provided, snapshot) => (
              <div
                style={{
                  ...getListStyle(snapshot.isDraggingOver),
                  width: '50%',
                }}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {rightItems.map((cell, index) => (
                  <Draggable
                    key={cell.objectId}
                    draggableId={`${cell.getPath().join('-')}-app-${
                      cell.objectId
                    }`}
                    index={index}
                  >
                    {(draggableProvided, draggableSnapshot) => (
                      <div
                        key={cell.objectId}
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                        style={getItemStyle(
                          draggableSnapshot,
                          draggableProvided.draggableProps.style,
                          cell.objectId === editCellItem.objectId,
                        )}
                      >
                        <DetailTextCell cellItem={cell} />
                        {draggableProvided.placeholder}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
        <Droppable
          droppableId={droppableIdBottom}
          isDropDisabled={
            draggableType !== BodyCellItemObject.DetailContainerKey
          }
        >
          {(provided, snapshot) => (
            <div
              style={getListStyle(snapshot.isDraggingOver)}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {longTextCells.map((cell, index) => (
                <Draggable
                  key={cell.objectId}
                  draggableId={`${cell.getPath().join('-')}-app-${
                    cell.objectId
                  }`}
                  index={index}
                >
                  {(draggableProvided, draggableSnapshot) => (
                    <div
                      key={cell.objectId}
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                      style={getItemStyle(
                        draggableSnapshot,
                        draggableProvided.draggableProps.style,
                        cell.objectId === editCellItem.objectId,
                      )}
                    >
                      <DetailParagraphCell cellItem={cell} />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <div className="templates-app__cell_bottom_border" />
      </div>
    );
  }
}

DetailCell.propTypes = {
  editCellItem: PropTypes.any,
  cellItem: PropTypes.instanceOf(BodyCellItemObject).isRequired,
  setTemplateState: PropTypes.func.isRequired,
  templateValues: PropTypes.shape({}).isRequired,
  setEditItem: PropTypes.func.isRequired,
  draggableType: PropTypes.string,
  onDoubleClick: PropTypes.func.isRequired,
};

DetailCell.defaultProps = {
  editCellItem: {},
  draggableType: undefined,
};

const mapStateToProps = ({ templatesEdit }) => ({
  templateValues: templatesEdit[templatesEdit.currentWorkingId],
  editCellItem: templatesEdit.editCellItem,
  draggableType: templatesEdit.draggableType,
});

const mapDispatchToProps = (dispatch) => ({
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
  setEditItem: (cellItem) => dispatch(setEditCellItem(cellItem)),
});

const wrappedComponent = onClickOutside(DetailCell);
export default connect(mapStateToProps, mapDispatchToProps)(wrappedComponent);
