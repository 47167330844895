/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import Parse from 'parse';
import { handleError } from './auth';
import { showLoader, hideLoader } from './loading';
import { pushToDataLayer } from './tagManager';
import { history } from '../router';

export const setCreditApplications = (items) => ({
  type: 'SET_CREDIT_APPLICATIONS',
  items,
});

export const setCreditApplication = (object) => ({
  type: 'SET_CREDIT_APPLICATION',
  object,
});

export const setPartnerCreditApplication = (items) => ({
  type: 'SET_PARTNER_CREDIT_APPLICATIONS',
  items,
});

export const getCreditApplications = (includedOffices = []) => async (
  dispatch,
  getState,
) => {
  try {
    const {
      auth: { offices },
      plan = {},
    } = getState();

    const query = new Parse.Query('CreditApplicationObject');

    const keys = [
      'image',
      'title',
      'partnerCreditApplication.image',
      'partnerCreditApplication.title',
    ];
    query.select(keys);

    if (plan.maxOfficeCount === 1) {
      includedOffices = [offices[0].id];
    }

    if (includedOffices.length) {
      const includedOfficesFilter = includedOffices.map((objectId) => {
        const office = new Parse.Object('Office');
        office.id = objectId;
        return office;
      });
      query.containedIn('includedOffices', includedOfficesFilter);
    } else {
      query.notContainedIn('includedOffices', offices);
    }
    query.ascending('orderNumber');
    const items = await query.find();

    const itemJsons = items.map((item) => {
      const itemJson = {
        objectId: item.id,
        title: item.get('title'),
        image: item.get('image') ? item.get('image').toJSON() : undefined,
      };
      const partnerCreditApplication = item.get('partnerCreditApplication');
      if (partnerCreditApplication) {
        itemJson.partnerCreditApplication = partnerCreditApplication.toJSON();
      }
      return itemJson;
    });
    dispatch(setCreditApplications(itemJsons));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const startFetchCreditApplication = (id) => async (dispatch) => {
  try {
    const query = new Parse.Query('CreditApplicationObject');
    query.select([
      'image',
      'title',
      'partnerCreditApplication.image',
      'partnerCreditApplication.title',
      'partnerCreditApplication.sections',
      'includedOffices',
      'sections',
      'pdfDisclosure',
    ]);
    const creditApplicationData = await query.get(id);

    dispatch(
      pushToDataLayer({
        event: 'creditApplicationEvent',
        eventCategory: 'Credit Applications',
        eventAction: 'View',
        eventLabel: creditApplicationData.get('title'),
      }),
    );
    dispatch(setCreditApplication(creditApplicationData));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const getPartnerCreditApplications = () =>
  // Not sure if this is supposed to return items each time or not?
  // eslint-disable-next-line consistent-return
  async (dispatch) => {
    try {
      const query = new Parse.Query('PartnerCreditApplication');
      query.select(['image', 'title', 'source']);
      query.ascending('title');
      const items = await query.find();
      dispatch(setPartnerCreditApplication(items));
      return items;
    } catch (e) {
      dispatch(handleError(e));
    }
  };

export const copyPartnerCreditApplication = (partnerApplictionData) => async (
  dispatch,
) => {
  try {
    dispatch(
      pushToDataLayer({
        event: 'creditApplicationEvent',
        eventCategory: 'Credit Applications',
        eventAction: 'Copy',
        eventLabel: partnerApplictionData.get('title'),
      }),
    );
    dispatch(showLoader('Copying Partner CreditApplication Data'));
    const query = new Parse.Query('PartnerCreditApplication');
    const partnerCreditApplication = await query.get(partnerApplictionData.id);
    const { title, source } = partnerCreditApplication.toJSON();
    const creditApplicationObject = new Parse.Object('CreditApplicationObject');
    creditApplicationObject.set(
      'partnerCreditApplication',
      partnerCreditApplication.toPointer(),
    );
    creditApplicationObject.set(
      'company',
      partnerCreditApplication.get('company'),
    );
    await creditApplicationObject.save({
      title,
      includedOffices: [],
      source,
    });
    dispatch(hideLoader());
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const updateCreditApplicationOrder = ({
  updates,
  creditApplications,
}) => async (dispatch) => {
  try {
    dispatch(showLoader('Updating Order Number'));
    const creditApplicationsUpdates = updates.map((credApp) => {
      const creditApplicationObject = new Parse.Object(
        'CreditApplicationObject',
      );
      creditApplicationObject.id = credApp.objectId;
      creditApplicationObject.set('orderNumber', credApp.orderNumber);
      return creditApplicationObject.save();
    });
    await Promise.all(creditApplicationsUpdates);
    dispatch(setCreditApplications(creditApplications));
    dispatch(hideLoader());
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const deleteCreditApplication = (id) => async (dispatch, getState) => {
  try {
    const creditApplicationObject = new Parse.Object('CreditApplicationObject');
    creditApplicationObject.id = id;
    await creditApplicationObject.destroy();
    dispatch(
      pushToDataLayer({
        event: 'creditApplicationEvent',
        eventCategory: 'Credit Applications',
        eventAction: 'Delete',
        eventLabel: creditApplicationObject.get('title'),
      }),
    );
    const {
      creditApplications: { items },
    } = getState();
    const updates = items.filter((item) => item.objectId !== id);
    dispatch(setCreditApplications(updates));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const saveCreditApplication = ({ creditApplicationData }) => async (
  dispatch,
) => {
  try {
    dispatch(showLoader('Saving Credit Application'));

    const { newFileData, title, includedOffices = [] } = creditApplicationData;
    const creditApplicationObject = new Parse.Object('CreditApplicationObject');
    creditApplicationObject.id = creditApplicationData.objectId;
    const includedOfficesList = includedOffices.map((office) => {
      const { __type } = office;
      if (__type === 'Pointer') {
        return office;
      }
      return office.toPointer();
    });
    if (newFileData) {
      creditApplicationObject.set(
        'image',
        new Parse.File(newFileData.name, newFileData),
      );
    }
    await creditApplicationObject.save({
      title,
      includedOffices: includedOfficesList,
    });

    dispatch(
      pushToDataLayer({
        event: 'creditApplicationEvent',
        eventCategory: 'Credit Applications',
        eventAction: 'Edit',
        eventLabel: creditApplicationData.title,
      }),
    );

    dispatch(hideLoader());
  } catch (e) {
    dispatch(handleError(e));
  } finally {
    dispatch(hideLoader());
  }
};

export const startAddDefaultApplication = (includedOffices = []) => async (
  dispatch,
) => {
  try {
    const config = await Parse.Config.get();
    const defaultApp = config.get('defaultCreditApplication');
    const newApp = new Parse.Object('CreditApplicationObject');
    const appOffices = includedOffices.map((id) => {
      const office = new Parse.Object('Office');
      office.id = id;
      return office.toPointer();
    });
    newApp.set('includedOffices', appOffices);
    const creditAppData = {
      ...defaultApp,
      includedOffices: appOffices,
    };
    const response = await newApp.save({ ...creditAppData });
    const editUrl = `/credit_applications/edit/${response.id}`;
    history.push(editUrl);
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const setEditCellItem = (editCellItem) => ({
  type: 'CREDIT_APPLICATIONS_SET_EDIT_CELL_ITEM',
  editCellItem,
});

export const setCreditApplicationCommit = (commit) => ({
  type: 'CREDIT_APPLICATIONS_SET_COMMIT',
  commit,
});
