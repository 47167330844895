/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

const NetWrapper = (props) => {
  const { children } = props;

  const [open, setOpen] = useState(false);
  return (
    <div onMouseDown={() => setOpen(true)} onMouseUp={() => setOpen(false)}>
      {open && <div className="net-wrapper-inner-net" />}
      {children}
    </div>
  );
};

NetWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default NetWrapper;
