// @ts-check
import moment from 'moment';

/**
 * @typedef {'date' |
 *  'datetime-local' |
 *  'email' |
 *  'month' |
 *  'number' |
 *  'tel' |
 *  'text' |
 *  'time' |
 *  'week'
 * } InputType Input types we can validate against
 * Object keys map to: formData > inputType
 * Object values are objects whos keys map to various validation props on an input
 * pattern is a regex source
 * @type {{
 *  [inputType: string]: {
 *   type?: InputType,
 *   minLength?: number,
 *   maxLength?: number,
 *   min?: number | string,
 *   max?: number | string,
 *   pattern?: string,
 *  }
 * }}
 */
const validators = {
  phone: {
    type: 'tel',
    pattern: /\d{3}-\d{3}-\d{4}/.source,
  },
  default: {},
  picker: {},
  keypad: {},
  keypadDecimal: {},
  currency: {},
  currencyWhole: {},
  email: {
    type: 'email',
    pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      .source,
  },
  numbers: {
    type: 'number',
    min: 0,
    max: 1000000000000,
  },
  datePicker: {
    type: 'date',
    // I don't anticipate ever having to use a date before the founding of the country
    min: moment('07/04/1776').format('MM/DD/YYYY'),
  },
  dateTimePicker: {},
  timePicker: {},
  formula: {},
  formulaWhole: {},
  formulaCurrency: {},
  formulaCurrencyWhole: {},
  creditCard: {},
  ccExpDate: {},
  dynamic: {},
  financeOptionsPicker: {},
  ssn: {
    type: 'tel',
    pattern: /\d{3}-\d{2}-\d{4}/.source,
  },
  statePicker: {},
  zipCode: {
    type: 'tel',
    minLength: 5,
    maxLength: 5,
  },
};

export default validators;
