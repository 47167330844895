import React from 'react';
import PropTypes from 'prop-types';
import LoadingOverlay from 'react-loading-overlay';
import { useSelector } from 'react-redux';

const Loader = ({ children }) => {
  const loadingObj = useSelector(({ loading }) => loading);

  return (
    <LoadingOverlay
      active={loadingObj.show !== false}
      spinner
      text={loadingObj.message}
      styles={{
        overlay: (base) => ({
          ...base,
          background: 'rgba(0, 0, 0, 0.5)',
          backdropFilter: 'blur(5px)',
        }),
      }}
    >
      {children}
    </LoadingOverlay>
  );
};

Loader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};
Loader.defaultProps = {
  children: null,
};

export default Loader;
