import React from 'react';
import PropTypes from 'prop-types';

import Switch from '../Misc/Switch';
import useZapActions from '../../hooks/Zaps/useZapActions';

const ZapSwitch = (props) => {
  const { value, item } = props;
  const { onActiveSwitch } = useZapActions(item);

  return <Switch onChange={onActiveSwitch} checked={value} />;
};

ZapSwitch.propTypes = {
  item: PropTypes.shape({}).isRequired,
  value: PropTypes.bool.isRequired,
};

export default ZapSwitch;
