/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import LeapBarChartPanel from './BarCharts/LeapBarChartPanel';
import LeapLineChartPanel from './LineCharts/LeapLineChartPanel';
import LeapPiePanel from './PieCharts/LeapPiePanel';

const ChartSelect = (props) => {
  const { chartName } = props;
  switch (chartName) {
    case 'LeapBarChartPanel': {
      return <LeapBarChartPanel {...props} />;
    }
    case 'LeapLineChartPanel': {
      return <LeapLineChartPanel {...props} />;
    }
    case 'LeapPiePanel': {
      return <LeapPiePanel {...props} />;
    }
    default: {
      return <span />;
    }
  }
};

export default ChartSelect;
