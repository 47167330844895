/* eslint-disable import/no-cycle */
/* eslint-disable react/no-access-state-in-setstate */

/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import Parse from 'parse';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import { Button } from '@blueprintjs/core';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import { jsx } from '@emotion/core';
import { withUserContext } from '../UserContext';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import Panel from '../Panel';
import FormGroup, { TextGroup, DateRangePickerFormGroup } from '../FormGroup';
import {
  startCountActiveUsers,
  startFetchDocuSigns,
  setDocuSignSearchText,
  setDocuSignPagination,
  setDocuSignFilterStartDate,
  setDocuSignFilterEndDate,
} from '../../actions/account';
import { startRefreshCompany, removeQueryParameter } from '../../actions/auth';
import { filterByText } from '../../selectors/account';
import Paginator from '../Misc/Paginator';
import PaymentMethodModal from '../PaymentMethodModal';
import AppToaster from '../../utils/AppToaster';
import BillingContactModal from '../BillingContactModal';
import { HistoryLink } from '../SharedComponents/HistoryLink';

export class InfoBilling extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      docuSignPage: 1,
      docuSignLimit: 5,
      docuSignStartDate: moment().startOf('month'),
      docuSignEndDate: moment(),
      limit: 5,
      skip: 0,
      page: 1,
      calendarFocused: null,
      company: {
        paymentHistory: [],
      },
      accountDetails: {
        costPerLicense: 0,
        numberOfLicenses: 0,
        totalMonthlyCost: 0,
        nextPaymentDueDate: '',
      },
      showModal: this.props.showCapture,
      showBillingContactModal: false,
      billingContact: {
        firstName: '',
        lastName: '',
        email: '',
      },
    };
    if (this.props.showCapture) {
      this.props.removeQueryParameter('showcapture');
    }
  }

  updatePagination(update) {
    this.setState(update, () => this.getPaymentHistory());
  }

  async getPaymentHistory() {
    const { limit = 10, page = 1 } = this.state;
    const skip = (page - 1) * limit;
    try {
      const paymentHistory = await Parse.Cloud.run('paymentHistory', {
        limit,
        skip,
      });
      this.setState((prev) => ({
        company: {
          ...prev.company,
          paymentHistory,
        },
      }));
    } catch (err) {
      this.setState((prev) => ({
        company: {
          ...prev.company,
          paymentHistory: [],
        },
      }));
    }
  }

  async componentDidMount() {
    this.props.setButtons('');
    if (this.props.loggedIn) {
      if (!this.props.activeUsers) {
        this.props.startCountActiveUsers();
      }
      if (!this.props.docusign.loaded) {
        this.props.startFetchDocuSigns({
          startDate: this.state.docuSignStartDate,
          endDate: this.state.docuSignEndDate,
          limit: this.state.docuSignLimit,
          page: this.state.docuSignPage,
        });
      }
      this.fetchAccountDetails();
      this.fetchPaymentMethod();
      this.fetchBillingContact();
      this.getPaymentHistory();
    }

    this.props.setCrumbs([{ title: 'Info/Billing', link: '/account' }]);
  }

  componentWillUnmount() {
    this.props.setCrumbs([]);
    this.props.setButtons('');
  }

  onDatesChange = ({ startDate, endDate }) => {
    const docuSignStartDate = startDate || this.state.docuSignStartDate;
    const docuSignEndDate = endDate || this.state.docuSignEndDate;
    this.setState({
      docuSignStartDate,
      docuSignEndDate,
      docuSignPage: 1,
    });
    this.props.startFetchDocuSigns({
      startDate: docuSignStartDate,
      endDate: docuSignEndDate,
      limit: this.state.docuSignLimit,
      docuSignPage: 1,
    });
  };

  updateDocuSignLimit = (limit) => {
    this.setState({
      docuSignLimit: limit,
    });
    this.props.startFetchDocuSigns({
      startDate: this.state.docuSignStartDate,
      endDate: this.state.docuSignEndDate,
      limit,
      page: this.state.docuSignPage,
    });
  };

  updateDocuSignPage = (page) => {
    this.setState({
      docuSignPage: page,
    });
    this.props.startFetchDocuSigns({
      startDate: this.state.docuSignStartDate,
      endDate: this.state.docuSignEndDate,
      limit: this.state.docuSignLimit,
      page,
    });
  };

  onFocusChange = (calendarFocused) => {
    this.setState(() => ({ calendarFocused }));
  };

  onPaymentMethodClick = () => {
    this.setState(() => ({ showModal: true }));
  };

  onChangePaymentMethod = async (paymentToken, zip) => {
    try {
      this.setState({ showModal: false });
      await Parse.Cloud.run('leapPaymentMethod', {
        paymentToken,
        isCorporate: true,
        zip,
      });
      await this.props.startRefreshCompany();
      this.fetchPaymentMethod();
    } catch (e) {
      AppToaster.show({ message: e.message, timeout: 5000 });
    }
  };

  onBillingContactClicked = async () => {
    this.setState(() => ({ showBillingContactModal: true }));
  };

  onChangeBillingContact = async ({ firstName, lastName, email }) => {
    try {
      await Parse.Cloud.run('updateBillingContact', {
        firstName,
        lastName,
        email,
      });
      this.setState({ billingContact: { firstName, lastName, email } });
    } catch (e) {
      AppToaster.show({ message: e.message, timeout: 5000 });
    } finally {
      this.setState({ showBillingContactModal: false });
    }
  };

  fetchPaymentMethod = async () => {
    if (this.props.company.paymentMethod) {
      try {
        const paymentMethod = await this.props.company.paymentMethod.fetch();
        this.setState(() => ({
          paymentMethodNumber: paymentMethod.get('number'),
        }));
      } catch (error) {
        this.setState(() => ({
          paymentMethodNumber: error.message,
        }));
      }
    } else {
      this.setState({ paymentMethodNumber: '' });
    }
  };

  fetchAccountDetails = async () => {
    if (!this.props.company.isDemo) {
      try {
        const { objectId } = this.props.company;
        const accountDetails = await Parse.Cloud.run('accountDetails', {
          companyId: objectId,
        });
        this.setState(accountDetails);
      } catch (e) {
        AppToaster.show({
          message: 'Error fetching account data, contact Leap support',
          timeout: 5000,
        });
      }
    }
  };

  fetchBillingContact = async () => {
    try {
      const { firstName, lastName, email } = await Parse.Cloud.run(
        'getBillingContact',
      );
      this.setState({ billingContact: { firstName, lastName, email } });
    } catch (e) {
      AppToaster.show({
        message: `Error fetching Billing Contact: ${e.message}`,
      });
    }
  };

  render() {
    const { docusign, company, activeUsers, style, planName } = this.props;
    const {
      paymentMethodNumber,
      costPerLicense = 0,
      numberOfLicenses = 0,
      totalMonthlyCost = 0,
      nextPaymentDueDate = '',
      billingContact = {},
    } = this.state;
    const { objects = [], searchText, count } = docusign;
    const { firstName, lastName, email } = billingContact;
    return (
      <>
        <PaymentMethodModal
          show={this.state.showModal}
          onClose={() => this.setState(() => ({ showModal: false }))}
          onChange={this.onChangePaymentMethod}
          style={style}
        />
        <BillingContactModal
          show={this.state.showBillingContactModal}
          onClose={() => this.setState({ showBillingContactModal: false })}
          onChange={this.onChangeBillingContact}
          firstName={firstName}
          lastName={lastName}
          email={email}
        />
        <div className="default-page-padding">
          <Panel title="Info/Billing">
            <FormGroup title="Plan">
              <div>{planName}</div>
            </FormGroup>
            <FormGroup title="Company Name">
              <div>{company.name}</div>
            </FormGroup>
            <FormGroup title="Next Billing Date">
              <div>{moment(nextPaymentDueDate).format('M/D/YYYY')}</div>
            </FormGroup>
            <FormGroup title="Used Licenses">
              <div>{activeUsers}</div>
            </FormGroup>
            <FormGroup title="Allowed Licenses">
              <div>{numberOfLicenses}</div>
            </FormGroup>
            <FormGroup title="Cost Per License">
              <div>{`$${costPerLicense}/mo`}</div>
            </FormGroup>
            <FormGroup title="Total Monthly Cost">
              <div>{`$${totalMonthlyCost}`}</div>
            </FormGroup>
            <FormGroup title="Payment Method">
              <div
                style={{
                  float: 'left',
                  display: 'flex',
                  verticalAlign: 'middle',
                }}
              >
                <span style={{ margin: 'auto 5px auto 0' }}>
                  {paymentMethodNumber || undefined}
                </span>
                <Button minimal onClick={this.onPaymentMethodClick}>
                  {paymentMethodNumber ? (
                    <i className="fas fa-pencil-alt" />
                  ) : (
                    'Add'
                  )}
                </Button>
              </div>
            </FormGroup>
            <FormGroup title="Billing Contact">
              <div
                style={{
                  float: 'left',
                  display: 'flex',
                  verticalAlign: 'middle',
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span style={{ margin: 'auto 5px auto 0' }}>
                    {`${firstName} ${lastName}`}
                  </span>
                  <span style={{ margin: 'auto 5px auto 0' }}>{email}</span>
                </div>
                <Button minimal onClick={this.onBillingContactClicked}>
                  <i className="fas fa-pencil-alt" />
                </Button>
              </div>
            </FormGroup>
          </Panel>

          <Panel title="Payment History">
            <Table bordered width="100%">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Date</th>
                  <th>Description</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {!this.state.company.paymentHistory.length && (
                  <tr>
                    <td colSpan="4">None</td>
                  </tr>
                )}
                {this.state.company.paymentHistory.map((transaction) => {
                  const { objectId, note, total, date, number } = transaction;
                  return (
                    <tr key={objectId}>
                      <td>{number}</td>
                      <td>{moment(date).format('M/D/YYYY')}</td>
                      <td width="300px">{note}</td>
                      <td style={{ textAlign: 'right' }}>{total.toFixed(2)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <Paginator
              noCount
              page={this.state.page}
              limit={this.state.limit}
              onLimitChanged={({ value }) =>
                this.updatePagination({ limit: value })
              }
              pageRange={5}
              onPageClicked={(value) => this.updatePagination({ page: value })}
            />
          </Panel>

          <Panel title="DocuSigns">
            <div>
              <TextGroup
                title="Filter"
                placeholder="Search Text"
                value={searchText}
                onChange={(value) => this.props.setDocuSignSearchText(value)}
              />
              <DateRangePickerFormGroup title="Date Filter">
                <DateRangePicker
                  startDate={this.state.docuSignStartDate}
                  startDateId="start_date"
                  endDate={this.state.docuSignEndDate}
                  endDateId="end_date"
                  onDatesChange={this.onDatesChange}
                  focusedInput={this.state.calendarFocused}
                  onFocusChange={this.onFocusChange}
                  showClearDates
                  noBorder
                  hideKeyboardShortcutsPanel
                  numberOfMonths={1}
                  isOutsideRange={(date) =>
                    date.valueOf() >= moment().valueOf()
                  }
                />
              </DateRangePickerFormGroup>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th style={{ width: '50px' }}>Status</th>
                    <th style={{ width: '80px' }}>Date</th>
                    <th style={{ width: '60px' }}>Time</th>
                    <th style={{ width: '50%' }}>Customer</th>
                    <th style={{ width: '50%' }}>Created By</th>
                    <th style={{ width: '35px' }}>PDF</th>
                  </tr>
                </thead>
                <tbody>
                  {objects.map(
                    ({
                      objectId,
                      customer,
                      createdBy,
                      createdAt,
                      completed,
                      file = {},
                    }) => (
                      <tr key={objectId}>
                        <td className="center">
                          <i
                            className={`${
                              completed
                                ? 'fas fa-check-circle green'
                                : 'far fa-clock'
                            } fill-cell`}
                          />
                        </td>
                        <td style={{ textAlign: 'right' }}>
                          {moment(createdAt).format('M/D/YYYY')}
                        </td>
                        <td style={{ textAlign: 'right' }}>
                          {moment(createdAt).format('h:mma')}
                        </td>
                        <td>
                          <HistoryLink
                            href={`/estimate_details/${customer.objectId ||
                              customer.id}`}
                          >
                            {customer
                              ? `${customer.nameFirst} ${customer.nameLast}`
                              : '-'}
                          </HistoryLink>
                        </td>
                        <td>
                          {createdBy
                            ? `${createdBy.nameFirst} ${createdBy.nameLast}`
                            : '-'}
                        </td>
                        <td className="center">
                          <a
                            href={file.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="fas fa-paperclip fill-cell" />
                          </a>
                        </td>
                      </tr>
                    ),
                  )}
                </tbody>
              </Table>
              <Paginator
                page={this.state.docuSignPage}
                limit={this.state.docuSignLimit}
                totalCount={count}
                onLimitChanged={({ value }) => this.updateDocuSignLimit(value)}
                pageRange={5}
                onPageClicked={(value) => this.updateDocuSignPage(value)}
              />
            </div>
          </Panel>
        </div>
      </>
    );
  }
}

InfoBilling.propTypes = {
  company: PropTypes.shape({
    objectId: PropTypes.string.isRequired,
    maxActiveUsers: PropTypes.number.isRequired,
  }).isRequired,
  startCountActiveUsers: PropTypes.func.isRequired,
  activeUsers: PropTypes.number,
  startFetchDocuSigns: PropTypes.func.isRequired,
  setDocuSignSearchText: PropTypes.func.isRequired,
  setDocuSignPagination: PropTypes.func.isRequired,
  setDocuSignFilterStartDate: PropTypes.func.isRequired,
  setDocuSignFilterEndDate: PropTypes.func.isRequired,
  startRefreshCompany: PropTypes.func.isRequired,
  removeQueryParameter: PropTypes.func.isRequired,
  docusign: PropTypes.shape({
    searchText: PropTypes.string,
    limit: PropTypes.number,
    page: PropTypes.number,
    count: PropTypes.number,
    loaded: PropTypes.bool,
    objects: PropTypes.arrayOf(
      PropTypes.shape({
        objectId: PropTypes.string.isRequired,
        customer: PropTypes.shape({
          nameFirst: PropTypes.string.isRequired,
          nameLast: PropTypes.string.isRequired,
        }),
        createdBy: PropTypes.shape({
          nameFirst: PropTypes.string.isRequired,
          nameLast: PropTypes.string.isRequired,
        }),
        completed: PropTypes.bool,
        file: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }),
      }),
    ),
    startDate: PropTypes.instanceOf(moment),
    endDate: PropTypes.instanceOf(moment),
  }),
  planName: PropTypes.string,
  ...layoutContextPropTypes,
};

InfoBilling.defaultProps = {
  activeUsers: 0,
  docusign: {
    searchText: '',
    limit: 10,
    page: 0,
    count: 0,
    objects: [],
  },
  company: {},
  planName: '',
};

const mapStateToProps = ({ auth, account, config: { styles = {} } }) => {
  const { company, plan = {} } = auth;
  const { docusign = {} } = account;
  const {
    searchText = '',
    limit = 10,
    page = 1,
    objects,
    count = 0,
    loaded = false,
    startDate = moment().startOf('month'),
    endDate = moment(),
  } = docusign;

  const { objects: docusigns } =
    filterByText(objects, searchText, limit, (page - 1) * limit) || {};
  return {
    company: company
      ? {
          ...company.toJSON(),
          paymentMethod: company.get('paymentMethod'),
          paymentHistory: company.get('paymentHistory'),
        }
      : {},
    activeUsers: account.activeUsers,
    docusign: {
      count,
      searchText,
      limit,
      page,
      loaded,
      objects: docusigns,
      startDate,
      endDate,
    },
    showCapture: auth.query ? auth.query.showcapture === 'true' : false,
    style: styles.TitleButton,
    planName: plan && plan.get ? plan.get('name') : '',
  };
};

const mapDispatchToProps = (dispatch) => ({
  startCountActiveUsers: () => dispatch(startCountActiveUsers()),
  startFetchDocuSigns: (params) => dispatch(startFetchDocuSigns(params)),
  setDocuSignSearchText: (text) => dispatch(setDocuSignSearchText(text)),
  setDocuSignPagination: (page, limit) =>
    dispatch(setDocuSignPagination(page, limit)),
  setDocuSignFilterStartDate: (startDate) =>
    dispatch(setDocuSignFilterStartDate(startDate)),
  setDocuSignFilterEndDate: (endDate) =>
    dispatch(setDocuSignFilterEndDate(endDate)),
  startRefreshCompany: () => dispatch(startRefreshCompany()),
  removeQueryParameter: (key) => dispatch(removeQueryParameter(key)),
});

export default withUserContext(
  withLayoutContext(connect(mapStateToProps, mapDispatchToProps)(InfoBilling)),
);
