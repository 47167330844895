/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import FormControl, {
  TextGroup,
  SwitchGroup,
  PasswordGroup,
} from '../FormGroup';
import { startUpdateConfig } from '../../actions/auth';
import Panel from '../Panel';
import TitleButton from '../TitleButton';
import Copy from '../AppSettings/Copy';
import { showDeleteConfirmation } from '../../actions/deleteConfirmation';
import { pushToDataLayer } from '../../actions/tagManager';

const enrollWithAmerifirstLink = 'https://leap.trustamerifirst.com/signup/';

export class Amerifirst extends React.Component {
  constructor(props) {
    super(props);
    const { amerifirst = {} } = props;
    const { docuSign = { signer: { email: '', name: '' } } } = amerifirst;
    const { password, ...credentialData } = amerifirst;
    this.state = {
      ...credentialData,
      docuSign: {
        ...docuSign,
        signer: {
          ...docuSign.signer,
        },
      },
    };
  }

  componentDidMount() {
    const {
      setCrumbs,
      setButtons,
      location: { pathname },
    } = this.props;
    const crumb = { title: 'Amerifirst', link: pathname };
    setCrumbs([crumb]);
    setButtons(
      <TitleButtons
        onClearClick={this.askForClearPermission}
        onCopyClicked={this.onCopyClicked}
        onSaveClicked={this.onSaveClicked}
      />,
    );
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { amerifirst = {} } = newProps;
    const { docuSign = { signer: { email: '', name: '' } } } = amerifirst;
    const { password, ...credentialData } = amerifirst;
    this.setState({
      ...credentialData,
      docuSign: {
        ...docuSign,
        signer: {
          ...docuSign.signer,
        },
      },
    });
  }

  componentWillUnmount() {
    const { setCrumbs, setButtons } = this.props;
    setCrumbs([]);
    setButtons();
  }

  onEnabledChanged = (checked) => {
    this.setState({ enabled: checked });
    if (!checked) {
      this.setState({
        password: '',
        username: '',
        docuSign: {
          emailSubject: '',
          returnURL: '',
          signer: { email: '', name: '' },
        },
      });
      this.props.pushToDataLayer({
        event: 'integrationEvent',
        eventCategory: 'Integration',
        eventAction: 'Disable',
        eventLabel: 'Amerifirst',
      });
    } else {
      this.props.pushToDataLayer({
        event: 'integrationEvent',
        eventCategory: 'Integration',
        eventAction: 'Enable',
        eventLabel: 'Amerifirst',
      });
    }
  };

  onClearClick = () => {
    this.onEnabledChanged(false);
  };

  onCopyClicked = () => {
    this.setState({ showCopy: true });
  };

  onSaveClicked = () => {
    const { ...updates } = this.state;
    if (updates.password) {
      updates.encrypted = false;
    }
    this.props.startUpdateConfig({ amerifirst: updates });
  };

  askForClearPermission = () => {
    this.props.showDeleteConfirmation({
      message: 'Are you sure you want to clear this integration?',
      title: 'Clear Integration?',
      onConfirm: this.onClearClick,
    });
  };

  enrollWithAmeriFirst = () => {
    window.location.href = enrollWithAmerifirstLink;
  };

  updateConfig = (key, value) => {
    const keySplit = key.split('.');
    const config = { ...this.state };
    switch (keySplit.length) {
      case 1:
        config[keySplit[0]] = value;
        break;
      case 2:
        config[keySplit[0]][keySplit[1]] = value;
        break;
      case 3:
        config[keySplit[0]][keySplit[1]][keySplit[2]] = value;
        break;
      default:
        break;
    }
    this.setState(config);
  };

  render() {
    return (
      <>
        <Copy
          title="Copy Amerifirst Settings"
          show={this.state.showCopy}
          warning="Warning! This will overwrite your current Amerifirst settings"
          configKeys={['amerifirst']}
          onClose={() => this.setState({ showCopy: false })}
        />
        <div className="default-page-padding">
          <Panel title="Amerifirst">
            <div>
              <SwitchGroup
                title="Enabled"
                checked={this.state.enabled}
                onChange={this.onEnabledChanged}
              />
              {this.state.enabled && (
                <div>
                  <TextGroup
                    title="Branch Id"
                    value={this.state.branchId}
                    placeholder="Branch Id"
                    onChange={(value) => {
                      this.updateConfig('branchId', value);
                    }}
                  />
                  <TextGroup
                    title="Username"
                    value={this.state.username}
                    placeholder="Username"
                    onChange={(value) => {
                      this.updateConfig('username', value);
                    }}
                  />
                  <PasswordGroup
                    title="Password"
                    value={this.state.password}
                    placeholder="hidden"
                    onChange={(value) => {
                      this.updateConfig('password', value);
                    }}
                  />
                  <FormControl title="">
                    <h3> Amerifirst Document Sending Settings </h3>
                  </FormControl>
                  <TextGroup
                    title="Subject"
                    value={this.state.docuSign.emailSubject}
                    placeholder="Email Subject"
                    onChange={(value) => {
                      this.updateConfig('docuSign.emailSubject', value);
                    }}
                  />
                  <TextGroup
                    title="Signer Name"
                    value={this.state.docuSign.signer.name}
                    placeholder="Signer name"
                    onChange={(value) => {
                      this.updateConfig('docuSign.signer.name', value);
                    }}
                  />
                  <TextGroup
                    title="Signer Email"
                    value={this.state.docuSign.signer.email}
                    placeholder="Signer email"
                    onChange={(value) => {
                      this.updateConfig('docuSign.signer.email', value);
                    }}
                  />
                  <FormControl title="">
                    <p style={{ marginLeft: '10px' }}>
                      Don&apos;t have an Amerifirst account? Create one
                      <a
                        href={enrollWithAmerifirstLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        here.
                      </a>{' '}
                    </p>
                  </FormControl>
                </div>
              )}
            </div>
          </Panel>
        </div>
      </>
    );
  }
}

Amerifirst.propTypes = {
  startUpdateConfig: PropTypes.func.isRequired,
  amerifirst: PropTypes.shape({
    enabled: PropTypes.bool,
  }),
  ...layoutContextPropTypes,
};

Amerifirst.defaultProps = {
  amerifirst: {
    enabled: false,
  },
};

const TitleButtons = ({ onCopyClicked, onSaveClicked, onClearClick }) => (
  <>
    <TitleButton
      variant="warning"
      onClick={() => onClearClick()}
      title="Clear"
    />
    <TitleButton
      variant="primary"
      onClick={() => onCopyClicked({ source: 'copy' })}
      title="Copy"
    />
    <TitleButton
      variant="success"
      onClick={() => onSaveClicked({ source: 'save' })}
      title="Save"
    />
  </>
);

TitleButtons.propTypes = {
  onCopyClicked: PropTypes.func.isRequired,
  onSaveClicked: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth: { config } }) => ({
  amerifirst: config.amerifirst,
});

const mapDispatchToProps = (dispatch) => ({
  startUpdateConfig: (updates) => dispatch(startUpdateConfig(updates)),
  pushToDataLayer: (variablesForLayer) =>
    dispatch(pushToDataLayer(variablesForLayer)),
  showDeleteConfirmation: (params) => dispatch(showDeleteConfirmation(params)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(Amerifirst),
);
