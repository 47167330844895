/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import onClickOutside from 'react-onclickoutside';
import { getTextWidth } from '../../Utils';
import {
  setInitialStateForTemplate,
  setEditCellItem,
} from '../../../../actions/templateEdit';
import InitialsCell from './InitialsCell';
import ContractObject from '../../Models/ContractObject';
import BodyCellItemObject from '../../Models/BodyCellItem';
import BodyRow from '../../BodyRow';
import { fontFamilyExists } from '../../FontSelect';
import PopoverSwitch from '../../PopoverSwitch';

const styleForCell = (displayFlex) => ({
  display: displayFlex === false ? undefined : 'flex',
  flexWrap: 'wrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  // justifyContent: 'space-between',
});

const styleForTitle = ({
  contractTitle,
  titleFontSize,
  titleFontBold,
  titleFontUnderline,
  titleFontName,
  titleFontColor,
  isForDetailCell,
}) => {
  const colorArray = titleFontColor ? titleFontColor.split('-') : [];
  const [r, g, b, a] = colorArray;
  return {
    display: contractTitle ? undefined : 'none',
    cursor: 'pointer',
    padding: isForDetailCell ? '0px 5px' : '3px 5px',
    fontSize: titleFontSize,
    fontWeight: titleFontBold ? 900 : undefined,
    fontFamily: fontFamilyExists(titleFontName)
      ? titleFontName
      : 'HelveticaNeue',
    color: titleFontColor ? `rgba(${r},${g},${b},${a})` : undefined,
    textDecoration: titleFontUnderline ? 'underline' : undefined,
  };
};

const styleForValue = ({
  valueFontSize,
  valueFontBold,
  valueFontUnderline,
  valueFontName,
  valueFontColor,
  isForDetailCell,
}) => {
  const colorArray = valueFontColor ? valueFontColor.split('-') : [];
  const [r, g, b, a] = colorArray;
  return {
    color: valueFontColor ? `rgba(${r},${g},${b},${a})` : undefined,
    cursor: 'pointer',
    fontFamily: fontFamilyExists(valueFontName)
      ? valueFontName
      : 'HelveticaNeue',
    fontSize: valueFontSize,
    fontWeight: valueFontBold ? 900 : undefined,
    minWidth: 20,
    overflow: isForDetailCell ? 'hidden' : undefined,
    padding: isForDetailCell ? '0px 5px' : '3px 5px',
    textDecoration: valueFontUnderline ? 'underline' : undefined,
    textOverflow: isForDetailCell ? 'ellipsis' : undefined,
    whiteSpace: isForDetailCell ? 'nowrap' : undefined,
  };
};

class TextShort extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      titleShowPopover: false,
      valueShowPopover: false,
    };
    this.contractTitleRef = React.createRef();
    this.contractValueRef = React.createRef();
  }

  shouldComponentUpdate(nextProps) {
    const { objectId } = this.props.cellItem;
    const nextEditId = nextProps.editCellItem.objectId;
    const editId = this.props.editCellItem.objectId;
    const nextEdit = objectId === nextEditId;
    const editing = objectId === editId;
    const isEdit = nextEdit || editing;

    const isEqual = this.props.cellItem.isEqualTo(nextProps.cellItem);

    return !isEqual || isEdit;
  }

  onMouseDown = (source) => {
    this.props.cellItem.editKey = source;
    this.props.setEditItem(this.props.cellItem);
  };

  onDoubleClick = (source) => {
    const key = `${source}ShowPopover`;
    const { openPopover } = this.state;
    if (openPopover !== key) {
      this.setState({
        [key]: true,
        [openPopover]: false,
        openPopover: key,
      });
    } else {
      this.setState({
        [key]: true,
        openPopover: key,
      });
    }
  };

  handleClickOutside = () => {
    this.setState({
      titleShowPopover: false,
      valueShowPopover: false,
      initialsShowPopover: false,
    });
  };

  onValuePopOverClose = () => {
    if (this.props.cellItem.value !== this.contractValueRef.current.value) {
      this.props.cellItem.value = this.contractValueRef.current.value;
      const contractObject = this.props.cellItem.getSourceObject();
      this.props.setTemplateState(new ContractObject(contractObject.toJSON()));
    }
  };

  onTitlePopOverClose = () => {
    if (
      this.props.cellItem.contractTitle !== this.contractTitleRef.current.value
    ) {
      this.props.cellItem.contractTitle = this.contractTitleRef.current.value;
      const contractObject = this.props.cellItem.getSourceObject();
      this.props.setTemplateState(new ContractObject(contractObject.toJSON()));
    }
  };

  render() {
    const {
      cellItem,
      template,
      setTemplateState,
      isForDetailCell,
      lockedTitle,
      lockedValue,
    } = this.props;
    const {
      appOnly = false,
      contractTitle: _contractTitle = '',
      value: _value = '',
      valueFontName = '',
      valueFontSize = 10,
      titleFontSize = 10,
      titleFontName = '',
      initialsRequired = false,
      initialsNote = '',
    } = cellItem;
    if (appOnly) {
      return null;
    }

    const contractTitle = lockedTitle || _contractTitle;
    const value = lockedValue || _value;
    const { wMargin, pageSize } = template;
    const contentWidth =
      parseInt(pageSize.split(',')[0], 10) - wMargin * 2 - 10; // minus 10 to account for padding
    const titleWidth = getTextWidth(
      contractTitle,
      `${titleFontSize}px${titleFontName ? ` ${titleFontName}` : ''}`,
    );
    const valueWidth = getTextWidth(
      value,
      `${valueFontSize}px${valueFontName ? ` ${valueFontName}` : ''}`,
    );
    const displayFlex = titleWidth + valueWidth < contentWidth;
    const cellStyle = styleForCell(displayFlex);
    const titleStyle = styleForTitle({
      ...cellItem,
      contractTitle,
      isForDetailCell,
    });
    const valueStyle = styleForValue({ ...cellItem, value, isForDetailCell });

    return (
      <BodyRow cellItem={cellItem}>
        <div
          className="text-short-wrapper"
          css={{
            ...cellStyle,
            // flexDirection: 'column',
          }}
        >
          <div
            css={{
              ...titleStyle,
            }}
            className="templates-pdf__text"
          >
            <PopoverSwitch
              onBlur={this.handleClickOutside}
              showPop={this.props.editCellItem.objectId === cellItem.objectId}
              inputRef={this.contractTitleRef}
              defaultValue={contractTitle}
              isOpen={this.state.titleShowPopover}
              lockedTitle={lockedTitle}
              onClose={this.onTitlePopOverClose}
            >
              <div
                className="templates-pdf__text templates-single_line"
                onClick={() => this.onMouseDown('title')}
                onDoubleClick={() => this.onDoubleClick('title')}
                css={{
                  fontFamily: titleStyle.fontFamily,
                  textDecoration: titleStyle.textDecoration,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                <span> {contractTitle || ' '} </span>
              </div>
            </PopoverSwitch>
          </div>
          <div
            css={{
              ...valueStyle,
              flex: 1,
              textAlign: 'end',
            }}
            className="templates-pdf__text"
          >
            <PopoverSwitch
              onBlur={this.handleClickOutside}
              showPop={this.props.editCellItem.objectId === cellItem.objectId}
              inputRef={this.contractValueRef}
              defaultValue={value}
              isOpen={this.state.valueShowPopover}
              lockedTitle={lockedTitle}
              onClose={this.onValuePopOverClose}
            >
              <div
                onClick={() => this.onMouseDown('value')}
                onDoubleClick={() => this.onDoubleClick('value')}
                css={{
                  textDecoration: valueStyle.textDecoration,
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  width: '100%',
                }}
                className="templates-pdf__text"
              >
                {value.split('\n').length > 1 ? (
                  value.split('\n').map((item, index, array) => {
                    if (index < array.length) {
                      return (
                        <p
                          key={`${cellItem.objectId}-${index}`}
                          css={{
                            textAlign: 'start',
                            whiteSpace: 'normal',
                            margin: 0,
                            minHeight: `${valueFontSize}px`,
                          }}
                        >
                          {item}
                        </p>
                      );
                    }
                    return item;
                  })
                ) : (
                  <div className="one-line-wrap" style={{ display: 'flex' }}>
                    <div
                      id={`${cellItem.objectId}-value-spacer`}
                      className="line-spacer"
                      style={{ flex: 1 }}
                    />
                    <p
                      ref={(ref) => {
                        if (ref) {
                          const rect = ref.getBoundingClientRect();
                          let flexContainer =
                            ref.parentElement.parentElement.parentElement
                              .parentElement;

                          if (
                            flexContainer.className === 'bp3-popover-wrapper'
                          ) {
                            flexContainer = flexContainer.parentNode.parentNode;
                          }
                          const containerRect = flexContainer.getBoundingClientRect();
                          const widthRatio = rect.width / containerRect.width;

                          if (widthRatio < 0.75) {
                            // flexContainer.style.flexDirection = 'row';
                            document.getElementById(
                              `${cellItem.objectId}-value-spacer`,
                            ).style.display = 'block';
                          } else {
                            // flexContainer.style.flexDirection = 'column';
                            document.getElementById(
                              `${cellItem.objectId}-value-spacer`,
                            ).style.display = 'none';
                          }
                        }
                      }}
                      className="one-line"
                      css={{
                        textAlign: 'start',
                        whiteSpace: 'normal',
                        margin: 0,
                      }}
                    >
                      {value}
                    </p>
                  </div>
                )}
              </div>
            </PopoverSwitch>
          </div>
          {initialsRequired && (
            <InitialsCell
              note={initialsNote}
              style={valueStyle}
              onMouseDown={() => this.onMouseDown('value')}
              onDoubleClick={() => this.onDoubleClick('initials')}
              showPopover={this.state.initialsShowPopover}
              onChange={(noteValue) => {
                cellItem.initialsNote = noteValue;
                const contractObject = cellItem.getSourceObject();
                setTemplateState(new ContractObject(contractObject.toJSON()));
              }}
            />
          )}
        </div>
      </BodyRow>
    );
  }
}

TextShort.propTypes = {
  cellItem: PropTypes.instanceOf(BodyCellItemObject).isRequired,
  template: PropTypes.shape({}).isRequired,
  setEditItem: PropTypes.func.isRequired,
  setTemplateState: PropTypes.func.isRequired,
  isForDetailCell: PropTypes.bool,
  lockedTitle: PropTypes.string,
  lockedValue: PropTypes.string,
};

TextShort.defaultProps = {
  isForDetailCell: false,
  lockedTitle: undefined,
  lockedValue: undefined,
};

const editCellItemDefault = {};
const defaultContract = new ContractObject();

const mapStateToProps = ({ templatesEdit }) => ({
  editCellItem: templatesEdit.editCellItem || editCellItemDefault,
  template: templatesEdit[templatesEdit.currentWorkingId] || defaultContract,
});

const mapDispatchToProps = (dispatch) => ({
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
  setEditItem: (cellItem) => dispatch(setEditCellItem(cellItem)),
});

const wrappedComponent = onClickOutside(TextShort);
export default connect(mapStateToProps, mapDispatchToProps)(wrappedComponent);
