/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import Parse from 'parse';
import moment from 'moment';
import { handleError } from './auth';
import AppToaster from '../utils/AppToaster';

export const setLogs = (logs) => ({
  type: 'SET_LOGS',
  logs,
});
export const setLastChangeLog = (lastChangeLog) => ({
  type: 'SET_LAST_CHANGE_LOG',
  lastChangeLog,
});
export const startLoadChangeLogs = (params) => async (dispatch) => {
  try {
    const query = new Parse.Query('ChangeLogMessages');
    query.descending('expDate');
    query.select(['message', 'expDate', 'displayTime']);
    const logs = await query.find();
    dispatch(setLogs(logs.map((l) => l.toJSON())));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const LoadLogsAndToast = () => async (dispatch) => {
  try {
    const query = new Parse.Query('ChangeLogMessages');
    query.select(['message', 'expDate', 'displayTime']);
    query.descending('expDate');
    query.greaterThan('expDate', moment().toDate());
    const logs = await query.find();
    const logsData = logs.map((l) => l.toJSON());
    if (logsData.length) {
      dispatch(setLogs(logsData));
      const [latest] = logsData;
      const { message, displayTime } = latest;
      AppToaster.show({
        message,
        className: 'change-log',
        timeout: displayTime * 1000,
      });
    }
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const startSaveChangeLog = (changeLogData) => async (dispatch) => {
  try {
    const changeLog = new Parse.Object('ChangeLogMessages');
    delete changeLogData.key;
    await changeLog.save({ ...changeLogData });
    await dispatch(startLoadChangeLogs({}));
    AppToaster.show({ message: 'Log Item Saved', timeout: 3000 });
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const deleleChangeLog = (changeLogData) => async (
  dispatch,
  getState,
) => {
  try {
    const { objectId, key } = changeLogData;
    if (objectId) {
      const changeLog = new Parse.Object('ChangeLogMessages');
      changeLog.id = objectId;
      await changeLog.destroy({ ...changeLogData });
      await dispatch(startLoadChangeLogs({}));
    } else {
      const {
        changeLogs: { logs },
      } = getState();
      const newLogs = logs.filter((l) => l.key !== key);
      dispatch(setLogs(newLogs));
    }
  } catch (e) {
    dispatch(handleError(e));
  }
};
