/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';
import { Position, Tooltip } from '@blueprintjs/core';
import { setOptionColumnWidth } from '../../../actions/priceGuide2';

const UpChargeHeaderCell = ({
  children,
  columnKey,
  popoverTitle,
  popoverBody,
  disableResize,
  activeColumns,
  setWidth,
  widths,
  borderLeft,
  ...rest
}) => {
  const [isOpen, setToolOpen] = useState(false);
  const [over, setOver] = useState(false);

  const component = (
    <div
      style={{ width: widths[columnKey] }}
      className={`priceguide__upcharge_header-cell${
        activeColumns[columnKey] ? '_hidden' : ''
      }${disableResize ? ' static' : ''}${borderLeft ? ' border-left' : ''}`}
      {...rest}
      onMouseDown={() => {
        if (!over) {
          setOver(true);
        }
      }}
      onMouseOver={() => {
        if (!over) {
          setOver(true);
        }
      }}
    >
      {children}
      {!disableResize && (
        <ReactResizeDetector
          handleWidth
          skipOnMount
          refreshMode="debounce"
          refreshRate={300}
          onResize={(width) => {
            if (over) {
              setOver(false);
              setWidth(columnKey, width);
            }
          }}
        />
      )}
    </div>
  );

  if (popoverTitle || popoverBody) {
    return (
      <Tooltip
        isOpen={isOpen}
        content={
          <span onMouseLeave={() => setToolOpen(false)}>
            {`${popoverTitle} - ${popoverBody}`}
          </span>
        }
        position={Position.RIGHT}
      >
        <div
          onMouseEnter={() => setToolOpen(true)}
          onMouseLeave={() => setToolOpen(false)}
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#ff8396',
            height: 35,
          }}
        >
          {component}
        </div>
      </Tooltip>
    );
  }

  return component;
};

UpChargeHeaderCell.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  columnKey: PropTypes.string,
  disableResize: PropTypes.bool,
  activeColumns: PropTypes.shape({
    upChargeSettings: PropTypes.bool.isRequired,
    upChargeAdditionalDetails: PropTypes.bool.isRequired,
    upChargePlaceholders: PropTypes.bool.isRequired,
    upChargeImage: PropTypes.bool.isRequired,
    upChargeName: PropTypes.bool.isRequired,
    upChargeNote: PropTypes.bool.isRequired,
    upChargeMeasurement: PropTypes.bool.isRequired,
    upChargeShowToggle: PropTypes.bool.isRequired,
    upChargePercentagePrice: PropTypes.bool.isRequired,
    upChargeDisabled: PropTypes.bool.isRequired,
    upChargeIdentifier: PropTypes.bool.isRequired,
    upChargePrices: PropTypes.bool.isRequired,
    upChargeSelect: PropTypes.bool.isRequired,
  }).isRequired,
  setWidth: PropTypes.func.isRequired,
  borderLeft: PropTypes.bool,
};

UpChargeHeaderCell.defaultProps = {
  disableResize: false,
  columnKey: '',
  borderLeft: false,
};

const mapStateToProps = ({ priceGuide2: { activeColumns, columnWidths } }) => ({
  activeColumns,
  widths: columnWidths.options,
});

const mapDispatchToProps = (dispatch) => ({
  setWidth: (columnKey, width) =>
    dispatch(setOptionColumnWidth(columnKey, width)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpChargeHeaderCell);
