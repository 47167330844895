/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import { Container } from 'react-bootstrap';
import ResponsiveTable from '../SharedComponents/ResponsiveTable';
import { LayoutContext } from '../Layout/LayoutContext';
import useZapTriggers from '../../hooks/Zaps/useZapTriggers';
import valueFormatter from './valueFormatter';

const ZapierPanel = () => {
  const { zaps = [] } = useZapTriggers();

  const { setCrumbs } = useContext(LayoutContext);

  useEffect(() => {
    setCrumbs([{ title: 'Zapier' }]);
  }, []);

  return (
    <Container className="zap-panel">
      <h1>Zapier Integration</h1>
      {zaps.length ? (
        <ResponsiveTable
          valueFormatter={valueFormatter}
          items={zaps}
          columns={[
            {
              title: 'Active',
              itemKey: 'isActive',
            },
            {
              title: 'Trigger',
              itemKey: 'trigger',
            },
            {
              title: 'User',
              itemKey: 'reportedUser',
            },

            {
              title: 'Date Created',
              itemKey: 'commonDate',
            },

            {
              title: 'Delete',
              itemKey: 'delete',
            },
          ]}
        />
      ) : (
        <table className="table mb-20 table-striped table-bordered table-hover">
          <thead>
            <tr>
              <th className="text-center">No Zaps Enabled</th>
            </tr>
          </thead>
        </table>
      )}
    </Container>
  );
};

export default ZapierPanel;
