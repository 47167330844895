import React from 'react';

const TermsOfService = () => (
  <>
    <p className="new-account__body-header">TERMS OF SERVICE</p>
    These Terms of Service are part of the User Agreement between Leap and
    Licensee. Leap and Licensee may hereinafter be referred to individually as a
    “Party” or collectively as the “Parties.”
    <div className="new-account__terms-container">
      <ol>
        <li>
          <span style={{ fontWeight: 'bold' }}>Grant of License.</span> License
          of the ProSell Licensed Product granted to Licensee herein is
          non-exclusive and non-transferable. Such license grants Licensee the
          limited right to use the ProSell Licensed Product during the term of
          this Agreement solely for its internal business purposes and in strict
          accordance with this Agreement.
        </li>
        <li>
          <span style={{ fontWeight: 'bold' }}>Licensee Covenants.</span>{' '}
          Licensee shall not reverse engineer, decompile, disassemble, modify,
          or create derivative works of the ProSell Licensed Product or attempt
          to reconstruct or discover any source code, underlying algorithms,
          file formats or programming interfaces of the ProSell Licensed
          Product. Licensee shall not directly or indirectly copy, distribute or
          archive the ProSell Licensed Product, except to the extent that
          copying is necessary to use the ProSell Licensed Product for the
          limited purpose set forth herein. Licensee shall not assign,
          sublicense, rent, sell, timeshare, loan, lease, or otherwise transfer
          the ProSell Licensed Product without the prior written consent of
          Leap. Licensee shall use the ProSell Licensed Product in compliance
          with all applicable foreign, federal, state and local laws, rules and
          regulations.
        </li>
        <li>
          <span style={{ fontWeight: 'bold' }}>Fees and Payment Terms.</span>{' '}
          Licensee shall enter into a twelve (12) month agreement wherein the
          Leap Setup Fee and Subscription Fee are waived for the first twelve
          (12) months provided that the account setup and QuickStart steps are
          completed in the first 60 days. Following this term, the Agreement
          will renew, and the Licensee shall pay Leap the Subscription Fee by
          credit card payment. The Subscription Fee is a monthly payment due
          each calendar month during the term of the license granted hereunder
          payable on or before the billing date. In the event Licensee fails to
          make any payment when due, or if any check or charge is returned,
          Licensee shall pay as a late fee an additional amount of ten percent
          (10%) of the unpaid amount. In addition, in the event of Licensee’s
          failure to make any payment when due, Leap shall have the right, in
          its sole discretion, to refuse performance, suspend performance,
          and/or terminate this Agreement without incurring any liability.
          Further, Leap shall be entitled to recover all costs and expenses,
          including reasonable attorneys’ fees, incurred in collecting or
          attempting to collect any amount due hereunder. Lastly, during the
          promotional period, the account shall be subject to deactivation if
          not used for 90 days.
        </li>
        <li>
          <span style={{ fontWeight: 'bold' }}>Updates and Enhancements.</span>{' '}
          For so long as the license granted herein is in effect, Leap may from
          time to time provide Updates to the ProSell Licensed Product.
          &quot;Updates&quot; shall mean new versions of and/or additions to the
          ProSell Licensed Product. Updates shall include patches, bug fixes,
          enhancements, or modifications made by Leap to the ProSell Licensed
          Product.
        </li>
        <li>
          <span style={{ fontWeight: 'bold' }}>Support Services.</span> For so
          long as the license granted herein is in effect, Leap shall provide
          Licensee basic support services relating to the ProSell Licensed
          Product as determined by Leap and as may be requested from time to
          time by Licensee such as answering questions about the use or
          operation of the Licensed Product and analyzing and resolving problems
          or issues relating to Licensee’s use of the Licensed Product. In the
          event Leap determines that Licensee’s request for services exceeds the
          scope of the basic support services provided hereunder, Leap shall
          advise Licensee of such and the Parties may agree on the additional
          fees to be paid to Leap for such services.
        </li>
        <li>
          <span style={{ fontWeight: 'bold' }}>Term and Termination.</span> The
          license granted herein shall commence on the later of full execution
          of the Agreement, and shall continue for twelve (12) months unless
          deactivated by Leap for non-use or failure to complete setup and
          QuickStart steps within 60 days. Following this term, the Agreement
          will renew until terminated by either Party upon at least thirty (30)
          days’ prior written notice to the other or by Leap immediately upon
          written notice to Licensee upon Licensee’s breach of this Agreement.
          Upon the termination of the license for any reason, (i) Licensee shall
          not be discharged from any liability or obligation that has arisen
          hereunder, including without limitation, payment in full of all
          amounts due hereunder, (ii) after the lapse of the notice period
          required to terminate this Agreement, if applicable, all rights
          granted to Licensee shall terminate and revert to Leap and Licensee
          shall be blocked and restricted from any further use of the ProSell
          Licensed Product, and (iii) Licensee shall return to Leap all ProSell
          Licensed Product and any other property belonging to Leap in
          Licensee’s possession. Further, Licensee agrees upon such termination
          to destroy all copies of the ProSell Licensed Product (and provide
          Leap with a sworn certificate of destruction signed by an officer of
          Licensee, if requested) or return the Licensed Product and all copies
          to Leap.
        </li>
        <li>
          <span style={{ fontWeight: 'bold' }}>Confidentiality.</span> Leap
          acknowledges and agrees that Licensee has a legitimate business
          interest in maintaining confidentiality with respect to non-public
          information concerning Licensee, its clients and/or potential clients
          (“Licensee Confidential Information”) and that Licensee may disclose
          or make such information available to Leap through Licensee’s use of
          the ProSell Licensed Product. Leap agrees that it shall not disclose
          to any person or entity any Licensee Confidential Information or use
          Licensee Confidential Information other than in the course of
          providing Licensee services hereunder. Leap acknowledges and agrees
          that the Licensee Confidential Information is unique, that money
          damages would not be a sufficient remedy for breach of this Section,
          and that the Licensee is entitled to seek equitable relief including
          an injunction and specific performance, in addition to any other
          remedies available to it, to enforce this Section 7 of this Agreement
          and, provided Licensee substantially prevails in such action, recovery
          of reasonable expenses and attorneys’ fees in connection with such
          proceedings.
        </li>
        <li>
          <span style={{ fontWeight: 'bold' }}>
            Ownership of the Licensed Product.
          </span>{' '}
          Licensee agrees that it will not claim ownership rights to the ProSell
          Licensed Product or any Updates. Licensee agrees that nothing in this
          Agreement shall give Licensee any right, title, or interest in the
          ProSell Licensed Product other than the limited right to use the same
          in strict accordance with the terms of this Agreement. Unless the
          prior written approval of Leap is obtained, Licensee may not modify or
          change the ProSell Licensed Product in any manner. Licensee admits the
          validity of all copyrights, patents, trade mark, trade secrets and
          other intellectual property rights of Leap to the ProSell Licensed
          Product and all associated intellectual property registrations.
        </li>
        <li>
          <span style={{ fontWeight: 'bold' }}>NO WARRANTY.</span> THE PROSELL
          LICENSED PRODUCT IS PROVIDED “AS IS,” “WHERE IS,” “AS AVAILABLE,”
          “WITH ALL FAULTS” AND, TO THE FULLEST EXTENT PERMITTED BY LAW, WITHOUT
          WARRANTY OF ANY KIND. LEAP DISCLAIMS ALL WARRANTIES WITH RESPECT TO
          THE PROSELL LICENSED PRODUCT, INCLUDING THE IMPLIED WARRANTY OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, WARRANTIES ARISING
          FROM COURSE OF DEALING OR USAGE OF TRADE, NON-INFRINGEMENT AND TITLE,
          AND ANY WARRANTIES REGARDING QUIET ENJOYMENT, QUALITY OF INFORMATION,
          SECURITY, RELIABILITY, TIMELINESS, AVAILABILITY AND PERFORMANCE OF THE
          PROSELL LICENSED PRODUCT. LEAP DOES NOT WARRANT THAT THE PROSELL
          LICENSED PRODUCT WILL MEET LICENESEE’S REQUIREMENTS, OR THAT THE
          OPERATION OF THE PROSELL LICENSED PRODUCT WILL BE UNINTERRUPTED OR
          ERROR-FREE, OR THAT OTHER SECURITY MEASURES WILL BE SECURE OR
          EFFECTIVE. LICENSEE UNDERSTANDS AND AGREES THAT ITS INSTALLATION, USE
          AND ACCESS TO THE PROSELL LICENSED PRODUCT IS AT ITS SOLE DISCRETION
          AND RISK AND THAT LICENSEE IS SOLELY RESPONSIBLE FOR ANY DAMAGES TO
          ITS COMPUTER, SOFTWARE AND THE LOSS OF DATA THAT RESULTS FROM THE USE
          THEREOF. LEAP ASSUMES NO RESPONSIBILITY FOR PROPER COLLECTION,
          VERIFICATION OR STORAGE OF DATA OR IMAGES. LICENSEE IS SOLELY
          RESPONSIBLE FOR THE SECURITY AND INTEGRITY OF ITS DATA AND SYSTEMS.
        </li>
        <li>
          <span style={{ fontWeight: 'bold' }}>
            LIMITATION OF LIABILITY AND DAMAGES.
          </span>{' '}
          TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL LEAP BE
          LIABLE TO LICENSEE OR ANY THIRD PARTY FOR ANY DIRECT, CONSEQUENTIAL,
          SPECIAL, INCIDENTAL, PUNITIVE, INDIRECT, EXEMPLARY, OR ANY OTHER
          DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR PERSONAL INJURY,
          LOST PROFITS, LOSS OF DATA AND BACKED-UP DATA, BUSINESS INTERRUPTION
          OR LOST REVENUES, ARISING OUT OF LICENSEE’S USE OR INABILITY TO USE
          THE PROSELL LICENSED PRODUCT, EVEN IF LEAP HAS BEEN ADVISED ABOUT THE
          POSSIBILITY OF SUCH DAMAGES (WHETHER SUCH DAMAGES ARISE IN CONTRACT,
          TORT (INCLUDING NEGLIGENCE) OR OTHERWISE). IN NO EVENT SHALL LEAP’S
          CUMULATIVE LIABILITY, FOR DAMAGES OF ANY TYPE TO LICENSEE OR ANY THIRD
          PARTY EXCEED THE AMOUNT PAID BY LICENSEE TO LEAP PURSUANT TO THIS
          AGREEMENT WITHIN THE THREE (3) CALENDAR MONTHS PRIOR TO THE ACTION
          GIVING RISE TO THE LIABILITY.
        </li>
        <li>
          <span style={{ fontWeight: 'bold' }}>Indemnification.</span> Licensee
          shall indemnify and hold harmless Leap and its members, employees, and
          agents from and against any and all claims, causes of action,
          proceedings, damages, liabilities, losses, costs and expenses,
          including attorneys’ fees and costs, by a third party arising from
          Licensee’s use or misuse of the ProSell Licensed Product.
        </li>
        <li>
          <span style={{ fontWeight: 'bold' }}>
            Governing Law, Jurisdiction, and Costs of Enforcement.
          </span>{' '}
          This Agreement shall be governed in all respects by the laws of the
          State of Maryland without regard to its conflict of laws provisions.
          The Parties agree that the exclusive venue and jurisdiction for all
          disputes arising out of or relating to this Agreement shall be in the
          District or Circuit Courts for Montgomery County, Maryland. The
          Parties hereby consent to the exclusive venue and jurisdiction of such
          Courts and agree to accept service of process pursuant to Maryland
          Rules and procedures. The parties further agree that this Agreement
          shall be deemed to have been entered into in the State the Maryland
          for purposes of jurisdiction. Leap shall be entitled to recover all
          costs and expenses, including attorneys’ fees and costs, incurred in
          enforcing, attempting to enforce, or defending any of the terms,
          covenants or conditions of this Agreement, including costs incurred
          prior to commencement of legal action and in any appeal.
        </li>
        <li>
          <span style={{ fontWeight: 'bold' }}>
            Severability and Modification by Court.
          </span>{' '}
          If any term or provision of this Agreement shall, for any reason, be
          adjudged by a court of competent jurisdiction to be invalid or
          unenforceable, such judgment shall not affect, impair or invalidate
          the remainder of this Agreement, but shall be confined in its
          operation to the provisions of this Agreement directly involved in the
          controversy in which such judgment shall have been rendered.
          Notwithstanding the above, it is the intent and desire of the Parties
          that this Agreement and all of its terms be enforceable and in the
          event any provision as presently set forth is determined to be invalid
          by a court of competent jurisdiction, the Parties hereto agree that
          this Agreement shall be appropriately modified by the court so that
          each and every provision hereof is enforceable to the maximum extent
          permitted by law.
        </li>
        <li>
          <span style={{ fontWeight: 'bold' }}>Entire Agreement.</span> This
          Agreement constitutes the entire agreement between Leap and Licensee
          with respect to the subject matter hereof and there are no
          representations, understandings or agreements that are not fully
          expressed in this Agreement. This Agreement shall supersede all other
          agreements between the Parties, whether written or oral. No amendment,
          change, waiver, or discharge hereof shall be valid unless in writing
          and signed by the Party against which such amendment, change, waiver,
          or discharge is sought to be enforced. This Agreement may not be
          assigned by Licensee without the prior written consent of Leap. This
          Agreement shall be binding upon the Parties and their respective
          successors and permitted assigns. The failure of Leap to enforce any
          provision of this Agreement shall not in any way be construed as a
          waiver of any such provision, nor prevent Leap from thereafter
          enforcing each and every other provision of this Agreement. The rights
          granted herein are cumulative and shall not constitute a waiver of
          Leap’s right to assert all other legal remedies available to it under
          the circumstances.
        </li>
        <li>
          <span style={{ fontWeight: 'bold' }}>Notices.</span> Any notices
          required or permitted hereunder shall be in writing and hand delivered
          or sent by United States registered, certified or express mail,
          postage prepaid, return receipt requested, or by a recognized national
          overnight shipping company such as FedEx or UPS addressed to the Party
          at its address listed in this Agreement, or to such other address as
          said Party may designate in writing from time to time. Notice shall be
          deemed given when received (as indicated by the return receipt or
          otherwise) or on the date such notice is first refused, if that be the
          case.
        </li>
        <li>
          <span style={{ fontWeight: 'bold' }}>Headings.</span> The heading of
          each section is inserted for convenience of reference only, and is not
          intended to be a part of or to affect the meaning or interpretation of
          this Agreement.
        </li>
        <li>
          <span style={{ fontWeight: 'bold' }}>Counterparts.</span> This
          Agreement may be executed in any number of counterparts and/or by
          facsimile or electronic signature, each of which shall be deemed to be
          an original and all of which together shall be deemed to be one and
          the same instrument.
        </li>
        <li>
          <span style={{ fontWeight: 'bold' }}>WAIVER OF JURY TRIAL.</span> THE
          PARTIES HEREBY EXPRESSLY WAIVE ANY RIGHT TO A TRIAL BY JURY FOR ANY
          DISPUTES ARISING OUT OF THIS AGREEMENT.
        </li>
      </ol>
    </div>
    This User Agreement including the Terms of Service comprise the entire
    agreement between Licensee and Leap (collectively, the ‘Agreement’).
  </>
);

export default TermsOfService;
