import React from 'react';
import PropTypes from 'prop-types';

const RADIAN = Math.PI / 180;
const LeapPieChartLabel = (props) => {
  const { cx, cy, midAngle, innerRadius, outerRadius, userLabel, name } = props;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor="middle"
      dominantBaseline="central"
    >
      {userLabel || `${name}`}
    </text>
  );
};

LeapPieChartLabel.propTypes = {
  cx: PropTypes.number,
  cy: PropTypes.number,
  midAngle: PropTypes.number,
  innerRadius: PropTypes.number,
  outerRadius: PropTypes.number,
  name: PropTypes.string,
  userLabel: PropTypes.string,
};

LeapPieChartLabel.defaultProps = {
  cx: 0,
  cy: 0,
  midAngle: 0,
  innerRadius: 0,
  outerRadius: 0,
  name: '',
  userLabel: null,
};

export default LeapPieChartLabel;
