import React, { useEffect, useContext } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { LayoutContext } from '../Layout';
import CreditApplicationsDashBoard from './CreditApplicaitonsDashBoard';
import NewFromPartnerList from './NewFromPartnerList';
import CreditApplicationEditor from './Editor';

const CreditApplications = () => {
  const { path } = useRouteMatch();
  const { crumbs, setCrumbs } = useContext(LayoutContext);

  useEffect(() => {
    const crumb = { title: 'Credit Applications', link: path };
    crumbs[0] = crumb;
    setCrumbs(crumbs);

    return () => {
      setCrumbs([]);
    };
  }, [setCrumbs, path, crumbs]);

  return (
    <Switch>
      <Route path={`${path}/new/from_partner`} component={NewFromPartnerList} />
      <Route
        path={[`${path}/edit/:id`, `${path}/new`]}
        component={CreditApplicationEditor}
      />
      <Route path={path} component={CreditApplicationsDashBoard} />
    </Switch>
  );
};

export default CreditApplications;
