/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import ContractObject from '../Models/ContractObject';
import BodyCellItem from '../Models/BodyCellItem';
import HeaderCell from './HeaderCell';
import TextCell from './TextCell';
import DetailCell from './DetailCell';
import PhotoSelector from './PhotoSelector';
import DrawingCell from './DrawingCell';
import MeasureSheetDetail from './MeasureSheetDetail';
import ProViaCell from './ProViaCell';
import ImagePicker from './ImagePicker';
import {
  setInitialStateForTemplate,
  setEditCellItem,
} from '../../../actions/templateEdit';
import { setCallbackForDragEnd } from '../Body/BodySectionGroup';
import SectionContent from './SectionContent';

export const cellForCellItem = (cellItem, onDoubleClick) => {
  switch (cellItem.cellType) {
    case 'textShort':
    case 'textLong':
      return <TextCell cellItem={cellItem} />;
    case 'textXLong':
      return <TextCell cellItem={cellItem} isParagraph />;
    case 'switched':
      return <TextCell cellItem={cellItem} isSwitch />;
    case 'securePaymentCaptureAll':
    case 'creditCardCell':
      return <TextCell cellItem={cellItem} isCreditCard />;
    case 'bankAccountCell':
      return <TextCell cellItem={cellItem} isBankAccount />;
    case 'detailCell':
      return <DetailCell cellItem={cellItem} onDoubleClick={onDoubleClick} />;
    case 'photoPickerCell':
      return <PhotoSelector cellItem={cellItem} />;
    case 'drawing':
      return <DrawingCell cellItem={cellItem} />;
    case 'measureSheetItemDetail':
      return <MeasureSheetDetail cellItem={cellItem} />;
    case 'provia':
      return <ProViaCell cellItem={cellItem} />;
    case 'imagePicker':
      return <ImagePicker cellItem={cellItem} />;
    default:
      return <h5>{cellItem.cellType}</h5>;
  }
};

export const shouldHideItem = (item) => {
  if (item.hidden) {
    return true;
  }
  switch (item.cellType) {
    case 'textOnly':
    case 'emptySpace':
    case 'header':
    case 'signatures':
      return true;
    default:
      return false;
  }
};

class AppView extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectedDetailCell: undefined };
  }

  onDoubleClick = (cellItem) => {
    this.setState({ selectedDetailCell: cellItem.objectId });
  };

  onBackClicked = () => {
    this.setState({ selectedDetailCell: undefined });
  };

  render() {
    const {
      contractObject,
      draggableType,
      setTemplateState,
      setEditCellItem,
    } = this.props;
    const { contractData = [] } = contractObject;
    const bodySection =
      contractData.find(({ groupType }) => groupType === 'body') || {};
    const { data = [] } = bodySection;
    return (
      <div className="templates-app__container">
        <div className="templates-app__navbar">
          <button
            type="button"
            onClick={this.onBackClicked}
            className="templates-app__navbar-back-button"
            disabled={!this.state.selectedDetailCell}
          >
            <span>
              <i
                className="fas fa-chevron-left"
                style={{ marginRight: '5px' }}
              />
              Back
            </span>
          </button>
          {contractObject.displayName}
        </div>
        <div style={{ display: 'flex' }}>
          <div
            className={`templates-app__content${
              this.state.selectedDetailCell ? '_offset' : ''
            }`}
          >
            {data.map((section) => {
              const { cellItems = [], userInputRequired } = section;
              if (!userInputRequired) {
                return null;
              }
              const sectionDroppableId = `${section.objectId}-app-${BodyCellItem.ContainerKey}`;
              setCallbackForDragEnd(
                sectionDroppableId,
                section,
                setTemplateState,
                setEditCellItem,
              );
              return (
                <Droppable
                  key={`app_${section.objectId}`}
                  droppableId={sectionDroppableId}
                  isDropDisabled={draggableType !== BodyCellItem.ContainerKey}
                >
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      <HeaderCell section={section} />
                      {cellItems.map((cellItem, index) => {
                        if (shouldHideItem(cellItem)) {
                          return null;
                        }
                        return (
                          <Draggable
                            key={`app_${cellItem.objectId}`}
                            draggableId={`${cellItem.getPath().join('-')}-app_${
                              cellItem.objectId
                            }`}
                            index={index}
                          >
                            {(draggableProvided, draggableSnapshot) => (
                              <div
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                                {...draggableProvided.dragHandleProps}
                              >
                                <div
                                  style={{
                                    boxShadow:
                                      this.props.editCellItem &&
                                      cellItem.objectId ===
                                        this.props.editCellItem.objectId
                                        ? ' -5px 5px 5px darkgrey, 5px 5px 5px darkgrey'
                                        : undefined,
                                    margin:
                                      this.props.editCellItem &&
                                      cellItem.objectId ===
                                        this.props.editCellItem.objectId
                                        ? 7
                                        : 0,
                                  }}
                                >
                                  {cellForCellItem(
                                    cellItem,
                                    this.onDoubleClick,
                                  )}
                                </div>
                                {draggableProvided.placeholder}
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              );
            })}
          </div>
          <SectionContent
            cellItem={contractObject.getObjectWithId(
              this.state.selectedDetailCell,
            )}
            isOpen={!!this.state.selectedDetailCell}
          />
        </div>
      </div>
    );
  }
}

AppView.propTypes = {
  contractObject: PropTypes.instanceOf(ContractObject),
  draggableType: PropTypes.string,
  setTemplateState: PropTypes.func.isRequired,
  setEditCellItem: PropTypes.func.isRequired,
};

AppView.defaultProps = {
  contractObject: {},
  draggableType: undefined,
};

const mapStateToProps = ({ templatesEdit }) => ({
  contractObject: templatesEdit[templatesEdit.currentWorkingId],
  editCellItem: templatesEdit.editCellItem,
  draggableType: templatesEdit.draggableType,
});

const mapDispatchToProps = (dispatch) => ({
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
  setEditCellItem: (template) => dispatch(setEditCellItem(template)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppView);
