/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Parse from 'parse';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { Popover, PopoverInteractionKind, Position } from '@blueprintjs/core';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import Select from '../Misc/Select';
import PlaceholdersPreview from '../PriceGuide/Placeholders/PreviewView';
import { pushToDataLayer } from '../../actions/tagManager';
import { startFetchContractSendingAPIs } from '../../actions/config';

import Panel from '../Panel';
import {
  startFetchUser,
  startUpdateUser,
  setUserToEdit,
} from '../../actions/users';
import FormGroup, {
  TextGroup,
  PasswordGroup,
  NumberGroup,
  DropDownGroup,
  SwitchGroup,
} from '../FormGroup';
import PlaceholderEditModal from '../PriceGuide/Placeholders/PlaceholderEditModal';

import { dropDownOptions } from '../AppSettings/NewUserDefaults';
import IncludedOfficesDropDown from '../IncludedOfficesDropDown';
import { decryptString } from '../../utils/utils';
import TitleButton from '../TitleButton';
import { historyPropType, locationPropType } from '../../router';
import PermissionsDropDown from './PermissionsDropDown';
import { showLoader, hideLoader } from '../../actions/loading';
import { regexValidators, getErrorMessage } from './userValidators';

const endpointOptions = [
  {
    value: 'Marketsharp',
    label: 'Marketsharp',
  },
  {
    value: 'Salesforce',
    label: 'Salesforce',
  },
  {
    value: 'LeadPerfection',
    label: 'LeadPerfection',
  },
  {
    value: 'EagleView',
    label: 'EagleView',
  },
  {
    value: 'JobNimbus',
    label: 'JobNimbus',
  },
];

const decryptedCredentials = async (apiCredentials = []) => {
  const credentials = apiCredentials.map(async (item) => {
    if (item.isEncrypted === true) {
      const { password } = await decryptString(item.password);
      return {
        ...item,
        password,
        isEncrypted: false,
      };
    }
    return Promise.resolve(item);
  });
  return Promise.all(credentials);
};

export class EditUser extends React.Component {
  crumb = (() => {
    const { location: { pathname = '' } = {} } = this.props;

    const title = pathname.includes('edit') ? 'Edit' : 'New';

    return { title, link: pathname };
  })();

  constructor(props) {
    super(props);
    let newState = {
      ...props.user,
      validationErrors: {},
      confirmPassword: '',
      selectedRoles: [],
      showPlaceHolderModal: false,
    };
    if (!newState.objectId) {
      newState = { ...newState, ...props.defaults };
    }
    this.state = newState;
  }

  componentDidMount() {
    this.props.startFetchContractSendingAPIs('apiCredentials');
    this.props.pushToDataLayer({
      event: 'userEditView',
      eventCategory: 'Users',
      eventAction: 'View',
    });
    if (this.props.match.params.id) {
      this.props.startFetchUser(this.props.match.params.id);
    } else {
      this.props.setUserToEdit({
        ...this.props.defaults,
      });
    }
    this.setBreadcrumbValues();
    this.setButtonValues();
  }

  async UNSAFE_componentWillReceiveProps(newProps) {
    const { apiCredentials = [] } = newProps.user;
    const { offices, maxOfficeCount } = newProps;
    const changed = !_.isEqual(this.props, newProps);
    const credentials = await decryptedCredentials(apiCredentials);
    if (changed) {
      if (maxOfficeCount === 1) {
        const [selectedOffice] = offices;
        this.setState({
          ...newProps.user,
          apiCredentials: credentials,
          selectedOffice: selectedOffice.toJSON(),
          allowedOffices: [selectedOffice.toPointer()],
        });
      } else {
        this.setState({
          ...newProps.user,
          apiCredentials: credentials,
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { location: { pathname = '' } = {}, crumbs } = this.props;
    const { location: { pathname: prevPathname = '' } = {} } = prevProps;

    const getLastElement = (array) => array[array.length - 1];

    if (
      pathname !== prevPathname ||
      !_.isEqual(getLastElement(crumbs), this.crumb)
    ) {
      this.setBreadcrumbValues();
    }

    const diff = Object.keys(prevState)
      .filter((key) => prevState[key])
      .filter((key) => {
        return !_.isEqual(prevState[key], this.state[key]);
      })
      .map((key) => {
        return [key, prevState[key], this.state[key]];
      });

    if (diff.length) {
      this.setButtonValues();
    }
  }

  componentWillUnmount() {
    const { crumbs, setCrumbs, setButtons } = this.props;
    crumbs.pop();
    setCrumbs(crumbs);
    setButtons('');
    this.props.setUserToEdit(undefined);
  }

  setBreadcrumbValues = () => {
    const { crumbs, setCrumbs } = this.props;
    setCrumbs([...crumbs, this.crumb]);
  };

  setButtonValues = () => {
    const { setButtons } = this.props;
    setButtons(
      <TitleButton
        variant="success"
        disabled={this.getValidationErrors().length > 0}
        onClick={this.onSaveClicked}
        title="Save"
      />,
    );
  };

  onAddCredentialsClicked = () => {
    const apiCredentials = [...this.state.apiCredentials];
    apiCredentials.push({
      username: '',
      password: '',
      endpoint: '',
      isEncrypted: false,
    });
    this.setState({ apiCredentials });
  };

  onCredentialsChanged = (value, key, index) => {
    const usedEndPoints = this.state.apiCredentials
      .map((item) => item.endpoint)
      .filter((item, i) => i !== index);
    if (value && key === 'endpoint') {
      if (usedEndPoints.includes(value)) {
        return;
      }
    }

    const apiCredentials = this.state.apiCredentials.map((object, i) => {
      if (i === index) {
        return {
          ...object,
          [key]: value,
        };
      }
      return object;
    });
    this.setState({ apiCredentials });
  };

  onDeleteCredentialClicked = (index) => {
    const apiCredentials = [...this.state.apiCredentials];
    apiCredentials.splice(index, 1);
    this.setState({ apiCredentials });
  };

  onAllowedOfficesChanged = (offices) => {
    this.setValueForKey(
      offices.map((officeId) => {
        const office = new Parse.Object('Office');
        office.id = officeId;
        return office.toPointer();
      }),
      'allowedOffices',
    );
  };

  onSelectedOfficeChanged = (objectId) => {
    const office = new Parse.Object('Office');
    office.id = objectId;
    const selectedOffice = office.toJSON();
    this.setState({ selectedOffice });
  };

  onSaveClicked = () => {
    const errors = this.getValidationErrors();
    if (errors.length === 0) {
      this.props.showLoader('Saving User ...');
      const { isActive } = this.state;
      const { user } = this.props;

      this.props.pushToDataLayer({
        event: 'userEditView',
        eventCategory: 'Users',
        eventAction: 'Edit',
      });

      if (isActive !== user.isActive) {
        if (isActive) {
          this.props.pushToDataLayer({
            event: 'userActivated',
            eventCategory: 'Users',
            eventAction: 'Enable',
          });
        } else {
          this.props.pushToDataLayer({
            event: 'userDeactivated',
            eventCategory: 'Users',
            eventAction: 'Disable',
          });
        }
      }

      this.props.startUpdateUser(this.state, () => {
        this.props.hideLoader();
        this.props.history.push('/users');
      });
    }
  };

  getPasswordErrorMessage = () => {
    const { password, objectId } = this.state;
    const passwordIsRequired = !objectId;
    if (!passwordIsRequired) return false;
    if (!password) return 'Required';
    return false;
  };

  getConfirmedPasswordErrorMessage = () => {
    const { password, confirmPassword, objectId } = this.state;
    const shouldConfirmPassword =
      !objectId || (password && password.length > 0);
    if (!shouldConfirmPassword) return false;
    if (!confirmPassword) return 'Required';
    return password === confirmPassword ? false : 'must match password';
  };

  onConfirmPasswordChanged = (value) => {
    const { password } = this.state;
    const matches = value === password;
    const updates = { confirmPassword: value };
    updates.confirmPaswordError = matches ? '' : 'Passwords must match';
    this.setState(updates);
  };

  onSavePlaceHolders = (event) => {
    this.setState({
      placeholders: event,
    });
    this.onPlaceHolderClose();
  };

  onPlaceHolderClose = () => {
    this.setState({
      showPlaceHolderModal: false,
    });
  };

  validate = (value, key) => {
    const keyValidator = regexValidators[key];

    if (keyValidator) {
      const testResult = keyValidator.validate(value);
      if (testResult) {
        this.setState({
          validationErrors: {
            ...this.state.validationErrors,
            [key]: {
              message: keyValidator.message,
            },
          },
        });
      } else {
        this.setState({
          validationErrors: {
            ...this.state.validationErrors,
            [key]: false,
          },
        });
      }
    }
  };

  getEndPointOptions = (selected) => {
    const selectedApiIDs = [
      ...this.state.apiCredentials
        .map((item) => item.endpoint)
        .filter((endpoint) => endpoint !== selected),
    ];

    return endpointOptions.filter(
      (option) => !selectedApiIDs.includes(option.value),
    );
  };

  setValueForKey = (value, key) => {
    this.validate(value, key);
    this.setState({ [key]: value });
  };

  getErrorMessage = (key, value) => {
    switch (key) {
      case 'password':
        return this.getPasswordErrorMessage();
      case 'confirmPassword':
        return this.getConfirmedPasswordErrorMessage();
      default:
        return getErrorMessage(key, value);
    }
  };

  openPlaceHolderModal = () => {
    this.setState({
      showPlaceHolderModal: true,
    });
  };

  getValidationErrors = () =>
    Object.keys(this.state)
      .map((key) => this.getErrorMessage(key, this.state[key]))
      .filter((error) => error);

  render() {
    const {
      allowedOffices = [],
      apiCredentials = [],
      selectedOffice = {},
    } = this.state;

    const assignedOfficeOptions = this.props.offices
      .filter(({ id }) => {
        const allowedIds = allowedOffices.map((office) => office.objectId);
        return allowedIds.indexOf(id) > -1;
      })
      .map((office) => ({
        label: office.get('name'),
        value: office.id,
      }));
    return (
      <div className="default-page-padding">
        <Panel title="Edit User">
          <div>
            <TextGroup
              title="First Name"
              value={this.state.nameFirst}
              onChange={(value) => this.setValueForKey(value, 'nameFirst')}
              errorMessage={getErrorMessage('nameFirst', this.state.nameFirst)}
            />
            <TextGroup
              title="Last Name"
              value={this.state.nameLast}
              onChange={(value) => this.setValueForKey(value, 'nameLast')}
              errorMessage={getErrorMessage('nameLast', this.state.nameLast)}
            />
            <TextGroup
              title="Email"
              value={this.state.email}
              onChange={(value) => this.setValueForKey(value, 'email')}
              errorMessage={getErrorMessage('email', this.state.email)}
            />
            <TextGroup
              title="Username"
              value={this.state.username}
              onChange={(value) => this.setValueForKey(value, 'username')}
              errorMessage={getErrorMessage('username', this.state.username)}
            />
            <PasswordGroup
              title="Password"
              placeholder="Hidden"
              value={this.state.password}
              onChange={(value) => this.setValueForKey(value, 'password')}
              errorMessage={this.getPasswordErrorMessage()}
            />

            <PasswordGroup
              title="Confirm Password"
              value={this.state.confirmPassword}
              onChange={(value) => this.onConfirmPasswordChanged(value)}
              errorMessage={this.getConfirmedPasswordErrorMessage()}
            />
            <FormGroup
              title="Phone Number"
              errorMessage={getErrorMessage(
                'phoneNumber',
                this.state.phoneNumber,
              )}
            >
              <NumberFormat
                value={this.state.phoneNumber}
                onChange={(event) =>
                  this.setValueForKey(event.target.value, 'phoneNumber')
                }
                format="(###) ###-####"
                mask="_"
              />
            </FormGroup>
            <TextGroup
              title="License Number"
              value={this.state.licenseNumber}
              onChange={(value) => this.setValueForKey(value, 'licenseNumber')}
              errorMessage={getErrorMessage(
                'licenseNumber',
                this.state.licenseNumber,
              )}
            />
            <NumberGroup
              title="Additional Estimate Amount"
              value={this.state.additionalMinimumAmount}
              onChange={(value) => {
                const number = parseFloat(value);
                this.setValueForKey(number, 'additionalMinimumAmount');
              }}
              errorMessage={getErrorMessage(
                'additionalMinimumAmount',
                this.state.additionalMinimumAmount,
              )}
            />
            <TextGroup
              title="Unique Identifier"
              value={this.state.identifier}
              onChange={(value) => this.setValueForKey(value, 'identifier')}
              errorMessage={getErrorMessage(
                'identifier',
                this.state.identifier,
              )}
            />
            <DropDownGroup
              title="Search Estimates For"
              value={dropDownOptions.find(
                (option) => option.value === this.state.searchableTimeCustomer,
              )}
              onChange={({ value }) =>
                this.setValueForKey(value, 'searchableTimeCustomer')
              }
              closeMenuOnSelect
              options={dropDownOptions}
              isClearable={false}
            />
            <SwitchGroup
              title="Submit Credit Apps"
              checked={this.state.canSubmitCreditApps}
              onChange={(value) =>
                this.setValueForKey(value, 'canSubmitCreditApps')
              }
            />
            <SwitchGroup
              title="Active"
              checked={this.state.isActive}
              onChange={(value) => this.setValueForKey(value, 'isActive')}
            />
            <SwitchGroup
              title="Search Other User's Estimates"
              checked={this.state.isManager}
              onChange={(value) => this.setValueForKey(value, 'isManager')}
            />
            {this.props.maxOfficeCount !== 1 && (
              <>
                <FormGroup
                  errorMessage={getErrorMessage(
                    'allowedOffices',
                    this.state.allowedOffices,
                  )}
                  title="Allowed Offices"
                >
                  <IncludedOfficesDropDown
                    onChange={this.onAllowedOfficesChanged}
                    selected={allowedOffices.map((office) => office.objectId)}
                  />
                </FormGroup>
                <FormGroup
                  errorMessage={getErrorMessage(
                    'selectedOffice',
                    this.state.selectedOffice,
                  )}
                  title="Assigned Office"
                >
                  <div className="users__assigned-office-container">
                    <Select
                      value={selectedOffice.objectId}
                      onChange={({ value }) =>
                        this.onSelectedOfficeChanged(value)
                      }
                      closeMenuOnSelect
                      options={assignedOfficeOptions}
                      isClearable={false}
                    />
                    Note: The user must log out and back into again for this
                    change to take effect
                  </div>
                </FormGroup>
              </>
            )}
            <FormGroup
              {...{
                errorMessage:
                  this.state.selectedRoles.length === 0
                    ? 'you need at least one permission selected'
                    : null,
              }}
              title="Permissions"
            >
              <PermissionsDropDown
                selected={this.state.selectedRoles}
                onSelectedChanged={(e) =>
                  this.setValueForKey(e, 'selectedRoles')
                }
              />
            </FormGroup>
            <FormGroup title="Credentials">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th style={{ position: 'relative', width: 150 }}>
                      Endpoint
                    </th>
                    <th>Username</th>
                    <th>Password</th>
                    <th>
                      <button
                        type="button"
                        disabled={
                          this.state.apiCredentials &&
                          this.state.apiCredentials.length ===
                            endpointOptions.length
                        }
                        onClick={this.onAddCredentialsClicked}
                      >
                        Add
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {apiCredentials.map(
                    ({ endpoint, username, password }, index) => (
                      <tr key={index}>
                        <td>
                          <div style={{ position: 'relative', width: 150 }}>
                            <Select
                              name="form-field-name"
                              value={endpoint}
                              onChange={({ value }) =>
                                this.onCredentialsChanged(
                                  value,
                                  'endpoint',
                                  index,
                                )
                              }
                              closeMenuOnSelect
                              options={this.getEndPointOptions(endpoint)}
                              isClearable={false}
                            />
                          </div>
                        </td>
                        <td style={{ width: '33.33%' }}>
                          <input
                            className={`table-row__cell__input${
                              username ? '' : 'error-border'
                            }`}
                            type="text"
                            value={username}
                            placeholder={`${endpoint} Username`}
                            onChange={(e) =>
                              this.onCredentialsChanged(
                                e.target.value,
                                'username',
                                index,
                              )
                            }
                          />
                        </td>
                        <td style={{ width: '33.33%' }}>
                          {endpoint !== 'JobNimbus' && (
                            <input
                              type="password"
                              value={password}
                              placeholder={`${endpoint} Password`}
                              onChange={(e) =>
                                this.onCredentialsChanged(
                                  e.target.value,
                                  'password',
                                  index,
                                )
                              }
                              autoComplete="new-password"
                            />
                          )}
                        </td>
                        <td style={{ width: '35px' }}>
                          <button
                            className="fill-cell danger"
                            type="button"
                            onClick={() =>
                              this.onDeleteCredentialClicked(index)
                            }
                          >
                            <i className="far fa-trash-alt" />
                          </button>
                        </td>
                      </tr>
                    ),
                  )}
                </tbody>
              </Table>
            </FormGroup>
            <FormGroup title="Placeholders">
              <Popover
                content={
                  <PlaceholdersPreview placeholders={this.state.placeholders} />
                }
                interactionKind={PopoverInteractionKind.HOVER}
                position={Position.RIGHT}
              >
                <TitleButton
                  title="Edit Placeholders"
                  onClick={this.openPlaceHolderModal}
                />
              </Popover>
            </FormGroup>
            <PlaceholderEditModal
              show={this.state.showPlaceHolderModal}
              onClose={this.onPlaceHolderClose}
              placeholders={this.state.placeholders || []}
              onSave={this.onSavePlaceHolders}
              showNotAdded={this.showNotAdded}
            />
          </div>
        </Panel>
      </div>
    );
  }
}

EditUser.propTypes = {
  history: historyPropType.isRequired,
  location: locationPropType.isRequired,
  user: PropTypes.shape({
    nameFirst: PropTypes.string,
    nameLast: PropTypes.string,
    username: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string,
    placeholders: PropTypes.arrayOf(
      PropTypes.shape({
        placeholder: PropTypes.string,
        replacement: PropTypes.string,
        id: PropTypes.string,
      }),
    ),
    licenseNumber: PropTypes.string,
    additionalMinimumAmount: PropTypes.number,
    identifier: PropTypes.string,
    searchableTimeCustomer: PropTypes.number,
    canSubmitCreditApps: PropTypes.bool,
    isActive: PropTypes.bool,
    isManager: PropTypes.bool,
    allowedOffices: PropTypes.arrayOf(
      PropTypes.shape({
        objectId: PropTypes.string.isRequired,
      }),
    ),
    apiCredentials: PropTypes.arrayOf(
      PropTypes.shape({
        username: PropTypes.string,
        password: PropTypes.string,
        endpoint: PropTypes.string,
        isEncrypted: PropTypes.bool,
      }),
    ),
    selectedOffice: PropTypes.shape({
      objectId: PropTypes.string,
    }),
  }),
  showLoader: PropTypes.func.isRequired,
  hideLoader: PropTypes.func.isRequired,
  startFetchUser: PropTypes.func.isRequired,
  startUpdateUser: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  offices: PropTypes.arrayOf(
    PropTypes.shape({
      objectId: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  defaults: PropTypes.shape({
    additionalMinimumAmount: PropTypes.number,
    isActive: PropTypes.bool,
    licenseNumber: PropTypes.string,
    searchableTimeCustomer: PropTypes.number,
    canSubmitCreditApps: PropTypes.bool,
  }),
  ...layoutContextPropTypes,
};

EditUser.defaultProps = {
  user: {
    nameFirst: '',
    nameLast: '',
    email: '',
    username: '',
    password: '',
    phoneNumber: '',
    licenseNumber: '',
    additionalMinimumAmount: 0,
    identifier: '',
    searchableTimeCustomer: 999999999,
    canSubmitCreditApps: false,
    isActive: location.pathname === '/users/new',
    isManager: false,
    allowedOffices: [],
    placeholders: [],
    apiCredentials: [],
    selectedOffice: {},
  },
  match: {
    params: {
      id: '',
    },
  },
  defaults: {
    additionalMinimumAmount: 0,
    isActive: true,
    licenseNumber: '',
    searchableTimeCustomer: 999999999,
    canSubmitCreditApps: false,
  },
};

const mapStateToProps = ({
  plan = {},
  users: { userToEdit } = {},
  auth: { config: { newUserDefaults } = {}, offices = [] } = {},
  permissions,
  config: { contractSendingAPIs = [] } = {},
}) => ({
  maxOfficeCount: plan.maxOfficeCount,
  user: userToEdit,
  offices,
  defaults: newUserDefaults,
  permissions,
  credentialsApiList: contractSendingAPIs.map((label) => ({
    label,
    value: label,
  })),
});

const mapDispatchToProps = (dispatch) => ({
  startFetchUser: (objectId) => dispatch(startFetchUser(objectId)),
  setUserToEdit: (userData) => dispatch(setUserToEdit(userData)),
  startUpdateUser: (user, callback) =>
    dispatch(startUpdateUser(user, callback)),
  showLoader: (message) => dispatch(showLoader(message)),
  hideLoader: () => dispatch(hideLoader()),
  startFetchContractSendingAPIs: (source) =>
    dispatch(startFetchContractSendingAPIs(source)),
  pushToDataLayer: (variablesForLayer) =>
    dispatch(pushToDataLayer(variablesForLayer)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(EditUser),
);
