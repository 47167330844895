/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import onClickOutside from 'react-onclickoutside';
import { Popover, TextArea } from 'leap-menu-item';
import Switch from 'react-ios-switch';
import {
  setInitialStateForTemplate,
  setEditCellItem,
} from '../../../actions/templateEdit';
import BodyCellItemObject from '../Models/BodyCellItem';
import ContractObject from '../Models/ContractObject';
import BodyRow from '../BodyRow';

class TextCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      titleShowPopover: false,
      valueShowPopover: false,
      noteShowPopover: false,
    };
    this.titleRef = React.createRef();
    this.valueRef = React.createRef();
    this.noteRef = React.createRef();
  }

  shouldComponentUpdate(nextProps) {
    return !this.props.cellItem.isEqualTo(nextProps.cellItem);
  }

  onMouseDown = (source) => {
    this.props.cellItem.editKey = source;
    this.props.setEditItem(this.props.cellItem);
  };

  onDoubleClick = (source) => {
    const key = `${source}ShowPopover`;
    const { openPopover } = this.state;
    if (openPopover !== key) {
      this.setState({
        [key]: true,
        [openPopover]: false,
        openPopover: key,
      });
    } else {
      this.setState({
        [key]: true,
        openPopover: key,
      });
    }
  };

  handleClickOutside = () => {
    this.setState({
      titleShowPopover: false,
      valueShowPopover: false,
      noteShowPopover: false,
    });
  };

  valueItem = () => {
    const {
      cellItem,
      setTemplateState,
      isSwitch,
      isCreditCard,
      isBankAccount,
    } = this.props;
    const {
      inputType,
      switchValueOn,
      switchValueOff,
      value = '',
      inputDisabled,
    } = cellItem;
    if (isSwitch) {
      return (
        <Switch
          checked={switchValueOn === value}
          handleColor="rgb(92, 183, 91)"
          offColor="white"
          // onColor="rgb(76, 217, 100)"
          onColor="black"
          onChange={(checked) => {
            cellItem.value = checked ? switchValueOn : switchValueOff;
            const contractObject = cellItem.getSourceObject();
            setTemplateState(new ContractObject(contractObject.toJSON()));
          }}
        />
      );
    }
    if (isCreditCard) {
      return (
        <div
          className="templates-app__value templates-app__disabled"
          onMouseDown={() => this.onMouseDown('value')}
          onDoubleClick={() => this.onDoubleClick('value')}
        >
          ####-####-####-9999
        </div>
      );
    }
    if (isBankAccount) {
      return (
        <div
          className="templates-app__value templates-app__disabled"
          onMouseDown={() => this.onMouseDown('value')}
          onDoubleClick={() => this.onDoubleClick('value')}
        >
          1234567890
        </div>
      );
    }
    const isDisabled =
      inputType === 'formula' ||
      inputType === 'formulaWhole' ||
      inputType === 'textFormula' ||
      inputType === 'formulaCurrency' ||
      inputType === 'formulaCurrencyWhole' ||
      inputType === 'creditCard' ||
      inputType === 'ccExpDate' ||
      inputDisabled;
    return (
      <Popover
        content={
          <textarea
            onContextMenu={(e) => e.stopPropagation()}
            fill
            ref={this.valueRef}
            defaultValue={value}
            className="ignore-react-onclickoutside"
          />
        }
        isOpen={this.state.valueShowPopover}
        onClose={() => {
          cellItem.value = this.valueRef.current.value;
          const contractObject = cellItem.getSourceObject();
          setTemplateState(new ContractObject(contractObject.toJSON()));
        }}
      >
        <div
          className={`templates-app__value${
            isDisabled ? ' templates-app__disabled' : ''
          }`}
          onMouseDown={() => this.onMouseDown('value')}
          onDoubleClick={() => this.onDoubleClick('value')}
        >
          {value.split('\n').map((item, index, array) => {
            if (index < array.length - 1) {
              return (
                <p
                  key={`${cellItem.objectId}-${index}`}
                  style={{ margin: 0, minHeight: '20px' }}
                >
                  {item}
                </p>
              );
            }
            return item;
          })}
        </div>
      </Popover>
    );
  };

  render() {
    const { cellItem, setTemplateState, isParagraph } = this.props;
    const { required } = cellItem;
    const firstLineOfTitle = (cellItem.appTitle || '').split('\n')[0];
    return (
      <BodyRow cellItem={cellItem}>
        <div
          className={`templates-app__text_cell${
            isParagraph ? '_paragraph' : ''
          }`}
        >
          <div className="templates-app__stacked">
            <Popover
              className={`templates-app__title${required ? '_required' : ''}`}
              content={
                <TextArea
                  onContextMenu={(e) => e.stopPropagation()}
                  fill
                  inputRef={this.titleRef}
                  defaultValue={cellItem.appTitle}
                  className="ignore-react-onclickoutside"
                />
              }
              isOpen={this.state.titleShowPopover}
              onClose={() => {
                if (cellItem.appTitle !== this.titleRef.current.value) {
                  cellItem.appTitle = this.titleRef.current.value;
                  const contractObject = cellItem.getSourceObject();
                  setTemplateState(new ContractObject(contractObject.toJSON()));
                }
              }}
            >
              <div
                className="templates-app__line-text"
                onMouseDown={() => this.onMouseDown('title')}
                onDoubleClick={() => this.onDoubleClick('title')}
              >
                {firstLineOfTitle}
              </div>
            </Popover>
            {cellItem.appNote && (
              <Popover
                className="templates-app__note"
                content={
                  <TextArea
                    onContextMenu={(e) => e.stopPropagation()}
                    fill
                    inputRef={this.noteRef}
                    defaultValue={cellItem.appNote}
                    className="ignore-react-onclickoutside"
                  />
                }
                isOpen={this.state.noteShowPopover}
                onClose={() => {
                  if (cellItem.appNote !== this.noteRef.current.value) {
                    cellItem.appNote = this.noteRef.current.value;
                    const contractObject = cellItem.getSourceObject();
                    setTemplateState(
                      new ContractObject(contractObject.toJSON()),
                    );
                  }
                }}
              >
                <div
                  onMouseDown={() => this.onMouseDown('note')}
                  onDoubleClick={() => this.onDoubleClick('note')}
                >
                  {cellItem.appNote}
                </div>
              </Popover>
            )}
          </div>
          {this.valueItem()}
          <div className="templates-app__cell_bottom_border" />
        </div>
      </BodyRow>
    );
  }
}

TextCell.propTypes = {
  cellItem: PropTypes.instanceOf(BodyCellItemObject).isRequired,
  setTemplateState: PropTypes.func.isRequired,
  setEditItem: PropTypes.func.isRequired,
  isParagraph: PropTypes.bool,
  isSwitch: PropTypes.bool,
  isCreditCard: PropTypes.bool,
  isBankAccount: PropTypes.bool,
};

TextCell.defaultProps = {
  isParagraph: false,
  isSwitch: false,
  isCreditCard: false,
  isBankAccount: false,
};

const mapStateToProps = ({ templatesEdit }) => ({
  template: templatesEdit[templatesEdit.currentWorkingId],
});

const mapDispatchToProps = (dispatch) => ({
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
  setEditItem: (cellItem) => dispatch(setEditCellItem(cellItem)),
});

const wrappedComponent = onClickOutside(TextCell);
export default connect(mapStateToProps, mapDispatchToProps)(wrappedComponent);
