import moment from 'moment';

/** @typedef {{title: string, enabled: boolean, locked: boolean}} LabelOverride
 * @typedef {{
 *   ACL?: {},
 *   allowedAppSettings?: Array<string>,
 *   allowedIntegration?: Array<string>,
 *   allowedMainFeatures?: Array<string>,
 *   allowedRoutes?: Array<string>,
 *   attachmentsAsLinksRequired?: boolean,
 *   availableContactsEnabled?: boolean,
 *   count: number,
 *   createdAt?: string,
 *   customCreditApplicationsAllowed?: boolean,
 *   customMainMenuOptionsAllowed?: boolean,
 *   customPermissionGroupsAllowed?: boolean,
 *   details: Record<string, unknown>,
 *   docuSignEnabled?: boolean,
 *   endDate: string,
 *   groupMeEnabled?: boolean,
 *   hoverEnabled?: boolean,
 *   isDefault?: boolean,
 *   items: Array<{ id: number}>,
 *   jobNimbusEnabled?: boolean,
 *   leadPerfectionEnabled?: boolean,
 *   limit: number,
 *   logsAllowed?: boolean,
 *   mainMenuLabelOverrides?: Record<string, LabelOverride>,
 *   marketsharpEnabled?: boolean,
 *   maxAdditionalDetailsPerItemCount?: number,
 *   maxCategoryCount?: number,
 *   maxCommissionFormulasCount?: number,
 *   maxContractTemplateCount?: number,
 *   maxCustomTemplateCount?: number,
 *   maxEmailRecipientCount?: number,
 *   maxFinanceOptionCount?: number,
 *   maxMeasureSheetItemCount?: number,
 *   maxOfficeCount?: number,
 *   maxOptionCount?: number,
 *   maxOptionsPerItem?: number,
 *   maxPackageCount?: number,
 *   maxPriceFormulasCount?: number,
 *   maxProposalTemplateCount?: number,
 *   maxTemplateCount?: number,
 *   maxUpChargeCount?: number,
 *   maxUpChargesPerItem?: number,
 *   name?: string,
 *   objectId?: string,
 *   partnerCreditApplicationSourcesAllowed?: Array<string>,
 *   paymentCaptureEnabled?: boolean,
 *   paymentGatewaysAllowed?: Array<string>,
 *   planId?: string,
 *   proviaEnabled?: true,
 *   salesforceEnabled?: true,
 *   skip: number,
 *   startDate: string,
 *   templateTypesAllowed?: Array<string>,
 *   updatedAt?: string,
 *   webhooksEnabled?: boolean,
 * }} PlanState */

const startDate = moment()
  .subtract(30, 'days')
  .startOf('day')
  .toISOString();
const endDate = moment()
  .endOf('day')
  .toISOString();

/** @type {PlanState} */
export const defaultPlanState = {
  startDate,
  endDate,
  count: 0,
  limit: 10,
  skip: 0,
  items: [{ id: 1 }, { id: 2 }, { id: 3 }],
  details: {},
};

export default (
  state = {
    ...defaultPlanState,
  },
  action,
) => {
  switch (action.type) {
    case 'SET_PLAN_CONFIG':
      return {
        ...state,
        ...action.config,
      };
    case 'SET_PLANS':
      return {
        ...state,
        items: [...action.items],
      };
    case 'SET_PLANS_DATE_FILTER':
      return {
        ...state,
        ...action.params,
      };
    case 'SET_PLANS_FILTER':
      return {
        ...state,
        ...action.params,
      };
    case 'SET_PLAN_DETAILS':
      return {
        ...state,
        details: { ...action.details },
      };
    default:
      return state;
  }
};
