import React from 'react';

import './styles.scss';

const ToggleSwitch =
  /** @param {{
   *  checked: boolean,
   *  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
   *  title: string,
   *  className?: string,
   *  name?: string,
   *  style?: React.CSSProperties
   * }} props */
  ({ checked, onChange, title, name, className, style }) => {
    return (
      <label
        className={`lpstrp-switch-container ${className || ''}`}
        htmlFor={name}
        style={style}
      >
        <input
          id={name}
          checked={checked}
          className="lpstrp-switch-input"
          name={name}
          onChange={onChange}
          type="checkbox"
        />
        <span className="lpstrp-switch-toggle" />
        <p className="lpstrp-switch-title">{title}</p>
      </label>
    );
  };

export default ToggleSwitch;
