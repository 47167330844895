/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DebounceInput } from 'react-debounce-input';

const UpChargeCell = ({
  columnKey,
  type,
  width,
  activeColumns,
  widths,
  children,
  onChange,
  value,
  disabled,
  onContextMenu,
}) => (
  <div
    className={`priceguide__upcharge-cell${
      activeColumns[columnKey] ? '_hidden' : ''
    }`}
    style={{ width: width || widths[columnKey] }}
    onContextMenu={onContextMenu}
  >
    {!!children && children}
    {!children && (
      <DebounceInput
        type={type}
        minLength={0}
        debounceTimeout={300}
        onChange={onChange}
        value={value}
        disabled={disabled}
      />
    )}
  </div>
);

UpChargeCell.propTypes = {
  columnKey: PropTypes.string,
  type: PropTypes.oneOf(['text', 'number']),
  activeColumns: PropTypes.shape({
    upChargeSettings: PropTypes.bool.isRequired,
    upChargeAdditionalDetails: PropTypes.bool.isRequired,
    upChargePlaceholders: PropTypes.bool.isRequired,
    upChargeImage: PropTypes.bool.isRequired,
    upChargeName: PropTypes.bool.isRequired,
    upChargeNote: PropTypes.bool.isRequired,
    upChargeMeasurement: PropTypes.bool.isRequired,
    upChargeShowToggle: PropTypes.bool.isRequired,
    upChargePercentagePrice: PropTypes.bool.isRequired,
    upChargeDisabled: PropTypes.bool.isRequired,
    upChargeIdentifier: PropTypes.bool.isRequired,
    upChargePrices: PropTypes.bool.isRequired,
    upChargeSelect: PropTypes.bool.isRequired,
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  width: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  onContextMenu: PropTypes.func,
};

UpChargeCell.defaultProps = {
  columnKey: '',
  type: 'text',
  children: undefined,
  width: undefined,
  onChange: undefined,
  value: '',
  disabled: false,
  onContextMenu: undefined,
};

const mapStateToProps = ({ priceGuide2: { activeColumns, columnWidths } }) => ({
  activeColumns,
  widths: columnWidths.options,
});

export default connect(mapStateToProps)(UpChargeCell);
