import Parse from 'parse';

class PriceGuideOption extends Parse.Object {
  constructor(obj) {
    super('SSPriceGuideItem');
    Object.assign(this, obj);
    if (!this.has('isAccessory')) {
      this.set('isAccessory', false);
    }
  }

  get includedOffices() {
    return this.get('includedOffices') || [];
  }

  set includedOffices(value) {
    this.set('includedOffices', value);
  }

  newInstance() {
    const newInstance = super.newInstance();
    Object.assign(newInstance, this);
    return newInstance;
  }

  getPriceForOffice(id) {
    const itemPrices = this.get('itemPrices') || [];
    const { total = 0 } =
      itemPrices.find(({ officeId }) => id === officeId) || {};
    return total;
  }

  setPriceForOffice(id, value) {
    let total = parseFloat(value);
    total = Number.isNaN(total) ? 0 : total;
    const itemPrices = this.get('itemPrices') || [];
    const updatedPrices = itemPrices.filter(({ officeId }) => id !== officeId);
    updatedPrices.push({
      officeId: id,
      total,
    });
    this.set('itemPrices', updatedPrices);
  }

  addPlaceholders(placeholders = []) {
    const existing = this.get('placeholders') || [];
    const updated = [...existing, ...placeholders];
    this.set('placeholders', updated);
  }
}

export default PriceGuideOption;
