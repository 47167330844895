/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';

const CustomPlaceholderTable = ({
  onAddClicked,
  onDelete,
  onValueChanged,
  placeholders,
  errors,
  showNotAdded,
}) => (
  <Table striped bordered hover>
    <thead>
      <tr>
        <th style={{ width: showNotAdded ? 1 / 3 : 50 }}>Placeholder</th>
        <th style={{ width: showNotAdded ? 1 / 3 : 50 }}>Replacement</th>
        {showNotAdded && (
          <th style={{ width: 1 / 3 }}>Not Added Replacement</th>
        )}
        <th style={{ width: '25px' }}>
          <button
            className="btn btn-primary"
            type="button"
            onClick={onAddClicked}
          >
            Add
          </button>
        </th>
      </tr>
    </thead>
    <tbody>
      {placeholders.map(
        ({ placeholder, replacement, notAddedReplacement, id }, index) => (
          <tr key={id}>
            <td>
              <input
                className={`table-row__cell__input${
                  errors[index].placeholder ? ' error-border' : ''
                }`}
                type="text"
                value={placeholder}
                placeholder="Placeholder"
                onChange={(e) =>
                  onValueChanged({
                    value: e.target.value,
                    source: 'placeholder',
                    id,
                  })
                }
              />
              <div className="error">{errors[index].placeholder}</div>
            </td>
            <td>
              <input
                className={`table-row__cell__input${
                  errors[index].replacement ? ' error-border' : ''
                }`}
                type="text"
                value={replacement}
                placeholder="Replacement"
                onChange={(e) =>
                  onValueChanged({
                    value: e.target.value,
                    source: 'replacement',
                    id,
                  })
                }
              />
              <div className="error">{errors[index].replacement}</div>
            </td>
            {showNotAdded && (
              <td>
                <input
                  className={`table-row__cell__input${
                    errors[index].notAddedReplacement ? ' error-border' : ''
                  }`}
                  type="text"
                  value={notAddedReplacement}
                  placeholder="Not Added Replacement"
                  onChange={(e) =>
                    onValueChanged({
                      value: e.target.value,
                      source: 'notAddedReplacement',
                      id,
                    })
                  }
                />
                <div className="error">{errors[index].notAddedReplacement}</div>
              </td>
            )}
            <td>
              <button
                className="fill-cell danger"
                type="button"
                onClick={() => onDelete(id)}
              >
                <i className="far fa-trash-alt" />
              </button>
            </td>
          </tr>
        ),
      )}
    </tbody>
  </Table>
);

CustomPlaceholderTable.propTypes = {
  onAddClicked: PropTypes.func.isRequired,
  onValueChanged: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  placeholders: PropTypes.arrayOf(
    PropTypes.shape({
      placeholder: PropTypes.string,
      replacement: PropTypes.string,
      id: PropTypes.string.isRequired,
    }),
  ),
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      placeholder: PropTypes.string,
      replacement: PropTypes.string,
    }),
  ),
  showNotAdded: PropTypes.bool,
};

CustomPlaceholderTable.defaultProps = {
  placeholders: [],
  errors: [],
  showNotAdded: false,
};

export default CustomPlaceholderTable;
