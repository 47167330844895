import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { LeapMenuContextWrapper } from 'leap-menu-item';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import UserContext from './components/UserContext';
import Router from './router';
import store from './store/configureStore';
import { useParseUser } from './hooks';

import MainAlert from './components/MainAlert';
import ErrorBoundary from './components/ErrorBoundary';
import Head from './components/Head';
import Layout from './components/Layout/Layout';
import Loader from './components/Loader';
import AccessControl from './components/AccessControl';
import configureFive9 from './five9';
import initTalkDeskWidget from './components/Widgets/talkDesk';
import initAuviousWidget from './components/Widgets/auvious';

const App = () => {
  const { loggedIn, user } = useParseUser();
  const flags = useFlags();
  const ldClient = useLDClient();

  useEffect(() => {
    if (ldClient && flags) {
      if (flags['talk-desk-chat']) {
        initTalkDeskWidget();
        initAuviousWidget();
      } else {
        configureFive9();
      }
    }
  }, [ldClient, flags]);

  return (
    <ErrorBoundary>
      {/* Provides a single source of truth for user info, directly connected
       to Parse, and updated in near real time */}
      <UserContext.Provider value={{ loggedIn, user }}>
        <Provider store={store}>
          <Head>
            <MainAlert />
            <Loader>
              <AccessControl />
              <LeapMenuContextWrapper>
                <Router>
                  <Layout />
                </Router>
              </LeapMenuContextWrapper>
            </Loader>
          </Head>
        </Provider>
      </UserContext.Provider>
    </ErrorBoundary>
  );
};

export default App;
