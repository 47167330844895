/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import {
  fetchCustomizedMenuByOfficeId,
  updateOfficeAppTitles,
  startUpdateConfig,
} from '../../actions/auth';
import { showErrorAlert, hideAlert } from '../../actions/alert';
import { SwitchGroup } from '../FormGroup';
import Panel from '../Panel';
import Copy from './Copy';
import AppTitle from './AppTitle';
import TitleButton from '../TitleButton';

export class AppAppearance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayPrices: props.displayPrices,
      customMenuItem: props.customMenuItem,
      appTitles: props.appTitles,
      error: {},
    };
  }

  componentDidMount() {
    this.props.setCrumbs([
      { title: 'Customize Menu', link: '/app_appearance' },
    ]);
    this.props.setButtons(
      <>
        <TitleButton
          onClick={() => this.setState({ showCopy: true })}
          variant="primary"
          title="Copy"
        />
        <TitleButton
          onClick={this.onSaveClicked}
          variant="success"
          title="Save"
        />
      </>,
    );

    if (
      this.props.flags &&
      this.props.flags['customize-menus'] &&
      this.props.selectedOffice.id
    ) {
      this.props.fetchCustomizedMenuByOfficeId(this.props.selectedOffice.id);
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(props) {
    const combinedTitles = props.customMenuItem;
    this.setState({
      displayPrices: props.displayPrices,
      appTitles: props.appTitles,
      customMenuItem: combinedTitles,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedOffice.id !== this.props.selectedOffice.id) {
      this.props.fetchCustomizedMenuByOfficeId(this.props.selectedOffice.id);
    }
  }

  componentWillUnmount() {
    this.props.setCrumbs([]);
    this.props.setButtons();
  }

  onDisplayPricesChange = (checked) => {
    this.setState({ displayPrices: checked });
  };

  onSaveClicked = async () => {
    if (this.props.flags && this.props.flags['customize-menus']) {
      if (Object.keys(this.state.error).length > 0) {
        this.props.showErrorAlert({
          title: 'Error',
          message:
            'Cannot save due to validation errors. Please correct them and try again.',
          onConfirm: this.props.hideAlert,
        });
        return;
      }

      this.props.updateOfficeAppTitles(
        this.props.selectedOffice.id,
        this.state.customMenuItem,
      );
    } else {
      this.props.startUpdateConfig({
        showUnitPrices: this.state.displayPrices,
        appTitles: this.state.appTitles,
      });
    }
  };

  onTextInputChange = (e, id) => {
    if (this.props.flags && this.props.flags['customize-menus']) {
      const newValue = e.target.value.trim();
      const { customMenuItem, error } = this.state;

      const newError = { ...error }; // Clone the current error state

      if (newValue === '') {
        newError[id] = 'Field must not be empty';
      } else if (
        customMenuItem.some((item) => item.title === newValue && item.id !== id)
      ) {
        newError[id] = 'Duplicate title found';
      } else {
        delete newError[id]; // Clear error for this ID
      }

      this.setState({ error: newError });

      const updatedCustomMenuItems = customMenuItem.map((item) => {
        if (item.id === id) {
          return { ...item, title: newValue };
        }
        return item;
      });

      this.setState({ customMenuItem: updatedCustomMenuItems });
    } else {
      // to avoid component update conflicts (timing), setState calls that depend
      // on previous state must be handled in a setState callback
      this.setState((prevState) => {
        const appTitles = { ...prevState.appTitles };
        appTitles[e.source] = e.target.value;
        return { appTitles };
      });
    }
  };

  render() {
    return (
      <>
        <Copy
          title="Copy Customize Menu"
          show={this.state.showCopy}
          warning="Warning! This will overwrite your current Customize Menu Settings"
          configKeys={['appTitles', 'showUnitPrices']}
          onClose={() => this.setState({ showCopy: false })}
        />
        <div className="default-page-padding">
          <DisplayPrices
            value={this.state.displayPrices}
            onValueChange={this.onDisplayPricesChange}
          />
          <AppTitle
            onTextInputChange={this.onTextInputChange}
            appTitles={this.state.appTitles}
            customMenuItem={this.state.customMenuItem}
            showNewCustomMenu={
              this.props.flags && this.props.flags['customize-menus']
            }
            error={this.state.error}
          />
        </div>
      </>
    );
  }
}

const DisplayPrices = ({ value, onValueChange }) => (
  <Panel title="Display Unit Prices">
    <SwitchGroup
      title="Display Unit Prices"
      checked={value}
      onChange={onValueChange}
    />
  </Panel>
);

export const AppTitleHeaderCell = ({ title }) => (
  <th className="app-appearance-header-cell">
    <div className="table-header__title ">{title}</div>
  </th>
);

AppTitleHeaderCell.propTypes = {
  title: PropTypes.string,
};
AppTitleHeaderCell.defaultProps = {
  title: '',
};

const appTitlePropType = PropTypes.shape({
  estimates: PropTypes.string,
  contracts: PropTypes.string,
  proposals: PropTypes.string,
  financeCalculator: PropTypes.string,
  priceGuide: PropTypes.string,
  availableManagers: PropTypes.string,
  pitchBooks: PropTypes.string,
  settings: PropTypes.string,
  creditApplication: PropTypes.string,
});

AppAppearance.propTypes = {
  displayPrices: PropTypes.bool,
  appTitles: appTitlePropType,
  startUpdateConfig: PropTypes.func.isRequired,
  ...layoutContextPropTypes,
};

AppAppearance.defaultProps = {
  displayPrices: false,
  appTitles: {
    estimates: '',
    contracts: '',
    proposals: '',
    financeCalculator: '',
    priceGuide: '',
    availableManagers: '',
    pitchBooks: '',
    settings: '',
    creditApplication: '',
  },
};

DisplayPrices.propTypes = {
  value: PropTypes.bool,
  onValueChange: PropTypes.func.isRequired,
};

DisplayPrices.defaultProps = {
  value: false,
};

const mapStateToProps = ({
  auth: {
    config: { objectId = '', showUnitPrices = false, appTitles = {} } = {},
    selectedOffice,
    customMenuItem,
  },
}) => ({
  configObjectId: objectId,
  displayPrices: showUnitPrices,
  selectedOffice,
  customMenuItem,
  appTitles,
  objectId, // this forces props to update when office is changed
});

const mapDispatchToProps = (dispatch) => ({
  fetchCustomizedMenuByOfficeId: (officeId) =>
    dispatch(fetchCustomizedMenuByOfficeId(officeId)),
  updateOfficeAppTitles: (officeId, menuItem) =>
    dispatch(updateOfficeAppTitles(officeId, menuItem)),
  showErrorAlert: (object) => dispatch(showErrorAlert(object)),
  hideAlert: () => dispatch(hideAlert()),
  startUpdateConfig: (updates) => dispatch(startUpdateConfig(updates)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(withLDConsumer()(AppAppearance)),
);
