/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem, Button } from 'leap-menu-item';
import ContractObject from '../../Models/ContractObject';
import BodyCellItem from '../../Models/BodyCellItem';
import DynamicObjectEditor from './DynamicObjectEditor';

// const inputTypeMaps = [{
//   value: 'default',
//   text: 'Standard Keyboard',
// }, {
//   value: 'ccExpDate',
//   text: 'Credit Card Exp Date',
// }, {
//   value: 'creditCard',
//   text: 'Credit Card Number',
// },
// {
//   value: 'currency',
//   text: 'Currency ($1.00)',
// },
// {
//   value: 'currencyWhole',
//   text: 'Currency ($1)',
// },{
//   value: 'datePicker',
//   text: 'Date Picker',
// },{
//   value: 'dateTimePicker',
//   text: 'Date & Time',
// },{
//   value: 'timePicker',
//   text: 'Time',
// }, {
//   value: 'email',
//   text: 'Email Address',
// }, {
//   value: 'numbers',
//   text: 'Number Keyboard',
// }, {
//   value: 'keypad',
//   text: 'Number Pad (1)',
// }, {
//   value: 'keypadDecimal',
//   text: 'Number Pad (1.00)',
// }, {
//   value: 'phone',
//   text: 'Phone Number',
// }, {
//   value: 'picker',
//   text: 'Picker',
// }, {
//   value: 'financeOptionsPicker',
//   text: 'Finance Options Picker',
// }];

const shouldDisableConditions = (pickerOptions, selected) =>
  selected.length &&
  pickerOptions.length &&
  pickerOptions.filter((option) => !selected.includes(option)).length === 0;

class DynamicInputEditor extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      isOpen: undefined,
      showDefault: true,
    };
  }

  render() {
    const { contractObject, cellItem, onChange } = this.props;
    const updatedItem = { ...cellItem };
    const dynamicInputType = updatedItem.dynamicInputType
      ? { ...updatedItem.dynamicInputType }
      : {};
    dynamicInputType.objects = dynamicInputType.objects || [];
    let defaultObject = dynamicInputType.objects.find(
      ({ values = [] }) => values.indexOf('default') === 0,
    );
    if (!defaultObject) {
      defaultObject = {
        inputType: 'default',
        enabled: false,
        required: false,
        values: ['default'],
        fractionDigits: '',
        pickerValues: [],
      };
      dynamicInputType.objects.unshift(defaultObject);
    }
    const otherObjects = dynamicInputType.objects.filter(
      ({ values = [] }) => values.indexOf('default') === -1,
    );
    const allValues = contractObject.dynamicValueOptionsForCellId(
      dynamicInputType.cellId,
    );
    const selectedValues = [];
    otherObjects.forEach(({ values = [] }) => {
      values.forEach((value) => selectedValues.push(value));
    });
    return (
      <div className="dy-object-editor" style={{ minWidth: '250px' }}>
        <MenuItem
          text="Dynamic Settings"
          shouldDismissPopover={false}
          popoverProps={{ isOpen: this.state.isOpen }}
          onClick={() =>
            this.setState({ isOpen: this.state.isOpen ? undefined : true })
          }
        >
          <MenuItem
            text="Dependent Cell"
            label={dynamicInputType.cellId}
            shouldDismissPopover={false}
          >
            {contractObject.getAllCellIdentifiers(cellItem.cellId).map((id) => (
              <MenuItem
                shouldDismissPopover={false}
                key={`dynamic-${id}`}
                text={id}
                icon={id === dynamicInputType.cellId ? 'dot' : 'blank'}
                active={id === dynamicInputType.cellId}
                onClick={() => {
                  onChange({
                    ...dynamicInputType,
                    cellId: id,
                  });
                }}
              />
            ))}
          </MenuItem>
          <Menu.Divider />
          <MenuItem
            text="Conditions"
            shouldDismissPopover={false}
            labelElement={
              <Button
                icon="plus"
                minimal
                small
                disabled={shouldDisableConditions(allValues, selectedValues)}
                onClick={() => {
                  dynamicInputType.objects.push({
                    inputType: 'default',
                    enabled: true,
                    required: false,
                    values: [],
                    fractionDigits: '',
                    pickerValues: [],
                  });
                  onChange(dynamicInputType);
                }}
              />
            }
          />
          <Menu.Divider />
          {this.state.showDefault && (
            <DynamicObjectEditor
              title="Default"
              menuKey="Default"
              openSubMenu={this.openSubMenu}
              object={defaultObject}
              hideValues
              onChange={(value) => {
                dynamicInputType.objects[0] = value;
                onChange(dynamicInputType);
              }}
            />
          )}
          {this.state.showDefault &&
            otherObjects.map((object, index) => {
              const { values = [] } = object;
              const label =
                values.length > 1 ? values.join(', ') : values.join('');
              const inactiveOptions = selectedValues.filter(
                (value) => values.indexOf(value) === -1,
              );
              const hideInputType =
                cellItem.cellType !== 'textShort' &&
                cellItem.cellType !== 'textLong' &&
                cellItem.cellType !== 'textXLong';
              const hideDefaultValue = cellItem.cellType === 'imagePicker';
              return (
                <DynamicObjectEditor
                  key={`dynamic-${cellItem.objectId}-${index}`}
                  title={label}
                  object={object}
                  options={allValues}
                  inactiveOptions={inactiveOptions}
                  hideInputType={hideInputType}
                  hideDefaultValue={hideDefaultValue}
                  onChange={(value) => {
                    dynamicInputType.objects[index + 1] = value;
                    onChange(dynamicInputType);
                  }}
                  onDelete={() => {
                    dynamicInputType.objects.splice(index + 1, 1);
                    onChange(dynamicInputType);
                  }}
                />
              );
            })}
        </MenuItem>
      </div>
    );
  }
}

DynamicInputEditor.propTypes = {
  onChange: PropTypes.func.isRequired,
  cellItem: PropTypes.instanceOf(BodyCellItem).isRequired,
  contractObject: PropTypes.instanceOf(ContractObject).isRequired,
};

export default DynamicInputEditor;
