/* eslint-disable import/no-cycle */
import Parse from 'parse';
import { handleError } from './auth';
import { showSuccessAlert, hideAlert } from './alert';
import { history } from '../router';
import { showLoader, hideLoader } from './loading';

export const setPackagesCount = (count = 0) => ({
  type: 'SET_PACKAGES_COUNT',
  count,
});

export const addPackages = (items = []) => ({
  type: 'ADD_PACKAGES',
  items,
});

export const setPackages = (items = []) => ({
  type: 'SET_PACKAGES',
  items,
});

export const setPackageOffices = (offices = []) => ({
  type: 'SET_PACKAGE_OFFICES',
  offices,
});

export const setPackageCategory = (category = '') => ({
  type: 'SET_PACKAGE_CATEGORY',
  category,
});

export const startUpdatePackage = (pkg) => ({
  type: 'UPDATE_PACKAGE',
  pkg,
});

export const startFetchPackages = (limit = 100) => async (
  dispatch,
  getState,
) => {
  try {
    const { packages = [] } = getState();
    const query = new Parse.Query('Package');
    const count = await query.count();
    if (count > packages.items.length) {
      query.ascending('createdAt');
      query.select([
        'items',
        'name',
        'orderNumber',
        'includedOffices',
        'category',
        'thumbnail',
      ]);
      query.limit(limit);
      query.skip(packages.items.length);
      const items = await query.find();
      dispatch(addPackages(items.map((item) => item.toJSON())));
    }
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const startGetPackages = () => async (dispatch) => {
  try {
    const query = new Parse.Query('Package');
    query.select([
      'items',
      'name',
      'orderNumber',
      'includedOffices',
      'category',
      'thumbnail',
    ]);
    const packages = [];
    await query.each((object) => {
      packages.push(object.toJSON());
    });
    dispatch(setPackages(packages));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const startUpdatePackages = ({ updates }) => async (dispatch) => {
  try {
    const parsePackages = updates.map((pack) => {
      const parsages = new Parse.Object('Package');
      parsages.id = pack.objectId;
      parsages.set('orderNumber', pack.orderNumber);
      return parsages;
    });
    dispatch(showLoader('Updating Packages'));
    await Parse.Object.saveAll(parsePackages);
    dispatch(hideLoader());
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const startSavePackage = (object, file) => async (dispatch) => {
  try {
    const { objectId, category, name, includedOffices = [], items } = object;
    const pkg = new Parse.Object('Package');
    pkg.id = objectId;
    const offices = includedOffices.map((office) => {
      const pointer = new Parse.Object('Office');
      pointer.id = office.objectId;
      return pointer;
    });
    const payload = {
      category,
      includedOffices: offices,
      name,
      items,
    };
    if (file) {
      payload.thumbnail = file;
    } else {
      pkg.unset('thumbnail');
    }
    await pkg.save(payload);
    if (objectId) {
      dispatch(startUpdatePackage(pkg.toJSON()));
    } else {
      dispatch(addPackages([pkg.toJSON()]));
    }
    history.push('/packages');
    dispatch(
      showSuccessAlert({
        title: 'Success!',
        message: `${name} Package saved successfully.`,
        onConfirm: () => dispatch(hideAlert()),
      }),
    );
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const setPackageOptions = (options = []) => ({
  type: 'SET_PACKAGE_OPTIONS',
  options,
});

export const startFetchPackageOptions = (category = '', offices = []) => async (
  dispatch,
) => {
  try {
    const query = new Parse.Query('SSMeasureSheetItem');
    query.greaterThan('itemCount', 1);
    query.equalTo('category', category);
    query.containedIn(
      'includedOffices',
      offices.map(({ objectId }) => {
        const office = new Parse.Object('Office');
        office.id = objectId;
        return office;
      }),
    );
    query.select(['subCategory', 'itemName', 'items', 'image']);
    query.include('items');
    query.limit(99999);
    query.ascending('category');
    query.descending('orderNumber');
    const options = await query.find();
    dispatch(setPackageOptions(options.map((option) => option.toJSON())));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const startDeletePackage = (objectId) => async (dispatch, getState) => {
  try {
    const object = new Parse.Object('Package');
    object.id = objectId;
    await object.destroy();
    const newItems = getState().packages.items.filter(
      (item) => item.objectId !== objectId,
    );
    dispatch(setPackages(newItems));
    dispatch(
      showSuccessAlert({
        title: 'Success!',
        message: 'Package successfully deleted',
        onConfirm: () => dispatch(hideAlert()),
      }),
    );
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const setIncludedOfficesFilter = (offices) => ({
  type: 'SET_PACKAGES_INCLUDED_OFFICES_FILTER',
  offices,
});

export const resetPackages = () => ({
  type: 'RESET_PACKAGES',
});

export const queryPackages = (params) => async (dispatch, getState) => {
  try {
    const { category } = params;

    const {
      auth: { offices },
      plan = {},
    } = getState();
    const query = new Parse.Query('Package');
    query.select([
      'items',
      'name',
      'orderNumber',
      'includedOffices',
      'category',
      'thumbnail',
    ]);

    let { includedOffices } = params;

    if (plan.maxOfficeCount === 1) {
      includedOffices = [offices[0].id];
    }

    if (includedOffices.length) {
      const includedOfficesFilter = includedOffices.map((objectId) => {
        const office = new Parse.Object('Office');
        office.id = objectId;
        return office;
      });
      query.containedIn('includedOffices', includedOfficesFilter);
    } else {
      query.notContainedIn('includedOffices', offices);
    }

    query.equalTo('category', category);

    const count = await query.count();
    dispatch(setPackagesCount(count));
    const packages = [];

    await query.each((object) => {
      packages.push(object.toJSON());
    });

    dispatch(
      setPackages(packages.sort((a, b) => a.orderNumber - b.orderNumber)),
    );
  } catch (e) {
    dispatch(handleError(e));
  }
};
