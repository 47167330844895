import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Panel from '../../Panel';
import ResourceGroupCell from '../../Resources/ResourceGroupCell';
import {
  startFetchSharedResourceGroups,
  resetSharedResources,
  startRemoveResourceGroup,
} from '../../../actions/resources';
import { showDeleteConfirmation } from '../../../actions/deleteConfirmation';

const SharedResourcesIndex = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const sharedResourceGroups = useSelector(
    ({ resources: { sharedResourceGroups: s = [] } }) => s,
  );

  useEffect(() => {
    dispatch(startFetchSharedResourceGroups());
  }, [dispatch]);

  const onDeleteGroupClick = async (sharedResourceGroup) => {
    dispatch(
      showDeleteConfirmation({
        title: 'Delete Resource Group ?',
        message: `Are you sure you want to delete ${sharedResourceGroup.get(
          'name',
        )}`,
        onConfirm: () => {
          deleteGroup(sharedResourceGroup);
        },
      }),
    );
  };

  const deleteGroup = async (sharedResourceGroup) => {
    await dispatch(startRemoveResourceGroup(sharedResourceGroup));
    dispatch(startFetchSharedResourceGroups());
  };

  const goToEditResourceGroup = (sharedResourceGroup) => {
    dispatch(resetSharedResources());
    history.push(`/shared_resources/edit/${sharedResourceGroup.id}`);
  };

  return (
    <div className="default-page-padding">
      <Panel title="Shared Resources">
        <div className="resources-container">
          {sharedResourceGroups.map((item) => (
            <ResourceGroupCell
              key={item.id}
              thumbnailURL={
                item.has('thumbnail')
                  ? item.get('thumbnail').url()
                  : '/images/no_image.png'
              }
              name={item.get('name')}
              onDelete={() => onDeleteGroupClick(item)}
              onClick={() => goToEditResourceGroup(item)}
            />
          ))}
        </div>
      </Panel>
    </div>
  );
};

export default SharedResourcesIndex;
