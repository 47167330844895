/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-cycle */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MultiSelect from '../Misc/MultiSelect';
import { startFetchStates } from '../../actions/config';

const valueRenderer = (value, options) => {
  if (value.length > 3 && value.length === options.length) {
    return `All ${value.length} states selected`;
  }
  switch (value.length) {
    case 0:
      return 'Select States...';
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
      return value
        .map((id) => options.find(({ value: officeId }) => id === officeId))
        .map(({ label }) => label)
        .join(', ');
    default:
      return `${value.length} States selected`;
  }
};

class StateSelector extends React.Component {
  componentDidMount() {
    this.props.startFetchStates();
  }

  render() {
    const { selected, onChange, stateValues } = this.props;

    return (
      <MultiSelect
        open
        options={stateValues.map((stateValue) => ({
          value: stateValue,
          label: stateValue,
        }))}
        onSelectedChanged={onChange}
        selected={selected}
        selectAllLabel="Select All"
        valueRenderer={valueRenderer}
        hasSelectAll={stateValues.length > 1}
        disableSearch={stateValues.length <= 1}
      />
    );
  }
}

StateSelector.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.string),
  stateValues: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  startFetchStates: PropTypes.func.isRequired,
};

StateSelector.defaultProps = {
  selected: [],
  stateValues: [],
};

const mapStateToProps = ({ config: { states } }) => ({
  stateValues: states,
});

const mapDispatchToProps = (dispatch) => ({
  startFetchStates: () => dispatch(startFetchStates()),
});

export default connect(mapStateToProps, mapDispatchToProps)(StateSelector);
