/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react';
import { Popover } from 'leap-menu-item';

const PopoverSwitch = (switchProps) => {
  const { showPop, ...props } = switchProps;
  if (showPop) {
    return (
      <Popover
        content={
          <textarea
            onContextMenu={(e) => e.stopPropagation()}
            autoFocus
            onBlur={props.onBlur}
            fill
            ref={props.inputRef}
            defaultValue={props.defaultValue}
            className="ignore-react-onclickoutside"
          />
        }
        isOpen={props.isOpen}
        disabled={!!props.lockedTitle}
        onClose={props.onClose}
      >
        {props.children}
      </Popover>
    );
  }
  return props.children;
};

export default PopoverSwitch;
