import React from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem, TextArea, Button } from 'leap-menu-item';
import YesNoMenuItem from '../YesNoMenuItem';
import PickerOptionsEditor from '../../PickerOptionsEditor';
import BodyCellItem from '../../Models/BodyCellItem';
import DebounceInputWithNet from '../DebounceInputWithNet';

const inputTypeMaps = [
  {
    value: 'default',
    text: 'Standard Keyboard',
  },
  {
    value: 'ccExpDate',
    text: 'Credit Card Exp Date',
  },
  {
    value: 'creditCard',
    text: 'Credit Card Number',
  },
  {
    value: 'currency',
    text: 'Currency ($1.00)',
  },
  {
    value: 'currencyWhole',
    text: 'Currency ($1)',
  },
  {
    value: 'datePicker',
    text: 'Date Picker',
  },
  {
    value: 'dateTimePicker',
    text: 'Date & Time',
  },
  {
    value: 'timePicker',
    text: 'Time',
  },
  {
    value: 'email',
    text: 'Email Address',
  },
  {
    value: 'numbers',
    text: 'Number Keyboard',
  },
  {
    value: 'keypad',
    text: 'Number Pad (1)',
  },
  {
    value: 'keypadDecimal',
    text: 'Number Pad (1.00)',
  },
  {
    value: 'phone',
    text: 'Phone Number',
  },
  {
    value: 'picker',
    text: 'Picker',
  },
  {
    value: 'sizePicker',
    text: 'Size Picker',
  },
  {
    value: '3DSizePicker',
    text: '3D Size Picker',
  },
  {
    value: 'financeOptionsPicker',
    text: 'Finance Options Picker',
  },
  {
    value: 'statePicker',
    text: 'State Picker',
  },
  {
    value: 'zipCode',
    text: 'Zip Code',
  },
];

const DynamicObjectEditor = ({
  hideValues,
  options,
  title,
  object,
  onChange,
  inactiveOptions,
  onDelete,
  hideInputType,
  hideDefaultValue,
}) => {
  const updatedObject = { ...object };
  return (
    <div
      className="dy-object-editor"
      style={{ minWidth: '250px', maxWidth: '350px' }}
    >
      <MenuItem
        shouldDismissPopover={false}
        text={title}
        labelElement={
          onDelete && (
            <Button icon="small-cross" minimal small onClick={onDelete} />
          )
        }
      >
        <div style={{ minWidth: '250px', maxWidth: '350px' }}>
          {!hideValues && (
            <MenuItem
              shouldDismissPopover={false}
              text="Dependent Values"
              label={`${updatedObject.values.length}`}
            >
              {options.length > 0 &&
                options.map((option, index) => (
                  <MenuItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${option}-${index}`}
                    shouldDismissPopover={false}
                    text={option}
                    active={object.values.indexOf(option) > -1}
                    icon={object.values.indexOf(option) > -1 ? 'dot' : 'blank'}
                    disabled={inactiveOptions.indexOf(option) > -1}
                    onClick={() => {
                      const values = updatedObject.values || [];
                      const updatedValues = values.filter((v) => v !== option);
                      if (values.length === updatedValues.length) {
                        updatedValues.push(option);
                      }
                      updatedObject.values = updatedValues;
                      onChange(updatedObject);
                    }}
                  />
                ))}
              {!options.length && (
                <DebounceInputWithNet
                  element={TextArea}
                  minLength={0}
                  debounceTimeout={300}
                  value={updatedObject.values[0]}
                  onChange={(e) => {
                    const values = updatedObject.values || [];
                    values[0] = e.target.value;
                    updatedObject.values = values;
                    onChange(updatedObject);
                  }}
                />
              )}
            </MenuItem>
          )}
          {!hideValues && <Menu.Divider />}
          <YesNoMenuItem
            text="Show Cell"
            active={updatedObject.enabled}
            onChange={(value) => {
              updatedObject.enabled = value;
              onChange(updatedObject);
            }}
          />
          <YesNoMenuItem
            text="Required"
            active={updatedObject.required}
            onChange={(value) => {
              updatedObject.required = value;
              onChange(updatedObject);
            }}
          />
          {updatedObject.enabled && !hideInputType && (
            <MenuItem
              shouldDismissPopover={false}
              text="Input Type"
              label={
                (
                  inputTypeMaps.find(
                    ({ value }) => value === updatedObject.inputType,
                  ) || { text: updatedObject.inputType }
                ).text
              }
            >
              {inputTypeMaps.map(({ value, text }) => (
                <MenuItem
                  key={value}
                  text={text}
                  shouldDismissPopover={false}
                  icon={updatedObject.inputType === value ? 'dot' : 'blank'}
                  active={updatedObject.inputType === value}
                  onClick={() => {
                    updatedObject.inputType = value;
                    onChange(updatedObject);
                  }}
                />
              ))}
            </MenuItem>
          )}
          {(updatedObject.inputType === 'picker' ||
            updatedObject.inputType === 'multiSelectPicker') &&
            !hideInputType && (
              <PickerOptionsEditor
                values={updatedObject.pickerValues}
                onChange={(values) => {
                  updatedObject.pickerValues = values;
                  onChange(updatedObject);
                }}
                menuItemProps={{
                  text: 'Picker Options',
                  label: updatedObject.pickerValues
                    ? updatedObject.pickerValues.length
                    : '0',
                  shouldDismissPopover: false,
                }}
              />
            )}
          {(updatedObject.inputType === 'sizePicker' ||
            updatedObject.inputType === '3DSizePicker') &&
            !hideInputType && (
              <MenuItem
                text="Fraction Digits"
                label={BodyCellItem.fractionDigitsLabelForFractionDigits(
                  object.fractionDigits,
                )}
              >
                {BodyCellItem.fractionDigits.map((fractionDigit) => (
                  <MenuItem
                    key={fractionDigit}
                    text={BodyCellItem.fractionDigitsLabelForFractionDigits(
                      fractionDigit,
                    )}
                    shouldDismissPopover={false}
                    active={updatedObject.fractionDigits === fractionDigit}
                    icon={
                      updatedObject.fractionDigits === fractionDigit
                        ? 'dot'
                        : 'blank'
                    }
                    onClick={() => {
                      updatedObject.fractionDigits = fractionDigit;
                      onChange(updatedObject);
                    }}
                  />
                ))}
              </MenuItem>
            )}
          {!hideDefaultValue && (
            <div style={{ width: '100%', overflow: 'hidden' }}>
              <MenuItem
                text="Default Value"
                label={updatedObject.defaultValue}
                shouldDismissPopover={false}
              >
                <DebounceInputWithNet
                  element={TextArea}
                  minLength={0}
                  debounceTimeout={300}
                  value={updatedObject.defaultValue}
                  onChange={(e) => {
                    updatedObject.defaultValue = e.target.value;
                    onChange(updatedObject);
                  }}
                />
              </MenuItem>
            </div>
          )}
        </div>
      </MenuItem>
    </div>
  );
};

DynamicObjectEditor.propTypes = {
  hideValues: PropTypes.bool,
  object: PropTypes.shape({
    inputType: PropTypes.string.isRequired,
    enabled: PropTypes.bool,
    required: PropTypes.bool,
    values: PropTypes.arrayOf(PropTypes.string),
    fractionDigits: PropTypes.string,
    pickerValues: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  options: PropTypes.arrayOf(PropTypes.string),
  inactiveOptions: PropTypes.arrayOf(PropTypes.string),
  onDelete: PropTypes.func,
  hideInputType: PropTypes.bool,
  hideDefaultValue: PropTypes.bool,
};

DynamicObjectEditor.defaultProps = {
  hideValues: false,
  options: [],
  inactiveOptions: [],
  onDelete: undefined,
  hideInputType: false,
  hideDefaultValue: false,
};

export default DynamicObjectEditor;
