import React from 'react';
import PropTypes from 'prop-types';

const TemplateCell = ({
  thumbnailURL,
  name,
  onCopy,
  onDelete,
  onEditClicked,
  onPublish,
}) => (
  <div className="mask no-caption resources-cell card">
    <div className="thumbnail-container">
      <div className="img-thumbnail view view-first">
        <img
          style={{ margin: 'auto', height: '100%', display: 'block' }}
          src={thumbnailURL}
          alt="Thumbnail"
        />
        <div className="mask no-caption">
          <div className="tools tools-bottom">
            <button
              type="button"
              style={{ backgroundColor: 0, border: 0 }}
              onClick={onEditClicked}
            >
              <i className="fas fa-pencil-alt" />
            </button>
            <button type="button" onClick={onCopy}>
              <i className="far fa-copy" />
            </button>
            <button type="button" onClick={onDelete} id="deleteButton">
              <i className="fas fa-trash-alt" />
            </button>
            <button type="button" onClick={onPublish}>
              <i className="fas fa-upload" />
            </button>
          </div>
        </div>
      </div>
      <div className="caption">
        <p>{name}</p>
      </div>
    </div>
  </div>
);

TemplateCell.propTypes = {
  thumbnailURL: PropTypes.string,
  name: PropTypes.string,
  onCopy: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEditClicked: PropTypes.func,
  onPublish: PropTypes.func.isRequired,
};

TemplateCell.defaultProps = {
  name: '',
  thumbnailURL: '/images/no_image.png',
  onEditClicked: undefined,
};

export default TemplateCell;
