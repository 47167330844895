export const defaultState = {
  emails: [],
  text: '',
  count: 0,
  page: 1,
  limit: 10,
};

export default (
  state = defaultState,
  {
    type,
    emails = [],
    text = '',
    count = 0,
    // startDate,
    // endDate,
  },
) => {
  switch (type) {
    case 'SET_EMAILS':
      return {
        ...state,
        emails,
      };
    case 'SET_EMAIL_SEARCH_TEXT':
      return {
        ...state,
        searchText: text,
      };
    // case 'SET_EMAIL_DATE_RANGE':
    //   return {
    //     ...state,
    //     startDate,
    //     endDate,
    //   };
    case 'SET_EMAIL_COUNT':
      return {
        ...state,
        count,
      };
    case 'RESET_EMAILS':
      return {
        ...defaultState,
      };
    default:
      return state;
  }
};
