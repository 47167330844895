import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, Modal, Button } from 'react-bootstrap';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import Panel from '../Panel';
import TitleButton from '../TitleButton';
import {
  startSaveOffices as startSaveOfficesAction,
  startDeleteOffice as startDeleteOfficeAction,
  setOffices as setOfficesAction,
} from '../../actions/offices';
import { historyPropType } from '../../router';
import Switch from '../Misc/Switch';
import { DropDownGroup } from '../FormGroup';

export class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.setButtonValues();
  }

  componentDidUpdate(prevProps) {
    const { maxOfficeCount, offices } = this.props;
    const {
      maxOfficeCount: prevMaxOfficeCount,
      offices: prevOffices,
    } = prevProps;
    if (
      prevMaxOfficeCount !== maxOfficeCount ||
      prevOffices.length !== offices.length
    ) {
      this.setButtonValues();
    }
  }

  componentWillUnmount() {
    const { setCrumbs, setButtons } = this.props;
    setCrumbs([]);
    setButtons('');
  }

  setButtonValues = () => {
    const { setButtons } = this.props;
    setButtons(
      <>
        <TitleButton
          disabled={!this.isInLimit()}
          variant="success"
          onClick={this.onNewClicked}
          title="New"
        />
        <TitleButton
          variant="success"
          onClick={this.onSaveClicked}
          title="Save"
        />
      </>,
    );
  };

  onSaveClicked = () => {
    const { startSaveOffices, offices } = this.props;
    startSaveOffices(offices);
  };

  onNewClicked = () => {
    const { history } = this.props;
    history.push('/offices/new');
  };

  onDeleteConfirm = () => {
    const { startDeleteOffice } = this.props;
    const { deleteOffice, moveOffice } = this.state;
    startDeleteOffice(
      {
        officeId: deleteOffice,
        moveOfficeId: moveOffice.value,
      },
      () => {
        this.setState({ deleteOffice: undefined });
      },
    );
  };

  isInLimit = () => {
    const { maxOfficeCount, offices } = this.props;
    const inLimit =
      maxOfficeCount === -1 ? true : offices.length < maxOfficeCount;
    return inLimit;
  };

  updateValue = (objectId, value, key) => {
    const { offices, setOffices } = this.props;
    const updatedOffices = offices.map((office) => {
      if (office.id === objectId) {
        office.set(key, value);
      }
      return office;
    });
    setOffices(updatedOffices);
  };

  render() {
    const { offices, selectedOffice, maxOfficeCount } = this.props;
    const { deleteOffice, moveOffice } = this.state;
    return (
      <>
        {!!deleteOffice && (
          <Modal
            show={!!deleteOffice}
            onHide={() => this.setState({ deleteOffice: undefined })}
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete Office</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                If users are currently logged into this office, which office
                should they be moved to?
              </div>
              <DropDownGroup
                title=""
                value={moveOffice}
                options={offices
                  .filter((office) => office.id !== deleteOffice)
                  .map((office) => ({
                    label: office.get('name'),
                    value: office.id,
                  }))}
                onChange={(office) =>
                  this.setState(() => ({ moveOffice: office }))
                }
                closeMenuOnSelect
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="success" onClick={this.onDeleteConfirm}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        <div className="default-page-padding">
          <Panel title="Offices">
            <div>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th style={{ width: '100%' }}>Name</th>
                    <th style={{ maxWidth: '150px', minWidth: '150px' }}>
                      Search All Estimates
                    </th>
                    <th style={{ maxWidth: '25px', minWidth: '25px' }} />
                  </tr>
                </thead>
                <tbody>
                  {offices.map((office) => (
                    <tr key={office.id}>
                      <td>
                        <input
                          className="table-row__cell__input"
                          type="text"
                          value={office.get('name')}
                          placeholder="Name"
                          onChange={(e) =>
                            this.updateValue(office.id, e.target.value, 'name')
                          }
                        />
                      </td>
                      <td>
                        <Switch
                          checked={office.get('canSearchAllEstimates')}
                          onChange={() =>
                            this.updateValue(
                              office.id,
                              !office.get('canSearchAllEstimates'),
                              'canSearchAllEstimates',
                            )
                          }
                          large
                        />
                      </td>
                      <td>
                        <button
                          onClick={() =>
                            this.setState({ deleteOffice: office.id })
                          }
                          type="button"
                          style={{
                            backgroundColor: 'transparent',
                            border: 0,
                            cursor:
                              office.id === selectedOffice.id
                                ? 'not-allowed'
                                : undefined,
                          }}
                          disabled={office.d === selectedOffice.id}
                        >
                          <i className="fas fa-trash-alt danger" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {!this.isInLimit() && (
                <p className="danger">
                  Plan is limited to {maxOfficeCount} Offices(s)
                </p>
              )}
            </div>
          </Panel>
        </div>
      </>
    );
  }
}

Index.propTypes = {
  history: historyPropType.isRequired,
  maxOfficeCount: PropTypes.number.isRequired,
  offices: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      objectId: PropTypes.string.isRequired,
    }),
  ),
  selectedOffice: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  startSaveOffices: PropTypes.func.isRequired,
  startDeleteOffice: PropTypes.func.isRequired,
  setOffices: PropTypes.func.isRequired,
  ...layoutContextPropTypes,
};

Index.defaultProps = {
  offices: [],
};

const mapStateToProps = ({ auth, plan = {} }) => ({
  offices: auth.offices || [],
  selectedOffice: auth.selectedOffice,
  maxOfficeCount: plan.maxOfficeCount,
});

const mapDispatchToProps = (dispatch) => ({
  setOffices: (offices) => dispatch(setOfficesAction(offices)),
  startSaveOffices: (offices) => dispatch(startSaveOfficesAction(offices)),
  startDeleteOffice: (objectId, callback) =>
    dispatch(startDeleteOfficeAction(objectId, callback)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(Index),
);
