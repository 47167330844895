import Parse from 'parse';
import _ from 'lodash';
import CreditApplicationSection from './CreditApplicationSection';

class CreditApplicationObject extends Parse.Object {
  constructor(obj) {
    super('CreditApplicationObject');
    Object.assign(this, obj);
  }

  getAllCellIds() {
    const cellIds = [];
    this.sections.forEach((section) => {
      section.cells.forEach((cell) => {
        cellIds.push(cell.cellId);
      });
    });
    return _.uniq(cellIds.filter((id) => id !== ''));
  }

  getDynamicValueOptionsForCellId = (cellId) => {
    let options = [];
    this.sections.forEach((section) => {
      section.cells.forEach((cell) => {
        if (cell.cellId === cellId) {
          switch (cell.cellType) {
            case 'switched':
              options.push(cell.switchValueOn);
              options.push(cell.switchValueOff);
              return;
            case 'textWords':
            case 'textSentence':
              options = cell.pickerOptions || [];
              break;
            default:
          }
        }
      });
    });
    return options;
  };

  smallJSON() {
    const image = this.get('image');
    const itemJson = {
      objectId: this.id,
      title: this.get('title'),
      image: image ? image.toJSON() : undefined,
      pdfDisclosure: this.get('pdfDisclosure'),
      sections: this.get('sections'),
      includedOffices: this.get('includedOffices'),
    };
    const partnerCreditApplication = this.get('partnerCreditApplication');
    if (partnerCreditApplication) {
      itemJson.partnerCreditApplication = partnerCreditApplication.toJSON();
    }
    return itemJson;
  }

  set(key, value, options) {
    if (key === 'sections') {
      this.sections = value;
      return;
    }
    super.set(key, value, options);
  }

  get(key) {
    if (key === 'sections') {
      return this.sections;
    }
    return super.get(key);
  }

  filteredSections() {
    const topSections = [];
    const leftSections = [];
    const rightSections = [];
    this.sections.forEach((section) => {
      switch (section.section) {
        case 'top':
          topSections.push(section);
          break;
        case 'left':
          leftSections.push(section);
          break;
        case 'right':
          rightSections.push(section);
          break;
        default:
          break;
      }
    });
    return { topSections, leftSections, rightSections };
  }

  get sections() {
    const sections = super.get('sections') || [];
    return sections.map(
      (section, index) => new CreditApplicationSection(section, index),
    );
  }

  set sections(value = []) {
    const sections = value.map((section) => section.toJSON());
    super.set('sections', sections);
  }
}

export default CreditApplicationObject;
