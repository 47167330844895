/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Parse from 'parse';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDispatch, connect } from 'react-redux';
import { handleError } from '../../actions/auth';
import { SectionTitle } from '../SharedComponents/SectionTitle';

const getNameFromSource = (source, mainMenuOptions) => {
  switch (source) {
    case 'contractUpload':
      return 'Contract';
    case 'proposalUpload':
      return 'Proposal';
    default: {
      const option = mainMenuOptions.find(
        ({ objectId }) => objectId === source,
      );
      if (option) {
        return option.title;
      }
      return 'Unknown';
    }
  }
};

const ResendButton = ({ documentId }) => {
  const [label, setLabel] = useState('Remind');
  const dispatch = useDispatch();
  return (
    <button
      style={{ display: 'block' }}
      type="button"
      onClick={async () => {
        try {
          setLabel('Sending...');
          await Parse.Cloud.run('lss_doc_resend', { documentId });
          setLabel('Sent');
          setTimeout(() => {
            setLabel('Remind');
          }, 3000);
        } catch (error) {
          setLabel('Remind');
          dispatch(handleError(error));
        }
      }}
      className="btn btn-sm btn-primary mt-3"
    >
      {label}
    </button>
  );
};

const CompletionCertButton = ({ documentId }) => {
  const [label, setLabel] = useState('View Completion Certificate');
  const dispatch = useDispatch();
  return (
    <button
      style={{ display: 'block', minWidth: 200 }}
      type="button"
      onClick={async () => {
        try {
          setLabel('Loading...');
          const {
            completion_cert_url: url,
          } = await Parse.Cloud.run('lss_doc_completion', { documentId });
          if (url) {
            window.open(url, '_blank');
          }
        } catch (error) {
          dispatch(handleError(error));
        } finally {
          setLabel('View Completion Certificate');
        }
      }}
      className="btn btn-sm btn-primary mt-3"
    >
      {label}
    </button>
  );
};

const SecureSignSection = (props) => {
  const { customerId, mainMenuOptions } = props;
  const [sentDocuments, setSentDocuments] = useState([]);
  const [placeholderText, setPlaceholderText] = useState('Loading...');

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const query = new Parse.Query('SentDocuSign');
        query.equalTo('service', 'lss');
        query.select([
          'docuSignResponse',
          'createdBy',
          'source',
          'document',
          'completed',
        ]);
        const customer = new Parse.Object('SSCustomer');
        customer.id = customerId;
        query.equalTo('customer', customer);
        const results = [];
        await query.each(async (obj) => {
          const {
            docuSignResponse,
            source,
            document,
            completed,
            createdAt,
          } = obj.attributes;
          const { lssId } = docuSignResponse;
          const {
            documentStatus,
            signers,
          } = await Parse.Cloud.run('lss_doc_status', { lssId });
          if (documentStatus) {
            const {
              done_state: doneState,
              current_signer: currentSigner,
            } = documentStatus;
            results.push({
              id: obj.id,
              createdAt,
              source,
              complete: doneState === 1,
              currentSigner,
              docURL: completed ? completed.url() : document.url(),
              signers: signers?.map(
                ({
                  signer_uuid: id,
                  name,
                  email,
                  signature_date: signatureDate,
                  signernumber: signerNumber,
                }) => ({
                  id,
                  name,
                  email,
                  signatureDate,
                  signerNumber,
                }),
              ),
            });
          }
        });
        results.sort(({ createdAt: a }, { createdAt: b }) => b - a);
        setSentDocuments(results);
        setPlaceholderText(results.length ? '' : 'No Documents Sent');
      } catch (error) {
        setPlaceholderText(error.message);
      }
    };
    if (customerId) {
      fetchDocuments();
    }
  }, [customerId]);

  return (
    <article className="customer-secure-sign-section leap-data-section">
      <SectionTitle title="Leap Secure Sign" />
      {sentDocuments.map(
        ({ id, source, complete, signers, docURL, currentSigner }) => (
          <React.Fragment key={id}>
            <div className="col-xl-3 col-md-4 col-sm-6">
              <dt className="secure-sign">
                {getNameFromSource(source, mainMenuOptions)}
              </dt>
              {signers.map(
                ({
                  id: signerId,
                  name,
                  email,
                  signatureDate,
                  signerNumber,
                }) => (
                  <dd key={signerId}>
                    <ul className="orderedList">
                      <li className="name">{name}</li>
                      <li>
                        {signatureDate ? (
                          `Signed ${moment(signatureDate).format(
                            'MM/DD/YYYY h:mm a',
                          )}`
                        ) : (
                          <span>
                            Awaiting Signature
                            {signerNumber === currentSigner && (
                              <ResendButton documentId={id} />
                            )}
                          </span>
                        )}
                      </li>
                    </ul>
                  </dd>
                ),
              )}
              <a href={docURL} target="_blank" rel="noopener noreferrer">
                <span>
                  View Document&nbsp;
                  <i className="fas fa-external-link-alt" />
                </span>
              </a>
              {complete && (
                <>
                  <br />
                  <CompletionCertButton documentId={id} />
                </>
              )}
            </div>
          </React.Fragment>
        ),
      )}
      {placeholderText && <p>{placeholderText}</p>}
    </article>
  );
};

SecureSignSection.propTypes = {
  customerId: PropTypes.string.isRequired,
};

const mapStateToProps = ({
  auth: {
    config: { mainMenuOptions = [] },
  },
}) => ({
  mainMenuOptions,
});

export default connect(mapStateToProps)(SecureSignSection);
