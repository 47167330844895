import React from 'react';
import PropTypes from 'prop-types';
import Select from '../../../../Leapstrap/Select';

const InputTypeDropDown = ({
  onChange,
  value,
  endpoints = [],
  additionalValues,
  showDocLinking,
  hasTotalInputTypeSelected,
}) => {
  let options = [
    showDocLinking && {
      value: 'linkedObject',
      label: 'Linked Object Value',
    },
    {
      value: '__keyboardNormal',
      label: 'Standard Keyboard (Sentence)',
    },
    {
      value: '__textView',
      label: 'Standard Keyboard (Paragraph)',
    },
    {
      value: '__picker',
      label: 'Standard Picker',
    },
    {
      value: '__financeOption',
      label: 'Finance Options Picker',
    },
    {
      value: '__managerList',
      label: 'Available Contacts Picker',
    },
    {
      value: '__saleAmount',
      label: 'Total Sale Amount ($1.00)',
    },
    {
      value: '__saleAmountWhole',
      label: 'Total Sale Amount ($1)',
    },
    {
      value: '__totalPrice',
      label: 'Total Estimate Price',
    },
    {
      value: '__commission',
      label: 'Commission Estimate',
    },
    {
      value: '__email',
      label: "Customer's Email",
    },
    {
      value: 'keypad',
      label: 'Number Pad (1)',
    },
    {
      value: 'keypadDecimal',
      label: 'Number Pad (1.00)',
    },
    {
      value: 'phone',
      label: 'Phone Number',
    },
    {
      value: '__keyboardCurrency',
      label: 'Currency ($1.00)',
    },
    {
      value: 'currencyWhole',
      label: 'Currency ($1)',
    },
    {
      value: 'email',
      label: 'Email Address',
    },
    {
      value: 'numbers',
      label: 'Number Keyboard',
    },
    {
      value: 'unitedInchPicker',
      label: 'United Inch Picker',
    },
    {
      value: 'sizePickerInch',
      label: 'Size Picker (Inch)',
    },
    {
      value: 'sizePickerQuarterInch',
      label: 'Size Picker (1/4")',
    },
    {
      value: 'sizePickerEighthInch',
      label: 'Size Picker (1/8")',
    },
    {
      value: 'sizePickerSixteenthInch',
      label: 'Size Picker (1/16")',
    },
    {
      value: '3DSizePickerInch',
      label: '3D Size Picker (Inch)',
    },
    {
      value: '3DSizePickerQuarterInch',
      label: '3D Size Picker (1/4")',
    },
    {
      value: '3DSizePickerEighthInch',
      label: '3D Size Picker (1/8")',
    },
    {
      value: '3DSizePickerSixteenthInch',
      label: '3D Size Picker (1/16")',
    },
    {
      value: 'datePicker',
      label: 'Date Picker',
    },
    {
      value: 'dateTimePicker',
      label: 'Date & Time',
    },
    {
      value: 'timePicker',
      label: 'Time',
    },

    {
      value: 'creditCard',
      label: 'Credit Card Number',
    },
    {
      value: 'ccExpDate',
      label: 'Credit Card Exp. Date',
    },
  ];

  if (endpoints.indexOf('Marketsharp') > -1) {
    options = options.concat([
      {
        value: '__msLeadResult',
        label: 'Marketsharp Lead Results Picker',
        source: 'ms',
      },
      {
        value: '__msResultReason',
        label: 'Marketsharp Result Reasons Picker',
        source: 'ms',
      },
    ]);
  } else if (endpoints.indexOf('LeadPerfection') > -1) {
    options = options.concat([
      {
        value: 'disposition_LP',
        label: 'LeadPerfection Dispositions',
        source: 'lp',
      },
      {
        value: 'productid1_LP',
        label: 'LeadPerfection Product 1',
        source: 'lp',
      },
      {
        value: 'productid2_LP',
        label: 'LeadPerfection Product 2',
        source: 'lp',
      },
      {
        value: 'productid3_LP',
        label: 'LeadPerfection Product 3',
        source: 'lp',
      },
      {
        value: 'productid4_LP',
        label: 'LeadPerfection Product 4',
        source: 'lp',
      },
      {
        value: 'productid5_LP',
        label: 'LeadPerfection Product 5',
        source: 'lp',
      },
    ]);
  }
  options = [...options, ...additionalValues];

  const filteredOptions = options.filter((o) => {
    const isTotalInputType =
      o.value === '__saleAmountWhole' || o.value === '__saleAmount';

    const valueIsTotalType =
      value === '__saleAmountWhole' || value === '__saleAmount';

    const shouldFilterOptions =
      hasTotalInputTypeSelected() && !valueIsTotalType;

    return shouldFilterOptions ? !isTotalInputType : true;
  });
  const selectedValue = filteredOptions.filter(
    (option) => option.value === value,
  )[0];
  return (
    <Select
      onChange={onChange}
      value={selectedValue}
      options={filteredOptions}
      searchable
      closeMenuOnSelect
      isClearable={false}
    />
  );
};
InputTypeDropDown.propTypes = {
  hasTotalInputTypeSelected: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  endpoints: PropTypes.arrayOf(PropTypes.string).isRequired,
  showDocLinking: PropTypes.bool,
  additionalValues: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
};
InputTypeDropDown.defaultProps = {
  additionalValues: [],
  showDocLinking: false,
};

export default InputTypeDropDown;
