import React, { useState } from 'react';
import { showWidget } from './actions';
import './speedDial.css';

const mainWidgetSelector = '#speed-dial';

const SpeedDialWidget = ({ loggedIn }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredAction, setHoveredAction] = useState(null); // For tracking which action is hovered

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  const handleActionHover = (action) => {
    setHoveredAction(action);
  };

  const handleTalkDeskClick = () => {
    showWidget({
      widgetSelector: '#tdWebchat',
      mainWidgetSelector,
    });
    setIsOpen(false);
  };

  const handleAuciousClick = () => {
    showWidget({
      widgetSelector: 'app-auvious-widget',
      mainWidgetSelector,
    });
    setIsOpen(false);
  };

  return (
    (loggedIn && (
      <div
        id="speed-dial"
        className="speed-dial"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <button
          className={`speed-dial__main ${
            isOpen ? 'speed-dial__main--rotate' : ''
          }`}
          type="button"
        >
          <span className="speed-dial__icon-root">
            <img src="/images/icons/add_plus_1.svg" alt="Co-Browse" />
          </span>
        </button>
        <div
          className={`speed-dial__actions ${
            isOpen ? 'speed-dial__actions--show' : ''
          }`}
        >
          <div className="speed-dial__item">
            {hoveredAction === 'Chat' && (
              <div className="popover popover--chat">Chat</div>
            )}
            <button
              className="speed-dial__action"
              onClick={handleTalkDeskClick}
              onMouseEnter={() => handleActionHover('Chat')}
              onMouseLeave={() => handleActionHover(null)}
              type="button"
            >
              <img
                className="speed-dial__actions__image"
                src="/images/icons/talk-desk-chat.svg"
                alt="Talk Desk"
              />
            </button>
          </div>
          <div className="speed-dial__item">
            {hoveredAction === 'Co-Browse' && (
              <div className="popover popover--video">Co-Browse</div>
            )}
            <button
              className="speed-dial__action"
              onClick={handleAuciousClick}
              onMouseEnter={() => handleActionHover('Co-Browse')}
              onMouseLeave={() => handleActionHover(null)}
              type="button"
            >
              <img
                className="speed-dial__actions__image"
                src="/images/icons/auvious-cobrowse-light.svg"
                alt="Co-Browse"
              />
            </button>
          </div>
        </div>
      </div>
    )) ||
    ''
  );
};

export default SpeedDialWidget;
