/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { TextGroup, SwitchGroup } from '../../../FormGroup';
import Panel from '../../../Panel';

const WebhookSection = (props) => (
  <Panel title="Webhook">
    <div>
      <SwitchGroup
        title="Use Webhook v2 (recommended)"
        checked={props.value.version === 'v2'}
        onChange={(value) =>
          props.onChange({
            key: 'webhook',
            value: {
              ...props.value,
              version: value ? 'v2' : 'v1',
            },
          })
        }
      />
      <TextGroup
        title="URL"
        value={props.value.url}
        placeholder="URL"
        onChange={(value) =>
          props.onChange({
            key: 'webhook',
            value: {
              ...props.value,
              url: value,
            },
          })
        }
        errorMessage={props.errors.webhook.url}
      />
      <TextGroup
        title="Auth Header"
        value={props.value.authHeader}
        placeholder="Auth Header"
        onChange={(value) =>
          props.onChange({
            key: 'webhook',
            value: {
              ...props.value,
              authHeader: value,
            },
          })
        }
      />
      <SwitchGroup
        title="Include PDF"
        checked={props.value.includePDF}
        onChange={(value) =>
          props.onChange({
            key: 'webhook',
            value: {
              ...props.value,
              includePDF: value,
            },
          })
        }
      />
    </div>
  </Panel>
);

WebhookSection.propTypes = {
  value: PropTypes.shape({
    url: PropTypes.string,
    authHeader: PropTypes.string,
    includePDF: PropTypes.bool,
    version: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    webhook: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default WebhookSection;
