/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { Table, Button } from 'react-bootstrap';
import { SingleDatePicker } from 'react-dates';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import {
  startLoadChangeLogs,
  LoadLogsAndToast,
  startSaveChangeLog,
  setLogs,
  deleleChangeLog,
} from '../../actions/changeLogs';
import Panel from '../Panel';

class CorporateAdminChangeLogView extends React.Component {
  constructor(props) {
    super(props);
    this.state = { updates: [], focusSet: {} };
  }

  componentDidMount = () => {
    const {
      setCrumbs,
      setButtons,
      location: { pathname },
    } = this.props;

    const crumb = { title: 'Admin Change Logs', link: pathname };
    setCrumbs([crumb]);
    setButtons(
      <Button onClick={this.onAddLog} key="add Log">
        Add Log
      </Button>,
    );

    this.loadChangeLogs();
  };

  componentWillUnmount() {
    const { setCrumbs, setButtons } = this.props;
    setCrumbs([]);
    setButtons();
  }

  onDeleteLog = (changeLog) => {
    this.props.deleleChangeLog(changeLog);
  };

  onSaveLog = (changeLog) => {
    const update =
      this.state.updates[changeLog.objectId || changeLog.key] || changeLog;
    this.props.startSaveChangeLog(update);
  };

  onAddLog = () => {
    const existing = this.props.logs.map((log) => {
      if (this.state.updates[log.objectId || log.key]) {
        return this.state.updates[log.objectId || log.key];
      }
      return log;
    });
    this.props.setLogs([
      ...existing,
      { message: '', key: Math.random() * 999999 },
    ]);
  };

  updateMessageValue = (message, changeLog) => {
    const id = changeLog.objectId || changeLog.key;
    this.setState({
      updates: {
        ...this.state.updates,
        [id]: {
          ...changeLog,
          ...this.state.updates[id],
          message,
        },
      },
    });
  };

  updateValue = (value, key, changeLog) => {
    const id = changeLog.objectId || changeLog.key;
    this.setState({
      updates: {
        ...this.state.updates,
        [id]: {
          ...changeLog,
          ...this.state.updates[id],
          [key]: value,
        },
      },
    });
  };

  loadChangeLogs = () => {
    this.props.startLoadChangeLogs({});
  };

  updateFocus = (i, focused) =>
    this.setState({
      focusSet: {
        ...this.state.focusSet,
        [i]: focused,
      },
    });

  dateValue = (changeLog) => {
    let dateone = this.valueRef(changeLog, 'expDate', null);
    if (dateone && dateone.iso) {
      dateone = dateone.iso;
    }
    const returnValue = dateone ? moment(dateone) : null;
    return returnValue;
  };

  valueRef = (changeLog, key, defaultValue = '') =>
    this.state.updates[changeLog.objectId || changeLog.key]
      ? this.state.updates[changeLog.objectId || changeLog.key][key]
      : changeLog[key] || defaultValue;

  render() {
    return (
      <div className="default-page-padding">
        <Panel title="Admin Change Log">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Message</th>
                <th style={{ width: 200 }}>Expiration Date</th>
                <th style={{ width: 200 }}>Message Display Time (seconds)</th>
                <th style={{ width: 200 }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.props.logs.map((changeLog, i) => {
                const readDateValue = this.dateValue(changeLog);
                return (
                  <tr key={changeLog.objectId || changeLog.key}>
                    <td>
                      <input
                        onChange={(event) =>
                          this.updateMessageValue(event.target.value, changeLog)
                        }
                        className="form-control"
                        value={this.valueRef(changeLog, 'message', '')}
                        type="text"
                      />
                    </td>
                    <td>
                      <SingleDatePicker
                        showClearDate
                        date={readDateValue}
                        onDateChange={(monentDate) => {
                          if (monentDate) {
                            this.updateValue(
                              monentDate.toDate(),
                              'expDate',
                              changeLog,
                            );
                          } else {
                            this.updateValue(null, 'expDate', changeLog);
                          }
                        }}
                        focused={this.state.focusSet[i] || false}
                        onFocusChange={({ focused }) =>
                          this.updateFocus(i, focused)
                        }
                        id={`date-input-${changeLog.objectId || changeLog.key}`}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        onChange={(event) => {
                          this.updateValue(
                            parseFloat(event.target.value),
                            'displayTime',
                            changeLog,
                          );
                        }}
                        className="form-control"
                        value={this.valueRef(changeLog, 'displayTime', -1)}
                      />
                    </td>
                    <td style={{ width: 200 }}>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-around',
                        }}
                      >
                        <Button onClick={() => this.onSaveLog(changeLog)}>
                          <i className="fas fa-file" />
                        </Button>
                        <Button onClick={() => this.onDeleteLog(changeLog)}>
                          <i className="fas fa-trash" />
                        </Button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Panel>
      </div>
    );
  }
}
CorporateAdminChangeLogView.propTypes = {
  logs: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ).isRequired,
  deleleChangeLog: PropTypes.func.isRequired,
  startLoadChangeLogs: PropTypes.func.isRequired,
  startSaveChangeLog: PropTypes.func.isRequired,
  setLogs: PropTypes.func.isRequired,
  ...layoutContextPropTypes,
};

CorporateAdminChangeLogView.defaultProps = {};

const mapStateToProps = ({ changeLogs: { logs } }) => ({
  logs,
});

const mapDispatchToProps = (dispatch) => ({
  deleleChangeLog: (params) => dispatch(deleleChangeLog(params)),
  startSaveChangeLog: (changeLogData) =>
    dispatch(startSaveChangeLog(changeLogData)),
  startLoadChangeLogs: (params) => dispatch(startLoadChangeLogs(params)),
  setLogs: (logs) => dispatch(setLogs(logs)),
  LoadLogsAndToast: () => dispatch(LoadLogsAndToast()),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(CorporateAdminChangeLogView),
);
