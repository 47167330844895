import Parse from 'parse';

class User extends Parse.User {
  toJSON() {
    const {
      company,
      allowedOffices,
      selectedOffice = {},
      ...userData
    } = this.attributes;
    const { config } = selectedOffice;

    const companyObject = this.get('company');

    const { offices, ...companyData } = companyObject
      ? companyObject.attributes
      : {};

    const newCompanyJson = companyObject
      ? {
          className: 'Company',
          __type: 'Object',
          objectId: companyObject.id,
          ...companyData,
        }
      : undefined;

    const itemJson = {
      className: '_User',
      __type: 'Object',
      objectId: this.id,
      sessionToken: this.get('sessionToken'),
      ...userData,
      company: newCompanyJson,
      selectedOffice: {
        ...selectedOffice,
        config: {
          ...config,
          company: newCompanyJson,
        },
        company: newCompanyJson,
      },
    };

    return itemJson;
  }
}

export default User;
