/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { LayoutContext } from '../Layout';
import FormGroup, { TextGroup } from '../FormGroup';
import Panel from '../Panel';
import TitleButton from '../TitleButton';
import IncludedOfficesDropDown from '../IncludedOfficesDropDown';
import { startCreateOffice } from '../../actions/offices';
import { pushToDataLayer } from '../../actions/tagManager';

export class NewOffice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      appSettingsCopy: '',
      priceGuideCopy: '',
      packagesCopy: [],
      financeOptionsCopy: [],
      contractsCopy: [],
      proposalsCopy: [],
      resourcesCopy: [],
      creditApplicationsCopy: [],
    };
  }

  componentDidMount() {
    const { location: { pathname = '' } = {} } = this.props;
    const { crumbs, setCrumbs } = this.context;
    this.renderButtons();
    setCrumbs([...crumbs, { title: 'New Office', link: pathname }]);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevState, this.state)) {
      this.renderButtons();
    }
  }

  componentWillUnmount() {
    const { setCrumbs, setButtons } = this.context;
    setCrumbs([]);
    setButtons('');
  }

  onSaveClicked = () => {
    this.props.pushToDataLayer({
      event: 'officeEvent',
      eventCategory: 'Offices',
      eventAction: 'Create',
      eventLabel: this.state.name,
    });
    this.props.startCreateOffice(this.state);
  };

  onNameChanged = (name) => {
    this.setState({ name });
  };

  renderButtons = () => {
    this.context.setButtons(
      <TitleButton
        variant="success"
        onClick={this.onSaveClicked}
        title="Save"
        disabled={!this.state.appSettingsCopy}
      />,
    );
  };

  onValueChanged = (value, key) => {
    this.setState({ [key]: value });
  };

  render() {
    return (
      <div className="default-page-padding">
        <Panel title="Edit Office">
          <TextGroup
            title="Name"
            value={this.state.name}
            placeholder="Name"
            onChange={this.onNameChanged}
            errorMessage=""
          />
          <FormGroup
            title="Copy App Settings From"
            errorMessage={!this.state.appSettingsCopy ? 'Required' : undefined}
          >
            <IncludedOfficesDropDown
              onChange={(office) =>
                this.onValueChanged(office[0], 'appSettingsCopy')
              }
              selected={
                this.state.appSettingsCopy ? [this.state.appSettingsCopy] : []
              }
              closeMenuOnSelect
              hideSelectAll
            />
          </FormGroup>
          <FormGroup title="Copy Price Guide From">
            <IncludedOfficesDropDown
              onChange={(office) =>
                this.onValueChanged(office[0], 'priceGuideCopy')
              }
              selected={
                this.state.priceGuideCopy ? [this.state.priceGuideCopy] : []
              }
              closeMenuOnSelect
              hideSelectAll
            />
          </FormGroup>
          <FormGroup title="Copy Packages From">
            <IncludedOfficesDropDown
              onChange={(offices) =>
                this.onValueChanged(offices, 'packagesCopy')
              }
              selected={this.state.packagesCopy}
            />
          </FormGroup>
          <FormGroup title="Copy Credit Applications From">
            <IncludedOfficesDropDown
              onChange={(offices) =>
                this.onValueChanged(offices, 'creditApplicationsCopy')
              }
              selected={this.state.creditApplicationsCopy}
            />
          </FormGroup>
          <FormGroup title="Copy Finance Options From">
            <IncludedOfficesDropDown
              onChange={(offices) =>
                this.onValueChanged(offices, 'financeOptionsCopy')
              }
              selected={this.state.financeOptionsCopy}
            />
          </FormGroup>
          <FormGroup title="Copy Contracts From">
            <IncludedOfficesDropDown
              onChange={(offices) =>
                this.onValueChanged(offices, 'contractsCopy')
              }
              selected={this.state.contractsCopy}
            />
          </FormGroup>
          <FormGroup title="Copy Proposals From">
            <IncludedOfficesDropDown
              onChange={(offices) =>
                this.onValueChanged(offices, 'proposalsCopy')
              }
              selected={this.state.proposalsCopy}
            />
          </FormGroup>
          <FormGroup title="Copy Resources From">
            <IncludedOfficesDropDown
              onChange={(offices) =>
                this.onValueChanged(offices, 'resourcesCopy')
              }
              selected={this.state.resourcesCopy}
            />
          </FormGroup>
        </Panel>
      </div>
    );
  }
}

NewOffice.contextType = LayoutContext;

NewOffice.propTypes = {
  startCreateOffice: PropTypes.func.isRequired,
};

NewOffice.defaultProps = {};

const mapStateToProps = ({ auth }) => ({
  offices: auth.offices.map((office) => office.toJSON()) || [],
});

const mapDispatchToProps = (dispatch) => ({
  startCreateOffice: (params) => dispatch(startCreateOffice(params)),
  pushToDataLayer: (variablesForLayer) =>
    dispatch(pushToDataLayer(variablesForLayer)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewOffice);
