import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { LayoutContext } from '../Layout';
import { pushToDataLayer as pushToDataLayerAction } from '../../actions/tagManager';
import { startUpdateConfig as startUpdateConfigAction } from '../../actions/auth';
import FormControl, { TextGroup } from '../FormGroup';
import Copy from '../AppSettings/Copy';
import Panel from '../Panel';
import TitleButton from '../TitleButton';

const enrollWithPureLink = 'https://www.purefinancegroup.com/new-dealer/';

const PureFinance = () => {
  const title = 'Pure Finance';
  const { setButtons, setCrumbs } = useContext(LayoutContext);
  // Get config values from redux store
  const pure = useSelector(({ auth: { config } }) => config.pure);
  // Set dispatch instance and map actions to functions
  const dispatch = useDispatch();
  const [, startUpdateConfig] = [
    /** @param {import('../../actions/tagManager').AnalyticsEventData} payload */
    (payload) => dispatch(pushToDataLayerAction(payload)),
    (configUpdate) => dispatch(startUpdateConfigAction(configUpdate)),
  ];
  // Create state object and destructure it
  const [{ dealerId, internalDealerId, showCopy }, updateState] = useState({
    dealerId: '',
    internalDealerId: '',
    showCopy: false,
  });
  // Create a setState function that mirrors the way class state works
  /** @param {Partial<PureState>} newState */
  const setState = (newState) => {
    updateState(
      /** @param {PureState} prevState */
      (prevState) => ({
        ...prevState,
        ...newState,
      }),
    );
  };

  // Set state from redux store values
  useEffect(() => {
    if (typeof pure === 'object') {
      const {
        dealerId: _dealerId = '',
        internalDealerId: _internalDealerId = '',
      } = pure;
      setState({ dealerId: _dealerId, internalDealerId: _internalDealerId });
    }
  }, [pure]);

  const onCopyClicked = () => {
    setState({ showCopy: true });
  };

  const onSaveClicked = () => {
    if (!dealerId || !internalDealerId) {
      return;
    }
    // DB3 uses the enabled field, here, we set it true if there's a dealerId and a internalDealerId, false, if there's none
    startUpdateConfig({
      pure: {
        dealerId,
        internalDealerId,
        enabled: !!dealerId && !!internalDealerId,
      },
    });
  };

  const buttons = (
    <TitleButtons onCopyClicked={onCopyClicked} onSaveClicked={onSaveClicked} />
  );

  // Add crumb and buttons to breadcrumb bar
  useEffect(() => {
    setCrumbs([{ title, link: '/pure' }]);
    setButtons(buttons);
    return () => {
      setCrumbs([]);
      setButtons();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealerId, internalDealerId, setCrumbs, setButtons]);

  /** @param {string} value */
  const onDealerIdChanged = (value) => {
    setState({ dealerId: value });
  };

  /** @param {string} value */
  const onInternalDealerIdChanged = (value) => {
    setState({ internalDealerId: value });
  };

  return (
    <div className="default-page-padding">
      <Copy
        title={`Copy ${title} Settings`}
        show={showCopy}
        warning={`Warning! This will overwrite your current ${title} settings`}
        configKeys={['pure']}
        onClose={() => setState({ showCopy: false })}
      />
      <Panel title={title}>
        <div>
          <TextGroup
            title="Dealer Id"
            value={dealerId}
            placeholder="Dealer Id"
            onChange={onDealerIdChanged}
            errorMessage={dealerId === '' ? 'Dealer Id is required' : null}
          />
          <TextGroup
            title="Internal Dealer Id"
            value={internalDealerId}
            placeholder="Internal Dealer Id"
            onChange={onInternalDealerIdChanged}
            errorMessage={
              internalDealerId === '' ? 'Internal Dealer Id is required' : null
            }
          />
          <FormControl title="">
            <p style={{ marginLeft: '10px' }}>
              Don&apos;t have an Pure Finance account? Create one&nbsp;
              <a
                href={enrollWithPureLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                here.
              </a>
            </p>
          </FormControl>
        </div>
      </Panel>
    </div>
  );
};

/**
 * @param {{
 *  onClearClick: () => void,
 *  onCopyClicked: () => void,
 *  onSaveClicked: () => void,
 * }} props
 */
const TitleButtons = ({ onClearClick, onCopyClicked, onSaveClicked }) => (
  <>
    <TitleButton variant="warning" onClick={onClearClick} title="Clear" />
    <TitleButton variant="primary" onClick={onCopyClicked} title="Copy" />
    <TitleButton variant="success" onClick={onSaveClicked} title="Save" />
  </>
);

TitleButtons.propTypes = {
  onClearClick: PropTypes.func.isRequired,
  onCopyClicked: PropTypes.func.isRequired,
  onSaveClicked: PropTypes.func.isRequired,
};

export default PureFinance;
