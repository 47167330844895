/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Parse from 'parse';
import Panel from '../Panel';
import { startFetchContractObjectGroups } from '../../actions/templates';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import collectCrumbsFromPath from './collectCrumbsFromPath';

export class TemplateGroupLibrary extends React.Component {
  componentDidMount() {
    this.props.startFetchContractObjectGroups();

    const { setCrumbs, match } = this.props;

    const crumbs = collectCrumbsFromPath({ match });

    setCrumbs(crumbs);
  }

  render() {
    const { type } = this.props.match.params;
    return (
      <div className="default-page-padding">
        <Panel title="Template Groups">
          <div className="resources-container">
            {this.props.templateGroups.map((templateGroup) => (
              <div
                key={templateGroup.id}
                className="mask no-caption resources-cell card"
              >
                <Link
                  to={`/templates/${type}/template_group/${templateGroup.id}`}
                  id="viewtemplates"
                >
                  <div
                    className="thumbnail-container"
                    style={{ width: '100%', height: '100%' }}
                  >
                    <div className="img-thumbnail view view-first">
                      <img
                        style={{
                          cursor: 'pointer',
                          margin: 'auto',
                          height: '100%',
                          display: 'block',
                        }}
                        src={
                          templateGroup.get('thumbnail')
                            ? templateGroup.get('thumbnail').url()
                            : '/images/no_image.png'
                        }
                        alt="Thumbnail"
                      />
                    </div>
                    <div className="caption">{templateGroup.get('name')}</div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </Panel>
      </div>
    );
  }
}

TemplateGroupLibrary.propTypes = {
  startFetchContractObjectGroups: PropTypes.func.isRequired,
  templateGroups: PropTypes.arrayOf(PropTypes.instanceOf(Parse.Object)),
  match: PropTypes.shape({
    params: PropTypes.shape({
      type: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  ...layoutContextPropTypes,
};

TemplateGroupLibrary.defaultProps = {
  templateGroups: [],
};

const mapStateToProps = ({
  templates: {
    templateGroups: { items = [] },
  },
}) => {
  return {
    templateGroups: items,
  };
};

const mapDispatchToProps = (dispatch) => ({
  startFetchContractObjectGroups: (params) =>
    dispatch(startFetchContractObjectGroups(params)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(TemplateGroupLibrary),
);
