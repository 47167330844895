import moment from 'moment';

const startDate = moment()
  .subtract(30, 'days')
  .startOf('day');
const endDate = moment().endOf('day');

const estimatesPage = (
  state = {
    validOffices: [],
    items: [],
    startDate,
    endDate,
    count: 0,
    limit: 10,
    skip: 0,
    customerFilter: '',
    userFilter: [],
    selectedOffices: [],
  },
  action,
) => {
  switch (action.type) {
    case 'SET_VALID_OFFICES': {
      return {
        ...state,
        validOffices: [...action.validOffices],
      };
    }
    case 'LOGOUT': {
      return {
        validOffices: [],
        items: [],
        startDate,
        endDate,
        count: 0,
        limit: 10,
        skip: 0,
        customerFilter: '',
        userFilter: [],
        selectedOffices: [],
      };
    }
    case 'SET_ESTIMATES':
      return {
        ...state,
        items: [...action.items],
      };
    case 'SET_ESTIMATES_DATE_FILTER':
      return {
        ...state,
        ...action.params,
      };
    case 'SET_ESTIMATES_FILTER':
      return {
        ...state,
        ...action.params,
      };
    case 'SET_ESTIMATE_DETAILS':
      return {
        ...state,
        details: { ...action.details },
      };
    default:
      return state;
  }
};

export default estimatesPage;
