/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const tooltip = (description) => <Tooltip id="tooltip">{description}</Tooltip>;

const ExpandAllCell = ({
  onClick,
  isOpen,
  disabled,
  type,
  itemCount,
  accessoriesCount,
  maxUpChargeCount,
}) => (
  <div
    style={{ position: 'relative' }}
    className={`priceguide__expand-all-button${type ? `_${type}` : ''}`}
  >
    {(maxUpChargeCount !== 0 || (itemCount >= 0 && accessoriesCount >= 0)) && (
      <button type="button" onClick={onClick} disabled={disabled}>
        <i className={`fas fa-angle-${isOpen ? 'down' : 'right'}`} />
      </button>
    )}
    {itemCount >= 0 && accessoriesCount >= 0 && (
      <div className="priceguide__expand-all-label-left">
        <OverlayTrigger placement="right" overlay={tooltip('Item Count')}>
          <span>{itemCount}</span>
        </OverlayTrigger>
        {maxUpChargeCount !== 0 && (
          <OverlayTrigger
            placement="right"
            overlay={tooltip('Accessories Count')}
          >
            <span style={{ float: 'right' }}>{accessoriesCount}</span>
          </OverlayTrigger>
        )}
      </div>
    )}
  </div>
);

ExpandAllCell.propTypes = {
  maxUpChargeCount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['item', 'option', 'item-header', 'option-header']),
  itemCount: PropTypes.number,
  accessoriesCount: PropTypes.number,
};

ExpandAllCell.defaultProps = {
  isOpen: false,
  disabled: false,
  type: undefined,
  itemCount: undefined,
  accessoriesCount: undefined,
};

const mapStateToProps = ({ plan = {} }) => ({
  maxUpChargeCount: plan.maxUpChargeCount,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ExpandAllCell);
