/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { jsx, css } from '@emotion/core';
import { startFetchStyles } from '../../actions/config';
import GlobalStyle from './GlobalStyle';
import DropContext from '../DropContext';
import { createStyleElement } from '../../utils/utils';

const Head = ({ children }) => {
  const dispatch = useDispatch();
  const { favicon, company } = useSelector(({ auth = {} }) => auth);
  const partnerData = company ? company.get('partner') : '';
  const partnerTag = partnerData ? partnerData.get('tag') : '';
  const style = useSelector(({ config: { styles = {} } }) => {
    return styles.Base;
  });

  useEffect(() => {
    dispatch(startFetchStyles());
  }, [dispatch, company]);

  return (
    <div
      css={css`
        ${createStyleElement(style)}
      `}
    >
      {favicon ? (
        <Helmet>
          <title>{favicon.pageTitle}</title>
          <link rel="icon" type="image/png" href={favicon._url} />
        </Helmet>
      ) : (
        undefined
      )}
      <GlobalStyle partnerTag={partnerTag} />
      <DropContext>{children}</DropContext>
    </div>
  );
};

Head.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Head;
