/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Menu, MenuItem, TextArea } from 'leap-menu-item';
import { setInitialStateForTemplate } from '../../../../actions/templateEdit';
import BodyCellItem from '../../Models/BodyCellItem';
import ContractObject from '../../Models/ContractObject';
import DebounceInputWithNet from '../DebounceInputWithNet';

const borderMap = {
  0: 'None',
  1: 'Outside',
  2: 'Inside & Outside',
  3: 'Top & Bottom',
};

const borders = [0, 1, 2, 3];

const HeaderMenu = ({ contractObject, object, setTemplateState }) => (
  <>
    <Menu.Divider />
    <MenuItem text="App Title">
      <DebounceInputWithNet
        element={TextArea}
        minLength={0}
        debounceTimeout={300}
        value={object.header.appTitle}
        onChange={(e) => {
          object.header.appTitle = e.target.value;
          setTemplateState(contractObject);
        }}
      />
    </MenuItem>
    <MenuItem text="PDF Title">
      <DebounceInputWithNet
        element={TextArea}
        minLength={0}
        debounceTimeout={300}
        value={object.header.title}
        onChange={(e) => {
          object.header.title = e.target.value;
          setTemplateState(contractObject);
        }}
      />
    </MenuItem>
    <MenuItem text="Border">
      {borders.map((border) => (
        <MenuItem
          key={`border-${border}`}
          text={borderMap[border]}
          active={object.header.border === border}
          onClick={() => {
            object.header.border = border;
            setTemplateState(contractObject);
          }}
        />
      ))}
    </MenuItem>
  </>
);

HeaderMenu.propTypes = {
  contractObject: PropTypes.instanceOf(ContractObject).isRequired,
  object: PropTypes.instanceOf(BodyCellItem).isRequired,
  setTemplateState: PropTypes.func.isRequired,
};

HeaderMenu.defaultProps = {};

const mapDispatchToProps = (dispatch) => ({
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
});

export default connect(undefined, mapDispatchToProps)(HeaderMenu);
