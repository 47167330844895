import Parse from 'parse';

const tagManageMiddleware = (store) => (next) => async (action) => {
  if (action.type === 'LOGIN') {
    const { config, user, isCorporateAdmin } = action;

    const configData = config.toJSON();

    const { company } = configData;

    const { isDemo } = company;

    window.dataLayer.push({ isDemo });

    const docQuery = new Parse.Query('SentDocuSign');

    docQuery.equalTo('company', config.get('company'));

    const Docusigns = await docQuery.count();

    const variablesForLayer = {
      userId: user.id,
      'Account ID': company.objectId,
      'Account Name': company.name,
      Docusigns,
      'Account Size': company.maxActiveUsers,
    };

    if (isCorporateAdmin) {
      window.dataLayer.push({
        event: 'isCorporateAdminEvent',
        isCorporateAdmin: true,
      });
    }
    window.dataLayer.push(variablesForLayer);
  }

  if (action.type === 'PUSH_TO_DATA_LAYER') {
    const { variablesForLayer } = action;
    window.dataLayer && window.dataLayer.push(variablesForLayer);
  }

  //  else if (action.type === 'RESET_DATA_LAYER') {
  //   _gtm[DATA_LAYER_CONTAINER].reset();
  // }

  return next(action);
};

export default tagManageMiddleware;
