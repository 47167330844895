/* eslint-disable react/prop-types */
import React from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Classes, Menu, MenuItem } from 'leap-menu-item';
import { setInitialStateForTemplate } from '../../../actions/templateEdit';
import ContractObject from '../Models/ContractObject';
import Stepper from '../Stepper';

const headerCellTypeMap = {
  default: 'Text',
  emptySpace: 'Empty Space',
  image: 'Image',
  companyLogo: 'Company Logo',
};

const MenuFix = () => (
  <MenuItem style={{ display: 'none' }}>
    <MenuItem />
  </MenuItem>
);

const HeaderCell = ({ templateValues, editCellItem, setTemplateState }) => {
  const contractObject = new ContractObject(templateValues.toJSON());
  const updatedObject =
    contractObject.getObjectWithId(editCellItem.objectId) || {};
  return (
    <Menu className={Classes.ELEVATION_1}>
      <MenuFix />
      <Menu.Item
        text="Cell Type"
        label={headerCellTypeMap[updatedObject.cellType]}
        shouldDismissPopover={false}
      >
        <Menu.Item
          text="Text"
          shouldDismissPopover={false}
          active={updatedObject.cellType === 'default'}
          icon={updatedObject.cellType === 'default' ? 'dot' : 'blank'}
          onClick={() => {
            updatedObject.cellType = 'default';
            if (
              updatedObject.value === '%companyLogo%' ||
              updatedObject.value === '%image%'
            ) {
              updatedObject.value = '';
            }
            setTemplateState(contractObject);
          }}
        />
        <Menu.Item
          text="Empty Space"
          shouldDismissPopover={false}
          active={updatedObject.cellType === 'emptySpace'}
          icon={updatedObject.cellType === 'emptySpace' ? 'dot' : 'blank'}
          onClick={() => {
            updatedObject.cellType = 'emptySpace';
            updatedObject.value = '';
            if (updatedObject.emptySpaceHeight < 1) {
              updatedObject.emptySpaceHeight = 4;
            }
            setTemplateState(contractObject);
          }}
        />
        <Menu.Item
          text="Image"
          shouldDismissPopover={false}
          active={updatedObject.cellType === 'image'}
          icon={updatedObject.cellType === 'image' ? 'dot' : 'blank'}
          onClick={() => {
            updatedObject.cellType = 'image';
            updatedObject.value = '%image%';
            setTemplateState(contractObject);
          }}
        />
        <Menu.Item
          text="Company Logo"
          shouldDismissPopover={false}
          active={updatedObject.cellType === 'companyLogo'}
          icon={updatedObject.cellType === 'companyLogo' ? 'dot' : 'blank'}
          onClick={() => {
            updatedObject.cellType = 'companyLogo';
            updatedObject.value = '%companyLogo%';
            if (updatedObject.logoHeight < 1) {
              updatedObject.logoHeight = 40;
            }
            setTemplateState(contractObject);
          }}
        />
      </Menu.Item>
      {updatedObject.cellType === 'emptySpace' && (
        <Menu.Item
          text="Empty Space Height"
          shouldDismissPopover={false}
          labelElement={
            <Stepper
              value={updatedObject.emptySpaceHeight}
              minValue={1}
              maxValue={1000}
              increment={1}
              small
              onChange={(value) => {
                updatedObject.emptySpaceHeight = value;
                // a new object needs to be created here because the stepper runs on a loop while pressed down
                // if we dont do this then the page is not rendered
                const newObject = new ContractObject(contractObject.toJSON());
                setTemplateState(newObject);
              }}
            />
          }
        />
      )}
      {(updatedObject.cellType === 'companyLogo' ||
        updatedObject.cellType === 'image') && (
        <Menu.Item
          text="Image Height"
          shouldDismissPopover={false}
          labelElement={
            <Stepper
              value={updatedObject.logoHeight}
              minValue={1}
              maxValue={1000}
              increment={1}
              small
              onChange={(value) => {
                updatedObject.logoHeight = value;
                // a new object needs to be created here because the stepper runs on a loop while pressed down
                // if we dont do this then the page is not rendered
                const newObject = new ContractObject(contractObject.toJSON());
                setTemplateState(newObject);
              }}
            />
          }
        />
      )}
    </Menu>
  );
};

const mapStateToProps = ({ templatesEdit = {} }) => ({
  templateValues: templatesEdit[templatesEdit.currentWorkingId],
  editCellItem: templatesEdit.editCellItem,
});

const mapDispatchToProps = (dispatch) => ({
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderCell);
