/* eslint-disable react/button-has-type */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import Parse from 'parse';
import { Button, ButtonGroup } from '@blueprintjs/core';
import { Droppable } from 'react-beautiful-dnd';
import _ from 'lodash';
import { randomKey, reorder } from '../../../../utils/utils';
import Panel from '../../../Panel';
import { setOnDragEndCallback } from '../../../DropContext';
import AppToaster from '../../../../utils/AppToaster';
import ResultsForm from './ResultsForm';
import detailRowUpdate from '../../../PriceGuide/AdditionalDetails/detailRowUpdate';

export const resultsTableDropableSectionId = 'resultsTableDropableSection';

export default class ResultsForms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: 'demoResult',
      jobNimbusWorkflowTypes: [],
      linkedObjectOptions: [],
    };
    setOnDragEndCallback(resultsTableDropableSectionId, (result) => {
      // dropped outside the list
      const { destination = {} } = result;
      if (destination === null) {
        return;
      }
      const { droppableId } = destination;
      if (droppableId !== resultsTableDropableSectionId) {
        return;
      }
      const list = reorder(
        this.props[this.state.activeKey],
        result.source.index,
        result.destination.index,
      ).map((item, order) => ({ ...item, order }));

      this.valueChanged(list);
    });
  }

  componentDidMount() {
    const loggedIn = !!Parse.User.current();
    if (loggedIn) {
      this.fetchJobNimbusWorkflowTypes(this.props.endpoints);
      if (this.props.showDocLinking) {
        this.fetchLinkedObjectOptions();
      }
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.fetchJobNimbusWorkflowTypes(newProps.endpoints);
  }

  onNoSaleClicked = () => {
    this.setState({ activeKey: 'demoResult' });
  };

  onSaleClicked = () => {
    this.setState({ activeKey: 'saleResult' });
  };

  onAddClicked = () => {
    const newObject = {
      title: '',
      inputType: '__keyboardNormal',
      required: false,
      id: randomKey(10),
    };
    const updates = [newObject]
      .concat(this.props[this.state.activeKey])
      .map((item, order) => ({ ...item, order }));
    this.valueChanged(updates);
  };

  onDeleteClicked = (index) => {
    const objects = this.props[this.state.activeKey];
    objects.splice(index, 1);
    this.valueChanged(objects);
  };

  onValueChange = (rawValue, key, index) => {
    const objects = this.props[this.state.activeKey];
    const { name, value } = detailRowUpdate(key, rawValue);
    const newObjects = objects.map((object, j) => {
      if (index === j) {
        return {
          ...object,
          [name]: value,
        };
      }

      return object;
    });
    this.valueChanged(newObjects);
  };

  onSalesforceValueChange = (value, key, index) => {
    const objects = this.props[this.state.activeKey];

    const newObjects = objects.map((object, j) => {
      if (index === j) {
        const { salesforce = {} } = object;

        return {
          ...object,
          salesforce: {
            type: 'string',
            ...salesforce,
            [key]: value,
          },
        };
      }

      return object;
    });

    this.valueChanged(newObjects);
  };

  valueChanged(changes) {
    this.props.onChange({
      key: this.state.activeKey,
      value: changes,
    });
  }

  hasTotalInputTypeSelected() {
    const objects = this.props[this.state.activeKey];

    const inputTypes = objects.map((o) => o.inputType);

    const totalTypes = inputTypes.filter(
      (o) => o === '__saleAmountWhole' || o === '__saleAmount',
    );

    return totalTypes.length > 0;
  }

  canAddTotalSalesAmountInputType = (addingValue, currentValue) => {
    if (addingValue !== '__saleAmountWhole' && addingValue !== '__saleAmount') {
      return true;
    }

    if (
      currentValue === '__saleAmountWhole' ||
      currentValue === '__saleAmount'
    ) {
      return true;
    }

    const totleInputTypeSelected = this.hasTotalInputTypeSelected();

    return !totleInputTypeSelected;
  };

  showSalesforce = () => this.props.endpoints.indexOf('Salesforce') > -1;

  onUpdateInputType = (e, index, inputType) => {
    const shouldUpdateInputType = this.canAddTotalSalesAmountInputType(
      e.value,
      inputType,
    );

    if (shouldUpdateInputType) {
      this.onValueChange(e.value, 'inputType', index);
    } else {
      this.onValueChange(inputType, 'inputType', index);
    }
  };

  fetchJobNimbusWorkflowTypes = async (endpoints = []) => {
    if (endpoints.indexOf('JobNimbus') > -1) {
      if (this.state.jobNimbusWorkflowTypes.length === 0) {
        try {
          const results = await Parse.Cloud.run('jobNimbusGetWorkflowTypes');
          const values = results.map(({ name, object_type }) => ({
            label: `JobNimbus ${name} Workflow Picker`,
            value: `status__${object_type}__${name}__fetch_jn`,
          }));
          this.setState({ jobNimbusWorkflowTypes: values });
        } catch (e) {
          AppToaster.show({ message: e.message, timeout: 5000 });
        }
      }
    } else {
      this.setState({ jobNimbusWorkflowTypes: [] });
    }
  };

  fetchLinkedObjectOptions = async () => {
    try {
      const currentUser = Parse.User.current();
      const company = currentUser?.get('company');
      const query = new Parse.Query('LinkedValueObject');
      query.equalTo('company', company);
      query.select('name');
      const linkedObjects = await query.find();

      if (!linkedObjects || !_.size(linkedObjects)) {
        this.setState({ linkedObjectOptions: [] });
      }

      const options = linkedObjects.map((linkedValue) => {
        const name = linkedValue.get('name');
        return {
          label: _.capitalize(name),
          value: name,
        };
      });
      this.setState({ linkedObjectOptions: options });
    } catch (e) {
      AppToaster.show({ message: e.message, timeout: 5000 });
      this.setState({ linkedObjectOptions: [] });
    }
  };

  render() {
    const objects = this.props[this.state.activeKey];

    const errors = { objects: this.props.errors[this.state.activeKey] };
    return (
      <Panel title="Results">
        <div className="results-container">
          <div className="results-table-tab-container">
            <ButtonGroup large fill>
              <Button
                onClick={this.onNoSaleClicked}
                active={this.state.activeKey === 'demoResult'}
                id="button-no-sale"
                style={{ zIndex: '0' }}
              >
                No-Sale
              </Button>
              <Button
                onClick={this.onSaleClicked}
                active={this.state.activeKey === 'saleResult'}
                id="button-sale"
                style={{ zIndex: '0' }}
              >
                Sale
              </Button>
            </ButtonGroup>
          </div>
        </div>

        <div className="d-flex w-100 justify-content-end">
          <button className="btn btn-primary mb-2" onClick={this.onAddClicked}>
            Add
          </button>
        </div>

        <Droppable
          droppableId={resultsTableDropableSectionId}
          direction="vertical"
          type={resultsTableDropableSectionId}
        >
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="result-dropable"
            >
              {objects.map((object, index) => (
                <ResultsForm
                  jobNimbusWorkflowTypes={this.state.jobNimbusWorkflowTypes}
                  endpoints={this.props.endpoints}
                  key={object.id}
                  errors={errors}
                  index={index}
                  object={object}
                  onSalesforceValueChange={(value, key) =>
                    this.onSalesforceValueChange(value, key, index)
                  }
                  onValueChange={(value, key) =>
                    this.onValueChange(value, key, index)
                  }
                  onUpdateInputType={(e, inputType) =>
                    this.onUpdateInputType(e, index, inputType)
                  }
                  hasTotalInputTypeSelected={() =>
                    this.hasTotalInputTypeSelected()
                  }
                  onDeleteClicked={() => this.onDeleteClicked(index)}
                  linkedObjectOptions={this.state.linkedObjectOptions}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </Panel>
    );
  }
}

ResultsForms.defaultProps = {
  showDocLinking: false,
};

ResultsForms.propTypes = {
  onChange: PropTypes.func.isRequired,
  endpoints: PropTypes.arrayOf(PropTypes.string).isRequired,
  showDocLinking: PropTypes.bool,
  errors: PropTypes.shape({
    demoResult: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        inputType: PropTypes.string.isRequired,
        values: PropTypes.string.isRequired,
      }),
    ),
    saleResult: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        inputType: PropTypes.string.isRequired,
        values: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
};
