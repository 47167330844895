import Parse from 'parse';
import { handleError } from './auth';
import { setInitialStateForTemplate } from './templateEdit';
import { showSuccessAlert, hideAlert } from './alert';
import ContractObject from '../components/Templates/Models/ContractObject';
import { history } from '../router';

export const restoreDocumentSnapshot = (objectId) => async (
  dispatch,
  getState,
) => {
  try {
    const restored = await Parse.Cloud.run('restoreContractObject', {
      objectId,
    });
    dispatch(setInitialStateForTemplate(new ContractObject(restored.toJSON())));
    dispatch(
      showSuccessAlert({
        title: 'Success!',
        message: 'Document Restored',
        onConfirm: () => {
          dispatch(hideAlert());
        },
      }),
    );
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const restoreDocumentDeleteSnapshot = (objectId) => async (
  dispatch,
  getState,
) => {
  try {
    await Parse.Cloud.run('restoreContractObject', {
      objectId,
    });

    history.goBack();

    dispatch(
      showSuccessAlert({
        title: 'Success!',
        message: 'Document Restored',
        onConfirm: () => {
          dispatch(hideAlert());
        },
      }),
    );
  } catch (e) {
    dispatch(handleError(e));
  }
};
