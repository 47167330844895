export default (state = {}, action) => {
  switch (action.type) {
    case 'SET_ACCOUNT_ACTIVE_USER_COUNT':
      return {
        ...state,
        activeUsers: action.activeUsers,
      };
    case 'SET_ACCOUNT_DOCUSIGNS':
      return {
        ...state,
        docusign: {
          ...state.docusign,
          objects: action.docusigns,
          loaded: true,
        },
      };
    case 'SET_ACCOUNT_DOCUSIGN_COUNT':
      return {
        ...state,
        docusign: {
          ...state.docusign,
          count: action.count,
        },
      };
    case 'SET_DOCUSIGN_SEARCH_TEXT':
      return {
        ...state,
        docusign: {
          ...state.docusign,
          searchText: action.text,
        },
      };
    case 'SET_DOCUSIGN_PAGINATION':
      return {
        ...state,
        docusign: {
          ...state.docusign,
          page: action.page,
          limit: action.limit,
        },
      };
    case 'SET_DOCUSIGN_FILTER_START_DATE':
      return {
        ...state,
        docusign: {
          ...state.docusign,
          startDate: action.startDate,
        },
      };
    case 'SET_DOCUSIGN_FILTER_END_DATE':
      return {
        ...state,
        docusign: {
          ...state.docusign,
          endDate: action.endDate,
        },
      };
    case 'RESET_ACCOUNT':
      return {};
    default:
      return state;
  }
};
