export const defaultState = {
  items: [],
  sharedResources: [],
  resourcesTotalCount: 0,
};

export default (state = defaultState, { type, items = [], ...rest }) => {
  switch (type) {
    case 'ADD_RESOURCES':
      return {
        ...state,
        items: [...state.items, ...items],
      };
    case 'SET_RESOURCES':
      return {
        ...state,
        items,
      };
    case 'LOGOUT': {
      return defaultState;
    }
    case 'SET_RESOURCES_COUNT':
      return {
        ...state,
        resourcesTotalCount: rest.count,
      };
    case 'UPDATE_RESOURCE':
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.objectId === rest.item.objectId) {
            return { ...rest.item };
          }
          return item;
        }),
      };
    case 'SET_RESOURCES_INCLUDED_OFFICES_FILTER':
      return {
        ...state,
        includedOffices: rest.offices,
      };
    case 'SET_RESOURCES_DIRECTORY':
      return {
        ...state,
        directory: rest.directory,
      };
    case 'RESET_RESOURCES':
      return {
        ...defaultState,
      };
    case 'SET_SHARED_RESOURCES':
      return {
        ...state,
        sharedResources: rest.objects,
      };
    case 'SET_SHARED_RESOURCE_GROUPS':
      return {
        ...state,
        sharedResourceGroups: rest.objects,
      };
    case 'SET_SHARED_RESOURCE_GROUP':
      return {
        ...state,
        sharedResourceGroup: rest.group,
        isDownloaded: true,
      };
    case 'RESET_SHARED_RESOURCES':
      return {
        ...state,
        sharedResources: defaultState.sharedResources,
        sharedResourceGroup: undefined,
        isDownloaded: false,
      };
    case 'SET_RESOURCE_FILTER':
      return {
        ...state,
        filter: rest.filter,
      };
    default:
      return state;
  }
};
