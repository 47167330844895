export const groupData = (list, groupKey) =>
  list.reduce((groups, item) => {
    const group = groups[item[groupKey]] || { set: [], count: 0 };
    const count = group.count + 1;
    return {
      ...groups,
      [item[groupKey]]: {
        name: item[groupKey],
        set: [...group.set, item],
        count,
      },
    };
  }, {});
export const listGroups = (groupedData) =>
  Object.keys(groupedData).map((groupKey) => ({
    ...groupedData[groupKey],
    value: groupedData[groupKey].count,
  }));
export const getDataList = (list, groupKey) =>
  listGroups(groupData(list, groupKey));
export const parseToJson = (list) =>
  list.map((item) => ({
    ...item.toJSON(),
  }));
export const groupParseData = (list, groupKey) =>
  getDataList(parseToJson(list), groupKey);
export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};
