/* eslint-disable eqeqeq */
export default ({ objects, tagRow }) => {
  const { tagParams = {} } = tagRow || {};
  return {
    objects: objects.map(
      ({ cellType, inputType, pickerValues = [], title, ...object }) => ({
        cellType: cellType ? '' : 'Required',
        inputType: inputType || cellType === 'photos' ? '' : 'Required',
        title: title || cellType === 'photos' ? '' : 'Required',
        pickerValues:
          inputType === 'picker' && !pickerValues.length ? 'Required' : '',
        minSizePickerWidth: validateDimention('minSizePickerWidth', object),
        maxSizePickerWidth: validateDimention('maxSizePickerWidth', object),
        minSizePickerHeight: validateDimention('minSizePickerHeight', object),
        maxSizePickerHeight: validateDimention('maxSizePickerHeight', object),
        minSizePickerDepth: validateDimention('minSizePickerDepth', object),
        maxSizePickerDepth: validateDimention('maxSizePickerDepth', object),
      }),
    ),
    tagRow: {
      inputType: !tagRow || tagRow.inputType ? '' : 'Required',
      pickerValues:
        tagRow &&
        tagRow.inputType === 'picker' &&
        (!tagRow.pickerValues || !tagRow.pickerValues.length)
          ? 'Required'
          : '',
      tagParams: {
        minSizePickerWidth: validateDimention('minSizePickerWidth', tagParams),
        maxSizePickerWidth: validateDimention('maxSizePickerWidth', tagParams),
        minSizePickerHeight: validateDimention(
          'minSizePickerHeight',
          tagParams,
        ),
        maxSizePickerHeight: validateDimention(
          'maxSizePickerHeight',
          tagParams,
        ),
        minSizePickerDepth: validateDimention('minSizePickerDepth', tagParams),
        maxSizePickerDepth: validateDimention('maxSizePickerDepth', tagParams),
      },
    },
  };
};

export const validateDimention = (name, object) => {
  const value = object[name];

  if (value && (value > 1000 || value < 1)) {
    return 'value must be between 1 and 1000';
  }

  const coDimentionError = checkCoDimention(name, object);

  return coDimentionError;
};

export const checkCoDimention = (name, object) => {
  const value = object[name];

  if (value || value == 0) {
    const isMin = name.indexOf('min') == 0;

    if (isMin) {
      const coKey = name.replace('min', 'max');
      const coParamValue = object[coKey];

      if (coParamValue == 0 || coParamValue) {
        return parseFloat(coParamValue) < parseFloat(value)
          ? `${name} must be less than ${coKey}`
          : '';
      }
    } else {
      const coKey = name.replace('max', 'min');
      const coParamValue = object[coKey];

      if (coParamValue == 0 || coParamValue) {
        return parseFloat(coParamValue) > parseFloat(value)
          ? `${name} must be greater than ${coKey}`
          : '';
      }
    }
  }
  return '';
};
