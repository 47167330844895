export default (items = [], directory = '', officeIds = []) =>
  items.filter((item) => {
    if (directory.length > 0 && !item.displayName.startsWith(directory)) {
      return false;
    }
    // if another "/" is included in the file name then it
    // should be displayed in a different directory
    const displayName = item.displayName.split(directory).join('');
    if (displayName.indexOf('/', 1) > 0 || item.displayName === directory) {
      return false;
    }
    if (officeIds.length === 0 && item.includedOffices.length === 0) {
      return true;
    }
    const includedOffices = item.includedOffices || [];
    const includedOfficeIds = includedOffices.map((office) => office.objectId);
    for (let i = 0; i < officeIds.length; i += 1) {
      const officeId = officeIds[i];
      if (officeId === 'all' || includedOfficeIds.indexOf(officeId) > -1) {
        return true;
      }
    }
    return false;
  });

export const selectResources = (resources, name = '') =>
  resources.filter((resource) =>
    resource.name.toLowerCase().includes(name.toLowerCase()),
  );
