import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import Select from '../Leapstrap/Select';

const inputOptions = [
  {
    value: 'default',
    label: 'Keyboard',
  },
  {
    value: 'picker',
    label: 'Picker',
  },
  {
    value: 'keypad',
    label: 'Numbers (1)',
  },
  {
    value: 'keypadDecimal',
    label: 'Numbers (1.00)',
  },
  {
    value: 'numberKeyboard',
    label: 'Number Keyboard',
  },
  {
    value: 'sizePickerInch',
    label: 'Size Picker (inch)',
  },
  {
    value: 'sizePickerQuarterInch',
    label: 'Size Picker (1/4")',
  },
  {
    value: 'sizePickerEighthInch',
    label: 'Size Picker (1/8")',
  },
  {
    value: 'sizePickerSixteenthInch',
    label: 'Size Picker (1/16")',
  },
  {
    value: 'currency',
    label: 'Currency ($1)',
  },
  {
    value: 'currencyDecimal',
    label: 'Currency ($1.00)',
  },
  {
    value: 'datePicker',
    label: 'Date Picker',
  },
  {
    value: 'dateTimePicker',
    label: 'Date & Time',
  },
  {
    value: 'timePicker',
    label: 'Time',
  },
  {
    value: 'unitedInchPicker',
    label: 'United Inch Picker',
  },
  {
    value: '3DSizePickerInch',
    label: '3D Size Picker (inch)',
  },
  {
    value: '3DSizePickerQuarterInch',
    label: '3D Size Picker (1/4")',
  },
  {
    value: '3DSizePickerEighthInch',
    label: '3D Size Picker (1/8")',
  },
  {
    value: '3DSizePickerSixteenthInch',
    label: '3D Size Picker (1/16")',
  },
];

const InputTypesDropDown = (props) => {
  const { inputType, onChange, color } = props;
  return (
    <Form.Group>
      <Form.Label>Input Type</Form.Label>
      <Select
        name="inputType"
        onChange={(selected) => {
          const event = {
            currentTarget: { value: selected.value, name: 'inputType' },
          };
          onChange(event);
        }}
        options={inputOptions}
        color={color}
        value={inputOptions.find((iType) => iType.value === inputType)}
      />
    </Form.Group>
  );
};
InputTypesDropDown.defaultProps = {
  color: 'blue',
};
InputTypesDropDown.propTypes = {
  onChange: PropTypes.func.isRequired,
  inputType: PropTypes.string.isRequired,
  color: PropTypes.string,
};

export default InputTypesDropDown;
