/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Classes, Position, Popover } from 'leap-menu-item';
import HeaderTitleToolbox from './HeaderSection';
import HeaderCellToolbox from './HeaderCell';
import BodySectionToolbox from './BodySection';
import BodyCellToolbox from './BodyCell/BodyCell';
import SignatureCellToolbox from './SignatureCell';
import SignatureAdditionalLine from './SignatureAdditionalLine';

import { pushToDataLayer } from '../../../actions/tagManager';

const toolboxForType = (type) => {
  switch (type) {
    case 'header-title':
      return <HeaderTitleToolbox />;
    case 'header-cell':
      return <HeaderCellToolbox />;
    case 'body-title':
      return <BodySectionToolbox />;
    case 'body-cell':
    case 'body-detail-cell':
      return <BodyCellToolbox />;
    case 'signature-title':
      return <SignatureCellToolbox />;
    case 'signature-detail':
      return <SignatureAdditionalLine />;
    default:
      return <div />;
  }
};

function SettingsMenu({ editCellItem, setHoverOpen, setClickOpen }) {
  SettingsMenu.handleClickOutside = () => {
    setHoverOpen(false);
    setClickOpen(false);
  };

  return (
    <div
      onMouseLeave={() => {
        setHoverOpen(false);
      }}
    >
      {toolboxForType(editCellItem.toolboxKey)}
    </div>
  );
}
const clickOutsideConfig = {
  handleClickOutside: () => SettingsMenu.handleClickOutside,
};

SettingsMenu.propTypes = {
  editCellItem: PropTypes.shape({}),
  setHoverOpen: PropTypes.func.isRequired,
  setClickOpen: PropTypes.func.isRequired,
};

SettingsMenu.defaultProps = {
  editCellItem: {},
};

const SettingsMenuPopOverContent = onClickOutside(
  SettingsMenu,
  clickOutsideConfig,
);

const Toolbox = () => {
  const dispatch = useDispatch();
  const pushToDataLayer2 = (variablesForLayer) =>
    dispatch(pushToDataLayer(variablesForLayer));

  const editCellItem = useSelector(({ templatesEdit = {} }) => ({
    ...templatesEdit.editCellItem,
  }));

  const [hoverOpen, setHoverOpen] = useState(false);
  const [clickOpen, setClickOpen] = useState(false);

  return (
    <Popover
      isOpen={hoverOpen || clickOpen}
      content={
        <SettingsMenuPopOverContent
          {...{ editCellItem, setHoverOpen, setClickOpen }}
        />
      }
      position={Position.BOTTOM}
    >
      <Button
        onMouseEnter={() => {
          if (!(hoverOpen || clickOpen)) {
            pushToDataLayer2({
              event: 'contractMenuEvent',
              eventCategory: 'Documents',
              eventAction: 'Menu Open',
              eventLabel: 'Settings',
            });
          }
          setHoverOpen(true);
        }}
        onClick={() => {
          if (hoverOpen || clickOpen) {
            pushToDataLayer2({
              event: 'contractMenuEvent',
              eventCategory: 'Documents',
              eventAction: 'Menu Open',
              eventLabel: 'Settings',
            });
          }
          setClickOpen(true);
        }}
        className={Classes.MINIMAL}
        text="Settings"
        disabled={!editCellItem.toolboxKey}
      />
    </Popover>
  );
};

export default Toolbox;
