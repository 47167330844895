/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
/** @jsx jsx */
import { jsx } from '@emotion/core';
// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { bodyRowForCellItem } from '../BodyRow';
import { getItemStyle, getListStyle } from '../Utils';
import { setOnDragEndCallback } from '../../DropContext';
import {
  setInitialStateForTemplate,
  setEditCellItem,
} from '../../../actions/templateEdit';
import ContractObject from '../Models/ContractObject';
import BodyGroupObject from '../Models/BodyGroup';
import BodyCellItem from '../Models/BodyCellItem';

const styleForCellItem = ({ shading }, cellItem, index) => {
  const shouldShade = shading === 2 || (shading === 1 && index % 2 !== 0);
  return {
    backgroundColor: shouldShade ? 'rgb(224, 224, 224, 0.7)' : undefined,
  };
};

export const setCallbackForDragEnd = (
  droppableId,
  section,
  setTemplateState,
  setEditCellItem,
) => {
  setOnDragEndCallback(droppableId, (result) => {
    const { source, destination, draggableId } = result;
    const contractObject = section.getSourceObject();
    if (source.droppableId === `new-${BodyCellItem.ContainerKey}`) {
      const newSection = new BodyCellItem(undefined, section);
      section.cellItems.splice(destination.index, 0, newSection);
      const updatedContractObject = new ContractObject(contractObject.toJSON());
      setTemplateState(updatedContractObject);
      setEditCellItem(newSection);
      return;
    }
    let sourcePath = draggableId.split('-');
    sourcePath.pop();
    sourcePath = sourcePath.map((index) => parseInt(index, 10));
    const sourceItem = contractObject.getItemAtPath(sourcePath);
    sourceItem.parent.cellItems.splice(source.index, 1);

    section.cellItems.splice(destination.index, 0, sourceItem);
    if (!sourceItem.parent.cellItems.length) {
      contractObject.deleteObjectAtPath(sourceItem.parent.getPath());
    }
    const updatedContractObject = new ContractObject(contractObject.toJSON());
    setTemplateState(updatedContractObject);
  });
};

const BodySectionGroup = ({
  section,
  setTemplateState,
  editCellItem,
  draggableType,
  setEditCellItem,
  scale,
}) => {
  const { cellItems, ...rest } = section;
  const droppableId = `${section.objectId}-${BodyCellItem.ContainerKey}`;
  setCallbackForDragEnd(
    droppableId,
    section,
    setTemplateState,
    setEditCellItem,
  );
  return (
    <Droppable
      droppableId={droppableId}
      isDropDisabled={draggableType !== BodyCellItem.ContainerKey}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          css={{
            border: rest.drawBorder ? '1px solid black' : undefined,
            ...getListStyle(snapshot.isDraggingOver),
          }}
          {...provided.droppableProps}
        >
          {cellItems.map((cellItem, index) => (
            <Draggable
              key={cellItem.objectId}
              draggableId={`${cellItem.getPath().join('-')}-${
                cellItem.objectId
              }`}
              index={index}
            >
              {(draggableProvided, draggableSnapshot) => {
                const component = (
                  <div
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}
                    css={{
                      ...getItemStyle(
                        draggableSnapshot,
                        draggableProvided.draggableProps.style,
                        editCellItem.objectId === cellItem.objectId,
                      ),
                      translate: 'translateX(-100%)',
                    }}
                  >
                    <div css={styleForCellItem(rest, cellItem, index)}>
                      {bodyRowForCellItem(cellItem)}
                    </div>
                    {draggableProvided.placeholder}
                  </div>
                );
                return component;
              }}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

BodySectionGroup.propTypes = {
  section: PropTypes.instanceOf(BodyGroupObject).isRequired,
  setTemplateState: PropTypes.func.isRequired,
  setEditCellItem: PropTypes.func.isRequired,
  editCellItem: PropTypes.any,
  draggableType: PropTypes.string,
};

BodySectionGroup.defaultProps = {
  editCellItem: {},
  draggableType: undefined,
};

const mapStateToProps = ({ templatesEdit }) => ({
  editCellItem: templatesEdit.editCellItem,
  draggableType: templatesEdit.draggableType,
  scale: templatesEdit.scale,
});

const mapDispatchToProps = (dispatch) => ({
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
  setEditCellItem: (template) => dispatch(setEditCellItem(template)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BodySectionGroup);
