/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-cycle */
import Parse from 'parse';
import { handleError } from './auth';

export const setCount = (count) => ({
  type: 'SET_FINANCE_OPTIONS_COUNT',
  count,
});

export const addFinanceOptions = (items = []) => ({
  type: 'ADD_FINANCE_OPTIONS',
  items,
});

export const setFinanceOptions = (items = []) => ({
  type: 'SET_FINANCE_OPTIONS',
  items,
});

export const startFetchFinanceOptions = (params) => async (
  dispatch,
  getState,
) => {
  try {
    const {
      limit = 100,
      skip = 0,
      sortOption = 'ascending',
      sortKey = 'feePercent',
    } = params;

    const state = getState();
    const query = new Parse.Query('SSFinanceOption');
    let { count } = state.financeOptions;
    if (!count) {
      count = await query.count();
      dispatch(setCount(count));
    }

    query.limit(limit);
    query.skip(skip);

    if (sortOption && query[sortOption] && sortKey) {
      query[sortOption](sortKey);
    }

    const items = await query.find();
    dispatch(setFinanceOptions(items));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const resetFinanceOptions = () => ({
  type: 'RESET_FINANCE_OPTIONS',
});

export const deleteFinanceOption = (financeOption) => async (
  dispatch,
  getState,
) => {
  try {
    const existed = financeOption.existed();
    if (existed) {
      await financeOption.destroy();
      const {
        financeOptions: { items },
      } = getState();
      const updatedItems = items.filter((item) => item.id !== financeOption.id);
      const { financeOptions } = getState();
      const { count } = financeOptions;
      if (count) {
        dispatch(setCount(count - 1));
      }
      dispatch(setFinanceOptions(updatedItems));
    } else {
      const {
        financeOptions: { items },
      } = getState();
      const updatedItems = items.filter(
        (item) => item._localId !== financeOption._localId,
      );
      dispatch(setFinanceOptions(updatedItems));
    }
  } catch (e) {
    dispatch(handleError(e));
  }
};
