import React from 'react';
import DeleteZapButton from './DeleteZapButton';
import ZapSwitch from './ZapSwitch';

const formatTriggerDisplay = (value) => {
  switch (value) {
    case 'results':
      return 'Results';
    case 'sendAttachment':
      return 'Send Attachment';
    case 'paymentTransaction':
      return 'Payment Transaction';
    default:
      return value;
  }
};

const valueFormatter = (value, { item, columnIndex }) => {
  switch (columnIndex) {
    case 0:
      return <ZapSwitch value={value} item={item} />;
    case 1:
      return formatTriggerDisplay(value);
    case 4:
      return <DeleteZapButton item={item} />;
    default:
      return value;
  }
};

export default valueFormatter;
