import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { currencyFromNumber } from '../../utils/utils';

const keysToFormat = [
  'ssn',
  'phone',
  'currency',
  'formulaCurrency',
  'currencyWhole',
  'formulaCurrencyWhole',
];

const DecryptedCell = (props) => {
  const { cellData = {} } = props;

  const formatSSN = (value) => {
    const cleaned = String(value).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/);
    return match ? `${match[1]}-${match[2]}-${match[3]}` : value;
  };

  const formatPhoneNumber = (value) => {
    const cleaned = String(value).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    return match ? `${match[1]}-${match[2]}-${match[3]}` : value;
  };

  const getFormattedValue = () => {
    const { inputType, decryptedValue, dynamicInputObject } = cellData;

    let validInputType = inputType;

    if (
      inputType === 'dynamic' &&
      dynamicInputObject &&
      dynamicInputObject.objects
    ) {
      const dynamicInputTypeCell = Object.values(
        dynamicInputObject.objects,
      ).find((values) => keysToFormat.includes(values.inputType));

      if (dynamicInputTypeCell) validInputType = dynamicInputTypeCell.inputType;
    }

    switch (validInputType) {
      case 'ssn':
        return formatSSN(decryptedValue);

      case 'phone':
        return formatPhoneNumber(decryptedValue);

      case 'currency': // with decimal
      case 'formulaCurrency':
        return currencyFromNumber(decryptedValue);

      case 'currencyWhole': // without decimal
      case 'formulaCurrencyWhole':
        return currencyFromNumber(decryptedValue, true);

      default:
        return decryptedValue;
    }
  };

  return (
    <Row className="decrypted-row">
      <Col>
        <span>{cellData.title}</span>
      </Col>
      <Col>
        <span>{getFormattedValue()}</span>
      </Col>
    </Row>
  );
};
DecryptedCell.propTypes = {
  cellData: PropTypes.shape({
    title: PropTypes.string,
    decryptedValue: PropTypes.string,
  }).isRequired,
};
DecryptedCell.defaultProps = {};
export default DecryptedCell;
