/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/** @jsx jsx */

// eslint-disable-next-line no-unused-vars
import React from 'react';
import { useDispatch } from 'react-redux';
import { jsx, css } from '@emotion/core';
import { NavLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { pushToDataLayer } from '../../../actions/tagManager';
import { createStyleElement } from '../../../utils/utils';

const titleCheck = (title) => {
  if (title === 'AVAILABLE CONTACTS') {
    return 'Available Contacts';
  }
  return title;
};

const MenuItem = ({ title = '', icon, anchor, style = {} }) => {
  const buttonStyle = style.button || {};
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isActive = window.location.pathname.startsWith(`/${anchor}`);

  const onClick = () => {
    if (anchor !== pathname.replace('/', '')) {
      dispatch(
        pushToDataLayer({
          event: 'leftNavigationEvent',
          eventCategory: 'Left Navigation',
          eventAction: 'Click',
          eventLabel: anchor,
        }),
      );
    }
  };

  return (
    <li className="main-menu__item" onClick={onClick}>
      <NavLink
        to={`/${anchor}`}
        className="button text-left pl-0 d-flex align-items-center"
        activeClassName="main-menu__active button--active"
        css={css`
          ${createStyleElement(
            isActive
              ? { ...buttonStyle, ...buttonStyle['&:hover'] }
              : buttonStyle,
          )}
        `}
        role="button"
      >
        {icon ? (
          <span className={`left-icon ${icon} d-block`} />
        ) : (
          <span className="dot" style={style.dot} />
        )}
        {/** If it's a top level item (has an icon) capitalize text, sub item
         *  (no icon) lowercase it then use css to capitalize first letters */}
        <p
          className={`p-0 m-0${icon ? ' text-uppercase' : ' text-capitalize'}`}
        >
          {icon ? title.toUpperCase() : titleCheck(title)}
        </p>
      </NavLink>
    </li>
  );
};

MenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  anchor: PropTypes.string.isRequired,
  style: PropTypes.shape({
    button: PropTypes.object,
    dot: PropTypes.object,
  }),
};

MenuItem.defaultProps = {
  icon: '',
  style: { button: {} },
};

export default MenuItem;
