/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Parse from 'parse';
import { DebounceInput } from 'react-debounce-input';
import {
  Collapse,
  ContextMenu,
  Popover,
  Position,
  PopoverInteractionKind,
} from '@blueprintjs/core';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import ExpandAllCell from '../ExpandAllCell';
import OptionCell from './Cell';
import PriceGuideOption from '../../../Models/PriceGuideOption';
import {
  setItem,
  setOption,
  setUpCharge,
  setUpCharges,
  onPriceGuideScroll,
  setPlaceholdersEdit,
  startFetchUpChargesForItem,
} from '../../../actions/priceGuide2';
import { handleError } from '../../../actions/auth';
import UpChargeHeader from '../UpCharges/Header';
import UpChargeRow from '../UpCharges/Row';
import { setOnDragEndCallback } from '../../DropContext';
import AppToaster from '../../../utils/AppToaster';
import CopyPasteMenu from '../CopyPasteMenu';
import {
  setPasteboardValue,
  getPasteboardValue,
} from '../../../utils/Pasteboard';
import PlaceholdersPreview from '../Placeholders/PreviewView';
import Sticky from '../Sticky';
import initContext from '../StickyUtils';

const priceGuideVertStickyContext = initContext('priceguide__index');

const UpchargeRowDraggableSwitch = ({
  updateUpCharge,
  item,
  droppableId,
  draggableId,
  index,
  option,
  upCharge,
  isDisabled,
  disabledParents,
}) => {
  return (
    <Draggable
      key={draggableId}
      draggableId={draggableId}
      index={index}
      type={droppableId}
    >
      {(upChargeDraggableProvided, snapshot) => {
        const component = (
          <UpChargeRow
            draggableProvided={upChargeDraggableProvided}
            upCharge={upCharge}
            option={option}
            item={item}
            isDisabled={isDisabled}
            onDisabled={(value) => {
              const updatedDisabledParents = disabledParents.filter(
                (id) => id !== option.id,
              );
              if (value) {
                updatedDisabledParents.push(option.id);
              }
              const updatedUpCharge = upCharge.newInstance();
              updatedUpCharge.set('disabledParents', updatedDisabledParents);
              updateUpCharge(updatedUpCharge);
            }}
          />
        );
        return component;
      }}
    </Draggable>
  );
};

const OptionRow = ({
  item,
  option,
  upCharges,
  updateItem,
  updateOption,
  updateUpCharge,
  updateUpCharges,
  setPlaceholders,
  includedOffices,
  draggableProvided,
  startFetchUpCharges,
  handleErr,
}) => {
  const droppableId = `${item.id}-${option.id}`;
  setOnDragEndCallback(droppableId, (result) => {
    const updatedItem = item.newInstance();
    const { source, destination } = result;
    updatedItem.moveUpCharge(source.index, destination.index);
    updateItem(updatedItem);
  });
  const onRightClick = (e) => {
    e.preventDefault();
    const pasteValue = getPasteboardValue('placeholders');
    const menu = (
      <CopyPasteMenu
        title="Placeholders"
        onCopy={() =>
          setPasteboardValue(option.get('placeholders') || [], 'placeholders')
        }
        pasteDisabled={!pasteValue}
        onPasteAdd={() => {
          const newOption = option.newInstance();
          newOption.addPlaceholders(pasteValue);
          updateOption(newOption);
        }}
        onPasteReplace={() => {
          const newOption = option.newInstance();
          newOption.set('placeholders', pasteValue);
          updateOption(newOption);
        }}
        onClearAll={() => {
          const newOption = option.newInstance();
          newOption.set('placeholders', []);
          updateOption(newOption);
        }}
      />
    );
    ContextMenu.show(menu, { left: e.clientX, top: e.clientY });
  };
  const onExpand = () => {
    const newOption = option.newInstance();
    newOption.isExpanded = !option.isExpanded;
    updateOption(newOption);
    if (newOption.isExpanded) {
      startFetchUpCharges(item);
    }
  };
  const onSaveClicked = async () => {
    await option.save();
    const newOption = option.newInstance();
    updateOption(newOption);
  };
  const onSelect = () => {
    const newOption = option.newInstance();
    newOption.isSelected = !option.isSelected;
    updateOption(newOption);
  };
  const onChange = (key, value) => {
    const newOption = option.newInstance();
    newOption.set(key, value);
    updateOption(newOption);
  };
  const onPriceChanged = (officeId, total) => {
    const newOption = option.newInstance();
    newOption.setPriceForOffice(officeId, total);
    updateOption(newOption);
  };
  const onEditPlaceholders = () => {
    setPlaceholders({
      values: option.get('placeholders'),
      onClose: () => setPlaceholders(undefined),
      onSave: (values) => {
        option.set('placeholders', values);
        const updatedOption = option.newInstance();
        updateOption(updatedOption);
        setPlaceholders(undefined);
      },
    });
  };
  const accessories = item.get('accessories') || [];
  const upChargeIds = accessories.map(({ id }) => id);
  const onSelectAllUpCharges = (select) => {
    const updatedUpCharges = upChargeIds
      .map((id) => upCharges[id])
      .filter((obj) => obj);
    const objects = {};
    updatedUpCharges.forEach((obj) => {
      const newObject = obj.newInstance();
      newObject.isSelected = select;
      objects[obj.id] = newObject;
    });
    updateUpCharges(objects);
  };
  const onSaveAllUpCharges = async () => {
    try {
      const objects = upChargeIds.map((id) => upCharges[id].convertImage());
      await Parse.Object.saveAll(objects);
      const updatedUpCharges = {};
      objects.forEach((obj) => {
        updatedUpCharges[obj.id] = obj.newInstance();
      });
      updateUpCharges(updatedUpCharges);
      AppToaster.show({ message: 'All Up-Charges Saved!', timeout: 3000 });
    } catch (e) {
      handleErr(e);
    }
  };
  const onDeleteAllUpCharges = () => {
    item.removeAllUpCharges();
    const newItem = item.newInstance();
    updateItem(newItem);
    const newOption = option.newInstance();
    newOption.isExpanded = false;
    updateOption(newOption);
  };
  const onDelete = () => {
    const newItem = item.newInstance();
    newItem.removeOption(option.id);
    updateItem(newItem);
  };
  const onRefresh = () => {
    const newOption = option.newInstance();
    newOption.revert();
    updateOption(newOption);
  };
  const revertNestedKey = async () => {
    const query = new Parse.Query('SSPriceGuideItem');
    query.containedIn('objectId', upChargeIds);
    const objects = await query.find();

    if (objects.length > 0) {
      objects.forEach((obj) => {
        const upCharge = upCharges[obj.id];
        if (upCharge.id === obj.id) {
          const newValues = obj.get('accessoryPrices');
          upCharge.set('accessoryPrices', newValues);
          // eslint-disable-next-line no-underscore-dangle
          upCharge._clearPendingOps();
        }
      });
      updateUpCharges(upCharges);
    }
  };
  const onRefreshAllUpCharges = () => {
    revertNestedKey();
    const updatedUpCharges = upChargeIds
      .map((id) => upCharges[id])
      .filter((obj) => obj);
    const objects = {};
    updatedUpCharges.forEach((obj) => {
      const newObject = obj.newInstance();
      newObject.revert();
      objects[obj.id] = newObject;
    });
    updateUpCharges(objects);
  };
  const isAllUpChargesSelected = upChargeIds.every(
    (id) => upCharges[id] && upCharges[id].isSelected,
  );
  const isUpChargeSaveAllDisabled = upChargeIds.every(
    (id) => !upCharges[id] || !upCharges[id].dirty(),
  );
  return (
    <>
      <Sticky
        enabelSticky={option.isExpanded || false}
        scrollElementId="priceguide__index"
        uid={`${item.id}-${option.id}`}
        bumpType={`green-item-${item.id}`}
        stackOrder={3}
        stickTo={{
          type: 'group',
          value: 'green',
        }}
        waitFor={item.id}
      >
        <div
          className="priceguide__option-row"
          ref={draggableProvided.innerRef}
          {...draggableProvided.draggableProps}
        >
          <ExpandAllCell
            onClick={onExpand}
            isOpen={option.isExpanded}
            disabled={accessories.length === 0}
            type="option"
          />
          <OptionCell width={35}>
            <div
              className="priceguide__drag-container"
              {...draggableProvided.dragHandleProps}
            >
              <i className="fas fa-grip-lines" />
            </div>
          </OptionCell>
          <OptionCell width={35} columnKey="save">
            <button
              className="priceguide__button"
              disabled={!option.dirty()}
              onClick={onSaveClicked}
            >
              <i className="far fa-save" />
            </button>
          </OptionCell>
          <OptionCell width={35} columnKey="refresh">
            <button
              className="priceguide__button"
              disabled={!option.dirty()}
              onClick={onRefresh}
            >
              <i className="fas fa-undo-alt" />
            </button>
          </OptionCell>
          <OptionCell width={35} columnKey="optionSelect">
            <input
              type="checkbox"
              checked={!!option.isSelected}
              onChange={onSelect}
            />
          </OptionCell>
          <OptionCell width={35} columnKey="settings">
            <div />
          </OptionCell>
          <OptionCell width={35} columnKey="additionalDetails">
            <div />
          </OptionCell>
          <OptionCell
            width={35}
            columnKey="optionPlaceholders"
            onContextMenu={onRightClick}
          >
            <Popover
              content={
                <PlaceholdersPreview
                  placeholders={option.get('placeholders')}
                />
              }
              interactionKind={PopoverInteractionKind.HOVER}
              position={Position.RIGHT}
            >
              <button
                className="priceguide__button"
                onClick={onEditPlaceholders}
              >
                <i className="fas fa-retweet" />
              </button>
            </Popover>
          </OptionCell>
          <OptionCell width={35} columnKey="showToggle">
            <div />
          </OptionCell>
          <OptionCell width={60} columnKey="image">
            <div />
          </OptionCell>
          <OptionCell
            value={option.get('subCategory2')}
            columnKey="optionBrand"
            onChange={(e) => onChange('subCategory2', e.target.value)}
          />
          <OptionCell
            value={option.get('displayTitle')}
            columnKey="optionName"
            onChange={(e) => onChange('displayTitle', e.target.value)}
          />
          {includedOffices.map((officeId) => (
            <OptionCell key={officeId} columnKey="optionPrices" width={200}>
              <DebounceInput
                type="number"
                className="priceguide__input-currency"
                value={
                  option.getPriceForOffice(officeId) !== 0
                    ? option.getPriceForOffice(officeId)
                    : ''
                }
                onChange={(e) => onPriceChanged(officeId, e.target.value)}
                minLength={0}
                debounceTimeout={300}
                placeholder="0"
              />
              <div className="priceguide__number-type-label">$</div>
            </OptionCell>
          ))}
          <OptionCell width={35} columnKey="trash">
            <button className="priceguide__button" onClick={onDelete}>
              <i className="far fa-trash-alt" />
            </button>
          </OptionCell>
        </div>
      </Sticky>
      <Collapse
        ref={(ref) => {
          if (ref) {
            priceGuideVertStickyContext.registerCutOff({
              holdUp: item.id,
              ref: ref.contents,
              uid: `${item.id}-${option.id}`,
            });
          }
        }}
        isOpen={option.isExpanded}
      >
        <Sticky
          scrollElementId="priceguide__index"
          stickTo={{
            type: 'group',
            value: `green-item-${item.id}`,
          }}
          uid={`${item.id}-pink-header-${option.id}`}
          stackOrder={4}
          bumpType="upcharge-header"
        >
          <UpChargeHeader
            onSelectAll={onSelectAllUpCharges}
            isAllSelected={isAllUpChargesSelected}
            onSaveAll={onSaveAllUpCharges}
            isSaveAllDisabled={isUpChargeSaveAllDisabled}
            onDeleteAll={onDeleteAllUpCharges}
            onRefresh={onRefreshAllUpCharges}
            isRefreshDisabled={isUpChargeSaveAllDisabled}
            itemId={item.id}
            optionId={option.id}
          />
        </Sticky>
        <Droppable droppableId={droppableId} type={droppableId}>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {upChargeIds.map((id, index) => {
                const upCharge = upCharges[id];
                if (!upCharge) {
                  return null;
                }
                const disabledParents = upCharge.get('disabledParents') || [];
                const isDisabled = disabledParents.indexOf(option.id) > -1;
                const draggableId = `${option.id}-${id}`;
                return (
                  <div key={id} style={{ height: 35, width: '100%' }}>
                    <UpchargeRowDraggableSwitch
                      {...{
                        updateUpCharge,
                        item,
                        droppableId,
                        draggableId,
                        index,
                        option,
                        upCharge,
                        isDisabled,
                        disabledParents,
                      }}
                    />
                  </div>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </Collapse>
    </>
  );
};

OptionRow.propTypes = {
  updateItem: PropTypes.func.isRequired,
  option: PropTypes.instanceOf(PriceGuideOption).isRequired,
  updateOption: PropTypes.func.isRequired,
  updateUpCharge: PropTypes.func.isRequired,
  updateUpCharges: PropTypes.func.isRequired,
  setPlaceholders: PropTypes.func.isRequired,
  includedOffices: PropTypes.arrayOf(PropTypes.string),
  upCharges: PropTypes.shape({}),
  handleErr: PropTypes.func.isRequired,
};

OptionRow.defaultProps = {
  includedOffices: [],
  upCharges: {},
};

const mapStateToProps = ({ priceGuide2, priceGuideScroll }) => ({
  includedOffices: priceGuide2.includedOffices,
  upCharges: priceGuide2.upCharges,
  priceGuideScroll,
});

const mapDispatchToProps = (dispatch) => ({
  updateItem: (item) => dispatch(setItem(item)),
  updateOption: (option) => dispatch(setOption(option)),
  updateUpCharge: (upCharge) => dispatch(setUpCharge(upCharge)),
  updateUpCharges: (upCharges) => dispatch(setUpCharges(upCharges)),
  setPlaceholders: (placeholders) =>
    dispatch(setPlaceholdersEdit(placeholders)),
  startFetchUpCharges: (item) => dispatch(startFetchUpChargesForItem(item)),
  handleErr: (e) => dispatch(handleError(e)),
  onPriceGuideScroll: (e) => dispatch(onPriceGuideScroll(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OptionRow);
