/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Parse from 'parse';
import MultiSelect from '../Misc/MultiSelect';

import { startFetchPermissions } from '../../actions/permissions';
import { permissionsByType } from '../../selectors/permissions';

const valueRenderer = (selected, options) => {
  if (selected.length) {
    const maped = selected.map((selection) => {
      const [option] = options.filter((op) => op.value === selection);
      return option;
    });
    const renderString = maped.reduce(
      (display, option) => `${display}, ${option.label}`,
      '',
    );
    return renderString.replace(',', '');
  }
  return 'Select Permissions';
};

const PermissionsDropDown = (props) => {
  if (!props.permissions.length) {
    props.startFetchPermissions();
  }
  return (
    <MultiSelect
      options={props.permissions.map((permission) => ({
        value: permission.id,
        label: permission.get('title'),
      }))}
      onSelectedChanged={props.onSelectedChanged}
      selected={props.selected}
      selectAllLabel="Select All"
      valueRenderer={props.valueRenderer}
      hasSelectAll={props.permissions.length > 1}
      disableSearch={props.permissions.length <= 1}
    />
  );
};

PermissionsDropDown.propTypes = {
  onSelectedChanged: PropTypes.func,
  startFetchPermissions: PropTypes.func.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.instanceOf(Parse.Object)),
  valueRenderer: PropTypes.func,
  selected: PropTypes.arrayOf(PropTypes.string),
};

PermissionsDropDown.defaultProps = {
  onSelectedChanged: () => {},
  valueRenderer,
  permissions: [],
  selected: [],
};

const mapStateToProps = ({ permissions }) => ({
  permissions: permissionsByType(permissions.items, 'Group'),
});

const mapDispatchToProps = (dispatch) => ({
  startFetchPermissions: () => dispatch(startFetchPermissions()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PermissionsDropDown);
