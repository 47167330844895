import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import PermissionsIndex from './IndexPage';
import PermissionsEdit from './Edit';

const Permissions = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={[`${path}/edit/:id`, `${path}/new`]}
        component={PermissionsEdit}
      />
      <Route path="/permissions" component={PermissionsIndex} />
    </Switch>
  );
};

export default Permissions;
