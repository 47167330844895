/* eslint-disable no-undef */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  ContextMenu,
  Popover,
  Position,
  PopoverInteractionKind,
} from '@blueprintjs/core';
import Parse from 'parse';
import {
  setUpCharge,
  setItem,
  setAdditionalDetailsEdit,
  setPlaceholdersEdit,
  setEditUpChargeModal,
} from '../../../actions/priceGuide2';
import { handleError } from '../../../actions/auth';

import UpChargeCell from './Cell';
import UpCharge from '../../../Models/UpCharge';
import MeasureSheetItem from '../../../Models/MeasureSheetItem';
import ThumbnailButton from '../Thumbnail/ThumbnailButton';
import UpChargePriceCell from './UpChargePriceCell';
import CopyPasteMenu from '../CopyPasteMenu';
import {
  setPasteboardValue,
  getPasteboardValue,
} from '../../../utils/Pasteboard';
import { newFileFromEvent, randomKey } from '../../../utils/utils';
import AdditionalDetailsPreview from '../AdditionalDetails/PreviewView';
import PlaceholdersPreview from '../Placeholders/PreviewView';

const UpChargeRow = ({
  upCharge,
  option,
  item,
  updateUpCharge,
  setAdditionalDetails,
  setPlaceholders,
  draggableProvided,
  isDisabled,
  onDisabled,
  includedOffices,
  updateItem,
  setEditUpCharge,
  handleErr,
}) => {
  const onSaveClicked = async () => {
    await upCharge.save();
    const newUpCharge = upCharge.newInstance();
    updateUpCharge(newUpCharge);
  };
  const onSelect = () => {
    const newUpCharge = upCharge.newInstance();
    newUpCharge.isSelected = !upCharge.isSelected;
    updateUpCharge(newUpCharge);
  };
  const onChange = (key, value) => {
    const newUpCharge = upCharge.newInstance();
    newUpCharge.set(key, value);
    updateUpCharge(newUpCharge);
  };
  const onPlaceholderRightClick = (e) => {
    e.preventDefault();
    const pasteValue = getPasteboardValue('placeholders');
    const menu = (
      <CopyPasteMenu
        title="Placeholders"
        onCopy={() =>
          setPasteboardValue(upCharge.get('placeholders') || [], 'placeholders')
        }
        pasteDisabled={!pasteValue}
        onPasteAdd={() => {
          const newUpCharge = upCharge.newInstance();
          newUpCharge.addPlaceholders(pasteValue);
          updateUpCharge(newUpCharge);
        }}
        onPasteReplace={() => {
          const newUpCharge = upCharge.newInstance();
          newUpCharge.set('placeholders', pasteValue);
          updateUpCharge(newUpCharge);
        }}
        onClearAll={() => {
          const newUpCharge = upCharge.newInstance();
          newUpCharge.set('placeholders', []);
          updateUpCharge(newUpCharge);
        }}
      />
    );
    ContextMenu.show(menu, { left: e.clientX, top: e.clientY });
  };
  const onAdditionalDetailsRightClick = (e) => {
    e.preventDefault();
    const { value } = getPasteboardValue('additionalDetails') || {};
    const menu = (
      <CopyPasteMenu
        title="Additional Details"
        pasteDisabled={!value}
        onCopy={() => {
          const object = { value: upCharge.get('additionalDetails') || [] };
          setPasteboardValue(object, 'additionalDetails');
        }}
        onPasteAdd={() => {
          const newUpCharge = upCharge.newInstance();
          const deatilsWithNewObjectIds = value.map((detailItem) => ({
            ...detailItem,
            objectId: randomKey(10),
          }));
          newUpCharge.addAdditionalDetails(deatilsWithNewObjectIds);
          updateUpCharge(newUpCharge);
        }}
        onPasteReplace={() => {
          const newUpCharge = upCharge.newInstance();
          newUpCharge.set('additionalDetails', value);
          updateUpCharge(newUpCharge);
        }}
        onClearAll={() => {
          const newUpCharge = upCharge.newInstance();
          newUpCharge.clearAdditionalDetails();
          updateUpCharge(newUpCharge);
        }}
      />
    );
    ContextMenu.show(menu, { left: e.clientX, top: e.clientY });
  };
  const onEditAdditionalDetails = () => {
    setAdditionalDetails({
      objects: upCharge.get('additionalDetails') || [],
      onClose: () => setAdditionalDetails(undefined),
      onSave: (value) => {
        const updatedItem = upCharge.newInstance();
        updatedItem.set('additionalDetails', value);
        updateUpCharge(updatedItem);
        setAdditionalDetails(undefined);
      },
      showNotAdded: true,
      isUpCharge: true,
    });
  };
  const onEditPlaceholders = () => {
    setPlaceholders({
      values: upCharge.get('placeholders'),
      onClose: () => setPlaceholders(undefined),
      onSave: (values) => {
        upCharge.set('placeholders', values);
        const updatedUpCharge = upCharge.newInstance();
        updateUpCharge(updatedUpCharge);
        setPlaceholders(undefined);
      },
      showNotAdded: true,
    });
  };
  const onSharedPriceChanged = (value, officeId) => {
    upCharge.setSharedPrice(value, officeId);
    const updatedUpCharge = upCharge.newInstance();
    updateUpCharge(updatedUpCharge);
  };
  const onUniquePriceChanged = (value, officeId) => {
    upCharge.setPrice(value, option.id, officeId);
    const updatedUpCharge = upCharge.newInstance();
    updateUpCharge(updatedUpCharge);
  };
  const onDelete = () => {
    const newItem = item.newInstance();
    newItem.removeUpCharge(upCharge.id);
    updateItem(newItem);
  };

  const revertNestedKey = async () => {
    const newUpCharge = upCharge.newInstance();
    const pgQuery = new Parse.Query('SSPriceGuideItem');
    const res = await pgQuery.get(upCharge.id);
    const newValues = res.get('accessoryPrices');
    newUpCharge.set('accessoryPrices', newValues);

    // eslint-disable-next-line no-underscore-dangle
    newUpCharge._clearPendingOps();
    updateUpCharge(newUpCharge);
  };
  const onRefresh = () => {
    const newUpCharge = upCharge.newInstance();
    revertNestedKey();
    newUpCharge.revert();
    updateUpCharge(newUpCharge);
  };
  const onEdit = () => {
    setEditUpCharge({
      upCharge,
      onClose: () => setEditUpCharge(undefined),
      onSave: (object) => {
        updateUpCharge(object);
        setEditUpCharge(undefined);
      },
    });
  };
  const onFileChanged = async (e) => {
    const newUpCharge = upCharge.newInstance();
    try {
      if (e.isNew) {
        newUpCharge.unset('image');
      } else {
        const file = await newFileFromEvent(e);
        newUpCharge.set('image', file);
      }
      updateUpCharge(newUpCharge);
    } catch (error) {
      handleErr(err);
    }
  };
  return (
    <div
      className={`priceguide__upcharge-row${isDisabled ? '_disabled' : ''}`}
      ref={draggableProvided.innerRef}
      {...draggableProvided.draggableProps}
    >
      <UpChargeCell width={35} columnKey="upChargeDisabled" borderLeft>
        <input
          type="checkbox"
          checked={!isDisabled}
          onChange={(e) => onDisabled(!e.target.checked)}
        />
      </UpChargeCell>
      <UpChargeCell width={35}>
        <div
          className="priceguide__drag-container"
          {...draggableProvided.dragHandleProps}
        >
          <i className="fas fa-grip-lines" />
        </div>
      </UpChargeCell>
      <UpChargeCell width={35} columnKey="save">
        <button
          className="priceguide__button"
          disabled={!upCharge.dirty()}
          onClick={onSaveClicked}
        >
          <i className="far fa-save" />
        </button>
      </UpChargeCell>
      <UpChargeCell width={35} columnKey="refresh">
        <button
          className="priceguide__button"
          disabled={!upCharge.dirty()}
          onClick={onRefresh}
        >
          <i className="fas fa-undo-alt" />
        </button>
      </UpChargeCell>
      <UpChargeCell width={35} columnKey="upChargeSelect">
        <input
          type="checkbox"
          checked={!!upCharge.isSelected}
          onChange={onSelect}
        />
      </UpChargeCell>
      <UpChargeCell width={35} columnKey="upChargeSettings">
        <button
          className="priceguide__button"
          onClick={onEdit}
          disabled={isDisabled}
        >
          <i className="fas fa-cog" />
        </button>
      </UpChargeCell>
      <UpChargeCell
        width={35}
        columnKey="upChargeAdditionalDetails"
        onContextMenu={onAdditionalDetailsRightClick}
      >
        <Popover
          content={
            <AdditionalDetailsPreview
              objects={upCharge.get('additionalDetails')}
            />
          }
          interactionKind={PopoverInteractionKind.HOVER}
          position={Position.RIGHT}
        >
          <button
            className="priceguide__button"
            onClick={onEditAdditionalDetails}
            disabled={isDisabled}
          >
            <i className="fas fa-keyboard" />
          </button>
        </Popover>
      </UpChargeCell>
      <UpChargeCell
        width={35}
        columnKey="upChargePlaceholders"
        onContextMenu={onPlaceholderRightClick}
      >
        <Popover
          content={
            <PlaceholdersPreview
              placeholders={upCharge.get('placeholders')}
              showNotAdded
            />
          }
          interactionKind={PopoverInteractionKind.HOVER}
          position={Position.RIGHT}
        >
          <button
            className="priceguide__button"
            onClick={onEditPlaceholders}
            disabled={isDisabled}
          >
            <i className="fas fa-retweet" />
          </button>
        </Popover>
      </UpChargeCell>
      <UpChargeCell width={35} columnKey="upChargeShowToggle">
        <input
          type="checkbox"
          checked={upCharge.get('shouldShowSwitch') === 1}
          onChange={(e) =>
            onChange('shouldShowSwitch', e.target.checked ? 1 : 0)
          }
          disabled={isDisabled}
        />
      </UpChargeCell>

      <UpChargeCell width={60} columnKey="upChargeImage">
        <ThumbnailButton
          imageURL={
            upCharge.has('image')
              ? upCharge.get('image').url()
              : '/images/no_image.png'
          }
          name={upCharge.get('name')}
          onFileChange={onFileChanged}
          disabled={isDisabled}
        />
      </UpChargeCell>
      <UpChargeCell
        value={upCharge.get('name')}
        columnKey="upChargeName"
        onChange={(e) => onChange('name', e.target.value)}
        disabled={isDisabled}
      />
      <UpChargeCell
        value={upCharge.get('info')}
        columnKey="upChargeNote"
        onChange={(e) => onChange('info', e.target.value)}
        disabled={isDisabled}
      />
      <UpChargeCell
        value={upCharge.get('measurementType')}
        columnKey="upChargeMeasurement"
        onChange={(e) => onChange('measurementType', e.target.value)}
        disabled={isDisabled}
      />
      <UpChargeCell
        value={upCharge.get('identifier')}
        columnKey="upChargeIdentifier"
        onChange={(e) => onChange('identifier', e.target.value)}
        disabled={isDisabled}
      />
      <UpChargeCell width={35} columnKey="upChargePercentagePrice">
        <input
          type="checkbox"
          checked={upCharge.get('percentagePrice')}
          onChange={(e) => onChange('percentagePrice', e.target.checked)}
          disabled={isDisabled}
        />
      </UpChargeCell>
      {includedOffices.map((officeId, index, array) => (
        <div
          className={`priceguide__upcharge-prices-container${
            index === 0 ? ' first' : ''
          }${index + 1 === array.length ? ' last' : ''}`}
          key={officeId}
        >
          <UpChargePriceCell
            value={
              upCharge.getSharedPrice(officeId) !== 0
                ? upCharge.getSharedPrice(officeId)
                : ''
            }
            onChange={(value) => onSharedPriceChanged(value, officeId)}
            calculatedTotal={upCharge.getSharedCalculatedTotal(
              option,
              officeId,
            )}
            disabled={isDisabled}
            showLink
            symbol={upCharge.get('percentagePrice') ? '%' : '$'}
            placeholder="0"
          />
          <UpChargePriceCell
            value={
              upCharge.getPrice(option.id, officeId)
                ? upCharge.getPrice(option.id, officeId)
                : ''
            }
            onChange={(value) => onUniquePriceChanged(value, officeId)}
            calculatedTotal={upCharge.getCalculatedTotal(option, officeId)}
            placeholder={upCharge.getSharedPrice(officeId)}
            disabled={isDisabled}
          />
        </div>
      ))}
      <UpChargeCell width={35} columnKey="trash">
        <button
          className="priceguide__button delete-upcharge"
          onClick={onDelete}
        >
          <i className="far fa-trash-alt" />
        </button>
      </UpChargeCell>
      {draggableProvided.placeholder}
    </div>
  );
};

UpChargeRow.propTypes = {
  updateUpCharge: PropTypes.func.isRequired,
  upCharge: PropTypes.instanceOf(UpCharge).isRequired,
  item: PropTypes.instanceOf(MeasureSheetItem).isRequired,
  setPlaceholders: PropTypes.func.isRequired,
  includedOffices: PropTypes.arrayOf(PropTypes.string),
  updateItem: PropTypes.func.isRequired,
  setEditUpCharge: PropTypes.func.isRequired,
};

UpChargeRow.defaultProps = {
  includedOffices: [],
};

const mapStateToProps = ({ priceGuide2 }) => ({
  includedOffices: priceGuide2.includedOffices,
});

const mapDispatchToProps = (dispatch) => ({
  updateUpCharge: (upCharge) => dispatch(setUpCharge(upCharge)),
  setAdditionalDetails: (additionalDetails) =>
    dispatch(setAdditionalDetailsEdit(additionalDetails)),
  setPlaceholders: (placeholders) =>
    dispatch(setPlaceholdersEdit(placeholders)),
  updateItem: (item) => dispatch(setItem(item)),
  setEditUpCharge: (object) => dispatch(setEditUpChargeModal(object)),
  handleErr: (error) => dispatch(handleError(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpChargeRow);
