/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/prop-types */
// Switch with dynamic styling
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Switch from 'react-switch';

const StyledSwitch = ({ checked, onChange, style, disabled }) => (
  <Switch
    checked={!!checked}
    disabled={disabled}
    onChange={onChange}
    uncheckedIcon={false}
    onColor={style.onColor}
    offColor={style.offColor || '#c0c0c0'}
  />
);

StyledSwitch.propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

StyledSwitch.defaultProps = {
  disabled: false,
  checked: false,
  style: {},
};

const mapStateToProps = ({ config: { styles = {} } }) => ({
  style: styles.Switch,
});

export default connect(mapStateToProps)(StyledSwitch);
