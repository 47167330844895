import { useEffect, useRef, useState } from 'react';
import Parse from 'parse';

// A hook to monitor the realtime login status of the user. Currently using a
// setInterval fn to poll the login status, because Parse does not fire events.
// Once the server is setup to use listener queries, this hook will be migrated
// to use an observable instead.
const useParseUser = () => {
  // Get the inital state of user, checking a current user exists and if they
  // are authenticated
  const initialUser = Parse.User.current();
  const initialStatus = initialUser && initialUser.authenticated();
  // The Ref allows us to access the state value in real time for the polling
  // function in useEffect. Because of React lifecycle, useEffect will always
  // see the state var as rendered, which is one "frame" behind. Refs exist
  // as mutable properties not affected by the renderer
  const loginRef = useRef();
  const [loggedIn, setLoggedIn] = useState(initialStatus);
  loginRef.current = loggedIn;
  const userRef = useRef();
  const [user, setUser] = useState(initialUser);
  userRef.current = user;

  useEffect(() => {
    // Creates a polling function that will run every second to check if there
    // is a current user, they are authenticated and then sets the state with
    // the returned value:
    // true - logged in
    // false - logged out
    // null - there is no user
    const getLoginStatus = () => {
      const currentUser = Parse.User.current();
      const isLoggedIn = currentUser && currentUser.authenticated();
      if (loginRef.current !== isLoggedIn) {
        setLoggedIn(isLoggedIn);
      }
      if (userRef.current !== currentUser) {
        setUser(currentUser);
      }
    };
    // Poll user every 1.5 seconds
    const intervalId = setInterval(getLoginStatus, 1500);

    // Our return function will clear the interval, stopping the polling, when
    // the component using the hook is dismounted
    return () => {
      clearInterval(intervalId);
    };
    // [] means this hook runs once at component mount
  }, []);

  return { loggedIn, user };
};

export default useParseUser;
