/**
 * This map defines the ordering and nesting of the admin side nav.
 * The keys of the link Maps will match the allowed paths returned from the
 * server. The related objects still have an anchor key so routing can be
 * changed client side in the future without requiring a patch to the server
 * @type {import("./menuTemplate").MenuMap}
 * */
export default new Map([
  [
    'leap_analytics',
    {
      anchor: 'leap_analytics',
      title: 'Leap Analytics',
      icon: 'fa fa-chevron-right',
    },
  ],
  [
    'admin-change-logs',
    {
      anchor: 'admin-change-logs',
      title: 'Admin Change Logs',
      icon: 'fa fa-chevron-right',
    },
  ],
  [
    'change_company',
    {
      anchor: 'change_company',
      title: 'Change Company',
      icon: 'fa fa-chevron-right',
    },
  ],
  [
    'new_company',
    {
      anchor: 'new_company',
      title: 'New Company',
      icon: 'fa fa-chevron-right',
    },
  ],
  [
    'shared_resources',
    {
      anchor: 'shared_resources',
      title: 'Shared Resources',
      icon: 'fa fa-chevron-right',
    },
  ],
  [
    'copy_price_guide',
    {
      anchor: 'copy_price_guide',
      title: 'Copy Price Guide',
      icon: 'fa fa-chevron-right',
    },
  ],
  [
    'copy_data',
    { anchor: 'copy_data', title: 'Copy Data', icon: 'fa fa-chevron-right' },
  ],
  [
    'email_usage_report',
    {
      anchor: 'email_usage_report',
      title: 'Email Usage Report',
      icon: 'fa fa-chevron-right',
    },
  ],
  [
    'copy_templates',
    {
      anchor: 'copy_templates',
      title: 'Copy Templates',
      icon: 'fa fa-chevron-right',
    },
  ],
  [
    'check_blacklist',
    {
      anchor: 'check_blacklist',
      title: 'Check Email Black list',
      icon: 'fa fa-chevron-right',
    },
  ],
  [
    'masquerade',
    { anchor: 'masquerade', title: 'Masquerade', icon: 'fa fa-chevron-right' },
  ],
]);
