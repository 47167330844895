import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import CellInputTypeSwitch from './CellInputTypeSwitch';
import { useCreditField } from '../../hooks/publicCreditAppForm';
import { randomKey } from '../../utils/utils';
/**
 * @typedef {import('../../Models/CreditApplicationObject').CreditApplicationCell} CreditApplicationCell
 */

const CreditAppCell = (props) => {
  const { fieldId } = props;

  const { onFieldUpdate, formData } = useCreditField(fieldId);

  const { enabled, title, errorMessage, required } = formData;
  return enabled ? (
    <Form.Group
      className={`${errorMessage ? 'danger' : ''} w-100`}
      key={randomKey()}
    >
      <Form.Label>
        {required ? '*' : ''}
        {title}
      </Form.Label>
      <CellInputTypeSwitch
        onChange={(update) => {
          onFieldUpdate({
            update: {
              ...formData,
              currentValue: update,
            },
            fieldId,
          });
        }}
        formData={formData}
      />
      {!!errorMessage && <span className="danger"> {errorMessage}</span>}
    </Form.Group>
  ) : (
    <></>
  );
};

CreditAppCell.propTypes = {
  fieldId: PropTypes.string.isRequired,
};

CreditAppCell.defaultProps = {};

export default CreditAppCell;
