/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import Select from '../Misc/Select';
import TitleButton from '../TitleButton';
import CategoriesDropDown from './CategoriesDropDown';
import SubCategoriesDropDown from './SubCategoriesDropDown';
import Switch from '../Misc/Switch';

import OfficesDropDown from '../IncludedOfficesDropDown';
import { startMassPriceChange } from '../../actions/priceGuide2';

const mathOptions = [
  {
    label: 'Multiply',
    value: '*',
  },
  {
    label: 'Divide',
    value: '/',
  },
  {
    label: 'Add',
    value: '+',
  },
  {
    label: 'Subtract',
    value: '-',
  },
];

class MassPriceChangeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: props.show,
      mathSymbol: mathOptions[0].value,
      categories: [],
      offices: [],
      amount: '',
      includeUpCharges: false,
    };
  }

  UNSAFE_componentWillReceiveProps({ show }) {
    this.setState({ show });
  }

  onMathSymbolChanged = ({ value }) => {
    this.setState({ mathSymbol: value });
  };

  onCategoriesChanged = (values) => {
    const categories = values;
    this.setState({ categories: [] }, () => this.setState({ categories }));
  };

  onSubCategoriesChanged = (values) => {
    const subCategories = values;
    this.setState({ subCategories });
  };

  onOfficesChanged = (offices) => {
    this.setState({ offices });
  };

  onAmountChanged = (amount) => {
    this.setState({ amount: parseFloat(amount) });
  };

  onSave = () => {
    const params = {
      subCategories: this.state.subCategories,
      categories: this.state.categories,
      offices: this.state.offices,
      mathSymbol: this.state.mathSymbol,
      amount: this.state.amount,
      includeUpCharges: this.state.includeUpCharges,
    };
    this.props.startMassPriceChange(params, this.props.onClose);
  };

  render() {
    return (
      <Modal show={this.state.show} onHide={this.props.onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Mass Price Change</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Select
            value={this.state.mathSymbol}
            options={mathOptions}
            isClearable={false}
            onChange={this.onMathSymbolChanged}
            closeMenuOnSelect
          />
          <div className="mass_price_change__input-pair">
            <label htmlFor="category-drop-down">all prices of</label>
            <CategoriesDropDown
              id="category-drop-down"
              onChange={this.onCategoriesChanged}
              value={this.state.categories}
              closeMenuOnSelect={false}
              isMulti
            />
          </div>
          {this.state.categories.length > 0 && (
            <div className="mass_price_change__input-pair">
              <label htmlFor="sub-category-drop-down">
                With sub-categories
              </label>
              <SubCategoriesDropDown
                id="sub-category-drop-down"
                selectedCategories={this.state.categories}
                onChange={this.onSubCategoriesChanged}
                value={this.state.subCategories}
                closeMenuOnSelect={false}
                isMulti
              />
            </div>
          )}
          {this.props.maxOfficeCount !== 1 && (
            <div className="mass_price_change__input-pair">
              <label htmlFor="office-drop-down">in</label>
              <OfficesDropDown
                id="office-drop-down"
                selected={this.state.offices}
                onChange={this.onOfficesChanged}
              />
            </div>
          )}
          <div className="mass_price_change__input-pair">
            {this.props.maxOfficeCount === 1 ? (
              <label htmlFor="amount-input">by</label>
            ) : (
              <label htmlFor="amount-input">
                {`office${this.state.offices.length > 1 ? 's' : ''} by`}
              </label>
            )}
            <input
              id="amount-input"
              type="number"
              onChange={(e) => this.onAmountChanged(e.target.value)}
              value={this.state.amount}
              placeholder="Amount"
              style={{
                width: '100%',
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="mass_price_change__footer">
            <div className="mass_price_change__include-up-charge-input-pair">
              <label htmlFor="include-up-charges-switch">
                Include Upcharges?
              </label>
              <Switch
                id="include-up-charges-switch"
                checked={this.state.includeUpCharges}
                onChange={(value) => {
                  this.setState({ includeUpCharges: value });
                }}
              />
            </div>
            <TitleButton variant="success" onClick={this.onSave} title="Save" />
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

MassPriceChangeModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  startMassPriceChange: PropTypes.func.isRequired,
  maxOfficeCount: PropTypes.number.isRequired,
};

MassPriceChangeModal.defaultProps = {
  show: false,
};

const mapStateToProps = ({ plan = {} }) => ({
  maxOfficeCount: plan.maxOfficeCount,
});

const mapDispatchToProps = (dispatch) => ({
  startMassPriceChange: (params, callback) =>
    dispatch(startMassPriceChange(params, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MassPriceChangeModal);
