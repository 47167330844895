/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import Parse from 'parse';
import { history } from '../../router';

const EsitmatesListSection = (props) => {
  const { items } = props;

  return (
    <div className="flex-1 list-section m-3">
      {items.map((customer, i) => {
        const id = `${customer.id}`;
        const estimate = customer.get('estimate');
        const createdBy = estimate.get('createdBy');
        const visibility = estimate.get('documentCount') ? 'visible' : 'hidden';
        return (
          <article
            id={id}
            key={id}
            onClick={() => {
              history.push(`/estimate_details/${id}`);
            }}
            className="email-estimate-list-item d-md-flex align-items-center list-line cursor-pointer"
          >
            <div className="flex-1">
              {!!customer && (
                <h1 className="list-line-header mb-10">
                  <span className="list-line-header">
                    {' '}
                    {customer.get('nameFirst')} {customer.get('nameLast')}
                  </span>
                  <span
                    style={{
                      display: customer.get('estimateName')
                        ? 'inline-block'
                        : 'none',
                    }}
                    className="list-line-action-item ml-10"
                  >
                    ({customer.get('estimateName')})
                  </span>
                </h1>
              )}
              {!!estimate && !!createdBy && (
                <p>
                  <span className="user-line-symbol">USER: </span>
                  <span className="user-line-name">
                    {`${createdBy.get('nameFirst')} ${createdBy.get(
                      'nameLast',
                    )}`}{' '}
                  </span>
                  <span className="user-line-date">
                    {' '}
                    {`${moment(estimate.get('createdAt')).format(
                      'MM/DD/YYYY',
                    )}`}
                  </span>
                </p>
              )}
            </div>
            <div
              className="d-flex flex-column align-items-center m-2"
              style={{ visibility }}
            >
              <i className="far fa-folder-open fa-5x" />
              <a className="list-line-action-item">
                Documents ({estimate ? estimate.get('documentCount') : ''}){' '}
              </a>
            </div>
          </article>
        );
      })}
    </div>
  );
};

EsitmatesListSection.propTypes = {
  items: PropTypes.arrayOf(PropTypes.instanceOf(Parse.Object)).isRequired,
};

const mapStateToProps = ({ estimatesPage }) => ({
  items: estimatesPage.items || [],
});

export default connect(mapStateToProps)(EsitmatesListSection);
