/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Menu, MenuItem, TextArea, Button } from 'leap-menu-item';
import {
  setInitialStateForTemplate,
  setShowLinkMeasureSheetItems,
} from '../../../../actions/templateEdit';
import YesNoMenuItem from '../YesNoMenuItem';
import ContractObject from '../../Models/ContractObject';
import Stepper from '../../Stepper';
import DebounceInputWithNet from '../DebounceInputWithNet';

const DetailCellMenu = ({
  object,
  contractObject,
  setTemplateState,
  setShowLinkItems,
  disableImageHeight,
}) => {
  const { linkedMeasureSheetItemIds = [] } = object;
  return (
    <>
      <MenuItem
        text="Image Size"
        shouldDismissPopover={false}
        labelElement={
          <Stepper
            value={object.imageHeight}
            minValue={0}
            maxValue={1000}
            increment={1}
            small
            onChange={(value) => {
              object.imageHeight = value;
              setTemplateState(new ContractObject(contractObject.toJSON()));
            }}
          />
        }
      />
      <Menu.Divider />
      <YesNoMenuItem
        text="Required"
        active={object.required}
        onChange={(value) => {
          object.required = value;
          setTemplateState(contractObject);
        }}
      />
      <Menu.Divider />

      <Menu.Divider />
      {!object.titleLinked && (
        <>
          <MenuItem
            text={
              <span>
                App Title
                <Button
                  small
                  minimal
                  icon="unlock"
                  onClick={() => {
                    object.titleLinked = true;
                    object.contractTitle = object.appTitle;
                    setTemplateState(contractObject);
                  }}
                />
              </span>
            }
          >
            <DebounceInputWithNet
              element={TextArea}
              minLength={0}
              debounceTimeout={300}
              value={object.appTitle}
              onChange={(e) => {
                object.appTitle = e.target.value;
                setTemplateState(contractObject);
              }}
            />
          </MenuItem>
          <MenuItem
            text={
              <span>
                PDF Title
                <Button
                  small
                  minimal
                  icon="unlock"
                  onClick={() => {
                    object.titleLinked = true;
                    object.appTitle = object.contractTitle;
                    setTemplateState(contractObject);
                  }}
                />
              </span>
            }
          >
            <DebounceInputWithNet
              element={TextArea}
              minLength={0}
              debounceTimeout={300}
              value={object.contractTitle}
              onChange={(e) => {
                object.contractTitle = e.target.value;
                setTemplateState(contractObject);
              }}
            />
          </MenuItem>
        </>
      )}
      {object.titleLinked && (
        <MenuItem
          text={
            <span>
              Title
              <Button
                small
                minimal
                icon="lock"
                onClick={() => {
                  object.titleLinked = false;
                  setTemplateState(contractObject);
                }}
              />
            </span>
          }
        >
          <DebounceInputWithNet
            element={TextArea}
            minLength={0}
            debounceTimeout={300}
            value={object.contractTitle}
            onChange={(e) => {
              object.contractTitle = e.target.value;
              setTemplateState(contractObject);
            }}
          />
        </MenuItem>
      )}
      <MenuItem text="App Note">
        <DebounceInputWithNet
          element={TextArea}
          minLength={0}
          debounceTimeout={300}
          value={object.appNote}
          onChange={(e) => {
            object.appNote = e.target.value;
            setTemplateState(contractObject);
          }}
        />
      </MenuItem>

      <Menu.Divider />
      <MenuItem
        text="Link Measure Sheet Item"
        label={linkedMeasureSheetItemIds.length || ''}
        onClick={() =>
          setShowLinkItems(
            true,
            'linkedMeasureSheetItem',
            true,
            'Linked Measure Sheet Item',
            true,
          )
        }
      />
    </>
  );
};

DetailCellMenu.propTypes = {
  object: PropTypes.any.isRequired,
  contractObject: PropTypes.instanceOf(ContractObject).isRequired,
  setTemplateState: PropTypes.func.isRequired,
  disableImageHeight: PropTypes.bool,
};

DetailCellMenu.defaultProps = {
  disableImageHeight: false,
};

const mapStateToProps = ({ templatesEdit = {} }) => ({
  templateValues: templatesEdit[templatesEdit.currentWorkingId],
  editCellItem: templatesEdit.editCellItem,
});

const mapDispatchToProps = (dispatch) => ({
  setShowLinkItems: (show, key, singleSelect, title, showCustomProduct) =>
    dispatch(
      setShowLinkMeasureSheetItems(
        show,
        key,
        singleSelect,
        title,
        showCustomProduct,
      ),
    ),
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailCellMenu);
