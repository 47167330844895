/* eslint-disable import/no-cycle */
import Parse from 'parse';
import { handleError } from './auth';
import { showLoader, hideLoader } from './loading';
import { getCustomersByName } from './estimatesPage';

export const setEmails = (emails) => ({
  type: 'SET_EMAILS',
  emails,
});

export const setEmailCount = (count) => ({
  type: 'SET_EMAIL_COUNT',
  count,
});

export const startFetchEmails = (
  limit,
  skip,
  startDate,
  endDate,
  userFilter,
  searchText,
) => async (dispatch, getState) => {
  try {
    dispatch(showLoader('Fetching Emails...'));

    const { auth } = getState();
    const { company } = auth;
    const query = new Parse.Query('EmailMessage');
    query.select([
      'attachment',
      'customer.nameFirst',
      'customer.nameLast',
      'subject',
      'status',
      'mgMessage.to',
      'user.nameFirst',
      'user.nameLast',
      'mailGunStatus',
    ]);
    query.greaterThanOrEqualTo('updatedAt', startDate.toDate());
    query.lessThanOrEqualTo('updatedAt', endDate.toDate());
    query.equalTo('company', company);
    if (searchText) {
      const filteredCustomers = await getCustomersByName({
        customerFilter: searchText,
        limit: 500, // limit to 500 since more than that should force the user to restrict their search more
      });
      query.containedIn('customer', filteredCustomers);
    }

    if (userFilter.length) {
      const usersFilter = userFilter.map((objectId) => {
        const user = new Parse.User();
        user.id = objectId;
        return user;
      });
      query.containedIn('user', usersFilter);
    }

    query.descending('updatedAt');
    query.skip(skip);
    query.limit(limit);
    const results = await query.find();
    await dispatch(
      setEmails(
        results.map((email) => {
          const json = email.toJSON();
          return {
            ...json,
            mgMessage: {
              ...json.mgMessage,
              to:
                typeof json.mgMessage.to === 'string'
                  ? json.mgMessage.to.split(',')
                  : json.mgMessage.to,
            },
          };
        }),
      ),
    );
    dispatch(hideLoader());
  } catch (e) {
    dispatch(handleError(e));
  } finally {
    dispatch(hideLoader());
  }
};

export const searchText = (text) => ({
  type: 'SET_EMAIL_SEARCH_TEXT',
  text,
});

export const setSearchText = (text) => (dispatch) => dispatch(searchText(text));

export const resetEmails = () => ({
  type: 'RESET_EMAILS',
});

// export const dateRange = (startDate, endDate) => ({
//   type: 'SET_EMAIL_DATE_RANGE',
//   startDate,
//   endDate,
// });

// export const setDateRange = (startDate, endDate) => (
//   dispatch => (
//     dispatch(dateRange(startDate, endDate))
//   )
// );
