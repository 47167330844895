import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import LeapActivate from './LeapActivate';
import ProSellActivate from './ProSellActivate';
import ProSellSignup from './ProSellSignup';

const NewAccountRoute = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/leap/activate`} component={LeapActivate} />
      <Route exact path={`${path}/prosell`} component={ProSellSignup} />
      <Route path={`${path}/prosell/activate`} component={ProSellActivate} />
    </Switch>
  );
};

export default NewAccountRoute;
