/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'react-bootstrap';

const ImagePopover = (props) => {
  const { url, name, onClear } = props;
  return (
    <Popover id="popover-trigger-hover-focus">
      <div style={{ maxWidth: '150px' }}>
        <img
          alt={name}
          src={url}
          style={{
            padding: '2px',
            width: 'auto',
            height: 'auto',
            maxWidth: '150px',
            maxHeight: '150px',
            objectFit: 'contain',
          }}
        />
        <button
          type="button"
          onClick={onClear}
          style={{
            background: 'transparent',
            border: 0,
            position: 'absolute',
            right: '0px',
            top: '0px',
          }}
        >
          <i className="fas fa-times-circle fa-2x danger" />
        </button>
      </div>
    </Popover>
  );
};

ImagePopover.propTypes = {
  url: PropTypes.string.isRequired,
  name: PropTypes.string,

  onClear: PropTypes.func.isRequired,
};

ImagePopover.defaultProps = {
  name: 'thumbnail',
};

export default ImagePopover;
