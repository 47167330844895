/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
// Select with dynamic styling
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Parse from 'parse';
import SelectComponent from 'react-select';
import { jsx, css } from '@emotion/core';
import { createStyleElement } from '../../utils/utils';

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? 'lightgreen'
        : isFocused
        ? 'lightblue'
        : null,
    };
  },
};

const Select = ({
  inTable,
  value,
  onChange,
  closeMenuOnSelect,
  options,
  isMulti,
  style = {},
  ...rest
}) => {
  let selectedValue = value;
  if (value && typeof value === 'string') {
    selectedValue = options.find((option) => option.value === value);
  }
  return (
    <div
      onMouseEnter={(event) => {
        if (inTable) {
          event.currentTarget.style.zIndex = 9999;
        }
      }}
      onMouseLeave={(event) => {
        if (inTable) {
          event.currentTarget.style.zIndex = style.zIndex || 1;
        }
      }}
      css={css`
        ${createStyleElement(style)}
      `}
    >
      <SelectComponent
        className="leap-select"
        styles={colourStyles}
        classNamePrefix="leap-select"
        value={selectedValue}
        onChange={onChange}
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
        options={options}
        {...rest}
      />
    </div>
  );
};

const dropDownGroupValuePropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.string,
  }),
]);

Select.propTypes = {
  value: PropTypes.oneOfType([
    dropDownGroupValuePropType,
    PropTypes.arrayOf(dropDownGroupValuePropType),
  ]),
  closeMenuOnSelect: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.instanceOf(Parse.Object),
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.any,
      }),
    ]),
  ).isRequired,
  isMulti: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

Select.defaultProps = {
  value: '',
  isMulti: false,
  closeMenuOnSelect: false,
};

const mapStateToProps = ({ config: { styles = {} } }) => ({
  style: styles.Select,
});

export default connect(mapStateToProps)(Select);
