/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import { resultsTableDropableSectionId } from './ResultsForms';

const ResultsDraggable = (props) => {
  const { children, index, draggableId } = props;

  return (
    <Draggable
      draggableId={draggableId}
      index={index}
      type={resultsTableDropableSectionId}
    >
      {(draggableProvided, snapshot) => {
        return (
          <div
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            {...draggableProvided.dragHandleProps}
            className="w-100 add-detail-block"
          >
            {children}
            {draggableProvided.placeholder}
          </div>
        );
      }}
    </Draggable>
  );
};

ResultsDraggable.propTypes = {
  draggableId: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  index: PropTypes.number.isRequired,
};

export default ResultsDraggable;
