/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import Parse from 'parse';
import PropTypes from 'prop-types';
import { Menu, MenuItem, Button, ButtonGroup } from 'leap-menu-item';
import AppToaster from '../../utils/AppToaster';
import { copyStringToClipboard } from '../../utils/Pasteboard';

const sortedPlaceholders = (objects = []) =>
  objects.sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

const combineAndSort = (...args) => {
  let results = [];
  args.forEach((obj) => {
    results = results.concat(obj);
  });
  results = results.filter(({ placeholder }) => placeholder);
  return sortedPlaceholders(results);
};

class MSIPlaceholderMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
      misc: [],
      items: [],
      options: [],
      upCharges: [],
    };
    this.fetchPlaceholders(props.itemIds);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      JSON.stringify(newProps.itemIds) !== JSON.stringify(this.props.itemIds)
    ) {
      this.fetchPlaceholders(newProps.itemIds);
    }
  }

  fetchPlaceholders = async (itemIds = []) => {
    try {
      if (!itemIds.length) {
        this.setState({
          misc: [],
          items: [],
          options: [],
          upCharges: [],
        });
        return;
      }
      const results = await Parse.Cloud.run(
        'getPlaceholdersForMeasureSheetItems',
        { objectIds: itemIds },
      );
      const {
        miscDefault,
        itemDefault,
        itemPlaceholders,
        itemAdditionalDetails,
        optionDefault,
        optionPlaceholders,
        upChargeDefault,
        upChargePlaceholders,
        upChargeAdditionalDetails,
      } = results;
      const updatedResults = {
        misc: combineAndSort(miscDefault),
        items: combineAndSort(
          itemDefault,
          itemPlaceholders,
          itemAdditionalDetails,
        ),
        options: combineAndSort(optionDefault, optionPlaceholders),
        upCharges: combineAndSort(
          upChargeDefault,
          upChargePlaceholders,
          upChargeAdditionalDetails,
        ),
      };
      this.setState(updatedResults);
    } catch (e) {
      AppToaster.show({ message: e.message, timeout: 5000 });
    }
  };

  render() {
    return (
      <MenuItem text="Linked Placeholders" disabled={this.props.disabled}>
        <ButtonGroup fill>
          <Button
            active={this.state.selectedIndex === 0}
            onClick={() => this.setState({ selectedIndex: 0 })}
          >
            Values
          </Button>
          <Button
            active={this.state.selectedIndex === 1}
            onClick={() => this.setState({ selectedIndex: 1 })}
          >
            Placeholders
          </Button>
        </ButtonGroup>
        <div
          style={{
            maxHeight: '90vh',
            maxWidth: '400px',
            overflowY: 'scroll',
            overflowX: 'clip',
          }}
        >
          {this.state.misc.length > 0 && (
            <>
              <Menu.Divider title="Misc" />
              {this.state.misc.map(({ placeholder, name }) => (
                <MenuItem
                  key={placeholder}
                  shouldDismissPopover={false}
                  text={this.state.selectedIndex === 1 ? placeholder : name}
                  onClick={() => {
                    copyStringToClipboard(placeholder);
                    AppToaster.show({
                      message: 'Copied to clipboard!',
                      timeout: 1000,
                    });
                  }}
                />
              ))}
            </>
          )}
          {this.state.items.length > 0 && (
            <>
              <Menu.Divider title="Item" />
              {this.state.items.map(({ placeholder, name }) => (
                <MenuItem
                  key={placeholder}
                  shouldDismissPopover={false}
                  text={
                    this.state.selectedIndex === 1
                      ? placeholder
                      : name || '(Blank Value)'
                  }
                  onClick={() => {
                    copyStringToClipboard(placeholder);
                    AppToaster.show({
                      message: 'Copied to clipboard!',
                      timeout: 1000,
                    });
                  }}
                />
              ))}
            </>
          )}
          {this.state.options.length > 0 && (
            <>
              <Menu.Divider title="Option" />
              {this.state.options.map(({ placeholder, name }) => (
                <MenuItem
                  key={placeholder}
                  shouldDismissPopover={false}
                  text={this.state.selectedIndex === 1 ? placeholder : name}
                  onClick={() => {
                    copyStringToClipboard(placeholder);
                    AppToaster.show({
                      message: 'Copied to clipboard!',
                      timeout: 1000,
                    });
                  }}
                />
              ))}
            </>
          )}
          {this.state.upCharges.length > 0 && (
            <>
              <Menu.Divider title="Up-Charge" />
              {this.state.upCharges.map(({ placeholder, name }) => (
                <MenuItem
                  key={placeholder}
                  shouldDismissPopover={false}
                  text={this.state.selectedIndex === 1 ? placeholder : name}
                  onClick={() => {
                    copyStringToClipboard(placeholder);
                    AppToaster.show({
                      message: 'Copied to clipboard!',
                      timeout: 1000,
                    });
                  }}
                />
              ))}
            </>
          )}
        </div>
      </MenuItem>
    );
  }
}

MSIPlaceholderMenu.propTypes = {
  itemIds: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
};

MSIPlaceholderMenu.defaultProps = {
  itemIds: [],
  disabled: false,
};

export default MSIPlaceholderMenu;
