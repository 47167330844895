// @ts-check

/** Typeguard to check if MenuMap value is a Link
 * @param {Link | ParentLink} link
 * @returns {link is Link}
 */
export const isLink = (link) =>
  !!link &&
  'anchor' in link &&
  typeof link.anchor === 'string' &&
  !('subLinks' in link);

/** Typeguard to check if MenuMap value is a ParentLink
 * @param {Link | ParentLink} plink
 * @returns {plink is ParentLink}
 */
export const isParentLink = (plink) =>
  !!plink &&
  'subLinks' in plink &&
  plink.subLinks instanceof Map &&
  !('anchor' in plink);

/** Function to convert the menu template map into an array for consuption by
 * React components
 * @param {MenuMap} mt */
export const mapTemplateToArr = (mt) =>
  [...mt.values()].map((l) =>
    isParentLink(l) ? { ...l, subLinks: [...l.subLinks.values()] } : l,
  );

/** This map defines the ordering and nesting of the side nav.
 * The keys of the link Maps will match the allowed paths returned from the
 * server. The related objects still have an anchor key so routing can be
 * changed client side in the future without requiring a patch to the server
 * @typedef {{title: string, icon: string, anchor: string}} Link
 * @typedef {Map<string, {title: string, anchor: string}>} ChildLinks
 * @typedef {{title: string, icon: string, subLinks: ChildLinks}} ParentLink
 * @typedef {Map<string, Link | ParentLink>} MenuMap
 *
 * @type {MenuMap}
 * */
export const menuTemplate = new Map([
  [
    'estimates',
    {
      title: 'Estimates',
      icon: 'fas fa-money-check-alt',
      anchor: 'estimates',
    },
  ],
  [
    'emails',
    {
      title: 'Emails',
      icon: 'fas fa-envelope',
      subLinks: new Map([
        [
          'email_usage_report',
          {
            title: 'Customer Emails',
            anchor: 'email_usage_report',
          },
        ],
        ['emails', { title: 'Emails', anchor: 'emails' }],
        [
          'email_links',
          {
            title: 'Email Links',
            anchor: 'email_links',
          },
        ],
      ]),
    },
  ],
  [
    'finance',
    {
      title: 'Finance',
      icon: 'fa fa-calculator',
      subLinks: new Map([
        [
          'finance_options',
          {
            title: 'Finance Options',
            anchor: 'finance_options',
          },
        ],
        [
          'credit_applications',
          {
            title: 'Credit Applications',
            anchor: 'credit_applications',
          },
        ],
        [
          'credit_app_upload',
          {
            title: 'Credit App Sending',
            anchor: 'credit_app_upload',
          },
        ],
        [
          'credit_app_sending',
          {
            title: 'Sendable Credit App',
            anchor: 'credit_app_sending',
          },
        ],
      ]),
    },
  ],
  [
    'resources',
    {
      title: 'Resources',
      icon: 'fa fa-th',
      anchor: 'resources',
    },
  ],
  [
    'price_guide',
    {
      title: 'Price Guide',
      icon: 'fa fa-dollar-sign',
      subLinks: new Map([
        [
          'price_guide',
          {
            title: 'Price Guide',
            anchor: 'price_guide',
          },
        ],
        [
          'categories',
          {
            title: 'Categories',
            anchor: 'categories',
          },
        ],
        [
          'packages',
          {
            title: 'Packages',
            anchor: 'packages',
          },
        ],
        [
          'price_formulas',
          { title: 'Price Formulas', anchor: 'price_formulas' },
        ],
      ]),
    },
  ],
  [
    'contracts',
    {
      title: 'Contracts',
      icon: 'fa fa-edit',
      subLinks: new Map([
        [
          'contract',
          {
            title: 'Contract Templates',
            anchor: 'templates/contract',
          },
        ],
        [
          'contract_sending',
          { title: 'Contract Sending', anchor: 'contract_sending' },
        ],
      ]),
    },
  ],
  [
    'proposals',
    {
      title: 'Proposals',
      icon: 'fa fa-file-alt',
      subLinks: new Map([
        [
          'proposal',
          {
            title: 'Proposal Templates',
            anchor: 'templates/proposal',
          },
        ],
        [
          'proposal_sending',
          { title: 'Proposal Sending', anchor: 'proposal_sending' },
        ],
      ]),
    },
  ],
  [
    'crm_integrations',
    {
      title: 'CRM Integrations',
      icon: 'fas fa-handshake',
      subLinks: new Map([
        [
          'jobnimbus',
          {
            title: 'JobNimbus',
            anchor: 'jobnimbus',
          },
        ],
        [
          'jobprogress',
          {
            title: 'JobProgress',
            anchor: 'jobprogress',
          },
        ],
        [
          'leadperfection',
          {
            title: 'LeadPerfection',
            anchor: 'leadperfection',
          },
        ],
        [
          'marketsharp',
          {
            title: 'MarketSharp',
            anchor: 'marketsharp',
          },
        ],
        [
          'salesforce',
          {
            title: 'Salesforce',
            anchor: 'salesforce',
          },
        ],
      ]),
    },
  ],
  [
    'finance_integrations',
    {
      title: 'Finance Integrations',
      icon: 'fas fa-hand-holding-usd',
      subLinks: new Map([
        [
          'amerifirst',
          {
            title: 'AmeriFirst',
            anchor: 'amerifirst',
          },
        ],
        [
          'aqua',
          {
            title: 'Aqua Finance',
            anchor: 'aqua',
          },
        ],
        ['dividend', { title: 'Dividend', anchor: 'dividend' }],
        [
          'enerBank',
          {
            title: 'EnerBank',
            anchor: 'enerBank',
          },
        ],
        [
          'fortiva',
          {
            title: 'Fortiva',
            anchor: 'fortiva',
          },
        ],
        [
          'foundation',
          {
            title: 'Foundation',
            anchor: 'foundation',
          },
        ],
        [
          'green_sky',
          {
            title: 'GreenSky',
            anchor: 'greensky',
          },
        ],
        [
          'lendkey',
          {
            title: 'LendKey',
            anchor: 'lendkey',
          },
        ],
        [
          'mosaic',
          {
            title: 'Mosaic',
            anchor: 'mosaic',
          },
        ],
        [
          'payment_capture',
          {
            title: 'Payment Capture',
            anchor: 'payment_capture',
          },
        ],
        [
          'powerPay',
          {
            title: 'PowerPay',
            anchor: 'powerPay',
          },
        ],
        [
          'pure',
          {
            title: 'Pure Finance',
            anchor: 'pure',
          },
        ],
        [
          'service_finance',
          {
            title: 'Service Finance',
            anchor: 'service_finance',
          },
        ],
        [
          'sunlight',
          {
            title: 'Sunlight',
            anchor: 'sunlight',
          },
        ],
      ]),
    },
  ],
  [
    'other_integrations',
    {
      title: 'Other Integrations',
      icon: 'fa fa-plug',
      subLinks: new Map([
        [
          'provia',
          {
            title: 'ProVia',
            anchor: 'provia',
          },
        ],
        [
          'hover',
          {
            title: 'HOVER',
            anchor: 'hover',
          },
        ],
        [
          'groupme',
          {
            title: 'GroupMe',
            anchor: 'groupme',
          },
        ],
        [
          'docusign',
          {
            title: 'Remote Signature Capture',
            anchor: 'docusign',
          },
        ],
        [
          'roofscope',
          {
            title: 'Scope Technologies',
            anchor: 'roofscope',
          },
        ],
        [
          'companyCam',
          {
            title: 'CompanyCam',
            anchor: 'companyCam',
          },
        ],
        [
          'gaf',
          {
            title: 'GAF QuickMeasure',
            anchor: 'gaf',
          },
        ],
        // [
        //   'zapier',
        //   {
        //     title: 'Zapier',
        //     anchor: 'zapier',
        //   },
        // ],
      ]),
    },
  ],
  [
    'company_settings',
    {
      title: 'Company Settings',
      icon: 'fa fa-cog',
      subLinks: new Map([
        [
          'company_logo',
          {
            title: 'Company Logo',
            anchor: 'company_logo',
          },
        ],
        [
          'watermark',
          {
            title: 'Watermark',
            anchor: 'watermark',
          },
        ],
        [
          'offices',
          {
            title: 'Offices',
            anchor: 'offices',
          },
        ],
        [
          'api_access',
          {
            title: 'API Access',
            anchor: 'api_access',
          },
        ],
        [
          'available_contacts',
          {
            title: 'Available Contacts',
            anchor: 'available_contacts',
          },
        ],
      ]),
    },
  ],
  [
    'app_settings',
    {
      title: 'App Settings',
      icon: 'fa fa-cog',
      subLinks: new Map([
        [
          'app_appearance',
          {
            title: 'Customize Menu',
            anchor: 'app_appearance',
          },
        ],
        ['appointments', { title: 'Appointments', anchor: 'appointments' }],
        [
          'commission_formulas',
          { title: 'Commission Formulas', anchor: 'commission_formulas' },
        ],
        [
          'custom_options',
          {
            title: 'Document Types',
            anchor: 'custom_options',
          },
        ],
        ['placeholders', { title: 'Placeholders', anchor: 'placeholders' }],
        [
          'report_sending',
          {
            title: 'Report Settings',
            anchor: 'report_sending',
          },
        ],
        [
          'results',
          {
            title: 'Results Sending',
            anchor: 'results',
          },
        ],
      ]),
    },
  ],
  [
    'account',
    {
      title: 'Account',
      icon: 'fas fa-wrench',
      subLinks: new Map([
        [
          'users',
          {
            title: 'Users',
            anchor: 'users',
          },
        ],
        [
          'new_user_defaults',
          {
            title: 'New User Defaults',
            anchor: 'new_user_defaults',
          },
        ],
        [
          'permissions',
          {
            title: 'Permissions',
            anchor: 'permissions',
          },
        ],
        [
          'account',
          {
            title: 'Info/Billing',
            anchor: 'account',
          },
        ],
        [
          'onboarding-status-report',
          {
            title: 'Status Report',
            anchor: 'onboarding-status-report',
          },
        ],
        [
          'event-log',
          {
            title: 'Event Logs',
            anchor: 'event-log',
          },
        ],
      ]),
    },
  ],
]);
