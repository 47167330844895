import React from 'react';
import PropTypes from 'prop-types';
import FileButton from './FileButton';
import PageButton from './PageButton';
import ViewSelectionButton from './ViewSelectionButton';
import Placeholders from './Placeholders';

const Toolbar = (props) => {
  const { urlParams } = props;
  return (
    <nav className="d-flex align-items-center px-2 h-100">
      <FileButton urlParams={urlParams} />
      <PageButton />
      <Placeholders />
      <ViewSelectionButton />
    </nav>
  );
};

Toolbar.propTypes = {
  urlParams: PropTypes.shape({
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
};
export default Toolbar;
