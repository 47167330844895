/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-shadow */
/* eslint-disable react/no-deprecated */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import { TextGroup } from '../FormGroup';
import { startUpdateConfig } from '../../actions/auth';
import Panel from '../Panel';
import TitleButton from '../TitleButton';
import Copy from '../AppSettings/Copy';
import { showDeleteConfirmation } from '../../actions/deleteConfirmation';

export class PowerPay extends React.Component {
  constructor(props) {
    super(props);
    const { powerPay = {} } = props;
    const { dealerCode = '' } = powerPay;
    this.state = { dealerCode };
  }

  componentDidMount() {
    const {
      setCrumbs,
      setButtons,
      location: { pathname },
    } = this.props;
    const crumb = { title: 'PowerPay', link: pathname };
    setCrumbs([crumb]);
    setButtons(
      <TitleButtons
        onClearClick={this.askForClearPermission}
        onCopyClicked={this.onCopyClicked}
        onSaveClicked={this.onSaveClicked}
      />,
    );
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { powerPay = {} } = newProps;
    const { dealerCode = '' } = powerPay;
    this.setState({ dealerCode });
  }

  componentWillUnmount() {
    const { setCrumbs, setButtons } = this.props;
    setCrumbs([]);
    setButtons();
  }

  askForClearPermission = () => {
    this.props.showDeleteConfirmation({
      message: 'Are you sure you want to clear this integration?',
      title: 'Clear Integration?',
      onConfirm: () => {
        this.onClearClick();
      },
    });
  };

  onClearClicked = () => {
    this.setState({ dealerCode: '' });
  };

  onDealerCodeChanged = (value) => {
    this.setState({ dealerCode: value });
  };

  onCopyClicked = () => {
    this.setState({ showCopy: true });
  };

  onSaveClicked = () => {
    const updates = { dealerCode: this.state.dealerCode };
    this.props.startUpdateConfig({ powerPay: updates });
  };

  render() {
    return (
      <>
        <Copy
          title="Copy PowerPay Settings"
          show={this.state.showCopy}
          warning="Warning! This will overwrite your current PowerPay settings"
          configKeys={['powerPay']}
          onClose={() => this.setState({ showCopy: false })}
        />
        <Panel title="PowerPay">
          <TextGroup
            title="Dealer Code"
            value={this.state.dealerCode}
            placeholder="Dealer Code"
            onChange={this.onDealerCodeChanged}
          />
        </Panel>
      </>
    );
  }
}

PowerPay.propTypes = {
  startUpdateConfig: PropTypes.func.isRequired,
  powerPay: PropTypes.shape({
    dealerCode: PropTypes.string,
  }),
  ...layoutContextPropTypes,
};

PowerPay.defaultProps = {
  powerPay: {
    dealerCode: '',
  },
};

const TitleButtons = ({
  onCopyClicked,
  onSaveClicked,
  onClearClicked,
  disableSave,
}) => (
  <>
    <TitleButton
      variant="warning"
      onClick={() => onClearClicked()}
      title="Clear"
    />
    <TitleButton
      variant="primary"
      onClick={() => onCopyClicked()}
      title="Copy"
    />
    <TitleButton
      disabled={disableSave}
      variant="success"
      onClick={() => onSaveClicked()}
      title="Save"
    />
  </>
);

TitleButtons.propTypes = {
  onCopyClicked: PropTypes.func.isRequired,
  onSaveClicked: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth: { config } }) => ({
  powerPay: config.powerPay,
});

const mapDispatchToProps = (dispatch) => ({
  startUpdateConfig: (updates) => dispatch(startUpdateConfig(updates)),
  showDeleteConfirmation: (params) => dispatch(showDeleteConfirmation(params)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(PowerPay),
);
