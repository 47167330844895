/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import FormGroup, { TextGroup } from '../FormGroup';
import OfficesDropDown from '../IncludedOfficesDropDown';
import { startDownloadSoftLite } from '../../actions/priceGuide2';

class SoftLiteModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: props.show,
      customerNumber: '',
      username: '',
      password: '',
      offices: [],
    };
  }

  UNSAFE_componentWillReceiveProps({ show }) {
    this.setState({ show });
  }

  /**
   * Checks form validity before submiting
   * @param {React.FormEvent<HTMLFormElement>} event
   */
  onSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity()) {
      const params = {
        customer: this.state.customerNumber,
        username: this.state.username,
        pswd: this.state.password,
        offices: this.state.offices,
      };
      this.props.startDownloadSoftLite(params, this.props.onClose);
    }
  };

  /**
   * Validate input on blur
   * @param {React.FocusEvent<HTMLInputElement>} event
   */
  onBlur = (event) => {
    const element = event.currentTarget;
    element.reportValidity();
  };

  render() {
    return (
      <Modal show={this.state.show} onHide={this.props.onClose}>
        <Form onSubmit={this.onSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Download Soft-Lite</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TextGroup
              title="Customer #"
              value={this.state.customerNumber}
              onChange={(customerNumber) => this.setState({ customerNumber })}
              required
            />
            <TextGroup
              title="Soft-Lite Username"
              value={this.state.username}
              onChange={(username) => this.setState({ username })}
              required
            />
            <TextGroup
              title="Soft-Lite Password"
              value={this.state.password}
              onChange={(password) => this.setState({ password })}
              required
            />
            <FormGroup title="Offices">
              <OfficesDropDown
                selected={this.state.offices}
                onChange={(offices) => this.setState({ offices })}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" type="submit">
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

SoftLiteModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  startDownloadSoftLite: PropTypes.func.isRequired,
};

SoftLiteModal.defaultProps = {
  show: false,
};

const mapDispatchToProps = (dispatch) => ({
  startDownloadSoftLite: (params, callback) =>
    dispatch(startDownloadSoftLite(params, callback)),
});

export default connect(undefined, mapDispatchToProps)(SoftLiteModal);
