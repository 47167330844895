/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Position, Tooltip } from '@blueprintjs/core';

const FormulaTipButton = (props) => (
  <Tooltip position={Position.BOTTOM}>
    <Button
      onClick={props.onClick}
      style={{ margin: 5, backgroundColor: props.color, border: 0 }}
      variant="success"
    >
      {props.symbol}
    </Button>
    <div style={{ padding: 0 }}>
      <span>
        <b>{props.info}</b>
      </span>
    </div>
  </Tooltip>
);

FormulaTipButton.propTypes = {
  info: PropTypes.string.isRequired,
  symbol: PropTypes.string.isRequired,
  color: PropTypes.string,
};

FormulaTipButton.defaultProps = {};
export default FormulaTipButton;
