import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Parse from 'parse';
import HeaderCell from './HeaderCell';
import ExpandAllCell from '../ExpandAllCell';
import Sticky from '../Sticky';

const OptionHeader = ({
  onSaveAll,
  onSelectAll,
  isAllSelected,
  isSaveAllDisabled,
  includedOffices,
  offices,
  onExpandAll,
  expandAllDisabled,
  isAllExpanded,
  onDeleteAll,
  onRefresh,
  isRefreshDisabled,
  itemId,
}) => (
  <Sticky
    scrollElementId="priceguide__index"
    stickTo={{
      type: 'group',
      value: 'blue-item',
    }}
    stackOrder={2}
    uid={`${itemId}-green-header`}
    bumpType="green"
  >
    <div id={itemId} className="priceguide__option_header-container">
      <ExpandAllCell
        onClick={() => onExpandAll(!isAllExpanded)}
        isOpen={isAllExpanded}
        disabled={expandAllDisabled}
        type="option-header"
      />
      <HeaderCell disableResize>
        <div />
      </HeaderCell>
      <HeaderCell columnKey="save" disableResize>
        <button
          type="button"
          className="priceguide__button"
          onClick={onSaveAll}
          disabled={isSaveAllDisabled}
        >
          <i className="far fa-save" />
        </button>
      </HeaderCell>
      <HeaderCell disableResize>
        <button
          type="button"
          className="priceguide__button"
          onClick={onRefresh}
          disabled={isRefreshDisabled}
        >
          <i className="fas fa-undo-alt" />
        </button>
      </HeaderCell>
      <HeaderCell columnKey="optionSelect" disableResize>
        <input
          type="checkbox"
          checked={isAllSelected}
          onChange={(e) => onSelectAll(e.target.checked)}
        />
      </HeaderCell>
      <HeaderCell columnKey="settings" disableResize>
        <div />
      </HeaderCell>
      <HeaderCell columnKey="additionalDetails" disableResize>
        <div />
      </HeaderCell>
      <HeaderCell columnKey="optionPlaceholders" disableResize>
        <i className="fas fa-retweet" />
      </HeaderCell>
      <HeaderCell columnKey="showToggle" disableResize>
        <div />
      </HeaderCell>
      <HeaderCell
        columnKey="image"
        disableResize
        style={{ minWidth: 60, maxWidth: 60 }}
      >
        <div />
      </HeaderCell>
      <HeaderCell columnKey="optionBrand">Brand</HeaderCell>
      <HeaderCell columnKey="optionName">Name</HeaderCell>
      {includedOffices.length > 0 && (
        <HeaderCell
          columnKey="optionPrices"
          disableResize
          style={{
            width: 'auto',
            minWidth: 'unset',
            maxWidth: 'unset',
            border: 0,
          }}
        >
          <div className="priceguide__office-name-container">
            {includedOffices.map((officeId) => (
              <div className="priceguide__office-name-option" key={officeId}>
                {offices.find(({ id }) => officeId === id)?.get('name') || ''}
              </div>
            ))}
          </div>
        </HeaderCell>
      )}
      <HeaderCell columnKey="delete" disableResize>
        <button
          type="button"
          className="priceguide__button"
          onClick={onDeleteAll}
        >
          <i className="far fa-trash-alt" />
        </button>
      </HeaderCell>
    </div>
  </Sticky>
);

OptionHeader.propTypes = {
  itemId: PropTypes.string.isRequired,
  onSaveAll: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  isAllSelected: PropTypes.bool,
  isSaveAllDisabled: PropTypes.bool,
  includedOffices: PropTypes.arrayOf(PropTypes.string),
  offices: PropTypes.arrayOf(PropTypes.instanceOf(Parse.Object)),
  onExpandAll: PropTypes.func.isRequired,
  expandAllDisabled: PropTypes.bool,
  isAllExpanded: PropTypes.bool,
  onDeleteAll: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  isRefreshDisabled: PropTypes.bool,
};

OptionHeader.defaultProps = {
  isAllSelected: false,
  isSaveAllDisabled: true,
  includedOffices: [],
  offices: [],
  expandAllDisabled: false,
  isAllExpanded: false,
  isRefreshDisabled: false,
};

const mapStateToProps = ({ priceGuide2, auth }) => ({
  includedOffices: priceGuide2.includedOffices,
  offices: auth.offices,
});

export default connect(mapStateToProps)(OptionHeader);
