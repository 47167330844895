/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Menu, MenuItem, TextArea, Button } from 'leap-menu-item';
import { DebounceInput } from 'react-debounce-input';
import {
  setInitialStateForTemplate,
  setShowLinkMeasureSheetItems,
} from '../../../../actions/templateEdit';
import BodyCellItem from '../../Models/BodyCellItem';
import YesNoMenuItem from '../YesNoMenuItem';
import ContractObject from '../../Models/ContractObject';
import PickerOptionsEditor from '../../PickerOptionsEditor';
import FormulaEditor from './FormulaEditor';
import DynamicInputEditor from './DynamicInputEditor';
import MSIPlaceholderMenu from '../../MSIPlaceholderMenu';
import { dateTimePlaceHolders } from '../../../../utils/utils';
import InitialsRequired from './InitialsRequired';
import DebounceInputWithNet from '../DebounceInputWithNet';
import SizeInputWidget from './SizeInputWidget';
import LinkedCellMenu from './LinkedCellMenu';

const shouldDisplayPickerOptions = ({ cellType, inputType }) => {
  const validCellTypes = ['textShort', 'textLong', 'textXLong'];
  const validInputTypes = ['picker', 'multiSelectPicker'];
  return (
    validCellTypes.indexOf(cellType) > -1 &&
    validInputTypes.indexOf(inputType) > -1
  );
};

const dateTimeLabelSwtich = (object) => {
  const { inputType } = object;

  switch (inputType) {
    case 'timePicker':
      return 'Time Format';

    default:
      return 'Date Format';
  }
};

const filterInputTypes = (object, allTypes) => {
  const { cellType } = object;

  switch (cellType) {
    case 'textXLong':
      return ['default', 'textFormula'];

    default:
      return allTypes;
  }
};
class TextMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { slideOutIsOpen: false, slideOutKey: '' };
  }

  render() {
    const {
      object,
      contractObject,
      setTemplateState,
      setShowLinkItems,
      showDocLinking,
    } = this.props;
    const inputTypes = filterInputTypes(object, BodyCellItem.inputTypes);

    return (
      <>
        {object.cellType !== 'switched' && (
          <MenuItem
            text="Input Type"
            label={BodyCellItem.inputTypeLabelForInputType(object.inputType)}
          >
            {inputTypes.map((inputType) => (
              <MenuItem
                key={inputType}
                text={BodyCellItem.inputTypeLabelForInputType(inputType)}
                shouldDismissPopover={false}
                active={object.inputType === inputType}
                icon={object.inputType === inputType ? 'dot' : 'blank'}
                onClick={() => {
                  object.inputType = inputType;
                  setTemplateState(contractObject);
                }}
              />
            ))}
          </MenuItem>
        )}
        {(object.inputType === 'datePicker' ||
          object.inputType === 'dateTimePicker' ||
          object.inputType === 'timePicker') && (
          <MenuItem
            text={dateTimeLabelSwtich(object)}
            label={object.dateDisplayFormat}
          >
            <DebounceInput
              minLength={0}
              placeholder={dateTimePlaceHolders[object.inputType]}
              debounceTimeout={300}
              value={object.dateDisplayFormat}
              onChange={(e) => {
                object.dateDisplayFormat = e.target.value;
                setTemplateState(contractObject);
              }}
            />
          </MenuItem>
        )}
        {shouldDisplayPickerOptions(object) && (
          <PickerOptionsEditor
            values={object.pickerValues}
            onChange={(values) => {
              object.pickerValues = values;
              setTemplateState(contractObject);
            }}
            menuItemProps={{
              text: 'Picker Options',
              label: object.pickerValues ? object.pickerValues.length : '0',
              shouldDismissPopover: false,
            }}
          />
        )}
        {object.inputType === 'multiSelectPicker' && (
          <MenuItem
            text="Multi-Select Separator"
            label={object.multiSelectorSeparator}
          >
            <DebounceInput
              minLength={0}
              debounceTimeout={300}
              value={object.multiSelectorSeparator}
              placeholder={'i.e. ", "'}
              onChange={(e) => {
                object.multiSelectorSeparator = e.target.value;
                setTemplateState(contractObject);
              }}
            />
          </MenuItem>
        )}
        {(object.inputType === 'sizePicker' ||
          object.inputType === '3DSizePicker') && (
          <MenuItem
            text="Fraction Digits"
            label={BodyCellItem.fractionDigitsLabelForFractionDigits(
              object.fractionDigits,
            )}
          >
            {BodyCellItem.fractionDigits.map((fractionDigit) => (
              <MenuItem
                key={fractionDigit}
                text={BodyCellItem.fractionDigitsLabelForFractionDigits(
                  fractionDigit,
                )}
                shouldDismissPopover={false}
                active={object.fractionDigits === fractionDigit}
                icon={object.fractionDigits === fractionDigit ? 'dot' : 'blank'}
                onClick={() => {
                  object.fractionDigits = fractionDigit;
                  setTemplateState(contractObject);
                }}
              />
            ))}
          </MenuItem>
        )}
        {(object.inputType === 'unitedInchPicker' ||
          object.inputType === 'sizePicker' ||
          object.inputType === '3DSizePicker') && (
          <SizeInputWidget
            object={object}
            setTemplateState={setTemplateState}
            contractObject={contractObject}
          />
        )}

        {object.cellType === 'switched' && (
          <YesNoMenuItem
            text="Dynamic"
            active={object.inputType === 'dynamic'}
            onChange={(value) => {
              object.inputType = value ? 'dynamic' : '';
              setTemplateState(contractObject);
            }}
          />
        )}
        {object.inputType === 'dynamic' && (
          <DynamicInputEditor
            contractObject={contractObject}
            cellItem={object}
            onChange={(value) => {
              object.dynamicInputType = value;
              setTemplateState(contractObject);
            }}
          />
        )}
        {object.isCurrencyCell && (
          <YesNoMenuItem
            text="Is Total Sale Amount"
            active={object.isTotalSaleAmount}
            onChange={(value) => {
              object.isTotalSaleAmount = value;
              setTemplateState(contractObject);
            }}
          />
        )}
        <Menu.Divider />
        {object.cellType === 'switched' && (
          <MenuItem
            text="On Value"
            shouldDismissPopover={false}
            label={object.switchValueOn}
          >
            <DebounceInput
              minLength={0}
              debounceTimeout={300}
              value={object.switchValueOn}
              placeholder="YES"
              onChange={(e) => {
                object.switchValueOn = e.target.value;
                setTemplateState(contractObject);
              }}
            />
          </MenuItem>
        )}
        {object.cellType === 'switched' && (
          <MenuItem
            text="Off Value"
            shouldDismissPopover={false}
            label={object.switchValueOff}
          >
            <DebounceInput
              minLength={0}
              debounceTimeout={300}
              value={object.switchValueOff}
              placeholder="NO"
              onChange={(e) => {
                object.switchValueOff = e.target.value;
                setTemplateState(contractObject);
              }}
            />
          </MenuItem>
        )}
        {object.cellType === 'switched' && <Menu.Divider />}
        <YesNoMenuItem
          text="Required"
          active={object.required}
          onChange={(value) => {
            object.required = value;
            setTemplateState(contractObject);
          }}
        />
        <YesNoMenuItem
          text="Hide In App"
          active={object.hidden}
          onChange={(value) => {
            object.hidden = value;
            setTemplateState(contractObject);
          }}
        />
        <YesNoMenuItem
          text="Hide On PDF"
          active={object.appOnly}
          onChange={(value) => {
            object.appOnly = value;
            setTemplateState(contractObject);
          }}
        />
        <YesNoMenuItem
          text="Disable Editing"
          active={object.inputDisabled}
          onChange={(value) => {
            object.inputDisabled = value;
            setTemplateState(contractObject);
          }}
        />
        <Menu.Divider />
        {!object.titleLinked && (
          <>
            <MenuItem
              text={
                <span>
                  App Title
                  <Button
                    small
                    minimal
                    icon="unlock"
                    onClick={() => {
                      object.titleLinked = true;
                      object.contractTitle = object.appTitle;
                      setTemplateState(contractObject);
                    }}
                  />
                </span>
              }
            >
              <DebounceInputWithNet
                element={TextArea}
                minLength={0}
                debounceTimeout={300}
                value={object.appTitle}
                onChange={(e) => {
                  object.appTitle = e.target.value;
                  setTemplateState(contractObject);
                }}
              />
            </MenuItem>
            <MenuItem
              text={
                <span>
                  PDF Title
                  <Button
                    small
                    minimal
                    icon="unlock"
                    onClick={() => {
                      object.titleLinked = true;
                      object.appTitle = object.contractTitle;
                      setTemplateState(contractObject);
                    }}
                  />
                </span>
              }
            >
              <DebounceInputWithNet
                element={TextArea}
                minLength={0}
                debounceTimeout={300}
                value={object.contractTitle}
                onChange={(e) => {
                  object.contractTitle = e.target.value;
                  setTemplateState(contractObject);
                }}
              />
            </MenuItem>
          </>
        )}
        {object.titleLinked && (
          <MenuItem
            text={
              <span>
                Title
                <Button
                  small
                  minimal
                  icon="lock"
                  onClick={() => {
                    object.titleLinked = false;
                    setTemplateState(contractObject);
                  }}
                />
              </span>
            }
          >
            <DebounceInputWithNet
              element={TextArea}
              minLength={0}
              debounceTimeout={300}
              value={object.contractTitle}
              onChange={(e) => {
                object.contractTitle = e.target.value;
                setTemplateState(contractObject);
              }}
            />
          </MenuItem>
        )}
        <MenuItem text="App Note">
          <DebounceInputWithNet
            element={TextArea}
            minLength={0}
            debounceTimeout={300}
            value={object.appNote}
            onChange={(e) => {
              object.appNote = e.target.value;
              setTemplateState(contractObject);
            }}
          />
        </MenuItem>
        <Menu.Divider />
        {object.isFormulaCell && (
          <>
            <FormulaEditor
              contractObject={contractObject}
              value={object.formula}
              onChange={(value) => {
                object.formula = value;
                setTemplateState(contractObject);
              }}
              excluding={object.cellId}
            />
            <YesNoMenuItem
              text="Clear Value If Zero"
              active={!!object.clearValueIfZero}
              onChange={(value) => {
                object.clearValueIfZero = value;
                setTemplateState(contractObject);
              }}
            />
          </>
        )}
        {!object.isFormulaCell &&
          (object.inputType === 'textFormula' ? (
            <FormulaEditor
              title="Default Value"
              contractObject={contractObject}
              value={object.value}
              onChange={(value) => {
                object.value = value;
                setTemplateState(contractObject);
              }}
              excluding={object.cellId}
            />
          ) : (
            <MenuItem text="Default Value">
              <DebounceInputWithNet
                element={TextArea}
                minLength={0}
                debounceTimeout={300}
                value={object.value}
                onChange={(e) => {
                  object.value = e.target.value;
                  setTemplateState(contractObject);
                }}
              />
            </MenuItem>
          ))}
        <YesNoMenuItem
          text="Hide If Left Blank"
          active={!object.drawIfEmpty}
          onChange={(value) => {
            object.drawIfEmpty = !value;
            setTemplateState(contractObject);
          }}
        />
        <MenuItem text="Value If Left Blank" disabled={!object.drawIfEmpty}>
          <DebounceInputWithNet
            element={TextArea}
            minLength={0}
            debounceTimeout={300}
            value={object.emptyValue}
            onChange={(e) => {
              object.emptyValue = e.target.value;
              setTemplateState(contractObject);
            }}
          />
        </MenuItem>
        <InitialsRequired
          object={object}
          setTemplateState={setTemplateState}
          contractObject={contractObject}
        />
        <Menu.Divider />
        {object.cellType !== 'switched' && showDocLinking && (
          <LinkedCellMenu
            value={object.linkedValueSettings}
            onChange={({ objectId, overwritePriority }) => {
              object.linkedValueSettings = {
                objectId,
                overwritePriority,
              };
              setTemplateState(contractObject);
            }}
          />
        )}
        <MenuItem
          text="Link Measure Sheet Item"
          onClick={() =>
            setShowLinkItems(
              true,
              'linkedMeasureSheetItem',
              true,
              'Link Measure Sheet Item',
            )
          }
          label={object.linkedMeasureSheetItem ? 'Linked' : ''}
        />
        <MSIPlaceholderMenu
          itemIds={[object.linkedMeasureSheetItem]}
          disabled={!object.linkedMeasureSheetItem}
        />
        <MenuItem
          text="Link Quantities"
          onClick={() =>
            setShowLinkItems(
              true,
              'measureSheetItemQuantityIds',
              false,
              'Link Quantities',
            )
          }
          label={
            object.measureSheetItemQuantityIds
              ? object.measureSheetItemQuantityIds.length
              : ''
          }
        />
        <MenuItem
          text="Link Totals"
          onClick={() =>
            setShowLinkItems(
              true,
              'measueSheetItemTotalIds',
              false,
              'Link Totals',
            )
          }
          label={
            object.measueSheetItemTotalIds
              ? object.measueSheetItemTotalIds.length
              : ''
          }
        />
      </>
    );
  }
}

TextMenu.propTypes = {
  object: PropTypes.any.isRequired,
  contractObject: PropTypes.instanceOf(ContractObject).isRequired,
  setTemplateState: PropTypes.func.isRequired,
  secureCaptureEnabled: PropTypes.bool,
  setShowLinkItems: PropTypes.func.isRequired,
  showDocLinking: PropTypes.bool,
};

TextMenu.defaultProps = {
  secureCaptureEnabled: false,
  showDocLinking: false,
};

const mapStateToProps = ({ templatesEdit = {}, auth }) => ({
  templateValues: templatesEdit[templatesEdit.currentWorkingId],
  editCellItem: templatesEdit.editCellItem,
  secureCaptureEnabled: auth.company.get('spreedlyEnabled'),
});

const mapDispatchToProps = (dispatch) => ({
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
  setShowLinkItems: (show, key, singleSelect, title) =>
    dispatch(setShowLinkMeasureSheetItems(show, key, singleSelect, title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TextMenu);
