/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
/* eslint-disable no-param-reassign */
/* eslint-disable import/named */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Parse from 'parse';
import { connect } from 'react-redux';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Button } from 'leap-menu-item';
import Dropzone from 'react-dropzone';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import HeaderContainer from './Header/HeaderContainer';
import BodyContainer from './Body/BodyContainer';
import SignatureContainer from './Signature/SignatureContainer';
import {
  setInitialStateForTemplate,
  setCurrentWorkingTemplate,
  setEditCellItem,
  setShowLinkMeasureSheetItems,
  onTemplateScroll,
} from '../../actions/templateEdit';
import TemplateScrollContext from './TemplateScrollContext';
import Toolbar from './Toolbar/Toolbar';
import BodyTitleToolbar from './Body/BodyTitleToolbar';
import DocumentTree from './DocumentTree';
import ContractObject from './Models/ContractObject';
import BodySectionObject from './Models/BodySection';
import HeaderSectionObject from './Models/HeaderSection';
import SignatureSectionObject from './Models/SignatureSection';
import MeasureSheetItemSlideOutPanel from './MeasureSheetItemSlideOutPanel';
import { setOnDragEndCallback } from '../DropContext';
import AppToaster from '../../utils/AppToaster';
import CellToolDrawer from './CellToolDrawer';
import AppView from './AppView/AppView';
import { getListStyle } from './Utils';
import { newFileFromFile } from '../../utils/utils';
import { pushToDataLayer } from '../../actions/tagManager';
import Well from '../SharedComponents/Well';

const componentForGroup = (group) => {
  switch (group.groupType) {
    case 'header':
      return <HeaderContainer section={group} />;
    case 'body':
      return <BodyContainer section={group} />;
    case 'signature':
      return (
        <SignatureContainer data={group.data} parentPath={group.getPath()} />
      );
    default:
      return <div>{group.groupType}</div>;
  }
};

const pageStyle = (
  {
    pageSize = '612,792',
    wMargin = 25,
    hMargin = 35,
    // eslint-disable-next-line no-unused-vars
  },
  scale = 1.0,
) => {
  const [width, height] = pageSize.split(',');
  return {
    backgroundColor: 'white',
    minHeight: `${height}px`,
    padding: `${hMargin}px ${wMargin}px`,
    position: 'relative',
    minWidth: `${width}px`,
    maxWidth: `${width}px`,
    // transform: `scale(${scale})`,
    // transformOrigin: '0 0',
  };
};

const toolbarForCellItem = (cellItem = {}) => {
  switch (cellItem.toolbarKey) {
    case 'sectionTitle':
      return <BodyTitleToolbar object={cellItem} />;
    default:
      return null;
  }
};

export const mainTemplatesRef = React.createRef();

export const templateScrollContext = new TemplateScrollContext();

const checkMenus = () => {
  setTimeout(() => {
    const menus = [...document.getElementsByClassName('bp3-menu')];
    menus.forEach((menu) => {
      const { parentNode } = menu;
      const grandParent = parentNode.parentNode;
      const grandClass = grandParent.className;
      if (grandClass.indexOf('bp3-submenu') === -1) {
        const maxHeight = `${window.innerHeight - 50}px`;
        menu.style.maxHeight = maxHeight;
      }
    });
  }, 500);
};

class TemplateEdit extends React.Component {
  UNSAFE_componentWillMount() {
    const { id, type } = this.props.match.params;
    if (id === 'new') {
      const templateId = `${this.props.match.params.type}_new`;
      const object = new ContractObject();
      object.type = type;
      object.objectId = templateId;
      object.includedOffices = [...this.props.offices];
      this.props.setInitialState(object);
      return;
    }
    const { template = {} } = this.props;
    if (template.objectId !== id) {
      const fetchTemplate = async () => {
        const object = new Parse.Object('ContractObject');
        object.id = id;
        await object.fetch();
        // includedOffices should always be pointers, otherwise toJSON() keeps all data including CustomConfig data
        // and that can take up too much memory per object
        const includedOffices = object.get('includedOffices') || [];
        object.set(
          'includedOffices',
          includedOffices.map((office) => {
            if (office instanceof Parse.Object) {
              return office.toPointer();
            }
            return office;
          }),
        );
        this.props.setInitialState(new ContractObject(object.toJSON()));
      };
      fetchTemplate();
    }
    if (
      !this.props.currentWorkingId ||
      this.props.currentWorkingId !== this.props.match.params.id
    ) {
      this.props.setCurrentWorkingTemplate(this.props.match.params.id);
    }
  }

  componentDidMount() {
    const { setToolbar, match = {} } = this.props;
    const { params } = match;

    setToolbar(<Toolbar urlParams={params} />);
    this.updateOnDragEndCallback();
  }

  componentDidUpdate(prevProps) {
    this.updateOnDragEndCallback();
  }

  componentWillUnmount() {
    const { setToolbar } = this.props;

    setToolbar(null);
  }

  onPDFFileChanged = async (e) => {
    try {
      const { template, setInitialState } = this.props;
      const updatedTemplate = new ContractObject(template.toJSON());
      const file = await newFileFromFile(e[0], 'document', 'template_pdf');
      updatedTemplate.pdf = file;
      setInitialState(updatedTemplate);
    } catch (err) {
      AppToaster.show({ message: err.message, timeout: 5000 });
    }
  };

  updateOnDragEndCallback = () => {
    const { template, setInitialState, setEditCellItem } = this.props;
    if (!template) {
      return;
    }
    setOnDragEndCallback(`${template.objectId}-root`, (result) => {
      const { source, destination, draggableId } = result;
      let newSection;
      switch (draggableId) {
        case 'new-header-root':
          newSection = new HeaderSectionObject();
          break;
        case 'new-body-root':
          newSection = new BodySectionObject();
          break;
        case 'new-signature-root':
          newSection = new SignatureSectionObject();
          break;
        default:
          break;
      }
      if (newSection) {
        template.contractData.splice(destination.index, 0, newSection);
        const updatedContractObject = new ContractObject(template.toJSON());
        setInitialState(updatedContractObject);
        setEditCellItem(
          updatedContractObject.contractData[destination.index].data[0],
        );
        return;
      }
      const sourceItem = template.contractData[source.index];
      template.contractData.splice(source.index, 1);
      template.contractData.splice(destination.index, 0, sourceItem);
      const updatedContractObject = new ContractObject(template.toJSON());
      setInitialState(updatedContractObject);
    });
  };

  handleRef = (ref) => {
    if (ref) {
      templateScrollContext.setMainRef(ref, this.props.onTemplateScroll);
    }
  };

  render() {
    const {
      template,
      editCellItem,
      watermark = {},
      watermarkAlpha = 0.05,
      watermarkWidthPercent,
      setShowLinkItems,
      setInitialState,
      linkMeasureSheetItemsShow,
      linkMeasuresheetItemsKey,
      linkMeasureSheetItemsSingleSelect,
      linkMeasureSheetItemsTitle,
      linkMeasureSheetItemsShowCustomProduct,
      draggableType,
      viewSelection,
      scale,
    } = this.props;
    if (!template) {
      return null;
    }
    const watermarkURL =
      watermark instanceof Parse.File ? watermark.url() : watermark.url;
    const newContractObject = new ContractObject(template.toJSON());
    const { contractData = [], useWatermark } = newContractObject;
    const editObject =
      newContractObject.getObjectWithId(
        editCellItem ? editCellItem.objectId : undefined,
      ) || {};
    editObject.editKey = editCellItem ? editCellItem.editKey : undefined;
    const containerStyle = pageStyle(newContractObject, scale);
    return (
      <div
        onMouseDown={(event) => {
          const { className } = event.target;
          const isString = typeof className === 'string';
          if (
            className &&
            isString &&
            (className.indexOf('bp3-menu-item') > -1 ||
              className.indexOf('bp3-text-overflow-ellipsis') > -1)
          ) {
            event.preventDefault();
          }
        }}
        onClick={(event) => {
          checkMenus(event);
        }}
        className="templates-pdf__background"
      >
        {toolbarForCellItem(editObject)}
        <MeasureSheetItemSlideOutPanel
          isOpen={linkMeasureSheetItemsShow}
          title={linkMeasureSheetItemsTitle}
          singleSelect={linkMeasureSheetItemsSingleSelect}
          selectedIds={editObject[linkMeasuresheetItemsKey]}
          showCustomProduct={linkMeasureSheetItemsShowCustomProduct}
          onClose={() => setShowLinkItems(false)}
          onChange={(selectedIds, closePanel) => {
            editObject[linkMeasuresheetItemsKey] = selectedIds;
            setInitialState(newContractObject);
            if (closePanel) {
              setShowLinkItems(false);
            }
          }}
        />
        <div className="templates-pdf__tree-container">
          <DocumentTree />
        </div>
        <div className="templates-pdf__cell-tool-drawer">
          <CellToolDrawer />
        </div>
        <div
          ref={this.handleRef}
          className={`templates-pdf__canvas${
            viewSelection === 2 ? ' both-view' : ''
          } ${
            editObject.toolbarKey === 'sectionTitle' ? 'toolbar_active' : ''
          }`}
        >
          {(viewSelection === 0 || viewSelection === 2) && <AppView />}
          {(viewSelection === 1 || viewSelection === 2) && (
            <div
              id="page-container"
              className="contract-editor"
              style={containerStyle}
            >
              {newContractObject.pageId === 'pdfPage' &&
                newContractObject.pdf &&
                !newContractObject.pdf.isNew && (
                  <embed
                    src={newContractObject.pdf.url}
                    alt="pdf"
                    pluginspage="http://www.adobe.com/products/acrobat/readstep2.html"
                  />
                )}
              {newContractObject.pageId === 'pdfPage' &&
                newContractObject.pdf &&
                newContractObject.pdf.isNew && (
                  <embed
                    src={newContractObject.pdf._url}
                    alt="pdf"
                    pluginspage="http://www.adobe.com/products/acrobat/readstep2.html"
                  />
                )}
              {newContractObject.pageId === 'pdfPage' &&
                !newContractObject.pdf && (
                  <div id="page-content">
                    <Dropzone
                      onDrop={this.onPDFFileChanged}
                      accept="application/pdf"
                    >
                      {({ getRootProps, getInputProps, isDragActive }) => (
                        <div
                          {...getRootProps()}
                          style={{ width: '100%', height: '100%' }}
                        >
                          <input {...getInputProps()} />
                          <Well
                            bsSize="lg"
                            style={{
                              minHeight: '255px',
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <Button text="Drop PDF file here..." />
                          </Well>
                        </div>
                      )}
                    </Dropzone>
                  </div>
                )}
              {newContractObject.pageId !== 'pdfPage' && (
                <div id="page-content">
                  {watermark && useWatermark && (
                    <div className="templates-pdf__watermark">
                      <img
                        onMouseDown={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          e.returnValue = false;
                        }}
                        onTouchStart={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          e.returnValue = false;
                        }}
                        className="watermark-image"
                        alt="Watermark"
                        src={watermarkURL}
                        style={{
                          opacity: `${watermarkAlpha}`,
                          padding: containerStyle.padding,
                          width: `${watermarkWidthPercent}%`,
                        }}
                      />
                    </div>
                  )}
                  <div className="templates-pdf__page-count">Page 1 of 1</div>
                  <Droppable
                    droppableId={`${newContractObject.objectId}-root`}
                    isDropDisabled={draggableType !== 'root'}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={{
                          ...getListStyle(snapshot.isDraggingOver),
                          minHeight: 100,
                        }}
                      >
                        {contractData.map((item, index) => (
                          <Draggable
                            key={item.objectId}
                            draggableId={`${item.getPath().join('-')}-${
                              item.objectId
                            }`}
                            index={index}
                            type="root"
                          >
                            {(draggableProvided) => (
                              <div
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                                {...draggableProvided.dragHandleProps}
                                style={{
                                  ...draggableProvided.draggableProps.style,
                                }}
                              >
                                {componentForGroup(item)}
                                {draggableProvided.placeholder}
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

TemplateEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      type: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  setEditCellItem: PropTypes.func.isRequired,
  setInitialState: PropTypes.func.isRequired,
  setCurrentWorkingTemplate: PropTypes.func.isRequired,
  editCellItem: PropTypes.any,
  linkMeasureSheetItemsShow: PropTypes.bool,
  linkMeasuresheetItemsKey: PropTypes.string,
  linkMeasureSheetItemsSingleSelect: PropTypes.bool,
  linkMeasureSheetItemsTitle: PropTypes.string,
  linkMeasureSheetItemsShowCustomProduct: PropTypes.bool,
  viewSelection: PropTypes.oneOf([0, 1, 2]).isRequired,
  draggableType: PropTypes.string,
  watermark: PropTypes.shape({
    url: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  }),
  watermarkAlpha: PropTypes.number,
  watermarkWidthPercent: PropTypes.number,
  setShowLinkItems: PropTypes.func.isRequired,
  template: PropTypes.instanceOf(ContractObject),
  ...layoutContextPropTypes,
};

TemplateEdit.defaultProps = {
  editCellItem: undefined,
  linkMeasureSheetItemsShow: false,
  linkMeasuresheetItemsKey: undefined,
  linkMeasureSheetItemsSingleSelect: false,
  linkMeasureSheetItemsTitle: '',
  linkMeasureSheetItemsShowCustomProduct: false,
  draggableType: undefined,
  watermark: undefined,
  watermarkAlpha: 0,
  watermarkWidthPercent: 0,
  template: undefined,
};

const mapStateToProps = (
  { templatesEdit, auth: { config = {}, offices = [] } } = {},
  ownprops,
) => {
  const { id, type } = ownprops.match.params;
  const key = id === 'new' ? `${type}_${id}` : id;
  const template = templatesEdit[key];
  const { watermark: templateWatermark } = template || {};
  const watermark = templateWatermark || config.watermark || {};
  const watermarkAlpha = templateWatermark
    ? template.watermarkAlpha
    : config.watermarkAlpha;
  const watermarkWidthPercent = templateWatermark
    ? template.watermarkWidthPercent
    : config.watermarkWidthPercent;
  return {
    template,
    watermark,
    watermarkAlpha,
    watermarkWidthPercent,
    scale: templatesEdit.scale,
    editCellItem: templatesEdit.editCellItem,
    linkMeasureSheetItemsShow: templatesEdit.linkMeasureSheetItemsShow,
    linkMeasuresheetItemsKey: templatesEdit.linkMeasuresheetItemsKey,
    linkMeasureSheetItemsSingleSelect:
      templatesEdit.linkMeasureSheetItemsSingleSelect,
    linkMeasureSheetItemsTitle: templatesEdit.linkMeasureSheetItemsTitle,
    linkMeasureSheetItemsShowCustomProduct:
      templatesEdit.linkMeasureSheetItemsShowCustomProduct,
    draggableType: templatesEdit.draggableType,
    viewSelection: templatesEdit.viewSelection,
    currentWorkingId: templatesEdit.currentWorkingId,
    offices,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onTemplateScroll: (ref) => dispatch(onTemplateScroll(ref)),
  setInitialState: (template) => dispatch(setInitialStateForTemplate(template)),
  setCurrentWorkingTemplate: (objectId) =>
    dispatch(setCurrentWorkingTemplate(objectId)),
  setShowLinkItems: (show, key, singleSelect) =>
    dispatch(setShowLinkMeasureSheetItems(show, key, singleSelect)),
  setEditCellItem: (cellItem) => dispatch(setEditCellItem(cellItem)),
  pushToDataLayer: (variablesForLayer) =>
    dispatch(pushToDataLayer(variablesForLayer)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(TemplateEdit),
);
