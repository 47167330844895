/* eslint-disable react/prop-types */
/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import List from './List';
import TitleButton from '../../TitleButton';

const itemColumns = [
  {
    title: 'Settings',
    source: 'settings',
  },
  {
    title: 'Additional Details',
    source: 'additionalDetails',
  },
  {
    title: 'Placeholders',
    source: 'placeholders',
  },
  {
    title: 'Show Toggle',
    source: 'showToggle',
  },
  {
    title: 'Image',
    source: 'image',
  },
  {
    title: 'Category',
    source: 'category',
  },
  {
    title: 'Sub-Category',
    source: 'subCategory',
  },
  {
    title: 'Drill-Downs',
    source: 'subSubCategories',
  },
  {
    title: 'Name',
    source: 'name',
  },
  {
    title: 'Note',
    source: 'note',
  },
  {
    title: 'Measurement',
    source: 'measurementType',
  },
  {
    title: 'Offices',
    source: 'offices',
  },
  {
    title: 'Formula ID',
    source: 'formulaId',
  },
  {
    title: 'Formula',
    source: 'formula',
  },
];

const optionColumns = [
  {
    title: 'Select',
    source: 'optionSelect',
  },
  {
    title: 'Placeholders',
    source: 'optionPlaceholders',
  },
  {
    title: 'Brand',
    source: 'optionBrand',
  },
  {
    title: 'Name',
    source: 'optionName',
  },
  {
    title: 'Prices',
    source: 'optionPrices',
  },
];

const upChargeColumns = [
  {
    title: 'Disabled',
    source: 'upChargeDisabled',
  },
  {
    title: 'Select',
    source: 'upChargeSelect',
  },
  {
    title: 'Settings',
    source: 'upChargeSettings',
  },
  {
    title: 'Additional Details',
    source: 'upChargeAdditionalDetails',
  },
  {
    title: 'Placeholders',
    source: 'upChargePlaceholders',
  },
  {
    title: 'Show Toggle',
    source: 'upChargeShowToggle',
  },
  {
    title: 'Image',
    source: 'upChargeImage',
  },
  {
    title: 'Name',
    source: 'upChargeName',
  },
  {
    title: 'Note',
    source: 'upChargeNote',
  },
  {
    title: 'Measurement',
    source: 'upChargeMeasurement',
  },
  {
    title: 'Identifier',
    source: 'upChargeIdentifier',
  },
  {
    title: 'Percentage Price',
    source: 'upChargePercentagePrice',
  },
  {
    title: 'Prices',
    source: 'upChargePrices',
  },
];

const ActiveColumns = ({ toggleStickyHeaders, disableSticky }) => {
  const [collapse, setCollapse] = useState(true);

  const { maxUpChargeCount, maxOfficeCount } = useSelector(({ plan }) => plan);

  const onToggleCollapse = () => {
    setCollapse(!collapse);
  };

  const filteredItemColumns = itemColumns.filter((itemColumn) => {
    if (maxOfficeCount === 1 && itemColumn.title === 'Offices') {
      return false;
    }
    return true;
  });

  const maxCount = Math.max(
    filteredItemColumns.length,
    optionColumns.length,
    upChargeColumns.length,
  );

  const placeholderArray = [];
  for (let i = 0; i < maxCount; i += 1) {
    placeholderArray.push(i);
  }

  return (
    <div
      style={{
        boxShadow: collapse
          ? ''
          : '0 0.4rem 0.8rem 0 rgba(0,0,0,.2), 0 0.3rem 1rem 0 rgba(0,0,0,.19)',
        position: 'absolute',
        border: '1px solid rgb(204, 204, 204)',
        borderTop: 0,
        zIndex: collapse ? 1 : 10,
        borderRadius: collapse ? '4px' : '4px 4px 0 0',
        display: 'flex',
        flexDirection: 'column',
        top: -10,
        minWidth: '100%',
        marginBottom: 20,
      }}
    >
      <div
        style={{
          display: 'flex',
          position: 'relative',
          flex: 1,
          borderRadius: collapse ? '4px' : '4px 4px 0 0',
          transition: 'height .5s',
          zIndex: 1,
        }}
      >
        <List
          onHeaderClick={onToggleCollapse}
          items={filteredItemColumns}
          header="Measure Sheet Items"
          type="msi"
          collapse={collapse}
        />
        <List
          onHeaderClick={onToggleCollapse}
          items={optionColumns}
          header="Price Guide Options"
          type="option"
          collapse={collapse}
        />
        {maxUpChargeCount !== 0 && (
          <List
            onHeaderClick={onToggleCollapse}
            items={upChargeColumns}
            header="Up-Charges"
            type="upcharge"
            collapse={collapse}
          />
        )}
        <button
          onClick={onToggleCollapse}
          type="button"
          style={{
            backgroundColor: 'transparent',
            border: 0,
            fontSize: '16px',
            position: 'absolute',
            right: '3px',
            top: '-1px',
          }}
        >
          <i className={`fas fa-caret-${collapse ? 'down' : 'up'}`} />
        </button>
      </div>
      <div
        style={{
          display: collapse ? 'none' : 'flex',
          position: 'relative',
          justifyContent: 'center',
          zIndex: 10,
          padding: 5,
          backgroundColor: 'white',
        }}
      >
        <TitleButton
          variant="info"
          onClick={() => {
            toggleStickyHeaders(!disableSticky);
          }}
          title={`${disableSticky ? 'Enable' : 'Disable'} Sticky Headers`}
        />
      </div>
    </div>
  );
};

export default ActiveColumns;
