/* eslint-disable no-shadow */
/* eslint-disable react/forbid-prop-types */
/** @jsx jsx */
import { jsx } from '@emotion/core';
// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { getItemStyle, getListStyle } from '../Utils';
import {
  setInitialStateForTemplate,
  setEditCellItem,
} from '../../../actions/templateEdit';
import { setOnDragEndCallback } from '../../DropContext';
import SignatureItemObject from '../Models/SignatureItem';
import SignatureCell from './SignatureCell';
import ContractObject from '../Models/ContractObject';
import BodyCellItemObject from '../Models/BodyCellItem';

const containerStyle = {
  display: 'flex',
  border: '3px solid transparent',
};

const SignatureContainer = ({
  data,
  setTemplateState,
  parentPath,
  draggableType,
  editCellItem,
  setEditCellItem,
}) => {
  const setDroppableCallbackForId = (id) => {
    setOnDragEndCallback(id, (result) => {
      const { destination, draggableId, source } = result;
      const contractObject = data[0].getSourceObject();
      const updatedContractObject = new ContractObject(contractObject.toJSON());
      let destinationPath = destination.droppableId.split('-');
      destinationPath.pop();
      destinationPath = destinationPath.map((i) => parseInt(i, 10));
      const containerIndex = destinationPath.pop();
      const parentContainer = updatedContractObject.getItemAtPath(parentPath);
      destinationPath.push(destination.index);
      if (source.droppableId === `new-${SignatureItemObject.ContainerKey}`) {
        const newItem = new SignatureItemObject();
        newItem.column = containerIndex;

        setEditCellItem(newItem);
        updatedContractObject.insertObjectAtPath(destinationPath, newItem, 0);
      } else {
        let sourcePath = draggableId.split('-');
        sourcePath.pop();
        sourcePath = sourcePath.map((i) => parseInt(i, 10));
        const sourceItem = updatedContractObject.getItemAtPath(sourcePath);
        sourceItem.column = containerIndex;
        updatedContractObject.deleteObjectAtPath(sourcePath);
        updatedContractObject.insertObjectAtPath(
          destinationPath,
          sourceItem,
          0,
        );
        if (parentContainer.containerKey === BodyCellItemObject.ContainerKey) {
          if (!parentContainer.signatures.length) {
            updatedContractObject.deleteObjectAtPath(parentContainer.getPath());
          }
        } else if (!parentContainer.data.length) {
          updatedContractObject.deleteObjectAtPath(parentContainer.getPath());
        }
      }
      setTemplateState(updatedContractObject);
    });
  };
  const leftCells = data.filter(({ column }) => column === 0);
  const rightCells = data.filter(({ column }) => column === 1);
  const droppableIdLeft = `${[...parentPath, 0].join('-')}-${
    SignatureItemObject.ContainerKey
  }`;
  const droppableIdRight = `${[...parentPath, 1].join('-')}-${
    SignatureItemObject.ContainerKey
  }`;
  setDroppableCallbackForId(droppableIdLeft);
  setDroppableCallbackForId(droppableIdRight);
  return (
    <div style={containerStyle}>
      <Droppable
        droppableId={droppableIdLeft}
        isDropDisabled={draggableType !== SignatureItemObject.ContainerKey}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={{
              ...getListStyle(snapshot.isDraggingOver),
              width: '50%',
              paddingRight: '5px',
            }}
            {...provided.droppableProps}
          >
            {leftCells.map((cell, index) => (
              <Draggable
                key={cell.objectId}
                draggableId={`${cell.getPath().join('-')}-${cell.objectId}`}
                index={index}
              >
                {(draggableProvided, draggableSnapshot) => (
                  <div
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}
                    style={{
                      ...getItemStyle(
                        draggableSnapshot,
                        draggableProvided.draggableProps.style,
                        editCellItem.objectId === cell.objectId,
                      ),
                    }}
                  >
                    <SignatureCell key={cell.objectId} cell={cell} />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <Droppable
        droppableId={droppableIdRight}
        isDropDisabled={draggableType !== SignatureItemObject.ContainerKey}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={{
              ...getListStyle(snapshot.isDraggingOver),
              width: '50%',
              paddingRight: '5px',
            }}
            {...provided.droppableProps}
          >
            {rightCells.map((cell, index) => (
              <Draggable
                key={cell.objectId}
                draggableId={`${cell.getPath().join('-')}-${cell.objectId}`}
                index={index}
              >
                {(draggableProvided, draggableSnapshot) => (
                  <div
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}
                    style={{
                      ...getItemStyle(
                        draggableSnapshot,
                        draggableProvided.draggableProps.style,
                        editCellItem.objectId === cell.objectId,
                      ),
                    }}
                  >
                    <SignatureCell key={cell.objectId} cell={cell} />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

SignatureContainer.propTypes = {
  data: PropTypes.arrayOf(PropTypes.instanceOf(SignatureItemObject)).isRequired,
  setTemplateState: PropTypes.func.isRequired,
  setEditCellItem: PropTypes.func.isRequired,
  parentPath: PropTypes.arrayOf(PropTypes.number).isRequired,
  draggableType: PropTypes.string,
  editCellItem: PropTypes.any,
};

SignatureContainer.defaultProps = {
  draggableType: undefined,
  editCellItem: {},
};

const mapStateToProps = ({ templatesEdit = {} }) => ({
  draggableType: templatesEdit.draggableType,
  editCellItem: templatesEdit.editCellItem,
});

const mapDispatchToProps = (dispatch) => ({
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
  setEditCellItem: (item) => dispatch(setEditCellItem(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignatureContainer);
