/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import ContainedDatePicker from './ContainedDatePicker';
import {
  updateAnalyticsPageDates,
  getCharts,
} from '../../actions/leapAnalytics';
import ChartSelect from './ChartSelect';

class LeapAnalytics extends React.Component {
  componentDidMount() {
    const { startDate, endDate, charts } = this.props;
    const [check] = charts;
    if (!(check && check.status === '400')) {
      this.updateAnalyticsPageDates({ startDate, endDate });
      this.props.getCharts();
    }
  }

  onDatesChange = (change) => {
    const { startDate, endDate } = change;
    if (startDate && endDate) {
      this.updateAnalyticsPageDates({ startDate, endDate });
    }
  };

  updateAnalyticsPageDates = ({ startDate, endDate }) => {
    this.props.updateAnalyticsPageDates({ startDate, endDate });
  };

  render() {
    return (
      // < title="Leap Analytics">
      <>
        <div style={{ display: 'flex', justifyContent: 'center', margin: 10 }}>
          <ContainedDatePicker
            endDate={this.props.endDate}
            startDate={this.props.startDate}
            onDatesChange={this.onDatesChange}
          />
        </div>
        <hr />
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {this.props.charts
            .filter((chart) => chart.chartName)
            .map((chartData) => (
              <ChartSelect key={chartData.objectId} {...chartData} />
            ))}
        </div>
      </>
    );
  }
}
LeapAnalytics.propTypes = {
  updateAnalyticsPageDates: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(moment).isRequired,
  endDate: PropTypes.instanceOf(moment).isRequired,
  charts: PropTypes.arrayOf(PropTypes.shape({})),
};

LeapAnalytics.defaultProps = {
  charts: [],
};

const mapStateToProps = ({
  leapAnalytics: { startDate, endDate, charts },
}) => ({
  startDate,
  endDate,
  charts,
});
const mapDispatchToProps = (dispatch) => ({
  updateAnalyticsPageDates: ({ startDate, endDate }) =>
    dispatch(updateAnalyticsPageDates({ startDate, endDate })),
  getCharts: () => dispatch(getCharts()),
});
export default connect(mapStateToProps, mapDispatchToProps)(LeapAnalytics);
