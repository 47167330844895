export const formattedValueForKey = (value, key) => {
  if (key === 'color') {
    const { r, g, b, a } = value;
    return `${r}-${g}-${b}-${a}`;
  }
  return value;
};

export const valueForCellId = (cellId, template) => {
  const { contractData = [] } = template;
  const ids = cellId.split('-');
  for (let i = 0; i < contractData.length; i += 1) {
    const section = contractData[i];
    if (section.groupType === 'header') {
      if (section.objectId === ids[1]) {
        if (ids.length === 3) {
          return section[ids[2]];
        }
        const { data = [] } = section;
        for (let j = 0; j < data.length; j += 1) {
          const sectionObject = data[j];
          if (sectionObject.objectId === ids[2]) {
            if (ids.length === 4) {
              return sectionObject[ids[3]];
            }
            const { cellItems = [] } = sectionObject;
            for (let k = 0; k < cellItems.length; k += 1) {
              const cellItem = cellItems[k];
              if (parseInt(ids[3], 10) === k) {
                for (let l = 0; l < cellItem.length; l += 1) {
                  const cellItemLine = cellItem[l];
                  if (cellItemLine.objectId === ids[4]) {
                    if (ids.length === 6) {
                      return cellItemLine[ids[5]];
                    }
                  }
                }
              }
            }
          }
        }
      }
    } else if (section.groupType === 'body') {
      if (section.objectId === ids[1]) {
        if (ids.length === 3) {
          return section[ids[2]];
        }
        const { data = [] } = section;
        for (let j = 0; j < data.length; j += 1) {
          const bodySectionGroup = data[j];
          if (bodySectionGroup.objectId === ids[2]) {
            if (ids.length === 4) {
              return bodySectionGroup[ids[3]];
            }
            const { cellItems = [] } = bodySectionGroup;
            for (let k = 0; k < cellItems.length; k += 1) {
              const cellItem = cellItems[k];
              if (cellItem.objectId === ids[3]) {
                if (ids.length === 5) {
                  return cellItem[ids[4]];
                }
                const { detailItems = [] } = cellItem;
                for (let l = 0; l < detailItems.length; l += 1) {
                  const detailItem = detailItems[l];
                  if (detailItem.objectId === ids[4]) {
                    if (ids.length === 6) {
                      return detailItem[ids[5]];
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  return undefined;
};

export const getItemStyle = ({ isDragging }, draggableStyle, isSelected) => ({
  userSelect: 'none',
  background: isDragging ? 'white' : undefined,
  boxShadow: isSelected
    ? '-5px 5px 5px darkgray, 5px 5px 5px darkgray'
    : undefined,
  position: 'relative',
  opacity: isDragging ? 0.8 : undefined,
  // maxWidth: combineWith === 'trash' ? '50px' : undefined,
  ...draggableStyle,
});

export const getListStyle = (isDraggingOver, isSelected) => ({
  background: isDraggingOver ? 'rgba(230, 230, 230, 0.5)' : undefined,
  boxShadow: isSelected
    ? '-5px 5px 5px darkgray, 5px 5px 5px darkgray'
    : undefined,
});

export const getTextWidth = (txt, font) => {
  const element = document.createElement('canvas');
  const context = element.getContext('2d');
  context.font = font;
  return context.measureText(txt).width;
};
