/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Parse from 'parse';
import { isEqual } from 'lodash';
import Panel from '../Panel';
import {
  startFetchContractObjectGroup,
  startCloneTemplate,
} from '../../actions/templates';
import { setCurrentWorkingTemplate } from '../../actions/templateEdit';
import { history } from '../../router';
import { pushToDataLayer } from '../../actions/tagManager';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import collectCrumbsFromPath from './collectCrumbsFromPath';

export class TemplateGroup extends React.Component {
  componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.props.startFetchContractObjectGroup(id);
  }

  componentDidUpdate() {
    const { crumbs, setCrumbs, selectedGroup, match = {} } = this.props;

    if (selectedGroup && selectedGroup.name) {
      const { params } = match;

      const { id } = params;

      const collected = collectCrumbsFromPath({ match });

      const formatted = collected.map((crumb) => {
        if (crumb.title === id) {
          return {
            ...crumb,
            title: selectedGroup.name,
          };
        }

        return crumb;
      });
      const changed = !isEqual(crumbs, formatted);
      if (changed) {
        setCrumbs(formatted);
      }
    }
  }

  onBackClicked = async () => {
    history.goBack();
  };

  onCopyClicked = async (template) => {
    this.props.pushToDataLayer({
      event: 'documentTemplateEvent',
      eventCategory: 'Documents',
      eventAction: 'Copy',
      eventLabel: template.get('displayName'),
    });
    const { type } = this.props.match.params;
    const newTemplate = await this.props.startCloneTemplate(template, type);
    this.props.setTemplate(newTemplate.id);
    this.props.pushToDataLayer({
      event: 'documentTemplateEvent',
      eventCategory: 'Documents',
      eventAction: 'View',
      eventLabel: template.get('displayName'),
    });
    // history.push(`/templates/${newTemplate.get('type')}/${newTemplate.id}`);
  };

  render() {
    return (
      // <MainSection>
      <Panel title="Choose a template">
        <div className="resources-container">
          {this.props.contractObjects.map((contractObject) => (
            <div
              key={contractObject.id}
              className="mask no-caption resources-cell card"
            >
              <div
                className="thumbnail-container"
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div className="img-thumbnail view view-first">
                  <img
                    style={{
                      cursor: 'pointer',
                      margin: 'auto',
                      height: '100%',
                      display: 'block',
                    }}
                    src={
                      contractObject.get('iconImage')
                        ? contractObject.get('iconImage').url()
                        : '/images/no_image.png'
                    }
                    alt="Thumbnail"
                  />
                  <div className="mask no-caption">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                      className="tools tools-bottom"
                    >
                      <button
                        type="button"
                        onClick={() => this.onCopyClicked(contractObject)}
                      >
                        <i className="far fa-copy" />
                      </button>
                      <div style={{ width: 10 }} />
                      {contractObject.has('preview') && (
                        <a
                          href={contractObject.get('preview').url()}
                          target="_blank"
                          id="viewButton"
                          rel="noopener noreferrer"
                        >
                          <i className="fas fa-paperclip" />
                        </a>
                      )}
                    </div>
                  </div>
                </div>
                <div className="caption-with-author">
                  <p style={{ textAlign: 'center' }}>
                    {contractObject.get('displayName')}
                  </p>
                  <div>
                    <span className="caption-author">
                      {contractObject.get('author')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Panel>
    );
  }
}

TemplateGroup.propTypes = {
  startCloneTemplate: PropTypes.func.isRequired,
  startFetchContractObjectGroup: PropTypes.func.isRequired,
  contractObjects: PropTypes.arrayOf(PropTypes.instanceOf(Parse.Object)),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  ...layoutContextPropTypes,
};

TemplateGroup.defaultProps = {
  contractObjects: [],
};

const mapStateToProps = ({ templates }) => {
  const {
    templateGroups: { selectedGroup = {} },
  } = templates;
  const { contractObjects = [] } = selectedGroup;
  return {
    contractObjects,
    selectedGroup,
  };
};

const mapDispatchToProps = (dispatch) => ({
  startFetchContractObjectGroup: (id) =>
    dispatch(startFetchContractObjectGroup(id)),
  startCloneTemplate: (object, type) =>
    dispatch(startCloneTemplate(type, object.id, true)),
  setTemplate: (templateId) => dispatch(setCurrentWorkingTemplate(templateId)),
  pushToDataLayer: (variablesForLayer) =>
    dispatch(pushToDataLayer(variablesForLayer)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(TemplateGroup),
);
