/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Select from '../Misc/Select';
import { pushToDataLayer } from '../../actions/tagManager';

const onToolSelect = ({ value }) => {
  value();
};

const Tools = ({
  onMassPriceChangeClicked,
  onMassCopy,
  onDownloadOwensCorning,
  onDownloadSoftLite,
  onExportPriceGuide,
  onImportPriceGuide,
  onIncrementUpCharges,
  ...props
}) => {
  const options = [
    { label: 'Mass Price Change', value: onMassPriceChangeClicked },
    { label: 'Mass Copy', value: onMassCopy },
    { label: 'Download Owens Corning', value: onDownloadOwensCorning },
    { label: 'DownLoad Soft-Lite', value: onDownloadSoftLite },
    { label: 'Export Price Guide', value: onExportPriceGuide },
    { label: 'Import Price Guide', value: onImportPriceGuide },
    { label: 'Auto Generate UpCharge IDs', value: onIncrementUpCharges },
  ];
  return (
    <Select
      style={props.style}
      value={null}
      options={options}
      closeMenuOnSelect
      onChange={(event) => {
        props.pushToDataLayer({
          event: 'priceGuideToolEvent',
          eventCategory: 'Price Guide',
          eventAction: 'Tool Used',
          eventLabel: event.label,
        });
        onToolSelect(event);
      }}
    />
  );
};

Tools.propTypes = {
  onMassPriceChangeClicked: PropTypes.func.isRequired,
  onDownloadOwensCorning: PropTypes.func.isRequired,
  onDownloadSoftLite: PropTypes.func.isRequired,
  onExportPriceGuide: PropTypes.func.isRequired,
  onImportPriceGuide: PropTypes.func.isRequired,
  pushToDataLayer: PropTypes.func.isRequired,
  onMassCopy: PropTypes.func.isRequired,
};

export const mapDispatchToProps = (dispatch) => ({
  pushToDataLayer: (variablesForLayer) =>
    dispatch(pushToDataLayer(variablesForLayer)),
});
export default connect(undefined, mapDispatchToProps)(Tools);
