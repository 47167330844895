import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { LayoutContext } from '../Layout';
import { pushToDataLayer as pushToDataLayerAction } from '../../actions/tagManager';
import { showDeleteConfirmation as showDeleteConfirmationAction } from '../../actions/deleteConfirmation';
import { startUpdateConfig as startUpdateConfigAction } from '../../actions/auth';
import { TextGroup, SwitchGroup } from '../FormGroup';
import Copy from '../AppSettings/Copy';
import Panel from '../Panel';
import TitleButton from '../TitleButton';

/** @typedef {{
 *  enabled: boolean,
 *  showCopy: boolean,
 *  token: string,
 *  valid: boolean,
 * }} RoofScopeState */

const RoofScope = () => {
  const title = 'Scope Technologies';
  const { setButtons, setCrumbs } = useContext(LayoutContext);
  // Get config values from redux store
  const roofScope = useSelector(({ auth: { config } }) => config.roofScope);
  // Set dispatch instance and map actions to functions
  const dispatch = useDispatch();
  const [pushToDataLayer, startUpdateConfig, showDeleteConfirmation] = [
    /** @param {import('../../actions/tagManager').AnalyticsEventData} payload */
    (payload) => dispatch(pushToDataLayerAction(payload)),
    (configUpdate) => dispatch(startUpdateConfigAction(configUpdate)),
    /** @param {{message: string, title: string, onConfirm: () => void}} params */
    (params) => dispatch(showDeleteConfirmationAction(params)),
  ];
  // Create state object and destructure it
  const [{ enabled, showCopy, token, valid }, updateState] = useState({
    enabled: false,
    showCopy: false,
    token: '',
    valid: false,
  });
  // Create a setState function that mirrors the way class state works
  /** @param {Partial<RoofScopeState>} newState */
  const setState = (newState) => {
    updateState(
      /** @param {RoofScopeState} prevState */
      (prevState) => ({
        ...prevState,
        ...newState,
      }),
    );
  };

  // Set state from redux store values
  useEffect(() => {
    if (typeof roofScope === 'object') {
      const { enabled: _enabled = false, token: _token = '' } = roofScope;
      setState({ enabled: _enabled, token: _token });
    }
  }, [roofScope]);

  const askForClearPermission = () => {
    showDeleteConfirmation({
      message: 'Are you sure you want to clear this integration ?',
      title: 'Clear Integration ?',
      onConfirm: () => onEnabledChanged(false),
    });
  };

  const onCopyClicked = () => {
    setState({ showCopy: true });
  };

  const onSaveClicked = () => {
    startUpdateConfig({ roofScope: { enabled, token } });
  };

  const buttons = (
    <TitleButtons
      onClearClick={askForClearPermission}
      onCopyClicked={onCopyClicked}
      onSaveClicked={onSaveClicked}
      valid={valid}
    />
  );

  // Add crumb and buttons to breadcrumb bar
  useEffect(() => {
    setCrumbs([{ title, link: '/roofscope' }]);
    setButtons(buttons);
    return () => {
      setCrumbs([]);
      setButtons();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, token, setCrumbs, setButtons, valid]);

  // Validation
  useEffect(() => {
    updateState((prevState) => ({
      ...prevState,
      valid:
        !prevState.enabled ||
        (prevState.enabled &&
          // Check that token matches this format as seen in documentation:
          // D3B29A3B-CA70-5EDE-67C3-07A60366AB72
          RegExp(
            /^[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}$/g,
          ).test(prevState.token)),
    }));
  }, [enabled, token]);

  /** @param {boolean} checked */
  const onEnabledChanged = (checked) => {
    setState({ enabled: checked });
    if (!checked) {
      setState({ token: '' });
      pushToDataLayer({
        event: 'integrationEvent',
        eventCategory: 'Integration',
        eventAction: 'Disable',
        eventLabel: 'RoofScope',
      });
    } else {
      pushToDataLayer({
        event: 'integrationEvent',
        eventCategory: 'Integration',
        eventAction: 'Enable',
        eventLabel: 'RoofScope',
      });
    }
  };

  /** @param {string} change */
  const onTokenChange = (change) => {
    setState({ token: change.trim() });
  };

  return (
    <div className="default-page-padding">
      <Copy
        title={`Copy ${title} Settings`}
        show={showCopy}
        warning={`Warning! This will overwrite your current ${title} settings`}
        configKeys={['roofScope']}
        onClose={() => setState({ showCopy: false })}
      />
      <Panel title={title}>
        <div>
          <SwitchGroup
            title="Enabled"
            checked={enabled}
            onChange={onEnabledChanged}
          />
          {enabled && (
            <div>
              <TextGroup
                title="API Token"
                value={token}
                placeholder="API Token"
                onChange={onTokenChange}
                errorMessage={!valid ? 'Invalid API Token!' : null}
              />
            </div>
          )}
        </div>
      </Panel>
    </div>
  );
};

/**
 * @param {{
 *  onClearClick: () => void,
 *  onCopyClicked: () => void,
 *  onSaveClicked: () => void,
 *  valid: boolean,
 * }} props
 */
const TitleButtons = ({
  onClearClick,
  onCopyClicked,
  onSaveClicked,
  valid,
}) => (
  <>
    <TitleButton variant="warning" onClick={onClearClick} title="Clear" />
    <TitleButton variant="primary" onClick={onCopyClicked} title="Copy" />
    <TitleButton
      variant="success"
      onClick={onSaveClicked}
      title="Save"
      disabled={!valid}
    />
  </>
);

TitleButtons.propTypes = {
  onClearClick: PropTypes.func.isRequired,
  onCopyClicked: PropTypes.func.isRequired,
  onSaveClicked: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
};

export default RoofScope;
