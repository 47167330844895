/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import cheerio from 'cheerio';

export const cleanHtml = (unsafeHtml) => {
  const $ = cheerio.load(unsafeHtml);

  $('link').remove();
  $('script').remove();
  $('head').remove();
  $('style').remove();

  $('body').map((i, bodyItem) => {
    bodyItem.tagName = 'div';
    return bodyItem;
  });

  const finalHTML = $.html();

  return finalHTML;
};
