import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import DecryptedCell from './DecryptedCell';

const DecryptedSection = (props) => {
  const { sectionData = {} } = props;

  const { cells = [] } = sectionData;

  return (
    <div className="decrypted-section">
      <Row>
        <Col>
          <h3>{sectionData.title}</h3>
        </Col>
      </Row>
      <div className="decrypted-cells">
        {cells.map((cellData) => {
          return <DecryptedCell cellData={cellData} />;
        })}
      </div>
    </div>
  );
};

DecryptedSection.propTypes = {
  sectionData: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
};

DecryptedSection.defaultProps = {};

export default DecryptedSection;
