/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Menu, MenuItem, TextArea } from 'leap-menu-item';
import {
  setInitialStateForTemplate,
  setShowLinkMeasureSheetItems,
} from '../../../../actions/templateEdit';
import YesNoMenuItem from '../YesNoMenuItem';
import ContractObject from '../../Models/ContractObject';
import FormulaEditor from './FormulaEditor';
import DebounceInputWithNet from '../DebounceInputWithNet';

const MeasureSheetItemDetailMenu = ({
  object,
  contractObject,
  setTemplateState,
  setShowLinkItems,
}) => {
  const { linkedMeasureSheetItemConfig = {} } = object;
  return (
    <>
      <Menu.Divider />
      <MenuItem text="Title">
        <DebounceInputWithNet
          element={TextArea}
          minLength={0}
          debounceTimeout={300}
          value={object.appTitle}
          onChange={(e) => {
            object.appTitle = e.target.value;
            setTemplateState(contractObject);
          }}
        />
      </MenuItem>
      <YesNoMenuItem
        text="Show Title"
        active={linkedMeasureSheetItemConfig.title}
        onChange={(value) => {
          object.linkedMeasureSheetItemConfig = {
            ...linkedMeasureSheetItemConfig,
            title: value,
          };
          setTemplateState(contractObject);
        }}
      />
      <YesNoMenuItem
        text="Show Selected Option"
        active={linkedMeasureSheetItemConfig.subtitle}
        onChange={(value) => {
          object.linkedMeasureSheetItemConfig = {
            ...linkedMeasureSheetItemConfig,
            subtitle: value,
          };
          setTemplateState(contractObject);
        }}
      />
      <YesNoMenuItem
        text="Show Quantity"
        active={linkedMeasureSheetItemConfig.quantity}
        onChange={(value) => {
          object.linkedMeasureSheetItemConfig = {
            ...linkedMeasureSheetItemConfig,
            quantity: value,
          };
          setTemplateState(contractObject);
        }}
      />
      <YesNoMenuItem
        text="Show Measure Type"
        active={linkedMeasureSheetItemConfig.measurementType}
        onChange={(value) => {
          object.linkedMeasureSheetItemConfig = {
            ...linkedMeasureSheetItemConfig,
            measurementType: value,
          };
          setTemplateState(contractObject);
        }}
      />
      <YesNoMenuItem
        text="Show Unit Total"
        active={linkedMeasureSheetItemConfig.unitTotal}
        onChange={(value) => {
          object.linkedMeasureSheetItemConfig = {
            ...linkedMeasureSheetItemConfig,
            unitTotal: value,
          };
          setTemplateState(contractObject);
        }}
      />
      <YesNoMenuItem
        text="Show Grand Total"
        active={linkedMeasureSheetItemConfig.total}
        onChange={(value) => {
          object.linkedMeasureSheetItemConfig = {
            ...linkedMeasureSheetItemConfig,
            total: value,
          };
          setTemplateState(contractObject);
        }}
      />
      <Menu.Divider />
      <FormulaEditor
        contractObject={contractObject}
        value={object.formula}
        onChange={(value) => {
          object.formula = value;
          setTemplateState(contractObject);
        }}
        excluding={object.cellId}
      />
      <YesNoMenuItem
        text="Hide Unless Added to Estimate"
        active={!object.drawIfEmpty}
        onChange={(value) => {
          object.drawIfEmpty = !value;
          setTemplateState(contractObject);
        }}
      />
      <Menu.Divider />
      <MenuItem
        text="Link Measure Sheet Item"
        onClick={() =>
          setShowLinkItems(
            true,
            'linkedMeasureSheetItem',
            true,
            'Link Measure Sheet Item',
          )
        }
        label={object.linkedMeasureSheetItem ? 'Linked' : ''}
      />
    </>
  );
};

MeasureSheetItemDetailMenu.propTypes = {
  object: PropTypes.any.isRequired,
  contractObject: PropTypes.instanceOf(ContractObject).isRequired,
  setTemplateState: PropTypes.func.isRequired,
  secureCaptureEnabled: PropTypes.bool,
  setShowLinkItems: PropTypes.func.isRequired,
};

MeasureSheetItemDetailMenu.defaultProps = {
  secureCaptureEnabled: false,
};

const mapStateToProps = ({ templatesEdit = {}, auth }) => ({
  templateValues: templatesEdit[templatesEdit.currentWorkingId],
  editCellItem: templatesEdit.editCellItem,
  secureCaptureEnabled: auth.company.get('spreedlyEnabled'),
});

const mapDispatchToProps = (dispatch) => ({
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
  setShowLinkItems: (show, key, singleSelect, title) =>
    dispatch(setShowLinkMeasureSheetItems(show, key, singleSelect, title)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MeasureSheetItemDetailMenu);
