/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Parse from 'parse';
import { Modal, Button } from 'react-bootstrap';
import { startPublishTemplate } from '../../actions/templates';
import { TextGroup } from '../FormGroup';

class PublishModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accept: false,
      templateName: null,
      templateAuthor: null,
    };
  }

  onSubmit = () => {
    const { defaultName, defaultAuthor } = this.props;
    const { templateName, templateAuthor } = this.state;
    this.props.template.set('name', templateName);
    this.props.template.set('author', templateAuthor);

    this.props.startPublishTemplate({
      templateId: this.props.template.id,
      name: templateName || defaultName,
      author: templateAuthor || defaultAuthor,
    });

    this.props.onClose();
  };

  render() {
    const { show, onClose, defaultName, defaultAuthor } = this.props;

    const { templateName, templateAuthor } = this.state;

    return (
      <Modal
        show={show}
        onHide={onClose}
        onEnter={() => this.setState(() => ({ accept: false }))}
      >
        <Modal.Header closeButton>
          <Modal.Title>Publish Template</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextGroup
            title="Name"
            value={templateName}
            placeholder={defaultName}
            defaultValue={defaultName}
            onChange={(value) => this.setState({ templateName: value })}
          />
          <TextGroup
            title="Author"
            value={templateAuthor}
            placeholder="anonymous"
            defaultValue={defaultAuthor}
            onChange={(value) => this.setState({ templateAuthor: value })}
          />
          <div style={{ fontWeight: 'bold', color: 'red' }}>
            YOU ARE MAKING THIS TEMPLATE PUBLIC
          </div>
          <div>
            By publishing this template to shared template library you are
            allowing access for any user of the Leap ecosystem to download this
            template into their account, modify the template to fit their needs,
            and/or use it as they see fit.
          </div>
        </Modal.Body>
        <Modal.Footer>
          <span
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              fontWeight: 'bold',
            }}
          >
            By checking this box, you agree to make this template public
            <div style={{ paddingLeft: 5 }}>
              <input
                type="checkbox"
                checked={this.state.accept}
                onChange={() =>
                  this.setState(({ accept }) => ({ accept: !accept }))
                }
              />
            </div>
          </span>
          {this.state.accept && (
            <Button onClick={this.onSubmit} variant="success">
              Publish
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

PublishModal.propTypes = {
  defaultName: PropTypes.string.isRequired,
  defaultAuthor: PropTypes.string.isRequired,
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  template: PropTypes.instanceOf(Parse.Object),
  startPublishTemplate: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth }, ownProps) => ({
  defaultName: ownProps.template ? ownProps.template.get('displayName') : '',
  defaultAuthor: auth.user ? auth.user.get('nameFirst') : '',
});

PublishModal.defaultProps = {
  show: false,
  template: undefined,
};

const mapDispatchToProps = (dispatch) => ({
  startPublishTemplate: (params) => dispatch(startPublishTemplate(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PublishModal);
