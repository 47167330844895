/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@blueprintjs/core';
import Dropzone from 'react-dropzone';
import BodyRow from '../BodyRow';
import BodyCellItem from '../Models/BodyCellItem';
import AppToaster from '../../../utils/AppToaster';
import ContractObject from '../Models/ContractObject';
import {
  setEditCellItem,
  setInitialStateForTemplate,
} from '../../../actions/templateEdit';
import { newFileFromFile } from '../../../utils/utils';

const DrawingCell = ({
  cellItem,
  template,
  setInitialStateForTemplate,
  setEditItem,
}) => {
  const { pageSize } = template;
  const [width] = pageSize.split(',');
  const aspect = 768 / parseInt(width, 10);
  const sketchHeight = cellItem.imageHeight * aspect;
  let imageurl = '';

  if (cellItem.drawingBackgroundImage) {
    if (cellItem.drawingBackgroundImage.url) {
      imageurl = cellItem.drawingBackgroundImage.url;
    }

    if (cellItem.drawingBackgroundImage._url) {
      imageurl = cellItem.drawingBackgroundImage._url;
    }
  }

  return (
    <BodyRow cellItem={cellItem}>
      <div
        className="templates-app__drawing_cell"
        style={{
          height: `${sketchHeight}px`,
        }}
        onClick={() => {
          setEditItem(cellItem);
        }}
      >
        <div
          className={`"drawing-text-container templates-app__title${
            cellItem.required ? '_required' : ''
          }`}
        >
          {cellItem.appTitle}
        </div>
        <div className="drawing-img-container">
          <img className="drawing-background-img" src={imageurl} />
        </div>
        <Dropzone
          onDrop={async (e) => {
            try {
              const file = await newFileFromFile(
                e[0],
                'document',
                'drawingBackgroundImage',
              );
              cellItem.drawingBackgroundImage = file;
              const contractObject = cellItem.getSourceObject();
              setInitialStateForTemplate(
                new ContractObject(contractObject.toJSON()),
              );
            } catch (err) {
              AppToaster.show({ message: err.message, timeout: 5000 });
            }
          }}
          accept="image/*"
        >
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div className="drawing-background-input-containter">
              <input {...getInputProps()} />
              <Button
                {...getRootProps()}
                text="Drop Background Image Here..."
              />
            </div>
          )}
        </Dropzone>
        <div className="templates-app__cell_bottom_border" />
      </div>
    </BodyRow>
  );
};

DrawingCell.propTypes = {
  setInitialStateForTemplate: PropTypes.func.isRequired,
  cellItem: PropTypes.instanceOf(BodyCellItem).isRequired,
  template: PropTypes.instanceOf(ContractObject).isRequired,
};

const mapStateToProps = ({ templatesEdit }) => ({
  template: templatesEdit[templatesEdit.currentWorkingId],
});

const mapDispatchToProps = (dispatch) => ({
  setInitialStateForTemplate: (template) =>
    dispatch(setInitialStateForTemplate(template)),
  setEditItem: (cellItem) => dispatch(setEditCellItem(cellItem)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DrawingCell);
