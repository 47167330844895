import React, { useEffect, useContext } from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
  useLocation,
  Link,
} from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { LayoutContext } from '../../Layout';
import SharedResourcesIndex from './SharedResourcesIndex';
import SharedResourcesEditPage from './SharedResourcesEditPage';
import SharedResourceObjEdit from './SharedResourcesEditObj';

const SharedResourcesRoute = () => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const { setCrumbs, setButtons } = useContext(LayoutContext);

  useEffect(() => {
    if (setButtons && setCrumbs) {
      const crumb = {
        title: 'Shared Resource Groups',
        link: '/shared_resources',
      };
      const newCrumbs = [crumb];
      setCrumbs(newCrumbs);
      setButtons(
        <Button as={Link} to={`${pathname}/add`}>
          Add
        </Button>,
      );
      return () => {
        setButtons();
        setCrumbs([]);
      };
    }

    return undefined;
  }, [pathname, setButtons, setCrumbs]);

  return (
    <Switch>
      <Route path={`${path}/edit/:id/:objId`}>
        <SharedResourceObjEdit />
      </Route>
      <Route path={[`${path}/edit/:id`, `${path}/add`]}>
        <SharedResourcesEditPage />
      </Route>
      <Route exact path={path}>
        <SharedResourcesIndex />
      </Route>
    </Switch>
  );
};

export default SharedResourcesRoute;
