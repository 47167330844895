const defaultPlanJSON = {
  customCreditApplicationsAllowed: false,
  customMainMenuOptionsAllowed: true,
  customPermissionGroupsAllowed: true,
  docuSignEnabled: true,
  attachmentsAsLinksRequired: false,
  groupMeEnabled: true,
  hoverEnabled: true,
  isDefault: true,
  jobNimbusEnabled: true,
  leadPerfectionEnabled: true,
  logsAllowed: true,
  mainMenuLabelOverrides: {
    estimates: {
      title: 'ESTIMATES',
      enabled: true,
      locked: false,
    },
    contracts: {
      title: 'CONTRACTS',
      enabled: true,
      locked: false,
    },
    proposals: {
      title: 'PROPOSALS',
      enabled: true,
      locked: false,
    },
    financeCalculator: {
      title: 'FINANCE CALCULATOR',
      enabled: true,
      locked: false,
    },
    priceGuide: {
      title: 'PRICE GUIDE',
      enabled: true,
      locked: false,
    },
    availableManagers: {
      title: 'AVAILABLE CONTACTS',
      enabled: true,
      locked: false,
    },
    pitchBooks: {
      title: 'RESOURCES',
      enabled: true,
      locked: false,
    },
    settings: {
      title: 'SETTINGS',
      enabled: true,
      locked: false,
    },
    creditApplication: {
      title: 'CREDIT APPLICATION',
      enabled: true,
      locked: false,
    },
  },
  marketsharpEnabled: true,
  maxAdditionalDetailsPerItemCount: -1,
  maxCategoryCount: -1,
  maxCommissionFormulasCount: -1,
  maxContractTemplateCount: -1,
  maxCustomTemplateCount: -1,
  maxEmailRecipientCount: -1,
  maxFinanceOptionCount: -1,
  maxMeasureSheetItemCount: -1,
  maxOfficeCount: -1,
  maxOptionCount: -1,
  maxOptionsPerItem: -1,
  maxPackageCount: -1,
  maxPriceFormulasCount: -1,
  maxProposalTemplateCount: -1,
  maxTemplateCount: -1,
  maxUpChargeCount: -1,
  maxUpChargesPerItem: -1,
  partnerCreditApplicationSourcesAllowed: [
    'enerBank',
    'dividend',
    'powerPay',
    'amerifirst',
    'foundation',
    'fortiva',
    'green_sky',
    'service_finance',
    'sunlight',
    'lendkey',
    'mosaic',
    'pure',
  ],
  paymentCaptureEnabled: true,
  paymentGatewaysAllowed: [
    'acapture',
    'adyen',
    'alternative_payments',
    'authorize_net',
    'bambora_apac',
    'banco_sabadell',
    'barclaycard_smartpay',
    'barclays_epdq_extra_plus',
    'beanstream',
    'blue_pay',
    'blue_snap',
    'borgun',
    'bpoint',
    'braintree',
    'bridge_pay',
    'card_connect',
    'card_stream',
    'cardprocess',
    'cayan',
    'cecabank',
    'cenpos',
    'checkout_v2',
    'clearhaus',
    'conekta',
    'creditcall',
    'credorax',
    'ct_payment',
    'culqi',
    'cyber_source',
    'd_local',
    'data_cash',
    'decidir',
    'diamond_mind',
    'dibs',
    'digitzs',
    'ebanx',
    'elavon',
    'element',
    'epay',
    'eway',
    'eway_rapid',
    'ezic',
    'fat_zebra',
    'first_data_e4',
    'first_data_e4_v27',
    'first_giving',
    'first_pay',
    'flo2cash',
    'flo2cash_simple',
    'forte',
    'global',
    'global_collect',
    'global_iris',
    'global_transport',
    'hps',
    'iats_payments',
    'iridium',
    'iveri',
    'ixopay',
    'jabstry',
    'jetpay',
    'jetpay_v2',
    'kushki',
    'latitude19',
    'litle',
    'maxipago',
    'mercado_pago',
    'merchant_e_solutions',
    'merchant_partners',
    'merchant_warrior',
    'mercury',
    'migs',
    'moneris',
    'mundipagg',
    'nab_transact',
    'ncr_secure_pay',
    'net_pay',
    'netbilling',
    'nmi',
    'ogone',
    'openpay',
    'optimal_payments',
    'orbital',
    'pay_conex',
    'pay_junction_v2',
    'payeezy',
    'payex',
    'payflow_pro',
    'payment_express',
    'paymentez',
    'paymill',
    'paypal',
    'paystation',
    'payu_latam',
    'pin',
    'pro_pay',
    'psi_gate',
    'qbms',
    'quantum',
    'quickbooks',
    'quickpay',
    'quickpay_v10',
    'qvalent',
    'realex',
    'redsys',
    's5',
    'safe_charge',
    'sage',
    'sage_pay',
    'secure_net',
    'secure_pay_au',
    'securion_pay',
    'stripe',
    'stripe_payment_intents',
    'test',
    'tns',
    'trans_first',
    'trans_first_transaction_express',
    'trust_commerce',
    'usa_epay',
    'vanco',
    'visanet_peru',
    'wepay',
    'wirecard',
    'worldpay',
    'worldpay_us',
  ],
  proviaEnabled: true,
  salesforceEnabled: true,
  templateTypesAllowed: ['contract', 'proposal', 'custom'],
};

export default defaultPlanJSON;
