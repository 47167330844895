/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React from 'react';
import Parse from 'parse';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import BodyRow from '../BodyRow';
import ContractObject from '../Models/ContractObject';
import BodyCellItem from '../Models/BodyCellItem';
import ImageObject from '../Models/ImageObject';
import {
  setInitialStateForTemplate,
  setEditCellItem,
} from '../../../actions/templateEdit';
import ImagePickerCell from './ImagePickerCell';
import { setOnDragEndCallback } from '../../DropContext';

const ImagePicker = ({
  cellItem,
  setTemplateState,
  contractObject,
  draggableType,
  setEditItem,
}) => {
  // eslint-disable-next-line prefer-const
  let { images, imagesSource } = cellItem;
  if (imagesSource === 'default') {
    images = contractObject.images;
  }
  const droppableId = `${cellItem.objectId}-${ImageObject.ContainerKey}`;
  setOnDragEndCallback(droppableId, async (result) => {
    const imageObjectData = {};
    const config = await Parse.Config.get();
    if (config && config.attributes && config.attributes.defaultPickerImage) {
      const file = config.attributes.defaultPickerImage;
      imageObjectData.file = file;
    }
    const updatedObject = new ContractObject(contractObject.toJSON());
    if (imagesSource === 'default') {
      let sourceObject;
      if (result.draggableId === 'new-image_object') {
        sourceObject = new ImageObject(imageObjectData);
      } else {
        sourceObject = updatedObject.images[result.source.index];
        updatedObject.images.splice(result.source.index, 1);
      }
      updatedObject.images.splice(result.destination.index, 0, sourceObject);
      sourceObject.parent = updatedObject;
      setTemplateState(updatedObject);
      setEditItem(cellItem);
    } else {
      const [destId] = result.destination.droppableId.split('-');
      const destParent = updatedObject.getObjectWithId(destId);
      let sourceObject;
      if (result.draggableId === 'new-image_object') {
        sourceObject = new ImageObject(imageObjectData);
        setEditItem(destParent);
      } else {
        const [sourceId] = result.source.droppableId.split('-');
        const sourceParent = updatedObject.getObjectWithId(sourceId);
        sourceObject = sourceParent.images[result.source.index];
        sourceParent.images.splice(result.source.index, 1);
      }
      destParent.images.splice(result.destination.index, 0, sourceObject);
      sourceObject.parent = destParent;
      setTemplateState(updatedObject);
    }
  });
  return (
    <BodyRow cellItem={cellItem}>
      <div
        className="templates-app__image_selector_container"
        onClick={() => setEditItem(cellItem)}
      >
        <Droppable
          droppableId={droppableId}
          direction="horizontal"
          isDropDisabled={draggableType !== ImageObject.ContainerKey}
        >
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="templates-app__image_selector_scroll-view"
            >
              {images.map((imageObject, index) => (
                <Draggable
                  key={imageObject.id}
                  draggableId={imageObject.id}
                  index={index}
                >
                  {(draggableProvided, draggableSnapshot) => (
                    <div
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                    >
                      <ImagePickerCell
                        imageObject={imageObject}
                        isDefault={imageObject.id === cellItem.defaultImageId}
                        onDelete={(event) => {
                          if (imagesSource !== 'default') {
                            cellItem.images = cellItem.images.filter(
                              (image, i) => i !== index,
                            );
                            const sourceContractObject = cellItem.getSourceObject();
                            setTemplateState(
                              new ContractObject(sourceContractObject.toJSON()),
                            );
                          } else {
                            const updatedObject = new ContractObject(
                              contractObject.toJSON(),
                            );
                            updatedObject.images = contractObject.images.filter(
                              (image, i) => i !== index,
                            );
                            setTemplateState(
                              new ContractObject(updatedObject.toJSON()),
                            );
                          }
                        }}
                        onChange={(event) => {
                          const sourceContractObject = cellItem.getSourceObject();
                          setTemplateState(
                            new ContractObject(sourceContractObject.toJSON()),
                          );
                        }}
                        onDefaultChanged={(id, value) => {
                          if (value) {
                            cellItem.defaultImageId = id;
                          } else {
                            delete cellItem.defaultImageId;
                          }
                          setTemplateState(
                            new ContractObject(contractObject.toJSON()),
                          );
                        }}
                      />
                      {draggableProvided.placeholder}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <div className="templates-app__cell_bottom_border" />
      </div>
    </BodyRow>
  );
};

ImagePicker.propTypes = {
  contractObject: PropTypes.instanceOf(ContractObject).isRequired,
  cellItem: PropTypes.instanceOf(BodyCellItem).isRequired,
  setTemplateState: PropTypes.func.isRequired,
  draggableType: PropTypes.string,
};

ImagePicker.defaultProps = {
  draggableType: undefined,
};

const mapStateToProps = ({ templatesEdit }) => ({
  contractObject: templatesEdit[templatesEdit.currentWorkingId],
  draggableType: templatesEdit.draggableType,
});

const mapDispatchToProps = (dispatch) => ({
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
  setEditItem: (cellItem) => dispatch(setEditCellItem(cellItem)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImagePicker);
