/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';
import Parse from 'parse';
import { Button, Table } from 'react-bootstrap';
import { FileInput, Icon } from '@blueprintjs/core';
import { connect } from 'react-redux';
import isURL from 'validator/lib/isURL';
import Select from '../Misc/Select';
import FormGroup, { TextGroup, DropDownGroup } from '../FormGroup';

import {
  startSaveResource,
  setResourcesDirectory,
} from '../../actions/resources';
import { checkForErrors } from '../AppSettings/ContractSending/Validation/Validator';
import { showErrorAlert, hideAlert } from '../../actions/alert';
import { history } from '../../router';

let newFile;
let newThumbnail;

const fileTypes = [
  {
    value: 'file',
    label: 'File',
  },
  {
    value: 'url',
    label: 'Website',
  },
];

const fileTypeForFileExtension = (extension) => {
  switch (extension.toLowerCase()) {
    case 'pdf':
      return 'pdf';
    case 'ppt':
    case 'pptx':
      return 'ppt';
    case 'epub':
      return 'epub';
    case 'aac':
    case 'ac3':
    case 'aif':
    case 'aiff':
    case 'aifc':
    case 'caf':
    case 'mp3':
    case 'mp4':
    case 'm4a':
    case 'snd':
    case 'au':
    case 'sd2':
    case 'wav':
    case 'mov':
    case 'm4v':
    case '3gp':
      return 'avf';
    default:
      return '';
  }
};

const fileTypeForDropDown = (fileType) => {
  switch (fileType) {
    case 'pdf':
    case 'ppt':
    case 'epub':
    case 'avf':
      return 'file';
    default:
      return fileType;
  }
};

const validationErrors = ({ item }) => {
  let url = '';
  if (item.fileType === 'url' && item.url) {
    if (!isURL(item.url)) {
      url = 'Invalid URL';
    }
  }
  const buttons = item.fileType === 'pdf' ? item.buttons || [] : [];
  const errors = {
    item: {
      url,
      displayName: item.displayName ? '' : 'You must specify a name',
      fileType: item.fileType ? '' : 'You must specify a file type',
      buttons: buttons.map(({ pageNumber, title }) => ({
        pageNumber: pageNumber > 0 ? '' : 'Invalid',
        title: title ? '' : 'You must specify a Tab Title',
      })),
    },
  };
  return errors;
};

class NewResourceModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: {
        displayName: '',
        includedOffices: [],
      },
      filePlaceholder: 'Choose File...',
      thumbnailPlaceholder: 'Choose Thumbnail...',
    };
    this.state.errors = validationErrors(this.state);
  }

  async UNSAFE_componentWillMount() {
    const item = {
      fileType: 'pdf',
      displayName: this.props.directory ? `${this.props.directory}/` : '',
      includedOffices: [],
      buttons: [],
    };
    const fileURL = '/images/no_image.png';
    const errors = validationErrors({ item });
    this.setState({ item, fileURL, errors });
  }

  onFileChanged = (e) => {
    const file = e.target.files[0];
    const reader = new window.FileReader();
    reader.onload = (onLoad) => {
      this.setState({ filePlaceholder: file.name });
    };
    reader.readAsDataURL(file);
    const extension = file.name.split('.').pop();
    const name = `resource.${extension}`;
    newFile = new Parse.File(name, file);
    const fileType = fileTypeForFileExtension(extension);
    const item = { ...this.state.item };
    item.fileType = fileType;
    const errors = validationErrors({ item });
    this.setState({ item, errors });
  };

  onThumbnailChanged = (e) => {
    const file = e.target.files[0];
    const reader = new window.FileReader();
    reader.onload = (onLoad) => {
      this.setState({
        fileURL: onLoad.target.result,
        thumbnailPlaceholder: file.name,
      });
    };
    reader.readAsDataURL(file);
    const extension = file.name.split('.').pop();
    const name = `thumbnail.${extension}`;
    newThumbnail = new Parse.File(name, file);
  };

  onTypeChanged = (type) => {
    const item = { ...this.state.item };
    item.fileType = type.value;
    const errors = validationErrors({ item });
    this.setState({ item, errors });
  };

  onNameChanged = (value) => {
    if (value.indexOf('/') > -1) {
      return;
    }
    const item = { ...this.state.item };
    const folders = item.displayName.split('/');
    folders.pop();
    if (folders.length) {
      const displayName = folders.join('/');
      item.displayName = `${displayName}/${value}`;
    } else {
      item.displayName = value;
    }
    const errors = validationErrors({ item });
    this.setState({ item, errors });
  };

  onURLChanged = (value) => {
    const item = { ...this.state.item };
    item.url = value;
    const errors = validationErrors({ item });
    this.setState({ item, errors });
  };

  onIncludedOfficesChanged = (value) => {
    const item = { ...this.state.item };
    item.includedOffices = value.map((id) => ({ objectId: id }));
    const errors = validationErrors({ item });
    this.setState({ item, errors });
  };

  onAddTab = () => {
    const item = { ...this.state.item };
    item.buttons = item.buttons || [];
    item.buttons.unshift({
      pageNumber: 0,
      title: '',
    });
    const errors = validationErrors({ item });
    this.setState({ item, errors });
  };

  onTabChange = (value, index, key) => {
    const item = { ...this.state.item };
    item.buttons = item.buttons.map((button, i) => {
      if (i === index) {
        return {
          ...button,
          [key]: value,
        };
      }
      return button;
    });
    const errors = validationErrors({ item });
    this.setState({ item, errors });
  };

  onDeleteTab = (index) => {
    const item = { ...this.state.item };
    item.buttons.splice(index, 1);
    const errors = validationErrors({ item });
    this.setState({ item, errors });
  };

  onSaveClicked = () => {
    if (checkForErrors(this.state.errors)) {
      this.props.showErrorAlert({
        title: 'Error',
        message: 'Please fix all validation errors',
        onConfirm: this.props.hideAlert,
      });
      return;
    }
    this.props.startSaveResource(this.state.item, newFile, newThumbnail);
  };

  onBreadCrumbClicked = (index) => {
    const directories = this.state.item.displayName.split('/');
    if (index === directories.length) {
      return;
    }
    const updatedDirectories = [];
    for (let i = 0; i < index; i += 1) {
      updatedDirectories.push(directories[i]);
    }
    const directory = updatedDirectories.join('/');
    this.props.setResourcesDirectory(directory);
    history.push('/resources');
  };

  onSubmit = () => {
    if (checkForErrors(this.state.errors)) {
      return;
    }
    this.props.onSubmit(this.state.item, newFile, newThumbnail);
  };

  render() {
    const buttons = this.state.item.buttons || [];
    const pageNumbers = [];
    if (this.state.item.fileType === 'pdf') {
      for (let i = 0; i < this.state.item.pageNumber; i += 1) {
        pageNumbers.push({
          value: i + 1,
          label: i + 1,
        });
      }
    }
    return (
      <div>
        <img
          alt="Thumbnail"
          src={this.state.fileURL}
          className="resources-thumbnail"
        />
        <FormGroup title="Thumbnail">
          <FileInput
            text={this.state.thumbnailPlaceholder}
            onChange={this.onThumbnailChanged}
            inputProps={{ accept: 'image/*' }}
            fill
            large
          />
        </FormGroup>
        <DropDownGroup
          title="Resource Type"
          value={
            fileTypeForDropDown(this.state.item.fileType) || fileTypes[0].value
          }
          onChange={this.onTypeChanged}
          closeMenuOnSelect
          options={fileTypes}
          isClearable={false}
          errorMessage={this.state.errors.item.fileType}
        />
        <TextGroup
          title="Name"
          value={this.state.item.displayName.split('/').pop()}
          placeholder="Name"
          onChange={this.onNameChanged}
          errorMessage={this.state.errors.item.displayName}
        />
        <div>
          {this.state.item.fileType !== 'url' &&
            this.state.item.fileType !== 'folder' && (
              <FormGroup title="File">
                <FileInput
                  text={this.state.filePlaceholder}
                  onChange={this.onFileChanged}
                  inputProps={{
                    accept:
                      '.pdf, .epub, .ppt, .pptx, .mp4, .aac, .ac3, .aif, .aiff, .aifc, .caf, .mp3, .m4a, .snd, .au, .sd2, .wav, .mov, .m4v, .3gp',
                  }}
                  fill
                  large
                />
              </FormGroup>
            )}
        </div>
        <div>
          {this.state.item.fileType === 'url' && (
            <TextGroup
              title="URL"
              value={this.state.item.url}
              placeholder="URL"
              onChange={this.onURLChanged}
              errorMessage={this.state.errors.url}
            />
          )}
        </div>
        <div>
          {this.state.item.fileType === 'pdf' && (
            <FormGroup title="Tabs">
              <Table striped bordered hover className="resources-tabs-table">
                <thead>
                  <tr>
                    <th style={{ textAlign: 'center', width: '80px' }}>Pg #</th>
                    <th style={{ textAlign: 'center' }}>Tab Title</th>
                    <th style={{ textAlign: 'center', width: '25px' }}>
                      <button type="button" onClick={this.onAddTab}>
                        Add
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {buttons.map(({ pageNumber, title }, index) => (
                    <tr key={`${pageNumber}${title}`}>
                      <td>
                        <div
                          className={
                            this.state.errors.item.buttons[index].pageNumber
                              ? 'error-border'
                              : ''
                          }
                        >
                          <Select
                            name="form-field-name"
                            value={pageNumber}
                            onChange={({ value }) =>
                              this.onTabChange(value, index, 'pageNumber')
                            }
                            closeMenuOnSelect
                            isClearable={false}
                            options={pageNumbers}
                            placeholder=""
                          />
                        </div>
                        <div className="error">
                          {this.state.errors.item.buttons[index].pageNumber}
                        </div>
                      </td>
                      <td>
                        <input
                          value={title}
                          onChange={(e) =>
                            this.onTabChange(e.target.value, index, 'title')
                          }
                          className={`resources__tab-title${
                            this.state.errors.item.buttons[index].title
                              ? ' error-border'
                              : ''
                          }`}
                        />
                        <div className="error">
                          {this.state.errors.item.buttons[index].title}
                        </div>
                      </td>
                      <td>
                        <Icon
                          icon="trash"
                          intent="danger"
                          onClick={() => this.onDeleteTab(index)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </FormGroup>
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={this.onSubmit} variant="danger">
            Submit
          </Button>
        </div>
      </div>
    );
  }
}

NewResourceModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  startSaveResource: PropTypes.func.isRequired,
  directory: PropTypes.string,
  showErrorAlert: PropTypes.func.isRequired,
  hideAlert: PropTypes.func.isRequired,
  setResourcesDirectory: PropTypes.func.isRequired,
};

NewResourceModal.defaultProps = {
  directory: '',
};

const mapStateToProps = ({ resources: { directory } }) => ({
  directory,
});

const mapDispatchToProps = (dispatch) => ({
  startSaveResource: (resource, file, thumbnail) =>
    dispatch(startSaveResource(resource, file, thumbnail)),
  showErrorAlert: (params) => dispatch(showErrorAlert(params)),
  hideAlert: () => dispatch(hideAlert()),
  setResourcesDirectory: (directory) =>
    dispatch(setResourcesDirectory(directory)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewResourceModal);
