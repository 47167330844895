/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { startLoadChangeLogs } from '../actions/changeLogs';
import Panel from './Panel';

class PublicChangeLogView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    this.loadChangeLogs();
  };

  loadChangeLogs = () => {
    this.props.startLoadChangeLogs({});
  };

  render() {
    return (
      // < title="Change Logs">
      <Panel title="Change Log">
        {this.props.logs.map((log) => (
          <p>{log.message}</p>
        ))}
      </Panel>
    );
  }
}
PublicChangeLogView.propTypes = {
  logs: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ).isRequired,
  startLoadChangeLogs: PropTypes.func.isRequired,
};

PublicChangeLogView.defaultProps = {};

const mapStateToProps = ({ changeLogs: { logs } }) => ({
  logs,
});

const mapDispatchToProps = (dispatch) => ({
  startLoadChangeLogs: (params) => dispatch(startLoadChangeLogs(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PublicChangeLogView);
