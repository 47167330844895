import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

export const ValidationLabel = (props) => {
  const { className = '', children, errorMessage } = props;

  return (
    <Form.Label className={`${className}`}>
      {children}
      <ErrorMessage errorMessage={errorMessage} />
    </Form.Label>
  );
};

ValidationLabel.defaultProps = {
  errorMessage: '',
  className: '',
};
ValidationLabel.propTypes = {
  errorMessage: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.shape({}).isRequired,
};

export const ErrorMessage = (props) => {
  const { className = '', errorMessage } = props;

  return errorMessage ? (
    <span className={`${className} danger`}> - {errorMessage}</span>
  ) : (
    ''
  );
};
ErrorMessage.defaultProps = {
  errorMessage: '',
  className: '',
};
ErrorMessage.propTypes = {
  errorMessage: PropTypes.string,
  className: PropTypes.string,
};

const ValidationForm = (props) => {
  const { title, className = '', children, errorMessage } = props;

  return (
    <Form.Group className={`${className} ${errorMessage ? 'has-error' : ''}`}>
      {title && (
        <ValidationLabel errorMessage={errorMessage}>{title}</ValidationLabel>
      )}
      {children}
    </Form.Group>
  );
};
ValidationForm.defaultProps = {
  errorMessage: '',
  className: '',
  title: '',
};
ValidationForm.propTypes = {
  errorMessage: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.shape({}).isRequired,
};

export default ValidationForm;
