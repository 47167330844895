/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { getListStyle, getItemStyle } from '../../Templates/Utils';
import {
  setCreditApplication,
  setEditCellItem,
} from '../../../actions/creditApplications';
import { onContextMenu } from './onContextMenu';
import { setCallbackForDragEnd } from './setCallbackForDragEnd';

const MiddleSection = ({
  sectionId,
  application,
  cell: currentCell,
  setEditCellItem2,
  leftCells,
  rightCells,
  onCellClicked,
  setApplication,
  commit,
}) => {
  setCallbackForDragEnd(
    `${sectionId}-left`,
    application,
    setApplication,
    commit,
  );
  setCallbackForDragEnd(
    `${sectionId}-right`,
    application,
    setApplication,
    commit,
  );
  return (
    <div className="credit-apps__section-middle-container">
      <div className="credit-apps__section">
        <Droppable droppableId={`${sectionId}-left`} isDropDisabled={false}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={getListStyle(
                snapshot.isDraggingOver,
                snapshot.isDraggingOver,
              )}
            >
              {leftCells.map((cell, index) => (
                <Draggable
                  key={`${sectionId}-left-${index}`}
                  draggableId={`${sectionId}-left-${index}`}
                  index={index}
                >
                  {(draggableProvided, draggableSnapshot) => (
                    <div
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.dragHandleProps}
                      {...draggableProvided.draggableProps}
                      style={{
                        ...getItemStyle(
                          draggableSnapshot,
                          draggableProvided.draggableProps.style,
                          currentCell.cell &&
                            cell.objectId === currentCell.cell.objectId,
                        ),
                        translate: 'translateX(-100%)',
                      }}
                      className="credit-apps__cell-container"
                      key={`left-${index}`}
                      onMouseDown={(e) => {
                        onCellClicked(
                          cell,
                          sectionId === 'applicationInfo' ? 0 : 1,
                          sectionId !== 'applicationInfo' ? index : 2 * index,
                        );
                        draggableProvided.dragHandleProps.onMouseDown(e);
                      }}
                    >
                      <div
                        className="credit-apps__cell-container"
                        onContextMenu={(e) =>
                          onContextMenu(e, {
                            sectionId,
                            application,
                            cellItem: currentCell,
                            leftCells,
                            rightCells,
                            setApplication,
                            commit,
                            setEditCellItem: setEditCellItem2,
                          })
                        }
                      >
                        <span className="credit-apps__cell-title">
                          {cell.title}
                        </span>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
      <div className="credit-apps__section">
        <Droppable droppableId={`${sectionId}-right`} isDropDisabled={false}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={getListStyle(
                snapshot.isDraggingOver || snapshot.draggingOverWith,
                snapshot.isDraggingOver,
              )}
            >
              {rightCells.map((cell, index) => (
                <Draggable
                  key={`${sectionId}-right-${index}`}
                  draggableId={`${sectionId}-right-${index}`}
                  index={index}
                >
                  {(draggableProvided, draggableSnapshot) => (
                    <div
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.dragHandleProps}
                      {...draggableProvided.draggableProps}
                      style={{
                        ...getItemStyle(
                          draggableSnapshot,
                          draggableProvided.draggableProps.style,
                          currentCell.cell &&
                            cell.objectId === currentCell.cell.objectId,
                        ),
                        translate: 'translateX(-100%)',
                      }}
                      className="credit-apps__cell-container"
                      key={`right-${index}`}
                      onMouseDown={(e) => {
                        onCellClicked(
                          cell,
                          sectionId === 'applicationInfo' ? 0 : 2,
                          sectionId !== 'applicationInfo'
                            ? index
                            : 2 * index + 1,
                        );
                        draggableProvided.dragHandleProps.onMouseDown(e);
                      }}
                    >
                      <div
                        className="credit-apps__cell-container"
                        onContextMenu={(e) =>
                          onContextMenu(e, {
                            sectionId,
                            application,
                            cellItem: currentCell,
                            leftCells,
                            rightCells,
                            setApplication,
                            commit,
                            setEditCellItem: setEditCellItem2,
                          })
                        }
                      >
                        <span className="credit-apps__cell-title">
                          {cell.title}
                        </span>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </div>
  );
};

MiddleSection.propTypes = {
  leftCells: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
    }),
  ),
  rightCells: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
    }),
  ),
  currentCell: PropTypes.shape({
    title: PropTypes.string,
  }),
};

MiddleSection.defaultProps = {
  leftCells: [],
  rightCells: [],
  cell: {},
};

const mapDispatchToProps = (dispatch) => ({
  setApplication: (application) => dispatch(setCreditApplication(application)),
  setEditCellItem2: (editCellItem) => dispatch(setEditCellItem(editCellItem)),
});

const mapStateToProps = ({ creditApplications }, ownProps) => ({
  cell: creditApplications.editCellItem,
  commit: creditApplications.commit,
});

export default connect(mapStateToProps, mapDispatchToProps)(MiddleSection);
