import { randomKey } from '../../../utils/utils';
import HeaderGroup from './HeaderGroup';

const containerKey = 'header_section';
const pasteKey = 'index_section';

class HeaderSection {
  constructor(section, parent) {
    this.keys = ['objectId', 'data', 'groupType'];
    this.containerKey = containerKey;
    this.pasteKey = pasteKey;
    this.JSON = section;
    this.parent = parent;
    this.showToolbar = false;
    this.toolboxKey = '';
    if (section) {
      Object.assign(this, section);
      this.objectId = this.objectId || randomKey(10);
      this.data = section.data.map(
        (sectionGroup) => new HeaderGroup(sectionGroup, this),
      );
    } else {
      this.data = [new HeaderGroup(undefined, this)];
      this.groupType = 'header';
    }
    if (!this.objectId) {
      this.objectId = randomKey(10);
    }
    const source = this.getSourceObject();
    if (source) {
      if (source.hasObjectId(this.objectId)) {
        this.objectId = randomKey(10);
      }
      source.addObjectId(this.objectId);
    }
  }

  get identifier() {
    return `${this.parent.identifier}-${this.objectId}`;
  }

  getPath() {
    if (!this.parent) {
      return undefined;
    }
    const index = this.parent.contractData.findIndex(
      ({ objectId }) => objectId === this.objectId,
    );
    return [index];
  }

  getSourceObject() {
    if (!this.parent) {
      return undefined;
    }
    return this.parent.getSourceObject();
  }

  clone() {
    const jsonObject = this.toJSON();
    const json = JSON.parse(JSON.stringify(jsonObject));
    json.objectId = randomKey(10);
    const headerSection = new HeaderSection(json, this.parent);
    headerSection.data = headerSection.data.map((group) => group.clone());
    return headerSection;
  }

  toJSON() {
    const json = {};
    this.keys.forEach((key) => {
      json[key] = this[key];
    });
    json.data = this.data.map((obj) => obj.toJSON());
    return json;
  }

  insertHeaderSectionAtIndex(index = this.data.length, obj) {
    const updatedData = [...this.data];
    const newObj = obj || new HeaderGroup(undefined, this);
    updatedData.splice(index, 0, newObj);
    return newObj;
  }

  convertNewFiles() {
    const data = this.data || [];
    const promises = data.map((obj) => obj.convertNewFiles());
    return Promise.all(promises);
  }

  static ContainerKey = containerKey;

  static PasteKey = pasteKey;
}

export default HeaderSection;
