import React from 'react';

import fastdom from 'fastdom';

const GlobalStickyContext = {};
let isScrolling = null;

class StickyContext {
  stickySet = [];

  bumpGroups = {};

  scrollVelosity = 0;

  topStickHeight = 0;

  viewables = {};

  lastSrollTop = 0;

  stackList = [];

  stackHeights = {};

  disabled = [];

  holdUps = {};

  cutOffItems = {};

  scrollElementRect = {};

  dispatch = {};

  getState = () => {};

  scrollElementRef = React.createRef();

  constructor(scrollElementId) {
    this.scrollElement = document.getElementById(scrollElementId);
  }

  setScrollElementRef = (elementRef, update) => {
    this.scrollElementRef = elementRef;
    this.scrollElementRect = elementRef.getBoundingClientRect();
    this.scrollElementRef.onscroll = () => {
      this.scrollVelosity = elementRef.scrollTop - this.lastSrollTop;
      this.lastSrollTop = elementRef.scrollTop;
      window.clearTimeout(isScrolling);
      isScrolling = setTimeout(() => {
        this.scrollElementRect = this.scrollElementRef.getBoundingClientRect();
        update({});
      }, 100);
      const { priceGuide2 } = this.getState();
      const { disableSticky } = priceGuide2;
      if (!disableSticky) {
        this.checkStickies();
      }
    };
  };

  injectRedux({ dispatch, getState }) {
    this.dispatch = dispatch;
    this.getState = getState;
  }

  resetContext = () => {
    this.stickySet = [];
    this.bumpGroups = {};
    this.scrollVelosity = 0;
    this.topStickHeight = 0;
    this.viewables = {};
    this.lastSrollTop = 0;
    this.stackList = [];
    this.stackHeights = {};
    this.disabled = [];
    this.holdUps = {};
    this.cutOffItems = {};
    this.scrollElementRect = {};
  };

  registerCutOff = (data) => {
    if (data.holdUp) {
      const holdUp = this.holdUps[data.holdUp];
      if (!holdUp) {
        this.holdUps[data.holdUp] = {};
      }
      this.holdUps[data.holdUp][data.uid] = data;
    }
    this.cutOffItems[data.uid] = data.ref;
  };

  enable = (uid) => {
    this.disabled = this.disabled.filter((itemuid) => itemuid !== uid);
  };

  disable = (uid) => {
    this.disabled.push(uid);
  };

  checkStickGroup = (sticky) => {
    let stickHeight = -1;
    if (sticky.stickTo && sticky.stickTo.type === 'height') {
      stickHeight = sticky.stickTo.value;
    }
    if (sticky.stickTo && sticky.stickTo.type === 'elementId') {
      const stickToRefElement = document.getElementById(sticky.stickTo.value);
      if (stickToRefElement) {
        const stickToRefElementRect = stickToRefElement.getBoundingClientRect();
        const stickToRefElementRectBottom = stickToRefElementRect.bottom;
        stickHeight = Math.max(stickToRefElementRectBottom, 0);
      }
    }
    if (sticky.stickTo && sticky.stickTo.type === 'group') {
      const stickToBumpGroup = this.bumpGroups[sticky.stickTo.value];
      if (stickToBumpGroup && stickToBumpGroup.uid !== sticky.uid) {
        stickHeight =
          this.stackHeights[sticky.stackOrder].displacement +
          this.topStickHeight;
        if (sticky.bumpType === 'upcharge-header') {
          const follow = this.stickySet.filter(
            (sitckySetItem) =>
              sitckySetItem.stickTo.value === sticky.stickTo.value,
          );
          const followIndex = follow
            .map((cohort) => cohort.uid)
            .indexOf(sticky.uid);
          stickHeight += 35 * followIndex;
        }
      }
    }
    if (sticky.topRef) {
      this.topStickHeight = stickHeight;
    }
    return { stickHeight };
  };

  checkStickies = () => {
    Object.keys(this.bumpGroups)
      .filter(
        (bumpKey) =>
          this.bumpGroups[bumpKey] &&
          this.bumpGroups[bumpKey].elementRef.current,
      )
      .forEach((bumpKey) => {
        this.bumpGroups[bumpKey] = {
          ...this.bumpGroups[bumpKey],
          rect: this.bumpGroups[
            bumpKey
          ].elementRef.current.getBoundingClientRect(),
        };
      });
    Object.keys(this.cutOffItems)
      .filter((key) => this.cutOffItems[key])
      .forEach((key) => {
        this.cutOffItems[key].preRect = this.cutOffItems[
          key
        ].getBoundingClientRect();
      });
    this.stickySet = this.stickySet.map((stickyItem) => {
      let rect;
      const stickyRef = stickyItem.elementRef.current;
      if (stickyRef) {
        rect = stickyRef.getBoundingClientRect();
      }
      const bumpGroup = this.bumpGroups[stickyItem.bumpType];
      let { bumpGroupTopRect } = stickyItem;
      if (bumpGroup && bumpGroup.elementRef.current) {
        bumpGroupTopRect = bumpGroup.rect;
      }
      const { stickHeight } = this.checkStickGroup(stickyItem);
      const match = this.cutOffItems[stickyItem.uid];

      let matchbottom;
      if (match) {
        matchbottom = match.preRect.bottom;
      }

      return {
        ...stickyItem,
        rect,
        stickHeight,
        matchbottom,
        bumpGroupTopRect,
      };
    });

    this.stickySet.forEach((sticky) => {
      let specialZ = null;
      const stickyRef = sticky.elementRef.current;

      if (stickyRef) {
        const stickyRefRect = sticky.rect;
        const [firstChild] = stickyRef.childNodes;

        let disabled = this.disabled.indexOf(sticky.uid) > -1;

        let { stickHeight } = sticky;

        let match = this.cutOffItems[sticky.uid];
        const holdUps = this.holdUps[sticky.waitFor];
        let bumpGroup = this.bumpGroups[sticky.bumpType];
        if (sticky.waitFor && holdUps) {
          const stickyWaitForCohorts = this.stickySet.filter(
            (sitckySetItem) => sitckySetItem.waitFor === sticky.waitFor,
          );
          const stickyCohortListIndex = stickyWaitForCohorts
            .map((cohort) => cohort.uid)
            .indexOf(sticky.uid);
          stickHeight += stickyRefRect.height * stickyCohortListIndex;
          match = null;
          disabled = false;
          bumpGroup = null;
        }
        let ydiff = stickHeight - stickyRefRect.top;
        if (match) {
          const { matchbottom } = sticky;
          if (stickyRefRect.bottom !== matchbottom) {
            if (matchbottom < stickHeight + stickyRefRect.height) {
              const realDiff = Math.abs(matchbottom - stickyRefRect.top);
              ydiff = realDiff - stickyRefRect.height;
              specialZ = 10;
            }
          }
        }
        if (stickHeight >= 0 && stickyRefRect.top < stickHeight && !disabled) {
          // hit top limit
          if (
            bumpGroup &&
            bumpGroup.uid !== sticky.uid &&
            bumpGroup.elementRef.current &&
            sticky.bumpGroupTopRect
          ) {
            const { bumpGroupTopRect } = sticky;
            if (bumpGroupTopRect.top - stickyRefRect.top < 0) {
              fastdom.mutate(() => {
                stickyRef.style.zIndex = specialZ || 500;
                firstChild.style.top = `${ydiff}px`;
              });
              this.bumpGroups[sticky.bumpType] = sticky;
            } else {
              fastdom.mutate(() => {
                stickyRef.style.zIndex = specialZ || 0;
                if (
                  firstChild.style.top !== 0 ||
                  firstChild.style.top !== `${0}px`
                ) {
                  firstChild.style.top = `${0}px`;
                }
              });
            }
          } else {
            fastdom.mutate(() => {
              stickyRef.style.zIndex = specialZ || 500;
              firstChild.style.top = `${ydiff}px`;
            });
            this.bumpGroups[sticky.bumpType] = sticky;
          }
        } else {
          // if below limit
          bumpGroup = this.bumpGroups[sticky.bumpType];
          if (bumpGroup && bumpGroup.uid === sticky.uid) {
            // remove from bump group if there
            this.bumpGroups[sticky.bumpType] = null;
          }
          if (firstChild.style.top !== 0 || firstChild.style.top !== `${0}px`) {
            fastdom.mutate(() => {
              firstChild.style.top = `${0}px`;
            });
          }
        } // main limit if
      } // check for element in dom
    });
  };

  setStackOrder = (event) => {
    if (!this.stackHeights[event.stackOrder]) {
      const order = event.stackOrder;

      const displacement = this.stackList.reduce(
        (result, { height }) => result + height,
        0,
      );

      const { height } = event.elementRef.current.getBoundingClientRect();
      this.stackList.push({
        order,
        height,
        bumpType: event.bumpType,
        displacement,
      });
      this.stackHeights[event.stackOrder] = {
        order,
        height,
        bumpType: event.bumpType,
        displacement,
      };
    }
  };

  registerSticky = (event) => {
    this.setStackOrder(event);
    if (event.topRef) {
      this.topRef = event.elementRef;
    }
    this.stickySet = this.stickySet.filter(
      (sticky) => sticky.uid !== event.uid,
    );
    this.stickySet.push({ ...event });
  };
}

const initContext = (scrollElementId) => {
  const context =
    GlobalStickyContext[scrollElementId] || new StickyContext(scrollElementId);
  GlobalStickyContext[scrollElementId] = context;
  return context;
};

export default initContext;
