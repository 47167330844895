export const defaultActiveColumns = {
  settings: false,
  additionalDetails: false,
  placeholders: false,
  showToggle: false,
  image: false,
  category: false,
  subCategory: false,
  subSubCategories: true,
  name: false,
  note: false,
  measurementType: false,
  offices: false,
  formulaId: true,
  formula: true,
  optionPlaceholders: false,
  optionBrand: false,
  optionName: false,
  optionPrices: false,
  optionSelect: false,
  upChargeSettings: false,
  upChargeAdditionalDetails: false,
  upChargePlaceholders: false,
  upChargeImage: false,
  upChargeName: false,
  upChargeNote: false,
  upChargeMeasurement: false,
  upChargeShowToggle: false,
  upChargePercentagePrice: false,
  upChargeDisabled: false,
  upChargeIdentifier: false,
  upChargePrices: false,
  upChargeSelect: false,
};

export const defaultState = () => {
  const cachedSubategories =
    localStorage.getItem('price_guide_selected_subCategories') || '[]';
  const cachedCategories =
    localStorage.getItem('price_guide_selected_categories') || '[]';
  const cachedOffices =
    localStorage.getItem('price_guide_included_offices') || '[]';
  let defaultSubCategories;
  let defaultCategories;
  let defaultOffices;
  try {
    defaultCategories = JSON.parse(cachedCategories);
    if (!Array.isArray(defaultCategories)) {
      defaultCategories = [];
    }
  } catch (e) {
    defaultCategories = [];
  }
  try {
    defaultSubCategories = JSON.parse(cachedSubategories);
    if (!Array.isArray(defaultSubCategories)) {
      defaultSubCategories = [];
    }
  } catch (e) {
    defaultSubCategories = [];
  }
  try {
    defaultOffices = JSON.parse(cachedOffices);
    if (!Array.isArray(defaultOffices)) {
      defaultOffices = [];
    }
  } catch (e) {
    defaultOffices = [];
  }
  return {
    disableSticky: true,
    categories: [],
    selectedSubCategories: defaultSubCategories,
    selectedCategories: defaultCategories,
    includedOffices: defaultOffices,
    page: 1,
    limit: 25,
    count: 0,
    items: {},
    itemIds: [],
    columnWidths: {
      items: {
        dropDown: 35,
        add: 35,
        settings: 35,
        additionalDetails: 35,
        showToggle: 35,
        image: 60,
        category: 200,
        subCategory: 200,
        subSubCategories: 200,
        name: 200,
        note: 200,
        measurementType: 200,
        offices: 200,
        formulaId: 200,
        formula: 200,
      },
      options: {
        dropDown: 35,
        optionSelect: 35,
        optionPlaceholders: 35,
        optionBrand: 200,
        optionName: 200,
        optionPrices: 200,
      },
    },
    activeColumns: {
      ...defaultActiveColumns,
    },
  };
};

export default (state = defaultState(), { type, ...rest }) => {
  switch (type) {
    case 'LOGOUT': {
      return defaultState();
    }
    case 'TOGGLE_STICKY_HEADERS':
      return {
        ...state,
        disableSticky: rest.disableSticky,
      };
    case 'PRICEGUIDE_RESET':
      localStorage.removeItem('price_guide_selected_categories');
      localStorage.removeItem('price_guide_selected_subCategories');
      localStorage.removeItem('price_guide_included_offices');
      return defaultState();
    case 'SET_PRICEGUIDE_PAGE':
      return {
        ...state,
        page: rest.page,
      };
    case 'SET_PRICEGUIDE_LIMIT':
      return {
        ...state,
        limit: rest.limit,
      };
    case 'SET_MSI_COUNT':
      return {
        ...state,
        count: rest.count,
      };
    case 'SET_OTHER_PAGE_ITEM':
      return {
        ...state,
        otherPageItem: rest.otherPageItem,
      };
    case 'SET_MSI_ITEMS': {
      const items = state.items || {};
      const itemIds = [];
      rest.items.forEach((item) => {
        items[item.id] = item;
        itemIds.push(item.id);
      });
      return {
        ...state,
        items,
        itemIds,
      };
    }
    case 'SET_MSI_ITEM':
      return {
        ...state,
        items: {
          ...state.items,
          [rest.item.id]: rest.item,
        },
      };
    case 'DELETE_MSI_ITEM': {
      const updatedItems = { ...state.items };
      delete updatedItems[rest.item.id];
      return {
        ...state,
        items: updatedItems,
        itemIds: state.itemIds.filter((id) => id !== rest.item.id),
      };
    }
    case 'SET_MSI_ITEM_IDS':
      return {
        ...state,
        itemIds: rest.ids,
      };
    case 'SET_PRICEGUIDE_CATEGORIES':
      return {
        ...state,
        categories: rest.categories,
      };
    case 'SET_PRICEGUIDE_SUBCATEGORIES':
      return {
        ...state,
        subCategories: rest.subCategories,
      };
    case 'SET_PRICEGUIDE_SELECTED_CATEGORIES': {
      localStorage.setItem(
        'price_guide_selected_categories',
        JSON.stringify(rest.categories),
      );
      return {
        ...state,
        selectedCategories: rest.categories,
      };
    }
    case 'SET_PRICEGUIDE_SELECTED_SUBCATEGORIES': {
      localStorage.setItem(
        'price_guide_selected_subCategories',
        JSON.stringify(rest.subCategories),
      );
      return {
        ...state,
        selectedSubCategories: rest.subCategories,
      };
    }
    case 'SET_PRICEGUIDE_INCLUDED_OFFICES': {
      localStorage.setItem(
        'price_guide_included_offices',
        JSON.stringify(rest.offices),
      );
      return {
        ...state,
        includedOffices: rest.offices,
      };
    }
    case 'SET_PRICEGUIDE_COLUMN_WIDTH':
      return {
        ...state,
        columnWidths: {
          ...state.columnWidths,
          [rest.columnKey]: rest.width,
        },
      };
    case 'PRICE_GUIDE_SET_ACTIVE_COLUMNS':
      return {
        ...state,
        activeColumns: rest.activeColumns,
      };
    case 'SET_PRICE_GUIDE_ITEM_COLUMN_WIDTH':
      return {
        ...state,
        columnWidths: {
          ...state.columnWidths,
          items: {
            ...state.columnWidths.items,
            [rest.key]: rest.width,
          },
        },
      };
    case 'SET_PRICEGUIDE_OPTION_COLUMN_WIDTH':
      return {
        ...state,
        columnWidths: {
          ...state.columnWidths,
          options: {
            ...state.columnWidths.options,
            [rest.key]: rest.width,
          },
        },
      };
    case 'SET_PRICEGUIDE_OPTIONS':
      return {
        ...state,
        options: {
          ...state.options,
          ...rest.options,
        },
      };
    case 'SET_PRICEGUIDE_OPTION':
      return {
        ...state,
        options: {
          ...state.options,
          [rest.option.id]: rest.option,
        },
      };
    case 'SET_PRICEGUIDE_ADDITIONAL_DETAILS_EDIT':
      return {
        ...state,
        additionalDetails: rest.additionalDetails,
      };
    case 'SET_PRICEGUIDE_PLACEHOLDERS_EDIT':
      return {
        ...state,
        placeholders: rest.placeholders,
      };
    case 'SET_PRICEGUIDE_UPCHARGES':
      return {
        ...state,
        upCharges: {
          ...state.upCharges,
          ...rest.upCharges,
        },
      };
    case 'SET_PRICEGUIDE_UPCHARGE':
      return {
        ...state,
        upCharges: {
          ...state.upCharges,
          [rest.upCharge.id]: rest.upCharge,
        },
      };
    case 'SET_PRICEGUIDE_UPCHARGE_EDIT_MODAL':
      return {
        ...state,
        upChargeEdit: rest.object,
      };
    case 'SET_PRICEGUIDE_ADDITIONAL_DETAILS_OBJECTS':
      return {
        ...state,
        additionalDetails: {
          ...state.additionalDetails,
          objects: rest.objects,
        },
      };
    default:
      return state;
  }
};
