import React, { useContext, useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { LayoutContext } from '../Layout';
import ResourcesIndex from './ResourcesIndex';
import ResourcesEdit from './Edit';
import SharedResources from './SharedResources';

const Resources = () => {
  const { path } = useRouteMatch();
  const { setCrumbs, setButtons } = useContext(LayoutContext);

  useEffect(() => {
    const crumb = { title: 'Resources', link: path };
    setCrumbs([crumb]);

    return () => {
      setCrumbs([]);
      setButtons();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Switch>
      <Route
        path={[`${path}/edit/:id`, `${path}/new`]}
        component={ResourcesEdit}
      />
      <Route path={`${path}/shared_resources`} component={SharedResources} />,
      <Route path={path} component={ResourcesIndex} />
    </Switch>
  );
};

export default Resources;
