/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { FileInput } from '@blueprintjs/core';
import FormGroup from './FormGroup';
import TitleButton from './TitleButton';
// import { startImportPriceGuide } from '../actions/priceGuide2';
import { handleError } from '../actions/auth';

export const fileDataFromFileChange = (e, modal) =>
  new Promise((resolve) => {
    const file = e.target.files[0];
    const { name } = file;
    const [type] = name.split('.').reverse();
    if (type.toLowerCase() !== 'xlsx') {
      modal.props.handleError({
        message:
          'The price guide importer only supports using Excel .xlsx files',
      });
    } else {
      if (modal) {
        modal.setState(() => ({ fileName: file.name }));
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
    }
  });

class ImportModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: props.show,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ show }) {
    this.setState({ show });
  }

  onFileChange = async (e) => {
    try {
      const toImport = await fileDataFromFileChange(e, this);
      const regex = new RegExp(/:.*;/);
      const formatted = toImport.replace(
        regex,
        ':application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
      );
      this.setState(() => ({ toImport: formatted }));
    } catch (err) {
      if (this.props.onError) {
        this.props.onError(err);
      }
    }
  };

  onSave = async (e) => {
    try {
      e.preventDefault();

      if (this.state.toImport) {
        const params = {
          base64string: this.state.toImport,
        };
        this.props.onSave(params);
      }
    } catch (err) {
      if (this.props.onError) {
        this.props.onError(err);
      }
    }
  };

  render() {
    return (
      <Modal show={this.state.show} onHide={this.props.onClose}>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <form onSubmit={this.onSave}>
          <Modal.Body>
            <FormGroup title="Excel File">
              <FileInput
                inputProps={{
                  accept: '.xlsx',
                }}
                text={this.state.fileName}
                onChange={this.onFileChange}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <TitleButton
              disabled={!this.state.toImport}
              variant="success"
              onClick={this.onSave}
              type="submit"
              title="Submit"
            />
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

ImportModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  // startImportPriceGuide: PropTypes.func.isRequired,
  title: PropTypes.string,
  onError: PropTypes.func,
  onSave: PropTypes.func.isRequired,
};

ImportModal.defaultProps = {
  show: false,
  title: '',
  onError: undefined,
};

const mapDispatchToProps = (dispatch) => ({
  // startImportPriceGuide: (params) => dispatch(startImportPriceGuide(params)),
  handleError: (params) => dispatch(handleError(params)),
});

export default connect(undefined, mapDispatchToProps)(ImportModal);
