/* eslint-disable no-underscore-dangle */
import Parse from 'parse';

class PitchBookObject extends Parse.Object {
  constructor(obj) {
    super('PitchBookObject');
    Object.assign(this, obj);
  }

  get includedOffices() {
    return this.get('includedOffices') || [];
  }

  set includedOffices(value) {
    this.set('includedOffices', value);
  }

  newInstance() {
    const newInstance = super.newInstance();
    Object.assign(newInstance, this);
    return newInstance;
  }

  set(key, value) {
    super.set(key, value);
    if (this.onChange) {
      this.onChange(this, key);
    }
  }

  convertFiles() {
    const keys = ['thumbnail', 'file'];
    keys.forEach((key) => {
      if (this.has(key)) {
        const file = this.get(key);
        if (file.isNew) {
          delete file._url;
          delete file.isNew;
        }
        this.set(key, file);
      }
    });
    return this;
  }

  async save(attributes = {}, options) {
    this.set(attributes);
    this.convertFiles();
    return super.save(null, options);
  }
}

export default PitchBookObject;
