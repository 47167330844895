/* eslint-disable no-plusplus */
/* eslint-disable import/no-cycle */
import Parse from 'parse';
import poll from 'poll/dist/esm/poll.mjs';

import { upperCaseFirst } from 'upper-case-first';
import * as changeCase from 'change-case';
import { showLoader, hideLoader } from './loading';
import { handleError } from './auth';

export const setOnboardingStatusReport = (onboardingStatusReport) => ({
  type: 'SET_ONBOARDING_STATUS_REPORT',
  onboardingStatusReport,
});

export const getOnboardingStatusReport = (props) => async (
  dispatch,
  getState,
) => {
  try {
    const { company } = props;
    const reportQuery = new Parse.Query('OnboardingStatusReport');
    reportQuery.equalTo('company', company);
    const foundReport = await reportQuery.first();
    const checkListResult = foundReport.get('checkListResult');
    const { onBoardingScore } = foundReport.get('scoreResult') || {
      onBoardingScore: 0,
    };
    const formated = checkListResult.map((checkListItem) => ({
      ...checkListItem,
      section: changeCase
        .sentenceCase(checkListItem.section)
        .split(' ')
        .map((word) => upperCaseFirst(word))
        .join(' '),
    }));
    dispatch(
      setOnboardingStatusReport({
        checkListResult: formated,
        onBoardingScore,
      }),
    );
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const waitForReportUpdate = (props) => async (dispatch, getState) => {
  const { jobId, company } = props;
  let shouldStop = false;
  let count = 0;

  poll(
    async () => {
      if (count > 120) {
        shouldStop = true;
      }
      const result = await Parse.Cloud.run('checkReportStatus', { jobId });
      const status = result.get('status');

      if (status === 'failed') {
        dispatch(hideLoader());
        shouldStop = true;
      }

      if (status === 'succeeded') {
        dispatch(hideLoader());
        dispatch(getOnboardingStatusReport({ company }));
        shouldStop = true;
      }
      count++;
    },
    1000,
    () => shouldStop,
  );
};

export const initOnboardingStatusReport = (props) => async (
  dispatch,
  getState,
) => {
  try {
    const { auth } = getState();
    const { company } = auth;
    const { sectionFilter, alertTypeFilter } = props;
    dispatch(showLoader('loading report'));
    const jobId = await Parse.Cloud.run('getOnboardingStatusReportJob', {
      sectionFilter,
      alertTypeFilter,
      companyId: company.id,
    });

    dispatch(waitForReportUpdate({ jobId, company }));
  } catch (e) {
    dispatch(hideLoader());
    dispatch(handleError(e));
  }
};
