/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Classes,
  Menu,
  Popover,
  Button,
  ButtonGroup,
  Position,
  MenuItem,
} from 'leap-menu-item';
import { pushToDataLayer } from '../../../actions/tagManager';

import { copyStringToClipboard } from '../../../utils/Pasteboard';
import AppToaster from '../../../utils/AppToaster';

const defaultPlaceholders = [
  {
    divider: true,
    title: 'App',
  },
  {
    placeholder: '%companyLogo%',
    replacement: 'Company Logo',
  },
  {
    placeholder: '%date%',
    replacement: `The Current Date (${moment().format('MM/DD/YY')})`,
  },
  {
    placeholder: '%year%',
    replacement: `Year - ${moment().format('YYYY')}`,
  },
  {
    placeholder: '%day%',
    replacement: `Day - ${moment().format('D')}`,
  },
  {
    placeholder: '%weekday%',
    replacement: `Weekday - ${moment().format('dddd')}`,
  },
  {
    placeholder: '%weekdayAbbv%',
    replacement: `Weekday (Abbv.)  - ${moment().format('ddd')}`,
  },
  {
    placeholder: '%month%',
    replacement: `Month - ${moment().format('MM')}`,
  },
  {
    placeholder: '%monthName%',
    replacement: `Month Name - ${moment().format('MMMM')}`,
  },
  {
    placeholder: '%monthNameAbbv%',
    replacement: `Month Name (Abbv.) - ${moment().format('MMM')}`,
  },
  {
    divider: true,
    title: 'User',
  },
  {
    placeholder: '%usersLicense%',
    replacement: 'License Number',
  },
  {
    placeholder: '%usersName%',
    replacement: 'First and Last Name',
  },
  {
    placeholder: '%usersPhone%',
    replacement: 'Phone Number',
  },
  {
    placeholder: '%userEmail%',
    replacement: 'Email Address',
  },
  {
    divider: true,
    title: 'Customer Names',
  },
  {
    placeholder: '%customerName%',
    replacement: 'Primary - First and Last name',
  },
  {
    placeholder: '%customerSpouseName%',
    replacement: 'Secondary - First and Last name',
  },
  {
    divider: true,
    title: 'Customer Address',
  },
  {
    placeholder: '%customerAddressFull%',
    replacement: 'Street, City, State and Zip Code',
  },
  {
    placeholder: '%customerAddressLine1%',
    replacement: 'Street Address',
  },
  {
    placeholder: '%customerAddressLine2%',
    replacement: 'City State and Zip Code',
  },
  {
    placeholder: '%customerAddressCity%',
    replacement: 'City',
  },
  {
    placeholder: '%customerAddressState%',
    replacement: 'State',
  },
  {
    placeholder: '%customerAddressZip%',
    replacement: 'Zip Code',
  },
  {
    divider: true,
    title: 'Customer Contacts',
  },
  {
    placeholder: '%customerContacts%',
    replacement: 'All Contacts (Email and Phone)',
  },
  {
    placeholder: '%customerEmail%',
    replacement: 'All Emails',
  },
  {
    placeholder: '%customerPhone%',
    replacement: 'All Phone Numbers',
  },
];

class Placeholders extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
  }

  render() {
    const { customPlaceholders } = this.props;
    const { activeIndex } = this.state;
    return (
      <Popover
        content={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Menu
            className={Classes.ELEVATION_1}
            style={{
              maxWidth: 300,
              minWidth: 300,
              overflow: 'scroll',
            }}
          >
            <ButtonGroup fill>
              <Button
                active={activeIndex === 0}
                onClick={() => this.setState({ activeIndex: 0 })}
              >
                Values
              </Button>
              <Button
                active={activeIndex === 1}
                onClick={() => this.setState({ activeIndex: 1 })}
              >
                Placeholders
              </Button>
            </ButtonGroup>
            {defaultPlaceholders.map(
              ({ placeholder, replacement, divider, title }) => {
                if (divider) {
                  return <Menu.Divider key={title} title={title} />;
                }
                return (
                  <MenuItem
                    key={placeholder}
                    text={activeIndex ? placeholder : replacement}
                    onClick={() => {
                      copyStringToClipboard(placeholder);
                      AppToaster.show({
                        message: 'Copied to clipboard!',
                        timeout: 1000,
                      });
                    }}
                  />
                );
              },
            )}
            {customPlaceholders.length && (
              <>
                <Menu.Divider title="Custom" />
                {customPlaceholders.map(({ placeholder, replacement }) => (
                  <MenuItem
                    key={placeholder}
                    text={
                      activeIndex ? placeholder : replacement || placeholder
                    }
                    onClick={() => {
                      copyStringToClipboard(placeholder);
                      AppToaster.show({
                        message: 'Copied to clipboard!',
                        timeout: 1000,
                      });
                    }}
                  />
                ))}
              </>
            )}
          </Menu>
        }
        position={Position.BOTTOM}
      >
        <Button
          onClick={(event) => {
            const { parentNode } = event.target;
            const { className } = parentNode;
            const open = !(
              className.indexOf('open') > -1 || className.indexOf('active') > -1
            );
            if (open) {
              // eslint-disable-next-line react/destructuring-assignment
              this.props.pushToDataLayer({
                event: 'contractMenuEvent',
                eventCategory: 'Documents',
                eventAction: 'Menu Open',
                eventLabel: 'Top Placeholder Button',
              });
            }
          }}
          className={Classes.MINIMAL}
          text="Placeholders"
        />
      </Popover>
    );
  }
}

Placeholders.propTypes = {
  customPlaceholders: PropTypes.arrayOf(
    PropTypes.shape({
      placeholder: PropTypes.string.isRequired,
      replacement: PropTypes.string.isRequired,
    }),
  ),
  pushToDataLayer: PropTypes.func,
};

Placeholders.defaultProps = {
  customPlaceholders: [],
  pushToDataLayer: () => {},
};

const mapStateToProps = ({ templatesEdit, auth = {} }) => {
  const { config = {} } = auth;
  return {
    customPlaceholders: config.placeholders,
  };
};

const mapDispatchToProps = (dispatch) => ({
  pushToDataLayer: (variablesForLayer) =>
    dispatch(pushToDataLayer(variablesForLayer)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Placeholders);
