/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/button-has-type */
/* eslint-disable max-len */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import Parse from 'parse';
import qs from 'query-string';
import ProSellTemplate from './ProSellTemplate';
import AccountInfoGroup from './AccountInfoGroup';
import ProSellSuccess from './ProSellSuccess';
import { history } from '../../router';
import AppToaster from '../../utils/AppToaster';
import PaymentMethodModal from '../PaymentMethodModal';
import TermsOfService from './ProSellTermsOfService';

class ProSellSignup extends React.Component {
  constructor(props) {
    super(props);
    const { token } = qs.parse(this.props.location.search);
    this.token = token;
    this.state = {
      companyName: '',
      firstName: '',
      lastName: '',
      occId: '',
      email: '',
      success: false,
      showPaymentCapture: false,
      paymentToken: '',
      submitButtontitle: 'SUBMIT',
      submitDisabled: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.downloadAccountInfo();
  }

  onSubmit = async () => {
    try {
      this.setState({ submitButtontitle: 'LOADING...', submitDisabled: true });
      const { paymentToken } = this.state;
      const result = await Parse.Cloud.run('activateAccount', {
        token: this.token,
        paymentToken,
      });
      if (result.status !== 200) {
        throw new Parse.Error(
          result.status,
          'There was an error activating your account. Please contact Leap @ 1-844-370-LEAP',
        );
      }
      this.setState({ success: true });
    } catch (err) {
      AppToaster.show({ message: err.message, timeout: 10000 });
    } finally {
      this.setState({ submitButtontitle: 'SUBMIT', submitDisabled: false });
    }
  };

  onChangePaymentMethod = async (paymentToken) => {
    try {
      const params = { paymentToken, newAccountToken: this.token };
      const result = await Parse.Cloud.run(
        'capturePaymentForNewAccount',
        params,
      );
      this.setState({ paymentToken: result, showPaymentCapture: false });
    } catch (e) {
      this.setState({ showPaymentCapture: false });
      AppToaster.show({ message: e.message, timeout: 10000 });
    }
  };

  downloadAccountInfo = async () => {
    try {
      const { token } = qs.parse(this.props.location.search);
      const result = await Parse.Cloud.run('fetchNewAccountFromToken', {
        token,
      });
      if (result.product !== 'ProSell') {
        throw new Parse.Error(500, 'This is not a ProSell Agreement');
      }
      this.setState({ ...result });
    } catch (e) {
      history.push('/signup/prosell');
    }
  };

  render() {
    if (this.state.success) {
      return (
        <ProSellTemplate>
          <ProSellSuccess email={this.state.email} />
        </ProSellTemplate>
      );
    }
    const {
      companyName,
      firstName,
      lastName,
      occId,
      email,
      numberOfUsers,
      userCount,
      setupFee,
      costPerUser,
      notes,
      term,
      nonPromoCostPerUser,
      nonPromoSetupFee,
      paymentToken,
      paymentNumber,
    } = this.state;
    const submitEnabled =
      this.state.accepted &&
      this.state.paymentToken &&
      !this.state.submitDisabled;
    const ccButtonTitle = `${paymentToken ? 'Change' : 'Add'} Credit Card`;
    const onboardingFee = nonPromoSetupFee || setupFee || 0;
    const licenseCost = nonPromoCostPerUser || costPerUser || 0;
    const licenseCount = numberOfUsers || userCount || 0;
    return (
      <ProSellTemplate>
        <PaymentMethodModal
          title={ccButtonTitle}
          show={this.state.showPaymentCapture}
          onClose={() => this.setState(() => ({ showPaymentCapture: false }))}
          onChange={this.onChangePaymentMethod}
        />
        Subject to ProSell Terms of Service Leap, LLC (‘Leap’) hereby licenses
        to Licensee the ProSell software platform (‘Licensed Product’) for the
        terms set forth below.
        <br />
        Credit card information is required for your subscription payments and
        any optional features not included in the ProSell package.
        <hr />
        <p className="new-account__body-header">
          WHAT A PROSELL SUBSCRIPTION COMES WITH:{' '}
        </p>
        <div className="row">
          <div className="col-md-4">
            <p className="new-account__body-header">DIGITAL TOOLS </p>
            <ul>
              <li> Estimator </li>
              <li> Proposal Generator </li>
              <li> Contract Generator </li>
              <li> Credit Application Generator </li>
              <li> Finance Caluclator</li>
            </ul>
          </div>
          <div className="col-md-4">
            <p className="new-account__body-header">RESOURCES</p>
            <ul>
              <li> Digital Resource Center </li>
              <li> All available shared templates & resources </li>
              <li> Access to all available integrations </li>
            </ul>
          </div>
          <div className="col-md-4">
            <p className="new-account__body-header">SUPPORT</p>
            <ul>
              <li>
                Support Via Email, Phone & Online Chat for all pre-packaged
                ProSell products*{' '}
              </li>
            </ul>
          </div>
        </div>
        <span style={{ fontSize: 10, fontColor: 'lightgray' }}>
          *Certain levels of support may incur additional fees
        </span>
        <hr />
        <p className="new-account__body-header">ACCOUNT INFO</p>
        <AccountInfoGroup title="Company Name" value={companyName} />
        <AccountInfoGroup title="Name" value={`${firstName} ${lastName}`} />
        <AccountInfoGroup title="Email" value={email} />
        {occId && <AccountInfoGroup title="OCCN Member ID #" value={occId} />}
        <hr />
        <p className="new-account__body-header">COSTS</p>
        <AccountInfoGroup title="Onboarding Fee" value={`$${onboardingFee}`} />
        <AccountInfoGroup title="Licenses" value={`${licenseCount}`} />
        <AccountInfoGroup
          title="Per License Cost"
          value={`$${licenseCost}/mo`}
        />
        {term && <AccountInfoGroup title="Term" value={`${term} Months`} />}
        {notes && <AccountInfoGroup title="Notes" value={notes} />}
        <hr />
        <p className="new-account__body-header">PROMOTION</p>
        <AccountInfoGroup
          title="Onboarding Fee Promo"
          value={`$${onboardingFee}`}
          altValue="FEE WAIVED"
        />
        <hr />
        <p className="new-account__body-header">PAYMENT</p>
        <AccountInfoGroup
          title="Total Cost"
          value={`$${licenseCost * licenseCount}/mo`}
        />
        <AccountInfoGroup
          title="Credit Card"
          value={paymentToken ? paymentNumber : 'Not Added'}
        />
        <br />
        <button
          className="new-account__prosell-submit-prosell"
          onClick={() => this.setState({ showPaymentCapture: true })}
        >
          {ccButtonTitle}
        </button>
        <br />
        <p className="new-account__body-header">WHY DO I NEED A CREDIT CARD</p>A
        credit card is required to sign up for ProSell{' '}
        <b>you will be charged for</b>:
        <p>
          - Monthly recurring subscription fee of ${`${licenseCost}`}/ per
          license{' '}
        </p>
        <p>- When you use optional features not included in ProSell Package </p>
        <hr />
        <TermsOfService />
        <div className="new-account__accept-container">
          <input
            type="checkbox"
            value={this.state.accepted}
            onChange={(e) => this.setState({ accepted: e.target.checked })}
          />
          I have read and agree to the ProSell TERMS OF SERVICE
        </div>
        <button
          className="new-account__prosell-submit-prosell"
          onClick={this.onSubmit}
          disabled={!submitEnabled}
        >
          {this.state.submitButtontitle}
        </button>
      </ProSellTemplate>
    );
  }
}

ProSellSignup.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

export default ProSellSignup;
