import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Parse from 'parse';
import {
  ContextMenu,
  Popover,
  PopoverInteractionKind,
  Position,
  Tooltip,
} from '@blueprintjs/core';
import { generateKeyBetween } from 'fractional-indexing';
import ExpandAllCell from '../ExpandAllCell';
import MSICell from './Cell';
import IncludedOfficesDropDown from '../../IncludedOfficesDropDown';
import ThumbnailButton from '../Thumbnail/ThumbnailButton';
import OptionContainer from '../PriceGuideOptions/Container';
import {
  startAddNewItem,
  startFetchOptionsForItem,
  setItem,
  setAdditionalDetailsEdit,
  setPlaceholdersEdit,
  setOption,
  setUpCharge,
  setOptions,
  setUpCharges,
  startDeleteItem,
  setCategories,
} from '../../../actions/priceGuide2';
import { showLoader, hideLoader } from '../../../actions/loading';
import CopyPasteMenu from '../CopyPasteMenu';
import {
  setPasteboardValue,
  getPasteboardValue,
} from '../../../utils/Pasteboard';
import { newFileFromEvent, randomKey } from '../../../utils/utils';
import AddMenu from './AddMenu';
import PriceGuideOption from '../../../Models/PriceGuideOption';
import UpCharge from '../../../Models/UpCharge';
import { handleError } from '../../../actions/auth';
import AdditionalDetailsPreview from '../AdditionalDetails/PreviewView';
import PlaceholdersPreview from '../Placeholders/PreviewView';
import Sticky from '../Sticky';

const MSIRow = ({
  addNewItem,
  categories,
  deleteItem,
  draggableProvided,
  handleErr,
  hideLoader: _hideLoader,
  index,
  isMSItemSortable,
  item,
  items,
  maxOfficeCount,
  onEdit,
  options,
  setAdditionalDetails,
  setCategories: _setCategories,
  setPlaceholders,
  showLoader: _showLoader,
  startFetchOptions,
  upCharges,
  updateItem,
  updateOption,
  updateOptions,
  updateUpCharge,
  updateUpCharges,
}) => {
  const onExpandAllOptions = async () => {
    try {
      const changes = item
        .dirtyKeys()
        .map((key) => ({ key, value: item.get(key) }));
      const newItem = item.newInstance();
      newItem.isOptionsExpanded = !newItem.isOptionsExpanded;
      if (newItem.isOptionsExpanded) {
        await startFetchOptions(newItem);
      }
      changes.forEach(({ key, value }) => newItem.set(key, value));
      const newItem2 = newItem.newInstance();
      updateItem(newItem2);
    } catch (e) {
      handleErr(e);
    }
  };

  const onSaveClicked = async () => {
    try {
      await item.save();
      const category = item.get('category');
      if (category && category.length) {
        const categoryUpdate = categories.filter(
          (storedCategory) => storedCategory !== category,
        );
        _setCategories([...categoryUpdate, category]);
      }
      const newItem = item.newInstance();
      updateItem(newItem);
    } catch (e) {
      handleErr(e);
    }
  };
  const onItemChanged = (key, value) => {
    if (key === 'category') {
      const categoryItems = items.filter(
        (categoryItem) =>
          categoryItem.id === item.id || categoryItem.get('category') === value,
      );
      let prevCategoryItemOrderNumber = null;
      let nextCategoryItemOrderNumber = null;
      if (categoryItems.length > 1) {
        const itemIndex = categoryItems.findIndex(
          (categoryItem) => categoryItem.id === item.id,
        );
        for (let i = itemIndex - 1; i >= 0; i--) {
          const categoryItem = categoryItems[i];
          if (categoryItem.get('orderNumber')) {
            nextCategoryItemOrderNumber = categoryItem.get('orderNumber');
            break;
          }
        }
        for (let i = itemIndex + 1; i < categoryItems.length; i++) {
          const categoryItem = categoryItems[i];
          if (categoryItem.get('orderNumber')) {
            prevCategoryItemOrderNumber = categoryItem.get('orderNumber');
            break;
          }
        }
      }
      const orderNumber = generateKeyBetween(
        prevCategoryItemOrderNumber,
        nextCategoryItemOrderNumber,
      );
      item.set('orderNumber', orderNumber);
    }
    item.set(key, value);
    const newItem = item.newInstance();
    updateItem(newItem);
  };
  const onAdditionalDetailsClicked = () => {
    setAdditionalDetails({
      objects: item.get('additionalDetailObjects') || [],
      onClose: () => setAdditionalDetails(undefined),
      tagRow: item.getTagRow(),
      onSave: (value, tagRow) => {
        const additionalDetailObjects = value.map((detailItem) => {
          const { cellType, defaultValue, ...data } = detailItem;
          if (cellType === 'photos') {
            return {
              cellType,
              ...data,
            };
          }
          return detailItem;
        });
        item.set('additionalDetailObjects', additionalDetailObjects);
        item.setTagRow(tagRow);
        const updatedItem = item.newInstance();
        updateItem(updatedItem);
        setAdditionalDetails(undefined);
      },
    });
  };
  const onPlaceholdersClicked = () => {
    setPlaceholders({
      values: item.get('placeholders'),
      onClose: () => setPlaceholders(undefined),
      onSave: (values) => {
        item.set('placeholders', values);
        const updatedItem = item.newInstance();
        updateItem(updatedItem);
        setPlaceholders(undefined);
      },
    });
  };
  const onAdditionalDetailsRightClick = (e) => {
    e.preventDefault();
    const { value, tagRow } = getPasteboardValue('additionalDetails') || {};
    const menu = (
      <CopyPasteMenu
        title="Additional Details"
        pasteDisabled={!value}
        onCopy={() => {
          const object = {
            value: item.get('additionalDetailObjects') || [],
            tagRow: item.getTagRow(),
          };
          setPasteboardValue(object, 'additionalDetails');
        }}
        onPasteAdd={() => {
          const newItem = item.newInstance();
          const detailsWithNewObjectIds = value.map((detailItem) => ({
            ...detailItem,
            objectId: randomKey(10),
          }));
          newItem.addAdditionalDetails(detailsWithNewObjectIds);
          updateItem(newItem);
        }}
        onPasteReplace={() => {
          const newItem = item.newInstance();
          newItem.set('additionalDetailObjects', value);
          newItem.setTagRow(tagRow);
          updateItem(newItem);
        }}
        onClearAll={() => {
          const newItem = item.newInstance();
          newItem.clearAdditionalDetails();
          updateItem(newItem);
        }}
      />
    );
    ContextMenu.show(menu, { left: e.clientX, top: e.clientY });
  };
  const onPlaceholderRightClick = (e) => {
    e.preventDefault();
    const pasteValue = getPasteboardValue('placeholders');
    const menu = (
      <CopyPasteMenu
        title="Placeholders"
        onCopy={() =>
          setPasteboardValue(item.get('placeholders') || [], 'placeholders')
        }
        pasteDisabled={!pasteValue}
        onPasteAdd={() => {
          const newItem = item.newInstance();
          newItem.addPlaceholders(pasteValue);
          updateItem(newItem);
        }}
        onPasteReplace={() => {
          const newItem = item.newInstance();
          newItem.set('placeholders', pasteValue);
          updateItem(newItem);
        }}
        onClearAll={() => {
          const newItem = item.newInstance();
          newItem.set('placeholders', []);
          updateItem(newItem);
        }}
      />
    );
    ContextMenu.show(menu, { left: e.clientX, top: e.clientY });
  };
  const onRefresh = () => {
    const newItem = item.newInstance();
    newItem.revert();
    updateItem(newItem);
  };
  const onFileChanged = async (e) => {
    try {
      const newItem = item.newInstance();
      if (e.isNew) {
        newItem.unset('image');
      } else {
        const file = await newFileFromEvent(e);
        newItem.set('image', file);
      }
      updateItem(newItem);
    } catch (err) {
      handleErr(err);
    }
  };

  const onNewItem = async () => {
    addNewItem(index, items);
  };

  const onAddNewOption = async () => {
    try {
      const newOption = new PriceGuideOption();
      const newItem = item.newInstance();
      const unsavedValues = item.unsavedValues();
      _showLoader();
      await newItem.fetch();
      newItem.set(unsavedValues);
      const currentItems = newItem.get('items') || [];
      const newItems = [...currentItems, newOption];
      newItem.set('items', newItems);
      await newItem.save();
      updateOption(new PriceGuideOption(newOption));
      updateItem(newItem);
    } catch (err) {
      item.revert('items');
      handleErr(err);
    } finally {
      _hideLoader();
    }
  };
  const onPasteNewOptions = async () => {
    try {
      const optionIds = Object.keys(options);
      const selectedOptions = optionIds
        .map((id) => options[id])
        .filter((obj) => obj.isSelected);
      const clonedOptions = selectedOptions.map((obj) => obj.clone());
      const newItem = item.newInstance();
      newItem.addAll('items', clonedOptions);
      await newItem.save();
      const updatedOptions = {};
      clonedOptions.forEach((obj) => {
        updatedOptions[obj.id] = obj;
      });
      updateOptions(updatedOptions);
      updateItem(newItem);
    } catch (err) {
      item.revert('items');
      handleErr(err);
    }
  };
  const onPasteCopyOptions = async () => {
    try {
      const optionIds = Object.keys(options);
      const selectedOptions = optionIds
        .map((id) => options[id])
        .filter((obj) => obj.isSelected);
      const newItem = item.newInstance();
      newItem.addAllUnique('items', selectedOptions);
      await newItem.save();
      updateItem(newItem);
    } catch (err) {
      item.revert('items');
      handleErr(err);
    }
  };
  const onAddNewUpCharge = async () => {
    try {
      const newUpCharge = new UpCharge();
      const newItem = item.newInstance();
      newItem.add('accessories', newUpCharge);
      await newItem.save();
      updateUpCharge(newUpCharge);
      updateItem(newItem);
    } catch (err) {
      item.revert('accessories');
      handleErr(err);
    }
  };
  const onPasteNewUpCharges = async () => {
    try {
      const upChargeIds = Object.keys(upCharges);
      const selectedUpCharges = upChargeIds
        .map((id) => upCharges[id])
        .filter((obj) => obj.isSelected);
      const clonedOptions = selectedUpCharges.map((obj) => obj.clone());
      const newItem = item.newInstance();
      newItem.addAll('accessories', clonedOptions);
      await newItem.save();
      const updatedUpCharges = {};
      clonedOptions.forEach((obj) => {
        updatedUpCharges[obj.id] = obj;
      });
      updateUpCharges(updatedUpCharges);
      updateItem(newItem);
    } catch (err) {
      item.revert('accessories');
      handleErr(err);
    }
  };
  const onPasteCopyUpCharges = async () => {
    try {
      const upChargeIds = Object.keys(upCharges);
      const selectedUpCharges = upChargeIds
        .map((id) => upCharges[id])
        .filter((obj) => obj.isSelected);
      const newItem = item.newInstance();
      newItem.addAllUnique('accessories', selectedUpCharges);
      await newItem.save();
      updateItem(newItem);
    } catch (err) {
      item.revert('accessories');
      handleErr(err);
    }
  };
  return (
    <>
      <Sticky
        enabelSticky={item.isOptionsExpanded || false}
        scrollElementId="priceguide__index"
        stickTo={{
          type: 'group',
          value: 'blue',
        }}
        uid={item.id}
        stackOrder={1}
        bumpType="blue-item"
      >
        <div
          id={`price-guide-row-${item.id}`}
          className="priceguide__msi-row"
          ref={draggableProvided.innerRef}
          {...draggableProvided.draggableProps}
        >
          <ExpandAllCell
            onClick={onExpandAllOptions}
            isOpen={item.isOptionsExpanded}
            disabled={item.get('itemCount') < 1}
            type="item"
            itemCount={item.get('itemCount') || 0}
            accessoriesCount={item.get('accessoriesCount') || 0}
          />
          <MSICell width={35}>
            <Tooltip
              content={
                !isMSItemSortable
                  ? 'Select only one Category filter to enable sorting'
                  : ''
              }
            >
              <div
                className="priceguide__drag-container"
                {...draggableProvided.dragHandleProps}
                style={{
                  cursor: isMSItemSortable ? 'grab' : 'not-allowed',
                }}
              >
                <i
                  className="fas fa-grip-lines"
                  style={{ color: isMSItemSortable ? '#777777' : '#ddd' }}
                />
              </div>
            </Tooltip>
          </MSICell>
          <MSICell width={35}>
            <button
              className="priceguide__button"
              disabled={!item.dirty()}
              onClick={onSaveClicked}
              type="button"
            >
              <i className="far fa-save" />
            </button>
          </MSICell>
          <MSICell width={35}>
            <button
              className="priceguide__button"
              disabled={!item.dirty()}
              onClick={onRefresh}
              type="button"
            >
              <i className="fas fa-undo-alt" />
            </button>
          </MSICell>
          <MSICell width={35} columnKey="add">
            <Popover
              usePortal
              content={
                <AddMenu
                  onNewItem={onNewItem}
                  onNewOption={onAddNewOption}
                  onPasteNewOptions={onPasteNewOptions}
                  onPasteCopyOptions={onPasteCopyOptions}
                  onNewUpCharge={onAddNewUpCharge}
                  onPasteNewUpCharges={onPasteNewUpCharges}
                  onPasteCopyUpCharges={onPasteCopyUpCharges}
                />
              }
            >
              <button className="priceguide__button" type="button">
                <i className="fas fa-plus-circle" />
              </button>
            </Popover>
          </MSICell>
          <MSICell width={35} columnKey="settings">
            <button
              className="priceguide__button"
              onClick={() => onEdit(item)}
              type="button"
            >
              <i className="fas fa-cog" />
            </button>
          </MSICell>
          <MSICell width={35} columnKey="additionalDetails">
            <Popover
              content={
                <AdditionalDetailsPreview
                  objects={item.get('additionalDetailObjects')}
                  tagRow={item.getTagRow()}
                />
              }
              interactionKind={PopoverInteractionKind.HOVER}
              position={Position.RIGHT}
            >
              <button
                className="priceguide__button"
                onClick={onAdditionalDetailsClicked}
                onContextMenu={onAdditionalDetailsRightClick}
                type="button"
              >
                <i className="fas fa-keyboard" />
              </button>
            </Popover>
          </MSICell>
          <MSICell width={35} columnKey="placeholders">
            <Popover
              content={
                <PlaceholdersPreview placeholders={item.get('placeholders')} />
              }
              interactionKind={PopoverInteractionKind.HOVER}
              position={Position.RIGHT}
            >
              <button
                className="priceguide__button"
                onClick={onPlaceholdersClicked}
                onContextMenu={onPlaceholderRightClick}
                type="button"
              >
                <i className="fas fa-retweet" />
              </button>
            </Popover>
          </MSICell>
          <MSICell width={35} columnKey="showToggle">
            <input
              type="checkbox"
              checked={item.get('shouldShowSwitch')}
              onChange={(e) =>
                onItemChanged('shouldShowSwitch', e.target.checked)
              }
            />
          </MSICell>
          <MSICell width={60} columnKey="image">
            <ThumbnailButton
              imageURL={
                item.has('image')
                  ? item.get('image').url()
                  : '/images/no_image.png'
              }
              name={item.get('itemName')}
              onFileChange={onFileChanged}
            />
          </MSICell>
          <MSICell
            value={item.get('category')}
            columnKey="category"
            onChange={(e) => onItemChanged('category', e.target.value)}
          />
          <MSICell
            value={item.get('subCategory')}
            columnKey="subCategory"
            onChange={(e) => onItemChanged('subCategory', e.target.value)}
          />
          <MSICell
            value={item.get('subSubCategories')}
            columnKey="subSubCategories"
            onChange={(e) => onItemChanged('subSubCategories', e.target.value)}
          />
          <MSICell
            value={item.get('itemName')}
            columnKey="name"
            onChange={(e) => onItemChanged('itemName', e.target.value)}
          />
          <MSICell
            value={item.get('itemNote')}
            columnKey="note"
            onChange={(e) => onItemChanged('itemNote', e.target.value)}
          />
          <MSICell
            value={item.get('measurementType')}
            columnKey="measurementType"
            onChange={(e) => onItemChanged('measurementType', e.target.value)}
          />
          {maxOfficeCount !== 1 && (
            <MSICell columnKey="offices">
              <div
                className="item-row-office-select"
                style={{ width: '100%' }}
                onMouseEnter={() => {
                  const rows = [
                    ...document.getElementsByClassName('priceguide__msi-row'),
                  ];
                  rows
                    .filter((row) => row.id !== `price-guide-row-${item.id}`)
                    .forEach((row) => {
                      const counterId = row.id.split('-row-')[1];
                      const counter = document.getElementById(counterId);
                      counter.style.zIndex = 1;
                    });
                  const thisRow = document.getElementById(`${item.id}`);
                  thisRow.style.zIndex = 10;
                }}
              >
                <IncludedOfficesDropDown
                  style={{ width: '100%' }}
                  selected={item.includedOffices
                    .map((office) => {
                      if (typeof office.id === 'string') {
                        return office.id;
                      }
                      if (typeof office.objectId === 'string') {
                        return office.objectId;
                      }
                      return '';
                    })
                    .filter((id) => !!id)}
                  onChange={(offices) => {
                    const newOffices = offices.map((id) => {
                      const office = new Parse.Object('Office');
                      office.id = id;
                      return office.toPointer();
                    });
                    onItemChanged('includedOffices', newOffices);
                  }}
                />
              </div>
            </MSICell>
          )}
          <MSICell
            value={item.get('formulaID')}
            columnKey="formulaId"
            onChange={(e) => onItemChanged('formulaID', e.target.value)}
          />
          <MSICell
            value={item.get('qtyFormula')}
            columnKey="formula"
            onChange={(e) => onItemChanged('qtyFormula', e.target.value)}
          />
          <MSICell width={35} columnKey="trash">
            <button
              className="priceguide__button"
              onClick={() => deleteItem(item)}
              type="button"
            >
              <i className="far fa-trash-alt" />
            </button>
          </MSICell>
          {draggableProvided.placeholder}
        </div>
      </Sticky>
      <OptionContainer item={item} />
    </>
  );
};

MSIRow.defaultProps = {
  items: [],
  upCharges: {},
  options: {},
};

MSIRow.propTypes = {
  addNewItem: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  deleteItem: PropTypes.func.isRequired,
  draggableProvided: PropTypes.shape({
    innerRef: PropTypes.func,
    draggableProps: PropTypes.object,
    dragHandleProps: PropTypes.object,
    placeholder: PropTypes.node,
  }).isRequired,
  handleErr: PropTypes.func.isRequired,
  hideLoader: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  isMSItemSortable: PropTypes.bool.isRequired,
  item: PropTypes.instanceOf(Parse.Object).isRequired,
  items: PropTypes.arrayOf(PropTypes.instanceOf(Parse.Object)),
  maxOfficeCount: PropTypes.number.isRequired,
  onEdit: PropTypes.func.isRequired,
  options: PropTypes.shape({
    isSelected: PropTypes.bool,
  }),
  setAdditionalDetails: PropTypes.func.isRequired,
  setCategories: PropTypes.func.isRequired,
  setPlaceholders: PropTypes.func.isRequired,
  showLoader: PropTypes.func.isRequired,
  startFetchOptions: PropTypes.func.isRequired,
  upCharges: PropTypes.shape({
    isSelected: PropTypes.bool,
  }),
  updateItem: PropTypes.func.isRequired,
  updateOption: PropTypes.func.isRequired,
  updateOptions: PropTypes.func.isRequired,
  updateUpCharge: PropTypes.func.isRequired,
  updateUpCharges: PropTypes.func.isRequired,
};

const mapStateToProps = ({ plan = {}, priceGuide2 }) => ({
  options: priceGuide2.options,
  upCharges: priceGuide2.upCharges,
  categories: priceGuide2.categories,
  maxOfficeCount: plan.maxOfficeCount,
});

const mapDispatchToProps = (dispatch) => ({
  addNewItem: (index, items) => dispatch(startAddNewItem(index, items)),
  startFetchOptions: (item) => dispatch(startFetchOptionsForItem(item)),
  updateItem: (item) => dispatch(setItem(item)),
  setCategories: (categories) => dispatch(setCategories(categories)),
  deleteItem: (item) => dispatch(startDeleteItem(item)),
  updateOption: (option) => dispatch(setOption(option)),
  updateOptions: (options) => dispatch(setOptions(options)),
  updateUpCharge: (upCharge) => dispatch(setUpCharge(upCharge)),
  updateUpCharges: (upCharges) => dispatch(setUpCharges(upCharges)),
  setAdditionalDetails: (additionalDetails) =>
    dispatch(setAdditionalDetailsEdit(additionalDetails)),
  setPlaceholders: (placeholders) =>
    dispatch(setPlaceholdersEdit(placeholders)),
  handleErr: (error) => dispatch(handleError(error)),
  showLoader: (message) => dispatch(showLoader(message)),
  hideLoader: () => dispatch(hideLoader()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MSIRow);
