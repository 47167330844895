import React from 'react';
import SelectComponent from 'react-select';
import PropTypes from 'prop-types';

const StatePicker = (props) => {
  const { value, onChange, statesList = [] } = props;

  return (
    <SelectComponent
      className="lpstrp-select w-100"
      classNamePrefix="lpstrp-select"
      value={{ label: value, value }}
      onChange={onChange}
      closeMenuOnSelect
      options={statesList.map((label) => ({ label, value: label }))}
    />
  );
};

StatePicker.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  statesList: PropTypes.arrayOf(PropTypes.string).isRequired,
};

StatePicker.defaultProps = {};

export default StatePicker;
