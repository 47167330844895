import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import LeapDebounceInput from '../../SharedComponents/LeapDebounceInput';
import UpChargeCell from './Cell';

const popoverHoverFocus = () => (
  <Popover id="popover-trigger-hover-focus" title="Shared Price">
    <strong>
      Changing this price will change it across any items this up-charge is
      copied to.
    </strong>
    <br />
    <br />
    When calculating a price for this item, Leap will first check if there is a
    unique price (the column to the right of this one). If there is a unique
    price it will use it for it&#39;s total. If there is NOT a unique price, it
    will use this price (the shared price) for it&#39;s total.
    <br />
    <br />
    Using shared pricing is reccommended as it makes updates to your price guide
    very quick due to it changing the price across multiple items at once.
  </Popover>
);

const calculatedTotalPopover = () => (
  <Popover id="popover-trigger-hover-focus" title="Calculated Total">
    <strong>
      Calculated total of the up-charge based off of the parent option.
    </strong>
  </Popover>
);

const UpChargePriceCell = ({
  value,
  onChange,
  showLink,
  placeholder,
  disabled,
  calculatedTotal,
  symbol,
}) => (
  <UpChargeCell columnKey="upChargePrices" width={100}>
    {showLink && (
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="left"
        overlay={popoverHoverFocus()}
      >
        <i className="fas fa-link priceguide__link-label" />
      </OverlayTrigger>
    )}
    {typeof calculatedTotal === 'number' && (
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="left"
        overlay={calculatedTotalPopover()}
      >
        <div className="priceguide__upcharge-calculated_total-label">
          {calculatedTotal.toFixed(2)}
        </div>
      </OverlayTrigger>
    )}
    <div className="priceguide__number-type-label">{symbol}</div>
    <LeapDebounceInput
      type="number"
      className="priceguide__input-currency"
      value={value}
      placeholder={placeholder}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
      minLength={0}
      debounceTimeout={300}
    />
  </UpChargeCell>
);

UpChargePriceCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  showLink: PropTypes.bool,
  placeholder: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
  calculatedTotal: PropTypes.number,
  symbol: PropTypes.string,
};

UpChargePriceCell.defaultProps = {
  value: undefined,
  showLink: false,
  placeholder: '',
  disabled: false,
  calculatedTotal: undefined,
  symbol: '',
};

export default UpChargePriceCell;
