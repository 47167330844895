import { hideWidget, showWidget } from '../actions';

const initTalkDeskWidget = () => {
  // Get the Talkdesk widget
  const talkDeskWidget = window.TalkdeskChatSDK;

  // Get the touchpoint ID
  const touchId = process.env.REACT_APP_TALK_DESK_TOUCH_POINT_ID || '';
  if (!touchId) {
    console.error('Talkdesk touchpoint ID is not defined');
    return false;
  }

  // Widget selector
  let widgetSelector = '';

  // Main widget selector
  const mainWidgetSelector = '#speed-dial';

  try {
    // Initialize the Talkdesk widget with props configuration
    window.webChat = talkDeskWidget('tdWebchat', {
      touchpointId: touchId,
      accountId: '',
      region: 'td-us-1',
    });
  } catch (error) {
    console.error('Failed to initialize Talkdesk widget:', error);
    return false;
  }

  // Get the widget selector
  widgetSelector =
    (window.webChat.injector && `#${window.webChat.injector.id}`) || '';

  // Initialize the Talkdesk widget with configs configuration
  window.webChat.init({
    enableValidation: false,
    enableEmoji: true,
    enableUserInput: true,
    enableAttachments: true,
  });

  // Register the hide and show widget events
  // This will hide the widget when the chatbot load.
  // Because we are showing the main widget when the user is logged in.
  // From there the user can choose the chat.
  window.webChat.onInitWebchat = () => {
    hideWidget({
      widgetSelector,
      mainWidgetSelector,
    });
  };

  // This will show the widget when the user choose the chat.
  window.webChat.onOpen = () => {
    showWidget({
      widgetSelector,
      mainWidgetSelector,
    });
  };

  // This will hide the widget when the user closes the chat.
  window.webChat.onClose = () => {
    hideWidget({
      widgetSelector,
      mainWidgetSelector,
    });
  };

  return true;
};

export default initTalkDeskWidget;
