/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Parse from 'parse';
import HeaderCell from './HeaderCell';
import { toggleAllUpChargesForOption } from '../../../actions/priceGuide2';

const UpChargeHeader = ({
  onSaveAll,
  onSelectAll,
  isAllSelected,
  isSaveAllDisabled,
  toggleAllUpChargesForOption2,
  includedOffices,
  offices,
  onDeleteAll,
  onRefresh,
  isRefreshDisabled,
  itemId,
  optionId,
}) => (
  <div className="priceguide__upcharge_header-container">
    <HeaderCell
      popoverTitle="hide"
      popoverBody="Select All"
      columnKey="upChargeDisabled"
      disableResize
      borderLeft
    >
      <input
        type="checkbox"
        defaultChecked
        onChange={(event) => {
          toggleAllUpChargesForOption2({
            itemId,
            optionId,
            checked: event.target.checked,
          });
        }}
      />
    </HeaderCell>
    <HeaderCell disableResize>
      <div />
    </HeaderCell>
    <HeaderCell columnKey="save" disableResize>
      <button
        type="button"
        className="priceguide__button"
        onClick={onSaveAll}
        disabled={isSaveAllDisabled}
      >
        <i className="far fa-save" />
      </button>
    </HeaderCell>
    <HeaderCell disableResize>
      <button
        type="button"
        className="priceguide__button"
        onClick={onRefresh}
        disabled={isRefreshDisabled}
      >
        <i className="fas fa-undo-alt" />
      </button>
    </HeaderCell>
    <HeaderCell columnKey="upChargeSelect" disableResize>
      <input
        type="checkbox"
        checked={isAllSelected}
        onChange={(e) => onSelectAll(e.target.checked)}
      />
    </HeaderCell>
    <HeaderCell columnKey="upChargeSettings" disableResize>
      <i className="fas fa-cog" />
    </HeaderCell>
    <HeaderCell columnKey="upChargeAdditionalDetails" disableResize>
      <i className="fas fa-keyboard" />
    </HeaderCell>
    <HeaderCell columnKey="upChargePlaceholders" disableResize>
      <i className="fas fa-retweet" />
    </HeaderCell>
    <HeaderCell columnKey="upChargeShowToggle" disableResize>
      <i className="fas fa-toggle-on" />
    </HeaderCell>
    <HeaderCell
      columnKey="upChargeImage"
      style={{ minWidth: 60, maxWidth: 60 }}
      disableResize
    >
      <i className="far fa-image" />
    </HeaderCell>
    <HeaderCell columnKey="upChargeName">Name</HeaderCell>
    <HeaderCell columnKey="upChargeNote">Note</HeaderCell>
    <HeaderCell columnKey="upChargeMeasurement">Measurement</HeaderCell>
    <HeaderCell columnKey="upChargeIdentifier">Identifier</HeaderCell>
    <HeaderCell columnKey="upChargePercentagePrice" disableResize>
      <i className="fas fa-percent" />
    </HeaderCell>
    {includedOffices.length > 0 && (
      <HeaderCell
        columnKey="upChargePrices"
        disableResize
        style={{ width: 'auto', minWidth: 'unset', maxWidth: 'unset' }}
      >
        <div className="priceguide__office-name-container">
          {includedOffices.map((officeId) => (
            <div className="priceguide__office-name-upcharge" key={officeId}>
              {offices.find(({ id }) => officeId === id)?.get('name')}
            </div>
          ))}
        </div>
      </HeaderCell>
    )}
    <HeaderCell columnKey="save" disableResize>
      <button
        type="button"
        className="priceguide__button"
        onClick={onDeleteAll}
      >
        <i className="far fa-trash-alt" />
      </button>
    </HeaderCell>
  </div>
);

UpChargeHeader.propTypes = {
  onSaveAll: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  isAllSelected: PropTypes.bool,
  isSaveAllDisabled: PropTypes.bool,
  includedOffices: PropTypes.arrayOf(PropTypes.string),
  offices: PropTypes.arrayOf(PropTypes.instanceOf(Parse.Object)),
  onDeleteAll: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  isRefreshDisabled: PropTypes.bool,
  itemId: PropTypes.string.isRequired,
  optionId: PropTypes.string.isRequired,
};

UpChargeHeader.defaultProps = {
  isAllSelected: false,
  isSaveAllDisabled: true,
  includedOffices: [],
  offices: [],
  isRefreshDisabled: false,
};

const mapStateToProps = ({ priceGuide2, auth }) => ({
  includedOffices: priceGuide2.includedOffices,
  offices: auth.offices,
});

const mapDispatchToProps = (dispatch) => ({
  toggleAllUpChargesForOption2: (props) =>
    dispatch(toggleAllUpChargesForOption(props)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpChargeHeader);
