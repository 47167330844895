/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import moneyFormatter from 'money-formatter';
import moment from 'moment';
import { withLayoutContext } from '../Layout';
import AddressList from '../SharedComponents/AddressList';
import OrderedList from '../SharedComponents/OrderedList';
import { startResendAttachment } from '../../actions/customers';
import { SectionTitle } from '../SharedComponents/SectionTitle';
import {
  getEstimateDetails,
  downloadAllDocuments,
  getValidOffices,
} from '../../actions/estimatesPage';
import ActionIconsSection from './ActionIconsSection';
import SaleSection from './SaleSection';
import AppointmentSection from './AppointmentSection';
import PaymentMethodSection from './PaymentMethodSection';
import CreditAppSection from './CreditAppSection';
import { HistoryLink } from '../SharedComponents/HistoryLink';
import ConfirmResendModal from './ConfirmResendModal';
import PaymentRequestModal from './PaymentRequestModal';
import SecureSignSection from './SecureSignSection';

const shouldShowPaymentRequestButton = (props) =>
  props.validOffices.length ? 'inline-block' : 'none';

class EstimatesDetailsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPaymentRequestModal: false,
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      setCrumbs,
      setButtons,
      location: { pathname = '' },
    } = this.props;

    const crumbs = [
      { title: 'Estimates', link: '/estimates' },
      { title: 'Estimate Break Down', link: `/estimate_details/${id}` },
    ];

    setCrumbs(crumbs);

    setButtons(this.buttons());

    this.props.getEstimateDetails(id);
    this.props.getValidOffices();
  }

  componentDidUpdate(prevProps) {
    const { setButtons } = this.props;

    if (!_.isEqual(prevProps, this.props)) {
      setButtons(this.buttons());
    }
  }

  componentWillUnmount() {
    const { setCrumbs, crumbs, setButtons } = this.props;

    setCrumbs(crumbs.slice(0, 1));
    setButtons();
  }

  buttons = () => (
    <>
      <HistoryLink href="/emails" className="btn btn-primary">
        View Emails
      </HistoryLink>
      <button
        style={{
          display: shouldShowPaymentRequestButton(this.props),
        }}
        onClick={() => {
          this.setState({ showPaymentRequestModal: true });
        }}
        className="btn btn-success"
      >
        Send Payment Request
      </button>
    </>
  );

  render() {
    const { details = {}, docuSign } = this.props;
    const {
      objectId,
      apiSourceData = {},
      addresses = [],
      contacts = [],
      estimateName,
      nameFirst,
      nameLast,
      estimate,
      createdBy,
      spouse,
    } = details;
    const address = addresses.map((addressItem) => ({
      value: `${addressItem.get('street')} \n ${addressItem.get(
        'city',
      )} ${addressItem.get('theState')}, ${addressItem.get('zipCode')}`,
    }));
    const phone = contacts
      .filter((contactItem) => contactItem.get('type') === 1)
      .map((contactItem) => contactItem.get('contact'));
    const emails = contacts.filter(
      (contactItem) => contactItem.get('type') === 2,
    );

    const customerEmails = emails.map((contactItem) => (
      <a href={`mailto:${contactItem.get('contact')}`}>
        {contactItem.get('contact')}
      </a>
    ));
    const names = [`${nameFirst || ''} ${nameLast || ''}`];
    if (spouse) {
      names.push(`${spouse.get('nameFirst')} ${spouse.get('nameLast')}`);
    }
    return (
      <div className="default-page-padding">
        <div className="stage h-100 d-flex flex-1 flex-column">
          {!!this.props.resendCreatedBy && (
            <ConfirmResendModal
              show={this.props.showResendConfirm}
              onClose={this.props.onResendClose}
              user={this.props.resendCreatedBy}
              onSend={this.props.onResendConfirm}
            />
          )}
          <article
            style={{
              visibility: estimateName || nameFirst ? 'visible' : 'hidden',
            }}
            className="title-section"
          >
            <h2 className="my-3 title-section-header">
              {estimateName || `${nameFirst} ${nameLast}`}
            </h2>
            <span className="user-line-symbol">USER: </span>
            <span className="user-line-name">
              {estimate.createdBy &&
                `${estimate.createdBy.nameFirst} ${estimate.createdBy.nameLast}`}
            </span>
            <span className="user-line-date">
              {estimate.createdAt
                ? `${moment(estimate.createdAt).format('MM/DD/YYYY')}`
                : ''}
            </span>
          </article>
          {!!this.props.details.estimateObject && (
            <ActionIconsSection
              onResend={(source) =>
                this.props.startResendAttachment(source, objectId, createdBy)
              }
              details={this.props.details}
              downloadAllDocuments={this.props.downloadAllDocuments}
            />
          )}
          <article className="customer-meta-section leap-data-section">
            <SectionTitle {...{ title: 'Customer' }} />
            <div className="field-section my-1">
              <dl className="row">
                <div className="col-xl-3 col-md-4 col-sm-6">
                  <dt>Name(s)</dt>
                  <dd>
                    <OrderedList list={names} />
                  </dd>
                </div>
                {address.length > 0 && (
                  <div className="col-xl-3 col-md-4 col-sm-6">
                    <dt>Address</dt>
                    <dd>
                      <AddressList list={address} />
                    </dd>
                  </div>
                )}

                {phone.length > 0 && (
                  <div className="col-xl-3 col-md-4 col-sm-6">
                    <dt>Phone Number</dt>
                    <dd>
                      <OrderedList list={phone} />
                    </dd>
                  </div>
                )}
                {customerEmails.length > 0 && (
                  <div className="col-xl-3 col-md-4 col-sm-6">
                    <dt>Email Addresses</dt>
                    <dd>
                      <OrderedList list={customerEmails} />
                    </dd>
                  </div>
                )}
                {estimate.office && estimate.office.name && (
                  <div className="col-xl-3 col-md-4 col-sm-6">
                    <dt>Office</dt>
                    <dd>{estimate.office.name}</dd>
                  </div>
                )}
                <div className="col-xl-3 col-md-4 col-sm-6">
                  <dt>Source</dt>
                  <dd>{apiSourceData.apiSource || 'Manual'}</dd>
                </div>
                {this.props.analyticsData &&
                  this.props.analyticsData.spousePresent && (
                    <div className="col-xl-3 col-md-4 col-sm-6">
                      <dt>Spouse Added</dt>
                      <dd>Yes</dd>
                    </div>
                  )}
              </dl>
            </div>
          </article>
          {estimate &&
            estimate.leadResults &&
            !!estimate.leadResults.length && (
              <SaleSection
                estimate={estimate}
                analyticsData={this.props.analyticsData}
              />
            )}
          {this.props.analyticsData.startTime && (
            <AppointmentSection analyticsData={this.props.analyticsData} />
          )}
          {!!(
            this.props.validPaymentCaptureOffices &&
            this.props.validPaymentCaptureOffices.length
          ) && (
            <PaymentMethodSection
              paymentMethods={this.props.paymentMethods}
              transactions={this.props.transactions}
              customer={this.props.details}
            />
          )}
          <CreditAppSection customerId={objectId} />
          {objectId && docuSign.source === 'LSS' && (
            <SecureSignSection customerId={objectId} />
          )}
          <PaymentRequestModal
            customerEmails={emails.map((sscontact) => ({
              value: sscontact.get('contact'),
            }))}
            show={this.state.showPaymentRequestModal}
            onClose={() => {
              this.setState({ showPaymentRequestModal: false });
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  customers,
  estimatesPage,
  auth: {
    config: { docuSign = {} },
  },
  paymentCapture,
}) => {
  const {
    showResendConfirm,
    onResendConfirm,
    onResendClose,
    resendCreatedBy,
  } = customers;
  const { details = { estimate: {} }, validOffices = [] } = estimatesPage;
  const analyticsData = details.analyticsData || {};
  const paymentMethods = (paymentCapture.paymentMethods || []).map(
    (paymentMethod) => ({
      objectId: paymentMethod.id,
      expDate:
        paymentMethod.get('month') && paymentMethod.get('year')
          ? `${paymentMethod.get('month')}/${paymentMethod.get('year')}`
          : 'N/A',
      cardType: paymentMethod.get('cardType'),
      number: paymentMethod.get('number'),
      zipCode: paymentMethod.get('zipCode'),
      token: paymentMethod.get('token'),
    }),
  );
  const transactions = (paymentCapture.transactions || []).map(
    (transaction) => ({
      objectId: transaction.id,
      date: moment(transaction.get('createdAt')).format('M/D/YYYY'),
      displayAmount: moneyFormatter.format(
        'USD',
        transaction.get('amount') / 100,
      ),
      amount: transaction.get('amount'),
      accountNumber: transaction.get('paymentMethod').get('number'),
      createdBy: transaction.get('createdBy')
        ? `${transaction.get('createdBy').get('nameFirst')} ${transaction
            .get('createdBy')
            .get('nameLast')}`
        : 'N/A',
      description: transaction.get('description'),
      type: transaction.get('type'),
      token: transaction.get('token'),
      gateway_transaction_id: transaction.get('response').transaction
        .gateway_transaction_id,
    }),
  );

  const logs = (analyticsData.logObjects || []).map((log) => ({
    time: new Date(log.date.iso).toLocaleString(),
    value: log.note,
  }));

  return {
    showResendConfirm,
    onResendConfirm,
    onResendClose,
    resendCreatedBy,
    validPaymentCaptureOffices: details.validPaymentCaptureOffices,
    validOffices,
    details,
    paymentMethods,
    transactions,
    analyticsData,
    logs,
    docuSign,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getEstimateDetails: (param) => dispatch(getEstimateDetails(param)),
  downloadAllDocuments: (estimate) => dispatch(downloadAllDocuments(estimate)),
  getValidOffices: () => dispatch(getValidOffices()),
  startResendAttachment: (objectId, source, user) =>
    dispatch(startResendAttachment(objectId, source, user)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(EstimatesDetailsPage),
);
