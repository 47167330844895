import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import NewResourceForm from './NewResourceForm';

const NewResourceModal = ({ show, onClose, onSubmit }) => (
  <Modal dialogClassName="new-resource-modal" show={show} onHide={onClose}>
    <Modal.Header closeButton>
      <Modal.Title>Add New Resource</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <NewResourceForm onSubmit={onSubmit} />
    </Modal.Body>
  </Modal>
);

NewResourceModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

NewResourceModal.defaultProps = {
  show: false,
};

export default NewResourceModal;
