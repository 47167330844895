/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { TextGroup, DropDownGroup, TagGroup } from '../../../FormGroup';
import Panel from '../../../Panel';

const hasErrors = (errors, key) => {
  if (errors.jobProgress) {
    return errors.jobProgress[key];
  }
  return false;
};

const destinationOptions = [
  { value: 'estimates', label: 'estimates' },
  { value: 'proposals', label: 'proposals' },
  { value: 'materials', label: 'materials' },
  { value: 'work_orders', label: 'work_orders' },
  { value: 'measurements', label: 'measurements' },
];

const JobProgressSection = (props) => (
  <Panel title="JobProgress">
    <div>
      <TextGroup
        title="File Name"
        value={props.value.fileName}
        placeholder="File Name"
        onChange={(value) =>
          props.onChange({
            key: 'jobProgress',
            value: {
              ...props.value,
              fileName: value,
            },
          })
        }
        errorMessage={hasErrors(props.errors, 'fileName')}
      />
      <DropDownGroup
        title="File Destination"
        placeholder="File Destination"
        value={
          destinationOptions.filter(
            (option) => option.value === props.value.fileDestination,
          )[0]
        }
        onChange={(selectedDestination) => {
          props.onChange({
            key: 'jobProgress',
            value: {
              ...props.value,
              fileDestination: selectedDestination.value,
            },
          });
        }}
        closeMenuOnSelect
        options={destinationOptions}
        isClearable={false}
        errorMessage={hasErrors(props.errors, 'fileDestination')}
      />
      <TagGroup
        title="Backup Recipients"
        values={props.value.backupRecipients}
        placeholder="Add Email Addresses"
        onChange={(value) =>
          props.onChange({
            key: 'jobProgress',
            value: {
              ...props.value,
              backupRecipients: value,
            },
          })
        }
        errorMessage={hasErrors(props.errors, 'backupRecipients')}
      />
    </div>
  </Panel>
);

JobProgressSection.propTypes = {
  value: PropTypes.shape({
    fileName: PropTypes.string,
    fileDestination: PropTypes.string,
    backupRecipients: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    jobProgress: PropTypes.shape({
      fileName: PropTypes.string,
      fileDestination: PropTypes.string,
      backupRecipients: PropTypes.string,
    }).isRequired,
  }),
};

JobProgressSection.defaultProps = {
  errors: {
    jobProgress: {
      fileName: '',
      fileDestination: '',
      backupRecipients: '',
    },
  },
};

export default JobProgressSection;
