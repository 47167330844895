import React from 'react';
import PropTypes from 'prop-types';
import Switch from './Switch';

const SwitchedCell = ({ style, value, onChange, source, id }) => (
  <td
    style={{
      width: '50px',
      ...style,
    }}
  >
    <div
      style={{ display: 'flex', alignItems: 'center', alignContent: 'center' }}
    >
      <Switch
        checked={value}
        onChange={() => onChange(source, !value, id)}
        large
        style={{ margin: 'auto' }}
      />
    </div>
  </td>
);

SwitchedCell.propTypes = {
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  source: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  style: PropTypes.shape({
    backgroundColor: PropTypes.string,
  }),
};

SwitchedCell.defaultProps = {
  style: {},
};

export default SwitchedCell;
