import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Classes, Menu, MenuItem, TextArea } from 'leap-menu-item';
import { setInitialStateForTemplate } from '../../../actions/templateEdit';
import ContractObject from '../Models/ContractObject';
import SignatureAdditionalLineObject from '../Models/SignatureAdditionalLine';
import YesNoMenuItem from './YesNoMenuItem';
import DebounceInputWithNet from './DebounceInputWithNet';

const SignatureAdditionalLine = ({
  templateValues,
  editCellItem,
  setTemplateState,
}) => {
  const contractObject = new ContractObject(templateValues.toJSON());
  const updatedObject =
    contractObject.getObjectWithId(editCellItem.objectId) || {};
  return (
    <Menu className={Classes.ELEVATION_1}>
      <MenuItem text="Above Line (Value)">
        <DebounceInputWithNet
          element={TextArea}
          minLength={0}
          debounceTimeout={300}
          value={updatedObject.value}
          onChange={(e) => {
            updatedObject.value = e.target.value;
            setTemplateState(contractObject);
          }}
        />
      </MenuItem>
      <MenuItem text="Below Line (Note)">
        <DebounceInputWithNet
          element={TextArea}
          minLength={0}
          debounceTimeout={300}
          value={updatedObject.note}
          onChange={(e) => {
            updatedObject.note = e.target.value;
            setTemplateState(contractObject);
          }}
        />
      </MenuItem>
      <Menu.Divider />
      <YesNoMenuItem
        text="Underline"
        active={updatedObject.underline}
        onChange={(value) => {
          updatedObject.underline = value;
          setTemplateState(contractObject);
        }}
      />
    </Menu>
  );
};

SignatureAdditionalLine.propTypes = {
  templateValues: PropTypes.instanceOf(ContractObject).isRequired,
  editCellItem: PropTypes.instanceOf(SignatureAdditionalLineObject).isRequired,
  setTemplateState: PropTypes.func.isRequired,
};

const mapStateToProps = ({ templatesEdit = {} }) => ({
  templateValues: templatesEdit[templatesEdit.currentWorkingId],
  editCellItem: templatesEdit.editCellItem,
});

const mapDispatchToProps = (dispatch) => ({
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignatureAdditionalLine);
