/* eslint-disable no-param-reassign */
/** @jsx jsx */
import { jsx } from '@emotion/core';
// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  setInitialStateForTemplate,
  setEditCellItem,
} from '../../../actions/templateEdit';
import HeaderRowGroupContainer from './HeaderRowGroupContainer';
import SectionTitle from '../SectionTitle';
import ContractObject from '../Models/ContractObject';
import HeaderGroupObject from '../Models/HeaderGroup';
import { fontFamilyExists } from '../FontSelect';

const sectionTitleStyle = ({
  title,
  titleFontBold = true,
  titleFontColor,
  titleFontName = undefined,
  titleFontSize = 12,
  titleFontUnderline = false,
}) => {
  const colorArray = titleFontColor ? titleFontColor.split('-') : [];
  const [r, g, b, a] = colorArray;
  return {
    fontWeight: titleFontBold ? 900 : undefined,
    color: titleFontColor ? `rgba(${r},${g},${b},${a})` : undefined,
    fontSize: `${titleFontSize}px`,
    textDecoration: titleFontUnderline ? 'underline' : undefined,
    fontFamily: fontFamilyExists(titleFontName)
      ? titleFontName
      : 'HelveticaNeue',
    cursor: 'pointer',
    maxHeight: title ? undefined : '1px',
  };
};

const keyMaps = {
  value: 'title',
  bold: 'titleFontBold',
  border: 'border',
  underline: 'titleFontUnderline',
  size: 'titleFontSize',
  font: 'titleFontName',
  color: 'titleFontColor',
  cellItems: 'cellItems',
};

const HeaderSection = ({ section = {}, setTemplateState, setEditItem }) => {
  const { title, ...rest } = section;
  return (
    <div id="header-section" css={{ position: 'relative' }}>
      <SectionTitle
        title={title}
        keyMaps={keyMaps}
        toolbarKey="headerTitle"
        style={sectionTitleStyle(section)}
        onChange={(value) => {
          section.title = value;
          const contractObject = section.getSourceObject();
          setTemplateState(new ContractObject(contractObject.toJSON()));
        }}
        onClick={() => setEditItem(section)}
        {...rest}
      />
      <HeaderRowGroupContainer section={section} />
    </div>
  );
};

HeaderSection.propTypes = {
  section: PropTypes.instanceOf(HeaderGroupObject).isRequired,
  setTemplateState: PropTypes.func.isRequired,
  setEditItem: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
  setEditItem: (cellItem) => dispatch(setEditCellItem(cellItem)),
});

export default connect(undefined, mapDispatchToProps)(HeaderSection);
