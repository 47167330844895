import React from 'react';
import './styles.scss';
// Using forwardRef to wrap the component to hoist the ref from the inner DOM
// element to the parent component to be able to access it's ref.
const Button = React.forwardRef(function Button(
  // Using "...props" as rest parameters to handle native props of the 'button'
  // element
  { children, color = 'blue', className = '', ...props },
  ref,
) {
  return (
    // eslint-disable-next-line react/button-has-type
    <button
      {...props}
      className={`lpstrp-btn ${color} ${className}`.trim()}
      ref={ref}
    >
      {children}
    </button>
  );
});

export default Button;
