/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import _ from 'lodash';
import isURL from 'validator/lib/isURL';
import { TextGroup } from '../../FormGroup';
import { randomKey } from '../../../utils/utils';

const validationErrors = ({ category, link, linkName, name }) => ({
  category: category ? undefined : 'Required',
  link: link && isURL(link) ? undefined : 'Invalid URL',
  linkName: linkName ? undefined : 'Required',
  name: name ? undefined : 'Required',
});

class New extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: props.show,
      errors: {},
    };
  }

  UNSAFE_componentWillReceiveProps({ show }) {
    this.setState({
      category: undefined,
      link: undefined,
      linkName: undefined,
      name: undefined,
      show,
    });
  }

  onTextChange = (value, source) => {
    this.setState((prev) => ({
      [source]: value,
      errors: {
        ...prev.errors,
        [source]: validationErrors({ [source]: value })[source],
      },
    }));
  };

  onSave = () => {
    const errors = validationErrors(this.state);
    const isValid = _.isEmpty(_.pickBy(errors, _.identity));
    if (isValid) {
      const { category, link, linkName, name } = this.state;
      this.props.onAdd({
        id: randomKey(10),
        category: _.capitalize(category),
        link,
        linkName,
        name,
      });
      this.props.onClose();
    } else {
      this.setState(() => ({ errors }));
    }
  };

  textGroupProps = (source) => ({
    value: this.state[source],
    errorMessage: this.state.errors[source],
    onChange: (value) => this.onTextChange(value, source),
  });

  render() {
    const { show } = this.state;
    return (
      <Modal show={show} onHide={this.props.onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Email Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextGroup title="Category" {...this.textGroupProps('category')} />
          <TextGroup title="Name" {...this.textGroupProps('name')} />
          <TextGroup title="Link" {...this.textGroupProps('link')} />
          <TextGroup title="Link Name" {...this.textGroupProps('linkName')} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={this.onSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

New.propTypes = {
  show: PropTypes.bool,
  warning: PropTypes.string,
  onAdd: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

New.defaultProps = {
  show: false,
  warning: '',
};

export default New;
