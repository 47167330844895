/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
// import PropTypes from 'prop-types';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Button, Table } from 'react-bootstrap';
import { DebounceInput } from 'react-debounce-input';

const CategorySectionTabel = (props) => (
  <Table striped bordered hover style={{ tableLayout: 'fixed' }}>
    <thead>
      <tr>
        <th>Category</th>
        <th>Name</th>
        <th>Link</th>
        <th>Link Name</th>
        <th style={{ width: '4.4em' }}>
          <Button
            type="button"
            variant="success"
            onClick={() => props.onAddClicked({ group: props.group })}
          >
            Add
          </Button>
        </th>
      </tr>
    </thead>
    <Droppable
      droppableId={props.sectionId}
      direction="vertical"
      type="category-section-id"
    >
      {(provided) => (
        <tbody
          style={{ position: 'relative', width: '100% !important' }}
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {props.emailLinks
            .sort((a, b) => a.orderNumber - b.orderNumber)
            .map(({ id, category, name, link, linkName }, index) => (
              <Draggable
                key={id}
                draggableId={id}
                index={index}
                type="category-section-id"
              >
                {(draggableProvided, snapshot) => (
                  <tr
                    style={{ position: 'relative', width: '100% !important' }}
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}
                    key={id}
                  >
                    <td
                      style={{
                        backgroundColor: snapshot.isDragging
                          ? 'white'
                          : 'transparent',
                        width: '25%',
                      }}
                    >
                      <DebounceInput
                        inputRef={(ref) => {
                          props.setRef({
                            id,
                            ref,
                          });
                        }}
                        className={`table-row__cell__input${
                          props.errors[index].category ? ' error-border' : ''
                        }`}
                        type="text"
                        value={category}
                        minLength={2}
                        debounceTimeout={700}
                        placeholder="Category"
                        onChange={(e) =>
                          props.onTextChange({
                            value: e.target.value,
                            source: 'category',
                            id,
                          })
                        }
                      />
                      <div className="error">
                        {props.errors[index].category}
                      </div>
                    </td>
                    <td
                      style={{
                        backgroundColor: snapshot.isDragging
                          ? 'white'
                          : 'transparent',
                        width: '25%',
                      }}
                    >
                      <input
                        className={`table-row__cell__input${
                          props.errors[index].name ? ' error-border' : ''
                        }`}
                        type="text"
                        value={name}
                        placeholder="Name"
                        onChange={(e) =>
                          props.onTextChange({
                            value: e.target.value,
                            source: 'name',
                            id,
                          })
                        }
                      />
                      <div className="error">{props.errors[index].name}</div>
                    </td>
                    <td
                      style={{
                        backgroundColor: snapshot.isDragging
                          ? 'white'
                          : 'transparent',
                        width: '25%',
                      }}
                    >
                      <input
                        className={`table-row__cell__input${
                          props.errors[index].link ? ' error-border' : ''
                        }`}
                        type="text"
                        value={link}
                        placeholder="Link"
                        onChange={(e) =>
                          props.onTextChange({
                            value: e.target.value,
                            source: 'link',
                            id,
                          })
                        }
                      />
                      <div className="error">{props.errors[index].link}</div>
                    </td>
                    <td
                      style={{
                        backgroundColor: snapshot.isDragging
                          ? 'white'
                          : 'transparent',
                        width: '25%',
                      }}
                    >
                      <input
                        className={`table-row__cell__input${
                          props.errors[index].linkName ? ' error-border' : ''
                        }`}
                        type="text"
                        value={linkName}
                        placeholder="Link Name"
                        onChange={(e) =>
                          props.onTextChange({
                            value: e.target.value,
                            source: 'linkName',
                            id,
                          })
                        }
                      />
                      <div className="error">
                        {props.errors[index].linkName}
                      </div>
                    </td>
                    <td
                      style={{
                        backgroundColor: snapshot.isDragging
                          ? 'white'
                          : 'transparent',
                        width: 50,
                      }}
                    >
                      <button
                        className="fill-cell danger"
                        type="button"
                        onClick={() =>
                          props.onDelete({ id, group: props.group })
                        }
                      >
                        <i className="far fa-trash-alt" />
                      </button>
                    </td>
                    {draggableProvided.placeholder}
                  </tr>
                )}
              </Draggable>
            ))}
          {provided.placeholder}
        </tbody>
      )}
    </Droppable>
  </Table>
);

export default CategorySectionTabel;
