/* eslint-disable no-param-reassign */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Classes,
  Tree,
  Button,
  ContextMenu,
  Menu,
  MenuItem,
  MenuDivider,
} from 'leap-menu-item';
import HoldButton from './HoldButton';
import { showWarningAlert, hideAlert } from '../../actions/alert';

import { pushToDataLayer } from '../../actions/tagManager';
import {
  setInitialStateForTemplate,
  setEditCellItem,
} from '../../actions/templateEdit';
import AddSectionButton from './AddSectionButton';
import ContractObject from './Models/ContractObject';
import { setPasteboardValue, getPasteboardValue } from '../../utils/Pasteboard';

const sectionMap = {
  body: 'Body',
  header: 'Header',
  signature: 'Signature',
};

const iconMap = {
  header: 'widget-header',
  body: 'widget-button',
  signature: 'draw',
};

const formattedHeaderGroups = (
  groupArray,
  index,
  parentId,
  expandedIds,
  editCellItem,
  onDelete,
  onContext,
) => ({
  id: groupArray.objectId,
  type: 'header',
  pasteKey: 'header-2',
  object: groupArray,
  hasCaret: groupArray.cellItems.length > 0,
  label: (
    <HoldButton
      longPressEnd={(e) => {
        onContext(
          {
            id: groupArray.objectId,
            type: 'header',
            pasteKey: 'header-2',
            object: groupArray,
          },
          e,
        );
      }}
    >
      {`Group ${index + 1}`}
    </HoldButton>
  ),

  isExpanded: expandedIds.indexOf(groupArray.objectId) > -1,
  isSelected: groupArray.objectId === editCellItem.objectId,
  secondaryLabel: (
    <Button icon="small-cross" onClick={() => onDelete(groupArray)} minimal />
  ),
  childNodes: groupArray.cellItems.map((groupItem) => {
    const { objectId: childId, value } = groupItem;
    return {
      id: groupItem.objectId,
      object: groupItem,
      type: 'header',
      pasteKey: 'header-3',
      hasCaret: false,
      label: (
        <HoldButton
          longPressEnd={(e) => {
            onContext(
              {
                id: groupItem.objectId,
                object: groupItem,
                type: 'header',
                pasteKey: 'header-3',
              },
              e,
            );
          }}
        >
          {value || '(undefined)'}
        </HoldButton>
      ),

      isExpanded: expandedIds.indexOf(childId) > -1,
      isSelected: groupItem.objectId === editCellItem.objectId,
      secondaryLabel: (
        <Button
          icon="small-cross"
          onClick={() => onDelete(groupItem)}
          minimal
        />
      ),
    };
  }),
});

const formattedHeaderData = (
  data,
  onDelete,
  expandedIds,
  editCellItem,
  onContext,
) =>
  data.map((object) => {
    const { objectId, appTitle, cellItems = [] } = object;
    return {
      id: objectId,
      object,
      type: 'header',
      pasteKey: 'header-1',
      hasCaret: cellItems.length > 0,
      label: (
        <HoldButton
          longPressEnd={(e) => {
            onContext(
              {
                id: objectId,
                object,
                type: 'header',
                pasteKey: 'header-1',
              },
              e,
            );
          }}
        >
          {appTitle}
        </HoldButton>
      ),

      isExpanded: expandedIds.indexOf(objectId) > -1,
      isSelected: objectId === editCellItem.objectId,
      secondaryLabel: (
        <Button icon="small-cross" onClick={() => onDelete(object)} minimal />
      ),
      childNodes: cellItems.map((groupArray, index) =>
        formattedHeaderGroups(
          groupArray,
          index,
          objectId,
          expandedIds,
          editCellItem,
          onDelete,
          onContext,
        ),
      ),
    };
  });

const formattedSignatureData = (
  data,
  onDelete,
  expandedIds,
  editCellItem,
  onContext,
) =>
  data.map((object) => {
    const { objectId, contractNote, additionalLines } = object;
    return {
      id: object.objectId,
      object,
      type: 'signature',
      pasteKey: 'signature-1',
      hasCaret: additionalLines.length > 0,
      label: (
        <HoldButton
          longPressEnd={(e) => {
            onContext(
              {
                id: object.objectId,
                object,
                type: 'signature',
                pasteKey: 'signature-1',
              },
              e,
            );
          }}
        >
          {contractNote}
        </HoldButton>
      ),
      isExpanded: expandedIds.indexOf(objectId) > -1,
      isSelected: object.objectId === editCellItem.objectId,
      secondaryLabel: (
        <Button icon="small-cross" onClick={() => onDelete(object)} minimal />
      ),
      childNodes: additionalLines.map((additionalLine) => {
        const { objectId: lineObjectId, note } = additionalLine;
        return {
          id: additionalLine.objectId,
          object: additionalLine,
          type: 'signature',
          pasteKey: 'signature-2',
          hasCaret: false,
          label: (
            <HoldButton
              longPressEnd={(e) => {
                onContext(
                  {
                    id: additionalLine.objectId,
                    object: additionalLine,
                    type: 'signature',
                    pasteKey: 'signature-2',
                  },
                  e,
                );
              }}
            >
              {note}
            </HoldButton>
          ),
          isExpanded: expandedIds.indexOf(lineObjectId) > -1,
          isSelected: additionalLine.objectId === editCellItem.objectId,
          secondaryLabel: (
            <Button
              icon="small-cross"
              onClick={() => onDelete(additionalLine)}
              minimal
            />
          ),
        };
      }),
    };
  });

const childNodesFromCellItem = (
  cellItem,
  expandedIds,
  editCellItem,
  onDelete,
  onContext,
) => {
  const { cellType } = cellItem;
  switch (cellType) {
    case 'header':
      return cellItem.header.cellItems.map((groupArray, index) =>
        formattedHeaderGroups(
          groupArray,
          index,
          cellItem.objectId,
          expandedIds,
          editCellItem,
          onDelete,
          onContext,
        ),
      );
    case 'signatures':
      return formattedSignatureData(
        cellItem.signatures,
        onDelete,
        expandedIds,
        editCellItem,
        onContext,
      );
    default: {
      return cellItem.detailItems.map((detailItem) => {
        const {
          objectId: detailItemId,
          appTitle: detailItemAppTitle,
        } = detailItem;
        return {
          id: detailItem.objectId,
          object: detailItem,
          type: 'body',
          pasteKey: 'body-3',
          hasCaret: false,
          label: (
            <HoldButton
              longPressEnd={(e) => {
                onContext(
                  {
                    id: detailItem.objectId,
                    object: detailItem,
                    type: 'body',
                    pasteKey: 'body-3',
                  },
                  e,
                );
              }}
            >
              {detailItemAppTitle}
            </HoldButton>
          ),
          isExpanded: expandedIds.indexOf(detailItemId) > -1,
          isSelected: detailItem.objectId === editCellItem.objectId,
          secondaryLabel: (
            <Button
              icon="small-cross"
              onClick={() => onDelete(detailItem)}
              minimal
            />
          ),
        };
      });
    }
  }
};

const formattedBodyData = (
  data,
  onDelete,
  expandedIds,
  editCellItem,
  onContext,
) =>
  data.map((object) => {
    const { objectId, appTitle, cellItems = [] } = object;
    return {
      id: objectId,
      object,
      type: 'body',
      pasteKey: 'body-1',
      hasCaret: cellItems.length > 0,
      label: (
        <HoldButton
          longPressEnd={(e) => {
            onContext(
              {
                id: objectId,
                object,
                type: 'body',
                pasteKey: 'body-1',
              },
              e,
            );
          }}
        >
          {appTitle}
        </HoldButton>
      ),

      isExpanded: expandedIds.indexOf(objectId) > -1,
      isSelected: object.objectId === editCellItem.objectId,
      secondaryLabel: (
        <Button icon="small-cross" onClick={() => onDelete(object)} minimal />
      ),
      childNodes: cellItems.map((cellItem) => {
        const childNodes = childNodesFromCellItem(
          cellItem,
          expandedIds,
          editCellItem,
          onDelete,
          onContext,
        );
        const nodeData = {
          id: cellItem.objectId,
          object: cellItem,
          type: 'body',
          pasteKey: 'body-2',
          hasCaret: childNodes.length > 0,
          label: (
            <HoldButton
              longPressEnd={(e) => {
                onContext(
                  {
                    id: cellItem.objectId,
                    object: cellItem,
                    type: 'body',
                    pasteKey: 'body-2',
                    hasCaret: childNodes.length > 0,
                  },
                  e,
                );
              }}
            >
              {cellItem.appTitle}
            </HoldButton>
          ),
          isExpanded: expandedIds.indexOf(cellItem.objectId) > -1,
          isSelected: cellItem.objectId === editCellItem.objectId,
          secondaryLabel: (
            <Button
              icon="small-cross"
              onClick={() => onDelete(cellItem)}
              minimal
            />
          ),
          childNodes,
        };

        return nodeData;
      }),
    };
  });

const childNodesFromSection = (
  section,
  type,
  onDelete,
  expandedIds,
  editCellItem,
  onContext,
) => {
  switch (type) {
    case 'header':
      return formattedHeaderData(
        section.data,
        onDelete,
        expandedIds,
        editCellItem,
        onContext,
      );
    case 'body':
      return formattedBodyData(
        section.data,
        onDelete,
        expandedIds,
        editCellItem,
        onContext,
      );
    case 'signature':
      return formattedSignatureData(
        section.data,
        onDelete,
        expandedIds,
        editCellItem,
        onContext,
      );
    default:
      return [];
  }
};

const initialStateForContractData = (
  { contractData },
  onDelete,
  expandedIds,
  editCellItem = {},
  onContext,
) =>
  contractData.map((object) => ({
    id: object.objectId,
    object,
    type: object.groupType,
    pasteKey: 'index',
    hasCaret: object.data.length > 0,
    icon: iconMap[object.groupType],
    label: (
      <HoldButton
        longPressEnd={(e) => {
          onContext(
            {
              id: object.objectId,
              object,
              type: object.groupType,
              pasteKey: 'index',
            },
            e,
          );
        }}
      >
        {sectionMap[object.groupType]}
      </HoldButton>
    ),
    isExpanded: expandedIds.indexOf(object.objectId) > -1,
    isSelected: object.objectId === editCellItem.objectId,
    secondaryLabel: (
      <Button icon="small-cross" onClick={() => onDelete(object)} minimal />
    ),
    childNodes: childNodesFromSection(
      object,
      object.groupType,
      onDelete,
      expandedIds,
      editCellItem,
      onContext,
    ),
  }));

class DocumentTree extends React.Component {
  constructor(props) {
    super(props);
    if (props.template) {
      this.contractObject = new ContractObject(props.template.toJSON());
      const nodes = initialStateForContractData(
        this.contractObject,
        this.handleOnDelete,
        [],
        props.editCellItem,
        (node, e) => {
          this.handleNodeContextMenu(node, node.object.getPath(), e);
        },
      );
      this.state = { nodes, expandedIds: [], isOpen: false };
    } else {
      this.state = { nodes: [], expandedIds: [], isOpen: false };
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.template) {
      const { editCellItem = {} } = newProps;
      const { identifier = '', objectId } = editCellItem;
      let expandedIds = this.state.expandedIds.filter((id) => id !== objectId);
      if (identifier) {
        const ids = identifier.split('-');
        expandedIds = expandedIds.filter((id) => ids.indexOf(id) === -1);
        expandedIds = [...expandedIds, ...ids];
      }
      this.contractObject = new ContractObject(newProps.template.toJSON());
      const nodes = initialStateForContractData(
        this.contractObject,
        this.handleOnDelete,
        expandedIds,
        newProps.editCellItem,
        (node, e) => {
          this.handleNodeContextMenu(node, node.object.getPath(), e);
        },
      );
      this.setState({ nodes, expandedIds });
    }
  }

  componentDidUpdate() {
    const { isOpen } = this.state;
    if (isOpen) {
      this.props.pushToDataLayer({
        event: 'contractSideDrawerEvent',
        eventCategory: 'Documents',
        eventAction: 'Side Drawer Open',
        eventLabel: 'DocumentTree',
      });
    }
  }

  handleNodeClick = (nodeData) => {
    const { editCellItem = {} } = this.props;
    if (
      nodeData.object.objectId === editCellItem.objectId &&
      editCellItem.objectId
    ) {
      this.props.setEditItem(undefined);
    } else {
      this.props.setEditItem(nodeData.object);
    }
  };

  handleNodeCollapse = (nodeData) => {
    nodeData.isExpanded = false;
    const expandedIds = this.state.expandedIds.filter(
      (id) => id !== nodeData.id,
    );
    this.setState({ expandedIds });
  };

  handleNodeExpand = (nodeData) => {
    nodeData.isExpanded = true;
    const expandedIds = [...this.state.expandedIds, nodeData.id];
    this.setState({ expandedIds });
  };

  handleOnDelete = (object) => {
    const expandedIds = this.state.expandedIds.filter(
      (id) => object.objectId !== id,
    );
    this.setState({ expandedIds });
    this.contractObject.deleteObject(object);
    const contractObject = new ContractObject(this.contractObject.toJSON());
    this.props.setTemplateState(contractObject);
  };

  handleCut = (object) => {
    this.handleCopy(object);
    this.handleOnDelete(object);
  };

  handleCopy = (object) => {
    setPasteboardValue(object);
  };

  handlePaste = (object, position) => {
    const copiedValue = getPasteboardValue();
    if (object && copiedValue) {
      const path = object.getPath();
      const clone = copiedValue.clone();
      const allIds = this.contractObject.getAllCellIdentifiers([]);
      if (clone.getCellIds) {
        const ids = clone.getCellIds();
        const conflicts = ids.filter((id) => allIds.indexOf(id) > -1);
        if (conflicts.length) {
          return this.props.showWarningAlert({
            title: 'Identifier Conflict',
            message: `Cannot paste due to cells already existing with identifiers: ${conflicts.join(
              ', ',
            )}`,
            onConfirm: () => {
              this.props.hideAlert();
            },
          });
        }
      }
      this.contractObject.insertObjectAtPath(path, clone, position);
      const contractObject = new ContractObject(this.contractObject.toJSON());
      this.props.setTemplateState(contractObject);
    }
  };

  handleOnInsert = (nodePath) => {
    this.contractObject.insertNewObjectAtPath(nodePath);
    this.props.setTemplateState(this.contractObject);
  };

  handleNodeContextMenu = (node, nodePath, e) => {
    e.preventDefault();
    const pasteBoardObject = getPasteboardValue() || {};
    let canPaste = node.object.pasteKey === pasteBoardObject.pasteKey;
    if (canPaste && pasteBoardObject.groupType === 'body') {
      canPaste = !this.contractObject.hasBodySection();
    }
    let canInsert = nodePath.length < 4;
    if (node.type === 'signature') {
      canInsert = nodePath.length < 3;
    }
    const menu = (
      <Menu>
        {canInsert && (
          <MenuItem
            onClick={() => this.handleOnInsert(nodePath)}
            icon="insert"
            text="New"
          />
        )}
        {canInsert && <MenuDivider />}
        <MenuItem
          onClick={() => this.handleCut(node.object)}
          icon="cut"
          text="Cut"
        />
        <MenuItem
          onClick={() => this.handleCopy(node.object)}
          icon="duplicate"
          text="Copy"
        />
        <MenuItem
          onClick={() => this.handlePaste(node.object, 0)}
          icon="clipboard"
          text="Paste Above"
          disabled={!canPaste}
        />
        <MenuItem
          onClick={() => this.handlePaste(node.object, 1)}
          icon="clipboard"
          text="Paste Below"
          disabled={!canPaste}
        />
      </Menu>
    );
    ContextMenu.show(menu, { left: e.clientX, top: e.clientY });
  };

  forEachNode(nodes, callback) {
    if (nodes == null) {
      return;
    }
    nodes.forEach((node) => {
      callback(node);
      this.forEachNode(node.childNodes, callback);
    });
  }

  render() {
    const { isOpen } = this.state;
    return (
      <div
        className={`document-tree__container${
          isOpen ? ' slide_right' : ' slide_left'
        }`}
      >
        <div className="document-tree__header">
          <button
            type="button"
            className="document-tree__collapse"
            onClick={() => this.setState({ isOpen: false })}
          >
            <i className="fas fa-arrow-left" />
          </button>
          Document Tree
          <div className="document-tree__add-button">
            {this.state.isOpen && <AddSectionButton />}
          </div>
          <button
            type="button"
            className={`document-tree__expand${isOpen ? '_open' : '_closed'}`}
            onClick={() => this.setState({ isOpen: true })}
          >
            <i className="fas fa-arrow-right" />
          </button>
        </div>
        <div className="document-tree__body">
          {this.state.isOpen && (
            <Tree
              contents={this.state.nodes}
              onNodeClick={this.handleNodeClick}
              onNodeCollapse={this.handleNodeCollapse}
              onNodeExpand={this.handleNodeExpand}
              onNodeContextMenu={this.handleNodeContextMenu}
              className={Classes.ELEVATION_0}
            />
          )}
        </div>
      </div>
    );
  }
}

DocumentTree.propTypes = {
  template: PropTypes.shape({
    objectId: PropTypes.string.isRequired,
  }),
  setTemplateState: PropTypes.func.isRequired,
  setEditItem: PropTypes.func.isRequired,
  pasteBoardObject: PropTypes.shape({
    sectionType: PropTypes.oneOf(['header', 'body', 'signature']),
    id: PropTypes.string,
    object: PropTypes.any,
  }),
};

DocumentTree.defaultProps = {
  template: undefined,
  pasteBoardObject: undefined,
};

const mapStateToProps = ({ templatesEdit }) => {
  const template = templatesEdit[templatesEdit.currentWorkingId];
  return {
    template,
    pasteBoardObject: templatesEdit.pasteBoardObject,
    editCellItem: templatesEdit.editCellItem,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
  setEditItem: (cellItem) => dispatch(setEditCellItem(cellItem)),
  pushToDataLayer: (variablesForLayer) =>
    dispatch(pushToDataLayer(variablesForLayer)),
  showWarningAlert: (props) => dispatch(showWarningAlert(props)),
  hideAlert: () => dispatch(hideAlert()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentTree);
