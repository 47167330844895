export const defaultState = {
  contractSendingAPIs: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'CONTRACT_SENDING_APIS':
      return action.loaded
        ? { ...state, contractSendingAPIs: action.apis }
        : state;
    case 'FETCH_COMPANIES':
      return { ...state, companies: action.companies };
    case 'FETCH_OFFICES':
      switch (action.descriptor) {
        case 'source':
          return { ...state, sourceOffices: action.offices };
        case 'destination':
          return { ...state, destinationOffices: action.offices };
        default:
          return state;
      }
    case 'FETCH_CATEGORIES':
      return { ...state, categories: action.categories };
    case 'FETCH_STYLES':
      return { ...state, styles: action.styles };
    case 'FETCH_STATES':
      return { ...state, states: action.states };
    default:
      return state;
  }
};
