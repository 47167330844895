/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import Parse from 'parse';

async function getSnapShotQuery(props) {
  const { template, limit, page } = props;
  const snapShotQuery = new Parse.Query('ContractObjectSnapshot');
  const contract = new Parse.Object('ContractObject');
  contract.id = template.objectId;
  snapShotQuery.equalTo('contractObject', contract);
  snapShotQuery.descending('createdAt');
  snapShotQuery.equalTo('isDeleted', false);
  snapShotQuery.select(['createdBy', 'createdAt', 'objectId', 'displayName']);
  snapShotQuery.include(['createdBy']);
  snapShotQuery.limit(limit);
  const skip = (page - 1) * limit;
  snapShotQuery.skip(skip);

  const results = await snapShotQuery.find();
  return results;
}

async function getDocumentSnapshot(props) {
  const { setDocumentSnapshots, show, template, limit, page } = props;

  if (show) {
    const snapShots = await getSnapShotQuery({ template, limit, page });

    const snapShotData = snapShots.map((snapShot) => {
      const createdBy = snapShot.get('createdBy');
      const createdAt = snapShot.get('createdAt');
      const displayName = snapShot.get('displayName');
      const firstName = createdBy ? createdBy.get('nameFirst') : 'unknown';
      const lastName = createdBy ? createdBy.get('nameLast') : '';
      return {
        id: snapShot.id,
        displayName,
        user: {
          firstName,
          lastName,
        },
        createdAt,
      };
    });
    setDocumentSnapshots(snapShotData);
  }
}

export default function useGetDocumentSnapshot(props) {
  const { show, limit, page } = props;

  useEffect(() => {
    getDocumentSnapshot(props);
  }, [show, limit, page]);
}
