import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import Parse from 'parse';
import { FileInput } from '@blueprintjs/core';
import { DebounceInput } from 'react-debounce-input';
import FormGroup, { SwitchGroup } from '../FormGroup';
import OfficesDropDown from '../IncludedOfficesDropDown';
import MeasureSheetItem from '../../Models/MeasureSheetItem';
import { newFileFromEvent } from '../../utils/utils';

const saveItem = async (item, callback) => {
  await item.save();
  if (callback) {
    callback(item);
  }
};

const MSIEditModal = ({ show, item = new Parse.Object(), onClose }) => {
  const [thumbnailURL, setThumbnailURL] = useState('/images/no_image.png');

  const onFileChanged = async (e) => {
    const file = await newFileFromEvent(e);
    item.set('image', file);
    if (file && item.has('image')) {
      setThumbnailURL(item.get('image').url());
    }
  };

  const [showToggle, setShowToggle] = useState(item.get('shouldShowSwitch'));

  const [storedChanges, setStoredChanges] = useState({});

  const itemOffices = item.get('includedOffices') || [];

  const [includedOffices, setIncludedOffices] = useState(itemOffices);

  if (itemOffices.length > 0 && includedOffices.length === 0) {
    setIncludedOffices(itemOffices);
  }
  useEffect(() => {
    if (includedOffices.length && !show) {
      setIncludedOffices([]);
    }
  }, [includedOffices.length, show]);

  const onCancel = () => {
    item.revert();
    if (Object.keys(storedChanges).length) {
      item.set(storedChanges);
    }
    setStoredChanges({});
    onClose();
  };

  useEffect(() => {
    if (show) {
      const unsavedValues = item.unsavedValues();
      setStoredChanges(unsavedValues);
      if (item.has('image')) {
        setThumbnailURL(item.get('image').url());
      }
    }
  }, [show, item]);

  return (
    <Modal
      show={show}
      onHide={onCancel}
      dialogClassName="price-guide__edit-msi-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Select Values to Copy</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <img
          className="packages-thumbnail-img"
          alt="Thumbnail"
          src={thumbnailURL}
        />
        <FormGroup title="Thumbnail">
          <div className="packages-thumbnail ">
            <FileInput
              text="Choose file..."
              onChange={onFileChanged}
              inputProps={{ accept: 'image/*' }}
              fill
              large
            />
          </div>
        </FormGroup>
        <FormGroup title="Category">
          <DebounceInput
            minLength={0}
            debounceTimeout={300}
            value={item.get('category')}
            onChange={(e) => item.set('category', e.target.value)}
          />
        </FormGroup>
        <FormGroup title="Sub-Category">
          <DebounceInput
            minLength={0}
            debounceTimeout={300}
            value={item.get('subCategory')}
            onChange={(e) => item.set('subCategory', e.target.value)}
          />
        </FormGroup>
        <FormGroup title="Drill-Downs">
          <DebounceInput
            minLength={0}
            debounceTimeout={300}
            value={item.get('subSubCategories')}
            onChange={(e) => item.set('subSubCategories', e.target.value)}
            placeholder="ex: Showers>Acrylic>White"
          />
        </FormGroup>
        <FormGroup title="Name">
          <DebounceInput
            minLength={0}
            debounceTimeout={300}
            value={item.get('itemName')}
            onChange={(e) => item.set('itemName', e.target.value)}
          />
        </FormGroup>
        <FormGroup title="Note">
          <DebounceInput
            minLength={0}
            debounceTimeout={300}
            value={item.get('itemNote')}
            onChange={(e) => item.set('itemNote', e.target.value)}
          />
        </FormGroup>
        <FormGroup title="Measurement">
          <DebounceInput
            minLength={0}
            debounceTimeout={300}
            value={item.get('measurementType')}
            onChange={(e) => item.set('measurementType', e.target.value)}
          />
        </FormGroup>
        <SwitchGroup
          title="Show Toggle"
          checked={showToggle}
          onChange={(value) => {
            item.set('shouldShowSwitch', value);
            setShowToggle(value);
          }}
        />
        <FormGroup title="Included Offices">
          <OfficesDropDown
            onChange={(value) => {
              const newOffices = value.map((id) => {
                const office = new Parse.Object('Office');
                office.id = id;
                return office.toPointer();
              });
              item.set('includedOffices', newOffices);
              setIncludedOffices(newOffices);
            }}
            selected={includedOffices.map(
              (office) => office.id || office.objectId,
            )}
          />
        </FormGroup>
        <FormGroup title="Formula Id">
          <DebounceInput
            minLength={0}
            debounceTimeout={300}
            value={item.get('formulaID')}
            onChange={(e) => item.set('formulaID', e.target.value)}
          />
        </FormGroup>
        <FormGroup title="Quantity Formula">
          <DebounceInput
            minLength={0}
            debounceTimeout={300}
            value={item.get('qtyFormula')}
            onChange={(e) => item.set('qtyFormula', e.target.value)}
          />
        </FormGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onCancel}>Close</Button>
        <Button onClick={() => saveItem(item, onClose)} variant="danger">
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

MSIEditModal.propTypes = {
  show: PropTypes.bool,
  item: PropTypes.instanceOf(MeasureSheetItem),
  onClose: PropTypes.func.isRequired,
};

MSIEditModal.defaultProps = {
  show: false,
  item: undefined,
};

export default MSIEditModal;
