import Validator from 'validator';

export const validateEmailAddress = (value = '') => {
  const placeholderEmails = ['%userEmail%', '%customerEmail%'];
  if (!Validator.isEmail(value) && placeholderEmails.indexOf(value) === -1) {
    return 'Invalid email address';
  }
  return '';
};

export const validateFrom = (value, state) => {
  if (state.endpoints?.length && state.endpoints.indexOf('Email') > -1) {
    return validateEmailAddress(value);
  }
  return '';
};

export const validateCCRecipients = (value = [], state) => {
  if (state.endpoints?.length && state.endpoints.indexOf('Email') > -1) {
    for (let i = 0; i < value.length; i += 1) {
      const error = validateEmailAddress(value[i]);
      if (error) {
        return error;
      }
    }
  }
  return '';
};

export const validateRecipients = (value = [], state) => {
  if (state.endpoints?.length && state.endpoints.indexOf('Email') > -1) {
    if (!value.length) {
      return 'You must specify at least 1 email address';
    }
    return validateCCRecipients(value, state);
  }
  return '';
};

export const validateSubjectLine = (value = '', state) => {
  if (state.endpoints?.length && state.endpoints.indexOf('Email') > -1) {
    if (!value.length) {
      return 'Subject cannot be blank';
    }
  }
  return '';
};

export const validateEmailHTML = (value = '', state) => {
  if (state.endpoints?.length && state.endpoints.indexOf('Email') > -1) {
    if (!value.length) {
      return 'Email HTML cannot be blank';
    }
  }
  return '';
};

export const validateEmailFileName = (value = '', state) => {
  if (state.endpoints?.length && state.endpoints.indexOf('Email') > -1) {
    if (!value.length) {
      return 'File Name cannot be blank';
    }
    if (!value.endsWith('.pdf')) {
      return "Attachment File Name must end with '.pdf'";
    }
  }
  return '';
};

export const validateMarketsharpBackupRecipients = (value = [], state) => {
  if (state.endpoints?.length && state.endpoints.indexOf('Marketsharp') > -1) {
    if (!value.length) {
      return 'You must specify at least 1 email address';
    }
    for (let i = 0; i < value.length; i += 1) {
      const error = validateEmailAddress(value[i]);
      if (error) {
        return error;
      }
    }
  }
  return '';
};

export const validateMarketsharpFileName = (value = [], state) => {
  if (state.endpoints?.length && state.endpoints.indexOf('Marketsharp') > -1) {
    if (!value.length) {
      return 'File Name cannot be blank';
    }
    if (!value.endsWith('.pdf')) {
      return "File Name must end with '.pdf'";
    }
  }
  return '';
};

export const validateLeadPerfection = (value = {}, state) => {
  const errors = {
    fileName: '',
    fileDescription: '',
    backupRecipients: '',
  };
  if (
    state.endpoints?.length &&
    state.endpoints.indexOf('LeadPerfection') > -1
  ) {
    // Validate file name
    if (!value.fileName) {
      errors.fileName = 'File Name cannot be blank';
    } else if (!value.fileName.endsWith('.pdf')) {
      errors.fileName = "File Name must end with '.pdf'";
    }
    // Validate file description
    if (!value.fileDescription) {
      errors.fileDescription = 'File Description cannot be blank';
    } else {
      // Replace the date placeholder so that we can check the length is less than 40
      const fileDescription = value.fileDescription
        .split('%date%')
        .join('12-31-2018');
      if (fileDescription.length > 40) {
        errors.fileDescription = 'No more than 40 characters allowed';
      } else {
        // Validate there is no placeholders used in the file description
        const placeholders = [
          '%stateLicense%',
          '%usersLicense%',
          '%usersName%',
          '%usersPhone%',
          '%userEmail%',
          '%customerName%',
          '%customerSpouseName%',
          '%customerAddressFull%',
          '%customerAddressLine1%',
          '%customerAddressLine2%',
          '%customerContacts%',
          '%customerEmail%',
          '%customerPhone%',
        ];
        for (let i = 0; i < placeholders.length; i += 1) {
          if (fileDescription.indexOf(placeholders[i]) > -1) {
            errors.fileDescription = `You cannot use the '${placeholders[i]}' placeholder in the File Description`;
            break;
          }
        }
      }
    }
    // Validate Backup Recipients
    if (!value.backupRecipients || !value.backupRecipients.length) {
      errors.backupRecipients = 'You must specify at least 1 email address';
    } else {
      for (let i = 0; i < value.backupRecipients.length; i += 1) {
        const error = validateEmailAddress(value.backupRecipients[i]);
        if (error) {
          errors.backupRecipients = error;
          break;
        }
      }
    }
  }
  return errors;
};

export const validateSalesforce = (value = {}, state) => {
  const { fileName = '', backupRecipients = [] } = value;
  const errors = {
    fileName: '',
    backupRecipients: '',
  };
  if (state.endpoints?.length && state.endpoints.indexOf('Salesforce') > -1) {
    // Validate file name
    if (!fileName) {
      errors.fileName = 'File Name cannot be blank';
    } else if (!fileName.endsWith('.pdf')) {
      errors.fileName = "File Name must end with '.pdf'";
    }
    // Validate Backup Recipients
    if (!backupRecipients.length) {
      errors.backupRecipients = 'You must specify at least 1 email address';
    }
    for (let i = 0; i < backupRecipients.length; i += 1) {
      const error = validateEmailAddress(backupRecipients[i]);
      if (error) {
        errors.backupRecipients = error;
        break;
      }
    }
  }
  return errors;
};

export const validateJobNimbus = (value = {}, state) => {
  const { fileName = '', backupRecipients = [] } = value;
  const errors = {
    fileName: '',
    backupRecipients: '',
  };
  if (state.endpoints?.length && state.endpoints.indexOf('JobNimbus') > -1) {
    // Validate file name
    if (!fileName) {
      errors.fileName = 'File Name cannot be blank';
    } else if (!fileName.endsWith('.pdf')) {
      errors.fileName = "File Name must end with '.pdf'";
    }
    // Validate Backup Recipients
    if (!backupRecipients.length) {
      errors.backupRecipients = 'You must specify at least 1 email address';
    }
    for (let i = 0; i < backupRecipients.length; i += 1) {
      const error = validateEmailAddress(backupRecipients[i]);
      if (error) {
        errors.backupRecipients = error;
        break;
      }
    }
  }
  return errors;
};

export const validateJobProgress = (value = {}, state) => {
  const { fileName = '', backupRecipients = [], fileDestination = '' } = value;
  const errors = {
    fileName: '',
    backupRecipients: '',
    fileDestination: '',
  };
  if (state.endpoints?.length && state.endpoints.indexOf('JobProgress') > -1) {
    // Validate file name
    if (!fileName) {
      errors.fileName = 'File Name cannot be blank';
    } else if (!fileName.endsWith('.pdf')) {
      errors.fileName = "File Name must end with '.pdf'";
    }

    if (!fileDestination) {
      errors.fileDestination = 'File Destination cannot be blank';
    }

    if (
      ![
        'estimates',
        'proposals',
        'materials',
        'work_orders',
        'measurements',
      ].includes(fileDestination)
    ) {
      errors.fileDestination = 'Invalid File Destination';
    }

    // Validate Backup Recipients
    if (!backupRecipients.length) {
      errors.backupRecipients = 'You must specify at least 1 email address';
    }
    for (let i = 0; i < backupRecipients.length; i += 1) {
      const error = validateEmailAddress(backupRecipients[i]);
      if (error) {
        errors.backupRecipients = error;
        break;
      }
    }
  }
  return errors;
};

export const validateWebhook = (value, state) => {
  const errors = {
    url: '',
  };
  if (state.endpoints?.length && state.endpoints.indexOf('Webhook') > -1) {
    if (!value?.url || !Validator.isURL(value.url, { protocols: ['https'] })) {
      errors.url = "Must be a valid URL that begins with 'https://'";
    }
  }
  return errors;
};
