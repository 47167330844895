/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Toggle from './Toggle';

const List = ({ items, header, type, collapse, onHeaderClick }) => (
  <div
    style={{
      position: 'relative',
      flex: 1,
      transition: 'height .5s',
    }}
  >
    <div
      onClick={(event) => {
        onHeaderClick();
      }}
      className={`price-guide__${type}-background`}
      style={{
        fontWeight: '600',
        textAlign: 'center',
        cursor: 'pointer',
        transition: 'height .5s',
        whiteSpace: 'nowrap',
      }}
    >
      {header}
    </div>

    <ul
      className={`price-guide__${type}-background-light`}
      style={{
        borderBottomLeftRadius: type === 'msi' ? '4px' : 0,
        borderBottomRightRadius: type === 'upcharge' ? '4px' : 0,
        height: !collapse ? '100%' : 0,
        overflow: 'hidden',
        transition: 'height .5s',
        listStyle: 'none',
        margin: 0,
        padding: 0,
        paddingTop: 0,
        position: 'relative',
        zIndex: '1',
      }}
    >
      {items.map(({ title, source }) => (
        <li key={`${type}-${source}`}>
          <Toggle title={title} source={source} />
        </li>
      ))}
    </ul>
  </div>
);

List.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      source: PropTypes.string.isRequired,
    }),
  ).isRequired,
  header: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  collapse: PropTypes.bool,
};

List.defaultProps = {
  collapse: false,
};

export default List;
