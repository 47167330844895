export const showDeleteConfirmation = ({
  message,
  title,
  onConfirm = () => {},
}) => ({
  type: 'SHOW_DELETE_CONFIRMATION',
  message,
  title,
  onConfirm,
});

export const hideDeleteConfirmation = () => ({
  type: 'HIDE_DELETE_CONFIRMATION',
});
