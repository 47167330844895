/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Menu, MenuItem, TextArea, Button } from 'leap-menu-item';
import { setInitialStateForTemplate } from '../../../../actions/templateEdit';
import YesNoMenuItem from '../YesNoMenuItem';
import ContractObject from '../../Models/ContractObject';
import DynamicInputEditor from './DynamicInputEditor';
import InitialsRequired from './InitialsRequired';
import DebounceInputWithNet from '../DebounceInputWithNet';

const SecurePaymentMenu = ({ object, contractObject, setTemplateState }) => (
  <>
    <YesNoMenuItem
      text="Dynamic"
      active={object.inputType === 'dynamic'}
      onChange={(value) => {
        object.inputType = value ? 'dynamic' : '';
        setTemplateState(contractObject);
      }}
    />
    {object.inputType === 'dynamic' && (
      <DynamicInputEditor
        contractObject={contractObject}
        cellItem={object}
        onChange={(value) => {
          object.dynamicInputType = value;
          setTemplateState(contractObject);
        }}
      />
    )}
    <YesNoMenuItem
      text="Capture Later Enabled"
      active={!object.disablePaymentRequest}
      onChange={(value) => {
        object.disablePaymentRequest = !value;
        setTemplateState(contractObject);
      }}
    />
    {!object.disablePaymentRequest && (
      <MenuItem text="Success Value">
        <DebounceInputWithNet
          placeholder="Pending Capture via Email"
          element={TextArea}
          minLength={0}
          debounceTimeout={300}
          value={object.paymentRequestText}
          onChange={(e) => {
            object.paymentRequestText = e.target.value;
            setTemplateState(contractObject);
          }}
        />
      </MenuItem>
    )}
    <Menu.Divider />
    <YesNoMenuItem
      text="Required"
      active={object.required}
      onChange={(value) => {
        object.required = value;
        setTemplateState(contractObject);
      }}
    />
    <Menu.Divider />
    {!object.titleLinked && (
      <>
        <MenuItem
          text={
            <span>
              App Title
              <Button
                small
                minimal
                icon="unlock"
                onClick={() => {
                  object.titleLinked = true;
                  object.contractTitle = object.appTitle;
                  setTemplateState(contractObject);
                }}
              />
            </span>
          }
        >
          <DebounceInputWithNet
            element={TextArea}
            minLength={0}
            debounceTimeout={300}
            value={object.appTitle}
            onChange={(e) => {
              object.appTitle = e.target.value;
              setTemplateState(contractObject);
            }}
          />
        </MenuItem>
        <MenuItem
          text={
            <span>
              PDF Title
              <Button
                small
                minimal
                icon="unlock"
                onClick={() => {
                  object.titleLinked = true;
                  object.appTitle = object.contractTitle;
                  setTemplateState(contractObject);
                }}
              />
            </span>
          }
        >
          <DebounceInputWithNet
            element={TextArea}
            minLength={0}
            debounceTimeout={300}
            value={object.contractTitle}
            onChange={(e) => {
              object.contractTitle = e.target.value;
              setTemplateState(contractObject);
            }}
          />
        </MenuItem>
      </>
    )}
    {object.titleLinked && (
      <MenuItem
        text={
          <span>
            Title
            <Button
              small
              minimal
              icon="lock"
              onClick={() => {
                object.titleLinked = false;
                setTemplateState(contractObject);
              }}
            />
          </span>
        }
      >
        <DebounceInputWithNet
          element={TextArea}
          minLength={0}
          debounceTimeout={300}
          value={object.contractTitle}
          onChange={(e) => {
            object.contractTitle = e.target.value;
            setTemplateState(contractObject);
          }}
        />
      </MenuItem>
    )}
    <MenuItem text="App Note">
      <DebounceInputWithNet
        element={TextArea}
        minLength={0}
        debounceTimeout={300}
        value={object.appNote}
        onChange={(e) => {
          object.appNote = e.target.value;
          setTemplateState(contractObject);
        }}
      />
    </MenuItem>
    <InitialsRequired
      object={object}
      setTemplateState={setTemplateState}
      contractObject={contractObject}
    />
  </>
);

SecurePaymentMenu.propTypes = {
  object: PropTypes.any.isRequired,
  contractObject: PropTypes.instanceOf(ContractObject).isRequired,
  setTemplateState: PropTypes.func.isRequired,
};

SecurePaymentMenu.defaultProps = {};

const mapStateToProps = ({ templatesEdit = {}, auth }) => ({
  templateValues: templatesEdit[templatesEdit.currentWorkingId],
  editCellItem: templatesEdit.editCellItem,
  secureCaptureEnabled: auth.company.get('spreedlyEnabled'),
});

const mapDispatchToProps = (dispatch) => ({
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SecurePaymentMenu);
