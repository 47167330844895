import React from 'react';
import PropTypes from 'prop-types';
import LeapPieChartLabel from './LeapPieChartLabel';

const PieCellRenderWrapper = ({ cellProps, userProps: { labelRenderer } }) =>
  labelRenderer ? (
    <LeapPieChartLabel userLabel={labelRenderer(cellProps)} {...cellProps} />
  ) : (
    <LeapPieChartLabel {...cellProps} />
  );

PieCellRenderWrapper.propTypes = {
  cellProps: PropTypes.shape({
    cx: PropTypes.number,
    cy: PropTypes.number,
    midAngle: PropTypes.number,
    innerRadius: PropTypes.number,
    outerRadius: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  userProps: PropTypes.shape({
    labelRenderer: PropTypes.func,
  }).isRequired,
};

export default PieCellRenderWrapper;
