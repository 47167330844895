/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import ThumbnailPopover from './ThumbnailPopover';

class ThumbnailButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
    this.containerRef = React.createRef();
    this.mouseOver = false;
  }

  onMouseEnter = () => {
    this.mouseOver = true;
    this.setState({ show: true });
  };

  onMouseLeave = () => {
    this.mouseOver = false;
    this.setState({ show: false });
  };

  render() {
    const { imageURL, name, disabled, onFileChange } = this.props;

    return (
      <div
        style={{
          overflow: this.state.show ? 'visible' : 'hidden',
        }}
        className="priceguide__thumbnail-button"
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        ref={this.containerRef}
      >
        <div
          className="thumnail-pop-up"
          style={{
            display: this.state.show ? 'block' : 'none',
          }}
        >
          <ThumbnailPopover
            url={imageURL}
            name={name}
            onClear={() => {
              onFileChange({
                isNew: true,
              });
            }}
          />
        </div>
        <button type="button" disabled={disabled}>
          <img alt={name} src={imageURL} />
        </button>
        <input accept="image/*" type="file" onChange={onFileChange} />
      </div>
    );
  }
}

ThumbnailButton.propTypes = {
  imageURL: PropTypes.string.isRequired,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onFileChange: PropTypes.func,
};

ThumbnailButton.defaultProps = {
  name: 'Thumbnail',
  disabled: false,
  onFileChange: () => {},
};

export default ThumbnailButton;
