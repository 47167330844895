/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/** @jsx jsx */
import { jsx } from '@emotion/core';
// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import BodyCellItemObject from '../../Models/BodyCellItem';
import BodyRow from '../../BodyRow';
import TextShort from './TextShort';
import { setOnDragEndCallback } from '../../../DropContext';
import {
  setInitialStateForTemplate,
  setEditCellItem,
} from '../../../../actions/templateEdit';
import ContractObject from '../../Models/ContractObject';
import { getItemStyle, getListStyle } from '../../Utils';

export const updateDraggableCallback = (
  dropId,
  cellItem,
  setTemplateState,
  setEditItem,
) => {
  setOnDragEndCallback(dropId, (result) => {
    const contractObject = cellItem.getSourceObject();
    const updatedContractObject = new ContractObject(contractObject.toJSON());
    const isNew =
      result.draggableId === `new-${BodyCellItemObject.DetailContainerKey}`;

    let sourceItem;
    if (isNew) {
      sourceItem = new BodyCellItemObject();
      setEditItem(sourceItem);
    } else {
      let sourcePath = result.draggableId.split('-');
      sourcePath.pop();
      sourcePath.pop();
      sourcePath = sourcePath.map((index) => parseInt(index, 10));
      sourceItem = updatedContractObject.getItemAtPath(sourcePath);
    }

    if (!isNew) {
      const { parent } = sourceItem;
      const appOnlyItems = [];
      const columnItems = [];
      parent.detailItems.forEach((obj) => {
        columnItems.push(obj);
      });
      let sourceContainer = result.source.droppableId.split('-');
      sourceContainer.pop();
      sourceContainer.pop();
      sourceContainer.pop();
      sourceContainer = sourceContainer.map((i) => parseInt(i, 10)).pop();

      columnItems.splice(result.source.index, 1);
      parent.detailItems = columnItems;
      for (let i = 0; i < appOnlyItems.length; i += 2) {
        const appOnlyIndex = appOnlyItems[i];
        const object = appOnlyItems[i + 1];
        parent.detailItems.splice(appOnlyIndex, 0, object);
      }
    }
    let destinationPath = result.destination.droppableId.split('-');
    destinationPath.pop();
    destinationPath.pop();
    destinationPath.pop();
    destinationPath = destinationPath.map((index) => parseInt(index, 10));
    destinationPath.pop();
    const destinationParent = updatedContractObject.getItemAtPath(
      destinationPath,
    );
    const { index: destinationIndex } = result.destination;
    const destinationColumnItems = [];
    destinationParent.detailItems.forEach((obj) => {
      destinationColumnItems.push(obj);
    });

    sourceItem.cellType = 'textXLong';
    destinationColumnItems.splice(destinationIndex, 0, sourceItem);
    sourceItem.parent = cellItem;
    destinationParent.detailItems = destinationColumnItems;
    if (destinationParent.detailItems.length === 0) {
      updatedContractObject.deleteObjectAtPath(destinationParent.getPath());
    }
    setTemplateState(updatedContractObject);
  });
};

const ProVia = ({
  cellItem,
  editCellItem,
  setTemplateState,
  draggableType,
  setEditItem,
}) => {
  const onMouseDown = () => {
    setEditItem(cellItem);
  };

  const { detailItems = [] } = cellItem;
  const droppableIdBottom = `${[...cellItem.getPath(), 2].join('-')}-${
    cellItem.objectId
  }-pdf-${BodyCellItemObject.DetailContainerKey}`;
  updateDraggableCallback(
    droppableIdBottom,
    cellItem,
    setTemplateState,
    setEditItem,
  );
  return (
    <BodyRow cellItem={cellItem}>
      <div className="templates-app__provia">
        <div css={{ display: 'flex' }} onClick={() => onMouseDown()}>
          <div css={{ minWidth: '50%', maxWidth: '50%' }}>
            <img
              css={{ objectFit: 'contain', width: '100%' }}
              src="/images/provia_door.png"
              alt="Provia Door"
            />
            <div css={{ display: 'flex', width: '100%' }}>
              <span className="templates-app__provia_image_label">
                Inside View
              </span>
              <span className="templates-app__provia_image_label">
                Outside View
              </span>
            </div>
          </div>
          <div css={{ minWidth: '50%', maxWidth: '50%' }}>
            <p className="templates-app__provia_details_bold">
              Signet Single Entry Door in Full Wood Frame
            </p>
            <p className="templates-app__provia_details">
              -36&quot; x 80&quot; Nominal Size
            </p>
            <p className="templates-app__provia_details">
              -14&quot; Sidelite Width
            </p>
            <p className="templates-app__provia_details">
              -Unit Size: 68 1/16&quot; x 81 5/8&quot; -Frame Depth: 4
              9/16&quot;
            </p>
            <p className="templates-app__provia_details">
              -2&quot; Standard Brickmold
            </p>
            <p className="templates-app__provia_details">
              -Right Hand Inswing - Inside Looking Out
            </p>
            <p className="templates-app__provia_details">-Frame</p>
            <p className="templates-app__provia_details">
              -Snow Mist White Aluminum Frame Cladding
            </p>
            <p className="templates-app__provia_details">
              -Chestnut Inside Frame
            </p>
            <p className="templates-app__provia_details">
              -Cherry Inside Mull Cover - Chestnut
            </p>
            <p className="templates-app__provia_details">
              -Bronze Sahara Threshold (6 1/16&quot; Depth)
            </p>
            <p className="templates-app__provia_details">
              -Satin Nickel Ball Bearing Hinges
            </p>
            <p className="templates-app__provia_details">-Security Plate</p>
            <p className="templates-app__provia_details">
              -Cherry Colonial 2 1/2&quot; Casing - Chestnut
            </p>
            <p className="templates-app__provia_details_bold">Entry Door</p>
            <p className="templates-app__provia_details">
              -449 Style Signet Cherry Fiberglass Door
            </p>
            <p className="templates-app__provia_details">-With Panel Planks</p>
            <p className="templates-app__provia_details">
              -Somerset Decorative Glass with Zinc Caming and Low-E (SOM)
            </p>
            <p className="templates-app__provia_details">
              -Chestnut Inside and Outside
            </p>
            <p className="templates-app__provia_details">-Hardware</p>
            <p className="templates-app__provia_details">
              -All Hardware in Satin Nickel Finish
            </p>
            <p className="templates-app__provia_details">
              -Camelot Grip Entrance Handle Outside
            </p>
            <p className="templates-app__provia_details">
              -Flair Handle Inside
            </p>
            <p className="templates-app__provia_details">-Thumbturn Deadbolt</p>
            <p className="templates-app__provia_details_bold">
              Sidelites on Both Sides
            </p>
            <p className="templates-app__provia_details">
              -160 Style Signet Cherry Fiberglass Sidelite
            </p>
            <p className="templates-app__provia_details">
              -Somerset Decorative Glass with Zinc Caming and Low-E (SOM)
            </p>
            <p className="templates-app__provia_details">
              -Chestnut Inside and Outside
            </p>
          </div>
        </div>
        <div>
          <div className="templates-app__provia_quantity_box">
            <div className="templates-app__provia_quantity_label">Quantity</div>
            <div className="templates-app__provia_quantity_number">2</div>
          </div>
          <div className="templates-app__provia_image_label">40123456</div>
        </div>
      </div>
      <Droppable
        droppableId={droppableIdBottom}
        isDropDisabled={draggableType !== BodyCellItemObject.DetailContainerKey}
      >
        {(provided, snapshot) => (
          <div
            css={{
              ...getListStyle(snapshot.isDraggingOver),
              minHeight: 20,
            }}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {detailItems.map((cell, index) => (
              <Draggable
                key={cell.objectId}
                draggableId={`${cell.getPath().join('-')}-pdf-${cell.objectId}`}
                index={index}
              >
                {(draggableProvided, draggableSnapshot) => (
                  <div
                    key={cell.objectId}
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}
                    css={getItemStyle(
                      draggableSnapshot,
                      draggableProvided.draggableProps.style,
                      editCellItem && cell.objectId === editCellItem.objectId,
                    )}
                  >
                    <TextShort cellItem={cell} />
                    {draggableProvided.placeholder}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </BodyRow>
  );
};

ProVia.propTypes = {
  cellItem: PropTypes.instanceOf(BodyCellItemObject).isRequired,
};
const mapStateToProps = ({ templatesEdit }) => ({
  templateValues: templatesEdit[templatesEdit.currentWorkingId],
  editCellItem: templatesEdit.editCellItem,
  draggableType: templatesEdit.draggableType,
});

const mapDispatchToProps = (dispatch) => ({
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
  setEditItem: (cellItem) => dispatch(setEditCellItem(cellItem)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProVia);
