/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { Button } from '@blueprintjs/core';
import 'react-dates/initialize';
import FormGroup, { TextGroup } from './FormGroup';

class BillingContactModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: props.firstName,
      lastName: props.lastName,
      email: props.email,
    };
  }

  UNSAFE_componentWillReceiveProps({ firstName, lastName, email }) {
    this.setState({ firstName, lastName, email });
  }

  onChange = (key, value) => {
    this.setState({ [key]: value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { firstName: fn, lastName: ln, email: em } = this.state;
    this.props.onChange({ firstName: fn, lastName: ln, email: em });
  };

  render() {
    const { show, title, onClose } = this.props;
    const { firstName, lastName, email } = this.state;
    return (
      <Modal show={show} variant="lg" onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ marginBottom: '2rem' }}>
            <TextGroup
              title="First Name"
              id="firstName"
              value={firstName}
              onChange={(value) => this.onChange('firstName', value)}
            />
          </div>
          <div style={{ marginBottom: '2rem' }}>
            <TextGroup
              title="Last Name"
              id="lastName"
              value={lastName}
              onChange={(value) => this.onChange('lastName', value)}
            />
          </div>
          <div style={{ marginBottom: '2rem' }}>
            <TextGroup
              title="Email"
              id="email"
              value={email}
              onChange={(value) => this.onChange('email', value)}
            />
          </div>
          <FormGroup title="">
            <Button type="submit" onClick={this.onSubmit}>
              Submit
            </Button>
          </FormGroup>
        </Modal.Body>
      </Modal>
    );
  }
}

BillingContactModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
};

BillingContactModal.defaultProps = {
  show: false,
  title: 'Change Billing Contact',
  firstName: '',
  lastName: '',
  email: '',
};

export default BillingContactModal;
