import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import './index.scss';

const BreadCrumbBar = ({ crumbs, buttons, toolbar }) => {
  return toolbar ? (
    <div className="leap-crumb-bar">{toolbar}</div>
  ) : (
    <div id="top-crumb-bar" className="leap-crumb-bar p-2 px-3 d-flex">
      <div className="breadcrumbs d-none d-sm-flex flex-grow-1 align-items-center px-1">
        {crumbs.map((crumb, i, list) => {
          return (
            <React.Fragment key={crumb.title}>
              <Link to={crumb.link} className="text-dark">
                {crumb.title}
              </Link>
              {list.length - 1 === i ? '' : <span>/</span>}
            </React.Fragment>
          );
        })}
      </div>
      <div className="action-space py-2 d-flex flex-sm-column flex-md-row ">
        {buttons}
      </div>
    </div>
  );
};

export const BreadCrumb = PropTypes.shape({
  title: PropTypes.string,
  link: PropTypes.string,
});

BreadCrumbBar.propTypes = {
  crumbs: PropTypes.arrayOf(BreadCrumb),
  buttons: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  toolbar: PropTypes.node,
};
BreadCrumbBar.defaultProps = {
  crumbs: [],
  buttons: null,
  toolbar: null,
};

export default BreadCrumbBar;
