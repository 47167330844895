/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import Parse from 'parse';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import { startFetchCompanies } from '../../actions/config';
import FormGroup, {
  DropDownGroup,
  MultiSelectGroup,
  ButtonGroup,
} from '../FormGroup';
import Panel from '../Panel';
import AppToaster from '../../utils/AppToaster';
import StateSelector from '../Templates/StateSelector';

const officeValueRenderer = (selected, options) => {
  if (selected.length === 0) {
    return 'Select offices to copy templates TO';
  }
  if (selected.length === options.length) {
    return 'All offices selected';
  }
  return '';
};

export class CopyTemplatesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companies: props.companies,
      sourceSections: [],
      sourceTemplates: [],
      destinationOffices: [],
      destinationSections: [],
      selectedSourceCompany: undefined,
      selectedSourceSections: [],
      selectedSourceTemplates: [],
      selectedDestinationCompany: undefined,
      selectedDestinationOffices: [],
      selectedDestinationSection: [],
      selectedDestinationStates: [],
    };
  }

  componentDidMount() {
    const {
      setCrumbs,
      location: { pathname },
    } = this.props;

    const crumb = { title: 'Copy Templates', link: pathname };
    setCrumbs([crumb]);

    const loggedIn = !!Parse.User.current();
    if (loggedIn) {
      this.props.startFetchCompanies();
    }
  }

  UNSAFE_componentWillReceiveProps({ companies }) {
    if (companies) {
      const objects = companies.map((company) => ({
        value: company.id,
        label: company.get('name'),
      }));
      this.setState({ companies: objects });
    }
  }

  componentWillUnmount() {
    const { setCrumbs } = this.props;
    setCrumbs([]);
  }

  onSourceCompanyChange = async (e = {}) => {
    this.setState({
      selectedSourceCompany: e.value,
      selectedSourceSections: [],
      selectedSourceTemplates: [],
    });
    const params = { companyObjectId: e.value };
    const results = await Parse.Cloud.run('getTemplateOptions', params);
    const sourceSections = results.map(({ objectId, title }) => ({
      value: objectId,
      label: title,
    }));
    this.setState({ sourceSections });
  };

  onSourceSectionChanged = async (value) => {
    this.setState(() => ({ selectedSourceSections: value }));
    if (value) {
      const params = {
        companyObjectId: this.state.selectedSourceCompany,
        types: value,
      };
      const sourceTemplates = await Parse.Cloud.run(
        'listContractObjects',
        params,
      );
      this.setState({ sourceTemplates });
    }
  };

  onTemplateSelected = async (id) => {
    const selectedSourceTemplates = this.state.selectedSourceTemplates.filter(
      (objectId) => objectId !== id,
    );
    if (this.state.selectedSourceTemplates.indexOf(id) === -1) {
      selectedSourceTemplates.push(id);
    }
    this.setState({ selectedSourceTemplates });
  };

  onDestinationCompanyChange = async (e = {}) => {
    this.setState({
      selectedDestinationCompany: e.value,
      selectedDestinationOffices: [],
      selectedDestinationSection: undefined,
      selectedDestinationStates: [],
    });
    const result = await Parse.Cloud.run('listOfficesForCompany', {
      companyId: e.value,
    });
    this.setState({
      destinationOffices: result.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    });
  };

  onDestinationOfficesChange = async (officeIds) => {
    this.setState({ selectedDestinationOffices: officeIds });
    const params = {
      companyObjectId: this.state.selectedDestinationCompany,
      officeIds,
    };
    const results = await Parse.Cloud.run('getTemplateOptions', params);
    const destinationSections = results.map(({ objectId, title }) => ({
      value: objectId,
      label: title,
    }));
    this.setState({ destinationSections });
  };

  onDestinationSectionChanged = (value) => {
    this.setState({ selectedDestinationSection: value });
  };

  onDestinationStatesChanged = (value) => {
    this.setState({ selectedDestinationStates: value });
  };

  onSelectAll = (e) => {
    if (e.target.checked) {
      const selectedSourceTemplates = this.state.sourceTemplates.map(
        ({ objectId }) => objectId,
      );
      this.setState({ selectedSourceTemplates });
    } else {
      this.setState({ selectedSourceTemplates: [] });
    }
  };

  onSubmit = async () => {
    try {
      const {
        selectedSourceCompany,
        selectedSourceSections,
        selectedSourceTemplates,
        selectedDestinationCompany,
        selectedDestinationSection,
        selectedDestinationOffices,
        selectedDestinationStates,
      } = this.state;
      const params = {
        sourceCompanyId: selectedSourceCompany,
        sourceTypes: selectedSourceSections,
        templateObjectIds: selectedSourceTemplates,
        destinationCompanyId: selectedDestinationCompany,
        destinationType: selectedDestinationSection.value,
        destinationOfficeIds: selectedDestinationOffices,
        destinationStates: selectedDestinationStates,
      };
      const result = await Parse.Cloud.run('copyTemplates', params);
      AppToaster.show({ message: result, timeout: 3000 });
    } catch (e) {
      AppToaster.show({ message: e.message, timeout: 5000 });
    }
  };

  render() {
    return (
      <div className="default-page-padding">
        <Panel title="Copy Templates">
          <>
            <DropDownGroup
              title="Source Company"
              value={this.state.selectedSourceCompany}
              placeholder="Select company to copy templates FROM"
              onChange={this.onSourceCompanyChange}
              options={this.state.companies}
              isDisabled={!this.state.companies}
              isClearable={false}
              backspaceRemovesValue={false}
              closeMenuOnSelect
            />
            {this.state.sourceSections.length > 0 && (
              <MultiSelectGroup
                title="Source Sections"
                selected={this.state.selectedSourceSections}
                placeholder="Select source Sections"
                onSelectedChanged={this.onSourceSectionChanged}
                options={this.state.sourceSections}
                disabled={!this.state.sourceSections}
                disableSearch
              />
            )}
            {this.state.sourceTemplates.length > 0 && (
              <FormGroup title="Templates">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <td width="25px">
                        <input type="checkbox" onChange={this.onSelectAll} />
                      </td>
                      <td width="50px">Thumbnail</td>
                      <td>Name</td>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.sourceTemplates.map(
                      ({ objectId, thumbnail, name }) => (
                        <tr
                          key={objectId}
                          onClick={() => this.onTemplateSelected(objectId)}
                        >
                          <td>
                            <input
                              type="checkbox"
                              checked={
                                this.state.selectedSourceTemplates.indexOf(
                                  objectId,
                                ) > -1
                              }
                              onChange={() => this.onTemplateSelected(objectId)}
                            />
                          </td>
                          <td>
                            <img
                              className="packages-thumbnail-img"
                              src={thumbnail}
                              alt="Thumbnail"
                            />
                          </td>
                          <td>{name}</td>
                        </tr>
                      ),
                    )}
                  </tbody>
                </Table>
              </FormGroup>
            )}
            <DropDownGroup
              title="Destination Company"
              value={this.state.selectedDestinationCompany}
              placeholder="Select company to copy templates TO"
              onChange={this.onDestinationCompanyChange}
              options={this.state.companies}
              isDisabled={!this.state.companies}
              isClearable={false}
              backspaceRemovesValue={false}
              closeMenuOnSelect
            />
            {this.state.selectedDestinationCompany && (
              <MultiSelectGroup
                title="Destination Offices"
                selected={this.state.selectedDestinationOffices}
                valueRenderer={officeValueRenderer}
                onSelectedChanged={this.onDestinationOfficesChange}
                options={this.state.destinationOffices}
                disableSearch
              />
            )}
            {this.state.selectedDestinationOffices.length > 0 && (
              <DropDownGroup
                title="Destination Section"
                value={this.state.selectedDestinationSection}
                placeholder="Select section to copy templates to"
                onChange={this.onDestinationSectionChanged}
                options={this.state.destinationSections}
                isDisabled={!this.state.destinationSections}
                isClearable={false}
                backspaceRemovesValue={false}
                closeMenuOnSelect
              />
            )}
            {this.state.selectedDestinationOffices.length > 0 && (
              <FormGroup title="Destination States">
                <StateSelector
                  selected={this.state.selectedDestinationStates}
                  onChange={this.onDestinationStatesChanged}
                />
              </FormGroup>
            )}
            <ButtonGroup
              title=""
              buttonTitle="Submit"
              onClick={this.onSubmit}
              variant="success"
            />
          </>
        </Panel>
      </div>
    );
  }
}

CopyTemplatesPage.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.instanceOf(Parse.Object)),
  startFetchCompanies: PropTypes.func.isRequired,
  ...layoutContextPropTypes,
};

CopyTemplatesPage.defaultProps = {
  companies: [],
};

const mapStateToProps = ({ config: { companies } }) => ({
  companies,
});

const mapDispatchToProps = (dispatch) => ({
  startFetchCompanies: () => dispatch(startFetchCompanies()),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(CopyTemplatesPage),
);
