/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

const ProSellSuccess = ({ email }) => (
  <div className="new-account__signup-body">
    <p className="new-account__sign-up-now">SUCCESS!</p>
    {`Your account has been created. We've sent an email to ${email} containing your login credentials`}
  </div>
);

ProSellSuccess.propTypes = {
  emails: PropTypes.string.isRequired,
};

export default ProSellSuccess;
