import { randomKey } from '../../../utils/utils';
import SignatureAdditionalLine from './SignatureAdditionalLine';
import BodyCellItem from './BodyCellItem';

const toolbarKeyMaps = {
  size: 'contractNoteFontSize',
  bold: 'contractNoteBold',
  underline: 'contractNoteUnderline',
  name: 'contractNoteFontName',
  color: 'contractNoteFontColor',
};

const containerKey = 'signature_item';
const pasteKey = 'signature_item';

class SignatureItem {
  constructor(item, parent) {
    this.JSON = item;
    this.keys = [
      'objectId',
      'appNote',
      'contractNote',
      'contractNoteFontSize',
      'contractNoteBold',
      'contractNoteUnderline',
      'contractNoteFontName',
      'contractNoteFontColor',
      'footerNote',
      'underline',
      'column',
      'additionalLines',
    ];
    this.containerKey = containerKey;
    this.pasteKey = pasteKey;
    this.parent = parent;
    this.toolbarKey = 'sectionTitle';
    this.toolbarKeyMaps = toolbarKeyMaps;
    this.toolboxKey = 'signature-title';
    this.toolbarKeys = ['size', 'bold', 'underline', 'name', 'color'];
    if (item) {
      Object.assign(this, item);
      this.objectId = this.objectId || randomKey(10);
      this.additionalLines = item.additionalLines.map(
        (line) => new SignatureAdditionalLine(line, this),
      );
    } else {
      this.appNote = '';
      this.contractNote = '%customerName%';
      this.contractNoteFontSize = 8;
      this.contractNoteBold = true;
      this.contractNoteUnderline = false;
      this.contractNoteFontName = '';
      this.contractNoteFontColor = '0-0-0-1';
      this.footerNote = '%customerName%';
      this.underline = true;
      this.column = 1;
      this.additionalLines = [new SignatureAdditionalLine(undefined, this)];
    }
    if (!this.objectId) {
      this.objectId = randomKey(10);
    }
    const source = this.getSourceObject();
    if (source) {
      if (source.hasObjectId(this.objectId)) {
        this.objectId = randomKey(10);
      }
      source.addObjectId(this.objectId);
    }
  }

  get identifier() {
    return `${this.parent.identifier}-${this.objectId}`;
  }

  getPath() {
    if (!this.parent) {
      return undefined;
    }
    if (this.parent.containerKey === BodyCellItem.ContainerKey) {
      const index = this.parent.signatures.findIndex(
        ({ objectId }) => objectId === this.objectId,
      );
      return [...this.parent.getPath(), index];
    }
    const index = this.parent.data.findIndex(
      ({ objectId }) => objectId === this.objectId,
    );
    return [...this.parent.getPath(), index];
  }

  getSourceObject() {
    if (!this.parent) {
      return undefined;
    }
    return this.parent.getSourceObject();
  }

  clone() {
    const jsonObject = this.toJSON();
    const json = JSON.parse(JSON.stringify(jsonObject));
    json.objectId = randomKey(10);
    const item = new SignatureItem(json, this.parent);
    item.additionalLines = item.additionalLines.map((line) => line.clone());
    return item;
  }

  toJSON() {
    const json = {};
    this.keys.forEach((key) => {
      json[key] = this[key];
    });
    json.additionalLines = this.additionalLines.map((obj) => obj.toJSON());
    return json;
  }

  static ContainerKey = containerKey;

  static PasteKey = pasteKey;
}

export default SignatureItem;
