import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Menu, MenuItem } from '@blueprintjs/core';

const AddMenu = ({
  onNewItem,
  onNewOption,
  onPasteNewOptions,
  onPasteCopyOptions,
  onNewUpCharge,
  onPasteNewUpCharges,
  onPasteCopyUpCharges,
  maxUpChargeCount,
  options,
  upCharges,
}) => {
  // eslint-disable-next-line arrow-parens
  const optionIds = Object.keys(options).filter((id) => options[id].isSelected);
  const upChargeIds = Object.keys(upCharges).filter(
    (id) => upCharges[id].isSelected,
  );
  const optionCountLabel = optionIds.length ? ` (${optionIds.length})` : '';
  const upChargeCountLabel = upChargeIds.length
    ? ` (${upChargeIds.length})`
    : '';
  return (
    <Menu>
      <Menu.Divider title="Measure Sheet Items" />
      <MenuItem text="New" onClick={onNewItem} />
      <Menu.Divider title="Price Guide Options" />
      <MenuItem text="New" onClick={onNewOption} />
      <MenuItem
        text={`Paste New${optionCountLabel}`}
        onClick={onPasteNewOptions}
        disabled={!optionIds.length}
      />
      <MenuItem
        text={`Paste Copy${optionCountLabel}`}
        onClick={onPasteCopyOptions}
        disabled={!optionIds.length}
      />
      {maxUpChargeCount !== 0 && (
        <>
          <Menu.Divider title="Up-Charges" />
          <MenuItem text="New" onClick={onNewUpCharge} />
          <MenuItem
            text={`Paste New${upChargeCountLabel}`}
            onClick={onPasteNewUpCharges}
            disabled={!upChargeIds.length}
          />
          <MenuItem
            text={`Paste Copy${upChargeCountLabel}`}
            onClick={onPasteCopyUpCharges}
            disabled={!upChargeIds.length}
          />
        </>
      )}
    </Menu>
  );
};

AddMenu.propTypes = {
  maxUpChargeCount: PropTypes.number.isRequired,
  onNewItem: PropTypes.func,
  onNewOption: PropTypes.func,
  onPasteNewOptions: PropTypes.func,
  onPasteCopyOptions: PropTypes.func,
  onNewUpCharge: PropTypes.func,
  onPasteNewUpCharges: PropTypes.func,
  onPasteCopyUpCharges: PropTypes.func,
  options: PropTypes.shape({}),
  upCharges: PropTypes.shape({}),
};

AddMenu.defaultProps = {
  onNewItem: undefined,
  onNewOption: undefined,
  onPasteNewOptions: undefined,
  onPasteCopyOptions: undefined,
  onNewUpCharge: undefined,
  onPasteNewUpCharges: undefined,
  onPasteCopyUpCharges: undefined,
  options: {},
  upCharges: {},
};

const mapStateToProps = ({ priceGuide2, plan = {} }) => ({
  options: priceGuide2.options,
  upCharges: priceGuide2.upCharges,
  maxUpChargeCount: plan.maxUpChargeCount,
});

export default connect(mapStateToProps)(AddMenu);
