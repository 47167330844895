import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { historyPropType, locationPropType } from '../../router';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import Panel from '../Panel';
import {
  getPartnerCreditApplications as getPartnerCreditApplicationsAction,
  copyPartnerCreditApplication as copyPartnerCreditApplicationAction,
} from '../../actions/creditApplications';

class NewFromPartnerList extends React.Component {
  crumb = {
    title: 'Partner Credit Applications',
    // eslint-disable-next-line react/destructuring-assignment
    link: this.props.location.pathname,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { getPartnerCreditApplications, crumbs, setCrumbs } = this.props;

    getPartnerCreditApplications();

    crumbs[1] = this.crumb;
    setCrumbs(crumbs);
  }

  componentWillUnmount() {
    const { crumbs, setCrumbs, setButtons } = this.props;
    setCrumbs(crumbs.slice(0, 1));
    setButtons();
  }

  onAddFromScratch = () => {
    const { history } = this.props;
    history.push('/credit_applications/new');
  };

  onAddFromPartner = () => {
    const { history } = this.props;
    history.push('/credit_applications/new/from_partner');
  };

  render() {
    const { items, copyPartnerCreditApplication } = this.props;

    return (
      <div className="default-page-padding">
        <h3>
          <Link
            style={{ margin: 10 }}
            to="/credit_applications"
            id="credit_applications"
          >
            Back to Credit Applications
          </Link>
        </h3>
        <Panel title="Choose a partner credit application to copy">
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            {items.map((partnerCreditApplication) => (
              <div
                className="mask no-caption resources-cell card"
                key={partnerCreditApplication.id}
                style={{ margin: 10 }}
              >
                <div className="thumbnail-container">
                  <div className="img-thumbnail view view-first">
                    <img
                      style={{
                        margin: 'auto',
                        height: '100%',
                        display: 'block',
                      }}
                      src={partnerCreditApplication.get('image').url()}
                      alt="Thumbnail"
                    />
                    <div className="mask no-caption">
                      <div className="tools tools-bottom">
                        <button
                          type="button"
                          onClick={() => {
                            copyPartnerCreditApplication(
                              partnerCreditApplication,
                            );
                          }}
                        >
                          <i className="far fa-copy" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="caption">
                    <p>{partnerCreditApplication.get('title')}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Panel>
      </div>
    );
  }
}

NewFromPartnerList.propTypes = {
  history: historyPropType.isRequired,
  location: locationPropType.isRequired,
  copyPartnerCreditApplication: PropTypes.func.isRequired,
  getPartnerCreditApplications: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  ...layoutContextPropTypes,
};

NewFromPartnerList.defaultProps = {
  items: [],
};

const mapStateToProps = ({
  creditApplications: {
    partners: { items },
  },
}) => ({
  items,
});

const mapDispatchToProps = (dispatch) => ({
  getPartnerCreditApplications: () =>
    dispatch(getPartnerCreditApplicationsAction()),
  copyPartnerCreditApplication: (partnerApplictionData) =>
    dispatch(copyPartnerCreditApplicationAction(partnerApplictionData)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(NewFromPartnerList),
);
