/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable func-names */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import LeapStrechColumnTableCell from './LeapStrechColumnTableCell';
import { getPartnerCreditApplications } from '../../actions/creditApplications';

const ColumnSortIcon = (props) => {
  const { sort } = props;
  switch (sort) {
    case 'ascending': {
      return <i className="fas fa-sort-alpha-up" />;
    }
    case 'descending': {
      return <i className="fas fa-sort-alpha-down" />;
    }
    default: {
      return <i className="fas fa-sort" />;
    }
  }
};

const LeapStrechColumnWidthTable = ({
  onCellChange,
  onDelete,
  onSort,
  tableColumns,
  tableData,
  tableErrors,
}) => {
  const dispatch = useDispatch();
  const [columnSort, setColumnSort] = useState({});
  const [columns, setColumns] = useState({});

  useEffect(() => {
    dispatch(getPartnerCreditApplications());
  }, []);

  const onSortColumn = (col, i) => {
    const sortStateKey = `${col.name}_${i}`;
    let sortOption = columnSort[sortStateKey];

    if (!sortOption) {
      sortOption = 'ascending';
    } else if (sortOption === 'ascending') {
      sortOption = 'descending';
    } else if (sortOption === 'descending') {
      sortOption = null;
    }

    setColumnSort({
      ...columnSort,
      [sortStateKey]: sortOption,
    });

    onSort({
      sortOption,
      sortKey: col.key,
    });
  };

  const setColumnWidth = () => {
    const updatedColumns = tableColumns.map((col, colIndex) => {
      const colEl = document.getElementById(`${col.name}-${colIndex}`);
      const rect = colEl.getBoundingClientRect();
      if (rect) {
        return {
          ...col,
          width: rect.width - 2,
        };
      }
      return col;
    });

    setColumns(updatedColumns);
  };

  useEffect(function() {
    setColumnWidth();
  }, []);

  const getColumns = () => columns;

  const setCellColumns = () => {
    setColumnWidth();
  };

  return (
    <div
      onMouseUp={setCellColumns}
      style={{
        position: 'relative',
        overflowY: 'hidden',
        overflowX: 'scroll',
        width: '100%',
        flex: 1,
      }}
      className="leap-table-container"
    >
      <div
        style={{
          position: 'absolute',
          overflowX: 'hidden',
          display: 'flex',
          flexDirection: 'Column',
          height: '100%',
        }}
      >
        <div
          style={{
            overflowX: 'hidden',
            display: 'flex',
          }}
        >
          {tableColumns.map((col, i) => (
            <div
              id={`${col.name}-${i}`}
              style={{
                position: 'relative',
              }}
              key={col.name}
            >
              <div
                style={{
                  resize: col.resizable ? 'horizontal' : 'none',
                  overflow: 'hidden',
                  width: col.width || 200,
                  margin: 0,
                  alignItems: 'center',
                  borderWidth: 1,
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingTop: 5,
                  height: 35,
                  paddingBottom: 5,
                  fontWeight: 'bold',
                  borderStyle: 'solid',
                  borderColor: 'lightgrey',
                  backgroundColor: '#f9f9f9',
                }}
              >
                <span style={{ margin: 5 }}>{col.name}</span>
                {col.sortable && (
                  <button
                    style={{
                      marginRight: 15,
                      backgroundColor: 'transparent',
                      borderWidth: 0,
                    }}
                    type="button"
                    onMouseDown={() => onSortColumn(col, i)}
                  >
                    <ColumnSortIcon
                      {...{ sort: columnSort[`${col.name}_${i}`] }}
                    />
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
        <div
          style={{
            flex: '1',
            overflowX: 'hidden',
            overflowY: 'scroll',
          }}
          className="leap-table-body"
        >
          {tableData.map((row) => (
            <div
              key={row.id}
              style={{
                display: 'flex',
                border: tableErrors[row.id || row._localId]
                  ? '1px solid red'
                  : '',
              }}
            >
              {tableColumns.map((col, i) => (
                <LeapStrechColumnTableCell
                  key={col.name}
                  {...{
                    getColumns,
                    cellData: row.get(col.key),
                    colIndex: i,
                    col,
                    rowData: row,
                    onChange: onCellChange,
                    onDelete,
                  }}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

LeapStrechColumnWidthTable.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  onCellChange: PropTypes.func.isRequired,
  tableColumns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ),
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      isActive: PropTypes.bool,
      bankName: PropTypes.string,
      category: PropTypes.string,
      name: PropTypes.string,
      note: PropTypes.string,
      planCode: PropTypes.string,
      minAmount: PropTypes.number,
      maxAmount: PropTypes.number,
      interestRate: PropTypes.number,
      term: PropTypes.number,
      paymentFactor: PropTypes.number,
      feePercent: PropTypes.number,
      includedOffices: PropTypes.arrayOf(
        PropTypes.shape({
          objectId: PropTypes.string,
        }),
      ),
    }),
  ),
  // eslint-disable-next-line react/forbid-prop-types
  tableErrors: PropTypes.object,
};

LeapStrechColumnWidthTable.defaultProps = {
  tableColumns: [],
  tableData: [],
  tableErrors: {},
};

export default LeapStrechColumnWidthTable;
