/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import Parse from 'parse';
import ProSellTemplate from './ProSellTemplate';
import TextInput from './TextInput';
import AppToaster from '../../utils/AppToaster';

const successSignupMessage =
  'Success! Please check your email with instructions to complete your account setup.';
const partnerId = 'fJ4DGcUOHQ';
const redirectURL = 'https://prosell.leaptodigital.com/signup/prosell/activate';

class ProSellSignup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyName: '',
      firstName: '',
      lastName: '',
      occId: '',
      email: '',
      didSignup: false,
      submitButtonTitle: 'SIGN UP',
    };
  }

  onSubmit = async (e) => {
    e.preventDefault();
    try {
      const { companyName, firstName, lastName, occId, email } = this.state;
      const params = {
        companyName,
        firstName,
        lastName,
        occId,
        email,
        partnerId,
        redirectURL,
        isDemo: false,
      };
      this.setState({ submitButtonTitle: 'LOADING...' });
      const result = await Parse.Cloud.run('createProSellAccountToken', params);
      if (result.status !== 200) {
        throw new Parse.Error(
          result.status,
          'Error creating account. Please contact 1-844-370-5327 for help',
        );
      }
      this.setState({ didSignup: true });
    } catch (err) {
      AppToaster.show({ message: err.message, timeout: 10000 });
    }
  };

  render() {
    if (this.state.didSignup) {
      return (
        <ProSellTemplate>
          <div className="new-account__signup-body">{successSignupMessage}</div>
        </ProSellTemplate>
      );
    }
    const { companyName, firstName, lastName, occId, email } = this.state;
    const isValid = companyName && firstName && lastName && email && occId;
    return (
      <ProSellTemplate>
        <div className="new-account__signup-body">
          <p className="new-account__sign-up-now">SIGN UP NOW:</p>
          <div style={{ margin: '0px 30px ' }}>
            <TextInput
              title="Company Name"
              value={companyName}
              onChange={(e) => this.setState({ companyName: e.target.value })}
            />
            <TextInput
              title="First Name"
              value={firstName}
              onChange={(e) => this.setState({ firstName: e.target.value })}
            />
            <TextInput
              title="Last Name"
              value={lastName}
              onChange={(e) => this.setState({ lastName: e.target.value })}
            />
            <TextInput
              title="Email"
              value={email}
              onChange={(e) => this.setState({ email: e.target.value })}
            />
            <TextInput
              title="OCCN Member ID #"
              value={occId}
              onChange={(e) => this.setState({ occId: e.target.value })}
            />
            <button
              type="button"
              className="new-account__prosell-submit-prosell"
              onClick={this.onSubmit}
              disabled={!isValid}
            >
              {this.state.submitButtonTitle}
            </button>
          </div>
        </div>
      </ProSellTemplate>
    );
  }
}

ProSellSignup.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default ProSellSignup;
