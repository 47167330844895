/* eslint-disable no-nested-ternary */
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import MenuItem from './MenuItem';
import ParentMenuItem from './ParentMenuItem';
import useMenuItems from './useMenuItems';
import { createStyleElement } from '../../../utils/utils';

import './index.scss';

// Redux store selector to get partner styles for main menu
const getMenuStyles = ({ config: { styles: { MainMenu } = {} } = {} }) =>
  MainMenu;

const MainMenu = ({ isOpen = false, toggleOpen = () => {} }) => {
  const menuItems = useMenuItems();
  const styles = useSelector(getMenuStyles);

  const buttonStyle =
    styles && styles.mainMenu ? styles.mainMenu.item.button : {};

  return (
    <aside className="main-menu" style={styles && styles.mainMenu}>
      <ul className="main-menu__main-list">
        {/* open/close button */}
        <li className="main-menu__item">
          <button
            type="button"
            onClick={toggleOpen}
            className="button text-left pl-0"
            css={css`
              ${createStyleElement(buttonStyle)}
            `}
          >
            <span
              className={`left-icon text-center ${
                isOpen ? 'fa fa-angle-double-left' : 'fa fa-angle-double-right'
              }`}
            />
            <span>{toggleOpen ? 'COLLAPSE' : 'EXPAND'}</span>
          </button>
        </li>
        {menuItems.map(({ title, icon, anchor, subLinks }) =>
          anchor ? (
            <MenuItem
              key={anchor}
              title={title}
              anchor={anchor}
              icon={icon}
              style={styles && styles.mainMenu.item}
            />
          ) : subLinks ? (
            <ParentMenuItem
              key={title}
              title={title}
              icon={icon}
              subLinks={subLinks}
              style={styles && styles.mainMenu.item}
            />
          ) : (
            ''
          ),
        )}
      </ul>
    </aside>
  );
};

MainMenu.propTypes = {
  isOpen: PropTypes.bool,
  toggleOpen: PropTypes.func,
};

MainMenu.defaultProps = {
  isOpen: false,
  toggleOpen: () => {},
};

export default MainMenu;
