/* eslint-disable jsx-a11y/no-static-element-interactions */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';
import { Popover, Position } from 'leap-menu-item';

class InitialsCell extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  render() {
    const {
      note,
      style,
      showPopover,
      onChange,
      onDoubleClick,
      onMouseDown,
    } = this.props;
    return (
      <div
        css={{
          height: '34px',
          padding: '3px 5px',
        }}
      >
        <Popover
          content={
            <textarea
              onContextMenu={(e) => e.stopPropagation()}
              fill
              ref={this.inputRef}
              defaultValue={note}
              className="ignore-react-onclickoutside"
            />
          }
          isOpen={showPopover}
          position={Position.RIGHT_TOP}
          onClose={() => {
            if (note !== this.inputRef.current.value) {
              onChange(this.inputRef.current.value);
            }
          }}
        >
          <div
            css={{
              ...style,
              position: 'absolute',
              display: 'flex',
              right: '0px',
              bottom: '0px',
            }}
            onMouseDown={onMouseDown}
            onDoubleClick={onDoubleClick}
            className="templates-pdf__text"
          >
            {note}
            <div
              css={{
                borderBottom: '1px solid black',
                width: '54.6875px',
              }}
            />
          </div>
        </Popover>
      </div>
    );
  }
}

InitialsCell.propTypes = {
  note: PropTypes.string,
  style: PropTypes.shape({}),
  showPopover: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
  onMouseDown: PropTypes.func.isRequired,
};

InitialsCell.defaultProps = {
  note: '',
  style: undefined,
  showPopover: false,
};

export default InitialsCell;
