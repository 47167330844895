import Parse from 'parse';
import { handleError } from '../auth';

export const updateAnalyticsPageDates = ({ startDate, endDate }) => ({
  type: 'UPDATE_ANALYTICS_PAGE_DATES',
  startDate,
  endDate,
});

export const setChartList = (charts) => ({
  type: 'SET_CHART_LIST',
  charts,
});

export const getCharts = () => async (dispatch) => {
  try {
    const query = new Parse.Query('Charts');
    const parseCharts = await query.find();
    const jsonCharts = parseCharts.map((pchart) => pchart.toJSON());
    dispatch(setChartList(jsonCharts));
  } catch (e) {
    dispatch(setChartList([{ status: '400' }]));
    dispatch(handleError(e));
  }
};
