export default [
  { value: 'default', label: 'Keyboard' },
  { value: 'picker', label: 'Picker' },
  { value: 'keypad', label: 'Numbers 1' },
  { value: 'keypadDecimal', label: 'Numbers 1.00' },
  { value: 'phone', label: 'Phone' },
  { value: 'currency', label: 'Currency $1.00' },
  { value: 'currencyWhole', label: 'Currency $1' },
  { value: 'email', label: 'Email' },
  { value: 'numbers', label: 'Number Keyboard' },
  { value: 'datePicker', label: 'Date Picker' },
  { value: 'dateTimePicker', label: 'Date & Time' },
  { value: 'timePicker', label: 'Time' },
  { value: 'formula', label: 'Formula (1.00)' },
  { value: 'formulaWhole', label: 'Formula (1)' },
  { value: 'formulaCurrency', label: 'Formula Currency ($1.00)' },
  { value: 'formulaCurrencyWhole', label: 'Formula Currency ($1)' },
  { value: 'creditCard', label: 'Credit Card' },
  { value: 'ccExpDate', label: 'Credit Card Exp. Date' },
  { value: 'dynamic', label: 'Dynamic' },
  { value: 'financeOptionsPicker', label: 'Finance Options Picker' },
  { value: 'ssn', label: 'Social Security Number' },
  { value: 'statePicker', label: 'State Picker' },
  { value: 'zipCode', label: 'Zip Code' },
];
