import { useState, useEffect } from 'react';

export default () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const resizeHandler = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    window.addEventListener('resize', resizeHandler, { passive: true });

    return () => {
      window.removeEventListener('resize', resizeHandler, { passive: true });
    };
  });

  return [width, height];
};
