/** @jsx jsx */
import { jsx } from '@emotion/core';
// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import BodyCellItemObject from '../../Models/BodyCellItem';
import BodyRow from '../../BodyRow';

const EmptySpace = ({ cellItem }) => (
  <BodyRow cellItem={cellItem}>
    <div
      css={{
        minHeight: `${cellItem.emptySpaceHeight || 0}px`,
        maxHeight: `${cellItem.emptySpaceHeight || 0}px`,
      }}
    />
  </BodyRow>
);

EmptySpace.propTypes = {
  cellItem: PropTypes.instanceOf(BodyCellItemObject).isRequired,
};

export default EmptySpace;
