/* eslint-disable react/no-deprecated */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MultiSelect from '../Misc/MultiSelect';
import Select from '../Misc/Select';
import { startDownloadSubCategories } from '../../actions/priceGuide2';

const valueRenderer = (value, options) => {
  if (value.length > 3 && value.length === options.length) {
    return `All ${value.length} SubCategories selected`;
  }
  switch (value.length) {
    case 0:
      return 'None Selected';
    case 1:
    case 2:
    case 3:
      return value
        .map((id) => options.find(({ value: officeId }) => id === officeId))
        .filter((obj) => obj)
        .map(({ label }) => label)
        .join(', ');
    default:
      return `${value.length} SubCategories selected`;
  }
};
class SubCategoriesDropDown extends React.Component {
  componentDidMount = () => {
    const { selectedCategories } = this.props;
    this.props.startDownloadSubCategories(selectedCategories);
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      this.props.selectedCategories &&
      newProps.selectedCategories &&
      this.props.selectedCategories.toString() !==
        newProps.selectedCategories.toString()
    ) {
      this.props.startDownloadSubCategories(newProps.selectedCategories);
    }
  }

  render() {
    const { value, onChange, subCategories, isMulti, ...rest } = this.props;
    const options = subCategories.map((subCat) => ({
      value: subCat,
      label: subCat,
    }));
    let values;
    if (Array.isArray(value)) {
      values = value.map((v) => options.find((obj) => obj.value === v));
    } else {
      values = options.find((obj) => obj.value === value);
    }
    if (isMulti) {
      return (
        <MultiSelect
          options={options}
          onSelectedChanged={onChange}
          selected={value || []}
          selectAllLabel="Select All"
          valueRenderer={valueRenderer}
          hasSelectAll={options.length > 1}
          disableSearch={options.length <= 1}
          {...rest}
        />
      );
    }
    return (
      <Select
        isLoading={!this.props.subCategories}
        placeholder={!this.props.subCategories ? 'Loading...' : 'None Selected'}
        value={values}
        options={options}
        clearable={false}
        closeMenuOnSelect={this.props.closeMenuOnSelect}
        onChange={onChange}
        {...rest}
      />
    );
  }
}

SubCategoriesDropDown.propTypes = {
  closeMenuOnSelect: PropTypes.bool,
  startDownloadSubCategories: PropTypes.func.isRequired,
  selectedCategories: PropTypes.arrayOf(PropTypes.string),
  isMulti: PropTypes.bool,
  subCategories: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

SubCategoriesDropDown.defaultProps = {
  closeMenuOnSelect: true,
  selectedCategories: null,
  isMulti: false,
  value: '',
  subCategories: [],
};

const mapStateToProps = (
  { priceGuide2: { subCategories, selectedCategories } },
  ownProps,
) => ({
  subCategories,
  selectedCategories: ownProps.selectedCategories || selectedCategories,
});

const mapDispatchToProps = (dispatch) => ({
  startDownloadSubCategories: (categories) =>
    dispatch(startDownloadSubCategories(categories)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubCategoriesDropDown);
