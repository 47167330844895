import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, DropdownButton, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import IncludedOfficesDropDown from '../IncludedOfficesDropDown';
import FormGroup from '../FormGroup';
import DragGrid from '../Resources/DragGrid';
import { historyPropType } from '../../router';
import Panel from '../Panel';
import {
  getCreditApplications as getCreditApplicationsAction,
  updateCreditApplicationOrder as updateCreditApplicationOrderAction,
  deleteCreditApplication as deleteCreditApplicationAction,
  startAddDefaultApplication as startAddDefaultApplicationAction,
} from '../../actions/creditApplications';
import { showDeleteConfirmation as showDeleteConfirmationAction } from '../../actions/deleteConfirmation';
import { pushToDataLayer as pushToDataLayerAction } from '../../actions/tagManager';

class CreditApplicationsDashBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      selectedOffices: [props.selectedOffice.id],
    };
  }

  componentDidMount() {
    const { selectedOffice, getCreditApplications, setButtons } = this.props;
    selectedOffice.id && getCreditApplications([selectedOffice.id]);
    setButtons(this.buttons());
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      items: newProps.items.sort((a, b) => a.orderNumber - b.orderNumber),
    });
  }

  componentDidUpdate(prevProps) {
    const { customCreditApplicationsAllowed, setButtons } = this.props;
    if (
      customCreditApplicationsAllowed !==
      prevProps.customCreditApplicationsAllowed
    ) {
      setButtons(this.buttons);
    }
  }

  componentWillUnmount() {
    const { setButtons } = this.props;
    setButtons();
  }

  onAddFromDefault = () => {
    const { pushToDataLayer, startAddDefaultApplication } = this.props;
    const { selectedOffices } = this.state;
    pushToDataLayer({
      event: 'creditApplicationEvent',
      eventCategory: 'Credit Applications',
      eventAction: 'Create',
    });
    startAddDefaultApplication(selectedOffices);
  };

  onAddFromPartner = () => {
    const { pushToDataLayer, history } = this.props;
    pushToDataLayer({
      event: 'creditApplicationEvent',
      eventCategory: 'Credit Applications',
      eventAction: 'Create',
    });
    history.push('/credit_applications/new/from_partner');
  };

  onSaveOrder = () => {
    const { items: propsItems, updateCreditApplicationOrder } = this.props;
    const { items } = this.state;
    const updates = items.filter((stateItem) => {
      // eslint-disable-next-line max-len
      const [match] = propsItems.filter(
        (propItem) => propItem.objectId === stateItem.objectId,
      );
      return match.orderNumber !== stateItem.orderNumber;
    });
    updateCreditApplicationOrder({
      updates,
      creditApplications: items,
    });
  };

  onSelectDeleteCreditApplication = (creditApplication) => {
    const { showDeleteConfirmation, deleteCreditApplication } = this.props;
    showDeleteConfirmation({
      message: `Are you sure you want to delete ${creditApplication.title}`,
      title: 'Delete Credit Application ?',
      onConfirm: () => deleteCreditApplication(creditApplication.objectId),
    });
  };

  updateOfficesFilter = (selectedOffices) => {
    const { getCreditApplications } = this.props;
    this.setState({ selectedOffices }, () =>
      getCreditApplications(selectedOffices),
    );
  };

  buttons = () => {
    const { customCreditApplicationsAllowed } = this.props;
    return (
      <>
        <DropdownButton variant="success" title="New" className="mr-1">
          {customCreditApplicationsAllowed && (
            <Dropdown.Item onSelect={this.onAddFromDefault} eventKey="2">
              From Scratch
            </Dropdown.Item>
          )}
          <Dropdown.Item onSelect={this.onAddFromPartner} eventKey="3">
            From Partner
          </Dropdown.Item>
        </DropdownButton>
        <Button variant="success" onClick={this.onSaveOrder}>
          Save Order
        </Button>
      </>
    );
  };

  render() {
    const { maxOfficeCount } = this.props;
    const { selectedOffices, items } = this.state;

    return (
      <div className="default-page-padding">
        {maxOfficeCount !== 1 && (
          <FormGroup title="Offices">
            <IncludedOfficesDropDown
              onChange={(selectedOffices_) =>
                this.updateOfficesFilter(selectedOffices_)
              }
              selected={selectedOffices}
              closeOnSelect
            />
          </FormGroup>
        )}
        <Panel title="Credit Applications">
          <div>
            <DragGrid
              pagenationSkip={() => 0}
              onDragableId={({ item }) => `drag-grid-item-${item.objectId}`}
              onCell={({ item }) => {
                const { partnerCreditApplication } = item;
                const imageFile = partnerCreditApplication
                  ? partnerCreditApplication.image
                  : item.image;
                const thumbnail = imageFile
                  ? imageFile.url
                  : '/images/no_image.png';
                return (
                  <div
                    key={item.objectId}
                    style={{
                      margin: 10,
                      cursor: 'pointer',
                    }}
                  >
                    <div className="mask no-caption resources-cell card">
                      <div className="thumbnail-container">
                        <div className="img-thumbnail view view-first">
                          <img
                            style={{
                              margin: 'auto',
                              height: '100%',
                              display: 'block',
                            }}
                            src={thumbnail}
                            alt="Thumbnail"
                          />
                          <div
                            style={{ cursor: 'pointer' }}
                            className="mask no-caption"
                          >
                            <div className="tools tools-bottom">
                              <Link
                                to={`/credit_applications/edit/${item.objectId}`}
                                id="editButton"
                              >
                                <i className="fas fa-pencil-alt fill-cell" />
                              </Link>
                              <button
                                type="button"
                                onClick={() =>
                                  this.onSelectDeleteCreditApplication(item)
                                }
                              >
                                <i className="far fa-trash-alt" />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="caption">
                          <p>{item.title}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }}
              onUpdatedItemOrder={(items_) => this.setState({ items: items_ })}
              items={() => items}
            />
          </div>
        </Panel>
      </div>
    );
  }
}

CreditApplicationsDashBoard.propTypes = {
  customCreditApplicationsAllowed: PropTypes.bool.isRequired,
  deleteCreditApplication: PropTypes.func.isRequired,
  getCreditApplications: PropTypes.func.isRequired,
  history: historyPropType.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  pushToDataLayer: PropTypes.func.isRequired,
  selectedOffice: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  showDeleteConfirmation: PropTypes.func.isRequired,
  startAddDefaultApplication: PropTypes.func,
  updateCreditApplicationOrder: PropTypes.func.isRequired,
  ...layoutContextPropTypes,
};

CreditApplicationsDashBoard.defaultProps = {
  items: [],
  startAddDefaultApplication: () => {},
};

const mapStateToProps = ({
  creditApplications: { items = [] } = {},
  auth: { selectedOffice = {} } = {},
  plan: { customCreditApplicationsAllowed = false, maxOfficeCount = 0 } = {},
}) => ({
  items,
  selectedOffice,
  customCreditApplicationsAllowed,
  maxOfficeCount,
});

const mapDispatchToProps = (dispatch) => ({
  getCreditApplications: (includedOffices) =>
    dispatch(getCreditApplicationsAction(includedOffices)),
  // eslint-disable-next-line max-len
  updateCreditApplicationOrder: (creditApplications) =>
    dispatch(updateCreditApplicationOrderAction(creditApplications)),
  deleteCreditApplication: (id) => dispatch(deleteCreditApplicationAction(id)),
  // eslint-disable-next-line max-len
  showDeleteConfirmation: ({ message, title, onConfirm }) =>
    dispatch(showDeleteConfirmationAction({ message, title, onConfirm })),
  // eslint-disable-next-line max-len
  startAddDefaultApplication: (includedOffices) =>
    dispatch(startAddDefaultApplicationAction(includedOffices)),
  pushToDataLayer: (variablesForLayer) =>
    dispatch(pushToDataLayerAction(variablesForLayer)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(CreditApplicationsDashBoard),
);
