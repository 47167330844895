import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import getLogo from '../../../selectors/logo';

import './index.scss';

const Logo = ({ isOpen = true }) => {
  const logo = useSelector(getLogo);

  return (
    <Link
      className={`ld-company-logo ${!isOpen ? 'closed' : ''} p-4`}
      to="/index"
    >
      <img src={logo} alt="company logo" />
    </Link>
  );
};

Logo.propTypes = {
  isOpen: PropTypes.bool,
};

Logo.defaultProps = {
  isOpen: true,
};

export default Logo;
