const configureFive9 = () => {
  const fields = {
    siteName: {
      show: false,
      label: 'Site Name',
      value: 'webApp',
    },
  };
  window.Five9SocialWidget.addWidget({
    type: 'chat',
    fields,
    rootUrl: 'https://app.five9.com/consoles/',
    tenant: 'Leap',
    title: 'Leap',
    profiles: 'Support Chat',
    showProfiles: true,
    enableCallback: true,
    callbaclList: [],
    theme: 'default-theme.css',
    useBusinessHours: true,
  });
};
export default configureFive9;
