/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import FormGroup, { TextGroup, SwitchGroup, PasswordGroup } from '../FormGroup';
import { startUpdateConfig } from '../../actions/auth';
import { decryptString } from '../../utils/utils';
import Panel from '../Panel';
import TitleButton from '../TitleButton';
import Copy from '../AppSettings/Copy';
import { showDeleteConfirmation } from '../../actions/deleteConfirmation';
import { pushToDataLayer } from '../../actions/tagManager';

export class Salesforce extends React.Component {
  constructor(props) {
    super(props);
    const { salesforce = {}, salesforceUseSandbox } = props;
    const { enabled = false, credentials = {} } = salesforce;
    const { username = '' } = credentials;
    this.state = {
      enabled,
      username,
      password: '', // set password to empty string initially because it's encrypted
      securityToken: '', // set securityToken to empty string initially because it's encrypted
      salesforceUseSandbox: !!salesforceUseSandbox,
    };
  }

  componentDidMount() {
    const {
      setCrumbs,
      setButtons,
      location: { pathname },
    } = this.props;

    this.decryptProps(this.props);

    const crumb = { title: 'Salesforce', link: pathname };
    setCrumbs([crumb]);
    setButtons(
      <TitleButtons
        onClearClick={this.askForClearPermission}
        onCopyClicked={this.onCopyClicked}
        onSaveClicked={this.onSaveClicked}
      />,
    );
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { salesforce = {}, salesforceUseSandbox } = newProps;
    const { enabled = false, credentials = {} } = salesforce;
    const { username = '' } = credentials;
    this.setState({
      enabled,
      username,
      password: '',
      securityToken: '',
      salesforceUseSandbox,
    });
    this.decryptProps(newProps);
  }

  componentWillUnmount() {
    const { setCrumbs, setButtons } = this.props;
    setCrumbs([]);
    setButtons();
  }

  onEnabledChanged = (checked) => {
    this.setState({ enabled: checked });
    if (!checked) {
      this.setState({
        enabled: false,
        username: '',
        password: '',
        securityToken: '',
      });
      this.props.pushToDataLayer({
        event: 'integrationEvent',
        eventCategory: 'Integration',
        eventAction: 'Disable',
        eventLabel: 'Salesforce',
      });
    } else {
      this.props.pushToDataLayer({
        event: 'integrationEvent',
        eventCategory: 'Integration',
        eventAction: 'Enable',
        eventLabel: 'Salesforce',
      });
    }
  };

  askForClearPermission = () => {
    this.props.showDeleteConfirmation({
      message: `Are you sure you want to clear this integration ?`,
      title: 'Clear Integration ?',
      onConfirm: () => {
        this.onClearClick();
      },
    });
  };

  onClearClick = () => {
    this.onEnabledChanged(false);
  };

  onUsernameChanged = (value) => {
    this.setState({ username: value });
  };

  onPasswordChanged = (value) => {
    this.setState({ password: value });
  };

  onSecurityTokenChanged = (value) => {
    this.setState({ securityToken: value });
  };

  onUseSandboxChanged = (value) => {
    this.setState({ salesforceUseSandbox: !!value });
  };

  onCopyClicked = () => {
    this.setState({ showCopy: true });
  };

  onSaveClicked = () => {
    const {
      enabled,
      username,
      password,
      securityToken,
      salesforceUseSandbox,
    } = this.state;
    const updates = {
      enabled,
      credentials: {
        username,
        password,
        encrypted: false,
        securityToken,
      },
    };
    this.props.startUpdateConfig({
      salesforce: updates,
      salesforceUseSandbox,
    });
  };

  decryptProps = async (props) => {
    const { salesforce = {} } = props;
    const { credentials = {} } = salesforce;
    const {
      password: encryptedPassword,
      securityToken: encryptedSecurityToken,
    } = credentials;
    const newState = {};
    if (encryptedPassword) {
      const { password } = await decryptString(encryptedPassword);
      newState.password = password;
    }
    if (encryptedSecurityToken) {
      const { password } = await decryptString(credentials.securityToken);
      newState.securityToken = password;
    }
    this.setState({ ...newState });
  };

  render() {
    return (
      <>
        <Copy
          title="Copy Salesforce Settings"
          show={this.state.showCopy}
          warning="Warning! This will overwrite your current Salesforce settings"
          configKeys={['salesforce']}
          onClose={() => this.setState({ showCopy: false })}
        />
        <div className="default-page-padding">
          <Panel title="Salesforce">
            <div>
              <SwitchGroup
                title="Single User Enabled"
                checked={this.state.enabled}
                onChange={this.onEnabledChanged}
              />
              {this.state.enabled && (
                <>
                  <TextGroup
                    title="Username"
                    value={this.state.username}
                    placeholder="Username"
                    onChange={this.onUsernameChanged}
                  />
                  <PasswordGroup
                    title="Password"
                    value={this.state.password}
                    placeholder="Password"
                    onChange={this.onPasswordChanged}
                  />
                  <TextGroup
                    title="Security Token"
                    value={this.state.securityToken}
                    placeholder="Security Token"
                    onChange={this.onSecurityTokenChanged}
                  />
                </>
              )}
              <FormGroup>
                <span>
                  Single User Enabled will use the entered credentials for all
                  Salesforce connectivity.
                </span>
              </FormGroup>
              <SwitchGroup
                title="Use Sandbox Environment"
                checked={this.state.salesforceUseSandbox}
                onChange={this.onUseSandboxChanged}
              />
              <FormGroup>
                <span>
                  Sandbox Environment can be enabled even if Single User is not
                  enabled.
                </span>
              </FormGroup>
            </div>
          </Panel>
        </div>
      </>
    );
  }
}

Salesforce.propTypes = {
  startUpdateConfig: PropTypes.func.isRequired,
  salesforce: PropTypes.shape({
    enabled: PropTypes.bool,
    credentials: PropTypes.shape({
      username: PropTypes.string,
      passwors: PropTypes.string,
      encrypted: PropTypes.bool,
      securityToken: PropTypes.string,
    }),
  }),
  salesforceUseSandbox: PropTypes.bool,
  ...layoutContextPropTypes,
};

Salesforce.defaultProps = {
  salesforce: {
    enabled: false,
    credentials: {
      username: '',
      password: '',
      encrypted: false,
      securityToken: '',
    },
  },
  salesforceUseSandbox: false,
};

const TitleButtons = ({ onCopyClicked, onSaveClicked, onClearClick }) => (
  <>
    <TitleButton
      variant="warning"
      onClick={() => onClearClick()}
      title="Clear"
    />
    <TitleButton
      variant="primary"
      onClick={() => onCopyClicked({ source: 'copy' })}
      title="Copy"
    />
    <TitleButton
      variant="success"
      onClick={() => onSaveClicked({ source: 'save' })}
      title="Save"
    />
  </>
);

TitleButtons.propTypes = {
  onCopyClicked: PropTypes.func.isRequired,
  onSaveClicked: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth: { config } }) => ({
  salesforce: config.salesforce,
  salesforceUseSandbox: config.salesforceUseSandbox,
});

const mapDispatchToProps = (dispatch) => ({
  startUpdateConfig: (updates) => dispatch(startUpdateConfig(updates)),
  pushToDataLayer: (variablesForLayer) =>
    dispatch(pushToDataLayer(variablesForLayer)),
  showDeleteConfirmation: (params) => dispatch(showDeleteConfirmation(params)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(Salesforce),
);
