import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ButtonGroup, Button } from 'leap-menu-item';
import { setViewSelection } from '../../../actions/templateEdit';
import ContractObject from '../Models/ContractObject';

const ViewSelectionButton = ({ viewSelection, setView, template }) => (
  <ButtonGroup>
    <Button
      active={viewSelection === 0}
      onClick={() => setView(0, 'App')}
      disabled={!template || template.pageId === 'pdfPage'}
    >
      App
    </Button>
    <Button
      active={viewSelection === 1}
      onClick={() => setView(1, 'PDF')}
      disabled={!template}
    >
      PDF
    </Button>
    <Button
      active={viewSelection === 2}
      onClick={() => setView(2, 'Both')}
      disabled={!template || template.pageId === 'pdfPage'}
    >
      Both
    </Button>
  </ButtonGroup>
);

ViewSelectionButton.propTypes = {
  viewSelection: PropTypes.oneOf([0, 1, 2]).isRequired,
  setView: PropTypes.func.isRequired,
  template: PropTypes.instanceOf(ContractObject),
};

ViewSelectionButton.defaultProps = {
  template: undefined,
};

const mapStateToProps = ({ templatesEdit }) => ({
  template: templatesEdit[templatesEdit.currentWorkingId],
  viewSelection: templatesEdit.viewSelection,
});

const mapDispatchToProps = (dispatch) => ({
  setView: (selection, name) => dispatch(setViewSelection(selection, name)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ViewSelectionButton);
