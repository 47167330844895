/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Menu, MenuItem, TextArea } from 'leap-menu-item';
import { setInitialStateForTemplate } from '../../../../actions/templateEdit';
import YesNoMenuItem from '../YesNoMenuItem';
import ContractObject from '../../Models/ContractObject';
import DynamicInputEditor from './DynamicInputEditor';
import DebounceInputWithNet from '../DebounceInputWithNet';

const PhotoSelectorMenu = ({ object, contractObject, setTemplateState }) => (
  <>
    <YesNoMenuItem
      text="Dynamic"
      active={object.inputType === 'dynamic'}
      onChange={(value) => {
        object.inputType = value ? 'dynamic' : '';
        setTemplateState(contractObject);
      }}
    />
    {object.inputType === 'dynamic' && (
      <DynamicInputEditor
        contractObject={contractObject}
        cellItem={object}
        onChange={(value) => {
          object.dynamicInputType = value;
          setTemplateState(contractObject);
        }}
      />
    )}
    <Menu.Divider />
    <MenuItem text="Default Tag">
      <DebounceInputWithNet
        element={TextArea}
        minLength={0}
        placeholder="Image: "
        debounceTimeout={300}
        value={object.photoTag}
        onChange={(e) => {
          object.photoTag = e.target.value;
          setTemplateState(contractObject);
        }}
      />
    </MenuItem>
    <Menu.Divider />
    {object.cellType === 'switched' && <Menu.Divider />}
    <YesNoMenuItem
      text="Required"
      active={object.required}
      onChange={(value) => {
        object.required = value;
        setTemplateState(contractObject);
      }}
    />
    <YesNoMenuItem
      text="Link Report Photos"
      active={object.allowReportPhotos}
      onChange={(value) => {
        object.allowReportPhotos = value;
        setTemplateState(contractObject);
      }}
    />
    <Menu.Divider />
    <MenuItem text="App Title">
      <DebounceInputWithNet
        element={TextArea}
        minLength={0}
        debounceTimeout={300}
        value={object.appTitle}
        onChange={(e) => {
          object.appTitle = e.target.value;
          setTemplateState(contractObject);
        }}
      />
    </MenuItem>
  </>
);

PhotoSelectorMenu.propTypes = {
  object: PropTypes.any.isRequired,
  contractObject: PropTypes.instanceOf(ContractObject).isRequired,
  setTemplateState: PropTypes.func.isRequired,
};

const mapStateToProps = ({ templatesEdit = {}, auth }) => ({
  templateValues: templatesEdit[templatesEdit.currentWorkingId],
  editCellItem: templatesEdit.editCellItem,
  secureCaptureEnabled: auth.company.get('spreedlyEnabled'),
});

const mapDispatchToProps = (dispatch) => ({
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhotoSelectorMenu);
