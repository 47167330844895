// TODO: migrate price guide from localStorage to Dexie (indexedDB)
const hiddenColumnsString = localStorage.getItem('price_guide_hidden_columns');
let activeColumns;
if (hiddenColumnsString) {
  activeColumns = JSON.parse(hiddenColumnsString);
} else {
  activeColumns = {
    settings: false,
    additionalDetails: false,
    placeholders: false,
    showToggle: false,
    image: false,
    category: false,
    subCategory: false,
    subSubCategories: true,
    name: false,
    note: false,
    measurementType: false,
    offices: false,
    formulaId: true,
    formula: true,
    optionPlaceholders: false,
    optionBrand: false,
    optionName: false,
    optionPrices: false,
    optionSelect: false,
    upChargeSettings: false,
    upChargeAdditionalDetails: false,
    upChargePlaceholders: false,
    upChargeImage: false,
    upChargeName: false,
    upChargeNote: false,
    upChargeMeasurement: false,
    upChargeShowToggle: false,
    upChargePercentagePrice: false,
    upChargeDisabled: false,
    upChargeIdentifier: false,
    upChargePrices: false,
    upChargeSelect: false,
  };
}
export const defaultState = {
  items: [],
  itemsJSON: [],
  options: [],
  optionsJSON: [],
  upCharges: [],
  upChargesJSON: [],
  activeColumns,
  categories: [],
};

const sortItems = (items) =>
  items.sort((a, b) => {
    const categoryA = a.get('category');
    const categoryB = b.get('category');
    if (categoryA === categoryB) {
      const orderA = a.get('orderNumber_');
      const orderB = b.get('orderNumber_');
      return orderB - orderA;
    }
    return categoryA - categoryB;
  });

const categoriesFromItems = (items) => {
  const categories = [];
  items.forEach(({ category }) => {
    if (category && categories.indexOf(category) === -1) {
      categories.push(category);
    }
  });
  return categories.sort();
};

export default (state = defaultState, { type, ...rest }) => {
  switch (type) {
    case 'SET_ITEMS': {
      const sortedItems = sortItems(rest.items);
      const itemsJSON = sortedItems.map((object) => object.toJSON());
      return {
        ...state,
        items: rest.items,
        itemsJSON,
        categories: categoriesFromItems(itemsJSON),
      };
    }
    case 'ADD_ITEMS': {
      const allItems = [...state.items, ...rest.items];
      const sortedItems = sortItems(allItems);
      const itemsJSON = sortedItems.map((object) => object.toJSON());
      return {
        ...state,
        items: sortedItems,
        itemsJSON,
        categories: categoriesFromItems(itemsJSON),
      };
    }
    case 'RESET_PRICE_GUIDE':
      return { ...defaultState };
    case 'PRICE_GUIDE_SET_FILTERED_CATEGORIES':
      return {
        ...state,
        filteredCategories: rest.categories,
      };
    case 'PRICE_GUIDE_SET_INCLUDED_OFFICES':
      return {
        ...state,
        filteredOffices: rest.offices,
      };
    case 'PRICE_GUIDE_ADD_OPTIONS':
      return {
        ...state,
        options: [...state.options, ...rest.options],
        optionsJSON: [...state.optionsJSON, ...rest.optionsJSON],
      };
    case 'PRICE_GUIDE_SET_OPTIONS':
      return {
        ...state,
        options: rest.options,
        optionsJSON: rest.optionsJSON,
      };
    case 'PRICE_GUIDE_SET_UP_CHARGES':
      return {
        ...state,
        upCharges: rest.upCharges,
        upChargesJSON: rest.upChargesJSON,
      };
    case 'PRICE_GUIDE_REPLACE_MEASURE_SHEET_ITEM':
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.id === rest.item.id) {
            return rest.item;
          }
          return item;
        }),
        itemsJSON: state.itemsJSON.map((item) => {
          if (item.objectId === rest.item.id) {
            return rest.item.toJSON();
          }
          return item;
        }),
      };
    case 'PRICE_GUIDE_DELETE_MEASURE_SHEET_ITEM':
      return {
        ...state,
        items: state.items.filter(({ id }) => id !== rest.objectId),
        itemsJSON: state.itemsJSON.filter(
          ({ objectId }) => objectId !== rest.objectId,
        ),
      };
    case 'PRICE_GUIDE_DELETE_PRICE_GUIDE_OPTION':
      return {
        ...state,
        options: state.options.filter(({ id }) => id !== rest.objectId),
        optionsJSON: state.optionsJSON.filter(
          ({ objectId }) => objectId !== rest.objectId,
        ),
      };
    case 'PRICE_GUIDE_ADD_UP_CHARGES':
      return {
        ...state,
        upCharges: [...state.upCharges, ...rest.upCharges],
        upChargesJSON: [...state.upChargesJSON, ...rest.upChargesJSON],
      };
    case 'PRICE_GUIDE_DELETE_UP_CHARGE':
      return {
        ...state,
        upCharges: state.upCharges.filter(({ id }) => id !== rest.objectId),
        upChargesJSON: state.upChargesJSON.filter(
          ({ objectId }) => objectId !== rest.objectId,
        ),
      };
    case 'PRICE_GUIDE_DELETE_UP_CHARGES':
      return {
        ...state,
        upCharges: state.upCharges.filter(
          ({ id }) => rest.upChargeIds.indexOf(id) === -1,
        ),
        upChargesJSON: state.upChargesJSON.filter(
          ({ objectId }) => rest.upChargeIds.indexOf(objectId) === -1,
        ),
      };
    case 'PRICE_GUIDE_REPLACE_UP_CHARGE':
      return {
        ...state,
        upCharges: state.upCharges.map((upCharge) => {
          if (upCharge.id === rest.upCharge.id) {
            return rest.upCharge;
          }
          return upCharge;
        }),
        upChargesJSON: state.upChargesJSON.map((upCharge) => {
          if (upCharge.objectId === rest.upCharge.id) {
            return rest.upCharge.toJSON();
          }
          return upCharge;
        }),
      };
    case 'PRICE_GUIDE_SET_ACTIVE_COLUMNS':
      return {
        ...state,
        activeColumns: rest.activeColumns,
      };
    case 'PRICE_GUIDE_SET_CATEGORIES':
      return {
        ...state,
        categories: rest.categories,
      };
    default:
      return state;
  }
};
