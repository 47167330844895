/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from 'leap-menu-item';
import { DebounceInput } from 'react-debounce-input';
import ContractObject from '../../../Models/ContractObject';
import SizeInput from './SizeInput';

const SizeInputWidget = (props) => {
  const { object, setTemplateState, contractObject } = props;
  return (
    <>
      {object.inputType === 'unitedInchPicker' && (
        <MenuItem
          text="United Inch Suffix"
          shouldDismissPopover={false}
          label={object.unitedInchSuffix}
        >
          <DebounceInput
            minLength={0}
            debounceTimeout={300}
            value={object.unitedInchSuffix}
            onChange={(e) => {
              object.unitedInchSuffix = e.target.value;
              setTemplateState(contractObject);
            }}
          />
        </MenuItem>
      )}
      <MenuItem
        text="Min Width"
        shouldDismissPopover={false}
        label={object.minSizePickerWidth}
      >
        <SizeInput
          sizeSpec={object}
          name="minSizePickerWidth"
          minLength={0}
          debounceTimeout={300}
          value={object.minSizePickerWidth}
          onChange={(e) => {
            object.minSizePickerWidth = parseFloat(e.target.value);
            setTemplateState(contractObject);
          }}
        />
      </MenuItem>
      <MenuItem
        text="Max Width"
        shouldDismissPopover={false}
        label={object.maxSizePickerWidth}
      >
        <SizeInput
          sizeSpec={object}
          name="maxSizePickerWidth"
          minLength={0}
          debounceTimeout={300}
          value={object.maxSizePickerWidth}
          onChange={(e) => {
            object.maxSizePickerWidth = parseFloat(e.target.value);
            setTemplateState(contractObject);
          }}
        />
      </MenuItem>
      <MenuItem
        text="Min Height"
        shouldDismissPopover={false}
        label={object.minSizePickerHeight}
      >
        <SizeInput
          sizeSpec={object}
          name="minSizePickerHeight"
          minLength={0}
          debounceTimeout={300}
          value={object.minSizePickerHeight}
          onChange={(e) => {
            object.minSizePickerHeight = parseFloat(e.target.value);
            setTemplateState(contractObject);
          }}
        />
      </MenuItem>
      <MenuItem
        text="Max Height"
        shouldDismissPopover={false}
        label={object.maxSizePickerHeight}
      >
        <SizeInput
          sizeSpec={object}
          name="maxSizePickerHeight"
          minLength={0}
          debounceTimeout={300}
          value={object.maxSizePickerHeight}
          onChange={(e) => {
            object.maxSizePickerHeight = parseFloat(e.target.value);
            setTemplateState(contractObject);
          }}
        />
      </MenuItem>

      {object.inputType === '3DSizePicker' && (
        <>
          <MenuItem
            text="Min Depth"
            shouldDismissPopover={false}
            label={object.minSizePickerDepth}
          >
            <SizeInput
              sizeSpec={object}
              name="minSizePickerDepth"
              minLength={0}
              debounceTimeout={300}
              value={object.minSizePickerDepth}
              onChange={(e) => {
                object.minSizePickerDepth = parseFloat(e.target.value);
                setTemplateState(contractObject);
              }}
            />
          </MenuItem>
          <MenuItem
            text="Max Depth"
            shouldDismissPopover={false}
            label={object.maxSizePickerDepth}
          >
            <SizeInput
              sizeSpec={object}
              name="maxSizePickerDepth"
              minLength={0}
              debounceTimeout={300}
              value={object.maxSizePickerDepth}
              onChange={(e) => {
                object.maxSizePickerDepth = parseFloat(e.target.value);
                setTemplateState(contractObject);
              }}
            />
          </MenuItem>
        </>
      )}
    </>
  );
};

SizeInputWidget.propTypes = {
  object: PropTypes.any.isRequired,
  contractObject: PropTypes.instanceOf(ContractObject).isRequired,
  setTemplateState: PropTypes.func.isRequired,
};

export default SizeInputWidget;
