// @ts-check
import Parse from 'parse';

/**
 * @typedef {{
 *  upChargeCount: number,
 *  billingDay: number,
 *  config: import('../CustomConfig').default,
 *  appStyles: string,
 *  apiKey: string,
 *  hoverEnabled: boolean,
 *  spreedlyEnabled: boolean,
 *  analytics: boolean,
 *  docuSignEnabled: boolean,
 *  restrictions: {},
 *  paymentMethod: import('../PaymentMethod').default,
 *  partner: import('../Partner').default,
 *  billingDate: Date,
 *  plan: import('../Plan').default,
 *  edits: import('../types').Edit[],
 *  ACL: Parse.ACL,
 *  name: string,
 *  contactName: string,
 *  isDemo: boolean,
 *  partnerParams: {},
 *  updatedAt: Date,
 *  paymentHistory: import('../PaymentTransaction').default[],
 *  companyCamEnabled: boolean,
 *  url: string,
 *  isActive: boolean,
 *  featureFlags: {},
 *  logsEnabled: boolean,
 *  stripeEnabled: boolean,
 *  activeSections: {[section: string]: boolean},
 *  creditAppOptions: string[],
 *  offices: import('../Office').default[],
 *  eagleViewEnabled: boolean,
 *  billingAddress: string,
 *  productName: 'Leap' | 'Prosell',
 *  optionCount: number,
 *  measureSheetItemCount: number,
 *  costPerUser: number,
 *  isLaunched: boolean,
 *  contactEmail: string,
 *  statesKey: 'statesUS',
 *  apiAccess: boolean,
 *  updatePaymentMethod: boolean,
 *  maxActiveUsers: number,
 * }} CompanyAttributes
 */

/**
 * @extends Parse.Object<CompanyAttributes>
 */
class Company extends Parse.Object {
  /**
   * @param {CompanyAttributes} attributes
   */
  constructor(attributes) {
    super('Company', attributes);
  }

  static async getDefaultPlan() {
    const result = await Parse.Cloud.run('getDefaultPlan');
    return result;
  }

  async getPlan() {
    await this.fetch();
    const plan = this.get('plan');
    if (plan) {
      return plan.fetch();
    }
    const defaultPlan = await Company.getDefaultPlan();
    // this.set('plan', defaultPlan);
    return defaultPlan;
  }

  async getValidPaymentRequestOffices() {
    try {
      const officesQuery = new Parse.Query('Office');
      officesQuery.select('config.spreedly');
      officesQuery.equalTo('company', this.toPointer());
      officesQuery.include('config');
      const companyOffices = await officesQuery.find();

      const validOffices = companyOffices.filter((office) => {
        const config = office.get('config');
        const spreedly = config.get('spreedly') || {};
        const { paymentRequest, gatewayToken, gatewayType } = spreedly || {};
        const hasPaymentCapture =
          (gatewayToken && gatewayType) || gatewayType === 'test';
        if (!paymentRequest || !hasPaymentCapture) {
          return false;
        }
        const {
          defaultFromEmail,
          requestEmailSubject,
          defaultEmailTemplate,
        } = paymentRequest;
        if (
          !defaultFromEmail ||
          !requestEmailSubject ||
          !defaultEmailTemplate
        ) {
          return false;
        }
        return true;
      });

      return validOffices;
    } catch (error) {
      return [];
    }
  }

  async getValidPaymentCaptureOffices() {
    try {
      const officesQuery = new Parse.Query('Office');
      officesQuery.select('config.spreedly');
      officesQuery.equalTo('company', this.toPointer());
      officesQuery.include('config');
      const companyOffices = await officesQuery.find();

      const validOffices = companyOffices.filter((office) => {
        const config = office.get('config');

        const spreedly = config.get('spreedly') || {};

        const { gatewayToken, gatewayType } = spreedly || {};

        if (!gatewayToken || !gatewayType) {
          return false;
        }

        return true;
      });

      return validOffices;
    } catch (error) {
      return [];
    }
  }

  async getCompanyOffices() {
    try {
      const officesQuery = new Parse.Query('Office');
      officesQuery.equalTo('company', this.toPointer());
      const companyOffices = await officesQuery.find();
      return companyOffices;
    } catch (error) {
      return [];
    }
  }
}

export default Company;
