import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const LockAnimation = (props) => {
  const { isOpen } = props;

  const lockColor = isOpen ? '#26D07C' : '#ff101f';

  const lockClass = isOpen ? 'open' : 'closed';

  return (
    <div
      className={`d-flex align-items-center flex-column lock-animation ${lockClass}`}
    >
      <svg
        className="lock-top"
        xmlns="http://www.w3.org/2000/svg"
        width="49.5"
        height="38.128"
        viewBox="0 0 49.5 38.128"
      >
        <path
          id="unlock-alt-regular"
          d="M22,38.128V27.113c0-9.859,7.236-18.057,16.328-18.15C47.506,8.851,55,16.936,55,26.889V31.37a4.139,4.139,0,1,0,8.25,0V27.15c0-14.864-11-27.113-24.7-27.15-13.664-.037-24.8,12.044-24.8,26.889V38.128Z"
          transform="translate(-13.75 0)"
          fill={lockColor}
        />
      </svg>
      <svg
        className="lock-bottom"
        xmlns="http://www.w3.org/2000/svg"
        width="77"
        height="55.017"
        viewBox="0 0 77 55.017"
      >
        <path
          id="lock-alt-regular"
          d="M38.5,70.812A4.8,4.8,0,0,1,33.687,66V55a4.813,4.813,0,0,1,9.625,0V66A4.8,4.8,0,0,1,38.5,70.812ZM77,41.25v38.5A8.252,8.252,0,0,1,68.75,88H8.25A8.252,8.252,0,0,1,0,79.75V41.25A8.252,8.252,0,0,1,8.25,33c59.938,0,.511-.039,60.5,0A8.252,8.252,0,0,1,77,41.25Zm-8.25,0H8.25v38.5h60.5Z"
          transform="translate(0 -32.982)"
          fill={lockColor}
        />
      </svg>
    </div>
  );
};

LockAnimation.propTypes = {
  isOpen: PropTypes.bool,
};
LockAnimation.defaultProps = {
  isOpen: false,
};

export default LockAnimation;
