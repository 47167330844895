import React, { useState } from 'react';
import Select from 'react-dropdown-select';
import PropTypes from 'prop-types';

function DropDownSelect(props) {
  const { options, selected } = props;

  const [optionSet, setOptionSet] = useState(options);

  return (
    <Select
      {...{
        ...props,
      }}
      create
      multi
      onChange={(values) => {
        props.onSelection(values);
      }}
      values={selected}
      noDataRenderer={() => <span />}
      inputRenderer={({ methods, state, inputRef }) => (
        <input
          ref={inputRef}
          className="dropdown-select-input form-control input-lg p-0"
          value={state.search}
          onBlur={(event) => {
            if (event.target.value) {
              methods.addItem({
                label: event.target.value,
                value: event.target.value,
              });
            }
          }}
          onKeyUp={(event) => {
            if (event.keyCode === 13 && event.target.value) {
              methods.addItem({
                label: event.target.value,
                value: event.target.value,
              });
            }
          }}
          onChange={(event) => {
            methods.setSearch(event);
          }}
        />
      )}
      onCreateNew={(item) => setOptionSet([...optionSet, item])}
    />
  );
}

DropDownSelect.propTypes = {
  errorMessage: PropTypes.string,
  onSelection: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

DropDownSelect.defaultProps = {
  errorMessage: undefined,
};

export default DropDownSelect;
