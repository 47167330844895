import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { TextGroup } from '../../FormGroup';
import ContractObject from '../Models/ContractObject';

const SaveAsModal = ({
  show,
  onClose,
  object = {},
  onNameChange,
  onSubmit,
}) => {
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Save As</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TextGroup
          title="Name"
          value={object.displayName}
          onChange={onNameChange}
          autofocus
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onSubmit} variant="success">
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

SaveAsModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  object: PropTypes.instanceOf(ContractObject),
  onNameChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

SaveAsModal.defaultProps = {
  show: false,
  object: new ContractObject({ displayName: '' }),
};

export default SaveAsModal;
