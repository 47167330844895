import React, { useState } from 'react';
import moment from 'moment';
import Parse from 'parse';
import PropTypes from 'prop-types';
import moneyFormatter from 'money-formatter';
import { FieldDisplayList } from '../SharedComponents/FieldDisplayList';
import { FieldTitle } from '../SharedComponents/FieldTitle';

export const getStatusStyle = (status) => {
  switch (status) {
    case 'Pending':
      return 'warning';
    case 'Approved':
      return 'success';
    case 'Status Error':
    case 'Declined':
      return 'danger';
    default:
      return 'warning';
  }
};

const ActionButton = (props) => {
  const { applicationStatus, redirectURL, getApplicationStatus } = props;
  switch (applicationStatus) {
    case 'Pending': {
      return (
        <button
          type="button"
          onClick={() => getApplicationStatus()}
          className="field-display-list-value cursor-pointer btn btn-primary btn-sm"
        >
          Refresh Status
        </button>
      );
    }
    case 'Approved': {
      return redirectURL ? (
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={redirectURL}
          className="field-display-list-value cursor-pointer btn btn-success btn-sm"
        >
          Complete Application
        </a>
      ) : (
        <></>
      );
    }
    default: {
      return <></>;
    }
  }
};

const getCreditAppData = (creditApplication) => {
  const isCustomerCreditApp = creditApplication.has('isFilledOut');

  if (isCustomerCreditApp) {
    const office = creditApplication.get('office');
    const config = office.get('config');
    const customer = creditApplication.get('customer');
    const customerFirst = customer.get('nameFirst');
    const customerLast = customer.get('nameLast');
    const createdAt = creditApplication.get('createdAt');
    return {
      isCustomerCreditApp,
      sourceLogo: config.get('companyLogo') || '/images/leap_logo.png',
      customerAccount: `${customerFirst} ${customerLast}`,
      createdAt,
    };
  }

  const sourceLogo = creditApplication.get('sourceLogo');
  const status = creditApplication.get('status');
  const appId = creditApplication.get('appId');
  const createdAt = creditApplication.get('createdAt');
  const requestedAmount = creditApplication.get('requestedAmount') || 0;
  const approvedAmount = creditApplication.get('approvedAmount') || 0;
  const redirectURL = creditApplication.get('redirectURL') || 0;
  const note = creditApplication.get('note');
  const source = creditApplication.get('source');
  return {
    sourceLogo,
    status,
    appId,
    createdAt,
    requestedAmount,
    approvedAmount,
    redirectURL,
    note,
    source,
  };
};

export const CreditAppComponent = (props) => {
  const { creditApplication, updateCreditApplication, onViewCompleted } = props;

  const {
    sourceLogo,
    status,
    appId,
    createdAt,
    requestedAmount,
    approvedAmount,
    redirectURL,
    note,
    source,
    customerAccount,
    isCustomerCreditApp,
  } = getCreditAppData(creditApplication);

  const [applicationStatus, setApplicationStatus] = useState(status);
  const getApplicationStatus = async () => {
    try {
      await Parse.Cloud.run('checkStatusOfApplication', {
        appObjectId: creditApplication.id,
      });
      const updatedCreditApplication = await creditApplication.fetch();
      updateCreditApplication(updatedCreditApplication);
      setApplicationStatus(updatedCreditApplication.get('status'));
    } catch (error) {
      setApplicationStatus('Status Check Error');
    }
  };

  return (
    <>
      <div className="field-section my-1">
        <FieldTitle title={source} />
        <div className="field-section-row my-2 row">
          <div className="col-xl-3 col-md-4 col-sm-6">
            {!!sourceLogo && (
              <img
                className="credit-app-source-img"
                alt="sourceLogo"
                src={sourceLogo._url}
              />
            )}
            <dt className="list-line-action-item"> Application ID: </dt>
            <dd className="field-display-list-value">{appId}</dd>
            {isCustomerCreditApp && (
              <button
                onClick={() => {
                  onViewCompleted({
                    customerAccount,
                    id: creditApplication.id,
                    customerId: creditApplication.get('customer').id,
                  });
                }}
                type="button"
                className="btn btn-success"
              >
                View App
                <svg
                  className="mx-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 77 88.035"
                >
                  <g
                    id="Group_1"
                    data-name="Group 1"
                    transform="translate(-341 -201)"
                  >
                    <path
                      id="lock-alt-regular"
                      d="M38.5,70.812A4.8,4.8,0,0,1,33.687,66V55a4.813,4.813,0,1,1,9.625,0V66A4.8,4.8,0,0,1,38.5,70.812ZM77,41.25v38.5A8.252,8.252,0,0,1,68.752,88H8.25A8.252,8.252,0,0,1,0,79.752V41.25A8.252,8.252,0,0,1,8.248,33c59.94,0,.513-.039,60.5,0A8.252,8.252,0,0,1,77,41.248Zm-8.25,0H8.25v38.5h60.5Z"
                      transform="translate(341 201.035)"
                      fill="black"
                    />
                    <path
                      id="unlock-alt-regular"
                      d="M22,38.128V27.113c0-9.859,7.236-18.057,16.328-18.15C47.506,8.851,55,16.936,55,26.889V31.37a4.139,4.139,0,1,0,8.25,0V27.15c0-14.864-11-27.113-24.7-27.15-13.664-.037-24.8,12.044-24.8,26.889V38.128Z"
                      transform="translate(340.993 201)"
                      fill="black"
                    />
                  </g>
                </svg>
              </button>
            )}
          </div>
          <div className="col-xl-9 col-md-8 col-sm-12">
            <div className="row">
              <div className="col-xl-3 col-md-4 col-sm-6">
                <dt className="list-line-action-item"> Status: </dt>
                <dd
                  className={`field-display-list-value text-${getStatusStyle(
                    applicationStatus,
                  )}`}
                >
                  {applicationStatus}
                </dd>
                <ActionButton
                  redirectURL={redirectURL}
                  getApplicationStatus={getApplicationStatus}
                  applicationStatus={applicationStatus}
                />
                <dt className="list-line-action-item"> Date </dt>
                <dd className="field-display-list-value">
                  {moment(createdAt).format('MM/DD/YYYY h:mma')}
                </dd>
              </div>
              <FieldDisplayList
                {...{
                  colClass: 'col-xl-3 col-md-4 col-sm-6',
                  list: [
                    {
                      label: 'Requested Amount:',
                      value: requestedAmount
                        ? moneyFormatter.format('USD', requestedAmount)
                        : 'N/A',
                    },
                    {
                      label: 'Approved Amount:',
                      value: approvedAmount
                        ? moneyFormatter.format('USD', approvedAmount)
                        : 'N/A',
                    },
                  ],
                }}
              />
            </div>
            <div className="field-section-row my-2 row">
              <div className="col-xl-6">
                <dt className="list-line-action-item"> Note: </dt>
                <pre className="field-display-list-value credit-app-note">
                  {note}
                </pre>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </>
  );
};

CreditAppComponent.defaultProps = {
  onViewCompleted: () => {},
};

CreditAppComponent.propTypes = {
  completedApp: PropTypes.instanceOf(Parse.Object).isRequired,
  creditApplication: PropTypes.instanceOf(Parse.Object).isRequired,
  updateCreditApplication: PropTypes.func.isRequired,
  onViewCompleted: PropTypes.func,
};
