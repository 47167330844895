/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
import { randomKey } from '../../../utils/utils';
import SignatureSection from './SignatureSection';
import SignatureItem from './SignatureItem';
import HeaderGroup from './HeaderGroup';
import ImageObject from './ImageObject';

const containerKey = 'body_cell';
const pasteKey = 'body_cell';
const detailContainerKey = 'detail_body_cell';

const titleToolbarKeyMaps = {
  size: 'titleFontSize',
  bold: 'titleFontBold',
  underline: 'titleFontUnderline',
  name: 'titleFontName',
  color: 'titleFontColor',
  value: 'contractTitle',
};

const valueToolbarKeyMaps = {
  size: 'valueFontSize',
  bold: 'valueFontBold',
  underline: 'valueFontUnderline',
  name: 'valueFontName',
  color: 'valueFontColor',
  value: 'value',
};

const cellTypeMap = {
  textOnly: 'Text Only',
  textShort: 'Text (Words)',
  textLong: 'Text (Sentence)',
  textXLong: 'Text (Paragraph)',
  switched: 'Switched',
  detailCell: 'Detail Image/Line Item',
  provia: 'ProVia',
  drawing: 'Sketch',
  emptySpace: 'Empty Space',
  imagePicker: 'Image Picker',
  photoPickerCell: 'Photo Selector',
  measureSheetItemDetail: 'Measure Sheet Item Detail',
  header: 'Header',
  signatures: 'Signature',
  creditCardCell: 'Secure Credit Card Capture',
  securePaymentCaptureAll: 'Secure Payment Capture',
  bankAccountCell: 'Secure eCheck Capture',
};

const inputTypeMap = {
  default: 'Keyboard',
  picker: 'Picker',
  multiSelectPicker: 'Multi-Select Picker',
  keypad: 'Keypad (1)',
  keypadDecimal: 'Keypad (1.00)',
  phone: 'Phone Number',
  currency: 'Currency ($1.00)',
  currencyWhole: 'Currency ($1)',
  email: 'Email Address',
  numbers: 'Number Keyboard',
  sizePicker: 'Size Picker',
  '3DSizePicker': '3D Size Picker',
  datePicker: 'Date Picker',
  dateTimePicker: 'Date & Time',
  timePicker: 'Time',
  formula: 'Formula (1.00)',
  formulaWhole: 'Formula (1)',
  textFormula: 'Text Formula',
  formulaCurrency: 'Formula Currency ($1.00)',
  formulaCurrencyWhole: 'Formula Currency ($1)',
  creditCard: 'Credit Card',
  ccExpDate: 'Credit Card Exp. Date',
  dynamic: 'Dynamic',
  financeOptionsPicker: 'Finance Options Picker',
  unitedInchPicker: 'United Inch Picker',
};

const fractionDigitsMap = {
  inch: 'Inch',
  quarter: '1/4"',
  eighth: '1/8"',
  sixteenth: '1/16"',
};

class BodyCellItem {
  constructor(cellItem, parent) {
    this.JSON = cellItem;
    this.keys = [
      'objectId',
      'cellId',
      'cellType',
      'appTitle',
      'contractTitle',
      'appNote',
      'inputType',
      'fractionDigits',
      'dynamicInputType',
      'pickerValues',
      'multiSelectorSeparator',
      'required',
      'appOnly',
      'drawIfEmpty',
      'photoTag',
      'titleLinked',
      'titleFontSize',
      'titleFontBold',
      'titleFontUnderline',
      'titleFontName',
      'titleFontColor',
      'valueFontSize',
      'dateDisplayFormat',
      'valueFontBold',
      'valueFontUnderline',
      'valueFontName',
      'valueFontColor',
      'imageHeight',
      'detailItems',
      'allowReportPhotos',
      'images',
      'imagesSource',
      'defaultImageId',
      'initialsRequired',
      'drawingBackgroundImage',
      'initialsNote',
      'emptyValue',
      'measureSheetItemQuantityIds',
      'measueSheetItemTotalIds',
      'linkedMeasureSheetItem',
      'linkedMeasureSheetItemConfig',
      'linkedValueSettings',
      'value',
      'formula',
      'emptySpaceHeight',
      'switchValueOn',
      'switchValueOff',
      'header',
      'signatures',
      'hasBeenAdded',
      'inputDisabled',
      'hidden',
      'clearValueIfZero',
      'isTotalSaleAmount',
      'disableImageLink',
      'disablePaymentRequest',
      'paymentRequestText',
      'unitedInchSuffix',
      'minSizePickerWidth',
      'maxSizePickerWidth',
      'minSizePickerHeight',
      'maxSizePickerHeight',
      'minSizePickerDepth',
      'maxSizePickerDepth',
    ];
    this.parent = parent;
    this.pasteKey = pasteKey;
    this.toolbarKey = 'sectionTitle';
    this.secondaryToolbarKeyMaps = valueToolbarKeyMaps;
    this.toolboxKey = this.isDetailItem ? 'body-detail-cell' : 'body-cell';
    if (cellItem) {
      Object.assign(this, cellItem);
      this.objectId = this.objectId || randomKey(10);
      const {
        header,
        detailItems = [],
        signatures = [],
        images = [],
      } = cellItem;
      this.detailItems = detailItems.map((detailItem) => {
        const item = new BodyCellItem(detailItem, this);
        return item;
      });
      this._value = cellItem.value;
      this.signatures = signatures.map(
        (signature) => new SignatureItem(signature, this),
      );
      this.images = images.map((image) => new ImageObject(image, this));
      if (header) {
        this.header = new HeaderGroup(cellItem.header, this);
      }
    } else {
      this.objectId = randomKey(10);
      this.cellId = '';
      this.cellType = 'textShort';
      this.appTitle = 'New Title';
      this.contractTitle = 'New Title';
      this.appNote = '';
      this.inputType = 'default';
      this.fractionDigits = 'inch';
      this.dynamicInputType = undefined;
      this.pickerValues = [];
      this.multiSelectorSeparator = ',';
      this.required = false;
      this.appOnly = false;
      this.drawIfEmpty = false;
      this.titleFontSize = 10;
      this.titleFontBold = true;
      this.titleFontUnderline = false;
      this.titleFontName = '';
      this.titleFontColor = '0-0-0-1';
      this.valueFontSize = 10;
      this.valueFontBold = false;
      this.valueFontUnderline = false;
      this.valueFontName = '';
      this.valueFontColor = '0-0-0-1';
      this.imageHeight = 40;
      this.detailItems = [];
      this.allowReportPhotos = false;
      this.images = [];
      this.imagesSource = 'default';
      this.defaultImageId = '';
      this.initialsRequired = false;
      this.initialsNote = "(Customer's Initials)";
      this.emptyValue = '';
      this.measureSheetItemQuantityIds = [];
      this.measueSheetItemTotalIds = [];
      this.linkedMeasureSheetItem = '';
      this.linkedMeasureSheetItemConfig = undefined;
      this.linkValueSettings = undefined;
      this._value = 'New Value';
      this.formula = '';
      this.emptySpaceHeight = 4;
      this.switchValueOn = 'YES';
      this.switchValueOff = 'NO';
      this.header = undefined;
      this.signatures = undefined;
      this.inputDisabled = false;
      this.hidden = false;
      this.clearValueIfZero = false;
      this.isTotalSaleAmount = false;
      this.disableImageLink = false;
      this.paymentRequestText = '';
    }
    if (!this.objectId) {
      this.objectId = randomKey(10);
    }
    if (this.titleLinked === undefined) {
      this.titleLinked = this.appTitle === this.contractTitle;
    }
    const source = this.getSourceObject();
    if (source) {
      if (source.hasObjectId(this.objectId)) {
        this.objectId = randomKey(10);
      }
      source.addObjectId(this.objectId);
    }
  }

  get identifier() {
    return `${this.parent.identifier}-${this.objectId}`;
  }

  get containerKey() {
    return this.isDetailItem ? detailContainerKey : containerKey;
  }

  get value() {
    if (this._value) {
      return this._value;
    }
    switch (this.cellType) {
      case 'signatures':
      case 'header':
        this._value = ' '; // empty space is required for value when signatures or header
        break;
      case 'imagePicker':
      case 'drawing':
        return undefined;
      default:
        break;
    }
    return this._value;
  }

  set value(value) {
    this._value = value;
  }

  set cellType(value) {
    switch (value) {
      case 'header': {
        if (!this.header) {
          this.header = new HeaderGroup();
        }
        break;
      }
      case 'signatures': {
        if (!this.signatures || !this.signatures.length) {
          const signatureSection = new SignatureSection();
          this.signatures = signatureSection.data;
        }
        break;
      }
      case 'imagePicker':
      case 'drawing':
        delete this._value;
        break;
      default:
        break;
    }
    this._cellType = value;
  }

  get cellType() {
    return this._cellType;
  }

  get appTitle() {
    return this._appTitle;
  }

  set appTitle(value) {
    this._appTitle = value;
    if (this.titleLinked) {
      this._contractTitle = value;
    }
  }

  get contractTitle() {
    return this._contractTitle;
  }

  set contractTitle(value) {
    this._contractTitle = value;
    if (this.titleLinked) {
      this._appTitle = value;
    }
  }

  getPath() {
    if (!this.parent) {
      return undefined;
    }
    if (this.isDetailItem || this.isProVia) {
      const index = this.parent.detailItems.findIndex(
        ({ objectId }) => objectId === this.objectId,
      );
      return [...this.parent.getPath(), index];
    }
    const index = this.parent.cellItems.findIndex(
      ({ objectId }) => objectId === this.objectId,
    );
    return [...this.parent.getPath(), index];
  }

  get toolbarKeys() {
    switch (this.cellType) {
      case 'detailCell':
      case 'measureSheetItemDetail':
        return ['size', 'bold', 'underline', 'name', 'color'];
      case 'emptySpace':
      case 'header':
      case 'signatures':
        return [];
      default:
        return [
          'size',
          'bold',
          'underline',
          'name',
          'color',
          'value',
          'titleValueControl',
        ];
    }
  }

  get toolbarKeyMaps() {
    if (this.cellType === 'measureSheetItemDetail') {
      return valueToolbarKeyMaps;
    }
    return titleToolbarKeyMaps;
  }

  get isDetailItem() {
    return this.parent && this.parent.cellType === 'detailCell';
  }

  get isProVia() {
    return this.parent && this.parent.cellType === 'provia';
  }

  isEqualTo(otherCell) {
    return JSON.stringify(this.toJSON()) === JSON.stringify(otherCell.toJSON());
  }

  updateUniqueId() {}

  getSourceObject() {
    if (!this.parent) {
      return undefined;
    }
    return this.parent.getSourceObject();
  }

  clone() {
    const jsonObject = this.toJSON();
    const json = JSON.parse(JSON.stringify(jsonObject));
    json.objectId = randomKey(10);
    const cellItem = new BodyCellItem(json, this.parent);
    const { detailItems = [] } = cellItem;
    if (detailItems.length) {
      cellItem.detailItems = detailItems.map((detailItem) =>
        detailItem.clone(),
      );
    }
    if (this.header) {
      cellItem.header = this.header.clone();
    }
    if (this.signatures) {
      cellItem.signatures = this.signatures.map((obj) => obj.clone());
    }
    if (this.images) {
      cellItem.images = this.images.map((image) => image.clone());
    }
    return cellItem;
  }

  getCellIds() {
    const jsonObject = this.toJSON();
    const { cellId, detailItems = [] } = jsonObject;
    const cellIds = [cellId, ...detailItems.map((item) => item.cellId)].filter(
      (cellid) => cellid,
    );

    return cellIds;
  }

  toJSON() {
    const { header, detailItems = [], signatures = [], images = [] } = this;
    const json = {};
    this.keys.forEach((key) => {
      json[key] = this[key];
    });
    json.detailItems = detailItems.map((obj) => obj.toJSON());
    json.signatures = signatures.map((obj) => obj.toJSON());
    json.images = images.map((image) => image.toJSON());
    if (header) {
      json.header = header.toJSON();
    }
    return json;
  }

  get cellTypes() {
    if (this.isProVia) {
      return ['textShort', 'textLong', 'textXLong', 'switched'];
    }
    if (this.isDetailItem) {
      return ['textShort', 'textLong', 'textXLong', 'switched', 'imagePicker'];
    }
    return [
      'textShort',
      'textLong',
      'textXLong',
      'textOnly',
      'switched',
      'detailCell',
      'provia',
      'drawing',
      'emptySpace',
      'photoPickerCell',
      'measureSheetItemDetail',
      'header',
      'creditCardCell',
      'bankAccountCell',
      'securePaymentCaptureAll',
    ];
  }

  get isFormulaCell() {
    switch (this.inputType) {
      case 'formula':
      case 'formulaWhole':
      case 'formulaCurrency':
      case 'formulaCurrencyWhole':
        return true;
      default:
        return false;
    }
  }

  get isCurrencyCell() {
    switch (this.inputType) {
      case 'currency':
      case 'currencyWhole':
      case 'formulaCurrency':
      case 'formulaCurrencyWhole':
      case 'dynamic':
        return true;
      default:
        return false;
    }
  }

  async convertDrawingBackgroundImage() {
    if (this.drawingBackgroundImage && this.drawingBackgroundImage.isNew) {
      delete this.drawingBackgroundImage.isNew;
      delete this.drawingBackgroundImage._url;
      await this.drawingBackgroundImage.save();
    }
  }

  async convertNewFiles() {
    const promises = this.images.map((image) => image.convertNewFiles());
    if (this.cellType === 'header') {
      promises.push(this.header.convertNewFiles());
    }
    this.detailItems.forEach((detailCellItem) => {
      promises.push(detailCellItem.convertNewFiles());
    });

    if (this.drawingBackgroundImage) {
      promises.push(this.convertDrawingBackgroundImage());
    }

    await Promise.all(promises);
  }

  static cellTypeLabelForCellType(cellType) {
    return cellTypeMap[cellType] || '';
  }

  static inputTypes = [
    'default',
    'email',
    'phone',
    'creditCard',
    'ccExpDate',
    'keypad',
    'keypadDecimal',
    'numbers',
    'currency',
    'currencyWhole',
    'picker',
    'multiSelectPicker',
    'financeOptionsPicker',
    'unitedInchPicker',
    'sizePicker',
    '3DSizePicker',
    'datePicker',
    'timePicker',
    'dateTimePicker',
    'formula',
    'formulaWhole',
    'textFormula',
    'formulaCurrency',
    'formulaCurrencyWhole',
    'dynamic',
  ];

  static CellTypes = [
    'textShort',
    'textLong',
    'textXLong',
    'textOnly',
    'switched',
    'detailCell',
    'provia',
    'drawing',
    'emptySpace',
    'photoPickerCell',
    'measureSheetItemDetail',
    'header',
    'creditCardCell',
    'bankAccountCell',
    'securePaymentCaptureAll',
  ];

  static DetailCellTypes = [
    'textShort',
    'textLong',
    'textXLong',
    'textOnly',
    'switched',
    'emptySpace',
    'imagePicker',
  ];

  static inputTypeLabelForInputType(inputType) {
    return inputTypeMap[inputType] || '';
  }

  static fractionDigitsLabelForFractionDigits(fractionDigits) {
    return fractionDigitsMap[fractionDigits] || '';
  }

  static fractionDigits = ['inch', 'quarter', 'eighth', 'sixteenth'];

  static ContainerKey = containerKey;

  static DetailContainerKey = detailContainerKey;

  static PasteKey = pasteKey;
} //

export default BodyCellItem;
