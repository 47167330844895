/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import Parse from 'parse';
import { Modal } from 'react-bootstrap';
import { Spinner, Button } from '@blueprintjs/core';
import 'react-dates/initialize';
import FormGroup, { TextGroup } from './FormGroup';
import AppToaster from '../utils/AppToaster';

class PaymentMethodModal extends React.Component {
  styles = {
    monthInput: {
      width: '4.4rem',
      marginRight: '0.3rem',
    },
    yearInput: {
      width: '4.35rem',
      marginRight: '0.3rem',
    },
    cvvLabel: {
      marginLeft: '0.6rem',
      marginRight: '0.3rem',
    },
    cvvInput: {
      marginTop: '0.96rem',
      width: '6.5rem',
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      spreedly: {
        name: '',
        month: '',
        year: '',
        zip: '',
      },
      errors: [],
      showForm: false,
      isSubmitting: false,
    };
  }

  onSpreedlyChange = (key, value) => {
    if ((key === 'month' || key === 'year') && !/^[0-9]{0,2}$/.test(value)) {
      return;
    }
    const spreedly = {};
    spreedly[key] = value;
    this.setState((prev) => ({
      spreedly: { ...prev.spreedly, ...spreedly },
    }));
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { name: fullName, month, year, zip } = this.state.spreedly;
    window.Spreedly.tokenizeCreditCard({
      full_name: fullName,
      month,
      year: '20'.concat(year),
      zip,
    });
  };

  onExit = () => {
    window.Spreedly.removeHandlers();
    this.setState(() => ({
      showForm: false,
      isSubmitting: false,
      spreedly: {
        name: '',
        month: '',
        year: '',
        zip: '',
      },
      errors: [],
    }));
  };

  initSpreedly = async () => {
    try {
      const apiKey = await Parse.Cloud.run('spreedlyAPIKey');
      // Delay to sync with spreedly style setup
      setTimeout(() => this.setState(() => ({ showForm: true })), 2000);
      window.Spreedly.init(apiKey, {
        numberEl: 'spreedly-number',
        cvvEl: 'spreedly-cvv',
      });
      window.Spreedly.on('ready', () => {
        window.Spreedly.setStyle(
          'number',
          'border-radius: 5px; padding-left: .67rem; 5px; width: 25.67rem; height: 2rem; font-size: .84rem;',
        );
        window.Spreedly.setStyle(
          'cvv',
          'border-radius: 5px; padding-left: .67rem; width: 65px; height: 2rem; font-size: .84rem;',
        );
        window.Spreedly.setFieldType('number', 'text');
        window.Spreedly.setNumberFormat('prettyFormat');
        window.Spreedly.setPlaceholder('number', 'XXXX XXXX XXXX XXXX');
        window.Spreedly.setPlaceholder('cvv', 'XXX(X)');
      });
      window.Spreedly.on('paymentMethod', async (token) => {
        try {
          this.setState(() => ({ isSubmitting: true, errors: [] }));
          this.props.onChange(token, this.state.spreedly.zip);
        } catch (e) {
          // handle payment method change failure
          this.setState((prev) => ({
            errors: prev.errors.concat(e),
            isSubmitting: false,
          }));
        }
      });
      window.Spreedly.on('errors', (errors) => {
        this.setState(() => ({ errors }));
      });
    } catch (e) {
      AppToaster.show({ message: e.message, timeout: 5000 });
    }
  };

  render() {
    const { show, title } = this.props;
    return (
      <Modal
        show={show}
        variant="lg"
        onEntered={this.initSpreedly}
        onHide={this.props.onClose}
        onExit={this.onExit}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!this.state.showForm && (
            <div style={{ height: '155.5px', marginTop: '100px' }}>
              <Spinner size={50} />
            </div>
          )}
          <form style={{ display: this.state.showForm ? 'initial' : 'none' }}>
            <div style={{ marginBottom: '2rem' }}>
              <TextGroup
                title="Name on Card"
                type="text"
                value={this.state.spreedly.name}
                onChange={(name) => this.onSpreedlyChange('name', name)}
              />
            </div>
            <FormGroup title="Credit Card Number">
              <div id="spreedly-number" />
            </FormGroup>
            <div className="input-pair row">
              <div className="col-md-4 input-pair_title">Exp. Date</div>
              <div className="col-md-6 row mx-0 align-items-center">
                <input
                  type="text"
                  placeholder="MM"
                  value={this.state.spreedly.month}
                  onChange={(e) =>
                    this.onSpreedlyChange('month', e.target.value)
                  }
                  style={this.styles.monthInput}
                />
                <input
                  type="text"
                  placeholder="YY"
                  value={this.state.spreedly.year}
                  onChange={(e) =>
                    this.onSpreedlyChange('year', e.target.value)
                  }
                  style={this.styles.yearInput}
                />
                <div className="font-weight-bold" style={this.styles.cvvLabel}>
                  CVV
                </div>
                <div id="spreedly-cvv" style={this.styles.cvvInput} />
              </div>
            </div>
            <div style={{ marginBottom: '2rem' }}>
              <TextGroup
                title="Billing Zip-Code"
                type="text"
                value={this.state.spreedly.zip}
                onChange={(zip) => this.onSpreedlyChange('zip', zip)}
              />
            </div>
            {this.state.errors.length > 0 && (
              <FormGroup title="">
                {this.state.errors.map((error) => (
                  <i key={error.message} style={{ color: 'red' }}>
                    {error.message}
                  </i>
                ))}
              </FormGroup>
            )}
            <FormGroup title="">
              <Button
                type="submit"
                disabled={this.state.isSubmitting}
                onClick={this.onSubmit}
                // css={css`${style.success}`}
              >
                {this.state.isSubmitting ? <Spinner size={21} /> : 'Submit'}
              </Button>
            </FormGroup>
            <div
              style={{
                height: '30px',
                textAlign: 'center',
                width: '100%',
                marginTop: '30px',
              }}
            >
              Powered by{' '}
              <img
                src="/images/leap_logo.png"
                style={{ maxHeight: '100%', margin: '0 5px' }}
                alt="leap logo"
              />{' '}
              Secure Payment Capture
            </div>
            {/* <ButtonGroup
              title=""
              buttonTitle="Submit"
              variant="success"
              type="submit"
              onClick={this.onSubmit} /> */}
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

PaymentMethodModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string,
};

PaymentMethodModal.defaultProps = {
  show: false,
  title: 'Change Payment Method',
};

export default PaymentMethodModal;
