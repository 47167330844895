import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import OfficesNew from './New';
import OfficesIndex from './IndexPage';
import { LayoutContext } from '../Layout';

const Offices = () => {
  const { path } = useRouteMatch();
  const { setCrumbs, setButtons } = useContext(LayoutContext);
  const maxOffices = useSelector(
    ({ plan: { maxOfficeCount = 1 } }) => maxOfficeCount,
  );

  useEffect(() => {
    const crumb = { title: 'Offices', link: path };
    setCrumbs([crumb]);

    return () => {
      setCrumbs([]);
      setButtons();
    };
  }, [path, setButtons, setCrumbs]);

  return (
    <Switch>
      {maxOffices !== 1 && [
        <Route key="newOffice" path={`${path}/new`} component={OfficesNew} />,
        <Route key="offices" path={path} component={OfficesIndex} />,
      ]}
    </Switch>
  );
};

export default Offices;
