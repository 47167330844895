/* eslint-disable import/no-named-as-default */
import React, { useContext, useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { LayoutContext } from '../Layout';
import UsersIndex from './UsersIndex';
import UsersEdit from './Edit';

const Users = () => {
  const { path } = useRouteMatch();
  const { setCrumbs, setButtons } = useContext(LayoutContext);

  useEffect(() => {
    setCrumbs([{ title: 'Users', link: path }]);

    return () => {
      setCrumbs([]);
      setButtons();
    };
  }, [path, setButtons, setCrumbs]);

  return (
    <Switch>
      <Route path={[`${path}/new`, `${path}/edit/:id`]} component={UsersEdit} />
      <Route path={path} component={UsersIndex} />
    </Switch>
  );
};

export default Users;
