/* eslint-disable import/prefer-default-export */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import CurrencyInput from 'react-currency-input';

const VertInputWidget = (props) => {
  const {
    textarea,
    type,
    style = {},
    errorMessage,
    className = '',
    title,
  } = props;
  return (
    <div className="vert-input flex-1">
      <div className="filter-section-dropdown-title mr-2">
        <span className="lfw-bold input-title" style={{ color: 'black' }}>
          {title}
        </span>
      </div>
      <div className="filter-section-dropdown-element-container flex-1">
        {textarea ? (
          <textarea
            style={{
              ...style,
              color: errorMessage ? 'red' : style.color,
            }}
            onChange={props.onChange}
            value={props.value}
            className={`form-control form-control-lg ${className}`}
          />
        ) : type !== 'currency' ? (
          <input
            style={{
              ...style,
              color: errorMessage ? 'red' : style.color,
            }}
            type={props.type}
            onChange={props.onChange}
            value={props.value}
            className={`form-control form-control-lg ${className}`}
          />
        ) : (
          <CurrencyInput
            prefix="$"
            style={{
              ...style,
              color: errorMessage ? 'red' : style.color,
            }}
            onChangeEvent={(event, maskedvalue, floatvalue) => {
              props.onChange({
                target: {
                  value: floatvalue,
                },
              });
            }}
            value={props.value}
            className={`form-control form-control-lg ${className}`}
          />
        )}
        {errorMessage ? (
          <span className="input-error-message lfw-bold danger">
            {errorMessage}
          </span>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

VertInputWidget.propTypes = {
  textarea: PropTypes.bool,
};

VertInputWidget.defaultProps = {
  textarea: false,
};

export { VertInputWidget };
