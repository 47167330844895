/* eslint-disable react/no-deprecated */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import { TextGroup } from '../FormGroup';
import { startUpdateConfig } from '../../actions/auth';
import Panel from '../Panel';
import TitleButton from '../TitleButton';
import Copy from '../AppSettings/Copy';
import { showDeleteConfirmation } from '../../actions/deleteConfirmation';
import { pushToDataLayer } from '../../actions/tagManager';

export class LeadPerfection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientId: props.leadPerfection.clientId,
      url: props.leadPerfection.url,
      enabled: this.props.leadPerfection.enabled,
    };
  }

  componentDidMount() {
    const {
      setCrumbs,
      setButtons,
      location: { pathname },
    } = this.props;
    const crumb = { title: 'Lead Perfection', link: pathname };
    setCrumbs([crumb]);
    setButtons(
      <TitleButtons
        disabled
        onClearClick={this.askForClearPermission}
        onCopyClicked={this.onCopyClicked}
        onSaveClicked={this.onSaveClicked}
      />,
    );
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const oldStateProps = {
      clientId: this.props.leadPerfection.clientId,
      url: this.props.leadPerfection.url,
    };

    const newStateProps = {
      clientId: newProps.leadPerfection.clientId,
      url: newProps.leadPerfection.url,
    };

    const statePropsChange = !isEqual(newStateProps, oldStateProps);

    const officeUpdate = !isEqual(newProps.configId, this.props.configId);

    if (statePropsChange || officeUpdate) {
      this.setState({
        clientId: newProps.leadPerfection.clientId,
        url: newProps.leadPerfection.url,
      });
    }
  }

  componentDidUpdate(prevProps, previousState) {
    const stateChange = !isEqual(this.state, previousState);

    if (stateChange) {
      const urlChange = previousState.url !== this.state.url;

      const oldValid =
        previousState.url && previousState.url.indexOf('https://') === 0;

      const validUrl =
        this.state.url && this.state.url.indexOf('https://') === 0;

      if (urlChange && !validUrl && oldValid) {
        this.props.setButtons(
          <TitleButtons
            disabled
            onClearClick={this.askForClearPermission}
            onCopyClicked={this.onCopyClicked}
            onSaveClicked={this.onSaveClicked}
          />,
        );
      } else {
        this.props.setButtons(
          <TitleButtons
            onClearClick={this.askForClearPermission}
            onCopyClicked={this.onCopyClicked}
            onSaveClicked={this.onSaveClicked}
          />,
        );
      }
    }
  }

  componentWillUnmount() {
    const { setCrumbs, setButtons } = this.props;
    setCrumbs([]);
    setButtons();
  }

  onEnabledChanged = (checked) => {
    this.setState({ enabled: checked });
    if (!checked) {
      const unset = Object.keys(this.state).reduce(
        (unset2, key) => ({ ...unset2, [key]: '' }),
        {},
      );
      this.setState({ ...unset });
      this.props.pushToDataLayer({
        event: 'integrationEvent',
        eventCategory: 'Integration',
        eventAction: 'Disable',
        eventLabel: 'LeadPerfection',
      });
    } else {
      this.props.pushToDataLayer({
        event: 'integrationEvent',
        eventCategory: 'Integration',
        eventAction: 'Enable',
        eventLabel: 'LeadPerfection',
      });
    }
  };

  askForClearPermission = () => {
    this.props.showDeleteConfirmation({
      message: `Are you sure you want to clear this integration ?`,
      title: 'Clear Integration ?',
      onConfirm: () => {
        this.onClearClick();
      },
    });
  };

  onClearClick = () => {
    this.onEnabledChanged(false);
  };

  onClientIdChanged = (value) => {
    this.setState({ clientId: value });
  };

  onURLChanged = (value) => {
    this.setState({ url: value });
  };

  onCopyClicked = () => {
    this.setState({ showCopy: true });
  };

  onSaveClicked = () => {
    const needsHTTPS =
      this.state.url && this.state.url.indexOf('https://') !== 0;
    if (!needsHTTPS) {
      this.props.startUpdateConfig({ leadPerfection: this.state });
    }
  };

  render() {
    return (
      <>
        <Copy
          title="Copy LeadPerfection Settings"
          show={this.state.showCopy}
          warning="Warning! This will overwrite your current LeadPerfection settings"
          configKeys={['leadPerfection']}
          onClose={() => this.setState({ showCopy: false })}
        />
        <div className="default-page-padding">
          <Panel title="Lead Perfection">
            <div>
              <TextGroup
                title="Client Id"
                value={this.state.clientId}
                placeholder="Client Id"
                onChange={this.onClientIdChanged}
              />
              <TextGroup
                errorMessage={
                  this.state.url && this.state.url.indexOf('https://') === 0
                    ? undefined
                    : 'Invalid Url Must Use https://'
                }
                title="URL"
                value={this.state.url}
                placeholder="LP URL"
                onChange={this.onURLChanged}
              />
            </div>
          </Panel>
        </div>
      </>
    );
  }
}

LeadPerfection.propTypes = {
  leadPerfection: PropTypes.shape({
    clientId: PropTypes.string,
    url: PropTypes.string,
  }),
  startUpdateConfig: PropTypes.func.isRequired,
  ...layoutContextPropTypes,
};

LeadPerfection.defaultProps = {
  leadPerfection: {
    clientId: '',
    url: '',
  },
};

const TitleButtons = ({
  disabled,
  onCopyClicked,
  onSaveClicked,
  onClearClick,
}) => (
  <>
    <TitleButton
      variant="warning"
      onClick={() => onClearClick()}
      title="Clear"
    />
    <TitleButton
      variant="primary"
      onClick={() => onCopyClicked({ source: 'copy' })}
      title="Copy"
    />
    <TitleButton
      disabled={disabled}
      variant="success"
      onClick={() => onSaveClicked({ source: 'save' })}
      title="Save"
    />
  </>
);

TitleButtons.propTypes = {
  onCopyClicked: PropTypes.func.isRequired,
  onSaveClicked: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth: { config } }) => ({
  configId: config.objectId,
  leadPerfection: config.leadPerfection,
});

const mapDispatchToProps = (dispatch) => ({
  startUpdateConfig: (updates) => dispatch(startUpdateConfig(updates)),
  pushToDataLayer: (variablesForLayer) =>
    dispatch(pushToDataLayer(variablesForLayer)),
  showDeleteConfirmation: (params) => dispatch(showDeleteConfirmation(params)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(LeadPerfection),
);
