export const defaultState = {
  templateGroups: {
    items: [],
    selectedGroup: {
      contractsObjects: [],
    },
  },
};

export default (state = defaultState, { type, ...rest }) => {
  switch (type) {
    case 'TEMPLATES_SET_TEMPLATES':
      return {
        ...state,
        [rest.templateType]: {
          ...state[rest.templateType],
          templates: rest.templates,
        },
      };
    case 'LOGOUT': {
      return defaultState;
    }
    case 'SET_SELECTED_CONTRACT_OBJECT_GROUPS':
      return {
        ...state,
        templateGroups: {
          ...state.templateGroups,
          selectedGroup: rest.contractObjectGroup,
        },
      };
    case 'SET_CONTRACT_OBJECT_GROUPS':
      return {
        ...state,
        templateGroups: {
          items: [...rest.contractObjectGroups],
        },
      };
    case 'TEMPLATES_SET_INCLUDED_OFFICES_FILTER':
      return {
        ...state,
        [rest.templateType]: {
          ...state[rest.templateType],
          includedOfficesFilter: rest.includedOfficesFilter,
        },
      };
    case 'TEMPLATES_SET_INCLUDED_STATES_FILTER':
      return {
        ...state,
        [rest.templateType]: {
          ...state[rest.templateType],
          includedStatesFilter: rest.includedStatesFilter,
        },
      };
    case 'TEMPLATES_SET_PAGE':
      return {
        ...state,
        [rest.templateType]: {
          ...state[rest.templateType],
          page: rest.page,
        },
      };
    case 'TEMPLATES_SET_LIMIT':
      return {
        ...state,
        [rest.templateType]: {
          ...state[rest.templateType],
          limit: rest.limit,
        },
      };
    case 'TEMPLATES_SET_COUNT':
      return {
        ...state,
        [rest.templateType]: {
          ...state[rest.templateType],
          count: rest.count,
        },
      };
    default:
      return state;
  }
};
