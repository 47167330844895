import { pushToDataLayer } from './tagManager';

export const showSuccessAlert = ({ title, message, onConfirm = () => {} }) => ({
  type: 'SHOW_SUCCESS',
  title,
  message,
  onConfirm,
});

export const showErrorAlert = ({
  title,
  message,
  style,
  onConfirm = () => {},
}) => (dispatch) => {
  dispatch(
    pushToDataLayer({
      event: 'leapErrorEvent',
      eventCategory: 'Errors',
      eventAction: window.location.pathname,
      eventLabel: `${title} - ${message}`,
    }),
  );

  return dispatch({
    type: 'SHOW_ERROR',
    title,
    message,
    style,
    onConfirm,
  });
};

export const showWarningAlert = ({ title, message, onConfirm = () => {} }) => (
  dispatch,
) => {
  return dispatch({
    type: 'SHOW_WARNING',
    title,
    message,
    onConfirm,
  });
};

export const hideAlert = () => ({
  type: 'HIDE_ALERT',
});
