/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Spinner } from '@blueprintjs/core';
import FormGroup from './FormGroup';

const CardPaymentForm = (props) => {
  return (
    <form
      style={{
        display:
          props.showCardForm && props.paymentType === 'Credit Card'
            ? 'initial'
            : 'none',
      }}
      onSubmit={props.onSubmitCreditCard}
    >
      <div className="form-row">
        <div className="col-sm-6 mb-1">
          <label htmlFor="nameOnCard" className="col-form-label">
            Name on Card
          </label>
          <div>
            <input
              type="text"
              required
              onChange={(e) => props.onSpreedlyChange('name', e.target.value)}
              className="form-control form-control-lg"
              id="nameOnCard"
            />
          </div>
        </div>
        <div className="col-sm-6 mb-1">
          <label htmlFor="spreedly-number" className="col-form-label">
            Credit Card Number
          </label>
          <div id="spreedly-number" />
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-4 mb-1">
          <label htmlFor="exprMonth" className="col-form-label">
            Month
          </label>
          <div>
            <input
              id="exprMonth"
              type="text"
              required
              placeholder="MM"
              onChange={(e) => props.onSpreedlyChange('month', e.target.value)}
              className="form-control form-control-lg"
            />
          </div>
        </div>
        <div className="col-sm-4 mb-1">
          <label htmlFor="exprYear" className="col-form-label">
            Year
          </label>
          <div>
            <input
              id="exprYear"
              type="text"
              required
              placeholder="YY"
              onChange={(e) => props.onSpreedlyChange('year', e.target.value)}
              className="form-control form-control-lg"
            />
          </div>
        </div>
        <div className="col-sm-4 mb-1">
          <label htmlFor="spreedly-cvv" className="col-form-label">
            CVV
          </label>
          <div>
            <span id="spreedly-cvv" />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-6 mb-1">
          <label htmlFor="billingAddress1" className="col-form-label">
            Address 1
          </label>
          <div>
            <input
              type="text"
              required
              onChange={(e) =>
                props.onSpreedlyChange('address1', e.target.value)
              }
              className="form-control form-control-lg"
              id="billingAddress1"
            />
          </div>
        </div>
        <div className="col-sm-6 mb-1">
          <label htmlFor="billingAddress2" className="col-form-label">
            Address 2
          </label>
          <div>
            <input
              type="text"
              onChange={(e) =>
                props.onSpreedlyChange('address2', e.target.value)
              }
              className="form-control form-control-lg"
              id="billingAddress2"
            />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-4 mb-1">
          <label htmlFor="billingCity" className="col-form-label">
            City
          </label>
          <div>
            <input
              type="text"
              required
              onChange={(e) => props.onSpreedlyChange('city', e.target.value)}
              className="form-control form-control-lg"
              id="billingCity"
            />
          </div>
        </div>
        <div className="col-sm-4 mb-1">
          <label htmlFor="billingState" className="col-form-label">
            State
          </label>
          <div>
            <input
              type="text"
              required
              onChange={(e) => props.onSpreedlyChange('state', e.target.value)}
              className="form-control form-control-lg"
              id="billingState"
            />
          </div>
        </div>
        <div className="col-sm-4 mb-1">
          <label htmlFor="billingZip" className="col-form-label">
            Zip Code
          </label>
          <div>
            <input
              type="text"
              required
              onChange={(e) => props.onSpreedlyChange('zip', e.target.value)}
              className="form-control form-control-lg"
              id="billingZip"
            />
          </div>
        </div>
      </div>
      {props.errors.length > 0 && (
        <FormGroup title="">
          {props.errors.map((error, index) => (
            <i key={`error_${index}`} className="danger">
              {error.message}
            </i>
          ))}
        </FormGroup>
      )}
      <div className="form-row">
        <div className="col-12 center mt-4">
          <button
            type="submit"
            disabled={props.isSubmitting}
            className="btn btn-lg btn-primary btn-block"
          >
            {props.isSubmitting ? <Spinner size={21} /> : 'Submit'}
          </button>
          <div className="mt-2">{props.submitMessage}</div>
        </div>
      </div>
    </form>
  );
};

export default CardPaymentForm;
