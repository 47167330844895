/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';

export const FieldTitle = (props) => {
  const { title } = props;
  return <h1 className="leap-field-title">{title}</h1>;
};

FieldTitle.propTypes = {
  title: PropTypes.string,
};

FieldTitle.defaultProps = {
  title: '',
};
