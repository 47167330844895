import Parse from 'parse';
import { startRefreshConfig, handleError } from './auth';

export default (token) => async (dispatch, getState) => {
  try {
    const { auth } = getState();
    const { config } = auth;
    const { objectId } = config;
    await Parse.Cloud.run('authorize_hover', {
      token,
      configId: objectId,
    });
    dispatch(startRefreshConfig());
  } catch (e) {
    dispatch(handleError(e));
  }
};
