/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

const OrderedList = (props) => {
  const { list = [] } = props;
  return (
    <ul className="orderedList">
      {list.map((value, i) => (
        <li key={i}>{value || ''}</li>
      ))}
    </ul>
  );
};

OrderedList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.node),
};

OrderedList.defaultProps = {
  list: [],
};

export default OrderedList;
