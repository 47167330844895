export const defaultState = {
  items: [],
};

export default (state = defaultState, { type, items = [], ...rest }) => {
  switch (type) {
    case 'ADD_PACKAGES':
      return {
        ...state,
        items: [...state.items, ...items],
      };
    case 'SET_PACKAGES_COUNT':
      return {
        ...state,
        count: rest.count,
      };
    case 'SET_PACKAGES':
      return {
        ...state,
        items,
      };
    case 'SET_PACKAGE_OFFICES':
      return {
        ...state,
        offices: rest.offices,
      };
    case 'SET_PACKAGE_CATEGORY':
      return {
        ...state,
        category: rest.category,
      };
    case 'SET_PACKAGE_OPTIONS':
      return {
        ...state,
        options: rest.options || [],
      };
    case 'UPDATE_PACKAGE':
      return {
        ...state,
        items: state.items.map((pkg) => {
          if (pkg.objectId === rest.pkg.objectId) {
            return { ...rest.pkg };
          }
          return pkg;
        }),
      };
    case 'SET_PACKAGES_INCLUDED_OFFICES_FILTER':
      return {
        ...state,
        includedOffices: rest.offices,
      };
    case 'RESET_PACKAGES':
      return {
        ...defaultState,
      };
    default:
      return state;
  }
};
