/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MenuItem } from 'leap-menu-item';
import { setInitialStateForTemplate } from '../../../../actions/templateEdit';
import ContractObject from '../../Models/ContractObject';
import Stepper from '../../Stepper';

const EmptySpaceMenu = ({ object, contractObject, setTemplateState }) => (
  <>
    <MenuItem
      text="Empty Space Height"
      shouldDismissPopover={false}
      labelElement={
        <Stepper
          value={object.emptySpaceHeight}
          minValue={1}
          maxValue={1000}
          increment={1}
          small
          onChange={(value) => {
            object.emptySpaceHeight = value;
            setTemplateState(new ContractObject(contractObject.toJSON()));
          }}
        />
      }
    />
  </>
);

EmptySpaceMenu.propTypes = {
  object: PropTypes.any.isRequired,
  contractObject: PropTypes.instanceOf(ContractObject).isRequired,
  setTemplateState: PropTypes.func.isRequired,
};

EmptySpaceMenu.defaultProps = {};

const mapStateToProps = ({ templatesEdit = {} }) => ({
  templateValues: templatesEdit[templatesEdit.currentWorkingId],
  editCellItem: templatesEdit.editCellItem,
});

const mapDispatchToProps = (dispatch) => ({
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmptySpaceMenu);
