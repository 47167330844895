import { createRxDatabase, addRxPlugin } from 'rxdb';
// default plugins
import { RxDBAdapterCheckPlugin } from 'rxdb/plugins/adapter-check';
import { RxDBAttachmentsPlugin } from 'rxdb/plugins/attachments';
import { RxDBEncryptionPlugin } from 'rxdb/plugins/encryption';
import { RxDBKeyCompressionPlugin } from 'rxdb/plugins/key-compression';
import { RxDBLeaderElectionPlugin } from 'rxdb/plugins/leader-election';
import { RxDBLocalDocumentsPlugin } from 'rxdb/plugins/local-documents';
import { RxDBMigrationPlugin } from 'rxdb/plugins/migration';
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder';
import { RxDBReplicationPlugin } from 'rxdb/plugins/replication';
import { RxDBUpdatePlugin } from 'rxdb/plugins/update';
import { RxDBValidatePlugin } from 'rxdb/plugins/validate';
import { RxDBWatchForChangesPlugin } from 'rxdb/plugins/watch-for-changes';
// IndexedDB adapter plugin
import idb from 'pouchdb-adapter-idb';
import memdb from 'pouchdb-adapter-memory';
import { AsyncSubject } from 'rxjs';
import CollectionNames from './collections';
import {
  navigationCollectionMethods,
  navigationMethods,
  navigationSchema,
} from './schemas/navigationSchema';

addRxPlugin(RxDBValidatePlugin);
addRxPlugin(RxDBKeyCompressionPlugin);
addRxPlugin(RxDBMigrationPlugin);
addRxPlugin(RxDBLeaderElectionPlugin);
addRxPlugin(RxDBEncryptionPlugin);
addRxPlugin(RxDBUpdatePlugin);
addRxPlugin(RxDBWatchForChangesPlugin);
addRxPlugin(RxDBReplicationPlugin);
addRxPlugin(RxDBAdapterCheckPlugin);
addRxPlugin(RxDBAttachmentsPlugin);
addRxPlugin(RxDBLocalDocumentsPlugin);
addRxPlugin(RxDBQueryBuilderPlugin);
addRxPlugin(idb);
addRxPlugin(memdb);

/**
 * @typedef {import('rxdb').RxDatabase<
 *  import('./collections').LeapDashboardCollections
 * >} LeapDashboardDatabase
 */

/** @type {AsyncSubject<LeapDashboardDatabase>} */
const $db = new AsyncSubject();

const createdDb = async () => {
  // Setup leap dashboard database
  /** @type {LeapDashboardDatabase} */
  const dbConfig = {
    adapter: 'idb',
    eventReduce: true,
    multiInstance: true,
    name: 'leapdashboard',
  };
  let db;
  try {
    db = await createRxDatabase(dbConfig);
  } catch (rxerr) {
    dbConfig.adapter = 'memory';
    try {
      db = await createRxDatabase(dbConfig);
    } catch (rxerr2) {
      // eslint-disable-next-line no-console
      console.log(rxerr);
      // eslint-disable-next-line no-console
      console.log(rxerr2);
      return;
    }
  }

  // Setup collections on database
  await db.collection({
    name: CollectionNames.Navigation,
    schema: navigationSchema,
    methods: navigationMethods,
    statics: navigationCollectionMethods,
  });

  // Emit opened db object to observable subject and complete it
  $db.next(db);
  $db.complete();

  // In development, put the db on window so it can be used from the browser
  // console.
  if (process.env.NODE_ENV === 'development') {
    Object.defineProperty(window, 'db', { value: db, writable: false });
  }
};
createdDb();

export default $db;
