import db$ from '../dbContext';

const domainKey = window.location.hostname;

export const getNavigation = async () => {
  const db = await db$.toPromise();
  const navigation = await db.navigation.findOne(domainKey).exec();
  if (navigation) {
    return navigation;
  }
  /** @type {import('../schemas/navigationSchema').NavigationDocType} */
  const navObj = {
    domainKey,
    lastPath: '/',
    selectedOffice: null,
    partnerTag: null,
  };

  return db.navigation.atomicUpsert(navObj);
};

export const setSelectedOffice = async (officeId = '') => {
  const navigation = await getNavigation();
  return navigation.atomicPatch({ selectedOffice: officeId });
};

export const setLastPath = async (path = '/') => {
  const navigation = await getNavigation();

  if (window.Five9SocialWidget && window.Five9SocialWidget.options) {
    window.Five9SocialWidget.options.fields = {
      ...window.Five9SocialWidget.options.fields,
      lastPath: {
        show: false,
        label: 'lastPath',
        value: path,
      },
    };
  }

  return navigation.atomicPatch({ lastPath: path });
};

export const setPartnerTag = async (partnerTag = null) => {
  const navigation = await getNavigation();
  return navigation.atomicPatch({ partnerTag });
};
