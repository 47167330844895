import { hideWidget } from '../actions';

const initAuviousWidget = () => {
  // Auvious application ID
  const auviousId = process.env.REACT_APP_AUVIOUS_APPLICATION_ID;

  // Check if the Auvious application ID is defined.
  if (!auviousId) {
    console.error('AUVIOUS_APPLICATION_ID is not defined');
    return false;
  }

  // Widget options
  const widgetOptions = {
    'application-id': auviousId,
    id: 'auvious-widget',
    'auvious-environment': 'auvious.video',
    'active-widgets': 'cobrowse',
    'greeting-sound-on': 'false',
    'position-horizontal': 'right',
    style: 'display: none;',
  };

  // Widget element
  let widget = null;

  // Widget selector
  const widgetSelector = 'app-auvious-widget';

  // Main widget selector
  const mainWidgetSelector = '#speed-dial';

  // Function to hide the widget
  function registerHideWidgetEvent() {
    // Add an event listener to the widget.
    // This will be triggered when the cobrowse is disconnected.
    widget.addEventListener('cobrowseDisconnected', () => {
      hideWidget({
        widgetSelector,
        mainWidgetSelector,
      });
    });
  }

  // Function to show the widget
  // This function will be called when the user is logged in.
  // It will create the widget and append it to the body.
  // It will also register an event listener to hide the widget when the cobrowse is disconnected.
  function showWidget() {
    // Check if the widget already exists.
    const isAlreadyExist = document.querySelector(widgetSelector);
    if (!isAlreadyExist) {
      // Create the widget element.
      widget = document.createElement(widgetSelector);

      // get all the widget options and pass it to our widget.
      Object.keys(widgetOptions).forEach((key) => {
        const value = widgetOptions[key];
        widget.setAttribute(key, value);
      });

      // add the newly created widget to the body.
      document.body.appendChild(widget);
    }

    // Register the hide widget event.
    registerHideWidgetEvent();
  }

  // Wait for the widget to be defined.
  customElements.whenDefined(widgetSelector).then(() => {
    showWidget();
  });

  return true;
};

export default initAuviousWidget;
