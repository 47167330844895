import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';

const PreviewView = ({ placeholders, showNotAdded }) => (
  <div>
    <div className="priceguide-placeholders_container">
      <Table striped bordered hover>
        <thead>
          <tr>
            <td className="priceguide-placeholders_header-cell">Placeholder</td>
            <td className="priceguide-placeholders_header-cell">Replacement</td>
            {showNotAdded && (
              <td className="priceguide-placeholders_header-cell">
                Not Added Replacement
              </td>
            )}
          </tr>
        </thead>
        <tbody>
          {placeholders.map(
            ({ id, placeholder, replacement, notAddedReplacement }, index) => (
              <tr
                key={id || `placeholder-${index}`}
                className="priceguide-placeholders_row"
              >
                <td className="priceguide-placeholders_cell">{placeholder}</td>
                <td className="priceguide-placeholders_cell">{replacement}</td>
                {showNotAdded && (
                  <td className="priceguide-placeholders_cell">
                    {notAddedReplacement}
                  </td>
                )}
              </tr>
            ),
          )}
          {!placeholders.length && (
            <tr>
              <td colSpan={showNotAdded ? '3' : '2'}>None Added</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
    <span className="priceguide-placeholders_disclosure">
      Right click icon to copy/paste
    </span>
  </div>
);

PreviewView.propTypes = {
  placeholders: PropTypes.arrayOf(
    PropTypes.shape({
      placeholder: PropTypes.string,
      replacement: PropTypes.string,
      id: PropTypes.string,
    }),
  ),
  showNotAdded: PropTypes.bool,
};

PreviewView.defaultProps = {
  placeholders: [],
  showNotAdded: false,
};

export default PreviewView;
