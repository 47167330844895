/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import FormGroup from '../FormGroup';
import OfficeDropDown from '../Misc/OfficeDropDown';
import { startCopySettings } from '../../actions/auth';

class Copy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: props.show,
      selectedOffice: '',
    };
  }

  UNSAFE_componentWillReceiveProps({ show }) {
    this.setState({ show });
  }

  onSave = () => {
    this.props.startCopySettings(
      this.state.selectedOffice,
      this.props.configKeys,
      this.props.onClose,
    );
  };

  onOfficeChanged = ({ value }) => {
    this.setState({ selectedOffice: value });
  };

  render() {
    const { show } = this.state;
    return (
      <Modal show={show} onHide={this.props.onClose}>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup title="Source Office">
            <OfficeDropDown
              onChange={this.onOfficeChanged}
              selected={this.state.selectedOffice}
              hideAllOption
              excludedOfficeIds={[this.props.selectedOfficeId]}
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <h5 className="danger" style={{ textAlign: 'center' }}>
            {this.props.warning}
          </h5>
          <Button
            variant="success"
            onClick={this.onSave}
            disabled={!this.state.selectedOffice}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

Copy.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string.isRequired,
  warning: PropTypes.string,
  startCopySettings: PropTypes.func.isRequired,
  configKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
  selectedOfficeId: PropTypes.string,
};

Copy.defaultProps = {
  show: false,
  warning: '',
  selectedOfficeId: '',
};

const mapStateToProps = ({
  auth: { selectedOffice: { id = '' } = {} } = {},
}) => ({
  selectedOfficeId: id,
});

const mapDispatchToProps = (dispatch) => ({
  startCopySettings: (fromOffice, keys, callback) =>
    dispatch(startCopySettings(fromOffice, keys, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Copy);
