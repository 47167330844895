/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/prefer-default-export */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const logKeys = [
  'timeStamp',
  'localObjectId',
  'objectId',
  'logNote',
  'key metric',
  'identifier',
];

const parseLogs = (fileData) => {
  const logs = fileData
    .split('\n')
    .filter((logLine) => logLine)
    .map((logLine) => {
      const logRow = logLine.split('|').reduce((row, part, index) => {
        const key = logKeys[index];

        return {
          ...row,
          [key]: part,
        };
      }, {});

      return logRow;
    });

  return logs;
};

export const FileLogSection = (props) => {
  const { logObjects = [], logId } = props;

  const [showLogs, setShowLogs] = useState([]);

  const fecthFileData = async (logFileURL) => {
    if (logFileURL) {
      const fileData = await (await fetch(logFileURL)).text();
      const parsedLogs = parseLogs(fileData);
      setShowLogs([...parsedLogs]);
    }
  };

  useEffect(() => {
    const [selectedFile] = logObjects.filter(
      (logObject) => logObject.id === logId,
    );

    if (selectedFile) {
      fecthFileData(selectedFile.logFileURL);
    }
  }, [logId, logObjects]);

  return (
    <div className="logs-section col-lg-8 offset-lg-2">
      <h2 className="logs-header">Log</h2>
      <table className="logs-table table table-striped table-sm table-bordered">
        <thead>
          <tr>
            <th className="text-center list-line-header">Time</th>
            <th className="text-center list-line-header"> Logs</th>
          </tr>
        </thead>
        <tbody>
          {showLogs.map((log, i) => {
            return (
              <tr
                key={i}
                className={` ${
                  log.key_metric ? 'table-primary logs-table-keymetric' : ''
                }`}
              >
                <th scope="row">{moment(log.timeStamp).format('LLL')}</th>
                <td>{log.logNote}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

FileLogSection.propTypes = {
  logObjects: PropTypes.arrayOf(PropTypes.shape({})),
  logId: PropTypes.string,
};

FileLogSection.defaultProps = {
  logObjects: [],
  logId: '',
};
