/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import Parse from 'parse';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from './Select';

const OfficeDropDown = ({ onChange, selected, offices }) => {
  const options = offices.map((office) => ({
    value: office.objectId,
    label: office.name,
  }));
  const selection =
    typeof selected === 'string'
      ? options.find(({ value }) => value === selected)
      : selected;
  return (
    <Select
      onChange={onChange}
      value={selection}
      options={options}
      isSearchable
      closeMenuOnSelect
      isClearable={false}
    />
  );
};

OfficeDropDown.propTypes = {
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ]),
  offices: PropTypes.arrayOf(
    PropTypes.shape({
      objectId: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  hideAllOption: PropTypes.bool,
  excludedOfficeIds: PropTypes.arrayOf(PropTypes.instanceOf(Parse.Object)),
};

OfficeDropDown.defaultProps = {
  selected: '',
  offices: [],
  hideAllOption: false,
  excludedOfficeIds: [],
};

const mapStateToProps = (
  { auth: { offices = [] } },
  { hideAllOption, excludedOfficeIds = [] },
) => {
  if (hideAllOption) {
    return {
      offices: offices
        .filter(({ id }) => excludedOfficeIds.indexOf(id) === -1)
        .map((office) => office.toJSON()),
    };
  }
  return {
    offices: [
      { name: 'All', objectId: 'all' },
      ...offices
        .filter(({ id }) => excludedOfficeIds.indexOf(id) === -1)
        .map((office) => office.toJSON()),
    ],
  };
};

export default connect(mapStateToProps)(OfficeDropDown);
