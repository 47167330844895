/* eslint-disable import/prefer-default-export */
import { validateAll as ValidateAll } from './Validator';

export const validateAll = (state) => {
  const errors = { ...ValidateAll(state) };
  errors.password = state.password
    ? ''
    : 'You must specify a value for Password';
  errors.confirmPassword =
    state.password === state.confirmPassword ? '' : 'Passwords must Match';
  return errors;
};
