/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Parse from 'parse';
import Select from '../../Misc/Select';

const mapPartners = (partners) =>
  partners.map((partner) => ({
    value: partner.get('source'),
    label: partner.get('title'),
  }));

const PartnerCreditAppDropDown = ({
  selected = [],
  partners = [],
  onChange,
  hideSelectAll,
  cellData,
  placeholder,
  value,
  selectAllLabel,
  ...rest
}) => (
  <Select
    options={[{ label: 'N/A', value: '' }, ...mapPartners(partners)]}
    onChange={onChange}
    placeholder={placeholder}
    selected={selected}
    value={value}
    selectAllLabel="Select All"
    isClearable={false}
  />
);

PartnerCreditAppDropDown.propTypes = {
  partners: PropTypes.arrayOf(PropTypes.instanceOf(Parse.Object)).isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};

PartnerCreditAppDropDown.defaultProps = {};

const mapStateToProps = ({
  creditApplications: {
    partners: { items },
  },
}) => ({
  partners: items,
});

export default connect(mapStateToProps)(PartnerCreditAppDropDown);
