/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Jumbotron } from 'react-bootstrap';

const NotFoundPage = ({ logo }) => (
  <Jumbotron style={{ height: '100%' }}>
    <div style={{ textAlign: 'center' }}>
      <img alt="Company Logo" src={logo.url} style={{ width: '400px' }} />
      <div>
        <h1>404</h1>
      </div>
      <div>
        <h3>Page not found</h3>
      </div>
      <div>
        The page you are looking for doesn&apos;t exist or another error
        occurred.
      </div>
      <div>
        <Link to="/">Go back</Link>, or head over to{' '}
        <Link to="/index">leaptodigital.com</Link> to choose a new direction.
      </div>
    </div>
  </Jumbotron>
);

NotFoundPage.defaultProps = {
  logo: { url: '/images/leap_logo.png' },
};

const mapStateToProps = ({ auth }) => {
  const { config = {}, partnerLogo } = auth;
  const { companyLogo } = config;
  return {
    logo: partnerLogo || companyLogo,
  };
};

export default connect(mapStateToProps)(NotFoundPage);
