import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';

const TitleButton = ({
  onClick,
  disabled = false,
  variant,
  title,
  style = {},
  ...rest
}) => {
  const defaultStyle = useSelector(
    ({ config: { styles = {} } }) => styles.TitleButton,
  );

  return (
    <Button
      disabled={disabled}
      variant={variant}
      onClick={onClick}
      {...rest}
      style={{ ...defaultStyle, ...style }}
    >
      {title}
    </Button>
  );
};

TitleButton.propTypes = {
  variant: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
  disabled: PropTypes.bool,
};

TitleButton.defaultProps = {
  style: {},
  disabled: false,
};

export default TitleButton;
