import { randomKey } from '../../../utils/utils';
import HeaderCell from './HeaderCell';

const containerKey = 'header_item_group';
const pasteKey = 'header_item_group';

class HeaderItemGroup {
  constructor(array, parent, index = randomKey(10)) {
    this.JSON = array;
    this.keys = [];
    this.parent = parent;
    this.showToolbar = true;
    this.toolbarKey = '';
    this.toolbarKeys = [];
    this.toolbarKeyMaps = {};
    this.toolboxKey = '';
    this.containerKey = containerKey;
    this.pasteKey = pasteKey;
    this.objectId = parent
      ? `${parent.objectId}_${index}`
      : `${randomKey(10)}_${index}`;
    if (array) {
      this.cellItems = array.map((item) => new HeaderCell(item, this));
    } else {
      this.cellItems = [new HeaderCell(undefined, this, 0)];
    }
    const source = this.getSourceObject();
    if (source) {
      if (source.hasObjectId(this.objectId)) {
        this.objectId = randomKey(10);
      }
      source.addObjectId(this.objectId);
    }
  }

  get identifier() {
    return `${this.parent.identifier}-${this.objectId}`;
  }

  getPath() {
    if (!this.parent) {
      return undefined;
    }
    const index = this.parent.cellItems.findIndex(
      ({ objectId }) => objectId === this.objectId,
    );
    return [...this.parent.getPath(), index];
  }

  getSourceObject() {
    if (!this.parent) {
      return undefined;
    }
    return this.parent.getSourceObject();
  }

  clone() {
    const headerItemGroup = new HeaderItemGroup([], this.parent);
    headerItemGroup.cellItems = this.cellItems.map((item) => item.clone());
    return headerItemGroup;
  }

  toJSON() {
    return this.cellItems.map((item) => item.toJSON());
  }

  async convertNewFiles() {
    const promises = this.cellItems.map((headerCell) =>
      headerCell.convertNewFiles(),
    );
    this.cellItems.forEach((headerCell) => {
      promises.push(headerCell.convertNewFiles());
    });
    return Promise.all(promises);
  }

  static ContainerKey = containerKey;

  static PasteKey = pasteKey;
}

export default HeaderItemGroup;
