/* eslint-disable react/prop-types */
import React, { useEffect, useState, useContext } from 'react';
import Parse from 'parse';
import { useDispatch } from 'react-redux';
import { Redirect, useRouteMatch } from 'react-router-dom';
import UserContext from '../components/UserContext';
import { showLoader, hideLoader } from '../actions/loading';
import { startFetchStyles } from '../actions/config';
import { useNavigation } from '../hooks';
import { strCompare } from '../utils/utils';
import NotFoundPage from '../components/NotFoundPage';

export const getValidPatnerTags = async () => {
  const query = new Parse.Query('Partner');
  query.select(['url']);
  const urls = await query.find();
  return urls.map((object) => object.get('url'));
};

export const CompanyRoute = () => {
  const { loggedIn } = useContext(UserContext);
  const dispatch = useDispatch();
  const { params: { company = '' } = {} } = useRouteMatch('/:company');
  const { lastPath, setPartnerTag } = useNavigation();
  const [validPartner, setValidPartner] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const updatePartnerData = async () => {
      dispatch(showLoader('Validating Company'));
      const partnerTags = await getValidPatnerTags();
      const validTag = partnerTags.some((tag) => strCompare(tag, company));
      setValidPartner(validTag);
      if (validTag) {
        await setPartnerTag(company);
        await dispatch(startFetchStyles());
        // ...Fetch logos code goes here
        setLoaded(true);
        dispatch(hideLoader());
      } else {
        setLoaded(true);
        dispatch(hideLoader());
      }
    };
    updatePartnerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn, company]);

  if (loaded) {
    if (loggedIn && validPartner) {
      return <Redirect to={lastPath || '/'} />;
    }
    if (validPartner) {
      return <Redirect to="/login" />;
    }
    return <NotFoundPage />;
  }
  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      Loading {company} Data...
    </div>
  );
};

export default CompanyRoute;
