/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-state */
/* eslint-disable import/no-cycle */
import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { getEstimateDetails } from '../../actions/estimatesPage';
import { withLayoutContext } from '../Layout';
import LogTypeSwitch from './LogTypeSwitch';

class EstimateLogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPaymentRequestModal: false,
    };
  }

  componentDidMount() {
    const { match } = this.props;

    const { params } = match;

    const { id } = params;

    const crumbs = [
      { title: 'Estimates', link: '/estimates' },
      { title: 'Estimate Break Down', link: `/estimate_details/${id}` },
      { title: 'Logs' },
    ];
    this.props.setCrumbs(crumbs);

    this.props.getEstimateDetails(id);
  }

  componentWillUnmount() {
    const { setCrumbs } = this.props;
    setCrumbs();
  }

  render() {
    const { match, logs, logObjects } = this.props;
    const { params } = match;
    const { type, logId } = params;
    return (
      <div className="center-stage-outer">
        <div className="row">
          <LogTypeSwitch
            type={type}
            logId={logId}
            logs={logs}
            logObjects={logObjects}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ estimatesPage }) => {
  const { details = { estimate: {} } } = estimatesPage;
  const { logObjects = [] } = details;
  const analyticsData = details.analyticsData || {};

  const logs = (analyticsData.logObjects || []).map((log) => {
    if (log.key_metric && log.key_metric === 'appointment_time') {
      log.note = `Appointment Time: ${moment(log.note).format(
        'MM/DD/YYYY h:mm A',
      )}`;
    }
    return {
      time: log.date.toLocaleString(),
      value: log.note,
      key_metric: log.key_metric,
    };
  });

  return {
    logs,
    logObjects,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getEstimateDetails: (param) => dispatch(getEstimateDetails(param)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(EstimateLogs),
);
