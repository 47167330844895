const tagRowUpdate = (name, value, tagRow) => {
  const { tagParams = {} } = tagRow;
  switch (name) {
    case 'minSizePickerWidth':
    case 'maxSizePickerWidth':
    case 'minSizePickerHeight':
    case 'maxSizePickerHeight':
    case 'minSizePickerDepth':
    case 'maxSizePickerDepth': {
      return {
        name: 'tagParams',
        value: {
          ...tagParams,
          [name]: parseFloat(value),
        },
      };
    }

    case 'unitedInchSuffix': {
      return {
        name: 'tagParams',
        value: {
          ...tagParams,
          [name]: value,
        },
      };
    }

    default:
      return { name, value };
  }
};

export default tagRowUpdate;
