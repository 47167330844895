import { randomKey } from '../../../utils/utils';
import HeaderItemGroup from './HeaderItemGroup';
import BodyCellItem from './BodyCellItem';

const toolbarKeys = ['size', 'bold', 'underline', 'name', 'color'];

const toolbarKeyMaps = {
  size: 'titleFontSize',
  bold: 'titleFontBold',
  underline: 'titleFontUnderline',
  name: 'titleFontName',
  color: 'titleFontColor',
};

const containerKey = 'header_group';
const pasteKey = 'header_group';

class HeaderGroup {
  constructor(section, parent) {
    this.JSON = section;
    this.keys = [
      'objectId',
      'border',
      'appTitle',
      'title',
      'titleFontName',
      'titleFontSize',
      'titleFontBold',
      'titleFontUnderline',
      'titleFontColor',
      'cellItems',
    ];
    this.parent = parent;
    this.showToolbar = true;
    this.toolbarKey = 'sectionTitle';
    this.toolbarKeys = toolbarKeys;
    this.toolbarKeyMaps = toolbarKeyMaps;
    this.toolboxKey = 'header-title';
    this.containerKey = containerKey;
    this.pasteKey = pasteKey;
    if (section) {
      Object.assign(this, section);
      this.objectId = this.objectId || randomKey(10);
      this.cellItems = section.cellItems.map(
        (array, index) => new HeaderItemGroup(array, this, index),
      );
    } else {
      this.border = 0;
      this.appTitle = 'App Title';
      this.title = 'New Contract Title';
      this.titleFontName = '';
      this.titleFontSize = 14;
      this.titleFontBold = true;
      this.titleFontUnderline = false;
      this.titleFontColor = '0-0-0-1';
      this.cellItems = [
        new HeaderItemGroup(undefined, this, 0),
        new HeaderItemGroup(undefined, this, 1),
        new HeaderItemGroup(undefined, this, 2),
      ];
    }
    if (!this.objectId) {
      this.objectId = randomKey(10);
    }
    const source = this.getSourceObject();
    if (source) {
      if (source.hasObjectId(this.objectId)) {
        this.objectId = randomKey(10);
      }
      source.addObjectId(this.objectId);
    }
  }

  get identifier() {
    return `${this.parent.identifier}-${this.objectId}`;
  }

  getPath() {
    if (!this.parent) {
      return undefined;
    }
    if (this.parent.containerKey === BodyCellItem.ContainerKey) {
      return [...this.parent.getPath(), 0];
    }
    const index = this.parent.data.findIndex(
      ({ objectId }) => objectId === this.objectId,
    );
    return [...this.parent.getPath(), index];
  }

  getSourceObject() {
    if (!this.parent) {
      return undefined;
    }
    return this.parent.getSourceObject();
  }

  clone() {
    const jsonObject = this.toJSON();
    const json = JSON.parse(JSON.stringify(jsonObject));
    json.objectId = randomKey(10);
    const headerGroup = new HeaderGroup(json, this.parent);
    return headerGroup;
  }

  toJSON() {
    const json = {};
    this.keys.forEach((key) => {
      json[key] = this[key];
    });
    json.cellItems = this.cellItems.map((headerItemGroup) =>
      headerItemGroup.toJSON(),
    );
    return json;
  }

  insertHeaderGroupAtIndex(index = this.cellItems.length, group) {
    const updatedItems = [...this.cellItems];
    const newGroup = group || [
      new HeaderItemGroup(undefined, this),
      new HeaderItemGroup(undefined, this),
      new HeaderItemGroup(undefined, this),
    ];
    updatedItems.splice(index, 0, newGroup);
    return newGroup;
  }

  async convertNewFiles() {
    const promises = this.cellItems.map((headerItemGroup) =>
      headerItemGroup.convertNewFiles(),
    );
    return Promise.all(promises);
  }

  static ContainerKey = containerKey;

  static PasteKey = pasteKey;
}

export default HeaderGroup;
