/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import Parse from 'parse';
import { Container } from 'react-bootstrap';
import Paginator from '../Misc/Paginator';
import ResponsiveTable from '../SharedComponents/ResponsiveTable';
import { fetchUsersForDropDown } from '../../actions/customers';
import { handleError } from '../../actions/auth';
import { LayoutContext } from '../Layout/LayoutContext';
import './style.scss';
import useFilterFromUrl from '../../hooks/useFilterFromUrl';

const fetchEventLogs = async (queryParams) => {
  const {
    categories = [],
    actions = [],
    startDate,
    endDate,
    users = [],
    page = 1,
    limit = 10,
    relatedPointerIds = [],
  } = queryParams;

  const user = Parse.User.current();
  const company = await user.get('company').fetch();
  const logsQuery = new Parse.Query('EventLog');

  if (relatedPointerIds.length) {
    logsQuery.containedIn('relatedPointerIds', relatedPointerIds);
  }

  if (categories.length) {
    logsQuery.containedIn('category', categories);
  }

  if (actions.length) {
    logsQuery.containedIn('action', actions);
  }

  if (users.length) {
    logsQuery.containedIn('user', users);
  }

  logsQuery.limit(limit);
  const skip = (page - 1) * limit;
  logsQuery.skip(skip);

  if (startDate) {
    logsQuery.greaterThanOrEqualTo(
      'createdAt',
      startDate.startOf('day').toDate(),
    );
  }

  if (endDate) {
    logsQuery.lessThanOrEqualTo('createdAt', endDate.endOf('day').toDate());
  }

  logsQuery.equalTo('company', company);

  const feteched = await logsQuery.find();

  const formatted = feteched.map((eventlog) => {
    const createdBy = eventlog.get('createdBy');

    const nameFirst = createdBy ? createdBy.get('nameFirst') : 'N/A';
    const nameLast = createdBy ? createdBy.get('nameLast') : 'N/A';

    return {
      category: eventlog.get('category'),
      action: eventlog.get('action'),
      user: `${nameLast}, ${nameFirst}`,
      date: moment(eventlog.get('createdAt')).format('LLL'),
    };
  });

  return [...formatted];
};

const EventLog = () => {
  const { relatedPointerIds, categories, actions } = useFilterFromUrl();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const onLimitChanged = (value) => {
    setPage(1);
    setLimit(value);
  };

  const [logs, setLogs] = useState([]);
  const dispatch = useDispatch();

  const getEventLogs = async () => {
    try {
      const newLogs = await fetchEventLogs({
        relatedPointerIds,
        categories,
        actions,
        page,
        limit,
      });
      setLogs([...newLogs]);
    } catch (error) {
      dispatch(handleError(error));
    }
  };

  useEffect(() => {
    getEventLogs();
  }, [categories, actions, relatedPointerIds, page, limit]);

  const { setCrumbs } = useContext(LayoutContext);

  useEffect(() => {
    setCrumbs([{ title: 'Account' }, { title: 'Event Logs' }]);
    dispatch(fetchUsersForDropDown());
  }, []);

  return (
    <Container className="public-credit-app-form">
      <Paginator
        noCount
        page={page}
        limit={limit}
        onLimitChanged={({ value }) => onLimitChanged(value)}
        pageRange={5}
        onPageClicked={(value) => setPage(value)}
      />

      <ResponsiveTable
        items={logs}
        columns={[
          {
            title: 'Category',
            itemKey: 'category',
          },
          {
            title: 'Action',
            itemKey: 'action',
          },
          {
            title: 'User',
            itemKey: 'user',
          },
          {
            title: 'Date and Time',
            itemKey: 'date',
          },
        ]}
      />
    </Container>
  );
};

export default EventLog;
