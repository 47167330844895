/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import Parse from 'parse';
import { connect } from 'react-redux';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import {
  startFetchCompanies,
  startFetchOfficesForCompany,
  startFetchCategoriesForOffice,
} from '../../actions/config';
import { startCopyPriceGuide } from '../../actions/admin';
import {
  DropDownGroup,
  MultiSelectGroup,
  SwitchGroup,
  ButtonGroup,
} from '../FormGroup';
import Panel from '../Panel';

export class CopyPriceGuidePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companies: props.companies,
      copyPrices: false,
    };
  }

  componentDidMount() {
    const {
      setCrumbs,
      location: { pathname },
    } = this.props;

    const crumb = { title: 'Copy Price Guide', link: pathname };
    setCrumbs([crumb]);

    this.props.startFetchCompanies();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const newState = {};
    const { companies } = newProps;
    if (companies) {
      newState.companies = companies.map((company) => ({
        value: company.id,
        label: company.get('name'),
      }));
    }
    const { sourceOffices } = newProps;
    if (sourceOffices) {
      newState.sourceOffices = sourceOffices.map((office) => ({
        value: office.id,
        label: office.name,
      }));
    }
    const destinationOfficesList = newProps.destinationOffices;
    if (destinationOfficesList) {
      newState.destinationOfficesList = destinationOfficesList.map(
        (office) => ({ value: office.id, label: office.name }),
      );
    }
    const categoriesList = newProps.categories;
    if (categoriesList) {
      newState.categoriesList = categoriesList.map((category) => ({
        value: category,
        label: category,
      }));
    }
    this.setState(() => newState);
  }

  componentWillUnmount() {
    const { setCrumbs } = this.props;
    setCrumbs([]);
  }

  onSourceCompanyChange = (e) => {
    this.setState(() => ({ sourceCompany: e, sourceCompanyError: undefined }));
    if (e) {
      this.props.startFetchOfficesForCompany({
        companyId: e.value,
        descriptor: 'source',
      });
    }
  };

  onSourceOfficeChange = (e) => {
    this.setState(() => ({ sourceOffice: e, sourceOfficeError: undefined }));
    if (e) {
      this.props.startFetchCategoriesForOffice(e.value);
    }
  };

  onCategoriesChange = (e) => {
    this.setState(() => ({ categories: e, categoriesError: undefined }));
  };

  onDestinationCompanyChange = (e) => {
    this.setState(() => ({
      destinationCompany: e,
      destinationCompanyError: undefined,
    }));
    if (e) {
      this.props.startFetchOfficesForCompany({
        companyId: e.value,
        descriptor: 'destination',
      });
    }
  };

  onDestinationOfficesChange = (e) => {
    this.setState(() => ({
      destinationOffices: e,
      destinationOfficesError: undefined,
    }));
  };

  onCopyPricesChange = () => {
    this.setState(({ copyPrices }) => ({ copyPrices: !copyPrices }));
  };

  onSubmit = () => {
    const {
      sourceCompany,
      destinationCompany,
      categories,
      destinationOffices,
      sourceOffice,
      copyPrices,
    } = this.state;
    const errors = {};
    if (!sourceCompany) {
      errors.sourceCompanyError = this.props.missingFieldMessage;
    }
    if (!destinationCompany) {
      errors.destinationCompanyError = this.props.missingFieldMessage;
    }
    if (sourceCompany && !sourceOffice) {
      errors.sourceOfficeError = this.props.missingFieldMessage;
    }
    if (
      destinationCompany &&
      (!destinationOffices || destinationOffices.length === 0)
    ) {
      errors.destinationOfficesError = this.props.missingFieldMessage;
    }
    if (
      sourceCompany &&
      sourceOffice &&
      (!categories || categories.length === 0)
    ) {
      errors.categoriesError = this.props.missingFieldMessage;
    }
    if (Object.keys(errors).length > 0) {
      this.setState(() => errors);
    } else {
      this.props.startCopyPriceGuide({
        sourceCompanyId: sourceCompany.value,
        destinationCompanyId: destinationCompany.value,
        categories,
        destinationOfficeIds: destinationOffices,
        sourceOfficeId: sourceOffice.value,
        copyPrices: copyPrices ? 'on' : false,
      });
    }
  };

  // eslint-disable-next-line consistent-return
  officeValueRenderer = (selected, options) => {
    if (selected.length === 0) {
      return 'Select offices to copy price guide TO';
    }
    if (selected.length === options.length) {
      return 'All offices selected';
    }
  };

  render() {
    return (
      <div className="default-page-padding">
        <Panel title="Copy Price Guide">
          <DropDownGroup
            title="Source Company"
            errorMessage={this.state.sourceCompanyError}
            value={this.state.sourceCompany}
            placeholder="Select company to copy price guide FROM"
            onChange={this.onSourceCompanyChange}
            options={this.state.companies || []}
            isDisabled={!this.state.companies}
            // isLoading={!this.state.companies}
            isClearable={false}
            backspaceRemovesValue={false}
            closeMenuOnSelect
          />
          {this.state.sourceCompany && (
            <DropDownGroup
              title="Source Office"
              errorMessage={this.state.sourceOfficeError}
              value={this.state.sourceOffice}
              placeholder="Select office to copy price guide FROM"
              onChange={this.onSourceOfficeChange}
              options={this.state.sourceOffices || []}
              isDisabled={!this.state.sourceOffices}
              // isLoading={!this.state.sourceOffices}
              isClearable={false}
              backspaceRemovesValue={false}
              closeMenuOnSelect
            />
          )}
          {this.state.sourceOffice && (
            <MultiSelectGroup
              title="Source Categories"
              errorMessage={this.state.categoriesError}
              selected={this.state.categories || []}
              placeholder="Select categories to copy price guide FROM"
              onSelectedChanged={this.onCategoriesChange}
              options={this.state.categoriesList || []}
              disabled={!this.state.categoriesList}
              // isLoading={!this.state.categoriesList}
              disableSearch
            />
          )}
          <DropDownGroup
            title="Destination Company"
            errorMessage={this.state.destinationCompanyError}
            value={this.state.destinationCompany}
            placeholder="Select company to copy price guide TO"
            onChange={this.onDestinationCompanyChange}
            options={this.state.companies || []}
            isDisabled={!this.state.companies}
            // isLoading={!this.state.companies}
            isClearable={false}
            backspaceRemovesValue={false}
            closeMenuOnSelect
          />
          {this.state.destinationCompany && (
            <MultiSelectGroup
              title="Destination Offices"
              errorMessage={this.state.destinationOfficesError}
              selected={this.state.destinationOffices || []}
              valueRenderer={this.officeValueRenderer}
              onSelectedChanged={this.onDestinationOfficesChange}
              options={this.state.destinationOfficesList || []}
              disabled={!this.state.destinationOfficesList}
              // isLoading={!this.state.destinationOfficesList}
              disableSearch
            />
          )}
          <SwitchGroup
            title="Copy Prices"
            checked={this.state.copyPrices}
            onChange={this.onCopyPricesChange}
          />
          <hr />
          <ButtonGroup
            title=""
            buttonTitle="Submit"
            onClick={this.onSubmit}
            variant="success"
          />
        </Panel>
      </div>
    );
  }
}

CopyPriceGuidePage.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.instanceOf(Parse.Object)),
  startFetchCompanies: PropTypes.func.isRequired,
  startFetchOfficesForCompany: PropTypes.func.isRequired,
  startFetchCategoriesForOffice: PropTypes.func.isRequired,
  startCopyPriceGuide: PropTypes.func.isRequired,
  ...layoutContextPropTypes,
};

CopyPriceGuidePage.defaultProps = {
  companies: [],
  missingFieldMessage: 'This field is required',
};

const mapStateToProps = ({ config }) => ({
  companies: config.companies,
  sourceOffices: config.sourceOffices,
  destinationOffices: config.destinationOffices,
  categories: config.categories,
});

const mapDispatchToProps = (dispatch) => ({
  startFetchCompanies: () => dispatch(startFetchCompanies()),
  startFetchOfficesForCompany: (params) =>
    dispatch(startFetchOfficesForCompany(params)),
  startFetchCategoriesForOffice: (office) =>
    dispatch(startFetchCategoriesForOffice(office)),
  startCopyPriceGuide: (params) => dispatch(startCopyPriceGuide(params)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(CopyPriceGuidePage),
);
