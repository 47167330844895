/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Button, Table } from 'react-bootstrap';
import Parse from 'parse';
import MeasureSheetItem from '../../../Models/MeasureSheetItem';
import { startMassCopy } from '../../../actions/priceGuide2';
import { history } from '../../../router';
import AppToaster from '../../../utils/AppToaster';

class MassCopySelectValuesModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valuesToCopy: {},
      showConfirmation: false,
    };
  }

  onValueChecked = (key) => {
    const { valuesToCopy } = this.state;
    if (!key) {
      if (
        Object.keys(valuesToCopy).filter((val) => valuesToCopy[val]).length ===
        15
      ) {
        this.setState(() => ({ valuesToCopy: {} }));
      } else {
        this.setState(() => ({
          valuesToCopy: {
            image: true,
            category: true,
            subCategory: true,
            subSubCategories: true,
            itemName: true,
            itemNote: true,
            measurementType: true,
            formulaID: true,
            qtyFormula: true,
            includedOffices: true,
            shouldShowSwitch: true,
            items: true,
            accessories: true,
            additionalDetailObjects: true,
            placeholders: true,
          },
        }));
      }
    } else {
      valuesToCopy[key] = !valuesToCopy[key];
      this.setState(() => ({ valuesToCopy }));
    }
  };

  onShowConfirmation = () => {
    const { valuesToCopy: values } = this.state;
    const valuesToCopy = Object.keys(values).filter((val) => values[val]);
    if (valuesToCopy.length === 0) {
      return;
    }
    this.setState(() => ({ showConfirmation: true }));
  };

  onSubmit = async () => {
    try {
      const { sourceItem, destinationItems } = this.props;
      const { valuesToCopy: values } = this.state;
      const valuesToCopy = Object.keys(values).filter((val) => values[val]);
      if (valuesToCopy.length === 0) {
        return;
      }
      await this.props.startMassCopy({
        sourceItem,
        destinationItems,
        valuesToCopy,
      });
      history.push('/price_guide');
    } catch (e) {
      AppToaster.show({ message: e.message, timeout: 5000 });
    }
  };

  render() {
    const { show, sourceItem: item = new Parse.Object(), onClose } = this.props;
    const { valuesToCopy, showConfirmation } = this.state;
    return (
      <div>
        <Modal
          show={show}
          onHide={onClose}
          dialogClassName="price-guide__edit-msi-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Select Values to Copy</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table bordered hover>
              <thead>
                <tr onClick={() => this.onValueChecked()}>
                  <td style={{ width: 25 }}>
                    <input
                      type="checkbox"
                      checked={
                        Object.keys(valuesToCopy).filter(
                          (val) => valuesToCopy[val],
                        ).length === 15
                      }
                      readOnly
                    />
                  </td>
                  <td style={{ width: '30%' }}>Key</td>
                  <td style={{ width: '70%' }}>Value</td>
                </tr>
              </thead>
              <tbody>
                <tr onClick={() => this.onValueChecked('image')}>
                  <td>
                    <input
                      type="checkbox"
                      checked={valuesToCopy.image || false}
                      readOnly
                    />
                  </td>
                  <td>Thumbnail Image</td>
                  <td>
                    <img
                      style={{ height: '150px' }}
                      src={
                        item.has('image')
                          ? item.get('image').url()
                          : '/images/no_image.png'
                      }
                      alt="missing"
                    />
                  </td>
                </tr>
                <tr onClick={() => this.onValueChecked('category')}>
                  <td>
                    <input
                      type="checkbox"
                      checked={valuesToCopy.category || false}
                      readOnly
                    />
                  </td>
                  <td>Category</td>
                  <td>{item.get('category')}</td>
                </tr>
                <tr onClick={() => this.onValueChecked('subCategory')}>
                  <td>
                    <input
                      type="checkbox"
                      checked={valuesToCopy.subCategory || false}
                      readOnly
                    />
                  </td>
                  <td>Sub-Category</td>
                  <td>{item.get('subCategory')}</td>
                </tr>
                <tr onClick={() => this.onValueChecked('subSubCategories')}>
                  <td>
                    <input
                      type="checkbox"
                      checked={valuesToCopy.subSubCategories || false}
                      readOnly
                    />
                  </td>
                  <td>Drill-Downs</td>
                  <td>{item.get('subSubCategories')}</td>
                </tr>
                <tr onClick={() => this.onValueChecked('itemName')}>
                  <td>
                    <input
                      type="checkbox"
                      checked={valuesToCopy.itemName || false}
                      readOnly
                    />
                  </td>
                  <td>Name</td>
                  <td>{item.get('itemName')}</td>
                </tr>
                <tr onClick={() => this.onValueChecked('itemNote')}>
                  <td>
                    <input
                      type="checkbox"
                      checked={valuesToCopy.itemNote || false}
                      readOnly
                    />
                  </td>
                  <td>Note</td>
                  <td>{item.get('itemNote')}</td>
                </tr>
                <tr onClick={() => this.onValueChecked('measurementType')}>
                  <td>
                    <input
                      type="checkbox"
                      checked={valuesToCopy.measurementType || false}
                      readOnly
                    />
                  </td>
                  <td>Measurement Type</td>
                  <td>{item.get('measurementType')}</td>
                </tr>
                <tr onClick={() => this.onValueChecked('formulaID')}>
                  <td>
                    <input
                      type="checkbox"
                      checked={valuesToCopy.formulaID || false}
                      readOnly
                    />
                  </td>
                  <td>Formula ID</td>
                  <td>{item.get('formulaID')}</td>
                </tr>
                <tr onClick={() => this.onValueChecked('qtyFormula')}>
                  <td>
                    <input
                      type="checkbox"
                      checked={valuesToCopy.qtyFormula || false}
                      readOnly
                    />
                  </td>
                  <td>Quantity Formula</td>
                  <td>{item.get('qtyFormula')}</td>
                </tr>
                <tr onClick={() => this.onValueChecked('includedOffices')}>
                  <td>
                    <input
                      type="checkbox"
                      checked={valuesToCopy.includedOffices || false}
                      readOnly
                    />
                  </td>
                  <td>Included Offices</td>
                  <td>{(item.get('includedOffices') || []).length}</td>
                </tr>
                <tr onClick={() => this.onValueChecked('shouldShowSwitch')}>
                  <td>
                    <input
                      type="checkbox"
                      checked={valuesToCopy.shouldShowSwitch || false}
                      readOnly
                    />
                  </td>
                  <td>Show Switch</td>
                  <td>{item.get('shouldShowSwitch') ? 'YES' : 'NO'}</td>
                </tr>
                <tr onClick={() => this.onValueChecked('items')}>
                  <td>
                    <input
                      type="checkbox"
                      checked={valuesToCopy.items || false}
                      readOnly
                    />
                  </td>
                  <td>Options</td>
                  <td>{(item.get('items') || []).length}</td>
                </tr>
                <tr onClick={() => this.onValueChecked('accessories')}>
                  <td>
                    <input
                      type="checkbox"
                      checked={valuesToCopy.accessories || false}
                      readOnly
                    />
                  </td>
                  <td>Upcharges</td>
                  <td>{(item.get('accessories') || []).length}</td>
                </tr>
                <tr
                  onClick={() => this.onValueChecked('additionalDetailObjects')}
                >
                  <td>
                    <input
                      type="checkbox"
                      checked={valuesToCopy.additionalDetailObjects || false}
                      readOnly
                    />
                  </td>
                  <td>Additional Details</td>
                  <td>{(item.get('additionalDetailObjects') || []).length}</td>
                </tr>
                <tr onClick={() => this.onValueChecked('placeholders')}>
                  <td>
                    <input
                      type="checkbox"
                      checked={valuesToCopy.placeholders || false}
                      readOnly
                    />
                  </td>
                  <td>Placeholders</td>
                  <td>{(item.get('placeholders') || []).length}</td>
                </tr>
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onClose}>Close</Button>
            <Button onClick={this.onShowConfirmation} variant="danger">
              Done
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showConfirmation}>
          <Modal.Header closeButton={false}>
            <Modal.Title>Mass Copy Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure? This will overwrite all destination items. This action
            CANNOT BE UNDONE!
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => this.setState(() => ({ showConfirmation: false }))}
            >
              No
            </Button>
            <Button onClick={this.onSubmit} variant="danger">
              YES
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

MassCopySelectValuesModal.propTypes = {
  show: PropTypes.bool,
  sourceItem: PropTypes.instanceOf(MeasureSheetItem),
  onClose: PropTypes.func.isRequired,
  destinationItems: PropTypes.arrayOf(PropTypes.string),
  startMassCopy: PropTypes.func.isRequired,
};

MassCopySelectValuesModal.defaultProps = {
  show: false,
  sourceItem: undefined,
  destinationItems: [],
};

const mapDispatchToProps = (dispatch) => ({
  startMassCopy: (params) => dispatch(startMassCopy(params)),
});

export default connect(
  undefined,
  mapDispatchToProps,
)(MassCopySelectValuesModal);
