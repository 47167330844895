/* eslint-disable no-shadow */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-param-reassign */
/** @jsx jsx */
import { jsx } from '@emotion/core';
// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import {
  setInitialStateForTemplate,
  setEditCellItem,
} from '../../../actions/templateEdit';
import BodySection from './BodySection';
import { getItemStyle, getListStyle } from '../Utils';
import { reorder } from '../../../utils/utils';
import { setOnDragEndCallback } from '../../DropContext';
import ContractObject from '../Models/ContractObject';
import BodySectionObject from '../Models/BodySection';
import BodyGroupObject from '../Models/BodyGroup';
import { templateScrollContext } from '../TemplateEdit';

const BodySectionPDF = ({
  section,
  setTemplateState,
  editCellItem,
  setEditCellItem,
  draggableType,
}) => {
  const droppableId = `${section.objectId}-${BodyGroupObject.ContainerKey}`;
  setOnDragEndCallback(droppableId, (result) => {
    const { source, destination } = result;
    let newItem;
    if (source.droppableId === `new-${BodyGroupObject.ContainerKey}`) {
      newItem = new BodyGroupObject();
      section.data.splice(destination.index, 0, newItem);
    } else {
      section.data = reorder(
        section.data,
        result.source.index,
        result.destination.index,
      );
    }
    const contractObject = section.getSourceObject();
    const updateContract = new ContractObject(contractObject.toJSON());
    setTemplateState(updateContract);
    const [bodysection] = updateContract.contractData.filter(
      (section) => section.groupType === 'body',
    );
    if (newItem) {
      setEditCellItem(bodysection.data[destination.index]);
    }
  });
  templateScrollContext.expectedSections.initCount = section.data.length;
  templateScrollContext.expectedSections.count = 0;

  return (
    <Droppable
      droppableId={droppableId}
      isDropDisabled={draggableType !== BodyGroupObject.ContainerKey}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          css={getListStyle(
            snapshot.isDraggingOver,
            editCellItem.objectId === section.objectId,
          )}
          {...provided.droppableProps}
          className="body-container"
        >
          {section.data.map((sectionObject, index) => {
            const boundsData = templateScrollContext.getBound(sectionObject);
            return (
              <Draggable
                key={sectionObject.objectId}
                draggableId={`${sectionObject.objectId}`}
                index={index}
              >
                {(draggableProvided, draggableSnapshot) => (
                  <div
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    css={getItemStyle(
                      draggableSnapshot,
                      draggableProvided.draggableProps.style,
                      editCellItem.objectId === sectionObject.objectId,
                    )}
                  >
                    <div
                      ref={(ref) => {
                        if (ref) {
                          templateScrollContext.addSectionRef(
                            sectionObject,
                            ref,
                          );
                        }
                      }}
                    >
                      {boundsData && boundsData.rect && !boundsData.inBound ? (
                        <div
                          style={{
                            height: boundsData.rect.height,
                            width: boundsData.rect.width,
                          }}
                        />
                      ) : (
                        <BodySection index={index} section={sectionObject} />
                      )}
                    </div>
                    <div
                      {...draggableProvided.dragHandleProps}
                      css={{
                        ...draggableProvided.draggableProps.style,
                        position: 'absolute',
                        left: '-15px',
                        top: '0',
                        height: '100%',
                        backgroundColor: 'transparent',
                        width: '15px',
                      }}
                    />
                    {draggableProvided.placeholder}
                  </div>
                )}
              </Draggable>
            );
          })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

BodySectionPDF.propTypes = {
  section: PropTypes.instanceOf(BodySectionObject).isRequired,
  setTemplateState: PropTypes.func.isRequired,
  setEditCellItem: PropTypes.func.isRequired,
  editCellItem: PropTypes.any,
  draggableType: PropTypes.string,
};

BodySectionPDF.defaultProps = {
  editCellItem: {},
  draggableType: undefined,
};
const defaultScrollContext = {};

// eslint-disable-next-line no-shadow
const mapStateToProps = ({ templatesEdit, templateScrollContext }) => ({
  editCellItem: templatesEdit.editCellItem,
  draggableType: templatesEdit.draggableType,
  templateScrollContext: templateScrollContext || defaultScrollContext,
});

const mapDispatchToProps = (dispatch) => ({
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
  setEditCellItem: (item) => dispatch(setEditCellItem(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BodySectionPDF);
