export const defaultState = {
  gateways: [],
  customerCount: 0,
  paymentMethods: [],
  transactions: [],
};

export default (state = defaultState, { type, ...rest }) => {
  switch (type) {
    case 'PAYMENT_CAPTURE_RESET':
      return defaultState;
    case 'PAYMENT_CAPTURE_SET_GATEWAYS':
      return {
        ...state,
        gateways: rest.gateways,
      };
    case 'PAYMENT_METHOD_SET_CUSTOMER_COUNT':
      return {
        ...state,
        customerCount: rest.count,
      };
    case 'SET_PAYMENT_METHOD_CUSTOMERS':
      return {
        ...state,
        customers: rest.customers,
      };
    case 'SET_PAYMENT_METHOD_CUSTOMER':
      return {
        ...state,
        customer: rest.object,
      };
    case 'SET_PAYMENT_METHODS':
      return {
        ...state,
        paymentMethods: rest.objects,
      };
    case 'SET_TRANSACTIONS':
      return {
        ...state,
        transactions: rest.transactions,
      };
    case 'PAYMENT_CAPTURE_SET_LIMIT':
      return {
        ...state,
        limit: rest.limit,
      };
    case 'PAYMENT_CAPTURE_SET_PAGE':
      return {
        ...state,
        page: rest.page,
      };
    case 'PAYMENT_CAPTURE_SET_SELECTED_OFFICE_ID':
      return {
        ...state,
        selectedOfficeId: rest.selectedOfficeId,
      };
    case 'PAYMENT_METHOD_CLEAR_FOR_NEW_CUSTOMER':
      return {
        ...state,
        customer: undefined,
        paymentMethods: defaultState.paymentMethods,
        transactions: defaultState.transactions,
      };
    default:
      return state;
  }
};
