/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import onClickOutside from 'react-onclickoutside';
import { Popover, TextArea } from 'leap-menu-item';
import {
  setInitialStateForTemplate,
  setEditCellItem,
} from '../../../actions/templateEdit';
import ContractObject from '../Models/ContractObject';

class DetailParagraphCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      titleShowPopover: false,
      valueShowPopover: false,
    };
    this.titleRef = React.createRef();
    this.valueRef = React.createRef();
  }

  shouldComponentUpdate(nextProps) {
    return !this.props.cellItem.isEqualTo(nextProps.cellItem);
  }

  onMouseDown = (source) => {
    this.props.cellItem.editKey = source;
    this.props.setEditItem(this.props.cellItem);
  };

  onDoubleClick = (source) => {
    const key = `${source}ShowPopover`;
    const { openPopover } = this.state;
    if (openPopover !== key) {
      this.setState({
        [key]: true,
        [openPopover]: false,
        openPopover: key,
      });
    } else {
      this.setState({
        [key]: true,
        openPopover: key,
      });
    }
  };

  handleClickOutside = () => {
    this.setState({
      titleShowPopover: false,
      valueShowPopover: false,
    });
  };

  render() {
    const { cellItem, setTemplateState } = this.props;
    const { required, appTitle, value, inputDisabled, inputType } = cellItem;
    const isDisabled =
      inputType === 'formula' ||
      inputType === 'formulaWhole' ||
      inputType === 'textFormula' ||
      inputType === 'formulaCurrency' ||
      inputType === 'formulaCurrencyWhole' ||
      inputDisabled;
    return (
      <div className="templates-app__stacked">
        <Popover
          className={`templates-app__detail_title${
            required ? '_required' : ''
          }`}
          content={
            <textarea
              onContextMenu={(e) => e.stopPropagation()}
              fill
              ref={this.titleRef}
              defaultValue={appTitle}
              className="ignore-react-onclickoutside"
            />
          }
          isOpen={this.state.titleShowPopover}
          onClose={() => {
            cellItem.appTitle = this.titleRef.current.value;
            const contractObject = cellItem.getSourceObject();
            setTemplateState(new ContractObject(contractObject.toJSON()));
          }}
        >
          <div
            style={{ width: '100%' }}
            onMouseDown={() => this.onMouseDown('title')}
            onDoubleClick={() => this.onDoubleClick('title')}
          >
            {appTitle}
          </div>
        </Popover>
        <Popover
          className={`templates-app__value${
            isDisabled ? ' templates-app__disabled' : ''
          }`}
          content={
            <TextArea
              onContextMenu={(e) => e.stopPropagation()}
              fill
              inputRef={this.valueRef}
              defaultValue={value}
              className="ignore-react-onclickoutside"
            />
          }
          isOpen={this.state.valueShowPopover}
          onClose={() => {
            cellItem.value = this.valueRef.current.value;
            const contractObject = cellItem.getSourceObject();
            setTemplateState(new ContractObject(contractObject.toJSON()));
          }}
        >
          <div
            style={{ width: '100%' }}
            onMouseDown={() => this.onMouseDown('value')}
            onDoubleClick={() => this.onDoubleClick('value')}
          >
            {value}
          </div>
        </Popover>
      </div>
    );
  }
}

const mapStateToProps = ({ templatesEdit }) => ({
  template: templatesEdit[templatesEdit.currentWorkingId],
});

const mapDispatchToProps = (dispatch) => ({
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
  setEditItem: (cellItem) => dispatch(setEditCellItem(cellItem)),
});

const wrappedComponent = onClickOutside(DetailParagraphCell);
export default connect(mapStateToProps, mapDispatchToProps)(wrappedComponent);
