/* eslint-disable import/no-cycle */
import Parse from 'parse';
import { handleError } from './auth';
import { getNavigation } from '../data/repo/navigation';

export const apisLoaded = (loaded, apis) => ({
  type: 'CONTRACT_SENDING_APIS',
  loaded,
  apis,
});

export const startFetchContractSendingAPIs = (
  source = 'contractUpload',
) => async (dispatch) => {
  try {
    const result = await Parse.Cloud.run('endpointList', { source });
    dispatch(apisLoaded(true, result));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const companiesLoaded = (companies) => ({
  type: 'FETCH_COMPANIES',
  companies,
});

export const startFetchCompanies = () => async (dispatch, getState) => {
  try {
    let companies = getState().companies || [];
    if (!companies.length) {
      companies = await Parse.Cloud.run('listCompanies', {
        skip: 0,
        isActive: true,
      });
    }
    dispatch(companiesLoaded(companies));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const startResetState = () => ({
  type: 'RESET_STATE',
});

export const officesLoaded = ({ offices, descriptor }) => ({
  type: 'FETCH_OFFICES',
  offices,
  descriptor,
});

export const startFetchOfficesForCompany = ({
  companyId,
  descriptor,
}) => async (dispatch) => {
  try {
    let offices = [];
    offices = await Parse.Cloud.run('listOfficesForCompany', { companyId });
    dispatch(officesLoaded({ offices, descriptor }));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const categoriesLoaded = (categories) => ({
  type: 'FETCH_CATEGORIES',
  categories,
});

export const startFetchCategoriesForOffice = (officeId) => async (dispatch) => {
  try {
    let categories = [];
    categories = await Parse.Cloud.run('listCategoriesForOffice', { officeId });
    dispatch(categoriesLoaded(categories));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const stylesLoaded = (styles) => ({
  type: 'FETCH_STYLES',
  styles,
});

export const startFetchStyles = () => async (dispatch) => {
  const user = Parse.User.current();
  try {
    let styles;
    if (user) {
      styles = await Parse.Cloud.run('fetch_styles_for_user');
    } else {
      const { partnerTag } = await getNavigation();
      styles = await Parse.Cloud.run('fetch_styles', {
        url: partnerTag || window.location.pathname.substr(1),
      });
    }

    const modifiedStyles = modifyStyles(styles);

    dispatch(stylesLoaded(modifiedStyles));
    return styles;
  } catch (e) {
    dispatch(handleError(e));
  }
  return {};
};

const modifyStyles = (styles) => {
  if (!styles) {
    return {};
  }
  const { MainMenu = {}, ...allStyles } = styles;
  const { mainMenu = {} } = MainMenu;
  const { item = {} } = mainMenu;
  const { button = {} } = item;
  const { '&:hover': hoverPseudo = {} } = button;
  const { padding, borderLeft, ...hoverPseudoStyle } = hoverPseudo;

  const update = {
    ...allStyles,
    MainMenu: {
      ...MainMenu,
      mainMenu: {
        ...mainMenu,
        item: {
          ...item,
          button: {
            ...button,
            '&:hover': hoverPseudoStyle,
          },
        },
      },
    },
  };

  return {
    ...update,
  };
};

export const statesLoaded = (states) => ({
  type: 'FETCH_STATES',
  states,
});

export const startFetchStates = () => async (dispatch, getState) => {
  try {
    const statesKey = getState().auth.company.get('statesKey');
    const config = await Parse.Config.get();
    const states = config.attributes[statesKey];

    dispatch(statesLoaded(states));
  } catch (e) {
    dispatch(handleError(e));
  }
};
