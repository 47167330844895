import React, { useState, useContext, useEffect, useMemo } from 'react';
import Parse from 'parse';
import { Switch, useLocation } from 'react-router-dom';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { LayoutContext } from './LayoutContext';
import UserContext from '../UserContext';
import { routes } from '../../router';

import Logo from './Logo';
import DeleteConfirmation from '../DeleteConfirm';
import Header from './Header';
import MainMenu from './MainMenu';
import BreadCrumbBar from './BreadCrumbBar';
import SpeedDialWidget from '../Widgets';

import './index.scss';

const fetchUserContext = async () => {
  const user = Parse.User.current();

  const rolesQuery = new Parse.Query(Parse.Role);
  rolesQuery.equalTo('users', user);

  const [roles] = await Promise.all([
    rolesQuery.find(),
    user.fetchWithInclude(['company', 'selectedOffice', 'allowedOffices']),
  ]);

  const company = user.get('company') || {};
  const selectedOffice = user.get('selectedOffice') || {};
  const allowedOffices = user.get('allowedOffices') || [];

  return {
    key: user.id,
    email: user.attributes.email,
    firstName: user.attributes.nameFirst,
    lastName: user.attributes.nameLast,
    name: user.attributes.username,
    custom: {
      company: {
        id: company?.id,
        name: company?.attributes.name,
      },
      assignedOffice: {
        id: selectedOffice?.id,
        name: selectedOffice?.attributes.name,
      },
      allowedOffices: allowedOffices.map((office) => ({
        id: office.id,
        name: office.attributes.name,
      })),
      roles: roles.map((role) => ({
        id: role.id,
        name: role.attributes.name,
        title: role.attributes.title,
        type: role.attributes.type,
      })),
    },
  };
};

const Layout = () => {
  const { pathname } = useLocation();
  // Routes we don't want to use the layout chrome with, whether or not the user
  // is logged in
  const noChromePath =
    pathname.toLowerCase().startsWith('/signup') ||
    pathname.toLowerCase().startsWith('/login') ||
    pathname.toLowerCase().startsWith('/submitpayment') ||
    pathname.toLowerCase().startsWith('/customer_credit_app');
  const { loggedIn } = useContext(UserContext);
  const ldClient = useLDClient();
  const [buttons, setButtons] = useState(null);
  const [crumbs, setCrumbs] = useState([]);
  const [toolbar, setToolbar] = useState(null);
  const [isOpen, setIsOpen] = useState(true);
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (ldClient && loggedIn) {
      fetchUserContext().then((userContext) => {
        ldClient.identify({
          kind: 'user',
          ...userContext,
        });
      });
    }
  }, [ldClient, loggedIn]);

  const layoutContextValue = useMemo(
    () => ({
      crumbs,
      setButtons,
      setCrumbs,
      setToolbar,
      isOpen,
    }),
    [crumbs, isOpen],
  );

  return loggedIn || !noChromePath ? (
    <LayoutContext.Provider value={layoutContextValue}>
      <DeleteConfirmation />
      <SpeedDialWidget loggedIn={loggedIn} />
      <section className={`ld-layout-container ${!isOpen ? 'closed' : ''}`}>
        <Logo isOpen={isOpen} />
        <Header />
        <MainMenu isOpen={isOpen} toggleOpen={toggleOpen} />
        <div className="ld-layout-main">
          <BreadCrumbBar crumbs={crumbs} buttons={buttons} toolbar={toolbar} />
          <main>
            <Switch>{routes}</Switch>
          </main>
        </div>
      </section>
    </LayoutContext.Provider>
  ) : (
    <Switch>{routes}</Switch>
  );
};

Layout.propTypes = {};

export default Layout;
