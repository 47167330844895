// @ts-check
import React, { useEffect, useState, useContext, useRef } from 'react';
import Parse from 'parse';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Button, Container } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';
import { startUpdateConfig as startUpdateConfigAction } from '../../../actions/auth';
import Switch from '../../Misc/Switch';
import { LayoutContext } from '../../Layout';
import EmailPreview from '../../Misc/EmailPreview';

import './styles.scss';

/**
 * @typedef {{
 *  enabled: boolean,
 *  fromEmail: string,
 *  emailSubject: string,
 *  successRecipients: string[],
 *  successRedirectUrl: string,
 *  template: string,
 * }} State
 */

/**
 * @param {import('../../../reducers').State} state
 */
const creditAppSendingSettingsSelector = ({
  auth: {
    config: { creditAppSending = {} },
  },
}) => creditAppSending;

const getDefaultTemplate = async () => {
  const config = await Parse.Config.get();
  const template = config.get('secureCreditAppEmailHTML');
  return template;
};

const components = {
  DropdownIndicator: null,
};

const CreditAppSending = () => {
  const formRef = useRef();
  const fieldNames = {
    enabled: 'enabled',
    fromEmail: 'fromEmail',
    emailSubject: 'emailSubject',
    successRecipients: 'successRecipients',
    successRedirectUrl: 'successRedirectUrl',
    template: 'template',
  };
  const [fieldValidity, setFieldValidity] = useState({});
  const { setCrumbs } = useContext(LayoutContext);
  const dispatch = useDispatch();
  const creditAppSending = useSelector(creditAppSendingSettingsSelector);
  const startUpdateConfig = (config) => {
    dispatch(startUpdateConfigAction(config));
  };
  const [showPreview, setShowPreview] = useState(false);
  const [validated, setValidated] = useState(false);
  const togglePreview = () => {
    setShowPreview((prev) => !prev);
  };
  /**
   * @type {[State ,React.Dispatch<React.SetStateAction<State>>]}
   */
  const [
    {
      enabled,
      fromEmail,
      emailSubject,
      successRecipients,
      successRedirectUrl,
      template,
    },
    setAppState,
  ] = useState({
    enabled: false,
    fromEmail: '',
    emailSubject: '',
    successRecipients: [],
    successRedirectUrl: '',
    template: '',
  });
  // Logic to preserve the state from the previous frame
  const prevStateRef = useRef({});
  useEffect(() => {
    prevStateRef.current = {
      enabled,
      fromEmail,
      emailSubject,
      successRecipients,
      successRedirectUrl,
      template,
    };
  });
  const prevState = prevStateRef.current;

  const successRecipientsOptions = successRecipients.map((recipient) => ({
    label: recipient,
    value: recipient,
  }));

  const [recipientsInputVal, setRecipientsInputVal] = useState('');

  const onRecipientsInputChange = (value, actionMeta) => {
    switch (actionMeta.action) {
      case 'input-change':
        setRecipientsInputVal(value);
        break;
      case 'input-blur':
        addSuccessRecipient();
        break;
      default:
    }
  };

  const addSuccessRecipient = () => {
    if (recipientsInputVal) {
      /**
       * @type {HTMLInputElement}
       */
      const input = document.getElementById(fieldNames.successRecipients);
      input.type = 'email';

      if (input.reportValidity()) {
        input.style.background = 'none';
        setState({
          [fieldNames.successRecipients]: [
            ...successRecipients,
            recipientsInputVal,
          ],
        });
        setRecipientsInputVal('');
      } else {
        input.style['border-radius'] = '3px';
        input.style.background = 'rgba(255,100,100,0.25)';
        input.style.padding = '2px';
      }
    }
  };

  /**
   *
   * @param {any} value
   * @param {import('react-select').ActionMeta<any>} actionMeta
   */
  const onRecipientsChange = (value, actionMeta) => {
    setState({
      [fieldNames.successRecipients]: Array.isArray(value)
        ? value.map((option) => option.value)
        : [],
    });
  };

  const onRecipientsKeyDown = (event) => {
    if (!recipientsInputVal) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab': {
        addSuccessRecipient();
        event.preventDefault();
        break;
      }
      default:
    }
  };

  const valid =
    !enabled ||
    (Object.values(fieldValidity).length &&
      Object.values(fieldValidity).reduce(
        (value, prevValue) => value && prevValue,
        true,
      ));

  /**
   * @param {Partial<State>} newState
   */
  const setState = (newState) => {
    setAppState((oldState) => ({
      ...oldState,
      ...newState,
    }));
  };

  useEffect(() => {
    const crumb = {
      title: 'Sendable Credit App',
      link: 'credit_app_sending',
    };
    setCrumbs([crumb]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const setValues = async () => {
      if (!creditAppSending.template) {
        creditAppSending.template = await getDefaultTemplate();
      }
      setState(creditAppSending);
    };
    setValues();
  }, [creditAppSending]);

  // Check field validity whenever state changes, whether programatically or from user input
  useEffect(() => {
    /**
     * @param {string} inputName
     */
    const validateFormElement = (inputName) => {
      /**
       * @type {HTMLFormElement}
       */
      const form = formRef.current;
      const element = form.elements.namedItem(inputName);
      const { tagName } = element;
      if (element && (tagName === 'INPUT' || tagName === 'TEXTAREA')) {
        const fieldValid = element.checkValidity();
        setFieldValidity((prev) => ({
          ...prev,
          [inputName]: fieldValid,
        }));
      }
    };
    if (fromEmail !== prevState.fromEmail)
      validateFormElement(fieldNames.fromEmail);
    if (emailSubject !== prevState.emailSubject)
      validateFormElement(fieldNames.emailSubject);
    if (template !== prevState.template)
      validateFormElement(fieldNames.template);
  });

  /**
   * @param {boolean} change
   */
  const onEnabledChange = (change) => {
    setState({ enabled: change });
  };

  const validateTemplate = () => {
    return true;
  };

  /**
   * @param {React.ChangeEvent<HTMLInputElement>} event
   */
  const onChange = (event) => {
    const { name, value } = event.currentTarget;
    setState({ [name]: value });
  };

  /**
   * @param {React.FocusEvent<HTMLInputElement>} event
   */
  const onBlur = (event) => {
    const element = event.currentTarget;
    const { name } = element;
    const isValid = element.checkValidity();
    if (name === fieldNames.template) {
      const tValid = validateTemplate();
      setFieldValidity((prev) => ({
        ...prev,
        [fieldNames.template]: tValid,
      }));
    }
    setFieldValidity((prev) => ({ ...prev, [name]: isValid }));
  };

  /**
   * @param {React.FormEvent<HTMLFormElement>} event
   */
  const onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    // Only save if the form is valid or the feature is being disabled
    if (form.checkValidity() || !enabled) {
      setValidated(true);
      startUpdateConfig({
        creditAppSending: {
          enabled,
          fromEmail,
          emailSubject,
          successRecipients,
          successRedirectUrl,
          template,
        },
      });
    }
  };

  return (
    <Container>
      <EmailPreview
        emailPreview={template}
        handleClose={togglePreview}
        show={showPreview}
      />
      <Form
        className="d-flex flex-column default-page-padding"
        noValidate
        onSubmit={onSubmit}
        ref={formRef}
        validated={validated}
      >
        <Form.Group
          controlId={fieldNames.enabled}
          className="d-flex flex-column"
        >
          <Form.Label className="font-weight-bold">Enabled</Form.Label>
          <Switch checked={enabled} onChange={onEnabledChange} />
        </Form.Group>
        <div className={enabled ? '' : 'd-none'}>
          <Form.Group controlId={fieldNames.fromEmail}>
            <Form.Label className="font-weight-bold">From Email</Form.Label>
            <Form.Control
              name={fieldNames.fromEmail}
              onBlur={onBlur}
              onChange={onChange}
              placeholder="noreply@email.com"
              required
              type="email"
              value={fromEmail}
              isInvalid={
                fieldValidity[fieldNames.fromEmail] !== undefined
                  ? !fieldValidity[fieldNames.fromEmail]
                  : undefined
              }
              isValid={fieldValidity[fieldNames.fromEmail]}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid email adress!
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId={fieldNames.emailSubject}>
            <Form.Label className="font-weight-bold">Email Subject</Form.Label>
            <Form.Control
              name={fieldNames.emailSubject}
              onBlur={onBlur}
              onChange={onChange}
              required
              value={emailSubject}
              isInvalid={
                fieldValidity[fieldNames.emailSubject] !== undefined
                  ? !fieldValidity[fieldNames.emailSubject]
                  : undefined
              }
              isValid={fieldValidity[fieldNames.emailSubject]}
            />
            <Form.Control.Feedback type="invalid">
              Email subject is required!
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId={fieldNames.successRecipients}>
            <Form.Label className="font-weight-bold">
              Success Notification Recipients
            </Form.Label>
            <CreatableSelect
              components={components}
              inputId={fieldNames.successRecipients}
              inputValue={recipientsInputVal}
              isClearable
              isMulti
              menuIsOpen={false}
              name={fieldNames.successRecipients}
              onChange={onRecipientsChange}
              onInputChange={onRecipientsInputChange}
              onKeyDown={onRecipientsKeyDown}
              placeholder="Type a valid email address and press enter..."
              value={successRecipientsOptions}
            />
          </Form.Group>
          <Form.Group controlId={fieldNames.successRedirectUrl}>
            <Form.Label className="font-weight-bold">
              Success Redirect URL
            </Form.Label>
            <Form.Control
              name={fieldNames.successRedirectUrl}
              onBlur={onBlur}
              onChange={onChange}
              placeholder="https://mysite.com"
              type="url"
              value={successRedirectUrl}
              isInvalid={
                fieldValidity[fieldNames.successRedirectUrl] !== undefined
                  ? !fieldValidity[fieldNames.successRedirectUrl]
                  : undefined
              }
              isValid={fieldValidity[fieldNames.successRedirectUrl]}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid URL!
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId={fieldNames.template}>
            <Form.Label className="font-weight-bold">
              Default Email Template
            </Form.Label>
            <Form.Control
              as="textarea"
              name={fieldNames.template}
              onBlur={onBlur}
              onChange={onChange}
              required
              rows="20"
              value={template}
              isInvalid={
                fieldValidity[fieldNames.template] !== undefined
                  ? !fieldValidity[fieldNames.template]
                  : undefined
              }
              isValid={fieldValidity[fieldNames.template]}
            />
            <Form.Text className="text-muted">
              %message% = Message defined by sender in app <br />{' '}
              %credit_app_link_url% = Server generated link to credit
              application
            </Form.Text>
            <Form.Control.Feedback type="invalid">
              Template is invalid!
            </Form.Control.Feedback>
          </Form.Group>
        </div>
        <div className="d-flex flex-row justify-content-between w-100">
          {enabled ? (
            <Button
              className="mr-2"
              type="button"
              variant="secondary"
              onClick={togglePreview}
            >
              See Email Preview
            </Button>
          ) : (
            <div />
          )}
          <Button
            className="mr-2"
            type="submit"
            variant="primary"
            disabled={!valid}
          >
            Save
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default CreditAppSending;
