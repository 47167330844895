/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';
import { formulaEvaluates, evaluateFormula } from '../../../../utils/utils';
import Panel from '../../../Panel';
import FormGroup, { NumberGroup } from '../../../FormGroup';
import PlaceholderInputRow from './PlaceholderInputRow';
import FormulaDisplayRow from './FormulaDisplayRow';

class PriceFormulaTestSuite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      placeholders: props.placeholders.map((pl) => ({ ...pl, value: '' })),
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      placeholders: newProps.placeholders.map((pl) => ({ ...pl, value: '' })),
    });
  }

  updateTestValue = (value, updated) => {
    const placeholders = this.state.placeholders.map((placeholder) => {
      if (placeholder.symbol === updated.symbol) {
        return {
          ...placeholder,
          value,
        };
      }
      return placeholder;
    });
    this.setState(() => ({ placeholders }));
  };

  calculatedResult = () => {
    const { placeholders } = this.state;
    const { getFormula } = this.props;
    const formula = getFormula();
    const customFormulaIds = placeholders
      .filter(({ isDefault }) => !isDefault)
      .map(({ id }) => id);
    if (formulaEvaluates(formula, customFormulaIds)) {
      const result = evaluateFormula({ formula, placeholders });
      return (result || 0).toFixed(2);
    }
    return 0;
  };

  render() {
    return (
      <Panel title="Test Suite">
        <FormGroup title="">
          <PlaceholderInputRow
            placeholders={this.state.placeholders}
            updateTestValue={this.updateTestValue}
          />
        </FormGroup>
        <FormulaDisplayRow
          placeholders={() => this.state.placeholders}
          getFormula={this.props.getFormula}
        />
        <NumberGroup
          value={this.calculatedResult()}
          title="Calculated"
          onChange={(e) => e}
        />
      </Panel>
    );
  }
}

PriceFormulaTestSuite.propTypes = {
  placeholders: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      symbol: PropTypes.string,
    }),
  ).isRequired,
  getFormula: PropTypes.func.isRequired,
};

PriceFormulaTestSuite.defaultProps = {};

export default PriceFormulaTestSuite;
