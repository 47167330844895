// Styled Paginator
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';
import Select from './Select';
import { pushToDataLayer } from '../../actions/tagManager';
import NoCountPageControl from './NoCountPageControl';

const Paginator = ({
  page,
  limit,
  totalCount,
  onLimitChanged,
  pageRange,
  onPageClicked,
  pushToDataLayer2,
  style,
  noCount,
  noRowCount,
}) => (
  <footer className="pagination-container">
    {!noRowCount && (
      <div className="pagination-select-container">
        {noCount
          ? `Showing page: ${page}`
          : `Showing ${(page - 1) * limit + 1}-${Math.min(
              page * limit,
              totalCount,
            )} of ${totalCount} rows`}
        <Select
          name="form-field-name"
          className="pagination-select"
          value={{ value: limit, label: `${limit}` }}
          onChange={(event) => {
            onLimitChanged(event);
            pushToDataLayer2({
              event: 'paginationEvent',
              eventCategory: 'Pagination',
              eventAction: 'Show Rows',
              eventLabel: event.value,
            });
          }}
          closeMenuOnSelect
          isClearable={false}
          placeholder=""
          isSearchable={false}
          options={[
            {
              value: 10,
              label: '10',
            },
            {
              value: 25,
              label: '25',
            },
            {
              value: 50,
              label: '50',
            },
            {
              value: 100,
              label: '100',
            },
          ]}
        />
        rows per page
      </div>
    )}
    <div style={style} className={`${noCount ? 'd-block' : 'd-none'}`}>
      <NoCountPageControl
        activePage={page}
        onChange={onPageClicked}
        nextDisabled={false}
      />
    </div>
    <div
      style={style}
      className={`${noCount ? 'd-none' : 'd-block'} ${
        noRowCount ? 'w-100' : ''
      }`}
    >
      <div className="side-panel-paginator">
        <Pagination
          activePage={page}
          itemsCountPerPage={limit}
          itemClass="page-item"
          linkClass="page-link"
          totalItemsCount={totalCount}
          pageRangeDisplayed={pageRange}
          onChange={onPageClicked}
          innerClass="pagination pagination-paginator"
          activeLinkClass="active"
        />
      </div>
    </div>
  </footer>
);

Paginator.propTypes = {
  page: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  onLimitChanged: PropTypes.func.isRequired,
  pageRange: PropTypes.number,
  onPageClicked: PropTypes.func.isRequired,
  pushToDataLayer2: PropTypes.func.isRequired,
  style: PropTypes.shape({}).isRequired,
  noCount: PropTypes.bool.isRequired,
  noRowCount: PropTypes.bool.isRequired,
};

Paginator.defaultProps = {
  pageRange: 5,
};

const mapStateToProps = ({
  config: { styles: { Paginator: style = {} } = {} },
}) => ({
  style,
});

const mapDispatchToProps = (dispatch) => ({
  pushToDataLayer2: (variablesForLayer) =>
    dispatch(pushToDataLayer(variablesForLayer)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Paginator);
