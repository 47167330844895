/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { TextGroup } from '../../FormGroup';

const SaveAsModal = ({ show, onClose, currentTitle, onSubmit }) => {
  const tempTitle = `${currentTitle} (copy)`;
  const [newName, setNewName] = useState(tempTitle);

  useEffect(() => {
    if (!show) {
      setNewName(tempTitle);
    }
  }, [show, tempTitle]);

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Save As</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TextGroup
          title="Name"
          value={newName}
          onChange={setNewName}
          autofocus
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            onSubmit(newName);
            onClose();
          }}
          variant="success"
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

SaveAsModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  currentTitle: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

SaveAsModal.defaultProps = {
  show: false,
};

export default SaveAsModal;
