/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import PlanActions from '../../actions/plan';
import Panel from '../Panel';
import {
  startFetchAvailableContacts,
  startSaveAvailableContacts,
} from '../../actions/availableContacts';
import FormGroup from '../FormGroup';
import { startFetchUsers } from '../../actions/users';
import { activeUsers } from '../../selectors/users';
import TitleButton from '../TitleButton';

import { pushToDataLayer } from '../../actions/tagManager';

export class AvailableContacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeUserIds: this.props.activeUserIds || [],
    };
  }

  UNSAFE_componentWillMount() {
    if (!this.props.allUsers.length) {
      this.props.startFetchUsers();
    }
    if (!this.props.role) {
      this.props.startFetchAvailableContacts();
    }
  }

  componentDidMount() {
    const {
      setCrumbs,
      setButtons,
      location: { pathname },
    } = this.props;

    const title = this.props.getSettingsLabel({
      anchor: `available_contacts`,
      title: 'Available Contacts',
    });
    const crumb = { title, link: pathname };
    setCrumbs([crumb]);
    setButtons(
      <TitleButton
        variant="success"
        onClick={this.onSaveClicked}
        title="Save"
      />,
    );
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { activeUserIds } = newProps;
    this.setState({
      activeUserIds,
    });
  }

  componentWillUnmount() {
    const { setCrumbs, setButtons } = this.props;
    setCrumbs([]);
    setButtons();
  }

  onUserCheckboxToggled = (objectId) => {
    const isAdded = this.state.activeUserIds.indexOf(objectId) > -1;
    const activeUserIds = this.state.activeUserIds.filter(
      (id) => id !== objectId,
    );
    if (!isAdded) {
      activeUserIds.push(objectId);
    }
    this.setState({ activeUserIds });
  };

  onSelectAllChanged = (value) => {
    let activeIds = [];
    if (value) {
      activeIds = this.props.allUsers.map((object) => object.objectId);
    }
    this.setState({ activeUserIds: activeIds });
  };

  onSaveClicked = () => {
    this.props.pushToDataLayer({
      event: 'settingsPageTitleButtonEvent',
      eventCategory: 'Settings Page',
      eventAction: 'available_contacts',
      eventLabel: 'Save',
    });

    this.props.startSaveAvailableContacts({
      role: this.props.role,
      addedUserIds: this.state.activeUserIds,
    });
    this.setState(() => ({}));
  };

  selectAllIsChecked = () => {
    return this.state.activeUserIds.length === this.props.allUsers.length;
  };

  render() {
    const title = this.props.getSettingsLabel({
      anchor: `available_contacts`,
      title: 'Available Contacts',
    });

    return (
      <div className="default-page-padding">
        <Panel title={title}>
          <div>
            <FormGroup title="">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th style={{ width: '35px' }}>
                      <input
                        type="checkbox"
                        checked={this.selectAllIsChecked()}
                        onChange={() =>
                          this.onSelectAllChanged(!this.selectAllIsChecked())
                        }
                      />
                    </th>
                    <th>User</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.allUsers.map(
                    ({ objectId, nameFirst, nameLast }) => (
                      <tr key={objectId}>
                        <td>
                          <input
                            type="checkbox"
                            checked={
                              this.state.activeUserIds.indexOf(objectId) > -1
                            }
                            onChange={() =>
                              this.onUserCheckboxToggled(objectId)
                            }
                          />
                        </td>
                        <td>
                          <button
                            className="fill-cell"
                            type="button"
                            onClick={() => this.onUserCheckboxToggled(objectId)}
                          >
                            {`${nameFirst} ${nameLast}`}
                          </button>
                        </td>
                      </tr>
                    ),
                  )}
                </tbody>
              </Table>
            </FormGroup>
          </div>
        </Panel>
      </div>
    );
  }
}

AvailableContacts.propTypes = {
  getSettingsLabel: PropTypes.func.isRequired,
  ...layoutContextPropTypes,
};

AvailableContacts.defaultProps = {};

const mapStateToProps = ({ availableContacts, users }) => ({
  role: availableContacts.role,
  activeUserIds: availableContacts.activeUserIds,
  allUsers: activeUsers(users.items),
});

const mapDispatchToProps = (dispatch) => ({
  getSettingsLabel: (anchor, defaultValue) =>
    dispatch(PlanActions.getSettingsLabel(anchor, defaultValue)),
  startFetchAvailableContacts: () => dispatch(startFetchAvailableContacts()),
  startFetchUsers: () => dispatch(startFetchUsers()),
  startSaveAvailableContacts: (object) =>
    dispatch(startSaveAvailableContacts(object)),
  pushToDataLayer: (variablesForLayer) =>
    dispatch(pushToDataLayer(variablesForLayer)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(AvailableContacts),
);
