/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import onClickOutside from 'react-onclickoutside';
import { Popover } from 'leap-menu-item';
import ContractObject from '../Models/ContractObject';
import SectionObject from '../Models/BodyGroup';
import {
  setInitialStateForTemplate,
  setEditCellItem,
} from '../../../actions/templateEdit';
import BodyRow from '../BodyRow';

class HeaderCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showPopover: false };
    this.titleRef = React.createRef();
  }

  onMouseDown = () => {
    this.props.setEditItem(this.props.section);
  };

  onDoubleClick = () => {
    this.setState({ showPopover: !this.state.showPopover });
  };

  handleClickOutside = () => {
    this.setState({ showPopover: false });
  };

  render() {
    const { section, setTemplateState } = this.props;
    return (
      <BodyRow cellItem={section}>
        <div className="templates-app__header_cell">
          <Popover
            content={
              <textarea
                onContextMenu={(e) => e.stopPropagation()}
                fill
                ref={this.titleRef}
                defaultValue={section.appTitle}
                className="ignore-react-onclickoutside"
              />
            }
            isOpen={this.state.showPopover}
            onClose={() => {
              section.appTitle = this.titleRef.current.value;
              const contractObject = section.getSourceObject();
              setTemplateState(new ContractObject(contractObject.toJSON()));
            }}
          >
            <div
              onMouseDown={() => this.onMouseDown()}
              onDoubleClick={() => this.onDoubleClick()}
            >
              {section.appTitle}
            </div>
          </Popover>
          <div className="templates-app__cell_bottom_border" />
          {section.canAddMultiple && <i className="fas fa-plus" />}
        </div>
      </BodyRow>
    );
  }
}

HeaderCell.propTypes = {
  setEditItem: PropTypes.func.isRequired,
  setTemplateState: PropTypes.func.isRequired,
  section: PropTypes.instanceOf(SectionObject).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
  setEditItem: (cellItem) => dispatch(setEditCellItem(cellItem)),
});

const wrappedComponent = onClickOutside(HeaderCell);
export default connect(undefined, mapDispatchToProps)(wrappedComponent);
