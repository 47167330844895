import React from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem } from '@blueprintjs/core';

const CopyPasteMenu = ({
  title,
  hideCopy,
  copyDisabled,
  onCopy,
  hidePaste,
  pasteDisabled,
  onPasteAdd,
  onPasteReplace,
  hideClearAll,
  onClearAll,
  clearAllDisabled,
}) => (
  <Menu>
    <Menu.Divider title={title} />
    {!hideCopy && (
      <>
        <MenuItem
          text="Copy"
          icon="duplicate"
          onClick={onCopy}
          disabled={copyDisabled}
        />
        {!hidePaste && (
          <>
            <MenuItem
              text="Paste (Add)"
              icon="clipboard"
              onClick={onPasteAdd}
              disabled={pasteDisabled}
            />
            <MenuItem
              text="Paste (Replace)"
              icon="clipboard"
              onClick={onPasteReplace}
              disabled={pasteDisabled}
            />
          </>
        )}
        {!hideClearAll && (
          <>
            <Menu.Divider />
            <MenuItem
              text="Clear All"
              icon="eraser"
              onClick={onClearAll}
              disabled={clearAllDisabled}
            />
          </>
        )}
      </>
    )}
  </Menu>
);

CopyPasteMenu.propTypes = {
  title: PropTypes.string,
  hideCopy: PropTypes.bool,
  copyDisabled: PropTypes.bool,
  onCopy: PropTypes.func,
  hidePaste: PropTypes.bool,
  pasteDisabled: PropTypes.bool,
  onPasteAdd: PropTypes.func,
  onPasteReplace: PropTypes.func,
  hideClearAll: PropTypes.bool,
  onClearAll: PropTypes.func,
  clearAllDisabled: PropTypes.bool,
};

CopyPasteMenu.defaultProps = {
  title: '',
  hideCopy: false,
  copyDisabled: false,
  onCopy: undefined,
  hidePaste: false,
  pasteDisabled: false,
  onPasteAdd: undefined,
  onPasteReplace: undefined,
  hideClearAll: false,
  onClearAll: undefined,
  clearAllDisabled: false,
};

export default CopyPasteMenu;
