/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Parse from 'parse';
import { Table } from 'react-bootstrap';
import { FileInput, Icon, ProgressBar, Button } from '@blueprintjs/core';
import Dropzone from 'react-dropzone';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import isURL from 'validator/lib/isURL';
import { pdfjs, Document, Page } from 'react-pdf';
// Don't add psdjd-dist to dependencies: doing so will make pdfjs throw irrelevant prop validation errors to the console
// eslint-disable-next-line import/no-extraneous-dependencies
import workerSrc from 'pdfjs-dist/build/pdf.worker';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import Select from '../Misc/Select';
import FormGroup, { TextGroup, DropDownGroup } from '../FormGroup';
import {
  startSaveResourceNoUpload as startSaveResourceNoUploadAction,
  setResourcesDirectory as setResourcesDirectoryAction,
} from '../../actions/resources';
import OfficesDropDown from '../IncludedOfficesDropDown';
import TitleButton from '../TitleButton';
import { checkForErrors } from '../AppSettings/ContractSending/Validation/Validator';
import {
  showErrorAlert as showErrorAlertAction,
  hideAlert as hideAlertAction,
} from '../../actions/alert';
import { handleError as handleErrorAction } from '../../actions/auth';
import { history } from '../../router';
import { setOnDragEndCallback } from '../DropContext';
import { newFileFromFile } from '../../utils/utils';
import PitchBookObject from '../../Models/PitchBookObject';
import { pushToDataLayer as pushToDataLayerAction } from '../../actions/tagManager';
import {
  showLoader as showLoaderAction,
  hideLoader as hideLoaderAction,
} from '../../actions/loading';
import Well from '../SharedComponents/Well';

pdfjs.GlobalWorkerOptions.workerSrc = workerSrc;

const droppableId = 'resourcesTabsEdit';

const acceptedString =
  '.pdf, .epub, .ppt, .pptx, .mp4, .aac, .ac3, .aif, .aiff, .aifc, .caf, .mp3, .m4a, .snd, .au, .sd2, .wav, .mov, .m4v, .3gp';

const fileTypes = [
  {
    value: 'file',
    label: 'File',
  },
  {
    value: 'folder',
    label: 'Folder',
  },
  {
    value: 'url',
    label: 'Website',
  },
  {
    value: 'deeplink',
    label: 'Ingage',
  },
];

const fileTypeForFileExtension = (extension) => {
  switch (extension.toLowerCase()) {
    case 'pdf':
      return 'pdf';
    case 'ppt':
    case 'pptx':
      return 'ppt';
    case 'epub':
      return 'epub';
    case 'aac':
    case 'ac3':
    case 'aif':
    case 'aiff':
    case 'aifc':
    case 'caf':
    case 'mp3':
    case 'mp4':
    case 'm4a':
    case 'snd':
    case 'au':
    case 'sd2':
    case 'wav':
    case 'mov':
    case 'm4v':
    case '3gp':
      return 'avf';
    default:
      return '';
  }
};

const fileTypeForDropDown = (fileType) => {
  switch (fileType) {
    case 'pdf':
    case 'ppt':
    case 'epub':
    case 'avf':
      return 'file';
    default:
      return fileType;
  }
};

const validationErrors = ({ item }) => {
  let url = '';
  if (item.get('fileType') === 'url' && item.get('url')) {
    if (!isURL(item.get('url'))) {
      url = 'Invalid URL';
    }
  }
  if (item.get('fileType') === 'deeplink') {
    if (!item.get('url')) {
      url = 'Invalid Deep Link Id';
    }
  }
  const buttons =
    item.get('fileType') === 'pdf' ? item.get('buttons') || [] : [];
  const displayName = item.get('displayName') || '';
  const namePath = displayName.split('/');
  const hasDisplayName = namePath[namePath.length - 1];
  const errors = {
    item: {
      url,
      displayName: hasDisplayName ? '' : 'You must specify a name',
      fileType: item.get('fileType') ? '' : 'You must specify a file type',
      buttons: buttons.map(({ pageNumber, title }) => ({
        pageNumber: pageNumber > 0 ? '' : 'Invalid',
        title: title ? '' : 'You must specify a Tab Title',
      })),
    },
  };
  return errors;
};

export class Edit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: new Parse.Object('PitchBookObject'),
      filePlaceholder: 'Drop or select file...',
      thumbnailPlaceholder: 'Drop or select thumbnail...',
      progress: 0,
      imgError: false,
    };
    this.state.errors = validationErrors(this.state);
    setOnDragEndCallback(droppableId, ({ source, destination }) => {
      const item = this.getNewInstance();
      const buttons = item.get('buttons') || [];
      const sourceObject = buttons[source.index];
      buttons.splice(source.index, 1);
      buttons.splice(destination.index, 0, sourceObject);
      item.set('buttons', buttons);
      const errors = validationErrors({ item });
      this.setState({ item, errors });
    });
  }

  async componentDidMount() {
    const {
      match: {
        params: { id = '' },
      },
      pushToDataLayer,
      setCrumbs,
      setButtons,
      maxOfficeCount,
      offices = [],
    } = this.props;
    const { filePlaceholder, thumbnailPlaceholder } = this.state;

    const [office] = offices;
    const initOffices = maxOfficeCount === 1 ? [office.toPointer()] : [];

    if (id) {
      const item = new PitchBookObject();
      item.id = id;
      await item.fetch();

      pushToDataLayer({
        event: 'resourceEvent',
        eventCategory: 'Resources',
        eventAction: 'View',
        eventLabel: item.get('displayName') || '',
      });

      const file = item.get('file');
      const thumbnail = item.get('thumbnail');
      const errors = validationErrors({ item });

      this.setState({
        item,
        errors,
        filePlaceholder: file
          ? file.name().substring(file.name().indexOf('_') + 1)
          : filePlaceholder,
        thumbnailPlaceholder: thumbnail
          ? thumbnail.name().substring(thumbnail.name().indexOf('_') + 1)
          : thumbnailPlaceholder,
      });
    } else {
      const item = new PitchBookObject();
      item.set('fileType', 'pdf');
      const search = new URLSearchParams(window.location.search);
      const folderData = search.get('folder');
      item.set('displayName', folderData ? `${folderData}/` : '');
      item.set('includedOffices', [...initOffices]);
      item.set('buttons', []);
      const errors = validationErrors({ item });
      this.setState({ item, errors });
    }

    setCrumbs(this.crumbs());
    setButtons(this.buttons());
  }

  componentDidUpdate(prevProps, prevState) {
    const { setButtons, setCrumbs } = this.props;
    if (
      !_.isEqual(prevProps, this.props) ||
      !_.isEqual(prevState, this.state)
    ) {
      setCrumbs(this.crumbs());
      setButtons(this.buttons());
    }
  }

  componentWillUnmount() {
    const { setCrumbs, setButtons } = this.props;

    setCrumbs([]);
    setButtons();
    const { item } = this.state;
    item.revert();
  }

  onInvalidFileType() {
    const { showErrorAlert, hideAlert } = this.props;
    showErrorAlert({
      title: 'Error',
      message: 'File type not supported',
      onConfirm: hideAlert,
    });
  }

  crumbs = () => {
    const {
      location: { pathname = '' },
    } = this.props;
    const { item } = this.state;
    const includedOffices = item.get('includedOffices') || [];

    const officeQuery = `?includedOffices=${includedOffices.map(
      (office) => office.id,
    )}`;

    const displayName = item.get('displayName');

    if (displayName) {
      const myCrumbs = displayName
        .split('/')
        .reduce((links, folder, i, all) => {
          const path = `${
            links.length ? '/resources/' : '/resources'
          }${links.map(({ title }) => title).join('/')}/${folder}`;

          const finalPath =
            i !== all.length - 1 ? `${path}${officeQuery}` : pathname;

          return [...links, { title: folder || '...', link: finalPath }];
        }, []);

      const newCrumbs = [
        { title: 'Resources', link: `/resources${officeQuery}` },
        ...myCrumbs,
      ];

      return newCrumbs;
    }

    return [];
  };

  buttons = () => <TitleButtons onSaveClicked={this.onSaveClicked} />;

  getNewInstance = () => {
    const { item } = this.state;
    return item.newInstance();
  };

  onFileChanged = async (e) => {
    const item = this.getNewInstance();
    const newFile = await newFileFromFile(e, 'resource', 'resource');
    item.set('file', newFile);
    const fileExtension = newFile
      .name()
      .split('.')
      .pop();
    const validFile = acceptedString
      .split(', ')
      .filter((type) => type === `.${fileExtension.toLowerCase()}`).length;
    if (!validFile) {
      this.onInvalidFileType();
    } else {
      const fileType = fileTypeForFileExtension(fileExtension.toLowerCase());
      item.set('fileType', fileType);
      const errors = validationErrors({ item });
      const filePlaceholder = e.name;
      this.setState({ item, errors, filePlaceholder });
    }
  };

  onThumbnailChanged = async (e) => {
    const newFile = await newFileFromFile(e, 'thumbnail', 'resource_thumbnail');
    const item = this.getNewInstance();
    item.set('thumbnail', newFile);
    const thumbnailPlaceholder = newFile.name();
    this.setState({ item, thumbnailPlaceholder });
  };

  onRemoveThumbnail = () => {
    const item = this.getNewInstance();
    item.unset('thumbnail');
    const thumbnailPlaceholder = 'Drop or select thumbnail...';
    this.setState({ item, thumbnailPlaceholder });
  };

  onTypeChanged = ({ value }) => {
    const { item } = this.state;
    const freshItem = item.newInstance();
    freshItem.set('fileType', value);
    const errors = validationErrors({ item: freshItem });
    this.setState({ item: freshItem, errors });
  };

  onNameChanged = (value) => {
    if (value.indexOf('/') > -1) {
      return;
    }
    const item = this.getNewInstance();
    const folders = item.get('displayName').split('/');
    folders.pop();
    if (folders.length) {
      const displayName = folders.join('/');
      item.set('displayName', `${displayName}/${value}`);
    } else {
      item.set('displayName', value);
    }
    const errors = validationErrors({ item });
    this.setState({ item, errors });
  };

  onURLChanged = (value) => {
    const item = this.getNewInstance();
    item.set('url', value);
    const errors = validationErrors({ item });
    this.setState({ item, errors });
  };

  onIngageURLChanged = (value) => {
    const item = this.getNewInstance();
    let deepLinkId = value;
    if (value.indexOf('ingage://applet/') === 0) {
      // eslint-disable-next-line prefer-destructuring
      deepLinkId = value.split('/')[3];
    }
    if (deepLinkId) {
      item.set(
        'url',
        `ingage://applet/${deepLinkId}/?callback-url=%app_url%://`,
      );
    } else {
      item.set('url', '');
    }
    const errors = validationErrors({ item });
    this.setState({ item, errors });
  };

  onIncludedOfficesChanged = (value) => {
    const item = this.getNewInstance();
    item.includedOffices = value.map((id) => {
      const office = new Parse.Object('Office');
      office.id = id;
      return office.toPointer();
    });
    const errors = validationErrors({ item });
    this.setState({ item, errors });
  };

  onAddTab = () => {
    const item = this.getNewInstance();
    const buttons = item.get('buttons') || [];
    buttons.unshift({
      pageNumber: '',
      title: '',
    });
    item.set('buttons', buttons);
    const errors = validationErrors({ item });
    this.setState({ item, errors });
  };

  onTabChange = (value, index, key) => {
    const item = this.getNewInstance();
    const buttons = item.get('buttons') || [];
    item.set(
      'buttons',
      buttons.map((button, i) => {
        if (i === index) {
          return {
            ...button,
            [key]: value,
          };
        }
        return button;
      }),
    );
    const errors = validationErrors({ item });
    this.setState({ item, errors });
  };

  onDeleteTab = (index) => {
    const item = this.getNewInstance();
    const buttons = item.get('buttons');
    buttons.splice(index, 1);
    item.set('buttons', buttons);
    const errors = validationErrors({ item });
    this.setState({ item, errors });
  };

  onSaveClicked = async () => {
    const {
      showErrorAlert,
      hideAlert,
      pushToDataLayer,
      showLoader,
      hideLoader,
    } = this.props;
    const { errors, item } = this.state;
    if (checkForErrors(errors)) {
      showErrorAlert({
        title: 'Error',
        message: 'Please fix all validation errors',
        onConfirm: hideAlert,
      });
      return;
    }
    try {
      const { id } = item;
      if (id) {
        pushToDataLayer({
          event: 'resourceEvent',
          eventCategory: 'Resources',
          eventAction: 'Edit',
          eventLabel: item.get('displayName') || '',
        });
      } else {
        pushToDataLayer({
          event: 'resourceEvent',
          eventCategory: 'Resources',
          eventAction: 'Create',
          eventLabel: item.get('displayName') || '',
        });
      }
      showLoader('... saving resource');
      await item.save(null, undefined, (progress) => {
        this.setState({ progress });
      });
      history.push('/resources');
    } catch (e) {
      showErrorAlert({
        title: 'Error',
        message: e.message,
        onConfirm: hideAlert,
      });
    } finally {
      hideLoader();
    }
  };

  onBreadCrumbClicked = (index) => {
    const { setResourcesDirectory } = this.props;
    const { item } = this.state;
    const directories = item.get('displayName').split('/');
    if (index === directories.length) {
      return;
    }
    const updatedDirectories = [];
    for (let i = 0; i < index; i += 1) {
      updatedDirectories.push(directories[i]);
    }
    const directory = updatedDirectories.join('/');
    setResourcesDirectory(directory);
    history.push('/resources');
  };

  onPDFLoadSuccess = (numPages) => {
    const item = this.getNewInstance();
    item.set('pageNumber', numPages);
    this.setState(() => ({ item }));
  };

  onImageError = () => {
    this.setState({ imgError: true });
  };

  render() {
    const { maxOfficeCount } = this.props;
    const {
      errors,
      filePlaceholder,
      imgError,
      item,
      progress,
      thumbnailPlaceholder,
    } = this.state;

    const buttons = item.get('buttons') || [];
    const displayName = item.get('displayName') || '';
    const includedOffices = item.includedOffices || [];
    const url = item.get('url') || '';

    const pageNumbers = [];
    if (item.get('fileType') === 'pdf') {
      for (let i = 0; i < item.get('pageNumber'); i += 1) {
        pageNumbers.push({
          value: i + 1,
          label: i + 1,
        });
      }
    }
    return (
      <>
        <div className="d-none">
          <Document
            file={item.has('file') ? item.get('file').url() : ''}
            onLoadSuccess={({ numPages }) => this.onPDFLoadSuccess(numPages)}
            noData={<h4>Please select a file</h4>}
          >
            <Page pageNumber={1} />
          </Document>
        </div>
        <div className="default-page-padding ">
          <ProgressBar
            value={progress}
            className={progress ? 'd-block' : 'd-none'}
          />
          <br />
          <FormGroup title="Thumbnail">
            <Dropzone
              onDrop={(e) => {
                if (e[0]) {
                  this.onThumbnailChanged(e[0]);
                }
              }}
              accept="image/*"
            >
              {({ getRootProps, getInputProps, isDragActive }) => (
                <div
                  {...getRootProps()}
                  style={{ minHeight: '255px', cursor: 'pointer' }}
                >
                  <input {...getInputProps()} />
                  <Well
                    bsSize="lg"
                    style={{
                      minHeight: '255px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {item.has('thumbnail') ? (
                      <>
                        <img
                          alt="Thumbnail"
                          src={item.get('thumbnail').url()}
                          className={`resources-thumbnail ${
                            imgError ? 'd-none' : 'd-block'
                          }`}
                          onError={this.onImageError}
                        />
                        <div
                          className={`h-100 w-100 text-center ${
                            imgError ? 'd-block' : 'd-none'
                          }`}
                        >
                          <i
                            style={{ fontSize: '10rem' }}
                            className="fas fa-camera-retro text-muted"
                          />
                          <br />
                          Couldn&apos;t load thumbnail image
                          <br />
                          Click to upload new thumbnail
                        </div>
                      </>
                    ) : (
                      <Button
                        text={
                          isDragActive
                            ? 'Drop file here...'
                            : thumbnailPlaceholder
                        }
                      />
                    )}
                  </Well>
                </div>
              )}
            </Dropzone>
            {item.has('thumbnail') ? (
              <button
                onClick={this.onRemoveThumbnail}
                type="button"
                className="clear-button"
              >
                <i className="fas fa-times-circle danger" />
              </button>
            ) : (
              <div />
            )}
          </FormGroup>
          <DropDownGroup
            title="Resource Type"
            value={
              fileTypeForDropDown(item.get('fileType')) || fileTypes[0].value
            }
            onChange={this.onTypeChanged}
            closeMenuOnSelect
            options={fileTypes}
            isClearable={false}
            errorMessage={errors.item.fileType}
          />
          <TextGroup
            title="Name"
            value={displayName.split('/').pop()}
            placeholder="Name"
            onChange={this.onNameChanged}
            errorMessage={errors.item.displayName}
          />
          {maxOfficeCount !== 1 && (
            <FormGroup title="Included Offices">
              <OfficesDropDown
                onChange={this.onIncludedOfficesChanged}
                selected={includedOffices.map(
                  ({ id, objectId }) => id || objectId,
                )}
              />
            </FormGroup>
          )}
          <div>
            {item.get('fileType') !== 'url' &&
              item.get('fileType') !== 'folder' &&
              item.get('fileType') !== 'deeplink' && (
                <FormGroup title="File">
                  <Dropzone
                    onDropRejected={() => this.onInvalidFileType()}
                    onDrop={(e) => {
                      if (e[0]) {
                        this.onFileChanged(e[0]);
                      }
                    }}
                    accept={acceptedString}
                  >
                    {({ getRootProps, isDragActive }) => (
                      <div {...getRootProps()}>
                        <FileInput
                          text={
                            isDragActive ? 'Drop file here...' : filePlaceholder
                          }
                          onChange={(e) =>
                            this.onFileChanged(e.target.files[0])
                          }
                          inputProps={{
                            accept: acceptedString,
                          }}
                          fill
                          large
                        />
                      </div>
                    )}
                  </Dropzone>
                </FormGroup>
              )}
          </div>
          <div>
            {item.get('fileType') === 'url' && (
              <TextGroup
                title="URL"
                value={
                  url.indexOf('ingage://applet/') === 0 ? '' : item.get('url')
                }
                placeholder="URL"
                onChange={this.onURLChanged}
                errorMessage={errors.item.url}
              />
            )}
          </div>
          {item.get('fileType') === 'deeplink' && (
            <TextGroup
              title="Ingage Deep Link Id"
              value={
                url.indexOf('ingage://applet/') === 0
                  ? url.split('/')[3] || ''
                  : ''
              }
              placeholder="ingage://applet/<Deep Link Id>"
              onChange={this.onIngageURLChanged}
              errorMessage={errors.item.url}
            />
          )}
          <div>
            {item.get('fileType') === 'pdf' && (
              <FormGroup title="Tabs">
                <Table bordered hover className="resources-tabs-table">
                  <thead>
                    <tr>
                      <th style={{ textAlign: 'center', width: '80px' }}>
                        Pg #
                      </th>
                      <th style={{ textAlign: 'center' }}>Tab Title</th>
                      <th style={{ textAlign: 'center', width: '25px' }}>
                        <button onClick={this.onAddTab} type="button">
                          Add
                        </button>
                      </th>
                    </tr>
                  </thead>
                </Table>
                <Droppable droppableId={droppableId} direction="vertical">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {buttons.map(({ pageNumber, title }, index) => (
                        <Draggable
                          draggableId={`button-${index}`}
                          index={index}
                          key={index}
                        >
                          {(draggableProvided, snapshot) => (
                            <div
                              // eslint-disable-next-line react/jsx-indent-props
                              ref={draggableProvided.innerRef}
                              // eslint-disable-next-line react/jsx-indent-props
                              {...draggableProvided.draggableProps}
                              // eslint-disable-next-line react/jsx-indent-props
                              {...draggableProvided.dragHandleProps}
                              // eslint-disable-next-line react/jsx-closing-bracket-location
                            >
                              <Table
                                bordered
                                hover
                                className="resources-tabs-table"
                              >
                                <tbody>
                                  <tr
                                    style={{
                                      backgroundColor: snapshot.isDragging
                                        ? 'white'
                                        : 'transparent',
                                    }}
                                  >
                                    <td style={{ width: '80px' }}>
                                      <div
                                        className={
                                          errors.item.buttons[index].pageNumber
                                            ? 'error-border'
                                            : ''
                                        }
                                      >
                                        <Select
                                          name="form-field-name"
                                          value={{
                                            value: pageNumber,
                                            label: pageNumber,
                                          }}
                                          onChange={({ value }) =>
                                            this.onTabChange(
                                              value,
                                              index,
                                              'pageNumber',
                                            )
                                          }
                                          closeMenuOnSelect
                                          isClearable={false}
                                          options={pageNumbers}
                                          noOptionsMessage="No file uploaded"
                                          placeholder=""
                                        />
                                      </div>
                                      <div className="error">
                                        {errors.item.buttons[index].pageNumber}
                                      </div>
                                    </td>
                                    <td>
                                      <input
                                        value={title}
                                        onChange={(e) =>
                                          this.onTabChange(
                                            e.target.value,
                                            index,
                                            'title',
                                          )
                                        }
                                        className={`resources__tab-title${
                                          errors.item.buttons[index].title
                                            ? ' error-border'
                                            : ''
                                        }`}
                                      />
                                      <div className="error">
                                        {errors.item.buttons[index].title}
                                      </div>
                                    </td>
                                    <td style={{ width: '54px' }}>
                                      <Icon
                                        icon="trash"
                                        intent="danger"
                                        onClick={() => this.onDeleteTab(index)}
                                        style={{ cursor: 'pointer' }}
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    </div>
                  )}
                </Droppable>
              </FormGroup>
            )}
          </div>
        </div>
      </>
    );
  }
}

Edit.propTypes = {
  startSaveResourceNoUpload: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  }).isRequired,
  directory: PropTypes.string,
  showErrorAlert: PropTypes.func.isRequired,
  hideAlert: PropTypes.func.isRequired,
  setResourcesDirectory: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  maxOfficeCount: PropTypes.number.isRequired,
  ...layoutContextPropTypes,
};

Edit.defaultProps = {
  directory: '',
};

const TitleButtons = ({ onSaveClicked }) => (
  <div>
    <TitleButton variant="success" onClick={onSaveClicked} title="Save" />
  </div>
);

TitleButtons.propTypes = {
  onSaveClicked: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, resources: { directory }, plan = {} }) => ({
  offices: auth.offices || [],
  directory,
  maxOfficeCount: plan.maxOfficeCount,
});

const mapDispatchToProps = (dispatch) => ({
  showLoader: (params) => dispatch(showLoaderAction(params)),
  hideLoader: (params) => dispatch(hideLoaderAction(params)),
  startSaveResourceNoUpload: (name, object) =>
    dispatch(startSaveResourceNoUploadAction(name, object)),
  showErrorAlert: (params) => dispatch(showErrorAlertAction(params)),
  hideAlert: () => dispatch(hideAlertAction()),
  setResourcesDirectory: (directory) =>
    dispatch(setResourcesDirectoryAction(directory)),
  handleError: (error) => dispatch(handleErrorAction(error)),
  pushToDataLayer: (variablesForLayer) =>
    dispatch(pushToDataLayerAction(variablesForLayer)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(Edit),
);
