import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { DragDropContext } from 'react-beautiful-dnd';
import { setDraggableType, setIsDragging } from '../../actions/templateEdit';

let lastDestination;

const onDragEndCallbacks = {};
export const setOnDragEndCallback = (identifier, callback) => {
  onDragEndCallbacks[identifier] = callback;
};

const DropContext = ({ children }) => {
  const dispatch = useDispatch();
  const [setDraggableTypeAction, setIsDraggingAction] = [
    (type) => dispatch(setDraggableType(type)),
    (dragging) => dispatch(setIsDragging(dragging)),
  ];

  return (
    <DragDropContext
      onDragUpdate={(result, provided) => {
        if (result.destination && result.destination != null) {
          lastDestination = {
            ...result.destination,
          };
        }
      }}
      onDragEnd={(result, provided) => {
        const { reason, destination = {}, combine } = result;
        const droppableId = combine
          ? combine.droppableId
          : destination && destination.droppableId;
        if (reason !== 'DROP' || !droppableId) {
          return;
        }
        const callback = onDragEndCallbacks[droppableId];
        if (callback) {
          callback({
            ...result,
            destination:
              result.destination && result.destination != null
                ? result.destination
                : lastDestination,
          });
        }
        setIsDraggingAction(false);
      }}
      onDragStart={(result) => {
        setIsDraggingAction(true);
        const { droppableId } = result.source;
        const type = droppableId.split('-').pop();
        setDraggableTypeAction(type);
      }}
    >
      {children}
    </DragDropContext>
  );
};

DropContext.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default DropContext;
