// It will show the widget and hide the main widget.
export function showWidget({ widgetSelector, mainWidgetSelector }) {
  // Get the main widget and the widget elements
  const mainWidget = document.querySelector(mainWidgetSelector);
  const widget = document.querySelector(widgetSelector);

  // If the main widget and the widget exist
  if (mainWidget && widget) {
    // Show the widget
    widget.style.display = 'block';

    // This will trigger the cobrowse dialog to open
    if (widget.id === 'auvious-widget') {
      widget.launch('cobrowse', '');
    }

    // This will trigger the chat dialog to open
    if (widget?.id === 'tdWebchat') {
      window.webChat.selfHostedApp.open();
    }

    // Hide the main widget
    mainWidget.style.display = 'none';
  }
}

// It will hide the widget and show the main widget.
export function hideWidget({ widgetSelector, mainWidgetSelector }) {
  // Get the main widget and the widget
  const mainWidget = document.querySelector(mainWidgetSelector);

  // Get the widget
  const widget = document.querySelector(widgetSelector);

  // If the main widget and the widget exist
  if (mainWidget && widget) {
    // Show the main widget
    mainWidget.style.display = 'flex';

    // Hide the cobrowse widget
    if (widget.id === 'auvious-widget') {
      widget.style.display = 'none';
    }

    // Hide the chat widget
    if (widget.id === 'tdWebchat') {
      widget.style.display = 'none';
    }
  }
}

// It will set the z-index of the elements with the given selector.
export function handleElementsStyles(elementSelector, styles) {
  const elements = document.querySelectorAll(elementSelector);

  // Set the styles after 100ms
  // This is because the parent element immediately changes the z-index
  // But the child element does not change the z-index immediately due to transition
  setTimeout(() => {
    elements.forEach((element) => {
      Object.assign(element.style, styles);
    });
  }, 100);
}
