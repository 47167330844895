// @ts-check
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { fromEvent } from 'rxjs';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import './styles.scss';

/**
 * Store selector to get the company logo url from config
 * @param {import('../../../reducers').State} state
 */
const getCompanyLogo = ({
  auth: { config: { companyLogo: { url = '' } = {} } = {} } = {},
} = {}) => url;

/** @param {{emailPreview: string, show:boolean, handleClose: () => void}} props */
const EmailPreview = ({ emailPreview, show, handleClose }) => {
  const bodyRef = useRef();
  const [width, setWidth] = useState(800);
  const [height, setHeight] = useState(600);
  const [parsedPreview, setParsedPreview] = useState(emailPreview || '');
  const companyLogo = useSelector(getCompanyLogo);

  // Intercept and replace some universal placeholders with known values
  useEffect(() => {
    const parsed = emailPreview.split('%companyLogo%').join(companyLogo);
    setParsedPreview(parsed);
  }, [emailPreview, companyLogo]);

  // Observer window size, set the body of the modal to 80% of window size
  useEffect(() => {
    const setSize = () => {
      if (bodyRef.current) {
        /**
         * @type {{ clientHeight: number, clientWidth: number}}
         */
        const { clientWidth = 200 } = bodyRef.current;
        const clientHeight = window.innerHeight * 0.8;
        setWidth(clientWidth);
        setHeight(clientHeight);
      }
    };
    const winSize$ = fromEvent(window, 'resize');
    const sub = winSize$.subscribe(setSize);

    setSize();

    return () => sub.unsubscribe();
  }, [show, bodyRef]);

  return (
    <Modal show={show} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Email Preview</Modal.Title>
      </Modal.Header>
      <Modal.Body ref={bodyRef} className="m-0 p-0">
        <iframe
          className="border-0 preview-iframe"
          height={height}
          sandbox=""
          security="restricted"
          srcDoc={parsedPreview}
          title="Email Preview"
          width={width}
        />
      </Modal.Body>
    </Modal>
  );
};

EmailPreview.propTypes = {
  emailPreview: PropTypes.string,
  show: PropTypes.bool,
  handleClose: PropTypes.func,
};
EmailPreview.defaultProps = {
  emailPreview: '',
  show: false,
  handleClose: () => {},
};

export default EmailPreview;
