export const actionIcons = [
  {
    name: 'Report',
    resendKey: 'reportUpload',
    documentKey: 'reportPDF',
    popIcon: '/images/report.svg',
  },
  {
    name: 'Contract',
    resendKey: 'contractUpload',
    documentKey: 'contractPDF',
    popIcon: '/images/contract.svg',
  },
  {
    name: 'Contract Acceptance',
    resendKey: 'contractVerifyUpload',
    documentKey: 'contractVerifyPDF',
    popIcon: '/images/contract-acceptance.svg',
  },
  {
    name: 'Proposal',
    resendKey: 'proposalUpload',
    documentKey: 'proposalPDF',
    popIcon: '/images/proposal.svg',
  },
  {
    name: 'Proposal Acceptance',
    resendKey: 'proposalVerifyUpload',
    documentKey: 'proposalVerifyPDF',
    popIcon: '/images/proposal-acceptance.svg',
  },
  {
    name: 'Results',
    resendKey: 'resultsUpload',
    documentKey: 'resultPDF',
    popIcon: '/images/results.svg',
  },
  {
    name: 'Credit App',
    resendKey: 'creditAppUpload',
    documentKey: 'creditAppPDF',
    popIcon: '/images/credit-app.svg',
  },
];

export const alwaysIcons = [
  {
    name: 'Log',
    resendKey: '',
    iconClass: 'far fa-file-alt',
  },
];
