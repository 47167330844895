/* eslint-disable import/no-named-as-default */
import React from 'react';
import { Route } from 'react-router-dom';
import AuthorizedRoute from './AuthorizedRoute';
import CompanyRoute from './CompanyRoute';
import { randomKey } from '../utils/utils';

import Account from '../components/Account/InfoBilling';
import AdminSharedResources from '../components/CorporateAdmin/SharedResources';
import Amerifirst from '../components/Integrations/Amerifirst';
import APIAccess from '../components/AppSettings/APIAccess';
import AppAppearance from '../components/AppSettings/AppAppearance';
import Appointments from '../components/AppSettings/Appointments';
import AvailableContacts from '../components/AppSettings/AvailableContacts';
import Categories from '../components/AppSettings/Categories';
import ChangeCompanyPage from '../components/CorporateAdmin/ChangeCompanyPage';
import CheckBlackList from '../components/CorporateAdmin/CheckBlackList';
import CommissionFormulas from '../components/AppSettings/CommissionFormulas';
import CompanyLogo from '../components/AppSettings/CompanyLogo';
import ContractSending from '../components/AppSettings/ContractSending';
import CopyDataPage from '../components/CorporateAdmin/CopyDataPage';
import CopyPriceGuidePage from '../components/CorporateAdmin/CopyPriceGuidePage';
import CopyTemplatesPage from '../components/CorporateAdmin/CopyTemplatesPage';
import CorporateAdminChangeLogView from '../components/CorporateAdmin/CorporateAdminChangeLogView';
import CreditAppSending from '../components/AppSettings/ContractSending/CreditAppSending';
import CustomerCreditApp from '../components/CustomerCreditApp';
import CompletedCreditApp from '../components/CompletedCreditApp';
import CreditAppSendingSettings from '../components/AppSettings/CreditAppSending';
import CustomOptions from '../components/AppSettings/ContractSending/CustomOptions';
import CustomOptionEdit from '../components/AppSettings/ContractSending/CustomOptions/CustomOptionEdit';
import DividendFinance from '../components/Integrations/DividendFinance';
import PowerPay from '../components/Integrations/PowerPay';
import Sunlight from '../components/Integrations/Sunlight';
import LendKey from '../components/Integrations/LendKey';
import Mosaic from '../components/Integrations/Mosaic';
import DocuSign from '../components/Integrations/DocuSign';
import EmailLinks from '../components/AppSettings/EmailLinks';
import EmailsPage from '../components/EmailsPage';
import EmailUsageReportPage from '../components/CorporateAdmin/EmailUsageReportPage';
import EnerBank from '../components/Integrations/EnerBank';
import EstimatesDetails from '../components/EstimateDetails';
import EstimatesDetailsPage from '../components/EstimateDetails/EstimateDetailsPage';
import EstimatesPage from '../components/EstimatesPage';
import FinanceOptions from '../components/FinanceOptions';
import Fortiva from '../components/Integrations/Fortiva';
import Foundation from '../components/Integrations/Foundation';
import GreenSky from '../components/Integrations/GreenSky';
import GroupMe from '../components/Integrations/GroupMe/GroupMe';
import Hover from '../components/Integrations/Hover';
import JobNimbus from '../components/Integrations/JobNimbus';
import JobProgress from '../components/Integrations/JobProgress';
import LeadPerfection from '../components/Integrations/LeadPerfection';
import LeapAnalytics from '../components/LeapAnalytics';
import Marketsharp from '../components/Integrations/Marketsharp';
import Masquerade from '../components/CorporateAdmin/Masquerade';
import NewAccountRoute from '../components/NewAccount';
import NewCompanyPage from '../components/CorporateAdmin/NewCompanyPage';
import NewUserDefaults from '../components/AppSettings/NewUserDefaults';
import PaymentCapture from '../components/Integrations/PaymentCapture';
import Placehoders from '../components/AppSettings/Placeholders';
import PriceFormulas from '../components/AppSettings/PriceFormulas';
import PriceGuide from '../components/PriceGuide';
import ProVia from '../components/Integrations/ProVia';
import PublicChangeLogView from '../components/PublicChangeLogView';
import ReportSending from '../components/AppSettings/ContractSending/ReportSending';
import Resources from '../components/Resources';
import ResultsSending from '../components/AppSettings/ContractSending/ResultsSending';
import Salesforce from '../components/Integrations/Salesforce';
import ServiceFinance from '../components/Integrations/ServiceFinance';
import PureFinance from '../components/Integrations/PureFinance';
import Watermark from '../components/AppSettings/Watermark';
import Packages from '../components/Packages';
import Templates from '../components/Templates';
import Offices from '../components/Offices';
import Users from '../components/Users';
import Permissions from '../components/Permissions';
import CreditApplications from '../components/CreditApplications';
import LoginPage from '../components/LoginPage';
import NotFoundPage from '../components/NotFoundPage';
import OnboardingStatusReport from '../components/OnboardingStatusReport';
import RoofScope from '../components/Integrations/RoofScope';
import CompanyCam from '../components/Integrations/CompanyCam';
import GAFQuickMeasure from '../components/Integrations/GAFQuickMeasure';
import AquaFinance from '../components/Integrations/AquaFinance';
import SubmitPaymentRequest from '../components/SubmitPaymentRequest';
import ZapierPanel from '../components/ZapierPanel';
import EventLog from '../components/EventLog';

const ContractSendingComponent = () => (
  <ContractSending title="Contract Sending" settingsKey="contractUpload" />
);

const ProposalSendingComponent = () => (
  <ContractSending title="Proposal Sending" settingsKey="proposalUpload" />
);

const routes = [
  <AuthorizedRoute
    exact
    key={randomKey(8)}
    path={['/', '/index', '/app_appearance']}
    component={AppAppearance}
  />,
  <AuthorizedRoute key={randomKey(8)} path="/account" component={Account} />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/admin-change-logs"
    component={CorporateAdminChangeLogView}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/amerifirst"
    component={Amerifirst}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/api_access"
    component={APIAccess}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/appointments"
    component={Appointments}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/available_contacts"
    component={AvailableContacts}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/categories"
    component={Categories}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/change_company"
    component={ChangeCompanyPage}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/change-logs"
    component={PublicChangeLogView}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/check_blacklist"
    component={CheckBlackList}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/commission_formulas"
    component={CommissionFormulas}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/company_logo"
    component={CompanyLogo}
  />,
  <AuthorizedRoute
    key="/contract_sending"
    path="/contract_sending"
    component={ContractSendingComponent}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/copy_data"
    component={CopyDataPage}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/copy_price_guide"
    component={CopyPriceGuidePage}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/copy_templates"
    component={CopyTemplatesPage}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/credit_app_upload"
    component={CreditAppSending}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/credit_app_sending"
    component={CreditAppSendingSettings}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/credit_applications"
    component={CreditApplications}
  />,
  <AuthorizedRoute
    path="/custom_documents/edit/:id"
    component={CustomOptionEdit}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/custom_options"
    component={CustomOptions}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/dividend"
    component={DividendFinance}
  />,
  <AuthorizedRoute key={randomKey(8)} path="/powerPay" component={PowerPay} />,
  <AuthorizedRoute key={randomKey(8)} path="/docusign" component={DocuSign} />,
  <AuthorizedRoute key={randomKey(8)} path="/sunlight" component={Sunlight} />,
  <AuthorizedRoute key={randomKey(8)} path="/lendkey" component={LendKey} />,
  <AuthorizedRoute key={randomKey(8)} path="/mosaic" component={Mosaic} />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/email_links"
    component={EmailLinks}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/email_usage_report"
    component={EmailUsageReportPage}
  />,
  <AuthorizedRoute key={randomKey(8)} path="/emails" component={EmailsPage} />,
  <AuthorizedRoute key={randomKey(8)} path="/enerBank" component={EnerBank} />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/estimate_details"
    component={EstimatesDetails}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path={['/estimates_page', '/estimates', '/payments']}
    component={EstimatesPage}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/finance_options"
    component={FinanceOptions}
  />,
  <AuthorizedRoute key={randomKey(8)} path="/fortiva" component={Fortiva} />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/foundation"
    component={Foundation}
  />,
  <AuthorizedRoute key={randomKey(8)} path="/greensky" component={GreenSky} />,
  <AuthorizedRoute key={randomKey(8)} path="/groupme" component={GroupMe} />,
  <AuthorizedRoute key={randomKey(8)} path="/groupme1" component={GroupMe} />,
  <AuthorizedRoute key={randomKey(8)} path="/hover" component={Hover} />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/jobnimbus"
    component={JobNimbus}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/jobprogress"
    component={JobProgress}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/leadperfection"
    component={LeadPerfection}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/leap_analytics"
    component={LeapAnalytics}
  />,
  <Route key={randomKey(8)} path="/login" component={LoginPage} />,
  <Route
    key={randomKey(8)}
    path="/submitPayment/:tokenId"
    component={SubmitPaymentRequest}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/marketsharp"
    component={Marketsharp}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/masquerade"
    component={Masquerade}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/new_company"
    component={NewCompanyPage}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/new_company"
    component={NewCompanyPage}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/new_user_defaults"
    component={NewUserDefaults}
  />,
  <AuthorizedRoute key={randomKey(8)} path="/offices" component={Offices} />,
  <AuthorizedRoute key={randomKey(8)} path="/packages" component={Packages} />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/payment_capture"
    component={PaymentCapture}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/payments/:id"
    component={EstimatesDetailsPage}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/permissions"
    component={Permissions}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/placeholders"
    component={Placehoders}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/price_formulas"
    component={PriceFormulas}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/price_guide"
    component={PriceGuide}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/proposal_sending"
    component={ProposalSendingComponent}
  />,
  <AuthorizedRoute key={randomKey(8)} path="/provia" component={ProVia} />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/report_sending"
    component={ReportSending}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/resources"
    component={Resources}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/results"
    component={ResultsSending}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/salesforce"
    component={Salesforce}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/service_finance"
    component={ServiceFinance}
  />,
  <AuthorizedRoute key={randomKey(8)} path="/pure" component={PureFinance} />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/shared_resources"
    component={AdminSharedResources}
  />,
  <Route key={randomKey(8)} path="/signup" component={NewAccountRoute} />,
  <AuthorizedRoute key={randomKey(8)} path="/event-log" component={EventLog} />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/templates"
    component={Templates}
  />,
  <AuthorizedRoute key={randomKey(8)} path="/users" component={Users} />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/watermark"
    component={Watermark}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/onboarding-status-report"
    component={OnboardingStatusReport}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/roofscope"
    component={RoofScope}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/companyCam"
    component={CompanyCam}
  />,
  <AuthorizedRoute
    key={randomKey(8)}
    path="/gaf"
    component={GAFQuickMeasure}
  />,
  <AuthorizedRoute key={randomKey(8)} path="/aqua" component={AquaFinance} />,
  <Route
    key={randomKey(8)}
    path="/customer_credit_app/:id"
    component={CustomerCreditApp}
  />,
  <Route key={randomKey(8)} path="/zapier" component={ZapierPanel} />,
  <Route
    key={randomKey(8)}
    path="/completed_credit_app/:id"
    component={CompletedCreditApp}
  />,
  <CompanyRoute key="company" path="/:company" />,
  <Route key="404" component={NotFoundPage} />,
];

export default routes;
