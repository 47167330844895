import React from 'react';
import PropTypes from 'prop-types';

class HoldButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPressed: false,
      initEvent: undefined,
    };
  }

  onMouseDown = (e) => {
    let { clientX, clientY } = e;
    const { touches } = e;
    if (touches) {
      clientX = touches[0].clientX;
      clientY = touches[0].clientY;
    }

    const event = {
      clientX,
      clientY,
      preventDefault: e.preventDefault,
    };

    const { startTimeout } = this.props;

    this.setState({ initEvent: event });
    this.clearTimeout();
    this.longPressTimeout = setTimeout(this.longPressStart, startTimeout);
  };

  onMouseOut = () => {
    this.clearTimeout();
    if (this.isCurrentlyPressed()) {
      this.setState({
        isPressed: false,
      });
    }
  };

  clearTimeout = () => {
    clearTimeout(this.longPressTimeout);
    clearInterval(this.pressInterval);
    this.longPressTimeout = undefined;
    this.pressInterval = undefined;
  };

  isCurrentlyPressed = () => {
    const { isPressed } = this.state;
    return isPressed;
  };

  longPressStart = () => {
    const {
      longPressStart,
      finite,
      pressCallback,
      pressCallbackTimeout,
    } = this.props;

    longPressStart();
    // When inifite call the timeout for regular period
    if (!finite) {
      pressCallback();
      this.pressInterval = setInterval(pressCallback, pressCallbackTimeout);
    } else if (finite) {
      this.pressInterval = setTimeout(this.longPressEnd, pressCallbackTimeout);
    }

    this.setState({
      isPressed: true,
    });
  };

  longPressEnd = () => {
    this.onMouseOut();
    const { longPressEnd } = this.props;
    const { initEvent } = this.state;
    longPressEnd(initEvent);
    this.setState({ initEvent: undefined });
  };

  render() {
    const { children, className } = this.props;

    return (
      <span
        className={className}
        onTouchCancel={this.onMouseOut}
        onTouchStart={this.onMouseDown}
        onTouchEnd={this.onMouseOut}
      >
        {children}
      </span>
    );
  }
}

HoldButton.defaultProps = {
  startTimeout: 300,
  longPressStart: () => {},
  longPressEnd: () => {},
  pressCallbackTimeout: 300,
  pressCallback: undefined,
  finite: true,
  className: '',
};

HoldButton.propTypes = {
  startTimeout: PropTypes.number,
  longPressStart: PropTypes.func,
  longPressEnd: PropTypes.func,
  pressCallbackTimeout: PropTypes.number,
  pressCallback: PropTypes.func,
  finite: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default HoldButton;
