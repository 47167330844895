import React from 'react';
import PropTypes from 'prop-types';
import { Alignment, Navbar, NavbarGroup } from 'leap-menu-item';
import FileButton from './FileButton';
import EnableSendButton from './EnableSendButton';
import CreditApplicationObject from './Models/CreditApplicationObject';

const toolbarStyle = {
  position: 'absolute',
};

const Toolbar = ({ application }) => (
  <Navbar style={toolbarStyle}>
    <NavbarGroup align={Alignment.LEFT}>
      <FileButton application={application} />
      <EnableSendButton />
    </NavbarGroup>
  </Navbar>
);

Toolbar.propTypes = {
  application: PropTypes.instanceOf(CreditApplicationObject),
};

Toolbar.defaultProps = {
  application: undefined,
};

export default Toolbar;
