/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';

const defaultStartDate = moment()
  .subtract(30, 'days')
  .startOf('day');
const defaultEndDate = moment().endOf('day');

const FilterSectionDateRangeInput = (props) => {
  const { startDate = defaultStartDate, endDate = defaultEndDate } = props;

  const [calendarFocused, setCalendarFocused] = useState(null);

  return (
    <div className="flex-1 filter-section-input-row d-flex m-3 align-items-center">
      <div className="filter-section-dropdown-title">
        <span className="list-line-action-item">{props.title}</span>
      </div>
      <div className="flex-1 filter-section-dropdown-element-container">
        <DateRangePicker
          startDate={startDate}
          startDateId="start_date"
          endDate={endDate}
          endDateId="end_date"
          onDatesChange={props.onDatesChange}
          focusedInput={calendarFocused}
          onFocusChange={setCalendarFocused}
          noBorder
          hideKeyboardShortcutsPanel
          numberOfMonths={1}
          isOutsideRange={props.isOutsideRange}
        />
      </div>
    </div>
  );
};

export default FilterSectionDateRangeInput;
