import { createGlobalStyle } from 'styled-components';
// TODO: if we are hard coding global styles in code, why pull styles from the
// server? Pick between either pulling limited style information, such as
// primaryColor, as an object, or full styles (preferably the first)
const GlobalStyle = createGlobalStyle`
  .btn-success {
    color: black !important;
    background-color:  ${(props) =>
      props.partnerTag === 'ProSell'
        ? 'rgb(230, 41, 153)'
        : '#26d07c'} !important;
    border-color:  ${(props) =>
      props.partnerTag === 'ProSell'
        ? 'rgb(230, 41, 153)'
        : '#26d07c'} !important;
  }

  .btn-success:hover {
    color: black !important;
    background-color:  ${(props) =>
      props.partnerTag === 'ProSell'
        ? 'rgb(230, 41, 153)'
        : '#26d07c'} !important;
    border-color:  ${(props) =>
      props.partnerTag === 'ProSell'
        ? 'rgb(230, 41, 153)'
        : '#26d07c'} !important;
  }

  .btn-light:hover {
    color: black !important;
    background-color: ${(props) =>
      props.partnerTag === 'ProSell'
        ? 'rgb(230, 41, 153)'
        : '#91f15f'} !important;
    border-color: ${(props) =>
      props.partnerTag === 'ProSell'
        ? 'rgb(230, 41, 153)'
        : '#91f15f'} !important; 
  }

  .user-line-symbol {
    color: ${(props) =>
      props.partnerTag === 'ProSell'
        ? 'rgb(230, 41, 153)'
        : '#26d07c'} !important;
  }

  .section-title::after {
    background-color: ${(props) =>
      props.partnerTag === 'ProSell'
        ? 'rgb(230, 41, 153)'
        : '#26d07c'} !important;
  }

  .theme-background-color{
    background-color: ${(props) =>
      props.partnerTag === 'ProSell'
        ? 'rgb(230, 41, 153)'
        : '#26d07c'} !important;
  }

  button {
    :focus {
      outline: none;

    }
  }

`;

export default GlobalStyle;
