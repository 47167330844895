export const defaultState = {
  items: [],
  text: '',
  count: 0,
  page: 1,
  limit: 10,
  isActiveFilter: 0,
};

export default (
  state = defaultState,
  {
    type,
    items,
    text = '',
    count = 0,
    page = 0,
    limit = 10,
    isActiveFilter = 0,
    user,
    userToEdit,
  },
) => {
  switch (type) {
    case 'SET_USERS':
      return {
        ...state,
        items,
      };
    case 'ADD_USERS':
      return {
        ...state,
        items: [...state.items, ...items],
      };
    case 'SET_USERS_SEARCH_TEXT':
      return {
        ...state,
        searchText: text,
      };
    case 'SET_USERS_COUNT':
      return {
        ...state,
        count,
      };
    case 'SET_USERS_PAGINATION':
      return {
        ...state,
        page,
        limit,
      };
    case 'SET_USERS_ACTIVE_FILTER':
      return {
        ...state,
        isActiveFilter,
      };
    case 'UPDATE_USER':
      return {
        ...state,
        items: state.items.map((object) => {
          if (object.objectId === user.objectId) {
            return user;
          }
          return object;
        }),
      };
    case 'SET_USER_TO_EDIT':
      return {
        ...state,
        userToEdit,
      };
    case 'RESET_USERS':
      return {
        ...defaultState,
      };
    default:
      return state;
  }
};
