/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
/* eslint-disable import/no-cycle */
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import FilterSectionDropRow from '../SharedComponents/FilterSectionDropRow';
import FilterSectionInputRow from '../SharedComponents/FilterSectionInputRow';
import FilterSectionDateRangeInput from '../SharedComponents/FilterSectionDateRangeInput';
import {
  getEstimates,
  setEstimatesDateFilter,
  setEstimatesFilter,
} from '../../actions/estimatesPage';

const onDatesChange = (dateChangeEvent, props) => {
  const { startDate, endDate } = props;
  props.setEstimatesDateFilter({
    startDate:
      dateChangeEvent.startDate && dateChangeEvent.startDate != null
        ? dateChangeEvent.startDate
        : startDate,
    endDate:
      dateChangeEvent.endDate && dateChangeEvent.endDate != null
        ? dateChangeEvent.endDate
        : endDate,
  });
};

const EsitmatesFilterSection = (props) => {
  const {
    startDate,
    endDate,
    customerFilter,
    userFilter,
    selectedOffices,
  } = props;

  return (
    <div className="filter-section my-3">
      <div className="filter-section-dropdowns row">
        <div className="col-md-6 filter-section-dropdowns-half">
          <FilterSectionInputRow
            onEnterPress={(event) => {
              props.getEstimates({});
            }}
            title="Customer"
            value={customerFilter}
            onChange={(event) => {
              props.setEstimatesFilter({ customerFilter: event.target.value });
            }}
          />
          <FilterSectionDateRangeInput
            isOutsideRange={(date) =>
              date.valueOf() >=
              moment()
                .add(1, 'days')
                .endOf('day')
                .valueOf()
            }
            title="Date"
            startDate={startDate}
            endDate={endDate}
            onDatesChange={(dateChangeEvent) =>
              onDatesChange(dateChangeEvent, props)
            }
          />
        </div>
        <div className="col-md-6 filter-section-dropdowns-half">
          <FilterSectionDropRow
            placeholder="Select a User"
            options={props.mappedOptions}
            selected={userFilter}
            onSelectedChanged={(selected) => {
              props.setEstimatesFilter({ userFilter: [...selected] });
            }}
            title="User"
          />
          {props.maxOfficeCount !== 1 && (
            <FilterSectionDropRow
              placeholder="Select a Office"
              options={props.offices}
              selected={selectedOffices}
              onSelectedChanged={(selected) => {
                props.setEstimatesFilter({ selectedOffices: [...selected] });
              }}
              title="Office"
            />
          )}
        </div>
      </div>
      <button
        onClick={(event) => {
          props.getEstimates({});
        }}
        className="btn btn-success m-3"
      >
        {' '}
        Search
      </button>
    </div>
  );
};

const mapStateToProps = ({
  estimatesPage,
  auth: { offices = [] },
  customers: { usersForDropDown = [] },
  plan = {},
}) => {
  const {
    startDate,
    endDate,
    userFilter,
    customerFilter,
    selectedOffices,
  } = estimatesPage;
  const mappedOptions = usersForDropDown.map((user) => ({
    label: `${user.nameFirst} ${user.nameLast}`,
    value: user.objectId,
    id: user.objectId,
  }));
  return {
    offices: offices.map((office) => ({
      label: office.get('name'),
      value: office.id,
    })),
    startDate,
    endDate,
    userFilter,
    customerFilter,
    selectedOffices,
    mappedOptions,
    maxOfficeCount: plan.maxOfficeCount,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setEstimatesDateFilter: (params) => dispatch(setEstimatesDateFilter(params)),
  setEstimatesFilter: (params) => dispatch(setEstimatesFilter(params)),
  getEstimates: (params) => dispatch(getEstimates(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EsitmatesFilterSection);
