const pasteBoard = {};

export const setPasteboardValue = (value, key) => {
  pasteBoard[key || 'default'] = value;
};

export const getPasteboardValue = (key) => pasteBoard[key || 'default'];

export const copyStringToClipboard = (str) => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style = { position: 'absolute', left: '-9999px' };
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};
