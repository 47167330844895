const icons = [
  { icon: 'fa-500px', web: 'fab fa-500px' },
  { icon: 'fa-address-book', web: 'fa fa-address-book' },
  // { icon: "fa-address-book-o", web: "fa fa-address-book-o" },
  { icon: 'fa-address-card', web: 'fa fa-address-card' },
  // { icon: "fa-address-card-o", web: "fa fa-address-card-o" },
  { icon: 'fa-adjust', web: 'fa fa-adjust' },
  { icon: 'fa-adn', web: 'fab fa-adn' },
  { icon: 'fa-align-center', web: 'fa fa-align-center' },
  { icon: 'fa-align-justify', web: 'fa fa-align-justify' },
  { icon: 'fa-align-left', web: 'fa fa-align-left' },
  { icon: 'fa-align-right', web: 'fa fa-align-right' },
  { icon: 'fa-amazon', web: 'fab fa-amazon' },
  { icon: 'fa-ambulance', web: 'fa fa-ambulance' },
  {
    icon: 'fa-american-sign-language-interpreting',
    web: 'fa fa-american-sign-language-interpreting',
  },
  { icon: 'fa-anchor', web: 'fa fa-anchor' },
  { icon: 'fa-android', web: 'fab fa-android' },
  { icon: 'fa-angellist', web: 'fab fa-angellist' },
  { icon: 'fa-angle-double-down', web: 'fa fa-angle-double-down' },
  { icon: 'fa-angle-double-left', web: 'fa fa-angle-double-left' },
  { icon: 'fa-angle-double-right', web: 'fa fa-angle-double-right' },
  { icon: 'fa-angle-double-up', web: 'fa fa-angle-double-up' },
  { icon: 'fa-angle-down', web: 'fa fa-angle-down' },
  { icon: 'fa-angle-left', web: 'fa fa-angle-left' },
  { icon: 'fa-angle-right', web: 'fa fa-angle-right' },
  { icon: 'fa-angle-up', web: 'fa fa-angle-up' },
  { icon: 'fa-apple', web: 'fab fa-apple' },
  { icon: 'fa-archive', web: 'fa fa-archive' },
  // { icon: "fa-area-chart", web: "fa fa-area-chart" },
  { icon: 'fa-arrow-circle-down', web: 'fa fa-arrow-circle-down' },
  { icon: 'fa-arrow-circle-left', web: 'fa fa-arrow-circle-left' },
  // { icon: "fa-arrow-circle-o-down", web: "fa fa-arrow-circle-o-down" },
  // { icon: "fa-arrow-circle-o-left", web: "fa fa-arrow-circle-o-left" },
  // { icon: "fa-arrow-circle-o-right", web: "fa fa-arrow-circle-o-right" },
  // { icon: "fa-arrow-circle-o-up", web: "fa fa-arrow-circle-o-up" },
  { icon: 'fa-arrow-circle-right', web: 'fa fa-arrow-circle-right' },
  { icon: 'fa-arrow-circle-up', web: 'fa fa-arrow-circle-up' },
  { icon: 'fa-arrow-down', web: 'fa fa-arrow-down' },
  { icon: 'fa-arrow-left', web: 'fa fa-arrow-left' },
  { icon: 'fa-arrow-right', web: 'fa fa-arrow-right' },
  { icon: 'fa-arrow-up', web: 'fa fa-arrow-up' },
  // { icon: "fa-arrows", web: "fa fa-arrows" },
  { icon: 'fa-arrows-alt', web: 'fa fa-arrows-alt' },
  // { icon: "fa-arrows-h", web: "fa fa-arrows-h" },
  // { icon: "fa-arrows-v", web: "fa fa-arrows-v" },
  // { icon: "fa-asl-interpreting", web: "fa fa-asl-interpreting" },
  {
    icon: 'fa-assistive-listening-systems',
    web: 'fa fa-assistive-listening-systems',
  },
  { icon: 'fa-asterisk', web: 'fa fa-asterisk' },
  { icon: 'fa-at', web: 'fa fa-at' },
  { icon: 'fa-audio-description', web: 'fa fa-audio-description' },
  // { icon: "fa-automobile", web: "fa fa-automobile" },
  { icon: 'fa-backward', web: 'fa fa-backward' },
  { icon: 'fa-balance-scale', web: 'fa fa-balance-scale' },
  { icon: 'fa-ban', web: 'fa fa-ban' },
  { icon: 'fa-bandcamp', web: 'fab fa-bandcamp' },
  // { icon: "fa-bank", web: "fa fa-bank" },
  // { icon: "fa-bar-chart", web: "fa fa-bar-chart" },
  // { icon: "fa-bar-chart-o", web: "fa fa-bar-chart-o" },
  { icon: 'fa-barcode', web: 'fa fa-barcode' },
  { icon: 'fa-bars', web: 'fa fa-bars' },
  { icon: 'fa-bath', web: 'fa fa-bath' },
  // { icon: "fa-bathtub", web: "fa fa-bathtub" },
  // { icon: "fa-battery", web: "fa fa-battery" },
  // { icon: "fa-battery-0", web: "fa fa-battery-0" },
  // { icon: "fa-battery-1", web: "fa fa-battery-1" },
  // { icon: "fa-battery-2", web: "fa fa-battery-2" },
  // { icon: "fa-battery-3", web: "fa fa-battery-3" },
  // { icon: "fa-battery-4", web: "fa fa-battery-4" },
  { icon: 'fa-battery-empty', web: 'fa fa-battery-empty' },
  { icon: 'fa-battery-full', web: 'fa fa-battery-full' },
  { icon: 'fa-battery-half', web: 'fa fa-battery-half' },
  { icon: 'fa-battery-quarter', web: 'fa fa-battery-quarter' },
  { icon: 'fa-battery-three-quarters', web: 'fa fa-battery-three-quarters' },
  { icon: 'fa-bed', web: 'fa fa-bed' },
  { icon: 'fa-beer', web: 'fa fa-beer' },
  { icon: 'fa-behance', web: 'fab fa-behance' },
  { icon: 'fa-behance-square', web: 'fab fa-behance-square' },
  { icon: 'fa-bell', web: 'fa fa-bell' },
  // { icon: "fa-bell-o", web: "fa fa-bell-o" },
  { icon: 'fa-bell-slash', web: 'fa fa-bell-slash' },
  // { icon: "fa-bell-slash-o", web: "fa fa-bell-slash-o" },
  { icon: 'fa-bicycle', web: 'fa fa-bicycle' },
  { icon: 'fa-binoculars', web: 'fa fa-binoculars' },
  { icon: 'fa-birthday-cake', web: 'fa fa-birthday-cake' },
  { icon: 'fa-bitbucket', web: 'fab fa-bitbucket' },
  // { icon: "fa-bitbucket-square", web: "fa fa-bitbucket-square" },
  { icon: 'fa-bitcoin', web: 'fab fa-bitcoin' },
  { icon: 'fa-black-tie', web: 'fab fa-black-tie' },
  { icon: 'fa-blind', web: 'fa fa-blind' },
  { icon: 'fa-bluetooth', web: 'fab fa-bluetooth' },
  { icon: 'fa-bluetooth-b', web: 'fab fa-bluetooth-b' },
  { icon: 'fa-bold', web: 'fa fa-bold' },
  { icon: 'fa-bolt', web: 'fa fa-bolt' },
  { icon: 'fa-bomb', web: 'fa fa-bomb' },
  { icon: 'fa-book', web: 'fa fa-book' },
  { icon: 'fa-bookmark', web: 'fa fa-bookmark' },
  // { icon: "fa-bookmark-o", web: "fa fa-bookmark-o" },
  { icon: 'fa-braille', web: 'fa fa-braille' },
  { icon: 'fa-briefcase', web: 'fa fa-briefcase' },
  { icon: 'fa-btc', web: 'fab fa-btc' },
  { icon: 'fa-bug', web: 'fa fa-bug' },
  { icon: 'fa-building', web: 'fa fa-building' },
  // { icon: "fa-building-o", web: "fa fa-building-o" },
  { icon: 'fa-bullhorn', web: 'fa fa-bullhorn' },
  { icon: 'fa-bullseye', web: 'fa fa-bullseye' },
  { icon: 'fa-bus', web: 'fa fa-bus' },
  { icon: 'fa-buysellads', web: 'fab fa-buysellads' },
  // { icon: "fa-cab", web: "fa fa-cab" },
  { icon: 'fa-calculator', web: 'fa fa-calculator' },
  { icon: 'fa-calendar', web: 'fa fa-calendar' },
  // { icon: "fa-calendar-check-o", web: "fa fa-calendar-check-o" },
  // { icon: "fa-calendar-minus-o", web: "fa fa-calendar-minus-o" },
  // { icon: "fa-calendar-o", web: "fa fa-calendar-o" },
  // { icon: "fa-calendar-plus-o", web: "fa fa-calendar-plus-o" },
  // { icon: "fa-calendar-times-o", web: "fa fa-calendar-times-o" },
  { icon: 'fa-camera', web: 'fa fa-camera' },
  { icon: 'fa-camera-retro', web: 'fa fa-camera-retro' },
  { icon: 'fa-car', web: 'fa fa-car' },
  { icon: 'fa-caret-down', web: 'fa fa-caret-down' },
  { icon: 'fa-caret-left', web: 'fa fa-caret-left' },
  { icon: 'fa-caret-right', web: 'fa fa-caret-right' },
  // { icon: "fa-caret-square-o-down", web: "fa fa-caret-square-o-down" },
  // { icon: "fa-caret-square-o-left", web: "fa fa-caret-square-o-left" },
  // { icon: "fa-caret-square-o-right", web: "fa fa-caret-square-o-right" },
  // { icon: "fa-caret-square-o-up", web: "fa fa-caret-square-o-up" },
  { icon: 'fa-caret-up', web: 'fa fa-caret-up' },
  { icon: 'fa-cart-arrow-down', web: 'fa fa-cart-arrow-down' },
  { icon: 'fa-cart-plus', web: 'fa fa-cart-plus' },
  // { icon: "fa-cc", web: "fa fa-cc" },
  { icon: 'fa-cc-amex', web: 'fab fa-cc-amex' },
  { icon: 'fa-cc-diners-club', web: 'fab fa-cc-diners-club' },
  { icon: 'fa-cc-discover', web: 'fab fa-cc-discover' },
  { icon: 'fa-cc-jcb', web: 'fab fa-cc-jcb' },
  { icon: 'fa-cc-mastercard', web: 'fab fa-cc-mastercard' },
  { icon: 'fa-cc-paypal', web: 'fab fa-cc-paypal' },
  { icon: 'fa-cc-stripe', web: 'fab fa-cc-stripe' },
  { icon: 'fa-cc-visa', web: 'fab fa-cc-visa' },
  { icon: 'fa-certificate', web: 'fa fa-certificate' },
  // { icon: "fa-chain", web: "fa fa-chain" },
  // { icon: "fa-chain-broken", web: "fa fa-chain-broken" },
  { icon: 'fa-check', web: 'fa fa-check' },
  { icon: 'fa-check-circle', web: 'fa fa-check-circle' },
  // { icon: "fa-check-circle-o", web: "fa fa-check-circle-o" },
  { icon: 'fa-check-square', web: 'fa fa-check-square' },
  // { icon: "fa-check-square-o", web: "fa fa-check-square-o" },
  { icon: 'fa-chevron-circle-down', web: 'fa fa-chevron-circle-down' },
  { icon: 'fa-chevron-circle-left', web: 'fa fa-chevron-circle-left' },
  { icon: 'fa-chevron-circle-right', web: 'fa fa-chevron-circle-right' },
  { icon: 'fa-chevron-circle-up', web: 'fa fa-chevron-circle-up' },
  { icon: 'fa-chevron-down', web: 'fa fa-chevron-down' },
  { icon: 'fa-chevron-left', web: 'fa fa-chevron-left' },
  { icon: 'fa-chevron-right', web: 'fa fa-chevron-right' },
  { icon: 'fa-chevron-up', web: 'fa fa-chevron-up' },
  { icon: 'fa-child', web: 'fa fa-child' },
  { icon: 'fa-chrome', web: 'fab fa-chrome' },
  { icon: 'fa-circle', web: 'fa fa-circle' },
  // { icon: "fa-circle-o", web: "fa fa-circle-o" },
  // { icon: "fa-circle-o-notch", web: "fa fa-circle-o-notch" },
  // { icon: "fa-circle-thin", web: "fa fa-circle-thin" },
  { icon: 'fa-clipboard', web: 'fa fa-clipboard' },
  // { icon: "fa-clock-o", web: "fa fa-clock-o" },
  { icon: 'fa-clone', web: 'fa fa-clone' },
  // { icon: "fa-close", web: "fa fa-close" },
  { icon: 'fa-cloud', web: 'fa fa-cloud' },
  // { icon: "fa-cloud-download", web: "fa fa-cloud-download" },
  // { icon: "fa-cloud-upload", web: "fa fa-cloud-upload" },
  // { icon: "fa-cny", web: "fa fa-cny" },
  { icon: 'fa-code', web: 'fa fa-code' },
  // { icon: "fa-code-fork", web: "fa fa-code-fork" },
  { icon: 'fa-codepen', web: 'fab fa-codepen' },
  { icon: 'fa-codiepie', web: 'fab fa-codiepie' },
  { icon: 'fa-coffee', web: 'fa fa-coffee' },
  { icon: 'fa-cog', web: 'fa fa-cog' },
  { icon: 'fa-cogs', web: 'fa fa-cogs' },
  { icon: 'fa-columns', web: 'fa fa-columns' },
  { icon: 'fa-comment', web: 'fa fa-comment' },
  // { icon: "fa-comment-o", web: "fa fa-comment-o" },
  // { icon: "fa-commenting", web: "fa fa-commenting" },
  // { icon: "fa-commenting-o", web: "fa fa-commenting-o" },
  { icon: 'fa-comments', web: 'fa fa-comments' },
  // { icon: "fa-comments-o", web: "fa fa-comments-o" },
  { icon: 'fa-compass', web: 'fa fa-compass' },
  { icon: 'fa-compress', web: 'fa fa-compress' },
  { icon: 'fa-connectdevelop', web: 'fab fa-connectdevelop' },
  { icon: 'fa-contao', web: 'fab fa-contao' },
  { icon: 'fa-copy', web: 'fa fa-copy' },
  { icon: 'fa-copyright', web: 'fa fa-copyright' },
  { icon: 'fa-creative-commons', web: 'fab fa-creative-commons' },
  { icon: 'fa-credit-card', web: 'fa fa-credit-card' },
  // { icon: "fa-credit-card-alt", web: "fa fa-credit-card-alt" },
  { icon: 'fa-crop', web: 'fa fa-crop' },
  { icon: 'fa-crosshairs', web: 'fa fa-crosshairs' },
  { icon: 'fa-css3', web: 'fab fa-css3' },
  { icon: 'fa-cube', web: 'fa fa-cube' },
  { icon: 'fa-cubes', web: 'fa fa-cubes' },
  { icon: 'fa-cut', web: 'fa fa-cut' },
  // { icon: "fa-cutlery", web: "fa fa-cutlery" },
  // { icon: "fa-dashboard", web: "fa fa-dashboard" },
  { icon: 'fa-dashcube', web: 'fab fa-dashcube' },
  { icon: 'fa-database', web: 'fa fa-database' },
  { icon: 'fa-deaf', web: 'fa fa-deaf' },
  // { icon: "fa-deafness", web: "fa fa-deafness" },
  // { icon: "fa-dedent", web: "fa fa-dedent" },
  { icon: 'fa-delicious', web: 'fab fa-delicious' },
  { icon: 'fa-desktop', web: 'fa fa-desktop' },
  { icon: 'fa-deviantart', web: 'fab fa-deviantart' },
  // { icon: "fa-diamond", web: "fa fa-diamond" },
  { icon: 'fa-digg', web: 'fab fa-digg' },
  // { icon: "fa-dollar", web: "fa fa-dollar" },
  // { icon: "fa-dot-circle-o", web: "fa fa-dot-circle-o" },
  { icon: 'fa-download', web: 'fa fa-download' },
  { icon: 'fa-dribbble', web: 'fab fa-dribbble' },
  // { icon: "fa-drivers-license", web: "fa fa-drivers-license" },
  // { icon: "fa-drivers-license-o", web: "fa fa-drivers-license-o" },
  { icon: 'fa-dropbox', web: 'fab fa-dropbox' },
  { icon: 'fa-drupal', web: 'fab fa-drupal' },
  { icon: 'fa-edge', web: 'fab fa-edge' },
  { icon: 'fa-edit', web: 'fa fa-edit' },
  // { icon: "fa-eercast", web: "fa fa-eercast" },
  { icon: 'fa-eject', web: 'fa fa-eject' },
  { icon: 'fa-ellipsis-h', web: 'fa fa-ellipsis-h' },
  { icon: 'fa-ellipsis-v', web: 'fa fa-ellipsis-v' },
  { icon: 'fa-empire', web: 'fab fa-empire' },
  { icon: 'fa-envelope', web: 'fa fa-envelope' },
  // { icon: "fa-envelope-o", web: "fa fa-envelope-o" },
  { icon: 'fa-envelope-open', web: 'fa fa-envelope-open' },
  // { icon: "fa-envelope-open-o", web: "fa fa-envelope-open-o" },
  { icon: 'fa-envelope-square', web: 'fa fa-envelope-square' },
  { icon: 'fa-envira', web: 'fab fa-envira' },
  { icon: 'fa-eraser', web: 'fa fa-eraser' },
  { icon: 'fa-etsy', web: 'fab fa-etsy' },
  // { icon: "fa-eur", web: "fa fa-eur" },
  // { icon: "fa-euro", web: "fa fa-euro" },
  // { icon: "fa-exchange", web: "fa fa-exchange" },
  { icon: 'fa-exclamation', web: 'fa fa-exclamation' },
  { icon: 'fa-exclamation-circle', web: 'fa fa-exclamation-circle' },
  { icon: 'fa-exclamation-triangle', web: 'fa fa-exclamation-triangle' },
  { icon: 'fa-expand', web: 'fa fa-expand' },
  { icon: 'fa-expeditedssl', web: 'fab fa-expeditedssl' },
  // { icon: "fa-external-link", web: "fa fa-external-link" },
  // { icon: "fa-external-link-square", web: "fa fa-external-link-square" },
  { icon: 'fa-eye', web: 'fa fa-eye' },
  { icon: 'fa-eye-slash', web: 'fa fa-eye-slash' },
  // { icon: "fa-eyedropper", web: "fa fa-eyedropper" },
  // { icon: "fa-fa", web: "fa fa-fa" },
  { icon: 'fa-facebook', web: 'fab fa-facebook' },
  { icon: 'fa-facebook-f', web: 'fab fa-facebook-f' },
  // { icon: "fa-facebook-official", web: "fa fa-facebook-official" },
  { icon: 'fa-facebook-square', web: 'fab fa-facebook-square' },
  { icon: 'fa-fast-backward', web: 'fa fa-fast-backward' },
  { icon: 'fa-fast-forward', web: 'fa fa-fast-forward' },
  { icon: 'fa-fax', web: 'fa fa-fax' },
  // { icon: "fa-feed", web: "fa fa-feed" },
  { icon: 'fa-female', web: 'fa fa-female' },
  { icon: 'fa-fighter-jet', web: 'fa fa-fighter-jet' },
  { icon: 'fa-file', web: 'fa fa-file' },
  // { icon: "fa-file-archive-o", web: "fa fa-file-archive-o" },
  // { icon: "fa-file-audio-o", web: "fa fa-file-audio-o" },
  // { icon: "fa-file-code-o", web: "fa fa-file-code-o" },
  // { icon: "fa-file-excel-o", web: "fa fa-file-excel-o" },
  // { icon: "fa-file-image-o", web: "fa fa-file-image-o" },
  // { icon: "fa-file-movie-o", web: "fa fa-file-movie-o" },
  // { icon: "fa-file-o", web: "fa fa-file-o" },
  // { icon: "fa-file-pdf-o", web: "fa fa-file-pdf-o" },
  // { icon: "fa-file-photo-o", web: "fa fa-file-photo-o" },
  // { icon: "fa-file-picture-o", web: "fa fa-file-picture-o" },
  // { icon: "fa-file-powerpoint-o", web: "fa fa-file-powerpoint-o" },
  // { icon: "fa-file-sound-o", web: "fa fa-file-sound-o" },
  // { icon: "fa-file-text", web: "fa fa-file-text" },
  // { icon: "fa-file-text-o", web: "fa fa-file-text-o" },
  // { icon: "fa-file-video-o", web: "fa fa-file-video-o" },
  // { icon: "fa-file-word-o", web: "fa fa-file-word-o" },
  // { icon: "fa-file-zip-o", web: "fa fa-file-zip-o" },
  // { icon: "fa-files-o", web: "fa fa-files-o" },
  { icon: 'fa-film', web: 'fa fa-film' },
  { icon: 'fa-filter', web: 'fa fa-filter' },
  { icon: 'fa-fire', web: 'fa fa-fire' },
  { icon: 'fa-fire-extinguisher', web: 'fa fa-fire-extinguisher' },
  { icon: 'fa-firefox', web: 'fab fa-firefox' },
  { icon: 'fa-first-order', web: 'fab fa-first-order' },
  { icon: 'fa-flag', web: 'fa fa-flag' },
  { icon: 'fa-flag-checkered', web: 'fa fa-flag-checkered' },
  // { icon: "fa-flag-o", web: "fa fa-flag-o" },
  // { icon: "fa-flash", web: "fa fa-flash" },
  { icon: 'fa-flask', web: 'fa fa-flask' },
  { icon: 'fa-flickr', web: 'fab fa-flickr' },
  // { icon: "fa-floppy-o", web: "fa fa-floppy-o" },
  { icon: 'fa-folder', web: 'fa fa-folder' },
  // { icon: "fa-folder-o", web: "fa fa-folder-o" },
  { icon: 'fa-folder-open', web: 'fa fa-folder-open' },
  // { icon: "fa-folder-open-o", web: "fa fa-folder-open-o" },
  { icon: 'fa-font', web: 'fa fa-font' },
  { icon: 'fa-font-awesome', web: 'fab fa-font-awesome' },
  { icon: 'fa-fonticons', web: 'fab fa-fonticons' },
  { icon: 'fa-fort-awesome', web: 'fab fa-fort-awesome' },
  { icon: 'fa-forumbee', web: 'fab fa-forumbee' },
  { icon: 'fa-forward', web: 'fa fa-forward' },
  { icon: 'fa-foursquare', web: 'fab fa-foursquare' },
  { icon: 'fa-free-code-camp', web: 'fab fa-free-code-camp' },
  // { icon: "fa-frown-o", web: "fa fa-frown-o" },
  // { icon: "fa-futbol-o", web: "fa fa-futbol-o" },
  { icon: 'fa-gamepad', web: 'fa fa-gamepad' },
  { icon: 'fa-gavel', web: 'fa fa-gavel' },
  // { icon: "fa-gbp", web: "fa fa-gbp" },
  // { icon: "fa-ge", web: "fa fa-ge" },
  // { icon: "fa-gear", web: "fa fa-gear" },
  // { icon: "fa-gears", web: "fa fa-gears" },
  { icon: 'fa-genderless', web: 'fa fa-genderless' },
  { icon: 'fa-get-pocket', web: 'fab fa-get-pocket' },
  { icon: 'fa-gg', web: 'fab fa-gg' },
  { icon: 'fa-gg-circle', web: 'fab fa-gg-circle' },
  { icon: 'fa-gift', web: 'fa fa-gift' },
  { icon: 'fa-git', web: 'fab fa-git' },
  { icon: 'fa-git-square', web: 'fab fa-git-square' },
  { icon: 'fa-github', web: 'fab fa-github' },
  { icon: 'fa-github-alt', web: 'fab fa-github-alt' },
  { icon: 'fa-github-square', web: 'fab fa-github-square' },
  { icon: 'fa-gitlab', web: 'fab fa-gitlab' },
  // { icon: "fa-gittip", web: "fa fa-gittip" },
  // { icon: "fa-glass", web: "fa fa-glass" },
  { icon: 'fa-glide', web: 'fab fa-glide' },
  { icon: 'fa-glide-g', web: 'fab fa-glide-g' },
  { icon: 'fa-globe', web: 'fa fa-globe' },
  { icon: 'fa-google', web: 'fab fa-google' },
  { icon: 'fa-google-plus', web: 'fab fa-google-plus' },
  // { icon: "fa-google-plus-circle", web: "fa fa-google-plus-circle" },
  // { icon: "fa-google-plus-official", web: "fa fa-google-plus-official" },
  { icon: 'fa-google-plus-square', web: 'fab fa-google-plus-square' },
  { icon: 'fa-google-wallet', web: 'fab fa-google-wallet' },
  { icon: 'fa-graduation-cap', web: 'fa fa-graduation-cap' },
  { icon: 'fa-gratipay', web: 'fab fa-gratipay' },
  { icon: 'fa-grav', web: 'fab fa-grav' },
  // { icon: "fa-group", web: "fa fa-group" },
  { icon: 'fa-h-square', web: 'fa fa-h-square' },
  { icon: 'fa-hacker-news', web: 'fab fa-hacker-news' },
  // { icon: "fa-hand-grab-o", web: "fa fa-hand-grab-o" },
  // { icon: "fa-hand-lizard-o", web: "fa fa-hand-lizard-o" },
  // { icon: "fa-hand-o-down", web: "fa fa-hand-o-down" },
  // { icon: "fa-hand-o-left", web: "fa fa-hand-o-left" },
  // { icon: "fa-hand-o-right", web: "fa fa-hand-o-right" },
  // { icon: "fa-hand-o-up", web: "fa fa-hand-o-up" },
  // { icon: "fa-hand-paper-o", web: "fa fa-hand-paper-o" },
  // { icon: "fa-hand-peace-o", web: "fa fa-hand-peace-o" },
  // { icon: "fa-hand-pointer-o", web: "fa fa-hand-pointer-o" },
  // { icon: "fa-hand-rock-o", web: "fa fa-hand-rock-o" },
  // { icon: "fa-hand-scissors-o", web: "fa fa-hand-scissors-o" },
  // { icon: "fa-hand-spock-o", web: "fa fa-hand-spock-o" },
  // { icon: "fa-hand-stop-o", web: "fa fa-hand-stop-o" },
  // { icon: "fa-handshake-o", web: "fa fa-handshake-o" },
  // { icon: "fa-hard-of-hearing", web: "fa fa-hard-of-hearing" },
  { icon: 'fa-hashtag', web: 'fa fa-hashtag' },
  // { icon: "fa-hdd-o", web: "fa fa-hdd-o" },
  // { icon: "fa-header", web: "fa fa-header" },
  { icon: 'fa-headphones', web: 'fa fa-headphones' },
  { icon: 'fa-heart', web: 'fa fa-heart' },
  // { icon: "fa-heart-o", web: "fa fa-heart-o" },
  { icon: 'fa-heartbeat', web: 'fa fa-heartbeat' },
  { icon: 'fa-history', web: 'fa fa-history' },
  { icon: 'fa-home', web: 'fa fa-home' },
  // { icon: "fa-hospital-o", web: "fa fa-hospital-o" },
  { icon: 'fa-hotel', web: 'fa fa-hotel' },
  { icon: 'fa-hourglass', web: 'fa fa-hourglass' },
  // { icon: "fa-hourglass-1", web: "fa fa-hourglass-1" },
  // { icon: "fa-hourglass-2", web: "fa fa-hourglass-2" },
  // { icon: "fa-hourglass-3", web: "fa fa-hourglass-3" },
  { icon: 'fa-hourglass-end', web: 'fa fa-hourglass-end' },
  { icon: 'fa-hourglass-half', web: 'fa fa-hourglass-half' },
  // { icon: "fa-hourglass-o", web: "fa fa-hourglass-o" },
  { icon: 'fa-hourglass-start', web: 'fa fa-hourglass-start' },
  { icon: 'fa-houzz', web: 'fab fa-houzz' },
  { icon: 'fa-html5', web: 'fab fa-html5' },
  { icon: 'fa-i-cursor', web: 'fa fa-i-cursor' },
  { icon: 'fa-id-badge', web: 'fa fa-id-badge' },
  { icon: 'fa-id-card', web: 'fa fa-id-card' },
  // { icon: "fa-id-card-o", web: "fa fa-id-card-o" },
  // { icon: "fa-ils", web: "fa fa-ils" },
  { icon: 'fa-image', web: 'fa fa-image' },
  { icon: 'fa-imdb', web: 'fab fa-imdb' },
  { icon: 'fa-inbox', web: 'fa fa-inbox' },
  { icon: 'fa-indent', web: 'fa fa-indent' },
  { icon: 'fa-industry', web: 'fa fa-industry' },
  { icon: 'fa-info', web: 'fa fa-info' },
  { icon: 'fa-info-circle', web: 'fa fa-info-circle' },
  // { icon: "fa-inr", web: "fa fa-inr" },
  { icon: 'fa-instagram', web: 'fab fa-instagram' },
  // { icon: "fa-institution", web: "fa fa-institution" },
  { icon: 'fa-internet-explorer', web: 'fab fa-internet-explorer' },
  // { icon: "fa-intersex", web: "fa fa-intersex" },
  { icon: 'fa-ioxhost', web: 'fab fa-ioxhost' },
  { icon: 'fa-italic', web: 'fa fa-italic' },
  { icon: 'fa-joomla', web: 'fab fa-joomla' },
  // { icon: "fa-jpy", web: "fa fa-jpy" },
  { icon: 'fa-jsfiddle', web: 'fab fa-jsfiddle' },
  { icon: 'fa-key', web: 'fa fa-key' },
  // { icon: "fa-keyboard-o", web: "fa fa-keyboard-o" },
  // { icon: "fa-krw", web: "fa fa-krw" },
  { icon: 'fa-language', web: 'fa fa-language' },
  { icon: 'fa-laptop', web: 'fa fa-laptop' },
  { icon: 'fa-lastfm', web: 'fab fa-lastfm' },
  { icon: 'fa-lastfm-square', web: 'fab fa-lastfm-square' },
  { icon: 'fa-leaf', web: 'fa fa-leaf' },
  { icon: 'fa-leanpub', web: 'fab fa-leanpub' },
  // { icon: "fa-legal", web: "fa fa-legal" },
  // { icon: "fa-lemon-o", web: "fa fa-lemon-o" },
  // { icon: "fa-level-down", web: "fa fa-level-down" },
  // { icon: "fa-level-up", web: "fa fa-level-up" },
  // { icon: "fa-life-bouy", web: "fa fa-life-bouy" },
  // { icon: "fa-life-buoy", web: "fa fa-life-buoy" },
  { icon: 'fa-life-ring', web: 'fa fa-life-ring' },
  // { icon: "fa-life-saver", web: "fa fa-life-saver" },
  // { icon: "fa-lightbulb-o", web: "fa fa-lightbulb-o" },
  // { icon: "fa-line-chart", web: "fa fa-line-chart" },
  { icon: 'fa-link', web: 'fa fa-link' },
  { icon: 'fa-linkedin', web: 'fab fa-linkedin' },
  // { icon: "fa-linkedin-square", web: "fa fa-linkedin-square" },
  { icon: 'fa-linode', web: 'fab fa-linode' },
  { icon: 'fa-linux', web: 'fab fa-linux' },
  { icon: 'fa-list', web: 'fa fa-list' },
  { icon: 'fa-list-alt', web: 'fa fa-list-alt' },
  { icon: 'fa-list-ol', web: 'fa fa-list-ol' },
  { icon: 'fa-list-ul', web: 'fa fa-list-ul' },
  { icon: 'fa-location-arrow', web: 'fa fa-location-arrow' },
  { icon: 'fa-lock', web: 'fa fa-lock' },
  // { icon: "fa-long-arrow-down", web: "fa fa-long-arrow-down" },
  // { icon: "fa-long-arrow-left", web: "fa fa-long-arrow-left" },
  // { icon: "fa-long-arrow-right", web: "fa fa-long-arrow-right" },
  // { icon: "fa-long-arrow-up", web: "fa fa-long-arrow-up" },
  { icon: 'fa-low-vision', web: 'fa fa-low-vision' },
  { icon: 'fa-magic', web: 'fa fa-magic' },
  { icon: 'fa-magnet', web: 'fa fa-magnet' },
  // { icon: "fa-mail-forward", web: "fa fa-mail-forward" },
  // { icon: "fa-mail-reply", web: "fa fa-mail-reply" },
  // { icon: "fa-mail-reply-all", web: "fa fa-mail-reply-all" },
  { icon: 'fa-male', web: 'fa fa-male' },
  { icon: 'fa-map', web: 'fa fa-map' },
  { icon: 'fa-map-marker', web: 'fa fa-map-marker' },
  // { icon: "fa-map-o", web: "fa fa-map-o" },
  { icon: 'fa-map-pin', web: 'fa fa-map-pin' },
  { icon: 'fa-map-signs', web: 'fa fa-map-signs' },
  { icon: 'fa-mars', web: 'fa fa-mars' },
  { icon: 'fa-mars-double', web: 'fa fa-mars-double' },
  { icon: 'fa-mars-stroke', web: 'fa fa-mars-stroke' },
  { icon: 'fa-mars-stroke-h', web: 'fa fa-mars-stroke-h' },
  { icon: 'fa-mars-stroke-v', web: 'fa fa-mars-stroke-v' },
  { icon: 'fa-maxcdn', web: 'fab fa-maxcdn' },
  // { icon: "fa-meanpath", web: "fa fa-meanpath" },
  { icon: 'fa-medium', web: 'fab fa-medium' },
  { icon: 'fa-medkit', web: 'fa fa-medkit' },
  { icon: 'fa-meetup', web: 'fab fa-meetup' },
  // { icon: "fa-meh-o", web: "fa fa-meh-o" },
  { icon: 'fa-mercury', web: 'fa fa-mercury' },
  { icon: 'fa-microchip', web: 'fa fa-microchip' },
  { icon: 'fa-microphone', web: 'fa fa-microphone' },
  { icon: 'fa-microphone-slash', web: 'fa fa-microphone-slash' },
  { icon: 'fa-minus', web: 'fa fa-minus' },
  { icon: 'fa-minus-circle', web: 'fa fa-minus-circle' },
  { icon: 'fa-minus-square', web: 'fa fa-minus-square' },
  // { icon: "fa-minus-square-o", web: "fa fa-minus-square-o" },
  { icon: 'fa-mixcloud', web: 'fab fa-mixcloud' },
  { icon: 'fa-mobile', web: 'fa fa-mobile' },
  // { icon: "fa-mobile-phone", web: "fa fa-mobile-phone" },
  { icon: 'fa-modx', web: 'fab fa-modx' },
  // { icon: "fa-money", web: "fa fa-money" },
  // { icon: "fa-moon-o", web: "fa fa-moon-o" },
  // { icon: "fa-mortar-board", web: "fa fa-mortar-board" },
  { icon: 'fa-motorcycle', web: 'fa fa-motorcycle' },
  { icon: 'fa-mouse-pointer', web: 'fa fa-mouse-pointer' },
  { icon: 'fa-music', web: 'fa fa-music' },
  // { icon: "fa-navicon", web: "fa fa-navicon" },
  { icon: 'fa-neuter', web: 'fa fa-neuter' },
  // { icon: "fa-newspaper-o", web: "fa fa-newspaper-o" },
  { icon: 'fa-object-group', web: 'fa fa-object-group' },
  { icon: 'fa-object-ungroup', web: 'fa fa-object-ungroup' },
  { icon: 'fa-odnoklassniki', web: 'fab fa-odnoklassniki' },
  { icon: 'fa-odnoklassniki-square', web: 'fab fa-odnoklassniki-square' },
  { icon: 'fa-opencart', web: 'fab fa-opencart' },
  { icon: 'fa-openid', web: 'fab fa-openid' },
  { icon: 'fa-opera', web: 'fab fa-opera' },
  { icon: 'fa-optin-monster', web: 'fab fa-optin-monster' },
  { icon: 'fa-outdent', web: 'fa fa-outdent' },
  { icon: 'fa-pagelines', web: 'fab fa-pagelines' },
  { icon: 'fa-paint-brush', web: 'fa fa-paint-brush' },
  { icon: 'fa-paper-plane', web: 'fa fa-paper-plane' },
  // { icon: "fa-paper-plane-o", web: "fa fa-paper-plane-o" },
  { icon: 'fa-paperclip', web: 'fa fa-paperclip' },
  { icon: 'fa-paragraph', web: 'fa fa-paragraph' },
  { icon: 'fa-paste', web: 'fa fa-paste' },
  { icon: 'fa-pause', web: 'fa fa-pause' },
  { icon: 'fa-pause-circle', web: 'fa fa-pause-circle' },
  // { icon: "fa-pause-circle-o", web: "fa fa-pause-circle-o" },
  { icon: 'fa-paw', web: 'fa fa-paw' },
  { icon: 'fa-paypal', web: 'fab fa-paypal' },
  // { icon: "fa-pencil", web: "fa fa-pencil" },
  // { icon: "fa-pencil-square", web: "fa fa-pencil-square" },
  // { icon: "fa-pencil-square-o", web: "fa fa-pencil-square-o" },
  { icon: 'fa-percent', web: 'fa fa-percent' },
  { icon: 'fa-phone', web: 'fa fa-phone' },
  { icon: 'fa-phone-square', web: 'fa fa-phone-square' },
  // { icon: "fa-photo", web: "fa fa-photo" },
  // { icon: "fa-picture-o", web: "fa fa-picture-o" },
  // { icon: "fa-pie-chart", web: "fa fa-pie-chart" },
  { icon: 'fa-pied-piper', web: 'fab fa-pied-piper' },
  { icon: 'fa-pied-piper-alt', web: 'fab fa-pied-piper-alt' },
  { icon: 'fa-pied-piper-pp', web: 'fab fa-pied-piper-pp' },
  { icon: 'fa-pinterest', web: 'fab fa-pinterest' },
  { icon: 'fa-pinterest-p', web: 'fab fa-pinterest-p' },
  { icon: 'fa-pinterest-square', web: 'fab fa-pinterest-square' },
  { icon: 'fa-plane', web: 'fa fa-plane' },
  { icon: 'fa-play', web: 'fa fa-play' },
  { icon: 'fa-play-circle', web: 'fa fa-play-circle' },
  // { icon: "fa-play-circle-o", web: "fa fa-play-circle-o" },
  { icon: 'fa-plug', web: 'fa fa-plug' },
  { icon: 'fa-plus', web: 'fa fa-plus' },
  { icon: 'fa-plus-circle', web: 'fa fa-plus-circle' },
  { icon: 'fa-plus-square', web: 'fa fa-plus-square' },
  // { icon: "fa-plus-square-o", web: "fa fa-plus-square-o" },
  { icon: 'fa-podcast', web: 'fa fa-podcast' },
  { icon: 'fa-power-off', web: 'fa fa-power-off' },
  { icon: 'fa-print', web: 'fa fa-print' },
  { icon: 'fa-product-hunt', web: 'fab fa-product-hunt' },
  { icon: 'fa-puzzle-piece', web: 'fa fa-puzzle-piece' },
  { icon: 'fa-qq', web: 'fab fa-qq' },
  { icon: 'fa-qrcode', web: 'fa fa-qrcode' },
  { icon: 'fa-question', web: 'fa fa-question' },
  { icon: 'fa-question-circle', web: 'fa fa-question-circle' },
  // { icon: "fa-question-circle-o", web: "fa fa-question-circle-o" },
  { icon: 'fa-quora', web: 'fab fa-quora' },
  { icon: 'fa-quote-left', web: 'fa fa-quote-left' },
  { icon: 'fa-quote-right', web: 'fa fa-quote-right' },
  // { icon: "fa-ra", web: "fa fa-ra" },
  { icon: 'fa-random', web: 'fa fa-random' },
  { icon: 'fa-ravelry', web: 'fab fa-ravelry' },
  { icon: 'fa-rebel', web: 'fab fa-rebel' },
  { icon: 'fa-recycle', web: 'fa fa-recycle' },
  { icon: 'fa-reddit', web: 'fab fa-reddit' },
  { icon: 'fa-reddit-alien', web: 'fab fa-reddit-alien' },
  { icon: 'fa-reddit-square', web: 'fab fa-reddit-square' },
  // { icon: "fa-refresh", web: "fa fa-refresh" },
  { icon: 'fa-registered', web: 'fa fa-registered' },
  // { icon: "fa-remove", web: "fa fa-remove" },
  { icon: 'fa-renren', web: 'fab fa-renren' },
  // { icon: "fa-reorder", web: "fa fa-reorder" },
  // { icon: "fa-repeat", web: "fa fa-repeat" },
  { icon: 'fa-reply', web: 'fa fa-reply' },
  { icon: 'fa-reply-all', web: 'fa fa-reply-all' },
  // { icon: "fa-resistance", web: "fa fa-resistance" },
  { icon: 'fa-retweet', web: 'fa fa-retweet' },
  // { icon: "fa-rmb", web: "fa fa-rmb" },
  { icon: 'fa-road', web: 'fa fa-road' },
  { icon: 'fa-rocket', web: 'fa fa-rocket' },
  // { icon: "fa-rotate-left", web: "fa fa-rotate-left" },
  // { icon: "fa-rotate-right", web: "fa fa-rotate-right" },
  // { icon: "fa-rouble", web: "fa fa-rouble" },
  { icon: 'fa-rss', web: 'fa fa-rss' },
  { icon: 'fa-rss-square', web: 'fa fa-rss-square' },
  // { icon: "fa-rub", web: "fa fa-rub" },
  // { icon: "fa-ruble", web: "fa fa-ruble" },
  // { icon: "fa-rupee", web: "fa fa-rupee" },
  // { icon: "fa-s15", web: "fa fa-s15" },
  { icon: 'fa-safari', web: 'fab fa-safari' },
  { icon: 'fa-save', web: 'fa fa-save' },
  // { icon: "fa-scissors", web: "fa fa-scissors" },
  { icon: 'fa-scribd', web: 'fab fa-scribd' },
  { icon: 'fa-search', web: 'fa fa-search' },
  { icon: 'fa-search-minus', web: 'fa fa-search-minus' },
  { icon: 'fa-search-plus', web: 'fa fa-search-plus' },
  { icon: 'fa-sellsy', web: 'fab fa-sellsy' },
  // { icon: "fa-send", web: "fa fa-send" },
  // { icon: "fa-send-o", web: "fa fa-send-o" },
  { icon: 'fa-server', web: 'fa fa-server' },
  { icon: 'fa-share', web: 'fa fa-share' },
  { icon: 'fa-share-alt', web: 'fa fa-share-alt' },
  { icon: 'fa-share-alt-square', web: 'fa fa-share-alt-square' },
  { icon: 'fa-share-square', web: 'fa fa-share-square' },
  // { icon: "fa-share-square-o", web: "fa fa-share-square-o" },
  // { icon: "fa-shekel", web: "fa fa-shekel" },
  // { icon: "fa-sheqel", web: "fa fa-sheqel" },
  // { icon: "fa-shield", web: "fa fa-shield" },
  { icon: 'fa-ship', web: 'fa fa-ship' },
  { icon: 'fa-shirtsinbulk', web: 'fab fa-shirtsinbulk' },
  { icon: 'fa-shopping-bag', web: 'fa fa-shopping-bag' },
  { icon: 'fa-shopping-basket', web: 'fa fa-shopping-basket' },
  { icon: 'fa-shopping-cart', web: 'fa fa-shopping-cart' },
  { icon: 'fa-shower', web: 'fa fa-shower' },
  // { icon: "fa-sign-in", web: "fa fa-sign-in" },
  { icon: 'fa-sign-language', web: 'fa fa-sign-language' },
  // { icon: "fa-sign-out", web: "fa fa-sign-out" },
  { icon: 'fa-signal', web: 'fa fa-signal' },
  // { icon: "fa-signing", web: "fa fa-signing" },
  { icon: 'fa-simplybuilt', web: 'fab fa-simplybuilt' },
  { icon: 'fa-sitemap', web: 'fa fa-sitemap' },
  { icon: 'fa-skyatlas', web: 'fab fa-skyatlas' },
  { icon: 'fa-skype', web: 'fab fa-skype' },
  { icon: 'fa-slack', web: 'fab fa-slack' },
  // { icon: "fa-sliders", web: "fa fa-sliders" },
  { icon: 'fa-slideshare', web: 'fab fa-slideshare' },
  // { icon: "fa-smile-o", web: "fa fa-smile-o" },
  { icon: 'fa-snapchat', web: 'fab fa-snapchat' },
  { icon: 'fa-snapchat-ghost', web: 'fab fa-snapchat-ghost' },
  { icon: 'fa-snapchat-square', web: 'fab fa-snapchat-square' },
  // { icon: "fa-snowflake-o", web: "fa fa-snowflake-o" },
  // { icon: "fa-soccer-ball-o", web: "fa fa-soccer-ball-o" },
  { icon: 'fa-sort', web: 'fa fa-sort' },
  // { icon: "fa-sort-alpha-asc", web: "fa fa-sort-alpha-asc" },
  // { icon: "fa-sort-alpha-desc", web: "fa fa-sort-alpha-desc" },
  // { icon: "fa-sort-amount-asc", web: "fa fa-sort-amount-asc" },
  // { icon: "fa-sort-amount-desc", web: "fa fa-sort-amount-desc" },
  // { icon: "fa-sort-asc", web: "fa fa-sort-asc" },
  // { icon: "fa-sort-desc", web: "fa fa-sort-desc" },
  { icon: 'fa-sort-down', web: 'fa fa-sort-down' },
  // { icon: "fa-sort-numeric-asc", web: "fa fa-sort-numeric-asc" },
  // { icon: "fa-sort-numeric-desc", web: "fa fa-sort-numeric-desc" },
  { icon: 'fa-sort-up', web: 'fa fa-sort-up' },
  { icon: 'fa-soundcloud', web: 'fab fa-soundcloud' },
  { icon: 'fa-space-shuttle', web: 'fa fa-space-shuttle' },
  { icon: 'fa-spinner', web: 'fa fa-spinner' },
  // { icon: "fa-spoon", web: "fa fa-spoon" },
  { icon: 'fa-spotify', web: 'fab fa-spotify' },
  { icon: 'fa-square', web: 'fa fa-square' },
  // { icon: "fa-square-o", web: "fa fa-square-o" },
  { icon: 'fa-stack-exchange', web: 'fab fa-stack-exchange' },
  { icon: 'fa-stack-overflow', web: 'fab fa-stack-overflow' },
  { icon: 'fa-star', web: 'fa fa-star' },
  { icon: 'fa-star-half', web: 'fa fa-star-half' },
  // { icon: "fa-star-half-empty", web: "fa fa-star-half-empty" },
  // { icon: "fa-star-half-full", web: "fa fa-star-half-full" },
  // { icon: "fa-star-half-o", web: "fa fa-star-half-o" },
  // { icon: "fa-star-o", web: "fa fa-star-o" },
  { icon: 'fa-steam', web: 'fab fa-steam' },
  { icon: 'fa-steam-square', web: 'fab fa-steam-square' },
  { icon: 'fa-step-backward', web: 'fa fa-step-backward' },
  { icon: 'fa-step-forward', web: 'fa fa-step-forward' },
  { icon: 'fa-stethoscope', web: 'fa fa-stethoscope' },
  { icon: 'fa-sticky-note', web: 'fa fa-sticky-note' },
  // { icon: "fa-sticky-note-o", web: "fa fa-sticky-note-o" },
  { icon: 'fa-stop', web: 'fa fa-stop' },
  { icon: 'fa-stop-circle', web: 'fa fa-stop-circle' },
  // { icon: "fa-stop-circle-o", web: "fa fa-stop-circle-o" },
  { icon: 'fa-street-view', web: 'fa fa-street-view' },
  { icon: 'fa-strikethrough', web: 'fa fa-strikethrough' },
  { icon: 'fa-stumbleupon', web: 'fab fa-stumbleupon' },
  { icon: 'fa-stumbleupon-circle', web: 'fab fa-stumbleupon-circle' },
  { icon: 'fa-subscript', web: 'fa fa-subscript' },
  { icon: 'fa-subway', web: 'fa fa-subway' },
  { icon: 'fa-suitcase', web: 'fa fa-suitcase' },
  // { icon: "fa-sun-o", web: "fa fa-sun-o" },
  { icon: 'fa-superpowers', web: 'fab fa-superpowers' },
  { icon: 'fa-superscript', web: 'fa fa-superscript' },
  // { icon: "fa-support", web: "fa fa-support" },
  { icon: 'fa-table', web: 'fa fa-table' },
  { icon: 'fa-tablet', web: 'fa fa-tablet' },
  // { icon: "fa-tachometer", web: "fa fa-tachometer" },
  { icon: 'fa-tag', web: 'fa fa-tag' },
  { icon: 'fa-tags', web: 'fa fa-tags' },
  { icon: 'fa-tasks', web: 'fa fa-tasks' },
  { icon: 'fa-taxi', web: 'fa fa-taxi' },
  { icon: 'fa-telegram', web: 'fab fa-telegram' },
  // { icon: "fa-television", web: "fa fa-television" },
  { icon: 'fa-tencent-weibo', web: 'fab fa-tencent-weibo' },
  { icon: 'fa-terminal', web: 'fa fa-terminal' },
  { icon: 'fa-text-height', web: 'fa fa-text-height' },
  { icon: 'fa-text-width', web: 'fa fa-text-width' },
  { icon: 'fa-th', web: 'fa fa-th' },
  { icon: 'fa-th-large', web: 'fa fa-th-large' },
  { icon: 'fa-th-list', web: 'fa fa-th-list' },
  { icon: 'fa-themeisle', web: 'fab fa-themeisle' },
  { icon: 'fa-thermometer', web: 'fa fa-thermometer' },
  // { icon: "fa-thermometer-0", web: "fa fa-thermometer-0" },
  // { icon: "fa-thermometer-1", web: "fa fa-thermometer-1" },
  // { icon: "fa-thermometer-2", web: "fa fa-thermometer-2" },
  // { icon: "fa-thermometer-3", web: "fa fa-thermometer-3" },
  // { icon: "fa-thermometer-4", web: "fa fa-thermometer-4" },
  { icon: 'fa-thermometer-empty', web: 'fa fa-thermometer-empty' },
  { icon: 'fa-thermometer-full', web: 'fa fa-thermometer-full' },
  { icon: 'fa-thermometer-half', web: 'fa fa-thermometer-half' },
  { icon: 'fa-thermometer-quarter', web: 'fa fa-thermometer-quarter' },
  {
    icon: 'fa-thermometer-three-quarters',
    web: 'fa fa-thermometer-three-quarters',
  },
  // { icon: "fa-thumb-tack", web: "fa fa-thumb-tack" },
  { icon: 'fa-thumbs-down', web: 'fa fa-thumbs-down' },
  // { icon: "fa-thumbs-o-down", web: "fa fa-thumbs-o-down" },
  // { icon: "fa-thumbs-o-up", web: "fa fa-thumbs-o-up" },
  { icon: 'fa-thumbs-up', web: 'fa fa-thumbs-up' },
  // { icon: "fa-ticket", web: "fa fa-ticket" },
  { icon: 'fa-times', web: 'fa fa-times' },
  { icon: 'fa-times-circle', web: 'fa fa-times-circle' },
  // { icon: "fa-times-circle-o", web: "fa fa-times-circle-o" },
  // { icon: "fa-times-rectangle", web: "fa fa-times-rectangle" },
  // { icon: "fa-times-rectangle-o", web: "fa fa-times-rectangle-o" },
  { icon: 'fa-tint', web: 'fa fa-tint' },
  // { icon: "fa-toggle-down", web: "fa fa-toggle-down" },
  // { icon: "fa-toggle-left", web: "fa fa-toggle-left" },
  { icon: 'fa-toggle-off', web: 'fa fa-toggle-off' },
  { icon: 'fa-toggle-on', web: 'fa fa-toggle-on' },
  // { icon: "fa-toggle-right", web: "fa fa-toggle-right" },
  // { icon: "fa-toggle-up", web: "fa fa-toggle-up" },
  { icon: 'fa-trademark', web: 'fa fa-trademark' },
  { icon: 'fa-train', web: 'fa fa-train' },
  { icon: 'fa-transgender', web: 'fa fa-transgender' },
  { icon: 'fa-transgender-alt', web: 'fa fa-transgender-alt' },
  { icon: 'fa-trash', web: 'fa fa-trash' },
  // { icon: "fa-trash-o", web: "fa fa-trash-o" },
  { icon: 'fa-tree', web: 'fa fa-tree' },
  { icon: 'fa-trello', web: 'fab fa-trello' },
  { icon: 'fa-tripadvisor', web: 'fab fa-tripadvisor' },
  { icon: 'fa-trophy', web: 'fa fa-trophy' },
  { icon: 'fa-truck', web: 'fa fa-truck' },
  // { icon: "fa-try", web: "fa fa-try" },
  { icon: 'fa-tty', web: 'fa fa-tty' },
  { icon: 'fa-tumblr', web: 'fab fa-tumblr' },
  { icon: 'fa-tumblr-square', web: 'fab fa-tumblr-square' },
  // { icon: "fa-turkish-lira", web: "fa fa-turkish-lira" },
  { icon: 'fa-tv', web: 'fa fa-tv' },
  { icon: 'fa-twitch', web: 'fab fa-twitch' },
  { icon: 'fa-twitter', web: 'fab fa-twitter' },
  { icon: 'fa-twitter-square', web: 'fab fa-twitter-square' },
  { icon: 'fa-umbrella', web: 'fa fa-umbrella' },
  { icon: 'fa-underline', web: 'fa fa-underline' },
  { icon: 'fa-undo', web: 'fa fa-undo' },
  { icon: 'fa-universal-access', web: 'fa fa-universal-access' },
  { icon: 'fa-university', web: 'fa fa-university' },
  { icon: 'fa-unlink', web: 'fa fa-unlink' },
  { icon: 'fa-unlock', web: 'fa fa-unlock' },
  { icon: 'fa-unlock-alt', web: 'fa fa-unlock-alt' },
  // { icon: "fa-unsorted", web: "fa fa-unsorted" },
  { icon: 'fa-upload', web: 'fa fa-upload' },
  { icon: 'fa-usb', web: 'fab fa-usb' },
  // { icon: "fa-usd", web: "fa fa-usd" },
  { icon: 'fa-user', web: 'fa fa-user' },
  { icon: 'fa-user-circle', web: 'fa fa-user-circle' },
  // { icon: "fa-user-circle-o", web: "fa fa-user-circle-o" },
  { icon: 'fa-user-md', web: 'fa fa-user-md' },
  // { icon: "fa-user-o", web: "fa fa-user-o" },
  { icon: 'fa-user-plus', web: 'fa fa-user-plus' },
  { icon: 'fa-user-secret', web: 'fa fa-user-secret' },
  { icon: 'fa-user-times', web: 'fa fa-user-times' },
  { icon: 'fa-users', web: 'fa fa-users' },
  // { icon: "fa-vcard", web: "fa fa-vcard" },
  // { icon: "fa-vcard-o", web: "fa fa-vcard-o" },
  { icon: 'fa-venus', web: 'fa fa-venus' },
  { icon: 'fa-venus-double', web: 'fa fa-venus-double' },
  { icon: 'fa-venus-mars', web: 'fa fa-venus-mars' },
  { icon: 'fa-viacoin', web: 'fab fa-viacoin' },
  { icon: 'fa-viadeo', web: 'fab fa-viadeo' },
  { icon: 'fa-viadeo-square', web: 'fab fa-viadeo-square' },
  // { icon: "fa-video-camera", web: "fa fa-video-camera" },
  { icon: 'fa-vimeo', web: 'fab fa-vimeo' },
  { icon: 'fa-vimeo-square', web: 'fab fa-vimeo-square' },
  { icon: 'fa-vine', web: 'fab fa-vine' },
  { icon: 'fa-vk', web: 'fab fa-vk' },
  // { icon: "fa-volume-control-phone", web: "fa fa-volume-control-phone" },
  { icon: 'fa-volume-down', web: 'fa fa-volume-down' },
  { icon: 'fa-volume-off', web: 'fa fa-volume-off' },
  { icon: 'fa-volume-up', web: 'fa fa-volume-up' },
  // { icon: "fa-warning", web: "fa fa-warning" },
  // { icon: "fa-wechat", web: "fa fa-wechat" },
  { icon: 'fa-weibo', web: 'fab fa-weibo' },
  { icon: 'fa-weixin', web: 'fab fa-weixin' },
  { icon: 'fa-whatsapp', web: 'fab fa-whatsapp' },
  { icon: 'fa-wheelchair', web: 'fa fa-wheelchair' },
  // { icon: "fa-wheelchair-alt", web: "fa fa-wheelchair-alt" },
  { icon: 'fa-wifi', web: 'fa fa-wifi' },
  { icon: 'fa-wikipedia-w', web: 'fab fa-wikipedia-w' },
  { icon: 'fa-window-close', web: 'fa fa-window-close' },
  // { icon: "fa-window-close-o", web: "fa fa-window-close-o" },
  { icon: 'fa-window-maximize', web: 'fa fa-window-maximize' },
  { icon: 'fa-window-minimize', web: 'fa fa-window-minimize' },
  { icon: 'fa-window-restore', web: 'fa fa-window-restore' },
  { icon: 'fa-windows', web: 'fab fa-windows' },
  // { icon: "fa-won", web: "fa fa-won" },
  { icon: 'fa-wordpress', web: 'fab fa-wordpress' },
  { icon: 'fa-wpbeginner', web: 'fab fa-wpbeginner' },
  { icon: 'fa-wpexplorer', web: 'fab fa-wpexplorer' },
  { icon: 'fa-wpforms', web: 'fab fa-wpforms' },
  { icon: 'fa-wrench', web: 'fa fa-wrench' },
  { icon: 'fa-xing', web: 'fab fa-xing' },
  { icon: 'fa-xing-square', web: 'fab fa-xing-square' },
  // { icon: "fa-y-combinator", web: "fabfa-y-combinator" },
  // { icon: "fa-y-combinator-square", web: "fa fa-y-combinator-square" },
  { icon: 'fa-yahoo', web: 'fab fa-yahoo' },
  // { icon: "fa-yc", web: "fa fa-yc" },
  // { icon: "fa-yc-square", web: "fa fa-yc-square" },
  { icon: 'fa-yelp', web: 'fab fa-yelp' },
  // { icon: "fa-yen", web: "fa fa-yen" },
  { icon: 'fa-yoast', web: 'fab fa-yoast' },
  { icon: 'fa-youtube', web: 'fab fa-youtube' },
  // { icon: "fa-youtube-play", web: "fab fa-youtube-play" },
  { icon: 'fa-youtube-square', web: 'fab fa-youtube-square' },
];

export default icons;
