/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Parse from 'parse';
import {
  setEditCellItem,
  setInitialStateForTemplate,
} from '../../../actions/templateEdit';
import BodyCellItemObject from '../Models/BodyCellItem';
import BodyRow from '../BodyRow';

const loadMeasureSheetItemDetails = (objectId) => {
  const query = new Parse.Query('SSMeasureSheetItem');
  query.select([
    'subCategory',
    'itemName',
    'measurementType',
    'items.subCategory2',
    'items.displayTitle',
  ]);
  query.equalTo('objectId', objectId);
  return query.first();
};

class MeasureSheetItemDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Loading...',
      subtitle: '',
      measurementType: '',
      quantity: '',
      unitTotal: '',
      total: '',
    };
    this.linkedMeasureSheetItemId = props.cellItem.linkedMeasureSheetItem;
  }

  UNSAFE_componentWillMount() {
    this.loadMeasureSheetItemData();
  }

  shouldComponentUpdate(nextProps) {
    return !this.props.cellItem.isEqualTo(nextProps.cellItem);
  }

  componentDidUpdate() {
    if (
      this.props.cellItem.linkedMeasureSheetItem !==
      this.linkedMeasureSheetItemId
    ) {
      this.loadMeasureSheetItemData();
    }
  }

  onClick = () => {
    this.props.setEditItem(this.props.cellItem);
  };

  cellStyleForCell = (width, align) => ({
    minWidth: `${width}%`,
    maxWidth: `${width}%`,
    textAlign: align || undefined,
  });

  loadMeasureSheetItemData = async () => {
    try {
      const measureSheetItem = await loadMeasureSheetItemDetails(
        this.props.cellItem.linkedMeasureSheetItem,
      );
      this.linkedMeasureSheetItemId = this.props.cellItem.linkedMeasureSheetItem;
      if (!measureSheetItem) {
        throw new Parse.Error(500, 'Could not find item');
      }
      let title = measureSheetItem.get('itemName');
      if (measureSheetItem.get('subCategory')) {
        title = `${measureSheetItem.get('subCategory')} - ${title}`;
      }
      const items = measureSheetItem.get('items') || [];
      const firstOption = items[0];
      let subtitle;
      if (firstOption) {
        if (firstOption.get('subCategory2')) {
          subtitle = `${firstOption.get('subCategory2')} - ${firstOption.get(
            'displayTitle',
          )}`;
        } else {
          subtitle = firstOption.get('displayTitle');
        }
      }
      this.setState({
        title,
        subtitle,
        measurementType: measureSheetItem.get('measurementType'),
        quantity: '99',
        unitTotal: '$100.00',
        total: '$999.00',
      });
    } catch (e) {
      this.setState({
        title: 'Error loading item',
        subtitle: e.message,
        measurementType: '',
        quantity: '',
        unitTotal: '',
        total: '',
      });
    }
  };

  render() {
    const { cellItem } = this.props;
    const { linkedMeasureSheetItemConfig = {} } = cellItem;
    const {
      measurementType: showMeasurementType = false,
      quantity: showQuantity = false,
      subtitle: showSubtitle = false,
      title: showTitle = false,
      total: showTotal = false,
      unitTotal: showUnitTotal = false,
    } = linkedMeasureSheetItemConfig;
    const {
      title,
      subtitle,
      measurementType,
      quantity,
      unitTotal,
      total,
    } = this.state;
    return (
      <BodyRow cellItem={cellItem}>
        <div
          className="templates-app__measure_sheet_detail"
          onClick={this.onClick}
        >
          <div style={this.cellStyleForCell(100 / 2 / 2)}>
            {showTitle ? title : ''}
          </div>
          <div style={this.cellStyleForCell(100 / 2 / 2)}>
            {showSubtitle ? subtitle : ''}
          </div>
          <div style={this.cellStyleForCell((100 / 2 / 16) * 3, 'right')}>
            {showQuantity ? quantity : ''}
          </div>
          <div style={this.cellStyleForCell((100 / 2 / 16) * 6, 'right')}>
            {showMeasurementType ? measurementType : ''}
          </div>
          <div style={this.cellStyleForCell((100 / 2 / 16) * 3, 'right')}>
            {showUnitTotal ? unitTotal : ''}
          </div>
          <div style={this.cellStyleForCell((100 / 2 / 16) * 4, 'right')}>
            {showTotal ? total : ''}
          </div>
          <div className="templates-app__cell_bottom_border" />
        </div>
      </BodyRow>
    );
  }
}

MeasureSheetItemDetail.propTypes = {
  cellItem: PropTypes.instanceOf(BodyCellItemObject).isRequired,
  setEditItem: PropTypes.func.isRequired,
};

const mapStateToProps = ({ templatesEdit }) => ({
  template: templatesEdit[templatesEdit.currentWorkingId],
});

const mapDispatchToProps = (dispatch) => ({
  setEditItem: (cellItem) => dispatch(setEditCellItem(cellItem)),
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MeasureSheetItemDetail);
