/* eslint-disable no-shadow */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, Button, ButtonGroup } from 'react-bootstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import Panel from '../Panel';
import {
  startQueryUsers,
  startActivateUser,
  setUserToEdit,
  exportUsers,
} from '../../actions/users';
import FormGroup, { TextGroupDebounce } from '../FormGroup';
import Paginator from '../Misc/Paginator';
import TitleButton from '../TitleButton';
import { historyPropType } from '../../router';
import Switch from '../Misc/Switch';
import { pushToDataLayer } from '../../actions/tagManager';

export class UsersIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      isActiveFilter: 2,
      pagination: {
        page: 1,
        limit: 25,
      },
    };
  }

  componentDidMount() {
    this.queryUsers();

    const { setButtons, exportUsers } = this.props;
    setButtons([
      <TitleButton
        variant="success"
        onClick={this.onAddClicked}
        title="Add New User"
      />,
      <TitleButton
        variant="primary"
        onClick={exportUsers}
        title="Export Users"
      />,
    ]);
  }

  componentWillUnmount() {
    const { setButtons } = this.props;
    setButtons();
  }

  onPagenation = (paginationEvent) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          ...paginationEvent,
        },
      },
      this.queryUsers,
    );
  };

  onAddClicked = async () => {
    // have to rename history outside of render method, as it's a global name
    const { history: routerHistory } = this.props;
    await this.props.setUserToEdit(undefined);
    routerHistory.push('/users/new');
  };

  onActiveChanged = (objectId, active) => {
    this.props.startActivateUser(objectId, active);
  };

  onIsActiveFilterChanged = (active) => {
    this.props.pushToDataLayer({
      event: 'userListFilter',
      eventCategory: 'Users',
      eventAction: 'Filter List',
      eventLabel: active ? 'Active' : 'Inactive',
    });

    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
        isActiveFilter: active,
      },
      this.queryUsers,
    );
  };

  setSearchText = (searchTerm) => {
    this.props.pushToDataLayer({
      event: 'userListFilter',
      eventCategory: 'Users',
      eventAction: 'Filter List',
      eventLabel: 'String',
    });
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
        searchTerm,
      },
      this.queryUsers,
    );
  };

  queryUsers = () => {
    const { page, limit } = this.state.pagination;
    const { searchTerm, isActiveFilter } = this.state;

    const skip = limit * (page - 1);

    this.props.startQueryUsers({
      isActiveFilter,
      searchTerm,
      limit,
      skip,
    });
  };

  render() {
    return (
      <div className="default-page-padding">
        <Panel title="Users">
          <div>
            <TextGroupDebounce
              title="Filter"
              placeholder="Search Text"
              value={this.state.searchTerm}
              onChange={(value) => this.setSearchText(value)}
            />
            <FormGroup title="">
              <ButtonGroup>
                <Button
                  className="mx-1"
                  variant="light"
                  disabled={this.state.isActiveFilter === 2}
                  onClick={() => this.onIsActiveFilterChanged(2)}
                >
                  Active
                </Button>
                <Button
                  className="mx-1"
                  variant="light"
                  disabled={this.state.isActiveFilter === 1}
                  onClick={() => this.onIsActiveFilterChanged(1)}
                >
                  Inactive
                </Button>
                <Button
                  className="mx-1"
                  variant="light"
                  disabled={this.state.isActiveFilter === 0}
                  onClick={() => this.onIsActiveFilterChanged(0)}
                >
                  All
                </Button>
              </ButtonGroup>
            </FormGroup>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th style={{ width: '25%' }}>Name</th>
                  <th style={{ width: '25%' }}>Username</th>
                  <th style={{ width: '25%' }}>Last Login</th>
                  {this.props.maxOfficeCount !== 1 && (
                    <th style={{ width: '25%' }}>Current Office</th>
                  )}
                  <th style={{ width: '75px' }}>Action</th>
                  <th style={{ width: '75px' }}>Activate</th>
                </tr>
              </thead>
              <tbody>
                {this.props.items.map(
                  ({
                    objectId,
                    lastLoginDate,
                    nameFirst,
                    nameLast,
                    isActive,
                    username,
                    selectedOffice = { name: '-' },
                  }) => (
                    <tr key={objectId}>
                      <td>{`${nameLast}, ${nameFirst}`}</td>
                      <td>{username}</td>
                      <td>
                        {lastLoginDate
                          ? moment(lastLoginDate).format('M/D/YYYY h:mma')
                          : '-'}
                      </td>
                      {this.props.maxOfficeCount !== 1 && (
                        <td>{selectedOffice.name}</td>
                      )}
                      <td>
                        <Link to={`/users/edit/${objectId}`}>
                          <i className="fas fa-pencil-alt fill-cell center" />
                        </Link>
                      </td>
                      <td>
                        <Switch
                          checked={isActive}
                          onChange={() =>
                            this.onActiveChanged(objectId, !isActive)
                          }
                          large
                        />
                      </td>
                    </tr>
                  ),
                )}
              </tbody>
            </Table>
            <Paginator
              page={this.state.pagination.page}
              limit={this.state.pagination.limit}
              totalCount={this.props.count}
              onLimitChanged={({ value }) =>
                this.onPagenation({ page: 1, limit: value })
              }
              pageRange={5}
              onPageClicked={(event) => this.onPagenation({ page: event })}
            />
          </div>
        </Panel>
      </div>
    );
  }
}

UsersIndex.propTypes = {
  history: historyPropType.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      lastLoginDate: PropTypes.shape({
        iso: PropTypes.string,
      }),
      count: PropTypes.number,
      nameFirst: PropTypes.string,
      nameLast: PropTypes.string,
      isActive: PropTypes.bool,
      username: PropTypes.string.isRequired,
      selectedOffice: PropTypes.shape({
        name: PropTypes.string,
        objectId: PropTypes.string.isRequired,
      }),
    }),
  ),
  maxOfficeCount: PropTypes.number.isRequired,
  pushToDataLayer: PropTypes.func.isRequired,
  setUserToEdit: PropTypes.func.isRequired,
  startQueryUsers: PropTypes.func.isRequired,
  count: PropTypes.number,
  startActivateUser: PropTypes.func.isRequired,
  ...layoutContextPropTypes,
};

UsersIndex.defaultProps = {
  items: [],
  count: 0,
};

const mapStateToProps = ({ users, plan = {} }) => {
  const { searchTerm, isActiveFilter, count, items } = users;
  return {
    items,
    searchTerm,
    isActiveFilter,
    count,
    maxOfficeCount: plan.maxOfficeCount,
  };
};

const mapDispatchToProps = (dispatch) => ({
  startQueryUsers: (params) => dispatch(startQueryUsers(params)),
  setUserToEdit: (params) => dispatch(setUserToEdit(params)),
  startActivateUser: (userId, isActive) =>
    dispatch(startActivateUser(userId, isActive)),
  pushToDataLayer: (variablesForLayer) =>
    dispatch(pushToDataLayer(variablesForLayer)),
  exportUsers: () => dispatch(exportUsers()),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(UsersIndex),
);
