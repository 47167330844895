/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import Parse from 'parse';
import { randomKey } from '../../../utils/utils';
import HeaderSection from './HeaderSection';
import HeaderGroup from './HeaderGroup';
import HeaderItemGroup from './HeaderItemGroup';
import HeaderCell from './HeaderCell';
import BodySection from './BodySection';
import BodyGroup from './BodyGroup';
import BodyCellItem from './BodyCellItem';
import SignatureSection from './SignatureSection';
import SignatureItem from './SignatureItem';
import SignatureAdditionalLine from './SignatureAdditionalLine';
import ImageObject from './ImageObject';

const newSectionForObject = (section, parent) => {
  switch (section.groupType) {
    case 'header':
      return new HeaderSection(section, parent);
    case 'body':
      return new BodySection(section, parent);
    case 'signature':
      return new SignatureSection(section, parent);
    default:
      return {};
  }
};

const removeHeaderObjectWithId = (id, headerSection) => {
  const ids = id.split('-');
  if (ids.length === 3) {
    headerSection.data = headerSection.data.filter(
      ({ objectId }) => objectId !== ids[2],
    );
  } else {
    const section = headerSection.data.find(
      ({ objectId }) => objectId === ids[2],
    );
    if (ids.length === 4) {
      section.cellItems = section.cellItems.filter(
        (obj, index) => index !== parseInt(ids[3], 10),
      );
    } else if (ids.length === 5) {
      section.cellItems = section.cellItems.map((array, index) => {
        if (index !== parseInt(ids[3], 10)) {
          return array;
        }
        return array.filter(({ objectId }) => objectId !== ids[4]);
      });
    }
  }
};

const removeBodyObjectWithId = (id, bodySection) => {
  const ids = id.split('-');
  if (ids.length === 3) {
    bodySection.data = bodySection.data.filter(
      ({ objectId }) => objectId !== ids[2],
    );
  } else {
    const section = bodySection.data.find(
      ({ objectId }) => objectId === ids[2],
    );
    if (ids.length === 4) {
      section.cellItems = section.cellItems.filter(
        ({ objectId }) => objectId !== ids[3],
      );
    } else if (ids.length === 5) {
      const cellItem = section.cellItems.find(
        ({ objectId }) => objectId === ids[3],
      );
      cellItem.detailItems = cellItem.detailItems.filter(
        ({ objectId }) => objectId !== ids[4],
      );
    }
  }
};

const removeSignatureObjectWithId = (id, signatureSection) => {
  const ids = id.split('-');
  if (ids.length === 3) {
    signatureSection.data = signatureSection.data.filter(
      ({ objectId }) => objectId !== ids[2],
    );
  } else {
    const section = signatureSection.data.find(
      ({ objectId }) => objectId === ids[2],
    );
    if (ids.length === 4) {
      section.additionalLines = section.additionalLines.filter(
        ({ objectId }) => objectId !== ids[3],
      );
    }
  }
};

class ContractObject {
  constructor(data) {
    this.JSON = data;
    this.keys = [
      'objectId',
      'canAddMultiplePages',
      'wMargin',
      'hMargin',
      'order',
      'isTemplate',
      'type',
      'pageSize',
      'pageId',
      'category',
      'displayName',
      'iconBackgroundColor',
      'includedStates',
      'pdf',
      'iconImage',
      'watermark',
      'watermarkWidthPercent',
      'watermarkAlpha',
      'useWatermark',
      'images',
      'contractData',
      'includedOffices',
      'photosPerPage',
    ];
    if (data) {
      Object.assign(this, data);
      this.objectId = this.objectId || randomKey(10);
      const { contractData = [], images = [] } = data;
      this.contractData = contractData.map((section) =>
        newSectionForObject(section, this),
      );
      this.images = images.map((image) => new ImageObject(image, this));
    } else {
      this.canAddMultiplePages = false;
      this.wMargin = 20;
      this.hMargin = 35;
      this.order = 0;
      this.isTemplate = false;
      this.type = 'contract';
      this.pageSize = '612,792';
      this.pageId = 'singlePage';
      this.category = '';
      this.displayName = 'New Template';
      this.iconBackgroundColor = [255, 255, 255, 0];
      this.includedStates = [];
      this.pdf = undefined;
      this.iconImage = undefined;
      this.watermark = undefined;
      this.watermarkWidthPercent = 100;
      this.watermarkAlpha = 0.05;
      this.useWatermark = true;
      this.images = [];
      this.contractData = [
        new HeaderSection(undefined, this),
        new BodySection(undefined, this),
        new SignatureSection(undefined, this),
      ];
      this.includedOffices = [];
      this.photosPerPage = 2;
    }
  }

  get identifier() {
    return this.objectId;
  }

  get iconImage() {
    return this._iconImage;
  }

  set iconImage(value) {
    if (value) {
      if (value instanceof Parse.File) {
        this._iconImage = value;
        return;
      }
      const newIconImage = new Parse.File();
      newIconImage._url = value.url;
      newIconImage._name = value.name;
      this._iconImage = newIconImage;
    } else {
      delete this._iconImage;
    }
  }

  get watermark() {
    return this._watermark;
  }

  set watermark(value) {
    if (value) {
      if (value instanceof Parse.File) {
        this._watermark = value;
        return;
      }
      const newWatermark = new Parse.File();
      newWatermark._url = value.url;
      newWatermark._name = value.name;
      this._watermark = newWatermark;
    } else {
      delete this._watermark;
    }
  }

  getSourceObject() {
    return this;
  }

  addObjectId(objectId) {
    if (!objectId) {
      return;
    }
    const objectIds = this.objectIds || [];
    objectIds.push(objectId);
    this.objectIds = objectIds;
  }

  hasObjectId(objectId) {
    if (!this.objectIds) {
      return false;
    }
    return this.objectIds.indexOf(objectId) > -1;
  }

  toJSON() {
    const json = {};
    this.keys.forEach((key) => {
      json[key] = this[key];
    });
    json.contractData = this.contractData.map((obj) => obj.toJSON());
    json.images = this.images.map((image) => image.toJSON());
    return json;
  }

  deleteObject(object) {
    if (object) {
      this.deleteObjectAtPath(object.getPath());
    }
  }

  getObjectWithId(id) {
    for (let i = 0; i < this.contractData.length; i += 1) {
      const section = this.contractData[i];
      if (section.objectId === id) {
        return section;
      }
      const { data = [] } = section;
      for (let j = 0; j < data.length; j += 1) {
        const group = data[j];
        if (group.objectId === id) {
          return group;
        }
        const { cellItems = [], additionalLines = [] } = group;
        for (let k = 0; k < cellItems.length; k += 1) {
          const cellItem = cellItems[k];
          if (cellItem.objectId === id) {
            return cellItem;
          }
          const {
            detailItems = [],
            signatures = [],
            header = {},
            cellItems: headerCellItems = [],
          } = cellItem;
          for (let l = 0; l < headerCellItems.length; l += 1) {
            if (headerCellItems[l].objectId === id) {
              return headerCellItems[l];
            }
          }
          for (let l = 0; l < detailItems.length; l += 1) {
            if (detailItems[l].objectId === id) {
              return detailItems[l];
            }
          }
          for (let l = 0; l < signatures.length; l += 1) {
            if (signatures[l].objectId === id) {
              return signatures[l];
            }
            const { additionalLines: subAddLines = [] } = signatures[l];
            for (let m = 0; m < subAddLines.length; m += 1) {
              if (subAddLines[m].objectId === id) {
                return subAddLines[m];
              }
            }
          }
          const { cellItems: subCellItems = [] } = header;
          for (let l = 0; l < subCellItems.length; l += 1) {
            const lineItemGroup = subCellItems[l];
            if (lineItemGroup.objectId === id) {
              return lineItemGroup;
            }
            for (let m = 0; m < lineItemGroup.cellItems.length; m += 1) {
              if (lineItemGroup.cellItems[m].objectId === id) {
                return lineItemGroup.cellItems[m];
              }
            }
          }
        }
        for (let k = 0; k < additionalLines.length; k += 1) {
          const additionalLine = additionalLines[k];
          if (additionalLine.objectId === id) {
            return additionalLine;
          }
        }
      }
    }
    return undefined;
  }

  getItemAtPath(path = []) {
    if (!path.length) {
      return undefined;
    }
    const { groupType } = this.contractData[path[0]];
    switch (path.length) {
      case 1:
        return this.contractData[path[0]];
      case 2:
        return this.contractData[path[0]].data[path[1]];
      case 3:
        switch (groupType) {
          case 'header':
          case 'body':
            return this.contractData[path[0]].data[path[1]].cellItems[path[2]];
          case 'signature':
            return this.contractData[path[0]].data[path[1]].additionalLines[
              path[2]
            ];
          default:
            return undefined;
        }
      case 4:
        switch (groupType) {
          case 'body': {
            const cellItem = this.contractData[path[0]].data[path[1]].cellItems[
              path[2]
            ];
            switch (cellItem.cellType) {
              case 'signatures':
                return cellItem.signatures[path[3]];
              case 'header':
                return cellItem.header;
              default:
                return cellItem.detailItems[path[3]];
            }
          }
          case 'header':
            return this.contractData[path[0]].data[path[1]].cellItems[path[2]]
              .cellItems[path[3]];
          default:
            return undefined;
        }
      case 5:
        switch (groupType) {
          case 'body': {
            const cellItem = this.contractData[path[0]].data[path[1]].cellItems[
              path[2]
            ];
            switch (cellItem.cellType) {
              case 'header':
                return cellItem.header.cellItems[path[4]];
              default:
                return undefined;
            }
          }
          default:
            return undefined;
        }
      case 6:
        switch (groupType) {
          case 'body': {
            const cellItem = this.contractData[path[0]].data[path[1]].cellItems[
              path[2]
            ];
            switch (cellItem.cellType) {
              case 'header':
                return cellItem.header.cellItems[path[4]].cellItems[path[5]];
              default:
                return undefined;
            }
          }
          default:
            return undefined;
        }
      default:
        return undefined;
    }
  }

  deleteObjectAtPath(path) {
    const { groupType } = this.contractData[path[0]];
    switch (path.length) {
      case 1:
        this.contractData.splice(path[0], 1);
        break;
      case 2:
        this.contractData[path[0]].data.splice(path[1], 1);
        break;
      case 3:
        switch (groupType) {
          case 'header':
          case 'body':
            this.contractData[path[0]].data[path[1]].cellItems.splice(
              path[2],
              1,
            );
            break;
          case 'signature':
            this.contractData[path[0]].data[path[1]].additionalLines.splice(
              path[2],
              1,
            );
            break;
          default:
            break;
        }
        break;
      case 4:
        switch (groupType) {
          case 'body': {
            const cellItem = this.contractData[path[0]].data[path[1]].cellItems[
              path[2]
            ];
            switch (cellItem.cellType) {
              case 'signatures':
                cellItem.signatures.splice(path[3], 1);
                break;
              case 'header':
                break;
              default:
                cellItem.detailItems.splice(path[3], 1);
                break;
            }
            break;
          }
          case 'header':
            this.contractData[path[0]].data[path[1]].cellItems[
              path[2]
            ].cellItems.splice(path[3], 1);
            break;
          default:
            break;
        }
        break;
      case 5:
        switch (groupType) {
          case 'body': {
            const cellItem = this.contractData[path[0]].data[path[1]].cellItems[
              path[2]
            ];
            switch (cellItem.cellType) {
              case 'header':
                cellItem.header.cellItems.splice(path[4], 1);
                break;
              default:
                break;
            }
            break;
          }
          default:
            break;
        }
        break;
      case 6:
        switch (groupType) {
          case 'body': {
            const cellItem = this.contractData[path[0]].data[path[1]].cellItems[
              path[2]
            ];
            switch (cellItem.cellType) {
              case 'header':
                cellItem.header.cellItems[path[4]].cellItems.splice(path[5], 1);
                break;
              default:
                break;
            }
            break;
          }
          default:
            break;
        }
        break;
      default:
        break;
    }
  }

  insertObjectAtPath(path, object, position = 1) {
    const { groupType } = this.contractData[path[0]];
    switch (path.length) {
      case 1:
        object.parent = this;
        this.contractData.splice(path[0] + position, 0, object);
        break;
      case 2: {
        const parent = this.contractData[path[0]];
        object.parent = parent;
        parent.data.splice(path[1] + position, 0, object);
        break;
      }
      case 3:
        switch (groupType) {
          case 'header':
          case 'body': {
            const parent = this.contractData[path[0]].data[path[1]];
            object.parent = parent;
            parent.cellItems.splice(path[2] + position, 0, object);
            break;
          }
          case 'signature': {
            const parent = this.contractData[path[0]].data[path[1]];
            object.parent = parent;
            parent.additionalLines.splice(path[2] + position, 0, object);
            break;
          }
          default:
            break;
        }
        break;
      case 4:
        switch (groupType) {
          case 'body': {
            const parent = this.contractData[path[0]].data[path[1]].cellItems[
              path[2]
            ];
            switch (parent.cellType) {
              case 'signatures':
                object.parent = parent;
                parent.signatures.splice(path[3] + position, 0, object);
                break;
              case 'header':
                break;
              default:
                object.parent = parent;
                parent.detailItems.splice(path[3] + position, 0, object);
                break;
            }
            break;
          }
          case 'header': {
            const parent = this.contractData[path[0]].data[path[1]].cellItems[
              path[2]
            ];
            object.parent = parent;
            parent.cellItems.splice(path[3] + position, 0, object);
            break;
          }
          default:
            break;
        }
        break;
      case 5:
        switch (groupType) {
          case 'body': {
            const parent = this.contractData[path[0]].data[path[1]].cellItems[
              path[2]
            ];
            switch (parent.cellType) {
              case 'header':
                object.cellItems.forEach((obj) => {
                  obj.parent = parent.header;
                });
                parent.header.cellItems.splice(path[4] + position, 0, object);
                break;
              default:
                break;
            }
            break;
          }
          default:
            break;
        }
        break;
      case 6:
        switch (groupType) {
          case 'body': {
            const cellItem = this.contractData[path[0]].data[path[1]].cellItems[
              path[2]
            ];
            switch (cellItem.cellType) {
              case 'header': {
                const parent = cellItem.header;
                object.parent = parent;
                parent.cellItems[path[4]].cellItems.splice(
                  path[5] + position,
                  0,
                  object,
                );
                break;
              }
              default:
                break;
            }
            break;
          }
          default:
            break;
        }
        break;
      default:
        break;
    }
  }

  pasteObjectAfterNode({ object }, nodePath, position = 1) {
    this.insertObjectAtPath(nodePath, object, position);
  }

  addHeaderSection() {
    this.contractData.push(new HeaderSection(undefined, this));
  }

  addBodySection() {
    this.contractData.push(new BodySection(undefined, this));
  }

  addSignatureSection() {
    this.contractData.push(new SignatureSection(undefined, this));
  }

  hasBodySection() {
    return this.contractData.some(({ groupType }) => groupType === 'body');
  }

  insertNewObjectAtPath(path) {
    const { groupType } = this.contractData[path[0]];
    let object;
    switch (path.length) {
      case 1:
        switch (groupType) {
          case 'header':
            object = new HeaderGroup();
            break;
          case 'body':
            object = new BodyGroup();
            break;
          case 'signature':
            object = new SignatureItem();
            break;
          default:
            break;
        }
        break;
      case 2:
        switch (groupType) {
          case 'header':
            object = new HeaderItemGroup();
            break;
          case 'body':
            object = new BodyCellItem();
            break;
          case 'signature':
            object = new SignatureAdditionalLine();
            break;
          default:
            break;
        }
        break;
      case 3:
        switch (groupType) {
          case 'header':
            object = new HeaderCell();
            break;
          case 'body':
            object = new BodyCellItem();
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
    if (object) {
      // eslint-disable-next-line max-len
      // this.insertObjectAtPath(path, object); TODO: Fix this so that it will insert a child item into the parent path passed in
    }
  }

  deleteObjectWithId(id) {
    const ids = id.split('-');
    if (ids.length === 2) {
      this.contractData = this.contractData.filter(
        ({ objectId }) => objectId !== ids[1],
      );
    } else {
      const section = this.contractData.find(
        ({ objectId }) => objectId === ids[1],
      );
      const { groupType } = section;
      switch (groupType) {
        case 'header':
          removeHeaderObjectWithId(id, section);
          break;
        case 'body':
          removeBodyObjectWithId(id, section);
          break;
        case 'signature':
          removeSignatureObjectWithId(id, section);
          break;
        default:
          break;
      }
    }
  }

  objectFromIdentifier(id = '') {
    const ids = id.split('-');
    const section = this.contractData.find(
      ({ objectId }) => objectId === ids[1],
    );
    if (!section) {
      return undefined;
    }
    switch (ids.length) {
      case 2:
        return section;
      case 3:
        return section.data.find(({ objectId }) => objectId === ids[2]);
      case 4:
        switch (section.groupType) {
          case 'header':
            return section.data
              .find(({ objectId }) => objectId === ids[2])
              .cellItems.find((array, index) => index === parseInt(ids[3], 10));
          case 'body':
            return section.data
              .find(({ objectId }) => objectId === ids[2])
              .cellItems.find(({ objectId }) => objectId === ids[3]);
          case 'signature':
            return section.data
              .find(({ objectId }) => objectId === ids[2])
              .additionalLines.find(({ objectId }) => objectId === ids[3]);
          default:
            return undefined;
        }
      case 5:
        switch (section.groupType) {
          case 'header':
            return section.data
              .find(({ objectId }) => objectId === ids[2])
              .cellItems.find((array, index) => index === parseInt(ids[3], 10))
              .find(({ objectId }) => objectId === ids[4]);
          case 'body': {
            const bodyGroup = section.data.find(
              ({ objectId }) => objectId === ids[2],
            );
            const cellItem = bodyGroup.cellItems.find(
              ({ objectId }) => objectId === ids[3],
            );
            switch (cellItem.cellType) {
              case 'signatures':
                return cellItem.signatures.find(
                  ({ objectId }) => objectId === ids[4],
                );
              default:
                return cellItem.detailItems.find(
                  ({ objectId }) => objectId === ids[4],
                );
            }
          }
          default:
            return undefined;
        }
      case 6:
        switch (section.groupType) {
          case 'body': {
            const bodyGroup = section.data.find(
              ({ objectId }) => objectId === ids[2],
            );
            const cellItem = bodyGroup.cellItems.find(
              ({ objectId }) => objectId === ids[3],
            );
            switch (cellItem.cellType) {
              case 'header':
                return undefined; // fix this
              case 'signatures': {
                const signatureItem = cellItem.signatures.find(
                  ({ objectId }) => objectId === ids[4],
                );
                return signatureItem.additionalLines.find(
                  ({ objectId }) => objectId === ids[5],
                );
              }
              default:
                return undefined;
            }
          }
          default:
            return undefined;
        }
      case 7:
        switch (section.groupType) {
          case 'body': {
            const bodyGroup = section.data.find(
              ({ objectId }) => objectId === ids[2],
            );
            const cellItem = bodyGroup.cellItems.find(
              ({ objectId }) => objectId === ids[3],
            );
            switch (cellItem.cellType) {
              case 'header': {
                return cellItem.header.cellItems
                  .find((array, index) => index === parseInt(ids[5], 10))
                  .find(({ objectId }) => objectId === ids[6]);
              }
              default:
                return undefined;
            }
          }
          default:
            return undefined;
        }
      default:
        return undefined;
    }
  }

  dynamicValueOptionsForCellId(cellId) {
    const bodySection =
      this.contractData.find(({ groupType }) => groupType === 'body') || {};
    const { data = [] } = bodySection;
    for (let i = 0; i < data.length; i += 1) {
      const { cellItems = [] } = data[i];
      let cellItem = cellItems.find(({ cellId: id }) => id === cellId);
      if (!cellItem) {
        cellItems.forEach((item) => {
          if (item.cellType === 'detailCell' && !cellItem) {
            cellItem = item.detailItems.find(({ cellId: id }) => id === cellId);
          }
        });
      }
      if (cellItem) {
        if (cellItem.cellType === 'switched') {
          return [cellItem.switchValueOn, cellItem.switchValueOff];
        }
        if (cellItem.inputType === 'picker') {
          return cellItem.pickerValues;
        }
      }
    }
    return [];
  }

  async convertNewFiles() {
    if (this.iconImage && this.iconImage.isNew) {
      delete this.iconImage.isNew;
      delete this.iconImage._url;
      await this.iconImage.save();
    }

    if (this.watermark && this.watermark.isNew) {
      delete this.watermark.isNew;
      delete this.watermark._url;
      await this.watermark.save();
    }
    if (this.pdf && this.pdf.isNew) {
      delete this.pdf.isNew;
      delete this.pdf._url;
      await this.pdf.save();
    }
    const promises = this.contractData.map((section) =>
      section.convertNewFiles(),
    );
    this.images.forEach((image) => {
      promises.push(image.convertNewFiles());
    });
    return Promise.all(promises);
  }

  getAllCellIdentifiers(excluding) {
    const ids = [];
    const bodySection =
      this.contractData.find(({ groupType }) => groupType === 'body') || {};
    const { data = [] } = bodySection;
    data.forEach(({ cellItems = [] }) => {
      cellItems.forEach(({ cellId, detailItems = [] }) => {
        if (cellId && ids.indexOf(cellId) === -1 && cellId !== excluding) {
          ids.push(cellId);
        }
        detailItems.forEach(({ cellId: cellId2 }) => {
          if (cellId2 && ids.indexOf(cellId2) === -1 && cellId2 !== excluding) {
            ids.push(cellId2);
          }
        });
      });
    });
    return ids.sort((a, b) => b - a);
  }

  async saveToServer() {
    await this.convertNewFiles();
    const template = this.toJSON();
    if (template.objectId.split('_').length > 1) {
      // This is a new object
      delete template.objectId;
    }

    const object = new Parse.Object('ContractObject', template);
    if (!template.iconImage) {
      object.unset('iconImage');
    }
    if (!template.watermark) {
      object.unset('watermark');
    }

    await object.save();
    this.objectId = object.id;
    return this;
  }

  async saveCopyToServer() {
    await this.convertNewFiles();
    const template = this.toJSON();
    delete template.objectId;
    // template.displayName = `${template.displayName}${!fromTemplate ? ' (copy)' : ''}`;
    const object = new Parse.Object('ContractObject', template);
    await object.save();
    const json = object.toJSON();

    const copy = new ContractObject(json);
    return copy;
  }

  async revert() {
    // Reset original back to server settings
    const original = new Parse.Object('ContractObject');
    original.id = this.objectId;
    original.revert();
    await original.fetch();
    const originalJSON = original.toJSON();
    Object.assign(this, originalJSON);
    const { contractData = [], images = [] } = originalJSON;
    this.contractData = contractData.map((section) =>
      newSectionForObject(section, this),
    );
    this.images = images.map((image) => new ImageObject(image, this));
    return this;
  }
}

export default ContractObject;
