/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { startUpdateConfig } from '../../actions/auth';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import {
  TextGroup,
  SwitchGroup,
  DropDownGroup,
  NumberGroup,
} from '../FormGroup';
import Panel from '../Panel';
import TitleButton from '../TitleButton';
import Copy from './Copy';

export const dropDownOptions = [
  {
    value: 999999999,
    label: 'Forever',
  },
  {
    value: 60 * 60 * 24,
    label: '1 Day',
  },
  {
    value: 60 * 60 * 24 * 2,
    label: '2 Days',
  },
  {
    value: 60 * 60 * 24 * 3,
    label: '3 Days',
  },
  {
    value: 60 * 60 * 24 * 4,
    label: '4 Days',
  },
  {
    value: 60 * 60 * 24 * 5,
    label: '5 Days',
  },
  {
    value: 60 * 60 * 24 * 6,
    label: '6 Days',
  },
  {
    value: 60 * 60 * 24 * 7,
    label: '1 Week',
  },
  {
    value: 60 * 60 * 24 * 14,
    label: '2 Weeks',
  },
  {
    value: 60 * 60 * 24 * 21,
    label: '3 Weeks',
  },
  {
    value: 60 * 60 * 24 * 30,
    label: '30 Days',
  },
  {
    value: 60 * 60 * 24 * 30 * 6,
    label: '6 Months',
  },
  {
    value: 60 * 60 * 24 * 365,
    label: '1 Year',
  },
];

export class NewUserDefaults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      additionalMinimumAmount: props.additionalMinimumAmount,
      isActive: props.isActive,
      licenseNumber: props.licenseNumber,
      searchableTimeCustomer: props.searchableTimeCustomer,
      canSubmitCreditApps: props.canSubmitCreditApps,
    };
  }

  componentDidMount() {
    const {
      setCrumbs,
      setButtons,
      location: { pathname },
    } = this.props;

    const crumb = { title: 'New User Defaults', link: pathname };
    setCrumbs([crumb]);
    setButtons(
      <TitleButtons
        onCopyClicked={this.onCopyClicked}
        onSaveClicked={this.onSaveClicked}
      />,
    );
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      additionalMinimumAmount: newProps.additionalMinimumAmount,
      isActive: newProps.isActive,
      licenseNumber: newProps.licenseNumber,
      searchableTimeCustomer: newProps.searchableTimeCustomer,
      canSubmitCreditApps: newProps.canSubmitCreditApps,
    });
  }

  componentWillUnmount() {
    const { setCrumbs, setButtons } = this.props;

    setCrumbs([]);
    setButtons();
  }

  onCopyClicked = () => {
    this.setState({ showCopy: true });
  };

  onSaveClicked = () => {
    this.props.startUpdateConfig({
      newUserDefaults: {
        additionalMinimumAmount:
          parseFloat(this.state.additionalMinimumAmount) || 0,
        isActive: this.state.isActive || false,
        licenseNumber: this.state.licenseNumber || '',
        searchableTimeCustomer: this.state.searchableTimeCustomer || 0,
        canSubmitCreditApps: this.state.canSubmitCreditApps || false,
      },
    });
  };

  onAdditionalMinimumAmountChange = (value) => {
    this.setState({ additionalMinimumAmount: value });
  };

  onIsActiveChange = (value) => {
    this.setState({ isActive: value });
  };

  onLicenseNumberChange = (value) => {
    this.setState({ licenseNumber: value });
  };

  onSearchableTimeCustomerChange = ({ value }) => {
    this.setState({ searchableTimeCustomer: value });
  };

  onCanSubmitCreditAppsChange = (value) => {
    this.setState({ canSubmitCreditApps: value });
  };

  render() {
    return (
      <>
        <Copy
          title="Copy New User Defaults"
          show={this.state.showCopy}
          warning="Warning! This will overwrite your current New User Default settings"
          configKeys={['newUserDefaults']}
          onClose={() => this.setState({ showCopy: false })}
        />
        <div className="default-page-padding">
          <Panel title="These settings will be added to all new users">
            <div>
              <NumberGroup
                title="Additional Amount"
                value={this.state.additionalMinimumAmount}
                placeholder="Additional Amount"
                onChange={this.onAdditionalMinimumAmountChange}
              />
              <SwitchGroup
                title="Submit Credit Apps"
                checked={this.state.canSubmitCreditApps}
                onChange={this.onCanSubmitCreditAppsChange}
              />
              <SwitchGroup
                title="Active"
                checked={this.state.isActive}
                onChange={this.onIsActiveChange}
              />
              <TextGroup
                title="License Number"
                value={this.state.licenseNumber}
                placeholder="License Number"
                onChange={this.onLicenseNumberChange}
              />
              <DropDownGroup
                title="Search Estimates"
                value={
                  dropDownOptions.filter(
                    (option) =>
                      option.value === this.state.searchableTimeCustomer,
                  )[0]
                }
                onChange={this.onSearchableTimeCustomerChange}
                closeMenuOnSelect
                options={dropDownOptions}
                isClearable={false}
              />
            </div>
          </Panel>
        </div>
      </>
    );
  }
}

NewUserDefaults.propTypes = {
  additionalMinimumAmount: PropTypes.number,
  isActive: PropTypes.bool,
  licenseNumber: PropTypes.string,
  searchableTimeCustomer: PropTypes.number,
  canSubmitCreditApps: PropTypes.bool,
  startUpdateConfig: PropTypes.func.isRequired,
  ...layoutContextPropTypes,
};

NewUserDefaults.defaultProps = {
  additionalMinimumAmount: 0,
  isActive: true,
  licenseNumber: '',
  searchableTimeCustomer: 999999999,
  canSubmitCreditApps: false,
};

const TitleButtons = ({ onCopyClicked, onSaveClicked }) => (
  <>
    <TitleButton
      variant="primary"
      onClick={() => onCopyClicked({ source: 'copy' })}
      title="Copy"
    />
    <TitleButton
      variant="success"
      onClick={() => onSaveClicked({ source: 'save' })}
      title="Save"
    />
  </>
);

TitleButtons.propTypes = {
  onCopyClicked: PropTypes.func.isRequired,
  onSaveClicked: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth: { config = {} } }) => ({
  ...config.newUserDefaults,
});

const mapDispatchToProps = (dispatch) => ({
  startUpdateConfig: (updates) => dispatch(startUpdateConfig(updates)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(NewUserDefaults),
);
