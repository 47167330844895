/* eslint-disable jsx-a11y/label-has-associated-control */
import { v4 } from 'uuid';
import React from 'react';
import PropTypes from 'prop-types';
import { Form, Col } from 'react-bootstrap';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { Button, ToggleSwitch } from '../../../../Leapstrap';
import InputTypeDropDown from './InputTypeDropDown';
import SalesForceFieldTypeDropDown from './SalesForceFieldTypeDropDown';
import ValidationForm from '../../../../SharedComponents/ValidationForm';
import MinMaxDimentionWidget from '../../../../SharedComponents/MinMaxDimentionWidget';
import ResultsDraggable from '../ResultsDraggable';
import DynamicInputSwitch from './DynamicInputSwitch';

import './styles.scss';

const ResultsForm = (props) => {
  const {
    object = {},
    onDeleteClicked,
    errors = { objects: {} },
    index,
    endpoints,
    hasTotalInputTypeSelected,
    onSalesforceValueChange,
    jobNimbusWorkflowTypes,
    onUpdateInputType,
    onValueChange,
    ...rest
  } = props;
  const { title, inputType, required, salesforce = {} } = object;

  const errorObject = errors.objects[index] || {};
  const showDocLinking = rest.flags && rest.flags['document-linking'];

  return (
    <ResultsDraggable draggableId={object.id} index={index}>
      <>
        <Form.Row className="my-1 mx-0 row w-100">
          <Col className="col-12 col-md-3">
            <ValidationForm title="Title" errorMessage={errorObject.title}>
              <Form.Control
                name="title"
                onChange={(event) => {
                  onValueChange(event.target.value, 'title');
                }}
                type="text"
                placeholder={undefined}
                value={title}
              />
            </ValidationForm>
          </Col>

          <Col className="col-12 col-md-3">
            <ValidationForm
              title="Input Type"
              errorMessage={errorObject.inputType}
            >
              <InputTypeDropDown
                value={inputType}
                endpoints={endpoints}
                hasTotalInputTypeSelected={hasTotalInputTypeSelected}
                onChange={(e) => onUpdateInputType(e, inputType)}
                additionalValues={jobNimbusWorkflowTypes}
                showDocLinking={showDocLinking}
              />
            </ValidationForm>
          </Col>

          <Col className="col-12 col-md-3 ">
            <DynamicInputSwitch {...props} showDocLinking={showDocLinking} />
          </Col>

          <Col className="col-12 col-md-3 d-flex flex-wrap align-items-center justify-content-around">
            <ValidationForm className="d-flex flex-column">
              <label className="labeled-switch">Required</label>
              <ToggleSwitch
                name={`${v4()}`}
                checked={required}
                onChange={({ target }) => {
                  onValueChange(target.checked, 'required');
                }}
              />
            </ValidationForm>
            <Button onClick={() => onDeleteClicked()} color="orange">
              Delete
            </Button>
          </Col>
        </Form.Row>
        <MinMaxDimentionWidget
          index={index}
          onChange={(event) => {
            const { name, value } = event.currentTarget;
            onValueChange(value, name);
          }}
          detailObject={object}
          errors={errors}
        />
        {endpoints?.length && endpoints.indexOf('Salesforce') > -1 && (
          <Form.Row className="my-1 mx-0 row w-100">
            <Col className="col-12 col-md-3">
              <ValidationForm
                title="Salesforce Field"
                errorMessage={(errorObject.salesforce || {}).field}
              >
                <Form.Control
                  onChange={(event) => {
                    onSalesforceValueChange(event.target.value, 'field');
                  }}
                  type="text"
                  value={salesforce.field}
                />
              </ValidationForm>
            </Col>

            <Col className="col-12 col-md-3">
              <ValidationForm
                title="SF Data Type"
                errorMessage={(errorObject.salesforce || {}).type}
              >
                <SalesForceFieldTypeDropDown
                  value={salesforce.type || 'string'}
                  onChange={(value) => {
                    onSalesforceValueChange(value, 'type');
                  }}
                />
              </ValidationForm>
            </Col>
          </Form.Row>
        )}
      </>
    </ResultsDraggable>
  );
};

ResultsForm.propTypes = {
  endpoints: PropTypes.arrayOf(PropTypes.string).isRequired,
  object: PropTypes.shape({}).isRequired,
  onDeleteClicked: PropTypes.func.isRequired,
  errors: PropTypes.shape({}).isRequired,
  hasTotalInputTypeSelected: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
  onUpdateInputType: PropTypes.func.isRequired,
  onSalesforceValueChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  jobNimbusWorkflowTypes: PropTypes.shape({}).isRequired,
};

export default withLDConsumer()(ResultsForm);
