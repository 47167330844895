/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';
import Parse from 'parse';
import { connect } from 'react-redux';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import { startFetchCompanies } from '../../actions/config';
import { startMoveUserToCompany } from '../../actions/admin';
import { DropDownGroup, ButtonGroup } from '../FormGroup';
import Panel from '../Panel';

export class ChangeCompanyPage extends React.Component {
  constructor(props) {
    super(props);
    const companies = props.companies || [];
    this.state = {
      companies: companies.map((company) => ({
        value: company.id,
        label: company.get('name'),
      })),
      selectedCompany: {
        value: props.selectedCompany,
        label: props.selectedCompany.get('name'),
      },
    };
    if (!companies.length) {
      props.startFetchCompanies();
    }
  }

  componentDidMount() {
    const {
      setCrumbs,
      location: { pathname },
    } = this.props;

    const crumb = { title: 'Change Company', link: pathname };
    setCrumbs([crumb]);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const newCompanies = newProps.companies;
    const companies = newCompanies.map((company) => ({
      value: company.id,
      label: company.get('name'),
    }));
    this.setState({
      companies,
    });
  }

  componentWillUnmount() {
    const { setCrumbs } = this.props;
    setCrumbs([]);
  }

  onSaveClicked = () => {
    this.props.startMoveUserToCompany();
    if (this.state.selectedCompany && this.state.selectedCompany.value) {
      this.props.startMoveUserToCompany(this.state.selectedCompany.value);
    }
  };

  onCompanyChange = (e) => {
    this.setState({ selectedCompany: e });
  };

  render() {
    return (
      <div className="default-page-padding">
        <Panel title="Change Company">
          <DropDownGroup
            title="Select Destination Company"
            value={this.state.selectedCompany}
            onChange={this.onCompanyChange}
            options={this.state.companies}
            isDisabled={!this.state.companies}
            // isLoading={!this.state.companies}
            isClearable={false}
            backspaceRemovesValue={false}
            closeMenuOnSelect
          />
          <ButtonGroup
            title=""
            buttonTitle="Save"
            onClick={this.onSaveClicked}
            variant="success"
            disabled={!this.state.selectedCompany}
          />
        </Panel>
      </div>
    );
  }
}

ChangeCompanyPage.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.instanceOf(Parse.Object)),
  startFetchCompanies: PropTypes.func.isRequired,
  startMoveUserToCompany: PropTypes.func.isRequired,
  selectedCompany: PropTypes.instanceOf(Parse.Object).isRequired,
  ...layoutContextPropTypes,
};

ChangeCompanyPage.defaultProps = {
  companies: [],
};

const mapStateToProps = ({
  config: { companies = [] },
  auth: { company },
}) => ({
  companies,
  selectedCompany: company,
});

const mapDispatchToProps = (dispatch) => ({
  startFetchCompanies: () => dispatch(startFetchCompanies()),
  startMoveUserToCompany: (companyId) =>
    dispatch(startMoveUserToCompany(companyId)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(ChangeCompanyPage),
);
