/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
import Parse from 'parse';
import { handleError } from './auth';
import { history } from '../router';
import AppToaster from '../utils/AppToaster';
import { showLoader, hideLoader } from './loading';

export const setTemplates = (templateType, templates) => ({
  type: 'TEMPLATES_SET_TEMPLATES',
  templateType,
  templates,
});

export const queryForTemplates = async (templateType, state) => {
  const { auth, templates } = state;
  const templateValues = templates[templateType] || {};
  const { offices: allOffices = [] } = auth;
  const { includedStatesFilter = [], page = 1, limit = 10 } = templateValues;

  const {
    auth: { offices },
    plan = {},
  } = state;

  let { includedOfficesFilter = [] } = templateValues;

  if (plan.maxOfficeCount === 1) {
    includedOfficesFilter = [offices[0].id];
  }

  const query = new Parse.Query('ContractObject');
  query.equalTo('type', templateType);
  query.notEqualTo('isTemplate', true);
  query.ascending('order');
  query.limit(limit);
  query.skip(limit * (page - 1));
  // Included offices
  if (includedOfficesFilter.length) {
    const offices = includedOfficesFilter.map((objectId) => {
      const office = new Parse.Object('Office');
      office.id = objectId;
      return office;
    });
    query.containedIn('includedOffices', offices);
  } else {
    query.notContainedIn('includedOffices', allOffices);
  }
  // Incldued States
  if (includedStatesFilter.length) {
    query.containedIn('includedStates', includedStatesFilter);
  } else {
    const statesKey = auth.company.get('statesKey');
    const config = await Parse.Config.get();
    const states = config.attributes[statesKey];
    query.notContainedIn('includedStates', states);
  }
  return query;
};

export const setSelectedContractObjectGroup = (contractObjectGroup) => ({
  type: 'SET_SELECTED_CONTRACT_OBJECT_GROUPS',
  contractObjectGroup,
});

export const setContractObjectGroups = (contractObjectGroups) => ({
  type: 'SET_CONTRACT_OBJECT_GROUPS',
  contractObjectGroups,
});

export const setTemplateCount = (templateType, count) => ({
  type: 'TEMPLATES_SET_COUNT',
  templateType,
  count,
});

export const startCountTemplates = (templateType) => async (
  dispatch,
  getState,
) => {
  try {
    const query = await queryForTemplates(templateType, getState());
    const count = await query.count();
    dispatch(setTemplateCount(templateType, count));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const startFetchTemplates = (templateType) => async (
  dispatch,
  getState,
) => {
  try {
    const query = await queryForTemplates(templateType, getState());
    query.select([
      'displayName',
      'iconImage',
      'iconBackgroundColor',
      'order',
      'type',
    ]);
    const objects = await query.find();
    dispatch(setTemplates(templateType, objects));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const startFetchTemplatesToCopy = (templateType) => async (dispatch) => {
  try {
    const query = new Parse.Query('ContractObject');
    query.equalTo('type', templateType);
    query.equalTo('isTemplate', true);
    const objects = await query.find();
    dispatch(setTemplates(templateType, objects));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const startFetchContractObjectGroup = (id) => async (dispatch) => {
  try {
    const ContractObjectGroup = new Parse.Object('ContractObjectGroup');
    ContractObjectGroup.id = id;
    await ContractObjectGroup.fetch();
    const contractObjectQuery = ContractObjectGroup.relation(
      'contractObjects',
    ).query();
    const contractObjects = await contractObjectQuery.find();
    const groupData = ContractObjectGroup.toJSON();
    dispatch(
      setSelectedContractObjectGroup({
        ...groupData,
        contractObjects,
      }),
    );
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const startFetchContractObjectGroups = () => async (dispatch) => {
  try {
    const query = new Parse.Query('ContractObjectGroup');
    query.select(['thumbnail', 'contractObjects', 'name']);
    const contractObjectGroups = await query.find();
    dispatch(setContractObjectGroups(contractObjectGroups));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const setIncludedOfficesFilter = (
  templateType,
  includedOfficesFilter,
) => ({
  type: 'TEMPLATES_SET_INCLUDED_OFFICES_FILTER',
  templateType,
  includedOfficesFilter,
});

export const startSetIncludedOfficesFilter = (
  templateType,
  includedOfficesFilter,
) => async (dispatch) => {
  try {
    await dispatch(
      setIncludedOfficesFilter(templateType, includedOfficesFilter),
    );
    dispatch(startCountTemplates(templateType));
    dispatch(startFetchTemplates(templateType));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const setIncludedStatesFilter = (
  templateType,
  includedStatesFilter,
) => ({
  type: 'TEMPLATES_SET_INCLUDED_STATES_FILTER',
  templateType,
  includedStatesFilter,
});

export const startSetIncludedStatesFilter = (
  templateType,
  includedStatesFilter,
) => async (dispatch) => {
  try {
    await dispatch(setIncludedStatesFilter(templateType, includedStatesFilter));
    dispatch(startCountTemplates(templateType));
    dispatch(startFetchTemplates(templateType));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const setPage = (templateType, page) => ({
  type: 'TEMPLATES_SET_PAGE',
  page,
  templateType,
});

export const startSetPage = (templateType, page) => async (dispatch) => {
  try {
    await dispatch(setPage(templateType, page));
    dispatch(startFetchTemplates(templateType));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const setLimit = (templateType, limit) => ({
  type: 'TEMPLATES_SET_LIMIT',
  limit,
  templateType,
});

export const startSetLimit = (templateType, limit) => async (dispatch) => {
  try {
    await dispatch(setLimit(templateType, limit));
    dispatch(startFetchTemplates(templateType));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const startDeleteTemplate = (templateType, objectId) => async (
  dispatch,
) => {
  try {
    const object = new Parse.Object('ContractObject');
    object.id = objectId;
    await object.destroy();
    dispatch(startCountTemplates(templateType));
    dispatch(startFetchTemplates(templateType));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const startCloneTemplate = (
  templateType,
  objectId,
  needFetch = true,
) => async (dispatch) => {
  try {
    dispatch(showLoader('Cloning template. This process may take awhile ...'));
    const startCloneTemplateJob = async () => {
      const jobStatusId = await Parse.Cloud.run('startCloneTemplateJob', {
        objectId,
        templateType,
      });
      const pollJobStatus = async (attempt) => {
        const { status, message } = await Parse.Cloud.run('getJobStatus', {
          jobStatusId,
        });
        switch (status) {
          case 'succeeded':
            return message;
          case 'running': {
            if (attempt > 10) {
              throw new Parse.Error(
                400,
                'This process is taking longer than expected. After the template is done cloning it will appear after refreshing this page.',
              );
            }
            await new Promise((res) => {
              setTimeout(() => {
                res();
              }, 6000);
            });
            return pollJobStatus(attempt + 1);
          }
          case 'failed':
          default:
            throw new Parse.Error(400, message);
        }
      };
      return pollJobStatus(1);
    };
    const templateId = await startCloneTemplateJob();
    if (needFetch) {
      dispatch(startCountTemplates(templateType));
      dispatch(startFetchTemplates(templateType));
      history.push(`/templates/${templateType}/${templateId}`);
      dispatch(hideLoader());
    }
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const startClearTemplates = (templateType) => async (dispatch) => {
  try {
    dispatch(setTemplates(templateType, []));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const startPublishTemplate = (params) => async (dispatch) => {
  try {
    const message = await Parse.Cloud.run('publishToTemplates', { ...params });
    AppToaster.show({ message, timeout: 3000 });
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const updateTemplatesOrder = (
  templatesWithOrderNumber,
  templateType,
) => async (dispatch) => {
  try {
    await Parse.Object.saveAll(templatesWithOrderNumber);
    await dispatch(startFetchTemplates(templateType));
    await dispatch(startCountTemplates(templateType));
  } catch (e) {
    dispatch(handleError(e));
  }
};
