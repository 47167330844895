/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Jumbotron } from 'react-bootstrap';
import getLogo from '../selectors/logo';

const Unauthorized = () => {
  const logoUrl = useSelector(getLogo);

  const { goBack } = useHistory();

  return (
    <div style={{ height: '100vh' }}>
      <Jumbotron className="w-100 d-flex flex-column align-content-center">
        <img alt="Company Logo" src={logoUrl} style={{ width: '400px' }} />
        <div>
          <h1>403</h1>
        </div>
        <div>
          <h3>Forbidden</h3>
        </div>
        <div>Opps! You don&pos;t have permission to view this page</div>
        <div>
          <Link to="" onClick={goBack}>
            Go back
          </Link>
          , or head over to <Link to="/">leaptodigital.com</Link> to choose a
          new direction.
        </div>
      </Jumbotron>
    </div>
  );
};

export default Unauthorized;
