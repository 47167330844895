/* eslint-disable import/no-named-as-default */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { withLayoutContext, layoutContextPropTypes } from '../../Layout';
import { startUpdateConfig, updateConfigState } from '../../../actions/auth';
import FormGroup, { SwitchGroup, NumberGroup } from '../../FormGroup';
import APIDropDown from '../APIDropDown';
import { randomKey } from '../../../utils/utils';
import TemplateSending from './TemplateSending';
import ResultsForms from './Sections/ResultsForms';
import Panel from '../../Panel';
import { showErrorAlert, hideAlert } from '../../../actions/alert';
import { updatedErrors, checkForErrors } from './Validation/Validator';

const formattedSettings = (settings) => {
  let alertInterval = settings.alertInterval || 0;
  if (alertInterval) {
    alertInterval /= 60; // Convert to minutes
  }
  let saleResult = settings.saleResult || [];
  saleResult = saleResult.map((object) => ({
    ...object,
    id: object.id || randomKey(10),
  }));
  let demoResult = settings.demoResult || [];
  demoResult = demoResult.map((object) => ({
    ...object,
    id: object.id || randomKey(10),
  }));
  return {
    ...settings,
    webhook: settings.webhook || {},
    salesforce: settings.salesforce || {},
    leadPerfection: settings.leadPerfection || {},
    saleResult,
    demoResult,
    alertInterval,
  };
};

const formatResults = (results = []) => {
  return results.map((result, order) => {
    if (
      result.inputType === '__msLeadResult' ||
      result.inputType === '__msResultReason'
    ) {
      return { ...result, endpoint: 'Marketsharp' };
    }
    if (result.salesforce && !result.salesforce.field) {
      const { endpoint, salesforce, ...data } = result;
      return { ...data, shouldFetch: false };
    }
    if (
      result.salesforce &&
      result.salesforce.field &&
      result.salesforce.type
    ) {
      return { ...result, endpoint: 'Salesforce', shouldFetch: false };
    }
    if (_.endsWith(result.inputType, '_LP')) {
      return { ...result, endpoint: 'LeadPerfection' };
    }
    if (_.endsWith(result.inputType, '_jn')) {
      if (_.endsWith(result.inputType, 'fetch_jn')) {
        return { ...result, endpoint: 'JobNimbus', shouldFetch: true };
      }
      return { ...result, endpoint: 'JobNimbus', shouldFetch: false };
    }
    return {
      ...result,
      order,
    };
  });
};

export class ResultsSending extends React.Component {
  constructor(props) {
    super(props);
    this.state = formattedSettings(props.settings);
    let errors;
    const keys = Object.keys(props.settings);
    keys.forEach((key) => {
      errors = updatedErrors(errors || {}, this.state);
    });
    this.state.errors = errors;
  }

  componentDidMount() {
    const {
      setCrumbs,
      location: { pathname },
    } = this.props;

    const crumb = { title: 'Result Sending', link: pathname };
    setCrumbs([crumb]);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const settings = { ...formattedSettings(newProps.settings) };
    let errors;
    const keys = Object.keys(newProps.settings);
    keys.forEach((key) => {
      errors = updatedErrors(errors || {}, settings);
    });
    this.setState({ ...settings, errors });
  }

  componentWillUnmount() {
    const { setCrumbs } = this.props;

    setCrumbs();
  }

  onSaveClicked = () => {
    const { emailHTML, ...resultsErrors } = this.state.errors;

    const onResultsPage =
      this.props.match.path && this.props.match.path === '/results';

    const errs = onResultsPage ? resultsErrors : this.state.errors;

    if (checkForErrors(errs)) {
      this.props.showErrorAlert({
        title: 'Invalid Values',
        message: 'You must correct all validation errors before saving',
        onConfirm: this.props.hideAlert,
      });
      return;
    }
    const { errors, ...rest } = this.state;
    const uploadSettings = { ...rest };
    uploadSettings.alertInterval = this.state.alertInterval * 60; // convert back to seconds
    uploadSettings.saleResult = formatResults(this.state.saleResult);
    uploadSettings.demoResult = formatResults(this.state.demoResult);
    delete uploadSettings.errors;
    this.props.startUpdateConfig({ resultsUpload: uploadSettings });
  };

  onValueChanged = ({ key, value }) => {
    const newState = { ...this.state };
    newState[key] = value;
    const errors = updatedErrors(this.state.errors, newState);
    this.setState({ [key]: value, errors });
  };

  onResultsChange = ({ key, value }) => {
    const newState = {
      ...this.state,
      [key]: value,
    };
    const errors = updatedErrors(this.state.errors, newState);
    this.setState({ [key]: value, errors }, () => {
      const uploadSettings = { ...newState };
      uploadSettings.alertInterval = newState.alertInterval * 60; // convert back to seconds
      uploadSettings.saleResult = formatResults(newState.saleResult);
      uploadSettings.demoResult = formatResults(newState.demoResult);
      this.props.updateConfigState({ resultsUpload: uploadSettings });
    });
  };

  settingsSection = () => (
    <Panel title="Results Settings">
      <div>
        <FormGroup title="APIs">
          <APIDropDown
            selectedOptions={this.state.endpoints}
            onChange={(value) =>
              this.onValueChanged({ key: 'endpoints', value })
            }
          />
        </FormGroup>
        <SwitchGroup
          title="Show Missing Results Notification"
          checked={this.state.alertEnabled}
          onChange={(value) =>
            this.onValueChanged({ key: 'alertEnabled', value })
          }
          errorMessage={this.state.errors.alertEnabled}
        />
        {this.state.alertEnabled && (
          <NumberGroup
            title="Show Notification After (Minutes)"
            placeholder="Alert Interval"
            value={this.state.alertInterval}
            onChange={(value) =>
              this.onValueChanged({ key: 'alertInterval', value })
            }
            errorMessage={this.state.errors.alertInterval}
          />
        )}
      </div>
    </Panel>
  );

  resultsTable = () => (
    <ResultsForms
      saleResult={this.state.saleResult}
      demoResult={this.state.demoResult}
      endpoints={this.state.endpoints}
      showDocLinking={this.props.flags && this.props.flags['document-linking']}
      onChange={this.onResultsChange}
      errors={this.state.errors}
    />
  );

  render() {
    return (
      <div className="default-page-padding">
        <TemplateSending
          currentPath={this.props.match.path}
          settingsSection={this.settingsSection}
          onValueChanged={this.onValueChanged}
          onSaveClicked={this.onSaveClicked}
          title="Results Settings"
          footer={this.resultsTable}
          isResults
          onCopy={() => this.setState({ showCopy: true })}
          onCopyClose={() => this.setState({ showCopy: false })}
          showCopy={this.state.showCopy}
          copyKey="resultsUpload"
          {...this.state}
        />
      </div>
    );
  }
}

ResultsSending.defaultProps = {
  settings: {
    leadPerfection: {
      fileName: '',
      fileDescription: '',
      backupRecipients: [],
    },
    salesforce: {
      fileName: '',
      fileDescription: '',
      backupRecipients: [],
    },
    webhook: {
      includePDF: false,
      url: '',
      authHeader: '',
    },
    endpoints: [],
    fillEmptyWhiteSpace: false,
    emptyWhiteSpaceNote: '',
    useBrochures: false,
    useVerifyEmail: false,
    from: '',
    subjectLine: '',
    recipients: [],
    ccRecipients: [],
    bccRecipients: [],
    emailHTML: '',
    emailFileName: '',
    fileName: '',
    alertEnabled: false,
    alertInterval: 0,
    saleResult: [],
    demoResult: [],
  },
};

const resultPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    title: PropTypes.string,
    inputType: PropTypes.string,
    required: PropTypes.bool,
    values: PropTypes.arrayOf(PropTypes.string),
    shouldFetch: PropTypes.bool,
    endpoint: PropTypes.string,
    salesforce: PropTypes.shape({
      field: PropTypes.string,
      type: PropTypes.string,
    }),
  }),
);

ResultsSending.propTypes = {
  updateConfigState: PropTypes.func.isRequired,
  startUpdateConfig: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    leadPerfection: PropTypes.shape({
      fileName: PropTypes.string,
      fileDescription: PropTypes.string,
      backupRecipients: PropTypes.arrayOf(PropTypes.string),
    }),
    salesforce: PropTypes.shape({
      fileName: PropTypes.string,
      fileDescription: PropTypes.string,
      backupRecipients: PropTypes.arrayOf(PropTypes.string),
    }),
    webhook: PropTypes.shape({
      includePDF: PropTypes.bool,
      url: PropTypes.string,
      authHeader: PropTypes.string,
    }),
    endpoints: PropTypes.arrayOf(PropTypes.string),
    fillEmptyWhiteSpace: PropTypes.bool,
    emptyWhiteSpaceNote: PropTypes.string,
    useBrochures: PropTypes.bool,
    useVerifyEmail: PropTypes.bool,
    from: PropTypes.string,
    subjectLine: PropTypes.string,
    recipients: PropTypes.arrayOf(PropTypes.string),
    ccRecipients: PropTypes.arrayOf(PropTypes.string),
    bccRecipients: PropTypes.arrayOf(PropTypes.string),
    emailHTML: PropTypes.string,
    emailFileName: PropTypes.string,
    backupRecipients: PropTypes.arrayOf(PropTypes.string),
    fileName: PropTypes.string,
    alertEnabled: PropTypes.bool,
    alertInterval: PropTypes.number,
    saleResult: resultPropTypes,
    demoResult: resultPropTypes,
  }),
  showErrorAlert: PropTypes.func.isRequired,
  hideAlert: PropTypes.func.isRequired,
  ...layoutContextPropTypes,
};

const mapStateToProps = ({ auth }) => {
  const { config = {} } = auth;
  return {
    settings: { ...config.resultsUpload },
  };
};

const mapDispatchToProps = (dispatch) => ({
  startUpdateConfig: (updates) => dispatch(startUpdateConfig(updates)),
  updateConfigState: (updates) => dispatch(updateConfigState(updates)),
  showErrorAlert: (props) => dispatch(showErrorAlert(props)),
  hideAlert: () => dispatch(hideAlert()),
});

export default withLDConsumer()(
  withLayoutContext(
    connect(mapStateToProps, mapDispatchToProps)(ResultsSending),
  ),
);
