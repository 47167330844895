/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Parse from 'parse';
import {
  Classes,
  Menu,
  MenuItem,
  Popover,
  Button,
  Position,
  TextArea,
} from 'leap-menu-item';
import CreditApplicationObject from './Models/CreditApplicationObject';
import {
  setCreditApplication,
  saveCreditApplication,
} from '../../actions/creditApplications';
import { history } from '../../router';
import AppToaster from '../../utils/AppToaster';
import { newFileFromEvent } from '../../utils/utils';
import SaveAsModal from './Editor/SaveAsModal';
import DebounceInputWithNet from '../Templates/Toolbox/DebounceInputWithNet';

const MenuFix = () => (
  <MenuItem style={{ display: 'none' }}>
    <MenuItem />
  </MenuItem>
);

class FileButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { thumbnailIsOpen: undefined };
  }

  onFileInputChanged = async (e) => {
    const { application } = this.props;
    const newCreditApp = application.newInstance();
    const parseFile = await newFileFromEvent(e);
    newCreditApp.set('image', parseFile);
    // eslint-disable-next-line react/destructuring-assignment
    this.props.setCreditApplication(newCreditApp);
  };

  onClearThumbnail = async (e) => {
    const { application } = this.props;
    const newCreditApp = application.newInstance();
    newCreditApp.set('image', undefined);
    newCreditApp.unset('image');
    // eslint-disable-next-line react/destructuring-assignment
    this.props.setCreditApplication(newCreditApp);
  };

  onSaveTemplate = async () => {
    const { application } = this.props;
    if (application.has('image') && application.get('image').isNew) {
      await application.get('image').save();
    }
    // eslint-disable-next-line react/destructuring-assignment
    await this.props.saveCreditApplication(application.smallJSON());
    AppToaster.show({ message: 'Saved Successfully!', timeout: 3000 });
    // eslint-disable-next-line react/destructuring-assignment
    this.props.setCreditApplication(application);
  };

  onSaveCopy = async (newName) => {
    const { application } = this.props;
    const copy = await application.clone();
    copy.set('title', newName);
    await copy.save();
    application.revert();
    this.props.setCreditApplication(copy);
    AppToaster.show({ message: 'Saved Successfully!', timeout: 3000 });
    history.push(`/credit_applications/edit/${copy.id}`);
  };

  onShowSaveModal = async () => {
    this.setState({ showModal: true });
  };

  render() {
    const { application = new CreditApplicationObject(), offices } = this.props;
    const includedOffices = application.get('includedOffices') || [];

    const imageURL = application.has('image')
      ? application.get('image').url()
      : '/images/no_image.png';
    return (
      <>
        <SaveAsModal
          show={this.state.showModal}
          onClose={() =>
            this.setState(() => ({
              showModal: false,
            }))
          }
          currentTitle={application.get('title')}
          onSubmit={this.onSaveCopy}
        />
        <Popover
          content={
            <Menu className={Classes.ELEVATION_1}>
              <MenuFix />
              <MenuItem
                icon="floppy-disk"
                text="Save"
                onClick={this.onSaveTemplate}
              />
              <MenuItem
                icon="floppy-disk"
                text="Save As New"
                onClick={this.onShowSaveModal}
              />
              <Menu.Divider />
              <MenuItem
                icon=""
                text="Name"
                label={application.get('title')}
                shouldDismissPopover={false}
              >
                <DebounceInputWithNet
                  element={TextArea}
                  minLength={0}
                  debounceTimeout={300}
                  value={application.get('title')}
                  onChange={(e) => {
                    const newCreditApp = application.newInstance();
                    newCreditApp.set('title', e.target.value);
                    // eslint-disable-next-line react/destructuring-assignment
                    this.props.setCreditApplication(newCreditApp);
                  }}
                />
              </MenuItem>
              <Menu.Divider />
              {this.props.maxOfficeCount !== 1 && (
                <>
                  <MenuItem
                    icon="map"
                    text="Included Offices"
                    label={includedOffices.length}
                  >
                    <div style={{ maxHeight: '350px', overflow: 'scroll' }}>
                      <MenuItem
                        icon={
                          includedOffices.length === offices.length
                            ? 'dot'
                            : 'blank'
                        }
                        text="Select All"
                        shouldDismissPopover={false}
                        active={includedOffices.length === offices.length}
                        onClick={() => {
                          const newCreditApp = application.newInstance();
                          if (includedOffices.length === offices.length) {
                            newCreditApp.set('includedOffices', []);
                          } else {
                            newCreditApp.set(
                              'includedOffices',
                              offices.map((office) => office.toPointer()),
                            );
                          }
                          // eslint-disable-next-line react/destructuring-assignment
                          this.props.setCreditApplication(newCreditApp);
                        }}
                      />
                      <Menu.Divider />
                      {offices.map((office) => (
                        <MenuItem
                          key={office.id}
                          icon={
                            includedOffices.find(
                              ({ objectId, id }) =>
                                office.id === objectId || office.id === id,
                            )
                              ? 'dot'
                              : 'blank'
                          }
                          active={
                            !!includedOffices.find(
                              ({ objectId, id }) =>
                                office.id === objectId || office.id === id,
                            )
                          }
                          shouldDismissPopover={false}
                          text={office.get('name')}
                          onClick={() => {
                            const updatedOffices = includedOffices.filter(
                              ({ objectId, id }) =>
                                objectId !== office.id && id !== office.id,
                            );
                            // eslint-disable-next-line max-len
                            if (
                              !includedOffices.find(
                                ({ objectId, id }) =>
                                  objectId === office.id || id === office.id,
                              )
                            ) {
                              updatedOffices.push(office.toPointer());
                            }
                            const newCreditApp = application.newInstance();
                            newCreditApp.set('includedOffices', updatedOffices);
                            // eslint-disable-next-line react/destructuring-assignment
                            this.props.setCreditApplication(newCreditApp);
                          }}
                        />
                      ))}
                    </div>
                  </MenuItem>
                  <Menu.Divider />
                </>
              )}
              <MenuItem
                icon="media"
                text="Thumbnail"
                shouldDismissPopover={false}
                popoverProps={{
                  onClose: () => {
                    this.setState({ thumbnailIsOpen: undefined });
                  },
                  // eslint-disable-next-line react/destructuring-assignment
                  isOpen: this.state.thumbnailIsOpen,
                }}
              >
                <div style={{ cursor: 'pointer' }}>
                  <button
                    type="button"
                    onClick={(event) => {
                      this.onClearThumbnail();
                    }}
                    className="btn remove-thumbnail"
                  >
                    X
                  </button>
                  <img
                    alt="Thumbnail"
                    src={imageURL}
                    style={{
                      maxWidth: '200px',
                      maxHeight: '200px',
                      cursor: 'pointer',
                    }}
                  />
                  <input
                    type="file"
                    accept="image/*"
                    onChange={this.onFileInputChanged}
                    onClick={() => this.setState({ thumbnailIsOpen: true })}
                    style={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      left: 0,
                      top: 0,
                      opacity: 0,
                      cursor: 'pointer',
                    }}
                  />
                </div>
              </MenuItem>
              <Menu.Divider />
              <MenuItem icon="" text="Disclosure" shouldDismissPopover={false}>
                <DebounceInputWithNet
                  element={TextArea}
                  minLength={0}
                  debounceTimeout={300}
                  value={application.get('pdfDisclosure')}
                  onChange={(e) => {
                    const newCreditApp = application.newInstance();
                    newCreditApp.set('pdfDisclosure', e.target.value);
                    // eslint-disable-next-line react/destructuring-assignment
                    this.props.setCreditApplication(newCreditApp);
                  }}
                />
              </MenuItem>
            </Menu>
          }
          position={Position.BOTTOM}
        >
          <Button className={Classes.MINIMAL} text="File" />
        </Popover>
      </>
    );
  }
}

FileButton.propTypes = {
  application: PropTypes.instanceOf(CreditApplicationObject),
  offices: PropTypes.arrayOf(PropTypes.instanceOf(Parse.Object)),
  setCreditApplication: PropTypes.func.isRequired,
  saveCreditApplication: PropTypes.func.isRequired,
  maxOfficeCount: PropTypes.number.isRequired,
};

FileButton.defaultProps = {
  offices: [],
  application: undefined,
};

const mapStateToProps = ({ auth, plan = {} }) => ({
  offices: auth.offices,
  maxOfficeCount: plan.maxOfficeCount,
});

const mapDispatchToProps = (dispatch) => ({
  setCreditApplication: (application) =>
    dispatch(setCreditApplication(application)),
  // eslint-disable-next-line max-len
  saveCreditApplication: (creditApplicationData) =>
    dispatch(saveCreditApplication({ creditApplicationData })),
});

export default connect(mapStateToProps, mapDispatchToProps)(FileButton);
