/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-cycle */
import React from 'react';
import { connect } from 'react-redux';
import { actionIcons, alwaysIcons } from './actionIcons';
import { history } from '../../router';

const getActionIcons = ({ details = {}, mainMenuOptions }) => {
  const { estimateObject = {} } = details;
  // eslint-disable-next-line max-len
  const icons = actionIcons
    .filter(({ documentKey }) => estimateObject.get(documentKey))
    .map((obj) => ({
      ...obj,
      documentURL: estimateObject.get(obj.documentKey).url(),
    }));
  icons.push(
    ...mainMenuOptions
      .filter(({ objectId }) => {
        const documents = estimateObject.get('documents') || {};
        return !!documents[objectId];
      })
      .map(({ objectId, title, webIcon }) => ({
        name: title,
        resendKey: objectId,
        documentKey: objectId,
        iconClass: webIcon,
        documentURL: estimateObject.get('documents')[objectId].url(),
      })),
  );
  icons.push(...alwaysIcons);

  if (estimateObject.get('documentCount')) {
    icons.push({
      name: 'Download All',
      resendKey: '',
      iconClass: 'far fa-folder-open',
    });
  }
  return icons;
};

const ActionIconsSection = (props) => {
  const { details = {} } = props;
  const { logObjects = [] } = details;

  const actions = getActionIcons(props);
  return (
    <div className="action-icons-section row ml-2">
      {actions
        .filter((action) => (props.logsAllowed ? true : action.name !== 'Log'))
        .map((action, i) => (
          <div
            className="d-flex flex-column align-items-center col-md-2 col-sm-4 col-6"
            key={`action-${action.name}-${i}`}
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={action.documentURL}
              onClick={(event) => {
                if (action.name === 'Download All') {
                  event.preventDefault();
                  props.downloadAllDocuments(props.details.estimateObject);
                }
                if (action.name === 'Log') {
                  event.preventDefault();

                  const logsLink = logObjects.length
                    ? `/estimate_details/${props.details.objectId}/Logs/files`
                    : `/estimate_details/${props.details.objectId}/Logs`;

                  history.push(logsLink);
                }
              }}
              className="estimate-action-link"
            >
              <div className="d-flex flex-column align-items-center">
                {action.iconClass && (
                  <i className={`${action.iconClass} fa-4x`} />
                )}
                {action.popIcon && (
                  <img
                    className="leap-action-icon"
                    src={action.popIcon}
                    alt="Leap Icon"
                  />
                )}
                <span
                  className="leap-action-icon-link"
                  style={{
                    marginTop: action.iconClass ? 10 : 0,
                  }}
                >
                  {action.name}
                </span>
              </div>
            </a>
            <button
              style={{ display: action.resendKey ? 'block' : 'none' }}
              type="button"
              onClick={() => {
                props.onResend(action.resendKey);
              }}
              className="btn btn-sm btn-primary mt-3"
            >
              Resend
            </button>
          </div>
        ))}
    </div>
  );
};

const mapStateToProps = ({ auth, plan }) => ({
  mainMenuOptions: auth.allMainMenuOptions,
  logsAllowed: plan.logsAllowed,
});

ActionIconsSection.defaultProps = {
  mainMenuOptions: [],
};

export default connect(mapStateToProps)(ActionIconsSection);
