/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-shadow */
/* eslint-disable react/no-deprecated */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import FormControl, { TextGroup } from '../FormGroup';
import { startUpdateConfig } from '../../actions/auth';
import Panel from '../Panel';
import TitleButton from '../TitleButton';
import Copy from '../AppSettings/Copy';
import { showDeleteConfirmation } from '../../actions/deleteConfirmation';

const enrollWithLendKeyLink =
  'https://www.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=347f290c-7b2e-4b2a-8c65-467b725e039a&env=na1&acct=b40eb6a3-2243-4ad4-ac6b-33d9521a3875&v=2';

export class LendKey extends React.Component {
  constructor(props) {
    super(props);
    const { lendkey = {} } = props;
    const { contractorId = '' } = lendkey;
    this.state = { contractorId };
  }

  componentDidMount() {
    const {
      setCrumbs,
      setButtons,
      location: { pathname },
    } = this.props;
    const crumb = { title: 'LendKey', link: pathname };
    setCrumbs([crumb]);
    setButtons(
      <TitleButtons
        onClearClicked={this.askForClearPermission}
        onCopyClicked={this.onCopyClicked}
        onSaveClicked={this.onSaveClicked}
      />,
    );
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { lendkey = {} } = newProps;
    const { contractorId = '' } = lendkey;
    this.setState({ contractorId });
  }

  componentWillUnmount() {
    const { setCrumbs, setButtons } = this.props;
    setCrumbs([]);
    setButtons();
  }

  askForClearPermission = () => {
    this.props.showDeleteConfirmation({
      message: 'Are you sure you want to clear this integration?',
      title: 'Clear Integration?',
      onConfirm: () => {
        this.onClearClicked();
      },
    });
  };

  onClearClicked = () => {
    this.setState({ contractorId: '' });
  };

  onContractorIdChanged = (value) => {
    this.setState({ contractorId: value });
  };

  onCopyClicked = () => {
    this.setState({ showCopy: true });
  };

  onSaveClicked = () => {
    const { contractorId } = this.state;
    const updates = { contractorId };
    this.props.startUpdateConfig({ lendkey: updates });
  };

  render() {
    return (
      <>
        <Copy
          title="Copy LendKey Settings"
          show={this.state.showCopy}
          warning="Warning! This will overwrite your current LendKey settings"
          configKeys={['lendkey']}
          onClose={() => this.setState({ showCopy: false })}
        />
        <Panel title="LendKey">
          <TextGroup
            title="Contractor ID"
            value={this.state.contractorId}
            placeholder="Contractor ID"
            onChange={this.onContractorIdChanged}
          />
          <FormControl title="">
            <p style={{ marginLeft: '10px' }}>
              Don&apos;t have an LendKey account? Create one
              <a
                href={enrollWithLendKeyLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                here.
              </a>{' '}
            </p>
          </FormControl>
        </Panel>
      </>
    );
  }
}

LendKey.propTypes = {
  startUpdateConfig: PropTypes.func.isRequired,
  lendkey: PropTypes.shape({
    contractorId: PropTypes.string,
  }),
  ...layoutContextPropTypes,
};

LendKey.defaultProps = {
  lendkey: {
    contractorId: '',
  },
};

const TitleButtons = ({
  onCopyClicked,
  onSaveClicked,
  onClearClicked,
  disableSave,
}) => (
  <>
    <TitleButton
      variant="warning"
      onClick={() => onClearClicked()}
      title="Clear"
    />
    <TitleButton
      variant="primary"
      onClick={() => onCopyClicked()}
      title="Copy"
    />
    <TitleButton
      disabled={disableSave}
      variant="success"
      onClick={() => onSaveClicked()}
      title="Save"
    />
  </>
);

TitleButtons.propTypes = {
  onCopyClicked: PropTypes.func.isRequired,
  onSaveClicked: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth: { config } }) => ({
  lendkey: config.lendkey,
});

const mapDispatchToProps = (dispatch) => ({
  startUpdateConfig: (updates) => dispatch(startUpdateConfig(updates)),
  showDeleteConfirmation: (params) => dispatch(showDeleteConfirmation(params)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(LendKey),
);
