/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { ProgressBar } from '@blueprintjs/core';
import { useDispatch, useSelector } from 'react-redux';
import Paginator from '../Misc/Paginator';
import ResponsiveTable from '../SharedComponents/ResponsiveTable';

import FilterSectionDropRow from '../SharedComponents/FilterSectionDropRow';
import { initOnboardingStatusReport } from '../../actions/onboardingStatusReport';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';

const sections = [
  {
    label: 'App appearance',
    value: 'appAppearance',
  },
  {
    label: 'Appointments',
    value: 'appointments',
  },
  {
    label: 'Categories',
    value: 'categories',
  },
  { label: 'Commission Formulas', value: 'commissionFormulas' },
  { label: 'Company Logo', value: 'companyLogo' },
  { label: 'Contract Sending', value: 'contractSending' },
  { label: 'Credit App Upload', value: 'creditAppUpload' },
  { label: 'Custom Options', value: 'customOptions' },
  { label: 'Documents', value: 'documents' },
  { label: 'Email Links', value: 'emailLinks' },
  { label: 'Finance Options', value: 'financeOptions' },
  { label: 'Packages', value: 'packages' },
  { label: 'Payment Capture', value: 'paymentCapture' },
  { label: 'Placeholders', value: 'placeholders' },
  { label: 'Price Formulas', value: 'priceFormulas' },
  { label: 'Price Guide', value: 'priceGuide' },
  { label: 'Report Sending', value: 'reportSending' },
  { label: 'Resources', value: 'resources' },
  { label: 'Users', value: 'users' },
];

const alertTypes = [
  {
    label: 'Info',
    value: 1,
  },
  {
    label: 'Warning',
    value: 2,
  },
  {
    label: 'Danger',
    value: 3,
  },
];

function onPageChanged(props) {
  const { setPage, value } = props;
  setPage(value);
}
//

function onValue(value, data, isCollapsed) {
  const { columnIndex } = data;
  switch (columnIndex) {
    case 0:
      return (
        <div className="text-left">
          <span className="status-check-section">{value}</span>
        </div>
      );
    case 1:
      return alertTypeFormatter(value);
    case 2: {
      const lines = value.split('\n');
      return lines.length > 2 ? (
        lines
          .filter((line) => line)
          .map((line) => <p className="status-check-message">{line}</p>)
      ) : (
        <p className="status-check-message">{value}</p>
      );
    }
    default:
      return value;
  }
}

function titleFormatter(value, col, index, asc, onSort) {
  if (index === 2) {
    return <span className="tatus-check-table-headers">{value}</span>;
  }
  const direction = asc ? '-asc' : '';

  return (
    <div
      className="cursor-pointer"
      onClick={() => {
        const key = col.itemKey.replace('on', 'onNumber');
        onSort({ key, asc: !asc });
      }}
    >
      <i className={`fas fa-caret-down onboarding-sort-arrow${direction}`} />
      <span className="tatus-check-table-headers">{value}</span>
    </div>
  );
}

function alertTypeFormatter(value) {
  switch (value) {
    case 1:
      return (
        <div className="alert-secondary status-check-alert text-dark p-2">
          <i className="fa fa-question" /> Info
        </div>
      );
    case 2:
      return (
        <div className="type-warning status-check-alert text-dark p-2">
          <i className="fa fa-exclamation" /> Warning
        </div>
      );
    case 3:
      return (
        <div className="type-danger status-check-alert text-dark p-2">
          <i className="fas fa-exclamation" /> Danger
        </div>
      );
    default:
      return value;
  }
}

function onLimitChanged(props) {
  const { setPage, setLimit, value } = props;
  setPage(1);
  setLimit(value);
}
function OnboardingStatusReport(props) {
  const dispatch = useDispatch();
  const { checkListResult = [], onBoardingScore = 0 } = useSelector(
    ({ onboardingStatusReport = {} }) => onboardingStatusReport,
  );

  const [sortParams, setSortParams] = useState({
    key: 'alertType',
    asc: true,
  });

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [alertTypeFilter, setAlertTypeFilter] = useState([]);
  const [sectionFilter, setSectionFilter] = useState([]);
  const [renderedScore, setRenderedScrore] = useState(0);

  useEffect(() => {
    if (!alertTypeFilter.length && !sectionFilter.length) {
      setRenderedScrore(onBoardingScore);
    }
  }, [alertTypeFilter, sectionFilter, onBoardingScore]);

  useEffect(() => {
    const crumbs = [
      { title: 'Status Report', link: '/onboarding-status-report' },
    ];
    props.setCrumbs(crumbs);
    props.setButtons(
      <button
        onClick={() => {
          dispatch(
            initOnboardingStatusReport({
              alertTypeFilter,
              sectionFilter,
            }),
          );
        }}
        type="button"
        className="btn btn-primary"
      >
        Rerun test
      </button>,
    );
    return () => {
      props.setButtons();
    };
  }, [sectionFilter, alertTypeFilter]);

  useEffect(() => {
    dispatch(
      initOnboardingStatusReport({
        alertTypeFilter,
        sectionFilter,
      }),
    );
  }, [alertTypeFilter, dispatch, sectionFilter]);

  return (
    <div className="onboarding-status-page h-100 default-page-padding">
      <div className="row progress-row">
        <div className="col-md-12 ">
          <ProgressBar
            className="bp3-no-stripes onboarding-progress-bar"
            value={renderedScore / 100}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 d-flex justify-content-center align-items-center">
          <span className="onboarding-progress-result">{renderedScore}</span>
          <span className="onboarding-progress-total">/ 100</span>
        </div>
      </div>

      <div className="filter-section my-3">
        <div className="filter-section-dropdowns row">
          <div className="col-md-6 filter-section-dropdowns-half">
            <FilterSectionDropRow
              placeholder="Select a Section"
              options={sections}
              selected={sectionFilter}
              onSelectedChanged={(selected) => {
                setPage(1);
                setSectionFilter(selected);
              }}
              title="Section"
            />
          </div>
          <div className="col-md-6 filter-section-dropdowns-half">
            <FilterSectionDropRow
              placeholder="Select an Alert Type"
              options={alertTypes}
              selected={alertTypeFilter}
              onSelectedChanged={(selected) => {
                setPage(1);
                setAlertTypeFilter(selected);
              }}
              title="Alert Type"
            />
          </div>
        </div>
      </div>
      <ResponsiveTable
        valueFormatter={onValue}
        titleFormatter={(value, col, index) => {
          const asc =
            col.itemKey.replace('on', 'onNumber') === sortParams.key &&
            sortParams.asc;
          return titleFormatter(value, col, index, asc, (event) => {
            setSortParams(event);
          });
        }}
        items={checkListResult
          .map((item) => {
            const sectionNumber = sections
              .map((section) => section.label)
              .indexOf(item.section);

            return {
              ...item,
              sectionNumber,
            };
          })
          .sort((a, b) =>
            sortParams.asc
              ? a[sortParams.key] - b[sortParams.key]
              : b[sortParams.key] - a[sortParams.key],
          )
          .slice(limit * (page - 1), limit * (page - 1) + limit)}
        columns={[
          {
            title: 'Section',
            itemKey: 'section',
          },
          {
            title: 'Alert Type',
            itemKey: 'alertType',
          },
          {
            title: 'Message',
            itemKey: 'message',
          },
        ]}
      />
      <Paginator
        totalCount={checkListResult.length}
        page={page}
        limit={limit}
        onLimitChanged={({ value }) =>
          onLimitChanged({ value, setPage, setLimit })
        }
        pageRange={5}
        onPageClicked={(value) => onPageChanged({ value, setPage, setLimit })}
      />
    </div>
  );
}

OnboardingStatusReport.propTypes = {
  ...layoutContextPropTypes,
};

export default withLayoutContext(OnboardingStatusReport);
