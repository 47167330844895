/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import CreditAppCell from './CreditAppCell';
import { useCreditAppForm } from '../../hooks/publicCreditAppForm';
import './styles.scss';
import { randomKey } from '../../utils/utils';

const CustomerCreditApp = () => {
  /**
   * Get the Customer Credit Application ID from the url as defined in the route path (.../customer_credit_app/id)
   * @type {{id: string}}
   */
  const { id: customerCreditAppId } = useParams();

  /**
   * @type {[CreditApplicationCell[], React.Dispatch<React.SetStateAction<CreditApplicationCell[]>]}
   */

  const {
    sectionList,
    creditAppData = {},
    getPublicCreditApp,
    onSubmit,
    submitted,
  } = useCreditAppForm();
  const { disclosure = '' } = creditAppData;

  useEffect(() => {
    if (customerCreditAppId) {
      getPublicCreditApp({ customerCreditAppId });
    }
    return undefined;
  }, [customerCreditAppId]);

  return submitted ? (
    <Container>
      <div className="row">
        <div className="col-12">
          <div className="thank-you-pane">
            <p className="text-center">THANK YOU</p>
          </div>
        </div>
      </div>
    </Container>
  ) : (
    <Container className="public-credit-app-form">
      {creditAppData.companyLogo && (
        <Row>
          <Col>
            <img
              alt="company logo"
              className="public-credit-app-logo"
              src={creditAppData.companyLogo}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <h1>{creditAppData.companyName}</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="leap-logo-container text-left px-0 m-0">
            Secure Credit Application Powered by&nbsp;&nbsp;
            <img
              className="h-100"
              src="/images/leap_logo.png"
              alt="leap logo"
            />
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form onSubmit={onSubmit}>
            {!!sectionList.top.length &&
              sectionList.top.map((section) => (
                <>
                  <Row>
                    <Col>
                      <h3 className="border-bottom py-1">Loan Information</h3>
                    </Col>
                  </Row>
                  <Row>
                    {section.cellList.map(({ fieldId }) => (
                      <Col key={fieldId} md={6}>
                        <CreditAppCell fieldId={fieldId} />
                      </Col>
                    ))}
                  </Row>
                </>
              ))}
            <Row>
              <Col md={6}>
                {!!sectionList.left.length &&
                  sectionList.left.map((leftSection) => (
                    <>
                      <h3 className="border-bottom py-1">
                        {leftSection.title}
                      </h3>
                      {leftSection.cellList.map(({ fieldId }) => (
                        <CreditAppCell key={fieldId} fieldId={fieldId} />
                      ))}
                    </>
                  ))}
              </Col>
              <Col md={6}>
                {!!sectionList.right.length &&
                  sectionList.right.map((section) => (
                    <>
                      <h3 className="border-bottom py-1">Co-Applicant</h3>
                      {section.cellList.map(({ fieldId }) => (
                        <CreditAppCell key={fieldId} fieldId={fieldId} />
                      ))}
                    </>
                  ))}
              </Col>
            </Row>
            {disclosure && (
              <p className="credit-app__disclosure">
                {disclosure.split(/[\r\n]/).map((string) => (
                  <span key={randomKey(8)}>
                    {string}
                    <br />
                  </span>
                ))}
              </p>
            )}
            <Row>
              <Col md={6}>
                <Button type="submit">Submit</Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default CustomerCreditApp;
