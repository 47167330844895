/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Menu, MenuItem, TextArea } from 'leap-menu-item';
import { DebounceInput } from 'react-debounce-input';
import { setInitialStateForTemplate } from '../../../../actions/templateEdit';
import BodyCellItem from '../../Models/BodyCellItem';
import YesNoMenuItem from '../YesNoMenuItem';
import ContractObject from '../../Models/ContractObject';
import DynamicInputEditor from './DynamicInputEditor';

const TextMenu = ({ object, contractObject, setTemplateState }) => (
  <>
    <YesNoMenuItem
      text="Use Shared Images"
      active={object.imagesSource === 'default'}
      onChange={(value) => {
        object.imagesSource = value ? 'default' : 'unique';
        setTemplateState(contractObject);
      }}
    />
    <YesNoMenuItem
      text="Dynamic"
      active={object.inputType === 'dynamic'}
      onChange={(value) => {
        object.inputType = value ? 'dynamic' : '';
        setTemplateState(contractObject);
      }}
    />
    {object.inputType === 'dynamic' && (
      <DynamicInputEditor
        contractObject={contractObject}
        cellItem={object}
        onChange={(value) => {
          object.dynamicInputType = value;
          setTemplateState(contractObject);
        }}
      />
    )}
    <Menu.Divider />
    <YesNoMenuItem
      text="Required"
      active={object.required}
      onChange={(value) => {
        object.required = value;
        setTemplateState(contractObject);
      }}
    />
    <Menu.Divider />
    <MenuItem text="App Title">
      <DebounceInput
        element={TextArea}
        minLength={0}
        debounceTimeout={300}
        value={object.appTitle}
        onChange={(e) => {
          object.titleLinked = false;

          object.appTitle = e.target.value;
          setTemplateState(contractObject);
        }}
      />
    </MenuItem>
    <YesNoMenuItem
      text="Link Price Guide Image"
      active={!object.disableImageLink}
      onChange={(value) => {
        object.disableImageLink = !value;
        setTemplateState(contractObject);
      }}
    />
  </>
);

TextMenu.propTypes = {
  object: PropTypes.instanceOf(BodyCellItem).isRequired,
  contractObject: PropTypes.instanceOf(ContractObject).isRequired,
  setTemplateState: PropTypes.func.isRequired,
  secureCaptureEnabled: PropTypes.bool,
};

TextMenu.defaultProps = {
  secureCaptureEnabled: false,
};

const mapStateToProps = ({ templatesEdit = {}, auth }) => ({
  templateValues: templatesEdit[templatesEdit.currentWorkingId],
  editCellItem: templatesEdit.editCellItem,
  secureCaptureEnabled: auth.company.get('spreedlyEnabled'),
});

const mapDispatchToProps = (dispatch) => ({
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TextMenu);
