export default (
  users = [],
  searchText = '',
  limit = 10,
  skip = 0,
  active = 0,
) => {
  const filteredUsers = users.filter(
    ({ nameFirst, nameLast, username, isActive }) => {
      if (!searchText) {
        return (
          active === 2 ||
          (isActive && active === 0) ||
          (!isActive && active === 1)
        );
      }
      const search = searchText.toLowerCase();
      const usersName = `${nameFirst} ${nameLast}`;
      return (
        usersName.toLowerCase().indexOf(search) > -1 ||
        username.toLowerCase().indexOf(search) > -1
      );
    },
  );
  return {
    users: filteredUsers.slice(skip, skip + limit),
    total: filteredUsers.length,
  };
};

export const activeUsers = (users) => users.filter((user) => user.isActive);
