/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Classes, Menu, MenuDivider, MenuItem, Popover } from 'leap-menu-item';
import { setInitialStateForTemplate } from '../../actions/templateEdit';
import ContractObject from './Models/ContractObject';

const includesBodySection = (template) => {
  const { contractData = [] } = template;
  for (let i = 0; i < contractData.length; i += 1) {
    const { groupType } = contractData[i];
    if (groupType === 'body') {
      return true;
    }
  }
  return false;
};

const AddSectionButton = ({ template, setTemplateState }) => (
  <Popover
    content={
      <Menu className={Classes.ELEVATION_1}>
        <MenuDivider title="Add New Section" />
        <MenuItem
          icon="widget-header"
          text="Header"
          onClick={() => {
            const contractObject = new ContractObject(template.toJSON());
            contractObject.addHeaderSection();
            setTemplateState(contractObject);
          }}
        />
        <MenuItem
          icon="widget-button"
          text="Body"
          disabled={includesBodySection(template)}
          onClick={() => {
            const contractObject = new ContractObject(template.toJSON());
            contractObject.addBodySection();
            setTemplateState(contractObject);
          }}
        />
        <MenuItem
          icon="draw"
          text="Signature"
          onClick={() => {
            const contractObject = new ContractObject(template.toJSON());
            contractObject.addSignatureSection();
            setTemplateState(contractObject);
          }}
        />
      </Menu>
    }
  >
    <div className="document-tree__add-button">
      <i className="fas fa-plus-circle" />
    </div>
  </Popover>
);

AddSectionButton.propTypes = {
  template: PropTypes.shape({
    objectId: PropTypes.isRequired,
  }).isRequired,
  setTemplateState: PropTypes.func.isRequired,
};

const mapStateToProps = ({ templatesEdit }) => {
  const { currentWorkingId } = templatesEdit;
  const template = templatesEdit[currentWorkingId] || {};
  return { template };
};

const mapDispatchToProps = (dispatch) => ({
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddSectionButton);
