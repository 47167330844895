/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Collapse } from '@blueprintjs/core';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import Parse from 'parse';
import Header from './Header';
import Row from './Row';
import MeasureSheetItem from '../../../Models/MeasureSheetItem';
import {
  setOptions,
  setItem,
  startFetchUpChargesForItem,
} from '../../../actions/priceGuide2';
import { handleError } from '../../../actions/auth';
import { setOnDragEndCallback } from '../../DropContext';
import AppToaster from '../../../utils/AppToaster';
import initContext from '../StickyUtils';

const priceGuideVertStickyContext = initContext('priceguide__index');

const OptionContainer = ({
  item,
  options,
  updateOptions,
  updateItem,
  handleErr,
  startFetchUpCharges,
}) => {
  setOnDragEndCallback(item.id, (result) => {
    const updatedItem = item.newInstance();
    const { source, destination } = result;
    updatedItem.moveOption(source.index, destination.index);
    updateItem(updatedItem);
  });
  const onSaveAll = async () => {
    try {
      const objects = item.get('items').map(({ id }) => options[id]);
      await Parse.Object.saveAll(objects);
      const updatedOptions = {};
      objects.forEach((obj) => {
        updatedOptions[obj.id] = obj.newInstance();
      });
      updateOptions(updatedOptions);
      AppToaster.show({ message: 'All Options Saved!', timeout: 3000 });
    } catch (e) {
      handleErr(e);
    }
  };
  const onSelectAll = (isSelected) => {
    const updatedOptions = {};
    item.get('items').forEach(({ id }) => {
      const option = options[id];
      const newInstance = option.newInstance();
      newInstance.isSelected = isSelected;
      updatedOptions[option.id] = newInstance;
    });
    updateOptions(updatedOptions);
  };
  const onExpandAll = (isExpanded) => {
    const updatedOptions = {};
    item.get('items').forEach((optionsItem) => {
      const { id } = optionsItem;
      const option = options[id];
      const newInstance = option.newInstance();
      newInstance.isExpanded = isExpanded;
      updatedOptions[option.id] = newInstance;
    });
    if (isExpanded) {
      startFetchUpCharges(item);
    }
    updateOptions(updatedOptions);
  };
  const onDeleteAllOptions = () => {
    const updatedItem = item.newInstance();
    updatedItem.removeAllOptions();
    updatedItem.isOptionsExpanded = false;
    updateItem(updatedItem);
  };
  const onRefreshAll = () => {
    const updatedOptions = {};
    item.get('items').forEach(({ id }) => {
      const option = options[id];
      const newInstance = option.newInstance();
      newInstance.revert();
      updatedOptions[option.id] = newInstance;
    });
    updateOptions(updatedOptions);
  };
  const itemOptions = item.get('items') || [];
  const ids = itemOptions.map(({ id }) => id);
  const isAllSelected = ids.every(
    (id) => options[id] && options[id].isSelected,
  );
  const isSomeDirty = ids.some((id) => options[id] && options[id].dirty());
  const isAllExpaned = ids.every((id) => options[id] && options[id].isExpanded);
  return (
    <>
      <Collapse
        ref={(ref) => {
          if (ref) {
            priceGuideVertStickyContext.registerCutOff({
              ref: ref.contents,
              uid: item.id,
            });
          }
        }}
        isOpen={item.isOptionsExpanded}
      >
        <Header
          itemId={item.id}
          isSaveAllDisabled={!isSomeDirty}
          onSaveAll={onSaveAll}
          onSelectAll={onSelectAll}
          isAllSelected={isAllSelected}
          onExpandAll={onExpandAll}
          expandAllDisabled={
            !item.has('accessories') || item.get('accessories').length < 1
          }
          isAllExpanded={isAllExpaned}
          onDeleteAll={onDeleteAllOptions}
          onRefresh={onRefreshAll}
          isRefreshDisabled={!isSomeDirty}
        />
        <Droppable droppableId={item.id} type={item.id}>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {ids.map((id, index) => {
                const option = options[id];
                if (!option) {
                  return <div key={id}>Loading...</div>;
                }
                return (
                  <Draggable
                    key={id}
                    draggableId={id}
                    index={index}
                    type={item.id}
                  >
                    {(draggableProvided) => {
                      const component = (
                        <Row
                          draggableProvided={draggableProvided}
                          option={option}
                          item={item}
                        />
                      );
                      return component;
                    }}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </Collapse>
    </>
  );
};

OptionContainer.propTypes = {
  item: PropTypes.instanceOf(MeasureSheetItem).isRequired,
  options: PropTypes.shape({}),
  updateOptions: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
  handleErr: PropTypes.func.isRequired,
};

OptionContainer.defaultProps = {
  options: {},
};

const mapStateToProps = ({ priceGuide2 }) => ({
  options: priceGuide2.options,
});

const mapDispatchToProps = (dispatch) => ({
  updateOptions: (options) => dispatch(setOptions(options)),
  updateItem: (item) => dispatch(setItem(item)),
  handleErr: (e) => dispatch(handleError(e)),
  startFetchUpCharges: (item) => dispatch(startFetchUpChargesForItem(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OptionContainer);
