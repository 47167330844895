/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Parse from 'parse';
import MultiSelectComponent from '@khanacademy/react-multi-select';
import { jsx, css } from '@emotion/core';
import { createStyleElement } from '../../utils/utils';

const MultiSelect = ({
  inTable,
  selected,
  onSelectedChanged,
  options,
  errorMessage,
  style = {},
  ...rest
}) => (
  <div
    onMouseEnter={(event) => {
      if (inTable) {
        event.currentTarget.style.zIndex = 9999;
      }
    }}
    onMouseLeave={(event) => {
      if (inTable) {
        event.currentTarget.style.zIndex = style.zIndex || 1;
      }
    }}
    css={css`
      ${createStyleElement(style)}
    `}
  >
    <MultiSelectComponent
      selected={selected}
      onSelectedChanged={onSelectedChanged}
      options={options}
      hasSelectAll={options.length > 1}
      disableSearch={options.length <= 1}
      {...rest}
    />
  </div>
);

const isMultiSelectSelectedPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]);

MultiSelect.propTypes = {
  selected: PropTypes.arrayOf(isMultiSelectSelectedPropType),
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.instanceOf(Parse.Object),
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
      }),
    ]),
  ).isRequired,
  onSelectedChanged: PropTypes.func.isRequired,
};

MultiSelect.defaultProps = {
  selected: [],
  closeMenuOnSelect: false,
};

const mapStateToProps = ({ config: { styles = {} } }, ownProps) => ({
  style: {
    ...styles.MultiSelect,
    ...ownProps.style,
    ...styles.Base,
  },
});

export default connect(mapStateToProps)(MultiSelect);
