import React from 'react';
import moment from 'moment';

const SignatureContainer = () => (
  <div className="credit-apps__section-signature-container">
    <div className="credit-apps__signature">
      <span className="credit-apps__signature-name">
        {`John Doe ${moment().format('MM/DD/YYYY')}`}
      </span>
    </div>
    <div className="credit-apps__signature">
      <span className="credit-apps__signature-name">
        {`Jane Doe ${moment().format('MM/DD/YYYY')}`}
      </span>
    </div>
  </div>
);

export default SignatureContainer;
