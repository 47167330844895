import React from 'react';
import PropTypes from 'prop-types';
import { LogsSection } from './LogsSection';
import { FileLogSection } from './FileLogSection';
import { LogObjectSection } from './LogObjectSection';

const LogTypeSwitch = (props) => {
  const { type, logs, logObjects, logId } = props;

  switch (type) {
    case 'files':
      return <LogObjectSection logId={logId} logObjects={logObjects} />;
    case 'file-logs':
      return <FileLogSection logId={logId} logObjects={logObjects} />;
    default:
      return <LogsSection logs={logs} />;
  }
};

LogTypeSwitch.propTypes = {
  type: PropTypes.string,
  logId: PropTypes.string,
  logs: PropTypes.arrayOf(PropTypes.shape({})),
  logObjects: PropTypes.arrayOf(PropTypes.shape({})),
};

LogTypeSwitch.defaultProps = {
  type: '',
  logId: '',
  logs: [],
  logObjects: [],
};

export default LogTypeSwitch;
