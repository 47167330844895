/* eslint-disable jsx-a11y/label-has-associated-control */
import { v4 } from 'uuid';
import React from 'react';
import PropTypes from 'prop-types';
import { Form, Col } from 'react-bootstrap';
import { Button, ToggleSwitch } from '../../Leapstrap';
import CellTypesDropDown from './CellTypesDropDown';
import InputTypesDropDown from '../../SharedComponents/InputTypesDropDown';
import ValidationForm from '../../SharedComponents/ValidationForm';
import MinMaxDimentionWidget from '../../SharedComponents/MinMaxDimentionWidget';
import DetailDraggable from './DetailDraggable';
import { dateTimePlaceHolders } from '../../../utils/utils';

const DetailForm = (props) => {
  const {
    detailObject = {},
    color,
    onChange,
    onRemoveClick,
    errors = { objects: {} },
    showNotAdded,
    draggable,
    index,
    isUpCharge,
    tagRow,
  } = props;

  const {
    title,
    note,
    cellType,
    inputType,
    pickerValues,
    defaultValue,
    placeholder,
    shouldCopy,
    required,
    notAddedReplacement,
    objectId,
    dateDisplayFormat,
    disableTemplatePhotoLinking,
  } = detailObject;

  return (
    <DetailDraggable
      draggableId={detailObject.objectId}
      index={index}
      draggable={draggable}
    >
      {cellType === 'photos' ? (
        <Form.Row className="my-1 mx-0 row w-100">
          <Col className="col-12 col-md-3">
            <ValidationForm
              title="Link photos to Templates "
              errorMessage={errors.objects[index].disableTemplatePhotoLinking}
            >
              <ToggleSwitch
                name={`${v4()}`}
                checked={!disableTemplatePhotoLinking}
                onChange={({ currentTarget: { checked } }) => {
                  const event = {
                    currentTarget: {
                      value: !checked,
                      name: 'disableTemplatePhotoLinking',
                    },
                  };
                  onChange(event, detailObject, index);
                }}
              />
            </ValidationForm>
          </Col>
          <Col className="col-12 col-md-3">
            <ValidationForm>
              <span
                style={{
                  width: '100%',
                  color: 'gray',
                  textAlign: 'center',
                }}
              >
                *Photo Selector cell must be added to template
              </span>
            </ValidationForm>
          </Col>
          <Col className="col-12 col-md-3">
            {tagRow ? (
              <ValidationForm
                title="Cell Type"
                errorMessage={errors.objects[index].note}
              >
                <Form.Control disabled type="text" value="Text (words)" />
              </ValidationForm>
            ) : (
              <CellTypesDropDown
                color={color}
                cellType={cellType}
                isUpCharge={isUpCharge}
                onChange={(event) => {
                  onChange(event, detailObject, index);
                }}
              />
            )}
          </Col>
          <Col className="col-12 col-md-3 d-flex flex-wrap align-items-center justify-content-around">
            <ValidationForm className="d-flex flex-column">
              <label className="labeled-switch">Required</label>
              <ToggleSwitch
                name={`${v4()}`}
                checked={required}
                onChange={({ currentTarget: { checked } }) => {
                  const event = {
                    currentTarget: {
                      value: checked,
                      name: 'required',
                    },
                  };
                  onChange(event, detailObject, index);
                }}
              />
            </ValidationForm>
            <ValidationForm className="d-flex flex-column">
              <label className="labeled-switch">Copyable</label>
              <ToggleSwitch
                name={`${v4()}`}
                checked={shouldCopy}
                onChange={({ currentTarget: { checked } }) => {
                  const event = {
                    currentTarget: {
                      value: checked,
                      name: 'shouldCopy',
                    },
                  };
                  onChange(event, detailObject, index);
                }}
              />
            </ValidationForm>
            <Button
              disabled={tagRow}
              onClick={() => onRemoveClick(objectId)}
              color="orange"
            >
              Delete
            </Button>
          </Col>
        </Form.Row>
      ) : (
        <>
          <Form.Row className="my-1 mx-0 row w-100">
            <Col className="col-12 col-md-3">
              <ValidationForm
                title="Title"
                errorMessage={errors.objects[index].title}
              >
                <Form.Control
                  name="title"
                  onChange={(event) => {
                    onChange(event, detailObject, index);
                  }}
                  type="text"
                  placeholder={tagRow ? 'Tag' : undefined}
                  value={title}
                />
              </ValidationForm>
            </Col>
            <Col className="col-12 col-md-3">
              <ValidationForm
                title="Note"
                errorMessage={errors.objects[index].note}
              >
                <Form.Control
                  name="note"
                  onChange={(event) => {
                    onChange(event, detailObject, index);
                  }}
                  type="text"
                  value={note}
                />
              </ValidationForm>
            </Col>
            <Col className="col-12 col-md-3">
              {tagRow ? (
                <ValidationForm
                  title="Cell Type"
                  errorMessage={errors.objects[index].note}
                >
                  <Form.Control disabled type="text" value="Text (words)" />
                </ValidationForm>
              ) : (
                <CellTypesDropDown
                  color={color}
                  cellType={cellType}
                  isUpCharge={isUpCharge}
                  onChange={(event) => {
                    onChange(event, detailObject, index);
                  }}
                />
              )}
            </Col>
            <Col className="col-12 col-md-3">
              <InputTypesDropDown
                color={color}
                inputType={inputType}
                onChange={(event) => {
                  onChange(event, detailObject, index);
                }}
              />
            </Col>
          </Form.Row>

          <Form.Row className="my-1 mx-0 row w-100">
            <Col className="col-12 col-md-3">
              {inputType === 'timePicker' ||
              inputType === 'dateTimePicker' ||
              inputType === 'datePicker' ? (
                <ValidationForm
                  title="Date Format"
                  errorMessage={errors.objects[index].dateDisplayFormat}
                >
                  <Form.Control
                    name="dateDisplayFormat"
                    placeholder={dateTimePlaceHolders[inputType]}
                    onChange={(event) => {
                      onChange(event, detailObject, index);
                    }}
                    type="text"
                    value={dateDisplayFormat}
                  />
                </ValidationForm>
              ) : (
                <ValidationForm
                  title="Picker Values"
                  errorMessage={errors.objects[index].pickerValues}
                >
                  <textarea
                    disabled={detailObject.inputType !== 'picker'}
                    className="form-control"
                    name="pickerValues"
                    onChange={(event) => {
                      const { name, value } = event.currentTarget;

                      const optionEvent = {
                        ...event,
                        currentTarget: {
                          ...event.currentTarget,
                          name,
                          value: value.split('\n'),
                        },
                      };

                      onChange(optionEvent, detailObject, index);
                    }}
                    type="text"
                    value={pickerValues ? pickerValues.join('\n') : ''}
                  />
                </ValidationForm>
              )}
            </Col>
            <Col className="col-12 col-md-3">
              <ValidationForm
                title="Default Value"
                errorMessage={errors.objects[index].defaultValue}
              >
                <Form.Control
                  name="defaultValue"
                  onChange={(event) => {
                    onChange(event, detailObject, index);
                  }}
                  type="text"
                  value={defaultValue}
                />
              </ValidationForm>
            </Col>
            <Col className="col-12 col-md-3">
              <ValidationForm
                title="Placeholder"
                errorMessage={errors.objects[index].placeholder}
              >
                <Form.Control
                  name="placeholder"
                  disabled={!!tagRow}
                  onChange={(event) => {
                    onChange(event, detailObject, index);
                  }}
                  type="text"
                  value={tagRow ? '%tag%' : placeholder}
                />
              </ValidationForm>
            </Col>
            <Col className="col-12 col-md-3 d-flex flex-wrap align-items-center justify-content-around">
              <ValidationForm className="d-flex flex-column">
                <label className="labeled-switch">Required</label>
                <ToggleSwitch
                  name={`${v4()}`}
                  checked={required}
                  onChange={({ currentTarget: { checked } }) => {
                    const event = {
                      currentTarget: {
                        value: checked,
                        name: 'required',
                      },
                    };
                    onChange(event, detailObject, index);
                  }}
                />
              </ValidationForm>
              <ValidationForm className="d-flex flex-column">
                <label className="labeled-switch">Copyable</label>
                <ToggleSwitch
                  name={`${v4()}`}
                  checked={shouldCopy}
                  onChange={({ currentTarget: { checked } }) => {
                    const event = {
                      currentTarget: {
                        value: checked,
                        name: 'shouldCopy',
                      },
                    };
                    onChange(event, detailObject, index);
                  }}
                />
              </ValidationForm>
              <Button
                disabled={tagRow}
                onClick={() => onRemoveClick(objectId)}
                color="orange"
              >
                Delete
              </Button>
            </Col>
          </Form.Row>

          {showNotAdded && (
            <Form.Row className="my-1 mx-0 row w-100">
              <Col className="col-12 col-md-3">
                <ValidationForm
                  title="Not Added Replacement"
                  errorMessage={errors.objects[index].notAddedReplacement}
                >
                  <Form.Control
                    name="notAddedReplacement"
                    onChange={(event) => {
                      onChange(event, detailObject, index);
                    }}
                    type="text"
                    value={notAddedReplacement}
                  />
                </ValidationForm>
              </Col>
            </Form.Row>
          )}
          <MinMaxDimentionWidget
            index={index}
            onChange={onChange}
            detailObject={detailObject}
            errors={errors}
          />
        </>
      )}
    </DetailDraggable>
  );
};

DetailForm.defaultProps = {
  draggable: false,
  showNotAdded: false,
  isUpCharge: false,
  tagRow: false,
  color: 'blue',
};
DetailForm.propTypes = {
  detailObject: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
  errors: PropTypes.shape({}).isRequired,
  showNotAdded: PropTypes.bool,
  draggable: PropTypes.bool,
  isUpCharge: PropTypes.bool,
  tagRow: PropTypes.bool,
  color: PropTypes.string,
  index: PropTypes.number.isRequired,
};

export default DetailForm;
