/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/** @jsx jsx */
import { jsx } from '@emotion/core';

import React from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'leap-menu-item';
import onClickOutside from 'react-onclickoutside';

class SectionTitle extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { showPopover: false };
    this.titleRef = React.createRef();
  }

  onDoubleClick = () => {
    const { showPopover } = this.state;
    this.setState({ showPopover: !showPopover });
  };

  handleClickOutside = () => {
    this.setState({ showPopover: false });
  };

  render() {
    const { style, onChange, onClick } = this.props;
    return (
      <div
        onDoubleClick={(event) => {
          this.onDoubleClick(event);
        }}
        className="outer sectin "
        css={{
          ...style,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <Popover
          content={
            <textarea
              onContextMenu={(e) => e.stopPropagation()}
              fill
              ref={this.titleRef}
              defaultValue={this.props.title}
              className="ignore-react-onclickoutside"
            />
          }
          isOpen={this.state.showPopover}
          onClose={() => {
            if (this.titleRef.current.value !== this.props.title) {
              onChange(this.titleRef.current.value);
            }
          }}
        >
          <div
            className="templates-pdf__text templates-single_line"
            onClick={(event) => {
              onClick(event);
            }}
            css={{
              ...style,
              height: style.fontSize,
              width: 768,
            }}
          >
            {this.props.title}
          </div>
        </Popover>
      </div>
    );
  }
}

SectionTitle.propTypes = {
  title: PropTypes.string,
  style: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

SectionTitle.defaultProps = {
  title: '',
};

export default onClickOutside(SectionTitle);
