import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Parse from 'parse';
import { withLayoutContext, layoutContextPropTypes } from '../../Layout';
import Panel from '../../Panel';
import ResourceGroupCell from '../ResourceGroupCell';
import {
  startFetchSharedResourceGroups as startFetchSharedResourceGroupsAction,
  resetSharedResources as resetSharedResourcesAction,
  importSharedResourceGroup as importSharedResourceGroupAction,
  setSharedResourceGroup as setSharedResourceGroupAction,
} from '../../../actions/resources';

export class ResourceLibrary extends React.Component {
  componentDidMount() {
    const { startFetchSharedResourceGroups } = this.props;
    startFetchSharedResourceGroups();
  }

  componentWillUnmount() {
    const { setCrumbs } = this.props;
    setCrumbs([]);
  }

  render() {
    const {
      history,
      importSharedResourceGroup,
      resetSharedResources,
      sharedResourceGroups,
      setSharedResourceGroup,
    } = this.props;

    return (
      <div className="default-page-padding">
        <Panel title="Shared Resources">
          <div className="resources-container">
            {sharedResourceGroups.map((item) => (
              <ResourceGroupCell
                key={item.id}
                thumbnailURL={
                  item.has('thumbnail')
                    ? item.get('thumbnail').url()
                    : '/images/no_image.png'
                }
                name={item.get('name')}
                onClick={() => {
                  resetSharedResources();
                  setSharedResourceGroup(item);
                  history.push(`/resources/shared_resources/${item.id}`);
                }}
                onCopyAll={() => importSharedResourceGroup(item.id)}
              />
            ))}
          </div>
        </Panel>
      </div>
    );
  }
}

ResourceLibrary.propTypes = {
  setSharedResourceGroup: PropTypes.func.isRequired,
  sharedResourceGroups: PropTypes.arrayOf(PropTypes.instanceOf(Parse.Object)),
  startFetchSharedResourceGroups: PropTypes.func.isRequired,
  resetSharedResources: PropTypes.func.isRequired,
  importSharedResourceGroup: PropTypes.func.isRequired,
  ...layoutContextPropTypes,
};

ResourceLibrary.defaultProps = {
  sharedResourceGroups: [],
};

const mapStateToProps = ({ resources: { sharedResourceGroups } }) => ({
  sharedResourceGroups,
});

const mapDispatchToProps = (dispatch) => ({
  importSharedResourceGroup: (sharedResourceGroupId) =>
    dispatch(importSharedResourceGroupAction(sharedResourceGroupId)),
  startFetchSharedResourceGroups: () =>
    dispatch(startFetchSharedResourceGroupsAction()),
  resetSharedResources: () => dispatch(resetSharedResourcesAction()),
  setSharedResourceGroup: (sharedResourceGroup) =>
    dispatch(setSharedResourceGroupAction(sharedResourceGroup)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(ResourceLibrary),
);
