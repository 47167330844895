/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { Button } from '@blueprintjs/core';

Modal.setAppElement('#root');

const CLOSE_TIMEOUT = 500;

const SlidingPane = ({
  isOpen,
  title,
  subtitle,
  onRequestClose,
  onAfterOpen,
  children,
  className,
  overlayClassName,
  from = 'right',
  width,
  onClearClicked,
  onRefreshClick,
  onDoneClicked,
}) => {
  const directionClass = `slide-pane_from_${from}`;
  return (
    <Modal
      className={`slide-pane ${directionClass} ${className || ''}`}
      style={{
        content: { width: width || '80%' },
      }}
      overlayClassName={`slide-pane__overlay ${overlayClassName || ''}`}
      closeTimeoutMS={CLOSE_TIMEOUT}
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={onRequestClose}
      contentLabel={`Modal "${title || ''}"`}
    >
      <div className="slide-pane__header">
        <Button icon="chevron-left" onClick={onRequestClose} minimal />
        <div className="slide-pane__title-wrapper">
          <h2 className="slide-pane__title">{title}</h2>
          <div className="slide-pane__subtitle">{subtitle}</div>
        </div>
        <Button icon="refresh" onClick={onRefreshClick} minimal />
        <Button onClick={onClearClicked} minimal>
          Clear
        </Button>
        <Button onClick={onDoneClicked} minimal>
          Done
        </Button>
      </div>
      <div className="slide-pane__content">{children}</div>
    </Modal>
  );
};

SlidingPane.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.any,
  subtitle: PropTypes.any,
  onRequestClose: PropTypes.func,
  onAfterOpen: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
  from: PropTypes.oneOf(['left', 'right', 'bottom']),
  width: PropTypes.string,
  onDoneClicked: PropTypes.func.isRequired,
  onClearClicked: PropTypes.func.isRequired,
};

export default SlidingPane;
