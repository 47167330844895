import { randomKey } from '../../../utils/utils';

const valueKeyMaps = {
  size: 'valueFontSize',
  bold: 'valueFontBold',
  underline: 'valueFontUnderline',
  name: 'valueFontName',
  color: 'valueFontColor',
};

const noteKeyMaps = {
  size: 'noteFontSize',
  bold: 'noteFontBold',
  underline: 'noteFontUnderline',
  name: 'noteFontName',
  color: 'noteFontColor',
};

const containerKey = 'signature_additional_line';
const pasteKey = 'signature_additional_line';

class SignatureAdditionalLine {
  constructor(item, parent) {
    this.JSON = item;
    this.keys = [
      'objectId',
      'underline',
      'value',
      'note',
      'valueFontSize',
      'valueFontBold',
      'valueFontUnderline',
      'valueFontName',
      'valueFontColor',
      'noteFontSize',
      'noteFontBold',
      'noteFontUnderline',
      'noteFontName',
      'noteFontColor',
    ];
    this.containerKey = containerKey;
    this.pasteKey = pasteKey;
    this.parent = parent;
    this.toolbarKey = 'sectionTitle';
    this.toolbarKeyMaps = valueKeyMaps;
    this.toolboxKey = 'signature-detail';
    this.secondaryToolbarKeyMaps = noteKeyMaps;
    this.titleValueControlTitles = ['Value', 'Note'];
    this.toolbarKeys = [
      'size',
      'bold',
      'underline',
      'name',
      'color',
      'titleValueControl',
    ];
    if (item) {
      Object.assign(this, item);
      this.objectId = this.objectId || randomKey(10);
    } else {
      this.underline = true;
      this.value = '%date%';
      this.note = 'Date';
      this.valueFontSize = 12;
      this.valueFontBold = true;
      this.valueFontUnderline = false;
      this.valueFontName = '';
      this.valueFontColor = '0-0-0-1';
      this.noteFontSize = 8;
      this.noteFontBold = false;
      this.noteFontUnderline = false;
      this.noteFontName = '';
      this.noteFontColor = '0-0-0-1';
    }
    if (!this.objectId) {
      this.objectId = randomKey(10);
    }
    const source = this.getSourceObject();
    if (source) {
      if (source.hasObjectId(this.objectId)) {
        this.objectId = randomKey(10);
      }
      source.addObjectId(this.objectId);
    }
  }

  get identifier() {
    return `${this.parent.identifier}-${this.objectId}`;
  }

  getPath() {
    if (!this.parent) {
      return undefined;
    }
    const index = this.parent.additionalLines.findIndex(
      ({ objectId }) => objectId === this.objectId,
    );
    return [...this.parent.getPath(), index];
  }

  getSourceObject() {
    if (!this.parent) {
      return undefined;
    }
    return this.parent.getSourceObject();
  }

  clone() {
    const jsonObject = this.toJSON();
    const json = JSON.parse(JSON.stringify(jsonObject));
    json.objectId = randomKey(10);
    return new SignatureAdditionalLine(json, this.parent);
  }

  toJSON() {
    const json = {};
    this.keys.forEach((key) => {
      json[key] = this[key];
    });
    return json;
  }

  static ContainerKey = containerKey;

  static PasteKey = pasteKey;
}

export default SignatureAdditionalLine;
