/* eslint-disable import/prefer-default-export */
/* eslint-disable no-param-reassign */
import React from 'react';
import { ContextMenu } from 'leap-menu-item';
import CopyPasteMenu from '../../Templates/CopyPasteMenu';
import {
  setPasteboardValue,
  getPasteboardValue,
} from '../../../utils/Pasteboard';
import CreditApplicationLineItem from '../Models/CreditApplicationLineItem';

import { initCreditAppCell } from './CreditApplicationCellToolDrawer';

const creditAppPasteboardKey = 'creditAppPasteboardKey';

const onDelete = ({ application, cellItem, commit, setApplication }) => {
  const { cell } = cellItem;
  const { objectId } = cell;

  const [sectionIndex, cellIndex] = objectId
    .split('-')
    .map((indexKey) => parseInt(indexKey, 10));
  const sections = application.get('sections');
  const rightSection = sections[sectionIndex];

  const { cells } = rightSection;

  cells.splice(cellIndex, 1);

  const updatedSections = sections.map((section, index) => {
    if (index === sectionIndex) {
      section.cells = cells;
      return section;
    }
    return section;
  });

  application.set('sections', updatedSections);
  setApplication(application);
  commit();
};

const onCopy = ({ cellItem }) => {
  setPasteboardValue(cellItem.cell, creditAppPasteboardKey);
};

const onCut = (props) => {
  onCopy(props);
  onDelete(props);
};

const onPasteObject = (
  object,
  index,
  { application, commit, setApplication, cellItem },
) => {
  if (object) {
    const sections = application.get('sections');
    const { cell } = cellItem;
    const { objectId } = cell;
    const [sectionIndex, cellIndex] = objectId
      .split('-')
      .map((indexKey) => parseInt(indexKey, 10));
    const rightSection = sections[sectionIndex];
    const { cells } = rightSection;
    const spliceIndex = cellIndex + (index - 1);
    cells.splice(spliceIndex, 0, object);
    const updatedSections = sections.map((section, index2) => {
      if (index2 === sectionIndex) {
        section.cells = cells;
        return section;
      }
      return section;
    });

    application.set('sections', updatedSections);
    setApplication(application);
    commit();
  }
};

const onInsertObject = (props) => {
  const { cellItem } = props;
  const { cell } = cellItem;
  const { objectId } = cell;
  const [sectionIndex, cellIndex] = objectId
    .split('-')
    .map((indexKey) => parseInt(indexKey, 10));
  const object = new CreditApplicationLineItem(
    initCreditAppCell,
    sectionIndex,
    cellIndex,
  );
  onPasteObject(object, 1, props);
};

export const onContextMenu = (e, props) => {
  e.preventDefault();
  props.setEditCellItem(props.cellItem);
  const pasteBoardValue = getPasteboardValue(creditAppPasteboardKey) || {};
  const menu = (
    <CopyPasteMenu
      onInsert={() => onInsertObject(props)}
      onDelete={() => onDelete(props)}
      onCut={() => onCut(props)}
      onCopy={() => onCopy(props)}
      onPasteAbove={() =>
        onPasteObject(getPasteboardValue(creditAppPasteboardKey), 0, props)
      }
      pasteAboveDisabled={
        pasteBoardValue.containerKey !== props.cellItem.containerKey
      }
      onPasteBelow={() =>
        onPasteObject(getPasteboardValue(creditAppPasteboardKey), 1, props)
      }
      pasteBelowDisabled={
        pasteBoardValue.containerKey !== props.cellItem.containerKey
      }
    />
  );
  ContextMenu.show(menu, { left: e.clientX, top: e.clientY });
};
