/* eslint-disable import/prefer-default-export */
import CreditApplicationLineItem from '../Models/CreditApplicationLineItem';
import { setOnDragEndCallback } from '../../DropContext';
import { creditAppCellTypes } from './CreditApplicationCellToolDrawer';

export const setCallbackForDragEnd = (
  droppableId,
  application,
  setApplication,
  commit,
) => {
  setOnDragEndCallback(droppableId, (result) => {
    const { destination, source } = result;
    const sections = application.get('sections');
    let sourceSectionIndex;
    let destinationSectionIndex;
    let sourceIndex;
    let destinationIndex;
    if (source.droppableId.split('-')[0] === 'applicationInfo') {
      sourceSectionIndex = 0;
      sourceIndex = 2 * source.index;
      if (source.droppableId.split('-')[1] === 'right') {
        sourceIndex += 1;
      }
    } else {
      sourceIndex = source.index;
      if (source.droppableId.split('-')[1] === 'left') {
        sourceSectionIndex = 1;
      } else {
        sourceSectionIndex = 2;
      }
    }
    if (destination.droppableId.split('-')[0] === 'applicationInfo') {
      destinationSectionIndex = 0;
      destinationIndex = 2 * destination.index;
      if (destination.droppableId.split('-')[1] === 'right') {
        destinationIndex += 1;
      }
    } else {
      destinationIndex = destination.index;
      if (destination.droppableId.split('-')[1] === 'left') {
        destinationSectionIndex = 1;
      } else {
        destinationSectionIndex = 2;
      }
    }
    if (source.droppableId === 'creditAppToolDrawer') {
      const destinationCells = sections[destinationSectionIndex].cells;
      const cellData = creditAppCellTypes[source.index];
      const newCell = new CreditApplicationLineItem(
        cellData,
        destinationSectionIndex,
        destinationIndex,
      );
      destinationCells.splice(destinationIndex, 0, newCell);
      sections[destinationSectionIndex].cells = destinationCells;
      application.set('sections', sections);
      setApplication(application);
      commit();
      return;
    }
    if (sourceSectionIndex === destinationSectionIndex) {
      const { cells } = sections[sourceSectionIndex];
      [cells[sourceIndex], cells[destinationIndex]] = [
        cells[destinationIndex],
        cells[sourceIndex],
      ];
      sections[sourceSectionIndex].cells = cells;
    } else {
      const sourceCells = sections[sourceSectionIndex].cells;
      const destinationCells = sections[destinationSectionIndex].cells;
      destinationCells.splice(destinationIndex, 0, sourceCells[sourceIndex]);
      sourceCells.splice(sourceIndex, 1);
      sections[sourceSectionIndex].cells = sourceCells;
    }
    application.set('sections', sections);
    setApplication(application);
    commit();
  });
};
