/* eslint-disable react/forbid-prop-types */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Classes,
  Alignment,
  Navbar,
  NavbarGroup,
  Button,
  ButtonGroup,
  Popover,
  Position,
  FileInput,
  Menu,
} from 'leap-menu-item';
import { ChromePicker } from 'react-color';
import {
  setInitialStateForTemplate,
  setEditCellItem,
} from '../../../actions/templateEdit';
import FontSelect from '../FontSelect';
import ContractObject from '../Models/ContractObject';
import HeaderGroup from '../Models/HeaderGroup';
import BodyGroup from '../Models/BodyGroup';
import SignatureSection from '../Models/SignatureSection';
import SignatureCellObject from '../Models/SignatureItem';
import SignatureAdditionalLine from '../Models/SignatureAdditionalLine';
import BodyCellItemObject from '../Models/BodyCellItem';
import HeaderCellObject from '../Models/HeaderCell';
import Toolbox from '../Toolbox/Toolbox';

const toolbarStyle = {
  position: 'relative',
  background: 'rgba(255,255,255, 0.85)',
  backdropFilter: 'blur(2px)',
  boxShadow: '0 3px 3px -3px rgba(0,0,0,0.75)',
};

class BodyTitleToolbar extends React.Component {
  constructor(props) {
    super(props);
    const { object } = props;
    const { editKey } = object;
    const selectionIndex = editKey === 'value' ? 1 : 0;
    this.state = { selectionIndex };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { object } = newProps;
    const { editKey } = object;
    const selectionIndex = editKey === 'value' ? 1 : 0;
    this.setState({ selectionIndex });
  }

  render() {
    const {
      templateValues,
      object,
      setTemplateState,
      editCellItem,
    } = this.props;
    const contractObject = new ContractObject(templateValues.toJSON());
    const updatedObject = contractObject.getObjectWithId(object.objectId) || {};
    const keys = updatedObject.toolbarKeys || [];
    const keyMaps =
      (this.state.selectionIndex === 0
        ? updatedObject.toolbarKeyMaps
        : updatedObject.secondaryToolbarKeyMaps) || {};
    const colorValue = updatedObject[keyMaps.color] || '0-0-0-1';
    const [r, g, b, a] = colorValue.split('-');
    const { titleValueControlTitles = [] } = updatedObject;
    return (
      <Navbar css={toolbarStyle}>
        <NavbarGroup align={Alignment.LEFT}>
          <Toolbox />
          <Menu.Divider />
          {keys.indexOf('titleValueControl') > -1 && (
            <ButtonGroup>
              <Button
                onClick={() => {
                  updatedObject.editKey = 'title';
                  editCellItem.editKey = 'title';
                  this.props.setEditCellItem(updatedObject);
                  setTemplateState(contractObject);
                  this.setState({ selectionIndex: 0 });
                }}
                active={this.state.selectionIndex === 0}
              >
                {titleValueControlTitles[0] || 'Title'}
              </Button>
              <Button
                onClick={() => {
                  updatedObject.editKey = 'value';
                  editCellItem.editKey = 'value';
                  this.props.setEditCellItem(updatedObject);
                  setTemplateState(contractObject);
                  this.setState({ selectionIndex: 1 });
                }}
                active={this.state.selectionIndex === 1}
              >
                {titleValueControlTitles[1] || 'Value'}
              </Button>
            </ButtonGroup>
          )}
          {keys.indexOf('titleValueControl') > -1 && <Navbar.Divider />}
          {keys.indexOf('bold') > -1 && (
            <Button
              icon="bold"
              active={
                updatedObject[keyMaps.bold] ||
                updatedObject[keyMaps.bold] === undefined
              }
              className={Classes.MINIMAL}
              onClick={() => {
                updatedObject[keyMaps.bold] = !updatedObject[keyMaps.bold];
                setTemplateState(contractObject);
              }}
            />
          )}
          {keys.indexOf('underline') > -1 && (
            <Button
              icon="underline"
              active={updatedObject[keyMaps.underline]}
              className={Classes.MINIMAL}
              onClick={() => {
                updatedObject[keyMaps.underline] = !updatedObject[
                  keyMaps.underline
                ];
                setTemplateState(contractObject);
              }}
            />
          )}
          {(keys.indexOf('bold') > -1 || keys.indexOf('underline') > -1) && (
            <Navbar.Divider />
          )}
          {(keys.indexOf('align') > -1 ||
            keys.indexOf('bold') > -1 ||
            keys.indexOf('underline') > -1) && (
            <ButtonGroup minimal>
              <Button
                icon="align-left"
                disabled={keys.indexOf('align') === -1}
                active={updatedObject[keyMaps.align] === 0}
                onClick={() => {
                  updatedObject[keyMaps.align] = 0;
                  setTemplateState(contractObject);
                }}
              />
              <Button
                icon="align-center"
                disabled={keys.indexOf('align') === -1}
                active={updatedObject[keyMaps.align] === 1}
                onClick={() => {
                  updatedObject[keyMaps.align] = 1;
                  setTemplateState(contractObject);
                }}
              />
              <Button
                icon="align-right"
                disabled={keys.indexOf('align') === -1}
                active={updatedObject[keyMaps.align] === 2}
                onClick={() => {
                  updatedObject[keyMaps.align] = 2;
                  setTemplateState(contractObject);
                }}
              />
            </ButtonGroup>
          )}
          {(keys.indexOf('align') > -1 ||
            keys.indexOf('bold') > -1 ||
            keys.indexOf('underline') > -1) && <Navbar.Divider />}
          {keys.indexOf('color') > -1 && (
            <Popover
              content={
                <ChromePicker
                  color={{ r, g, b, a }}
                  onChange={({ rgb }) => {
                    const newValue = `${rgb.r}-${rgb.g}-${rgb.b}-${rgb.a}`;
                    updatedObject[keyMaps.color] = newValue;
                    setTemplateState(contractObject);
                  }}
                />
              }
              position={Position.BOTTOM}
            >
              <div
                css={{
                  marginRight: '10px',
                  padding: '5px',
                  background: '#fff',
                  borderRadius: '1px',
                  boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                  display: 'inline-block',
                  cursor: 'pointer',
                }}
              >
                <div
                  css={{
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                    background: `rgba(${r}, ${g}, ${b}, ${a})`,
                  }}
                />
              </div>
            </Popover>
          )}
          {keys.indexOf('size') > -1 && (
            <input
              type="number"
              value={updatedObject[keyMaps.size]}
              onChange={(event) => {
                const size = parseFloat(event.target.value);
                updatedObject[keyMaps.size] = size > 0 ? size : undefined;
                setTemplateState(contractObject);
              }}
            />
          )}
          {keys.indexOf('name') > -1 && (
            <FontSelect
              css={{
                width: '200px',
                cursor: 'pointer',
                margin: '0 10px',
              }}
              onChange={(newValue) => {
                updatedObject[keyMaps.name] = newValue;
                setTemplateState(contractObject);
              }}
              value={updatedObject[keyMaps.name]}
              isClearable={false}
            />
          )}
          {keys.indexOf('file') > -1 && (
            <div css={{ margin: '0px 10px' }}>
              <FileInput
                text="Image"
                inputProps={{ accept: 'image/*' }}
                onInputChange={(e) => {
                  const file = e.target.files[0];
                  const reader = new FileReader();
                  reader.onload = (onload) => {
                    updatedObject[keyMaps.file] = {
                      url: onload.target.result,
                      isNew: true,
                      file,
                    };
                    setTemplateState(contractObject);
                  };
                  reader.readAsDataURL(file);
                }}
              />
            </div>
          )}
        </NavbarGroup>
      </Navbar>
    );
  }
}

BodyTitleToolbar.propTypes = {
  templateValues: PropTypes.instanceOf(ContractObject).isRequired,
  setTemplateState: PropTypes.func.isRequired,
  setEditCellItem: PropTypes.func.isRequired,
  object: PropTypes.oneOfType([
    PropTypes.instanceOf(HeaderGroup),
    PropTypes.instanceOf(BodyGroup),
    PropTypes.instanceOf(SignatureSection),
    PropTypes.instanceOf(BodyCellItemObject),
    PropTypes.instanceOf(HeaderCellObject),
    PropTypes.instanceOf(SignatureCellObject),
    PropTypes.instanceOf(SignatureAdditionalLine),
  ]).isRequired,
  editCellItem: PropTypes.any,
};

BodyTitleToolbar.defaultProps = {
  editCellItem: undefined,
};

const mapStateToProps = ({ templatesEdit = {} }) => ({
  templateValues:
    templatesEdit[templatesEdit.currentWorkingId] || new ContractObject(),
  editCellItem: templatesEdit.editCellItem,
});

const mapDispatchToProps = (dispatch) => ({
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
  setEditCellItem: (editCellItem) => dispatch(setEditCellItem(editCellItem)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BodyTitleToolbar);
