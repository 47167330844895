/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/no-access-state-in-setstate */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Popover, Position } from 'leap-menu-item';
import onClickOutside from 'react-onclickoutside';
import {
  setInitialStateForTemplate,
  setEditCellItem,
} from '../../../actions/templateEdit';
import ContractObject from '../Models/ContractObject';
import HeaderCellObject from '../Models/HeaderCell';
import BodyRow from '../BodyRow';
import { fontFamilyExists } from '../FontSelect';

const textAlignMap = {
  0: 'left',
  1: 'center',
  2: 'right',
};

const cellHeight = (cellType, emptySpaceHeight, logoHeight = 40) => {
  switch (cellType) {
    case 'emptySpace':
      return `${emptySpaceHeight}px`;
    case 'companyLogo':
    case 'image':
      return `${logoHeight || 0}px`;
    default:
      return undefined;
  }
};

const colorFromString = (string) => {
  const [r, g, b, a] = string.split('-');
  return `rgba(${r},${g},${b},${a})`;
};

const headerRowStyle = (
  {
    fontSize,
    logoHeight,
    fontBold,
    fontUnderline,
    fontName,
    alignment,
    emptySpaceHeight,
    cellType,
    fontColor,
  },
  isHovering,
) => ({
  color: fontColor ? colorFromString(fontColor) : undefined,
  cursor: 'pointer',
  fontSize,
  fontFamily: fontFamilyExists(fontName) ? fontName : 'HelveticaNeue',
  minHeight: cellHeight(cellType, emptySpaceHeight, logoHeight),
  maxHeight: cellHeight(cellType, emptySpaceHeight, logoHeight),
  textAlign: textAlignMap[alignment],
  textDecoration: fontUnderline ? 'underline' : undefined,
  fontWeight: fontBold ? '900' : '400',
  width: '100%',
  opacity: isHovering ? '0.7' : undefined,
});

const imageCell = (imageURL, style) => (
  <div id="header-cell-item" css={style}>
    <img
      alt="Header"
      src={imageURL}
      css={{
        objectFit: 'contain',
        maxWidth: '100%',
        maxHeight: style.maxHeight,
      }}
    />
  </div>
);

class HeaderCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isHovering: false, showPopover: false };
    this.inputRef = React.createRef();
  }

  onMouseOver = () => {
    this.setState({ isHovering: true });
  };

  onMouseOut = () => {
    this.setState({ isHovering: false });
  };

  onMouseDown = () => {
    this.props.setEditItem(this.props.cell);
  };

  onDoubleClick = () => {
    this.setState({ showPopover: !this.state.showPopover });
  };

  handleClickOutside = () => {
    if (this.state.showPopover) {
      this.setState({ showPopover: false });
    }
  };

  render() {
    const style = headerRowStyle(this.props.cell, this.state.isHovering);
    const { cell = {}, setTemplateState, companyLogo } = this.props;
    const { cellType, image, value = '' } = cell;
    if (value === '%image%' || cellType === 'image') {
      return (
        <BodyRow cellItem={cell}>
          <div
            onMouseOver={this.onMouseOver}
            onMouseOut={this.onMouseOut}
            onMouseDown={this.onMouseDown}
          >
            {imageCell(
              image
                ? typeof image.url === 'string'
                  ? image.url
                  : typeof image.url === 'function'
                  ? image.url()
                  : undefined
                : undefined,
              style,
            )}
          </div>
        </BodyRow>
      );
    }
    if (value === '%companyLogo%' || cellType === 'companyLogo') {
      return (
        <BodyRow cellItem={cell}>
          <div
            onMouseOver={this.onMouseOver}
            onMouseOut={this.onMouseOut}
            onMouseDown={this.onMouseDown}
          >
            {imageCell(companyLogo.url, style)}
          </div>
        </BodyRow>
      );
    }
    if (cellType === 'emptySpace') {
      return (
        <BodyRow cellItem={cell}>
          <div
            onMouseOver={this.onMouseOver}
            onMouseOut={this.onMouseOut}
            onMouseDown={this.onMouseDown}
          >
            <div css={style} />
          </div>
        </BodyRow>
      );
    }
    return (
      <BodyRow cellItem={cell}>
        <div
          onMouseOver={this.onMouseOver}
          onMouseOut={this.onMouseOut}
          onMouseDown={this.onMouseDown}
          onDoubleClick={this.onDoubleClick}
          css={style}
        >
          <Popover
            content={
              <textarea
                onContextMenu={(e) => e.stopPropagation()}
                fill
                ref={this.inputRef}
                defaultValue={value}
                className="ignore-react-onclickoutside"
              />
            }
            position={Position.BOTTOM}
            isOpen={this.state.showPopover}
            onClose={() => {
              if (cell.value !== this.inputRef.current.value) {
                cell.value = this.inputRef.current.value;
                const contractObject = cell.getSourceObject();
                setTemplateState(new ContractObject(contractObject.toJSON()));
              }
            }}
          >
            <div css={style}>
              {value.split(/[\r\n]/).map((string, i) => (
                <span key={i}>
                  {string}
                  <br />
                </span>
              ))}
            </div>
          </Popover>
        </div>
      </BodyRow>
    );
  }
}

HeaderCell.propTypes = {
  cell: PropTypes.instanceOf(HeaderCellObject).isRequired,
  setEditItem: PropTypes.func.isRequired,
  setTemplateState: PropTypes.func.isRequired,
  companyLogo: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
};

HeaderCell.defaultProps = {
  companyLogo: {
    url: '',
  },
};

const mapStateToProps = ({ auth }) => ({
  companyLogo: auth.config.companyLogo,
});

const mapDispatchToProps = (dispatch) => ({
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
  setEditItem: (cellItem) => dispatch(setEditCellItem(cellItem)),
});

const wrappedComponent = onClickOutside(HeaderCell);
export default connect(mapStateToProps, mapDispatchToProps)(wrappedComponent);
