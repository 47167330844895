import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ValidationForm from '../../../../SharedComponents/ValidationForm';
import { dateTimePlaceHolders } from '../../../../../utils/utils';
import Select from '../../../../Leapstrap/Select';

const DynamicInputSwitch = (props) => {
  const {
    index,
    object = {},
    errors = { objects: {} },
    onValueChange,
    linkedObjectOptions,
    showDocLinking,
  } = props;

  const { inputType, values = [], dateDisplayFormat } = object;
  const errorObject = errors.objects[index] || {};
  switch (inputType) {
    case 'timePicker':
    case 'dateTimePicker':
    case 'datePicker': {
      return (
        <ValidationForm
          title="Date Format"
          errorMessage={errorObject.dateDisplayFormat}
        >
          <Form.Control
            name="dateDisplayFormat"
            placeholder={dateTimePlaceHolders[inputType]}
            onChange={(event) => {
              const { value } = event.currentTarget;
              onValueChange(value, 'dateDisplayFormat');
            }}
            type="text"
            value={dateDisplayFormat}
          />
        </ValidationForm>
      );
    }
    case '__picker': {
      return (
        <ValidationForm
          title="Picker Options"
          errorMessage={errorObject.values}
        >
          <textarea
            className="form-control"
            name="values"
            onChange={(event) => {
              const { value } = event.currentTarget;
              const list = value.split('\n');
              onValueChange(list, 'values');
            }}
            type="text"
            value={values.join('\n')}
          />
        </ValidationForm>
      );
    }
    case 'linkedObject' && showDocLinking: {
      const capitalizeWord = _.capitalize(values);
      return (
        <ValidationForm
          title="Picker Options"
          errorMessage={errorObject.values}
        >
          <Select
            options={linkedObjectOptions}
            placeholder="Select Linked Object"
            onChange={(selected) => {
              onValueChange(selected.value, 'values');
            }}
            value={{ label: capitalizeWord, value: capitalizeWord }}
            closeMenuOnSelect
            isClearable={false}
          />
        </ValidationForm>
      );
    }
    default:
      return <></>;
  }
};

DynamicInputSwitch.propTypes = {
  object: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  onValueChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  jobNimbusWorkflowTypes: PropTypes.shape({}).isRequired,
  linkedObjectOptions: PropTypes.shape([{}]),
  showDocLinking: PropTypes.bool,
};

DynamicInputSwitch.defaultProps = {
  linkedObjectOptions: [],
  showDocLinking: false,
};

export default DynamicInputSwitch;
