/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Parse from 'parse';
import { Card } from 'react-bootstrap';
import LeapAnalyticsPie from './LeapAnalyticsPie';

const ExamplePieSectionRenderer = ({ name, percent, ...rest }) =>
  `${name} ${(percent * 100).toFixed(0)}%`;
const ExampleToolTipRenderer = ({ name, percent, ...rest }) => (
  <div>{`${(percent * 100).toFixed(0)}%  ${name}`}</div>
);

class LeapPiePanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      data: [],
    };
  }

  componentDidMount = async () => {
    const loggedIn = !!Parse.User.current();
    if (loggedIn) {
      const { startDate, endDate } = this.props;
      const results = await Parse.Cloud.run(this.props.cloudFunctionName, {
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
        ...this.props.cloudFunctionParams,
      });
      this.setState({
        ...results,
      });
    }
  };

  componentDidUpdate = async (previousProps) => {
    const { startDate, endDate } = this.props;
    if (
      startDate !== previousProps.startDate ||
      endDate !== previousProps.endDate
    ) {
      const results = await Parse.Cloud.run(this.props.cloudFunctionName, {
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
        ...this.props.cloudFunctionParams,
      });
      this.setState({
        ...results,
      });
    }
  };

  render() {
    return (
      <Card style={{ width: 250, margin: 20 }}>
        <Card.Title
          style={{ display: 'flex', justifyContent: 'center', margin: 10 }}
          componentClass="h3"
        >
          {this.props.chartTitle}
        </Card.Title>
        <Card.Body
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <LeapAnalyticsPie
            toolTipRenderer={
              this.props.toolTipRenderer || ExampleToolTipRenderer
            }
            labelRenderer={
              this.props.labelRenderer || ExamplePieSectionRenderer
            }
            data={this.state.data}
            dataKey={this.props.dataKey}
          />
          <span>
            <b>Total</b>
          </span>
          <p>
            <b>{this.state.total}</b>
          </p>
        </Card.Body>
      </Card>
    );
  }
}

LeapPiePanel.propTypes = {
  cloudFunctionName: PropTypes.string.isRequired,
  cloudFunctionParams: PropTypes.shape({}).isRequired,
  toolTipRenderer: PropTypes.func,
  labelRenderer: PropTypes.func,
  title: PropTypes.string,
  dataKey: PropTypes.string,
};

LeapPiePanel.defaultProps = {
  labelRenderer: null,
  toolTipRenderer: null,
  title: '',
  dataKey: 'value',
};

const mapStateToProps = ({ leapAnalytics }) => {
  const { startDate, endDate } = leapAnalytics;
  return {
    startDate,
    endDate,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LeapPiePanel);
