/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import { FieldTitle } from './FieldTitle';

export const FieldDisplayList = (props) => {
  const { title, list = [], colClass = 'col-md-3', horizontal } = props;
  return (
    <section className={`d-flex flex-column ${colClass}`}>
      {!!title && <FieldTitle title={title} />}
      <dl className={`d-flex flex-${horizontal ? 'row' : 'column'}`}>
        {list.map((item, i) => {
          return (
            <div
              key={i}
              className="d-flex flex-column"
              style={{ flex: horizontal ? 1 : 0 }}
            >
              {item.label && item.label.length && (
                <dt className="list-line-action-item"> {item.label || ''} </dt>
              )}
              {item.value && item.value.length && (
                <dd className="field-display-list-value"> {item.value}</dd>
              )}
            </div>
          );
        })}
      </dl>
    </section>
  );
};
