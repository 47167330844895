import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { LayoutContext } from '../Layout';
import { pushToDataLayer as pushToDataLayerAction } from '../../actions/tagManager';
import { startUpdateConfig as startUpdateConfigAction } from '../../actions/auth';
import { SwitchGroup } from '../FormGroup';
import Copy from '../AppSettings/Copy';
import Panel from '../Panel';
import TitleButton from '../TitleButton';

/** @typedef {{
 *  isEnabled: boolean,
 *  showCopy: boolean,
 * }} CompanyCamState */

const CompanyCam = () => {
  const title = 'CompanyCam';
  const { setButtons, setCrumbs } = useContext(LayoutContext);
  // Get config values from redux store
  const companyCam = useSelector(({ auth: { config } }) => config.companyCam);
  // Set dispatch instance and map actions to functions
  const dispatch = useDispatch();
  const [pushToDataLayer, startUpdateConfig] = [
    /** @param {import('../../actions/tagManager').AnalyticsEventData} payload */
    (payload) => dispatch(pushToDataLayerAction(payload)),
    (configUpdate) => dispatch(startUpdateConfigAction(configUpdate)),
  ];
  // Create state object and destructure it
  const [{ isEnabled, showCopy }, updateState] = useState({
    isEnabled: false,
    showCopy: false,
  });
  // Create a setState function that mirrors the way class state works
  /** @param {Partial<CompanyCamState>} newState */
  const setState = (newState) => {
    updateState(
      /** @param {CompanyCamState} prevState */
      (prevState) => ({
        ...prevState,
        ...newState,
      }),
    );
  };

  // Set state from redux store values
  useEffect(() => {
    if (typeof companyCam === 'object') {
      const { isEnabled: _isEnabled = false } = companyCam;
      setState({ isEnabled: _isEnabled });
    }
  }, [companyCam]);

  const onCopyClicked = () => {
    setState({ showCopy: true });
  };

  const onSaveClicked = () => {
    startUpdateConfig({ companyCam: { isEnabled } });
  };

  const buttons = (
    <TitleButtons onCopyClicked={onCopyClicked} onSaveClicked={onSaveClicked} />
  );

  // Add crumb and buttons to breadcrumb bar
  useEffect(() => {
    setCrumbs([{ title, link: '/companyCam' }]);
    setButtons(buttons);
    return () => {
      setCrumbs([]);
      setButtons();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnabled, setCrumbs, setButtons]);

  /** @param {boolean} checked */
  const onEnabledChanged = (checked) => {
    setState({ isEnabled: checked });
    if (!checked) {
      setState({ token: '' });
      pushToDataLayer({
        event: 'integrationEvent',
        eventCategory: 'Integration',
        eventAction: 'Disable',
        eventLabel: 'CompanyCam',
      });
    } else {
      pushToDataLayer({
        event: 'integrationEvent',
        eventCategory: 'Integration',
        eventAction: 'Enable',
        eventLabel: 'CompanyCam',
      });
    }
  };

  return (
    <div className="default-page-padding">
      <Copy
        title={`Copy ${title} Settings`}
        show={showCopy}
        warning={`Warning! This will overwrite your current ${title} settings`}
        configKeys={['companyCam']}
        onClose={() => setState({ showCopy: false })}
      />
      <Panel title={title}>
        <div>
          <SwitchGroup
            title="Enabled"
            checked={isEnabled}
            onChange={onEnabledChanged}
          />
        </div>
      </Panel>
    </div>
  );
};

/**
 * @param {{
 *  onClearClick: () => void,
 *  onCopyClicked: () => void,
 *  onSaveClicked: () => void,
 * }} props
 */
const TitleButtons = ({ onClearClick, onCopyClicked, onSaveClicked }) => (
  <>
    <TitleButton variant="warning" onClick={onClearClick} title="Clear" />
    <TitleButton variant="primary" onClick={onCopyClicked} title="Copy" />
    <TitleButton variant="success" onClick={onSaveClicked} title="Save" />
  </>
);

TitleButtons.propTypes = {
  onClearClick: PropTypes.func.isRequired,
  onCopyClicked: PropTypes.func.isRequired,
  onSaveClicked: PropTypes.func.isRequired,
};

export default CompanyCam;
