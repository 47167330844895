/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Parse from 'parse';
import Panel from '../Panel';
import {
  startCloneTemplate,
  startClearTemplates,
  startFetchTemplatesToCopy,
} from '../../actions/templates';
import { history } from '../../router';

export class TemplateLibrary extends React.Component {
  componentDidMount() {
    this.props.startFetchTemplates();
  }

  onCopyClicked = async (template) => {
    const newTemplate = await this.props.startCloneTemplate(template);
    history.push(`/templates/${newTemplate.get('type')}/${newTemplate.id}`);
  };

  onBackClicked = async () => {
    await this.props.startClearTemplates();
    history.goBack();
  };

  render() {
    return (
      // <MainSection
      //   title={
      //     <a onClick={this.onBackClicked}>
      //       Back to {_.capitalize(pluralize(this.props.match.params.type))}
      //     </a>
      //   }
      // >
      <>
        <div className="custom-panel__header-title">
          Finance Options
          {/* 
            This component is not defined! no idea where it's supposed to
            come from
            <TitleButtons onClick={this.onButtonClick} /> 
          */}
        </div>
        <Panel title="Choose a template to copy">
          <div className="resources-container">
            {this.props.templates.map((template) => (
              <div
                key={template.id}
                className="mask no-caption resources-cell card"
              >
                <div
                  className="thumbnail-container"
                  style={{ width: '100%', height: '100%' }}
                >
                  <div className="img-thumbnail view view-first">
                    <img
                      style={{
                        cursor: 'pointer',
                        margin: 'auto',
                        height: '100%',
                        display: 'block',
                      }}
                      src={
                        template.get('iconImage')
                          ? template.get('iconImage').url()
                          : '/images/no_image.png'
                      }
                      alt="Thumbnail"
                    />
                    <div className="mask no-caption">
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                        className="tools tools-bottom"
                      >
                        <button
                          type="button"
                          nClick={() => this.onCopyClicked(template)}
                        >
                          <i className="far fa-copy" />
                        </button>
                        <div style={{ width: 10 }} />
                        {template.has('preview') && (
                          <a
                            href={template.get('preview').url()}
                            target="_blank"
                            id="viewButton"
                            rel="noopener noreferrer"
                          >
                            <i className="fas fa-paperclip" />
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="caption">{template.get('displayName')}</div>
                </div>
              </div>
            ))}
          </div>
        </Panel>
      </>
    );
  }
}

TemplateLibrary.propTypes = {
  templates: PropTypes.arrayOf(PropTypes.instanceOf(Parse.Object)),
  startCloneTemplate: PropTypes.func.isRequired,
  startClearTemplates: PropTypes.func.isRequired,
  startFetchTemplates: PropTypes.func.isRequired,
};

TemplateLibrary.defaultProps = {
  templates: [],
};

const mapStateToProps = ({ templates = {} }, ownProps) => {
  const typeTemplates = templates[ownProps.match.params.type] || {};

  return {
    templates: typeTemplates.templates,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  startCloneTemplate: (object) =>
    dispatch(startCloneTemplate(undefined, object.id, false)),
  startClearTemplates: () =>
    dispatch(startClearTemplates(ownProps.match.params.type)),
  startFetchTemplates: () =>
    dispatch(startFetchTemplatesToCopy(ownProps.match.params.type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TemplateLibrary);
