/* eslint-disable default-case */
import { useState, useEffect } from 'react';
import { from } from 'rxjs';
import {
  getNavigation,
  setLastPath,
  setPartnerTag,
} from '../data/repo/navigation';

const useNavigation = () => {
  /**
   * @type {[
   *  import('../data/schemas/navigationSchema').NavigationDocument?,
   *  React.Dispatch<import('../data/schemas/navigationSchema').NavigationDocument>,
   * ]}
   */
  const [navigation, setNavigation] = useState();
  /** @type {[string | null, React.Dispatch<string>]} */
  const [lastPath, _setLastPath] = useState(null);
  /** @type {[string | null, React.Dispatch<string>]} */
  const [partnerTag, _setPartnerTag] = useState(null);

  useEffect(() => {
    const navObserver = from(getNavigation());
    const sub = navObserver.subscribe((nav) => {
      setNavigation(nav);
    });
    return () => sub.unsubscribe();
  }, []);

  useEffect(() => {
    if (navigation) {
      const sub1 = navigation.get$('lastPath').subscribe((newPath) => {
        _setLastPath(newPath);
      });
      const sub2 = navigation.get$('partnerTag').subscribe((newTag) => {
        _setPartnerTag(newTag);
      });
      return () => {
        sub1.unsubscribe();
        sub2.unsubscribe();
      };
    }
    return () => {};
  }, [navigation]);

  return {
    lastPath,
    partnerTag,
    setPartnerTag,
    setLastPath,
  };
};

export default useNavigation;
