/* eslint-disable react/require-default-props */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { withLayoutContext, layoutContextPropTypes } from '../../Layout';
import Panel from '../../Panel';
import FormGroup, { TextGroup } from '../../FormGroup';
import PriceFormulaTestSuite from './FormulaTestSuite';
import { startUpdateConfig } from '../../../actions/auth';
import { randomKey, formulaEvaluates } from '../../../utils/utils';
import FormulaTipButton from './FormulaTestSuite/FormulaTipButton';

const defaultColors = [
  '#000000',
  '#324001',
  '#A0A603',
  '#D9A404',
  '#AD7D03',
  '#89442B',
  '#1D639F',
  '#D06203',
  '#525252',
  '#256A63',
  '#1EA864',
  '#26D07C',
  '#463093',
  '#023059',
  '#364A73',
  '#9B2A2A',
];

const DEFAULT_PLACEHOLDERS = [
  {
    symbol: '[FP]',
    name: 'Financing Fee',
    color: '#000000',
    isDefault: true,
    id: 'FP',
  },
  {
    symbol: '[UA]',
    name: 'User Amount',
    color: '#324001',
    isDefault: true,
    id: 'UA',
  },
  {
    symbol: '[DP]',
    name: 'Down Payment',
    color: '#A0A603',
    isDefault: true,
    id: 'DP',
  },
  {
    symbol: '[B]',
    name: 'Book Price',
    color: 'orange',
    isDefault: true,
    id: 'B',
  },
  {
    symbol: '[CP]',
    name: 'Custom Product',
    color: 'lightblue',
    isDefault: true,
    id: 'CP',
  },
];

export const addDefaultColors = (objects = []) =>
  objects.map((obj, index) => ({
    ...obj,
    color: defaultColors[index % defaultColors.length],
  }));

class EditPriceFormulas extends React.Component {
  inputRef = React.createRef();

  constructor(props) {
    super(props);
    const { priceFormula } = props;
    const { formula, name } = priceFormula;
    this.state = {
      name,
      formula,
    };
  }

  componentDidMount() {
    const {
      crumbs,
      setCrumbs,
      location: { pathname },
    } = this.props;

    const newCrumbs = [...crumbs];
    const crumb = { title: 'Edit', link: pathname };
    newCrumbs[1] = crumb;
    setCrumbs(newCrumbs);
  }

  componentWillUnmount() {
    const { crumbs, setCrumbs } = this.props;

    const newCrumbs = crumbs.slice(0, 1);
    setCrumbs(newCrumbs);
  }

  selectPlaceholder = (placeholder) => {
    const formulaInputRef = this.inputRef.current;
    let cursor = formulaInputRef.selectionStart;
    const { formula } = this.state;
    const before = formula.substring(0, cursor);
    const after = formula.substring(cursor);
    this.setState(
      {
        formula: `${before}${placeholder.symbol}${after}`,
      },
      () => {
        formulaInputRef.focus();
        cursor += placeholder.symbol.length;
        this.inputRef.current.selectionStart = cursor;
        this.inputRef.current.selectionEnd = cursor;
      },
    );
  };

  updatedFormulas = () => {
    const { priceFormulas, priceFormula } = this.props;
    const { name, formula } = this.state;
    if (!priceFormula.id) {
      priceFormulas.push({
        name,
        formula,
        id: randomKey(10),
        isVisable: false,
        isDefault: false,
        isResultsFormula: false,
        isResultsReport: false,
        isPriceGuide: false,
        isContract: false,
      });
      return [...priceFormulas];
    }
    return priceFormulas.map((pf) => {
      if (pf.id === priceFormula.id) {
        return {
          ...pf,
          name,
          formula,
        };
      }
      return pf;
    });
  };

  clearFormula = () => this.setState({ formula: '', name: '' });

  saveFormula = () => {
    const priceFormulas = this.updatedFormulas();
    const customFormulaIds = this.props.placeholders
      .filter(({ isDefault }) => !isDefault)
      .map(({ id }) => id);
    const evaluates = priceFormulas.every(({ formula }) =>
      formulaEvaluates(formula, customFormulaIds),
    );
    if (evaluates) {
      this.props.startUpdateConfig({ priceFormulas });
    }
  };

  render() {
    return (
      <div className="default-page-padding">
        <h3>
          <Link to="/price_formulas">Back to Price Formulas</Link>
        </h3>
        <Panel title={this.props.title}>
          <TextGroup
            value={this.state.name}
            onChange={(value) => this.setState({ name: value })}
            title="Name"
          />
          <TextGroup
            inputRef={this.inputRef}
            value={this.state.formula}
            onChange={(value) => this.setState({ formula: value })}
            title="Formula"
          />
          <FormGroup title="">
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {this.props.placeholders.map((placeholder) => (
                <FormulaTipButton
                  onClick={() => this.selectPlaceholder(placeholder)}
                  key={placeholder.symbol}
                  info={placeholder.name}
                  symbol={placeholder.symbol}
                  color={placeholder.color}
                />
              ))}
            </div>
          </FormGroup>
          <hr />
          <FormGroup title="">
            <div style={{ display: 'flex' }}>
              <Button
                onClick={() => this.clearFormula()}
                style={{ margin: 5 }}
                variant="info"
              >
                Clear
              </Button>
              <Button
                onClick={() => this.saveFormula()}
                style={{ margin: 5 }}
                variant="success"
              >
                Save
              </Button>
            </div>
          </FormGroup>
        </Panel>
        <PriceFormulaTestSuite
          getFormula={() => this.state.formula}
          placeholders={this.props.placeholders}
        />
      </div>
    );
  }
}

EditPriceFormulas.propTypes = {
  title: PropTypes.string,
  priceFormula: PropTypes.shape({
    id: PropTypes.string,
    formula: PropTypes.string,
    name: PropTypes.string,
    isVisable: PropTypes.bool,
    isDefault: PropTypes.bool,
    isResultsFormula: PropTypes.bool,
    isResultsReport: PropTypes.bool,
    isPriceGuide: PropTypes.bool,
    isContract: PropTypes.bool,
  }),
  priceFormulas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      formula: PropTypes.string,
      name: PropTypes.string,
      isVisable: PropTypes.bool,
      isDefault: PropTypes.bool,
      isResultsFormula: PropTypes.bool,
      isResultsReport: PropTypes.bool,
      isPriceGuide: PropTypes.bool,
      isContract: PropTypes.bool,
    }),
  ),
  startUpdateConfig: PropTypes.func.isRequired,
  placeholders: PropTypes.arrayOf(
    PropTypes.shape({
      symbol: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ),
  ...layoutContextPropTypes,
};

EditPriceFormulas.defaultProps = {
  priceFormula: { formula: '', name: '' },
  title: 'Create Price Formulas',
  priceFormulas: [],
};

const mapStateToProps = (
  { auth: { config } },
  {
    match: {
      params: { id },
    },
    ...ownProps
  },
) => {
  const [found] = [...config.priceFormulas].filter((pf) => pf.id === id);
  const formulaIdCategories = config.categories_.filter(
    ({ formulaId }) => formulaId,
  );
  const placeholders = [
    ...DEFAULT_PLACEHOLDERS,
    ...formulaIdCategories.map(({ name, formulaId }) => ({
      symbol: `[${formulaId}]`,
      name,
      isDefault: false,
      id: formulaId,
    })),
  ];
  return {
    title: id ? 'Edit Price Formula' : ownProps.title,
    priceFormula: found || { formula: '', name: '' },
    priceFormulas: [...config.priceFormulas],
    placeholders: addDefaultColors(placeholders),
  };
};

const mapDispatchToProps = (dispatch) => ({
  startUpdateConfig: (updates) => dispatch(startUpdateConfig(updates)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(EditPriceFormulas),
);
