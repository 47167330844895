import React from 'react';
import PropTypes from 'prop-types';
import { Form, Col } from 'react-bootstrap';
import ValidationForm from './ValidationForm';

const shouldShow = (inputType) => {
  switch (inputType) {
    case 'unitedInchPicker':
    case 'sizePickerInch':
    case 'sizePickerQuarterInch':
    case 'sizePickerEighthInch':
    case 'sizePickerSixteenthInch':
    case '3DSizePickerInch':
    case '3DSizePickerQuarterInch':
    case '3DSizePickerEighthInch':
    case '3DSizePickerSixteenthInch':
      return true;

    default:
      return false;
  }
};

const shouldShowDepth = (inputType) => {
  switch (inputType) {
    case '3DSizePickerInch':
    case '3DSizePickerQuarterInch':
    case '3DSizePickerEighthInch':
    case '3DSizePickerSixteenthInch':
      return true;

    default:
      return false;
  }
};

const MinMaxDimentionWidget = (props) => {
  const { detailObject = {}, onChange, index, errors = {} } = props;

  const {
    inputType,
    minSizePickerWidth,
    maxSizePickerWidth,
    minSizePickerHeight,
    maxSizePickerHeight,
    minSizePickerDepth,
    maxSizePickerDepth,
    unitedInchSuffix = ' UI',
  } = detailObject;

  const show = shouldShow(inputType);

  const showDepth = shouldShowDepth(inputType);

  const errorObject = errors.objects[index] || {};

  return (
    show && (
      <>
        {inputType === 'unitedInchPicker' && (
          <Form.Row className="my-1 mx-0 row w-100">
            <Col className="col-12 col-md-3">
              <ValidationForm
                title="United Inch Suffix"
                errorMessage={errorObject.unitedInchSuffix}
              >
                <Form.Control
                  name="unitedInchSuffix"
                  onChange={(event) => {
                    onChange(event, detailObject, index);
                  }}
                  type="text"
                  value={unitedInchSuffix}
                />
              </ValidationForm>
            </Col>
          </Form.Row>
        )}

        <Form.Row className="my-1 mx-0 row w-100">
          <Col className="col-12 col-md-3">
            <ValidationForm
              title="Min Width"
              errorMessage={errorObject.minSizePickerWidth}
            >
              <Form.Control
                name="minSizePickerWidth"
                onChange={(event) => {
                  onChange(event, detailObject, index);
                }}
                type="number"
                value={minSizePickerWidth}
              />
            </ValidationForm>
          </Col>

          <Col className="col-12 col-md-3">
            <ValidationForm
              title="Max Width"
              errorMessage={errorObject.maxSizePickerWidth}
            >
              <Form.Control
                name="maxSizePickerWidth"
                onChange={(event) => {
                  onChange(event, detailObject, index);
                }}
                type="number"
                value={maxSizePickerWidth}
              />
            </ValidationForm>
          </Col>

          <Col className="col-12 col-md-3">
            <ValidationForm
              title="Min Height"
              errorMessage={errorObject.minSizePickerHeight}
            >
              <Form.Control
                name="minSizePickerHeight"
                onChange={(event) => {
                  onChange(event, detailObject, index);
                }}
                type="number"
                value={minSizePickerHeight}
              />
            </ValidationForm>
          </Col>

          <Col className="col-12 col-md-3">
            <ValidationForm
              title="Max Height"
              errorMessage={errorObject.maxSizePickerHeight}
            >
              <Form.Control
                name="maxSizePickerHeight"
                onChange={(event) => {
                  onChange(event, detailObject, index);
                }}
                type="number"
                value={maxSizePickerHeight}
              />
            </ValidationForm>
          </Col>
        </Form.Row>

        {showDepth && (
          <Form.Row className="my-1 mx-0 row w-100">
            <Col className="col-12 col-md-3">
              <ValidationForm
                title="Min Depth"
                errorMessage={errorObject.minSizePickerDepth}
              >
                <Form.Control
                  name="minSizePickerDepth"
                  onChange={(event) => {
                    onChange(event, detailObject, index);
                  }}
                  type="number"
                  value={minSizePickerDepth}
                />
              </ValidationForm>
            </Col>

            <Col className="col-12 col-md-3">
              <ValidationForm
                title="Max Depth"
                errorMessage={errorObject.maxSizePickerDepth}
              >
                <Form.Control
                  name="maxSizePickerDepth"
                  onChange={(event) => {
                    onChange(event, detailObject, index);
                  }}
                  type="number"
                  value={maxSizePickerDepth}
                />
              </ValidationForm>
            </Col>
          </Form.Row>
        )}
      </>
    )
  );
};

MinMaxDimentionWidget.defaultProps = {
  onChange: () => {},
};
MinMaxDimentionWidget.propTypes = {
  onChange: PropTypes.func,
  detailObject: PropTypes.shape({}).isRequired,
  index: PropTypes.number.isRequired,
  errors: PropTypes.shape({}).isRequired,
};

export default MinMaxDimentionWidget;
