/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import { startUpdateConfig } from '../../actions/auth';
import { randomKey } from '../../utils/utils';
import Panel from '../Panel';
import TitleButton from '../TitleButton';
import { checkForErrors } from './ContractSending/Validation/Validator';
import { showErrorAlert, hideAlert } from '../../actions/alert';
import CustomPlaceholderTable from '../CustomPlaceholderTable';
import Copy from './Copy';

const defaultPlaceholders = [
  {
    placeholder: '%companyLogo%',
    replacement: 'Company Logo',
  },
  {
    placeholder: '%date%',
    replacement: 'The date inside of the application (12/31/16)',
  },
  {
    placeholder: '%stateLicense%',
    replacement: 'State License of Company',
  },
  {
    placeholder: '%usersLicense%',
    replacement: 'Users License Number',
  },
  {
    placeholder: '%usersName%',
    replacement: 'Users First and Last Name',
  },
  {
    placeholder: '%usersPhone%',
    replacement: 'Users Phone Number',
  },
  {
    placeholder: '%userEmail%',
    replacement: 'Users Email Address',
  },
  {
    placeholder: '%customerName%',
    replacement: 'First and Last name of Customer',
  },
  {
    placeholder: '%customerSpouseName%',
    replacement: "First and Last name of Customer's Spouse",
  },
  {
    placeholder: '%customerAddressFull%',
    replacement: 'Street, City, State and Zip Code of Customer',
  },
  {
    placeholder: '%customerAddressLine1%',
    replacement: 'Street Address of Customer',
  },
  {
    placeholder: '%customerAddressLine2%',
    replacement: 'City State and Zip Code of Customer',
  },
  {
    placeholder: '%customerAddressState%',
    replacement: 'State of Customer',
  },
  {
    placeholder: '%customerAddressCity%',
    replacement: 'City of Customer',
  },
  {
    placeholder: '%customerAddressZip%',
    replacement: 'Zip Code of Customer',
  },
  {
    placeholder: '%customerContacts%',
    replacement: 'All Contacts of Customer (Phone and Emails)',
  },
  {
    placeholder: '%customerEmail%',
    replacement: 'All Emails of Customer',
  },
  {
    placeholder: '%customerPhone%',
    replacement: 'All Phone numbers of Customer',
  },
];

const validationErrors = ({ placeholders }) => ({
  placeholders: placeholders.map(({ placeholder }) => {
    let message = placeholder ? '' : 'Required';
    for (let i = 0; i < defaultPlaceholders.length; i += 1) {
      if (placeholder === defaultPlaceholders[i].placeholder) {
        message = `Default placeholders are not allowed (${defaultPlaceholders[i].placeholder})`;
        break;
      }
    }
    return {
      placeholder: message,
      replacement: '', // No validation needed for replacement
    };
  }),
});

export class Placeholders extends React.Component {
  constructor(props) {
    super(props);
    const newState = {
      placeholders: props.placeholders || [],
    };
    newState.errors = validationErrors(newState);
    this.state = newState;
  }

  componentDidMount() {
    const {
      setCrumbs,
      setButtons,
      location: { pathname },
    } = this.props;

    const crumb = { title: 'Placeholders', link: pathname };
    setCrumbs([crumb]);
    setButtons(
      <TitleButtons
        onCopyClicked={this.onCopyClicked}
        onSaveClicked={this.onSaveClicked}
      />,
    );
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const newState = {
      placeholders: newProps.placeholders,
    };
    newState.errors = validationErrors(newState);
    this.setState(newState);
  }

  componentWillUnmount() {
    const { setCrumbs, setButtons } = this.props;

    setCrumbs([]);
    setButtons();
  }

  onSaveClicked = () => {
    if (checkForErrors(this.state.errors)) {
      this.props.showErrorAlert({
        title: 'Error',
        message: 'Please fix all validation errors',
        onConfirm: this.props.hideAlert,
      });
      return;
    }
    this.props.startUpdateConfig({
      placeholders: this.state.placeholders,
    });
  };

  onAddClicked = () => {
    const placeholders = [...this.state.placeholders];
    placeholders.unshift({
      placeholder: '',
      replacement: '',
      id: randomKey(10),
    });
    const newState = {
      ...this.state,
      placeholders,
    };
    newState.errors = validationErrors(newState);
    this.setState(newState);
  };

  onTextChange = ({ value, source, id }) => {
    const placeholders = this.state.placeholders.map((placeholder) => {
      const updatedPlaceholder = { ...placeholder };
      if (id === placeholder.id) {
        updatedPlaceholder[source] = value;
      }
      return updatedPlaceholder;
    });
    const newState = {
      ...this.state,
      placeholders,
    };
    newState.errors = validationErrors(newState);
    this.setState(newState);
  };

  onCopyClicked = () => {
    this.setState({ showCopy: true });
  };

  onDelete = (id) => {
    const placeholders = this.state.placeholders.filter((old) => old.id !== id);
    const newState = {
      ...this.state,
      placeholders,
    };
    newState.errors = validationErrors(newState);
    this.setState(newState);
  };

  render() {
    return (
      <>
        <Copy
          title="Copy Placeholders"
          show={this.state.showCopy}
          warning="Warning! This will overwrite your current Placeholders"
          configKeys={['placeholders']}
          onClose={() => this.setState({ showCopy: false })}
        />
        <div className="default-page-padding">
          <Panel title="Default">
            <div>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th style={{ width: '50%' }}>Placeholder</th>
                    <th style={{ width: '50%' }}>Replacement</th>
                  </tr>
                </thead>
                <tbody>
                  {defaultPlaceholders.map(({ placeholder, replacement }) => (
                    <tr key={placeholder}>
                      <td style={{ width: '50%' }}>{placeholder}</td>
                      <td style={{ width: '50%' }}>{replacement}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Panel>
          <Panel title="Custom">
            <div>
              <CustomPlaceholderTable
                onAddClicked={this.onAddClicked}
                onValueChanged={this.onTextChange}
                onDelete={this.onDelete}
                placeholders={this.state.placeholders}
                errors={this.state.errors.placeholders}
              />
            </div>
          </Panel>
        </div>
      </>
    );
  }
}

Placeholders.propTypes = {
  placeholders: PropTypes.arrayOf(
    PropTypes.shape({
      placeholder: PropTypes.string,
      replacement: PropTypes.string,
      id: PropTypes.string.isRequired,
    }),
  ),
  startUpdateConfig: PropTypes.func.isRequired,
  showErrorAlert: PropTypes.func.isRequired,
  hideAlert: PropTypes.func.isRequired,
  ...layoutContextPropTypes,
};

Placeholders.defaultProps = {
  placeholders: [],
};

const TitleButtons = ({ onCopyClicked, onSaveClicked }) => (
  <>
    <TitleButton
      variant="primary"
      onClick={() => onCopyClicked()}
      title="Copy"
    />
    <TitleButton
      variant="success"
      onClick={() => onSaveClicked()}
      title="Save"
    />
  </>
);

TitleButtons.propTypes = {
  onCopyClicked: PropTypes.func.isRequired,
  onSaveClicked: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth: { config } }) => ({
  placeholders: config.placeholders.map((placeholder) => {
    if (placeholder.id) {
      return placeholder;
    }
    return {
      ...placeholder,
      id: randomKey(10),
    };
  }),
});

const mapDispatchToProps = (dispatch) => ({
  startUpdateConfig: (updates) => dispatch(startUpdateConfig(updates)),
  showErrorAlert: (params) => dispatch(showErrorAlert(params)),
  hideAlert: () => dispatch(hideAlert()),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(Placeholders),
);
