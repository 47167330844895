/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Parse from 'parse';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Spinner } from '@blueprintjs/core';
import {
  getPaymentRequestDataFromToken,
  processPaymentRequest,
} from '../actions/estimatesPage';
import AppToaster from '../utils/AppToaster';
import { currencyFromNumber } from '../utils/utils';
import { hideLoader } from '../actions/loading';
import CardPaymentForm from './CardPaymentForm';
import BankAccountForm from './BankAccountForm';

class SubmitPaymentRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logo: {},
      spreedly: {
        name: '',
        month: '',
        year: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
      },
      showThanks: false,
      paymentType: '',
      errors: [],
      showForm: false,
      isSubmitting: false,
      apiKey: '',
      redirect_url: '',
    };
  }

  componentDidMount() {
    this.initPage();
  }

  componentWillUnmount() {
    Spreedly.removeHandlers();
  }

  onSubmitBankAccount = async (e) => {
    e.preventDefault();
    try {
      const {
        environment_key,
        utf8,
        fullName,
        bank_routing_number,
        bank_account_number,
        bank_account_type,
        bank_account_holder_type,
      } = e.target.elements;
      const result = await fetch(
        'https://core.spreedly.com/v1/payment_methods',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            environment_key: environment_key.value,
            utf8: utf8.value,
            payment_method: {
              bank_account: {
                full_name: fullName.value,
                bank_routing_number: bank_routing_number.value,
                bank_account_number: bank_account_number.value,
                bank_account_type: bank_account_type.value,
                bank_account_holder_type: bank_account_holder_type.value,
              },
            },
          }),
        },
      );
      const { transaction } = await result.json();
      const { token } = transaction.payment_method;
      const paymentParams = {
        transactionAmount: this.state.paymentAmount,
        paymentToken: token,
        paymentRequestToken: this.props.match.params.tokenId,
      };
      const response = await this.props.processPaymentRequest(paymentParams);
      if (!response.redirectURL) {
        this.setState({ showThanks: true });
      } else {
        window.location.href = response.redirectURL;
      }
    } catch (err) {
      this.setState(() => ({
        show: true,
        error: err.message,
        hideSpinner: true,
      }));
    }
  };

  onSubmitCreditCard = (e) => {
    e.preventDefault();
    const {
      exprMonth,
      exprYear,
      nameOnCard,
      billingAddress1,
      billingAddress2,
      billingCity,
      billingState,
      billingZip,
    } = e.target.elements;
    Spreedly.tokenizeCreditCard({
      full_name: nameOnCard.value,
      month: exprMonth.value,
      year: `20${exprYear.value}`,
      address1: billingAddress1.value,
      address2: billingAddress2.value,
      city: billingCity.value,
      state: billingState.value,
      zip: billingZip.value,
    });
  };

  onToken = async (paymentToken) => {
    const { match = {} } = this.props;
    const { params = {} } = match;
    const { tokenId } = params;
    const { paymentAmount, spreedly } = this.state;
    const {
      name,
      month,
      year,
      address1,
      address2,
      city,
      state,
      zip,
    } = spreedly;
    const paymentParams = {
      name,
      month,
      year,
      address1,
      address2,
      city,
      state,
      zip,
      paymentToken,
      transactionAmount: paymentAmount,
      paymentRequestToken: tokenId,
    };
    try {
      const response = await this.props.processPaymentRequest(paymentParams);
      if (!response.redirectURL) {
        this.setState({ showThanks: true });
      } else {
        window.location.href = response.redirectURL;
      }
    } catch (error) {
      this.setState({ show: true, error: error.message });
      this.props.hideLoader();
    }
  };

  onSpreedlyChange = (key, value) => {
    if ((key === 'month' || key === 'year') && !/^[0-9]{0,2}$/.test(value)) {
      return;
    }
    const spreedly = {};
    spreedly[key] = value;
    this.setState((prev) => ({
      spreedly: { ...prev.spreedly, ...spreedly },
    }));
  };

  initSpreedly = async (providedKey) => {
    try {
      let apiKey = providedKey;
      if (!providedKey) {
        apiKey = await Parse.Cloud.run('spreedlyAPIKey');
      }
      Spreedly.init(apiKey, {
        numberEl: 'spreedly-number',
        cvvEl: 'spreedly-cvv',
      });
      Spreedly.on('ready', () => {
        Spreedly.setStyle(
          'number',
          ' padding-left: .67rem; 5px; width: 96%; height: 27px; font-size: .84rem; margin-top: 0; margin-right:2rem;',
        );
        Spreedly.setStyle(
          'cvv',
          'padding-left: .67rem; width: 96%; height: 27px; font-size: .84rem; margin-top: 0;',
        );
        Spreedly.setFieldType('number', 'text');
        Spreedly.setNumberFormat('prettyFormat');
        Spreedly.setPlaceholder('number', 'XXXX XXXX XXXX XXXX');
        Spreedly.setPlaceholder('cvv', 'XXX(X)');
        this.setState({ showCardForm: true });
      });
      Spreedly.on('paymentMethod', async (token) => {
        try {
          this.setState(() => ({ isSubmitting: true, errors: [] }));
          this.onToken(token);
        } catch (e) {
          // handle payment method change failure
          this.setState((prev) => ({
            errors: prev.errors.concat(e),
            isSubmitting: false,
          }));
        }
      });
      Spreedly.on('errors', (errors) => {
        this.setState(() => ({ errors }));
      });
    } catch (e) {
      AppToaster.show({ message: e.message, timeout: 5000 });
    }
  };

  initForSinglePageType = async (requestData) => {
    const { allowedPaymentTypes = [] } = requestData;
    const [paymentType] = allowedPaymentTypes;
    const showForm = paymentType === 'Bank/eCheck';
    let apiKey;
    if (!showForm) {
      await this.initSpreedly();
    } else {
      apiKey = await Parse.Cloud.run('spreedlyAPIKey');
    }
    this.setState({
      ...requestData,
      paymentType,
      showForm,
      apiKey,
    });
  };

  initPaymentForms = async (requestData) => {
    const { allowedPaymentTypes = [] } = requestData;
    if (allowedPaymentTypes.length === 1) {
      await this.initForSinglePageType(requestData);
    } else if (allowedPaymentTypes.length > 1) {
      const apiKey = await Parse.Cloud.run('spreedlyAPIKey');
      await this.initSpreedly(apiKey);
      this.setState({
        ...requestData,
        showPaymentOptions: true,
        paymentType: allowedPaymentTypes[0],
        apiKey,
      });
    }
  };

  initPage = async () => {
    const { match = {} } = this.props;
    const { params = {} } = match;
    const { tokenId } = params;
    try {
      if (tokenId) {
        const requestData = await this.props.getPaymentRequestDataFromToken(
          tokenId,
        );
        if (requestData) {
          await this.initPaymentForms(requestData);
        }
      }
    } catch (error) {
      this.setState({ show: true, error: error.message, hideSpinner: true });
    }
  };

  render() {
    const { requestType } = this.state;
    let requestLabel;
    let successMessage;
    let creditCardLabel;
    let bankAccountLabel;
    let submitMessage;
    const { paymentAmount = 0 } = this.state;
    const amount = currencyFromNumber(paymentAmount / 100);
    if (requestType === 'captureOnly') {
      requestLabel = 'Payment Capture Requested By:';
      successMessage = 'Payment Captured!';
      creditCardLabel = 'Use Credit Card';
      bankAccountLabel = 'Use Bank Account';
      submitMessage = 'Your payment method will not be charged at this time';
    } else if (requestType === 'captureAndCharge') {
      requestLabel = 'Payment Requested By:';
      successMessage = 'Payment Submitted!';
      creditCardLabel = 'Pay With Credit Card';
      bankAccountLabel = 'Pay With Bank Account';
      submitMessage = `Your payment method will be charged ${amount}`;
    }
    return (
      <div id="sumbit-payment-page" className="sumbit-payment-page-outer">
        <div className="sumbit-payment-page container d-flex flex-column">
          <div className="row">
            <div className="col-12">
              {this.state.show && (
                <SweetAlert
                  danger
                  title="Error"
                  onConfirm={() => {
                    this.setState({ show: false, error: undefined });
                  }}
                >
                  {this.state.error}
                </SweetAlert>
              )}
            </div>
          </div>
          <header className="row">
            <div className="col-12 col-md-11 offset-md-1 col-lg-10 offset-lg-2">
              <img
                src={this.state.logo._url}
                className="sending-company-logo"
                alt="company logo"
              />
            </div>
            <div className="col-lg-5 offset-lg-2 col-md-5 offset-md-1 d-flex align-items-center">
              <div className="row">
                <div className="col-12">
                  <p className="submit-payment-requested-by-label mb-2">
                    {requestLabel}
                  </p>
                  <h3 className="submit-payment-requested-by-name mt-0 mb-2">
                    {this.state.name}
                  </h3>

                  <p className="submit-payment-requested-by-message">
                    {this.state.paymentRequestMessage}
                  </p>
                </div>
              </div>
            </div>
            {requestType === 'captureAndCharge' && (
              <div className="col-lg-5 col-md-5">
                <p className="amount-text-label">
                  {this.state.showThanks
                    ? 'Amount Submitted:'
                    : 'Amount Requested:'}
                </p>
                <p className="amount-text-value">{amount}</p>
              </div>
            )}
          </header>
          <div
            className="row"
            style={{ display: this.state.showThanks ? undefined : 'none' }}
          >
            <div className="col-12">
              <div className="thank-you-pane">
                <p className="text-center submited-text">{successMessage}</p>
                <p className="text-center">THANK YOU</p>
              </div>
            </div>
          </div>

          <div
            className="row"
            style={{ display: this.state.showThanks ? 'none' : undefined }}
          >
            <div className="col-12">
              <div
                className="justify-content-center submit-payment-form-selector-box"
                style={{
                  display: this.state.showPaymentOptions ? 'flex' : 'none',
                }}
              >
                <button
                  type="button"
                  className={`m-3 btn ${
                    this.state.paymentType === 'Credit Card'
                      ? 'btn-success'
                      : 'btn-light'
                  }`}
                  onClick={() => {
                    this.setState({ paymentType: 'Credit Card' });
                  }}
                >
                  {creditCardLabel}
                </button>
                <button
                  type="button"
                  className={`m-3 btn ${
                    this.state.paymentType === 'Bank/eCheck'
                      ? 'btn-success'
                      : 'btn-light'
                  }`}
                  onClick={() => {
                    this.setState({
                      paymentType: 'Bank/eCheck',
                      showForm: true,
                    });
                  }}
                >
                  {bankAccountLabel}
                </button>
              </div>

              {!(this.state.showForm || this.state.showCardForm) &&
                !this.state.hideSpinner && (
                  <div className="submit-spinner">
                    <Spinner size={50} />
                  </div>
                )}

              <div className="row">
                <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                  <BankAccountForm
                    showForm={this.state.showForm}
                    isSubmitting={this.state.isSubmitting}
                    paymentType={this.state.paymentType}
                    redirect_url={this.state.redirect_url}
                    apiKey={this.state.apiKey}
                    onSubmitBankAccount={this.onSubmitBankAccount}
                    submitMessage={submitMessage}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                  <CardPaymentForm
                    isSubmitting={this.state.isSubmitting}
                    showCardForm={this.state.showCardForm}
                    paymentType={this.state.paymentType}
                    onSubmitCreditCard={this.onSubmitCreditCard}
                    onSpreedlyChange={this.onSpreedlyChange}
                    errors={this.state.errors}
                    submitMessage={submitMessage}
                  />
                </div>
              </div>
            </div>
          </div>
          <footer className="row">
            <div className="leap-logo-container mt-4">
              Powered by&nbsp;&nbsp;
              <img
                className="h-100"
                src="/images/leap_logo.png"
                alt="leap logo"
              />
              &nbsp;&nbsp;Secure Payment Capture
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getPaymentRequestDataFromToken: (tokenId) =>
    dispatch(getPaymentRequestDataFromToken(tokenId)),
  processPaymentRequest: (params) => dispatch(processPaymentRequest(params)),
  hideLoader: () => dispatch(hideLoader()),
});

export default connect(undefined, mapDispatchToProps)(SubmitPaymentRequest);
