/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setActiveColumns } from '../../../actions/priceGuide2';

const Toggle = ({
  activeColumns,
  source,
  setActiveColumns: setActive,
  title,
}) => (
  <div
    onClick={(e) => {
      const object = { ...activeColumns };
      object[source] = !activeColumns[source];
      setActive(object);
    }}
    style={{ alignItems: 'center', display: 'flex', padding: '0 5px' }}
  >
    <input
      type="checkbox"
      checked={!activeColumns[source]}
      onChange={(e) => {
        const object = { ...activeColumns };
        object[source] = !e.target.checked;
        setActive(object);
      }}
    />
    <div style={{ padding: '0 5px' }}>{title}</div>
  </div>
);

Toggle.propTypes = {
  activeColumns: PropTypes.shape({
    settings: PropTypes.bool.isRequired,
    additionalDetails: PropTypes.bool.isRequired,
    placeholders: PropTypes.bool.isRequired,
    showToggle: PropTypes.bool.isRequired,
    image: PropTypes.bool.isRequired,
    category: PropTypes.bool.isRequired,
    subCategory: PropTypes.bool.isRequired,
    subSubCategories: PropTypes.bool.isRequired,
    name: PropTypes.bool.isRequired,
    note: PropTypes.bool.isRequired,
    measurementType: PropTypes.bool.isRequired,
    offices: PropTypes.bool.isRequired,
    formulaId: PropTypes.bool.isRequired,
    formula: PropTypes.bool.isRequired,
    optionPlaceholders: PropTypes.bool.isRequired,
    optionBrand: PropTypes.bool.isRequired,
    optionName: PropTypes.bool.isRequired,
    optionPrices: PropTypes.bool.isRequired,
    upChargeSettings: PropTypes.bool.isRequired,
    upChargeAdditionalDetails: PropTypes.bool.isRequired,
    upChargePlaceholders: PropTypes.bool.isRequired,
    upChargeImage: PropTypes.bool.isRequired,
    upChargeName: PropTypes.bool.isRequired,
    upChargeNote: PropTypes.bool.isRequired,
    upChargeMeasurement: PropTypes.bool.isRequired,
    upChargeShowToggle: PropTypes.bool.isRequired,
    upChargePercentagePrice: PropTypes.bool.isRequired,
    upChargeDisabled: PropTypes.bool.isRequired,
    upChargeIdentifier: PropTypes.bool.isRequired,
    upChargePrices: PropTypes.bool.isRequired,
  }).isRequired,
  source: PropTypes.string.isRequired,
  setActiveColumns: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

const mapStateToProps = ({ auth }) => {
  const { user } = auth;
  const activeColumns = user.get('activeColumns') || {};

  return {
    activeColumns,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setActiveColumns: (activeColumns) =>
    dispatch(setActiveColumns(activeColumns)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Toggle);
