/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import UserContext from '../components/UserContext';
import { useIsAuthorized, useNavigation } from '../hooks';
import Unauthorized from '../components/Unauthorized';

const AuthorizedRoute = ({ component: Component, path, exact = false }) => {
  const { setLastPath } = useNavigation();
  const { pathname } = useLocation();
  const isAuthorized = useIsAuthorized();
  const { loggedIn } = useContext(UserContext);
  // Boolean value, true if user data has been hydrated into redux
  const userDataLoaded = useSelector(
    ({ auth: { user } }) => user !== undefined,
  );

  // When the user is logged in, track navigation
  useEffect(() => {
    if (loggedIn) {
      // DB transaction tha gets the first (and only) navigation item, updates
      // updates lastPath with the router's current path, then applies the change
      setLastPath(pathname);
    }
    // Only run when loggedIn or pathname changes
  }, [pathname, loggedIn, setLastPath]);

  if (loggedIn) {
    return (
      <Route
        exact={exact}
        path={path}
        component={
          userDataLoaded
            ? isAuthorized
              ? Component
              : Unauthorized
            : // Blank component to show while user data is being loaded in store
              () => <></>
        }
      />
    );
  }
  return <Redirect to="/login" />;
};

// ]).isRequired,
AuthorizedRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.object,
    PropTypes.func,
  ]),
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  exact: PropTypes.bool,
};

AuthorizedRoute.defaultProps = {
  path: '',
  exact: false,
  component: () => null,
};

export default AuthorizedRoute;
