import React from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem, TextArea } from 'leap-menu-item';
import YesNoMenuItem from '../YesNoMenuItem';
import ContractObject from '../../Models/ContractObject';
import Conditional from '../../../SharedComponents/Conditional';
import DebounceInputWithNet from '../DebounceInputWithNet';

const InitialsRequired = (props) => {
  const { object, contractObject } = props;

  return (
    <Conditional condition={object.toolboxKey !== 'body-detail-cell'}>
      <Menu.Divider />
      <YesNoMenuItem
        text="Initials Required"
        active={object.initialsRequired}
        onChange={(value) => {
          object.initialsRequired = value;
          props.setTemplateState(contractObject);
        }}
      />
      <MenuItem text="Initials Note" disabled={!object.initialsRequired}>
        <DebounceInputWithNet
          element={TextArea}
          minLength={0}
          debounceTimeout={300}
          value={object.initialsNote}
          onChange={(e) => {
            object.initialsNote = e.target.value;
            props.setTemplateState(contractObject);
          }}
        />
      </MenuItem>
    </Conditional>
  );
};

InitialsRequired.propTypes = {
  object: PropTypes.shape({
    toolboxKey: PropTypes.string.isRequired,
    initialsNote: PropTypes.string.isRequired,
    initialsRequired: PropTypes.bool.isRequired,
  }).isRequired,
  contractObject: PropTypes.instanceOf(ContractObject).isRequired,
  setTemplateState: PropTypes.func.isRequired,
};

export default InitialsRequired;
