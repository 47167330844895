import { randomKey } from '../../../utils/utils';
import SignatureItem from './SignatureItem';

const containerKey = 'signature_section';
const pasteKey = 'index_section';

class SignatureSection {
  constructor(section, parent) {
    this.JSON = section;
    this.keys = ['objectId', 'data', 'groupType'];
    this.containerKey = containerKey;
    this.pasteKey = pasteKey;
    this.parent = parent;
    this.toolboxKey = '';
    if (section) {
      Object.assign(this, section);
      this.objectId = this.objectId || randomKey(10);
      this.data = section.data.map((data) => new SignatureItem(data, this));
    } else {
      this.groupType = 'signature';
      const firstSignature = {
        column: 0,
        contractNote: '%customerName%',
        appNote: '',
        additionalLines: [
          {
            noteFontSize: 8,
            note: 'Date',
            noteFontBold: false,
            valueFontBold: true,
            value: '%date%',
            valueFontSize: 12,
            underline: true,
          },
        ],
        contractNoteBold: true,
        footerNote: '%customerName%',
        underline: true,
        contractNoteFontSize: 8,
      };
      const secondSignature = {
        column: 1,
        contractNote: '%customerSpouseName%',
        appNote: '',
        additionalLines: [
          {
            noteFontSize: 8,
            note: 'Date',
            noteFontBold: false,
            valueFontBold: true,
            value: '%date%',
            valueFontSize: 12,
            underline: true,
          },
        ],
        contractNoteBold: true,
        footerNote: '%customerSpouseName%',
        underline: true,
        contractNoteFontSize: 8,
      };
      this.data = [
        new SignatureItem(firstSignature, this),
        new SignatureItem(secondSignature, this),
      ];
    }
    if (!this.objectId) {
      this.objectId = randomKey(10);
    }
    const source = this.getSourceObject();
    if (source) {
      if (source.hasObjectId(this.objectId)) {
        this.objectId = randomKey(10);
      }
      source.addObjectId(this.objectId);
    }
  }

  get identifier() {
    return `${this.parent.identifier}-${this.objectId}`;
  }

  getPath() {
    if (!this.parent) {
      return undefined;
    }
    const index = this.parent.contractData.findIndex(
      ({ objectId }) => objectId === this.objectId,
    );
    return [index];
  }

  getSourceObject() {
    if (!this.parent) {
      return undefined;
    }
    return this.parent.getSourceObject();
  }

  clone() {
    const jsonObject = this.toJSON();
    const json = JSON.parse(JSON.stringify(jsonObject));
    json.objectId = randomKey(10);
    const section = new SignatureSection(json, this.parent);
    section.data = section.data.map((data) => data.clone());
    return section;
  }

  toJSON() {
    const json = {};
    this.keys.forEach((key) => {
      json[key] = this[key];
    });
    json.data = this.data.map((obj) => obj.toJSON());
    return json;
  }

  convertNewFiles() {
    return this;
  }

  static ContainerKey = containerKey;

  static PasteKey = pasteKey;
}

export default SignatureSection;
