import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

export default function DocumentSnapshotList(props) {
  const { documentSnapshots, onVersionSelection } = props;
  return (
    <div className="list-section m-3">
      {documentSnapshots.map((documentSnapshot) => {
        const id = `${documentSnapshot.id}`;
        return (
          <article
            key={id}
            className="email-estimate-list-item d-md-flex align-items-center list-line"
          >
            <div className="flex-1">
              <h1 className="email-estimate-list-item-title flex-1 d-flex m-0">
                <span className="list-line-header">
                  {documentSnapshot.displayName}
                </span>
              </h1>
              <span className="user-line-symbol">USER:</span>
              <span className="user-line-name">
                {`${documentSnapshot.user.firstName} ${documentSnapshot.user.lastName}`}
              </span>
              <span className="user-line-date">
                {`${moment(documentSnapshot.createdAt).format(
                  'MM/DD/YYYY h:mm a',
                )}`}
              </span>
            </div>
            <div className="email-estimate-list-item-statusbox d-flex justify-content-end">
              <button
                type="button"
                onClick={() => {
                  onVersionSelection(documentSnapshot);
                }}
                className="btn btn-primary"
              >
                Restore Version
              </button>
            </div>
          </article>
        );
      })}
    </div>
  );
}

DocumentSnapshotList.propTypes = {
  documentSnapshots: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onVersionSelection: PropTypes.func.isRequired,
};
DocumentSnapshotList.defaultProps = {};
