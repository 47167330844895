/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import CurrencyInput from 'react-currency-input';
import Select from '../Misc/Select';
import FormGroup, { TextGroup } from '../FormGroup';

class RefundModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transactionType: 'refund',
      amount: 0,
      description: '',
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { transaction = {} } = newProps;
    const { amount = '' } = transaction;
    this.setState({ amount: amount / 100 });
  }

  onAmountChanged = (event, maskedvalue, floatvalue) => {
    this.setState({ amount: floatvalue });
  };

  onSubmit = () => {
    const params = {
      ...this.state,
      amount: this.state.amount ? this.state.amount * 100 : undefined,
      transaction: this.props.transaction,
    };
    this.props.onClose();
    this.props.onSubmit(params);
  };

  render() {
    const { show, onClose, transaction = {} } = this.props;
    const { accountNumber } = transaction;
    return (
      <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>New Transaction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup title="Account">
            <p style={{ marginTop: 'auto', marginBottom: 'auto' }}>
              {accountNumber}
            </p>
          </FormGroup>
          <FormGroup title="Transaction Type">
            <Select
              value={this.state.transactionType}
              options={[
                { value: 'void', label: 'Void' },
                { value: 'refund', label: 'Refund' },
              ]}
              isClearable={false}
              onChange={({ value }) =>
                this.setState({ transactionType: value })
              }
            />
          </FormGroup>
          {this.state.transactionType === 'refund' && (
            <FormGroup title="Amount">
              <CurrencyInput
                value={this.state.amount}
                onChangeEvent={this.onAmountChanged}
                prefix="$"
              />
            </FormGroup>
          )}
          <TextGroup
            title="Description"
            value={this.state.description}
            onChange={(description) => this.setState({ description })}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={this.props.loading.show}
            onClick={this.onSubmit}
            variant="danger"
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

RefundModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.shape({
    show: PropTypes.bool.isRequired,
  }).isRequired,
  transaction: PropTypes.shape({
    amount: PropTypes.number,
    objectId: PropTypes.string.isRequired,
    paymentMethod: PropTypes.shape({
      objectId: PropTypes.string.isRequired,
      zipCode: PropTypes.string,
      number: PropTypes.string,
    }),
  }),
};

RefundModal.defaultProps = {
  show: false,
  transaction: undefined,
};

const mapStateToProps = ({ loading = {} }) => ({
  loading,
});

export default connect(mapStateToProps)(RefundModal);
