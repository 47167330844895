/* eslint-disable react/no-access-state-in-setstate */
/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { WithContext as ReactTags } from 'react-tag-input';
import { jsx, css } from '@emotion/core';
import { createStyleElement } from '../utils/utils';

const KeyCodes = {
  tab: 9,
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.tab];

class TagInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: props.tags.map((tag) => ({ id: tag, text: tag })),
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const tags = newProps.tags.map((tag) => ({ id: tag, text: tag }));
    this.setState({ tags });
  }

  handleDelete(i) {
    const tags = this.state.tags.filter((tag, index) => index !== i);
    this.setState({
      tags,
    });
    this.props.onChange(tags.map((tag) => tag.text));
  }

  handleAddition(tag) {
    const tags = [...this.state.tags, tag];
    this.setState({ tags });
    this.props.onChange(tags.map((t) => t.text));
  }

  handleDrag(tag, currPos, newPos) {
    const tags = [...this.state.tags];
    const newTags = tags.slice();
    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);
    this.setState({ tags: newTags });
    this.props.onChange(newTags.map((t) => t.text));
  }

  handleInputBlur = (tag) => {
    if (tag) {
      if (this.props.inputProps.allowUnique) {
        const containsObject = !!this.state.tags.find(({ id }) => id === tag);
        if (containsObject) {
          return;
        }
      }
      this.handleAddition({ id: tag, text: tag });
    }
  };

  render() {
    return (
      <div
        css={css`
          ${createStyleElement(this.props.style)}
        `}
      >
        <ReactTags
          tags={this.state.tags}
          handleDelete={this.handleDelete}
          handleAddition={this.handleAddition}
          handleDrag={this.handleDrag}
          handleInputBlur={this.handleInputBlur}
          delimiters={delimiters}
          placeholder={this.props.placeholder}
          {...this.props.inputProps}
        />
      </div>
    );
  }
}

TagInput.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

TagInput.defaultProps = {
  tags: [],
  placeholder: '',
  style: {},
};

const mapStateToProps = ({ config: { styles = {} } }) => ({
  style: styles.TagInput,
});

export default connect(mapStateToProps)(TagInput);
