/* eslint-disable import/no-cycle */
import Parse from 'parse';
import { handleError } from './auth';
import { showSuccessAlert, hideAlert } from './alert';
import { showLoader, hideLoader } from './loading';

export const setCustomerCount = (count) => ({
  type: 'SET_CUSTOMER_COUNT',
  count,
});

export const setSelectedOfficeId = (selectedOfficeId) => ({
  type: 'SET_CUSTOMER_SELECTED_OFFICE_ID',
  selectedOfficeId,
});

export const setDateFilter = (value) => ({
  type: 'SET_CUSTOMER_DATE_FILTER',
  value,
});

export const setDateField = (value) => ({
  type: 'SET_CUSTOMER_DATE_FIELD',
  value,
});

export const setSelectedUser = (value) => ({
  type: 'SET_CUSTOMER_SELECTED_USER',
  value,
});

export const setLimit = (value) => ({
  type: 'SET_CUSTOMER_LIMIT',
  value,
});

export const setPage = (value) => ({
  type: 'SET_CUSTOMER_PAGE',
  value,
});

export const customerQuery = (
  selectedOfficeId,
  startDate,
  endDate,
  dateField,
  selectedUserId = null,
) => {
  const query = new Parse.Query('SSCustomer');
  query.notEqualTo('isSpouse', true);
  query.greaterThanOrEqualTo(dateField, startDate.startOf('day').toDate());
  query.lessThanOrEqualTo(dateField, endDate.endOf('day').toDate());
  query.descending(dateField);
  if (selectedOfficeId && selectedOfficeId !== 'all') {
    const office = new Parse.Object('Office');
    office.id = selectedOfficeId;
    const estimateQuery = new Parse.Query('SSEstimate');
    estimateQuery.equalTo('office', office);
    query.matchesQuery('estimate', estimateQuery);
  }
  if (selectedUserId) {
    const userQuery = new Parse.Query(Parse.User);
    userQuery.equalTo('objectId', selectedUserId);
    query.matchesQuery('createdBy', userQuery);
  }
  return query;
};

export const startCountCustomers = (
  selectedOfficeId,
  startDate,
  endDate,
  dateField,
  selectedUserId = null,
) => async (dispatch) => {
  try {
    const query = customerQuery(
      selectedOfficeId,
      startDate,
      endDate,
      dateField,
      selectedUserId,
    );
    const count = await query.count();
    dispatch(setCustomerCount(count));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const setCustomers = (objects) => ({
  type: 'SET_CUSTOMERS',
  objects,
});

export const startFetchCustomers = (
  limit,
  skip,
  selectedOfficeId,
  startDate,
  endDate,
  dateField,
  selectedUserId = null,
  showLoaderScreen = true,
) => async (dispatch) => {
  try {
    dispatch(setCustomers(null));

    if (showLoaderScreen) {
      dispatch(showLoader('Loading customers...'));
    }

    const query = customerQuery(
      selectedOfficeId,
      startDate,
      endDate,
      dateField,
      selectedUserId,
    );
    query.select([
      'nameFirst',
      'nameLast',
      'apiSourceData',
      'estimate.contractPDF',
      'estimate.proposalPDF',
      'estimate.resultPDF',
      'estimate.creditAppPDF',
      'estimate.reportPDF',
      'estimate.documents',
      'createdBy.nameFirst',
      'createdBy.nameLast',
    ]);
    query.limit(limit);
    query.skip(skip);
    const items = await query.find();
    dispatch(setCustomers(items.map((item) => item.toJSON())));
    dispatch(hideLoader());
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const resetCustomers = () => ({
  type: 'RESET_CUSTOMERS',
});

export const setResendConfirm = (
  showResendConfirm,
  onResendConfirm,
  onResendClose,
  createdBy,
) => ({
  type: 'SET_RESEND_CONFIRM',
  showResendConfirm,
  onResendConfirm,
  onResendClose,
  createdBy,
});

export const startResendAttachment = (source, customerId, createdBy) => (
  dispatch,
) => {
  dispatch(
    setResendConfirm(
      true,
      async () => {
        try {
          dispatch(setResendConfirm(false));
          await Parse.Cloud.run('resendAttachment', {
            customerId,
            source,
          });
          dispatch(
            showSuccessAlert({
              title: 'Success!',
              message: 'Document Resent!',
              onConfirm: () => dispatch(hideAlert()),
            }),
          );
        } catch (e) {
          dispatch(handleError(e));
        }
      },
      () => {
        dispatch(setResendConfirm(false));
      },
      createdBy,
    ),
  );
};

export const setUsersForDropDown = (usersForDropDown) => ({
  type: 'SET_USERS_FOR_DROP_DOWN',
  usersForDropDown,
});

export const fetchUsersForDropDown = () => async (dispatch) => {
  try {
    const query = new Parse.Query(Parse.User);
    const usersForDropDown = [];
    query.select(['nameFirst', 'nameLast']);
    query.equalTo('isActive', true);
    await query.each(async (object) => {
      usersForDropDown.push({
        ...object.toJSON(),
        sortNum: object
          .get('nameFirst')
          .toLowerCase()
          .charCodeAt(0),
      });
    });
    usersForDropDown.sort((a, b) => a.sortNum - b.sortNum);
    dispatch(setUsersForDropDown(usersForDropDown));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const analyticObjectFetched = (object) => ({
  type: 'FETCH_ANALYTIC_OBJECT',
  object,
});

export const openLogsModal = () => ({
  type: 'OPEN_LOGS_MODAL',
});

export const hideLogsModal = () => ({
  type: 'HIDE_LOGS_MODAL',
});

export const startFetchAnalyticObject = ({
  objectId,
  nameFirst,
  nameLast,
}) => async (dispatch) => {
  try {
    dispatch(analyticObjectFetched(undefined));
    dispatch(showLoader('Loading logs...'));

    const result = await Parse.Cloud.run('fetch_analytic_object', {
      customerId: objectId,
    });
    if (result) {
      const fields = [
        'apptTime',
        'onTime',
        'apptDuration',
        'resourceQty',
        'isSale',
        'estimateAmount',
        'spousePresent',
        'proposalSent',
        'contractSent',
        'primaryResource',
        'usedFinanceOption',
        'creditAppSubmitted',
        'logObjects',
      ];

      const object = { customerName: `${nameFirst} ${nameLast}` };
      fields.forEach((field) => {
        object[field] = result.get(field);
      });
      dispatch(analyticObjectFetched(object));
    }
  } catch (e) {
    // Logs not found
    if (e.code === 107) {
      dispatch(analyticObjectFetched(undefined));
    } else {
      dispatch(handleError(e));
    }
  } finally {
    dispatch(hideLoader());
  }
};
