import { randomKey } from '../../../utils/utils';
import BodyCellItem from './BodyCellItem';

const toolbarKeys = ['size', 'bold', 'underline', 'name', 'color'];

const toolbarKeyMaps = {
  size: 'fontSize',
  bold: 'fontBold',
  underline: 'fontUnderline',
  name: 'fontName',
  color: 'fontColor',
};

const toolboxKeys = [
  'drawBorder',
  'shading',
  'userInputRequired',
  'canAddMultiple',
  'newPage',
  'linkedMeasureSheetItemIds',
];

const containerKey = 'body_group';
const pasteKey = 'body_group';

class BodyGroup {
  constructor(group, parent) {
    this.JSON = group;
    this.keys = [
      'objectId',
      'appTitle',
      'contractTitle',
      'drawBorder',
      'shading',
      'userInputRequired',
      'fontSize',
      'fontBold',
      'fontUnderline',
      'fontName',
      'fontColor',
      'cellItems',
      'canAddMultiple',
      'newPage',
      'linkedMeasureSheetItemIds',
    ];
    this.containerKey = containerKey;
    this.pasteKey = pasteKey;
    this.parent = parent;
    this.showToolbar = true;
    this.toolbarKey = 'sectionTitle';
    this.toolbarKeys = toolbarKeys;
    this.toolbarKeyMaps = toolbarKeyMaps;
    this.toolboxKey = 'body-title';
    this.toolboxKeys = toolboxKeys;
    if (group) {
      Object.assign(this, group);
      this.objectId = this.objectId || randomKey(10);
      this.cellItems = group.cellItems.map(
        (cellItem) => new BodyCellItem(cellItem, this),
      );
    } else {
      this.appTitle = 'New App Title';
      this.contractTitle = 'New Contract Title';
      this.drawBorder = true;
      this.shading = 1;
      this.userInputRequired = true;
      this.fontSize = 12;
      this.fontBold = true;
      this.fontUnderline = false;
      this.fontName = '';
      this.fontColor = '0-0-0-1';
      this.cellItems = [new BodyCellItem(undefined, this)];
      this.canAddMultiple = false;
      this.newPage = false;
      this.linkedMeasureSheetItemIds = [];
    }
    if (!this.objectId) {
      this.objectId = randomKey(10);
    }
    const source = this.getSourceObject();
    if (source) {
      if (source.hasObjectId(this.objectId)) {
        this.objectId = randomKey(10);
      }
      source.addObjectId(this.objectId);
    }
  }

  get identifier() {
    return `${this.parent.identifier}-${this.objectId}`;
  }

  getPath() {
    if (!this.parent) {
      return undefined;
    }
    const index = this.parent.data.findIndex(
      ({ objectId }) => objectId === this.objectId,
    );
    return [...this.parent.getPath(), index];
  }

  getSourceObject() {
    if (!this.parent) {
      return undefined;
    }
    return this.parent.getSourceObject();
  }

  getCellIds() {
    const { cellItems = [] } = this;
    return cellItems.reduce(
      (cellIds, cellItem) => [...cellIds, ...cellItem.getCellIds()],
      [],
    );
  }

  clone() {
    const jsonObject = this.toJSON();
    const json = JSON.parse(JSON.stringify(jsonObject));
    json.objectId = randomKey(10);
    const bodyGroup = new BodyGroup(json, this.parent);
    bodyGroup.cellItems = bodyGroup.cellItems.map((cellItem) =>
      cellItem.clone(),
    );
    return bodyGroup;
  }

  toJSON() {
    const json = {};
    this.keys.forEach((key) => {
      json[key] = this[key];
    });
    json.cellItems = this.cellItems.map((obj) => obj.toJSON());
    return json;
  }

  convertNewFiles() {
    const { cellItems = [] } = this;
    const promises = cellItems.map((cellItem) => cellItem.convertNewFiles());
    return Promise.all(promises);
  }

  static ContainerKey = containerKey;

  static PasteKey = pasteKey;
}

export default BodyGroup;
