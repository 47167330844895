/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
/* eslint-disable no-shadow */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DebounceInput } from 'react-debounce-input';

import { Menu, MenuItem, Classes } from 'leap-menu-item';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { connect } from 'react-redux';
import {
  setInitialStateForTemplate,
  setEditCellItem,
} from '../../../../actions/templateEdit';
import ContractObject from '../../Models/ContractObject';
import BodyCellItem from '../../Models/BodyCellItem';
import SettingsMenuContent from './SettingsMenuContent';
import SettingMenuCellType from './SettingMenuCellType';

const MenuFix = () => (
  <MenuItem style={{ display: 'none' }}>
    <MenuItem />
  </MenuItem>
);

const showIdentifier = (updatedObject) => {
  switch (updatedObject.cellType) {
    case 'textShort':
    case 'textLong':
    case 'textXLong':
    case 'switched':
      return true;
    default:
      return false;
  }
};

const validateIdentifier = (props) => {
  const {
    contractObject,
    value,
    categoryFormulaIds = [],
    currentCellIdentifier,
  } = props;
  const allContractIds = contractObject.getAllCellIdentifiers(
    currentCellIdentifier,
  );
  const defaultFormulaIds = ['B', 'T', 'CP', 'DP', 'UA', 'FP'];
  const allIds = [...defaultFormulaIds, ...allContractIds];
  const inAllIds = allIds.includes(value);
  const inCategoryFormulaIds = categoryFormulaIds.includes(value);
  return !inAllIds && !inCategoryFormulaIds;
};

const BodyCell = ({
  templateValues,
  editCellItem,
  secureCaptureEnabled,
  setTemplateState,
  setEditCellItem,
  categoryFormulaIds,
  ...rest
}) => {
  const contractObject = new ContractObject(templateValues.toJSON());
  const updatedObject =
    contractObject.getObjectWithId(editCellItem.objectId) || {};
  const [error, setError] = useState(false);
  const [identifierValue, setIdentifierValue] = useState(updatedObject.cellId);

  const showDocLinking = rest.flags && rest.flags['document-linking'];
  return (
    <Menu style={{ width: '280px' }} className={Classes.ELEVATION_1}>
      <MenuFix />
      {showIdentifier(updatedObject) && (
        <MenuItem
          popoverProps={{
            onClose: () => {
              const valid = validateIdentifier({
                contractObject,
                value: identifierValue,
                categoryFormulaIds,
                currentCellIdentifier: updatedObject.cellId,
              });
              if (valid) {
                updatedObject.cellId = identifierValue;
                setTemplateState(contractObject);
                setEditCellItem(updatedObject);
              }
            },
          }}
          text="Identifier"
          label={updatedObject.cellId}
        >
          {error && <p className="danger"> {error}</p>}
          <DebounceInput
            minLength={0}
            debounceTimeout={300}
            value={identifierValue}
            onChange={(e) => {
              const valid = validateIdentifier({
                contractObject,
                value: e.target.value,
                categoryFormulaIds,
                currentCellIdentifier: updatedObject.cellId,
              });
              if (valid) {
                setIdentifierValue(e.target.value);
                setError(undefined);
              } else {
                setIdentifierValue(e.target.value);
                setError('Invalid: Identifier already exists');
              }
            }}
          />
        </MenuItem>
      )}
      <SettingMenuCellType
        {...{
          updatedObject,
          setTemplateState,
          secureCaptureEnabled,
          contractObject,
        }}
      />
      <SettingsMenuContent
        {...{ updatedObject, contractObject }}
        showDocLinking={showDocLinking}
      />
    </Menu>
  );
};

BodyCell.propTypes = {
  templateValues: PropTypes.instanceOf(ContractObject).isRequired,
  editCellItem: PropTypes.instanceOf(BodyCellItem).isRequired,
  setTemplateState: PropTypes.func.isRequired,
  secureCaptureEnabled: PropTypes.bool.isRequired,
  setEditCellItem: PropTypes.bool.isRequired,
  categoryFormulaIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = ({ auth, templatesEdit = {} }) => {
  const { company, config } = auth;
  return {
    templateValues: templatesEdit[templatesEdit.currentWorkingId],
    editCellItem: templatesEdit.editCellItem,
    secureCaptureEnabled: company.get('spreedlyEnabled'),
    categoryFormulaIds: config.categories_
      .filter(({ formulaId }) => formulaId)
      .map(({ formulaId }) => formulaId),
  };
};

const mapDispatchToProps = (dispatch) => ({
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
  setEditCellItem: (editCellItem) => dispatch(setEditCellItem(editCellItem)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLDConsumer()(BodyCell));
