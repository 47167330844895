/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BodyRow from '../BodyRow';
import BodyCellItem from '../Models/BodyCellItem';
import { setEditCellItem } from '../../../actions/templateEdit';

const PhotoSelector = ({ cellItem, setEditItem }) => (
  <BodyRow cellItem={cellItem}>
    <div
      className="templates-app__photo_selector_cell"
      onClick={() => setEditItem(cellItem)}
    >
      <img
        src="/images/no_image.png"
        className="templates-app__photo_selector_cell_image"
        alt="phoo selector"
      />
      Add New
      <div className="templates-app__cell_bottom_border" />
    </div>
  </BodyRow>
);

PhotoSelector.propTypes = {
  cellItem: PropTypes.instanceOf(BodyCellItem).isRequired,
  setEditItem: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  setEditItem: (cellItem) => dispatch(setEditCellItem(cellItem)),
});

export default connect(undefined, mapDispatchToProps)(PhotoSelector);
