/* eslint-disable import/prefer-default-export */
import Parse from 'parse';
import { showWarningAlert, hideAlert } from './alert';

export const checkspfRecords = ({ email }, onConfirm) => async (
  dispatch,
  getState,
) => {
  const [, domain] = email.split('@');
  const sender = 'leaptodigital.com';
  try {
    const response = await Parse.Cloud.run('checkDomainSpf', {
      sender,
      domain,
    });
    if (onConfirm) {
      onConfirm();
    }
    return response;
  } catch (error) {
    if (onConfirm) {
      dispatch(
        showWarningAlert({
          title: 'SPF Records',
          message: error.message,
          onConfirm: () => {
            dispatch(hideAlert());
            if (onConfirm) {
              onConfirm();
            }
          },
        }),
      );
    }

    return error;
  }
};
