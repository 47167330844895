export default (state = {}, action) => {
  switch (action.type) {
    case 'CREATE_COMPANY':
      return { ...state, ...action.result };
    case 'FETCH_PACKAGE_CATEGORIES':
      return { ...state, packageCategories: action.packageCategories };
    default:
      return state;
  }
};
