/* eslint-disable import/no-named-as-default */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLayoutContext, layoutContextPropTypes } from '../../Layout';
import { startUpdateConfig } from '../../../actions/auth';
import FormGroup, { SwitchGroup } from '../../FormGroup';
import APIDropDown from '../APIDropDown';
import TemplateSending from './TemplateSending';
import Panel from '../../Panel';
import { showErrorAlert, hideAlert } from '../../../actions/alert';
import { updatedErrors, checkForErrors } from './Validation/Validator';

const formattedSettings = (settings) => {
  return {
    ...settings,
    webhook: settings.webhook || {},
    salesforce: settings.salesforce || {},
    leadPerfection: settings.leadPerfection || {},
    endpoints: settings.endpoints || [],
  };
};

export class ReportSending extends React.Component {
  constructor(props) {
    super(props);
    const newState = formattedSettings(props.settings);
    this.state = {
      ...newState,
      errors: updatedErrors({}, newState),
    };
  }

  componentDidMount() {
    const {
      setCrumbs,
      location: { pathname },
    } = this.props;

    const crumb = { title: 'Report Sending', link: pathname };
    setCrumbs([crumb]);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (!_.isEqual(newProps, this.props)) {
      const newState = formattedSettings(newProps.settings);
      this.setState({
        ...newState,
        errors: updatedErrors(this.state.errors, newState),
      });
    }
  }

  componentWillUnmount() {
    const { setCrumbs } = this.props;

    setCrumbs([]);
  }

  onSaveClicked = () => {
    if (checkForErrors(this.state.errors)) {
      this.props.showErrorAlert({
        title: 'Invalid Values',
        message: 'You must correct all validation errors before saving',
        onConfirm: this.props.hideAlert,
      });
      return;
    }
    const { errors, ...rest } = this.state;
    this.props.startUpdateConfig({ reportUpload: rest });
  };

  onValueChanged = ({ key, value }) => {
    const newState = {
      ...this.state,
      [key]: value,
    };
    const errors = updatedErrors(this.state.errors, newState);
    this.setState({ [key]: value, errors });
  };

  updateStateErrors = (settingsState) => {
    const errors = updatedErrors(this.state.errors, {
      ...this.state,
      ...settingsState,
    });
    this.setState({ errors });
  };

  settingsSection = () => (
    <Panel title="Report Sending">
      <div>
        <FormGroup title="APIs">
          <APIDropDown
            selectedOptions={this.state.endpoints}
            onChange={(value) =>
              this.onValueChanged({ key: 'endpoints', value })
            }
          />
        </FormGroup>
        <SwitchGroup
          title="Show In Resources"
          checked={this.state.showInResources}
          onChange={(value) =>
            this.onValueChanged({ key: 'showInResources', value })
          }
        />
        <SwitchGroup
          title="Show In Contracts"
          checked={this.state.showInContracts}
          onChange={(value) =>
            this.onValueChanged({ key: 'showInContracts', value })
          }
        />
        <SwitchGroup
          title="Show In Proposals"
          checked={this.state.showInProposals}
          onChange={(value) =>
            this.onValueChanged({ key: 'showInProposals', value })
          }
        />
      </div>
    </Panel>
  );

  render() {
    return (
      <div className="default-page-padding">
        <TemplateSending
          settingsSection={this.settingsSection}
          onValueChanged={this.onValueChanged}
          onSaveClicked={this.onSaveClicked}
          title="Report Sending"
          onCopy={() => this.setState({ showCopy: true })}
          onCopyClose={() => this.setState({ showCopy: false })}
          showCopy={this.state.showCopy}
          copyKey="reportUpload"
          updateStateErrors={this.updateStateErrors}
          {...this.state}
        />
      </div>
    );
  }
}

ReportSending.defaultProps = {
  settings: {
    leadPerfection: {
      fileName: '',
      fileDescription: '',
      backupRecipients: [],
    },
    salesforce: {
      fileName: '',
      fileDescription: '',
      backupRecipients: [],
    },
    webhook: {
      includePDF: false,
      url: '',
      authHeader: '',
    },
    endpoints: [],
    fillEmptyWhiteSpace: false,
    emptyWhiteSpaceNote: '',
    useBrochures: false,
    useVerifyEmail: false,
    from: '',
    subjectLine: '',
    recipients: [],
    ccRecipients: [],
    bccRecipients: [],
    emailHTML: '',
    emailFileName: '',
    fileName: '',
    showInContracts: false,
    showInProposals: false,
    showInResources: false,
  },
};

ReportSending.propTypes = {
  startUpdateConfig: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    leadPerfection: PropTypes.shape({
      fileName: PropTypes.string,
      fileDescription: PropTypes.string,
      backupRecipients: PropTypes.arrayOf(PropTypes.string),
    }),
    salesforce: PropTypes.shape({
      fileName: PropTypes.string,
      fileDescription: PropTypes.string,
      backupRecipients: PropTypes.arrayOf(PropTypes.string),
    }),
    webhook: PropTypes.shape({
      includePDF: PropTypes.bool,
      url: PropTypes.string,
      authHeader: PropTypes.string,
    }),
    endpoints: PropTypes.arrayOf(PropTypes.string),
    fillEmptyWhiteSpace: PropTypes.bool,
    emptyWhiteSpaceNote: PropTypes.string,
    useBrochures: PropTypes.bool,
    useVerifyEmail: PropTypes.bool,
    from: PropTypes.string,
    subjectLine: PropTypes.string,
    recipients: PropTypes.arrayOf(PropTypes.string),
    ccRecipients: PropTypes.arrayOf(PropTypes.string),
    bccRecipients: PropTypes.arrayOf(PropTypes.string),
    emailHTML: PropTypes.string,
    emailFileName: PropTypes.string,
    backupRecipients: PropTypes.arrayOf(PropTypes.string),
    fileName: PropTypes.string,
    showInContracts: PropTypes.bool,
    showInProposals: PropTypes.bool,
    showInResources: PropTypes.bool,
  }),
  showErrorAlert: PropTypes.func.isRequired,
  hideAlert: PropTypes.func.isRequired,
  ...layoutContextPropTypes,
};

const mapStateToProps = ({ auth: { config } }) => ({
  settings: { ...config.reportUpload },
});

const mapDispatchToProps = (dispatch) => ({
  startUpdateConfig: (updates) => dispatch(startUpdateConfig(updates)),
  showErrorAlert: (props) => dispatch(showErrorAlert(props)),
  hideAlert: () => dispatch(hideAlert()),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(ReportSending),
);
