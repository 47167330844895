/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import Parse from 'parse';
import { connect } from 'react-redux';
import {
  Classes,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
  Button,
  Position,
  Slider,
  FileInput,
} from 'leap-menu-item';
import { setInitialStateForTemplate } from '../../../actions/templateEdit';
import ContractObject from '../Models/ContractObject';
import Stepper from '../Stepper';
import YesNoMenuItem from '../Toolbox/YesNoMenuItem';
import { pushToDataLayer } from '../../../actions/tagManager';

const MenuFix = () => (
  <MenuItem style={{ display: 'none' }}>
    <MenuItem />
  </MenuItem>
);

const pageSizeOptions = [
  { label: 'Letter', value: '612,792' },
  { label: 'Letter (Landscape)', value: '792,612' },
  { label: 'Legal', value: '612,1008' },
  { label: 'Legal (Landscape)', value: '1008,612' },
  { label: 'A4', value: '495,842' },
  { label: 'A4 (Landscape)', value: '842,495' },
];

const labelForPageSize = (pageSize) => {
  const [found] = pageSizeOptions.filter((option) => option.value === pageSize);
  return found ? found.label : '';
};

const labelForPageType = (pageType) => {
  switch (pageType) {
    case 'singlePage':
      return 'Default';
    case 'pdfPage':
      return 'PDF';
    default:
      return '';
  }
};

export const fileDataFromFileChange = (e) =>
  new Promise((resolve) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (onload) => {
      const extension = file.type.split('/')[1];
      const name = `image.${extension}`;
      const newFile = new Parse.File(name, file);
      newFile.isNew = true;
      newFile._url = onload.target.result;
      resolve(newFile);
    };
    reader.readAsDataURL(file);
  });

const watermarkSourceLabel = (template) => {
  if (!template.useWatermark) {
    return '';
  }
  return template.watermark ? 'Saved' : 'Using App Settings';
};

const watermarkWidthValue = (template, width = 0) => {
  if (!template.useWatermark) {
    return 0;
  }
  if (template.watermark) {
    return template.watermarkWidthPercent || 0;
  }
  return width;
};

const watermarkTransparencyValue = (template, alpha = 0) => {
  if (!template.useWatermark) {
    return 0;
  }
  if (template.watermark) {
    const { watermarkAlpha } = template;
    return 100 - (watermarkAlpha || 0) * 100;
  }
  return 100 - alpha * 100;
};

class PageButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { watermarkIsOpen: undefined };
  }

  onFileInputChanged = async (e) => {
    const newFile = await fileDataFromFileChange(e);
    const contractObject = new ContractObject(this.props.template.toJSON());
    contractObject.watermark = newFile;
    this.props.setTemplateState(contractObject);
    this.setState({ watermarkIsOpen: undefined });
  };

  render() {
    const {
      template = {},
      setTemplateState,
      watermarkWidthPercent,
      watermarkAlpha,
    } = this.props;
    return (
      <Popover
        content={
          <Menu className={Classes.ELEVATION_1}>
            <MenuFix />
            <MenuDivider title="Page Setup" />
            <MenuItem
              icon="symbol-circle"
              text="Page Type"
              label={labelForPageType(template.pageId)}
            >
              <Menu.Divider title="Page Type" />
              <MenuItem
                icon={template.pageId === 'singlePage' ? 'dot' : 'blank'}
                text="Standard"
                shouldDismissPopover={false}
                active={template.pageId === 'singlePage'}
                onClick={() => {
                  const contractObject = new ContractObject(template.toJSON());
                  contractObject.pageId = 'singlePage';
                  setTemplateState(contractObject);
                }}
              />
              <MenuItem
                icon={template.pageId === 'pdfPage' ? 'dot' : 'blank'}
                text="PDF"
                shouldDismissPopover={false}
                active={template.pageId === 'pdfPage'}
                onClick={() => {
                  const contractObject = new ContractObject(template.toJSON());
                  contractObject.pageId = 'pdfPage';
                  setTemplateState(contractObject);
                }}
              />
            </MenuItem>
            <MenuItem
              icon="widget"
              text="Page Size"
              label={labelForPageSize(template.pageSize)}
            >
              <Menu.Divider title="Page Size" />

              {pageSizeOptions.map((pageSizeOption) => {
                return (
                  <MenuItem
                    icon={
                      template.pageSize === pageSizeOption.value
                        ? 'dot'
                        : 'blank'
                    }
                    text={pageSizeOption.label}
                    shouldDismissPopover={false}
                    active={template.pageSize === pageSizeOption.value}
                    onClick={() => {
                      const contractObject = new ContractObject(
                        template.toJSON(),
                      );
                      contractObject.pageSize = pageSizeOption.value;
                      setTemplateState(contractObject);
                    }}
                  />
                );
              })}
            </MenuItem>
            <Menu.Divider />
            <MenuItem
              icon="media"
              text="Watermark"
              label={template.useWatermark ? 'Yes' : 'No'}
              shouldDismissPopover={false}
              popoverProps={{ isOpen: this.state.watermarkIsOpen }}
            >
              <Menu.Divider title="Watermark" />
              <YesNoMenuItem
                text="Use Watermark"
                active={template.useWatermark}
                onChange={(value) => {
                  const contractObject = new ContractObject(template.toJSON());
                  contractObject.useWatermark = value;
                  setTemplateState(contractObject);
                }}
              />
              <MenuItem
                icon=""
                text="File"
                shouldDismissPopover={false}
                disabled={!template.useWatermark}
                label={watermarkSourceLabel(template)}
                popoverProps={{ isOpen: this.state.watermarkIsOpen }}
              >
                <Menu.Divider title="Choose Watermark" />
                <MenuItem
                  text="Use App Settings"
                  active={template.useWatermark && !template.watermark}
                  shouldDismissPopover={false}
                  icon={
                    template.useWatermark && !template.watermark
                      ? 'dot'
                      : 'blank'
                  }
                  onClick={() => {
                    const contractObject = new ContractObject(
                      template.toJSON(),
                    );
                    contractObject.useWatermark = true;
                    contractObject.watermark = undefined;
                    setTemplateState(contractObject);
                  }}
                />
                <MenuItem
                  text=""
                  active={template.useWatermark && template.watermark}
                  shouldDismissPopover={false}
                  icon={
                    template.useWatermark && template.watermark
                      ? 'dot'
                      : 'blank'
                  }
                  labelElement={
                    <FileInput
                      text="Choose file..."
                      onInputChange={this.onFileInputChanged}
                      onClick={() => this.setState({ watermarkIsOpen: true })}
                      inputProps={{ accept: 'image/*' }}
                    />
                  }
                />
              </MenuItem>
              <MenuItem
                icon=""
                text="Width"
                disabled={!template.useWatermark || !template.watermark}
                shouldDismissPopover={false}
                // label={watermarkWidthLabel(template, watermarkWidthPercent)}
                labelElement={
                  <Slider
                    disabled={!template.useWatermark || !template.watermark}
                    value={watermarkWidthValue(template, watermarkWidthPercent)}
                    max={100}
                    min={0}
                    labelStepSize={100}
                    labelRenderer={false}
                    onChange={(value) => {
                      const contractObject = new ContractObject(
                        template.toJSON(),
                      );
                      contractObject.watermarkWidthPercent = value;
                      setTemplateState(contractObject);
                    }}
                  />
                }
              />
              <MenuItem
                icon=""
                text="Transparency"
                disabled={!template.useWatermark || !template.watermark}
                shouldDismissPopover={false}
                // label={watermarkTransparencyLabel(template, watermarkAlpha)}
                labelElement={
                  <Slider
                    disabled={!template.useWatermark || !template.watermark}
                    value={watermarkTransparencyValue(template, watermarkAlpha)}
                    max={100}
                    min={0}
                    labelStepSize={100}
                    labelRenderer={false}
                    onChange={(value) => {
                      const contractObject = new ContractObject(
                        template.toJSON(),
                      );
                      contractObject.watermarkAlpha = (100 - value) / 100;
                      setTemplateState(contractObject);
                    }}
                  />
                }
              />
            </MenuItem>
            <MenuItem
              icon="camera"
              text="Photos Per Page"
              label={template.photosPerPage}
            >
              <Menu.Divider title="Photos Per Page" />
              <MenuItem
                icon={template.photosPerPage === 1 ? 'dot' : 'blank'}
                text="1"
                shouldDismissPopover={false}
                active={template.photosPerPage === 1}
                onClick={() => {
                  const contractObject = new ContractObject(template.toJSON());
                  contractObject.photosPerPage = 1;
                  setTemplateState(contractObject);
                }}
              />
              <MenuItem
                icon={template.photosPerPage === 2 ? 'dot' : 'blank'}
                text="2"
                shouldDismissPopover={false}
                active={template.photosPerPage === 2}
                onClick={() => {
                  const contractObject = new ContractObject(template.toJSON());
                  contractObject.photosPerPage = 2;
                  setTemplateState(contractObject);
                }}
              />
              <MenuItem
                icon={template.photosPerPage === 3 ? 'dot' : 'blank'}
                text="3"
                shouldDismissPopover={false}
                active={template.photosPerPage === 3}
                onClick={() => {
                  const contractObject = new ContractObject(template.toJSON());
                  contractObject.photosPerPage = 3;
                  setTemplateState(contractObject);
                }}
              />
            </MenuItem>
            <Menu.Divider />
            <MenuItem
              icon="arrows-horizontal"
              text="Left/Right Margins"
              shouldDismissPopover={false}
              labelElement={
                <Stepper
                  value={template.wMargin}
                  minValue={0}
                  maxValue={1000}
                  small
                  onChange={(value) => {
                    const contractObject = new ContractObject(
                      template.toJSON(),
                    );
                    const pageSize = parseFloat(contractObject.pageSize);
                    const limit = pageSize / 4;
                    contractObject.wMargin = value < limit ? value : limit;
                    setTemplateState(contractObject);
                  }}
                />
              }
            />
            <MenuItem
              icon="arrows-vertical"
              text="Top/Bottom Margins"
              shouldDismissPopover={false}
              labelElement={
                <Stepper
                  value={template.hMargin}
                  minValue={0}
                  maxValue={1000}
                  small
                  onChange={(value) => {
                    const contractObject = new ContractObject(
                      template.toJSON(),
                    );
                    const pageSize = parseFloat(contractObject.pageSize);
                    const limit = pageSize / 4;
                    contractObject.hMargin = value < limit ? value : limit;
                    setTemplateState(contractObject);
                  }}
                />
              }
            />
          </Menu>
        }
        position={Position.BOTTOM}
        isOpen={this.state.watermarkIsOpen}
      >
        <Button
          onClick={(event) => {
            const { parentNode } = event.target;
            const { className } = parentNode;
            const open = !(
              className.indexOf('open') > -1 || className.indexOf('active') > -1
            );
            if (open) {
              this.props.pushToDataLayer({
                event: 'contractMenuEvent',
                eventCategory: 'Documents',
                eventAction: 'Menu Open',
                eventLabel: 'Page Button',
              });
            }
          }}
          className={Classes.MINIMAL}
          text="Page"
        />
      </Popover>
    );
  }
}

PageButton.propTypes = {
  template: PropTypes.instanceOf(ContractObject),
  setTemplateState: PropTypes.func.isRequired,
  watermarkWidthPercent: PropTypes.number,
  watermarkAlpha: PropTypes.number,
};

PageButton.defaultProps = {
  template: undefined,
  watermarkWidthPercent: 0,
  watermarkAlpha: 0,
};

const mapStateToProps = ({
  templatesEdit,
  auth: { config: { watermarkAlpha = 0, watermarkWidthPercent = 0 } = {} } = {},
}) => ({
  template: templatesEdit[templatesEdit.currentWorkingId],
  watermarkWidthPercent,
  watermarkAlpha,
});

const mapDispatchToProps = (dispatch) => ({
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
  pushToDataLayer: (variablesForLayer) =>
    dispatch(pushToDataLayer(variablesForLayer)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageButton);
