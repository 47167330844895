/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup } from 'leap-menu-item';

let continueLoop;
const startLoop = (start, value, callback) => {
  continueLoop = start;
  if (!start) {
    return;
  }
  const newValue = callback(value);
  setTimeout(() => {
    startLoop(continueLoop, newValue, callback);
  }, 10);
};

const stopLoop = () => {
  continueLoop = false;
};

const Stepper = ({
  small,
  value,
  minValue,
  maxValue,
  onChange,
  increment,
  fill,
}) => (
  <div onMouseOut={() => stopLoop()}>
    <ButtonGroup minimal fill={fill}>
      <Button
        icon="minus"
        disabled={value <= minValue}
        small={small}
        onMouseDown={() => {
          startLoop(true, value, (oldValue) => {
            const newValue =
              minValue !== undefined
                ? Math.max(oldValue - increment, minValue)
                : oldValue - increment;
            onChange(newValue);
            return newValue;
          });
        }}
        onMouseUp={() => stopLoop()}
      />
      <Button
        icon="plus"
        disabled={value >= maxValue}
        small={small}
        onMouseDown={() => {
          startLoop(true, value, (oldValue) => {
            const newValue =
              maxValue !== undefined
                ? Math.min(oldValue + increment, maxValue)
                : oldValue + increment;
            onChange(newValue);
            return newValue;
          });
        }}
        onMouseUp={() => stopLoop()}
      />
    </ButtonGroup>
  </div>
);

Stepper.propTypes = {
  small: PropTypes.bool,
  value: PropTypes.number,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  increment: PropTypes.number,
  fill: PropTypes.bool,
};

Stepper.defaultProps = {
  small: false,
  value: 0,
  minValue: undefined,
  maxValue: undefined,
  increment: 1,
  fill: false,
};

export default Stepper;
