import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';

// NOTE: this only shows the most recent error, will need some upgrades if
// we want to show all errors the user hasn't dissmissed manually
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorStr: '',
    };

    this.ellapsedRef = createRef();
  }

  static getDerivedStateFromError(error) {
    // Function that uses regex on the error name to decide whether or not
    // to display the error to the user, or replace the error message with
    // something more user readable. Returning false supresses error output
    const shouldNotify = () => {
      switch (error.name) {
        case /^The connection to .* was interrupted while the page was loading\.$/:
          return false;
        case /^.*https:\/\/va\.tawk\.to\/.*/:
          return false;
        default:
          return true;
      }
    };

    function replaceErrors(key, value) {
      if (value instanceof Error) {
        const err = {};

        Object.getOwnPropertyNames(value).forEach((k) => {
          err[k] = value[k];
        });

        return err;
      }

      return value;
    }

    const errorStr = JSON.stringify(error, replaceErrors, 2);
    // Update state so the next render will show the fallback UI.
    return {
      hasError: shouldNotify(),
      error,
      time: new Date(Date.now()),
      errorStr,
    };
  }

  componentDidCatch(error, errorInfo) {
    // This will be a placeholder for connecting to our error reporting service
    // For now, we'll just log it to the conosle
    // eslint-disable-next-line no-console
    console.error(error, errorInfo);
  }

  render() {
    const { hasError, error, errorStr } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <div className="p-4">
          <h1>{`${error.name}: ${error.message}`}</h1>
          <p style={{ whiteSpace: 'pre-wrap', color: 'red' }}>
            {errorStr.split('\\n').map((value, index) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <React.Fragment key={index}>
                  {value}
                  <br />
                </React.Fragment>
              );
            })}
          </p>
        </div>
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ErrorBoundary;
