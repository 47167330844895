/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { SectionTitle } from '../SharedComponents/SectionTitle';
import { FieldTitle } from '../SharedComponents/FieldTitle';

const SaleSection = (props) => {
  const { leadResults } = props.estimate || [];

  const { contractSent, creditAppSubmitted, isSale, usedFinanceOption } =
    props.analyticsData || {};

  const resultList = [
    {
      label: 'Sale:',
      value: isSale ? 'Yes' : 'No',
    },
    {
      label: 'Contract Sent:',
      value: contractSent ? 'Yes' : 'No',
    },
    {
      label: 'Fin Offered:',
      value: usedFinanceOption ? 'Yes' : 'No',
    },
    {
      label: 'Fin Submitted:',
      value: creditAppSubmitted ? 'Yes' : 'No',
    },
  ];

  const pricingOptionsList = leadResults.map(({ inputType, title, value }) => {
    switch (inputType) {
      case '__email':
        return {
          label: title,
          value: <a href={`mailto:${value}`}>{value}</a>,
        };
      default: {
        return {
          label: title,
          value,
        };
      }
    }
  });

  return (
    <article className="sale-meta-section leap-data-section">
      <SectionTitle title="Results" />
      <div className="field-section my-1">
        <FieldTitle title="Summary" />
        <div className="field-section-row row  my-2">
          {resultList.map((resultItem, i) => {
            return (
              <div
                key={`resultItem_${i}`}
                className="col-md-3 col-sm-6 result-summary-item"
              >
                <p className="list-line-action-item my-1">
                  {' '}
                  {resultItem.label}
                </p>
                <p className="field-display-list-value">{resultItem.value}</p>
              </div>
            );
          })}
        </div>
        <div className="field-section-row my-2">
          <section>
            <FieldTitle title="Details" />
            <dl className="row">
              {pricingOptionsList.map((item, i) => {
                return (
                  <div
                    key={`priceOption_${i}`}
                    className="col-xl-3 col-md-4 col-sm-6"
                  >
                    {item.label && (
                      <dt className="list-line-action-item">{item.label}</dt>
                    )}
                    {item.value && (
                      <dd className="field-display-list-value">{item.value}</dd>
                    )}
                  </div>
                );
              })}
            </dl>
          </section>
        </div>
      </div>
    </article>
  );
};

export default SaleSection;
