import React from 'react';
import { DebounceInput } from 'react-debounce-input';
import NetWrapper from './NetWrapper';

const DebounceInputWithNet = (props) => {
  return (
    <NetWrapper>
      <DebounceInput {...props} />
    </NetWrapper>
  );
};

export default DebounceInputWithNet;
