import React from 'react';
import PropTypes from 'prop-types';

const TextInput = ({ title, value, onChange, ...rest }) => (
  <div className="new-account__text-input-group">
    <label htmlFor="text-input">
      {title}
      <input
        name="text-input"
        type="text"
        value={value}
        onChange={onChange}
        {...rest}
      />
    </label>
  </div>
);

TextInput.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
};

TextInput.defaultProps = {
  title: '',
  value: '',
  onChange: undefined,
};

export default TextInput;
