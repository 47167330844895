/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Menu, MenuItem, TextArea, Button } from 'leap-menu-item';
import {
  setInitialStateForTemplate,
  setShowLinkMeasureSheetItems,
} from '../../../../actions/templateEdit';
import YesNoMenuItem from '../YesNoMenuItem';
import ContractObject from '../../Models/ContractObject';
import MSIPlaceholderMenu from '../../MSIPlaceholderMenu';
import InitialsRequired from './InitialsRequired';
import DebounceInputWithNet from '../DebounceInputWithNet';
import LinkedCellMenu from './LinkedCellMenu';

const TextOnlyMenu = ({
  object,
  contractObject,
  setTemplateState,
  secureCaptureEnabled,
  setShowLinkItems,
  showDocLinking,
}) => (
  <>
    <Menu.Divider />
    <YesNoMenuItem
      text="Required"
      active={object.required}
      onChange={(value) => {
        object.required = value;
        setTemplateState(contractObject);
      }}
    />
    <Menu.Divider />
    {!object.titleLinked && (
      <>
        <MenuItem
          text={
            <span>
              App Title
              <Button
                small
                minimal
                icon="unlock"
                onClick={() => {
                  object.titleLinked = true;
                  object.contractTitle = object.appTitle;
                  setTemplateState(contractObject);
                }}
              />
            </span>
          }
        >
          <DebounceInputWithNet
            element={TextArea}
            minLength={0}
            debounceTimeout={300}
            value={object.appTitle}
            onChange={(e) => {
              object.appTitle = e.target.value;
              setTemplateState(contractObject);
            }}
          />
        </MenuItem>
        <MenuItem
          text={
            <span>
              PDF Title
              <Button
                small
                minimal
                icon="unlock"
                onClick={() => {
                  object.titleLinked = true;
                  object.appTitle = object.contractTitle;
                  setTemplateState(contractObject);
                }}
              />
            </span>
          }
        >
          <DebounceInputWithNet
            element={TextArea}
            minLength={0}
            debounceTimeout={300}
            value={object.contractTitle}
            onChange={(e) => {
              object.contractTitle = e.target.value;
              setTemplateState(contractObject);
            }}
          />
        </MenuItem>
      </>
    )}
    {object.titleLinked && (
      <MenuItem
        text={
          <span>
            Title
            <Button
              small
              minimal
              icon="lock"
              onClick={() => {
                object.titleLinked = false;
                setTemplateState(contractObject);
              }}
            />
          </span>
        }
      >
        <DebounceInputWithNet
          element={TextArea}
          minLength={0}
          debounceTimeout={300}
          value={object.contractTitle}
          onChange={(e) => {
            object.contractTitle = e.target.value;
            setTemplateState(contractObject);
          }}
        />
      </MenuItem>
    )}
    <Menu.Divider />
    <MenuItem text="Value" shouldDismissPopover={false}>
      <DebounceInputWithNet
        element={TextArea}
        minLength={0}
        debounceTimeout={300}
        value={object.value}
        onChange={(e) => {
          object.value = e.target.value;
          setTemplateState(contractObject);
        }}
      />
    </MenuItem>
    <InitialsRequired
      object={object}
      setTemplateState={setTemplateState}
      contractObject={contractObject}
    />
    <Menu.Divider />
    {showDocLinking && (
      <LinkedCellMenu
        value={object.linkedValueSettings}
        onChange={({ objectId, overwritePriority }) => {
          object.linkedValueSettings = {
            objectId,
            overwritePriority,
          };
          setTemplateState(contractObject);
        }}
      />
    )}
    <MenuItem
      text="Link Measure Sheet Item"
      shouldDismissPopover={false}
      onClick={
        () =>
          setShowLinkItems(
            true,
            'linkedMeasureSheetItem',
            true,
            'Link Measure Sheet Item',
          )
        // eslint-disable-next-line react/jsx-curly-newline
      }
      label={object.linkedMeasureSheetItem ? 'Linked' : ''}
    />
    <MSIPlaceholderMenu
      itemIds={[object.linkedMeasureSheetItem]}
      disabled={!object.linkedMeasureSheetItem}
    />
    <MenuItem
      text="Link Quantities"
      shouldDismissPopover={false}
      onClick={() =>
        setShowLinkItems(
          true,
          'measureSheetItemQuantityIds',
          false,
          'Link Quantities',
        )
      }
      label={
        object.measureSheetItemQuantityIds
          ? object.measureSheetItemQuantityIds.length
          : ''
      }
    />
    <MenuItem
      text="Link Totals"
      shouldDismissPopover={false}
      onClick={() =>
        setShowLinkItems(true, 'measueSheetItemTotalIds', false, 'Link Totals')
      }
      label={
        object.measueSheetItemTotalIds
          ? object.measueSheetItemTotalIds.length
          : ''
      }
    />
  </>
);

TextOnlyMenu.propTypes = {
  object: PropTypes.any.isRequired,
  contractObject: PropTypes.instanceOf(ContractObject).isRequired,
  setTemplateState: PropTypes.func.isRequired,
  secureCaptureEnabled: PropTypes.bool,
  setShowLinkItems: PropTypes.func.isRequired,
  showDocLinking: PropTypes.bool,
};

TextOnlyMenu.defaultProps = {
  secureCaptureEnabled: false,
  showDocLinking: false,
};

const mapStateToProps = ({ templatesEdit = {}, auth }) => ({
  templateValues: templatesEdit[templatesEdit.currentWorkingId],
  editCellItem: templatesEdit.editCellItem,
  secureCaptureEnabled: auth.company.get('spreedlyEnabled'),
});

const mapDispatchToProps = (dispatch) => ({
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
  setShowLinkItems: (show, key, singleSelect, title) =>
    dispatch(setShowLinkMeasureSheetItems(show, key, singleSelect, title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TextOnlyMenu);
