/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Parse from 'parse';
import { Table } from 'react-bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import { LayoutContext } from '../Layout';
import Panel from '../Panel';
import {
  startFetchPermissions,
  startDeletePermission,
  showDeleteConfirm,
  setEditPermission,
  startClonePermission,
} from '../../actions/permissions';
import { permissionsByType } from '../../selectors/permissions';
import TitleButton from '../TitleButton';
import { history } from '../../router';
import { pushToDataLayer } from '../../actions/tagManager';

const onNewClicked = (props) => {
  props.setEditPermission({
    activePermissionIds: [],
    activeUserIds: [],
    permission: new Parse.Role(),
  });
  history.push('/permissions/new');
};

const Index = (props) => {
  const { setCrumbs, setButtons } = useContext(LayoutContext);
  const buttons = [];

  if (props.customPermissionGroupsAllowed) {
    buttons.push(
      <TitleButton
        variant="success"
        onClick={() => onNewClicked(props)}
        title="New"
      />,
    );
  }

  useEffect(() => {
    setCrumbs([{ title: 'Permissions', link: '/permissions' }]);
    props.startFetchPermissions();
    setButtons(buttons);
    return () => {
      setButtons();
    };
  }, []);

  return (
    <>
      {props.confirmDelete && (
        <SweetAlert
          warning
          showCancel
          title="Delete Permission Group"
          confirmBtnText="Yes"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          onConfirm={() => props.startDeletePermission(props.confirmDelete.id)}
          onCancel={() => props.showDeleteConfirm()}
        >
          {`Are you sure you want to delete the ${props.confirmDelete.get(
            'title',
          )} Permission Group?`}
        </SweetAlert>
      )}
      <div className="default-page-padding">
        <Panel title="Permissions">
          <div>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Permission Group</th>
                  <th style={{ width: '35px' }}>Edit</th>
                  {props.customPermissionGroupsAllowed && (
                    <th style={{ width: '35px' }}>Clone</th>
                  )}
                  <th style={{ width: '35px' }}>Delete</th>
                </tr>
              </thead>
              <tbody>
                {props.permissions.map((object) => (
                  <tr key={object.id}>
                    <td>{object.get('title')}</td>
                    <td>
                      <Link
                        onClick={() => {
                          props.pushToDataLayer({
                            event: 'permissionGroupEvent',
                            eventCategory: 'Permissions',
                            eventAction: 'View',
                          });
                        }}
                        to={`/permissions/edit/${object.id}`}
                      >
                        <button className="fill-cell">
                          <i className="fas fa-pencil-alt" />
                        </button>
                      </Link>
                    </td>
                    {props.customPermissionGroupsAllowed && (
                      <td>
                        <button
                          onClick={() => props.startClonePermission(object.id)}
                          className="fill-cell"
                        >
                          <i className="far fa-clone" />
                        </button>
                      </td>
                    )}
                    <td>
                      <button
                        onClick={() => props.showDeleteConfirm(object)}
                        className="fill-cell danger"
                        disabled={object.get('isDefault')}
                        style={{
                          cursor: object.get('isDefault')
                            ? 'not-allowed'
                            : undefined,
                        }}
                      >
                        <i className="far fa-trash-alt" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Panel>
      </div>
    </>
  );
};

Index.propTypes = {
  startFetchPermissions: PropTypes.func.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.instanceOf(Parse.Object)),
  confirmDelete: PropTypes.instanceOf(Parse.Role),
  startDeletePermission: PropTypes.func.isRequired,
  showDeleteConfirm: PropTypes.func.isRequired,
  customPermissionGroupsAllowed: PropTypes.func.isRequired,
};

Index.defaultProps = {
  permissions: [],
  confirmDelete: undefined,
};

const mapStateToProps = ({ permissions, plan = {} }) => ({
  permissions: permissionsByType(permissions.items, 'Group'),
  confirmDelete: permissions.confirmDelete,
  customPermissionGroupsAllowed: plan.customPermissionGroupsAllowed,
});

const mapDispatchToProps = (dispatch) => ({
  startFetchPermissions: () => dispatch(startFetchPermissions()),
  startDeletePermission: (id) => dispatch(startDeletePermission(id)),
  showDeleteConfirm: (object) => dispatch(showDeleteConfirm(object)),
  startClonePermission: (id) => dispatch(startClonePermission(id)),
  setEditPermission: (editPermission) =>
    dispatch(setEditPermission(editPermission)),
  pushToDataLayer: (variablesForLayer) =>
    dispatch(pushToDataLayer(variablesForLayer)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
