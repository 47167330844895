import { emailRegex } from '../../validators';

export const regexValidators = {
  nameFirst: {
    validate: () => {},
    message: 'Required',
    required: true,
  },
  nameLast: {
    validate: () => {},
    message: 'Required',
    required: true,
  },
  password: {
    validate: (value) => value.length < 8,
    message: 'Must be at least 8 characters',
    required: true,
  },
  confirmPassword: {
    validate: (value) => value.length < 8,
    message: 'Must be at least 8 characters',
    required: true,
  },
  selectedRoles: {
    validate: (selectedRoles) => selectedRoles.length === 0,
    message: 'At least one permission required',
    required: true,
  },
  allowedOffices: {
    validate: (allowedOffices) => allowedOffices.length === 0,
    message: 'At least one office required',
    required: true,
  },
  selectedOffice: {
    validate: (selectedOffice) => !selectedOffice.objectId,
    message: 'Required',
    required: true,
  },
  email: {
    validate: (value) => !emailRegex.test(value),
    message: 'Invalid email',
    flip: true,
    required: true,
  },
  username: {
    validate: () => {},
    message: 'Required',
    required: true,
  },
};

export const getErrorMessage = (key, value) => {
  const keyValidator = regexValidators[key];
  if (keyValidator) {
    const testResult = keyValidator.validate(value);
    if (keyValidator.required && (!value || value.length === 0)) {
      return 'Required';
    }
    if (testResult) {
      return keyValidator.message;
    }
  }
  return null;
};
