/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Parse from 'parse';
import { DebounceInput } from 'react-debounce-input';
import isNumeric from 'validator/lib/isNumeric';
import Slider from './Misc/Slider';
import Switch from './Misc/Switch';
import Select from './Misc/Select';
import MultiSelect from './Misc/MultiSelect';
import TitleButton from './TitleButton';
import TagInput from './TagInput';

/** @param {{
 *  title: string,
 *  checked: boolean,
 *  onChange: (checked: boolean, event: MouseEvent | React.SyntheticEvent<MouseEvent | KeyboardEvent, Event>, id: string) => void,
 * }} props */
export const SwitchGroup = ({ title, checked, onChange }) => (
  <FormGroup title={title}>
    <Switch checked={checked} onChange={onChange} />
  </FormGroup>
);

SwitchGroup.propTypes = {
  title: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

SwitchGroup.defaultProps = {
  checked: false,
};

export const TextGroupDebounce = ({
  title,
  value,
  placeholder,
  onChange,
  inputRef,
  errorMessage,
  ...rest
}) => (
  <FormGroup title={title} errorMessage={errorMessage}>
    <DebounceInput
      minLength={2}
      debounceTimeout={300}
      onChange={(event) => onChange(event.target.value)}
      {...rest}
    />
  </FormGroup>
);

TextGroupDebounce.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  errorMessage: PropTypes.string,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

TextGroupDebounce.defaultProps = {
  value: '',
  placeholder: '',
  errorMessage: '',
  onChange: () => {},
  inputRef: React.createRef(),
};

/** @param {{
 *  title: string,
 *  value: string,
 *  placeholder?: string,
 *  onChange: (change: string) => void,
 *  inputRef?: React.Ref<HTMLInputElement>,
 *  errorMessage?: string,
 *  name?: string,
 *  id?: string,
 *  defaultValue?: string,
 * }} props */
export const TextGroup = ({
  title,
  value,
  placeholder,
  onChange,
  inputRef,
  errorMessage,
  name,
  id,
  defaultValue,
  ...rest
}) => (
  <FormGroup title={title} errorMessage={errorMessage}>
    <input
      defaultValue={defaultValue}
      name={name}
      id={id}
      ref={inputRef}
      type="text"
      value={value}
      placeholder={placeholder}
      onChange={(e) => onChange(e.target.value)}
      {...rest}
    />
  </FormGroup>
);

TextGroup.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  errorMessage: PropTypes.string,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

TextGroup.defaultProps = {
  value: undefined,
  placeholder: '',
  errorMessage: '',
  onChange: () => {},
  inputRef: React.createRef(),
};

export const TextAreaGroup = ({
  title,
  value,
  onChange,
  errorMessage,
  textAreaStyle,
  ...rest
}) => (
  <FormGroup title={title} errorMessage={errorMessage}>
    <textarea
      style={textAreaStyle}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      {...rest}
    />
  </FormGroup>
);

TextAreaGroup.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  errorMessage: PropTypes.string,
};

TextAreaGroup.defaultProps = {
  value: '',
  onChange: () => {},
  errorMessage: '',
};

export const PasswordGroup = ({
  title,
  value,
  placeholder,
  onChange,
  errorMessage,
  ...rest
}) => (
  <FormGroup title={title} errorMessage={errorMessage}>
    <input
      type="password"
      value={value}
      placeholder={placeholder}
      onChange={(e) => onChange(e.target.value)}
      autoComplete="new-password"
      {...rest}
    />
  </FormGroup>
);

PasswordGroup.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

PasswordGroup.defaultProps = {
  value: '',
  placeholder: '',
  errorMessage: '',
};

export const NumberGroup = ({
  title,
  value,
  placeholder,
  onChange,
  errorMessage,
  ...rest
}) => (
  <FormGroup title={title} errorMessage={errorMessage}>
    <input
      type="number"
      value={value}
      placeholder={placeholder}
      onChange={({ target }) => {
        if (
          target.value.length &&
          isNumeric(target.value, { no_symbols: true })
        ) {
          onChange(target.value);
        } else {
          onChange(target.value); // this should only be an empty string ''
        }
      }}
      {...rest}
    />
  </FormGroup>
);

NumberGroup.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

NumberGroup.defaultProps = {
  value: 0,
  placeholder: '',
  errorMessage: '',
};

export const TagGroup = ({
  title,
  values,
  placeholder,
  onChange,
  errorMessage,
  ...rest
}) => (
  <FormGroup title={title} errorMessage={errorMessage}>
    <TagInput
      tags={values}
      onChange={onChange}
      placeholder={placeholder}
      inputProps={{ allowUnique: true, ...rest }}
    />
  </FormGroup>
);

TagGroup.propTypes = {
  title: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.string),
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

TagGroup.defaultProps = {
  values: [],
  placeholder: '',
  errorMessage: '',
};

export const SliderGroup = ({
  title,
  value,
  max,
  min,
  onChange,
  labelStepSize,
  errorMessage,
  ...rest
}) => (
  <FormGroup title={title} errorMessage={errorMessage}>
    <Slider
      initialValue={value}
      value={value}
      max={max}
      min={min}
      onChange={onChange}
      labelStepSize={labelStepSize}
      labelRenderer={(e) => `${e}%`}
      {...rest}
    />
  </FormGroup>
);

SliderGroup.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  labelStepSize: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

SliderGroup.defaultProps = {
  value: 0,
  errorMessage: '',
};

export const DropDownGroup = ({
  title,
  value,
  onChange,
  isMulti,
  closeMenuOnSelect,
  options,
  errorMessage,
  ...rest
}) => {
  return (
    <FormGroup title={title} errorMessage={errorMessage}>
      <Select
        name="form-field-name"
        value={value}
        onChange={onChange}
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
        options={options}
        {...rest}
      />
    </FormGroup>
  );
};

const dropDownGroupValuePropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.shape({
    value: PropTypes.any.isRequired,
    label: PropTypes.any.isRequired,
  }),
]);

DropDownGroup.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    dropDownGroupValuePropType,
    PropTypes.arrayOf(dropDownGroupValuePropType),
  ]),
  isMulti: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.instanceOf(Parse.Object),
      PropTypes.shape({
        label: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
      }),
    ]),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

DropDownGroup.defaultProps = {
  value: '',
  isMulti: false,
  closeMenuOnSelect: false,
  errorMessage: '',
};

export const MultiSelectGroup = ({
  title,
  selected,
  onSelectedChanged,
  options,
  errorMessage,
  ...rest
}) => (
  <FormGroup title={title} errorMessage={errorMessage}>
    <MultiSelect
      name="form-field-name"
      selected={selected}
      onSelectedChanged={onSelectedChanged}
      options={options}
      hasSelectAll={options.length > 1}
      disableSearch={options.length <= 1}
      {...rest}
    />
  </FormGroup>
);

const isMultiSelectGroupSelectedPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]);

MultiSelectGroup.propTypes = {
  title: PropTypes.string.isRequired,
  selected: PropTypes.arrayOf(isMultiSelectGroupSelectedPropType),
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.instanceOf(Parse.Object),
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
      }),
    ]),
  ).isRequired,
  onSelectedChanged: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

MultiSelectGroup.defaultProps = {
  selected: [],
  closeMenuOnSelect: false,
  errorMessage: '',
};

export const ButtonGroup = ({
  title,
  buttonTitle,
  onClick,
  errorMessage,
  ...rest
}) => (
  <FormGroup title={title} errorMessage={errorMessage}>
    <TitleButton title={buttonTitle} onClick={onClick} {...rest} />
  </FormGroup>
);

ButtonGroup.propTypes = {
  title: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

ButtonGroup.defaultProps = {
  errorMessage: '',
};

export const DateRangePickerFormGroup = ({
  title,
  children = [],
  errorMessage,
}) => (
  <div className="input-pair">
    <div className={`input-pair_title${errorMessage ? ' error' : ''}`}>
      {title}
    </div>
    <div className="input-container">
      {React.Children.map(children, (child) => {
        return React.cloneElement(child);
      })}
      {errorMessage && <div className="error">{errorMessage}</div>}
    </div>
  </div>
);

const FormGroup = ({ title, children, errorMessage }) => (
  <div className="input-pair row">
    <div className={`col-md-4 input-pair_title${errorMessage ? ' error' : ''}`}>
      {title}
    </div>
    <div className="col-md-6 input-container">
      {React.Children.map(children, (child) => {
        const className = `${child.props.className}${
          errorMessage ? ' error-border' : ''
        }`;
        return React.cloneElement(child, { className });
      })}
      {errorMessage && <div className="error">{errorMessage}</div>}
    </div>
  </div>
);

FormGroup.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  errorMessage: PropTypes.string,
};

FormGroup.defaultProps = {
  errorMessage: '',
};

export default FormGroup;
