/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';

class ContainedDatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: props.startDate,
      endDate: props.endDate,
    };
  }

  onFocusChange = (calendarFocused) => {
    this.setState(() => ({ calendarFocused }));
  };

  onDatesChange = (change) => {
    const { startDate, endDate } = change;
    this.setState({ startDate, endDate });

    this.props.onDatesChange(change);
  };

  render() {
    return (
      <DateRangePicker
        startDate={this.state.startDate}
        startDateId="start_date"
        endDate={this.state.endDate}
        endDateId="end_date"
        onDatesChange={this.onDatesChange}
        focusedInput={this.state.calendarFocused}
        onFocusChange={this.onFocusChange}
        noBorder
        hideKeyboardShortcutsPanel
        numberOfMonths={1}
        isOutsideRange={() => false}
      />
    );
  }
}

ContainedDatePicker.propTypes = {
  onDatesChange: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(moment),
  endDate: PropTypes.instanceOf(moment),
};

ContainedDatePicker.defaultProps = {
  startDate: moment().subtract(1, 'day'),
  endDate: moment(),
};

export default ContainedDatePicker;
