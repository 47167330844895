/* eslint-disable no-underscore-dangle */
import Parse from 'parse';
import { handleError } from './auth';
import AppToaster from '../utils/AppToaster';
import { pushToDataLayer } from './tagManager';

export const setCurrentWorkingTemplate = (objectId) => ({
  type: 'TEMPLATES_EDIT_SET_CURRENT_WORKING_TEMPLATE',
  objectId,
});

export const setInitialStateForTemplate = (template) => ({
  type: 'TEMPLATES_EDIT_SET_INITIAL_STATE',
  template,
});

export const setContractDataForTemplate = (templateObjectId, contractData) => ({
  type: 'TEMPLATES_SET_CONTRACT_DATA',
  templateObjectId,
  contractData,
});

export const setChangesForTemplate = (templateObjectId, changes) => ({
  type: 'TEMPLATES_EDIT_SET_CHANGES',
  templateObjectId,
  changes,
});

export const setEditCellItem = (editCellItem) => (dispatch) =>
  dispatch({
    type: 'TEMPLATES_SET_EDIT_CELL_ITEM',
    editCellItem,
  });

export const setHeaderChangesForTemplate = (identifier, value) => (
  dispatch,
  getState,
) => {
  try {
    const { templatesEdit } = getState();
    const ids = identifier.split('-');
    const template = { ...templatesEdit[ids[0]] };
    template.contractData = template.contractData.map((section) => {
      if (section.groupType !== 'header') {
        return section;
      }
      if (section.objectId === ids[1]) {
        if (ids.length === 3) {
          return {
            ...section,
            [ids[2]]: value,
          };
        }
        return {
          ...section,
          data: section.data.map((sectionObject) => {
            if (sectionObject.objectId === ids[2]) {
              if (ids.length === 4) {
                return {
                  ...sectionObject,
                  [ids[3]]: value,
                };
              }
              return {
                ...sectionObject,
                cellItems: sectionObject.cellItems.map((cellItem, index) => {
                  if (parseInt(ids[3], 10) !== index) {
                    return cellItem;
                  }
                  if (ids.length === 5) {
                    // these are arrays so we do not check for a key
                    return value;
                  }
                  return cellItem.map((cellItemLine) => {
                    if (cellItemLine.objectId === ids[4]) {
                      if (ids.length === 6) {
                        return {
                          ...cellItemLine,
                          [ids[5]]: value,
                        };
                      }
                    }
                    return cellItemLine;
                  });
                }),
              };
            }
            return sectionObject;
          }),
        };
      }
      return section;
    });
    dispatch(setInitialStateForTemplate(template));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const setBodyChangesForTemplate = (identifier, value) => (
  dispatch,
  getState,
) => {
  try {
    const { templatesEdit } = getState();
    const ids = identifier.split('-');
    const template = { ...templatesEdit[ids[0]] };
    template.contractData = template.contractData.map((section) => {
      if (section.groupType !== 'body') {
        return section;
      }
      if (section.objectId === ids[1]) {
        if (ids.length === 3) {
          return {
            ...section,
            [ids[2]]: value,
          };
        }
        return {
          ...section,
          data: section.data.map((sectionObject) => {
            if (sectionObject.objectId === ids[2]) {
              if (ids.length === 4) {
                return {
                  ...sectionObject,
                  [ids[3]]: value,
                };
              }
              return {
                ...sectionObject,
                cellItems: sectionObject.cellItems.map((cellItem) => {
                  if (cellItem.objectId === ids[3]) {
                    if (ids.length === 5) {
                      return {
                        ...cellItem,
                        [ids[4]]: value,
                      };
                    }
                    return {
                      ...cellItem,
                      detailItems: cellItem.detailItems.map((detailItem) => {
                        if (detailItem.objectId === ids[4]) {
                          if (ids.length === 6) {
                            return {
                              ...detailItem,
                              [ids[5]]: value,
                            };
                          }
                        }
                        return detailItem;
                      }),
                    };
                  }
                  return cellItem;
                }),
              };
            }
            return sectionObject;
          }),
        };
      }
      return section;
    });
    dispatch(setInitialStateForTemplate(template));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const setShowWatermarkToolbarValue = (template, show) => ({
  type: 'TEMPLATE_EDIT_SET_SHOW_WATERMARK_TOOLBAR',
  template,
  show,
});

export const setShowWatermarkToolbar = (show) => (dispatch, getState) => {
  const { templatesEdit } = getState();
  const { currentWorkingId } = templatesEdit;
  dispatch(setShowWatermarkToolbarValue(currentWorkingId, show));
};

export const setScale = (scale) => ({
  type: 'TEMPLATE_EDIT_SET_SCALE',
  scale,
});

export const setShowLinkMeasureSheetItems = (
  show,
  key,
  singleSelect,
  title,
  showCustomProduct = false,
) => ({
  type: 'TEMPLATE_EDIT_SET_SHOW_MEASURE_SHEET_ITEMS',
  show,
  key,
  singleSelect,
  title,
  showCustomProduct,
});

export const setCategoryFilter = (category) => ({
  type: 'TEMPLATE_EDIT_SET_CATEGORY_FILTER',
  category,
});

export const setSubCategoryFilter = (subCategory) => ({
  type: 'TEMPLATE_EDIT_SET_SUBCATEGORY_FILTER',
  subCategory,
});

export const setMeasureSheetItems = (items) => ({
  type: 'TEMPLATE_SET_MEASURE_SHEET_ITEMS',
  items,
});

export const setAllMeasureSheetItems = (items) => ({
  type: 'TEMPLATE_SET_ALL_MEASURE_SHEET_ITEMS',
  items,
});

export const setMeasureSheetItemsTotal = (itemTotal) => ({
  type: 'TEMPLATE_SET_MEASURE_SHEET_ITEM_TOTAL',
  itemTotal,
});

export const startFetchLinkedMeasureSheetItems = ({
  limit,
  skip,
  category,
  subCategory,
}) => async (dispatch, getState) => {
  dispatch(setAllMeasureSheetItems([]));
  dispatch(setMeasureSheetItems([]));
  dispatch(setMeasureSheetItemsTotal());

  const { auth } = getState();
  const { offices = [] } = auth;
  const query = new Parse.Query('SSMeasureSheetItem');
  query.select(['image', 'subCategory', 'itemName', 'orderNumber', 'category']);
  query.equalTo('category', category);
  query.containedIn('includedOffices', offices);
  if (subCategory) {
    query.equalTo('subCategory', subCategory);
  }
  query.ascending('category');
  query.descending('orderNumber');
  const totalRecords = await query.count();
  query.skip(skip);
  query.limit(limit);
  const result = await query.find();
  await dispatch(
    setMeasureSheetItems(
      result.map((item) => {
        return item.toJSON();
      }),
    ),
  );

  const allItemsQuery = query;
  allItemsQuery.skip(0);
  allItemsQuery.limit(totalRecords);
  const allItems = await allItemsQuery.find();
  await dispatch(
    setAllMeasureSheetItems(
      allItems.map((item) => {
        return item.toJSON();
      }),
    ),
  );

  await dispatch(setMeasureSheetItemsTotal(totalRecords));
};

export const setDraggableType = (draggableType) => ({
  type: 'TEMPLATE_SET_DRAGGABLE_TYPE',
  draggableType,
});

export const setViewSelection = (viewSelection, name) => (dispatch) => {
  dispatch(
    pushToDataLayer({
      event: 'documentView',
      eventCategory: 'Documents',
      eventAction: 'DocumentView',
      eventLabel: name,
    }),
  );

  return dispatch({
    type: 'TEMPLATE_SET_VIEW_SELECTION',
    viewSelection,
  });
};

export const startSyncPlaceholders = (objectIds = [], bodySection) => async (
  dispatch,
) => {
  try {
    if (!objectIds.length || !bodySection) {
      return;
    }
    const { cellItems = [] } = bodySection.toJSON() || {};
    const params = {
      sourceObjectIds: objectIds,
      cells: cellItems,
    };
    const message = await Parse.Cloud.run('add_missing_placeholders', params);
    AppToaster.show({ message, timeout: 3000 });
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const onTemplateScroll = (scrollEventData) => ({
  type: 'ON_TEMPLATE_SCROLL',
  scrollEventData,
});

export const setIsDragging = (dragging = false) => ({
  type: 'TEMPLATE_SET_IS_DRAGGING',
  payload: dragging,
});

export const setValidLinkedCount = (newCount) => ({
  type: 'SET_VALID_LINKED_COUNT',
  newCount,
});
