/* eslint-disable react/no-deprecated */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import { NumberGroup } from '../FormGroup';
import { startUpdateConfig } from '../../actions/auth';
import Panel from '../Panel';
import TitleButton from '../TitleButton';
import Copy from '../AppSettings/Copy';
import { showDeleteConfirmation } from '../../actions/deleteConfirmation';
import { pushToDataLayer } from '../../actions/tagManager';

export class GreenSky extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dealer: props.greenSky.dealer || '',
      enabled: this.props.greenSky.enabled,
    };
  }

  componentDidMount() {
    const {
      setCrumbs,
      setButtons,
      location: { pathname },
    } = this.props;
    const crumb = { title: 'GreenSky', link: pathname };
    setCrumbs([crumb]);
    setButtons(
      <TitleButtons
        onClearClick={this.askForClearPermission}
        onCopyClicked={this.onCopyClicked}
        onSaveClicked={this.onSaveClicked}
      />,
    );
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      dealer: newProps.greenSky.dealer || '',
    });
  }

  componentWillUnmount() {
    const { setCrumbs, setButtons } = this.props;
    setCrumbs([]);
    setButtons();
  }

  onEnabledChanged = (checked) => {
    this.setState({ enabled: checked });
    if (!checked) {
      const unset = Object.keys(this.state).reduce(
        (unset2, key) => ({ ...unset2, [key]: '' }),
        {},
      );
      this.setState({ ...unset });
      this.props.pushToDataLayer({
        event: 'integrationEvent',
        eventCategory: 'Integration',
        eventAction: 'Disable',
        eventLabel: 'GreenSky',
      });
    } else {
      this.props.pushToDataLayer({
        event: 'integrationEvent',
        eventCategory: 'Integration',
        eventAction: 'Enable',
        eventLabel: 'GreenSky',
      });
    }
  };

  askForClearPermission = () => {
    this.props.showDeleteConfirmation({
      message: `Are you sure you want to clear this integration ?`,
      title: 'Clear Integration ?',
      onConfirm: () => {
        this.onClearClick();
      },
    });
  };

  onClearClick = () => {
    this.onEnabledChanged(false);
  };

  onDealerNumberChanged = (value) => {
    this.setState({ dealer: parseInt(value, 10) });
  };

  onCopyClicked = () => {
    this.setState({ showCopy: true });
  };

  onSaveClicked = () => {
    this.props.startUpdateConfig({ greenSkyConfig: this.state });
  };

  render() {
    return (
      <>
        <Copy
          title="Copy GreenSky Settings"
          show={this.state.showCopy}
          warning="Warning! This will overwrite your current GreenSky settings"
          configKeys={['greenSkyConfig']}
          onClose={() => this.setState({ showCopy: false })}
        />
        <div className="default-page-padding">
          <Panel title="GreenSky">
            <div>
              <NumberGroup
                title="Dealer Number"
                value={this.state.dealer}
                placeholder="Dealer Number"
                onChange={this.onDealerNumberChanged}
              />
            </div>
          </Panel>
        </div>
      </>
    );
  }
}

GreenSky.propTypes = {
  greenSky: PropTypes.shape({
    dealer: PropTypes.number,
  }),
  startUpdateConfig: PropTypes.func.isRequired,
};

GreenSky.defaultProps = {
  greenSky: {
    dealer: '',
  },
};

const TitleButtons = ({ onCopyClicked, onSaveClicked, onClearClick }) => (
  <>
    <TitleButton
      variant="warning"
      onClick={() => onClearClick()}
      title="Clear"
    />
    <TitleButton
      variant="primary"
      onClick={() => onCopyClicked({ source: 'copy' })}
      title="Copy"
    />
    <TitleButton
      variant="success"
      onClick={() => onSaveClicked({ source: 'save' })}
      title="Save"
    />
  </>
);

TitleButtons.propTypes = {
  onCopyClicked: PropTypes.func.isRequired,
  onSaveClicked: PropTypes.func.isRequired,
  ...layoutContextPropTypes,
};

const mapStateToProps = ({ auth: { config } }) => ({
  greenSky: config.greenSkyConfig,
});

const mapDispatchToProps = (dispatch) => ({
  startUpdateConfig: (updates) => dispatch(startUpdateConfig(updates)),
  pushToDataLayer: (variablesForLayer) =>
    dispatch(pushToDataLayer(variablesForLayer)),
  showDeleteConfirmation: (params) => dispatch(showDeleteConfirmation(params)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(GreenSky),
);
