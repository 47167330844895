export default (state = {}, action) => {
  switch (action.type) {
    case 'MAIN_MENU_SET_OPEN_KEYS':
      return { openKeys: action.openKeys };
    case 'MAIN_MENU_SET_STATE':
      return { ...state, mainMenuState: action.mainMenuState };
    default:
      return state;
  }
};
