/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { Menu, MenuItem, Classes } from 'leap-menu-item';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import BodyGroup from './Models/BodyGroup';
import BodyCellItem from './Models/BodyCellItem';
import ImageObject from './Models/ImageObject';
import HeaderSection from './Models/HeaderSection';
import HeaderGroup from './Models/HeaderGroup';
import HeaderCell from './Models/HeaderCell';
import SignatureItem from './Models/SignatureItem';
import SignatureAdditionalLine from './Models/SignatureAdditionalLine';
import { pushToDataLayer } from '../../actions/tagManager';

const onTouchEnd = (onTransitionEnd) => {
  try {
    onTransitionEnd({
      propertyName: 'transform',
    });
  } catch (error) {
    console.log('error', error);
  }
};

const DraggableCell = ({
  containerKey,
  index,
  title,
  onSelected,
  disabled,
}) => {
  return disabled ? (
    <MenuItem text={title} disabled={disabled} />
  ) : (
    <Draggable
      disabled={disabled}
      draggableId={`new-${containerKey}`}
      index={index}
    >
      {(draggableProvided, snapshot) => {
        return (
          <div
            onTouchEnd={() => {
              onTouchEnd(draggableProvided.draggableProps.onTransitionEnd);
            }}
            className={snapshot.isDragging ? 'cell-draw-menu-item' : ''}
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            {...draggableProvided.dragHandleProps}
          >
            <MenuItem
              text={title}
              onTouchEnd={() => {
                onTouchEnd(draggableProvided.draggableProps.onTransitionEnd);
              }}
              onTouchStart={() => onSelected(containerKey)}
              onMouseDown={() => onSelected(containerKey)}
              disabled={disabled}
            />
            {draggableProvided.placeholder}
          </div>
        );
      }}
    </Draggable>
  );
};

class CellToolDrawer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { droppableType: 'new', isOpen: false };
  }

  componentDidUpdate() {
    const { isOpen } = this.state;
    if (isOpen) {
      this.props.pushToDataLayer({
        event: 'contractSideDrawerEvent',
        eventCategory: 'Documents',
        eventAction: 'Side Drawer Open',
        eventLabel: 'CellToolDrawer',
      });
    }
  }

  onCellSelected = (cellType) => {
    this.setState({ droppableType: cellType });
  };

  render() {
    const { isOpen } = this.state;

    const hasBodySection =
      this.props.templateValues && this.props.templateValues.hasBodySection();
    return (
      <div style={{ overflow: 'visible', height: 0 }}>
        <button
          type="button"
          className={`cell-tool-drawer__expand${isOpen ? '_open' : '_closed'}`}
          onClick={() => this.setState({ isOpen: true })}
        >
          <i className="fas fa-arrow-left" />
        </button>
        <div
          className={`cell-tool-drawer${isOpen ? ' show_left' : ' hide_right'}`}
        >
          <div className="cell-tool-drawer__header">
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              Cell Tool Drawer
            </div>
            <button
              type="button"
              className="cell-tool-drawer__collapse"
              onClick={() => this.setState({ isOpen: false })}
            >
              <i className="fas fa-arrow-right" />
            </button>
          </div>
          <Droppable droppableId="safe-no-drop">
            {(safeNoDropProvided) => (
              <div
                ref={safeNoDropProvided.innerRef}
                {...safeNoDropProvided.droppableProps}
              >
                <Droppable
                  droppableId={`new-${this.state.droppableType}`}
                  isDropDisabled
                >
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      <Menu className={Classes.ELEVATION_1}>
                        <Menu.Divider title="Header" />
                        <DraggableCell
                          containerKey="header-root"
                          index={0}
                          title="Container"
                          onSelected={this.onCellSelected}
                        />
                        <DraggableCell
                          containerKey={HeaderSection.ContainerKey}
                          index={1}
                          title="Section"
                          onSelected={this.onCellSelected}
                        />
                        <DraggableCell
                          containerKey={HeaderGroup.ContainerKey}
                          index={2}
                          title="Group"
                          onSelected={this.onCellSelected}
                        />
                        <DraggableCell
                          containerKey={HeaderCell.ContainerKey}
                          index={3}
                          title="Line Item"
                          onSelected={this.onCellSelected}
                        />
                        <Menu.Divider title="Body" />
                        <DraggableCell
                          containerKey="body-root"
                          index={4}
                          title="Container"
                          onSelected={this.onCellSelected}
                          id="body_container"
                          disabled={hasBodySection}
                        />
                        <DraggableCell
                          containerKey={BodyGroup.ContainerKey}
                          index={5}
                          title="Section"
                          onSelected={this.onCellSelected}
                        />
                        <DraggableCell
                          containerKey={BodyCellItem.ContainerKey}
                          index={6}
                          title="Line Item"
                          onSelected={this.onCellSelected}
                        />
                        <DraggableCell
                          containerKey={BodyCellItem.DetailContainerKey}
                          index={7}
                          title="Detail Line Item"
                          onSelected={this.onCellSelected}
                        />
                        {this.props.editCellItem &&
                          this.props.editCellItem.cellType ===
                            'imagePicker' && (
                            <DraggableCell
                              containerKey={ImageObject.ContainerKey}
                              index={8}
                              title="Image Icon"
                              onSelected={this.onCellSelected}
                            />
                          )}
                        <Menu.Divider title="Signatures" />
                        <DraggableCell
                          containerKey="signature-root"
                          index={8}
                          title="Container"
                          onSelected={this.onCellSelected}
                          id="signature_container"
                        />
                        <DraggableCell
                          containerKey={SignatureItem.ContainerKey}
                          index={10}
                          title="Capture"
                          onSelected={this.onCellSelected}
                        />
                        <DraggableCell
                          containerKey={SignatureAdditionalLine.ContainerKey}
                          index={11}
                          title="Note"
                          onSelected={this.onCellSelected}
                        />
                      </Menu>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            )}
          </Droppable>
        </div>
      </div>
    );
  }
}
const defaultEdit = {};

const mapStateToProps = ({ templatesEdit = defaultEdit }) => ({
  templateValues: templatesEdit[templatesEdit.currentWorkingId],
  editCellItem: templatesEdit.editCellItem,
});

const mapDispatchToProps = (dispatch) => ({
  pushToDataLayer: (variablesForLayer) =>
    dispatch(pushToDataLayer(variablesForLayer)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CellToolDrawer);
