import Parse from 'parse';
import { isEqual } from 'lodash';
import { handleError } from './auth';
import { hideLoader, showLoader } from './loading';
import { showSuccessAlert, hideAlert } from './alert';
import { pushToDataLayer } from './tagManager';
import UserObject from '../Models/UserObject';

export const addUsers = (items) => ({
  type: 'ADD_USERS',
  items,
});

export const setUsers = (items) => ({
  type: 'SET_USERS',
  items,
});

export const setUserCount = (count) => ({
  type: 'SET_USERS_COUNT',
  count,
});

export const startFetchUsers = () => async (dispatch, getState) => {
  try {
    const query = new Parse.Query(Parse.User);
    const count = await query.count();
    const userState = getState().users;
    dispatch(setUserCount(count));

    const items = userState.items || [];
    if (items.length === count) {
      return;
    }
    query.select([
      'lastLoginDate',
      'nameFirst',
      'nameLast',
      'isActive',
      'username',
      'selectedOffice.name',
    ]);
    query.descending('createdAt');
    query.limit(count);
    const results = await query.find();
    dispatch(setUsers(results.map((item) => item.toJSON())));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const searchText = (text) => ({
  type: 'SET_USERS_SEARCH_TEXT',
  text,
});

export const setSearchText = (text) => (dispatch) => dispatch(searchText(text));

export const pagination = (page, limit) => ({
  type: 'SET_USERS_PAGINATION',
  page,
  limit,
});

export const setPagination = (page, limit) => (dispatch) =>
  dispatch(pagination(page, limit));

export const activeFilter = (isActiveFilter) => ({
  type: 'SET_USERS_ACTIVE_FILTER',
  isActiveFilter,
});

export const setIsActiveFilter = (isActiveFilter) => (dispatch) =>
  dispatch(activeFilter(isActiveFilter));

export const updateUser = (user) => ({
  type: 'UPDATE_USER',
  user,
});

export const startActivateUser = (userId, isActive) => async (dispatch) => {
  try {
    if (isActive) {
      dispatch(
        pushToDataLayer({
          event: 'userActivated',
          eventCategory: 'Users',
          eventAction: 'Enable',
        }),
      );
    } else {
      dispatch(
        pushToDataLayer({
          event: 'userDeactivated',
          eventCategory: 'Users',
          eventAction: 'Disable',
        }),
      );
    }
    await Parse.Cloud.run('activateUser', {
      userId,
      isActive,
    });
    const query = new Parse.Query(Parse.User);
    query.select([
      'lastLoginDate',
      'nameFirst',
      'nameLast',
      'isActive',
      'username',
      'selectedOffice.name',
    ]);
    query.equalTo('objectId', userId);
    const user = await query.first();
    dispatch(updateUser(user.toJSON()));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const setUserToEdit = (userToEdit) => ({
  type: 'SET_USER_TO_EDIT',
  userToEdit,
});

export const getPermissionGroups = async () => {
  const roleQuery = new Parse.Query(Parse.Role);
  roleQuery.equalTo('type', 'Group');
  roleQuery.notEqualTo('availableContacts', true);
  const permissionGroups = await roleQuery.find();
  return permissionGroups;
};

export const getUserPermissionGroups = async (userId) => {
  const permissionGroups = await getPermissionGroups();

  const findUsers = permissionGroups.map((group) => {
    const groupUserQuery = group.getUsers().query();
    groupUserQuery.equalTo('objectId', userId);
    return groupUserQuery.find();
  });

  const getUserQueriesResponse = await Promise.all(findUsers);

  const response = permissionGroups
    .map((group, i) => ({
      ...group.toJSON(),
      users: getUserQueriesResponse[i].map((parseUser) => parseUser.toJSON()),
    }))
    .map(({ name, title, users, objectId }) => ({
      title,
      name,
      objectId,
      hasUser: users.length,
    }))
    .filter((group) => group.hasUser);

  return response;
};

export const startFetchUser = (objectId) => async (dispatch) => {
  try {
    dispatch(setUserToEdit(undefined));
    const user = await Parse.Cloud.run('fetch_user', { objectId });
    const permissionGroups = await getUserPermissionGroups(objectId);
    dispatch(
      setUserToEdit({
        ...user,
        allowedOffices: [
          ...user.allowedOffices.map((office) => office.toJSON()),
        ],
        selectedRoles: permissionGroups.map((g) => g.objectId),
        selectedOffice: user.selectedOffice.toJSON(),
      }),
    );
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const exportUsers = () => async (dispatch) => {
  try {
    dispatch(showLoader('Exporting ... '));
    const result = await Parse.Cloud.run('exportUsers');
    dispatch(
      showSuccessAlert({
        title: 'Success !',
        message: result,
        onConfirm: () => {
          dispatch(hideAlert());
        },
      }),
    );
  } catch (e) {
    dispatch(handleError(e));
  } finally {
    dispatch(hideLoader());
  }
};

export const resetUsers = () => ({
  type: 'RESET_USERS',
});

export const saveUser = async (original, changes) => {
  const keys = [
    'placeholders',
    'nameFirst',
    'nameLast',
    'username',
    'phoneNumber',
    'licenseNumber',
    'additionalMinimumAmount',
    'identifier',
    'searchableTimeCustomer',
    'canSubmitCreditApps',
    'isActive',
    'isManager',
    'apiCredentials',
  ];
  const updates = {
    objectId: changes.objectId,
  };
  keys.forEach((key) => {
    if (original[key] !== changes[key]) {
      updates[key] = changes[key];
    }
  });
  const { allowedOffices = [], selectedOffice, password, email } = changes;

  const allowedChange = !isEqual(allowedOffices, original.allowedOffices);
  if (allowedChange) {
    updates.allowedOffices = allowedOffices.map(({ objectId }) => {
      const office = new Parse.Object('Office');
      office.id = objectId;
      return office.toPointer();
    });
  }

  if (original.selectedOffice.objectId !== selectedOffice.objectId) {
    const office = new Parse.Object('Office');
    office.id = selectedOffice.objectId;
    updates.selectedOffice = office.toPointer();
  }
  if (password) {
    updates.password = password;
  }
  if (email) {
    updates.email = email;
  }
  const result = await Parse.Cloud.run('UpdateUser', updates);
  return result;
};

export const newUserParams = (changes) => {
  const { selectedOffice } = changes;
  return {
    ...changes,
    office: selectedOffice.objectId,
  };
  // {
  //   "username": "wmatthews@leaptodigital.com",
  //   "password": "Matthews456",
  //   "email": "wmatthews@leaptodigital.com",
  //   "nameFirst": "Wauker",
  //   "nameLast": "Matthews",
  //   "phoneNumber": "555-555-5555",
  //   "licenseNumber": "",
  //   "office": "mVxyj5OdiA",
  //   "apiCredentials": [],
  //   "allowedOffices": null
  // }
};

export const clearUserPermissions = async (userId) => {
  const user = new Parse.User();
  user.id = userId;
  const permissionGroups = await getPermissionGroups();

  permissionGroups.map((group) => {
    group.getUsers().remove(user);
    return group;
  });
  await Parse.Object.saveAll(permissionGroups);
};

export const setUserToRoles = async ({ userId, selectedRoles }) => {
  const user = new Parse.User();
  user.id = userId;
  const permissionGroups = await getPermissionGroups();
  selectedRoles.map((permissionId) => {
    const group = new Parse.Role();
    group.id = permissionId;
    group.getUsers().add(user);
    return group;
  });
  await Parse.Object.saveAll(permissionGroups);
};

export const updateUserPermissions = async ({ userId, selectedRoles }) => {
  const user = new Parse.User();
  user.id = userId;
  const permissionGroups = await getPermissionGroups();
  permissionGroups.forEach((group) => {
    group.getUsers().remove(user);
    if (selectedRoles.indexOf(group.id) > -1) {
      group.getUsers().add(user);
    }
  });
  await Parse.Object.saveAll(permissionGroups);
};

export const startUpdateUser = (update, callback) => async (dispatch) => {
  try {
    dispatch(setUserToEdit(update));
    const { selectedRoles, ...changes } = update;
    const { objectId } = changes;
    if (objectId) {
      const original = new UserObject();
      original.id = objectId;
      await original.fetch();
      const result = await saveUser(original.toJSON(), changes);
      if (selectedRoles) {
        await updateUserPermissions({ userId: objectId, selectedRoles });
      }
      const json = result.toJSON();
      const userUpdate = {
        ...json,
        selectedRoles,
      };
      await dispatch(updateUser(userUpdate));
      callback(userUpdate);
      return;
    }
    const result = await Parse.Cloud.run('signupUser', newUserParams(update));
    const json = result.toJSON();
    await dispatch(resetUsers());
    callback(json);
  } catch (e) {
    dispatch(handleError(e));
  } finally {
    dispatch(hideLoader());
  }
};

export const startQueryUsers = (params) => async (dispatch) => {
  try {
    const { isActiveFilter, limit, skip, searchTerm } = params;

    let mainQuery = new Parse.Query(Parse.User);
    const query = new Parse.Query(Parse.User);

    const currentUser = Parse.User.current();
    const company = currentUser.get('company');

    query.equalTo('company', company);
    query.select([
      'lastLoginDate',
      'nameFirst',
      'nameLast',
      'isActive',
      'username',
      'selectedOffice.name',
    ]);
    if (isActiveFilter) {
      query.equalTo('isActive', isActiveFilter === 2);
    }

    if (searchTerm) {
      const nameFirst = new Parse.Query(Parse.User);
      nameFirst.contains('nameFirstLowerCase', searchTerm.toLowerCase());

      const nameLast = new Parse.Query(Parse.User);
      nameLast.contains('nameLastLowerCase', searchTerm.toLowerCase());

      mainQuery = Parse.Query.and(Parse.Query.or(nameLast, nameFirst), query);
    } else {
      mainQuery = query;
    }
    mainQuery.ascending('nameLast');
    mainQuery.addAscending('nameFirst');
    const count = await mainQuery.count();

    dispatch(setUserCount(count));
    mainQuery.limit(limit);
    mainQuery.skip(skip);

    const items = await mainQuery.find();

    dispatch(
      setUsers(
        items.map((item) => {
          const lastLoginDate = item.get('lastLoginDate');
          const nameFirst = item.get('nameFirst');
          const nameLast = item.get('nameLast');
          const isActive = item.get('isActive');
          const username = item.get('username');
          const selectedOffice = item.get('selectedOffice');
          return {
            id: item.id,
            objectId: item.id,
            lastLoginDate,
            nameFirst,
            nameLast,
            isActive,
            username,
            selectedOffice: {
              name: selectedOffice ? selectedOffice.get('name') : 'n/a',
            },
          };
        }),
      ),
    );
  } catch (e) {
    dispatch(handleError(e));
  }
};
