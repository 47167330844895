/* eslint-disable import/no-named-as-default */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { withLayoutContext, layoutContextPropTypes } from '../../../Layout';
import { startUpdateConfig } from '../../../../actions/auth';
import FormGroup, { SwitchGroup, TextGroup } from '../../../FormGroup';
import APIDropDown from '../../APIDropDown';
import { randomKey } from '../../../../utils/utils';
import TemplateSending from '../TemplateSending';
import Panel from '../../../Panel';
import { checkForErrors } from '../Validation/Validator';
import { validateAll } from '../Validation/CustomOptions';
import { showErrorAlert, hideAlert } from '../../../../actions/alert';
import icons from '../../../../utils/icons';

const getIconName = (iconName = '') =>
  iconName
    .toUpperCase()
    .split('-')
    .slice(1)
    .join(' ');
const defaultIcon = { icon: 'fa-angle-right', web: 'fa fa-angle-right' };

export class CustomOptionEdit extends React.Component {
  constructor(props) {
    super(props);
    const icon = this.props.mainMenuOptions.find(
      ({ objectId }) => objectId === this.props.match.params.id,
    );
    this.state = {
      ...props.settings,
      icon: icon
        ? icons.find((option) => option.icon === icon.icon)
        : defaultIcon,
      showIconsModal: false,
      errors: validateAll(props.settings),
    };
  }

  componentDidMount() {
    const { setCrumbs, location: { pathname = '' } = {} } = this.props;
    const { title } = this.state;
    const crumb = { title: `${title} Sending`, anchor: pathname };
    setCrumbs([crumb]);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      ...newProps.settings,
      icon: newProps.settings.icon
        ? icons.find((option) => option.icon === newProps.settings.icon)
        : defaultIcon,
      errors: validateAll(newProps.settings),
    });
  }

  componentWillUnmount() {
    const { setCrumbs } = this.props;
    setCrumbs([]);
  }

  onSaveClicked = () => {
    if (checkForErrors(this.state.errors)) {
      this.props.showErrorAlert({
        title: 'Invalid Values',
        message: 'You must correct all validation errors before saving',
        onConfirm: this.props.hideAlert,
      });
      return;
    }

    const { errors, showIconsModal, icon = defaultIcon, ...state } = this.state;
    const mainMenuOptions = [...this.props.mainMenuOptions];
    let added = false;
    // If the item is there then updated it
    let updatedOptions = mainMenuOptions.map((option) => {
      if (option.objectId === this.props.match.params.id) {
        added = true;
        return { ...state, icon: icon.icon, webIcon: icon.web };
      }
      return option;
    });
    // If it's not there then add it to the array
    if (!added) {
      updatedOptions.unshift({
        ...state,
        icon: icon.icon,
        webIcon: icon.web,
        objectId: this.props.match.params.id,
      });
    }
    // update the order numbers based on their indexs
    updatedOptions = updatedOptions.map((option, index) => ({
      ...option,
      order: index,
    }));
    // Save to server
    this.props.startUpdateConfig({ mainMenuOptions: updatedOptions });
  };

  onValueChanged = ({ key, value }) => {
    if (key === 'title') {
      const newState = {
        ...this.state,
        [key]: value,
      };
      const errors = validateAll(newState);
      this.setState({
        [key]: value,
        errors,
      });
      return;
    }
    if (key === 'icon') {
      this.setState(() => ({ icon: value }));
      return;
    }
    const { uploadSettings } = this.state;
    const updatedSettings = { ...uploadSettings };
    updatedSettings[key] = value;
    const errors = validateAll({
      ...this.state,
      uploadSettings: updatedSettings,
    });
    this.setState({
      uploadSettings: updatedSettings,
      errors,
    });
  };

  onSelectIconsClick = (isOpening) => {
    this.setState(() => ({ showIconsModal: isOpening }));
  };

  settingsSection = () => (
    <Panel title={`${this.state.title} Settings`}>
      <Modal
        show={this.state.showIconsModal}
        onHide={() => this.onSelectIconsClick(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Main Menu Icon Select</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-wrap">
            {icons.map(({ icon, web }) => (
              <button
                key={web}
                className="p-2 btn"
                type="button"
                onClick={() => {
                  this.onValueChanged({ key: 'icon', value: { icon, web } });
                  this.onSelectIconsClick(false);
                }}
              >
                <i className={`${web} fa-2x`} />
              </button>
            ))}
          </div>
        </Modal.Body>
      </Modal>
      <div>
        <TextGroup
          title="Main Menu Title"
          value={this.state.title}
          onChange={(value) => this.onValueChanged({ key: 'title', value })}
          errorMessage={this.state.errors.title}
        />
        <FormGroup title="Main Menu Icon">
          <Button
            onClick={() => this.onSelectIconsClick(true)}
            style={{ textAlign: 'left' }}
          >
            {this.state.icon ? (
              <div className="d-flex align-items-center">
                <i className={`${this.state.icon.web} fa-2x`} />
                &nbsp;
                {getIconName(this.state.icon.icon)}
              </div>
            ) : (
              'None Selected'
            )}
          </Button>
        </FormGroup>
        <FormGroup title="APIs">
          <APIDropDown
            selectedOptions={this.state.uploadSettings.endpoints}
            onChange={(value) =>
              this.onValueChanged({ key: 'endpoints', value })
            }
          />
        </FormGroup>
        <SwitchGroup
          title="Fill Empty White Space"
          checked={this.state.uploadSettings.fillEmptyWhiteSpace}
          onChange={(value) =>
            this.onValueChanged({ key: 'fillEmptyWhiteSpace', value })
          }
        />
        {this.state.uploadSettings.fillEmptyWhiteSpace && (
          <TextGroup
            title="Empty White Space Note"
            value={this.state.uploadSettings.emptyWhiteSpaceNote}
            placeholder="Empty White Space Note"
            onChange={(value) =>
              this.onValueChanged({ key: 'emptyWhiteSpaceNote', value })
            }
          />
        )}
        <SwitchGroup
          title="Use Email Links"
          checked={this.state.uploadSettings.useBrochures}
          onChange={(value) =>
            this.onValueChanged({ key: 'useBrochures', value })
          }
        />
        <SwitchGroup
          title="Show Verify Email Alert"
          checked={!this.state.uploadSettings.disableVerifyEmail}
          onChange={(value) =>
            this.onValueChanged({ key: 'disableVerifyEmail', value: !value })
          }
        />
        <SwitchGroup
          title="Require Email"
          checked={!this.state.uploadSettings.disableRequireEmail}
          onChange={(value) =>
            this.onValueChanged({ key: 'disableRequireEmail', value: !value })
          }
        />
        <SwitchGroup
          title="Require Phone"
          checked={!this.state.uploadSettings.disableRequirePhone}
          onChange={(value) =>
            this.onValueChanged({ key: 'disableRequirePhone', value: !value })
          }
        />
        <SwitchGroup
          title="Disable Review Button"
          checked={this.state.uploadSettings.disableReviewButton}
          onChange={(value) =>
            this.onValueChanged({ key: 'disableReviewButton', value })
          }
        />
        <SwitchGroup
          title="Disable Print Button"
          checked={this.state.uploadSettings.disablePrintButton}
          onChange={(value) =>
            this.onValueChanged({ key: 'disablePrintButton', value })
          }
        />
      </div>
    </Panel>
  );

  render() {
    return (
      <TemplateSending
        settingsSection={this.settingsSection}
        onValueChanged={this.onValueChanged}
        onSaveClicked={this.onSaveClicked}
        title={this.state.title}
        {...this.state.uploadSettings}
        errors={this.state.errors}
        showCopyButton={false}
        copyKey=""
      />
    );
  }
}

CustomOptionEdit.defaultProps = {
  mainMenuOptions: [],
  settings: {
    title: '',
    objectId: randomKey(10),
    order: 0,
    uploadSettings: {
      disableRequireEmail: false,
      disableRequirePhone: false,
      disableVerifyEmail: false,
      disableReviewButton: false,
      disablePrintButton: false,
      leadPerfection: {
        fileName: '',
        fileDescription: '',
        backupRecipients: [],
      },
      salesforce: {
        fileName: '',
        fileDescription: '',
        backupRecipients: [],
      },
      webhook: {
        includePDF: false,
        url: '',
        authHeader: '',
      },
      endpoints: [],
      fillEmptyWhiteSpace: false,
      emptyWhiteSpaceNote: '',
      useBrochures: false,
      useVerifyEmail: false,
      from: '',
      subjectLine: '',
      recipients: [],
      ccRecipients: [],
      bccRecipients: [],
      emailHTML: '',
      emailFileName: '',
      fileName: '',
    },
  },
};

export const mainMenuOptionPropType = PropTypes.shape({
  title: PropTypes.string,
  objectId: PropTypes.string.isRequired,
  order: PropTypes.number.isRequired,
  uploadSettings: PropTypes.shape({
    disableRequireEmail: PropTypes.bool,
    disableRequirePhone: PropTypes.bool,
    disableVerifyEmail: PropTypes.bool,
    disableReviewButton: PropTypes.bool,
    disablePrintButton: PropTypes.bool,
    leadPerfection: PropTypes.shape({
      fileName: PropTypes.string,
      fileDescription: PropTypes.string,
      backupRecipients: PropTypes.arrayOf(PropTypes.string),
    }),
    salesforce: PropTypes.shape({
      fileName: PropTypes.string,
      fileDescription: PropTypes.string,
      backupRecipients: PropTypes.arrayOf(PropTypes.string),
    }),
    webhook: PropTypes.shape({
      includePDF: PropTypes.bool,
      url: PropTypes.string,
      authHeader: PropTypes.string,
    }),
    endpoints: PropTypes.arrayOf(PropTypes.string),
    fillEmptyWhiteSpace: PropTypes.bool,
    emptyWhiteSpaceNote: PropTypes.string,
    useBrochures: PropTypes.bool,
    useVerifyEmail: PropTypes.bool,
    from: PropTypes.string,
    subjectLine: PropTypes.string,
    recipients: PropTypes.arrayOf(PropTypes.string),
    ccRecipients: PropTypes.arrayOf(PropTypes.string),
    bccRecipients: PropTypes.arrayOf(PropTypes.string),
    emailHTML: PropTypes.string,
    emailFileName: PropTypes.string,
    backupRecipients: PropTypes.arrayOf(PropTypes.string),
    fileName: PropTypes.string,
  }),
});

CustomOptionEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  startUpdateConfig: PropTypes.func.isRequired,
  settings: mainMenuOptionPropType,
  mainMenuOptions: PropTypes.arrayOf(mainMenuOptionPropType),
  showErrorAlert: PropTypes.func.isRequired,
  hideAlert: PropTypes.func.isRequired,
  ...layoutContextPropTypes,
};

const mapStateToProps = ({ auth: { config } }, props) => ({
  settings: (config.mainMenuOptions || []).find(
    ({ objectId }) => props.match.params.id === objectId,
  ),
  mainMenuOptions: config.mainMenuOptions || [],
});

const mapDispatchToProps = (dispatch) => ({
  startUpdateConfig: (updates) => dispatch(startUpdateConfig(updates)),
  showErrorAlert: (props) => dispatch(showErrorAlert(props)),
  hideAlert: () => dispatch(hideAlert()),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(CustomOptionEdit),
);
