import moment from 'moment';

export const defaultState = {
  objects: null,
  page: 1,
  limit: 10,
  count: 0,
  usersForDropDown: [],
  selectedOfficeId: 'all',
  dateFilter: {
    startDate: moment().subtract(1, 'month'),
    endDate: moment(),
    calendarFocused: null,
  },
  dateField: 'createdAt',
  selectedUser: {
    value: 'All',
    label: 'All',
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_CUSTOMER_COUNT':
      return {
        ...state,
        count: action.count,
      };
    case 'SET_CUSTOMERS':
      return {
        ...state,
        objects: action.objects,
      };
    case 'RESET_CUSTOMERS':
      return {
        ...defaultState,
      };
    case 'SET_RESEND_CONFIRM':
      return {
        ...state,
        showResendConfirm: action.showResendConfirm,
        onResendConfirm: action.onResendConfirm,
        onResendClose: action.onResendClose,
        resendCreatedBy: action.createdBy,
      };
    case 'SET_USERS_FOR_DROP_DOWN':
      return {
        ...state,
        usersForDropDown: action.usersForDropDown,
      };
    case 'FETCH_ANALYTIC_OBJECT':
      return {
        ...state,
        analyticObject: action.object,
      };
    case 'OPEN_LOGS_MODAL':
      return {
        ...state,
        showLogsModal: true,
      };
    case 'HIDE_LOGS_MODAL':
      return {
        ...state,
        showLogsModal: false,
      };
    case 'SET_CUSTOMER_SELECTED_OFFICE_ID':
      return {
        ...state,
        selectedOfficeId: action.selectedOfficeId,
      };
    case 'SET_CUSTOMER_DATE_FILTER':
      return {
        ...state,
        dateFilter: action.value,
      };
    case 'SET_CUSTOMER_DATE_FIELD':
      return {
        ...state,
        dateField: action.value,
      };
    case 'SET_CUSTOMER_SELECTED_USER':
      return {
        ...state,
        selectedUser: action.value,
      };
    case 'SET_CUSTOMER_LIMIT':
      return {
        ...state,
        limit: action.value,
      };
    case 'SET_CUSTOMER_PAGE':
      return {
        ...state,
        page: action.value,
      };
    default:
      return state;
  }
};
