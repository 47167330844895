/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DebounceInput } from 'react-debounce-input';

const MSICell = ({
  value,
  columnKey,
  type,
  width,
  activeColumns,
  widths,
  children,
  onChange,
  onContextMenu,
}) => (
  <div
    className={`priceguide__msi-cell${
      activeColumns[columnKey] ? '_hidden' : ''
    }`}
    style={{ width: width || widths[columnKey] }}
    onContextMenu={onContextMenu}
  >
    {children && children}
    {!children && (
      <DebounceInput
        type={type}
        minLength={0}
        debounceTimeout={300}
        onChange={onChange}
        value={value}
      />
    )}
  </div>
);

MSICell.propTypes = {
  value: PropTypes.string,
  columnKey: PropTypes.string,
  type: PropTypes.oneOf(['text']),
  activeColumns: PropTypes.shape({
    settings: PropTypes.bool.isRequired,
    additionalDetails: PropTypes.bool.isRequired,
    placeholders: PropTypes.bool.isRequired,
    showToggle: PropTypes.bool.isRequired,
    image: PropTypes.bool.isRequired,
    category: PropTypes.bool.isRequired,
    subCategory: PropTypes.bool.isRequired,
    subSubCategories: PropTypes.bool.isRequired,
    name: PropTypes.bool.isRequired,
    note: PropTypes.bool.isRequired,
    measurementType: PropTypes.bool.isRequired,
    offices: PropTypes.bool.isRequired,
    formulaId: PropTypes.bool.isRequired,
    formula: PropTypes.bool.isRequired,
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onChange: PropTypes.func,
  width: PropTypes.number,
  onContextMenu: PropTypes.func,
};

MSICell.defaultProps = {
  value: '',
  columnKey: undefined,
  type: 'text',
  children: undefined,
  onChange: undefined,
  width: undefined,
  onContextMenu: undefined,
};

const mapStateToProps = ({ priceGuide2: { activeColumns, columnWidths } }) => ({
  activeColumns,
  widths: columnWidths.items,
});

export default connect(mapStateToProps)(MSICell);
