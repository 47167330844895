import Parse from 'parse';
import { handleError } from './auth';
import { showSuccessAlert, hideAlert } from './alert';
import AppToaster from '../utils/AppToaster';
import { pushToDataLayer } from './tagManager';
import { showLoader, hideLoader } from './loading';

export const startSaveOffices = (offices = []) => async (dispatch) => {
  if (!offices.length) {
    return;
  }
  try {
    dispatch(showLoader('saving offices...'));
    const needsSaved = offices.filter((office) => office.dirtyKeys().length);
    needsSaved.forEach((office) => {
      dispatch(
        pushToDataLayer({
          event: 'officeEvent',
          eventCategory: 'Offices',
          eventAction: 'Edit',
          eventLabel: office.get('name'),
        }),
      );
    });

    await Parse.Object.saveAll(needsSaved);
    dispatch(
      showSuccessAlert({
        title: 'Success!',
        message: `${needsSaved.length} office${
          needsSaved.length > 1 ? 's' : ''
        } saved successfully!`,
        onConfirm: () => dispatch(hideAlert()),
      }),
    );
  } catch (e) {
    dispatch(handleError(e));
  } finally {
    dispatch(hideLoader());
  }
};

export const setOffices = (offices) => ({
  type: 'SET_OFFICES',
  offices,
});

export const startCreateOffice = (params) => async (dispatch, getState) => {
  try {
    const {
      auth: { company },
    } = getState();
    dispatch(showLoader('saving office ...'));

    const result = await Parse.Cloud.run('createNewOffice', params);

    const offices = await company.getCompanyOffices();

    dispatch(setOffices(offices));
    dispatch(
      showSuccessAlert({
        title: 'Success!',
        message: result,
        onConfirm: () => dispatch(hideAlert()),
      }),
    );
  } catch (e) {
    dispatch(handleError(e));
  } finally {
    dispatch(hideLoader());
  }
};

export const deleteOffice = (officeId) => ({
  type: 'OFFICE_DELETE',
  officeId,
});

export const startDeleteOffice = (params, callback) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch(showLoader('deleting office ...'));

    const message = await Parse.Cloud.run('deleteOffice', params);

    const {
      auth: { offices },
    } = getState();

    const [selected] = offices.filter(
      (office) => office.id === params.officeId,
    );
    if (selected) {
      dispatch(
        pushToDataLayer({
          event: 'officeEvent',
          eventCategory: 'Offices',
          eventAction: 'Delete',
          eventLabel: selected.get('name'),
        }),
      );
    }

    AppToaster.show({ message, timeout: 3000 });
    await dispatch(deleteOffice(params.officeId));
    callback();
  } catch (e) {
    dispatch(handleError(e));
  } finally {
    dispatch(hideLoader());
  }
};
