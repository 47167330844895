/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-cycle */
import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import { LayoutContext } from '../../Layout';
import DeletedDocumentsListSection from './DeletedDocumentsListSection';

import Paginator from '../../Misc/Paginator';
import useGetDeletedDocuments from './useGetDeletedDocuments';

function ContractRestorePage(props) {
  const { match: { params: { type } = {} } = {} } = props;
  const { pathname } = useLocation();
  const { crumbs, setCrumbs } = useContext(LayoutContext);

  const [page, setPage] = useState(1);

  const [limit, setLimit] = useState(10);

  const [deletedDocuments, setDeletedDocuments] = useState([]);

  useEffect(() => {
    const crumb = {
      title: 'Select Deleted Documents To Restore',
      link: pathname,
    };
    if (!_.isEqual(crumbs[crumbs.length - 1], crumb)) {
      setCrumbs([...crumbs, crumb]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crumbs]);

  useGetDeletedDocuments({ page, type, limit, setDeletedDocuments });
  return (
    <div className="center-stage-outer">
      <div
        id="estimates-center-stage"
        style={{ flex: 1 }}
        className="stage h-100 d-flex flex-column"
      >
        <DeletedDocumentsListSection deletedDocuments={deletedDocuments} />
        <Paginator
          noCount
          page={page}
          limit={limit}
          onLimitChanged={({ value }) => {
            setLimit(value);
          }}
          pageRange={5}
          onPageClicked={(value) => {
            setPage(value);
          }}
        />
      </div>
    </div>
  );
}

export default ContractRestorePage;
