/* eslint-disable import/no-cycle */
import Parse from 'parse';
import { logout, handleError } from './auth';
import { resetUsers } from './users';
import { resetResources } from './resources';
import { resetPriceGuide } from './priceGuide2';
import { resetPermissions } from './permissions';
import { resetPackages } from './packages';
import { resetFinanceOptions } from './financeOptions';
import { resetEmails } from './emails';
import { resetCustomers } from './customers';
import { resetAvailableContacts } from './availableContacts';
import { resetAccount } from './account';
import { resetPaymentCapture } from './paymentCapture';
import { showLoader, hideLoader } from './loading';
import { showSuccessAlert, hideAlert } from './alert';
import { setSelectedOffice } from '../data/repo/navigation';

export const startMoveUserToCompany = (companyObjectId) => async (dispatch) => {
  try {
    dispatch(showLoader('Changing company...'));
    if (companyObjectId && companyObjectId.length) {
      await Parse.Cloud.run('moveUserToCompany', {
        userObjectId: Parse.User.current().id,
        companyObjectId,
      });
      await setSelectedOffice(null);
      dispatch(resetUsers());
      dispatch(resetResources());
      dispatch(resetPriceGuide());
      dispatch(resetPermissions());
      dispatch(resetPackages());
      dispatch(resetFinanceOptions());
      dispatch(resetEmails());
      dispatch(resetCustomers());
      dispatch(resetAvailableContacts());
      dispatch(resetAccount());
      dispatch(resetPaymentCapture());
      dispatch(logout());
    }
  } catch (e) {
    dispatch(handleError(e));
  } finally {
    dispatch(hideLoader());
  }
};

export const createCompanyResponse = (result) => ({
  type: 'CREATE_COMPANY',
  result,
});

export const startCreateNewCompany = (params) => async (dispatch) => {
  try {
    const fetchedThru = [];

    if (
      await Parse.Cloud.run('check_user_by', { field: 'username', ...params })
    ) {
      fetchedThru.push('username');
    }
    if (await Parse.Cloud.run('check_user_by', { field: 'email', ...params })) {
      fetchedThru.push('email');
    }

    if (fetchedThru.length > 0) {
      dispatch(createCompanyResponse({ fetchedThru }));
    } else {
      try {
        dispatch(showLoader('Creating new company...'));
        const company = await Parse.Cloud.run('createNewCompany', params);
        dispatch(createCompanyResponse({ company }));
        // Unexpected error during company creation.
      } catch (e) {
        dispatch(handleError(e));
      } finally {
        dispatch(hideLoader());
      }
    }
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const startCopyPriceGuide = (params) => async (dispatch) => {
  try {
    dispatch(showLoader('Copying prices...'));
    const response = await Parse.Cloud.run('copymsiv2', params);

    dispatch(
      showSuccessAlert({
        title: 'Copy Price Guide',
        message: response,
        onConfirm: () => {
          dispatch(hideAlert());
        },
      }),
    );
  } catch (e) {
    dispatch(handleError(e));
  } finally {
    dispatch(hideLoader());
  }
};

export const startCopyData = ({ descriptor, ...params }) => async (
  dispatch,
) => {
  try {
    dispatch(showLoader('Copying data...'));
    const response = await Parse.Cloud.run(descriptor, params);
    dispatch(
      showSuccessAlert({
        title: 'Copy Data',
        message: response,
        onConfirm: () => {
          dispatch(hideAlert());
        },
      }),
    );
  } catch (e) {
    dispatch(handleError(e));
  } finally {
    dispatch(hideLoader());
  }
};

export const startEmailUsageReport = (params) => async (dispatch) => {
  try {
    dispatch(showLoader('Emailing usage report...'));
    await Parse.Cloud.run('email_usage_report', params);
  } catch (e) {
    dispatch(handleError(e));
  } finally {
    dispatch(hideLoader());
  }
};

export const packageCategoriesFetched = (packageCategories) => ({
  type: 'FETCH_PACKAGE_CATEGORIES',
  packageCategories,
});

export const startFetchPackageCategories = (companyId) => async (dispatch) => {
  try {
    const categories = await Parse.Cloud.run('unique_package_categories', {
      companyId,
    });
    dispatch(packageCategoriesFetched(categories));
  } catch (e) {
    dispatch(handleError(e));
  }
};
