/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import PropTypes from 'prop-types';
import React from 'react';
import { MenuItem } from 'leap-menu-item';
import ContractObject from '../../Models/ContractObject';
import BodyCellItem from '../../Models/BodyCellItem';

export const getMenuCellTypes = (updatedObject) => {
  switch (updatedObject.cellType) {
    case 'header':
    case 'signatures':
      return BodyCellItem.CellTypes;
    default:
      return updatedObject.cellTypes;
  }
};

const onCellTypeChange = (updatedObject, newCellType) => {
  updatedObject.cellType = newCellType;

  switch (newCellType) {
    case 'textXLong':
      updatedObject.inputType = 'default';
      break;

    default:
      break;
  }
};

const SettingMenuCellType = ({
  updatedObject,
  setTemplateState,
  secureCaptureEnabled,
  contractObject,
}) => (
  <MenuItem
    text="Cell Type"
    label={BodyCellItem.cellTypeLabelForCellType(updatedObject.cellType)}
    shouldDismissPopover={false}
  >
    {getMenuCellTypes(updatedObject, contractObject).map((cellType) => {
      const label = BodyCellItem.cellTypeLabelForCellType(cellType);
      let enabled = true;
      if (
        cellType === 'creditCardCell' ||
        cellType === 'bankAccountCell' ||
        cellType === 'securePaymentCaptureAll'
      ) {
        enabled = secureCaptureEnabled;
      }
      return (
        <MenuItem
          key={cellType}
          text={label}
          disabled={!enabled}
          shouldDismissPopover={false}
          active={updatedObject.cellType === cellType}
          icon={updatedObject.cellType === cellType ? 'dot' : 'blank'}
          onClick={() => {
            // eslint-disable-next-line no-param-reassign
            onCellTypeChange(updatedObject, cellType);
            setTemplateState(contractObject);
          }}
        />
      );
    })}
  </MenuItem>
);

export default SettingMenuCellType;

SettingMenuCellType.propTypes = {
  contractObject: PropTypes.instanceOf(ContractObject).isRequired,
  updatedObject: PropTypes.instanceOf(BodyCellItem).isRequired,
  setTemplateState: PropTypes.func.isRequired,
  secureCaptureEnabled: PropTypes.bool.isRequired,
};
