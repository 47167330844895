/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import { startEmailUsageReport } from '../../actions/admin';
import { DateRangePickerFormGroup, ButtonGroup } from '../FormGroup';
import Panel from '../Panel';

export class EmailUsageReportPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {
      setCrumbs,
      location: { pathname },
    } = this.props;

    const crumb = { title: 'Email Usage Report', link: pathname };
    setCrumbs([crumb]);
  }

  componentWillUnmount() {
    const { setCrumbs } = this.props;
    setCrumbs([]);
  }

  onDatesChange = ({ startDate, endDate }) => {
    const newState = { startDate, endDate };
    if (startDate && endDate) {
      newState.dateRangeError = undefined;
    }
    this.setState(() => newState);
  };

  onDateFocusChange = (focusedInput) => {
    this.setState(() => ({ focusedInput }));
  };

  onSubmit = () => {
    const { startDate, endDate } = this.state;
    const errors = {};
    if (!startDate || !endDate) {
      errors.dateRangeError = this.props.missingFieldMessage;
      if (!startDate && !endDate) {
        errors.errorMessage = 'Date range is required';
      } else if (!startDate) {
        errors.errorMessage = 'Start date is required';
      } else if (!endDate) {
        errors.errorMessage = 'End date is required';
      }

      this.setState(() => errors);
    } else {
      this.props.startEmailUsageReport({
        startDate: startDate.format(),
        endDate: endDate.format(),
      });
    }
  };

  render() {
    return (
      <div className="default-page-padding">
        <Panel title="Email Usage Report">
          <DateRangePickerFormGroup
            title="Date Range"
            errorMessage={this.state.dateRangeError}
          >
            <DateRangePicker
              startDate={this.state.startDate}
              startDateId="start_date"
              endDate={this.state.endDate}
              endDateId="end_date"
              onDatesChange={this.onDatesChange}
              focusedInput={this.state.focusedInput}
              onFocusChange={this.onDateFocusChange}
              isOutsideRange={(date) => date.isAfter(this.props.date, 'day')}
              minimumNights={0}
              numberOfMonths={1}
              showClearDates
              noBorder
              hideKeyboardShortcutsPanel
            />
          </DateRangePickerFormGroup>
          <ButtonGroup
            title=""
            buttonTitle="Submit"
            onClick={this.onSubmit}
            variant="success"
          />
        </Panel>
      </div>
    );
  }
}

EmailUsageReportPage.propTypes = {
  moment: PropTypes.instanceOf(moment),
  ...layoutContextPropTypes,
};

EmailUsageReportPage.defaultProps = {
  endDate: moment(),
};

const mapDispatchToProps = (dispatch) => ({
  startEmailUsageReport: (params) => dispatch(startEmailUsageReport(params)),
});

export default withLayoutContext(
  connect(undefined, mapDispatchToProps)(EmailUsageReportPage),
);
