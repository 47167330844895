/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { TextGroup, TagGroup } from '../../../FormGroup';
import Panel from '../../../Panel';

const MarketsharpSection = (props) => (
  <Panel title="Marketsharp">
    <div>
      <TextGroup
        title="File Name"
        value={props.fileName}
        placeholder="File Name"
        onChange={(value) => props.onChange({ key: 'fileName', value })}
        errorMessage={props.errors.fileName}
      />
      <TagGroup
        title="Backup Recipients"
        values={props.backupRecipients}
        placeholder="Add Email Addresses"
        onChange={(value) => props.onChange({ key: 'backupRecipients', value })}
        errorMessage={props.errors.backupRecipients}
      />
    </div>
  </Panel>
);

MarketsharpSection.propTypes = {
  fileName: PropTypes.string,
  backupRecipients: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    fileName: PropTypes.string,
    backupRecipients: PropTypes.string,
  }).isRequired,
};

MarketsharpSection.defaultProps = {
  fileName: '',
  backupRecipients: [],
};

export default MarketsharpSection;
