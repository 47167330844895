import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { LayoutContext } from '../Layout';
import { pushToDataLayer as pushToDataLayerAction } from '../../actions/tagManager';
import { startUpdateConfig as startUpdateConfigAction } from '../../actions/auth';
import { TextGroup } from '../FormGroup';
import Copy from '../AppSettings/Copy';
import Panel from '../Panel';
import TitleButton from '../TitleButton';
import { showDeleteConfirmation } from '../../actions/deleteConfirmation';

/**
 * @typedef {{
 *  dealerNumber: string,
 *  showCopy: boolean,
 * }} AquaState
 */

const AquaFinance = () => {
  const title = 'Aqua Finance';
  const { setButtons, setCrumbs } = useContext(LayoutContext);
  // Get config values from redux store
  const aqua = useSelector(({ auth: { config } }) => config.aqua);
  // Set dispatch instance and map actions to functions
  const dispatch = useDispatch();
  const [pushToDataLayer, startUpdateConfig, confirmClear] = [
    /** @param {import('../../actions/tagManager').AnalyticsEventData} payload */
    (payload) => dispatch(pushToDataLayerAction(payload)),
    (configUpdate) => dispatch(startUpdateConfigAction(configUpdate)),
    (params) => dispatch(showDeleteConfirmation(params)),
  ];
  // Create state object and destructure it
  const [{ dealerNumber, showCopy }, updateState] = useState({
    dealerNumber: '',
    showCopy: false,
  });
  // Create a setState function that mirrors the way class state works
  /** @param {Partial<AquaState>} newState */
  const setState = (newState) => {
    updateState(
      /** @param {AquaState} prevState */
      (prevState) => ({
        ...prevState,
        ...newState,
      }),
    );
  };

  // Set state from redux store values
  useEffect(() => {
    if (typeof aqua === 'object') {
      const { dealerNumber: _dealerNumber = '' } = aqua;
      setState({ dealerNumber: _dealerNumber });
    }
  }, [aqua]);

  const onCopyClicked = () => {
    setState({ showCopy: true });
  };

  const onSaveClicked = () => {
    // DB3 uses the enabled field, here, we set it true if there's a dealerNumber, false, if there's none
    startUpdateConfig({ aqua: { dealerNumber, enabled: !!dealerNumber } });
  };

  const onClearClick = () => {
    confirmClear({
      message: 'Are you sure you want to clear your Aqua Finance settings?',
      title: 'Clear Aqua Finance Settings',
      onConfirm: () => {
        setState({ dealerNumber: '' });
      },
    });
  };

  const buttons = (
    <TitleButtons
      onCopyClicked={onCopyClicked}
      onSaveClicked={onSaveClicked}
      onClearClick={onClearClick}
    />
  );

  // Add crumb and buttons to breadcrumb bar
  useEffect(() => {
    setCrumbs([{ title, link: '/aqua' }]);
    setButtons(buttons);
    return () => {
      setCrumbs([]);
      setButtons();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealerNumber, setCrumbs, setButtons]);

  /** @param {boolean} checked */
  const onDealerNumberChanged = (checked) => {
    setState({ dealerNumber: checked });
    if (!checked) {
      setState({ token: '' });
      pushToDataLayer({
        event: 'integrationEvent',
        eventCategory: 'Integration',
        eventAction: 'Disable',
        eventLabel: 'GAF QuickMeasure',
      });
    } else {
      pushToDataLayer({
        event: 'integrationEvent',
        eventCategory: 'Integration',
        eventAction: 'Enable',
        eventLabel: 'GAF QuickMeasure',
      });
    }
  };

  return (
    <div className="default-page-padding">
      <Copy
        title={`Copy ${title} Settings`}
        show={showCopy}
        warning={`Warning! This will overwrite your current ${title} settings`}
        configKeys={['aqua']}
        onClose={() => setState({ showCopy: false })}
      />
      <Panel title={title}>
        <div>
          <TextGroup
            title="Dealer Number"
            value={dealerNumber}
            placeholder="Dealer Number"
            onChange={onDealerNumberChanged}
          />
        </div>
      </Panel>
    </div>
  );
};

/**
 * @param {{
 *  onClearClick: () => void,
 *  onCopyClicked: () => void,
 *  onSaveClicked: () => void,
 * }} props
 */
const TitleButtons = ({ onClearClick, onCopyClicked, onSaveClicked }) => (
  <>
    <TitleButton variant="warning" onClick={onClearClick} title="Clear" />
    <TitleButton variant="primary" onClick={onCopyClicked} title="Copy" />
    <TitleButton variant="success" onClick={onSaveClicked} title="Save" />
  </>
);

TitleButtons.propTypes = {
  onClearClick: PropTypes.func.isRequired,
  onCopyClicked: PropTypes.func.isRequired,
  onSaveClicked: PropTypes.func.isRequired,
};

export default AquaFinance;
