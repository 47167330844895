import moment from 'moment';

export const filterByText = (
  docusigns,
  searchText = '',
  limit = 10,
  skip = 0,
) => {
  if (!docusigns) {
    return docusigns;
  }
  const filteredObjects = docusigns.filter(
    ({ customer, createdBy, createdAt, completed }) => {
      if (!searchText) {
        return true;
      }
      const search = searchText.toLowerCase();
      const customerName = `${customer.nameFirst} ${customer.nameLast}`;
      const usersName = `${createdBy.nameFirst} ${createdBy.nameLast}`;
      return (
        customerName.toLowerCase().indexOf(search) > -1 ||
        usersName.toLowerCase().indexOf(search) > -1
      );
    },
  );
  return {
    objects: filteredObjects,
    total: filteredObjects.length,
  };
};

export const filterByDateRange = (objects, startDate, endDate) => {
  if (!objects) {
    return objects;
  }
  return objects.filter(
    ({ createdAt }) =>
      !startDate ||
      !endDate ||
      (moment(createdAt).valueOf() >= startDate.valueOf() &&
        moment(createdAt).valueOf() <= endDate.valueOf()),
  );
};
