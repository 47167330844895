/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-cycle */
import React from 'react';
import { connect } from 'react-redux';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { setCreditApplication } from '../../../actions/creditApplications';
import { getListStyle } from '../../Templates/Utils';

export const initCreditAppCell = {
  apiKey: '',
  appKey: '',
  cellId: '',
  cellType: 'textWords',
  inputType: 'default',
  note: '',
  pickerOptions: [],
  placeholder: '',
  regex: '',
  required: true,
  title: 'line item',
  type: 'textWords',
};

export const creditAppCellTypes = [initCreditAppCell];

const CreditApplicationCellToolDrawer = (props) => (
  <>
    <div
      style={{
        display:
          props.application &&
          !props.application.get('partnerCreditApplication')
            ? 'block'
            : 'none',
        position: 'absolute',
        top: 100,
        right: 0,
        bottom: 0,
        backgroundColor: 'white',
        borderTopLeftRadius: 5,
      }}
    >
      <div
        style={{
          backgroundColor: '#2196F3',
          borderTopLeftRadius: 5,
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <p
          style={{
            width: 100,
            textAlign: 'center',
            color: 'white',
            margin: 0,
          }}
        >
          {' '}
          Cell Toll Drawer{' '}
        </p>
      </div>
      <Droppable droppableId="creditAppToolDrawer" isDropDisabled={false}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={getListStyle(
              snapshot.isDraggingOver,
              snapshot.isDraggingOver,
            )}
          >
            {creditAppCellTypes.map((lintItem, itemIndex) => (
              <Draggable
                // eslint-disable-next-line react/no-array-index-key
                key={`drawer-item-${itemIndex}`}
                draggableId={`drawer-item-${itemIndex}`}
                index={itemIndex}
              >
                {(draggableProvided, draggableSnapshot) => (
                  <div
                    className={
                      draggableSnapshot.isDragging ? 'cell-draw-menu-item' : ''
                    }
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.dragHandleProps}
                    {...draggableProvided.draggableProps}
                    style={{
                      ...draggableProvided.draggableProps.style,
                    }}
                    onMouseDown={(e) => {
                      draggableProvided.dragHandleProps.onMouseDown(e);
                    }}
                  >
                    <p
                      style={{
                        width: 100,
                        textAlign: 'center',
                        margin: 10,
                      }}
                    >
                      {' '}
                      {lintItem.title}
                    </p>
                  </div>
                )}
              </Draggable>
            ))}
          </div>
        )}
      </Droppable>
    </div>
  </>
);

const mapStateToProps = ({ creditApplications }) => ({
  commit: creditApplications.commit,
});
const mapDispatchToProps = (dispatch) => ({
  setApplication: (application) => dispatch(setCreditApplication(application)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreditApplicationCellToolDrawer);
