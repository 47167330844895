import React from 'react';
import PropTypes from 'prop-types';
import Select from '../../../../Leapstrap/Select';

const SalesForceFieldTypeDropDown = ({ onChange, value }) => {
  const options = [
    {
      value: 'string',
      label: 'Text',
    },
    {
      value: 'number',
      label: 'Number/Currency',
    },
    {
      value: 'boolean',
      label: 'Checkbox',
    },
    {
      value: 'date',
      label: 'Date',
    },
    {
      value: 'dateTime',
      label: 'DateTime',
    },
    {
      value: 'time',
      label: 'Time',
    },
  ];
  const selectedValue = options.filter((option) => option.value === value)[0];
  return (
    <Select
      onChange={(e) => {
        onChange(e ? e.value : undefined);
      }}
      value={selectedValue}
      options={options}
      isSearchable={false}
      isClearable={false}
    />
  );
};
SalesForceFieldTypeDropDown.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

SalesForceFieldTypeDropDown.defaultProps = {
  value: 'string',
};

export default SalesForceFieldTypeDropDown;
