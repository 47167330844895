/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ContextMenu, Menu, MenuItem } from '@blueprintjs/core';

const ResourceGroupCellContextMenu = ({ onCopyAll }) => {
  ResourceGroupCellContextMenu.propTypes = {
    onCopyAll: PropTypes.func.isRequired,
  };
  return (
    <Menu>
      <MenuItem onClick={onCopyAll} text="Import Entire Group" />
    </Menu>
  );
};

const onContextMenu = (e, props) => {
  e.preventDefault();
  const menu = <ResourceGroupCellContextMenu {...props} />;
  ContextMenu.show(menu, { left: e.clientX, top: e.clientY });
};

const ResourceGroupCell = ({
  thumbnailURL,
  name,
  onClick,
  onDelete,
  onCopyAll,
}) => {
  const [imgError, setImgError] = useState(false);

  const handleImgError = () => {
    setImgError(true);
  };

  return (
    <div
      onContextMenu={(e) => onContextMenu(e, { onCopyAll })}
      className="mask no-caption resources-cell card"
      onClick={onClick}
    >
      <div className="thumbnail-container w-100 h-100">
        <div
          style={{ cursor: 'pointer' }}
          className="img-thumbnail view view-first"
        >
          <img
            className={`m-auto h-100 ${imgError ? 'd-none' : 'd-block'}`}
            src={thumbnailURL}
            onError={handleImgError}
            alt="Thumbnail"
          />
          <div
            style={{ fontSize: '7rem' }}
            className={`h-100 w-100 ${imgError ? 'd-block' : 'd-none'}`}
          >
            <i className="fas fa-camera-retro text-muted" />
          </div>
          {onDelete && (
            <div className="mask no-caption">
              <div className="tools tools-bottom d-flex justify-content-center">
                <div style={{ width: 10 }} />
                <button type="button" onClick={onDelete}>
                  <i className="fas fa-trash-alt" />
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="caption">{name}</div>
      </div>
    </div>
  );
};

ResourceGroupCell.propTypes = {
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onCopyAll: PropTypes.func,
  thumbnailURL: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

ResourceGroupCell.defaultProps = {
  onDelete: null,
  onCopyAll: null,
};

export default ResourceGroupCell;
