import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

const geStatusIcon = (props) => {
  const { status } = props;
  switch (status) {
    case 'opened': {
      return {
        icon: '/images/openEmailIcon.png',
        title: 'Opened',
      };
    }
    case 'delivered': {
      return {
        icon: '/images/senticon.png',
        title: 'Sent',
      };
    }
    default:
      return {
        icon: '/images/notsenticon.png',
        title: 'Unknown',
      };
  }
};

const EmailsListSection = (props) => {
  const { emails } = props;
  return (
    <div className="list-section m-3">
      {emails.map((email) => {
        const id = `${email.objectId}`;
        const { mailGunStatus = {} } = email;
        const timeStamp = mailGunStatus.timestamp
          ? mailGunStatus.timestamp * 1000
          : email.createdAt;
        const statusData = geStatusIcon(email);
        return (
          <article
            id={id}
            key={id}
            className="email-estimate-list-item d-md-flex align-items-center list-line"
          >
            <div className="flex-1">
              <h1 className="email-estimate-list-item-title flex-1 d-flex">
                {email.customer ? (
                  <span className="list-line-header">
                    {`${email.customer.nameFirst} ${email.customer.nameLast}`}:
                  </span>
                ) : (
                  <span className="list-line-header">To:</span>
                )}
                {email.mgMessage.to.map((emailAddress) => (
                  <a
                    className="list-line-action-item"
                    href={`mailto:${emailAddress}`}
                  >
                    {emailAddress}
                  </a>
                ))}
              </h1>
              <span className="user-line-symbol">USER:</span>
              <span className="user-line-name">{`${email.user.nameFirst} ${email.user.nameLast}`}</span>
              <span className="user-line-date">
                {`${moment(timeStamp).format('MM/DD/YYYY h:mm a')}`}
              </span>
              {email.customer && (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/estimate_details/${email.customer.objectId}`}
                >
                  <i className="fas fa-external-link-alt ml-3" />
                </a>
              )}
              <p className="list-line-note">{email.subject} </p>
            </div>
            <div className="email-estimate-list-item-statusbox d-flex">
              <div className="d-flex flex-1 flex-column align-items-center">
                <div className="d-flex flex-1 justify-content-center align-items-center">
                  <img src={statusData.icon} alt="status" />
                </div>
                <span className="list-line-action-item">
                  {statusData.title}
                </span>
              </div>
              {email.attachment && (
                <div className="flex-1 d-flex flex-column align-items-center ml-50">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={email.attachment.url}
                    className="list-line-action-item"
                  >
                    <img src="/images/paperclip.png" alt="paperclip" />
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={email.attachment.url}
                    className="list-line-action-item"
                  >
                    Attachment
                  </a>
                </div>
              )}
            </div>
          </article>
        );
      })}
    </div>
  );
};

EmailsListSection.propTypes = {
  emails: PropTypes.arrayOf(
    PropTypes.shape({
      objectId: PropTypes.string.isRequired,
    }),
  ),
};

EmailsListSection.defaultProps = {
  emails: [],
};

const mapStateToProps = ({ estimatesPage }) => {
  return {
    items: estimatesPage.items || [],
  };
};

export default connect(mapStateToProps)(EmailsListSection);
