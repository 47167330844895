/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const MainAlert = (props) => {
  return props.alert.show ? (
    <SweetAlert
      success={props.alert.type === 'success'}
      warning={props.alert.type === 'warning'}
      danger={props.alert.type === 'error'}
      title={props.alert.title}
      onConfirm={props.alert.confirm}
    >
      <div
        className="main-alert-message"
        style={props.alert.style ? props.alert.style : null}
      >
        {props.alert.message}
      </div>
    </SweetAlert>
  ) : (
    ''
  );
};

MainAlert.propTypes = {
  alert: PropTypes.shape({
    show: PropTypes.bool,
    message: PropTypes.string,
  }).isRequired,
};

MainAlert.defaultProps = {};

const mapStateToProps = ({ alert }) => ({
  alert: alert || {},
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MainAlert);
