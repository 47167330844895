/* eslint-disable no-shadow */
/* eslint-disable react/forbid-prop-types */
/** @jsx jsx */
import { jsx } from '@emotion/core';
// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import HeaderCell from './HeaderCell';
import {
  setInitialStateForTemplate,
  setEditCellItem,
} from '../../../actions/templateEdit';
import { getItemStyle, getListStyle } from '../Utils';
import { setOnDragEndCallback } from '../../DropContext';
import ContractObject from '../Models/ContractObject';
import HeaderCellObject from '../Models/HeaderCell';
import HeaderItemGroup from '../Models/HeaderItemGroup';

const containerStyle = (border, widthPercent) => ({
  border: border ? '1px solid black' : undefined,
  padding: '2px',
  position: 'relative',
  height: '100%',
});

const HeaderGroup = ({
  cells,
  border,
  widthPercent,
  setTemplateState,
  editCellItem,
  setEditCellItem,
  templateValues,
  draggableType,
}) => {
  const droppableId = `${cells.getPath().join('-')}-${cells.objectId}-${
    HeaderCellObject.ContainerKey
  }`;
  setOnDragEndCallback(droppableId, (result) => {
    let destinationPath = result.destination.droppableId.split('-');
    destinationPath.pop();
    destinationPath.pop();
    destinationPath = destinationPath.map((index) => parseInt(index, 10));
    destinationPath.push(result.destination.index);
    const updatedContractObject = new ContractObject(templateValues.toJSON());
    if (result.source.droppableId === `new-${HeaderCellObject.ContainerKey}`) {
      const newItem = new HeaderCellObject();
      updatedContractObject.insertObjectAtPath(destinationPath, newItem, 0);
      setTemplateState(updatedContractObject);
      setEditCellItem(newItem);
      return;
    }
    let sourcePath = result.draggableId.split('-');
    sourcePath.pop();
    sourcePath = sourcePath.map((index) => parseInt(index, 10));
    if (sourcePath.every((value, index) => value === destinationPath[index])) {
      return; // same path
    }
    const sourceItem = updatedContractObject.getItemAtPath(sourcePath);
    const sourceContainerPath = [...sourcePath];
    sourceContainerPath.pop();
    const sourceContainer = updatedContractObject.getItemAtPath(
      sourceContainerPath,
    );
    updatedContractObject.deleteObjectAtPath(sourcePath);
    updatedContractObject.insertObjectAtPath(destinationPath, sourceItem, 0);
    if (!sourceContainer.length) {
      const sourceContainerParentPath = [...sourceContainerPath];
      sourceContainerParentPath.pop();
      const sourceContainerParent = updatedContractObject.getItemAtPath(
        sourceContainerParentPath,
      );
      if (
        sourceContainerParent.cellItems.every(
          (group = []) => group.length === 0,
        )
      ) {
        updatedContractObject.deleteObjectAtPath(sourceContainerParentPath);
      }
    }
    setTemplateState(updatedContractObject);
  });
  return (
    <Droppable
      droppableId={droppableId}
      isDropDisabled={draggableType !== HeaderCellObject.ContainerKey}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          css={{
            ...getListStyle(snapshot.isDraggingOver),
            ...containerStyle(border, widthPercent),
          }}
          {...provided.droppableProps}
        >
          {cells.cellItems.map((cell, index) => (
            <Draggable
              key={cell.objectId}
              draggableId={`${cell.getPath().join('-')}-${cell.objectId}`}
              index={index}
            >
              {(draggableProvided, draggableSnapshot) => {
                const component = (
                  <div
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    css={getItemStyle(
                      draggableSnapshot,
                      draggableProvided.draggableProps.style,
                      editCellItem.objectId === cell.objectId,
                    )}
                  >
                    <HeaderCell cell={cell} />
                    <div
                      {...draggableProvided.dragHandleProps}
                      css={{
                        ...draggableProvided.draggableProps.style,
                        position: 'absolute',
                        left: '0',
                        top: '0',
                        height: '50%',
                        maxHeight: '20px',
                        backgroundColor: 'transparent',
                        width: '100%',
                      }}
                    />
                    {draggableProvided.placeholder}
                  </div>
                );
                return component;
              }}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

HeaderGroup.propTypes = {
  templateValues: PropTypes.instanceOf(ContractObject).isRequired,
  cells: PropTypes.instanceOf(HeaderItemGroup),
  border: PropTypes.bool,
  widthPercent: PropTypes.number,
  setTemplateState: PropTypes.func.isRequired,
  setEditCellItem: PropTypes.func.isRequired,
  editCellItem: PropTypes.any,
  draggableType: PropTypes.string,
};

HeaderGroup.defaultProps = {
  cells: [],
  border: false,
  widthPercent: 100,
  editCellItem: {},
  draggableType: undefined,
};

const mapStateToProps = ({ templatesEdit = {} }) => ({
  editCellItem: templatesEdit.editCellItem,
  templateValues:
    templatesEdit[templatesEdit.currentWorkingId] || new ContractObject(),
  draggableType: templatesEdit.draggableType,
});

const mapDispatchToProps = (dispatch) => ({
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
  setEditCellItem: (item) => dispatch(setEditCellItem(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderGroup);
