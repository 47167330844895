import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import Select from '../../Leapstrap/Select';

const measureSheetItemCellTypes = [
  {
    value: 'textWords',
    label: 'Text (Words)',
  },
  {
    value: 'textSentence',
    label: 'Text (Sentence)',
  },
  {
    value: 'textParagraph',
    label: 'Text (Paragraph)',
  },
  {
    value: 'photos',
    label: 'Photos',
  },
];

const upChargeCellTypes = [
  {
    value: 'textWords',
    label: 'Text (Words)',
  },
  {
    value: 'textSentence',
    label: 'Text (Sentence)',
  },
  {
    value: 'textParagraph',
    label: 'Text (Paragraph)',
  },
];

const cellTypes = (isUpCharge) =>
  isUpCharge ? upChargeCellTypes : measureSheetItemCellTypes;

const CellTypesDropDown = (props) => {
  const { cellType, isUpCharge, onChange, color } = props;
  return (
    <Form.Group>
      <Form.Label>Cell Type</Form.Label>
      <Select
        name="cellType"
        onChange={(selected) => {
          const event = {
            currentTarget: { value: selected.value, name: 'cellType' },
          };
          onChange(event);
        }}
        options={cellTypes(isUpCharge)}
        color={color}
        value={cellTypes(isUpCharge).find((cType) => cType.value === cellType)}
      />
    </Form.Group>
  );
};
CellTypesDropDown.defaultProps = {
  isUpCharge: false,
  color: 'blue',
};
CellTypesDropDown.propTypes = {
  onChange: PropTypes.func.isRequired,
  isUpCharge: PropTypes.bool,
  cellType: PropTypes.string.isRequired,
  color: PropTypes.string,
};

export default CellTypesDropDown;
