/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-cycle */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { withLayoutContext } from '../Layout';
import EmailsFilterSection from './EmailsFilterSection';
import EmailsListSection from './EmailsListSection';
import {
  startFetchEmails,
  setSearchText /* , setDateRange */,
} from '../../actions/emails';
import Paginator from '../Misc/Paginator';
import { fetchUsersForDropDown } from '../../actions/customers';

class EmailsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      limit: 10,
      page: 1,
      startDate: moment()
        .subtract(30, 'days')
        .startOf('day'),
      endDate: moment().endOf('day'),
      userFilter: [],
    };
  }

  componentDidMount() {
    const {
      location: { pathname = '' } = {},
      fetchUsersForDropDown: fetchUsers,
      setCrumbs,
    } = this.props;
    fetchUsers();
    this.updateObjects();
    setCrumbs([
      { title: 'Estimates', link: '/estimates' },
      { title: 'Emails', link: pathname },
    ]);
  }

  onLimitChanged = (limit) => {
    this.setState({ page: 1, limit }, () => {
      this.updateObjects();
    });
  };

  onPageChanged = (page) => {
    this.setState({ page }, () => {
      this.updateObjects();
    });
  };

  updateObjects = () => {
    const {
      startDate,
      endDate,
      limit,
      page,
      userFilter,
      searchText,
    } = this.state;
    const skip = limit * (page - 1);
    this.props.startFetchEmails(
      limit,
      skip,
      startDate,
      endDate,
      userFilter,
      searchText,
    );
  };

  render() {
    return (
      <div className="default-page-padding">
        <div
          id="estimates-center-stage"
          style={{ flex: 1 }}
          className="stage h-100 d-flex flex-column"
        >
          <EmailsFilterSection
            filter={{
              startDate: this.state.startDate,
              endDate: this.state.endDate,
              limit: this.state.limit,
              page: this.state.page,
              userFilter: this.state.userFilter,
            }}
            onSearch={() => {
              this.updateObjects();
            }}
            onFilterUpdate={(update) => {
              this.setState({ ...update, page: 1 });
            }}
          />
          <EmailsListSection emails={this.props.emails} />
          <Paginator
            noCount
            page={this.state.page}
            limit={this.state.limit}
            onLimitChanged={({ value }) => this.onLimitChanged(value)}
            pageRange={5}
            onPageClicked={(value) => this.onPageChanged(value)}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ emails = {} }) => {
  const { emails: allEmails = [], searchText = '' } = emails;
  return {
    emails: allEmails.map((object) => ({
      ...object,
      user: object.user || { nameFirst: 'Leap' },
    })),
    searchText,
  };
};

const mapDispatchToProps = (dispatch) => ({
  // eslint-disable-next-line max-len
  startFetchEmails: (limit, skip, startDate, endDate, userFilter, searchText) =>
    dispatch(
      startFetchEmails(limit, skip, startDate, endDate, userFilter, searchText),
    ),
  setSearchText: (search) => dispatch(setSearchText(search)),
  fetchUsersForDropDown: () => dispatch(fetchUsersForDropDown()),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(EmailsPage),
);
