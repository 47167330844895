/* eslint-disable react/no-deprecated */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import qs from 'query-string';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import FormGroup, { ButtonGroup, SwitchGroup } from '../FormGroup';
import Panel from '../Panel';
import TitleButton from '../TitleButton';
import { startUpdateConfig } from '../../actions/auth';
import startAuthenticateHover from '../../actions/hover';
import { history } from '../../router';
import Copy from '../AppSettings/Copy';
import { showDeleteConfirmation } from '../../actions/deleteConfirmation';
import { pushToDataLayer } from '../../actions/tagManager';

const onAuthenticateClicked = () => {
  const clientId = process.env.REACT_APP_HOVER_CLIENT_ID;
  const redirectURL = process.env.REACT_APP_HOVER_REDIRECT_URL;
  window.location.assign(
    `https://hover.to/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectURL}`,
  );
};

export class Hover extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCopy: false,
      enabled: this.props.hover.enabled,
    };
  }

  componentDidMount() {
    const {
      setCrumbs,
      setButtons,
      location: { pathname },
    } = this.props;
    const crumb = { title: 'HOVER', link: pathname };
    setCrumbs([crumb]);
    setButtons(
      <>
        <TitleButton
          variant="warning"
          onClick={this.askForClearPermission}
          title="Clear"
        />
        <TitleButton
          variant="primary"
          onClick={this.onCopyClicked}
          title="Copy"
        />
      </>,
    );
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      enabled: newProps.hover.enabled,
    });
  }

  componentWillUnmount() {
    const { setCrumbs, setButtons } = this.props;
    setCrumbs([]);
    setButtons();
  }

  onEnabledChanged = (checked) => {
    this.setState({ enabled: checked });
    this.props.startUpdateConfig({
      hover: {
        ...this.props.hover,
        enabled: checked,
      },
    });
    if (!checked) {
      this.props.pushToDataLayer({
        event: 'integrationEvent',
        eventCategory: 'Integration',
        eventAction: 'Disable',
        eventLabel: 'Hover',
      });
    } else {
      this.props.pushToDataLayer({
        event: 'integrationEvent',
        eventCategory: 'Integration',
        eventAction: 'Enable',
        eventLabel: 'Hover',
      });
    }
  };

  askForClearPermission = () => {
    this.props.showDeleteConfirmation({
      message: 'Are you sure you want to clear this integration ?',
      title: 'Clear Integration ?',
      onConfirm: () => {
        this.onClearClick();
      },
    });
  };

  onClearClick = () => {
    this.onEnabledChanged(false);
  };

  onCopyClicked = () => {
    this.setState({ showCopy: true });
  };

  render() {
    const { code } = qs.parse(this.props.location.search);
    if (code) {
      this.props.startAuthenticateHover(code);
      history.push('hover');
    }
    return (
      <>
        <Copy
          title="Copy HOVER Settings"
          show={this.state.showCopy}
          warning="Warning! This will overwrite your current HOVER settings"
          configKeys={['hover']}
          onClose={() => this.setState({ showCopy: false })}
        />
        <div className="default-page-padding">
          <Panel title="HOVER">
            <SwitchGroup
              title="Enabled"
              checked={this.state.enabled}
              onChange={this.onEnabledChanged}
            />
            {this.state.enabled && (
              <div>
                <ButtonGroup
                  title="Authenticate"
                  variant="primary"
                  buttonTitle={
                    this.props.hover.access_token
                      ? 'Re-Authenticate'
                      : 'Authenticate'
                  }
                  onClick={onAuthenticateClicked}
                />
                <FormGroup title="Authenticated">
                  <div>
                    {this.props.hover.access_token ? (
                      <i className="fas fa-check-circle green" />
                    ) : (
                      <i className="fas fa-times-circle danger" />
                    )}
                  </div>
                </FormGroup>
              </div>
            )}
          </Panel>
        </div>
      </>
    );
  }
}

Hover.propTypes = {
  hover: PropTypes.shape({
    access_token: PropTypes.string,
  }),
  startAuthenticateHover: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  ...layoutContextPropTypes,
};

Hover.defaultProps = {
  hover: {
    access_token: '',
  },
};

const mapStateToProps = ({ auth: { config } }) => ({
  hover: config.hover,
});
const mapDispatchToProps = (dispatch) => ({
  startUpdateConfig: (update) => dispatch(startUpdateConfig(update)),
  startAuthenticateHover: (token) => dispatch(startAuthenticateHover(token)),
  pushToDataLayer: (variablesForLayer) =>
    dispatch(pushToDataLayer(variablesForLayer)),
  showDeleteConfirmation: (params) => dispatch(showDeleteConfirmation(params)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(Hover),
);
