/** @typedef {{
 *  fileName: string,
 *  endpoints: string[],
 *  uploadToMarketsharp: boolean,
 *  email: boolean,
 *  emailFileName: string,
 *  recipients: string[],
 *  ccRecipients: string[],
 *  bccRecipients: string[],
 *  subjectLine: string,
 *  emailBody: string,
 *  emailHTML: string,
 *  from: string,
 *  disclosure: string,
 *  backupRecipients: [],
 * }} UploadBase
 * @typedef {UploadBase | {
 *  password: string | null,
 * }} UploadCreditApp
 * @typedef {{
 *   title: string,
 *   inputType: string,
 *   order: number,
 *   required: boolean,
 *   id: string,
 * }} Result
 * @typedef {UploadBase | {
 *  saleResult: Result[],
 *  demoResult: Result[],
 * }} ResultsUpload
 * @typedef {{
 *  enabled: boolean,
 *  fromEmail: string,
 *  emailSubject: string,
 *  successRecipients: string[];
 *  successRedirectUrl: string;
 *  template: string;
 * }} CreditAppSending
 * @typedef {{
 *  allMainMenuOptions: [],
 *  loading?: boolean,
 *  user?: Parse.User,
 *  company?: import('../Models/CompanyModel'),
 *  apiKey?: string,
 *  offices?: import('../Models/Office')[],
 *  selectedOffice?: import('../Models/Office'),
 *  config?: {
 *    serviceFinance: {
 *     dealerNumber: string,
 *     isEnabled: boolean
 *    },
 *    creditAppUpload: UploadCreditApp,
 *    creditAppSending: CreditAppSending,
 *    contractVerifyUpload: UploadBase,
 *    proposalVerifyUpload: UploadBase,
 *    commissionFormulas: string[],
 *    brochures_: [],
 *    appColors: Record<string, string>,
 *    contractUpload: UploadBase,
 *    proposalUpload: UploadBase,
 *    priceFormulas: {
 *     id: string,
 *     formula: string,
 *     name: string,
 *     isVisible: boolean,
 *     isDefault: boolean,
 *     isResultsFormula: boolean,
 *     isResultsReport: boolean,
 *     isPriceGuide: boolean,
 *     isContract: boolean,
 *    }[],
 *    resultsUpload: ResultsUpload,
 *    categories_: [],
 *    newUserDefaults: {
 *     additionalMinimumAmount: number,
 *     canActivateUsers: boolean,
 *     canChangeSettings: boolean,
 *     canSubmitCreditApps: boolean,
 *     isActive: boolean,
 *     isAvailableToCall: boolean,
 *     isManager: boolean,
 *     licenseNumber: string,
 *     searchableTimeCustomer: number,
 *    },
 *    greenSkyConfig: { dealer: null | string },
 *    placeholders: { placeholder: string, replacement: string }[],
 *    company: import('../Models/CompanyModel'),
 *    configVersion: number,
 *    pitchBookVersion: number,
 *    priceGuideVersion: number,
 *    searchableTime: number,
 *    companyLogo: {
 *      __type: string,
 *      name: string,
 *      url: string,
 *      watermarkAlpha: number,
 *      watermarkFileSize: number,
 *      watermarkWidthPercent: number,
 *      createdAt: string,
 *      updatedAt: string,
 *      officesRelation: {
 *      ___type: string,
 *      className: string,
 *      }
 *    },
 *    ACL: {},
 *    appTitles: Record<string, string>,
 *    objectId: string,
 *    mainMenuOptions: {
 *      title: string,
 *      objectId: string,
 *      webIcon?: string,
 *    }[],
 *  },
 *  isCorporateAdmin?: boolean,
 *  groupMeGroups?: [],
 *  partnerLogo?: { _url: string } | undefined,
 *  favicon?: { _url: string, pageTitle: string } | undefined,
 *  partnerTags?: string[],
 *  plan?: import('./plan').PlanState,
 * }} AuthState */

/** @type {AuthState} */
export const defaultAuthState = {
  allMainMenuOptions: [],
};

export const validateConfig = (sourceConfig) => {
  const config = sourceConfig ? sourceConfig.toJSON() : [];
  config.mainMenuOptions = config.mainMenuOptions || [];
  return config;
};

export default (state = defaultAuthState, action) => {
  switch (action.type) {
    case 'LOGIN': {
      const { favicon: icon, pageTitle } = action.favicon;
      return {
        ...state,
        loading: false,
        user: action.user,
        company: action.user.get('company'),
        apiKey: action.user.get('company').get('apiKey'),
        offices: action.offices || [],
        selectedOffice: action.selectedOffice,
        config: validateConfig(action.config),
        isCorporateAdmin: action.isCorporateAdmin || false,
        groupMeGroups: [],
        partnerLogo: action.partnerLogo
          ? { url: action.partnerLogo._url }
          : undefined,
        favicon: icon ? { ...icon, pageTitle } : undefined,
        partnerTags: action.partnerTags || [],
        plan: action.plan,
      };
    }
    case 'SET_COMPANY':
      return {
        ...state,
        company: action.company,
      };
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.loading,
      };
    case 'LOGOUT': {
      let partnerLogo;
      if (action.partnerLogo && action.partnerLogo._url) {
        const { _url: url } = action.partnerLogo;
        partnerLogo = { url };
      } else if (state.config) {
        partnerLogo = state.config.companyLogo;
      }

      let favicon;
      if (action.favicon) {
        const { favicon: icon2, pageTitle2 } = action.favicon;
        favicon = { ...icon2, pageTitle: pageTitle2 };
      } else if (state.config) {
        favicon = state.config.favicon;
      }

      return {
        query: state.query,
        partnerLogo,
        favicon,
        config: { placeholders: [], waterMark: {} },
        offices: [],
      };
    }
    case 'SELECTED_OFFICE':
      return {
        ...state,
        selectedOffice: action.selectedOffice,
        config: action.config.toJSON(),
        groupMeGroups: state.groupMeGroups || [],
      };
    case 'SET_CUSTOMIZED_MENU':
      return {
        ...state,
        customMenuItem: action.customizedMenuItems,
      };
    case 'UPDATE_CONFIG_STATE':
      return {
        ...state,
        config: {
          ...state.config,
          ...action.updates,
        },
      };
    case 'SET_API_KEY':
      return {
        ...state,
        apiKey: action.apiKey,
      };
    case 'SET_GROUP_ME_GROUPS':
      return {
        ...state,
        groupMeGroups: action.groups || [],
      };
    case 'OFFICE_DELETE':
      return {
        ...state,
        offices: state.offices.filter(({ id }) => id !== action.officeId),
      };
    case 'SET_QUERY':
      return {
        ...state,
        query: action.query,
      };
    case 'SET_OFFICES':
      return {
        ...state,
        offices: action.offices,
      };
    case 'SET_ALL_MAIN_MENU_OPTIONS':
      return {
        ...state,
        allMainMenuOptions: action.options,
      };
    case 'UPDATE_IS_DOCUSIGN_AUTHORIZED':
      return {
        ...state,
        docuSignStatus: action.updates,
      };
    default:
      return state;
  }
};
