/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Popover } from 'leap-menu-item';
import onClickOutside from 'react-onclickoutside';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import TextShort from './TextShort';
import BodyCellItemObject from '../../Models/BodyCellItem';
import { setOnDragEndCallback } from '../../../DropContext';
import {
  setInitialStateForTemplate,
  setEditCellItem,
} from '../../../../actions/templateEdit';
import ContractObject from '../../Models/ContractObject';
import { getItemStyle, getListStyle } from '../../Utils';
import { interlace } from '../../../../utils/utils';
import { fontFamilyExists } from '../../FontSelect';

export const styleForTitle = (
  {
    contractTitle,
    titleFontSize,
    titleFontBold,
    titleFontUnderline,
    titleFontName,
    titleFontColor,
  },
  isHovering,
) => {
  const colorArray = titleFontColor ? titleFontColor.split('-') : [];
  const [r, g, b, a] = colorArray;
  return {
    display: contractTitle ? undefined : 'none',
    cursor: 'pointer',
    padding: '0px 5px',
    fontSize: titleFontSize,
    fontWeight: titleFontBold ? 900 : undefined,
    fontFamily: fontFamilyExists(titleFontName)
      ? titleFontName
      : 'HelveticaNeue',
    color: titleFontColor ? `rgba(${r},${g},${b},${a})` : undefined,
    textDecoration: titleFontUnderline ? 'underline' : undefined,
    opacity: isHovering ? '0.7' : undefined,
  };
};

export const updateDraggableCallback = (
  dropId,
  cellItem,
  setTemplateState,
  editCellItem,
) => {
  setOnDragEndCallback(dropId, (result) => {
    const contractObject = cellItem.getSourceObject();
    const updatedContractObject = new ContractObject(contractObject.toJSON());
    const isNew =
      result.draggableId === `new-${BodyCellItemObject.DetailContainerKey}`;

    let sourceItem;
    if (isNew) {
      sourceItem = new BodyCellItemObject();
      editCellItem(sourceItem);
    } else {
      let sourcePath = result.draggableId.split('-');
      sourcePath.pop();
      sourcePath.pop();
      sourcePath = sourcePath.map((index) => parseInt(index, 10));
      sourceItem = updatedContractObject.getItemAtPath(sourcePath);
    }

    if (!isNew) {
      const { parent } = sourceItem;
      const appOnlyItems = [];
      const bottomItems = [];
      const columnItems = [];
      parent.detailItems.forEach((obj, i) => {
        const { appOnly, cellType } = obj;
        if (appOnly) {
          appOnlyItems.push(i, obj);
          return;
        }
        if (cellType === 'textXLong') {
          bottomItems.push(obj);
          return;
        }
        columnItems.push(obj);
      });
      const leftItems = columnItems.filter((item, i) => i % 2 === 0);
      const rightItems = columnItems.filter((item, i) => i % 2 === 1);
      let sourceContainer = result.source.droppableId.split('-');
      sourceContainer.pop();
      sourceContainer.pop();
      sourceContainer.pop();
      sourceContainer = sourceContainer.map((i) => parseInt(i, 10)).pop();
      switch (sourceContainer) {
        case 0:
          leftItems.splice(result.source.index, 1);
          break;
        case 1:
          rightItems.splice(result.source.index, 1);
          break;
        default:
          bottomItems.splice(result.source.index, 1);
          break;
      }
      while (rightItems.length > leftItems.length) {
        leftItems.push(rightItems.pop());
      }
      parent.detailItems = [
        ...interlace(leftItems, rightItems),
        ...bottomItems,
      ];
      for (let i = 0; i < appOnlyItems.length; i += 2) {
        const appOnlyIndex = appOnlyItems[i];
        const object = appOnlyItems[i + 1];
        parent.detailItems.splice(appOnlyIndex, 0, object);
      }
    }

    let destinationPath = result.destination.droppableId.split('-');
    destinationPath.pop();
    destinationPath.pop();
    destinationPath.pop();
    destinationPath = destinationPath.map((index) => parseInt(index, 10));
    const destinationContainerIndex = destinationPath.pop();
    const destinationParent = updatedContractObject.getItemAtPath(
      destinationPath,
    );
    const { index: destinationIndex } = result.destination;
    const destinationAppOnlyItems = [];
    const destinationBottomItems = [];
    const destinationColumnItems = [];
    destinationParent.detailItems.forEach((obj, i) => {
      const { appOnly, cellType } = obj;
      if (appOnly) {
        destinationAppOnlyItems.push(i, obj);
        return;
      }
      if (cellType === 'textXLong') {
        destinationBottomItems.push(obj);
        return;
      }
      destinationColumnItems.push(obj);
    });
    const destinationLeftItems = destinationColumnItems.filter(
      (item, i) => i % 2 === 0,
    );
    const destinationRightItems = destinationColumnItems.filter(
      (item, i) => i % 2 === 1,
    );
    switch (destinationContainerIndex) {
      case 0:
        sourceItem.cellType =
          sourceItem.cellType === 'textXLong'
            ? 'textLong'
            : sourceItem.cellType;
        destinationLeftItems.splice(destinationIndex, 0, sourceItem);
        break;
      case 1:
        sourceItem.cellType =
          sourceItem.cellType === 'textXLong'
            ? 'textLong'
            : sourceItem.cellType;
        destinationRightItems.splice(destinationIndex, 0, sourceItem);
        break;
      default:
        sourceItem.cellType = 'textXLong';
        destinationBottomItems.splice(destinationIndex, 0, sourceItem);
        break;
    }
    sourceItem.parent = cellItem;
    while (destinationRightItems.length > destinationLeftItems.length) {
      destinationLeftItems.push(destinationRightItems.pop());
    }
    const mergedLeftAndRightItems = interlace(
      destinationLeftItems,
      destinationRightItems,
    );
    for (let i = destinationAppOnlyItems.length; i > 0; i -= 2) {
      const index = destinationAppOnlyItems[i - 2];
      const object = destinationAppOnlyItems[i - 1];
      mergedLeftAndRightItems.splice(index, 0, object);
    }
    destinationParent.detailItems = [
      ...mergedLeftAndRightItems,
      ...destinationBottomItems,
    ];
    if (destinationParent.detailItems.length === 0) {
      updatedContractObject.deleteObjectAtPath(destinationParent.getPath());
    }
    setTemplateState(updatedContractObject);
  });
};

class DetailImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showPopover: false };
    this.titleRef = React.createRef();
  }

  onMouseDown = () => {
    this.props.cellItem.editKey = 'title';
    this.props.setEditItem(this.props.cellItem);
  };

  onMouseOver = () => {
    this.setState({ isHovering: true });
  };

  onMouseOut = () => {
    this.setState({ isHovering: false });
  };

  onDoubleClick = () => {
    this.setState({ showPopover: true });
  };

  handleClickOutside = () => {
    if (this.state.showPopover) {
      this.setState({ showPopover: false });
    }
  };

  render() {
    const {
      cellItem,
      editCellItem,
      setTemplateState,
      templateValues,
      draggableType,
      setEditItem,
    } = this.props;
    const contractObject = new ContractObject(templateValues.toJSON());
    const updatedObject =
      contractObject.getObjectWithId(cellItem.objectId) || {};
    const { imageHeight = 0, detailItems = [] } = updatedObject;
    const filteredItems = detailItems.filter(({ appOnly }) => appOnly !== true);
    const longTextCells = filteredItems.filter(
      ({ cellType }) => cellType === 'textXLong',
    );
    const noLongTextCells = filteredItems.filter(
      ({ cellType }) => cellType !== 'textXLong',
    );
    const leftItems = noLongTextCells.filter((obj, index) => index % 2 === 0);
    const rightItems = noLongTextCells.filter((obj, index) => index % 2 > 0);
    const imageCell = detailItems.find(
      ({ cellType }) => cellType === 'imagePicker',
    );
    const titleStyle = styleForTitle(updatedObject, this.state.isHovering);
    const droppableIdLeft = `${[...cellItem.getPath(), 0].join('-')}-${
      cellItem.objectId
    }-pdf-${BodyCellItemObject.DetailContainerKey}`;
    const droppableIdRight = `${[...cellItem.getPath(), 1].join('-')}-${
      cellItem.objectId
    }-pdf-${BodyCellItemObject.DetailContainerKey}`;
    const droppableIdBottom = `${[...cellItem.getPath(), 2].join('-')}-${
      cellItem.objectId
    }-pdf-${BodyCellItemObject.DetailContainerKey}`;
    updateDraggableCallback(
      droppableIdLeft,
      cellItem,
      setTemplateState,
      setEditItem,
    );
    updateDraggableCallback(
      droppableIdRight,
      cellItem,
      setTemplateState,
      setEditItem,
    );
    updateDraggableCallback(
      droppableIdBottom,
      cellItem,
      setTemplateState,
      setEditItem,
    );
    return (
      <div css={{ width: '100%' }}>
        <div
          css={{
            ...titleStyle,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100%',
          }}
        >
          <Popover
            content={
              <textarea
                onContextMenu={(e) => e.stopPropagation()}
                fill
                ref={this.titleRef}
                defaultValue={updatedObject.contractTitle}
                className="ignore-react-onclickoutside"
              />
            }
            isOpen={this.state.showPopover}
            onClose={() => {
              updatedObject.contractTitle = this.titleRef.current.value;
              setTemplateState(contractObject);
            }}
          >
            <div
              onMouseDown={() => this.onMouseDown()}
              onDoubleClick={() => this.onDoubleClick()}
              onMouseOver={() => this.onMouseOver()}
              onMouseOut={() => this.onMouseOut()}
              css={titleStyle}
            >
              {updatedObject.contractTitle}
            </div>
          </Popover>
        </div>
        <div css={{ position: 'relative', width: '100%', display: 'flex' }}>
          {imageCell && imageHeight > 0 && (
            <div
              css={{
                paddingLeft: '3px',
                paddingTop: '3px',
                paddingBottom: '3px',
                height: `${imageHeight}px`,
                maxHeight: `${imageHeight}px`,
                minWidth: `${imageHeight}px`,
                maxWidth: `${imageHeight}px`,
              }}
              onClick={() => this.onMouseDown()}
            >
              <img
                src="/images/no_image.png"
                css={{
                  objectFit: 'contain',
                  maxWidth: '100%',
                  maxHeight: '100%',
                }}
                alt="thumbnail"
              />
            </div>
          )}
          <Droppable
            droppableId={droppableIdLeft}
            isDropDisabled={
              draggableType !== BodyCellItemObject.DetailContainerKey
            }
          >
            {(provided, snapshot) => (
              <div
                css={{
                  ...getListStyle(snapshot.isDraggingOver),
                  position: 'relative',
                  flex: 1,
                  width: 0,
                  minHeight: 20,
                }}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {leftItems.map((cell, index) => (
                  <Draggable
                    key={cell.objectId}
                    draggableId={`${cell.getPath().join('-')}-pdf-${
                      cell.objectId
                    }`}
                    index={index}
                  >
                    {(draggableProvided, draggableSnapshot) => (
                      <div
                        key={cell.objectId}
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                        css={getItemStyle(
                          draggableSnapshot,
                          draggableProvided.draggableProps.style,
                          cell.objectId === editCellItem.objectId,
                        )}
                      >
                        <TextShort cellItem={cell} isForDetailCell />
                        {draggableProvided.placeholder}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <Droppable
            droppableId={droppableIdRight}
            isDropDisabled={
              draggableType !== BodyCellItemObject.DetailContainerKey
            }
          >
            {(provided, snapshot) => (
              <div
                css={{
                  ...getListStyle(snapshot.isDraggingOver),
                  position: 'relative',
                  flex: 1,
                  width: 0,
                  minHeight: 20,
                }}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {rightItems.map((cell, index) => (
                  <Draggable
                    key={cell.objectId}
                    draggableId={`${cell.getPath().join('-')}-pdf-${
                      cell.objectId
                    }`}
                    index={index}
                  >
                    {(draggableProvided, draggableSnapshot) => (
                      <div
                        key={cell.objectId}
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                        css={getItemStyle(
                          draggableSnapshot,
                          draggableProvided.draggableProps.style,
                          cell.objectId === editCellItem.objectId,
                        )}
                      >
                        <TextShort cellItem={cell} isForDetailCell />
                        {draggableProvided.placeholder}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
        <Droppable
          droppableId={droppableIdBottom}
          isDropDisabled={
            draggableType !== BodyCellItemObject.DetailContainerKey
          }
        >
          {(provided, snapshot) => (
            <div
              css={{
                ...getListStyle(snapshot.isDraggingOver),
                minHeight: 20,
              }}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {longTextCells.map((cell, index) => (
                <Draggable
                  key={cell.objectId}
                  draggableId={`${cell.getPath().join('-')}-pdf-${
                    cell.objectId
                  }`}
                  index={index}
                >
                  {(draggableProvided, draggableSnapshot) => (
                    <div
                      key={cell.objectId}
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                      css={getItemStyle(
                        draggableSnapshot,
                        draggableProvided.draggableProps.style,
                        cell.objectId === editCellItem.objectId,
                      )}
                    >
                      <TextShort cellItem={cell} />
                      {draggableProvided.placeholder}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    );
  }
}

DetailImage.propTypes = {
  editCellItem: PropTypes.any,
  cellItem: PropTypes.instanceOf(BodyCellItemObject).isRequired,
  setTemplateState: PropTypes.func.isRequired,
  templateValues: PropTypes.shape({}).isRequired,
  setEditItem: PropTypes.func.isRequired,
  draggableType: PropTypes.string,
};

DetailImage.defaultProps = {
  editCellItem: {},
  draggableType: undefined,
};

const mapStateToProps = ({ templatesEdit }) => ({
  templateValues: templatesEdit[templatesEdit.currentWorkingId],
  editCellItem: templatesEdit.editCellItem,
  draggableType: templatesEdit.draggableType,
});

const mapDispatchToProps = (dispatch) => ({
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
  setEditItem: (cellItem) => dispatch(setEditCellItem(cellItem)),
});

const wrappedComponent = onClickOutside(DetailImage);
export default connect(mapStateToProps, mapDispatchToProps)(wrappedComponent);
