/* eslint-disable react/forbid-prop-types */
/** @jsx jsx */
import { jsx } from '@emotion/core';
// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import HeaderGroup from './HeaderGroup';
import {
  setInitialStateForTemplate,
  setEditCellItem,
} from '../../../actions/templateEdit';
import { getItemStyle, getListStyle } from '../Utils';
import { setOnDragEndCallback } from '../../DropContext';
import ContractObject from '../Models/ContractObject';
import HeaderGroupObject from '../Models/HeaderGroup';
import HeaderItemGroup from '../Models/HeaderItemGroup';

const styleForHeaderRow = (border) => {
  switch (border) {
    case 1:
    case '1':
      return {
        border: '1px solid black',
        display: 'flex',
      };
    case 3:
    case '3':
      return {
        borderTop: '1px solid black',
        borderBottom: '1px solid black',
        display: 'flex',
      };
    case 0:
    case '0':
    case 2:
    case '2':
    default:
      return {
        border: '0',
        display: 'flex',
      };
  }
};

const HeaderRowGroupContainer = ({
  section,
  editCellItem,
  setTemplateState,
  templateValues,
  setEditItem,
  draggableType,
}) => {
  const { noBorder = false, border = 0, cellItems = [] } = section;
  const droppableId = `${section.getPath().join('-')}-${section.objectId}-${
    HeaderGroupObject.ContainerKey
  }`;
  setOnDragEndCallback(droppableId, (result) => {
    const { draggableId, destination, source } = result;
    let destinationPath = destination.droppableId.split('-');
    destinationPath.pop();
    destinationPath.pop();
    destinationPath = destinationPath.map((index) => parseInt(index, 10));
    destinationPath.push(destination.index);
    const updatedContractObject = new ContractObject(templateValues.toJSON());
    if (source.droppableId === `new-${HeaderGroupObject.ContainerKey}`) {
      const newGroup = new HeaderItemGroup();
      setEditItem(newGroup.cellItems[0]);
      updatedContractObject.insertObjectAtPath(destinationPath, newGroup, 0);
      setTemplateState(updatedContractObject);
      return;
    }
    let sourcePath = draggableId.split('-');
    sourcePath.pop();
    sourcePath = sourcePath.map((index) => parseInt(index, 10));
    if (sourcePath.every((value, index) => value === destinationPath[index])) {
      return; // same path
    }
    const sourceItem = updatedContractObject.getItemAtPath(sourcePath);
    const sourceContainerPath = [...sourcePath];
    sourceContainerPath.pop();
    const sourceContainer = updatedContractObject.getItemAtPath(
      sourceContainerPath,
    );
    updatedContractObject.deleteObjectAtPath(sourcePath);
    updatedContractObject.insertObjectAtPath(destinationPath, sourceItem, 0);
    if (!sourceContainer.cellItems.length) {
      updatedContractObject.deleteObjectAtPath(sourceContainer.getPath());
    }
    setTemplateState(updatedContractObject);
  });
  return (
    <Droppable
      droppableId={droppableId}
      direction="horizontal"
      isDropDisabled={draggableType !== HeaderGroupObject.ContainerKey}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          css={{
            ...styleForHeaderRow(noBorder ? 0 : border),
            ...getListStyle(snapshot.isDraggingOver),
          }}
          {...provided.droppableProps}
        >
          {cellItems.map((itemGroup, index) => {
            const newParentId = `${section.objectId}-${index}`;
            const widthPercent = 100 / cellItems.length;
            const draggableId = `${itemGroup
              .getPath()
              .join('-')}-header_row_group`;
            return (
              <Draggable
                key={newParentId}
                draggableId={draggableId}
                index={index}
              >
                {(draggableProvided, draggableSnapshot) => (
                  <div
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}
                    css={{
                      ...getItemStyle(
                        draggableSnapshot,
                        draggableProvided.draggableProps.style,
                        itemGroup.objectId === editCellItem.objectId,
                      ),
                      minWidth: draggableSnapshot.isDragging
                        ? undefined
                        : `${widthPercent}%`,
                      maxWidth: draggableSnapshot.isDragging
                        ? undefined
                        : `${widthPercent}%`,
                    }}
                  >
                    <HeaderGroup
                      cells={itemGroup}
                      border={border === 2 || border === '2'}
                    />
                    {draggableProvided.placeholder}
                  </div>
                )}
              </Draggable>
            );
          })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

HeaderRowGroupContainer.propTypes = {
  section: PropTypes.instanceOf(HeaderGroupObject).isRequired,
  setTemplateState: PropTypes.func.isRequired,
  setEditItem: PropTypes.func.isRequired,
  editCellItem: PropTypes.any,
  templateValues: PropTypes.instanceOf(ContractObject).isRequired,
  draggableType: PropTypes.string,
};

HeaderRowGroupContainer.defaultProps = {
  editCellItem: {},
  draggableType: undefined,
};

const mapStateToProps = ({ templatesEdit = {} }) => ({
  templateValues:
    templatesEdit[templatesEdit.currentWorkingId] || new ContractObject(),
  editCellItem: templatesEdit.editCellItem,
  draggableType: templatesEdit.draggableType,
});

const mapDispatchToProps = (dispatch) => ({
  setTemplateState: (template) =>
    dispatch(setInitialStateForTemplate(template)),
  setEditItem: (cellItem) => dispatch(setEditCellItem(cellItem)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HeaderRowGroupContainer);
