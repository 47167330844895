/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';
import { setOptionColumnWidth } from '../../../actions/priceGuide2';

const OptionHeaderCell = ({
  children,
  columnKey,
  disableResize,
  activeColumns,
  setWidth,
  widths,
  ...rest
}) => {
  const [over, setOver] = useState(false);

  return (
    <div
      style={{ width: widths[columnKey] }}
      className={`priceguide__option_header-cell${
        activeColumns[columnKey] ? '_hidden' : ''
      }${disableResize ? ' static' : ''}`}
      {...rest}
      onMouseDown={() => {
        if (!over) {
          setOver(true);
        }
      }}
      onMouseOver={() => {
        if (!over) {
          setOver(true);
        }
      }}
    >
      {children}
      {!disableResize && (
        <ReactResizeDetector
          handleWidth
          skipOnMount
          refreshMode="debounce"
          refreshRate={300}
          onResize={(width) => {
            if (over) {
              setOver(false);
              setWidth(columnKey, width);
            }
          }}
        />
      )}
    </div>
  );
};

OptionHeaderCell.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  columnKey: PropTypes.string,
  disableResize: PropTypes.bool,
  activeColumns: PropTypes.shape({
    optionPlaceholders: PropTypes.bool.isRequired,
    optionBrand: PropTypes.bool.isRequired,
    optionName: PropTypes.bool.isRequired,
    optionPrices: PropTypes.bool.isRequired,
  }).isRequired,
  setWidth: PropTypes.func.isRequired,
};

OptionHeaderCell.defaultProps = {
  disableResize: false,
  columnKey: '',
};

const mapStateToProps = ({ priceGuide2: { activeColumns, columnWidths } }) => ({
  activeColumns,
  widths: columnWidths.options,
});

const mapDispatchToProps = (dispatch) => ({
  setWidth: (columnKey, width) =>
    dispatch(setOptionColumnWidth(columnKey, width)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OptionHeaderCell);
