/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
import Parse from 'parse';
import { showWarningAlert, hideAlert } from './alert';
import { handleError } from './auth';
import { getPartnerCreditApplications } from './creditApplications';

import { showLoader, hideLoader } from './loading';
import defaultPlanJSON from './defaultPlanJSON';

class PlanActions {
  /** @param {{
   *  docuSignEnabled: boolean,
   *  groupMeEnabled: boolean,
   *  hoverEnabled: boolean,
   *  jobNimbusEnabled: boolean,
   *  leadPerfectionEnabled: boolean,
   *  marketsharpEnabled: boolean,
   *  partnerCreditApplicationSourcesAllowed: string[],
   *  paymentCaptureEnabled: boolean,
   *  proviaEnabled: boolean,
   *  roofScopeEnabled: boolean,
   *  companyCamEnabled: boolean,
   *  gafEnabled: boolean,
   *  aquaEnabled: boolean,
   *  salesforceEnabled: boolean,
   * }} plan */
  static collectIntegrations({
    docuSignEnabled,
    groupMeEnabled,
    hoverEnabled,
    jobNimbusEnabled,
    leadPerfectionEnabled,
    marketsharpEnabled,
    partnerCreditApplicationSourcesAllowed,
    paymentCaptureEnabled,
    proviaEnabled,
    roofScopeEnabled,
    companyCamEnabled,
    gafEnabled,
    // aquaEnabled,
    salesforceEnabled,
    jobProgressEnabled,
    zapierEnabled,
  }) {
    const collectedIntegrations = [];

    if (docuSignEnabled) {
      collectedIntegrations.push('docusign');
    }
    if (groupMeEnabled) {
      collectedIntegrations.push('groupme');
    }
    if (hoverEnabled) {
      collectedIntegrations.push('hover');
    }
    if (jobNimbusEnabled) {
      collectedIntegrations.push('jobnimbus');
    }
    if (leadPerfectionEnabled) {
      collectedIntegrations.push('leadperfection');
    }
    if (marketsharpEnabled) {
      collectedIntegrations.push('marketsharp');
    }
    if (paymentCaptureEnabled) {
      collectedIntegrations.push('payment_capture');
    }
    if (proviaEnabled) {
      collectedIntegrations.push('provia');
    }
    if (roofScopeEnabled) {
      collectedIntegrations.push('roofscope');
    }

    if (companyCamEnabled) {
      collectedIntegrations.push('companyCam');
    }

    if (gafEnabled) {
      collectedIntegrations.push('gaf');
    }

    // TODO: aqua needs to be added to the PlanObject
    // if (aquaEnabled) {
    collectedIntegrations.push('aqua');
    // }

    if (salesforceEnabled) {
      collectedIntegrations.push('salesforce');
    }

    if (jobProgressEnabled) {
      collectedIntegrations.push('jobprogress');
    }

    if (zapierEnabled) {
      collectedIntegrations.push('zapier');
    }

    collectedIntegrations.push(...partnerCreditApplicationSourcesAllowed);
    const sortedIntegrations = collectedIntegrations.sort((a, b) => {
      const aChar = a.charCodeAt(0);
      const bChar = b.charCodeAt(0);
      return aChar - bChar;
    });
    return sortedIntegrations;
  }

  static collectAppSettings(plan) {
    const {
      maxCustomTemplateCount,
      maxPriceFormulasCount,
      maxCategoryCount,
      maxContractTemplateCount,
      maxProposalTemplateCount,
      maxCommissionFormulasCount,
      availableContactsEnabled,
      webhooksEnabled,
      customCreditApplicationsAllowed,
    } = plan;

    const collectedAppSettings = [];

    if (webhooksEnabled) {
      collectedAppSettings.push('api_access');
    }

    collectedAppSettings.push(...['app_appearance', 'appointments']);

    if (maxCategoryCount) {
      collectedAppSettings.push('categories');
    }

    collectedAppSettings.push(
      ...[
        'company_logo',
        'email_links',
        'new_user_defaults',
        'placeholders',
        'report_sending',
        'results',
        'watermark',
      ],
    );

    if (availableContactsEnabled) {
      collectedAppSettings.push('available_contacts');
    }

    if (maxContractTemplateCount !== 0) {
      collectedAppSettings.push('contract_sending');
    }
    if (maxProposalTemplateCount !== 0) {
      collectedAppSettings.push('proposal_sending');
      collectedAppSettings.push('credit_app_sending');
    }
    if (customCreditApplicationsAllowed) {
      collectedAppSettings.push('credit_app_upload');
    }
    if (maxCustomTemplateCount) {
      collectedAppSettings.push('custom_options');
    }

    if (maxPriceFormulasCount) {
      collectedAppSettings.push('price_formulas');
    }

    if (maxCommissionFormulasCount) {
      collectedAppSettings.push('commission_formulas');
    }

    const sortedAppSettings = collectedAppSettings.sort((a, b) => {
      const aChar = a.charCodeAt(0);
      const bChar = b.charCodeAt(0);
      return aChar - bChar;
    });
    return sortedAppSettings;
  }

  static collectMainFeatures(plan) {
    const {
      maxMeasureSheetItemCount,
      maxContractTemplateCount,
      maxPackageCount,
      maxProposalTemplateCount,
      maxResourceCount,
    } = plan;

    const collectedtMainFeatures = ['estimates'];

    if (maxMeasureSheetItemCount) {
      collectedtMainFeatures.push('price_guide');
    }

    collectedtMainFeatures.push('finance_options');

    if (maxResourceCount) {
      collectedtMainFeatures.push('resources');
    }
    if (maxPackageCount) {
      collectedtMainFeatures.push('packages');
    }

    if (maxContractTemplateCount !== 0) {
      collectedtMainFeatures.push('contract');
    }
    if (maxProposalTemplateCount !== 0) {
      collectedtMainFeatures.push('proposal');
    }

    collectedtMainFeatures.push('credit_applications');

    // const sortedMainFeature = collectedtMainFeatures.sort((a, b) => {
    //   const aChar = a.charCodeAt(0);
    //   const bChar = b.charCodeAt(0);
    //   return aChar - bChar;
    // });
    return collectedtMainFeatures;
  }

  static planKeyMap(key) {
    const keyMap = {
      resources: 'pitchBooks',
      contract: 'contracts',
      proposal: 'proposals',
      creditApplications: 'creditApplication',
    };
    return keyMap[key] || key;
  }

  static getSettingsLabel = (appSetting, defaultValue) => (
    dispatch,
    getState,
  ) => {
    const { plan = {} } = getState();

    const { mainMenuLabelOverrides = {} } = plan;

    const planKey =
      appSetting.anchor === 'available_contacts'
        ? 'availableManagers'
        : `${appSetting.anchor.split('_')[0]}s`;
    const hasLock =
      mainMenuLabelOverrides[planKey] && mainMenuLabelOverrides[planKey].locked;
    let defaultTitle = mainMenuLabelOverrides[planKey]
      ? mainMenuLabelOverrides[planKey].settingsLabel
      : appSetting.title;

    if (planKey === 'availableManagers') {
      defaultTitle =
        mainMenuLabelOverrides.availableManagers.settingsLabel ||
        'Available Contacts';
    }

    const transFormedTitle = hasLock
      ? mainMenuLabelOverrides[`${planKey}`].settingsLabel
      : defaultTitle;
    return transFormedTitle;
  };

  static getLabelByAnchor = (feature) => (dispatch, getState) => {
    const { plan = {}, auth = {} } = getState();

    const { mainMenuLabelOverrides = {} } = plan;

    const { config = {} } = auth;

    const { appTitles = {} } = config;

    const planKey = feature.anchor
      .split('_')
      .map((part, i) =>
        i > 0 ? `${part.charAt(0).toUpperCase()}${part.substring(1)}` : part,
      )
      .join('')
      .replace('templates/', '');

    const mappedKey = PlanActions.planKeyMap(planKey);
    const hasLock =
      mainMenuLabelOverrides[mappedKey] &&
      mainMenuLabelOverrides[mappedKey].locked;
    const defaultTitle = mainMenuLabelOverrides[mappedKey]
      ? mainMenuLabelOverrides[mappedKey].title
      : feature.title;
    const transFormedTitle = hasLock
      ? mainMenuLabelOverrides[mappedKey].title
      : appTitles[mappedKey] || defaultTitle;
    return (
      transFormedTitle.charAt(0).toUpperCase() +
      transFormedTitle.substr(1).toLowerCase()
    );
  };

  static collectAllowedRoutes(plan, collectedFeatures) {
    const collectAllowedRoutes = [...collectedFeatures];
    return collectAllowedRoutes.map((featureRoute) =>
      featureRoute === 'green_sky' ? 'greensky' : featureRoute,
    );
  }

  static deletePlan = (plan, onConfirm) => async (dispatch) => {
    try {
      return dispatch(
        showWarningAlert({
          title: 'Delete Plan',
          message: 'Are You sure you want to delete this plan',
          onConfirm: async () => {
            dispatch(hideAlert());
            dispatch(showLoader('Deleting Plan...'));
            await plan.destroy();
            if (onConfirm) {
              onConfirm();
            }
          },
        }),
      );
    } catch (error) {
      dispatch(handleError(error));
    } finally {
      dispatch(hideLoader());
    }
  };

  static getPlanDetails = (id) => async (dispatch, getState) => {
    dispatch(showLoader('Fetching Plan Details'));
    try {
      return dispatch({
        type: 'SET_PLAN_DETAILS',
        details: {
          ...defaultPlanJSON,
        },
      });
    } catch (error) {
      dispatch(handleError(error));
    } finally {
      dispatch(hideLoader());
    }
  };

  static getPlans = () => async (dispatch, getState) => {
    dispatch(showLoader('Fetching Plans...'));

    const { plan } = getState();

    const { startDate, endDate, limit, skip } = plan;

    const params = {
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
      limit,
      skip,
    };

    try {
      const PlansQuery = new Parse.Query('PlanObject');
      const items = await PlansQuery.find();
      return dispatch({ type: 'SET_PLANS', items });
    } catch (error) {
      dispatch(handleError(error));
    } finally {
      dispatch(hideLoader());
    }
  };

  static setPlanConfig(plan) {
    return async (dispatch) => {
      const planData = plan.toJSON();

      const partners = await dispatch(getPartnerCreditApplications());

      planData.partnerCreditApplicationSourcesAllowed = partners.map((p) =>
        p.get('source'),
      );
      const allowedIntegration = PlanActions.collectIntegrations(planData);
      const allowedAppSettings = PlanActions.collectAppSettings(planData);
      const allowedMainFeatures = PlanActions.collectMainFeatures(planData);

      const collectedFeatures = [
        ...allowedIntegration,
        ...allowedAppSettings,
        ...allowedMainFeatures,
      ];

      const allowedRoutes = PlanActions.collectAllowedRoutes(
        planData,
        collectedFeatures,
      );

      dispatch({
        type: 'SET_PLAN_CONFIG',
        config: {
          ...planData,
          allowedIntegration,
          allowedAppSettings,
          allowedMainFeatures,
          allowedRoutes,
        },
      });
    };
  }
}

export default PlanActions;
