import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';

import { hideDeleteConfirmation } from '../actions/deleteConfirmation';

const DeleteConfirmation = ({ show, onConfirm, message, onClose, title }) => (
  <div>
    {show && (
      <SweetAlert
        warning
        showCancel
        title={title}
        confirmBtnText="Yes"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        onConfirm={() => {
          onClose();
          onConfirm();
        }}
        onCancel={() => {
          onClose();
        }}
      >
        <span>{message}</span>
      </SweetAlert>
    )}
  </div>
);

DeleteConfirmation.propTypes = {
  show: PropTypes.bool,
  onConfirm: PropTypes.func,
  message: PropTypes.string,
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

DeleteConfirmation.defaultProps = {
  show: false,
  title: 'Delete',
  message: 'Are You Sure you want to delete ?',
  onConfirm: () => {},
};

const mapStateToProps = ({
  deleteConfirmation: { show, message, title, onConfirm },
}) => ({
  show,
  message,
  title,
  onConfirm,
});

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(hideDeleteConfirmation()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteConfirmation);
