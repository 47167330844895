import React from 'react';
import { DebounceInput } from 'react-debounce-input';
import Switch from '../Misc/Switch';
import IncludedOfficesDropDown from '../IncludedOfficesDropDown';
import PartnerCreditAppDropDown from './PartnerCreditAppDropDown';

const cellStyle = {
  flex: 'none',
  position: 'relative',
  height: 45,
  overflow: 'hidden',
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: 'lightgrey',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: 'white',
};

const LeapStrechColumnTableCell = (props) => {
  const { getColumns, colIndex, cellData, col } = props;
  const columns = getColumns();

  const key = `${props.rowData.objectId}_${col.key}`;

  const found = columns[colIndex];

  const width = found ? found.width + 2 : 200;

  switch (col.key) {
    case 'isActive': {
      return (
        <div
          style={{
            width,
            ...cellStyle,
            alignItems: 'center',
          }}
          className="leap-table-cell-div"
          key={key}
        >
          <div style={{ paddingTop: 10 }}>
            <Switch
              checked={!!cellData}
              onChange={() =>
                props.onChange({
                  value: !cellData,
                  row: props.rowData,
                  key: col.key,
                })
              }
            />
          </div>
        </div>
      );
    }
    case 'delete': {
      return (
        <div
          style={{
            width,
            ...cellStyle,
          }}
          className="leap-table-cell-div"
          key={key}
        >
          <button
            type="button"
            onClick={() => props.onDelete(props.rowData)}
            className="fill-cell danger"
          >
            <i className="far fa-trash-alt" />
          </button>
        </div>
      );
    }
    case 'apiSource': {
      return (
        <div
          style={{
            width,
            ...cellStyle,
            overflow: 'visible',
          }}
          className="leap-table-cell-div"
          key={key}
        >
          <PartnerCreditAppDropDown
            onChange={(selectedUpdate) => {
              props.onChange({
                value: selectedUpdate.value,
                row: props.rowData,
                key: col.key,
              });
            }}
            placeholder={cellData || 'N/A'}
            selected={cellData}
            value={cellData || ''}
            selectAllLabel="Select All"
            isClearable={false}
          />
        </div>
      );
    }
    case 'includedOffices': {
      return (
        <div
          style={{
            width,
            ...cellStyle,
            overflow: 'visible',
          }}
          className="leap-table-cell-div"
          key={key}
        >
          <IncludedOfficesDropDown
            onChange={(offices) =>
              props.onChange({
                value: offices,
                row: props.rowData,
                key: col.key,
              })
            }
            selected={cellData.map((office) => office.id || office.objectId)}
          />
        </div>
      );
    }
    case 'minAmount':
    case 'maxAmount':
    case 'interestRate':
    case 'term':
    case 'paymentFactor':
    case 'feePercent': {
      return (
        <div
          style={{
            width,
            ...cellStyle,
          }}
          className="leap-table-cell-div"
          key={key}
        >
          <input
            type="number"
            value={cellData}
            style={{
              border: 0,
              width: '100%',
              height: '100%',
              padding: '8px',
            }}
            onBlur={() => {
              props.onChange({
                value: parseFloat(cellData),
                row: props.rowData,
                key: col.key,
              });
            }}
            onChange={({ target: { value } }) => {
              props.onChange({
                value,
                row: props.rowData,
                key: col.key,
              });
            }}
          />
        </div>
      );
    }
    default: {
      return (
        <div
          style={{
            width,
            ...cellStyle,
          }}
          className="leap-table-cell-div"
          key={key}
        >
          <DebounceInput
            debounceTimeout={300}
            value={cellData || ''}
            style={{
              border: 0,
              width: '100%',
              height: '100%',
              padding: '8px',
            }}
            onChange={({ target: { value } }) => {
              props.onChange({ value, row: props.rowData, key: col.key });
            }}
          />
        </div>
      );
    }
  }
};

export default LeapStrechColumnTableCell;
