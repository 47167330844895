/* eslint-disable import/prefer-default-export */
/** @typedef {{
 *  event: string,
 *  eventCategory: string,
 *  eventAction: string,
 *  eventLabel: string,
 * }} AnalyticsEventData
 * @param {AnalyticsEventData} variablesForLayer */
export const pushToDataLayer = (variablesForLayer) => ({
  type: 'PUSH_TO_DATA_LAYER',
  variablesForLayer,
});
