import React, { useContext } from 'react';
import PropTypes from 'prop-types';

/** @typedef {{
 *  title: string,
 *  link: string,
 * }} Crumb
 * @typedef {{
 *  crumbs: Crumb[],
 *  setCrumbs: (crumbs: Crumb[]) => void,
 *  setButtons: (buttons?: React.ReactNode) => void,
 *  setToolbar: (toolbar?: React.ReactNode) => void,
 *  isOpen: boolean,
 * }} LayoutContextObj */

/** @type {LayoutContextObj} initialContext */
const initialContext = {
  crumbs: [],
  setCrumbs: (crumbs) => {},
  setButtons: (buttons) => {},
  setToolbar: (toolbar) => {},
  isOpen: true,
};

export const LayoutContext = React.createContext(initialContext);

// A high order component to use with React class components that passes values
// from LayoutContext as props, in order to trigger component updates when a
// value changes
export const withLayoutContext = (Component) => (props) => {
  const context = useContext(LayoutContext);

  return <Component {...props} {...context} />;
};

export const layoutContextPropTypes = {
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.string,
    }),
  ),
  setCrumbs: PropTypes.func,
  setButtons: PropTypes.func,
  setToolbar: PropTypes.func,
  isOpen: PropTypes.bool,
};
