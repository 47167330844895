/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from '../Misc/Select';
import { startFetchContractSendingAPIs } from '../../actions/config';

export class APIDropDown extends React.Component {
  constructor(props) {
    super(props);
    if (!props.apis || !props.apis.length) {
      props.startFetchContractSendingAPIs();
    }
    const selectedOption = props.selectedOptions.map((option) => ({
      value: option,
      label: option,
    }));
    this.state = {
      selectedOption,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const selectedOption = nextProps.selectedOptions.map((option) => ({
      value: option,
      label: option,
    }));
    this.setState({ selectedOption });
  }

  onSelectionChange = (selectedOption) => {
    this.setState({ selectedOption });
    if (selectedOption) {
      const options = selectedOption.map((option) => option.value);
      this.props.onChange(options);
    } else {
      this.props.onChange([]);
    }
  };

  apiOptions = () => {
    if (this.props.apis && this.props.apis.length) {
      const disabledOptions = [];
      this.state.selectedOption.forEach((option) => {
        if (option.value === 'Marketsharp') {
          disabledOptions.push('LeadPerfection');
          disabledOptions.push('Salesforce');
        } else if (option.value === 'LeadPerfection') {
          disabledOptions.push('Marketsharp');
          disabledOptions.push('Salesforce');
        } else if (option.value === 'Salesforce') {
          disabledOptions.push('Marketsharp');
          disabledOptions.push('LeadPerfection');
        }
      });
      return this.props.apis.map((option) => ({
        value: option,
        label: option,
        disabled: disabledOptions.indexOf(option) > -1,
      }));
    }
    return [];
  };

  render() {
    const { selectedOption } = this.state;
    return (
      <Select
        name="form-field-name"
        value={selectedOption}
        onChange={this.onSelectionChange}
        isMulti
        closeMenuOnSelect={false}
        options={this.apiOptions()}
      />
    );
  }
}

APIDropDown.propTypes = {
  apis: PropTypes.arrayOf(PropTypes.string),
  startFetchContractSendingAPIs: PropTypes.func.isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
};

APIDropDown.defaultProps = {
  apis: [],
  selectedOptions: [],
};

const mapStateToProps = ({ config: { contractSendingAPIs } }) => ({
  apis: contractSendingAPIs,
});

const mapDispatchToProps = (dispatch) => ({
  startFetchContractSendingAPIs: () =>
    dispatch(startFetchContractSendingAPIs()),
});

export default connect(mapStateToProps, mapDispatchToProps)(APIDropDown);
