/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
// import PropTypes from 'prop-types';
import Parse from 'parse';
import { connect } from 'react-redux';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import { DropDownGroup } from '../FormGroup';
import { showSuccessAlert, hideAlert } from '../../actions/alert';
import { handleError, startBecomeUser } from '../../actions/auth';

import { showDeleteConfirmation } from '../../actions/deleteConfirmation';
import { showLoader, hideLoader } from '../../actions/loading';

import Panel from '../Panel';

export class Masquerade extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      selectedUser: {},
    };
  }

  componentDidMount() {
    const {
      setCrumbs,
      location: { pathname },
    } = this.props;

    const crumb = { title: 'Masquerade as User', link: pathname };
    setCrumbs([crumb]);
  }

  componentWillUnmount() {
    const { setCrumbs } = this.props;
    setCrumbs([]);
  }

  onUserSelect = (event) => {
    this.props.showDeleteConfirmation({
      message: `Are you sure you want to masquerade as ${event.label}`,
      title: 'Masquerade ?',
      onConfirm: async () => {
        this.props.showLoader('Masquerade...');
        try {
          const masqueradeAsUserResult = await Parse.Cloud.run(
            'masqueradeAsUser',
            {
              userId: event.value,
              source: 'website',
            },
          );
          await Parse.User.become(masqueradeAsUserResult.sessionToken);
          window.location.href = '/index';
        } catch (err) {
          this.props.handleError(err);
        } finally {
          this.props.hideLoader();
        }
      },
    });
  };

  componentDidMount = async () => {
    const loggedIn = !!Parse.User.current();
    if (loggedIn) {
      const canMasquerade = await Parse.Cloud.run('canMasquerade');
      if (canMasquerade) {
        const listUsersForMasquerade = await Parse.Cloud.run(
          'listUsersForMasquerade',
        );
        this.setState({
          userList: listUsersForMasquerade.map((user) => {
            return {
              label: user.name,
              value: user.id,
            };
          }),
        });
      } else {
        this.props.handleError({
          message: 'You are not authorized to perform this action',
        });
      }
    }
  };

  render() {
    return (
      <div className="default-page-padding">
        <Panel title="Masquerade as User">
          <DropDownGroup
            title="Select User to Masquerade as"
            value={this.state.selectedUser}
            onChange={this.onUserSelect}
            options={this.state.userList}
            isDisabled={!this.state.userList.length}
            // isLoading={!this.state.companies}
            isClearable={false}
            backspaceRemovesValue={false}
            closeMenuOnSelect
          />
        </Panel>
      </div>
    );
  }
}

Masquerade.propTypes = {
  ...layoutContextPropTypes,
};

Masquerade.defaultProps = {};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  showSuccessAlert: (params) => dispatch(showSuccessAlert(params)),
  hideAlert: () => dispatch(hideAlert()),
  handleError: (params) => dispatch(handleError(params)),
  showLoader: (params) => dispatch(showLoader(params)),
  hideLoader: () => dispatch(hideLoader()),
  startBecomeUser: (params) => dispatch(startBecomeUser(params)),
  showDeleteConfirmation: (params) => dispatch(showDeleteConfirmation(params)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(Masquerade),
);
