import { useDispatch, useSelector } from 'react-redux';
import { startUpdateConfig } from '../../actions/auth';

const useZapActions = (item) => {
  const dispatch = useDispatch();

  const zapierConfig = useSelector(({ auth }) => {
    const { config = {} } = auth;
    const { zapier = {} } = config;
    return zapier;
  });

  const { triggers = {} } = zapierConfig;

  const { zapId, trigger } = item;

  const triggerSet = triggers[trigger];

  const onActiveSwitch = (event) => {
    dispatch(
      startUpdateConfig({
        zapier: {
          ...zapierConfig,
          triggers: {
            ...triggers,
            [trigger]: triggerSet.map((storedTrigger) => {
              if (storedTrigger.zapId === zapId) {
                return {
                  ...storedTrigger,
                  isActive: event,
                };
              }
              return storedTrigger;
            }),
          },
        },
      }),
    );
  };

  const onRemoveZap = () => {
    dispatch(
      startUpdateConfig({
        zapier: {
          ...zapierConfig,
          triggers: {
            ...triggers,
            [trigger]: triggerSet.filter((storedTrigger) => {
              return storedTrigger.zapId !== zapId;
            }),
          },
        },
      }),
    );
  };

  return { onRemoveZap, onActiveSwitch };
};

export default useZapActions;
