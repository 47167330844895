export default (state = {}, { type, ...rest }) => {
  switch (type) {
    case 'PASTEBOARD_SET_VALUE':
      return {
        ...state,
        [rest.key]: rest.value,
      };
    case 'PASTEBOARD_SET_VALUES':
      return {
        ...state,
        ...rest.values,
      };
    default:
      return state;
  }
};
