import React, { useContext } from 'react';

/**
 * UserContext + useParseUser hook will provide an object: {loggedIn: boolean, user: ParseUserObj} available to all child components, updated in near real time.
 * @typedef {{company: import('../Models/Company').default}} UserAttributes
 * @type {React.Context<{loggedIn: boolean, user?: Parse.User<UserAttributes>} | undefined>}
 */
const UserContext = React.createContext();

// HOC we can wrap class components with to get context values as props instead
// of this.context, since context value changes won't re-render class components
export const withUserContext = (Component) => (props) => {
  const userContext = useContext(UserContext);
  return <Component {...props} {...userContext} />;
};

export default UserContext;
