import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ConfirmResendModal = ({ show, onClose, user, onSend }) => (
  <Modal show={show} onHide={onClose}>
    <Modal.Header closeButton>
      <Modal.Title>Resend Attachment</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
        {`WARNING! This will cause the document to be resent on behalf of ${user.get(
          'nameFirst',
        )} ${user.get('nameLast')} as if they sent it from the Leap app.`}
      </p>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={onClose}>Close</Button>
      <Button onClick={onSend} variant="danger">
        Send
      </Button>
    </Modal.Footer>
  </Modal>
);

ConfirmResendModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  user: PropTypes.shape({
    nameFirst: PropTypes.string,
    nameLast: PropTypes.string,
    get: PropTypes.func,
  }),
  onSend: PropTypes.func,
};

ConfirmResendModal.defaultProps = {
  show: false,
  user: {
    nameFirst: '',
    nameLast: '',
    get: () => {},
  },
  onSend: undefined,
  onClose: undefined,
};

export default ConfirmResendModal;
