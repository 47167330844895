import moment from 'moment';

export default (
  state = {
    startDate: moment().subtract(1, 'month'),
    endDate: moment(),
  },
  action,
) => {
  switch (action.type) {
    case 'SET_ANALYTICS_DATA_SETS':
      return {
        ...state,
        ...action.data,
      };
    case 'UPDATE_ANALYTICS_PAGE_DATES':
      return {
        ...state,
        startDate: action.startDate,
        endDate: action.endDate,
      };
    case 'SET_CHART_LIST':
      return {
        ...state,
        charts: action.charts,
      };
    default:
      return state;
  }
};
