/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* eslint-disable react/no-deprecated */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Editors } from 'react-data-grid-addons';
import Parse from 'parse';
import { withUserContext } from '../UserContext';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import {
  startFetchFinanceOptions,
  deleteFinanceOption,
  setFinanceOptions,
} from '../../actions/financeOptions';
import TitleButton from '../TitleButton';
import LeapStrechColumnWidthTable from './LeapStrechColumnWidthTable';
import Paginator from '../Misc/Paginator';
import { handleError } from '../../actions/auth';
import { DropDownGroup } from '../FormGroup';
import { showDeleteConfirmation } from '../../actions/deleteConfirmation';
import { startExportPriceGuide } from '../../actions/priceGuide2';
import ImportModal from '../ImportModal';
import AppToaster from '../../utils/AppToaster';

const sources = [
  { id: 'none', title: '-None-' },
  { id: 'green_sky', title: 'GreenSky', value: 'green_sky' },
];
const { AutoComplete: AutoCompleteEditor } = Editors;
const SourceEditor = <AutoCompleteEditor options={sources} />;

const tools = [
  { value: 'export', label: 'Export Finance Options' },
  { value: 'import', label: 'Import Finance Options' },
];

const columns = [
  {
    key: 'isActive',
    name: 'Active',
    sortable: false,
    resizable: false,
    editable: false,
    width: 60,
  },
  {
    key: 'apiSource',
    name: 'Source',
    editor: SourceEditor,
  },
  {
    key: 'bankName',
    name: 'Bank Name',
    resizable: true,
    sortable: true,
    editable: true,
  },
  {
    key: 'category',
    name: 'Category',
    sortable: true,
    resizable: true,
    editable: true,
  },
  {
    key: 'name',
    name: 'Name',
    sortable: true,
    resizable: true,
    editable: true,
  },
  {
    key: 'note',
    name: 'Description',
    sortable: true,
    resizable: true,
    editable: true,
  },
  {
    key: 'planCode',
    name: 'Plan Code',
    resizable: true,
    sortable: true,
    editable: true,
  },
  {
    key: 'minAmount',
    name: 'Min Amount',
    resizable: true,
    sortable: true,
    editable: true,
  },
  {
    key: 'maxAmount',
    name: 'Max Amount',
    resizable: true,
    sortable: true,
    editable: true,
  },
  {
    key: 'interestRate',
    name: 'Interest Rate',
    resizable: true,
    sortable: true,
    editable: true,
  },
  {
    key: 'term',
    name: 'Term',
    resizable: true,
    sortable: true,
    editable: true,
  },
  {
    key: 'paymentFactor',
    name: 'Payment Multiplier',
    resizable: true,
    sortable: true,
    editable: true,
  },
  {
    key: 'feePercent',
    name: 'Dealer Fee',
    resizable: true,
    sortable: true,
    editable: true,
  },
  {
    key: 'includedOffices',
    name: 'Offices',
    resizable: true,
    sortable: false,
    editable: false,
    width: 400,
  },
  {
    key: 'delete',
    name: '',
    resizable: false,
    width: 50,
  },
];

export class FinanceOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        page: 1,
        limit: 25,
      },
      options: props.options,
      optionErrors: {},
    };
  }

  componentDidMount() {
    const {
      setButtons,
      setCrumbs,
      location: { pathname },
      loggedIn,
    } = this.props;

    if (loggedIn) {
      this.queryFinanceOptions();
    }

    const crumb = { title: 'Finance Options', link: pathname };
    setCrumbs([crumb]);
    setButtons(
      <TitleButtons
        isInLimit={() => this.isInLimit()}
        onClick={(event) => this.onButtonClick(event)}
      />,
    );
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const original =
      this.state.original && this.state.original.length
        ? this.state.original
        : newProps.options.map((option) => ({ ...option }));
    this.setState({
      options: newProps.options,
      original,
    });
  }

  componentWillUnmount() {
    const { setButtons, setCrumbs } = this.props;
    setCrumbs([]);
    setButtons();
  }

  onButtonClick = ({ source }) => {
    switch (source) {
      case 'new':
        return this.onNewClicked();
      case 'save':
        return this.onSaveClicked();
      default:
        return () => {};
    }
  };

  isInLimit = () => {
    const { maxFinanceOptionCount, optionsTotal } = this.props;

    const newObjectCount = this.state.options.filter((option) => option.isNew())
      .length;

    const currentTotal = optionsTotal + newObjectCount;

    const inLimit =
      maxFinanceOptionCount === -1
        ? true
        : currentTotal < maxFinanceOptionCount;
    return inLimit;
  };

  onNewClicked = () => {
    if (this.isInLimit()) {
      const newOption = {
        includedOffices: [],
        isActive: false,
      };

      const parseOption = new Parse.Object(
        'SSFinanceOption',
        {
          ...newOption,
        },
        {},
      );

      this.props.setFinanceOptions([
        parseOption,
        ...this.props.financeOptions.items,
      ]);
    }
  };

  onSaveClicked = () => {
    const optionErrors = {};
    this.state.options.forEach((option) => {
      if (
        !this.validateValue(option.get('bankName')) ||
        !this.validateValue(option.get('category')) ||
        !this.validateValue(option.get('feePercent')) ||
        !this.validateValue(option.get('interestRate')) ||
        !this.validateValue(option.get('name')) ||
        !this.validateValue(option.get('minAmount')) ||
        !this.validateValue(option.get('maxAmount')) ||
        !this.validateValue(option.get('note')) ||
        !this.validateValue(option.get('paymentFactor')) ||
        !this.validateValue(option.get('planCode')) ||
        !this.validateValue(option.get('term'))
      ) {
        optionErrors[option.id || option._localId] = true;
      }
    });
    if (Object.values(optionErrors).includes(true)) {
      this.setState(() => ({ optionErrors }));
      const message =
        'All fields must be filled in before saving a finance option.';
      AppToaster.show({ message, timeout: 3000 });
      return;
    }
    const updatedObjects = this.state.options.filter(
      (option) => option.dirtyKeys().length || option.isNew(),
    );
    Parse.Object.saveAll(updatedObjects)
      .then(() => {
        AppToaster.show({ message: 'All saved!', timeout: 3000 });
        this.setState({ original: [] });
        this.queryFinanceOptions();
      })
      .catch((error) => {
        AppToaster.show({ message: error.message, timeout: 3000 });
      });
  };

  onDeleteClick = async (financeOption) => {
    this.props.showDeleteConfirmation({
      message: `Are you Sure you want to delete ${financeOption.get('name')}`,
      title: 'Delete Finance Options',
      onConfirm: () => {
        this.props.deleteFinanceOption(financeOption);
      },
    });
  };

  onCellChanged = ({ value, row: option, key }) => {
    const { optionErrors } = this.state;
    delete optionErrors[option.id || option._localId];
    this.setState(() => ({ optionErrors }));
    if (key === 'includedOffices') {
      const offices = value.map((objectId) => {
        const office = new Parse.Object('Office');
        office.id = objectId;
        return office.toPointer();
      });
      option.set(key, offices);
    } else {
      option.set(key, value);
    }
    this.setState({ options: this.state.options, optionErrors });
    this.props.setFinanceOptions(this.state.options);
  };

  onTableSortEvent = (event) => {
    this.setState(
      {
        ...event,
      },
      () => this.queryFinanceOptions(),
    );
  };

  onPagenation = (paginationEvent) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          ...paginationEvent,
        },
      },
      () => this.queryFinanceOptions(),
    );
  };

  queryFinanceOptions = () => {
    const { page, limit } = this.state.pagination;
    const { sortKey, sortOption } = this.state;
    const skip = limit * (page - 1);
    this.props.startFetchFinanceOptions({
      limit,
      skip,
      sortOption,
      sortKey,
    });
  };

  handleGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    const options = [...this.state.options];
    for (let i = fromRow; i <= toRow; i += 1) {
      const rowToUpdate = options[i];
      const updatedRow = { ...rowToUpdate, ...updated };
      options[i] = updatedRow;
    }
    this.setState({ options });
  };

  handleGridSort = (sortColumn, sortDirection) => {
    const comparer = (a, b) => {
      if (sortDirection === 'ASC') {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      }
      if (sortDirection === 'DESC') {
        return a[sortColumn] < b[sortColumn] ? 1 : -1;
      }
      return a.feePercent > b.feePercent ? 1 : -1;
    };
    const options = this.state.options.sort(comparer);
    this.setState({ options });
  };

  onToolSelect = (tool) => {
    switch (tool.value) {
      case 'import':
        this.setState(() => ({ showImport: true }));
        break;
      case 'export':
        this.onExportFinanceOptions();
        break;
      default:
    }
  };

  onExportFinanceOptions = async () => {
    try {
      const message = await Parse.Cloud.run('exportFinanceOptions');
      AppToaster.show({ message, timeout: 3000 });
    } catch ({ message }) {
      AppToaster.show({ message, timeout: 5000 });
    }
  };

  onImportFinanceOptions = async (params) => {
    try {
      this.setState({ showImport: false });
      const message = await Parse.Cloud.run('importFinanceOptions', params);
      AppToaster.show({ message, timeout: 3000 });
    } catch ({ message }) {
      AppToaster.show({ message, timeout: 5000 });
    }
  };

  onImportFinanceOptionsError = (e) => {
    AppToaster.show({ message: e.message, timeout: 5000 });
  };

  validateValue = (value) => {
    if (value === 0) {
      return true;
    }
    return !!value;
  };

  render() {
    return (
      <div className="h-100 d-flex flex-column bg-white default-page-padding">
        <ImportModal
          title="Import Finance Options"
          show={this.state.showImport}
          onClose={() => this.setState({ showImport: false })}
          onSave={this.onImportFinanceOptions}
          onError={this.onImportFinanceOptionsError}
        />
        <DropDownGroup
          title="Tools"
          value={this.state.tool}
          options={tools}
          onChange={this.onToolSelect}
          closeMenuOnSelect
        />
        {!this.isInLimit() && (
          <p className="danger">
            Plan is limited to {this.props.maxFinanceOptionCount} Finance
            Options
          </p>
        )}
        <LeapStrechColumnWidthTable
          tableColumns={columns.filter((col) =>
            this.props.maxOfficeCount === 1
              ? col.key !== 'includedOffices'
              : true,
          )}
          tableData={this.state.options}
          onCellChange={this.onCellChanged}
          onDelete={this.onDeleteClick}
          onSort={this.onTableSortEvent}
          tableErrors={this.state.optionErrors}
        />
        <Paginator
          page={this.state.pagination.page}
          limit={this.state.pagination.limit}
          totalCount={this.props.optionsTotal}
          onLimitChanged={({ value }) =>
            this.onPagenation({ page: 1, limit: value })
          }
          pageRange={5}
          onPageClicked={(event) => this.onPagenation({ page: event })}
        />
      </div>
    );
  }
}

FinanceOptions.propTypes = {
  financeOptions: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        objectId: PropTypes.arrayOf(PropTypes.string),
      }),
    ),
  }).isRequired,
  optionsTotal: PropTypes.number,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      isActive: PropTypes.bool,
      bankName: PropTypes.string,
      category: PropTypes.string,
      name: PropTypes.string,
      note: PropTypes.string,
      planCode: PropTypes.string,
      minAmount: PropTypes.number,
      maxAmount: PropTypes.number,
      interestRate: PropTypes.number,
      term: PropTypes.number,
      paymentFactor: PropTypes.number,
      feePercent: PropTypes.number,
      includedOffices: PropTypes.arrayOf(
        PropTypes.shape({
          objectId: PropTypes.string,
        }),
      ),
    }),
  ),
  setFinanceOptions: PropTypes.func.isRequired,
  startFetchFinanceOptions: PropTypes.func.isRequired,
  deleteFinanceOption: PropTypes.func.isRequired,
  showDeleteConfirmation: PropTypes.func.isRequired,
  startExportPriceGuide: PropTypes.func.isRequired,
  maxFinanceOptionCount: PropTypes.number.isRequired,
  ...layoutContextPropTypes,
};

FinanceOptions.defaultProps = {
  optionsTotal: 0,
  options: [],
};

const TitleButtons = ({ onClick, isInLimit }) => (
  <>
    <TitleButton
      variant="success"
      disabled={!isInLimit()}
      onClick={() => onClick({ source: 'new' })}
      title="New"
    />
    <TitleButton
      variant="success"
      onClick={() => onClick({ source: 'save' })}
      title="Save"
    />
  </>
);

TitleButtons.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const mapStateToProps = ({ financeOptions, plan = {} }) => {
  const items = financeOptions.items || [];
  return {
    optionsTotal: financeOptions.count,
    options: items,
    financeOptions,
    maxFinanceOptionCount: plan.maxFinanceOptionCount,
    maxOfficeCount: plan.maxOfficeCount,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setFinanceOptions: (items) => dispatch(setFinanceOptions(items)),
  startFetchFinanceOptions: (params) =>
    dispatch(startFetchFinanceOptions(params)),
  deleteFinanceOption: (objectId) => dispatch(deleteFinanceOption(objectId)),
  showDeleteConfirmation: ({ message, title, onConfirm }) =>
    dispatch(
      showDeleteConfirmation({
        message,
        title,
        onConfirm,
      }),
    ),
  startExportPriceGuide: () => dispatch(startExportPriceGuide()),
  startImportPriceGuide: (params) => dispatch(startImportPriceGuide(params)),
});

export default withUserContext(
  withLayoutContext(
    connect(mapStateToProps, mapDispatchToProps)(FinanceOptions),
  ),
);
