/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'leap-menu-item';

import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import {
  startFetchLinkedMeasureSheetItems,
  setCategoryFilter,
  setSubCategoryFilter,
} from '../../actions/templateEdit';
import CategoriesDropDown from '../PriceGuide/CategoriesDropDown';
import SubCategoriesDropDown from '../PriceGuide/SubCategoriesDropDown';

import SlidingPane from '../SlidingPane';
import Paginator from '../Misc/Paginator';

export const itemLabel = (subCategory, itemName) => {
  if (subCategory) {
    return `${subCategory} - ${itemName}`;
  }
  return itemName;
};

class MeasureSheetItemSlideOutPanel extends React.Component {
  constructor(props) {
    super(props);
    let { selectedIds = [] } = props;
    if (typeof selectedIds === 'string') {
      selectedIds = [selectedIds];
    }
    this.state = {
      selectedIds,
      isLoading: false,
      page: 1,
      limit: 10,
    };
  }

  UNSAFE_componentWillReceiveProps = (newProps) => {
    if (typeof newProps.selectedIds === 'string') {
      const selectedIds = [newProps.selectedIds];
      this.setState({ selectedIds });
    } else {
      this.setState({ selectedIds: newProps.selectedIds });
    }
  };

  onRefreshClick = async () => {
    this.setState({ isLoading: true });
    await this.props.startFetchLinkedMeasureSheetItems({
      limit: this.state.limit,
      skip: 0,
      category: this.props.categoryFilter,
      subCategory: this.props.subCategoryFilter,
    });
    this.setState({ isLoading: false });
  };

  onCategoryChanged = async (category) => {
    this.props.setSubCategoryFilter(null);
    this.props.setCategoryFilter(category);
    this.setState({ isLoading: true });
    this.onDoneClicked(false);
    await this.props.startFetchLinkedMeasureSheetItems({
      limit: this.state.limit,
      skip: 0,
      category,
      subCategory: null,
    });
    this.setState({ isLoading: false, page: 1 });
  };

  onSubCategoryChanged = async (selectedSubCategory) => {
    this.props.setSubCategoryFilter(selectedSubCategory.value);
    this.setState({ isLoading: true });
    this.onDoneClicked(false);
    await this.props.startFetchLinkedMeasureSheetItems({
      limit: this.state.limit,
      skip: 0,
      category: this.props.categoryFilter,
      subCategory: selectedSubCategory.value,
    });
    this.setState({ isLoading: false, page: 1 });
  };

  onSelectItem = (checked, objectId) => {
    if (this.props.singleSelect) {
      if (checked) {
        this.setState({ selectedIds: [objectId] });
      } else {
        this.setState({ selectedIds: [] });
      }
    } else {
      const selectedIds = this.state.selectedIds.filter(
        (id) => id !== objectId,
      );
      if (checked) {
        selectedIds.push(objectId);
      }
      this.setState({ selectedIds });
    }
  };

  onSelectAll = async (checked) => {
    const allIds = this.props.allMeasureSheetItems.map(
      ({ objectId }) => objectId,
    );
    let selectedIds = this.state.selectedIds.filter(
      (id) => allIds.indexOf(id) === -1,
    );
    if (checked) {
      selectedIds = [...selectedIds, ...allIds];
    }
    this.setState({ selectedIds });
  };

  onClearClicked = () => {
    this.setState({ selectedIds: [] });
  };

  onDoneClicked = (closePanel) => {
    if (this.props.singleSelect) {
      this.props.onChange(this.state.selectedIds[0], closePanel);
    } else {
      this.props.onChange(this.state.selectedIds, closePanel);
    }
  };

  clearCategoryFilters = () => {
    this.onCategoryChanged('');
  };

  clearSubCategoryFilters = () => {
    this.onSubCategoryChanged({ value: null });
  };

  displayCount = () => {
    return this.state.selectedIds.length;
  };

  onPageClicked = (page) => {
    this.setState({ isLoading: true });
    this.onDoneClicked(false);
    this.setState({ page }, this.updateObjects);
    this.setState({ isLoading: false });
  };

  updateObjects = () => {
    const { limit, page } = this.state;
    const skip = limit * (page - 1);
    this.props.startFetchLinkedMeasureSheetItems({
      limit,
      skip,
      category: this.props.categoryFilter,
      subCategory: this.props.subCategoryFilter,
    });
  };

  render() {
    const {
      measureSheetItems,
      singleSelect,
      showCustomProduct,
      measureSheetItemTotal,
    } = this.props;

    const allSelected =
      this.state.selectedIds.length &&
      measureSheetItems.every(
        ({ objectId }) => this.state.selectedIds.indexOf(objectId) > -1,
      );
    return (
      <SlidingPane
        isOpen={this.props.isOpen}
        title={`${this.props.title} (${this.displayCount()})`}
        width="500px"
        onRefreshClick={this.onRefreshClick}
        onRequestClose={this.props.onClose}
        onClearClicked={this.onClearClicked}
        onDoneClicked={() => this.onDoneClicked(true)}
      >
        <div style={{ marginBottom: '10px', display: 'flex' }}>
          <div style={{ flex: '1', marginRight: 20 }}>
            <CategoriesDropDown
              onChange={({ value }) => this.onCategoryChanged(value)}
              value={[this.props.categoryFilter]}
              showCustomProduct={showCustomProduct}
            />
          </div>
          <Button onClick={this.clearCategoryFilters} minimal>
            Clear
          </Button>
        </div>
        <div style={{ marginBottom: '10px', display: 'flex' }}>
          <div style={{ flex: '1', marginRight: 20 }}>
            <SubCategoriesDropDown
              selectedCategories={[this.props.categoryFilter]}
              closeMenuOnSelect
              onChange={(value) => this.onSubCategoryChanged(value)}
              value={[this.props.subCategoryFilter]}
            />
          </div>
          <Button onClick={this.clearSubCategoryFilters} minimal>
            Clear
          </Button>
        </div>
        <Table striped bordered hover className="package-options-table">
          <thead>
            <tr>
              <th style={{ width: '25px' }}>
                {!singleSelect && (
                  <input
                    type="checkbox"
                    checked={allSelected}
                    onChange={(e) => this.onSelectAll(e.target.checked)}
                    title="Select All Items"
                  />
                )}
              </th>
              <th style={{ width: '50px' }}>Image</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {showCustomProduct &&
              this.props.categoryFilter === 'custom_product' && (
                <tr>
                  <td>
                    <input
                      style={{ cursor: 'pointer' }}
                      type="checkbox"
                      checked={
                        this.state.selectedIds.indexOf('custom_product') > -1
                      }
                      onChange={(e) =>
                        this.onSelectItem(e.target.checked, 'custom_product')
                      }
                    />
                  </td>
                  <td>
                    <img
                      alt="Custom Product"
                      src="/images/no_image.png"
                      style={{ width: '100%' }}
                    />
                  </td>
                  <td>All Custom Products</td>
                </tr>
              )}
            {measureSheetItems.length === 0 &&
              this.props.categoryFilter !== 'custom_product' && (
                <tr>
                  <td />
                  <td />
                  <td>
                    {this.state.isLoading
                      ? 'Loading...'
                      : 'Nothing results found'}
                  </td>
                </tr>
              )}
            {measureSheetItems.map(
              ({ subCategory, itemName, objectId, image }) => (
                <tr
                  key={objectId}
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    this.onSelectItem(
                      this.state.selectedIds.indexOf(objectId) === -1,
                      objectId,
                    )
                  }
                >
                  <td>
                    <input
                      style={{ cursor: 'pointer' }}
                      type="checkbox"
                      checked={this.state.selectedIds.indexOf(objectId) > -1}
                      onChange={(e) =>
                        this.onSelectItem(e.target.checked, objectId)
                      }
                    />
                  </td>
                  <td>
                    <img
                      alt={itemName}
                      src={image ? image.url : '/images/no_image.png'}
                      style={{ width: '100%' }}
                    />
                  </td>
                  <td>{itemLabel(subCategory, itemName)}</td>
                </tr>
              ),
            )}
          </tbody>
        </Table>
        {measureSheetItemTotal > 0 && measureSheetItems.length > 0 && (
          <Paginator
            noRowCount
            page={this.state.page}
            limit={this.state.limit}
            pageRange={5}
            onPageClicked={this.onPageClicked}
            totalCount={measureSheetItemTotal}
          />
        )}
      </SlidingPane>
    );
  }
}

MeasureSheetItemSlideOutPanel.propTypes = {
  startFetchLinkedMeasureSheetItems: PropTypes.func.isRequired,
  measureSheetItems: PropTypes.arrayOf(
    PropTypes.shape({
      objectId: PropTypes.string.isRequired,
      itemName: PropTypes.string,
      subCategory: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }),
    }),
  ),
  allMeasureSheetItems: PropTypes.arrayOf(
    PropTypes.shape({
      objectId: PropTypes.string.isRequired,
      itemName: PropTypes.string,
      subCategory: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }),
    }),
  ),
  subCategoryFilter: PropTypes.string,
  categoryFilter: PropTypes.string,
  setCategoryFilter: PropTypes.func.isRequired,
  selectedIds: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  setSubCategoryFilter: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  singleSelect: PropTypes.bool,
  title: PropTypes.string,
  showCustomProduct: PropTypes.bool,
  validLinkedItemCounts: PropTypes.shape({}).isRequired,
  editCellItem: PropTypes.shape({
    objectId: PropTypes.string.isRequired,
  }).isRequired,
  measureSheetItemTotal: PropTypes.number,
};

MeasureSheetItemSlideOutPanel.defaultProps = {
  measureSheetItems: [],
  allMeasureSheetItems: [],
  categoryFilter: '',
  subCategoryFilter: '',
  selectedIds: [],
  isOpen: false,
  singleSelect: false,
  title: '',
  showCustomProduct: false,
  measureSheetItemTotal: 0,
};

const mapStateToProps = ({ templatesEdit }) => ({
  measureSheetItems: templatesEdit.measureSheetItems,
  allMeasureSheetItems: templatesEdit.allMeasureSheetItems,
  categoryFilter: templatesEdit.categoryFilter,
  subCategoryFilter: templatesEdit.subCategoryFilter,
  validLinkedItemCounts: templatesEdit.validLinkedItemCounts,
  editCellItem: templatesEdit.editCellItem,
  measureSheetItemTotal: templatesEdit.measureSheetItemTotal,
});

const mapDispatchToProps = (dispatch) => ({
  startFetchLinkedMeasureSheetItems: ({ limit, skip, category, subCategory }) =>
    dispatch(
      startFetchLinkedMeasureSheetItems({
        limit,
        skip,
        category,
        subCategory,
      }),
    ),
  setCategoryFilter: (category) => dispatch(setCategoryFilter(category)),
  setSubCategoryFilter: (subCategory) =>
    dispatch(setSubCategoryFilter(subCategory)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MeasureSheetItemSlideOutPanel);
