/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import { SwitchGroup, TextGroup, ButtonGroup } from '../FormGroup';
import { startUpdateConfig } from '../../actions/auth';
import Panel from '../Panel';
import TitleButton from '../TitleButton';
import Copy from '../AppSettings/Copy';
import { pushToDataLayer } from '../../actions/tagManager';
import { showDeleteConfirmation } from '../../actions/deleteConfirmation';

const leapEnrollLink = 'https://enroll.svcfin.com/Dealer/Enroll?id=47733';
const prosellEnrollLink = 'https://enroll.svcfin.com/Dealer/Enroll?id=47737';
const prosellPartnerObjectId = 'fJ4DGcUOHQ';

export class ServiceFinance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dealerNumber: props.service.dealerNumber,
      isEnabled: props.service.isEnabled,
    };
  }

  componentDidMount() {
    const {
      setCrumbs,
      setButtons,
      location: { pathname },
    } = this.props;
    const crumb = { title: 'Service Finance', link: pathname };
    setCrumbs([crumb]);
    setButtons(
      <TitleButtons
        onClearClick={this.askForClearPermission}
        onCopyClicked={this.onCopyClicked}
        onSaveClicked={this.onSaveClicked}
      />,
    );
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      dealerNumber: newProps.service.dealerNumber,
      isEnabled: newProps.service.isEnabled,
    });
  }

  componentWillUnmount() {
    const { setCrumbs, setButtons } = this.props;
    setCrumbs([]);
    setButtons();
  }

  onIsEnabledChanged = (checked) => {
    this.setState({ isEnabled: checked });
    if (!checked) {
      const unset = Object.keys(this.state).reduce(
        (unset2, key) => ({ ...unset2, [key]: '' }),
        {},
      );
      this.setState({ ...unset });
      this.props.pushToDataLayer({
        event: 'integrationEvent',
        eventCategory: 'Integration',
        eventAction: 'Disable',
        eventLabel: 'ServiceFinance',
      });
    } else {
      this.props.pushToDataLayer({
        event: 'integrationEvent',
        eventCategory: 'Integration',
        eventAction: 'Enable',
        eventLabel: 'ServiceFinance',
      });
    }
  };

  askForClearPermission = () => {
    this.props.showDeleteConfirmation({
      message: `Are you sure you want to clear this integration ?`,
      title: 'Clear Integration ?',
      onConfirm: () => {
        this.onClearClick();
      },
    });
  };

  onClearClick = () => {
    this.onIsEnabledChanged(false);
  };

  onDealerNumberChanged = (value) => {
    this.setState({ dealerNumber: value });
  };

  onCopyClicked = () => {
    this.setState({ showCopy: true });
  };

  onSaveClicked = () => {
    this.props.startUpdateConfig({ serviceFinance: this.state });
  };

  enrollLink = () =>
    this.props.partner === prosellPartnerObjectId
      ? prosellEnrollLink
      : leapEnrollLink;

  render() {
    return (
      <>
        <Copy
          title="Copy Service Finance Settings"
          show={this.state.showCopy}
          warning="Warning! This will overwrite your current Service Finance settings"
          configKeys={['serviceFinance']}
          onClose={() => this.setState({ showCopy: false })}
        />
        <div className="default-page-padding">
          <Panel title="Service Finance">
            <div>
              <TextGroup
                title="Dealer Number"
                value={this.state.dealerNumber}
                placeholder="Dealer Number"
                onChange={this.onDealerNumberChanged}
              />
              <SwitchGroup
                title="Enabled"
                checked={this.state.isEnabled}
                onChange={this.onIsEnabledChanged}
              />
              <a
                href={this.enrollLink()}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }}
              >
                <ButtonGroup
                  title=""
                  buttonTitle="Enroll with Service"
                  variant="success"
                  onClick={() => {}}
                />
              </a>
            </div>
          </Panel>
        </div>
      </>
    );
  }
}

ServiceFinance.propTypes = {
  service: PropTypes.shape({
    dealerNumber: PropTypes.string.isRequired,
    isEnabled: PropTypes.bool.isRequired,
  }),
  startUpdateConfig: PropTypes.func.isRequired,
  partner: PropTypes.string,
  ...layoutContextPropTypes,
};

ServiceFinance.defaultProps = {
  service: {
    dealerNumber: '',
    isEnabled: false,
  },
  partner: undefined,
};

const TitleButtons = ({ onCopyClicked, onSaveClicked, onClearClick }) => (
  <>
    <TitleButton
      variant="warning"
      onClick={() => onClearClick()}
      title="Clear"
    />
    <TitleButton
      variant="primary"
      onClick={() => onCopyClicked({ source: 'copy' })}
      title="Copy"
    />
    <TitleButton
      variant="success"
      onClick={() => onSaveClicked({ source: 'save' })}
      title="Save"
    />
  </>
);

TitleButtons.propTypes = {
  onCopyClicked: PropTypes.func.isRequired,
  onSaveClicked: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth: { config } }) => ({
  service: config.serviceFinance,
  partner: config.company.partner ? config.company.partner.objectId : undefined,
});

const mapDispatchToProps = (dispatch) => ({
  startUpdateConfig: (updates) => dispatch(startUpdateConfig(updates)),
  pushToDataLayer: (variablesForLayer) =>
    dispatch(pushToDataLayer(variablesForLayer)),
  showDeleteConfirmation: (params) => dispatch(showDeleteConfirmation(params)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(ServiceFinance),
);
