/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Collapse } from '@blueprintjs/core';
import { jsx, css } from '@emotion/core';
import { createStyleElement } from '../../../utils/utils';
import MenuItem from './MenuItem';

const ParentMenuItem = ({ title, icon, subLinks, style }) => {
  const { pathname = '' } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    // If a child link's route is the current location, open the parent menu,
    // if the user navigates away from a child link's route, close the menu
    const activeChild = subLinks.some(({ anchor }) => {
      return pathname.toLowerCase().includes(anchor.toLowerCase());
    });
    setIsOpen(activeChild);
  }, [subLinks, pathname]);

  return (
    <li className="main-menu__parent-item">
      <button
        type="button"
        onClick={toggleOpen}
        className={`button text-left pl-0 d-flex align-items-center ${
          isOpen ? 'main-menu__active' : ''
        }`}
        css={css`
          ${createStyleElement(
            isOpen
              ? { ...style.button, ...style.button['&:hover'] }
              : style.button,
          )}
        `}
      >
        <span className={`left-icon ${icon} d-block`} />
        <p className="p-0 m-0 text-uppercase">{title}</p>
        <span
          className={`fa ${
            isOpen ? 'fa-chevron-up' : 'fa-chevron-down'
          } right-chevron`}
        />
      </button>
      <Collapse isOpen={isOpen}>
        <ul className="main-menu__sub-list">
          {subLinks.map(({ title: title_, anchor }) => (
            <MenuItem
              key={anchor}
              title={title_}
              anchor={anchor}
              style={style}
            />
          ))}
        </ul>
      </Collapse>
    </li>
  );
};

ParentMenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  subLinks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      anchor: PropTypes.string,
    }),
  ).isRequired,
  style: PropTypes.shape({
    button: PropTypes.object,
    dot: PropTypes.object,
  }),
};

ParentMenuItem.defaultProps = {
  style: { button: {} },
};

export default ParentMenuItem;
