/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { startResetPassword } from '../actions/auth';
import { TextGroup } from './FormGroup';

class ForgotPasswordModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: props.show,
      email: '',
    };
  }

  UNSAFE_componentWillReceiveProps({ show }) {
    this.setState({ show });
  }

  onEmailChanged = (email) => {
    this.setState({ email });
  };

  onSave = () => {
    this.props.startResetPassword(this.state.email, this.props.onClose);
  };

  render() {
    const { show } = this.state;
    return (
      <Modal show={show} onHide={this.props.onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextGroup
            title="Email"
            value={this.state.email}
            onChange={this.onEmailChanged}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={this.onSave}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

ForgotPasswordModal.propTypes = {
  show: PropTypes.bool,
  startResetPassword: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

ForgotPasswordModal.defaultProps = {
  show: false,
};

const mapDispatchToProps = (dispatch) => ({
  startResetPassword: (email, callback) =>
    dispatch(startResetPassword(email, callback)),
});

export default connect(undefined, mapDispatchToProps)(ForgotPasswordModal);
