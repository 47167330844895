import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Parse from 'parse';
import _ from 'lodash';
import { withLayoutContext, layoutContextPropTypes } from '../../Layout';
import Panel from '../../Panel';
import { TextGroupDebounce } from '../../FormGroup';
import ResourceCell from './SharedResourceCell';
import {
  startFetchSharedResources as startFetchSharedResourcesAction,
  setResourceFilter as setResourceFilterAction,
} from '../../../actions/resources';
import { selectResources } from '../../../selectors/resources';

class SharedResourceGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: '',
    };
  }

  componentDidMount() {
    const { match, startFetchSharedResources: startFetch } = this.props;
    this.updateCrumbs();
    startFetch(match.params.groupId);
  }

  componentDidUpdate(prevProps) {
    const {
      crumbs: oldCrumbs,
      sharedResourceGroup: oldSharedResourceGroup,
    } = prevProps;
    const { crumbs, sharedResourceGroup } = this.props;
    if (
      !_.isEqual(crumbs, oldCrumbs) ||
      !_.isEqual(sharedResourceGroup, oldSharedResourceGroup)
    ) {
      this.updateCrumbs();
    }
  }

  componentWillUnmount() {
    const { crumbs, setCrumbs } = this.props;
    const newCrumbs = [...crumbs];
    newCrumbs.pop();
    setCrumbs(newCrumbs);
  }

  onFilterChange = (filter) => {
    const { setResourceFilter } = this.props;
    this.setState(() => ({ filter }));
    setResourceFilter(filter);
  };

  updateCrumbs() {
    const {
      crumbs,
      location: { pathname },
      setCrumbs,
      sharedResourceGroup,
    } = this.props;
    if (crumbs.length > 0) {
      const resourceCrumb = {
        title: sharedResourceGroup.get('name') || 'Loading...',
        link: pathname,
      };
      const newCrumbs = [...crumbs];
      newCrumbs[2] = resourceCrumb;
      setCrumbs(newCrumbs);
    }
  }

  render() {
    const { filter } = this.state;
    const { sharedResourceGroup, items = [] } = this.props;
    return (
      <div className="default-page-padding">
        <Panel title={sharedResourceGroup.get('name') || 'Loading...'}>
          <TextGroupDebounce
            title="Filter by Name"
            value={filter}
            onChange={this.onFilterChange}
          />
          <hr />
          <br />
          <div className="resources-container">
            {items.map((item) => (
              <ResourceCell
                objectId={item.objectId}
                thumbnailURL={
                  item.thumbnail ? item.thumbnail.url : '/images/no_image.png'
                }
                fileURL={item.file ? item.file.url : item.url}
                name={item.name}
                key={item.objectId}
                isFolder={false}
                onFolderClick={() => {}}
              />
            ))}
          </div>
        </Panel>
      </div>
    );
  }
}

SharedResourceGroup.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      objectId: PropTypes.string.isRequired,
      name: PropTypes.string,
      thumbnail: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }),
      url: PropTypes.url,
      isLandscape: PropTypes.bool,
      fileType: PropTypes.oneOf([
        'pdf',
        'epub',
        'ppt',
        'avf',
        'url',
        'html',
        'folder',
      ]),
      file: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }),
      fileSize: PropTypes.number,
      category: PropTypes.string,
      pageNumber: PropTypes.number,
    }),
  ),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  }).isRequired,
  startFetchSharedResources: PropTypes.func.isRequired,
  sharedResourceGroup: PropTypes.instanceOf(Parse.Object),
  setResourceFilter: PropTypes.func.isRequired,
  ...layoutContextPropTypes,
};

SharedResourceGroup.defaultProps = {
  items: undefined,
  sharedResourceGroup: new Parse.Object('SharedResourceGroup'),
};

const mapStateToProps = ({
  resources: { sharedResources, sharedResourceGroup, isDownloaded, filter },
}) => ({
  items: selectResources(sharedResources, filter),
  sharedResourceGroup,
  isDownloaded,
});

const mapDispatchToProps = (dispatch) => ({
  startFetchSharedResources: (objectId) =>
    dispatch(startFetchSharedResourcesAction(objectId)),
  setResourceFilter: (filter) => dispatch(setResourceFilterAction(filter)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(SharedResourceGroup),
);
