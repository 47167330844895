/* eslint-disable import/no-named-as-default */
import React, { useContext, useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { LayoutContext } from '../../../Layout';
import CustomOptionsIndex from './CustomOptionsIndex';

const CustomOptions = () => {
  const { path } = useRouteMatch();
  const { crumbs, setCrumbs } = useContext(LayoutContext);

  useEffect(() => {
    const crumb = { title: 'Document Types', link: path };
    const newCrumbs = [...crumbs];
    newCrumbs[0] = crumb;
    setCrumbs(newCrumbs);

    return () => setCrumbs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Switch>
      <Route path={path} component={CustomOptionsIndex} />,
    </Switch>
  );
};

export default CustomOptions;
