/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Parse from 'parse';
import { TextGroup, TagGroup, DropDownGroup } from '../../../FormGroup';
import Panel from '../../../Panel';

const LeadPerfectionSection = (props) => {
  const defaultDocumentType = { value: '0', label: 'Default' };

  const [documentTypes, setDocumentTypes] = useState([
    { value: '0', label: 'Default' },
  ]);

  useEffect(() => {
    const fetchDocumentTypes = async () => {
      const result = await Parse.Cloud.run('LP_fetchDocumentTypes');
      const options = result.map(({ key, value }) => ({
        value: key,
        label: value,
      }));
      setDocumentTypes([defaultDocumentType, ...options]);
    };
    fetchDocumentTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Panel title="LeadPerfection">
      <div>
        <TextGroup
          title="File Name"
          value={props.value.fileName}
          placeholder="File Name"
          onChange={(value) =>
            props.onChange({
              key: 'leadPerfection',
              value: {
                ...props.value,
                fileName: value,
              },
            })
          }
          errorMessage={props.errors.leadPerfection.fileName}
        />
        <TextGroup
          title="File Description"
          value={props.value.fileDescription}
          placeholder="File Description"
          onChange={(value) =>
            props.onChange({
              key: 'leadPerfection',
              value: {
                ...props.value,
                fileDescription: value,
              },
            })
          }
          errorMessage={props.errors.leadPerfection.fileDescription}
        />
        <DropDownGroup
          title="Document Type"
          value={
            documentTypes.find(
              ({ value }) => value === props.value.documentType,
            ) || defaultDocumentType
          }
          onChange={({ value }) => {
            props.onChange({
              key: 'leadPerfection',
              value: {
                ...props.value,
                documentType: value,
              },
            });
          }}
          closeMenuOnSelect
          options={documentTypes}
          isClearable={false}
          errorMessage={props.errors.leadPerfection.documentType}
        />
        <TagGroup
          title="Backup Recipients"
          values={props.value.backupRecipients}
          placeholder="Add Email Addresses"
          onChange={(value) =>
            props.onChange({
              key: 'leadPerfection',
              value: {
                ...props.value,
                backupRecipients: value,
              },
            })
          }
          errorMessage={props.errors.leadPerfection.backupRecipients}
        />
      </div>
    </Panel>
  );
};

LeadPerfectionSection.propTypes = {
  value: PropTypes.shape({
    fileName: PropTypes.string,
    fileDescription: PropTypes.string,
    backupRecipients: PropTypes.arrayOf(PropTypes.string),
    documentType: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    leadPerfection: PropTypes.shape({
      fileName: PropTypes.string,
      fileDescription: PropTypes.string,
      backupRecipients: PropTypes.string,
      documentType: PropTypes.string,
    }).isRequired,
  }),
};

LeadPerfectionSection.defaultProps = {
  errors: {
    leadPerfection: {
      fileName: '',
      fileDescription: '',
      backupRecipients: '',
      documentType: '',
    },
  },
};

export default LeadPerfectionSection;
