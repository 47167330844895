/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import { locationPropType } from '../../../router';
import { withLayoutContext, layoutContextPropTypes } from '../../Layout';
import FormGroup, { SwitchGroup, ButtonGroup } from '../../FormGroup';
import { startUpdateGroupMe as startUpdateGroupMeAction } from '../../../actions/auth';
import GroupsDropDown from './DropDown';
import Panel from '../../Panel';
import TitleButton from '../../TitleButton';
import Copy from '../../AppSettings/Copy';
import { showDeleteConfirmation as showDeleteConfirmationAction } from '../../../actions/deleteConfirmation';
import { pushToDataLayer as pushToDataLayerAction } from '../../../actions/tagManager';

export class GroupMe extends React.Component {
  constructor(props) {
    super(props);
    const {
      botId,
      botName,
      groupId,
      groupName,
      botAvatarURL,
      token,
      enabled = false,
      sale = { messages: [''] },
      noSale = { messages: [''] },
    } = props.groupMe;

    const {
      location: { search = '' } = {},
      startUpdateGroupMe = () => {},
      groupMe,
    } = props;

    const newToken = search.replace('?access_token=', '');
    if (newToken && newToken.length && newToken !== token) {
      startUpdateGroupMe({
        ...groupMe,
        enabled: true,
        token: newToken,
      });
    }

    this.state = {
      botId,
      botName,
      groupId,
      groupName,
      botAvatarURL,
      token,
      enabled,
      sale,
      noSale,
    };
  }

  componentDidMount() {
    const {
      setCrumbs,
      setButtons,
      location: { pathname },
    } = this.props;

    const crumb = { title: 'GroupMe', link: pathname };
    setCrumbs([crumb]);

    setButtons(this.buttons());
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      groupMe: prevGroupMe,
      showDeleteConfirmation: prevShowDeleteConfirmation,
      startUpdateGroupMe: prevStartUpdateGroupMe,
    } = prevProps;
    const {
      groupMe,
      setButtons,
      showDeleteConfirmation,
      startUpdateGroupMe,
    } = this.props;

    if (
      !_.isEqual(showDeleteConfirmation, prevShowDeleteConfirmation) ||
      !_.isEqual(startUpdateGroupMe, prevStartUpdateGroupMe)
    ) {
      setButtons(this.buttons());
    }

    if (!_.isEqual(groupMe, prevGroupMe)) {
      const {
        botId,
        botName,
        groupId,
        groupName,
        botAvatarURL,
        token,
        enabled,
        sale = { messages: [''] },
        noSale = { messages: [''] },
      } = groupMe;
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        botId,
        botName,
        groupId,
        groupName,
        botAvatarURL,
        token,
        enabled,
        sale,
        noSale,
      });
    }
  }

  componentWillUnmount() {
    const { setCrumbs, setButtons } = this.props;

    setCrumbs([]);
    setButtons();
  }

  buttons = () => (
    <TitleButtons
      onClearClick={this.askForClearPermission}
      onCopyClicked={this.onCopyClicked}
      onSaveClicked={this.onSaveClicked}
    />
  );

  onEnabledChanged = (checked) => {
    const { pushToDataLayer } = this.props;

    this.setState({ enabled: checked });

    if (!checked) {
      const unset = Object.keys(this.state).reduce(
        (unset2, key) => ({ ...unset2, [key]: '' }),
        {},
      );
      this.setState({ ...unset });
      pushToDataLayer({
        event: 'integrationEvent',
        eventCategory: 'Integration',
        eventAction: 'Disable',
        eventLabel: 'Group Me',
      });
    } else {
      pushToDataLayer({
        event: 'integrationEvent',
        eventCategory: 'Integration',
        eventAction: 'Enable',
        eventLabel: 'Group Me',
      });
    }
  };

  askForClearPermission = () => {
    const { showDeleteConfirmation } = this.props;

    showDeleteConfirmation({
      message: `Are you sure you want to clear this integration ?`,
      title: 'Clear Integration ?',
      onConfirm: () => {
        this.onClearClick();
      },
    });
  };

  onClearClick = () => {
    this.onEnabledChanged(false);
  };

  onAuthenticateClicked = () => {
    window.location.assign(
      `https://oauth.groupme.com/oauth/authorize?client_id=${process.env.REACT_APP_GROUP_ME_CLIENT_ID}`,
    );
  };

  onGroupChange = ({ value }) => {
    this.setState({ groupId: value });
  };

  onPostEnabledChanged = (checked, key) => {
    const { [key]: object } = this.state;

    object.enabled = checked;
    const changes = {};
    changes[key] = object;
    this.setState(changes);
  };

  onMessageDelete = (index, key) => {
    const { [key]: object } = this.state;

    object.messages.splice(index, 1);
    const changes = {};
    changes[key] = object;
    this.setState(changes);
  };

  onMessageChanged = (value, index, key) => {
    const { [key]: object } = this.state;

    object.messages[index] = value;
    const changes = {};
    changes[key] = object;
    this.setState(changes);
  };

  onAddMessageClicked = (key) => {
    const { [key]: object } = this.state;

    const messages = object.messages || [];
    messages.unshift('');
    object.messages = messages;
    const changes = {};
    changes[key] = object;
    this.setState(changes);
  };

  onCopyClicked = () => {
    this.setState({ showCopy: true });
  };

  onSaveClicked = () => {
    const { startUpdateGroupMe } = this.props;

    startUpdateGroupMe(this.state);
  };

  render() {
    const { showCopy, enabled, groupId, sale, noSale } = this.state;

    return (
      <>
        <Copy
          title="Copy GroupMe Settings"
          show={showCopy}
          warning="Warning! This will overwrite your current GroupMe settings"
          configKeys={['groupMe']}
          onClose={() => this.setState({ showCopy: false })}
        />
        <div className="default-page-padding">
          <Panel title="GroupMe">
            <div>
              <SwitchGroup
                title="Enabled"
                checked={enabled}
                onChange={this.onEnabledChanged}
              />
              {enabled && (
                <div>
                  <ButtonGroup
                    title="GroupMe Authentication"
                    buttonTitle="Authenticate"
                    variant="primary"
                    onClick={this.onAuthenticateClicked}
                  />
                  <FormGroup title="Chat Group">
                    <GroupsDropDown
                      onChange={this.onGroupChange}
                      selectedGroupId={groupId}
                    />
                  </FormGroup>
                  <SwitchGroup
                    title="Post Messages For Sales"
                    checked={sale.enabled}
                    onChange={(checked) =>
                      this.onPostEnabledChanged(checked, 'sale')
                    }
                  />
                  {sale.enabled && (
                    <MesesagesTable
                      source="sale"
                      title="Sale Messages"
                      onAddClicked={this.onAddMessageClicked}
                      messages={sale.messages}
                      onChanged={this.onMessageChanged}
                      onDelete={this.onMessageDelete}
                    />
                  )}
                  <SwitchGroup
                    title="Post Messages For No-Sales"
                    checked={noSale.enabled}
                    onChange={(checked) =>
                      this.onPostEnabledChanged(checked, 'noSale')
                    }
                  />
                  {noSale.enabled && (
                    <MesesagesTable
                      source="noSale"
                      title="No-Sale Messages"
                      onAddClicked={this.onAddMessageClicked}
                      messages={noSale.messages}
                      onChanged={this.onMessageChanged}
                      onDelete={this.onMessageDelete}
                    />
                  )}
                </div>
              )}
            </div>
          </Panel>
        </div>
      </>
    );
  }
}

GroupMe.propTypes = {
  groupMe: PropTypes.shape({
    botId: PropTypes.string,
    botName: PropTypes.string,
    groupId: PropTypes.string,
    groupName: PropTypes.string,
    botAvatarURL: PropTypes.string,
    token: PropTypes.string,
    enabled: PropTypes.bool,
    sale: PropTypes.shape({
      enabled: PropTypes.bool,
      messages: PropTypes.arrayOf(PropTypes.string),
    }),
    noSale: PropTypes.shape({
      enabled: PropTypes.bool,
      messages: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  location: locationPropType.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      access_token: PropTypes.string,
    }).isRequired,
  }).isRequired,
  startUpdateGroupMe: PropTypes.func.isRequired,
  ...layoutContextPropTypes,
};

GroupMe.defaultProps = {
  groupMe: {
    enabled: false,
    sale: {
      enabled: false,
      messages: [],
    },
    noSale: {
      enabled: false,
      messages: [],
    },
  },
};

const MesesagesTable = ({
  source,
  title,
  onAddClicked,
  messages,
  onChanged,
  onDelete,
}) => (
  <Table striped bordered hover>
    <thead>
      <tr>
        <th>{title}</th>
        <th style={{ width: '35px' }}>
          <button type="button" onClick={() => onAddClicked(source)}>
            Add
          </button>
        </th>
      </tr>
    </thead>
    <tbody>
      {messages.map((message, index) => (
        <tr key={index}>
          <td>
            <input
              className="table-row__cell__input"
              type="text"
              value={message}
              placeholder="Message"
              onChange={(e) => onChanged(e.target.value, index, source)}
            />
          </td>
          <td>
            <button
              type="button"
              className="fill-cell danger"
              onClick={() => onDelete(index, source)}
            >
              <i className="far fa-trash-alt" />
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  </Table>
);

MesesagesTable.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.string),
  onAddClicked: PropTypes.func.isRequired,
  onChanged: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  source: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

MesesagesTable.defaultProps = {
  messages: [],
};

const TitleButtons = ({ onCopyClicked, onSaveClicked, onClearClick }) => (
  <>
    <TitleButton
      variant="warning"
      onClick={() => onClearClick()}
      title="Clear"
    />
    <TitleButton
      variant="primary"
      onClick={() => onCopyClicked({ source: 'copy' })}
      title="Copy"
    />
    <TitleButton
      variant="success"
      onClick={() => onSaveClicked({ source: 'save' })}
      title="Save"
    />
  </>
);

TitleButtons.propTypes = {
  onCopyClicked: PropTypes.func.isRequired,
  onSaveClicked: PropTypes.func.isRequired,
  onClearClick: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth: { config } }) => ({
  groupMe: config.groupMe,
});

const mapDispatchToProps = (dispatch) => ({
  startUpdateGroupMe: (updates) => dispatch(startUpdateGroupMeAction(updates)),
  pushToDataLayer: (variablesForLayer) =>
    dispatch(pushToDataLayerAction(variablesForLayer)),
  showDeleteConfirmation: (params) =>
    dispatch(showDeleteConfirmationAction(params)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(GroupMe),
);
