import React from 'react';
// import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import Parse from 'parse';
import TitleButton from '../../TitleButton';
import Select from '../../Misc/Select';
import { history } from '../../../router';
import { logout, startChangeOffice, handleError } from '../../../actions/auth';
import { pushToDataLayer } from '../../../actions/tagManager';
import { setSelectedOffice } from '../../../data/repo/navigation';
import { randomKey } from '../../../utils/utils';

import './index.scss';

const getHeaderValues = ({
  auth: {
    user = {},
    company = {},
    offices = [],
    selectedOffice: { id: selectedOfficeId = '' } = {},
    isCorporateAdmin = false,
  } = {},
  config = {},
  plan = {},
}) => {
  const { styles: { Header = {} } = {} } = config;
  const { header = {} } = Header;
  const headerStyle = header;
  const { maxOfficeCount, allowedApps } = plan;
  return {
    allowedApps,
    hideOffices: maxOfficeCount === 1,
    user,
    company,
    offices: Array.isArray(offices)
      ? offices.map((office) => ({
          value: office.id,
          label: office.get('name'),
        }))
      : [],
    selectedOfficeId,
    isCorporateAdmin,
    headerStyle,
  };
};

const Header = () => {
  const dispatch = useDispatch();

  const {
    allowedApps = [],
    hideOffices,
    user,
    company,
    offices,
    selectedOfficeId,
    isCorporateAdmin,
    headerStyle,
  } = useSelector(getHeaderValues);

  const userName =
    user && user.get
      ? `${user.get('nameFirst')} ${user.get('nameLast')}`
      : 'user name';
  const selectedId = selectedOfficeId;
  let selectedOffice = offices.find((office) => selectedId === office.value);
  selectedOffice = selectedOffice || offices[0];

  const onChangeDashboard = async () => {
    try {
      const key = randomKey(10);
      const { sessionId } = await Parse.Cloud.run('generate_temp_session', {
        key,
      });
      window.open(
        `${process.env.REACT_APP_DASH360_URL}?sessionId=${sessionId}&key=${key}`,
        '_self',
      );
    } catch (error) {
      dispatch(handleError(error));
    }
  };

  const onLogoutChange = async () => {
    await setSelectedOffice(null);
    dispatch(
      pushToDataLayer({
        event: 'topNavigationEvent',
        eventCategory: 'Top Navigation',
        eventAction: 'Click',
        eventLabel: 'Logout',
      }),
    );
    dispatch(logout());
  };

  const onOfficeChange = (e) => {
    dispatch(
      pushToDataLayer({
        event: 'topNavigationEvent',
        eventCategory: 'Top Navigation',
        eventAction: 'Click',
        eventLabel: 'OfficeChange',
      }),
    );

    dispatch(startChangeOffice(e.value));
  };

  const onCorporateAdminClick = () => {
    dispatch(
      pushToDataLayer({
        event: 'topNavigationEvent',
        eventCategory: 'Top Navigation',
        eventAction: 'Click',
        eventLabel: 'onCorporateAdminClick',
      }),
    );
    history.push('/change_company');
  };

  return (
    <header
      className="header d-flex h-100 align-items-center"
      style={headerStyle}
    >
      <div className="header__new-dashboard-button-container">
        {allowedApps.includes('DB3') && (
          <div className="header__new-dashboard-button">
            <button onClick={onChangeDashboard} type="button">
              <img src="/images/toggle.png" alt="toggle" />
              <span>Try the Redesigned Dashboard!</span>
            </button>
          </div>
        )}
        <h1
          className="header__company-name p-2"
          style={headerStyle.companyName}
        >
          {company && company.get && company.get('name')}
        </h1>
      </div>
      <Select
        className={`p-2${hideOffices ? ' d-none' : ''}`}
        onChange={onOfficeChange}
        value={selectedOffice}
        options={offices}
        isSearchable
        isClearable={false}
      />
      <DropdownButton
        className="leap-drop-down p-2"
        bsPrefix="default"
        title={<span className="fas fa-user" style={{ textAlign: 'center' }} />}
      >
        <Dropdown.Header>{userName}</Dropdown.Header>
        <Dropdown.Item className="no-underline">
          {isCorporateAdmin && (
            <TitleButton
              block
              onClick={onCorporateAdminClick}
              variant="primary"
              title="Corporate Admin"
            />
          )}
        </Dropdown.Item>
        <Dropdown.Item className="no-underline">
          <TitleButton
            block
            onClick={onLogoutChange}
            variant="success"
            title="Logout"
          />
        </Dropdown.Item>
      </DropdownButton>
    </header>
  );
};

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
