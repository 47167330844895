/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-shadow */
/* eslint-disable react/no-deprecated */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import { TextGroup, SwitchGroup } from '../FormGroup';
import { startUpdateConfig } from '../../actions/auth';
import Panel from '../Panel';
import TitleButton from '../TitleButton';
import Copy from '../AppSettings/Copy';
import { showDeleteConfirmation } from '../../actions/deleteConfirmation';
import { pushToDataLayer } from '../../actions/tagManager';

const letterNumberRegex = /[^a-z0-9]/i;

export class DividendFinance extends React.Component {
  constructor(props) {
    super(props);
    const { dividend = {} } = props;
    const { installerId = '' } = dividend;
    this.state = {
      enabled: dividend.enabled || false,
      installerId,
    };
  }

  componentDidMount() {
    const {
      setCrumbs,
      setButtons,
      location: { pathname },
    } = this.props;
    const crumb = { title: 'Dividend Finance', link: pathname };
    setCrumbs([crumb]);
    setButtons(
      <TitleButtons
        disableSave={
          this.validateInstallerId(this.state.installerId) === 'Invalid Format'
        }
        onClearClick={this.askForClearPermission}
        onCopyClicked={this.onCopyClicked}
        onSaveClicked={this.onSaveClicked}
      />,
    );
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { dividend = {} } = newProps;
    const { installerId = '' } = dividend;
    this.setState({
      enabled: dividend.enabled || false,
      installerId,
    });
  }

  componentWillUnmount() {
    const { setCrumbs, setButtons } = this.props;
    setCrumbs([]);
    setButtons();
  }

  onEnabledChanged = (checked) => {
    this.setState({ enabled: checked });
    if (!checked) {
      const unset = Object.keys(this.state).reduce(
        (unset, key) => ({ ...unset, [key]: '' }),
        {},
      );
      this.setState({ ...unset });
      this.props.pushToDataLayer({
        event: 'integrationEvent',
        eventCategory: 'Integration',
        eventAction: 'Disable',
        eventLabel: 'DividendFinance',
      });
    } else {
      this.props.pushToDataLayer({
        event: 'integrationEvent',
        eventCategory: 'Integration',
        eventAction: 'Enable',
        eventLabel: 'DividendFinance',
      });
    }
  };

  askForClearPermission = () => {
    this.props.showDeleteConfirmation({
      message: 'Are you sure you want to clear this integration ?',
      title: 'Clear Integration ?',
      onConfirm: () => {
        this.onClearClick();
      },
    });
  };

  onClearClick = () => {
    this.onEnabledChanged(false);
  };

  onInstallerIdChanged = (value) => {
    this.setState({ installerId: value });
  };

  validateInstallerId = (value) => {
    const result = letterNumberRegex.test(value);
    if (result) {
      return 'Invalid Format';
    }
  };

  onCopyClicked = () => {
    this.setState({ showCopy: true });
  };

  onSaveClicked = () => {
    const updates = {
      enabled: this.state.enabled,
      installerId: this.state.installerId,
    };
    if (!this.validateInstallerId(this.state.installerId)) {
      this.props.startUpdateConfig({ dividend: updates });
    }
  };

  render() {
    return (
      <>
        <Copy
          title="Copy Dividend Finance Settings"
          show={this.state.showCopy}
          warning="Warning! This will overwrite your current Dividend Finance settings"
          configKeys={['dividend']}
          onClose={() => this.setState({ showCopy: false })}
        />
        <Panel title="Dividend Finance">
          <div>
            <SwitchGroup
              title="Enabled"
              checked={this.state.enabled}
              onChange={this.onEnabledChanged}
            />
            {this.state.enabled && (
              <div>
                <TextGroup
                  errorMessage={this.validateInstallerId(
                    this.state.installerId,
                  )}
                  title="Installer Id"
                  value={this.state.installerId}
                  placeholder="Installer Id"
                  onChange={this.onInstallerIdChanged}
                />
              </div>
            )}
          </div>
        </Panel>
      </>
    );
  }
}

DividendFinance.propTypes = {
  startUpdateConfig: PropTypes.func.isRequired,
  dividend: PropTypes.shape({
    enabled: PropTypes.bool,
    installerId: PropTypes.string,
  }),
  ...layoutContextPropTypes,
};

DividendFinance.defaultProps = {
  dividend: {
    enabled: false,
  },
};

const TitleButtons = ({
  onCopyClicked,
  onSaveClicked,
  onClearClick,
  disableSave,
}) => (
  <>
    <TitleButton
      variant="warning"
      onClick={() => onClearClick()}
      title="Clear"
    />
    <TitleButton
      variant="primary"
      onClick={() => onCopyClicked({ source: 'copy' })}
      title="Copy"
    />
    <TitleButton
      disabled={disableSave}
      variant="success"
      onClick={() => onSaveClicked({ source: 'save' })}
      title="Save"
    />
  </>
);

TitleButtons.propTypes = {
  onCopyClicked: PropTypes.func.isRequired,
  onSaveClicked: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth: { config } }) => ({
  dividend: config.dividend,
});

const mapDispatchToProps = (dispatch) => ({
  startUpdateConfig: (updates) => dispatch(startUpdateConfig(updates)),
  pushToDataLayer: (variablesForLayer) =>
    dispatch(pushToDataLayer(variablesForLayer)),
  showDeleteConfirmation: (params) => dispatch(showDeleteConfirmation(params)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(DividendFinance),
);
