/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-deprecated */
/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { Droppable } from 'react-beautiful-dnd';
import { randomKey, reorder } from '../../../utils/utils';
import { setOnDragEndCallback } from '../../DropContext';
import { setAdditionalDetailsEdit } from '../../../actions/priceGuide2';
import DetailForm from './DetailForm';
import validationErrors from './validationErrors';
import tagRowUpdate from './tagRowUpdate';
import detailRowUpdate from './detailRowUpdate';

const droppableId = 'additionalDetailsEdit';

class AdditionalDetailsEditModal extends React.Component {
  constructor(props) {
    super(props);
    const newState = {
      objects: props.objects.map((object) => {
        const cellType = object.cellType || 'default';
        const objectId = object.objectId || randomKey(10);
        return {
          ...object,
          cellType,
          objectId,
        };
      }),
    };
    if (props.tagRow) {
      newState.tagRow = {
        ...props.tagRow,
        inputType: props.tagRow.inputType || 'default',
      };
    }
    newState.errors = validationErrors(newState);
    this.state = newState;
    setOnDragEndCallback(droppableId, (result) => {
      const ordered = reorder(
        this.state.objects,
        result.source.index,
        result.destination.index,
      );
      const newState = {
        ...this.state,
        objects: ordered,
      };
      newState.errors = validationErrors(newState);
      this.setState({ objects: ordered });
      this.props.setObjects(ordered, this.props.additionalDetails);
    });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const newState = {
      objects: newProps.objects.map((object) => {
        const cellType = object.cellType || 'default';
        const objectId = object.objectId || randomKey(10);
        return {
          ...object,
          cellType,
          objectId,
        };
      }),
    };
    if (newProps.tagRow) {
      newState.tagRow = {
        ...newProps.tagRow,
        inputType: newProps.tagRow.inputType || 'default',
      };
    }
    newState.errors = validationErrors(newState);
    this.setState(newState);
  }

  onAddClicked = () => {
    const inLimit = this.isInLimit();
    if (inLimit) {
      const objects = [...this.state.objects];
      objects.push({
        objectId: randomKey(10),
        cellType: 'textWords',
        inputType: 'default',
        placeholder: '',
        title: '',
        note: '',
        defaultValue: '',
        notAddedReplacement: '',
        shouldCopy: false,
        required: false,
      });
      const newState = {
        ...this.state,
        objects,
      };
      newState.errors = validationErrors(newState);
      this.setState(newState);
      this.props.setObjects(objects, this.props.additionalDetails);
    }
  };

  onValueChanged = ({ value, source, objectId }) => {
    const objects = this.state.objects.map((object) => {
      const updatedObject = { ...object };
      if (objectId === updatedObject.objectId) {
        updatedObject[source] = value;
      }
      return updatedObject;
    });
    const newState = {
      ...this.state,
      objects,
    };
    newState.errors = validationErrors(newState);
    this.setState(newState);
  };

  onTagValueChanged = ({ value, source }) => {
    const tagRow = {
      ...this.state.tagRow,
      [source]: value,
    };
    const newState = {
      ...this.state,
      tagRow,
    };
    newState.errors = validationErrors(newState);
    this.setState(newState);
    const withUpdates = {
      ...this.props.additionalDetails,
      objects: this.state.objects,
    };
    this.props.setTagRow(tagRow, withUpdates);
  };

  onDelete = (id) => {
    const objects = this.state.objects.filter(
      ({ objectId }) => objectId !== id,
    );
    const newState = {
      ...this.state,
      objects,
    };
    newState.errors = validationErrors(newState);
    this.setState(newState);
    this.props.setObjects(objects, this.props.additionalDetails);
  };

  hasErrors = () => {
    const { errors = {} } = this.state;
    const { objects = [], tagRow = {} } = errors;
    for (let i = 0; i < objects.length; i += 1) {
      const error = objects[i];
      const keys = Object.keys(error);
      for (let j = 0; j < keys.length; j += 1) {
        if (error[keys[j]]) {
          return true;
        }
      }
    }
    const { inputType, pickerValues } = tagRow;
    return !!inputType || !!pickerValues;
  };

  canSave = () =>
    this.props.maxAdditionalDetailsPerItemCount === -1
      ? true
      : this.state.objects.length <=
        this.props.maxAdditionalDetailsPerItemCount;

  isInLimit = () => {
    const { objects } = this.state;
    const { maxAdditionalDetailsPerItemCount } = this.props;
    const inLimit =
      maxAdditionalDetailsPerItemCount === -1
        ? true
        : objects.length < maxAdditionalDetailsPerItemCount;
    return inLimit;
  };

  render() {
    const { objects, tagRow, errors } = this.state;
    const { tagParams = {} } = tagRow || {};
    const inLimit = this.isInLimit();
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onClose}
        dialogClassName="price-guide__edit-msi-modal-additional-details"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Additional Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <button
            className="btn btn-primary"
            onClick={this.onAddClicked}
            color="green"
          >
            Add
          </button>
          <Droppable droppableId={droppableId} direction="vertical">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="placeholders-widget-show my-1 mx-0 w-100"
              >
                {!!tagRow && (
                  <DetailForm
                    objectId="tag"
                    onChange={(event) => {
                      const { name, value } = tagRowUpdate(
                        event.currentTarget.name,
                        event.currentTarget.value,
                        this.state.tagRow,
                      );
                      this.onTagValueChanged({ source: name, value });
                    }}
                    detailObject={{ ...tagRow, ...tagParams }}
                    showNotAdded={this.props.showNotAdded}
                    index="tagRow"
                    errors={{
                      objects: {
                        tagRow: {
                          ...errors.tagRow,
                          ...(errors.tagRow.tagParams || {}),
                        },
                      },
                    }}
                    tagRow
                    isUpCharge={this.props.isUpCharge}
                  />
                )}
                {objects.map((detailObject, index) => {
                  return (
                    <DetailForm
                      draggable
                      key={detailObject.objectId}
                      detailObject={detailObject}
                      showNotAdded={this.props.showNotAdded}
                      index={index}
                      onChange={(event, detailObject) => {
                        const { name, value } = detailRowUpdate(
                          event.currentTarget.name,
                          event.currentTarget.value,
                        );
                        this.onValueChanged({
                          source: name,
                          value,
                          objectId: detailObject.objectId,
                        });
                      }}
                      onRemoveClick={this.onDelete}
                      errors={errors}
                      isUpCharge={this.props.isUpCharge}
                    />
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          {!inLimit ? (
            <p className="danger">
              Plan is limited to {this.props.maxAdditionalDetailsPerItemCount}{' '}
              additional details per item
            </p>
          ) : (
            <span />
          )}
          <Button
            onClick={() => this.props.onSave(objects, this.state.tagRow)}
            disabled={!this.canSave() || this.hasErrors()}
          >
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

AdditionalDetailsEditModal.propTypes = {
  maxAdditionalDetailsPerItemCount: PropTypes.number.isRequired,
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  objects: PropTypes.arrayOf(
    PropTypes.shape({
      objectId: PropTypes.string,
      cellType: PropTypes.string,
      inputType: PropTypes.string,
      placeholder: PropTypes.string,
      notAddedReplacement: PropTypes.string,
      pickerValues: PropTypes.arrayOf(PropTypes.string),
      title: PropTypes.string,
      note: PropTypes.string,
      defaultValue: PropTypes.string,
      shouldCopy: PropTypes.bool,
      required: PropTypes.bool,
    }),
  ),
  tagRow: PropTypes.shape({
    inputType: PropTypes.string,
    pickerValues: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
    note: PropTypes.string,
    defaultValue: PropTypes.string,
    shouldCopy: PropTypes.bool,
    required: PropTypes.bool,
  }),
  showNotAdded: PropTypes.bool,
  setObjects: PropTypes.func.isRequired,
  setTagRow: PropTypes.func.isRequired,
  additionalDetails: PropTypes.shape({
    objects: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    tagRow: PropTypes.shape({
      inputType: PropTypes.string,
      pickerValues: PropTypes.arrayOf(PropTypes.string),
      title: PropTypes.string,
      note: PropTypes.string,
      defaultValue: PropTypes.string,
      shouldCopy: PropTypes.bool,
      required: PropTypes.bool,
    }),
  }),
  isUpCharge: PropTypes.bool,
};

AdditionalDetailsEditModal.defaultProps = {
  show: false,
  objects: [],
  tagRow: undefined,
  onSave: () => {},
  showNotAdded: false,
  additionalDetails: undefined,
  isUpCharge: false,
};

const mapStateToProps = ({ priceGuide2, plan = {} }) => ({
  additionalDetails: priceGuide2.additionalDetails,
  maxAdditionalDetailsPerItemCount: plan.maxAdditionalDetailsPerItemCount,
});

const mapDispatchToProps = (dispatch) => ({
  setObjects: (objects, additionalDetails) =>
    dispatch(setAdditionalDetailsEdit({ ...additionalDetails, objects })),
  setTagRow: (tagRow, additionalDetails) =>
    dispatch(setAdditionalDetailsEdit({ ...additionalDetails, tagRow })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdditionalDetailsEditModal);
