const keys = [
  'title',
  'placeholder',
  'note',
  'cellType',
  'inputType',
  'required',
  'appKey',
  'apiKey',
  'pickerOptions',
  'isCustomerFillable',
  'value',
  'clearValueIfZero',
  'dateDisplayFormat',
  'cellId',
  'dynamicInputObject',
  'formula',
  'hideFromPDF',
  'loadFromEndpoint',
  'endpoint',
  'switchValueOn',
  'switchValueOff',
];

const cellTypes = [
  { value: 'switched', label: 'Switch' },
  { value: 'textWords', label: 'Text (Words)' },
  { value: 'textSentence', label: 'Text (Sentence)' },
  { value: 'textParagraph', label: 'Text (Paragraph)' },
  { value: 'disclosure', label: 'Disclosure' },
  { value: 'photos', label: 'Photo Selector' },
];

const applicantAppKeys = [
  { value: 'applicantFirstName', label: 'First Name' },
  { value: 'applicantMiddleInitial', label: 'Middle Initial' },
  { value: 'applicantLastName', label: 'Last Name' },
  { value: 'applicantStreetAddress', label: 'Street Address' },
  { value: 'applicantStreetNumber', label: 'Street Number' },
  { value: 'applicantOtherAddress', label: 'Other Address' },
  { value: 'applicantCity', label: 'City' },
  { value: 'applicantState', label: 'State' },
  { value: 'applicantZipCode', label: 'Zip Code' },
  { value: 'applicantZipCode4', label: 'Zip Code 4' },
  { value: 'applicantPhoneNumber', label: 'Phone Number' },
  { value: 'applicantCellPhoneNumber', label: 'Cell Phone Number' },
  { value: 'applicantMobileOptIn', label: 'Mobile Opt-In' },
  { value: 'applicantEmailAddress', label: 'Email' },
  { value: 'applicantEmployerName', label: 'Employer Name' },
  { value: 'applicantYearsOnJob', label: 'Years On Job' },
  { value: 'applicantEmployerPhoneNumber', label: 'Employer Phone Number' },
  { value: 'applicantSSN', label: 'Social Security Number' },
  { value: 'applicantDateOfBirth', label: 'Date of Birth' },
  { value: 'applicantGrossIncome', label: 'Gross Income' },
  { value: 'applicantOtherIncome', label: 'Other Income' },
  { value: 'applicantNameOnId', label: 'Name On ID' },
  { value: 'applicantIDNumber', label: 'ID Number' },
  { value: 'applicantTypeOfID', label: 'Type of ID' },
  { value: 'applicantStateOnID', label: 'Sate on ID' },
  { value: 'applicantIDExpiryDate', label: 'ID Exp. Date' },
  { value: 'applicantMarried', label: 'Married Indicator' },
];

const applicantSpouseAppKeys = [
  { value: 'applicantSpouseFirstName', label: 'First Name' },
  { value: 'applicantSpouseMiddleInitial', label: 'Middle Initial' },
  { value: 'applicantSpouseLastName', label: 'Last Name' },
  { value: 'applicantSpouseStreetNumber', label: 'Street Number' },
  { value: 'applicantSpouseStreetAddress', label: 'Street Address' },
  { value: 'applicantSpouseCity', label: 'City' },
  { value: 'applicantSpouseState', label: 'State' },
  { value: 'applicantSpouseZipCode', label: 'Zip Code' },
  { value: 'applicantSpouseOtherAddress', label: 'Other Address' },
];

const coApplicantAppKeys = [
  { value: 'coApplicantFirstName', label: 'First Name' },
  { value: 'coApplicantMiddleInitial', label: 'Middle Initial' },
  { value: 'coApplicantLastName', label: 'Last Name' },
  { value: 'coApplicantStreetAddress', label: 'Street Address' },
  { value: 'coApplicantStreetNumber', label: 'Street Number' },
  { value: 'coApplicantOtherAddress', label: 'Other Address' },
  { value: 'coApplicantCity', label: 'City' },
  { value: 'coApplicantState', label: 'State' },
  { value: 'coApplicantZipCode', label: 'Zip Code' },
  { value: 'coApplicantZipCode4', label: 'Zip Code 4' },
  { value: 'coApplicantPhoneNumber', label: 'Phone Number' },
  { value: 'coApplicantCellPhoneNumber', label: 'Cell Phone Number' },
  { value: 'coApplicantMobileOptIn', label: 'Mmobile Opt-In' },
  { value: 'coApplicantEmailAddress', label: 'Email' },
  { value: 'coApplicantEmployerName', label: 'Employer Name' },
  { value: 'coApplicantYearsOnJob', label: 'Years on Job' },
  { value: 'coApplicantEmployerPhoneNumber', label: 'Employer Phone Number' },
  { value: 'coApplicantSSN', label: 'Social Security Number' },
  { value: 'coApplicantDateOfBirth', label: 'Date of Birth' },
  { value: 'coApplicantGrossIncome', label: 'Gross Income' },
  { value: 'coApplicantOtherIncome', label: 'Other Income' },
  { value: 'coApplicantNameOnId', label: 'Name On ID' },
  { value: 'coApplicantIDNumber', label: 'ID Number' },
  { value: 'coApplicantTypeOfID', label: 'Type of ID' },
  { value: 'coApplicantStateOnID', label: 'State on ID' },
  { value: 'coApplicantIDExpiryDate', label: 'ID Exp. Date' },
  { value: 'coApplicantMarried', label: 'Married Indicator' },
];

const coApplicantSpouseAppKeys = [
  { value: 'coApplicantSpouseFirstName', label: 'First Name' },
  { value: 'coApplicantSpouseMiddleInitial', label: 'Middle Initial' },
  { value: 'coApplicantSpouseLastName', label: 'Last Name' },
  { value: 'coApplicantSpouseStreetNumber', label: 'Street Number' },
  { value: 'coApplicantSpouseStreetAddress', label: 'Street Address' },
  { value: 'coApplicantSpouseCity', label: 'City' },
  { value: 'coApplicantSpouseState', label: 'State' },
  { value: 'coApplicantSpouseZipCode', label: 'Zip Code' },
  { value: 'coApplicantSpouseOtherAddress', label: 'Other Address' },
];

const applicationAppKeys = [
  { value: 'coApplicantIndicator', label: 'Co-Applicant Indicator' },
  { value: 'contractLanguage', label: 'Contract Language' },
  { value: 'requestedLoanAmount', label: 'Requested Loan Amount' },
];

class CreditApplicationLineItem {
  constructor(obj, section, index) {
    Object.assign(this, obj);
    keys.forEach((key) => {
      this[key] = obj[key];
    });
    this.objectId = `${section}-${index}`;
    if (!this.cellType) {
      this.cellType = 'textWords';
    }
    if (!this.dynamicInputObject) {
      this.dynamicInputObject = {
        cellId: '',
        objects: [
          {
            inputType: 'default',
            pickerValues: [],
            enabled: false,
            values: ['default'],
            required: false,
            fractionDigits: '',
            defaultValue: '',
          },
        ],
      };
    }
  }

  get isFormulaCell() {
    switch (this.inputType) {
      case 'formula':
      case 'formulaWhole':
      case 'formulaCurrency':
      case 'formulaCurrencyWhole':
        return true;
      default:
        return false;
    }
  }

  newInstance() {
    return new CreditApplicationLineItem(this.toJSON());
  }

  toJSON() {
    const object = {};
    keys.forEach((key) => {
      object[key] = this[key];
    });
    return object;
  }

  getDynamicSelectedOptions = () => {
    if (!this.dynamicInputObject) {
      return [];
    }
    const otherObjects = this.dynamicInputObject.objects.filter(
      ({ values = [] }) => values.indexOf('default') === -1,
    );
    const selectedValues = [];
    otherObjects.forEach(({ values = [] }) => {
      values.forEach((value) => selectedValues.push(value));
    });
    return selectedValues;
  };

  static get CellTypes() {
    return cellTypes;
  }

  static get applicantAppKeys() {
    return applicantAppKeys;
  }

  static get applicantSpouseAppKeys() {
    return applicantSpouseAppKeys;
  }

  static get coApplicantAppKeys() {
    return coApplicantAppKeys;
  }

  static get coApplicantSpouseAppKeys() {
    return coApplicantSpouseAppKeys;
  }

  static get applicationAppKeys() {
    return applicationAppKeys;
  }

  static labelForAppKey(appKey) {
    const allKeys = [
      ...applicantAppKeys,
      ...applicantSpouseAppKeys,
      ...coApplicantAppKeys,
      ...coApplicantSpouseAppKeys,
      ...applicantAppKeys,
    ];
    const object = allKeys.find(({ value }) => value === appKey);
    return object ? object.label : appKey;
  }
}

export default CreditApplicationLineItem;
