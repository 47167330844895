import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useFilterFromUrl = () => {
  const location = useLocation();

  const [params, setParams] = useState({});

  useEffect(() => {
    const search = new URLSearchParams(location.search);

    const relatedPointerIds = search.get('ids')
      ? search.get('ids').split(',')
      : [];

    const categories = search.get('categories')
      ? search.get('categories').split(',')
      : [];

    const actions = search.get('actions')
      ? search.get('actions').split(',')
      : [];

    setParams({
      relatedPointerIds,
      categories,
      actions,
    });
  }, [location.search]);

  return {
    ...params,
  };
};

export default useFilterFromUrl;
