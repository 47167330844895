/* eslint-disable react/no-deprecated */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import Parse from 'parse';
import { isEqual } from 'lodash';
import { Table } from 'react-bootstrap';
import { Button } from '@blueprintjs/core';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import FormGroup, { TextGroup, DropDownGroup } from '../FormGroup';
import Select from '../Misc/Select';
import {
  startSavePackage,
  startFetchPackageOptions,
} from '../../actions/packages';
import OfficesDropDown from '../IncludedOfficesDropDown';
import Panel from '../Panel';
import TitleButton from '../TitleButton';
import Well from '../SharedComponents/Well';

export class Edit extends React.Component {
  constructor(props) {
    super(props);
    const itemJSON = props.item;
    itemJSON.category = itemJSON.category || props.selectedCategory;
    itemJSON.includedOffices = itemJSON.includedOffices || [];
    itemJSON.name = itemJSON.name || '';
    itemJSON.items = itemJSON.items || [];
    this.state = {
      item: itemJSON,
      options: props.options,
      fileURL: itemJSON.thumbnail
        ? itemJSON.thumbnail.url
        : '/images/no_image.png',
    };
    this.newFile = undefined;
  }

  async UNSAFE_componentWillMount() {
    if (this.props.match.params.id) {
      // this is the edit page
      const pkg = new Parse.Object('Package');
      pkg.id = this.props.match.params.id;
      await pkg.fetch();
      const item = pkg.toJSON();
      const fileURL = item.thumbnail
        ? item.thumbnail.url
        : '/images/no_image.png';
      this.newFile = item.thumbnail;
      this.setState({ item, fileURL }, () => {
        this.props.startFetchPackageOptions(
          this.state.item.category,
          this.state.item.includedOffices,
        );
      });
      return;
    }
    this.props.startFetchPackageOptions(
      this.state.item.category,
      this.state.item.includedOffices,
    );
  }

  componentDidMount() {
    const {
      crumbs,
      setCrumbs,
      setButtons,
      location: { pathname },
    } = this.props;
    const crumb = {
      title: `${pathname.includes('new') ? 'New' : 'Edit'} Package`,
      link: pathname,
    };
    crumbs[1] = crumb;
    setCrumbs(crumbs);
    setButtons(<TitleButtons disabled onSaveClicked={this.onSaveClicked} />);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const item = { ...this.state.item };
    if (!item.items || !item.items.length) {
      item.items = newProps.options.map((object) => ({
        itemObjectId: object.objectId,
        activeObjectId: object.items[0].objectId,
      }));
    }
    this.setState({
      options: newProps.options,
      item,
    });
  }

  componentDidUpdate(prevProps, previousState) {
    const changed = !isEqual(previousState, this.state);

    if (changed) {
      if (!this.state.item.name) {
        this.props.setButtons(<TitleButtons disabled />);
      } else {
        this.props.setButtons(
          <TitleButtons onSaveClicked={this.onSaveClicked} />,
        );
      }
    }
  }

  componentWillUnmount() {
    const { crumbs, setCrumbs, setButtons } = this.props;
    setCrumbs(crumbs.slice(0, 1));
    setButtons();
  }

  onFileChanged = (e) => {
    const file = e;
    const reader = new window.FileReader();
    reader.onload = (onLoad) => {
      this.setState({ fileURL: onLoad.target.result });
    };
    reader.readAsDataURL(file);
    const extension = file.type.split('/')[1];
    const name = `thumbnail.${extension}`;
    this.newFile = new Parse.File(name, file);
  };

  onNameChanged = (value) => {
    const item = { ...this.state.item };
    item.name = value;
    this.setState({ item });
  };

  onCategoryChanged = ({ value }) => {
    const item = { ...this.state.item };
    item.category = value;
    item.items = [];
    this.props.startFetchPackageOptions(value, item.includedOffices);
    this.setState({ item });
  };

  onIncludedOfficesChanged = (value) => {
    const item = { ...this.state.item };
    item.includedOffices = value.map((id) => {
      const office = new Parse.Object('Office');
      office.id = id;
      return office.toJSON();
    });
    this.props.startFetchPackageOptions(item.category, item.includedOffices);
    this.setState({ item });
  };

  onSelectedOptionChange = (itemObjectId, activeObjectId) => {
    const { items = [] } = this.state.item;
    let didFind = false;
    const newItems = items.map((object) => {
      if (object.itemObjectId === itemObjectId) {
        didFind = true;
        return {
          itemObjectId,
          activeObjectId,
        };
      }
      return object;
    });
    if (!didFind) {
      newItems.push({
        itemObjectId,
        activeObjectId,
      });
    }
    const item = {
      ...this.state.item,
      items: newItems,
    };
    this.setState({ item });
  };

  onSaveClicked = () => {
    if (this.state.item.name) {
      this.props.setButtons(<TitleButtons disabled />);
      this.props.startSavePackage(this.state.item, this.newFile);
    }
  };

  onRemoveThumbnail = () => {
    this.setState(() => ({ fileURL: '/images/no_image.png' }));
    this.newFile = undefined;
  };

  shouldShowSubCategory = () => {
    const options = this.state.options || [];
    for (let i = 0; i < options.length; i += 1) {
      if (options[i].subCategory) {
        return true;
      }
    }
    return false;
  };

  selectedOptionForItem = (itemObjectId) => {
    const { items = [] } = this.state.item;
    const object = items.find((option) => option.itemObjectId === itemObjectId);
    if (object) {
      return object.activeObjectId;
    }
    return null;
  };

  render() {
    return (
      <div className="default-page-padding">
        <Panel title={this.state.item.name}>
          <FormGroup title="Thumbnail">
            <Dropzone
              onDrop={(e) => {
                if (e[0]) {
                  this.onFileChanged(e[0]);
                }
              }}
              accept="image/*"
            >
              {({ getRootProps, getInputProps, isDragActive }) => (
                <div
                  {...getRootProps()}
                  style={{ minHeight: '255px', cursor: 'pointer' }}
                >
                  <input {...getInputProps()} />
                  <Well
                    bsSize="lg"
                    style={{
                      minHeight: '255px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {this.newFile ? (
                      <img
                        style={{ verticalAlign: 'top' }}
                        className="resources-thumbnail"
                        alt="Thumbnail"
                        src={this.state.fileURL}
                      />
                    ) : (
                      <Button
                        text={
                          isDragActive
                            ? 'Drop file here...'
                            : 'Drop or select thumbnail...'
                        }
                      />
                    )}
                  </Well>
                </div>
              )}
            </Dropzone>
            {this.newFile ? (
              <button
                onClick={this.onRemoveThumbnail}
                style={{
                  background: 'transparent',
                  border: 0,
                  alignSelf: 'flex-end',
                  position: 'absolute',
                }}
              >
                <i className="fas fa-times-circle danger" />
              </button>
            ) : (
              <div />
            )}
          </FormGroup>
          <TextGroup
            errorMessage={
              !this.state.item.name ? 'Name is Required' : undefined
            }
            title="Name"
            value={this.state.item.name}
            placeholder="Name"
            onChange={this.onNameChanged}
          />
          <DropDownGroup
            title="Category"
            value={{
              value: this.state.item.category,
              label: this.state.item.category,
            }}
            onChange={this.onCategoryChanged}
            closeMenuOnSelect
            options={this.props.categories}
            isClearable={false}
          />
          {this.props.maxOfficeCount !== 1 && (
            <FormGroup title="Included Offices">
              <OfficesDropDown
                onChange={this.onIncludedOfficesChanged}
                selected={this.state.item.includedOffices.map(
                  ({ objectId }) => objectId,
                )}
              />
            </FormGroup>
          )}
          <FormGroup title="Options">
            <Table striped bordered hover className="package-options-table">
              <thead>
                <tr>
                  <th>Image</th>
                  {this.shouldShowSubCategory() && <th>Sub Category</th>}
                  <th>Name</th>
                  <th>Option</th>
                </tr>
              </thead>
              <tbody>
                {this.state.options.map(
                  ({ subCategory, itemName, objectId, items = [], image }) => (
                    <tr key={objectId}>
                      <td style={{ width: '50px' }}>
                        <img
                          alt={itemName}
                          src={image ? image.url : '/images/no_image.png'}
                          style={{ width: '100%' }}
                        />
                      </td>
                      {this.shouldShowSubCategory() && (
                        <td style={{ width: '33%' }}>{subCategory}</td>
                      )}
                      <td
                        style={{
                          width: this.shouldShowSubCategory() ? '33%' : '50%',
                        }}
                      >
                        {itemName}
                      </td>
                      <td style={{ width: '50%' }}>
                        <Select
                          name="form-field-name"
                          value={this.selectedOptionForItem(objectId)}
                          onChange={({ value }) =>
                            this.onSelectedOptionChange(objectId, value)
                          }
                          closeMenuOnSelect
                          options={items.map((item) => ({
                            value: item.objectId,
                            label: item.displayTitle,
                          }))}
                          isClearable={false}
                        />
                      </td>
                    </tr>
                  ),
                )}
              </tbody>
            </Table>
          </FormGroup>
        </Panel>
      </div>
    );
  }
}

const packagePropType = {
  objectId: PropTypes.string,
  category: PropTypes.string,
  name: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      itemObjectId: PropTypes.string.isRequired,
      activeObjectId: PropTypes.string.isRequired,
    }),
  ),
};

const optionPropType = {
  objectId: PropTypes.string.isRequired,
  subCategory: PropTypes.string,
  itemName: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      objectId: PropTypes.string.isRequired,
      displayTitle: PropTypes.string.isRequired,
    }),
  ),
};

const categoryPropType = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

Edit.propTypes = {
  maxOfficeCount: PropTypes.number.isRequired,
  item: PropTypes.shape(packagePropType),
  selectedCategory: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape(optionPropType)),
  startFetchPackageOptions: PropTypes.func.isRequired,
  startSavePackage: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape(categoryPropType)).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  }).isRequired,
  ...layoutContextPropTypes,
};

Edit.defaultProps = {
  item: {},
  options: [],
};

const TitleButtons = ({ onSaveClicked = () => {}, disabled }) => (
  <div>
    <TitleButton
      disabled={disabled}
      variant="success"
      onClick={onSaveClicked}
      title="Save"
    />
  </div>
);

TitleButtons.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onSaveClicked: PropTypes.func.isRequired,
};

const mapStateToProps = (
  { packages: { items, options = [], category }, auth, plan = {} },
  props,
) => ({
  item: items.find((object) => object.objectId === props.match.params.id),
  options,
  maxOfficeCount: plan.maxOfficeCount,
  categories: auth.config.categories_.map((cat) => ({
    value: cat.name,
    label: cat.name,
  })),
  selectedCategory: category || auth.config.categories_[0].name,
});

const mapDispatchToProps = (dispatch) => ({
  startSavePackage: (object, file) => dispatch(startSavePackage(object, file)),
  startFetchPackageOptions: (category, offices) =>
    dispatch(startFetchPackageOptions(category, offices)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(Edit),
);
