export default (state = {}, action) => {
  switch (action.type) {
    case 'SHOW_SUCCESS':
      return {
        show: true,
        type: 'success',
        title: action.title,
        confirm: action.onConfirm,
        message: action.message,
      };
    case 'SHOW_ERROR':
      return {
        show: true,
        type: 'error',
        title: action.title,
        style: action.style || {},
        confirm: action.onConfirm,
        message: action.message,
      };
    case 'SHOW_WARNING':
      return {
        show: true,
        type: 'warning',
        title: action.title,
        confirm: action.onConfirm,
        message: action.message,
      };
    case 'HIDE_ALERT':
      return {
        show: false,
      };
    default:
      return state;
  }
};
