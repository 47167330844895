/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Parse from 'parse';
import {
  Alignment,
  Navbar,
  NavbarGroup,
  Button,
  Classes,
  Position,
  Popover,
} from 'leap-menu-item';
import TextWordsToolbox from './Toolbox/TextWords';

const BodyTitleToolbarStyle = {
  position: 'absolute',
  margin: '50px 0',
};

const BodyTitleToolbar = ({ application, cell }) => (
  <Navbar style={BodyTitleToolbarStyle}>
    <NavbarGroup align={Alignment.LEFT}>
      <Popover
        content={<TextWordsToolbox application={application} />}
        position={Position.BOTTOM}
      >
        <Button
          className={Classes.MINIMAL}
          text="Settings"
          disabled={!cell.cellType}
        />
      </Popover>
    </NavbarGroup>
  </Navbar>
);

BodyTitleToolbar.propTypes = {
  application: PropTypes.instanceOf(Parse.Object),
  cell: PropTypes.object,
};

BodyTitleToolbar.defaultProps = {
  application: undefined,
  cell: {},
};

export default BodyTitleToolbar;
