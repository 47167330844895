/* eslint-disable react/no-unused-state */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Classes, Menu, MenuItem, Button } from 'leap-menu-item';
import { DebounceInput } from 'react-debounce-input';
import {
  setEditCellItem,
  setCreditApplication,
} from '../../../actions/creditApplications';
import inputTypes from './InputTypes';
import Switch from '../../Misc/Switch';
import PickerOptionsEditor from '../../Templates/PickerOptionsEditor';
import DynamicObjectEditor from '../../Templates/Toolbox/BodyCell/DynamicObjectEditor';
import YesNoMenuItem from '../../Templates/Toolbox/YesNoMenuItem';
import CreditApplicationLineItem from '../Models/CreditApplicationLineItem';
import CreditApplicationObject from '../Models/CreditApplicationObject';
import { dateTimePlaceHolders } from '../../../utils/utils';
import FormulaEditor from './FormulaEditor';

const MenuFix = () => (
  <MenuItem style={{ display: 'none' }}>
    <MenuItem />
  </MenuItem>
);

const showCustomerFillable = (cellType) => {
  switch (cellType) {
    case 'photos':
      return false;
    default:
      return true;
  }
};
class TextWords extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMenus: {},
      showDefault: true,
    };
  }

  closeSubMenus = () => {
    this.setState({ dynamicMenus: {} });
  };

  updateCell = () => {
    const { editCellItem, application, setCell, setApp, commit } = this.props;
    const { cell, index } = editCellItem;
    const sections = application.get('sections');
    sections[index[0]].cells[index[1]] = cell;
    application.set('sections', sections);
    setCell(editCellItem);
    setApp(application);
    commit();
    this.setState(() => ({}));
  };

  openSubMenu = (menuKey) => {
    this.setState({ showDefault: false }, () => {
      this.setState({ showDefault: true, dynamicMenus: { [menuKey]: true } });
    });
  };

  render() {
    const {
      editCellItem: { cell },
      application,
    } = this.props;
    const { label: cellTypeLabel = '' } =
      CreditApplicationLineItem.CellTypes.find(
        ({ value }) => value === cell.cellType,
      ) || {};
    const { label: inputTypeLabel = '' } =
      inputTypes.find(({ value }) => value === cell.inputType) || {};

    const dynamicValueOptions = application.getDynamicValueOptionsForCellId(
      cell.dynamicInputObject.cellId,
    );
    const dynamicSelectedOptions = cell.getDynamicSelectedOptions();
    const defaultObject = cell.dynamicInputObject.objects.find(
      ({ values = [] }) => values.indexOf('default') === 0,
    );
    const otherObjects = cell.dynamicInputObject.objects.filter(
      ({ values = [] }) => values.indexOf('default') === -1,
    );
    return (
      <Menu className={Classes.ELEVATION_1}>
        <MenuFix />
        <MenuItem text="Cell Id" label={cell.cellId}>
          <DebounceInput
            minLength={0}
            debounceTimeout={300}
            value={cell.cellId}
            onChange={(e) => {
              cell.cellId = e.target.value;
              this.updateCell();
            }}
          />
        </MenuItem>
        <Menu.Divider />
        <MenuItem
          text="Cell Type"
          label={cellTypeLabel}
          shouldDismissPopover={false}
        >
          <Menu>
            {CreditApplicationLineItem.CellTypes.map((cellType) => (
              <MenuItem
                key={cellType.value}
                text={cellType.label}
                active={cellType.value === cell.cellType}
                onClick={() => {
                  cell.cellType = cellType.value;
                  cell.isCustomerFillable = false;
                  this.updateCell();
                }}
              />
            ))}
          </Menu>
        </MenuItem>

        {cell.cellType !== 'switched' &&
          cell.cellType !== 'photoPickerCell' &&
          cell.cellType !== 'disclosure' && (
            <MenuItem
              text="Input Type"
              label={inputTypeLabel}
              shouldDismissPopover={false}
            >
              <Menu>
                {inputTypes.map((inputType) => (
                  <MenuItem
                    key={inputType.value}
                    text={inputType.label}
                    active={inputType.value === cell.inputType}
                    shouldDismissPopover={false}
                    onClick={() => {
                      cell.inputType = inputType.value;
                      this.updateCell();
                    }}
                  />
                ))}
              </Menu>
            </MenuItem>
          )}

        {(cell.inputType === 'datePicker' ||
          cell.inputType === 'dateTimePicker' ||
          cell.inputType === 'timePicker') && (
          <MenuItem text="Date Format" label={cell.dateDisplayFormat}>
            <DebounceInput
              minLength={0}
              placeholder={dateTimePlaceHolders[cell.inputType]}
              debounceTimeout={300}
              value={cell.dateDisplayFormat}
              onChange={(e) => {
                cell.dateDisplayFormat = e.target.value;
                this.updateCell();
              }}
            />
          </MenuItem>
        )}

        {cell.cellType === 'switched' && (
          <>
            <MenuItem
              text="On Value"
              shouldDismissPopover={false}
              label={cell.switchValueOn}
            >
              <DebounceInput
                minLength={0}
                debounceTimeout={300}
                value={cell.switchValueOn}
                placeholder="YES"
                onChange={(e) => {
                  cell.switchValueOn = e.target.value;
                  this.updateCell();
                }}
              />
            </MenuItem>
            <MenuItem
              text="Off Value"
              shouldDismissPopover={false}
              label={cell.switchValueOff}
            >
              <DebounceInput
                minLength={0}
                debounceTimeout={300}
                value={cell.switchValueOff}
                placeholder="NO"
                onChange={(e) => {
                  cell.switchValueOff = e.target.value;
                  this.updateCell();
                }}
              />
            </MenuItem>
          </>
        )}
        {(cell.cellType === 'switched' || cell.cellType === 'disclosure') && (
          <YesNoMenuItem
            text="Dynamic"
            active={cell.inputType === 'dynamic'}
            onChange={(value) => {
              cell.inputType = value ? 'dynamic' : '';
              this.updateCell();
            }}
          />
        )}
        {cell.inputType === 'dynamic' && (
          <MenuItem text="Dynamic Settings" shouldDismissPopover={false}>
            <Menu>
              <MenuItem
                text="Dependent Cell"
                label={
                  cell.dynamicInputObject
                    ? cell.dynamicInputObject.cellId
                    : undefined
                }
              >
                <Menu>
                  {application
                    .getAllCellIds()
                    .filter((id) => id !== cell.cellId)
                    .map((cellId) => (
                      <MenuItem
                        key={cellId}
                        text={cellId}
                        shouldDismissPopover={false}
                        icon={
                          cellId === cell.dynamicInputObject.cellId
                            ? 'dot'
                            : 'blank'
                        }
                        active={cellId === cell.dynamicInputObject.cellId}
                        onClick={() => {
                          cell.dynamicInputObject.cellId = cellId;
                          this.updateCell();
                        }}
                      />
                    ))}
                </Menu>
              </MenuItem>
              <Menu.Divider />
              <MenuItem
                text="Conditions"
                shouldDismissPopover={false}
                labelElement={
                  <Button
                    icon="plus"
                    minimal
                    small
                    disabled={
                      dynamicValueOptions.length &&
                      dynamicSelectedOptions.length >=
                        dynamicValueOptions.length
                    }
                    onClick={() => {
                      cell.dynamicInputObject.objects.push({
                        inputType: 'default',
                        enabled: true,
                        required: false,
                        values: [],
                        fractionDigits: '',
                        pickerValues: [],
                      });
                      this.updateCell();
                    }}
                  />
                }
              />
              <Menu.Divider />
              <DynamicObjectEditor
                title="Default"
                menuKey="Default"
                holdMenuOpen={this.state.dynamicMenus.Default}
                object={defaultObject}
                hideValues
                openSubMenu={this.openSubMenu}
                onChange={(value) => {
                  cell.dynamicInputObject.objects[0] = value;
                  this.updateCell();
                }}
              />
              {otherObjects.map((object, index) => {
                const { values = [] } = object;
                const label =
                  values.length > 1 ? values.join(', ') : values.join('');
                const inactiveOptions = dynamicSelectedOptions.filter(
                  (value) => values.indexOf(value) === -1,
                );
                const hideInputType =
                  cell.cellType !== 'textWords' &&
                  cell.cellType !== 'textSentence' &&
                  cell.cellType !== 'textParagraph';

                const hideDefaultValue = cell.cellType === 'photoPickerCell';
                return (
                  <DynamicObjectEditor
                    // eslint-disable-next-line react/no-array-index-key
                    key={`dynamic-${index}`}
                    menuKey={`dynamic-${index}`}
                    title={label}
                    object={object}
                    holdMenuOpen={this.state.dynamicMenus[`dynamic-${index}`]}
                    openSubMenu={this.openSubMenu}
                    options={dynamicValueOptions}
                    inactiveOptions={inactiveOptions}
                    hideInputType={hideInputType}
                    hideDefaultValue={hideDefaultValue}
                    onChange={(value) => {
                      cell.dynamicInputObject.objects[index + 1] = value;
                      this.updateCell();
                    }}
                    onDelete={() => {
                      cell.dynamicInputObject.objects.splice(index + 1, 1);
                      this.updateCell();
                    }}
                  />
                );
              })}
            </Menu>
          </MenuItem>
        )}
        {cell.inputType === 'picker' && (
          <PickerOptionsEditor
            values={cell.pickerOptions}
            onChange={(values) => {
              cell.pickerOptions = values;
              this.updateCell();
            }}
            menuItemProps={{
              text: 'Picker Options',
            }}
          />
        )}
        <Menu.Divider />
        <MenuItem
          text="Required"
          shouldDismissPopover={false}
          label={
            <Switch
              checked={cell.required}
              onChange={() => {
                cell.required = !cell.required;
                this.updateCell();
              }}
            />
          }
        />
        {showCustomerFillable(cell.cellType) && (
          <MenuItem
            text="Customer Fillable"
            shouldDismissPopover={false}
            label={
              <Switch
                checked={cell.isCustomerFillable}
                onChange={() => {
                  cell.isCustomerFillable = !cell.isCustomerFillable;
                  this.updateCell();
                }}
              />
            }
          />
        )}
        <Menu.Divider />
        <MenuItem text="Title" label={cell.title}>
          <DebounceInput
            minLength={0}
            debounceTimeout={300}
            value={cell.title}
            onChange={(e) => {
              cell.title = e.target.value;
              this.updateCell();
            }}
          />
        </MenuItem>
        <MenuItem text="Placeholder" label={cell.placeholder}>
          <DebounceInput
            minLength={0}
            debounceTimeout={300}
            value={cell.placeholder}
            onChange={(e) => {
              cell.placeholder = e.target.value;
              this.updateCell();
            }}
          />
        </MenuItem>
        <MenuItem text="Note" label={cell.note}>
          <DebounceInput
            minLength={0}
            debounceTimeout={300}
            value={cell.note}
            onChange={(e) => {
              cell.note = e.target.value;
              this.updateCell();
            }}
          />
        </MenuItem>
        {cell.isFormulaCell && (
          <>
            <FormulaEditor
              creditApplication={application}
              value={cell.formula}
              onChange={(value) => {
                cell.formula = value;
                this.updateCell();
              }}
              excluding={cell.cellId}
            />
          </>
        )}
        <Menu.Divider />
        <MenuItem
          text="Linked Key"
          label={CreditApplicationLineItem.labelForAppKey(cell.appKey)}
        >
          <DebounceInput
            minLength={0}
            debounceTimeout={300}
            value={CreditApplicationLineItem.labelForAppKey(cell.appKey)}
            onChange={(e) => {
              cell.appKey = e.target.value;
              this.updateCell();
            }}
          />
          <MenuItem text="Applicant">
            {CreditApplicationLineItem.applicantAppKeys.map(
              ({ value, label }) => (
                <MenuItem
                  key={value}
                  text={label}
                  active={cell.appKey === value}
                  shouldDismissPopover={false}
                  onClick={() => {
                    cell.appKey = value;
                    this.updateCell();
                  }}
                />
              ),
            )}
          </MenuItem>
          <MenuItem text="Co-Applicant">
            {CreditApplicationLineItem.coApplicantAppKeys.map(
              ({ value, label }) => (
                <MenuItem
                  key={value}
                  text={label}
                  active={cell.appKey === value}
                  shouldDismissPopover={false}
                  onClick={() => {
                    cell.appKey = value;
                    this.updateCell();
                  }}
                />
              ),
            )}
          </MenuItem>
          <Menu.Divider />
          <MenuItem text="Applicant Spouse">
            {CreditApplicationLineItem.applicantSpouseAppKeys.map(
              ({ value, label }) => (
                <MenuItem
                  key={value}
                  text={label}
                  active={cell.appKey === value}
                  shouldDismissPopover={false}
                  onClick={() => {
                    cell.appKey = value;
                    this.updateCell();
                  }}
                />
              ),
            )}
          </MenuItem>
          <MenuItem text="Co-Applicant Spouse">
            {CreditApplicationLineItem.coApplicantSpouseAppKeys.map(
              ({ value, label }) => (
                <MenuItem
                  key={value}
                  text={label}
                  active={cell.appKey === value}
                  shouldDismissPopover={false}
                  onClick={() => {
                    cell.appKey = value;
                    this.updateCell();
                  }}
                />
              ),
            )}
          </MenuItem>
        </MenuItem>
      </Menu>
    );
  }
}

TextWords.propTypes = {
  editCellItem: PropTypes.shape({
    cell: PropTypes.instanceOf(CreditApplicationLineItem),
    index: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  application: PropTypes.instanceOf(CreditApplicationObject).isRequired,
  setApp: PropTypes.func.isRequired,
  setCell: PropTypes.func.isRequired,
  commit: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  setCell: (cell) => dispatch(setEditCellItem(cell)),
  setApp: (application) => dispatch(setCreditApplication(application)),
});

const mapStateToProps = ({ creditApplications }) => ({
  editCellItem: creditApplications.editCellItem,
  commit: creditApplications.commit,
});

export default connect(mapStateToProps, mapDispatchToProps)(TextWords);
