/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-danger */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import FormGroup, {
  TextGroup,
  TagGroup,
  SwitchGroup,
} from '../../../FormGroup';
import Panel from '../../../Panel';
import EmailPreview from '../../../Misc/EmailPreview';

import { cleanHtml } from '../../../../utils/cleanHtml';

class EmailSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showEmailPreview: false };
  }

  onShowEmailPreview = (showEmailPreview) => {
    this.setState({ showEmailPreview });
  };

  isInLimit = () => {
    const numberOfRecipients =
      this.props.ccRecipients.length +
      this.props.recipients.length +
      this.props.bccRecipients.length;
    const { maxEmailRecipientCount } = this.props;
    const inLimit =
      maxEmailRecipientCount === -1
        ? true
        : numberOfRecipients < maxEmailRecipientCount;
    return inLimit;
  };

  render() {
    const {
      disableAttachment,
      from,
      subjectLine,
      recipients,
      ccRecipients,
      bccRecipients,
      emailHTML,
      emailFileName,
      onChange,
      errors,
    } = this.props;
    const fileNameLabel = disableAttachment
      ? 'Attachment Link Name'
      : 'Attachment File Name';
    const onResultsPage =
      this.props.currentPath && this.props.currentPath === '/results';
    return (
      <Panel title="Email">
        <div>
          {!this.props.attachmentsAsLinksRequired && (
            <SwitchGroup
              title="Use Link Instead of Attachment"
              checked={disableAttachment}
              onChange={(value) =>
                onChange({ key: 'disableAttachment', value })
              }
            />
          )}
          <TextGroup
            title="From"
            value={from}
            placeholder="From email address"
            onChange={(value) => onChange({ key: 'from', value })}
            errorMessage={errors.from}
          />
          <TextGroup
            title="Subject Line"
            value={subjectLine}
            placeholder="Subject Line"
            onChange={(value) => onChange({ key: 'subjectLine', value })}
            errorMessage={errors.subjectLine}
          />
          <TagGroup
            title="Recipients"
            values={recipients}
            onChange={(value) => {
              if (this.isInLimit() || value.length < recipients.length) {
                onChange({ key: 'recipients', value });
              } else {
                onChange({ key: 'recipients', value: recipients });
              }
            }}
            placeholder="Add Email Addresses"
            errorMessage={errors.recipients}
          />
          <TagGroup
            title="CC Recipients"
            values={ccRecipients}
            onChange={(value) => {
              if (this.isInLimit() || value.length < ccRecipients.length) {
                onChange({ key: 'ccRecipients', value });
              } else {
                onChange({ key: 'ccRecipients', value: ccRecipients });
              }
            }}
            placeholder="Add Email Addresses"
            errorMessage={errors.ccRecipients}
          />
          <TagGroup
            title="BCC Recipients"
            values={bccRecipients}
            onChange={(value) => {
              if (this.isInLimit() || value.length < bccRecipients.length) {
                onChange({ key: 'bccRecipients', value });
              } else {
                onChange({ key: 'bccRecipients', value: bccRecipients });
              }
            }}
            placeholder="Add Email Addresses"
            errorMessage={errors.bccRecipients}
          />
          {!onResultsPage && (
            <FormGroup title="Email HTML" errorMessage={errors.emailHTML}>
              <div>
                <textarea
                  value={emailHTML}
                  onChange={(e) =>
                    onChange({ key: 'emailHTML', value: e.target.value })
                  }
                  style={{ width: '100%' }}
                />
                {/* eslint-disable-next-line max-len */}
                <EmailPreview
                  emailPreview={cleanHtml(emailHTML)}
                  handleClose={() => this.onShowEmailPreview(false)}
                  show={this.state.showEmailPreview}
                />
              </div>
            </FormGroup>
          )}
          {!onResultsPage && (
            <FormGroup title="">
              <Button onClick={() => this.onShowEmailPreview(true)}>
                Preview
              </Button>
            </FormGroup>
          )}
          <TextGroup
            title={fileNameLabel}
            value={emailFileName}
            placeholder={fileNameLabel}
            onChange={(value) => onChange({ key: 'emailFileName', value })}
            errorMessage={errors.emailFileName}
          />
        </div>
        {!this.isInLimit() && (
          <FormGroup title="">
            <p className="danger">
              Plan is limited to {this.props.maxEmailRecipientCount} total email
              recipients
            </p>
          </FormGroup>
        )}
      </Panel>
    );
  }
}

EmailSection.propTypes = {
  disableAttachment: PropTypes.bool,
  from: PropTypes.string,
  maxEmailRecipientCount: PropTypes.number.isRequired,
  attachmentsAsLinksRequired: PropTypes.bool.isRequired,
  currentPath: PropTypes.string,
  subjectLine: PropTypes.string,
  recipients: PropTypes.arrayOf(PropTypes.string),
  ccRecipients: PropTypes.arrayOf(PropTypes.string),
  bccRecipients: PropTypes.arrayOf(PropTypes.string),
  emailHTML: PropTypes.string,
  emailFileName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    from: PropTypes.string,
    subjectLine: PropTypes.string,
    recipients: PropTypes.string,
    ccRecipients: PropTypes.string,
    bccRecipients: PropTypes.string,
    emailHTML: PropTypes.string,
    emailFileName: PropTypes.string,
  }),
};

EmailSection.defaultProps = {
  currentPath: null,
  disableAttachment: false,
  from: '',
  subjectLine: '',
  recipients: [],
  ccRecipients: [],
  bccRecipients: [],
  emailHTML: '',
  emailFileName: '',
  errors: {},
};

const mapStateToProps = ({ plan = {} }) => ({
  attachmentsAsLinksRequired: plan.attachmentsAsLinksRequired,
  maxEmailRecipientCount: plan.maxEmailRecipientCount,
});

export default connect(mapStateToProps)(EmailSection);
