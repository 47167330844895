/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/prop-types */
import React from 'react';
import _ from 'lodash';
import qs from 'query-string';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import { history } from '../../router';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import FormGroup, {
  TextGroup,
  SwitchGroup,
  TagGroup,
  DropDownGroup,
  ButtonGroup,
} from '../FormGroup';
import { startUpdateConfig } from '../../actions/auth';
import Panel from '../Panel';
import TitleButton from '../TitleButton';
import Copy from '../AppSettings/Copy';
import { validateEmailAddress } from '../AppSettings/ContractSending/Validation/ContractSending';
import { showDeleteConfirmation } from '../../actions/deleteConfirmation';
import { pushToDataLayer } from '../../actions/tagManager';
import { emailRegex } from '../../validators';
import {
  startAuthenticateDocuSign,
  startCheckDocuSignIsAuthorized,
  removeDocusignAuth,
} from '../../actions/docusign';
import { showWarningAlert } from '../../actions/alert';

const validateFileName = (value) => {
  if (value.length < 5 || value.substring(value.length - 4) !== '.pdf') {
    return 'Must be a file name that ends in .pdf';
  }
  return undefined;
};

const validateEmail = (value) => {
  if (!value) {
    return false;
  }
  const isPlaceHolder = ['%userEmail%', '%customerEmail%'].indexOf(value) > -1;
  return isPlaceHolder || emailRegex.test(value);
};

const validateCarbonCopy = ({ name, email }) => !!name && validateEmail(email);

const ErrorMessage = (props = {}) => {
  const { message } = props;
  return (
    <span style={{ display: message ? 'block' : 'none', color: 'red' }}>
      {`${message} `}
    </span>
  );
};

const onAuthenticateClicked = (docuSignStatus, showAlert, removeDocusign) => {
  const scopes = 'signature';
  const {
    REACT_APP_DOCUSIGN_AUTH_URL: authURL,
    REACT_APP_DOCUSIGN_INTEGRATION_KEY: clientId,
    REACT_APP_DOCUSIGN_REDIRECT_URL: redirectURL,
  } = process.env;

  const docuSignUrl = `${authURL}/oauth/auth?response_type=code&scope=${scopes}&client_id=${clientId}&redirect_uri=${redirectURL}`;
  if (docuSignStatus.isAuthorized) {
    showAlert({
      title: 'Continue to connect?',
      message: `If you are currently logged into a ${docuSignStatus.docuSignAccountName} account in this browser, that account will automatically connect to this office if you continue. If you do not want to automatically connect, please log out of ${docuSignStatus.docuSignAccountName} before continuing.`,
      onConfirm: async () => {
        const isDocuSignReAuthorized = await removeDocusign();
        if (isDocuSignReAuthorized) {
          window.location.assign(docuSignUrl);
        }
      },
    });
  } else {
    window.location.assign(docuSignUrl);
  }
};

let lastState = '{}';
export class DocuSign extends React.Component {
  constructor(props) {
    super(props);
    const { docuSign } = props;
    this.state = {
      source: docuSign.source || 'DocuSign',
      enabled: docuSign.enabled || false,
      subject: docuSign.subject || '',
      fileName: docuSign.fileName || '',
      carbonCopies: docuSign.carbonCopies || [],
      backupRecipients: docuSign.backupRecipients || [],
      agree: false,
    };
  }

  componentDidMount() {
    const {
      setCrumbs,
      setButtons,
      location: { pathname },
    } = this.props;
    this.updatePropsToState(this.props);
    this.props.startCheckDocuSignIsAuthorized();
    const crumb = { title: 'Remote Signature Capture', link: pathname };
    setCrumbs([crumb]);
    setButtons(
      <TitleButtons
        onClearClick={this.askForClearPermission}
        onCopyClicked={this.onCopyClicked}
        onSaveClicked={this.onSaveClicked}
      />,
    );
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const changed = !_.isEqual(this.props, newProps);
    if (changed) {
      this.props.startCheckDocuSignIsAuthorized();
      this.updatePropsToState(newProps);
      const { code } = qs.parse(this.props.location.search);
      if (code) {
        const authenticateDocuSign = async () => {
          await this.props.startAuthenticateDocuSign(code);
          history.push('docusign');
        };
        authenticateDocuSign();
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const last = JSON.parse(lastState);
    const changed = !_.isEqual(last, this.state);
    lastState = JSON.stringify(this.state);
    if (changed) {
      this.renderButtons();
    }
  }

  componentWillUnmount() {
    const { setCrumbs, setButtons } = this.props;
    setCrumbs([]);
    setButtons();
  }

  renderButtons = () => {
    const { setButtons } = this.props;
    setButtons(
      <TitleButtons
        onClearClick={this.askForClearPermission}
        onCopyClicked={this.onCopyClicked}
        onSaveClicked={this.onSaveClicked}
      />,
    );
  };

  onEnabledChanged = (checked) => {
    this.setState({ enabled: checked }, () => {
      if (!checked) {
        this.onClearClick();
      } else {
        this.props.pushToDataLayer({
          event: 'integrationEvent',
          eventCategory: 'Integration',
          eventAction: 'Enable',
          eventLabel: 'DocuSign',
        });
      }
    });
  };

  onSourceChanged = ({ value }) => {
    this.setState({ source: value });
  };

  askForClearPermission = () => {
    this.props.showDeleteConfirmation({
      message: `Are you sure you want to clear this integration?`,
      title: 'Clear Integration?',
      onConfirm: () => {
        this.onClearClick();
      },
    });
  };

  onClearClick = () => {
    this.props.pushToDataLayer({
      event: 'integrationEvent',
      eventCategory: 'Integration',
      eventAction: 'Disable',
      eventLabel: 'DocuSign',
    });
    const unset = Object.keys(this.state).reduce((clearedOut, key) => {
      if (Array.isArray(this.state[key])) {
        return {
          ...clearedOut,
          [key]: [],
        };
      }
      return {
        ...clearedOut,
        [key]: '',
      };
    }, {});
    this.setState({ ...unset });
  };

  onSubjectChanged = (value) => {
    this.setState({ subject: value });
  };

  onFileNameChanged = (value) => {
    this.setState({ fileName: value });
  };

  onCarbonCopiesChanged = (value, key, index) => {
    const carbonCopies = [...this.state.carbonCopies];
    carbonCopies[index][key] = value;
    this.setState({ carbonCopies });
  };

  onCarbonCopiesDelete = (index) => {
    const carbonCopies = [...this.state.carbonCopies];
    carbonCopies.splice(index, 1);
    this.setState({ carbonCopies });
  };

  onBackupRecipientsChanged = (value) => {
    let isValid = true;
    value.forEach((email) => {
      if (validateEmailAddress(email)) {
        isValid = false;
      }
    });
    if (isValid) {
      this.setState({ backupRecipients: value });
    }
  };

  onCopyClicked = () => {
    this.setState({ showCopy: true });
  };

  onSaveClicked = async () => {
    if (!this.isDataValid()) {
      return;
    }
    const updates = {
      source: this.state.source,
      enabled: this.state.enabled,
      subject: this.state.subject,
      fileName: this.state.fileName,
      carbonCopies: this.state.carbonCopies,
      backupRecipients: this.state.backupRecipients,
      encrypted: false,
    };
    this.props.startUpdateConfig({ docuSign: updates });
    if (this.state.source !== 'DocuSign') {
      await this.props.removeDocusign();
    }
  };

  onAddClicked = () => {
    const newObject = {
      name: '',
      email: '',
    };
    const carbonCopies = [newObject, ...this.state.carbonCopies];
    this.setState({ carbonCopies });
  };

  updatePropsToState = async (props) => {
    const { docuSign = {} } = props;
    const {
      enabled,
      source = 'LSS',
      subject = '',
      fileName = '',
      carbonCopies = [],
      backupRecipients = [],
    } = docuSign;
    this.setState({
      enabled: !!enabled || false,
      source: source || 'DocuSign',
      subject,
      fileName,
      carbonCopies,
      backupRecipients,
    });
  };

  isDataValid = () => {
    const {
      enabled,
      source,
      agree,
      backupRecipients,
      fileName,
      subject,
      carbonCopies,
    } = this.state;
    if (!enabled) {
      return true;
    }
    if (source !== 'DocuSign' && source !== 'LSS') {
      return false;
    }
    if (!agree) {
      return false;
    }
    if (!backupRecipients.length) {
      return false;
    }
    if (
      carbonCopies.length &&
      carbonCopies.filter((obj) => !validateCarbonCopy(obj)).length
    ) {
      return false;
    }
    if (validateFileName(fileName)) {
      return false;
    }
    if (source === 'DocuSign') {
      if (!subject) {
        return false;
      }
    }
    return true;
  };

  render() {
    const {
      showCopy,
      enabled,
      source,
      subject,
      fileName,
      carbonCopies,
      backupRecipients,
      agree,
    } = this.state;
    const { docuSignStatus, showAlert, removeDocusign } = this.props;
    return (
      <>
        <Copy
          title="Copy Remote Signature Capture Settings"
          show={showCopy}
          warning="Warning! This will overwrite your current Remote Signature Capture Settings"
          configKeys={['docuSign']}
          onClose={() => this.setState({ showCopy: false })}
        />
        <div className="default-page-padding">
          <Panel title="Remote Signature Capture">
            <div>
              <SwitchGroup
                title="Enabled"
                checked={enabled}
                onChange={this.onEnabledChanged}
              />
              {enabled && (
                <div>
                  <DropDownGroup
                    title="Remote Signature Capture"
                    value={source}
                    onChange={this.onSourceChanged}
                    closeMenuOnSelect
                    options={[
                      {
                        value: 'DocuSign',
                        label: 'DocuSign',
                      },
                      {
                        value: 'LSS',
                        label: 'Leap Secure Sign',
                      },
                    ]}
                  />
                  {source === 'DocuSign' && (
                    <FormGroup title="Authenticated Account">
                      <div>
                        {docuSignStatus.docuSignAccountName
                          ? docuSignStatus.docuSignAccountName
                          : 'None'}
                      </div>
                    </FormGroup>
                  )}
                  {source === 'DocuSign' && (
                    <>
                      <ButtonGroup
                        title="Authenticate"
                        variant="primary"
                        buttonTitle={
                          docuSignStatus.isAuthorized
                            ? 'Re-Authenticate'
                            : 'Authenticate'
                        }
                        onClick={() =>
                          onAuthenticateClicked(
                            docuSignStatus,
                            showAlert,
                            removeDocusign,
                          )
                        }
                      />
                      {docuSignStatus.isAuthorized && (
                        <TextGroup
                          title="Subject"
                          value={subject}
                          placeholder="Subject"
                          onChange={this.onSubjectChanged}
                          errorMessage={!subject ? 'Required' : undefined}
                        />
                      )}
                    </>
                  )}
                  {(source !== 'DocuSign' || docuSignStatus.isAuthorized) && (
                    <>
                      <TextGroup
                        title="File Name"
                        value={fileName}
                        placeholder="File Name"
                        onChange={this.onFileNameChanged}
                        errorMessage={validateFileName(fileName)}
                      />
                      <FormGroup title="Carbon Copies">
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Email</th>
                              <th style={{ width: '35px' }}>
                                <button
                                  type="button"
                                  onClick={this.onAddClicked}
                                >
                                  Add
                                </button>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {carbonCopies.map(({ name, email }, index) => (
                              <tr key={index}>
                                <td>
                                  <input
                                    className="table-row__cell__input"
                                    type="text"
                                    value={name}
                                    placeholder="Name"
                                    onChange={(e) =>
                                      this.onCarbonCopiesChanged(
                                        e.target.value,
                                        'name',
                                        index,
                                      )
                                    }
                                  />
                                  {!name && <ErrorMessage message="Required" />}
                                </td>
                                <td>
                                  <input
                                    className="table-row__cell__input"
                                    type="text"
                                    value={email}
                                    placeholder="Email"
                                    onChange={(e) =>
                                      this.onCarbonCopiesChanged(
                                        e.target.value,
                                        'email',
                                        index,
                                      )
                                    }
                                  />
                                  {!validateEmail(email) && (
                                    <ErrorMessage message="Invalid Email" />
                                  )}
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    className="fill-cell danger"
                                    onClick={() =>
                                      this.onCarbonCopiesDelete(index)
                                    }
                                  >
                                    <i className="far fa-trash-alt" />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </FormGroup>
                      <TagGroup
                        title="Failure Recipients"
                        values={backupRecipients}
                        onChange={this.onBackupRecipientsChanged}
                        placeholder="Add Email Addresses"
                        errorMessage={
                          !backupRecipients.length
                            ? 'At least 1 required'
                            : undefined
                        }
                      />
                      <FormGroup
                        title=""
                        errorMessage={!agree ? 'Required' : ''}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={agree}
                            onChange={() =>
                              this.setState(({ agree: a }) => ({ agree: !a }))
                            }
                          />
                          <div style={{ marginLeft: '10px' }}>
                            I have read and agree to the{' '}
                            <a
                              href="https://leaptodigital.com/leap-terms-use-docusign/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Terms of Use
                            </a>{' '}
                            associated with this feature.
                          </div>
                        </div>
                      </FormGroup>
                    </>
                  )}
                </div>
              )}
            </div>
          </Panel>
        </div>
      </>
    );
  }
}

DocuSign.propTypes = {
  docuSign: PropTypes.shape({
    source: PropTypes.string,
    enabled: PropTypes.bool,
    subject: PropTypes.string,
    fileName: PropTypes.string,
    carbonCopies: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        email: PropTypes.string,
      }),
    ),
    encrypted: PropTypes.bool,
  }),
  startUpdateConfig: PropTypes.func.isRequired,
  startAuthenticateDocuSign: PropTypes.func.isRequired,
  startCheckDocuSignIsAuthorized: PropTypes.func.isRequired,
  docuSignStatus: PropTypes.shape({
    docuSignAccountName: PropTypes.string,
    isAuthorized: PropTypes.bool,
  }),
  ...layoutContextPropTypes,
};

DocuSign.defaultProps = {
  docuSign: {
    source: 'LSS',
    enabled: false,
    subject: '',
    fileName: '',
    carbonCopies: [
      {
        name: '',
        email: '',
      },
    ],
    encrypted: true,
  },
  docuSignStatus: { isAuthorized: false, docuSignAccountName: '' },
};

const TitleButtons = ({ onCopyClicked, onSaveClicked, onClearClick }) => (
  <>
    <TitleButton
      variant="warning"
      onClick={() => onClearClick()}
      title="Clear"
    />
    <TitleButton
      variant="primary"
      onClick={() => onCopyClicked({ source: 'copy' })}
      title="Copy"
    />
    <TitleButton
      variant="success"
      onClick={() => onSaveClicked({ source: 'save' })}
      title="Save"
    />
  </>
);

TitleButtons.propTypes = {
  onCopyClicked: PropTypes.func.isRequired,
  onSaveClicked: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth: { config, docuSignStatus } }) => ({
  docuSign: config.docuSign,
  docuSignStatus,
});

const mapDispatchToProps = (dispatch) => ({
  startUpdateConfig: (updates) => dispatch(startUpdateConfig(updates)),
  pushToDataLayer: (variablesForLayer) =>
    dispatch(pushToDataLayer(variablesForLayer)),
  showDeleteConfirmation: (params) => dispatch(showDeleteConfirmation(params)),
  startAuthenticateDocuSign: (token) =>
    dispatch(startAuthenticateDocuSign(token)),
  startCheckDocuSignIsAuthorized: () =>
    dispatch(startCheckDocuSignIsAuthorized()),
  removeDocusign: () => dispatch(removeDocusignAuth()),
  showAlert: (params) => dispatch(showWarningAlert(params)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(DocuSign),
);
