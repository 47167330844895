import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DebounceInput } from 'react-debounce-input';
import { validateDimention } from '../../../../PriceGuide/AdditionalDetails/validationErrors';

const SizeInput = (props) => {
  const [error, setError] = useState(false);
  const { name, onChange = () => {}, sizeSpec = {}, ...passedIn } = props;

  return (
    <>
      {error && <p className="danger"> {error}</p>}
      <DebounceInput
        {...passedIn}
        minLength={0}
        debounceTimeout={300}
        onChange={(event) => {
          const {
            target: { value },
          } = event;
          const updatedSpec = { ...sizeSpec, [name]: value };
          const errorMessage = validateDimention(name, updatedSpec);
          if (errorMessage) {
            setError(errorMessage);
          } else {
            onChange(event);
            setError(undefined);
          }
        }}
      />
    </>
  );
};

SizeInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  sizeSpec: PropTypes.shape({}).isRequired,
};

export default SizeInput;
