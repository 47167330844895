/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-shadow */
/* eslint-disable react/no-deprecated */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLayoutContext, layoutContextPropTypes } from '../Layout';
import { TextGroup, SwitchGroup, DropDownGroup } from '../FormGroup';
import { startUpdateConfig } from '../../actions/auth';
import Panel from '../Panel';
import TitleButton from '../TitleButton';
import Copy from '../AppSettings/Copy';
import { showDeleteConfirmation } from '../../actions/deleteConfirmation';
import { pushToDataLayer } from '../../actions/tagManager';

const documentDeliveryMethodOptions = [
  {
    label: 'Paper',
    value: 'paper',
  },
  {
    label: 'Electronic',
    value: 'electronic',
  },
  {
    label: 'Delivered To Contractor',
    value: 'deliveredToContractor',
  },
];

export class EnerBank extends React.Component {
  constructor(props) {
    super(props);
    const { enerBank = {} } = props;
    const {
      programPhoneNumber = '',
      loanCode = '',
      contractorNumber = '',
      documentDeliveryMethod = 'paper',
      defaultLoanCode = '',
    } = enerBank;
    this.state = {
      enabled: enerBank.enabled || false,
      programPhoneNumber,
      loanCode,
      contractorNumber,
      documentDeliveryMethod,
      defaultLoanCode,
    };
  }

  componentDidMount() {
    const {
      setCrumbs,
      location: { pathname },
    } = this.props;
    const crumb = { title: 'EnerBank', link: pathname };
    setCrumbs([crumb]);
    this.renderButtons();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { enerBank = {} } = newProps;
    const changed = !_.isEqual(this.props, newProps);
    if (changed) {
      const {
        programPhoneNumber = '',
        loanCode = '',
        contractorNumber = '',
        documentDeliveryMethod = 'paper',
        defaultLoanCode = '',
      } = enerBank;
      this.setState({
        enabled: enerBank.enabled || false,
        programPhoneNumber,
        loanCode,
        contractorNumber,
        documentDeliveryMethod,
        defaultLoanCode,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const changed = !_.isEqual(prevState, this.state);
    if (changed) {
      this.renderButtons();
    }
  }

  componentWillUnmount() {
    const { setCrumbs, setButtons } = this.props;
    setCrumbs([]);
    setButtons();
  }

  renderButtons = () => {
    const { setButtons } = this.props;
    setButtons(
      <TitleButtons
        disableSave={!this.canSave()}
        onClearClick={this.askForClearPermission}
        onCopyClicked={this.onCopyClicked}
        onSaveClicked={this.onSaveClicked}
      />,
    );
  };

  onEnabledChanged = (checked) => {
    this.setState({ enabled: checked });
    if (!checked) {
      const unset = Object.keys(this.state).reduce(
        (unset, key) => ({ ...unset, [key]: '' }),
        {},
      );
      this.setState({ ...unset });
      this.props.pushToDataLayer({
        event: 'integrationEvent',
        eventCategory: 'Integration',
        eventAction: 'Disable',
        eventLabel: 'EnerBank',
      });
    } else {
      this.props.pushToDataLayer({
        event: 'integrationEvent',
        eventCategory: 'Integration',
        eventAction: 'Enable',
        eventLabel: 'EnerBank',
      });
    }
  };

  askForClearPermission = () => {
    this.props.showDeleteConfirmation({
      message: 'Are you sure you want to clear this integration ?',
      title: 'Clear Integration ?',
      onConfirm: () => {
        this.onClearClick();
      },
    });
  };

  onClearClick = () => {
    this.onEnabledChanged(false);
  };

  onChange = (key, value) => {
    this.setState({ [key]: value });
  };

  onCopyClicked = () => {
    this.setState({ showCopy: true });
  };

  canSave = () => {
    if (!this.state.enabled) {
      return true;
    }

    return this.state.programPhoneNumber.length === 10;
  };

  onSaveClicked = () => {
    const updates = {
      enabled: this.state.enabled,
      programPhoneNumber: this.state.programPhoneNumber,
      loanCode: this.state.loanCode,
      contractorNumber: this.state.contractorNumber,
      documentDeliveryMethod: this.state.documentDeliveryMethod,
      defaultLoanCode: this.state.defaultLoanCode,
    };

    if (this.canSave()) {
      this.props.startUpdateConfig({ enerBank: updates });
    }
  };

  render() {
    return (
      <>
        <Copy
          title="Copy EnerBank Settings"
          show={this.state.showCopy}
          warning="Warning! This will overwrite your current EnerBank settings"
          configKeys={['enerBank']}
          onClose={() => this.setState({ showCopy: false })}
        />
        <div className="default-page-padding">
          <Panel title="EnerBank">
            <div>
              <SwitchGroup
                title="Enabled"
                checked={this.state.enabled}
                onChange={this.onEnabledChanged}
              />
              {this.state.enabled && (
                <div>
                  <TextGroup
                    title="Program Phone Number"
                    value={this.state.programPhoneNumber}
                    placeholder="EnerBank Loan by Phone Number"
                    onChange={(value) => {
                      this.onChange(
                        'programPhoneNumber',
                        value.replace(/\D/g, ''),
                      );
                    }}
                    errorMessage={
                      this.state.programPhoneNumber.length !== 10
                        ? 'Must Be At Least 10 Digits Long'
                        : undefined
                    }
                  />
                  <TextGroup
                    title="Contractor Number"
                    value={this.state.contractorNumber}
                    placeholder="EnerBank Contractor ID"
                    onChange={(value) => {
                      this.onChange('contractorNumber', value);
                    }}
                  />
                  <DropDownGroup
                    title="Document Delivery Method"
                    value={this.state.documentDeliveryMethod}
                    onChange={({ value }) => {
                      this.onChange('documentDeliveryMethod', value);
                    }}
                    isMulti={false}
                    closeMenuOnSelect
                    options={documentDeliveryMethodOptions}
                  />
                  <TextGroup
                    title="Integration Code"
                    value={this.state.defaultLoanCode}
                    placeholder="01"
                    onChange={(value) => {
                      this.onChange('defaultLoanCode', value);
                    }}
                  />
                </div>
              )}
            </div>
          </Panel>
        </div>
      </>
    );
  }
}

EnerBank.propTypes = {
  startUpdateConfig: PropTypes.func.isRequired,
  enerBank: PropTypes.shape({
    enabled: PropTypes.bool,
    programPhoneNumber: PropTypes.string,
  }),
};

EnerBank.defaultProps = {
  enerBank: {
    enabled: false,
  },
};

const TitleButtons = ({
  onCopyClicked,
  onSaveClicked,
  onClearClick,
  disableSave,
}) => (
  <>
    <TitleButton
      variant="warning"
      onClick={() => onClearClick()}
      title="Clear"
    />
    <TitleButton
      variant="primary"
      onClick={() => onCopyClicked({ source: 'copy' })}
      title="Copy"
    />
    <TitleButton
      disabled={disableSave}
      variant="success"
      onClick={() => onSaveClicked({ source: 'save' })}
      title="Save"
    />
  </>
);

TitleButtons.propTypes = {
  onCopyClicked: PropTypes.func.isRequired,
  onSaveClicked: PropTypes.func.isRequired,
  ...layoutContextPropTypes,
};

const mapStateToProps = ({ auth: { config } }) => ({
  enerBank: config.enerBank,
});

const mapDispatchToProps = (dispatch) => ({
  startUpdateConfig: (updates) => dispatch(startUpdateConfig(updates)),
  pushToDataLayer: (variablesForLayer) =>
    dispatch(pushToDataLayer(variablesForLayer)),
  showDeleteConfirmation: (params) => dispatch(showDeleteConfirmation(params)),
});

export default withLayoutContext(
  connect(mapStateToProps, mapDispatchToProps)(EnerBank),
);
