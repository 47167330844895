/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BodyRow from '../BodyRow';
import BodyCellItem from '../Models/BodyCellItem';
import { setEditCellItem } from '../../../actions/templateEdit';

const ProViaCell = ({ cellItem, setEditItem }) => (
  <BodyRow cellItem={cellItem}>
    <div
      className="templates-app__text_cell"
      onClick={() => setEditItem(cellItem)}
    >
      <span
        className={`templates-app__title${
          cellItem.required ? '_required' : ''
        }`}
      >
        {cellItem.appTitle}
      </span>
      <i style={{ color: 'lightgray' }} className="fas fa-chevron-right" />
      <div className="templates-app__cell_bottom_border" />
    </div>
  </BodyRow>
);

ProViaCell.propTypes = {
  cellItem: PropTypes.instanceOf(BodyCellItem).isRequired,
  setEditItem: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  setEditItem: (cellItem) => dispatch(setEditCellItem(cellItem)),
});

export default connect(undefined, mapDispatchToProps)(ProViaCell);
