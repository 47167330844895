/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import CustomPlaceholderTable from '../../CustomPlaceholderTable';
import { randomKey } from '../../../utils/utils';

const validationErrors = ({ placeholders }) => ({
  placeholders: placeholders.map(({ placeholder }, index, array) => {
    const existingIndex = array.find(
      ({ placeholder: existing }) => placeholder === existing,
    );
    const message =
      array.indexOf(existingIndex) !== index ? 'No duplicates allowed' : '';
    return {
      placeholder: placeholder ? message : 'Required',
      replacement: '', // No validation needed for replacement
      notAddedReplacement: '', // No validation needed for notAddedReplacement
    };
  }),
});

export default class PlaceholderEditModal extends React.Component {
  constructor(props) {
    super(props);
    const newState = {
      placeholders: props.placeholders.map((object) => {
        if (!object.id) {
          return {
            ...object,
            id: randomKey(10),
          };
        }
        return object;
      }),
    };
    newState.errors = validationErrors(newState);
    this.state = newState;
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const newState = {
      placeholders: newProps.placeholders.map((object) => {
        if (!object.id) {
          return {
            ...object,
            id: randomKey(10),
          };
        }
        return object;
      }),
    };
    newState.errors = validationErrors(newState);
    this.setState(newState);
  }

  onAddClicked = () => {
    const placeholders = [...this.state.placeholders];
    placeholders.unshift({
      placeholder: '',
      replacement: '',
      id: randomKey(10),
    });
    const newState = {
      ...this.state,
      placeholders,
    };
    newState.errors = validationErrors(newState);
    this.setState(newState);
  };

  onTextChange = ({ value, source, id }) => {
    const placeholders = this.state.placeholders.map((placeholder) => {
      const updatedPlaceholder = { ...placeholder };
      if (id === placeholder.id) {
        updatedPlaceholder[source] = value;
      }
      return updatedPlaceholder;
    });
    const newState = {
      ...this.state,
      placeholders,
    };
    newState.errors = validationErrors(newState);
    this.setState(newState);
  };

  onDelete = (id) => {
    const placeholders = this.state.placeholders.filter((old) => old.id !== id);
    const newState = {
      ...this.state,
      placeholders,
    };
    newState.errors = validationErrors(newState);
    this.setState(newState);
  };

  hasErrors = () => {
    const { errors = {} } = this.state;
    const { placeholders = [] } = errors;
    for (let i = 0; i < placeholders.length; i += 1) {
      const { placeholder, replacement } = placeholders[i];
      if (placeholder || replacement) {
        return true;
      }
    }
    return false;
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onClose}
        dialogClassName="price-guide__edit-msi-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Placeholders</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CustomPlaceholderTable
            onAddClicked={this.onAddClicked}
            onValueChanged={this.onTextChange}
            onDelete={this.onDelete}
            placeholders={this.state.placeholders}
            errors={this.state.errors.placeholders}
            showNotAdded={this.props.showNotAdded}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => this.props.onSave(this.state.placeholders)}
            disabled={this.hasErrors()}
          >
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

PlaceholderEditModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  placeholders: PropTypes.arrayOf(
    PropTypes.shape({
      placeholder: PropTypes.string,
      replacement: PropTypes.string,
      id: PropTypes.string,
    }),
  ),
  showNotAdded: PropTypes.bool,
};

PlaceholderEditModal.defaultProps = {
  show: false,
  placeholders: [],
  showNotAdded: false,
};
