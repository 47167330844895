import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const Panel = ({ title, children, styles }) => (
  <div className="custom-panel">
    <div className="custom-panel__header" style={styles.customPanel.header}>
      <div className="custom-panel__title">{title}</div>
    </div>
    <div className="custom-panel__body">{children}</div>
  </div>
);

Panel.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  styles: PropTypes.shape({
    customPanel: PropTypes.object,
  }),
};

Panel.defaultProps = {
  styles: { customPanel: {} },
};

const mapStateToProps = ({ config: { styles = {} } }) => ({
  styles: styles.Panel,
});

export default connect(mapStateToProps)(Panel);
