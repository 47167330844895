/* eslint-disable no-underscore-dangle */
import Parse from 'parse';

class UpCharge extends Parse.Object {
  constructor(obj) {
    super('SSPriceGuideItem');
    Object.assign(this, obj);
    if (!this.has('isAccessory')) {
      this.set('isAccessory', true);
    }
  }

  newInstance() {
    const newInstance = super.newInstance();
    Object.assign(newInstance, this);
    return newInstance;
  }

  getPricesForOptionId(id) {
    const accessoryPrices = this.get('accessoryPrices') || [];
    const prices = accessoryPrices.find(
      ({ priceGuideItemId }) => priceGuideItemId === id,
    ) || { priceGuideItemId: id, itemTotals: [] };
    return prices;
  }

  getPrice(optionId, officeId) {
    const { itemTotals = [] } = this.getPricesForOptionId(optionId);
    const { total } =
      itemTotals.find(({ officeId: id }) => officeId === id) || {};
    return total;
  }

  getSharedPrice(officeId) {
    return this.getPrice('default', officeId) || 0;
  }

  getCalculatedTotal(option, officeId) {
    if (!this.get('percentagePrice')) {
      return undefined;
    }
    const optionPrice = option.getPriceForOffice(officeId);
    const percentage = this.getPrice(option.id, officeId);
    if (!percentage) {
      return undefined;
    }
    return optionPrice && percentage ? optionPrice * percentage : 0;
  }

  getSharedCalculatedTotal(option, officeId) {
    if (!this.get('percentagePrice')) {
      return undefined;
    }
    const optionPrice = option.getPriceForOffice(officeId);
    const percentage = this.getPrice('default', officeId);
    if (!percentage) {
      return undefined;
    }
    return optionPrice && percentage ? optionPrice * percentage : 0;
  }

  setPrice(value, optionId, officeId) {
    const prices = this.get('accessoryPrices') || [];
    const sharedPrice = prices.find(
      ({ priceGuideItemId }) => priceGuideItemId === optionId,
    ) || { priceGuideItemId: optionId, itemTotals: [] };
    sharedPrice.itemTotals = sharedPrice.itemTotals.filter(
      ({ officeId: id }) => id !== officeId,
    );
    if (value === '') {
      if (!sharedPrice.itemTotals.length) {
        const updatedPrices = prices.filter(
          ({ priceGuideItemId }) => priceGuideItemId !== optionId,
        );
        this.set('accessoryPrices', updatedPrices);
        return;
      }
      const updatedPrices = prices.map((obj) => {
        if (obj.priceGuideItemId === optionId) {
          return sharedPrice;
        }
        return obj;
      });
      this.set('accessoryPrices', updatedPrices);
      return;
    }
    const price = parseFloat(value);
    const total = Number.isNaN(price) ? 0 : price;
    sharedPrice.itemTotals.push({ officeId, total });
    const updatedPrices = prices.filter(
      ({ priceGuideItemId }) => priceGuideItemId !== optionId,
    );
    updatedPrices.push(sharedPrice);
    this.set('accessoryPrices', updatedPrices);
  }

  setSharedPrice(value, officeId) {
    const prices = this.get('accessoryPrices') || [];
    const sharedPrice = prices.find(
      ({ priceGuideItemId }) => priceGuideItemId === 'default',
    ) || { priceGuideItemId: 'default', itemTotals: [] };
    sharedPrice.itemTotals = sharedPrice.itemTotals.filter(
      ({ officeId: id }) => id !== officeId,
    );
    const price = parseFloat(value);
    const total = Number.isNaN(price) ? 0 : price;
    sharedPrice.itemTotals.push({ officeId, total });
    const updatedPrices = prices.filter(
      ({ priceGuideItemId }) => priceGuideItemId !== 'default',
    );
    updatedPrices.unshift(sharedPrice);
    this.set('accessoryPrices', updatedPrices);
  }

  addPlaceholders(placeholders = []) {
    const existing = this.get('placeholders') || [];
    const updated = [...existing, ...placeholders];
    this.set('placeholders', updated);
  }

  addAdditionalDetails(values = []) {
    const existing = this.get('additionalDetails') || [];
    const updated = [...existing, ...values];
    this.set('additionalDetails', updated);
  }

  clearAdditionalDetails() {
    this.set('additionalDetails', []);
  }

  convertImage() {
    if (this.has('image')) {
      const image = this.get('image');
      if (image.isNew) {
        delete image._url;
        delete image.isNew;
      }
    }
    return this;
  }

  save(...rest) {
    this.convertImage();
    return super.save(...rest);
  }

  clone() {
    const clone = super.clone();
    const accessoryPrices = this.get('accessoryPrices') || [];
    const clonedPrices = JSON.parse(JSON.stringify(accessoryPrices));
    clone.set('accessoryPrices', clonedPrices);
    return clone;
  }
}

export default UpCharge;
