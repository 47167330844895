import Parse from 'parse';
import { generateKeyBetween } from 'fractional-indexing';
import { handleError } from './auth';
import { showSuccessAlert, hideAlert } from './alert';
import MeasureSheetItem from '../Models/MeasureSheetItem';
import PriceGuideOption from '../Models/PriceGuideOption';
import UpCharge from '../Models/UpCharge';
import AppToaster from '../utils/AppToaster';

const itemKeys = [
  'accessoriesCount',
  'additionalDetailObjects',
  'category',
  'formulaID',
  'image',
  'includedOffices',
  'itemCount',
  'itemName',
  'itemNote',
  'measurementType',
  'orderNumber',
  'placeholders',
  'qtyFormula',
  'shouldCopyTag',
  'shouldShowSwitch',
  'subCategory',
  'subSubCategories',
  'tagDefaultValue',
  'tagInputType',
  'tagNote',
  'tagParams',
  'tagPickerOptions',
  'tagRequired',
  'tagTitle',
];

export const setPage = (page) => ({
  type: 'SET_PRICEGUIDE_PAGE',
  page,
});

export const setLimit = (limit) => ({
  type: 'SET_PRICEGUIDE_LIMIT',
  limit,
});

export const setCount = (count) => ({
  type: 'SET_MSI_COUNT',
  count,
});

export const setOtherPageItem = (otherPageItem) => ({
  type: 'SET_OTHER_PAGE_ITEM',
  otherPageItem,
});

export const setItems = (items) => ({
  type: 'SET_MSI_ITEMS',
  items,
});

export const setItem = (item) => ({
  type: 'SET_MSI_ITEM',
  item,
});

export const deleteItem = (item) => ({
  type: 'DELETE_MSI_ITEM',
  item,
});

export const setItemIds = (ids) => ({
  type: 'SET_MSI_ITEM_IDS',
  ids,
});

export const setCategories = (categories) => ({
  type: 'SET_PRICEGUIDE_CATEGORIES',
  categories,
});

export const setSubCategories = (subCategories) => ({
  type: 'SET_PRICEGUIDE_SUBCATEGORIES',
  subCategories,
});

export const setSelectedCategories = (categories) => ({
  type: 'SET_PRICEGUIDE_SELECTED_CATEGORIES',
  categories,
});

export const setSelectedSubCategories = (subCategories) => ({
  type: 'SET_PRICEGUIDE_SELECTED_SUBCATEGORIES',
  subCategories,
});

export const setIncludedOffices = (offices) => ({
  type: 'SET_PRICEGUIDE_INCLUDED_OFFICES',
  offices,
});

export const setActiveColumns = (activeColumns) => async (dispatch) => {
  try {
    dispatch({
      type: 'PRICE_GUIDE_SET_ACTIVE_COLUMNS',
      activeColumns,
    });
    const currentUser = Parse.User.current();
    currentUser.set('activeColumns', activeColumns);
    await currentUser.save();
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const setItemColumnWidth = (key, width) => ({
  type: 'SET_PRICE_GUIDE_ITEM_COLUMN_WIDTH',
  key,
  width,
});

export const setOptionColumnWidth = (key, width) => ({
  type: 'SET_PRICEGUIDE_OPTION_COLUMN_WIDTH',
  key,
  width,
});

export const setOptions = (options = []) => ({
  type: 'SET_PRICEGUIDE_OPTIONS',
  options,
});

export const setOption = (option) => ({
  type: 'SET_PRICEGUIDE_OPTION',
  option,
});

export const setAdditionalDetailsEdit = (additionalDetails) => ({
  type: 'SET_PRICEGUIDE_ADDITIONAL_DETAILS_EDIT',
  additionalDetails,
});

export const setPlaceholdersEdit = (placeholders) => ({
  type: 'SET_PRICEGUIDE_PLACEHOLDERS_EDIT',
  placeholders,
});

export const setUpCharges = (upCharges) => ({
  type: 'SET_PRICEGUIDE_UPCHARGES',
  upCharges,
});

export const setUpCharge = (upCharge) => ({
  type: 'SET_PRICEGUIDE_UPCHARGE',
  upCharge,
});

export const setEditUpChargeModal = (object) => ({
  type: 'SET_PRICEGUIDE_UPCHARGE_EDIT_MODAL',
  object,
});

export const resetPriceGuide = () => ({
  type: 'PRICEGUIDE_RESET',
});

export const startDownloadCategories = () => async (dispatch) => {
  try {
    const results = await Parse.Cloud.run('unique_categories');
    dispatch(setCategories(results));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const startDownloadSubCategories = (categories) => async (dispatch) => {
  try {
    const results = await Parse.Cloud.run('sub_categories', { categories });
    dispatch(setSubCategories(results));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const queryForMeasureSheetItems = (
  { auth, plan = {} },
  selectedOffices = [],
  categories = [],
  subCategories = [],
) => {
  // Don't modify params
  const clonedSelectedOffices = [...selectedOffices];
  // Query for items with no selected offices
  const noOfficeQuery = new Parse.Query('SSMeasureSheetItem');
  const noOfficeIndex = clonedSelectedOffices.findIndex(
    (officeId) => officeId === '**No Office**',
  );
  if (noOfficeIndex !== -1) {
    // Remove the no office from the selected offices
    clonedSelectedOffices.splice(noOfficeIndex, 1);
    noOfficeQuery.containedBy('includedOffices', []);
  }

  // Query for items with selected offices
  const includedOfficesQuery = new Parse.Query('SSMeasureSheetItem');
  if (clonedSelectedOffices.length) {
    const queryOffices =
      plan.maxOfficeCount === 1 ? [auth.offices[0].id] : clonedSelectedOffices;
    const offices = queryOffices.map((id) => {
      const office = new Parse.Object('Office');
      office.id = id;
      return office.toPointer();
    });
    includedOfficesQuery.containedIn('includedOffices', offices);
  }

  // Combine the queries for items with no selected offices and items with selected offices
  let officeQuery = Parse.Query.or(noOfficeQuery, includedOfficesQuery);
  // If only **No Office** is selected, use the noOfficeQuery
  if (noOfficeIndex !== -1 && clonedSelectedOffices.length === 0) {
    officeQuery = noOfficeQuery;
  }

  // If only offices are selected, use the includedOfficesQuery
  if (noOfficeIndex === -1 && clonedSelectedOffices.length > 0) {
    officeQuery = includedOfficesQuery;
  }

  // Main query for items with universal constraints
  const mainQuery = new Parse.Query('SSMeasureSheetItem');
  const currentUser = Parse.User.current();
  const company = currentUser.get('company');
  mainQuery.equalTo('company', company);
  if (categories.length) {
    mainQuery.containedIn('category', categories);
    if (subCategories.length) {
      mainQuery.containedIn('subCategory', subCategories);
    }
  }

  // Combine the main query with the office query and add sorting
  const combinedQuery = Parse.Query.and(mainQuery, officeQuery)
    .ascending('category')
    .addDescending('orderNumber');

  return combinedQuery;
};

export const startCountItems = (
  includedOffices,
  categories,
  subCategories,
) => async (dispatch, getState) => {
  try {
    const query = queryForMeasureSheetItems(
      getState(),
      includedOffices,
      categories,
      subCategories,
    );
    const count = await query.count();
    dispatch(setCount(count));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const getOtherPageItem = (
  pageType,
  includedOffices,
  limit,
  page,
  categories,
  subCategories,
) => async (dispatch, getState) => {
  try {
    const state = getState();
    const query = queryForMeasureSheetItems(
      state,
      includedOffices,
      categories,
      subCategories,
    );
    let result;
    if (pageType === 'next') {
      query.skip(page * limit);
      result = await query.first();
    }
    if (pageType === 'prev') {
      const prevPageLastItem = (page - 1) * limit - 1;
      query.skip(prevPageLastItem);
      result = await query.first();
    }
    dispatch(setOtherPageItem(result));
    return result;
  } catch (e) {
    dispatch(handleError(e));
  }
  return undefined;
};

export const startFetchItems = (
  includedOffices,
  limit,
  page,
  categories,
  subCategories,
) => async (dispatch, getState) => {
  try {
    const state = getState();
    const query = queryForMeasureSheetItems(
      state,
      includedOffices,
      categories,
      subCategories,
    );
    query.limit(limit);
    const skip = (page - 1) * limit;
    query.skip(skip);
    query.select(itemKeys);
    const items = await query.find();
    const existingItems = state.priceGuide2.items;
    const newItems = items.map((obj) => existingItems[obj.id] || obj);
    dispatch(setItems(newItems));
  } catch (e) {
    dispatch(handleError(e));
  }
};

/**
 *
 * @param {number | undefined} index the Array (MSItems) index to add the new item after
 * @param {Parse.Object[]} MSItems the SSMeasureSheetItems in the current view (category)
 * @returns {void}
 */
export const startAddNewItem = (index, MSItems) => async (
  dispatch,
  getState,
) => {
  try {
    /** @type {string} */
    let orderNumber;
    /** @type {string} */
    let category = '';
    // Place new items without an empty category at the top
    if (index === undefined) {
      const emptyCategoryItems = MSItems.filter(
        (item) => item.attributes.category === '',
      );
      const firstEmptyCategoryItem = emptyCategoryItems[0];
      const firstEmptyCategoryItemOrderNumber =
        firstEmptyCategoryItem?.attributes.orderNumber || null;
      orderNumber = generateKeyBetween(firstEmptyCategoryItemOrderNumber, null);
    } else {
      // New item in the middle of a category
      const currentItemId = MSItems[index].id;
      const categoryItems = MSItems.filter(
        (item) =>
          item.attributes.category === MSItems[index].attributes.category,
      );
      const currentItemIndex = categoryItems.findIndex(
        (item) => item.id === currentItemId,
      );
      const currentItem = categoryItems[currentItemIndex];
      const currentItemOrderNumber = currentItem.attributes.orderNumber || null;
      const nextItemOrderNumber =
        categoryItems[currentItemIndex + 1]?.attributes.orderNumber || null;
      orderNumber = generateKeyBetween(
        nextItemOrderNumber,
        currentItemOrderNumber,
      );
      category = currentItem.attributes.category;
    }
    const item = new MeasureSheetItem();
    const option = new PriceGuideOption();
    const { priceGuide2 = {} } = getState();
    const { itemIds = [], count = 0, includedOffices = [] } = priceGuide2;
    const newOffices =
      includedOffices
        ?.filter((id) => id !== '**No Office**')
        .map((id) => {
          const office = new Parse.Object('Office');
          office.id = id;
          return office.toPointer();
        }) || [];
    await item.save({
      accessories: [],
      additionalDetailObjects: [],
      category,
      formulaID: '',
      includedOffices: [...newOffices],
      itemName: '',
      itemNote: '',
      items: [option],
      measurementType: '',
      orderNumber,
      placeholders: [],
      qtyFormula: '',
      shouldShowSwitch: false,
      subCategory: '',
    });
    dispatch(setItem(item));
    dispatch(setOption(option));
    dispatch(setCount(count + 1));
    if (index === undefined) {
      dispatch(setItemIds([item.id, ...itemIds]));
    } else {
      if (index < MSItems.length - 1) {
        const updatedItemIds = [...itemIds];
        updatedItemIds.splice(index + 1, 0, item.id);
        dispatch(setItemIds(updatedItemIds));
      }
      if (index === MSItems.length - 1) {
        dispatch(setItemIds([...itemIds, item.id]));
      }
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    dispatch(handleError(e));
  }
};

const revertNestedKey = async (upCharges) => {
  const query = new Parse.Query('SSPriceGuideItem');
  const upChargeIds = Object.keys(upCharges);
  query.containedIn('objectId', upChargeIds);
  const objects = await query.find();

  if (objects.length > 0) {
    objects.forEach((obj) => {
      const upCharge = upCharges[obj.id];
      if (upCharge.id === obj.id) {
        const newValues = obj.get('accessoryPrices');
        upCharge.set('accessoryPrices', newValues);
        // eslint-disable-next-line no-underscore-dangle
        upCharge._clearPendingOps();
      }
    });
  }
  setUpCharges(upCharges);
};

export const startRefreshItems = (items) => async (dispatch, getState) => {
  const { priceGuide2 = {} } = getState();
  const { options, upCharges } = priceGuide2;
  const newItems = items.map((item) => {
    const itemOptions = item.get('items');
    const accessories = item.get('accessories') || [];
    const upChargeIds = accessories.map(({ id }) => id);
    const newMSInsance = item.newInstance();
    newMSInsance.revert();
    if (itemOptions && itemOptions.length) {
      itemOptions.forEach((itemOption) => {
        const option = options ? options[itemOption.id] : undefined;
        if (option) {
          const newInstance = option.newInstance();
          newInstance.revert();
        }
      });
    }
    if (upChargeIds && upChargeIds.length && upCharges) {
      revertNestedKey(upCharges);
      const filteredUpCharges = upChargeIds
        .map((id) => upCharges[id])
        .filter((obj) => obj);
      filteredUpCharges.forEach((upChargeOption) => {
        const newInstance = upChargeOption.newInstance();
        newInstance.revert();
      });
    }
    return newMSInsance;
  });
  dispatch(setItems(newItems));
};

export const startFetchOptionsForItem = (item) => async (dispatch) => {
  try {
    await item.fetch({ include: 'items' });
    const options = item.get('items');
    await Parse.Object.fetchAllIfNeeded(options, {
      include: [
        'displayTitle',
        'subCategory2',
        'itemPrices',
        'placeholders',
        'isAccessory',
      ],
    });
    const object = {};
    options.forEach((obj) => {
      object[obj.id] = new PriceGuideOption(obj);
    });
    dispatch(setOptions(object));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const onPriceGuideScroll = (event) => ({
  type: 'ON_PRICE_GUIDE_SCROLL',
  event,
});

export const startFetchOptionsForItems = (items) => async (dispatch) => {
  items.forEach((item) => {
    dispatch(startFetchOptionsForItem(item));
  });
};

export const startFetchUpChargesForItem = (item) => async (dispatch) => {
  try {
    const upCharges = item.get('accessories');
    await Parse.Object.fetchAllIfNeeded(upCharges, {
      include: [
        'name',
        'info',
        'measurementType',
        'accessoryPrices',
        'image',
        'identifier',
        'additionalDetails',
        'placeholders',
        'shouldShowSwitch',
        'percentagePrice',
        'disabledParents',
        'isAccessory',
      ],
    });
    const object = {};
    upCharges.forEach((obj) => {
      object[obj.id] = new UpCharge(obj);
    });
    dispatch(setUpCharges(object));
    setTimeout(() => {
      dispatch(onPriceGuideScroll({}));
    }, 500);
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const startDeleteItem = (item) => async (dispatch) => {
  try {
    await item.destroy();
    dispatch(deleteItem(item));
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const startMassPriceChange = (params, callback) => async (
  dispatch,
  getState,
) => {
  const {
    plan = {},
    auth: { offices = [] },
  } = getState();
  const newParams = { ...params };
  if (plan.maxOfficeCount === 1) {
    newParams.offices = [offices[0].id];
  }

  try {
    const result = await Parse.Cloud.run('increasePrices', newParams);
    callback(result);
    dispatch(
      showSuccessAlert({
        title:
          'Mass Price Change, You will receive an email once the price change is complete',
        message: result,
        onConfirm: () => {
          dispatch(hideAlert());
        },
      }),
    );
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const startDownloadOwensCorning = (callback) => async (dispatch) => {
  try {
    const result = await Parse.Cloud.run('owensCorningPriceGuide');
    callback(result);
    dispatch(
      showSuccessAlert({
        title: 'Download Owens Corning Price Guide',
        message: result,
        onConfirm: () => {
          dispatch(hideAlert());
        },
      }),
    );
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const startDownloadSoftLite = (params, callback) => async (dispatch) => {
  try {
    const result = await Parse.Cloud.run('softLitePriceGuide', params);
    callback(result);
    dispatch(
      showSuccessAlert({
        title: 'Download Soft-Lite Price Guide',
        message: result,
        onConfirm: () => {
          dispatch(hideAlert());
        },
      }),
    );
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const startExportPriceGuide = () => async (dispatch) => {
  try {
    const message = await Parse.Cloud.run('exportPriceGuideToExcel');
    AppToaster.show({ message, timeout: 3000 });
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const startImportPriceGuide = (params) => async (dispatch) => {
  try {
    const message = await Parse.Cloud.run('importPriceGuideFromExcel', params);
    AppToaster.show({ message, timeout: 3000 });
  } catch (e) {
    dispatch(handleError(e));
  }
};

export const startMassCopy = ({
  sourceItem,
  destinationItems,
  valuesToCopy,
}) => async (dispatch) => {
  try {
    const params = {
      sourceObjectId: sourceItem.id,
      destinationObjectIds: destinationItems,
      keys: valuesToCopy,
    };
    const result = await Parse.Cloud.run('copyValues', params);
    return result;
  } catch (e) {
    return dispatch(handleError(e));
  }
};

const removeOptionFromUpChargeList = (props) => {
  const { upCharge, optionId } = props;
  const { disabledParents = [] } = upCharge.toJSON();
  const disabledParentsUpdate = disabledParents.filter(
    (parentOptionId) => parentOptionId !== optionId,
  );
  upCharge.set('disabledParents', disabledParentsUpdate);
  return upCharge;
};

const addOptionToUpChargeList = (props) => {
  const { upCharge, optionId } = props;
  const { disabledParents = [] } = upCharge.toJSON();
  const disabledParentsUpdate = disabledParents.filter(
    (parentOptionId) => parentOptionId !== optionId,
  );
  disabledParentsUpdate.push(optionId);
  upCharge.set('disabledParents', disabledParentsUpdate);
  return upCharge;
};

export const toggleAllUpChargesForOption = (props) => async (
  dispatch,
  getState,
) => {
  try {
    const { itemId, checked, optionId } = props;
    const { priceGuide2 } = getState();
    const { items } = priceGuide2;
    const selectedItem = items[itemId];
    const selectedItemUpCharges = selectedItem.get('accessories');
    const updatedUpCharges = selectedItemUpCharges
      .map((upCharge) => {
        const { createdAt, ...data } = upCharge;
        return new UpCharge({ ...data });
      })
      .map((upCharge) => {
        if (checked) {
          return removeOptionFromUpChargeList({ upCharge, optionId });
        }
        return addOptionToUpChargeList({ upCharge, optionId });
      });

    const updatedUpChargeIdHash = updatedUpCharges.reduce(
      (hash, upCharge) => ({
        [upCharge.id]: upCharge,
        ...hash,
      }),
      {},
    );

    dispatch(setUpCharges(updatedUpChargeIdHash));
  } catch (e) {
    dispatch(handleError(e));
  }
};
