import React from 'react';
import PropTypes from 'prop-types';

const FormulaPart = ({ section }) => {
  if (section.symbol) {
    return (
      <span
        style={{
          color: section.color,
          fontSize: 20,
          whiteSpace: 'nowrap',
          margin: 5,
          fontWeight: 'bold',
        }}
      >
        {section.value || 0}
      </span>
    );
  }
  return (
    <span style={{ fontSize: 20, whiteSpace: 'nowrap', margin: 5 }}>
      {section}
    </span>
  );
};

export default FormulaPart;

FormulaPart.propTypes = {
  section: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
      ]),
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
};

FormulaPart.defaultProps = {};
